import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { CenterAlignStack, FormErrorMessage, TextInput } from 'components'
import { Desktop, Mobile, useBreakPoint } from 'hooks/useMediaQuery'
import { useEffect, useRef, useState } from 'react'
import { apis } from 'apis'
import { DataGrid } from '@mui/x-data-grid'
import { useSnackbar } from 'notistack'
import moment from 'moment'

export const columns = [
  {
    field: 'id',
    width: 80,
    headerName: 'ID',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'username',
    width: 200,
    headerName: 'Username',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => (
      <Typography fontSize="2.2rem" fontWeight={700} color="red">
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'credit',
    width: 120,
    headerName: 'credit',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
]

export default function AdminCredit() {
  const [note, setNote] = useState('어드민 지급')
  const [userIdInput, setUserIdInput] = useState('')
  const [targetUser, setTargetUser] = useState(null)
  const [creditAmount, setCreditAmount] = useState(10)
  const [useCreditAmount, setUseCreditAmount] = useState(1)
  const { enqueueSnackbar } = useSnackbar()
  const [expires, setExpres] = useState('1m')

  useEffect(() => {}, [])

  const getTargetUser = () => {
    if (userIdInput.length < 1) return
    apis.admin.getUserById(userIdInput).then(response => {
      setTargetUser(response.data[0])
    })
  }

  const handleAddCredit = () => {
    const c = confirm(`${targetUser?.username} 에게 ${creditAmount} 크레딧을 지급합니다??`)
    if (!c) return

    let exp = moment().add(1, 'M').local().format('YYYY-MM-DDT23:59:59')

    if (expires === '1m') {
      exp = moment().add(1, 'M').local().format('YYYY-MM-DDT23:59:59')
    } else if (expires === '2y') {
      exp = moment().add(2, 'Y').local().format('YYYY-MM-DDT23:59:59')
    }

    const expUtc = moment.utc(new Date(exp)).format('YYYY-MM-DDTHH:mm:ss')

    apis.admin
      .addCreditToUser({
        target_user_id: targetUser.id,
        credit_amount: creditAmount,
        note,
        expires: expUtc,
      })
      .then(() => {
        enqueueSnackbar('지급되었습니다', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('오류', { variant: 'error' })
      })
  }

  const handleUseCredit = () => {
    const c = confirm(`${targetUser?.username}에게서 ${useCreditAmount} 크레딧 차감합니다`)
    if (!c) return

    apis.admin
      .useUserCredit({
        id: targetUser.id,
        use_amount: useCreditAmount,
      })
      .then(() => {
        getTargetUser()
      })
  }

  return (
    <div>
      <Desktop>
        <CenterAlignStack sx={{ width: '100%' }} spacing="1rem">
          <Typography sx={{ fontWeight: 800, fontSize: '3rem' }}>
            크레딧 지급 - 지급시 유저 잘 체크!! 주의하세여!!
          </Typography>

          <Stack direction="row">
            <TextField
              InputLabelProps={{ shrink: true }}
              label="user id 또는 username"
              autoComplete="off"
              value={userIdInput}
              onChange={e => setUserIdInput(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  getTargetUser()
                }
              }}
            ></TextField>
            <Button variant="outlined" onClick={getTargetUser}>
              유저 조회
            </Button>
          </Stack>

          <Box sx={{ width: '50rem', height: '20rem' }}>
            <Typography>유저정보</Typography>

            <DataGrid
              disableColumnMenu
              rows={targetUser ? [targetUser] : []}
              columns={columns}
              autoHeight
              hideFooter
            />
          </Box>

          <Stack direction="row">
            <FormControl>
              <InputLabel shrink id="demo-simple-select-label" sx={{ backgroundColor: 'white' }}>
                유효기간
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                label="유효기간"
                sx={{ width: '10rem' }}
                value={expires}
                onChange={e => {
                  setExpres(e.target.value)
                }}
              >
                <MenuItem value={'1m'}>한달</MenuItem>
                <MenuItem value={'2y'}>2년</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="메모"
              autoComplete="off"
              value={note}
              onChange={e => setNote(e.target.value)}
            ></TextField>
            <TextField
              label="크레딧"
              placeholder="크레딧"
              autoComplete="off"
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              value={creditAmount}
              onChange={e => setCreditAmount(e.target.value)}
            ></TextField>
          </Stack>
          <Button
            sx={{ width: '50rem', height: '6rem' }}
            variant="outlined"
            disabled={!targetUser || creditAmount < 1}
            onClick={handleAddCredit}
          >
            지급
          </Button>

          <hr style={{ width: '700px' }} />
          <br />
          <br />
          <br />

          <TextField
            label="차감할 크레딧"
            placeholder="차감할 크레딧"
            autoComplete="off"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={useCreditAmount}
            onChange={e => setUseCreditAmount(e.target.value)}
          ></TextField>
          <Button
            sx={{ width: '10rem', height: '4rem', borderColor: 'coral', color: 'coral' }}
            variant="outlined"
            disabled={!targetUser}
            onClick={handleUseCredit}
          >
            차감
          </Button>
        </CenterAlignStack>
      </Desktop>
    </div>
  )
}
