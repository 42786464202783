// 제휴 파트너사 관련 api

import axios from 'axios'
import crudAxios from './configs/crud'

const path = '/partners'

export const partnersAPI = {
  auth: body => {
    return crudAxios.post(`${path}/auth`, body)
  },

  getPromotionPricing: body => {
    return crudAxios.post(`${path}/pricing`, body)
  },
  getPromotionInfo: body => {
    return crudAxios.post(`${path}/promotion`, body)
  },
}
