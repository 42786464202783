import { useTranslation } from 'react-i18next'
import { LANGUAGES } from 'config'

export const English = ({ children }) => {
  const { i18n } = useTranslation()
  return i18n.language !== 'ko' ? children : null
}

export const Korean = ({ children }) => {
  const { i18n } = useTranslation()
  return i18n.language === 'ko' ? children : null
}

export const getStaticResourceLanguage = i18nLanguage => {
  // 이미지, 비디오 등 언어별로 따로 있는 리소스에 대하여
  // 새로운 언어 추가되었을 때 해당 언어의 이미지/비디오가 없으면 영어를 디폴트로 표시해주기 위해 ..
  if (LANGUAGES.includes(i18nLanguage)) {
    return i18nLanguage
  } else {
    return 'en'
  }
}
