import { useRecoilState, useResetRecoilState } from 'recoil'
import { confirmDialogAtom } from 'atoms'

export default function useConfirm() {
  const [confirmDialog, setConfirmDialog] = useRecoilState(confirmDialogAtom)
  const resetData = useResetRecoilState(confirmDialogAtom)

  const showConfirm = props => {
    setConfirmDialog({ ...confirmDialog, open: true, ...props })
  }

  const hideConfirm = () => {}

  return {
    confirmDialog,
    setConfirmDialog,
    showConfirm,
    hideConfirm,
  }
}
