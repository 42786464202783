import axios from 'axios'
import { configData } from 'config'
import * as cookie from 'utils/cookie'
import moment from 'moment'
import { sendApiErrorAlert } from 'utils/common'
import { apis } from 'apis'

let isTokenRefreshing = false

const guestAxios = axios.create({
  baseURL: configData.DB_URL,
  withCredentials: true,
  headers: {
    time_delta: moment().utcOffset() / 60,
  },
})

guestAxios.interceptors.request.use(config => {
  const sessionToken = cookie.getGuestSession()

  if (sessionToken) {
    config.headers.Authorization = `Bearer ${sessionToken}`
    config.headers[cookie.guestSessionToken] = sessionToken
  }

  const guestAccessToken = cookie.getGuestAccess()

  if (guestAccessToken) {
    config.headers[cookie.guestAccessToken] = guestAccessToken
  }
  return config
})

guestAxios.interceptors.response.use(
  response => response,
  async error => {
    const { response } = error

    if (response && response.status && response.status === 401) {
      if (!isTokenRefreshing) {
        isTokenRefreshing = true

        try {
          const res1 = await apis.guest.getGuestSession()
          if (res1.data) {
            if (res1.data.s) {
              cookie.setGuestSession(res1.data.s)
            }
          } else {
            //
          }
          isTokenRefreshing = false
        } catch (error) {
          cookie.removeUserCookie()
          window.location.href = '/login'
        } finally {
          isTokenRefreshing = false
        }
      }
    } else if (response && response.status && response.status === 500) {
      sendApiErrorAlert({
        path: error.request.responseURL,
        httpStatus: response.status,
        message: error.message,
        detail: response,
      })
    }
    return Promise.reject(error)
  }
)
export default guestAxios

export const refreshGuestAccess = async () => {}
