import { indexAtom, removeMaskImgAtom, selectedMaskImgAtom } from 'atoms'
import { useRecoilState, useResetRecoilState } from 'recoil'

export function useFragment() {
  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [index, SetIndex] = useRecoilState(indexAtom)

  const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)

  const handleReset = () => {
    resetSelectedMaskImg()
  }

  const handleUndo = () => {
    if (index > -1) {
      const removedNumber = selectedMaskImg[index]
      SetRemoveMaskImg([removedNumber, ...removeMaskImg]) // 빠진 숫자 저장
      setSelectedMaskImg(selectedMaskImg.slice(0, index))
    }
  }

  const handleRedo = () => {
    if (removeMaskImg.length > 0) {
      const newNumbers = [...selectedMaskImg, removeMaskImg[0]]
      setSelectedMaskImg(newNumbers)
      SetRemoveMaskImg(removeMaskImg.slice(1)) // 빠진 숫자에서 가져온 것 제거
      SetIndex(index + 1)
    }
  }

  return { handleReset, handleUndo, handleRedo }
}
