import CircularSlider from '@fseehawer/react-circular-slider'
import { bgConfigAtom, lightDirectionAtom, touchHandleAtom } from 'atoms'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

const LIGHT_RANGE_FLOOR = 22.5

export function FloorShadow() {
  const [bgConfig, setBgConfig] = useRecoilState(bgConfigAtom)
  const lightDirection = useRecoilValue(lightDirectionAtom)
  const setTouchHandle = useSetRecoilState(touchHandleAtom)

  const sphereRef = useRef(null)
  const shadowRef = useRef(null)
  const [value, setValue] = useState(0)
  const [lightPos, setLightPos] = useState(0)

  const translateValue = value => {
    if (value >= LIGHT_RANGE_FLOOR * 1 && value < LIGHT_RANGE_FLOOR * 3) {
      return 7
    }
    if (value >= LIGHT_RANGE_FLOOR * 3 && value < LIGHT_RANGE_FLOOR * 5) {
      return 0
    }
    if (value >= LIGHT_RANGE_FLOOR * 5 && value < LIGHT_RANGE_FLOOR * 7) {
      return 1
    }
    if (value >= LIGHT_RANGE_FLOOR * 7 && value < LIGHT_RANGE_FLOOR * 9) {
      return 2
    }
    if (value >= LIGHT_RANGE_FLOOR * 9 && value < LIGHT_RANGE_FLOOR * 11) {
      return 3
    }
    if (value >= LIGHT_RANGE_FLOOR * 11 && value < LIGHT_RANGE_FLOOR * 13) {
      return 4
    }
    if (value >= LIGHT_RANGE_FLOOR * 13 && value < LIGHT_RANGE_FLOOR * 15) {
      return 5
    }
    if (value >= LIGHT_RANGE_FLOOR * 15 || value < LIGHT_RANGE_FLOOR * 1) {
      return 6
    }
  }

  useEffect(() => {
    if (lightDirection === 'vertical') {
      const updateBgConfig = { ...bgConfig }
      updateBgConfig.light_src = 8

      setBgConfig(updateBgConfig)
    } else {
      const updateBgConfig = { ...bgConfig }

      updateBgConfig.light_src = translateValue(value)
      setBgConfig(updateBgConfig)
    }
  }, [value, lightDirection])

  const shadowStyleHori = {
    position: 'absolute',
    width: '200px',
    height: '150px',
    top: '77px',
    left: '22px',
    background: 'linear-gradient(to right, #9b9b9b, white)',
    transformOrigin: '30% 50%',
    transform: `scaleY(0.3) rotate3d(0, 0, 0.1, -${value}deg)`,
    borderRadius: '50%',
    filter: 'blur(10px)',
  }

  const shadowStyleVerti = {
    position: 'absolute',
    width: '169px',
    height: '155px',
    top: '84px',
    left: '-2px',
    background: 'linear-gradient(to right, #b2b2b26a, #b2b2b26a)',
    transformOrigin: '30% 50%',
    transform: `scaleY(0.2) rotate3d(1, 0, 0, 0deg)`,
    borderRadius: '50%',
    filter: 'blur(13px)', // 추가됨
  }

  const spherStyleHori = {
    position: 'relative',
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    background: `radial-gradient( circle at ${lightPos}% 40%, #FFF, #EAEAEA )`,
    zIndex: '3',
    boxShadow:
      'rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px',
  }
  const spherStyleVerti = {
    position: 'relative',
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    background: `radial-gradient( circle at 50% 0%, #FFF, #EAEAEA)`,
    zIndex: '3',
    boxShadow:
      'rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px',
  }

  const lightPosFunc = value => {
    if (value >= 180 && value <= 270) {
      return 5 * value - 800
    }

    if (value >= 270 && value <= 360) {
      return 5 * value - 1801
    }

    return (value * 100) / 180
  }

  return (
    <div style={{ height: '100%', display: 'flex' }}>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ transform: 'scaleX(1.5)' }}>
          {lightDirection === 'horizontal' ? (
            <CircularSlider
              direction={-1}
              labelColor="#005a58"
              knobColor="#868686"
              knobPosition="left"
              knobDraggable={bgConfig.cast_shadow || false}
              progressColorFrom="#5B5B5B"
              progressColorTo="#5B5B5B"
              progressSize={1}
              trackColor="#5B5B5B"
              trackSize={1}
              dataIndex={1}
              min={0}
              max={360}
              width={220}
              hideLabelValue={false}
              onChange={value => {
                setValue(() => value)
                setLightPos(() => lightPosFunc(value))
              }}
              isDragging={() => {
                setTouchHandle(true)
              }}
            >
              {''}
            </CircularSlider>
          ) : null}
        </div>
        <div style={{ position: 'absolute', top: '16px' }}>
          <div
            ref={sphereRef}
            style={lightDirection === 'horizontal' ? spherStyleHori : spherStyleVerti}
          ></div>
          <div
            ref={shadowRef}
            style={
              bgConfig.cast_shadow === true
                ? lightDirection === 'horizontal'
                  ? shadowStyleHori
                  : shadowStyleVerti
                : null
            }
          ></div>
        </div>
      </div>
    </div>
  )
}
