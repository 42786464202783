import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Input,
  InputAdornment,
  Typography,
  Paper,
  Tabs,
  Tab,
  Divider,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import {
  BgOntlinedInput,
  CenterAlignBox,
  CenterAlignStack,
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
  SearchInput,
  TempLayoutDiv,
} from 'components'
import { Desktop, Mobile } from 'hooks/useMediaQuery'

import { DRAWER_WIDTH_PERCENTAGE } from 'layouts/InfoSideBar'
import { MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import { useEffect, useState, createElement } from 'react'
import { AiOutlineDown, AiOutlineSearch } from 'react-icons/ai'
import { DropdownArrowIcon, SearchIcon } from 'theme/icon'
import C from './test/C'
import { INFO_PERCENTAGE_WIDHT } from 'layouts/InfoLayout'
import { useTranslation } from 'react-i18next'

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: 500,

  '&.Mui-selected': {
    color: theme.palette.common.black,
    fontWeight: 800,
  },

  // [theme.breakpoints.down('lg')]: {
  //   '&.Mui-selected': {
  //     color: theme.palette.common.black,
  //     fontWeight: 600,
  //   },
  //   fontSize: '1.5rem',
  //   width: '100%',
  // },
}))

const StyledMobileTab = styled(Tab)(({ theme }) => ({
  fontSize: '1.6rem',
  fontWeight: 400,
  minHeight: '2rem',
  padding: 0,
  minWidth: 'fit-content',

  '&.Mui-selected': {
    color: theme.palette.draph.blue,
    fontWeight: 800,
  },

  // [theme.breakpoints.down('lg')]: {
  //   '&.Mui-selected': {
  //     color: theme.palette.common.black,
  //     fontWeight: 600,
  //   },
  //   fontSize: '1.5rem',
  //   width: '100%',
  // },
}))

export default function FAQ() {
  const [value, setValue] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [filteredPosts, setFilteredPosts] = useState([])
  const { Allposts } = useFAQobj()
  const { t, i18n } = useTranslation()

  const theme = useTheme()

  const tabChange = (event, newValue) => {
    setInputValue('')
    setValue(newValue)
  }

  const onChange = event => {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    setValue(3)
    if (!inputValue) return setValue(0)

    const filter = Allposts.filter((post, idx) => {
      const jsonDetail = JSON.stringify(post.details)
      const titleContains = post.title.includes(inputValue)
      const detailsContains = jsonDetail.includes(inputValue)
      return titleContains || detailsContains
    })
    setFilteredPosts(filter)
  }, [inputValue])

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ width: INFO_PERCENTAGE_WIDHT, pl: MYPAGE_SIDE_MARGIN }}>
          <Box
            sx={{
              height: '100%',
              width: '88.4rem',
              minHeight: '57rem',
              display: 'flex',
              flexDirection: 'column',
              mb: '5rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontSize: '3.6rem', fontWeight: 800 }}>FAQ</Typography>
            </Box>
            <Box
              sx={{
                mt: '2.1rem',
                mb: '1.3rem',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Tabs
                value={value}
                onChange={tabChange}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <StyledTab value={0} label={t('FAQ.pricing.class')} />
                <StyledTab
                  label=""
                  icon={<Divider orientation="vertical" />}
                  disabled
                  sx={{ minWidth: 0 }}
                />
                <StyledTab value={1} label={t('FAQ.service.class')} />
                <StyledTab
                  label=""
                  icon={<Divider orientation="vertical" />}
                  disabled
                  sx={{ minWidth: 0 }}
                />
                <StyledTab value={2} label={t('FAQ.user.class')} />
              </Tabs>

              <SearchInput
                value={inputValue}
                onChange={onChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Divider flexItem sx={{ border: '3px solid' }} />
            <FAQtabDetail value={value} filteredPosts={filteredPosts} inputValue={inputValue} />
          </Box>
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack sx={{ mb: '6.5rem' }}>
          <Typography sx={{ pt: '3rem', fontSize: '2.4rem', fontWeight: 800 }}>FAQ</Typography>

          <CenterAlignStack sx={{ mb: '1rem', ml: '2rem' }}>
            <Tabs
              value={value}
              onChange={tabChange}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: '100%',
                minHeight: '2rem',

                '& .MuiTabs-indicator': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <StyledMobileTab value={0} label={t('FAQ.pricing.class')} />
              <StyledTab
                label=""
                icon={
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ borderColor: theme.palette.common.black }}
                  />
                }
                disabled
                sx={{ minWidth: 0 }}
              />

              <StyledMobileTab value={1} label={t('FAQ.service.class')} />
              <StyledTab
                label=""
                icon={
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ borderColor: theme.palette.common.black }}
                  />
                }
                disabled
                sx={{ minWidth: 0 }}
              />
              <StyledMobileTab value={2} label={t('FAQ.user.class')} />
            </Tabs>
          </CenterAlignStack>

          <CenterAlignStack sx={{ width: '100%', py: '2.3rem', backgroundColor: '#F8F8F8' }}>
            <SearchInput
              value={inputValue}
              onChange={onChange}
              sx={{
                '& .MuiInputBase-root': {
                  width: '20rem',
                  backgroundColor: 'white',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </CenterAlignStack>

          <Divider flexItem sx={{ border: '2px solid' }} />
          <FAQtabDetail value={value} filteredPosts={filteredPosts} inputValue={inputValue} />
        </CenterAlignStack>
      </Mobile>
    </>
  )
}

function FAQtabDetail({ value, filteredPosts, inputValue }) {
  switch (value) {
    case 0:
      return <FAQpricing />
    case 1:
      return <FAQservice />
    case 2:
      return <FAQuserInfo />
    case 3:
      return <FilterPostsInfo filteredPosts={filteredPosts} inputValue={inputValue} />
  }
}

function FAQpricing() {
  const { pricingPosts } = useFAQobj()
  return (
    <>
      {pricingPosts.map(post => {
        return <Post key={post.id} title={post.title} details={post.details} />
      })}
    </>
  )
}
function FAQservice() {
  const { servicePosts } = useFAQobj()

  return (
    <>
      {servicePosts.map(post => {
        return <Post key={post.id} title={post.title} details={post.details} />
      })}
    </>
  )
}
function FAQuserInfo() {
  const { userPosts } = useFAQobj()

  return (
    <>
      {userPosts.map(post => {
        return <Post key={post.id} title={post.title} details={post.details} />
      })}
    </>
  )
}

function FilterPostsInfo({ filteredPosts, inputValue }) {
  return (
    <>
      {filteredPosts.map((post, i) => {
        return (
          <FilteredPost
            key={i}
            title={post.title}
            details={post.details}
            classification={post.class}
            inputValue={inputValue}
          />
        )
      })}
    </>
  )
}

const Post = ({ title, details }) => {
  const DetailsComponent = () => {
    return <div dangerouslySetInnerHTML={{ __html: details }} />
  }
  return (
    <>
      <CustomAccordion sx={{ width: '100%' }}>
        <CustomAccordionSummary
          expandIcon={<DropdownArrowIcon color="black" />}
          sx={{
            pr: '2rem',
            height: '6.4rem',
            '&.Mui-expanded': {
              borderTop: '1px solid rgb(128, 128, 128, 0.4)',
              fontWeight: 800,
            },
          }}
        >
          <Box
            sx={{
              mx: '2.5rem',
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: '1.5rem' }}>{title}</Typography>
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Box sx={{ px: '2.5rem', py: '2.4rem', fontWeight: 400, fontSize: '1.5rem' }}>
            {DetailsComponent()}
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
    </>
  )
}

const FilteredPost = ({ title, details, classification, inputValue }) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  const theme = useTheme()

  const handleAccordionChange = (event, expanded) => {
    setIsAccordionExpanded(expanded)
  }

  // 좀 더 뜯어보자.. 나중에
  const highlightText = text => {
    const regex = new RegExp(inputValue, 'gi')

    const wrapper = document.createElement('div')
    wrapper.innerHTML = text

    const textNodes = Array.from(wrapper.childNodes).filter(
      node => node.nodeType === Node.TEXT_NODE
    )

    textNodes.forEach(textNode => {
      const matches = textNode.textContent.match(regex)
      if (matches) {
        const highlightedText = document.createElement('span')

        let lastIndex = 0
        matches.forEach(match => {
          const index = textNode.textContent.indexOf(match, lastIndex)
          if (index > -1) {
            const before = document.createTextNode(textNode.textContent.substring(lastIndex, index))
            const highlightedMatch = document.createElement('span')
            highlightedMatch.style.color = theme.palette.draph.blue
            highlightedMatch.style.fontWeight = '700'
            highlightedMatch.innerHTML = match

            highlightedText.appendChild(before)
            highlightedText.appendChild(highlightedMatch)

            lastIndex = index + match.length
          }
        })

        if (lastIndex < textNode.textContent.length) {
          const remaining = document.createTextNode(textNode.textContent.substring(lastIndex))
          highlightedText.appendChild(remaining)
        }

        const parent = textNode.parentNode
        parent.replaceChild(highlightedText, textNode)
      }
    })

    // console.log(wrapper)

    // return text.replace(
    //   regex,
    //   `<span style="color:${theme.palette.draph.blue}; font-weight: 700">$&</span>`
    // )

    return wrapper.innerHTML
  }

  const highlightedTitle = inputValue ? highlightText(title) : title
  const highlightedDetails = inputValue ? highlightText(details) : details

  return (
    <>
      <CustomAccordion
        sx={{ width: '100%' }}
        expanded={isAccordionExpanded}
        onChange={handleAccordionChange}
      >
        <CustomAccordionSummary
          expandIcon={<DropdownArrowIcon color="black" />}
          sx={{
            pr: '2rem',
            height: '12.4rem',
            '&.Mui-expanded': {
              borderTop: '1px solid rgb(128, 128, 128, 0.4)',
              fontWeight: 800,
            },
          }}
        >
          <Box
            sx={{
              mx: '2.5rem',
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: '1.8rem', mb: '1.2rem' }}>
              {classification}
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: '1.6rem', mb: '0.8rem', lineHeight: '1.9rem' }}
              dangerouslySetInnerHTML={{ __html: highlightedTitle }}
            />
            {!isAccordionExpanded && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '1.3rem',
                  width: { lg: '45rem', xs: '27rem' },
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  height: '1.95rem',
                }}
                dangerouslySetInnerHTML={{ __html: highlightedDetails }}
              />
            )}
          </Box>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Box sx={{ px: '2.5rem', py: '2.4rem' }}>
            <Typography
              sx={{ fontWeight: 400, fontSize: '1.5rem' }}
              dangerouslySetInnerHTML={{ __html: highlightedDetails }}
            />
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
    </>
  )
}

const useFAQobj = () => {
  const { t, i18n } = useTranslation()

  const servicePosts = [
    {
      id: 1,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_1')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_1')} `,
    },
    {
      id: 2,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_2')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_2')} `,
    },
    {
      id: 3,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_3')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_3_a')} <br /> ${t('FAQ.service.detail_3_b')}`,
    },
    {
      id: 4,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_4')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_4')} `,
    },
    {
      id: 5,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_5')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_5_a')} <br /> <br /> ${t(
        'FAQ.service.detail_5_b'
      )} <br />  ${t('FAQ.service.detail_5_c')} <br /> ${t('FAQ.service.detail_5_d')}<br />   ${t(
        'FAQ.service.detail_5_e'
      )}`,
    },
    {
      id: 6,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_6')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_6')} `,
    },
    {
      id: 7,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_7')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_7')} `,
    },
    {
      id: 8,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_8')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_8')} `,
    },

    {
      id: 9,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_9')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_9')} `,
    },
    {
      id: 10,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_10')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_10')} `,
    },
    {
      id: 11,
      class: `${t('FAQ.service.class')} `,
      title: `${t('FAQ.service.title_11')} `,
      date: `23.03.01`,
      details: `${t('FAQ.service.detail_11')} `,
    },
  ]

  // ${t('FAQ.pricing.title_1')}

  const pricingPosts = [
    {
      id: 1,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_1')}`,
      date: `23.03.01`,
      details: `${t('FAQ.pricing.detail_1')}`,
    },
    {
      id: 2,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_2')}`,
      date: `23.03.01`,
      details: `${t('FAQ.pricing.detail_2_a')} <br /> <br />
      ${t('FAQ.pricing.detail_2_b')} <br />${t('FAQ.pricing.detail_2_c')}`,
    },
    {
      id: 3,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_3')}`,
      date: `23.03.01`,
      details: `${t('FAQ.pricing.detail_3_a')} <br /> ${t(
        'FAQ.pricing.detail_3_b'
      )} <br /><br />${t('FAQ.pricing.detail_3_c')}  <br /> ${t('FAQ.pricing.detail_3_d')} `,
    },

    {
      id: 4,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_4')}`,
      date: `23.03.01`,
      details: `${t('FAQ.pricing.detail_4_a')} <br /><br /> ${t(
        'FAQ.pricing.detail_4_b'
      )}  <br />${t('FAQ.pricing.detail_4_c')}`,
    },
    {
      id: 5,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_5')}`,
      date: `23.03.01`,
      details: `${t('FAQ.pricing.detail_5')}`,
    },
    {
      id: 6,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_6')}`,
      date: `23.03.01`,
      details: `${t('FAQ.pricing.detail_6')}`,
    },
    {
      id: 7,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_7')}`,
      date: `23.03.01`,
      details: `${t('FAQ.pricing.detail_7')}`,
    },
    {
      id: 8,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_8')}`,
      date: `23.03.01`,
      details: `${t(
        'FAQ.pricing.detail_8'
      )}<br /> <br /> <a href="https://forms.gle/NtFizsUdmcT4JDDT9" style="text-decoration: none; color: inherit;" target="_blank" > ${t(
        'FAQ.pricing.detail_8_link'
      )} </a> `,
    },
    {
      id: 9,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_9')}`,
      date: `23.03.01`,
      details: `${t('FAQ.pricing.detail_9')}`,
    },
    {
      id: 10,
      class: `${t('FAQ.pricing.class')}`,
      title: `${t('FAQ.pricing.title_10')}`,
      date: `23.03.01`,
      details: `${t('FAQ.pricing.detail_10_a')}<br /> ${t('FAQ.pricing.detail_10_b')}`,
    },
  ]

  const userPosts = [
    {
      id: 1,
      class: `${t('FAQ.user.class')}`,
      title: `${t('FAQ.user.title_1')}`,
      date: `23.03.01`,
      details: `${t('FAQ.user.detail_1_a')}<br /> ${t('FAQ.user.detail_1_b')} <br /> <br /> ${t(
        'FAQ.user.detail_1_c'
      )} <br /> ${t('FAQ.user.detail_1_d')}`,
    },
    {
      id: 2,
      class: `${t('FAQ.user.class')}`,
      title: `${t('FAQ.user.title_2')}`,
      date: `23.03.01`,
      details: `${t('FAQ.user.detail_2')}`,
    },
    {
      id: 3,
      class: `${t('FAQ.user.class')}`,
      title: `${t('FAQ.user.title_3')}`,
      date: `23.03.01`,
      details: `${t('FAQ.user.detail_3')}`,
    },
  ]

  const Allposts = [...servicePosts, ...pricingPosts, ...userPosts]

  return { servicePosts, pricingPosts, userPosts, Allposts }
}
