import { useCallback, useEffect, useRef, useState } from 'react'

import { Button, ButtonGroup, Box, Typography, Slider, Circle } from '@mui/material'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import styles from './TestPage6.module.css'
import CircularSlider from '@fseehawer/react-circular-slider'

function App() {
  const sphereRef = useRef(null)
  const shadowRef = useRef(null)
  const [value, setValue] = useState(0)
  const [lightPos, setLightPos] = useState(0)

  const shadowStyle = {
    position: 'absolute',
    left: '94px',
    top: '368px',
    marginLeft: '200px',
    marginTop: '-35px',
    width: '530px',
    height: '350px',
    background: 'linear-gradient(to right, #b2b2b26a, white)',
    transformOrigin: '30% 50%',
    transform: `scaleY(0.3) rotate3d(0, 0, 0.1, -${value}deg)`,
    borderRadius: '50%',
  }

  const spherStyle = {
    width: '500px',
    height: '500px',
    borderRadius: '50%',
    margin: '10px 10px 10px 200px',
    background: `radial-gradient( circle at ${lightPos}% 40%, #FFFFFF, #5BE584 )`,
    position: 'relative',
    zIndex: '1',
    boxShadow:
      'rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px',
  }

  const lightPosFunc = value => {
    if (value >= 180 && value <= 270) {
      return 5 * value - 800
    }

    if (value >= 270 && value <= 360) {
      return 5 * value - 1801
    }

    return (value * 100) / 180
  }

  return (
    <div className="container">
      <div ref={sphereRef} style={spherStyle}>
        <div style={{ position: 'absolute', left: '39px', top: '39px' }}>
          <CircularSlider
            width={420}
            direction={-1}
            labelColor="#005a58"
            knobColor="#005a58"
            knobPosition="left"
            progressColorFrom="#eeeeee"
            progressColorTo="#eeeeee"
            progressSize={4}
            trackColor="#eeeeee"
            trackSize={4}
            dataIndex={1}
            min={0}
            max={360}
            hideLabelValue={true}
            onChange={value => {
              setValue(() => value)
              setLightPos(() => lightPosFunc(value))
            }}
            isDragging={value => {}}
          />
        </div>
      </div>
      <div ref={shadowRef} style={shadowStyle}></div>
    </div>
  )
}

function TestPage6() {
  return (
    <>
      <App />
    </>
  )
}

export default TestPage6
