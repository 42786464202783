import { apis } from 'apis'
import { userAtom } from 'atoms'
import { forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { FormErrorMessage } from './FormInput'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { iOS } from 'utils/common'
import MobileSwipeableDrawer from './MobileSwipeableDrawer'

const {
  Dialog,
  DialogContent,
  Stack,
  OutlinedInput,
  useTheme,
  Typography,
  Box,
  IconButton,
  InputAdornment,
  Slide,
  Button,
} = require('@mui/material')
const { CenterAlignStack } = require('./CenterAlignContainer')

const { DialogButton } = require('./DialogButtons')

const Transition = forwardRef(function NoTransition(props, ref) {
  return (
    <Slide direction="left" ref={ref} {...props} in={props.in} timeout={{ enter: 200, exit: 0 }} />
  )
})

export default function CheckPasswordDialog({
  open,
  setOpen,
  openNextDialog,
  setOpenNextDialog,
  type = null, // withdrawal or unsubscribe
}) {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const user = useRecoilValue(userAtom)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (open) {
      if (type) {
        // ----- GA4 event -----
        window.gtag('event', `${type}_process`, { menu: 'enter_password' })
        // ---------------------
      }
    }
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = () => {
    // API pwd 체크 후.. 아니면 오류 메세지 나오도록 ~
    apis.user.checkPassword({ username: user.username, password }).then(res => {
      const isSuccess = res.data.success

      if (isSuccess) {
        setOpen(false)
        setOpenNextDialog(true)
      } else {
        // 오류 처리
        setError(true)
      }
    })
  }

  const contents = (
    <Stack>
      <Typography
        sx={{
          fontSize: { lg: '2.0rem', xs: '1.6rem' },
          fontWeight: { lg: 700, xs: 800 },
          lineHeight: 'normal',
        }}
      >
        {t('password_dialog.title')}
      </Typography>
      <Typography
        sx={{
          fontSize: { lg: '1.4rem', xs: '1.3rem' },
          fontWeight: { lg: 500, xs: 400 },
          color: { lg: theme.palette.common.black, xs: '#595959' },
        }}
      >
        {t('password_dialog.subTitle')}
      </Typography>

      <Stack sx={{ p: '2.4rem 0', position: 'relative' }}>
        <Typography
          sx={{
            fontSize: { lg: '1.4rem', xs: '1.4rem' },
            mb: { lg: '0.4rem', xs: '0.4rem' },
          }}
        >
          {t('password_dialog.password')}
        </Typography>
        <OutlinedInput
          placeholder={t('password_dialog.password_placeholder')}
          value={password}
          type={showPassword ? 'text' : 'password'}
          onChange={e => {
            if (error) {
              setError(false)
            }
            setPassword(e.target.value)
          }}
          sx={{
            width: { lg: '35rem', xs: '32rem' },
            height: { lg: '4.8rem', xs: '3.6rem' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#D9E1EC',
            },
            '& .MuiOutlinedInput-input': { padding: 0 },
          }}
          inputprops={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(p => !p)}
                  sx={{ fontSize: { lg: '1.6rem', xs: '1.4rem' } }}
                >
                  {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {error && (
          <FormErrorMessage
            message="비밀번호를 다시 확인해주세요."
            style={{
              height: error ? '1.6rem' : '0',
            }}
          />
        )}
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{
            minWidth: '10.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            color: theme.palette.common.black,
            '&:hover': { background: '#ffffff', color: theme.palette.common.red },
          }}
          onClick={handleAction}
        >
          {t('button.confirmation')}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '9.9rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 400,
          }}
          onClick={handleClose}
        >
          {t('button.cancel')}
        </Button>
      </Box>
    </Stack>
  )

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: { lg: '40rem', xs: '320px' },
              height: { lg: 'auto', xs: '320px' },
              borderRadius: '10px',
            },
          }}
        >
          <DialogContent
            sx={{
              overflowY: 'hidden',
              padding: '2.8rem 2.4rem',
            }}
          >
            {contents}
          </DialogContent>
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <CenterAlignStack sx={{ mt: '0.8rem', mb: '2.2rem' }}>{contents}</CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}
