// ----------------------------------------------------------------------

import { SelectSideIcon } from 'theme/icon'

export default function Select(theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: SelectSideIcon,

        // 아이콘 사이즈 조절

        MenuProps: {
          PaperProps: {
            sx: {
              borderRadius: 0,
              '& .MuiMenu-list': {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          },
        },
      },

      styleOverrides: {
        root: {
          width: '8.4rem',
          height: '3.6rem',
          padding: '1px 0 0 0',
          color: `${theme.palette.common.black}`,
          backgroundColor: theme.palette.common.white,
          fontWeight: '500',
          fontSize: '1.5rem',

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.common.black,
          },

          '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.draph.blue,
          },
          '&:not(.Mui-disabled).Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.draph.blue,
          },
          '& MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input	': {
            padding: '0 3.8rem 0 2.3rem',
          },
          '& .MuiSelect-icon': {
            top: 'calc(50% - 0.5rem)',
          },

          '& .MuiSvgIcon-root': {
            right: '1rem',
          },

          '&.Mui-disabled .MuiSvgIcon-root path': {
            stroke: theme.palette.text.disabled,
          },
        },
      },
    },
  }
}
