import { configData } from 'config'
import {
  Box,
  Typography,
  Button,
  Stack,
  Select,
  MenuItem,
  Card,
  IconButton,
  TextField,
} from '@mui/material'
import {
  ScrollToTop,
  ArtworkList,
  ArtworkFilters,
  UploadWrapper,
  CenterAlignStack,
  CenterAlignBox,
  DragDropFullSize,
  UploadImageDialog,
  triggerGA4UploadEvent,
  EmptyArtworkList,
  UploadHeader,
  BundleConfigComponent,
  GuideComponent,
} from 'components'
import { BeforeAfterArrow, MinusIcon, PlusIcon, SettingIcon } from 'theme/icon'
import { styled, useTheme } from '@mui/material/styles'

import { useEffect, useState } from 'react'
import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  portfolioDetailAtom,
  userAtom,
  portfolioConfigAtom,
  artworkViewConfigAtom,
  userLikedBgAtom,
  tutorialAtom,
  endpointAtom,
  artworkListsIsFoldedAtom,
  defaultPortfolioAtom,
  dragDropOpenAtom,
  uploadDialogOpenAtom,
  uploadFilesAtom,
  uploadFilesAndUrlAtom,
  blockDragDropAtom,
} from 'atoms'
import { apis } from 'apis'
import { BarLoader as Loader, PuffLoader } from 'react-spinners'
import {
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
} from 'selector'
import useConfirm from 'hooks/useConfirm'
import _, { debounce } from 'lodash'
import axios from 'axios'
import { getAccessToken } from 'utils/cookie'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { APPBAR_DESKTOP, APPBAR_MOBILE } from 'layouts/main/MainAppBar'
import MainFooter from 'layouts/main/MainFooter'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import * as config from 'config'
import { useMotionValueEvent, useScroll, useTransform } from 'framer-motion'

import { PAGE_HEADER_HEIGHT, ScrollHandler } from './PortfolioDetail'
import { useTranslation } from 'react-i18next'
import { EmptyPageHeader, EmptyUploadHeader, PageHeaderLayout } from './PortfolioUpload'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { usePortfolio } from 'hooks/usePortfolio'

const MIN_BUNDLE = 2
const MAX_BUNDLE = 80

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    // alignItems: 'center',
  },
}))

const PageHeaderStyle = styled(Box)(({ theme }) => ({
  top: 0,
  minHeight: 'auto',
  justifyContent: 'center',
  alignItems: 'end',

  bordercolor: theme.palette.common.black,
  background: theme.palette.common.white,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '5.4rem',
    minHeight: PAGE_HEADER_HEIGHT,

    paddingBottom: '3.8rem',
    display: 'flex',
    flexDirection: 'row',
  },
}))

export default function Bundle() {
  const tutorial = useRecoilValue(tutorialAtom)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)

  const [uploadFiles, setUploadFiles] = useRecoilState(uploadFilesAndUrlAtom)

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'multipack_photo', {})
    // ---------------------

    return () => {}
  }, [])

  const token = getAccessToken()
  if (!token)
    return (
      <RootStyle>
        <Mobile>
          <EmptyPageHeader />
        </Mobile>

        <Desktop>
          <EmptyUploadHeader videoSrc={config.GUIDE_VIDEOS.bundle} />
        </Desktop>
        <EmptyArtworkList />
      </RootStyle>
    )

  return (
    <>
      {/* <Wrapper> */}
      <RootStyle
        style={{
          // overflow: tutorial.step === 15 ? 'hidden' : 'scroll',
          zIndex: tutorial.step === 17 ? '-1' : null,
        }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()

          if (!blockDragDrop) {
            setDragDropOpen(true)
          }
        }}
      >
        <>
          <UploadWrapper>
            <Desktop>
              <UploadHeader
                configComponent={<BundleConfigComponent />}
                uploadButtonComponent={<BundleConfigComponent uploadButtonOnly={true} />}
                gudieComponent={<GuideComponent />}
                /* 하단 2개가 들어가면 1개 모드로 변경  */
                multiple={false}
                uploadImglimit={1}
                configWidth="48rem"
              />
            </Desktop>
            <Mobile>
              <PageHeader />
            </Mobile>
          </UploadWrapper>
          <Mobile>
            <Guide />
          </Mobile>
          <ArtworkList upload={true} hideGroupHeader={true} />
        </>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />
      {dragDropOpen && !blockDragDrop && <DragDropFullSize uploadImgLimit={1} />}

      {/* </Wrapper> */}
    </>
  )
}

function PageHeader() {
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()

  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const [user, setUser] = useRecoilState(userAtom)
  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)
  const { t } = useTranslation()

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)

  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetPortfolioConfig = useResetRecoilState(portfolioConfigAtom)

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [uploading, setUploading] = useState(false)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [openEndpointDialog, setOpenEndpointDialog] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')
  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  // 번들 시에만 필요한 설정 state 입니다.
  const [bundleCount, setBundleCount] = useState(MIN_BUNDLE)
  const countUp = () => {
    setBundleCount(prev => prev + 1)
  }
  const countDown = () => {
    if (bundleCount <= MIN_BUNDLE) return
    setBundleCount(prev => prev - 1)
  }

  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)

  const isMobile = useMobileMediaQuery()

  const queryParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (uploadOpen) {
      // ----- GA4 event -----
      window.gtag('event', 'multipack_photo_begin', {})
      // ---------------------
    }
  }, [uploadOpen])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter])

  const handleUpload = async (files, eventName = 'multipack_photo_upload') => {
    const uploadFiles = await prepareUpload(files, config.PORTFOLIO_TYPE_BUNDLE)
    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)
    setUploadOpen(false)

    // ----- GA4 event -----
    triggerGA4UploadEvent({ count: bundleCount }, user, uploadFiles.length, eventName)
    // ---------------------

    const formData = await makeUploadFormData(uploadFiles)

    const genOptions = JSON.parse(formData.get('gen_options'))

    formData.delete('gen_options')

    genOptions.simple_bg_color_list = [[255, 255, 255]]
    genOptions.theme_template = 'custom'
    genOptions.theme_custom = `multiobject_${bundleCount}`

    formData.append('gen_options', JSON.stringify(genOptions))

    console.log('--------- start uploading')
    setUploading(true)
    apis.appfront
      .upload(formData)
      .then(() => {
        checkUserCredit()
        setUploading(false)
        setUploadButtonDiasbled(false)
        refreshArtworks()

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  return (
    <>
      <PageHeaderLayout
        headerProps={{
          sx: {
            opacity: isFolded.value ? 0 : 1,
            transition: 'all 0.2s ease',
          },
          zIndex: isFolded.value
            ? -1
            : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
            ? 'auto'
            : null,
        }}
        uploadButtonProps={{
          disabled: uploading || !isOwner || uploadButtonDisabled,
          onClick: () => {
            tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_1' }))
            setUploadOpen(true)
          },
        }}
        uploading={uploading}
        uploadDialog={
          uploadOpen && (
            <UploadImageDialog
              uploadImage={handleUpload}
              swipeableDrawerSx={{
                '& .MuiDrawer-paper': {
                  top: 'auto',
                },
              }}
              title={
                <Typography fontWeight={700} sx={{ fontSize: { lg: '2.2rem', xs: '1.6rem' } }}>
                  {t('bundle.upload_image')}
                </Typography>
              }
              notice={
                <CenterAlignStack
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: { lg: '1.4rem', xs: '1.2rem' },
                      fontWeight: 400,
                      color: '#595959',
                      lineHeight: 'normal',
                    },
                  }}
                >
                  <Desktop>
                    <Typography>{t('bundle.upload_notice_1')}</Typography>
                    <Typography>{t('bundle.upload_notice_2')}</Typography>
                  </Desktop>
                  <Mobile>
                    <Typography>{t('bundle.upload_notice_mobile_1')}</Typography>
                    <Typography>{t('bundle.upload_notice_mobile_2')}</Typography>
                  </Mobile>
                </CenterAlignStack>
              }
              multiple={false}
              uploadImglimit={1}
              example={
                <UploadExample
                  bundleCount={bundleCount}
                  setBundleCount={setBundleCount}
                  countDown={countDown}
                  countUp={countUp}
                />
              }
              actionDisabledCondition={bundleCount < MIN_BUNDLE}
              uploadButtonTitle={t('button.bundle_upload')}
            />
          )
        }
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
          />
        }
      ></PageHeaderLayout>
    </>
  )
}

function Guide() {
  const isMobile = useMobileMediaQuery()
  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        mb: isMobile ? '1.8rem' : '3rem',
        gap: isMobile ? '1rem' : '2rem',
      }}
    >
      <ExampleImage />
      <ExampleText />
    </Stack>
  )
}

const ExampleImage = () => {
  const theme = useTheme()
  const isMobile = useMobileMediaQuery()

  return (
    <Stack direction="row" sx={{ alignItems: 'center', gap: isMobile ? '0.8rem' : '1.5rem' }}>
      <CenterAlignStack sx={{ gap: isMobile ? '1rem' : '1.2rem' }}>
        <img
          src="/static/images/bundle_before.jpeg"
          style={{
            width: isMobile ? '6rem' : '10rem',
            height: isMobile ? '6rem' : '10rem',
            boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.20)',
            borderRadius: isMobile ? '1.1rem' : '2rem',
          }}
        />

        <Box display="flex">
          <Box
            sx={{
              borderRadius: '0.6rem 0rem 0rem 0.6rem',
              background: theme.palette.common.black,
              color: theme.palette.common.white,
              width: isMobile ? '1.8rem' : '2.4rem',
              height: isMobile ? '1.8rem' : '2.4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.8rem',
              pl: '0.15rem',
            }}
          >
            <MinusIcon
              color={theme.palette.common.white}
              style={{ width: '0.75rem', height: '0.75rem' }}
            />
          </Box>
          <Box
            sx={{
              borderRadius: '0',
              background: theme.palette.common.white,
              color: theme.palette.common.black,
              width: isMobile ? '2.4rem' : '3.2rem',
              height: isMobile ? '1.8rem' : '2.4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: isMobile ? '1.2rem' : '1.4rem',
              border: '0.2px solid #BBB;',
            }}
          >
            10
          </Box>
          <Box
            sx={{
              borderRadius: '0rem 0.6rem 0.6rem 0rem',
              background: theme.palette.common.black,
              width: isMobile ? '1.8rem' : '2.4rem',
              height: isMobile ? '1.8rem' : '2.4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PlusIcon
              color={theme.palette.common.white}
              style={{ width: '0.75rem', height: '0.75rem' }}
            />
          </Box>
        </Box>
      </CenterAlignStack>

      <BeforeAfterArrow />
      <img
        src="/static/images/bundle_after_1.jpeg"
        style={{
          width: isMobile ? '8.8rem' : '13.6rem',
          height: isMobile ? '8.8rem' : '13.6rem',
          boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.20)',
          borderRadius: isMobile ? '1rem' : '2rem',
        }}
      />
      <img
        src="/static/images/bundle_after_2.jpeg"
        style={{
          width: isMobile ? '8.8rem' : '13.6rem',
          height: isMobile ? '8.8rem' : '13.6rem',
          boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.20)',
          borderRadius: isMobile ? '1rem' : '2rem',
        }}
      />
    </Stack>
  )
}

const ExampleText = ({ sx }) => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  return (
    <Typography sx={{ fontSize: isMobile ? '1.4rem' : '2rem', fontWeight: 500 }}>
      {t('bundle.example_text_1')}
    </Typography>
  )
}

const UploadExample = ({ bundleCount, setBundleCount, countUp, countDown }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      setBundleCount(MIN_BUNDLE)
    }
  }, [])

  return (
    <CenterAlignStack sx={{ width: '100%', gap: '2.5rem', mt: '2.5rem' }}>
      <CenterAlignBox sx={{ gap: '1.6rem' }}>
        <Typography sx={{ fontSize: '1.8rem', fontWeight: 600 }}>
          {t('bundle.upload_bundle_count')}
        </Typography>
        <Box display="flex" sx={{ borderRadius: '0.6rem 0.6rem 0.6rem 0.6rem' }}>
          <IconButton
            sx={{
              borderRadius: '0.6rem 0rem 0rem 0.6rem',
              background: theme.palette.common.black,
              color: theme.palette.common.white,
              width: '2.4rem',
              height: '2.4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.8rem',
              // pl: '0.15rem',
              '&.Mui-disabled': {
                background: '#BBB',
              },
              '&: hover': {
                background: theme.palette.draph.blue,
              },
            }}
            onClick={countDown}
            disabled={bundleCount <= MIN_BUNDLE}
          >
            <MinusIcon
              color={theme.palette.common.white}
              style={{ width: '0.75rem', height: '0.75rem' }}
            />
          </IconButton>
          <Box
            sx={{
              borderRadius: '0',
              background: theme.palette.common.white,
              color: theme.palette.common.black,
              width: '3.2rem',
              height: '2.4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.4rem',
              borderTop: '0.2px solid #BBB;',
              borderBottom: '0.2px solid #BBB;',
            }}
          >
            <TextField
              variant="standard"
              type="text"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*', // 숫자만 허용
                style: {
                  WebkitAppearance: 'none', // Remove default arrow in Safari
                  margin: 0, // Remove default margin in Firefox
                },
              }}
              value={bundleCount}
              onChange={e => {
                const input = e.target.value
                if (input.length < 3) setBundleCount(parseInt(input) || input)
                // if (input < MIN_BUNDLE || !e.target.value) {
                //   setBundleCount(MIN_BUNDLE)
                // } else {
                //   const newValue = Math.min(Math.max(0, parseInt(input) || 0), 80)
                //   setBundleCount(newValue)
                // }
              }}
              onBlur={e => {
                const input = e.target.value

                if (Number(input) > MAX_BUNDLE) {
                  setBundleCount(MAX_BUNDLE)
                }
                if (Number(input) < MIN_BUNDLE) {
                  setBundleCount(MIN_BUNDLE)
                }
              }}
              sx={{
                width: '3.2rem',

                '&:focus': {
                  background: theme.palette.common.white, // Prevent color change on focus
                },

                '& .MuiInputBase-root.MuiInput-root': {
                  fontSize: '1.6rem',
                  px: '0.67rem',
                  height: '2.4rem',
                },

                '& input': {
                  textAlign: 'center',
                },
              }}
            />
          </Box>
          <IconButton
            sx={{
              borderRadius: '0rem 0.6rem 0.6rem 0rem',
              background: theme.palette.common.black,
              width: '2.4rem',
              height: '2.4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&.Mui-disabled': {
                background: '#BBB',
              },
              '&: hover': {
                background: theme.palette.draph.blue,
              },
            }}
            onClick={countUp}
            disabled={bundleCount >= 80}
          >
            <PlusIcon
              color={theme.palette.common.white}
              style={{ width: '0.75rem', height: '0.75rem' }}
            />
          </IconButton>
        </Box>
      </CenterAlignBox>

      <Box
        sx={{
          '& .MuiTypography-root': {
            fontSize: { lg: '1.4rem', xs: '1.2rem' },
            '& span': {
              fontWeight: 700,
              color: theme => theme.palette.draph.blue,
            },
          },
        }}
      >
        <ul>
          <li>
            <Typography>
              {t('bundle.upload_example_comment_1_a')}
              <span>{t('bundle.upload_example_comment_1_b')}</span>
              {t('bundle.upload_example_comment_1_c')}
            </Typography>
          </li>
          <li>
            <Typography>
              {t('bundle.upload_example_comment_2_a')}
              <span>{t('bundle.upload_example_comment_2_b')}</span>
              {t('bundle.upload_example_comment_2_c')}
            </Typography>
          </li>
          <li>
            <Typography>
              {t('bundle.upload_example_comment_3_a')}
              <span>{t('bundle.upload_example_comment_3_b')}</span>
              {t('bundle.upload_example_comment_3_c')}
            </Typography>
          </li>
        </ul>
      </Box>
    </CenterAlignStack>
  )
}
