import { useState, useCallback, useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { apiRequestsState } from 'atoms'

export const useApiHandler = ({ apiKey, apis }) => {
  const setApiRequests = useSetRecoilState(apiRequestsState)
  const apiRequests = useRecoilValue(apiRequestsState)
  const [localController, setLocalController] = useState(null)

  const callApi = useCallback(
    async (...args) => {
      const controller = new AbortController()
      const signal = controller.signal
      // console.log('API 호출 시작:', apiKey)

      setLocalController(controller)
      setApiRequests(prev => ({
        ...prev,
        [apiKey]: controller,
      }))

      try {
        const response = await apis[apiKey](...args, { signal })
        return response
      } catch (err) {
        if (err.name === 'AbortError') {
          // console.log('API 취소 완료:', apiKey)
        } else {
          // console.error('API 에러:', err)
        }
        throw err
      } finally {
        setApiRequests(prev => {
          const { [apiKey]: _, ...rest } = prev
          return rest
        })
        setLocalController(null)
      }
    },
    [apiKey, apis, setApiRequests]
  )

  const abortApi = useCallback(() => {
    // console.log('API 취소 시도:', apiKey)
    const controller = apiRequests[apiKey] || localController
    if (controller) {
      controller.abort()
      // console.log('취소 요청 신호 전송')
    } else {
      // console.log('취소할 API 요청 없음:', apiKey)
    }
  }, [apiKey, apiRequests, localController])

  useEffect(() => {
    return () => {
      if (localController) {
        // console.log('컴포넌트 언마운트 시 API 취소:', apiKey)
        localController.abort()
      }
    }
  }, [apiKey, localController])

  return { callApi, abortApi }
}
