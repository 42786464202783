import { Button, Box, Typography, useTheme, FormControl, Select, MenuItem } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { portfolioTypeAtom, userAtom } from 'atoms'
import { useState } from 'react'

import { CustomTooltip, CenterAlignBox, CenterAlignStack } from 'components'
import { NukkiIcon, RefreshIcon, TrashCanIcon, SelectSideIconThin } from 'theme/icon'
import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'
import * as config from 'config'

export function ArtowrkDownloadButton({ downloadCount, handleClick, slim = false, sx, ...props }) {
  const { t } = useTranslation()
  const user = useRecoilValue(userAtom)

  return (
    <>
      <Button
        variant="contained"
        sx={{
          fontSize: { lg: '1.8rem', xs: '1.3rem' },
          fontWeight: { lg: 800, xs: 700 },
          width: { lg: '23.2rem', xs: '13.2rem' },
          height: { lg: slim ? '4rem' : '5.2rem', xs: '2.8rem' },
          //   zIndex: tutorial.step === 15 ? 4000 : null,
          lineHeight: 'normal',
          boxShadow: {
            lg: '2px 2px 10px 0px rgba(77, 128, 255, 0.20)',
            xs: ' 1px 1px 4px 0px rgba(77, 128, 255, 0.20)',
          },
          ...(downloadCount > 0
            ? {
                backgroundColor: theme => theme.palette.common.black,
                boxShadow: {
                  lg: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
                  xs: ' 1px 1px 4px 0px rgba(0, 0, 0, 0.20)',
                },
              }
            : {
                backgroundColor: theme => theme.palette.common.white,
                border: 1,
                borderWidth: { lg: '0.2rem', xs: '0.1rem' },
                borderColor: theme => theme.palette.draph.blue,
                color: theme => theme.palette.draph.blue,

                '&:hover': {
                  backgroundColor: theme => theme.palette.draph.blue,

                  color: theme => theme.palette.common.white,
                },
              }),
          //   ...(tutorial.step === 16
          //     ? {
          //         borderColor: theme => theme.palette.common.black,
          //         color: theme => theme.palette.common.black,
          //         borderWidth: '0.1rem',
          //       }
          //     : {}),
          ...sx,
        }}
        onClick={handleClick}
        {...props}
      >
        {t('button.download')}
      </Button>
    </>
  )
}

const freeBadgeStyle = {
  '& #free-badge-text': { color: '#FFFFFF' },
  background: theme => theme.palette.draph.blue,
}

export function RegenerateButton({
  handleClick,
  isOwner,
  buttonClass = '',
  type = 'regenerate',
  redoAnimationStart,
  ...props
}) {
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const { t } = useTranslation()
  const [regenButtonHover, setRegenButtonHover] = useState(false)
  const isMobile = useMobileMediaQuery()

  return (
    <>
      <Box
        sx={{
          animation: redoAnimationStart ? 'tilt-shaking 0.2s linear 25' : '',
          borderRadius: '1rem',
          '@keyframes tilt-shaking': {
            '0%': { transform: 'rotate(0deg)', boxShadow: '0 0 2px rgb(77, 128, 255)' },
            '25%': { transform: 'rotate(3deg)' },
            '50%': { transform: 'rotate(0eg)', boxShadow: '0 0 5px rgb(77, 128, 255, 0.8)' },
            '75%': { transform: 'rotate(-3deg)' },
            '100%': { transform: 'rotate(0deg)', boxShadow: '0 0 2px rgb(77, 128, 255)' },
          },
          width: { lg: 'auto', xs: 'auto' },
          position: 'relative',
          '&:hover': {
            '& #regenerate-button-free-badge': regenButtonHover ? { '& ': freeBadgeStyle } : {},
          },
        }}
      >
        <CustomTooltip
          enterDelay={0}
          leaveDelay={100}
          title={
            // <div style={{ whiteSpace: 'pre-line', padding: '6px 10px', }}>
            //   {type === 'removebg'
            //     ? t('artwork_control.tooltip_comment_1')
            //     : t('artwork_control.tooltip_comment_2')}

            // </div>
            <div
              onClick={() => {
                if (
                  portfolioType === config.PORTFOLIO_TYPE_UPLOAD ||
                  portfolioType === config.PORTFOLIO_TYPE_NORMAL
                ) {
                  // ----- GA4 event -----
                  const eventName = portfolioType
                    ? 'regenerate_begin'
                    : 'portfolio_regenerate_begin'
                  window.gtag('event', eventName, { method: 'tooltip' })
                  // ---------------------
                }
                handleClick()
              }}
              style={{ cursor: 'pointer' }}
            >
              {t('artwork_control.tooltip_comment')}
            </div>
          }
        >
          <Button
            variant="text"
            startIcon={
              type === 'removebg' ? (
                <NukkiIcon />
              ) : (
                <RefreshIcon size={isMobile ? 'small' : 'medium'} />
              )
            }
            onClick={() => {
              if (
                portfolioType === config.PORTFOLIO_TYPE_UPLOAD ||
                portfolioType === config.PORTFOLIO_TYPE_NORMAL
              ) {
                // ----- GA4 event -----
                const eventName = portfolioType ? 'regenerate_begin' : 'portfolio_regenerate_begin'
                window.gtag('event', eventName, { method: 'button' })
                // ---------------------
              }
              handleClick()
            }}
            className={buttonClass}
            onMouseEnter={() => setRegenButtonHover(true)}
            onMouseLeave={() => setRegenButtonHover(false)}
            sx={{
              backgroundColor: theme => theme.palette.common.white,
              width: { lg: '10.8rem', xs: '6rem' },
              height: { lg: '3.8rem', xs: '2.4rem' },
              px: { lg: null, xs: 0 },
              py: { lg: null, xs: '0.5rem' },
              borderWidth: '0.1rem',
              borderRadius: { lg: '1rem', xs: '0.6rem' },
              boxShadow: {
                lg: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
                xs: '1px 1px 4px 0px rgba(0, 0, 0, 0.20)',
              },
              fontSize: { lg: '1.4rem', xs: '1rem' },
              fontWeight: 600,
              lineHeight: 'normal',
              transition: 'all 0.3s',
              color: theme => theme.palette.common.black,
              // animation: redoAnimationStart ? 'tilt-shaking 0.3s linear 15' : '',

              '&:hover': {
                boxShadow: {
                  lg: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
                  xs: '1px 1px 4px 0px rgba(0, 0, 0, 0.20)',
                },
                backgroundColor: theme => theme.palette.common.white,
                borderWidth: '0.1rem',
                color: theme => theme.palette.draph.blue,
                '& .MuiButton-startIcon path': {
                  fill: theme => theme.palette.draph.blue,
                  // stroke: theme => theme.palette.draph.blue,
                },
              },
              // '& .MuiButton-startIcon path': {
              //   transition: 'fill 0.3s',
              // },

              '& .MuiButton-startIcon': {
                marginLeft: { lg: '0', xs: '0' },
                marginRight: { lg: '0.8rem', xs: '0.3rem' },
              },
              '@keyframes tilt-shaking': {
                '0%': { transform: 'rotate(0deg)' },
                '25%': { transform: 'rotate(3deg)' },
                '50%': { transform: 'rotate(0eg)' },
                '75%': { transform: 'rotate(-3deg)' },
                '100%': { transform: 'rotate(0deg)' },
              },
              '&.shake': { animation: 'tilt-shaking 0.2s linear 3' },

              //   ...(tutorial.step === 16
              //     ? {
              //         borderColor: theme => theme.palette.common.black,
              //         color: theme => theme.palette.common.black,
              //         animation: 'tiltTutorial 1s infinite',
              //       }
              //     : {}),
            }}
            disabled={!isOwner}
          >
            <span className="text">
              {type === 'removebg' ? t('button.removebg_edit') : t('button.regenerate')}
            </span>
          </Button>
        </CustomTooltip>

        <Box
          id="regenerate-button-free-badge"
          sx={{
            transition: 'all 0.3s',
            position: 'absolute',
            top: { lg: '-1.2rem', xs: '-0.7rem' },
            right: 0,
            width: { lg: '4.4rem', xs: '3.2rem' },
            height: { lg: '2rem', xs: '1.2rem' },
            background: '#EAF0FF',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            id="free-badge-text"
            sx={{
              fontSize: { lg: '1.1rem', xs: '1rem' },
              transform: { lg: 'scale(1)', xs: 'scale(0.8)' },
              fontWeight: 600,
              color: theme => theme.palette.draph.blue,
            }}
          >
            FREE
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export function DeleteSelectedButton({ handleDelete, sx, fullSize = false, ...props }) {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()
  return (
    <>
      <Button
        variant="text"
        startIcon={
          <TrashCanIcon
            color={theme.palette.common.black}
            strokeWidth={1.4}
            style={{
              ...(isMobile
                ? {
                    width: '1rem',
                    ...(fullSize && { width: '1.2rem' }),
                  }
                : { width: '1.4rem', ...(fullSize && { width: '1.8rem' }) }),
            }}
          />
        }
        onClick={handleDelete}
        sx={{
          backgroundColor: theme => theme.palette.common.white,
          width: { lg: '10.8rem', xs: '6.4rem' },
          height: { lg: '3.8rem', xs: '2.4rem' },
          px: { lg: null, xs: 0.3 },
          borderWidth: '0.1rem',
          borderRadius: { lg: '1rem', xs: '0.6rem' },
          fontSize: { lg: '1.4rem', xs: '1rem' },
          fontWeight: 600,
          lineHeight: 'normal',
          color: theme => theme.palette.common.black,
          boxShadow: {
            lg: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
            xs: '1px 1px 4px 0px rgba(0, 0, 0, 0.20)',
          },

          '&:hover': {
            boxShadow: {
              lg: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
              xs: '1px 1px 4px 0px rgba(0, 0, 0, 0.20)',
            },
            backgroundColor: theme => theme.palette.common.white,

            color: theme => theme.palette.error.main,
            '& .MuiButton-startIcon path': {
              transition: 'stroke 0.3s',
              stroke: theme => theme.palette.error.main,
            },
          },
          '& .MuiButton-startIcon path': {
            transition: 'stroke 0.3s',
          },
          '& .MuiButton-startIcon': {
            marginLeft: { lg: '0', xs: '0' },
            marginRight: { lg: '0.8rem', xs: '0.3rem' },
          },

          ...(fullSize && {
            width: { lg: '23.2rem', xs: '13.2rem' },
            height: { lg: '5.2rem', xs: '2.8rem' },
            fontSize: { lg: '1.7rem', xs: '1.3rem' },
          }),
          ...sx,
        }}
        {...props}
      >
        {t('button.delete_selected')}
      </Button>
    </>
  )
}

export function IntroduceGIF({ handleButtonClick = () => {}, ...props }) {
  const isMobile = useMobileMediaQuery()
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  return (
    <CenterAlignBox gap="1.9rem" sx={{ alignItems: 'center', gap: { lg: '1.9rem', xs: '0.8rem' } }}>
      <img
        src="/static/images/gifImage.gif"
        style={{
          width: isMobile ? '4.8rem ' : '8rem',
          height: isMobile ? '4.8rem ' : '8rem',
        }}
      />
      <CenterAlignStack sx={{ gap: { lg: '0.6rem', xs: '0.4rem' } }}>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: { lg: '1.2rem', xs: '1rem' },
            fontWeight: 500,
            lineHeight: 'normal',
          }}
        >
          {t('artwork_list.control.GIF_need_1')} <br /> {t('artwork_list.control.GIF_need_2')}
        </Typography>
        <Button
          variant="contained"
          sx={{
            fontSize: { lg: '1.3rem', xs: '1rem' },
            height: { lg: '3rem', xs: '2rem' },
            borderRadius: { lg: '1rem', xs: '0.6rem' },
            boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
            '&:hover span': {
              color: `${theme.palette.common.white} !important`,
              transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
            padding: { lg: '0.7rem 1.2rem 0.7rem 1.4rem', xs: '0.4rem 0.6rem 0.4rem 0.6rem' },
          }}
          onClick={handleButtonClick}
        >
          {isMobile ? t('artwork_list.control.create_gif_m') : t('artwork_list.control.create_gif')}
          <span
            style={{
              fontSize: '0.8rem',
              fontWeight: 600,
              color: theme.palette.draph.green,
              marginLeft: '0.4rem',
              transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}
          >
            FREE
          </span>
        </Button>
      </CenterAlignStack>
    </CenterAlignBox>
  )
}

export function MoveArtworkToPortfolio({
  moveToPortfolio,
  placeholder,
  setPlaceholder,
  selectedPortfolio,
  portfolio,
  portfolioId,
  ...props
}) {
  const isMobile = useMobileMediaQuery()
  return (
    <FormControl>
      <Select
        displayEmpty
        renderValue={v => (v?.length ? v : placeholder)}
        onOpen={() => {
          setPlaceholder('이동할 포트폴리오 선택')
        }}
        onClose={() => {
          setPlaceholder(' 다른 포트폴리오로 이동')
        }}
        IconComponent={isMobile ? () => <SelectSideIconThin size="small" /> : SelectSideIconThin}
        // label="다른 포트폴리오로 이동"
        size="small"
        value={selectedPortfolio}
        onChange={moveToPortfolio}
        sx={{
          width: { lg: '23.2rem', xs: '13.2rem' },
          height: { lg: '2.8rem', xs: '2rem' },

          '& .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
            color: theme => theme.palette.common.gray,
            display: { lg: 'flex' },
            justifyContent: { lg: 'center' },
            width: { lg: 'inherit', xs: '9.3rem' },
            pl: { lg: '0', xs: '1rem' },
            pr: { lg: '0', xs: '1rem' },
            pt: { lg: '1.6rem', xs: '0.7rem' },
            pb: { lg: '1.6rem', xs: '0.7rem' },
          },
          '&:not(.Mui-disabled).Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0.1rem',
          },
        }}
        MenuProps={{
          sx: {
            maxHeight: { lg: '30rem', xs: '25rem' },
            // width: '25.4rem',
          },
        }}
        {...props}
      >
        {portfolio?.filter(p => `${p.id}` !== portfolioId).length > 0 ? (
          portfolio
            .filter(p => `${p.id}` !== portfolioId)
            .map(p => {
              return (
                <MenuItem
                  key={p.id}
                  value={p}
                  sx={{
                    fontSize: { lg: '1.6rem', xs: '1.2rem' },
                    width: { lg: '25.4rem', xs: '12rem' },
                    maxHeight: { lg: '30rem', xs: '20rem' },
                  }}
                >
                  {p.name}
                  {/* TODO: 포트폴리오 이름이 길어서 넘치는 경우 ... 표시 (지금은 그냥 잘림) */}
                </MenuItem>
              )
            })
        ) : (
          <MenuItem disabled>이동할 포트폴리오가 없습니다.</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}
