import { Tooltip } from '@mui/material'

const arrowTooltipStyle = {
  '& .MuiTooltip-arrow': {
    color: 'rgba(0,0,0, 0.6)',
  },
  fontSize: '1.4rem',
  fontWeight: 400,
  p: '1rem',
  background: 'rgba(0,0,0, 0.6)',
  maxWidth: 'none',
}

export default function CustomArrowTooltip({ children, componentsSx = {}, ...props }) {
  if (children) {
    return (
      <Tooltip
        arrow
        PopperProps={{ sx: { zIndex: 1300 } }}
        componentsProps={{
          tooltip: {
            sx: { ...arrowTooltipStyle, ...componentsSx },
          },
        }}
        // sx={{ background: 'yellow' }}
        {...props}
      >
        {children}
      </Tooltip>
    )
  } else {
    return children
  }
}
