import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import { CenterAlignStack } from 'components'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import moment from 'moment'
import { getNextPaidDay } from 'pricing'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BlueCircleCheckIcon, CheckedCircleIconNotistack } from 'theme/icon'
import { motion } from 'framer-motion'
import { useRecoilValue } from 'recoil'
import { creditPlansAtom } from '../atoms'
import { isKo, numberCommaFormatter, trasnferDate } from 'utils/common'
import { useTranslation } from 'react-i18next'
import { usePricing } from 'hooks/usePricing'
import { SUB_EVENT_ID } from './Pricing'

const testState = {
  test: 123,
  paymentResponse: {
    PCD_PAY_RST: 'success',
    PCD_PAY_CODE: '0000',
    PCD_PAY_MSG: '카드승인완료',
    PCD_PAY_TYPE: 'card',
    PCD_CARD_VER: '02',
    PCD_PAY_WORK: 'PAY',
    PCD_AUTH_KEY:
      'K0VnWlZ5TWZSaGNla1Vpay96YnNQRmJ5eHRmeTNYTzlwdkpxeUdUUXYybXdMbDRZSjJSOEdlWTdFd3VYZE1zMXhvR3l6WU1VUDU3SkFhTlZRZDZHVWZGcU9wdkkzTy9pb2hacndYd1pvSTV1RVJ6SnkxV3diYXd3WXJJbTUxbzFZODFjY2J4bjBaWXZJUTlsYllMd295TWFwS1pJMjQwY2VKTUIvRTFWd0xTS3N5cHR1aGRYWC9vM3FqVUx2amRWUklLb0lvY3J3QnhhUURMMHByZTluMlRialEvejVqNVNwTi9WZUdlNFhXczkycFpyZ0hNY1l0ZHVZT1lTMXBndGZuUGwvV0NUK1cxN0tVelliTDh1dE9LSEMwUGhKT2dUVHJPZ1p2SkZEUlVNUjQvZHZmYWNrQ2w0Qy8yU0JudUxiSFBBUTYvcjBhSExDdFdibE1zb0tkcUViRnJUckVYVDgxV1RhMDdydUs4PQ==',
    PCD_PAY_REQKEY: '',
    PCD_PAY_HOST: 'https://democpay.payple.kr',
    PCD_PAY_URL: '',
    PCD_PAY_COFURL: '',
    PCD_PAYER_ID: '',
    PCD_PAYER_NO: '80',
    PCD_PAYER_NAME: 'ga00e',
    PCD_PAYER_HP: '01038041492',
    PCD_PAYER_EMAIL: '1231412af@naver.com',
    PCD_PAY_YEAR: '',
    PCD_PAY_MONTH: '',
    PCD_PAY_OID: '20230616185743-23749061',
    PCD_PAY_GOODS: '드랩 아트 - 건별 충전 200',
    PCD_PAY_AMOUNT: '100',
    PCD_PAY_DISCOUNT: '',
    PCD_PAY_AMOUNT_REAL: '100',
    PCD_PAY_TOTAL: 100,
    PCD_PAY_TAXTOTAL: 0,
    PCD_PAY_ISTAX: 'Y',
    PCD_PAY_CARDNAME: '삼성카드',
    PCD_PAY_CARDNUM: '518831******8694',
    PCD_PAY_CARDQUOTA: '00',
    PCD_PAY_CARDTRADENUM: '202306161857447505643400',
    PCD_PAY_CARDAUTHNO: '07791983',
    PCD_PAY_CARDRECEIPT:
      'https://www.danalpay.com/receipt/creditcard/view.aspx?dataType=receipt&cpid=9810000530&data=DUw4hdDWkkX9Nl3m2jY0eal1%2BiyKOgwEYgLYMM%2FpI1KW0lgkAem5R1%2B8v0AvIA7E',
    PCD_PAY_TIME: '20230616185802',
    PCD_REGULER_FLAG: 'N',
    PCD_SIMPLE_FLAG: 'N',
    PCD_RST_URL: '/home',
    PCD_USER_DEFINE1: '',
    PCD_USER_DEFINE2: '',
  },
  paymentInfo: {
    payment_type: 'paygo',
    payment_cycle: '',
    order_id: '20230616185743-23749061',
    credit_amount: 200,
    plan_id: 'paygo200',
    plan_name: '건별 충전 200',
  },
}

const testAuthState = {
  PCD_PAY_RST: 'success',
  PCD_PAY_CODE: '0000',
  PCD_PAY_MSG: '카드등록이 완료되었습니다.',
  PCD_PAY_WORK: 'AUTH',
  PCD_AUTH_KEY:
    'K0VnWlZ5TWZSaGNla1Vpay96YnNQRmJ5eHRmeTNYTzlwdkpxeUdUUXYybXdMbDRZSjJSOEdlWTdFd3VYZE1zMXhvR3l6WU1VUDU3SkFhTlZRZDZHVWZGcU9wdkkzTy9pb2hacndYd1pvSTV1RVJ6SnkxV3diYXd3WXJJbTUxbzFZODFjY2J4bjBaWXZJUTlsYllMd295TWFwS1pJMjQwY2VKTUIvRTFWd0xTS3N5cHR1aGRYWC9vM3FqVUx2amRWUklLb0lvY3J3QnhhUURMMHByZTluMm5CNzRQejR4a2dWS1lHb1o2SGNrZWY5RENsWnJxcHBLMWVmOW5zcXpkYkpncTVDS2lWTDdUUTI1UkhOd0ZBY3d3V0NxWkt4N2xNOTdKQ3E5M1dUNXIvTkZodURPbjM1bVdtT29xaWN3SUtOOXZ4d3FRRFZrZ2JCM3gxQ2dLbmVnRXRjNnBncnVxOEJ1bzdrMEZWWXZrPQ==',
  PCD_PAY_TYPE: 'card',
  PCD_PAYER_AUTHTYPE: 'sms',
  PCD_PAYER_NO: '80',
  PCD_PAYER_ID: 'cDhjdVpYRk0zYmVlNHlIcDZ4a1V5WUEwZjFIdGF1NmNOMXkyakpWMzZFZz0=',
  PCD_PAYER_NAME: 'ga00e',
  PCD_PAYER_EMAIL: '',
  PCD_PAYER_HP: '01038041492',
  PCD_PAY_CARDNAME: '현대카드',
  PCD_PAY_CARDNUM: '4045-****-****-9108',
  PCD_RST_URL: '/paid',
  PCD_USER_DEFINE1: '',
  PCD_USER_DEFINE2: '',
}

const testPaymentInfo = {
  payment_type: 'subscription',
  payment_cycle: 'monthly',
  order_id: '20240228161738-89694078',
  credit_amount: 35,
  plan_id: 'monthly35',
  plan_name: '월정액 35',
  event_id: 'f2402_2d82f9e7ce76',
  plan_normal_price: 9900,
}
export default function Afterpayment() {
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMobileMediaQuery()

  const { t, i18n } = useTranslation()

  const { state } = useLocation()

  const { getPlanDisplayName, getCurrencySymbol } = usePricing()

  if (!state) {
    history.go(-1)
    return
  }

  const { paymentResponse, paymentInfo, changeSubscription, newSubscriptionInfo } = state

  if (!paymentInfo) {
    history.go(-1)
    return
  }

  const [recentCreditInfo, setRecentCreditInfo] = useState({})
  const [userPlantype, setUserPlantype] = useState('')

  const creditPlans = useRecoilValue(creditPlansAtom)

  const isSubscrition = paymentInfo?.payment_type === 'subscription'

  useEffect(() => {
    // apis.user.getCreditHistory('charge').then(response => {
    //   setRecentCreditInfo(response.data[0])
    //   setUserPlantype(response.data[0].type.split('_'))
    //   if (response.data[0].type.split('_')[0] === 'subscription') {
    //     apis.user.getUserSubscription().then(response => {
    //       setCurrentSubscription(response.data)
    //     })
    //   }
    // })
  }, [])

  const displayPlantype = plantype => {
    const dict = {
      subscription: t('after_payment.subscription'),
      paygo: t('after_payment.credit'),
    }

    return dict[plantype]
  }

  useEffect(() => {
    if (!state) {
      navigate('/home')
    }
  })
  return (
    <>
      <CenterAlignStack sx={{ pt: { lg: '6.7rem', xs: '7.1rem' } }}>
        <BlueCircleCheckIcon style={{ width: '6rem', height: '6rem' }} />
        <Stack sx={{ m: { lg: '2.6rem 0 3.6rem 0', xs: '2.4rem 0 3.6rem 0' } }}>
          {changeSubscription ? (
            <Typography
              sx={{
                fontSize: { lg: '3rem', xs: '2.4rem' },
                fontWeight: 800,
                textAlign: 'center',
                '& span': { color: theme.palette.draph.blue },
              }}
            >
              {t('after_payment.change_subscription')}
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: { lg: '3rem', xs: '2.4rem' },
                fontWeight: 800,
                textAlign: 'center',
                '& span': { color: theme.palette.draph.blue },
              }}
            >
              <span>{displayPlantype(paymentInfo?.payment_type)}</span>
              {isSubscrition ? t('after_payment.start') : t('after_payment.charge')}
              <br /> {t('after_payment.start_now')}
            </Typography>
          )}
        </Stack>
        <Stack
          sx={{
            width: {
              lg: 'auto',
              xs: changeSubscription ? '26rem' : isSubscrition ? '32rem' : '23.5rem',
            },
            border: `2px solid  ${theme.palette.draph.blue}`,
            boxShadow: '4px 4px 10px rgba(77, 128, 255, 0.2)',
            borderRadius: '20px',
            p: { lg: isSubscrition ? '3.1rem 2.7rem' : '2.1rem 4rem', xs: ' 1rem 1.5rem' },
          }}
        >
          {changeSubscription ? (
            <Table>
              <TableBody
                sx={{
                  '& .MuiTableCell-root': {
                    padding: { lg: '0.5rem 0.7rem', xs: '0.2rem 0.4rem' },
                    textAlign: 'left',
                    width: 'auto',
                    fontSize: {
                      lg: isKo(i18n) ? '2rem' : '1.7rem',
                      xs: isKo(i18n) ? '1.4rem' : '1.2rem',
                    },
                    border: 0,
                  },
                  '& .MuiTableCell-head': {
                    color: theme.palette.draph.blue,
                    textAlign: 'left',
                    fontWeight: 700,
                    width: { lg: isKo(i18n) ? '17rem' : '24rem', xs: '10rem' },
                  },
                }}
              >
                <TableRow>
                  <TableCell variant="head">{t('after_payment.plan')}</TableCell>
                  <TableCell>{getPlanDisplayName(paymentInfo.plan_id) || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t('after_payment.next_price')}</TableCell>
                  <TableCell>
                    {paymentResponse?.PCD_PAY_TOTAL
                      ? `${getCurrencySymbol(paymentInfo.currency)} ${numberCommaFormatter(
                          paymentResponse.PCD_PAY_TOTAL
                        )}`
                      : paymentResponse?.amount
                      ? `${getCurrencySymbol(paymentInfo.currency)} ${numberCommaFormatter(
                          paymentResponse.amount
                        )}`
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t('after_payment.next_charge_credit')}</TableCell>
                  <TableCell>
                    {paymentInfo?.charge_credit_amount ?? '-'} {t('after_payment.credit')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t('after_payment.next_paid_day')}</TableCell>
                  <TableCell>
                    {trasnferDate(moment(new Date(paymentInfo.start_date)), 'detail', i18n)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Table>
              <TableBody
                sx={{
                  '& .MuiTableCell-root': {
                    padding: { lg: '0.5rem 0.7rem', xs: '0.2rem 0rem' },
                    textAlign: 'left',
                    width: 'auto',
                    fontSize: {
                      lg: isKo(i18n) ? '2rem' : '1.7rem',
                      xs: isKo(i18n) ? '1.4rem' : '1.2rem',
                    },
                    border: 0,
                  },
                  '& .MuiTableCell-head': {
                    color: theme.palette.draph.blue,
                    textAlign: 'left',
                    fontWeight: 700,
                    width: { lg: isKo(i18n) ? '14rem' : '15rem', xs: '8rem' },
                  },
                }}
              >
                <TableRow>
                  <TableCell variant="head">{t('after_payment.plan')}</TableCell>
                  <TableCell>{getPlanDisplayName(paymentInfo.plan_id) || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t('after_payment.price')}</TableCell>
                  <TableCell>
                    {paymentInfo.event_id === SUB_EVENT_ID ? (
                      <>
                        {t('after_payment.free_for_first_month')}
                        {paymentInfo.plan_normal_price &&
                          ` (${t('after_payment.from_next_month')} ${
                            !isKo(i18n) ? getCurrencySymbol(paymentInfo.currency) : ''
                          } ${numberCommaFormatter(paymentInfo.plan_normal_price)}
                          ${isKo(i18n) ? t('after_payment.currency_txt') : ''})`}
                      </>
                    ) : paymentResponse?.PCD_PAY_TOTAL ? (
                      // 확인 부탁드립니다 !
                      `${getCurrencySymbol(paymentInfo.currency)} ${numberCommaFormatter(
                        paymentResponse.PCD_PAY_TOTAL
                      )}`
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t('after_payment.charge_credit')}</TableCell>
                  <TableCell>
                    {paymentInfo?.credit_amount ?? '-'} {t('after_payment.credit')}
                    {isSubscrition && t('after_payment.charge_comment')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    {isSubscrition
                      ? t('after_payment.next_paid_day_1')
                      : t('after_payment.paid_day')}
                  </TableCell>
                  <TableCell>
                    {isSubscrition
                      ? trasnferDate(moment(getNextPaidDay(moment().local())), 'detail', i18n)
                      : paymentResponse?.PCD_PAY_TIME
                      ? trasnferDate((moment(new Date()), 'detail', i18n))
                      : '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Stack>

        <motion.div
          key={isMobile}
          initial={isMobile && { opacity: 0, y: -20 }}
          animate={isMobile && { opacity: 1, y: 0 }}
          transition={isMobile && { duration: 0.5, delay: 0.2 }}
        >
          <Box
            sx={{
              width: { lg: '50rem', xs: '32rem' },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: { lg: '4.4rem', xs: '3.6rem' },
              flexDirection: isMobile ? 'column' : 'row',
            }}
            gap="2rem"
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate('/generate/upload')
              }}
              sx={{
                width: { lg: '24rem', xs: '32rem' },
                height: { lg: '6rem', xs: '4.8rem' },
                fontWeight: 800,
                fontSize: { lg: '2.2rem', xs: '1.8rem' },
              }}
            >
              {t('after_payment.to_upload_image')}
            </Button>
            {changeSubscription && (
              <Button
                variant="outlined"
                onClick={() => {
                  navigate('/user/mypage')
                }}
                sx={{
                  width: { lg: '24rem', xs: '32rem' },
                  height: { lg: '6rem', xs: '4.8rem' },
                  fontWeight: 800,
                  fontSize: { lg: '2.2rem', xs: '1.8rem' },
                }}
              >
                {t('after_payment.move_mypage')}
              </Button>
            )}
          </Box>
        </motion.div>
      </CenterAlignStack>
    </>
  )
}
