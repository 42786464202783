import { useLocation, useParams } from 'react-router-dom'

export function usePortfolioId() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const upload = location.pathname.includes('upload')

  const { portfolioId } = upload ? { portfolioId: queryParams.get('id') } : useParams()

  return portfolioId
}
