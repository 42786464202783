import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { useRecoilState, useResetRecoilState } from 'recoil'
import { confirmDialogAtom } from 'atoms'
import { CenterAlignStack } from 'components'
import { useState } from 'react'

export default function SingleImageDialog({ open, setOpen, image, isGuide }) {
  const bg = [1, 2, 3]
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: 0 } }}>
      <DialogContent sx={{ p: 0 }}>
        <CenterAlignStack>
          <img src={image} style={{ width: '60vh', objectFit: 'cover' }} />
        </CenterAlignStack>
      </DialogContent>
    </Dialog>
  )
}

export function GuideImageDialog({ open, setOpen, image }) {
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: 0, maxWidth: '75vw' } }}
    >
      <DialogContent sx={{ p: 0 }}>
        <CenterAlignStack>
          <img src={image} style={{ width: '100%', objectFit: 'cover' }} />
        </CenterAlignStack>
      </DialogContent>
    </Dialog>
  )
}
