import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import BasicAppBar, { APPBAR_DESKTOP, APPBAR_MOBILE } from './BasicAppBar'
import { SnackbarProvider } from 'notistack'
import { ConfirmDialog } from 'components'

import { styled } from '@mui/material/styles'
import { StyledMaterialDesignContent } from 'components/CustomStyle'
import { CheckedCircleIconNotistack, ErrorTriangleIconNotistack } from 'theme/icon'

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  overflow: 'hidden',
})

const LayoutStyle = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  paddingBottom: theme.spacing(10),
  paddingLeft: '2rem',
  paddingRight: '2rem',
  paddingTop: APPBAR_MOBILE,

  [theme.breakpoints.up('lg')]: {
    paddingTop: APPBAR_DESKTOP,
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
  },
}))

const snackbarProps = {
  maxSnack: 1,
  autoHideDuration: 1000,
  // style: {
  //   fontSize: '1.2rem',
  // },
}

export default function BasicLayout() {
  useEffect(() => {}, [])

  return (
    <RootStyle>
      <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <CheckedCircleIconNotistack />,
          error: <ErrorTriangleIconNotistack />,
        }}
        {...snackbarProps}
      >
        <BasicAppBar />
        <LayoutStyle>
          <Outlet />
        </LayoutStyle>
        <ConfirmDialog />
      </SnackbarProvider>
    </RootStyle>
  )
}
