import { useEffect, useRef, useState } from 'react'
import * as _ from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'

import { compareImages } from './fragmentHelpers'
import {
  imageAtom,
  indexAtom,
  mannequinLoadingAtom,
  maskImgAtom,
  removeMaskImgAtom,
  selectedMaskImgAtom,
} from 'atoms'

const Tool = ({ handleMouseMove }) => {
  const image = useRecoilValue(imageAtom)
  const [maskImg, setMaskImg] = useRecoilState(maskImgAtom)
  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [mannequinLoading, setMannequinLoading] = useRecoilState(mannequinLoadingAtom)
  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [index, SetIndex] = useRecoilState(indexAtom)

  // Determine if we should shrink or grow the images to match the
  // width or the height of the page and setup a ResizeObserver to
  // monitor changes in the size of the page
  const [shouldFitToWidth, setShouldFitToWidth] = useState(true)
  const bodyEl = document.body
  const fitToPage = () => {
    if (!image) return
    const imageAspectRatio = image.width / image.height
    const screenAspectRatio = window.innerWidth / window.innerHeight
    setShouldFitToWidth(imageAspectRatio > screenAspectRatio)
  }
  const resizeObserver = new ResizeObserver(entries => {
    for (const entry of entries) {
      if (entry.target === bodyEl) {
        fitToPage()
      }
    }
  })

  useEffect(() => {
    SetIndex(selectedMaskImg.length - 1)
  }, [selectedMaskImg])

  const handleClick = async () => {
    const shouldAddImage = true

    if (!maskImg) return
    if (!selectedMaskImg.length) {
      SetRemoveMaskImg([])
      return setSelectedMaskImg([...new Set([...selectedMaskImg, maskImg.src])])
    } else {
      // // 새 이미지와 이미지 배열 속 이미지 비교

      // for (let i = 0; i < selectedMaskImg.length; i++) {
      //   const newMask = maskImg.src
      //   const comparisonMask = selectedMaskImg[i]

      //   // 이미지 유사도 계산
      //   const compareData = await compareImages(newMask, comparisonMask)

      //   // 유사도가 0.9 이상인 경우 이미지 추가하지 않음
      //   if (compareData.similarity >= 0.7) {
      //     console.log('similarity 70% ')
      //     shouldAddImage = false
      //     break // 이미지 추가하지 않고 루프 종료
      //   }
      // }
      // if (shouldAddImage) {
      const copy = [...selectedMaskImg]
      copy.push(maskImg.src)
      setSelectedMaskImg([...copy])
    }
    // }
  }

  useEffect(() => {
    fitToPage()
    resizeObserver.observe(bodyEl)
    return () => {
      resizeObserver.unobserve(bodyEl)
    }
  }, [image])

  const maskRef = useRef(null)

  const imageStyle = shouldFitToWidth ? { width: '100%' } : { height: '100%' }

  // Render the image and the predicted mask image on top
  return (
    <>
      {image && (
        <img
          onMouseMove={handleMouseMove}
          onMouseOut={() => _.defer(() => setMaskImg(null))}
          onTouchStart={handleMouseMove}
          src={image.src}
          onClick={mannequinLoading ? () => {} : handleClick}
          style={{
            cursor: mannequinLoading ? 'auto' : 'pointer',
            ...imageStyle,
          }}
        ></img>
      )}
      {maskImg && (
        <img
          ref={maskRef}
          src={maskImg.src}
          style={{
            position: 'absolute',
            opacity: '40%',
            pointerEvents: 'none',
            zIndex: 1,
            ...imageStyle,
          }}
        ></img>
      )}
      {selectedMaskImg &&
        selectedMaskImg.map((m, i) => {
          return (
            <img
              key={i}
              src={m}
              style={{
                position: 'absolute',
                opacity: '30%',
                pointerEvents: 'none',
                ...imageStyle,
              }}
            ></img>
          )
        })}
    </>
  )
}

export default Tool
