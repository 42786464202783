import { Box, Button, CircularProgress, IconButton, Popover, Stack, TextField } from '@mui/material'
import { apis } from 'apis'
import { defaultConfigAtom, portfolioDetailAtom, userAtom } from 'atoms'
import axios from 'axios'
import {
  BannerArtworkList,
  BannerTemplatesList,
  BannerV2default,
  CenterAlignBox,
  CenterAlignStack,
  InpaintCanvas,
} from 'components'
import { BannerTemplate2 } from 'components/portfolio/BannerConfig'
import { IMG_BASE64 } from 'components/portfolio/RegenerateDialog'
import { usePortfolio } from 'hooks/usePortfolio'
import { useArtworkQuery, useSelectedTemplateData, useTemplates } from 'hooks/useReactQuery'
import { Suspense, useEffect, useMemo, useState } from 'react'
import { MdSettingsInputComponent } from 'react-icons/md'
import { useRecoilState, useRecoilValue } from 'recoil'

export default function BannerUploadV2() {
  const [selectedIds, setSelectedIds] = useState([])
  const [sizeSettings, setSizeSettings] = useState({ width: 160, height: 320 }) // 설정 API 반영용
  const [anchorEl, setAnchorEl] = useState(null)
  const [tempSize, setTempSize] = useState(sizeSettings) // 설정 변경 용
  const [inpaintOpen, setInpaintOpen] = useState(false)
  const [bboxs, setBboxs] = useState({})
  const [urls, setUrls] = useState({})

  const [backgroundUserPrompt, setBackgroundUserPrompt] = useState('')

  const portfolioDetail = useRecoilValue(portfolioDetailAtom)

  const user = useRecoilValue(userAtom)

  const [defaultConfig, setDefaultConfig] = useRecoilState(defaultConfigAtom)

  const reset = () => {
    setBboxs({})
    setUrls({})
    setSelectedIds([])
  }

  // artworks
  const { getArtworks } = useArtworkQuery({ portfolioId: portfolioDetail.id })
  const { data, refetch } = getArtworks

  const { selectedTemplateData, isInpaint, isLogo, isLoading, isError } =
    useSelectedTemplateData(selectedIds)

  const handleSettingsClick = event => {
    setAnchorEl(event.currentTarget)
    setTempSize(sizeSettings)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSizeChange = e => {
    const { name, value } = e.target
    setTempSize(prev => ({ ...prev, [name]: Number(value) }))
  }

  const handleSizeSubmit = () => {
    setSizeSettings(tempSize)
    handleClose()
  }

  useEffect(() => {
    console.log('selectedTemplateData', selectedTemplateData, selectedIds)
    if (selectedTemplateData) {
      setSizeSettings(selectedTemplateData.canvasSize)
    }
  }, [selectedIds])
  const uploadBannerV2 = () => {
    const formData = new FormData()

    const adjustedShapes = selectedTemplateData.shapes.map(shape => {
      const shapeCopy = { ...shape }

      if (shape.config.type === 'background') {
        // inpaint 가 있고 직접 기입한게 있으면 backgroundUserPrompt 로 없으면 템플릿으로
        return {
          ...shape,
          config: {
            ...shape.config,
            ...(isInpaint
              ? { bg_inpaint_prompt: backgroundUserPrompt || shape.config.bg_inpaint_prompt }
              : { bg_concept_prompt: backgroundUserPrompt || shape.config.bg_concept_prompt }),

            bg_inpaint_image_bbox: bboxs?.product || [],
            bg_inpaint_image_url: urls?.product || '',
          },
        }
      }

      if (shape.config.type === 'inpaint') {
        return {
          ...shape,
          config: {
            ...shape.config,
            bg_inpaint_image_bbox: bboxs?.product || [],
            bg_inpaint_image_url: urls?.product || '',
          },
        }
      }

      if (shape.config.type === 'logo') {
        return {
          ...shape,
          ...(bboxs?.logo && {
            x: bboxs?.logo[0],
            y: bboxs?.logo[1],
            width: bboxs?.logo[2] - bboxs?.logo[0],
            height: bboxs?.logo[3] - bboxs?.logo[1],
          }),
          config: {
            ...shape.config,
            bbox: bboxs?.logo || [],
            logo_image_url: urls?.logo || '',
          },
        }
      }

      return shapeCopy
    })

    // const finalShapes = adjustedShapes.map(shape => {
    //   if (shape.config.type === 'background') {
    //     return {
    //       ...shape,
    //       config: {
    //         ...shape.config,
    //         bg_inpaint_image_bbox: inpaintBbox || [],
    //         bg_inpaint_image_url: inpaintURL || '',
    //       },
    //     }
    //   }

    //   return shape
    // })

    console.log(adjustedShapes)

    const bannerPresets = {
      canvasSize: { width: sizeSettings.width, height: sizeSettings.height },
      shapes: adjustedShapes,
      templateId: selectedIds[0],
      defaultConfig,
    }

    // // 나중에 이사가야해
    formData.append('user_id', user.id)
    formData.append('portfolio_id', portfolioDetail.id)
    formData.append('username', user.username)
    // formData.append('banner_template_id_list', JSON.stringify(selectedIds))
    formData.append('banner_presets', JSON.stringify([bannerPresets, bannerPresets, bannerPresets]))

    apis.appfront.makeBannerV2(formData).then(r => {
      console.log(r.data)
      reset()
      refetch()
    })
  }

  return (
    <CenterAlignStack sx={{}}>
      <BannerV2ConfigStep />
      <Box>
        <IconButton onClick={handleSettingsClick}>
          <MdSettingsInputComponent />
        </IconButton>
      </Box>
      <BannerV2default />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2 }}>
          <TextField
            name="width"
            label="Width"
            type="number"
            value={tempSize.width}
            onChange={handleSizeChange}
            sx={{ mb: 2 }}
          />
          <TextField
            name="height"
            label="Height"
            type="number"
            value={tempSize.height}
            onChange={handleSizeChange}
            sx={{ mb: 2 }}
          />
          <Button onClick={handleSizeSubmit} variant="contained">
            적용
          </Button>
        </Box>
      </Popover>
      <BannerTemplatesList
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        size={{ width: '10rem', height: '10rem' }}
      />
      {(isInpaint || isLogo) && (
        <Button
          onClick={() => {
            setInpaintOpen(true)
          }}
        >
          상품을 넣고 위치를 지정해야죠 ?
        </Button>
      )}

      {/* 직접 만들 때 프롬프트 */}
      <TextField
        label="프롬프트"
        type="text"
        value={backgroundUserPrompt}
        onChange={e => {
          setBackgroundUserPrompt(e.target.value)
        }}
        sx={{ width: '40rem' }}
      />

      <Button onClick={uploadBannerV2} disabled={!selectedIds.length} variant="outlined">
        전송 !
      </Button>

      <InpaintCanvas
        bboxs={bboxs}
        setBboxs={setBboxs}
        urls={urls}
        setUrls={setUrls}
        isOpen={inpaintOpen}
        isInpaint={isInpaint}
        isLogo={isLogo}
        url={
          selectedTemplateData?.templateImage ||
          'https://www.southseabeachcafe.co.uk/wp-content/uploads/2014/08/500x500.gif'
        }
        onClose={(callback = () => {}) => {
          setInpaintOpen(false)
          callback()
        }}
      />

      <BannerArtworkList />
    </CenterAlignStack>
  )
}

function BannerV2ConfigStep() {
  const [step, setStep] = useState(0)
  const [bannerCategory, setBannerCategory] = useState('auto')

  switch (step) {
    case 0:
      return <BannerCategory />

    default:
      break
  }
}

const BannerCategory = () => {
  return (
    <CenterAlignBox
      sx={{
        '& .box': {
          width: '50rem',
        },
      }}
    >
      <Stack className="box auto">콘셉트로 AI 제작</Stack>

      <Stack className="box manual">직접 제작</Stack>
    </CenterAlignBox>
  )
}
