import { Box, Divider, Stack, Typography, useTheme } from '@mui/material'
import { userCreditInfoAtom } from 'atoms'
import { CenterAlignBox, CenterAlignStack } from 'components'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { UserCharge, UserCredit } from 'pages'
import { useEffect, useState } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import { apis } from 'apis'

import { useTranslation } from 'react-i18next'
import { userSubInfoAtom } from '../atoms'

const mobileFooterStyle = {
  fontWeight: 400,
  fontSize: '1.2rem',
}

export default function UserCreditInfo() {
  const [userCreditInfo, setUserCreditInfo] = useRecoilState(userCreditInfoAtom)
  const resetUserCreditInfo = useResetRecoilState(userCreditInfoAtom)

  const [currentSubscription, setCurrentSubscription] = useRecoilState(userSubInfoAtom)
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  const refreshSubscription = () => {
    apis.user.getUserSubscription().then(response => {
      setCurrentSubscription(response.data)
    })
  }

  useEffect(() => {
    refreshSubscription()

    return () => {
      resetUserCreditInfo()
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [userCreditInfo])

  const typoStyle = {
    fontSize: '1.6rem',
    fontWeight: 400,
    cursor: 'pointer',
    width: '12.5rem',
    '&:hover': {
      opacity: 0.6,
      fontWeight: 400,
    },
  }

  const selectedTypoStyle = {
    fontSize: '1.6rem',
    fontWeight: 800,
    cursor: 'pointer',
    color: theme.palette.draph.blue,
    width: '12.5rem',

    '&:hover': {
      opacity: 0.8,
      fontWeight: 800,
    },
  }

  return (
    <Box>
      <Desktop>
        {userCreditInfo === 0 ? (
          <UserCharge
            currentSubscription={currentSubscription}
            refreshSubscription={refreshSubscription}
          />
        ) : (
          <UserCredit currentSubscription={currentSubscription} />
        )}
      </Desktop>
      <Mobile>
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography sx={{ mb: '1.8rem', fontSize: '2.4rem', fontWeight: 800 }}>
            {t('mypage_menu.credit')}
          </Typography>
          <CenterAlignBox sx={{ height: '2rem', alignItems: 'center' }}>
            <Typography
              sx={
                userCreditInfo === 0
                  ? { ...selectedTypoStyle, textAlign: 'right' }
                  : { ...typoStyle, textAlign: 'right' }
              }
              onClick={() => setUserCreditInfo(0)}
            >
              {t('mypage_menu.charged_credit')}
            </Typography>
            <Divider orientation="vertical" sx={{ mx: '2rem', border: '0.6px solid #909090' }} />
            <Typography
              sx={userCreditInfo === 1 ? selectedTypoStyle : typoStyle}
              onClick={() => setUserCreditInfo(1)}
            >
              {t('mypage_menu.credit_history')}
            </Typography>
          </CenterAlignBox>

          {userCreditInfo === 0 ? (
            <UserCharge
              currentSubscription={currentSubscription}
              refreshSubscription={refreshSubscription}
            />
          ) : (
            <UserCredit currentSubscription={currentSubscription} />
          )}
        </Stack>
      </Mobile>

      <CenterAlignStack
        sx={{
          width: '100%',
          pl: { lg: MYPAGE_SIDE_MARGIN },
          background: theme.palette.common.lightgray,
          py: '3.6rem',
          mt: '8.2rem',
          px: { xs: '2rem' },
        }}
      >
        <Box
          component="ul"
          sx={{
            width: { lg: '88.4rem' },
            pl: '0.5rem',
            '& li': {
              fontSize: { lg: '1.6rem', xs: '1.2rem' },
              fontWeight: 400,
              lineHeight: { lg: '2.5rem' },
              mb: { xs: '1rem' },
              listStyleType: 'none',
              textIndent: '-0.8rem',
              '&::before': {
                content: '"•"',
                marginRight: '0.3rem',
              },
            },
          }}
        >
          <Typography component="li" sx={{ ...mobileFooterStyle }}>
            {t('credit.notice_1')}
          </Typography>
          <Typography component="li" sx={{ ...mobileFooterStyle }}>
            {t('credit.notice_2_a')}
            <Mobile>
              <br />
            </Mobile>{' '}
            {t('credit.notice_2_b')}
            <Desktop>
              <br /> &nbsp;
            </Desktop>
            {t('credit.notice_2_c')}
          </Typography>
          <Typography component="li" sx={{ ...mobileFooterStyle }}>
            {t('credit.notice_3')}
          </Typography>
          <Typography component="li" sx={{ ...mobileFooterStyle }}>
            {t('credit.notice_4')}
          </Typography>
        </Box>
      </CenterAlignStack>
    </Box>
  )
}
