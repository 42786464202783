import crudAxios from './configs/crud'

export const effectAPI = {
  getAlleffect: () => {
    return crudAxios.get('/effect')
  },
  createEffect: body => {
    return crudAxios.post('/effect', body)
  },

  getEffect: effectId => {
    return crudAxios.get(`/effect/${effectId}`)
  },
  putEffect: (effectId, body) => {
    return crudAxios.put(`/effect/${effectId}`, body)
  },
  deleteEffect: effectId => {
    return crudAxios.delete(`/effect/${effectId}`)
  },

  getArtworkEffect: effectId => {
    return crudAxios.get(`/effect/${effectId}/artwork`)
  },
}
