import { Dialog, Grid, Typography } from '@mui/material'
import { autoUploadAtom } from 'atoms'
import axios from 'axios'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { DialogButton } from 'components/DialogButtons'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { portfolioBgTypeSelector } from 'selector'
import { getS3ImageSrc } from 'utils/common'
import { ThemeTemplateThumb } from './PortfolioConfig'
import * as config from 'config'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import MobileSwipeableDrawer from 'components/MobileSwipeableDrawer'

const THEME_ITEM_PER_PAGE = 8

export default function CreateBgForNukkiDialog({ open, setOpen, artwork }) {
  const bgTypes = useRecoilValue(portfolioBgTypeSelector)
  const [removeBgImageFile, setRemoveBgImageFile] = useRecoilState(autoUploadAtom)

  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  const handleAction = async () => {
    // ----- GA4 event -----
    window.gtag('event', 'removebg_img_generate_complete', {})
    // ---------------------

    const res = await axios.get(
      getS3ImageSrc(artwork.pieces[0].path) + `?w=${Date.now().toString()}`,
      {
        headers: {
          withCredentials: false,
        },
        responseType: 'blob',
      }
    )

    const fileImage = new File([res.data], artwork.name, { type: 'image/png' })

    setRemoveBgImageFile({ auto: true, file: fileImage })
    navigate('/generate/upload?pages=1')
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              minWidth: '59.6rem',
              p: '2.8rem 5.2rem 0rem 5.2rem',
              borderRadius: '1rem',
            },
          }}
        >
          {' '}
          <Contents handleAction={handleAction} handleClose={handleClose} />
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={handleClose}
        >
          <Contents handleAction={handleAction} handleClose={handleClose} />
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

const Contents = ({ handleAction, handleClose }) => {
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()

  return (
    <CenterAlignStack sx={{ mt: { lg: 0, xs: '0.8rem' } }}>
      <Typography
        sx={{
          fontSize: { lg: '2rem', xs: '1.6rem' },
          fontWeight: { lg: 700, xs: 800 },
          lineHeight: 'normal',
        }}
      >
        {t('nukki_dialog.title')}
      </Typography>
      <Typography
        sx={{ fontSize: { lg: '1.4rem', xs: '1.3rem' }, fontWeight: 400, textAlign: 'center' }}
      >
        {t('nukki_dialog.comment_1')} {isMobile && <br />} {t('nukki_dialog.comment_2')}
      </Typography>

      <CenterAlignBox gap="2.4rem" sx={{ m: '2rem 0 2.4rem 0' }}>
        {/* <Grid
          id="thumb-list"
          container
          columns={4}
          gap={1}
          sx={{
            // width: { lg: '52rem', xs: '33rem' },
            // mb: '3rem',
            // mt: '1.8rem',
            justifyContent: 'center',
            // border: '1px solid red',
          }}
        >
          {themePageItem.map((item, idx) => (
            <Grid
              item
              // key={idx}
              key={idx}
              // lg={item.id === 'auto' ? 13 : 3}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setSelected(item)
              }}
            >
              <ThemeTemplateThumb theme={item} selected={selected?.id === item.id} />
            </Grid>
          ))}

  
          {Array(THEME_ITEM_PER_PAGE - themePageItem.length)
        .fill(0)
        .map((a, i) => (
          <Grid item key={i}>
            <ThemeTemplateThumb theme={{ id: null }} sx={{ background: 'transparent' }} />
          </Grid>
        ))}
        </Grid> */}
      </CenterAlignBox>

      <CenterAlignBox sx={{ mb: '2.8rem' }}>
        <DialogButton
          actionText={t('button.confirmation')}
          handleAction={handleAction}
          handleClose={handleClose}
        />
      </CenterAlignBox>
    </CenterAlignStack>
  )
}
