import { apis } from 'apis'
import {
  backgroundAtom,
  backgroundFetchInfoAtom,
  backgroundFilterAtom,
  loadingAtom,
  userLikedBgAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

export default function useBackground() {
  const setBackgroundList = useSetRecoilState(backgroundAtom)
  const [fetchInfo, setFetchInfo] = useRecoilState(backgroundFetchInfoAtom)
  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)
  const filter = useRecoilValue(backgroundFilterAtom)
  const [isBgAddLoading, setIsBgAddLoading] = useRecoilState(loadingAtom)

  const refreshBackground = () => {
    const pageSize = window.devicePixelRatio < 1 ? 70 : 30
    const params = new URLSearchParams()
    if (pageSize * (fetchInfo.page - 1) === 0) return
    setFetchInfo(prev => ({ ...prev, isLoading: true }))

    params.append('page', 1)
    params.append('page_size', pageSize * (fetchInfo.page - 1))
    params.append('pinned', filter.pinned)
    params.append('user', filter.user)

    apis.background
      .getBackgrounds(params)
      .then(response => {
        setBackgroundList(response.data)

        if (isBgAddLoading === true) {
          setIsBgAddLoading(false)
        }

        setFetchInfo(prev => {
          // console.log(response.data.length, pageSize)
          const hasNextPage = Boolean(response.data.length >= pageSize)

          return { ...prev, hasNextPage }
        })
      })
      .catch(() => {
        setFetchInfo(prev => ({ ...prev, hasNextPage: false }))
      })
      .finally(() => {
        setFetchInfo(prev => ({ ...prev, isLoading: false }))
      })

    if (setUserLikedBg) {
      apis.background.getLikedBackgrounds().then(response => {
        setUserLikedBg(response.data)
      })
    }
  }

  const fetchBackground = () => {
    const pageSize = window.devicePixelRatio < 1 ? 70 : 40

    setFetchInfo(prev => ({ ...prev, isLoading: true }))
    // console.log('배경 가져오기')
    const params = new URLSearchParams()
    params.append('page', fetchInfo.page)
    params.append('page_size', pageSize)
    params.append('pinned', filter.pinned)
    params.append('user', filter.user)

    apis.background
      .getBackgrounds(params)
      .then(response => {
        setBackgroundList(b => {
          if (b) {
            return b.concat(response.data)
          } else {
            return response.data
          }
        })
        setFetchInfo(prev => {
          const afterPage = prev.page + 1
          const hasNextPage = Boolean(response.data.length === pageSize)

          return { ...prev, page: afterPage, hasNextPage }
        })
      })
      .catch(() => {
        setFetchInfo(prev => ({ ...prev, hasNextPage: false }))
      })
      .finally(() => {
        setFetchInfo(prev => ({ ...prev, isLoading: false }))
      })
  }

  return { refreshBackground, fetchBackground }
}
