import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { UploadButton } from 'components/UploadHeader'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SetNewPortfolioConcept, SetPortfolioConcept } from './PortfolioConfig'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  artworkPageAtom,
  artworkViewConfigAtom,
  autoUploadAtom,
  bannerMajorPageAtom,
  bannerMannInfoAtom,
  brandConfigUpdateAtom,
  brandReadOnlyConfigAtom,
  bulkUploadFileAtom,
  currentMenuAtom,
  customTemplateAtom,
  defaultPortfolioAtom,
  f1Atom,
  f2Atom,
  f3Atom,
  indexAtom,
  isAnchorAtom,
  mannequinLoadingAtom,
  maskImgArrayAtom,
  portfolioConfigAtom,
  portfolioDetailAtom,
  portfolioLastArtwork,
  portfolioTypeAtom,
  removeMaskImgAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  selectedMaskImgAtom,
  unusualPortfolioConfigUpdateAtom,
  uploadDialogOpenAtom,
  uploadFilesAndUrlAtom,
  uploadImgHeightAtom,
  uploadImgWidthAtom,
  uploadLoadingAtom,
  userAtom,
  userLikedBgAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  isSimpleAtom,
} from 'atoms'
import { usePortfolio } from 'hooks/usePortfolio'
import { useGuestPortfolio } from 'hooks/useGuestPortfolio'
import { apis } from 'apis'
import { triggerGA4UploadEvent } from './Header'
import {
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
  userPortfolioNamesSelector,
} from 'selector'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import * as config from 'config'
import { BarLoader as Loader } from 'react-spinners'
import {
  CloseBlackIcon,
  EraserIcon,
  MinusIcon,
  PlusIcon,
  UploadIcon,
  UploadIcon2,
  ZipIcon,
} from 'theme/icon'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { English, Korean } from 'hooks/useLanguage'
import {
  CreateButton,
  CustomOutlinedInput,
  OpacityMotionDiv,
  SpanDraphBlue,
} from 'components/CustomStyle'
import OutputField from './OutputField'
import { Desktop, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { BannerConfigPage, StyledToggleButton } from './BannerConfig'
import { Segment } from 'components/fragment/Segment'
import { useFragment } from 'hooks/useFragement'
import { imgMergeAndGrayScale } from './UploadMannequinDialog'
import useConfirm from 'hooks/useConfirm'
import useBanner from 'hooks/useBanner'
import { PORTFOLIO_CONFIG_DEFAULT_BACK, BANNER_ARTWORK_CONFIG } from 'config'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { isDev, transferToBack } from 'utils/common'
import { getUploadUUID } from 'utils/artwork'
import { useNavigate } from 'react-router-dom'
import { getAccessToken, getGuestAccess } from 'utils/cookie'
import { NaverProductListDialog, NaverUploadDialog } from './NaverCommerce'
import { NHNUploadDialog } from 'pages/NHNCommerce'
import { Cafe24UploadDialog } from 'pages/Cafe24'
import { useResetNpy } from 'hooks/useResetNpy'
import { MannequinConfig, ModelCommonConfig } from 'components/ModelRenerateDialog'

export const roundButtonStyle = {
  borderRadius: '3rem',
  border: 0,
  fontSize: '1.2rem',

  boxShadow: ' 2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
  fontWeight: 600,
  '&.Mui-disabled': {
    border: 'none',
    '& svg path': {
      stroke: '#919EAB',
    },
  },
  '&:hover': {
    boxShadow: ' 2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
    background: 'unset',
    color: theme => theme.palette.draph.blue,
    '& svg path': {
      stroke: theme => theme.palette.draph.blue,
      transition: 'stroke 0.3s',
    },
  },
  '& svg path': {
    transition: 'stroke 0.3s',
  },
  '& svg': {
    mr: '0.5rem',
  },
}

const UploadComponent = ({
  handleClick,
  uploading,
  uploadButtonDisabled,
  files,
  addTag,
  buttonText,
  sx = {},
  filesNotRequired = false,
}) => {
  const theme = useTheme()

  const { t, i18n } = useTranslation()
  const realText = buttonText ?? t('upload_header.upload')

  return (
    <CreateButton
      disabled={uploading || uploadButtonDisabled || (!filesNotRequired && !files.length)}
      onClick={handleClick}
      sx={{ ...sx }}
    >
      {uploading ? (
        <Loader />
      ) : (
        <Box
          className="content"
          sx={{
            display: 'flex',
            alignItems: 'center',
            transition: 'transform 0.3s ease-in-out', // transition 추가
          }}
        >
          <UploadIcon sx={{ marginRight: '0.5rem' }} />
          {realText}
          {addTag && (
            <CenterAlignStack
              id="tag"
              sx={{
                ml: '0.8rem',
                color: theme.palette.draph.newBlue,
                background: theme.palette.common.white,
                heigth: '2.4rem',
                borderRadius: '4px',
                fontSize: '1.2rem',
                fontWeight: 700,
                px: '1rem',
              }}
            >
              {addTag}
            </CenterAlignStack>
          )}
        </Box>
      )}
    </CreateButton>
  )
}

const PortfolioUploadConfigComponent = ({ uploadButtonOnly = false }) => {
  const theme = useTheme()
  const [refImage, serRefImage] = useState([])

  const isCancelled = useRef(false)

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])

  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)

  const { t, i18n } = useTranslation()
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [customTemplate, setCustomTemplate] = useRecoilState(customTemplateAtom)

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const user = useRecoilValue(userAtom)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const [modified, setModified] = useState(false)
  const [removeBgImageFile, setRemoveBgImageFile] = useRecoilState(autoUploadAtom)
  const [isAnchor, setIsAnchor] = useRecoilState(isAnchorAtom)

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)
  const resetRemoveBgImageFile = useResetRecoilState(autoUploadAtom)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isFunFamePage = portfolioType === config.PORTFOLIO_TYPE_FAME
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isNormalPortfolio = portfolioType === config.PORTFOLIO_TYPE_NORMAL

  const isBgExpansion =
    portfolioConfig.flagBgExpansion || portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const {
    refreshArtworks,
    checkUserCredit,
    prepareUpload,
    makeUploadFormData,
    updatePortfolioDetail,
  } = usePortfolio()

  const handleUpload = async (filesObj, eventName = 'upload') => {
    const transferConfig = transferToBack(portfolioConfig)
    const addGenOption = {
      ...PORTFOLIO_CONFIG_DEFAULT_BACK,
      ...transferConfig,

      // 참고 배경 넣기 위해 넣음
      has_ref_image: Boolean(refImage.length),

      // ...(Boolean(refImage.length) && {
      //   flag_generate: true,
      //   flag_complex_cmp: true,
      //   flag_simple_cmp: false,
      // }),
    }

    const addFormData = refImage.length
      ? {
          ref_image: refImage[0]?.file ?? '',
        }
      : {}

    await updatePortfolioDetail()

    const files = filesObj.map(f => f.file)

    const uploadFiles = await prepareUpload(files)
    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)

    // ----- GA4 event -----
    triggerGA4UploadEvent(uploadConfig, user, uploadFiles.length, eventName)
    // ---------------------

    const formData = await makeUploadFormData(uploadFiles, addGenOption, addFormData)

    console.log('--------- start uploading')
    setUploading(true)
    apis.appfront
      .upload(formData)
      .then(() => {
        // console.log('revoke_3')
        checkUserCredit()
        removeBgImageFile.auto && resetRemoveBgImageFile()

        // console.log('실행 전 !')
        if (!isCancelled.current) {
          // console.log('실행되었어 !')
          setUploading(false)
          setUploadButtonDiasbled(false)
          setModified(false)

          files.forEach(image => URL.revokeObjectURL(image.url))
          resetFiles()
          refreshArtworks()
          setIsAnchor(true)

          if ('Notification' in window && Notification.permission === 'default') {
            showBrowserNotificationDialog()
          }
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  const handleClick = () => {
    const eventName = isFunFamePage
      ? 'famous_person_upload'
      : isBundlePage
      ? 'multipack_photo_upload'
      : isBgExpansion
      ? 'ai_canvas_exp_upload'
      : isNormalPortfolio
      ? 'portfolio_upload'
      : 'upload'

    handleUpload(files, eventName)

    // handleClose()
  }

  const [isSimple, setIsSimple] = useState('nothing') // 기본 선택일 경우 옮겨지는 느낌이 생김
  const [selected, setSelected] = useState(null)

  const deleteCallback = () => {
    setIsSimple(false)
    setSelected({ id: 'auto' })
  }

  if (!uploadButtonOnly) {
    return (
      <Stack>
        <Box sx={{ p: '4.4rem 3rem', display: 'flex', alignItems: 'flex-start' }}>
          <Stack
            sx={{
              width: 'fit-content',
              alignItems: 'center',
              gap: '1.2rem',
            }}
          >
            <UploadButton
              formStyle={{ width: '9.2rem', height: '9.2rem' }}
              multiple={false}
              files={refImage}
              setFiles={serRefImage}
              uploadImglimit={1}
              thumbnailMode={true}
              deleteCallback={deleteCallback}
            />
            <Typography sx={{ fontWeight: 600, width: 'max-content' }}>
              {t('upload_header.ref_image')}
            </Typography>
          </Stack>
          <Stack>
            <SetNewPortfolioConcept
              files={refImage}
              portfolioConfig={portfolioConfig}
              setPortfolioConfig={setPortfolioConfig}
              isSimple={isSimple}
              setIsSimple={setIsSimple}
              selected={selected}
              setSelected={setSelected}
            />
          </Stack>
        </Box>
        <UploadComponent
          handleClick={handleClick}
          uploading={uploading}
          uploadButtonDisabled={uploadButtonDisabled}
          files={files}
        />
      </Stack>
    )
  } else {
    return (
      <UploadComponent
        handleClick={handleClick}
        uploading={uploading}
        uploadButtonDisabled={uploadButtonDisabled}
        files={files}
      />
    )
  }
}

const RemoveBgConfigComponent = ({ uploadButtonOnly = false }) => {
  const theme = useTheme()

  const isCancelled = useRef(false)

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])

  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const { t, i18n } = useTranslation()
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)

  const [nukkiUploadBlock, setNukkiUploadBlock] = useState(true)
  const lastArtwork = useRecoilValue(portfolioLastArtwork)

  const [intervalId, setIntervalId] = useState()

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const { refreshArtworks, checkUserCredit, prepareUpload, makeUploadFormData } = usePortfolio()

  useEffect(() => {
    if (lastArtwork) {
      const c = JSON.parse(lastArtwork.config)
      const diff = moment.duration(moment().diff(moment(lastArtwork.created)))
      const diffMinutes = parseInt(diff.asMinutes()) % 60

      if (config.ARTWORK_IN_PROGRESS_STATUS.includes(lastArtwork.status)) {
        // 가장 최근 아트워크가 진행중이면, (백엔드 이슈 등으로) 생성이 지연되고있을 때 시간 초과에 따른 버튼 활성화 해주기 위해 인터벌 세팅
        if (!intervalId) {
          const timerId = setInterval(() => {
            const diff = moment.duration(moment().diff(moment(lastArtwork.created)))
            const diffMinutes = parseInt(diff.asMinutes()) % 60
            if (
              !config.ARTWORK_IN_PROGRESS_STATUS.includes(lastArtwork.status) ||
              diffMinutes >= 1
            ) {
              setNukkiUploadBlock(false)
              clearInterval(timerId)
            }
          }, 60 * 1000)
          setIntervalId(timerId)
        }
      }

      if (!config.ARTWORK_IN_PROGRESS_STATUS.includes(lastArtwork.status) || diffMinutes >= 1) {
        setNukkiUploadBlock(false)
      } else {
        setNukkiUploadBlock(true)
      }
    } else {
      setNukkiUploadBlock(false)
    }
  }, [lastArtwork])

  const handleUpload = async filesObj => {
    const files = filesObj.map(f => f.file)

    let uploadFiles = []
    if (files.length > 1) {
      uploadFiles = await prepareUpload(files, null, 'artwork_create_nukki_bulk', true)
    } else {
      uploadFiles = await prepareUpload(files)
    }
    if (!uploadFiles) return

    setUploadButtonDiasbled(true)
    setUploading(true)

    // ----- GA4 event -----
    window.gtag('event', 'removebg_upload', { count: uploadFiles.length })
    // ---------------------
    if (uploadFiles.length > 1) {
      const groupUid = uuidv4()
      const addOption = { upload_group_uuid: groupUid }

      const group1 = uploadFiles.slice(0, uploadFiles.length - 1)
      const group2 = [uploadFiles[uploadFiles.length - 1]]

      // group1 업로드
      const formData = await makeUploadFormData(group1, addOption)
      const formData2 = await makeUploadFormData(group2, {
        ...addOption,
        charge_credit_priority: 'artwork_create_nukki_bulk',
      })

      console.log('--------- start uploading')

      await apis.appfront.upload(formData).catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })

      // group2 (1개) 업로드
      apis.appfront
        .upload(formData2)
        .then(() => {
          // console.log('revoke_4')

          checkUserCredit()

          if (!isCancelled.current) {
            setUploading(false)
            setUploadButtonDiasbled(false)

            files.forEach(image => URL.revokeObjectURL(image.url))
            resetFiles()
            refreshArtworks()
          }

          if ('Notification' in window && Notification.permission === 'default') {
            showBrowserNotificationDialog()
          }
        })
        .catch(() => {
          setUploading(false)
          setUploadButtonDiasbled(false)
        })
    } else {
      // 1장 무료 업로드

      const formData = await makeUploadFormData(uploadFiles)
      console.log('--------- start uploading')
      setUploading(true)

      apis.appfront
        .upload(formData)
        .then(() => {
          // console.log('revoke_5')
          files.forEach(image => URL.revokeObjectURL(image.url))
          resetFiles()
          checkUserCredit()
          setUploading(false)
          setUploadButtonDiasbled(false)
          refreshArtworks()

          if ('Notification' in window && Notification.permission === 'default') {
            showBrowserNotificationDialog()
          }
        })
        .catch(() => {
          setUploading(false)
          setUploadButtonDiasbled(false)
        })
    }
  }

  const handleClick = () => {
    handleUpload(files)
  }

  if (!uploadButtonOnly) {
    return null
  } else {
    return (
      <UploadComponent
        handleClick={handleClick}
        uploading={uploading}
        uploadButtonDisabled={uploadButtonDisabled || nukkiUploadBlock}
        files={files}
        addTag={
          nukkiUploadBlock ? '' : files.length > 1 ? `-1 ${t('upload_header.credit')}` : 'FREE'
        }
        buttonText={
          nukkiUploadBlock ? t('upload_header_nukki_comment_b') : t('upload_header.bg_remove')
        }
      />
    )
  }
}

const RemoveBgGuestConfigComponent = ({ uploadButtonOnly = false }) => {
  const theme = useTheme()

  const isCancelled = useRef(false)

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])

  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const { t, i18n } = useTranslation()

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)

  const [nukkiUploadBlock, setNukkiUploadBlock] = useState(true)
  const lastArtwork = useRecoilValue(portfolioLastArtwork)

  const [intervalId, setIntervalId] = useState()

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const { refreshArtworks, makeUploadFormData, prepareUpload } = useGuestPortfolio()

  useEffect(() => {
    if (lastArtwork) {
      const c = JSON.parse(lastArtwork.config)
      const diff = moment.duration(moment().diff(moment(lastArtwork.created)))
      const diffMinutes = parseInt(diff.asMinutes()) % 60

      if (config.ARTWORK_IN_PROGRESS_STATUS.includes(lastArtwork.status)) {
        // 가장 최근 아트워크가 진행중이면, (백엔드 이슈 등으로) 생성이 지연되고있을 때 시간 초과에 따른 버튼 활성화 해주기 위해 인터벌 세팅
        if (!intervalId) {
          const timerId = setInterval(() => {
            const diff = moment.duration(moment().diff(moment(lastArtwork.created)))
            const diffMinutes = parseInt(diff.asMinutes()) % 60
            if (
              !config.ARTWORK_IN_PROGRESS_STATUS.includes(lastArtwork.status) ||
              diffMinutes >= 1
            ) {
              setNukkiUploadBlock(false)
              clearInterval(timerId)
            }
          }, 60 * 1000)
          setIntervalId(timerId)
        }
      }

      if (!config.ARTWORK_IN_PROGRESS_STATUS.includes(lastArtwork.status) || diffMinutes >= 1) {
        setNukkiUploadBlock(false)
      } else {
        setNukkiUploadBlock(true)
      }
    } else {
      setNukkiUploadBlock(false)
    }
  }, [lastArtwork])

  useEffect(() => {}, [nukkiUploadBlock])

  const handleUpload = async filesObj => {
    const files = filesObj.map(f => f.file)

    const uploadFiles = await prepareUpload(files)
    if (!uploadFiles) return

    setUploadButtonDiasbled(true)
    setUploading(true)

    // ----- GA4 event -----
    window.gtag('event', 'non_member_removebg_upload', {})
    // ---------------------

    const formData = await makeUploadFormData(uploadFiles)
    const guestAccessToken = getGuestAccess()
    const headers = {
      Authorization: `Bearer ${guestAccessToken}`,
    }
    console.log('--------- start uploading')
    setUploading(true)

    apis.appfront
      .upload(formData, { headers })
      .then(response => {
        files.forEach(image => URL.revokeObjectURL(image.url))
        resetFiles()

        if (response.data.artwork_id) {
          refreshArtworks()
        } else {
          // 정상적으로 생성 요청이 진행되지 않은 경우
        }

        setUploading(false)
        setUploadButtonDiasbled(false)

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  const handleClick = () => {
    handleUpload(files)
  }

  if (!uploadButtonOnly) {
    return null
  } else {
    return (
      <UploadComponent
        handleClick={handleClick}
        uploading={uploading}
        uploadButtonDisabled={uploadButtonDisabled || nukkiUploadBlock}
        files={files}
        addTag={
          nukkiUploadBlock ? '' : files.length > 1 ? `-1 ${t('upload_header.credit')}` : 'FREE'
        }
        buttonText={
          nukkiUploadBlock ? t('upload_header_nukki_comment_b') : t('upload_header.bg_remove')
        }
      />
    )
  }
}

const MIN_BUNDLE = 2
const MAX_BUNDLE = 80

const BundleConfigComponent = ({ uploadButtonOnly = false }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const [refImage, serRefImage] = useState([])

  const isCancelled = useRef(false)

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])

  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [customTemplate, setCustomTemplate] = useRecoilState(customTemplateAtom)

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const user = useRecoilValue(userAtom)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const [modified, setModified] = useState(false)

  const [bundleCount, setBundleCount] = useState(MIN_BUNDLE)
  const countUp = () => {
    setBundleCount(prev => prev + 1)
  }
  const countDown = () => {
    if (bundleCount <= MIN_BUNDLE) return
    setBundleCount(prev => prev - 1)
  }

  useEffect(() => {
    return () => {
      setBundleCount(MIN_BUNDLE)
    }
  }, [])

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isFunFamePage = portfolioType === config.PORTFOLIO_TYPE_FAME
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isNormalPortfolio = portfolioType === config.PORTFOLIO_TYPE_NORMAL

  const isBgExpansion =
    portfolioConfig.flagBgExpansion || portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const {
    refreshArtworks,
    checkUserCredit,
    prepareUpload,
    makeUploadFormData,
    updatePortfolioDetail,
  } = usePortfolio()

  const handleUpload = async (filesObj, eventName = 'multipack_photo_upload') => {
    const files = filesObj.map(f => f.file)

    const addGenOption = {
      simple_bg_color_list: [[255, 255, 255]],
      theme_template: 'custom',
      theme_custom: `multiobject_${bundleCount}`,
    }

    const uploadFiles = await prepareUpload(files, config.PORTFOLIO_TYPE_BUNDLE)
    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)

    // ----- GA4 event -----
    triggerGA4UploadEvent({ count: bundleCount }, user, uploadFiles.length, eventName)
    // ---------------------

    const formData = await makeUploadFormData(uploadFiles, addGenOption)

    // const genOptions = JSON.parse(formData.get('gen_options'))

    // formData.delete('gen_options')

    // genOptions.simple_bg_color_list = [[255, 255, 255]]
    // genOptions.theme_template = 'custom'
    // genOptions.theme_custom = `multiobject_${bundleCount}`

    // formData.append('gen_options', JSON.stringify(genOptions))

    console.log('--------- start uploading')
    setUploading(true)
    apis.appfront
      .upload(formData)
      .then(() => {
        // console.log('revoke_6')
        checkUserCredit()
        if (!isCancelled.current) {
          setUploading(false)
          setUploadButtonDiasbled(false)

          files.forEach(image => URL.revokeObjectURL(image.url))
          refreshArtworks()
          resetFiles()
        }

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  const handleClick = () => {
    const eventName = isFunFamePage
      ? 'famous_person_upload'
      : isBundlePage
      ? 'multipack_photo_upload'
      : isBgExpansion
      ? 'ai_canvas_exp_upload'
      : isNormalPortfolio
      ? 'portfolio_upload'
      : 'upload'

    handleUpload(files, eventName)

    // handleClose()
  }

  if (!uploadButtonOnly) {
    return (
      <Stack>
        <CenterAlignStack sx={{ p: '3.2rem 3rem', boxShadow: '4px 4px 20px 0px #0000001A' }}>
          <Typography sx={{ fontWeight: 800 }}>{t('upload_header.bundle_title')}</Typography>
          <Typography sx={{ fontSize: '1.3rem' }}>
            <Korean>
              <span style={{ color: theme.palette.draph.newBlue, fontWeight: 600 }}>묶음 상품</span>
              의 개수를 입력해 주세요.
            </Korean>
            <English>
              Please enter the number of{' '}
              <span style={{ color: theme.palette.draph.newBlue, fontWeight: 600 }}>
                multipacks
              </span>
              .
            </English>
          </Typography>

          <Box display="flex" sx={{ borderRadius: '0.6rem 0.6rem 0.6rem 0.6rem', mt: '1.6rem' }}>
            <IconButton
              sx={{
                borderRadius: '0.6rem 0rem 0rem 0.6rem',
                background: theme.palette.common.black,
                color: theme.palette.common.white,
                width: '2.4rem',
                height: '2.4rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.8rem',
                // pl: '0.15rem',
                '&.Mui-disabled': {
                  background: '#BBB',
                },
                '&: hover': {
                  background: theme.palette.draph.blue,
                },
              }}
              onClick={countDown}
              disabled={bundleCount <= MIN_BUNDLE}
            >
              <MinusIcon
                color={theme.palette.common.white}
                style={{ width: '0.75rem', height: '0.75rem' }}
              />
            </IconButton>
            <Box
              sx={{
                borderRadius: '0',
                background: theme.palette.common.white,
                color: theme.palette.common.black,
                width: '3.2rem',
                height: '2.4rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.4rem',
                borderTop: '0.2px solid #BBB;',
                borderBottom: '0.2px solid #BBB;',
              }}
            >
              <TextField
                variant="standard"
                type="number"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*', // 숫자만 허용
                  style: {
                    WebkitAppearance: 'none',
                    margin: 0,
                  },
                }}
                value={bundleCount}
                onChange={e => {
                  const input = e.target.value

                  if (input.length < 3 && /^[0-9]*$/.test(input)) {
                    setBundleCount(parseInt(input) || input)
                  }
                  // if (input < MIN_BUNDLE || !e.target.value) {
                  //   setBundleCount(MIN_BUNDLE)
                  // } else {
                  //   const newValue = Math.min(Math.max(0, parseInt(input) || 0), 80)
                  //   setBundleCount(newValue)
                  // }
                }}
                onBlur={e => {
                  const input = e.target.value

                  if (Number(input) > MAX_BUNDLE) {
                    setBundleCount(MAX_BUNDLE)
                  }
                  if (Number(input) < MIN_BUNDLE) {
                    setBundleCount(MIN_BUNDLE)
                  }
                }}
                sx={{
                  width: '3.2rem',

                  '&:focus': {
                    background: theme.palette.common.white, // Prevent color change on focus
                  },

                  '& .MuiInputBase-root.MuiInput-root': {
                    fontSize: '1.6rem',
                    px: '0.67rem',
                    height: '2.4rem',
                  },

                  '& input': {
                    textAlign: 'center',
                  },
                }}
              />
            </Box>
            <IconButton
              sx={{
                borderRadius: '0rem 0.6rem 0.6rem 0rem',
                background: theme.palette.common.black,
                width: '2.4rem',
                height: '2.4rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&.Mui-disabled': {
                  background: '#BBB',
                },
                '&: hover': {
                  background: theme.palette.draph.blue,
                },
              }}
              onClick={countUp}
              disabled={bundleCount >= 80}
            >
              <PlusIcon
                color={theme.palette.common.white}
                style={{ width: '0.75rem', height: '0.75rem' }}
              />
            </IconButton>
          </Box>
        </CenterAlignStack>
        <UploadComponent
          handleClick={handleClick}
          uploading={uploading}
          uploadButtonDisabled={uploadButtonDisabled}
          files={files}
          buttonText={t('upload_header.bundle')}
        />
      </Stack>
    )
  } else {
    return (
      <UploadComponent
        handleClick={handleClick}
        uploading={uploading}
        uploadButtonDisabled={uploadButtonDisabled}
        files={files}
        buttonText={t('upload_header.bundle')}
      />
    )
  }
}

const BgExpansionConfigComponent = ({ uploadButtonOnly = false }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const isCancelled = useRef(false)

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])

  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const user = useRecoilValue(userAtom)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const [minSizeWarning, setMinSizeWarning] = useState(false)
  const [maxSizeWarning, setMaxSizeWarnig] = useState(false)
  const [modified, setModified] = useState(false)

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const {
    refreshArtworks,
    checkUserCredit,
    prepareUpload,
    makeUploadFormData,
    updatePortfolioDetail,
  } = usePortfolio()

  const outputSizeValid =
    portfolioConfig.outputSizeList[0] &&
    portfolioConfig.outputSizeList[0].w &&
    portfolioConfig.outputSizeList[0].h &&
    true

  useEffect(() => {
    if (portfolioConfig.outputSizeList.length < 1) {
      setPortfolioConfig(prev => {
        return { ...prev, outputSizeList: [{ w: null, h: null }] }
      })
    }
  }, [portfolioConfig])

  const handleUpload = async filesObj => {
    const files = filesObj.map(f => f.file)

    const addGenOption = {
      flag_gen_compo: false,
      flag_bg_expansion: true,
      flag_generate: true,
      output_size_list: portfolioConfig.outputSizeList.map(o => ({ output_w: o.w, output_h: o.h })),
    }

    const uploadFiles = await prepareUpload(files)
    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)

    // ----- GA4 event -----
    triggerGA4UploadEvent(
      {
        ...uploadConfig,
        ...addGenOption,
        output_size_w: addGenOption.output_size_list[0].output_w,
        output_size_h: addGenOption.output_size_list[0].output_h,
      },
      user,
      uploadFiles.length,
      'ai_canvas_exp_upload'
    )
    // ---------------------

    const formData = await makeUploadFormData(uploadFiles, addGenOption)

    console.log('--------- start uploading')
    setUploading(true)
    apis.appfront
      .upload(formData)
      .then(() => {
        // console.log('revoke_6')
        checkUserCredit()
        if (!isCancelled.current) {
          setUploading(false)
          setUploadButtonDiasbled(false)

          files.forEach(image => URL.revokeObjectURL(image.url))
          resetFiles()
          refreshArtworks()
        }

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  const handleClick = () => {
    handleUpload(files)

    // handleClose()
  }

  if (!uploadButtonOnly) {
    return (
      <Stack>
        <CenterAlignStack sx={{ p: '3.2rem 3rem', boxShadow: '4px 4px 20px 0px #0000001A' }}>
          <Typography sx={{ fontWeight: 800 }}>
            {t('upload_header.expansion_title')}{' '}
            <sup
              style={{
                color: theme.palette.error.main,
                fontWeight: 500,
                fontSize: '1.5rem',
                lineHeight: '1.5rem',
                verticalAlign: 'text-top',
              }}
            >
              &#42;
            </sup>
          </Typography>
          <Typography sx={{ fontSize: '1.3rem' }}>
            <Korean>
              <span style={{ color: theme.palette.draph.newBlue, fontWeight: 600 }}>
                직접 입력하여{' '}
              </span>
              원하시는 사이즈로 수정해 주세요.
            </Korean>
          </Typography>

          <Box sx={{ mt: '1.6rem' }}>
            {portfolioConfig.outputSizeList.length > 0 && (
              <OutputField
                config={portfolioConfig}
                setConfig={setPortfolioConfig}
                setMinSizeWarning={setMinSizeWarning}
                setMaxSizeWarning={setMaxSizeWarnig}
                index={0}
                list={portfolioConfig.outputSizeList[0]}
                openMarket={false}
                required={true}
                modified={modified}
                setModified={setModified}
                sx={{ width: '15.6rem' }}
              />
            )}
          </Box>
        </CenterAlignStack>
        <UploadComponent
          handleClick={handleClick}
          uploading={uploading}
          uploadButtonDisabled={uploadButtonDisabled || !outputSizeValid}
          files={files}
          buttonText={t('upload_header.bg_expansion')}
        />
      </Stack>
    )
  } else {
    return (
      <UploadComponent
        handleClick={handleClick}
        uploading={uploading}
        uploadButtonDisabled={uploadButtonDisabled || !outputSizeValid}
        files={files}
        buttonText={t('upload_header.bg_expansion')}
      />
    )
  }
}

const ModelBgConfigComponent = ({
  uploadButtonOnly = false,
  genderOff,
  ageOff,
  conceptOff,
  raceOff,
}) => {
  const theme = useTheme()

  const isCancelled = useRef(false)

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const isMobile = useMobileMediaQuery()

  const { t, i18n } = useTranslation()
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const user = useRecoilValue(userAtom)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const [concept, setConcept] = useState('')
  const [gender, setGender] = useState('female')
  const [age, setAge] = useState('adult')
  const [model, setModel] = useState(['auto'])

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const {
    refreshArtworks,
    checkUserCredit,
    prepareUpload,
    makeUploadFormData,
    updatePortfolioDetail,
  } = usePortfolio()

  const handleUpload = async filesObj => {
    const files = filesObj.map(f => f.file)

    const modelBgConfig = {
      flag_human_background: true,
      // SO_length_scale: soSize,

      // 나머지 설정 오버라이드용
      flag_generate: false,
      flag_complex_cmp: false,
      flag_simple_cmp: false,
      flag_white_cmp: false,
      is_male: gender === 'male',
      model_name: model,
    }

    const uploadFiles = await prepareUpload(files)
    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)

    // ----- GA4 event -----
    triggerGA4UploadEvent({}, user, uploadFiles.length, 'modelbg_upload')
    // ---------------------

    const formData = await makeUploadFormData(uploadFiles, modelBgConfig)

    console.log('--------- start uploading')
    setUploading(true)
    apis.appfront
      .upload(formData)
      .then(() => {
        // console.log('revoke_7')

        checkUserCredit()
        if (!isCancelled.current) {
          setUploading(false)
          setUploadButtonDiasbled(false)

          files.forEach(image => URL.revokeObjectURL(image.url))
          resetFiles()
          refreshArtworks()
        }

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  const handleClick = () => {
    handleUpload(files)

    // handleClose()
  }

  const genderList = [
    { label: t('button.female'), value: 'female' },
    { label: t('button.male'), value: 'male' },
  ]

  const ageList = [
    { label: t('button.bady'), value: 'bady' },
    { label: t('button.child'), value: 'child' },
    { label: t('button.adult'), value: 'adult' },
  ]

  const [isFocused, setIsFocused] = useState(false)

  const onFocus = () => {
    setIsFocused(true)
  }
  const onBlur = () => {
    setIsFocused(false)
  }

  const onChange = e => {
    setConcept(e.target.value)
  }

  const raceList = {
    male: [{ id: 'asian' }, { id: 'black' }, { id: 'caucasian' }, { id: 'hispanic' }],
    female: [{ id: 'asian' }, { id: 'black' }, { id: 'caucasian' }, { id: 'hispanic' }],
  }

  if (!uploadButtonOnly) {
    return (
      <Stack>
        <CenterAlignStack sx={{ p: '4.4rem 4rem', boxShadow: '4px 4px 20px 0px #0000001A' }}>
          <ModelCommonConfig
            gender={gender}
            setGender={setGender}
            model={model}
            setModel={setModel}
            age={age}
            setAge={setAge}
            multiple={true}
          />

          {/* <Box sx={{ display: 'flex', width: '100%', mt: '2.4rem' }}>
            <CenterAlignStack sx={{ width: '50%' }}>
              <CenterAlignStack
                className="model"
                sx={{
                  width: '100%',
                  position: 'relative',
                  alignItems: 'start',
                  gap: { lg: '3.3rem', xs: '2rem' },
                  flexDirection: { lg: 'column', xs: 'row' },
                  mt: { lg: 0, xs: '1.8rem' },
                }}
              >
                {!genderOff && (
                  <Stack
                    sx={{
                      gap: { lg: '0.8rem', xs: '0.6rem' },
                      justifyContent: { lg: 'start', xs: 'center' },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '1.4rem',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        textAlign: { lg: 'start', xs: 'center' },
                      }}
                    >
                      {t('mannequin.gender')}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
                      {genderList.map((g, i) => {
                        return (
                          <StyledToggleButton
                            key={g.value}
                            value={g.value}
                            selected={gender === g.value}
                            onChange={e => {
                              setGender(e.target.value)
                            }}
                            sx={{
                              minWidth: { lg: '8rem', xs: '5.2rem' },
                              height: '3.2rem',
                              fontSize: '1.4rem',

                              border: `2px solid`,
                              borderColor: `transparent`,
                              fontWeight: 600,
                              color: theme.palette.common.black,

                              '&.Mui-selected': {
                                boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                                color: theme.palette.draph.blue,
                                background: 'none',
                                borderColor: theme.palette.draph.blue,
                                outline: 'none',
                                fontWeight: 800,
                              },
                              ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                            }}
                          >
                            {g.label}
                          </StyledToggleButton>
                        )
                      })}
                    </Box>
                  </Stack>
                )}

                {!ageOff && (
                  <Stack sx={{ gap: '0.8rem' }}>
                    <Typography
                      sx={{
                        fontSize: '1.4rem',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        textAlign: { lg: 'start', xs: 'center' },
                      }}
                    >
                      {t('mannequin.age')}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
                      {ageList.map((a, i) => {
                        return (
                          <StyledToggleButton
                            key={a.value}
                            value={a.value}
                            selected={age === a.value}
                            onChange={e => {
                              setAge(e.target.value)
                            }}
                            sx={{
                              minWidth: { lg: '8rem', xs: '5.2rem' },
                              height: '3.2rem',
                              fontSize: '1.4rem',

                              border: `2px solid`,
                              borderColor: `transparent`,
                              fontWeight: 600,
                              color: theme.palette.common.black,

                              '&.Mui-selected': {
                                boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                                color: theme.palette.draph.blue,
                                background: 'none',
                                borderColor: theme.palette.draph.blue,
                                outline: 'none',
                                fontWeight: 800,
                              },
                              ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                            }}
                          >
                            {a.label}
                          </StyledToggleButton>
                        )
                      })}
                    </Box>
                  </Stack>
                )}
              </CenterAlignStack>

              {!conceptOff && (
                <CenterAlignStack
                  className="theme"
                  sx={{
                    width: '100%',
                    position: 'relative',
                    alignItems: 'start',
                    gap: '1.6rem',
                    mt: { lg: '3.6rem', xs: '2rem' },
                  }}
                >
                  <Stack sx={{ gap: '0.8rem', width: '100%' }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '1.4rem',
                        lineHeight: 'normal',
                        textAlign: { lg: 'start', xs: 'center' },
                      }}
                    >
                      {t('mannequin.background_config')}
                      <br />
                      <SpanDraphBlue> {t('mannequin.step_1_prompt_comment_a')}</SpanDraphBlue>
                      {t('mannequin.step_1_prompt_comment_b')}
                      <SpanDraphBlue> {t('mannequin.step_1_prompt_comment_c')}</SpanDraphBlue>
                    </Typography>

                    <CustomOutlinedInput
                      value={concept}
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      type="text"
                      inputProps={{ maxLength: 1000 }}
                      multiline
                      minRows={5}
                      startAdornment={
                        <div style={{ position: 'absolute', top: '2.2rem', left: '1.2rem' }}>
                          {!isFocused && !concept.length && (
                            <InputAdornment position="start">
                              <Box
                                sx={{
                                  backgroundColor: theme.palette.common.black,
                                  color: theme.palette.common.white,
                                  p: '0.3rem 0.6rem',
                                  fontSize: '1.2rem',
                                  fontWeight: 600,
                                  borderRadius: '6px',
                                  lineHeight: 'normal',
                                }}
                              >
                                {t('upload_header.custom_template')}
                              </Box>
                            </InputAdornment>
                          )}
                        </div>
                      }
                      sx={{
                        height: 'auto',
                        width: 'calc(100% - 3.9rem)',
                        '&.MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderWidth: '0.1rem',
                            borderColor: '#000000',
                            borderRadius: '0px',
                          },
                        },
                      }}
                    />
                  </Stack>
                </CenterAlignStack>
              )}
            </CenterAlignStack>

            <CenterAlignStack sx={{ width: '50%' }}>
              {!raceOff && (
                <Stack sx={{ gap: '0.8rem', width: '100%' }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '1.4rem',
                      lineHeight: 'normal',
                      textAlign: { lg: 'start', xs: 'center' },
                    }}
                  >
                    {t('upload_header.race')}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={{ lg: '1rem', xs: '0.7rem' }}
                    sx={{
                      '& .item': {
                        position: 'relative',
                        width: { lg: '8.8rem', xs: '7.4rem' },
                        height: { lg: '8.8rem', xs: '7.4rem' },
                        cursor: 'pointer',
                        '& .selected': {
                          border: theme => `3px solid ${theme.palette.draph.blue}`,
                          borderRadius: '1rem',
                        },
                      },
                    }}
                  >
                    {raceList.map(r => (
                      <Box
                        key={r.id}
                        value={r.id}
                        className={`item`}
                        onClick={e => {
                          setRace(r.id)
                        }}
                      >
                        <img src={`/static/images/model_example/${gender}_${r.id}.png`} />

                        <Box
                          className={`${r.id === race ? 'selected' : ''}`}
                          sx={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              )}
            </CenterAlignStack>
          </Box> */}
        </CenterAlignStack>
        <UploadComponent
          handleClick={handleClick}
          uploading={uploading}
          uploadButtonDisabled={uploadButtonDisabled}
          files={files}
          buttonText={t('upload_header.model')}
        />
      </Stack>
    )
  } else {
    return (
      <UploadComponent
        handleClick={handleClick}
        uploading={uploading}
        uploadButtonDisabled={uploadButtonDisabled}
        files={files}
        buttonText={t('upload_header.model')}
      />
    )
  }
}

const ModelFaceConfigComponent = ({
  uploadButtonOnly = false,
  genderOff,
  ageOff,
  conceptOff,
  raceOff,
}) => {
  const theme = useTheme()

  const isCancelled = useRef(false)

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const isMobile = useMobileMediaQuery()

  const { t, i18n } = useTranslation()

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)

  const { handleRedo, handleReset, handleUndo } = useFragment()

  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [index, SetIndex] = useRecoilState(indexAtom)

  const { resetAllState } = useResetNpy()

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)
  const [gender, setGender] = useState('female')
  const [model, setModel] = useState('auto')

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  const {
    refreshArtworks,
    checkUserCredit,
    prepareUpload,
    makeUploadFormData,
    updatePortfolioDetail,
  } = usePortfolio()

  const handleUpload = async filesObj => {
    const files = filesObj.map(f => f.file)

    setUploading(true)

    const mask = await imgMergeAndGrayScale(selectedMaskImg)
    // 여기 다시 짜야함
    const addFormData = {
      binary_mask: mask,
      is_male: gender === 'male',
      model_name: model,
    }

    const uploadFiles = await prepareUpload(files, config.PORTFOLIO_TYPE_FACE)
    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)

    // ----- GA4 event -----
    window.gtag('event', 'model_face_upload', {
      count: uploadFiles.length,
      method: `${gender}_${model}`,
    })
    // ---------------------
    const formData = await makeUploadFormData(uploadFiles, {}, addFormData, 'modelFace')

    console.log('--------- start uploading')
    setUploading(true)
    apis.appfront
      .genFace(formData)
      .then(() => {
        checkUserCredit()
        if (!isCancelled.current) {
          setUploading(false)
          setUploadButtonDiasbled(false)

          files.forEach(image => URL.revokeObjectURL(image.url))
          resetFiles()
          refreshArtworks()
        }

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
      .finally(() => {
        resetAllState()
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  const handleClick = () => {
    handleUpload(files)

    // handleClose()
  }

  const genderList = [
    { label: t('button.female'), value: 'female' },
    { label: t('button.male'), value: 'male' },
  ]

  const raceList = [{ id: 'asian' }, { id: 'black' }, { id: 'caucasian' }, { id: 'hispanic' }]

  if (!uploadButtonOnly) {
    return (
      <Stack>
        <Box
          sx={{
            p: '4.4rem 4rem',
            display: 'flex',
            width: '100%',
            boxShadow: '4px 4px 20px 0px #0000001A',
          }}
        >
          <CenterAlignStack sx={{ mr: '4rem' }}>
            <Stack sx={{ alignItems: 'center', gap: '0.2rem' }}>
              <Typography sx={{ fontWeight: 800, lineHeight: 'normal' }}>
                {t('regenerate_dialog.face_title')}{' '}
                <sup
                  style={{
                    color: theme.palette.error.main,
                    fontWeight: 500,
                    fontSize: '1.5rem',
                    lineHeight: '1.5rem',
                    verticalAlign: 'text-top',
                  }}
                >
                  &#42;
                </sup>
              </Typography>
              <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
                {t('regenerate_dialog.face_notice_1')}
              </Typography>
            </Stack>
            <CenterAlignStack
              sx={{
                width: '40rem',
                height: '34.4rem',
                backgroundColor: '#D9D9D9',
                justifyContent: 'center',
                mt: '1.8rem',
              }}
            >
              <Segment widthLimit={400} heightLimit={344} />
            </CenterAlignStack>
            <CenterAlignBox sx={{ gap: '1.2rem', mt: '1.6rem' }}>
              <Button
                variant="outlined"
                sx={{ ...roundButtonStyle, width: '9.2rem', height: '2.8rem' }}
                onClick={handleReset}
                disabled={segmentLoading}
              >
                <EraserIcon />
                {t('regenerate_dialog.bg_edit_reset')}
              </Button>

              <Button
                variant="outlined"
                sx={{ ...roundButtonStyle, width: '9.2rem', height: '2.8rem' }}
                onClick={handleUndo}
                disabled={index === -1 || segmentLoading}
              >
                <svg
                  width="1rem"
                  height="1rem"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 11L1 6L6 1"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 6H11.5C12.2223 6 12.9375 6.14226 13.6048 6.41866C14.272 6.69506 14.8784 7.10019 15.3891 7.61091C15.8998 8.12163 16.3049 8.72795 16.5813 9.39524C16.8577 10.0625 17 10.7777 17 11.5C17 12.2223 16.8577 12.9375 16.5813 13.6048C16.3049 14.272 15.8998 14.8784 15.3891 15.3891C14.8784 15.8998 14.272 16.3049 13.6048 16.5813C12.9375 16.8577 12.2223 17 11.5 17H8"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('regenerate_dialog.bg_edit_undo')}
              </Button>
              <Button
                variant="outlined"
                sx={{ ...roundButtonStyle, width: '9.2rem', height: '2.8rem' }}
                onClick={handleRedo}
                disabled={removeMaskImg.length === 0 || segmentLoading}
              >
                <svg
                  width="1rem"
                  height="1rem"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 11L17 6L12 1"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 6H6.5C5.04131 6 3.64236 6.57946 2.61091 7.61091C1.57946 8.64236 1 10.0413 1 11.5C1 12.2223 1.14226 12.9375 1.41866 13.6048C1.69506 14.272 2.10019 14.8784 2.61091 15.3891C3.64236 16.4205 5.04131 17 6.5 17H10"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('regenerate_dialog.bg_edit_redo')}
              </Button>
            </CenterAlignBox>
          </CenterAlignStack>

          <CenterAlignStack sx={{ flex: 1 }}>
            {/* <Stack sx={{ alignItems: 'center', gap: '0.2rem' }}>
              <Typography sx={{ fontWeight: 800, lineHeight: 'normal' }}>
                {t('upload_header.model_bg_title')}{' '}
                <sup
                  style={{
                    color: theme.palette.error.main,
                    fontWeight: 500,
                    fontSize: '1.5rem',
                    lineHeight: '1.5rem',
                    verticalAlign: 'text-top',
                  }}
                >
                  &#42;
                </sup>
              </Typography>
              <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
                {t('upload_header.model_notice_1')}
              </Typography>
            </Stack> */}

            <ModelCommonConfig
              gender={gender}
              setGender={setGender}
              model={model}
              setModel={setModel}
              // age={age}
              // setAge={setAge}
              // multiple={true}
            />
            {/* <CenterAlignStack
              className="model"
              sx={{
                width: '100%',
                position: 'relative',
                alignItems: 'center',
                gap: { lg: '3.3rem', xs: '2rem' },
                flexDirection: { lg: 'column', xs: 'row' },
                mt: { lg: '2.8rem', xs: '1.8rem' },
              }}
            >
              <Stack
                sx={{
                  gap: { lg: '0.8rem', xs: '0.6rem' },
                  justifyContent: { lg: 'center', xs: 'center' },
                  alignItems: { lg: 'center', xs: 'center' },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    textAlign: { lg: 'center', xs: 'center' },
                  }}
                >
                  {t('mannequin.gender')}
                </Typography>
                <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
                  {genderList.map((g, i) => {
                    return (
                      <StyledToggleButton
                        key={g.value}
                        value={g.value}
                        selected={gender === g.value}
                        onChange={e => {
                          setGender(e.target.value)
                        }}
                        sx={{
                          minWidth: { lg: '8rem', xs: '5.2rem' },
                          height: '3.2rem',
                          fontSize: '1.4rem',

                          border: `2px solid`,
                          borderColor: `transparent`,
                          fontWeight: 600,
                          color: theme.palette.common.black,

                          '&.Mui-selected': {
                            boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                            color: theme.palette.draph.blue,
                            background: 'none',
                            borderColor: theme.palette.draph.blue,
                            outline: 'none',
                            fontWeight: 800,
                          },
                          ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                        }}
                      >
                        {g.label}
                      </StyledToggleButton>
                    )
                  })}
                </Box>

                <Stack
                  sx={{
                    gap: { lg: '0.8rem', xs: '0.6rem' },
                    justifyContent: { lg: 'center', xs: 'center' },
                  }}
                >
                  <Typography className="config-title">{t('upload_header.race')}</Typography>
                  <Stack
                    direction="row"
                    spacing={{ lg: '1rem', xs: '1rem' }}
                    sx={{
                      '& .item': {
                        position: 'relative',
                        width: { lg: '9.2rem', xs: '10rem' },
                        height: { lg: '9.2rem', xs: '10rem' },
                        cursor: 'pointer',

                        '& .selected': {
                          // border: theme => `3px solid ${theme.palette.draph.blue}`,
                          borderRadius: '1rem',
                          opacity: 1,
                        },
                      },
                    }}
                  >
                    <Box
                      className={'item'}
                      onClick={e => {
                        setModel('auto')
                      }}
                      sx={{
                        borderRadius: '1rem',
                        border: model === 'auto' ? `1px solid transparent` : `1px solid #D9D9D9`,
                        transition: 'border 0.2s ease-in',
                      }}
                    >
                      <AutoThumb theme={theme} selected={model.includes('auto')} />
                      <Box
                        className={`${model === 'auto' ? 'selected' : ''}`}
                        sx={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: 0,
                          borderRadius: '10px',
                          border: theme => `3px solid ${theme.palette.draph.blue}`,
                          opacity: 0,
                          transition: 'opacity 0.2s ease-in',
                        }}
                      />
                    </Box>

                    {config.MODEL[gender].map(r => (
                      <Box
                        key={r.id}
                        value={r.id}
                        className={`item`}
                        onClick={e => {
                          setModel(r.id)
                        }}
                      >
                        <img
                          className={`item`}
                          style={{ borderRadius: '10px' }}
                          src={`/static/images/model_example/${gender}_${r.id}.png`}
                        />

                        <Box
                          className={`${r.id === model ? 'selected' : ''}`}
                          sx={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            borderRadius: '1rem',
                            border: theme => `3px solid ${theme.palette.draph.blue}`,
                            opacity: 0,
                            transition: 'opacity 0.3s ease-in',
                          }}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </CenterAlignStack> */}
          </CenterAlignStack>
        </Box>
        {/* <CenterAlignStack sx={{ p: '4.4rem 4rem', boxShadow: '4px 4px 20px 0px #0000001A' }}>
         <Typography sx={{ fontWeight: 800 }}>
            {t('upload_header.model_bg_title')}{' '}
            <sup
              style={{
                color: theme.palette.error.main,
                fontWeight: 500,
                fontSize: '1.5rem',
                lineHeight: '1.5rem',
                verticalAlign: 'text-top',
              }}
            >
              &#42;
            </sup>
          </Typography>
          <Typography sx={{ fontSize: '1.3rem', color: '#505050' }}>
            <Korean>입력하신 정보를 반영해 모델이 생성됩니다.</Korean>
          </Typography>

        <CenterAlignStack sx={{ display: 'flex', width: '100%', mt: '2.4rem' }}>
            {!genderOff && (
              <CenterAlignStack
                sx={{
                  gap: { lg: '0.8rem', xs: '0.6rem' },
                  justifyContent: { lg: 'start', xs: 'center' },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    textAlign: { lg: 'start', xs: 'center' },
                  }}
                >
                  {t('mannequin.gender')}
                </Typography>
                <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
                  {genderList.map((g, i) => {
                    return (
                      <StyledToggleButton
                        key={g.value}
                        value={g.value}
                        selected={gender === g.value}
                        onChange={e => {
                          setGender(e.target.value)
                        }}
                        sx={{
                          minWidth: { lg: '8rem', xs: '5.2rem' },
                          height: '3.2rem',
                          fontSize: '1.4rem',

                          border: `2px solid`,
                          borderColor: `transparent`,
                          fontWeight: 600,
                          color: theme.palette.common.black,

                          '&.Mui-selected': {
                            boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                            color: theme.palette.draph.blue,
                            background: 'none',
                            borderColor: theme.palette.draph.blue,
                            outline: 'none',
                            fontWeight: 800,
                          },
                          ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                        }}
                      >
                        {g.label}
                      </StyledToggleButton>
                    )
                  })}
                </Box>
              </CenterAlignStack>
            )}

            {!raceOff && (
              <CenterAlignStack sx={{ gap: '0.8rem', mt: '3.2rem' }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    lineHeight: 'normal',
                    textAlign: { lg: 'start', xs: 'center' },
                  }}
                >
                  {t('upload_header.race')}
                </Typography>
                <Stack
                  direction="row"
                  spacing={{ lg: '1rem', xs: '0.7rem' }}
                  sx={{
                    '& .item': {
                      position: 'relative',
                      width: { lg: '8.8rem', xs: '7.4rem' },
                      height: { lg: '8.8rem', xs: '7.4rem' },
                      cursor: 'pointer',
                      '& .selected': {
                        border: theme => `3px solid ${theme.palette.draph.blue}`,
                        borderRadius: '1rem',
                      },
                    },
                  }}
                >
                  {raceList.map(r => (
                    <Box
                      key={r.id}
                      value={r.id}
                      className={`item`}
                      onClick={e => {
                        setRace(r.id)
                      }}
                    >
                      <img src={`/static/images/model_example/${gender}_${r.id}.png`} />

                      <Box
                        className={`${r.id === race ? 'selected' : ''}`}
                        sx={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}
                      />
                    </Box>
                  ))}
                </Stack>
              </CenterAlignStack>
            )}
          </CenterAlignStack> 
        </CenterAlignStack> */}
        <UploadComponent
          handleClick={handleClick}
          uploading={uploading}
          uploadButtonDisabled={uploadButtonDisabled || !selectedMaskImg.length || segmentLoading}
          files={files}
          buttonText={t('upload_header.model')}
        />
      </Stack>
    )
  } else {
    return (
      <UploadComponent
        handleClick={handleClick}
        uploading={uploading}
        uploadButtonDisabled={uploadButtonDisabled || !selectedMaskImg.length || segmentLoading}
        files={files}
        buttonText={t('upload_header.model')}
      />
    )
  }
}

const MannequinConfigComponent = ({ uploadButtonOnly = false }) => {
  const theme = useTheme()

  const isCancelled = useRef(false)

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const isMobile = useMobileMediaQuery()

  const { t, i18n } = useTranslation()

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)

  const { handleRedo, handleReset, handleUndo } = useFragment()

  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [index, SetIndex] = useRecoilState(indexAtom)

  const { resetAllState } = useResetNpy()

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)

  const [concept, setConcept] = useState('')
  const [gender, setGender] = useState('female')
  const [age, setAge] = useState('adult')
  const [race, setRace] = useState('asian')

  const f1 = useRecoilValue(f1Atom)
  const f2 = useRecoilValue(f2Atom)

  const genderList = [
    { label: t('button.female'), value: 'female' },
    { label: t('button.male'), value: 'male' },
  ]

  const ageList = [
    { label: t('button.bady'), value: 'bady' },
    { label: t('button.child'), value: 'child' },
    { label: t('button.adult'), value: 'adult' },
  ]

  const [isFocused, setIsFocused] = useState(false)

  const onFocus = () => {
    setIsFocused(true)
  }
  const onBlur = () => {
    setIsFocused(false)
  }

  const onChange = e => {
    setConcept(e.target.value)
  }

  const raceList = [{ id: 'asian' }, { id: 'black' }, { id: 'caucasian' }, { id: 'hispanic' }]

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()
  const { showConfirm } = useConfirm()

  const {
    refreshArtworks,
    checkUserCredit,
    prepareUpload,
    makeUploadFormData,
    updatePortfolioDetail,
  } = usePortfolio()

  const handleUpload = async filesObj => {
    const files = filesObj.map(f => f.file)

    setUploading(true)

    const mask = await imgMergeAndGrayScale(selectedMaskImg)

    const ready = await prepareUpload([{}], null, null, false, true)
    if (!ready.success) return
    setUploadButtonDiasbled(true)

    const getTypes = () => {
      if (f1.length) return 'mann'
      if (f2.length) return 'human'
    }

    const addFormData = {
      binary_mask: mask,
      is_male: gender === 'male',
      mann2man_type: getTypes(),
      prompt: concept,
      age,
    }

    // ----- GA4 event -----
    window.gtag(
      'event',
      'mannequin_complete',

      {
        menu: gender === 'male' ? '남' : '여', // 성별
        method: age === 'baby' ? '아기' : age === 'child' ? '어린이' : '성인', // 연령대
        theme: concept.length > 0, // 배경 테마가 공란인지(false) 입력값있는지(true)
      }
    )
    // ---------------------

    const formData = await makeUploadFormData(files, {}, addFormData, 'mannequin')

    console.log('--------- start uploading')

    try {
      await apis.appfront.getMann2man(formData)

      if ('Notification' in window && Notification.permission === 'default') {
        showBrowserNotificationDialog()
      }
      // console.log('revoke_8')

      checkUserCredit()
      if (!isCancelled.current) {
        setUploading(false)
        setUploadButtonDiasbled(false)

        files.forEach(image => URL.revokeObjectURL(image.url))
        resetFiles()
        refreshArtworks()
      }

      resetAllState()
    } catch (error) {
      // Show a warning dialog if an error occurs
      showConfirm({
        content: t('upload_dialog.warning'),
        alertOnly: true,
      })
    } finally {
      // Always stop the loading animation, whether an error occurred or not
      setUploading(false)
      setUploadButtonDiasbled(false)
    }
  }

  const handleClick = () => {
    handleUpload(files)

    // handleClose()
  }

  if (!uploadButtonOnly) {
    return (
      <Stack>
        <Box
          sx={{
            p: '4.4rem 4rem',
            display: 'flex',
            width: '100%',
            boxShadow: '4px 4px 20px 0px #0000001A',
          }}
        >
          <CenterAlignStack sx={{ mr: '4rem' }}>
            <Stack sx={{ alignItems: 'center', gap: '0.2rem' }}>
              <Typography sx={{ fontWeight: 800, lineHeight: 'normal' }}>
                {t('mannequin.step_1_title')}{' '}
                <sup
                  style={{
                    color: theme.palette.error.main,
                    fontWeight: 500,
                    fontSize: '1.5rem',
                    lineHeight: '1.5rem',
                    verticalAlign: 'text-top',
                  }}
                >
                  &#42;
                </sup>
              </Typography>
              <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
                {t('mannequin.step_1_comment')}
              </Typography>
            </Stack>
            <CenterAlignStack
              sx={{
                width: '40rem',
                height: '34.4rem',
                backgroundColor: '#D9D9D9',
                justifyContent: 'center',
                mt: '1.8rem',
              }}
            >
              <Segment widthLimit={400} heightLimit={344} />
            </CenterAlignStack>
            <CenterAlignBox sx={{ gap: '1.2rem', mt: '1.6rem' }}>
              <Button
                variant="outlined"
                sx={{ ...roundButtonStyle, width: '9.2rem', height: '2.8rem' }}
                onClick={handleReset}
                disabled={segmentLoading}
              >
                <EraserIcon />
                {t('regenerate_dialog.bg_edit_reset')}
              </Button>

              <Button
                variant="outlined"
                sx={{ ...roundButtonStyle, width: '9.2rem', height: '2.8rem' }}
                onClick={handleUndo}
                disabled={index === -1 || segmentLoading}
              >
                <svg
                  width="1rem"
                  height="1rem"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 11L1 6L6 1"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 6H11.5C12.2223 6 12.9375 6.14226 13.6048 6.41866C14.272 6.69506 14.8784 7.10019 15.3891 7.61091C15.8998 8.12163 16.3049 8.72795 16.5813 9.39524C16.8577 10.0625 17 10.7777 17 11.5C17 12.2223 16.8577 12.9375 16.5813 13.6048C16.3049 14.272 15.8998 14.8784 15.3891 15.3891C14.8784 15.8998 14.272 16.3049 13.6048 16.5813C12.9375 16.8577 12.2223 17 11.5 17H8"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('regenerate_dialog.bg_edit_undo')}
              </Button>
              <Button
                variant="outlined"
                sx={{ ...roundButtonStyle, width: '9.2rem', height: '2.8rem' }}
                onClick={handleRedo}
                disabled={removeMaskImg.length === 0 || segmentLoading}
              >
                <svg
                  width="1rem"
                  height="1rem"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 11L17 6L12 1"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17 6H6.5C5.04131 6 3.64236 6.57946 2.61091 7.61091C1.57946 8.64236 1 10.0413 1 11.5C1 12.2223 1.14226 12.9375 1.41866 13.6048C1.69506 14.272 2.10019 14.8784 2.61091 15.3891C3.64236 16.4205 5.04131 17 6.5 17H10"
                    stroke="#303030"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                {t('regenerate_dialog.bg_edit_redo')}
              </Button>
            </CenterAlignBox>
          </CenterAlignStack>

          <MannequinConfig
            gender={gender}
            setGender={setGender}
            age={age}
            setAge={setAge}
            prompt={concept}
            setPrompt={setConcept}
            wide={true}
          />

          {/* <CenterAlignStack sx={{}}>
            <Stack sx={{ alignItems: 'center', gap: '0.2rem' }}>
              <Typography sx={{ fontWeight: 800, lineHeight: 'normal' }}>
                {t('upload_header.model_bg_title')}{' '}
                <sup
                  style={{
                    color: theme.palette.error.main,
                    fontWeight: 500,
                    fontSize: '1.5rem',
                    lineHeight: '1.5rem',
                    verticalAlign: 'text-top',
                  }}
                >
                  &#42;
                </sup>
              </Typography>
              <Typography sx={{ fontSize: '1.3rem', color: '#505050', lineHeight: 'normal' }}>
                {t('upload_header.model_notice_1')}
              </Typography>
            </Stack>
            <CenterAlignStack
              className="model"
              sx={{
                width: '100%',
                position: 'relative',
                alignItems: 'start',
                gap: { lg: '3.3rem', xs: '2rem' },
                flexDirection: { lg: 'column', xs: 'row' },
                mt: { lg: '2.8rem', xs: '1.8rem' },
              }}
            >
              <Stack
                sx={{
                  gap: { lg: '0.8rem', xs: '0.6rem' },
                  justifyContent: { lg: 'start', xs: 'center' },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    textAlign: { lg: 'start', xs: 'center' },
                  }}
                >
                  {t('mannequin.gender')}
                </Typography>
                <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
                  {genderList.map((g, i) => {
                    return (
                      <StyledToggleButton
                        key={g.value}
                        value={g.value}
                        selected={gender === g.value}
                        onChange={e => {
                          setGender(e.target.value)
                        }}
                        sx={{
                          minWidth: { lg: '8rem', xs: '5.2rem' },
                          height: '3.2rem',
                          fontSize: '1.4rem',

                          border: `2px solid`,
                          borderColor: `transparent`,
                          fontWeight: 600,
                          color: theme.palette.common.black,

                          '&.Mui-selected': {
                            boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                            color: theme.palette.draph.blue,
                            background: 'none',
                            borderColor: theme.palette.draph.blue,
                            outline: 'none',
                            fontWeight: 800,
                          },
                          ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                        }}
                      >
                        {g.label}
                      </StyledToggleButton>
                    )
                  })}
                </Box>
              </Stack>

              <Stack sx={{ gap: '0.8rem' }}>
                <Typography
                  sx={{
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    textAlign: { lg: 'start', xs: 'center' },
                  }}
                >
                  {t('mannequin.age')}
                </Typography>
                <Box sx={{ display: 'flex', gap: { lg: '1.2rem', xs: '0.6rem' } }}>
                  {ageList.map((a, i) => {
                    return (
                      <StyledToggleButton
                        key={a.value}
                        value={a.value}
                        selected={age === a.value}
                        onChange={e => {
                          setAge(e.target.value)
                        }}
                        sx={{
                          minWidth: { lg: '8rem', xs: '5.2rem' },
                          height: '3.2rem',
                          fontSize: '1.4rem',

                          border: `2px solid`,
                          borderColor: `transparent`,
                          fontWeight: 600,
                          color: theme.palette.common.black,

                          '&.Mui-selected': {
                            boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.2)',
                            color: theme.palette.draph.blue,
                            background: 'none',
                            borderColor: theme.palette.draph.blue,
                            outline: 'none',
                            fontWeight: 800,
                          },
                          ...(isMobile && { borderRadius: '10px', px: '1rem' }),
                        }}
                      >
                        {a.label}
                      </StyledToggleButton>
                    )
                  })}
                </Box>
              </Stack>
            </CenterAlignStack>
            <CenterAlignStack
              className="theme"
              sx={{
                width: '100%',
                position: 'relative',
                alignItems: 'start',
                gap: '1.6rem',
                mt: { lg: '3.6rem', xs: '2rem' },
              }}
            >
              <Stack sx={{ gap: '0.8rem', width: '100%' }}>
                <Stack sx={{ gap: '0.2rem' }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '1.4rem',
                      lineHeight: 'normal',
                      textAlign: { lg: 'start', xs: 'center' },
                    }}
                  >
                    {t('mannequin.background_config')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1.4rem',
                      lineHeight: '1.2',
                      textAlign: { lg: 'start', xs: 'center' },
                    }}
                  >
                    <SpanDraphBlue style={{ fontWeight: 600 }}>
                      {' '}
                      {t('mannequin.step_1_prompt_comment_a')}
                    </SpanDraphBlue>
                    {t('mannequin.step_1_prompt_comment_b')}
                    <SpanDraphBlue> {t('mannequin.step_1_prompt_comment_c')}</SpanDraphBlue>
                  </Typography>
                </Stack>

                <CustomOutlinedInput
                  value={concept}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  type="text"
                  inputProps={{ maxLength: 1000 }}
                  multiline
                  minRows={5}
                  startAdornment={
                    <div style={{ position: 'absolute', top: '2.2rem', left: '1.2rem' }}>
                      {!isFocused && !concept.length && (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              backgroundColor: theme.palette.common.black,
                              color: theme.palette.common.white,
                              p: '0.3rem 0.6rem',
                              fontSize: '1.2rem',
                              fontWeight: 600,
                              borderRadius: '6px',
                              lineHeight: 'normal',
                            }}
                          >
                            {t('upload_header.custom_template')}
                          </Box>
                        </InputAdornment>
                      )}
                    </div>
                  }
                  sx={{
                    height: 'auto',
                    lineHeight: '1.6',
                    width: '34.8rem',
                    p: '1rem 1.4rem',
                    '&.MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderWidth: '0.1rem',
                        borderColor: '#000000',
                        borderRadius: '0px',
                      },
                    },
                  }}
                />
              </Stack>
            </CenterAlignStack>
          </CenterAlignStack> */}
        </Box>
        <UploadComponent
          handleClick={handleClick}
          uploading={uploading}
          uploadButtonDisabled={uploadButtonDisabled || !selectedMaskImg.length || segmentLoading}
          files={files}
          buttonText={t('upload_header.model')}
        />
      </Stack>
    )
  } else {
    return (
      <UploadComponent
        handleClick={handleClick}
        uploading={uploading}
        uploadButtonDisabled={uploadButtonDisabled || !selectedMaskImg.length || segmentLoading}
        files={files}
        buttonText={t('upload_header.model')}
      />
    )
  }
}

const BannerConfigComponent = ({ uploadButtonOnly = false }) => {
  const theme = useTheme()
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const isCancelled = useRef(false)

  useEffect(() => {
    isCancelled.current = false
    return () => {
      isCancelled.current = true
    }
  }, [])
  const isMobile = useMobileMediaQuery()
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)

  const { t, i18n } = useTranslation()

  const [uploading, setUploading] = useRecoilState(uploadLoadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)

  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [bannerMannInfo, setBannerMannInfo] = useRecoilState(bannerMannInfoAtom)

  const [brandConfig, setBrandConfig] = useRecoilState(brandConfigUpdateAtom)
  const [brandReadOnlyConfig, setBrandReadOnlyConfig] = useRecoilState(brandReadOnlyConfigAtom)

  const { logoSaveApi } = useBanner()
  const disabledButton = !(
    bannerConfig.productName &&
    bannerConfig.productFeature &&
    bannerConfig.productCategory &&
    bannerConfig.outputW &&
    bannerConfig.outputH
  )

  const { handleRedo, handleReset, handleUndo } = useFragment()

  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [index, SetIndex] = useRecoilState(indexAtom)

  const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)
  const resetRemoveMaskImg = useResetRecoilState(removeMaskImgAtom)
  const resetSegmentLoading = useResetRecoilState(segmentLoadingAtom)
  const resetIndex = useResetRecoilState(indexAtom)
  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)
  const resetBannerMannInfo = useResetRecoilState(bannerMannInfoAtom)
  const resetMajorPage = useResetRecoilState(bannerMajorPageAtom)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()
  const { showConfirm } = useConfirm()

  const {
    refreshArtworks,
    checkUserCredit,
    prepareUpload,
    makeUploadFormData,
    updatePortfolioDetail,
  } = usePortfolio()

  const handleUpload = async filesObj => {
    const files = filesObj.map(f => f.file)

    const body = { config: bannerConfig }

    apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
      setPortfolioDetail({ ...portfolioDetail, ...response.data })
    })

    const uploadFiles = await prepareUpload(files)

    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)
    setUploading(true)

    const option = {
      ...BANNER_ARTWORK_CONFIG,
      logoS3path: brandConfig.logoS3path,
      humanDepict: bannerConfig.humanDepict,
      bgDepict: bannerConfig.bgDepict,
      brandFeature: brandConfig.brandFeature,
      productFeature: bannerConfig.productFeature,
    }

    const addForm = {
      ...(bannerConfig.genType === 'mann2man' && bannerMannInfo),
    }

    const formData = await makeUploadFormData(uploadFiles, option, addForm, 'banner')

    // ----- GA4 event -----
    const eventParams = {
      output_size_width: bannerConfig?.outputW,
      output_size_height: bannerConfig?.outputH,
      config_object_category: bannerConfig?.productCategory ? 'yes' : 'no',
      config_object_sub_category:
        bannerConfig?.genType === 'full'
          ? 'no'
          : bannerConfig?.genType === 'mann2man'
          ? 'wearing'
          : bannerConfig?.genType === 'side'
          ? 'model'
          : '',

      menu: bannerMannInfo.mann2man_is_male ? 'male' : 'female',
      method: bannerMannInfo.mann2man_age,

      count: 1,
    }

    window.gtag('event', 'banner_upload', eventParams)
    // ---------------------

    apis.appfront
      .makeBanner(formData)
      .then(() => {
        // console.log('revoke_9')
        checkUserCredit()

        if (!isCancelled.current) {
          setUploading(false)
          setUploadButtonDiasbled(false)
          files.forEach(image => URL.revokeObjectURL(image.url))
          resetFiles()
          refreshArtworks()

          resetSelectedMaskImg()
          resetRemoveMaskImg()
          resetSegmentLoading()
          resetIndex()
          resetBannerMannInfo()
          resetMajorPage()
        }

        // if (page === 1) {
        //   const anchor = document.querySelector(`#scroll-anchor`)

        //   if (anchor) {
        //     anchor.scrollIntoView({
        //       block: 'start',
        //       behavior: 'smooth',
        //     })
        //   }
        // }
      })
      .catch(() => {
        showConfirm({
          content: t('upload_dialog.warning'),
          alertOnly: true,
        })
      })
      .finally(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  const handleClick = () => {
    handleUpload(files)

    // handleClose()
  }

  if (!uploadButtonOnly) {
    return (
      <Stack>
        {/* 배너는 안에 버튼을 따로 넣어야해서 여기서 실행하지 않음 */}
        <Box
          sx={{
            p: '4.4rem 0 0 0',
            display: 'flex',
            width: '100%',
            boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.1)',
            justifyContent: 'center',
            borderRadius: '0 0 10px 10px',
          }}
        >
          <BannerConfigPage
            uploadButton={
              <UploadComponent
                handleClick={handleClick}
                uploading={uploading}
                uploadButtonDisabled={uploadButtonDisabled || disabledButton}
                files={files}
                buttonText={t('upload_header.upload')}
                filesNotRequired={bannerConfig.genType === 'no_SO'}
                sx={{ borderRadius: '0 0 10px 0' }}
              />
            }
          />
        </Box>
      </Stack>
    )
  } else {
    return (
      <UploadComponent
        handleClick={handleClick}
        uploading={uploading}
        uploadButtonDisabled={uploadButtonDisabled}
        files={files}
        buttonText={t('upload_header.model')}
      />
    )
  }
}

const BulkUploadComponent = () => {
  const { showConfirm } = useConfirm()
  const { t } = useTranslation()
  const uuid = Math.ceil(Math.random() * 1000)
  const inputRef = useRef(null)
  const theme = useTheme()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [files, setFiles] = useRecoilState(bulkUploadFileAtom)
  const currentMenu = useRecoilValue(currentMenuAtom)
  const user = useRecoilValue(userAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const navigate = useNavigate()

  const [bulkLoading, setBulkLoading] = useState(false)
  const resetFiles = useResetRecoilState(bulkUploadFileAtom)

  useEffect(() => {
    return () => {
      if (inputRef && inputRef.current) inputRef.current.value = null
    }
  }, [])

  const handleChange = async function (e) {
    e.preventDefault()

    if (e.target.files) {
      const uploadFiles = Array.prototype.slice.call(e.target.files, 0, 1)

      const allowedTypes = ['application/zip', 'application/x-zip-compressed']
      const zipfiles = uploadFiles.filter(f => allowedTypes.includes(f.type)).slice(0, 1)
      const otherfiles = uploadFiles.filter(f => !allowedTypes.includes(f.type))

      if (otherfiles.length) {
        showConfirm({ content: '확장자(.zip)을 확인해주세요 !', alertOnly: true })
      }
      if (zipfiles.length) {
        setFiles(zipfiles)
      }
      e.target.value = ''
    }
  }

  const bulkUploadImage = async () => {
    setBulkLoading(true)
    const params = {}
    const uploadGroupUid = getUploadUUID()

    let conf = {
      gen_options: { ...uploadConfig, upload_id: uploadGroupUid },
      username: user.username,
    }

    apis.user.getPresignedUrl().then(r => {
      const url = r.data.presigned_url
      const path = r.data.path

      params.s3_path = path
      params.period = 1
      params.rate = 3

      axios
        .put(url, files[0], {
          headers: {
            'Content-Type': 'application/zip',
          },
        })
        .then(() => {
          const today = moment(new Date()).format('YYYY.MM.DD')

          const pattern = new RegExp(`${today} 대용량_(\\d+)`)
          const numbers = userPortfolioNames
            .filter(name => pattern.test(name))
            .map(name => parseInt(name.match(pattern)[1]))

          const maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0

          const nameCopy = `${today} 대용량_${maxNumber + 1}`
          const configCopy = {
            ...portfolioDetail.config,
            name: nameCopy,
            from_zip: true,
            zip_path: path,
            zip_file_total: null,
            zip_artwork_count: 0,
            zip_fail_count: 0,
          }
          const themeCopy = portfolioDetail.theme

          const body = {
            name: nameCopy,
            theme: themeCopy,
            config: configCopy,
          }

          const token = getAccessToken()

          apis.portfolio
            .createPortfoilo(body)
            .then(async response => {
              const newPortfolioId = response.data.id
              conf = {
                ...conf,
                request: '',
                images: [],
                background_tasks: '',
                token,
                user_id: user.id,
                webhook_url: '',
                portfolio_id: newPortfolioId.toString(),
                use_credit_type: '',
                options: {},
                gen_type: 'initial',
              }

              params.conf = JSON.stringify(conf)

              await apis.portfolio.setUploadProgress(newPortfolioId, {
                upload_id: uploadGroupUid,
                upload_type: 'zip',
                config: { zip_s3_path: params.s3_path, original_filename: files[0].name },
              })

              axios
                .post(
                  isDev
                    ? 'https://ftp-dev.draph.art/upload_zip/'
                    : 'https://ftp.draph.art/upload_zip/',
                  null,
                  { params }
                )
                .then(() => {
                  resetFiles()
                  navigate(`/portfolio/${newPortfolioId}?pages=1`)

                  // ----- GA4 event -----
                  window.gtag('event', 'bulk_mail_popup_imp', {
                    menu: currentMenu?.id,
                  })
                  // ---------------------

                  const emailSnackbar = enqueueSnackbar(
                    <>
                      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                        <Typography
                          sx={{
                            fontSize: { lg: '1.6rem', xs: '' },
                            fontWeight: 600,
                            color: 'white',
                            '& span': { color: theme => theme.palette.draph.green },
                          }}
                        >
                          <Korean>
                            모든 생성이 완료되면 <span> 알림 메일</span>을 드립니다.
                          </Korean>
                          <English>
                            Once all generation is complete, <span>we’ll notify you by email</span>.
                          </English>
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: { lg: '1.6rem', xs: '1.4rem' },
                            fontWeight: 300,
                            color: 'white',
                            cursor: 'pointer',
                            ml: { lg: '4.8rem', xs: '2rem' },
                          }}
                          onClick={() => {
                            apis.portfolio.deleteUploadProgress(newPortfolioId, uploadGroupUid)
                            closeSnackbar(emailSnackbar)

                            // ----- GA4 event -----
                            window.gtag('event', 'bulk_mail_popup_click', {
                              menu: currentMenu?.id,
                            })
                            // ---------------------
                          }}
                        >
                          <Korean>거절</Korean>
                          <English>Decline</English>
                        </Typography>
                      </Stack>
                    </>,
                    {
                      variant: 'alert',
                      autoHideDuration: 5000,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    }
                  )
                })
                .catch(() => {
                  apis.portfolio.deleteUploadProgress(newPortfolioId, uploadGroupUid)
                })
                .finally(() => {
                  setBulkLoading(false)
                })
            })
            .catch(error => {
              console.log(error)
              setBulkLoading(false)
            })
        })
        .catch(err => {
          console.log(err)
          setBulkLoading(false)
        })
    })
  }

  return (
    <CenterAlignStack>
      <CenterAlignStack
        sx={{
          p: files.length ? '4.4rem 4rem' : '3.1rem 3.8rem 2.6rem 3.8rem',
          backgroundColor: theme.palette.common.white,
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',

          boxShadow: ' 4px 4px 20px 0px #0000001A',
          alignItems: 'center',
          width: '48rem',
          transition: 'width 0.5s ease-in-out',
          zIndex: 1,
        }}
      >
        <OpacityMotionDiv style={{}}>
          {files.length === 0 && (
            <>
              <form
                className="form-file-upload"
                id={`form-file-upload-${uuid}`}
                // onDragEnter={handleDrag}
                onSubmit={e => e.preventDefault()}
                style={{
                  width: '40.4rem',
                  height: '9.2rem',
                }}
              >
                <input
                  ref={inputRef}
                  type="file"
                  className="input-file-upload"
                  id={`input-file-upload-${uuid}`}
                  accept={''}
                  multiple={false}
                  onChange={handleChange}
                />
                <label id="label-file-upload" htmlFor={`input-file-upload-${uuid}`} className={''}>
                  <div>
                    <UploadIcon2 />
                  </div>
                </label>
              </form>
              <Typography sx={{ mt: '1.2rem', fontWeight: 600, textAlign: 'center' }}>
                {t('upload_header.zip_notice_1')}
              </Typography>
            </>
          )}

          {files.length > 0 && (
            <Stack
              sx={{
                position: 'relative',

                width: '12rem',
                height: '12rem',
                borderRadius: '10px',
                alignItems: 'center',
                pt: '3.3rem',
                px: '3.9rem',
                backgroundColor: '#E3ECFF',
              }}
            >
              <ZipIcon />
              <Box sx={{ mt: '2.1rem', display: 'flex' }}>
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 600,
                    width: '5.5rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {files[0].name}
                </Typography>
                <Typography sx={{ fontSize: '1rem', fontWeight: 600 }}> .zip</Typography>
              </Box>

              <IconButton
                onClick={() => {
                  if (setFiles.length !== 1) return
                  setFiles([])
                }}
                sx={{ position: 'absolute', top: '-1.4rem', right: '-1.5rem' }}
              >
                <CloseBlackIcon />
              </IconButton>
            </Stack>
          )}
        </OpacityMotionDiv>
      </CenterAlignStack>
      <CreateButton disabled={!files.length || bulkLoading} onClick={bulkUploadImage}>
        {bulkLoading ? (
          <Loader />
        ) : (
          <Box
            className="content"
            sx={{
              display: 'flex',
              alignItems: 'center',
              transition: 'transform 0.3s ease-in-out', // transition 추가
            }}
          >
            <UploadIcon sx={{ marginRight: '0.5rem' }} />
            {t('bulk_upload')}
          </Box>
        )}
      </CreateButton>
    </CenterAlignStack>
  )
}

const CommerceButtonComponent = () => {
  const user = useRecoilValue(userAtom)

  const isNCommerceUser = user.login_sns === 'naver_commerce'
  const isNHNCommerceUser = user.login_sns === 'nhn_commerce'
  const isCafe24CommerceUser = user.login_sns === 'cafe24_commerce'
  const isCommerceUser = isNCommerceUser || isNHNCommerceUser || isCafe24CommerceUser

  if (isCommerceUser) {
    return (
      <Stack>
        {isNCommerceUser && <NaverUploadDialog />}
        {isNHNCommerceUser && <NHNUploadDialog />}
        {isCafe24CommerceUser && <Cafe24UploadDialog />}
      </Stack>
    )
  } else {
    return null
  }
}

export {
  PortfolioUploadConfigComponent,
  RemoveBgConfigComponent,
  BundleConfigComponent,
  BgExpansionConfigComponent,
  ModelBgConfigComponent,
  ModelFaceConfigComponent,
  MannequinConfigComponent,
  BannerConfigComponent,
  BulkUploadComponent,
  CommerceButtonComponent,
  RemoveBgGuestConfigComponent,
}

export const AutoThumb = ({ theme, selected }) => (
  <CenterAlignStack id="theme-auto" sx={{ height: '100%', justifyContent: 'center' }}>
    <Box id="image-box" sx={{ width: { lg: '4rem', xs: '4rem' } }}>
      <img
        src="/static/images/logo/logo_image.png"
        style={{
          objectFit: 'cover',
          height: 'auto',
        }}
      />
    </Box>
    <CenterAlignBox
      id="title-box"
      sx={{
        mt: '1rem',
        width: { lg: '5.8rem', xs: '6.4rem' },
        height: { lg: '2rem', xs: '2.2rem' },
        borderRadius: '10px',
        background: selected ? theme.palette.draph.blue : theme.palette.common.black,
        alignItems: 'center',
      }}
    >
      <Typography id="title" sx={{ color: 'white', fontSize: '1.2rem', fontWeight: 700 }}>
        AI
      </Typography>
    </CenterAlignBox>
  </CenterAlignStack>
)
