// material
import { alpha, styled, useTheme } from '@mui/material/styles'
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Skeleton,
  Divider,
  Menu,
} from '@mui/material'

import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'

import { MdMenuOpen } from 'react-icons/md'

import {
  TempLayoutDiv,
  UserInfoDrawer,
  CenterAlignBox,
  LanguageSelect,
  MenuDrawer,
  ServiceTitle,
  StyledLink,
  BackgroundSkeleton,
  CenterAlignStack,
} from 'components'

import { appMenuAtom, userAtom, languageAtom, tutorialAtom, subMenuAtom } from 'atoms'
import { useRecoilValue, useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil'

import { removeUserCookie, getAccessToken } from 'utils/cookie'
import { MHidden } from 'components/@material-extend'
import { BsBorderWidth } from 'react-icons/bs'
import { Fragment, useEffect, useState } from 'react'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { MypageIcon, PricingIcon } from 'theme/icon'
import { triggerGAEvent, APPBAR_MOBILE } from './MainAppBar'
import { useMediaQuery } from 'react-responsive'
import { iOS } from 'utils/common'
import { useTranslation } from 'react-i18next'
import { useMenu } from 'hooks/useMenu'

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.common.black,
  top: 'auto',
  bottom: 0,
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  // justifyContent: 'space-between',
  flexDirection: 'row',
  // borderTop: '0.5px solid #fff',
  minHeight: `${APPBAR_MOBILE} !important`,
  // padding: '0 2rem',
  padding: 0,
}))

export default function MainDownBar() {
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const [anchorEl1, setAnchorEl1] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)

  const handleButtonClick = item => event => {
    if (item.id === 'generate') {
      setAnchorEl1(event.currentTarget)
      setAnchorEl2(null)
    } else if (item.id === 'model') {
      setAnchorEl1(null)
      setAnchorEl2(event.currentTarget)
    }
    triggerGAEvent(item.id)
    setSubMenu({ open: true, main: item.id })
  }

  const handleClose = () => {
    setAnchorEl1(null)
    setAnchorEl2(null)
  }

  const { menu } = useMenu()
  const [subMenu, setSubMenu] = useRecoilState(subMenuAtom)
  const location = useLocation()
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const tutorialHandle = () => {
    tutorial.mode && tutorial.step === 0 && setTutorial(prev => ({ ...prev, step: 1 }))
  }
  const breakpoint = useMediaQuery({ query: '(max-width: 447px)' })

  const findObjectById = (menu, subMenu) => {
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].id === subMenu) {
        return menu[i]
      }
    }
    // 해당 ID를 가진 객체를 찾지 못한 경우
    return null
  }

  const firstMenuPosition = {
    position: 'absolute',
    left: '50% !important',
    transform: 'translate(0%, 0%) translateX(-219px) !important',
  }

  return (
    <>
      {/* <RootStyle sx={{ alignItems: breakpoint ? 'flex-start' : 'center', overflowX: 'auto' }}>
        <ToolbarStyle sx={{ width: breakpoint ? '100%' : 'auto' }}> */}
      <RootStyle sx={{ alignItems: 'center', overflowX: 'auto' }}>
        <ToolbarStyle sx={{ width: 'auto' }}>
          <Stack
            sx={{
              // overflow: 'scroll',
              backgroundColor: theme.palette.common.black,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // gap: iOS ? '17px' : '0rem',
                // width: iOS ? '438px' : 'fit-content',
                width: 'auto',
                px: '1rem',
                textWrap: 'nowrap',
              }}
            >
              {menu.map((item, idx) => {
                if (!item.children) {
                  return (
                    <StyledLink
                      key={item.id + idx}
                      to={item.path}
                      onClick={() => {
                        triggerGAEvent(item.id)
                      }}
                      style={{
                        ...item.style,
                        cursor: 'pointer',
                        padding: '1.35rem 1rem',
                      }}
                    >
                      <CenterAlignBox sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Typography
                          fontWeight={600}
                          fontSize="1.6rem"
                          sx={{
                            color: location.pathname.includes(item.id)
                              ? theme.palette.draph.green
                              : theme.palette.common.white,
                          }}
                        >
                          {t(`menu.${item.id}`)}
                        </Typography>
                      </CenterAlignBox>
                    </StyledLink>
                  )
                } else {
                  return (
                    <Box
                      key={item.id + idx}
                      sx={{ ...item.style, cursor: 'pointer', padding: '1.35rem 1rem' }}
                    >
                      <CenterAlignBox
                        onClick={handleButtonClick(item)}
                        sx={{ justifyContent: 'center', alignItems: 'center' }}
                      >
                        <Typography
                          fontWeight={600}
                          fontSize="1.6rem"
                          sx={{
                            color: location.pathname.includes(item.id)
                              ? theme.palette.draph.green
                              : theme.palette.common.white,
                          }}
                        >
                          {t(`menu.${item.id}`)}
                        </Typography>
                      </CenterAlignBox>
                      <Menu
                        anchorEl={item.id === 'generate' ? anchorEl1 : anchorEl2}
                        id="account-menu"
                        open={Boolean(item.id === 'generate' ? anchorEl1 : anchorEl2)}
                        onClose={handleClose}
                        onClick={handleClose}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          '& .MuiList-root': {
                            margin: '0 16px',
                            py: '6px', // 마진을 0으로 설정
                          },

                          '& .MuiMenuItem-root': {
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            minHeight: ' 40px',

                            '&:hover': {
                              backgroundColor: 'transparent',
                              color: theme.palette.draph.blue,
                            },
                          },
                        }}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.1))',
                            borderRadius: '10px',
                            width: '11.2rem',
                            top: 'auto !important',
                            bottom: '6rem !important',
                            transform: 'translateX(-39px) !important',
                            // ...(idx === 0 && !breakpoint && firstMenuPosition),
                            // ...(breakpoint && {
                            //   left: idx === 0 ? '0.6rem !important' : '17.6rem !important',
                            // }),
                          },
                        }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                      >
                        {findObjectById(menu, subMenu.main)?.children.map((item, idx) => {
                          return (
                            <Box key={item.id}>
                              <MenuItem>
                                <StyledLink
                                  to={item.path}
                                  onClick={() => {
                                    triggerGAEvent(item.id)
                                  }}
                                  style={{ ...item.style, cursor: 'pointer', width: '100%' }}
                                >
                                  <CenterAlignBox
                                    sx={{ justifyContent: 'center', alignItems: 'center' }}
                                  >
                                    <Typography
                                      fontWeight={700}
                                      fontSize="1.3rem"
                                      sx={{
                                        width: '100%',
                                        textAlign: 'center',
                                        backgroundImage: location.pathname.includes(item.id)
                                          ? 'linear-gradient(to top, rgba(0, 232, 185, 0.20) 36%, rgba(0, 0, 0, 0) 36%)'
                                          : 'transparent',
                                      }}
                                    >
                                      {t(`menu.${item.id}`)}
                                    </Typography>
                                  </CenterAlignBox>
                                </StyledLink>
                              </MenuItem>
                              {!(
                                findObjectById(menu, subMenu.main)?.children.length - 1 ===
                                idx
                              ) && (
                                <Divider
                                  orientation="horizontal"
                                  flexItem
                                  sx={{ m: '0rem 0rem !important' }}
                                />
                              )}
                            </Box>
                          )
                        })}
                      </Menu>
                    </Box>
                  )
                }
              })}

              {/* {menu.map((item, idx) =>
                item.id !== 'portfolio' ? (
                  <StyledLink
                    key={item.id}
                    to={item.path}
                    onClick={() => {
                      triggerGAEvent(item.id)
                    }}
                  >
                    <CenterAlignBox
                      sx={{ minWidth: 'max-content', width: iOS ? '-webkit-max-content' : 'auto' }}
                    >
                      {item.id === 'removebg' && (
                        <Typography
                          sx={{
                            color: theme.palette.common.white,
                            fontSize: '1rem',
                            transform: 'scale(0.8)',
                            borderRadius: '2rem',
                            alignSelf: 'center',
                            border: `1px solid ${theme.palette.common.white}`,
                            p: '0.06rem 0.7rem',
                            fontWeight: 600,
                          }}
                        >
                          FREE
                        </Typography>
                      )}
                      {item.id === 'mannequin' && (
                        <Typography
                          sx={{
                            color: theme.palette.draph.green,
                            fontSize: '1rem',
                            transform: 'scale(0.8)',
                            borderRadius: '2rem',
                            alignSelf: 'center',
                            border: `1px solid ${theme.palette.draph.green}`,
                            p: '0.06rem 0.7rem',
                            fontWeight: 600,
                          }}
                        >
                          BETA
                        </Typography>
                      )}
                      <Typography
                        fontWeight={800}
                        fontSize="1.6rem"
                        sx={{
                          color: location.pathname.includes(item.id)
                            ? theme.palette.draph.green
                            : theme.palette.common.white,
                        }}
                      >
                        {t(`menu.${item.id}`)}
                      </Typography>
                    </CenterAlignBox>
                  </StyledLink>
                ) : (
                  <Tutorial key={item.id} step={0}>
                    <StyledLink
                      to={item.path}
                      onClick={() => {
                        triggerGAEvent(item.id)
                        tutorial.mode &&
                          tutorial.step === 0 &&
                          setTutorial(prev => ({ ...prev, step: 1 }))
                      }}
                    >
                      <CenterAlignBox sx={{ minWidth: 'max-content' }}>
                        <Typography
                          fontWeight={800}
                          fontSize="1.6rem"
                          sx={{
                            color:
                              !location.pathname.includes('upload') &&
                              !location.pathname.includes('removebg') &&
                              !location.pathname.includes('mannequin') &&
                              location.pathname.includes(item.id)
                                ? theme.palette.draph.green
                                : theme.palette.common.white,
                          }}
                        >
                          {t(`menu.${item.id}`)}
                        </Typography>
                      </CenterAlignBox>
                    </StyledLink>
                  </Tutorial>
                )
              )} */}
            </Box>
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    </>
  )
}
