import { Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import MobileSwipeableDrawer from './MobileSwipeableDrawer'
import {
  indexAtom,
  portfolioTypeAtom,
  removeMaskImgAtom,
  segmentLoadingAtom,
  selectedMaskImgAtom,
  uploadDialogOpenAtom,
  uploadFilesAndUrlAtom,
  uploadFilesAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { CloseBlackIcon, EraserIcon, PlusIcon, PlusMobileIcon } from 'theme/icon'
import { useEffect, useRef } from 'react'
import { useCheckImage } from 'hooks/useRefineUploadFiles'
import { allowedTypes } from 'utils/common'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { Segment } from './fragment/Segment'
import { Button, Divider, IconButton, Stack } from '@mui/material'
import { useFragment } from 'hooks/useFragement'
import { useTranslation } from 'react-i18next'
import { MannequinConfig, ModelCommonConfig } from './ModelRenerateDialog'
import { PORTFOLIO_TYPE_FACE, PORTFOLIO_TYPE_MODELBG, PORTFOLIO_TYPE_MANNEQUIN } from 'config'
import { useResetNpy } from 'hooks/useResetNpy'
import { DialogButton } from './DialogButtons'

export function MobileModelUploadOld({
  title,
  notice,
  multiple = false,
  mannquinUpload,
  editOnly,
  keepShowingOn,
  gender,
  setGender = () => {},
  age,
  setAge = () => {},
  prompt,
  setPrompt = () => {},
  model,
  setModel = () => {},
  handleUpload = () => {},
  uploadButtonTitle,
  openAtom = uploadDialogOpenAtom,
}) {
  const isMobile = useMobileMediaQuery()
  const inputRef = useRef(null)

  const checkImage = useCheckImage()
  const { t, i18n } = useTranslation()

  const { handleRedo, handleReset, handleUndo } = useFragment()
  const { resetAllState } = useResetNpy()

  const [uploadOpen, setUploadOpen] = useRecoilState(openAtom)
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const [files1, setFiles1] = useRecoilState(uploadFilesAtom)

  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [removeMaskImg, SetRemoveMaskImg] = useRecoilState(removeMaskImgAtom)
  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)

  const [index, SetIndex] = useRecoilState(indexAtom)

  const resetFiles = useResetRecoilState(uploadFilesAndUrlAtom)
  const resetFiles1 = useResetRecoilState(uploadFilesAtom)

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isFacePage = portfolioType === PORTFOLIO_TYPE_FACE
  const isModelBgPage = portfolioType === PORTFOLIO_TYPE_MODELBG
  const isMannequinPage = portfolioType === PORTFOLIO_TYPE_MANNEQUIN

  const hasSegment = isMannequinPage || isFacePage
  const hasModel = isModelBgPage || isFacePage

  const uuid = Math.ceil(Math.random() * 1000)
  const filesRef = useRef(files)

  useEffect(() => {
    filesRef.current = files
  }, [files])

  const handleClose = () => {
    setUploadOpen(false)
    setFiles1([])

    if (hasSegment) {
      filesRef.current.forEach(image => URL.revokeObjectURL(image.url))
      resetFiles()
      resetAllState()
      setAge('adult')
      setGender('female')
      setModel('auto')
      setPrompt('')
    }
  }

  const handleChange = async function (e) {
    e.preventDefault()

    if (e.target.files) {
      const uploadFiles = Array.prototype.slice.call(e.target.files)

      checkImage(uploadFiles, files, setFiles, mannquinUpload, editOnly, keepShowingOn)

      e.target.value = ''
    }
  }

  // Update the ref whenever files changes
  useEffect(() => {
    filesRef.current = files
  }, [files])

  useEffect(() => {
    return () => {
      if (inputRef && inputRef.current) inputRef.current.value = null

      if (hasSegment) {
        filesRef.current.forEach(image => URL.revokeObjectURL(image.url))
        resetFiles()
        resetAllState()
        setAge('adult')
        setGender('female')
        setModel('auto')
        setPrompt('')
      }
    }
  }, [])

  return (
    <Mobile>
      <MobileSwipeableDrawer
        open={uploadOpen}
        onOpen={() => {
          return uploadOpen
        }}
        onClose={handleClose}
        sx={{ mb: '3rem' }}
      >
        <CenterAlignStack sx={{ mx: '2rem' }}>
          <CenterAlignStack sx={{ gap: '0.3rem' }}>
            {title}
            {notice}
          </CenterAlignStack>

          <CenterAlignStack
            sx={{
              mt: '1.6rem',
              gap: '1.6rem',
              minWidth: hasSegment ? '32rem' : 'auto',
              minHeight: hasSegment ? '25.2rem' : 'auto',
            }}
          >
            {files.length === 0 ? (
              hasSegment && (
                <form
                  className="form-file-upload"
                  id={`form-file-upload-${uuid}`}
                  onSubmit={e => e.preventDefault()}
                  style={{
                    width: '32rem',
                    height: '25.2rem',
                  }}
                >
                  <input
                    ref={inputRef}
                    type="file"
                    className="input-file-upload"
                    id={`input-file-upload-${uuid}`}
                    accept={allowedTypes.join(', ')}
                    multiple={multiple}
                    onChange={handleChange}
                  />
                  <label id="label-file-upload" htmlFor={`input-file-upload-${uuid}`}>
                    <div>{isMobile ? <PlusMobileIcon /> : <PlusIcon />}</div>
                  </label>
                </form>
              )
            ) : (
              <Stack sx={{ backgroundColor: files.length ? '#D9D9D9' : 'transparent' }}>
                {hasSegment ? (
                  <Segment widthLimit={320} heightLimit={252} />
                ) : (
                  <Stack sx={{ width: '32rem', height: '25.2rem', position: 'relative' }}>
                    <img src={files[0].url} style={{ maxHeight: '100%', objectFit: 'contain' }} />
                    <IconButton
                      onClick={() => {
                        URL.revokeObjectURL(files[0].url)
                        resetFiles()
                      }}
                      sx={{
                        position: 'absolute',
                        top: '-1.4rem',
                        right: '-1.5rem',
                      }}
                    >
                      <CloseBlackIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            )}

            {hasSegment && (
              <CenterAlignBox
                sx={{
                  gap: '1.2rem',

                  '& .roundStyle': {
                    borderRadius: '3rem',
                    border: 0,
                    fontSize: '1.2rem',

                    boxShadow: ' 2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
                    fontWeight: 600,
                    '&.Mui-disabled': {
                      border: 'none',
                      '& svg path': {
                        stroke: '#919EAB',
                      },
                    },
                    '&:hover': {
                      boxShadow: ' 2px 2px 10px 0px rgba(0, 0, 0, 0.15)',
                      background: 'unset',
                      color: theme => theme.palette.draph.blue,
                      '& svg path': {
                        stroke: theme => theme.palette.draph.blue,
                        transition: 'stroke 0.3s',
                      },
                    },
                    '& svg path': {
                      transition: 'stroke 0.3s',
                    },
                    '& svg': {
                      mr: '0.5rem',
                    },
                  },
                }}
              >
                <Button
                  variant="outlined"
                  className="roundStyle"
                  sx={{ width: '9.2rem', height: '2.8rem' }}
                  onClick={handleReset}
                  disabled={segmentLoading}
                >
                  <EraserIcon />
                  {t('regenerate_dialog.bg_edit_reset')}
                </Button>

                <Button
                  variant="outlined"
                  className="roundStyle"
                  sx={{ width: '9.2rem', height: '2.8rem' }}
                  onClick={handleUndo}
                  disabled={index === -1 || segmentLoading}
                >
                  <svg
                    width="1rem"
                    height="1rem"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 11L1 6L6 1"
                      stroke="#303030"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 6H11.5C12.2223 6 12.9375 6.14226 13.6048 6.41866C14.272 6.69506 14.8784 7.10019 15.3891 7.61091C15.8998 8.12163 16.3049 8.72795 16.5813 9.39524C16.8577 10.0625 17 10.7777 17 11.5C17 12.2223 16.8577 12.9375 16.5813 13.6048C16.3049 14.272 15.8998 14.8784 15.3891 15.3891C14.8784 15.8998 14.272 16.3049 13.6048 16.5813C12.9375 16.8577 12.2223 17 11.5 17H8"
                      stroke="#303030"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t('regenerate_dialog.bg_edit_undo')}
                </Button>
                <Button
                  variant="outlined"
                  className="roundStyle"
                  sx={{ width: '9.2rem', height: '2.8rem' }}
                  onClick={handleRedo}
                  disabled={removeMaskImg.length === 0 || segmentLoading}
                >
                  <svg
                    width="1rem"
                    height="1rem"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 11L17 6L12 1"
                      stroke="#303030"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 6H6.5C5.04131 6 3.64236 6.57946 2.61091 7.61091C1.57946 8.64236 1 10.0413 1 11.5C1 12.2223 1.14226 12.9375 1.41866 13.6048C1.69506 14.272 2.10019 14.8784 2.61091 15.3891C3.64236 16.4205 5.04131 17 6.5 17H10"
                      stroke="#303030"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {t('regenerate_dialog.bg_edit_redo')}
                </Button>
              </CenterAlignBox>
            )}
          </CenterAlignStack>

          {hasSegment && <Divider flexItem orientation="horizontal" sx={{ my: '2.4rem' }} />}

          {hasModel && (
            <ModelCommonConfig
              gender={gender}
              setGender={setGender}
              model={model}
              setModel={setModel}
              multiple={isModelBgPage}
            />
          )}
          {isMannequinPage && (
            <MannequinConfig
              gender={gender}
              setGender={setGender}
              age={age}
              setAge={setAge}
              prompt={prompt}
              setPrompt={setPrompt}
            />
          )}
          {/* 모바일 모델은 예전 파일형식 */}
          <Stack sx={{ my: '2.8rem' }}>
            <DialogButton
              handleClose={handleClose}
              actionText={<>{uploadButtonTitle ?? t('upload')}</>}
              handleAction={() => {
                handleUpload(isModelBgPage ? files1 : files)
                handleClose()
              }}
              actionprops={
                hasSegment ? { disabled: !selectedMaskImg.length || segmentLoading } : {}
              }
            />
          </Stack>
        </CenterAlignStack>
      </MobileSwipeableDrawer>
    </Mobile>
  )
}
