import React, { useState, useRef, useEffect } from 'react'
import Moveable from 'react-moveable'

function TestPage11() {
  const ref = useRef(null)
  const ref1 = useRef(null)

  return (
    <div>
      <img
        ref={ref}
        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
        alt="My Image"
      />

      <Moveable
        target={ref}
        scalable={true}
        keepRatio={true}
        throttleScale={0}
        renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
        onScale={e => {
          e.target.style.transform = e.drag.transform
        }}
        draggable={true}
        throttleDrag={1}
        edgeDraggable={true}
        startDragRotate={0}
        throttleDragRotate={0}
        onDrag={e => {
          e.target.style.transform = e.transform
        }}
      />
      <img
        ref={ref1}
        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
        alt="My Image"
      />

      <Moveable
        target={ref1}
        scalable={true}
        keepRatio={true}
        throttleScale={0}
        renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
        onScale={e => {
          e.target.style.transform = e.drag.transform
        }}
        draggable={true}
        throttleDrag={1}
        edgeDraggable={true}
        startDragRotate={0}
        throttleDragRotate={0}
        onDrag={e => {
          e.target.style.transform = e.transform
        }}
      />
    </div>
  )
}

export default TestPage11
