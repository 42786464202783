import {
  styled,
  Box,
  Button,
  Card,
  Divider,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Stack,
  Checkbox,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  useTheme,
  DialogActions,
  CircularProgress,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { CenterAlignStack, CenterAlignBox } from './CenterAlignContainer'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { usePricing } from 'hooks/usePricing'
import { fontWeight } from '@mui/system'
import { useEffect, useState } from 'react'
import { getDiscountRate, getOriginalPrice, getNextPaidDay } from 'pricing'
import {
  convertPriceSymbolToWord,
  getCurrencyFormatter,
  isKo,
  numberCommaFormatter,
  trasnferDate,
} from 'utils/common'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userAtom } from 'atoms'
import { apis } from 'apis'
import moment from 'moment'
import 'moment-timezone'
import {
  CheckIcon,
  CheckedSquareIcon2,
  CheckedSquareIcon3,
  MiniCheckBox,
  MiniSquare,
  SquareIcon2,
} from 'theme/icon'
import { creditPlansAtom } from '../atoms'
import { useLocation, useNavigate } from 'react-router-dom'
import { SUB_EVENT_ID, addSessionStorageData } from 'pages/Pricing'
import { useTranslation } from 'react-i18next'
import { English, Korean } from 'hooks/useLanguage'
import { checkAppliedSubEvent } from 'pages/CreditAndSubscriptions'
import { CustomRadio1 } from './CustomStyle'
import { generatePaymentUid } from '../hooks/usePricing'

export const SubtitleTypography = styled(Typography)(({ theme }) => ({
  '& svg': {
    marginRight: '0.7rem',
  },
  fontSize: '1.6rem',
  fontWeight: 400,
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    '& svg': {
      marginRight: '0.4rem',
    },
  },
}))

const StyledTable = styled(Table)(({ theme }) => ({
  width: '43.4rem',
  borderCollapse: 'separate',
  borderSpacing: '0 1rem',
  // minHeight: '32rem',
  '& th': {
    padding: 0,
    height: '4rem',
    borderBottom: 0,
    background: theme.palette.common.black,
    color: '#FFFFFF',
    fontSize: '1.4rem',
    fontWeight: 700,
    textAlign: 'center',
  },
  '& td': {
    padding: 0,
    height: '3.2rem',
    borderBottom: 0,
    lineHeight: 'normal',
    '& p': {
      fontSize: '1.4rem',
    },
  },

  '& tbody .MuiTableRow-root:hover': {
    background: theme.palette.draph.lighterblue,
  },
  '& th:nth-of-type(1),& td:nth-of-type(1)': {
    textAlign: 'center',
    width: '5.2rem',
    // border: '1px solid pink',
  },
  '& th:nth-of-type(2),& td:nth-of-type(2)': {
    width: '10rem',
    paddingLeft: '1.5rem',
    paddingRight: '1rem',
    textAlign: 'right',
    // border: '1px solid yellow',
  },
  '& th:nth-of-type(3)': {
    // border: '1px solid red',
  },

  '& td:nth-of-type(3)': {
    width: '8.5rem',
    // border: '1px solid red',
  },

  // '& td:nth-of-type(4)': {
  //   width: '14rem',
  //   // border: '1px solid red',
  // },

  '& td:nth-of-type(5)': {
    width: '13rem',
  },
  // '& th:not(th:nth-of-type(1))': {
  //   background: theme.palette.common.black,
  //   color: '#FFFFFF',
  //   fontSize: '1.4rem',
  //   fontWeight: 700,
  //   textAlign: 'center',
  // },

  '& tbody tr td .MuiRadio-root:hover': {
    background: 'transparent',
  },

  [theme.breakpoints.down('lg')]: {
    borderSpacing: '0 0.4rem',
    width: 'unset',
    // minHeight: '19.1rem',
    '& th': {
      height: '3.2rem',
    },
    '& td': {
      height: '3.2rem',
      '& p': {
        fontSize: '1rem',
      },
    },

    '& th:nth-of-type(1),& td:nth-of-type(1)': {
      textAlign: 'center',
      width: '3.7rem',
      // border: '1px solid pink',
    },
    '& th:nth-of-type(2),& td:nth-of-type(2)': {
      width: '4rem',
      paddingLeft: '0.3rem',
      paddingRight: '1rem',
    },
    '& td:nth-of-type(3)': {
      width: '4.8rem',
      paddingLeft: '1rem',
    },

    '& td:nth-of-type(4)': {
      width: '10rem',
    },

    '& td:nth-of-type(5)': {
      width: '7.5rem',
    },
    '& th:not(th:nth-of-type(1))': {
      fontSize: '1rem',
    },
  },
}))

export const Header = styled(Typography)(({ theme }) => ({
  fontSize: '1.7rem',

  fontWeight: 700,
  color: theme.palette.common.white,

  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
    fontWeight: 700,
  },
}))

const FirstSubFreeBadge = () => {
  const { t } = useTranslation()

  return (
    <CenterAlignBox
      sx={{
        alignSelf: 'center',
        width: 'fit-content',
        background: theme => theme.palette.draph.blue,
        px: { lg: '1.2rem', xs: '0.8rem' },
        py: { lg: '0.4rem', xs: '0.3rem' },
        fontSize: { lg: '1.2rem', xs: '1rem' },
        fontWeight: 700,
        color: 'white',
        borderRadius: '2rem',
      }}
    >
      {t('pricing.free_for_first_month')}
    </CenterAlignBox>
  )
}

async function checkSubscriptionEvent() {
  const response = await apis.user.getUserSubscription()
  return response.data.subscription_event
}
async function checkPlanPrice(data) {
  const response = await apis.common.checkPlanValid(data)
  return response.data
}

export function PricingCard({
  name,
  plan,
  requestPay,
  userSubId,
  userSubInfo,
  setOpenNonMemberDialog,
  highlight,
  requestPaypal,
  eventId,
  partnerPlans = {},
}) {
  const [currency, setCurrency] = useState('krw')
  const [currencyFormatter, setCurrencyFormatter] = useState(getCurrencyFormatter(currency))
  const [selected, setSelected] = useState({ id: '' })
  const [userSubPlan, setUserSubPlan] = useState(null)
  const [termAgreed, setTermAgreed] = useState(false)
  const [openSubDialog, setOpenSubDialog] = useState(false)
  const { t, i18n } = useTranslation()

  const { pricingPlanDict, getPlanDisplayName } = usePricing()

  const publicPlans = useRecoilValue(creditPlansAtom)
  const creditPlans = {
    subscription: [...publicPlans.subscription, ...(partnerPlans?.subscription ?? [])],
    payGo: [...publicPlans.payGo, ...(partnerPlans?.payGo ?? [])],
  }

  const userInfo = useRecoilValue(userAtom)
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const location = useLocation()
  const isSubscriptionMenu = name === 'subscription'
  const isPublicPricingPage = location.pathname === '/pricing'

  const subEventValid =
    eventId &&
    eventId === SUB_EVENT_ID &&
    plan.filter(p => p.ev && p.ev[eventId] && !isNaN(p.ev[eventId][currency])).length > 0

  const [abroadUser, setAbroadUser] = useState(false) // TODO 해외결제 테스트용! 나중에 접속지역 기반 판단

  useEffect(() => {
    const isKo = i18n.language === 'ko'

    setAbroadUser(!isKo)
  }, [i18n.language])

  useEffect(() => {
    if (plan?.length > 0) {
      const dflt = plan.filter(p => p.default)[0]
      if (isSubscriptionMenu && userSubId) {
        const s = plan.filter(p => userSubId === p.id)[0]
        setUserSubPlan(s)
        setSelected(s ?? dflt ?? plan[0])
      } else {
        setSelected(dflt ?? plan[0])
      }
    }
  }, [plan, userSubId])

  useEffect(() => {
    const currency = localStorage.getItem('i18nextLng') === 'ko' ? 'krw' : 'usd'
    setCurrency(currency)
  }, [])

  useEffect(() => {
    setCurrencyFormatter(getCurrencyFormatter(currency))
  }, [currency])

  useEffect(() => {
    if (abroadUser) {
      setCurrency('usd')
    } else {
      setCurrency('krw')
    }
  }, [abroadUser])

  useEffect(() => {}, [selected])

  const handleAction = async () => {
    if (!selected.price[currency]) {
      alert(t('payment_error_1'))
      window.location.reload()
    }
    const plans = await checkPlanPrice({
      plan_id: selected.id,
      currency,
      price: selected.price[currency],
    })

    const checkPlan = plans.filter(p => p.plan_type === selected.plan_type && p.id === selected.id)
    let finalPrice = selected.price[currency]

    if (
      !checkPlan ||
      checkPlan.length < 1 ||
      (checkPlan && checkPlan[0].price[currency] !== finalPrice)
    ) {
      alert(t('payment_error_1'))
      window.location.reload()
      return
    }

    if (abroadUser) {
      // TODO 해외결제도 프로모션 금액을 적용하려면 국내결제와 동일하게 처리.. requestPaypal 쪽도 $0 결제에 대한 처리가 필요함
      if (isSubscriptionMenu) {
        if (userSubId) {
          setOpenSubDialog(true)
        } else {
          requestPaypal({
            paymentType: name,
            plan: selected,
            price: finalPrice,
            currency,
          })
        }
      } else {
        requestPaypal({
          paymentType: name,
          plan: selected,
          price: finalPrice,
          currency,
        })
      }
    } else {
      if (isSubscriptionMenu) {
        const userEventId = await checkSubscriptionEvent() // 현재 진행중인 이벤트 아이디(SUB_EVENT_ID) 와 매칭되어야함

        const applyEventPrice =
          eventId &&
          eventId === SUB_EVENT_ID &&
          userEventId === SUB_EVENT_ID &&
          selected.ev &&
          selected.ev[SUB_EVENT_ID] &&
          !isNaN(selected.ev[SUB_EVENT_ID][currency])

        if (userEventId === SUB_EVENT_ID) {
          finalPrice = applyEventPrice
            ? selected.ev[SUB_EVENT_ID][currency]
            : selected.price[currency]

          if (applyEventPrice) {
            const p = checkPlan[0]
            const priceValid =
              p.ev &&
              p.ev[SUB_EVENT_ID] &&
              !isNaN(p.ev[SUB_EVENT_ID][currency]) &&
              p.ev[SUB_EVENT_ID][currency] === finalPrice
            if (!priceValid) {
              alert('결제 시도 중 오류가 발생하였습니다.')
              window.location.reload()
              return
            }
          }
        }

        if (userSubId) {
          setOpenSubDialog(true)
        } else {
          requestPay({
            paymentType: name,
            plan: selected,
            finalPrice,
            currency,
            eventId: applyEventPrice ? SUB_EVENT_ID : null,
          })
        }
      } else {
        requestPay({
          paymentType: name,
          plan: selected,
          price: finalPrice,
          currency,
        })
      }
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      {highlight && (
        <Box
          sx={{
            // width: { lg: '10rem', xs: '5.6rem' },
            height: { lg: '4.4rem', xs: '2.4rem' },
            borderRadius: { lg: '10px', xs: '0.8rem' },
            background: theme => theme.palette.draph.blue,
            color: 'white',
            fontSize: { lg: '2.2rem', xs: '1.2rem' },
            fontWeight: 800,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%)',
            top: { lg: '-2rem', xs: '-1.2rem' },
            zIndex: 99,
            px: { lg: '2.2rem', xs: '1.3rem' },
          }}
        >
          {subEventValid ? 'PROMOTION' : 'BEST'}
        </Box>
      )}
      <Card
        sx={{
          borderRadius: '2rem',
          // height: { lg: '60.5rem', xs: '45rem' },
          p: { lg: '4.1rem 3.3rem 4.2rem 3.3rem ', xs: '3rem 1.6rem 2.4rem 1.6rem' },

          width: { lg: '50rem', xs: '32rem' },
          ...(highlight && {
            border: 1,
            borderWidth: { lg: '0.1rem', xs: '0.1rem' },
            borderColor: theme => theme.palette.draph.blue,
            boxShadow: '4px 4px 20px rgba(77, 128, 255, 0.2)',
          }),
        }}
        elevation={9}
      >
        <CenterAlignStack sx={{ width: '100%', justifyContent: 'center' }}>
          <Typography
            fontSize={{ lg: '2.4rem', xs: '1.6rem' }}
            fontWeight={700}
            sx={{ mb: { lg: '1.2rem', xs: '0.7rem' } }}
          >
            {pricingPlanDict[name]}
          </Typography>

          {isSubscriptionMenu && isPublicPricingPage && (
            <Box
              sx={{
                position: 'absolute',
                right: { lg: '2rem', xs: '1.6rem' },
                top: { lg: '2rem', xs: '1.6rem' },
              }}
            >
              <PriorityBadge />
            </Box>
          )}

          <Box sx={{ mb: { lg: '1.7rem', xs: '1rem' }, '& span': { fontWeight: 700 } }}>
            {isSubscriptionMenu && (
              <>
                {isPublicPricingPage && (
                  <SubtitleTypography sx={{ fontSize: isKo(i18n) ? '1.6rem' : '1.4rem' }}>
                    <CheckIcon />

                    <Korean>
                      <span>가장 빠른 생성 속도</span>로 많은 상품 이미지 제작 가능!
                    </Korean>
                    <English>
                      {/* TODO 번역 문구 점검 */}
                      <span>The fastest way</span> to generate numerous product images
                    </English>
                  </SubtitleTypography>
                )}
                {isPublicPricingPage && (
                  <SubtitleTypography sx={{ fontSize: isKo(i18n) ? '1.6rem' : '1.4rem' }}>
                    <CheckIcon />
                    <Korean>
                      10분 이상 생성 지연 시, <span>사용 크레딧 원상복구</span>
                    </Korean>
                    <English>
                      <span>Restore credits</span> if generation delayed for more than 10 minutes
                    </English>
                  </SubtitleTypography>
                )}
                <SubtitleTypography sx={{ fontSize: isKo(i18n) ? '1.6rem' : '1.4rem' }}>
                  <CheckIcon />
                  <span style={{ fontWeight: isKo(i18n) ? 400 : 700 }}>
                    {t('pricing.pricingCard.subscription_comment_2_a')}
                  </span>
                  <span style={{ fontWeight: isKo(i18n) ? 700 : 400 }}>
                    {' '}
                    {t('pricing.pricingCard.subscription_comment_2_b')}
                  </span>
                </SubtitleTypography>

                {subEventValid && t('pricing.pricingCard.subscription_comment_event_1') && (
                  <SubtitleTypography
                    sx={{
                      fontSize: isKo(i18n) ? '1.6rem' : '1.4rem',
                      color: theme => theme.palette.draph.blue,
                      fontWeight: 700,
                    }}
                  >
                    <CheckIcon />
                    {t('pricing.pricingCard.subscription_comment_event_1')}
                  </SubtitleTypography>
                )}
              </>
            )}
            {name === 'payGo' && (
              <>
                <SubtitleTypography sx={{ fontSize: isKo(i18n) ? '1.6rem' : '1.4rem' }}>
                  <CheckIcon />
                  {t('pricing.pricingCard.paygo_comment_1')}
                </SubtitleTypography>
                <SubtitleTypography sx={{ fontSize: isKo(i18n) ? '1.6rem' : '1.4rem' }}>
                  <CheckIcon />
                  {t('pricing.pricingCard.paygo_comment_2_a')}{' '}
                  <span> {t('pricing.pricingCard.paygo_comment_2_b')}</span>
                </SubtitleTypography>
              </>
            )}
          </Box>
          <Box sx={{}}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        color: 'inherit',
                        width: { lg: '5.9rem', xs: '4.3rem' },
                      }}
                    >
                      {t('pricing.pricingCard.table_col_1')}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    {isSubscriptionMenu
                      ? t('pricing.pricingCard.table_col_2')
                      : t('pricing.pricingCard.table_col_2_paygo')}
                  </TableCell>
                  <TableCell> {t('pricing.pricingCard.table_col_3')}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {plan?.map(p => (
                  <TableRow
                    key={p.id}
                    onClick={() => {
                      setSelected(p)
                    }}
                    sx={{
                      cursor: 'pointer',
                      '&.MuiTableRow-root': {
                        background:
                          selected.id === p.id ? theme => theme.palette.draph.lighterblue : 'unset',
                      },
                    }}
                  >
                    <TableCell>
                      <CustomRadio1 checked={selected.id === p.id} sx={{ p: 0, mx: '0.5rem' }} />
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{ width: { lg: isKo(i18n) ? '5.9rem' : '6.5rem', xs: '4.3rem' } }}
                      >
                        {p.creditAmount}{' '}
                        {isSubscriptionMenu && (
                          <span>&nbsp;{t('pricing.pricingCard.monthly')}</span>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'right',
                        width: isSubscriptionMenu
                          ? '14rem'
                          : isMobile
                          ? '6.8rem !important'
                          : '8.5rem',
                        pr: { lg: '0.6rem !important', xs: '0.3rem !important' },
                      }}
                    >
                      <Typography sx={{ textDecoration: 'line-through' }}>
                        {subEventValid &&
                        p.ev &&
                        p.ev[SUB_EVENT_ID] &&
                        !isNaN(p.ev[eventId][currency])
                          ? currencyFormatter.format(p.price[currency]) // 첫달 0원 프로모션 - 비포 가격을 원래 판매가격으로 표시
                          : getOriginalPrice(creditPlans, p.id, currency) !== p?.price[currency] &&
                            currencyFormatter.format(getOriginalPrice(creditPlans, p.id, currency))}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'left',
                        width: isSubscriptionMenu
                          ? isKo(i18n)
                            ? '14rem'
                            : '7rem'
                          : isMobile
                          ? '6.8rem !important'
                          : '8.5rem',
                        pl: { lg: '0.6rem !important', xs: '0.3rem !important' },
                      }}
                    >
                      <Typography
                        sx={{ color: theme => theme.palette.draph.blue, fontWeight: 700 }}
                      >
                        {subEventValid &&
                        p.ev &&
                        p.ev[SUB_EVENT_ID] &&
                        !isNaN(p.ev[SUB_EVENT_ID][currency]) ? (
                          currencyFormatter.format(p.ev[SUB_EVENT_ID][currency])
                        ) : (
                          <PrefixMonthly
                            price={currencyFormatter.format(p.price[currency])}
                            isSubscription={isSubscriptionMenu}
                          />
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <CenterAlignBox>
                        {subEventValid &&
                        p.ev &&
                        p.ev[SUB_EVENT_ID] &&
                        !isNaN(p.ev[SUB_EVENT_ID][currency]) ? (
                          <FirstSubFreeBadge />
                        ) : (
                          getDiscountRate(creditPlans, p.id, currency) > 0 && (
                            <Typography>
                              {getDiscountRate(creditPlans, p.id, currency)} %
                            </Typography>
                          )
                        )}
                      </CenterAlignBox>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </Box>

          {/* <FormControlLabel
            control={
              <Checkbox
                sx={{ p: 0, mr: '0.6rem' }}
                icon={
                  isMobile ? (
                    <SquareIcon2 style={{ width: '1.2rem', height: '1.2rem' }} />
                  ) : (
                    <SquareIcon2 />
                  )
                }
                checkedIcon={
                  isMobile ? (
                    <CheckedSquareIcon3 sx={{ width: '1.2rem', height: '1.2rem' }} />
                  ) : (
                    <CheckedSquareIcon3 />
                  )
                }
                checked={termAgreed}
                onChange={(e, c) => {
                  setTermAgreed(c)
                }}
              />
            }
            sx={{
              m: 0,
              mt: { lg: '1.2rem', xs: '0.9rem' },
              mb: { lg: '1.2rem', xs: '1.1rem' },
              '& span.MuiTypography-root': {
                fontSize: { lg: '1.4rem', xs: '0.9rem' },
                fontWeight: 400,
              },
            }}
            label={t('pricing.pricingCard.purchase_agree')}
          /> */}

          <Button
            sx={{
              mt: { lg: '1.2rem', xs: '0.9rem' },
              // mb: { lg: '1.2rem', xs: '1.1rem' },
              width: { lg: '36rem', xs: '28.8rem' },
              height: { lg: '4.8rem', xs: '3.6rem' },
              backgroundColor: isSubscriptionMenu ? theme.palette.draph.blue : '',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: isSubscriptionMenu ? theme.palette.common.black : '',
              },

              '& p': {
                color: 'white',
                fontSize: { lg: '1.6rem', xs: '1.4rem' },
                fontWeight: { lg: 600, xs: 600 },
              },
            }}
            variant="contained"
            onClick={() => {
              if (userInfo.id) {
                handleAction()
              } else {
                setOpenNonMemberDialog(true)
              }
            }}
            disabled={
              // !termAgreed ||
              selected.id === userSubId || selected.id === userSubInfo?.next_subscription?.plan_id
            }
          >
            {eventId &&
            selected.ev &&
            selected.ev[SUB_EVENT_ID] &&
            !isNaN(selected.ev[SUB_EVENT_ID][currency]) ? (
              <Typography>{t('pricing.pricingCard.start_free_subscription')}</Typography>
            ) : isSubscriptionMenu && userSubId && selected?.id && userSubPlan?.id ? (
              userSubId.includes(selected?.id) ? (
                <>
                  <Typography>{t('pricing.pricingCard.current_subscription')}</Typography>
                </>
              ) : selected.id === userSubInfo?.next_subscription?.plan_id ? (
                <Typography>{t('pricing.pricingCard.change_subscription')}</Typography>
              ) : selected.creditAmount > userSubPlan.creditAmount ? (
                <Typography>{t('pricing.pricingCard.higher_subscription')}</Typography>
              ) : (
                <Typography>{t('pricing.pricingCard.lower_subscription')}</Typography>
              )
            ) : (
              selected?.price &&
              selected.price[currency] && (
                <>
                  <Korean>
                    {isSubscriptionMenu && <Typography>매달&nbsp;</Typography>}
                    <Typography>
                      {convertPriceSymbolToWord(currencyFormatter.format(selected.price[currency]))}
                    </Typography>
                    <Typography fontSize="1.3rem" sx={{ mx: '0.5rem' }}>
                      (VAT 포함)
                    </Typography>
                    <Typography>결제하기</Typography>
                  </Korean>
                  <English>
                    <Typography>Pay &nbsp;</Typography>
                    <Typography>{currencyFormatter.format(selected.price[currency])}</Typography>
                    <Typography fontSize="1.3rem" sx={{ mx: '0.5rem' }}>
                      (Price incl. VAT)
                    </Typography>
                  </English>
                </>
              )
            )}
          </Button>

          <Box
            sx={{
              width: '100%',
              mt: { lg: '2.4rem', xs: '1.6rem' },
              // height: { lg: '4.5rem', xs: '3rem' },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
              '& p': { fontSize: { lg: '1.5rem', xs: '1.1rem' }, fontWeight: 600 },
              '& span': {
                fontWeight: 800,
                color: theme => theme.palette.draph.blue,
              },
            }}
          >
            {!userSubId?.includes(selected.id) ? (
              <>
                {isSubscriptionMenu ? (
                  <>
                    <Korean>
                      <CenterAlignStack>
                        {eventId &&
                          selected.ev &&
                          selected.ev[SUB_EVENT_ID] &&
                          !isNaN(selected.ev[SUB_EVENT_ID][currency]) && (
                            <>
                              <Typography>
                                <span>{t('pricing.pricingCard.first_month_free_comment_1_a')}</span>
                                {t('pricing.pricingCard.first_month_free_comment_1_b')}
                              </Typography>
                            </>
                          )}
                        <Typography sx={{ textAlign: 'center' }}>
                          매월 결제일마다{' '}
                          <span>
                            {selected?.price && currencyFormatter.format(selected?.price[currency])}
                          </span>{' '}
                          이 결제되고{' '}
                          <Mobile>
                            <br />
                          </Mobile>
                          <span>{selected.creditAmount}크레딧</span>이 자동 충전됩니다.
                        </Typography>
                      </CenterAlignStack>
                    </Korean>
                    <English>
                      <CenterAlignStack>
                        {eventId &&
                          selected.ev &&
                          selected.ev[SUB_EVENT_ID] &&
                          !isNaN(selected.ev[SUB_EVENT_ID][currency]) && (
                            <>
                              <Typography>
                                <span>After First month for free</span>
                                {/* {t('pricing.pricingCard.first_month_free_comment_1_b')} */}
                              </Typography>
                            </>
                          )}
                        <Typography sx={{ textAlign: 'center' }}>
                          <span>
                            {selected?.price && currencyFormatter.format(selected?.price[currency])}
                          </span>{' '}
                          will be charged on monthly due date
                          <br />
                          <span>{selected.creditAmount} Credit(s)</span> will be replenished.
                        </Typography>
                      </CenterAlignStack>
                    </English>
                  </>
                ) : (
                  <Typography>
                    <span>
                      {selected.creditAmount} {t('credit.charged_credit')}
                    </span>
                    {t('pricing.pricingCard.credit_info')}
                  </Typography>
                )}
              </>
            ) : (
              <Typography>
                {t('pricing.pricingCard.current_status_1_a')}
                <span>
                  {' '}
                  {getPlanDisplayName(
                    userSubId?.includes('admin') ? userSubId.slice(0, -6) : userSubId
                  )}
                </span>
                {t('pricing.pricingCard.current_status_1_b')}
              </Typography>
            )}
          </Box>
        </CenterAlignStack>

        {isSubscriptionMenu && (
          <ChangeSubscriptionDialog
            open={openSubDialog}
            setOpen={setOpenSubDialog}
            selectedPlan={selected}
            userSubPlan={userSubPlan}
            currency={currency}
            currencyFormatter={currencyFormatter}
          />
        )}
      </Card>
    </div>
  )
}

function ChangeSubscriptionDialog({
  open,
  setOpen,
  selectedPlan,
  userSubPlan,
  currency,
  currencyFormatter,
}) {
  const userInfo = useRecoilValue(userAtom)
  const [userSubInfo, setUserSubInfo] = useState(null)
  const [userCredit, setUserCredit] = useState(null)
  const [nextPayday, setNextPayday] = useState(null)
  const [change, setChange] = useState() // up or down
  const [isLoading, setIsLoading] = useState(false)
  const [subEventApplied, setSubEventApplied] = useState(false)

  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()

  const { getPlanDisplayName } = usePricing()

  useEffect(() => {
    // console.log('userSubPlan', userSubPlan)
    // console.log('selectedPlan', selectedPlan)
  })

  // selectedPlan.creditAmount < userSubPlan.creditAmount
  useEffect(() => {
    if (selectedPlan && userSubPlan) {
      if (selectedPlan.creditAmount < userSubPlan.creditAmount) {
        setChange('down')
      } else {
        setChange('up')
      }
    } else {
      setChange(null)
    }
  }, [selectedPlan, userSubPlan])

  useEffect(() => {
    if (open) {
      apis.user.getCredit().then(response => {
        setUserCredit(response.data.credit)
      })
      apis.user.getUserSubscription().then(response => {
        const lastPaid = response.data.last_paid

        if (lastPaid) {
          const utcDate = moment.utc(lastPaid).toDate()
          const localNextMonth = moment(utcDate).add(1, 'M').local().format('YYYY-MM-DD')
          setNextPayday(localNextMonth)

          setSubEventApplied(checkAppliedSubEvent(response.data))
        } else {
          setNextPayday('')
        }
        setUserSubInfo(response.data)
      })
    }
  }, [open])
  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeSubscription = () => {
    setIsLoading(true)

    const d = {}
    addSessionStorageData(d)

    apis.user
      .changeSubscription({
        new_plan_id: selectedPlan.id,
        current_subscription_id: userSubInfo.id,
        additional_payment_data: d,
      })
      .then(response => {
        handleClose()

        if (!response.data.success) {
          alert('결제 요청 중 오류가 발생하였습니다.')
          return
        }

        const paidPrice = response.data.payment_response?.PCD_PAY_TOTAL

        // ----- GA4 event -----
        const transactionId = generatePaymentUid()
        window.gtag('event', 'purchase', {
          item_category: 'subscription',
          item_id: response.data.payment_info.plan_id,
          item_name: getPlanDisplayName(response.data.payment_info.plan_id),
          price: paidPrice,
          currency: response.data.payment_info.currency ?? 'KRW',
          transaction_id: transactionId,
        })
        // ---------------------

        // ----- Google Ads event -----
        window.gtag_report_conversion_purchase(
          paidPrice,
          transactionId,
          response.data.payment_info.currency
        )

        // ----------------------------
        // ----- Naver Ads event -----
        var _nasa = {}
        if (window.wcs) _nasa.cnv = window.wcs.cnv('purchase', paidPrice)
        if (window.runNaScript) window.runNaScript()
        // ----------------------------
        // ----- Meta(facebook) pixel event -----
        window.fbq('track', 'Purchase', {
          value: paidPrice,
          currency: response.data.payment_info.currency ?? 'KRW',
        })
        // --------------------------------------
        // ----- linkedin event -----
        window.lintrk('track', { conversion_id: 17109489 })
        // --------------------------

        const pRes =
          response.data.payment_info.currency === 'USD'
            ? response.data.payment_response.response
            : response.data.payment_response

        navigate('/paid', {
          state: {
            paymentResponse: pRes,
            paymentInfo: response.data.payment_info,
            changeSubscription: response.data.change_subscription,
          },
        })
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          borderRadius: '20px',
          boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
          margin: '20px',
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            background: 'black',
            opacity: 0.3,
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 99,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <DialogContent
        sx={{
          width: { lg: '500px', xs: '320px' },
          p: { lg: '37px 0 30px 0', xs: '22px 12px 30px 12px' },
        }}
      >
        <CenterAlignStack>
          <Typography sx={{ fontWeight: 700, fontSize: { lg: '20px' } }}>
            {t('pricing.pricingCard.change_subscription_title_1')}
            <span style={{ color: theme.palette.draph.blue }}>
              {' '}
              {t('pricing.pricingCard.change_subscription_title_2')}
            </span>
            {t('pricing.pricingCard.change_subscription_title_3')}
          </Typography>

          {userSubPlan?.id && selectedPlan?.id && (
            <Table sx={{ mt: { lg: '13px', xs: '8px' }, mb: '2rem' }}>
              <TableBody
                sx={{
                  '& .MuiTableCell-head': {
                    color: theme.palette.common.black,
                    textAlign: 'right',
                    fontSize: { lg: '15px', xs: '11px' },
                    fontWeight: 700,
                  },
                  '& .MuiTableCell-body': { textAlign: 'center' },
                  '& .MuiTableRow-root:nth-of-type(even) .MuiTableCell-body': {
                    backgroundColor: theme.palette.common.lightgray,
                  },
                  '& .MuiTableRow-root:nth-of-type(odd) .MuiTableCell-body': {
                    padding: { lg: '16px', xs: '20px 9px' },
                  },

                  '& .MuiTableCell-root': {
                    borderBottom: 0,
                    padding: { lg: '16px', xs: '5px 7px' },
                    fontSize: { lg: '15px', xs: '11px' },
                    lineHeight: 'normal',
                  },

                  '& .MuiTableRow-root:last-child .MuiTableCell-root ': {
                    padding: { lg: '10px 16px', xs: '7px 9px' },
                  },
                }}
              >
                <TableRow>
                  <TableCell variant="head">
                    {t('pricing.pricingCard.change_subscription_row_1')}
                  </TableCell>
                  <TableCell colSpan={2} sx={{ textAlign: 'center', fontWeight: 700 }}>
                    {userCredit} {t('credit.credit')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    {' '}
                    {t('pricing.pricingCard.change_subscription_row_2_a')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {trasnferDate(moment(nextPayday), 'detail', i18n)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700, color: theme.palette.draph.blue }}>
                    {change === 'down'
                      ? trasnferDate(moment(nextPayday), 'detail', i18n)
                      : t('pricing.pricingCard.change_subscription_row_2_b')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">
                    {' '}
                    {t('pricing.pricingCard.change_subscription_row_3_a')}
                  </TableCell>
                  <TableCell>
                    <CenterAlignStack>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: { lg: '13px', xs: '10px' },
                          lineHeight: { lg: '16px', xs: '12px' },
                        }}
                      >
                        {t('pricing.pricingCard.change_subscription_row_3_b')}
                      </Typography>
                      <Typography sx={{ fontSize: { lg: '18px', xs: '13px' }, fontWeight: 600 }}>
                        {getPlanDisplayName(userSubPlan.id)}
                      </Typography>
                      <Typography
                        sx={{ fontSize: { lg: '12px', xs: '10px' }, textAlign: 'center' }}
                      >
                        {numberCommaFormatter(userSubPlan.creditAmount)}{' '}
                        {t('pricing.pricingCard.change_subscription_row_3_c')}
                        <br />
                        {currencyFormatter.format(userSubPlan.price[currency]).slice(1)}{' '}
                        {t('pricing.pricingCard.change_subscription_row_3_d')}
                      </Typography>
                    </CenterAlignStack>
                  </TableCell>
                  <TableCell>
                    <CenterAlignStack>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: { lg: '13px', xs: '10px' },
                          lineHeight: { lg: '16px', xs: '12px' },
                          color: theme.palette.draph.blue,
                        }}
                      >
                        {t('pricing.pricingCard.change_subscription_row_3_e')}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: '18px', xs: '13px' },
                          fontWeight: 600,
                          color: theme.palette.draph.blue,
                        }}
                      >
                        {getPlanDisplayName(selectedPlan.id)}
                      </Typography>
                      <Typography
                        sx={{ fontSize: { lg: '12px', xs: '10px' }, textAlign: 'center' }}
                      >
                        {numberCommaFormatter(selectedPlan.creditAmount)}{' '}
                        {t('pricing.pricingCard.change_subscription_row_3_c')}
                        <br />
                        {currencyFormatter.format(selectedPlan.price[currency]).slice(1)}{' '}
                        {t('pricing.pricingCard.change_subscription_row_3_d')}
                      </Typography>
                    </CenterAlignStack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head" sx={{ lineHeight: { xs: '13px' } }}>
                    {t('pricing.pricingCard.change_subscription_row_4_a')}
                    <br />
                    {t('pricing.pricingCard.change_subscription_row_4_b')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {numberCommaFormatter(userCredit + userSubPlan.creditAmount)}{' '}
                    {t('credit.credit')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>
                    {numberCommaFormatter(userCredit + selectedPlan.creditAmount)}{' '}
                    {t('credit.credit')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}

          {subEventApplied && userSubPlan.creditAmount < selectedPlan.creditAmount ? (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: { lg: '1.4rem', xs: '1.2rem' },
                fontWeight: 500,
                color: '#FF2323',
                lineHeight: { lg: 1.2, xs: 1.1 },
                px: { lg: '6rem', xs: '1rem' },
              }}
            >
              {t('pricing.pricingCard.change_event_subscription_notice_a')}
            </Typography>
          ) : (
            <Typography fontSize="1.2rem" fontWeight={400}>
              {t('pricing.pricingCard.change_subscription_notice')}
            </Typography>
          )}
        </CenterAlignStack>
      </DialogContent>

      <DialogActions sx={{ p: 0 }}>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            width: '50%',
            height: { lg: '48px', xs: '40px' },
            fontSize: { lg: '16px', xs: '14px' },
            fontWeight: 600,
            backgroundColor: theme.palette.common.black,
            borderRadius: 0,
            color: theme.palette.common.white,
            '&:hover': {
              color: theme.palette.common.white,
            },
          }}
        >
          {t('button.keep_plan')}
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            handleChangeSubscription()
          }}
          sx={{
            ml: '0 !important',
            width: '50%',
            height: { lg: '48px', xs: '40px' },
            fontSize: { lg: '16px', xs: '14px' },
            fontWeight: 600,
            backgroundColor: theme.palette.common.white,
            borderRadius: 0,
            color: theme.palette.common.black,
            '&:hover': {
              backgroundColor: '#F8F8F8',
              color: theme => theme.palette.common.black,
            },
          }}
        >
          {t('button.change_plan_l')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const PrefixMonthly = ({ price, isSubscription }) => {
  const theme = useTheme()
  const isMobile = useMobileMediaQuery()
  return (
    <>
      <Korean>
        {isSubscription && (
          <span
            style={{
              fontSize: isMobile ? '1rem' : '1.2rem',
              fontWeight: 600,
              backgroundColor: theme.palette.draph.blue,
              color: theme.palette.common.white,
              padding: isMobile ? '0.2rem 0.6rem' : '0.2rem 0.6rem',
              borderRadius: '4px',
              marginRight: '0.4rem',
              lineHeight: 'normal',
            }}
          >
            매달
          </span>
        )}
      </Korean>

      {price}
    </>
  )
}

function PriorityBadge() {
  // 구독 요금제 카드에 붙는 우선생성 딱지
  const { t } = useTranslation()

  return (
    <>
      <CenterAlignBox
        sx={{
          alignItems: 'center',
          background: theme => theme.palette.draph.blue,
          py: { lg: '0.7rem', xs: '0.5rem' },
          px: { lg: '1.1rem', xs: '0.8rem' },
          borderRadius: '0.4rem',
        }}
      >
        <svg
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.83333 0H9.28788L5.95455 4.2H10.5L3.22727 12L4.74242 6.3H0.5L3.83333 0Z"
            fill="#1FFF50"
          />
        </svg>

        <Typography
          sx={{
            color: 'white',
            fontSize: { lg: '1.2rem', xs: '1.1rem' },
            fontWeight: 700,
            ml: '0.2rem',
          }}
        >
          {t('pricing.pricingCard.priority_generation')}
        </Typography>
      </CenterAlignBox>
    </>
  )
}
