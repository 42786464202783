import { AgreeDialog, CenterAlignBox, CenterAlignStack } from 'components'
import styles from '../promotionEvents/DomeggookEvent.module.css'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'

import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import { ExperienceSection, PresetSamples, mobileWidthVW } from 'pages/Home'
import { useEffect, useState } from 'react'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { CheckIcon, CheckedSquareIcon2, MiniCheckBox, MiniSquare, SquareIcon2 } from 'theme/icon'
import { useRecoilState, useRecoilValue } from 'recoil'
import { creditPlansAtom, userAtom } from 'atoms'
import { apis } from 'apis'
import { generatePaymentUid } from 'pages/Pricing'
import { getNextPaidDay } from 'pricing'
import moment from 'moment'
import { numberCommaFormatter } from 'utils/common'
import { useNavigateForPromotion, useNavigateWithQuery } from 'hooks/useNavigateWithQuery'
import { useTranslation } from 'react-i18next'
import { usePricing } from 'hooks/usePricing'
import { promotionInfo } from './DomeggookEvent'
import { BeforeAfterSlider } from '../Home'
import { getAccessToken } from 'utils/cookie'

const eventPriceKrw = 100
const eventPaymentType = 'subscription'
const eventId = 'shoplinker_100_event_2402'

export const promotionInfoKeys = Object.keys(promotionInfo)

export default function ShoplinkerEvent() {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const { getPlanDisplayName } = usePricing()
  const { navigateWithQuery } = useNavigateForPromotion()
  const [beforeImage, setBeforeImage] = useState('/static/images/main/sample_1_before.png')
  const [afterImage, setAfterImage] = useState('/static/images/main/sample_1_after.png')
  const [isAgree, setIsAgree] = useState(false)

  const plans = useRecoilValue(creditPlansAtom)
  const [userInfo, setUserInfo] = useRecoilState(userAtom)
  const navigate = useNavigate()

  const plan = plans?.subscription?.filter(p => p.id === 'monthly200')[0]

  useEffect(() => {
    if (localStorage.getItem('promotionQuery')) {
      localStorage.clear('promotionQuery')
    }
  }, [])

  const requestPay = async () => {
    // if (!userInfo.id) {
    //   navigateWithQuery('/login')
    //   return
    // }

    if (!plan) {
      alert(t('common.error'))
      return
    }

    const response = await apis.user.checkEvent(eventId, {})
    if (response.data.applied) {
      alert(response.data.message)
      return
    }

    const orderId = generatePaymentUid()
    const customPaymentInfo = {
      payment_type: eventPaymentType.toLowerCase(),
      payment_cycle: plan.cycle,
      order_id: orderId,
      credit_amount: plan.creditAmount,
      plan_id: plan.id,
      plan_name: getPlanDisplayName(plan.id),
    }

    const b = {
      PCD_PAY_TYPE: 'card',
      PCD_PAY_WORK: 'PAY',
      PCD_CARD_VER: eventPaymentType === 'subscription' ? '01' : '02',
      PCD_PAY_GOODS: '드랩 아트 - ' + getPlanDisplayName(plan.id),
      PCD_PAY_TOTAL: eventPriceKrw,
      PCD_RST_URL: '/paid',

      PCD_PAY_OID: orderId,
      PCD_PAYER_NO: userInfo.id,
      PCD_PAYER_NAME: userInfo.username,
      PCD_PAYER_HP: userInfo.phone,
      PCD_PAYER_EMAIL: userInfo.email,
      PCD_USER_DEFINE1: '',
      PCD_USER_DEFINE2: '',

      callbackFunction: res => {
        // 데이터는 웹훅에서 받아서 처리
        if (res.PCD_PAY_RST === 'success') {
          apis.user.applyEvent(eventId, {})
          paymentCallback(res, { ...customPaymentInfo })
        }
      },
    }

    apis.payment
      .auth({ ...b, ...customPaymentInfo })
      .then(response => {
        b.PCD_AUTH_KEY = response.data.PCD_AUTH_KEY
        b.PCD_PAY_URL = response.data.PCD_PAY_URL

        // ----- GA4 event -----
        window.gtag('event', 'begin_checkout', {
          item_category: eventPaymentType, // subscription || paygo
          item_id: plan.id,
          item_name: getPlanDisplayName(plan.id),
          price: eventPriceKrw,
          currency: 'KRW',
        })
        // ---------------------

        window.PaypleCpayAuthCheck(b)
      })
      .catch(() => {
        alert(t('payment.fail'))
      })

    const paymentCallback = (response, paymentInfo) => {
      // ----- GA4 event -----
      window.gtag('event', 'purchase', {
        item_category: eventPaymentType, // subscription || paygo
        item_id: plan.id,
        item_name: getPlanDisplayName(plan.id),
        price: eventPriceKrw,
        currency: 'KRW',
        transaction_id: orderId,
      })
      // ---------------------

      // ----- Google Ads event -----
      window.gtag_report_conversion_purchase(eventPriceKrw, orderId)
      // ----------------------------
      // ----- Naver Ads event -----
      var _nasa = {}
      if (window.wcs) _nasa.cnv = window.wcs.cnv('purchase', eventPriceKrw)
      if (window.runNaScript) window.runNaScript()
      // ----------------------------
      // ----- Meta(facebook) pixel event -----
      window.fbq('track', 'Purchase', {
        value: eventPriceKrw,
        currency: 'KRW',
      })
      // --------------------------------------
      // ----- linkedin event -----
      window.lintrk('track', { conversion_id: 17109489 })
      // --------------------------

      navigate('/paid', { state: { paymentResponse: response, paymentInfo } })
    }
  }

  return (
    <div className={styles.customFontContainer} style={{ minWidth: isMobile ? '36rem' : 'unset' }}>
      <CenterAlignStack sx={{ width: '100%' }}>
        <CenterAlignBox sx={{ width: '100%', backgroundColor: theme.palette.common.black }}>
          <img
            style={{ width: isMobile ? '36rem' : '75rem' }}
            src="/static/images/promotion/shoplinker/shoplinker_promotion_1.jpg"
          />
        </CenterAlignBox>

        <CenterAlignStack sx={{ width: '100%' }}>
          <CenterAlignStack
            sx={{
              width: '100%',
              height: { lg: '90.8rem', xs: 'unset' },
              backgroundColor: theme.palette.draph.blue,
              position: 'relative',
              // flexDirection: { lg: 'row', xs: 'column' },
              // alignItems: { lg: 'flex-start', xs: 'center' },
            }}
          >
            <img
              style={{ width: isMobile ? '36rem' : '75rem' }}
              src="/static/images/promotion/shoplinker/shoplinker_promotion_2.jpg"
            />
            <Box
              sx={{
                width: { lg: '83rem', xs: '36rem' },
                '& .laptop': {
                  height: { xs: '27rem' },
                },
                '& .laptop .laptop-content': {
                  minWidth: { xs: '26rem !important', lg: '63.5rem !important' },
                  maxWidth: { xs: '27.5rem !important', lg: '63.5rem !important' },
                  width: { lg: '63.5rem !important' },
                  height: { lg: '41rem' },
                  top: { lg: '1.8rem', xs: '0.9rem' },
                  aspectRatio: { xs: '1.55 / 1' },
                },
                '& .laptop .laptop-image': {
                  minWidth: { xs: '34rem !important' },
                },
              }}
            >
              <BeforeAfterSlider hideText />
            </Box>
          </CenterAlignStack>
        </CenterAlignStack>
        <CenterAlignStack sx={{ width: '100%', backgroundColor: '#F8F8F8' }}>
          <img
            style={{ width: isMobile ? '36rem' : '75rem' }}
            src="/static/images/promotion/shoplinker/shoplinker_promotion_3.jpg"
          />
        </CenterAlignStack>

        <CenterAlignStack sx={{ width: '100%', backgroundColor: theme.palette.draph.blue }}>
          <img
            style={{ width: isMobile ? '36rem' : '75rem' }}
            src="/static/images/promotion/shoplinker/shoplinker_promotion_4.jpg"
          />
        </CenterAlignStack>

        <CenterAlignStack
          sx={{
            width: '100%',
            height: { lg: '125rem', xs: '91.6rem' },
            backgroundColor: theme.palette.common.black,
            p: { lg: '9.6rem 0 10rem 0', xs: '4.8rem 0' },
            '& .title.MuiTypography-root': {
              fontSize: isMobile ? '2.2rem' : '4.4rem',
              fontWeight: 900,
              color: theme.palette.common.white,
              '& span': { color: '#FFF94D' },
              fontFamily: 'GmarketSans',
              lineHeight: 1.2,
            },
          }}
        >
          <Typography className="title">무료 체험이 만족스러웠다면</Typography>
          <Typography className="title">
            <span>100원으로 첫 구독</span>을 시작하세요!
          </Typography>

          <CenterAlignStack
            sx={{
              width: { lg: '51rem', xs: '33.2rem' },
              height: { lg: '31.6rem', xs: '26rem' },
              backgroundColor: theme.palette.common.white,
              mt: { lg: '5rem', xs: '3.6rem' },
              p: { lg: '4.5rem 4.2rem', xs: '3.6rem 0 3.2rem 0' },
              borderRadius: { lg: '30px', xs: '20px' },
            }}
          >
            <p style={{ fontWeight: 700, fontSize: isMobile ? '1.8rem' : '2.6rem' }}>
              1. 드랩아트 무료 체험하기
            </p>
            <CenterAlignStack sx={{ m: { lg: '3rem 0 2.7rem 0', xs: '2.5rem 0' }, gap: '1.2rem' }}>
              <Button
                variant="contained"
                sx={{
                  width: { lg: '20rem', xs: '18rem' },
                  height: { lg: '4.8rem', xs: '4rem' },
                  fontSize: '1.8rem',
                  fontWeight: 800,
                }}
                onClick={() => {
                  window.open(`${window.location.origin}/login`, '_blank').focus()
                }}
              >
                로그인하기
              </Button>
            </CenterAlignStack>
            <Box sx={{ width: { lg: '30rem', xs: '24.5rem' } }}>
              <Typography sx={{ fontSize: { lg: '1.5rem', xs: '1.2rem' } }}>
                <span style={{ color: '#FF2323' }}>* 가입 즉시 5회 무료 이용 가능</span> <br />
                * SNS 계정 로그인 가능 (구글, 카카오)
                <br />* 로그인 후 본 페이지에서만 혜택가로 구독 가능
              </Typography>
            </Box>
          </CenterAlignStack>

          <CenterAlignStack
            sx={{
              width: { lg: '51rem', xs: '33.2rem' },

              backgroundColor: theme.palette.common.white,
              mt: { lg: '4rem', xs: '2.1rem' },
              p: { lg: '4.5rem 3.5rem 4.6rem 3.5rem', xs: '3.6rem 1.6rem 3.8rem' },
              borderRadius: { lg: '30px', xs: '20px' },
            }}
          >
            <p style={{ fontWeight: 700, fontSize: isMobile ? '1.8rem' : '2.6rem' }}>
              2. 월정액 구독 시작하기
            </p>

            <img
              style={{
                width: isMobile ? '28.4rem' : '35rem',
                marginTop: isMobile ? '2rem' : '2.7rem',
              }}
              src="/static/images/promotion/shoplinker/shoplinker_promotion_5.png"
            />

            <Box
              sx={{
                mt: { lg: '2.6rem', xs: '2rem' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.7rem',
                }}
              >
                <CheckIcon />{' '}
                <Typography sx={{ fontSize: { lg: '1.5rem', xs: '1.2rem' }, fontWeight: 400 }}>
                  200크레딧 충전, <span style={{ fontWeight: 800 }}>약 200회</span> 상품 사진 생성
                  가능
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.7rem',
                  mt: { lg: 0, xs: '0.4rem' },
                }}
              >
                <CheckIcon />{' '}
                <Typography sx={{ fontSize: { lg: '1.5rem', xs: '1.2rem' }, fontWeight: 400 }}>
                  구독 해지 전까지 미사용 크레딧 <span style={{ fontWeight: 800 }}>무한 이월</span>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.7rem',
                  mt: { lg: 0, xs: '0.4rem' },
                }}
              >
                <CheckIcon />{' '}
                <Typography
                  sx={{
                    fontSize: { lg: '1.5rem', xs: '1.2rem' },
                    fontWeight: 400,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ fontWeight: 800 }}>요금제 변경&nbsp;</span>및 구독 취소 가능
                  <RouterLink
                    to="/pricing"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'unset',
                      fontSize: '1.2rem',
                      fontWeight: 400,
                      marginLeft: '0.5rem',
                    }}
                  >
                    요금제 보기 {'>'}
                  </RouterLink>{' '}
                </Typography>
              </Box>
            </Box>

            <Divider flexItem sx={{ my: { lg: '3.2rem', xs: '2.3rem' }, borderColor: '#D9D9D9' }} />

            <FormControlLabel
              control={
                <Checkbox
                  sx={{ p: 0, mr: '1rem' }}
                  icon={isMobile ? <MiniSquare /> : <SquareIcon2 />}
                  checkedIcon={isMobile ? <MiniCheckBox /> : <CheckedSquareIcon2 />}
                  checked={isAgree}
                  onChange={(e, c) => {
                    setIsAgree(c)
                  }}
                />
              }
              sx={{
                m: 0,

                '& span.MuiTypography-root': {
                  fontSize: { lg: '1.4rem', xs: '1rem' },
                  fontWeight: 400,
                },
              }}
              label="상품, 가격, 할인 정보, 유의 사항 등을 확인하였으며 구매에 동의합니다.​"
            />

            <Button
              disabled={!isAgree}
              variant="contained"
              sx={{
                width: { lg: '36rem', xs: '20rem' },
                height: { lg: '5.2rem', xs: '3.6rem' },
                fontSize: { lg: '1.8rem', xs: '1.4rem' },
                fontWeight: { lg: 800, xs: 700 },
                m: { lg: '3.8rem 0 2.4rem 0', xs: '1.6rem 0 2.5rem 0' },
              }}
              onClick={() => {
                // requestPay()

                const token = getAccessToken()
                if (token) {
                  apis.user.getUser().then(response => {
                    setUserInfo(response.data)
                    requestPay()
                  })
                } else {
                  alert('로그인 회원만 결제 진행이 가능합니다.')
                }
              }}
            >
              결제하기
            </Button>

            <Typography
              textAlign="center"
              sx={{ fontSize: { lg: '1.4rem', xs: '1rem' }, fontWeight: 600 }}
            >
              첫 달에는 <span style={{ color: theme.palette.draph.blue }}>100원</span>이 결제되고{' '}
              <span style={{ color: theme.palette.draph.blue }}>200크레딧</span>이 충전되며, <br />
              이후 매월 결제일마다 <span style={{ color: theme.palette.draph.blue }}>49,900원</span>
              이 결제되고 <span style={{ color: theme.palette.draph.blue }}>200크레딧</span>이
              자동충전됩니다.
            </Typography>
          </CenterAlignStack>
        </CenterAlignStack>

        <CenterAlignStack sx={{ py: '5rem' }}>
          <Typography
            sx={{
              fontSize: { lg: '3.2rem', xs: '2rem' },
              fontWeight: 800,
              fontFamily: 'GmarketSans',
            }}
          >
            드랩아트를 체험해보세요.
          </Typography>
          <PresetSamples sx={{ pt: { lg: '3rem', xs: '3rem' } }} />
        </CenterAlignStack>

        <CenterAlignStack
          sx={{
            width: '100%',
            backgroundColor: '#F8F8F8',
            p: { lg: '4.2rem 0 4.1rem 0', xs: '2.6rem 0 2.7rem 0' },
          }}
        >
          <Typography
            fontSize={{ lg: '2rem', xs: '1.4rem' }}
            fontWeight={600}
            sx={{ mb: { lg: '2rem', xs: '1.6rem' }, alignSelf: { lg: 'center', xs: 'center' } }}
          >
            결제 유의사항 및 이벤트 안내
          </Typography>
          <Box
            component="ul"
            sx={{
              padding: { xs: '0 1.1rem 0 1.7rem', lg: '0 0 0 1.5rem' },
              maxWidth: { lg: 'none', xs: '36rem' },

              '& li': {
                fontSize: { lg: '1.6rem', xs: '1.2rem' },
                fontWeight: 400,
                lineHeight: { lg: '2.5rem' },
                mb: { xs: '1rem' },
                listStyleType: 'none',
                textIndent: { lg: '-0.8rem', xs: '-0.9rem' },
                '&::before': {
                  content: '"•"',
                  marginRight: { lg: '0.7rem', xs: '0.4rem' },
                },
              },
            }}
          >
            <Typography component="li">
              본 혜택은 현재 접속한 페이지에서만 적용되며, 별도 종료 안내가 없는 한 상시로
              진행됩니다.
            </Typography>
            <Typography component="li">
              본 혜택은 ‘월정액 200 요금제’에 한하며, 드랩아트 계정 당 1회만 제공됩니다.
            </Typography>
            <Typography component="li">
              매월 정기 결제되는 월정액 구독의 경우, 최초 결제 카드 등록 시 유효성 검증을 위해 100원
              결제 후 바로 전액이 취소되는 100원 인증이 진행됩니다.
            </Typography>
            <Typography component="li">
              다음달 구독을 취소해도 첫 구독 100원 혜택은 유지됩니다.
            </Typography>

            <Typography component="li">
              구독 후 언제든지 월정액 요금제 변경이 가능합니다. 단, 첫 구독 100원 혜택은 ‘월정액 200
              요금제’에 한하기 때문에 요금제 변경 후 첫 달부터 정상가로 결제됩니다.
            </Typography>
            <Typography component="li">
              높은 요금제로 변경하는 경우에는 변경일부터 새롭게 구독이 시작되기 때문에 변경한
              요금제의 가격만큼 다시 결제됩니다.
            </Typography>

            <Typography component="li">
              결제 후 7일 이내 충전된 크레딧을 사용하지 않은 경우 청약철회가 가능하며 청약철회에
              대한 상세 내용은{' '}
              <AgreeDialog
                category="Terms"
                openButton={
                  <span style={{ textDecoration: 'underline' }}>관련 조건 및 환불 약관</span>
                }
              />
              을 확인해 주세요.
            </Typography>
            <Typography component="li">
              월정액 구독의 경우 다음 결제 때 결제일(예: 31일)에 해당하는 날이 없는 달에는 그달의
              말일에 결제가 이루어집니다.
            </Typography>
            <Typography component="li">
              월정액 구독으로 충전된 크레딧의 경우 유효 기간은 1개월입니다. 단, 구독을 연장하는 경우
              전월에 미사용한 크레딧의 유효 기간 역시 자동 연장됩니다.
            </Typography>

            <Typography component="li">
              내부 사정에 따라 이벤트 기간, 혜택 등이 변경될 수 있습니다.
            </Typography>

            <Typography component="li">
              그 외 문의사항은{' '}
              <RouterLink
                to="/FAQ"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'unset' }}
              >
                FAQ
              </RouterLink>{' '}
              및 1:1문의를 이용해 주세요.
            </Typography>
          </Box>
        </CenterAlignStack>
      </CenterAlignStack>
    </div>
  )
}
