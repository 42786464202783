import { Box } from '@mui/material'
import { motion } from 'framer-motion'

export default function TwoImageSlider({
  size,
  sx,
  image1,
  image2,
  position = { top: 0 },
  duration = 10,
  image1Style = {},
  image2Style = {},
}) {
  return (
    <Box component={motion.div} sx={{ position: 'relative', ...sx }}>
      <Box
        sx={{ ...size }}
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 0, 1, 1, 1, 1, 0, 0] }}
        transition={{ duration, repeat: Infinity }}
      >
        <img src={image1} style={{ ...image1Style }} />
      </Box>
      <Box
        sx={{
          ...size,
          position: 'absolute',
          ...position,
        }}
        component={motion.div}
        initial={{ opacity: 1 }}
        animate={{ opacity: [1, 1, 0, 0, 0, 0, 1, 1] }}
        transition={{ duration, repeat: Infinity }}
      >
        <img src={image2} style={{ ...image2Style }} />
      </Box>
    </Box>
  )
}
