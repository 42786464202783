import { Box } from '@mui/material'

export function YouTubeVideo({ videoId }) {
  return (
    <Box>
      <iframe
        style={{ width: '20rem', height: '14rem' }}
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Box>
  )
}
