import { Dialog, DialogContent, Divider, Typography } from '@mui/material'
import { apis } from 'apis'
import { pieceConfigAtom, portfolioDetailAtom } from 'atoms'
import { TextEditor } from 'components'
import { CenterAlignStack } from 'components/CenterAlignContainer'
import { DialogButton } from 'components/DialogButtons'
import { Desktop } from 'hooks/useMediaQuery'
import { TestPage17 } from 'pages'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PuffLoader } from 'react-spinners'
import { useRecoilState, useRecoilValue } from 'recoil'
import { canvasToBlob, hexToRgb, loadCanvasFromJSON } from 'utils/common'
import { fabric } from 'fabric'

export default function BannerEditor({ open, setOpen, artwork, setArtwork, currentPiece, copy }) {
  const { t, i18n } = useTranslation()
  const canvasRef = useRef(null)
  const [canvas, setCanvas] = useState(null)
  const [scale, setScale] = useState(null)
  const [pieceConfig, setPieceConfig] = useRecoilState(pieceConfigAtom)
  const [loading, setLoading] = useState(false)

  const portfolioDetail = useRecoilValue(portfolioDetailAtom)

  const refreshArtwork = () => {
    apis.portfolio.getArtwork(portfolioDetail.id, artwork.id).then(response => {
      if (response.data) {
        setLoading(false)
        setArtwork({ ...response.data })
      }
    })
  }

  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return
    setOpen(false)
  }

  const handleAction = async () => {
    setLoading(true)

    // ----- GA4 event -----
    window.gtag('event', 'banner_edit_complete', {})
    // ---------------------

    const portfolioId = portfolioDetail.id
    const artworkId = artwork.id
    const pieceId = currentPiece.id

    const currentZoom = canvas.getZoom()
    const originalWidth = canvas.getWidth()
    const originalHeight = canvas.getHeight()

    // canvas.setZoom(1)
    // canvas.setWidth(originalWidth / scale)
    // canvas.setHeight(originalHeight / scale)

    const canvasData = canvas.toJSON()

    // 새로운 캔버스 인스턴스 생성
    const newCanvas = new fabric.Canvas('newCanvas') // 'newCanvas'는 새 캔버스의 DOM id

    await loadCanvasFromJSON(newCanvas, canvasData)

    // newCanvas.renderAll() // 새 캔버스 렌더링

    newCanvas.setZoom(1)
    newCanvas.setWidth(originalWidth / scale)
    newCanvas.setHeight(originalHeight / scale)

    const canvasEl = newCanvas.toCanvasElement()
    const imageURL = newCanvas.toDataURL('image/png')

    const dataURL = canvas.toDataURL({
      format: 'png',
      quality: 1.0,
    })
    const blob = await canvasToBlob(canvasEl)

    const fileName = 'image.png' // 원하는 파일 이름 설정
    const image = new File([blob], fileName, { type: 'image/png' })

    // console.log(imageURL)

    const addConfig = {}

    canvas.getObjects().forEach(obj => {
      const activeObject = canvas.getActiveObject()
      const innerObject = canvas.getObjects()

      innerObject.forEach(o => {
        if (o.type === 'textbox' || o.type === 'image') {
          canvas.setActiveObject(o)
        }
      })

      if (obj.type === 'textbox') {
        // textbox인 경우에만 확인

        if (obj.id === 'title') {
          addConfig.main_text_color = hexToRgb(obj.fill)
          addConfig.main_text_font = obj.fontFamily
          addConfig.main_text_size = obj.fontSize * obj.scaleX
          addConfig.main_text_lu_pt = [obj.left, obj.top]
          addConfig.main_text_angle = obj.angle
        } else if (obj.id === 'subtitle') {
          addConfig.sub_text_color = hexToRgb(obj.fill)
          addConfig.sub_text_font = obj.fontFamily
          addConfig.sub_text_size = obj.fontSize * obj.scaleX
          addConfig.sub_text_lu_pt = [obj.left, obj.top]
          addConfig.sub_text_angle = obj.angle
        }
      } else if (obj.type === 'image') {
        addConfig.logo_lu_pt = [obj.left, obj.top]
        addConfig.logo_size = [obj.width * obj.scaleX, obj.height * obj.scaleY]
      }
    })
    const config = { ...pieceConfig, ...addConfig }

    // canvas.setZoom(currentZoom)
    // canvas.setWidth(originalWidth)
    // canvas.setHeight(originalHeight)

    const formData = new FormData()
    formData.append('image', image)
    formData.append('config', JSON.stringify(config))

    apis.portfolio
      .updatePieceImage(portfolioId, artworkId, pieceId, formData)
      .then(r => {
        refreshArtwork()
        handleClose()
      })
      .catch(() => {
        setLoading(false)
        console.log('오류가 발생하였습니다.')
      })
  }

  useEffect(() => {
    setPieceConfig(currentPiece.config)
  }, [])

  // useEffect(() => {
  //   console.log(pieceConfig)
  // }, [pieceConfig])

  return (
    <Desktop>
      <Dialog
        disableEnforceFocus={true}
        open={open}
        sx={{
          overflowX: 'hidden',
          '& .MuiDialog-paper': {
            minWidth: '832px',
            minHeight: '774px',
            borderRadius: '20px',
          },
        }}
        onClose={handleClose}
      >
        <DialogContent sx={{ m: 0, p: 0 }}>
          {pieceConfig ? (
            <>
              {' '}
              <CenterAlignStack className="title" sx={{ p: '26px 0 20px 0' }}>
                <Typography
                  sx={{
                    fontSize: '22px',
                    fontWeight: 700,
                    lineHeight: 'normal',
                  }}
                >
                  {t('banner_config.control_banner_editor')}
                </Typography>
                <Typography sx={{ fontSize: '15px' }}>
                  {t('banner_config.editor_subtitle')}
                </Typography>
              </CenterAlignStack>
              <Divider sx={{ borderColor: '#AFAFAF' }} />
              <TextEditor
                canvasRef={canvasRef}
                canvas={canvas}
                setCanvas={setCanvas}
                artwork={artwork}
                currentPiece={currentPiece}
                copy={copy}
                scale={scale}
                setScale={setScale}
              />
              <Divider sx={{ borderColor: '#AFAFAF' }} />
              <CenterAlignStack className="action" sx={{ p: '22px 0 23px 0' }}>
                <DialogButton
                  actionText={loading ? <PuffLoader /> : t('banner_config.regenerate')}
                  handleClose={handleClose}
                  handleAction={handleAction}
                  actionprops={{ disabled: loading }}
                />
              </CenterAlignStack>
            </>
          ) : (
            <CenterAlignStack sx={{ justifyContent: 'center', minHeight: '774px' }}>
              <PuffLoader />
            </CenterAlignStack>
          )}
        </DialogContent>
      </Dialog>
    </Desktop>
  )
}
