import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { CenterAlignBox, CenterAlignStack, DialogButton } from 'components'
import { useEffect, useState, useRef } from 'react'
import {
  ArtworkGrayCloseIcon,
  BadExampleIcon,
  CloseIcon,
  MobileArtworkGrayCloseIcon,
  PlusIcon,
  PlusMobileIcon,
  ZipIcon,
} from 'theme/icon'
import './uploadimage.css'
import axios from 'axios'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  defaultPortfolioAtom,
  portfolioArtworkAtom,
  portfolioAtom,
  portfolioConfigAtom,
  portfolioDetailAtom,
  tutorialAtom,
  userAtom,
  portfolioTypeAtom,
  uploadDialogOpenAtom,
  uploadFilesAtom,
  dragDropOpenAtom,
  autoUploadAtom,
  dragAutoUploadAtom,
  isBulkUploadAtom,
  currentMenuAtom,
} from 'atoms'
import { PORTFOLIO_CATEGORY_DICT } from 'config'
import { motion } from 'framer-motion'
import styled from '@emotion/styled'
import { AiFillCloseCircle } from 'react-icons/ai'
import { Checkbox, Divider, FormControlLabel, Grid, SwipeableDrawer, useTheme } from '@mui/material'
import { BsArrowClockwise, BsFillXCircleFill } from 'react-icons/bs'
import { Tutorial } from 'theme/Tutorial'
import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { allowedTypes, iOS, isDev } from 'utils/common'
import * as config from 'config'
import { useTranslation } from 'react-i18next'
import { useRefineUploadFiles } from 'hooks/useRefineUploadFiles'
import { apis } from 'apis'
import { portfolioUploadConfigSelector, userPortfolioNamesSelector } from 'selector'
import { getAccessToken } from 'utils/cookie'
import { useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import moment from 'moment'
import { getUploadUUID } from 'utils/artwork'
import { useSnackbar } from 'notistack'
import { English, Korean } from 'hooks/useLanguage'

const explaingTextStyle = { fontWeight: 500, fontSize: '1.4rem', lineHeight: '1.7rem' }

const buttonStyle = { width: '18.4rem', height: '5rem', fontSize: '1.8rem', fontWeight: 800 }
const mobileButtonStyle = {
  width: '20rem',
  height: '4.8rem',
  fontSize: '1.8rem',
  fontWeight: 800,
  margin: '3rem 0',
}

export const badExample = {
  auto: [
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_1.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>손으로 상품을</Typography>
          <Typography sx={explaingTextStyle}>잡고 있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_2.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>수평이 맞지 않거나 </Typography>
          <Typography sx={explaingTextStyle}>회전되어 있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_3.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>배경이 복잡해 상품과 </Typography>
          <Typography sx={explaingTextStyle}>구분이 힘든 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_4.jpg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>상품이 다른 물체에 </Typography>
          <Typography sx={explaingTextStyle}>가려진 사진</Typography>
        </>
      ),
    },
  ],
  person: [
    {
      img: '/static/images/subCategory/human_bad/human_bad_4.jpeg',
      explain: <Typography sx={explaingTextStyle}>여러명 있는 사진</Typography>,
    },
    {
      img: '/static/images/subCategory/human_bad/human_bad_1.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>수평이 맞지 않거나 </Typography>
          <Typography sx={explaingTextStyle}>회전되어 있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/human_bad/human_bad_2.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>앉아있거나 </Typography>
          <Typography sx={explaingTextStyle}>누워있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/human_bad/human_bad_3.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>다른 물체에 </Typography>
          <Typography sx={explaingTextStyle}>사람이 가려진 사진</Typography>
        </>
      ),
    },
  ],
  food: [
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_1.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>손으로 상품을</Typography>
          <Typography sx={explaingTextStyle}>잡고 있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_2.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>수평이 맞지 않거나 </Typography>
          <Typography sx={explaingTextStyle}>회전되어 있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_3.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>배경이 복잡해 상품과 </Typography>
          <Typography sx={explaingTextStyle}>구분이 힘든 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_4.jpg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>상품이 다른 물체에 </Typography>
          <Typography sx={explaingTextStyle}>가려진 사진</Typography>
        </>
      ),
    },
  ],
  cosmetics: [
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_1.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>손으로 상품을</Typography>
          <Typography sx={explaingTextStyle}>잡고 있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_2.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>수평이 맞지 않거나 </Typography>
          <Typography sx={explaingTextStyle}>회전되어 있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_3.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>배경이 복잡해 상품과 </Typography>
          <Typography sx={explaingTextStyle}>구분이 힘든 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_4.jpg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>상품이 다른 물체에 </Typography>
          <Typography sx={explaingTextStyle}>가려진 사진</Typography>
        </>
      ),
    },
  ],
  clothes: [
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_1.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>손으로 상품을</Typography>
          <Typography sx={explaingTextStyle}>잡고 있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_3.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>배경이 복잡해 상품과 </Typography>
          <Typography sx={explaingTextStyle}>구분이 힘든 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_4.jpg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>상품이 다른 물체에 </Typography>
          <Typography sx={explaingTextStyle}>가려진 사진</Typography>
        </>
      ),
    },
  ],
  car: [
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_2.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>수평이 맞지 않거나 </Typography>
          <Typography sx={explaingTextStyle}>회전되어 있는 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_3.jpeg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>배경이 복잡해 상품과 </Typography>
          <Typography sx={explaingTextStyle}>구분이 힘든 사진</Typography>
        </>
      ),
    },
    {
      img: '/static/images/subCategory/etc_bad/etc_bad_4.jpg',
      explain: (
        <>
          <Typography sx={explaingTextStyle}>상품이 다른 물체에 </Typography>
          <Typography sx={explaingTextStyle}>가려진 사진</Typography>
        </>
      ),
    },
  ],
}

export const getImagePromise = src => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve([img.width, img.height])
    img.onerror = reject
    img.src = src
  })
}

export default function UploadDialog({
  uploadImage,
  multiple = true,
  title = null,
  notice = null,
  example = null,
  uploadImglimit = 20,
  swipeableDrawerSx = {},
  uploadButtonTitle = null,
  actionDisabledCondition = null,
  bulkUploadAble = false,
  resetImage = true,
}) {
  const theme = useTheme()
  const { t } = useTranslation()

  const portfolioConfig = useRecoilValue(portfolioConfigAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [images, setImages] = useRecoilState(uploadFilesAtom)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isFunFamePage = portfolioType === config.PORTFOLIO_TYPE_FAME
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isNormalPortfolio = portfolioType === config.PORTFOLIO_TYPE_NORMAL

  const isBgExpansion =
    portfolioConfig.flagBgExpansion || portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION
  const isPb = isBundlePage || isFunFamePage

  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [dragAutoUpload, setDragAutoUpload] = useRecoilState(dragAutoUploadAtom)
  const resetDragAutoUpload = useResetRecoilState(dragAutoUploadAtom)
  const [modified, setModified] = useState(false)

  const user = useRecoilValue(userAtom)
  const refineUploadFiles = useRefineUploadFiles()
  const portfolioArtworklimit = portfolioDetail.is_default ? Infinity : 200
  const portfolioArtwork = useRecoilValue(portfolioArtworkAtom)
  const [isBulkUpload, setIsBulkUpload] = useRecoilState(isBulkUploadAtom)
  const resetIsBulkUpload = useResetRecoilState(isBulkUploadAtom)
  const [files, setFiles] = useRecoilState(uploadFilesAtom)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)
  const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)
  const currentMenu = useRecoilValue(currentMenuAtom)
  const isMobile = useMobileMediaQuery()

  const navigate = useNavigate()
  const [bulkLoading, setBulkLoading] = useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (dragAutoUpload.auto && !modified && portfolioArtwork) {
      setModified(true)
      const copy = [...dragAutoUpload.file]
      refineUploadFiles(copy)
    }
  }, [portfolioArtwork])

  useEffect(() => {
    return () => {
      resetDragAutoUpload()
      resetIsBulkUpload()
      setModified(false)
      if (resetImage) {
        setImages([])
      }
    }
  }, [])

  // useEffect(() => {
  //   const show = portfolioConfig.category === 'food'

  //   setShowGenCompo(show)
  // }, [portfolioConfig.category, portfolioConfig.subCategory])

  // useEffect(() => {
  //   const show = portfolioConfig.category === 'food'

  //   setShowGenCompo1(show)
  // }, [portfolioConfig.category, portfolioConfig.subCategory])

  const handleClose = (e, r) => {
    const reasons = ['backdropClick', 'escapeKeyDown']
    if (bulkLoading && reasons.includes(r)) return
    setUploadOpen(false)
    setImages([])
  }
  // useEffect(() => {
  //   console.log(uploadConfig, portfolioConfig, portfolioDetail)
  // })

  const bulkUploadImage = async () => {
    setBulkLoading(true)
    const params = {}
    const uploadGroupUid = getUploadUUID()

    let conf = {
      gen_options: { ...uploadConfig, upload_id: uploadGroupUid },
      username: user.username,
    }

    apis.user.getPresignedUrl().then(r => {
      const url = r.data.presigned_url
      const path = r.data.path

      params.s3_path = path
      params.period = 1
      params.rate = 3

      axios
        .put(url, images[0], {
          headers: {
            'Content-Type': 'application/zip',
          },
        })
        .then(() => {
          const today = moment(new Date()).format('YYYY.MM.DD')

          const pattern = new RegExp(`${today} 대용량_(\\d+)`)
          const numbers = userPortfolioNames
            .filter(name => pattern.test(name))
            .map(name => parseInt(name.match(pattern)[1]))

          const maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0

          const nameCopy = `${today} 대용량_${maxNumber + 1}`
          const configCopy = {
            ...portfolioDetail.config,
            name: nameCopy,
            from_zip: true,
            zip_path: path,
            zip_file_total: null,
            zip_artwork_count: 0,
            zip_fail_count: 0,
          }
          const themeCopy = portfolioDetail.theme

          const body = {
            name: nameCopy,
            theme: themeCopy,
            config: configCopy,
          }

          const token = getAccessToken()

          apis.portfolio
            .createPortfoilo(body)
            .then(async response => {
              const newPortfolioId = response.data.id
              conf = {
                ...conf,
                request: '',
                images: [],
                background_tasks: '',
                token,
                user_id: user.id,
                webhook_url: '',
                portfolio_id: newPortfolioId.toString(),
                use_credit_type: '',
                options: {},
                gen_type: 'initial',
              }

              params.conf = JSON.stringify(conf)

              await apis.portfolio.setUploadProgress(newPortfolioId, {
                upload_id: uploadGroupUid,
                upload_type: 'zip',
                config: { zip_s3_path: params.s3_path, original_filename: files[0].name },
              })

              axios
                .post(
                  isDev
                    ? 'https://ftp-dev.draph.art/upload_zip/'
                    : 'https://ftp.draph.art/upload_zip/',
                  null,
                  { params }
                )
                .then(() => {
                  setUploadOpen(false)

                  navigate(`/portfolio/${newPortfolioId}?pages=1`)

                  // ----- GA4 event -----
                  window.gtag('event', 'bulk_mail_popup_imp', {
                    menu: currentMenu?.id,
                  })
                  // ---------------------

                  const emailSnackbar = enqueueSnackbar(
                    <>
                      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                        <Typography
                          sx={{
                            fontSize: { lg: '1.6rem', xs: '' },
                            fontWeight: 600,
                            color: 'white',
                            '& span': { color: theme => theme.palette.draph.green },
                          }}
                        >
                          <Korean>
                            모든 생성이 완료되면 <span> 알림 메일</span>을 드립니다.
                          </Korean>
                          <English>
                            Once all generation is complete, <span>we’ll notify you by email</span>.
                          </English>
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: { lg: '1.6rem', xs: '1.4rem' },
                            fontWeight: 300,
                            color: 'white',
                            cursor: 'pointer',
                            ml: { lg: '4.8rem', xs: '2rem' },
                          }}
                          onClick={() => {
                            apis.portfolio.deleteUploadProgress(newPortfolioId, uploadGroupUid)
                            closeSnackbar(emailSnackbar)

                            // ----- GA4 event -----
                            window.gtag('event', 'bulk_mail_popup_click', {
                              menu: currentMenu?.id,
                            })
                            // ---------------------
                          }}
                        >
                          <Korean>거절</Korean>
                          <English>Decline</English>
                        </Typography>
                      </Stack>
                    </>,
                    {
                      variant: 'alert',
                      autoHideDuration: 5000,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    }
                  )
                })
                .catch(() => {
                  apis.portfolio.deleteUploadProgress(newPortfolioId, uploadGroupUid)
                })
                .finally(() => {
                  setBulkLoading(false)
                })
            })
            .catch(error => {
              console.log(error)
              setBulkLoading(false)
            })
        })
        .catch(err => {
          console.log(err)
          setBulkLoading(false)
        })
    })
    // axios.post('http://localhost:4000/presigned-url', { filename: 'HIyO~' }).then(r => {
    //   const url = r.data.url
    //   axios
    //     .put(url, images, {
    //       headers: {
    //         'Content-Type': 'application/zip',
    //       },
    //     })
    //     .then(r => {
    //       console.log(r.data)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // })
  }

  const handleUpload = () => {
    const eventName = isFunFamePage
      ? 'famous_person_upload'
      : isBundlePage
      ? 'multipack_photo_upload'
      : isBgExpansion
      ? 'ai_canvas_exp_upload'
      : isNormalPortfolio
      ? 'portfolio_upload'
      : 'upload'

    isBulkUpload ? bulkUploadImage() : uploadImage(images, eventName)

    // handleClose()
  }

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragDropOpen(true)
    } else if (e.type === 'dragleave') {
      setDragDropOpen(false)
    }
  }

  const isBulkUploadAndGetfile = isBulkUpload && files.length > 0

  return (
    <>
      <Desktop>
        <Dialog
          open={uploadOpen}
          onClose={handleClose}
          // disableScrollLock={true}
          sx={{
            overflowX: 'hidden',
            '& .MuiDialog-paper': {
              minWidth: '68rem',
              borderRadius: '10px',
            },
          }}
          disableEscapeKeyDown={bulkLoading}
        >
          {/* <DialogTitle sx={{ p: '3rem', pb: 0, textAlign: 'end' }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle> */}

          <DialogContent sx={{ p: 0, pt: '2.8rem' }}>
            <Tutorial step="14_2">
              <CenterAlignStack sx={{}} onDragEnter={handleDrag}>
                {title ? (
                  <>{title}</>
                ) : bulkUploadAble ? (
                  <>
                    <CenterAlignBox sx={{ alignItems: ' center' }}>
                      <Typography
                        fontSize="2rem"
                        fontWeight={700}
                        sx={{
                          width: '10.832rem',
                          cursor: 'pointer',
                          color: isBulkUpload
                            ? theme.palette.common.black
                            : theme.palette.draph.blue,
                        }}
                        onClick={() => {
                          setIsBulkUpload(false)
                          setFiles([])
                        }}
                      >
                        {t('generate_new_image')}
                      </Typography>{' '}
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          mx: '1rem',
                          borderColor: '#303030',
                          height: '2rem',
                          alignSelf: 'center',
                          borderRightWidth: '2px',
                        }}
                      />
                      <Typography
                        fontSize="2rem"
                        fontWeight={700}
                        sx={{
                          width: '10.832rem',
                          cursor: 'pointer',
                          color: isBulkUpload
                            ? theme.palette.draph.blue
                            : theme.palette.common.black,
                        }}
                        onClick={() => {
                          setIsBulkUpload(true)
                          setFiles([])
                        }}
                      >
                        {t('bulk_upload')}
                      </Typography>{' '}
                    </CenterAlignBox>
                  </>
                ) : (
                  <Typography sx={{ fontSize: '2rem' }} fontWeight={700}>
                    {t('generate_new_image')}
                  </Typography>
                )}

                {notice ??
                  (isBulkUpload ? (
                    <Typography fontSize="1.4rem" fontWeight={400}>
                      {t('upload_dialog.notice_bulk_1')}
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          fontSize: { lg: '1.4rem', xs: '1.2rem' },
                          fontWeight: 400,
                          color: '#595959',
                          lineHeight: 'normal',
                        }}
                      >
                        {t('upload_dialog.notice_1')}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: '1.4rem', xs: '1.2rem' },
                          fontWeight: 400,
                          color: '#595959',
                          lineHeight: 'normal',
                        }}
                      >
                        {t('upload_dialog.notice_2')}
                      </Typography>
                    </>
                  ))}

                <CenterAlignStack
                  sx={{
                    width: '100%',
                    mt: '2rem',
                    mb: isPb ? '3.6rem' : '0rem',
                    pb: isBulkUploadAndGetfile ? '2rem' : 0,
                  }}
                >
                  <DragDropFile
                    files={images}
                    setFiles={setImages}
                    width="60rem"
                    height="15rem"
                    portfolioArtworklimit={portfolioArtworklimit}
                    multiple={isBulkUpload ? false : multiple}
                    uploadImglimit={uploadImglimit}
                    bulkUploadAble={bulkUploadAble}
                    isBulkUpload={isBulkUpload}
                  />
                </CenterAlignStack>
              </CenterAlignStack>
            </Tutorial>
            {example}
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: 'center',
              padding: isBulkUploadAndGetfile ? '1.75rem 0 3.5rem 0' : '2.4rem 0 2.8rem 0',
            }}
          >
            <Tutorial step="14_3">
              <DialogButton
                handleClose={handleClose}
                actionText={
                  bulkLoading ? (
                    <PuffLoader size={40} />
                  ) : (
                    <>
                      {uploadButtonTitle ?? t('upload')}
                      {isRemoveBgPage && images.length <= 1 && <UploadFreeBadge />}
                    </>
                  )
                }
                actionprops={{
                  disabled: actionDisabledCondition ? true : images.length < 1 || bulkLoading,
                }}
                actionSx={{
                  '&:hover #free-text': {
                    transition: 'all 0.3s',
                    color: theme => theme.palette.draph.blue,
                  },
                }}
                cancelprops={{
                  disabled: bulkLoading,
                }}
                handleAction={handleUpload}
              />
            </Tutorial>
          </DialogActions>
          {isRemoveBgPage && images.length > 1 && <NukkiUploadComment />}
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={uploadOpen}
          onOpen={() => {
            return uploadOpen
          }}
          id="image-swiper-dialog"
          onClose={handleClose}
          anchor="bottom"
          sx={{
            // backgroundColor: 'white',
            '& .MuiDrawer-paper': {
              // top: isRemoveBgPage ? 'auto' : '20px',
            },
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
            ...swipeableDrawerSx,
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignStack>
            <CenterAlignBox
              sx={{
                width: '4rem',
                height: '0.4rem',
                backgroundColor: '#BCBCBC',
                borderRadius: '20px',
                mt: '1.2rem',
                mb: tutorial.mode && tutorial.step === '14_2' ? '3.2rem' : '1.2rem',
              }}
            ></CenterAlignBox>
            <Tutorial step="14_2">
              <CenterAlignStack
                sx={{
                  width: '100vw',
                }}
              >
                {title ?? (
                  <Typography fontSize="1.6rem" fontWeight={800} sx={{ mt: '0.8rem' }}>
                    {t('generate_new_image')}
                  </Typography>
                )}
                {notice ?? (
                  <Typography fontSize="1.2rem" fontWeight={400} sx={{ px: '2rem' }}>
                    {t('upload_dialog.notice_1')}
                  </Typography>
                )}

                <CenterAlignStack sx={{ width: '100%', mt: '1.6rem' }}>
                  <DragDropFile
                    files={images}
                    setFiles={setImages}
                    width="89%"
                    height={isMobile ? '20rem' : '8rem'}
                    portfolioArtworklimit={portfolioArtworklimit}
                    multiple={multiple}
                    uploadImglimit={uploadImglimit}
                  />
                </CenterAlignStack>
              </CenterAlignStack>
            </Tutorial>

            {/* {showGenCompo && (
              <CenterAlignStack sx={{ pb: '2rem', width: '32rem' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={flagGenCompo}
                      onChange={e => {
                        setFlagBgExpansion(false)
                        setFlagGenCompo(e.target.checked)
                      }}
                      sx={{ py: '0px' }}
                    />
                  }
                  sx={{ '& .MuiTypography-root': { lineHeight: '1.8rem' } }}
                  label={
                    <span style={{ fontSize: '1.4rem' }}>
                      접시의 잘린 부분을 ai로 제작하여 <br /> 온전한 형태로 이미지를 생성합니다.
                    </span>
                  }
                />
                <Typography
                  sx={{
                    color: theme => theme.palette.common.red,
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    mt: '0.3rem',
                    ml: '1rem',
                  }}
                >
                  * 선택 시, 업로드 이미지당 1크레딧{' '}
                  <span style={{ fontWeight: 700 }}>추가 차감</span>
                </Typography>
              </CenterAlignStack>
            )}

            {showGenCompo1 && (
              <CenterAlignStack sx={{ pb: '2rem', width: '32rem' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={flagBgExpansion}
                      onChange={e => {
                        setFlagGenCompo(false)
                        setFlagBgExpansion(e.target.checked)
                      }}
                      sx={{ py: '0px' }}
                    />
                  }
                  sx={{ '& .MuiTypography-root': { lineHeight: '1.8rem' } }}
                  label={
                    <span style={{ fontSize: '1.4rem' }}>
                      접시의 잘린 부분을 ai로 제작하여 <br /> 온전한 형태로 이미지를 생성합니다.
                    </span>
                  }
                />
                <Typography
                  sx={{
                    color: theme => theme.palette.common.red,
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    mt: '0.3rem',
                    ml: '1rem',
                  }}
                >
                  * 선택 시, 업로드 이미지당 1크레딧{' '}
                  <span style={{ fontWeight: 700 }}>추가 차감</span>
                </Typography>
              </CenterAlignStack>
            )} */}

            <>{example}</>

            {isRemoveBgPage && images.length > 1 && <NukkiUploadComment />}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '10.4rem',
              }}
            >
              <Tutorial step="14_3">
                {isRemoveBgPage && images.length > 1 && <></>}
                <DialogButton
                  handleClose={handleClose}
                  actionText={
                    bulkLoading ? (
                      <PuffLoader />
                    ) : (
                      <>
                        {uploadButtonTitle ?? t('upload')}
                        {isRemoveBgPage && images.length <= 1 && (
                          <Box
                            sx={{
                              ml: '0.7rem',
                              transition: 'all 0.3s',
                              width: '4rem',
                              height: '1.8rem',
                              background: 'white',
                              borderRadius: '10px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              id="free-text"
                              sx={{
                                fontSize: { lg: '1.1rem', xs: '1rem' },
                                fontWeight: 600,
                                color: theme => theme.palette.common.black,
                              }}
                            >
                              FREE
                            </Typography>
                          </Box>
                        )}
                      </>
                    )
                  }
                  actionprops={{
                    disabled: actionDisabledCondition ? true : images.length < 1 || bulkLoading,
                  }}
                  actionSx={{
                    '&:hover #free-text': {
                      transition: 'all 0.3s',
                      color: theme => theme.palette.draph.blue,
                    },
                  }}
                  handleAction={handleUpload}
                />
              </Tutorial>
            </Box>
          </CenterAlignStack>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}

// drag drop file component
export function DragDropFile({
  files,
  setFiles,
  portfolioArtworklimit,
  multiple,
  uploadImglimit,
  isBulkUpload,
  bulkUploadAble,
  thumbsx = {},
  ...props
}) {
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)

  // drag state
  // ref
  const inputRef = useRef(null)
  const isDesktop = useDesktopMediaQuery()
  const portfolioArtwork = useRecoilValue(portfolioArtworkAtom)
  const isMobile = useMobileMediaQuery()

  const [currentImageUrl, setCurrentImageUrl] = useState('')

  const { showConfirm } = useConfirm()
  const { t } = useTranslation()
  const refineUploadFiles = useRefineUploadFiles(uploadImglimit)

  const uuid = Math.ceil(Math.random() * 1000)

  useEffect(() => {
    return () => {
      if (inputRef && inputRef.current) inputRef.current.value = null
    }
  }, [])

  useEffect(() => {
    if (files?.length > 0) {
      const url = URL.createObjectURL(files[0])
      setCurrentImageUrl(url)
    }
  }, [files])

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragDropOpen(true)
    } else if (e.type === 'dragleave') {
      setDragDropOpen(false)
    }
  }

  const handleChange = async function (e) {
    e.preventDefault()

    if (e.target.files) {
      if (!isBulkUpload) {
        const uploadFiles = Array.prototype.slice.call(e.target.files)
        refineUploadFiles(uploadFiles)
      } else {
        const uploadFiles = Array.prototype.slice.call(e.target.files, 0, 1)

        const allowedTypes = ['application/zip', 'application/x-zip-compressed']
        const zipfiles = uploadFiles.filter(f => allowedTypes.includes(f.type)).slice(0, 1)
        const otherfiles = uploadFiles.filter(f => !allowedTypes.includes(f.type))

        if (otherfiles.length) {
          showConfirm({ content: '확장자(.zip)을 확인해주세요 !', alertOnly: true })
        }

        if (zipfiles.length) {
          setFiles(zipfiles)
        }
      }
    }
  }

  const handleChangeForBulk = function (e) {
    e.preventDefault()
  }

  return (
    <>
      {files.length === 0 && (
        <form
          className="form-file-upload"
          id={`form-file-upload-${uuid}`}
          onDragEnter={handleDrag}
          onSubmit={e => e.preventDefault()}
          style={{
            width: props.width ?? '12.8rem',
            height: props.height ?? '14.4rem',
          }}
        >
          <input
            ref={inputRef}
            type="file"
            className="input-file-upload"
            id={`input-file-upload-${uuid}`}
            accept={isBulkUpload ? '' : allowedTypes.join(', ')}
            multiple={multiple}
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor={`input-file-upload-${uuid}`}
            className={dragDropOpen ? 'drag-active' : ''}
          >
            <div>{isMobile ? <PlusMobileIcon /> : <PlusIcon />}</div>
          </label>

          {dragDropOpen && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              // onDrop={handleDrop}
            ></div>
          )}
        </form>
      )}

      {!isBulkUpload && files.length > 0 && files.length < uploadImglimit && (
        <CenterAlignStack sx={{ width: isDesktop ? '100%' : '32rem' }}>
          <Grid
            container
            sx={{
              justifyContent: 'center',
              columnGap: { lg: 1.2, xs: 0.4 },
              rowGap: { lg: 1.3, xs: 0.5 },
            }}
            column={10}
          >
            {[...files].map((f, i) => (
              <ImageThumb
                key={i}
                url={URL.createObjectURL(f)}
                idx={i}
                setFiles={setFiles}
                files={files}
                thumbsx={thumbsx}
              />
            ))}
            <Grid
              item
              xs={2}
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                maxWidth: isDesktop ? '12.8rem !important' : '6rem',
              }}
            >
              <form
                className="form-file-upload"
                id={`form-file-upload-${uuid}`}
                onDragEnter={handleDrag}
                onSubmit={e => e.preventDefault()}
                style={{
                  width: isDesktop ? '12.8rem' : '6rem',
                  height: isDesktop ? '14.4rem' : '8rem',
                }}
              >
                <input
                  ref={inputRef}
                  type="file"
                  className="input-file-upload"
                  id={`input-file-upload-${uuid}`}
                  accept={allowedTypes.join(', ')}
                  multiple={multiple}
                  onChange={handleChange}
                  key={files.length}
                />
                <label
                  id="label-file-upload"
                  htmlFor={`input-file-upload-${uuid}`}
                  className={dragDropOpen ? 'drag-active' : ''}
                >
                  <div>{isMobile ? <PlusMobileIcon /> : <PlusIcon />}</div>
                </label>
              </form>
            </Grid>
            {files.length % 4 === 0 && (
              <>
                <FakeThumb /> <FakeThumb />
                <FakeThumb />
              </>
            )}
            {files.length % 4 === 1 && (
              <>
                <FakeThumb /> <FakeThumb />
              </>
            )}
            {files.length % 4 === 2 && (
              <>
                <FakeThumb />
              </>
            )}
            {/* {files.length % 4 === 3 && (
              <>
                <FakeThumb />
              </>
            )} */}
          </Grid>
          {dragDropOpen && (
            <div
              id="drag-file-element-box"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              // onDrop={handleDrop}
            ></div>
          )}
        </CenterAlignStack>
      )}

      {files.length === uploadImglimit && (
        <CenterAlignStack sx={{ width: '100%' }}>
          <Grid
            container
            sx={{
              justifyContent: 'center',
              columnGap: { lg: 1.2, xs: 0.4 },
              rowGap: { lg: 1.3, xs: 0.4 },
            }}
          >
            {[...files].map((f, i) => (
              <ImageThumb
                key={i}
                url={URL.createObjectURL(f)}
                idx={i}
                setFiles={setFiles}
                files={files}
                thumbsx={thumbsx}
              />
            ))}
          </Grid>
        </CenterAlignStack>
      )}

      {isBulkUpload && files.length > 0 && (
        <CenterAlignStack
          sx={{
            position: 'relative',
            gap: '1rem',
            boxShadow: `4px 4px 20px 0px #0000001A`,
            width: '12.8rem',
            height: '14.4rem',
            borderRadius: '1rem',
            justifyContent: 'center',
            pt: '1rem',
          }}
        >
          <ZipIcon />
          <Typography sx={{ fontSize: '2rem', fontWeight: 800 }}>ZIP</Typography>
          <IconButton
            onClick={() => {
              if (setFiles.length !== 1) return
              setFiles([])
            }}
            sx={{ position: 'absolute', top: '-1.8rem', right: '-1.9rem', fontSize: '2rem ' }}
          >
            <BsFillXCircleFill
              style={{
                background: 'white',
                borderRadius: '50px',
                width: '2.4rem',
                height: '2.4rem',
                padding: '1px',
              }}
            />
          </IconButton>
        </CenterAlignStack>
      )}
    </>
  )
}

export function ImageThumb({ url, setFiles, files, idx, thumbsx }) {
  const isDesktop = useDesktopMediaQuery()
  const [isMouseOver, setIsMouseOver] = useState(false)
  const user = useRecoilValue(userAtom)
  const isNCommerceUser = user.login_sns === 'naver_commerce'

  const handleClick = () => {
    if (isNCommerceUser && files.length === 1) return
    setFiles(prev => {
      const updatedFiles = [...prev].filter((f, i) => i !== idx)
      return updatedFiles
    })
  }

  return (
    <Grid
      item
      xs={2}
      key={idx}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        maxWidth: isDesktop ? '12.8rem !important' : '6rem',
        minWidth: isDesktop ? '12.8rem' : '6rem',
      }}
    >
      <img
        className="previewImg"
        src={url}
        style={{
          width: isDesktop ? '12.8rem' : '6rem',
          height: isDesktop ? '14.4rem' : '8rem',
          objectFit: 'cover',
          borderRadius: isDesktop ? '2rem' : '1rem',
          maxWidth: 'none',
          ...thumbsx,
        }}
      />

      {isDesktop ? (
        <IconButton
          onClick={handleClick}
          sx={{
            position: 'absolute',
            top: '-1.8rem',
            right: thumbsx ? (!Object.keys(thumbsx).length ? '-1.9rem' : '-27px') : '-19px',
            fontSize: '2rem ',
          }}
        >
          <BsFillXCircleFill
            style={{
              background: 'white',
              borderRadius: '50px',
              width: '2.4rem',
              height: '2.4rem',
              padding: '1px',
            }}
          />
        </IconButton>
      ) : (
        <IconButton
          onClick={handleClick}
          sx={{ position: 'absolute', top: '-1.4rem', right: '-1.3rem' }}
        >
          <MobileArtworkGrayCloseIcon
            style={{
              background: 'white',
              borderRadius: '50px',
              width: '2.4rem',
              height: '2.4rem',
              padding: '1px',
            }}
          />
        </IconButton>
      )}
    </Grid>
  )
}

function FakeThumb() {
  const isDesktop = useDesktopMediaQuery()

  return (
    <Grid
      item
      xs={2}
      sx={{
        position: 'relative',
        maxWidth: isDesktop ? '12.8rem !important' : '6rem',
      }}
    >
      <div
        style={{
          width: isDesktop ? '12.8rem' : '6rem',
          height: isDesktop ? '14.4rem' : '8rem',
          objectFit: 'cover',
          borderRadius: isDesktop ? '2rem' : '1rem',
        }}
      />
    </Grid>
  )
}

// https://www.codemzy.com/blog/react-drag-drop-file-upload

export function BadExampleWarning({ portfolioConfig }) {
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const user = useRecoilValue(userAtom)
  const isNCommerceUser = user.login_sns === 'naver_commerce'
  return (
    <>
      {!isRemoveBgPage && (
        <CenterAlignStack
          sx={{
            height: { lg: '27.6rem', xs: 'auto' },
            width: '100%',
            backgroundColor: theme => theme.palette.common.lightgray,
            py: { lg: '3.2rem', xs: '2.8rem' },
          }}
        >
          <Typography
            variant="body"
            sx={{ fontSize: { lg: '1.6rem', xs: '1.4rem' }, fontWeight: 700, mb: '2.4rem' }}
          >
            <Typography
              variant="span"
              sx={{
                fontSize: { lg: '1.6rem', xs: '1.6rem' },
                fontWeight: 700,
                color: theme => (isNCommerceUser ? theme.palette.naver : theme.palette.draph.blue),
              }}
            >
              {PORTFOLIO_CATEGORY_DICT[portfolioConfig.category]}
              {(portfolioConfig.category === 'clothes' || portfolioConfig.category === 'person') &&
                ` - ${PORTFOLIO_CATEGORY_DICT[portfolioConfig.subCategory]}`}{' '}
            </Typography>{' '}
            이미지 업로드 전 확인해주세요!
          </Typography>
          <Desktop>
            <CenterAlignBox gap={2}>
              {badExample[portfolioConfig.category]?.map(({ img, explain }, idx) => {
                return (
                  <CenterAlignStack key={idx} sx={{ position: 'relative' }}>
                    <img
                      src={img}
                      style={{
                        objectFit: 'cover',
                        width: '12rem',
                        height: '12rem',
                        borderRadius: '10px',
                        marginBottom: '1.3rem',
                      }}
                    />
                    <Box sx={{ position: 'absolute', top: '0.7rem', right: '0.8rem' }}>
                      <BadExampleIcon />
                    </Box>
                    {explain}
                  </CenterAlignStack>
                )
              })}
            </CenterAlignBox>
          </Desktop>
          <Mobile>
            <Tutorial step="14_1">
              <CenterAlignStack sx={{ width: '27.4rem' }}>
                <Grid
                  container
                  columns={12}
                  rowSpacing={1.7}
                  columnSpacing={2}
                  sx={{ justifyContent: 'center' }}
                >
                  {badExample[portfolioConfig.category]?.map(({ img, explain }, idx, array) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        key={idx}
                        sx={{
                          position: 'relative',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CenterAlignStack>
                          <img
                            src={img}
                            style={{
                              objectFit: 'cover',
                              width: '12rem',
                              height: '12rem',
                              borderRadius: '10px',
                              marginBottom: '1.3rem',
                            }}
                          />
                          <Box sx={{ position: 'absolute', top: '2.7rem', right: '0.8rem' }}>
                            <BadExampleIcon />
                          </Box>
                          <Box
                            sx={{
                              height: '3.4rem',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            {explain}
                          </Box>
                        </CenterAlignStack>
                      </Grid>
                    )
                  })}
                </Grid>
              </CenterAlignStack>
            </Tutorial>
          </Mobile>
        </CenterAlignStack>
      )}
    </>
  )
}

export const useAlertModal = () => {
  const { showConfirm } = useConfirm()
  const { t } = useTranslation()

  const alertModal = (
    removedImgURL,
    isSmallSize = false,
    isRatioTooLarge = false,
    notAllowedExt = false,
    revokeFunc
  ) =>
    showConfirm({
      alertOnly: true,
      onConfirm: revokeFunc,
      sx: {
        '& .MuiDialog-paper': { width: '81.6rem' },
      },
      content: (
        <>
          <CenterAlignStack>
            <Typography sx={{ fontSize: '2rem', fontWeight: 700 }}>
              {t('upload_dialog.warning_title')}{' '}
            </Typography>
          </CenterAlignStack>

          <Stack
            sx={{
              mt: '0.5rem',
              mb: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isSmallSize && (
              <>
                {' '}
                <Typography>{t('upload_dialog.warning_1')} </Typography>
                <Typography>{t('upload_dialog.warning_2')} </Typography>
              </>
            )}

            {isRatioTooLarge && (
              <>
                {' '}
                <Typography>{t('upload_dialog.warning_3')} </Typography>
                <Typography>{t('upload_dialog.warning_4')}</Typography>
              </>
            )}

            {notAllowedExt && (
              <>
                {' '}
                <Typography>{t('upload_dialog.warning_7')}</Typography>
              </>
            )}
          </Stack>

          <Grid
            container
            sx={{
              justifyContent: 'center',
              columnGap: { lg: 1, xs: 0.4 },
              rowGap: { lg: 1, xs: 0.5 },
              columns: { lg: 10, xs: 4 },
              py: removedImgURL.length ? 0 : '2.5rem',
            }}
          >
            {removedImgURL.map((url, i) => {
              return (
                <Grid
                  item
                  xs={2}
                  key={i}
                  sx={{
                    position: 'relative',
                    minWidth: removedImgURL.length && '9rem !important',
                  }}
                >
                  <img
                    style={{
                      width: '12rem',
                      height: '12rem',
                      borderRadius: '20px',
                      objectFit: 'cover',
                    }}
                    src={url}
                  />
                </Grid>
              )
            })}
          </Grid>
        </>
      ),
    })
  return alertModal
}

function UploadFreeBadge() {
  return (
    <Box
      sx={{
        ml: '0.7rem',
        transition: 'all 0.3s',
        width: '4rem',
        height: '1.8rem',
        background: 'white',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        id="free-text"
        sx={{
          fontSize: { lg: '1.1rem', xs: '1.1rem' },
          fontWeight: 600,
          color: theme => theme.palette.common.black,
        }}
      >
        FREE
      </Typography>
    </Box>
  )
}

function NukkiUploadComment() {
  const { t, i18n } = useTranslation()
  return (
    <CenterAlignStack
      sx={{
        width: '100%',
        mt: { lg: '-2rem' },
        pb: { lg: '2.5rem' },
        '& .text': {
          fontSize: '1.4rem',
          fontWeight: 700,
          lineHeight: { lg: 1.2, xs: 1.1 },
        },
      }}
    >
      <Typography className="text">{t('upload_dialog.nukki_warning_1')}</Typography>
      <Typography className="text" sx={{ color: '#FF2323' }}>
        {t('upload_dialog.nukki_warning_2')}
      </Typography>
    </CenterAlignStack>
  )
}
