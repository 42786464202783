import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  Typography,
} from '@mui/material'
import { apis } from 'apis'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { forwardRef, useEffect, useRef, useState } from 'react'
import Moveable from 'react-moveable'
import { CloseIcon } from 'theme/icon'
import { getS3ImageSrc } from 'utils/common'
import { IMG_BASE64 } from './RegenerateDialog'
import axios from 'axios'
import { ARTWORK_MODPIECE_STATUS } from 'config'
import { defaultPortfolioAtom, userAtom, portfolioTypeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { useParams } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { checkedURLforNoCache } from './ArtworkList'
import { usePortfolioId } from 'hooks/usePortfoliId'
import { useTranslation } from 'react-i18next'
import { getUserType } from 'utils/user'

const imgBoxMaxWidth = 784
const imgBoxMaxHeight = 560
let realWidth
let realHeight

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

export default function ControlSizeDialog({ open, setOpen, artwork, setArtwork, pieceIdx }) {
  const [uploadImgHeight, setUploadImgHeight] = useState(0)
  const [uploadImgWidth, setUploadImgWidth] = useState(0)
  const [scale, setScale] = useState()
  const [soBase64, setSoBase64] = useState('')
  const [soPosInfo, setSoPosInfo] = useState()
  const { t, i18n } = useTranslation()

  const [clickMoveable, setClickMoveable] = useState(false)
  const [afterPosInfo, setAfterPosInfo] = useState({})
  const [noCacheImage, setNoCacheImage] = useState('')

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const imgRef = useRef()
  const contentRef = useRef()

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId
  const user = useRecoilValue(userAtom)

  const piece = artwork.pieces[pieceIdx]

  const piecePath = getS3ImageSrc(piece.path) ?? null
  const piecePos = piece.pos_info

  const piecePosScale = piecePos.scale || 1
  const piecePosOffsetW = piecePos.offset_w || 0
  const piecePosOffsetH = piecePos.offset_h || 0

  const handleClose = () => {
    setOpen(false)
  }

  const loadHandler = e => {
    realWidth = e.target.naturalWidth
    realHeight = e.target.naturalHeight

    const widthScale = imgBoxMaxWidth / realWidth
    const heightScale = imgBoxMaxHeight / realHeight //  높이 / 너비 너비 비율

    if (widthScale >= heightScale) {
      setUploadImgWidth(realWidth * heightScale)
      setUploadImgHeight(realHeight * heightScale)
      setScale(heightScale)
    }

    if (widthScale < heightScale) {
      setUploadImgWidth(realWidth * widthScale)
      setUploadImgHeight(realHeight * widthScale)
      setScale(widthScale)
    }
  }

  const updateAfterPosInfo = () => {
    const contentBoxClintRect = contentRef.current?.getBoundingClientRect()
    const imgClintRect = imgRef.current?.getBoundingClientRect()

    const x = Math.max(0, Number((imgClintRect?.x - contentBoxClintRect?.x).toFixed(2)))
    const y = Math.max(0, Number((imgClintRect?.y - contentBoxClintRect?.y).toFixed(2)))

    const convertedClientPosInfo = {
      usermod_so_h: Math.floor(imgClintRect?.height / scale / piecePosScale),
      usermod_so_w: Math.floor(imgClintRect?.width / scale / piecePosScale),
      usermod_lr_x: Math.floor((x / scale + piecePosOffsetW) / piecePosScale),
      usermod_lr_y: Math.floor((y / scale + piecePosOffsetH) / piecePosScale),
      usermod_bg_h: piecePos.bg_h,
      usermod_bg_w: piecePos.bg_w,
    }

    setAfterPosInfo(convertedClientPosInfo)
  }

  const sizeHandler = () => {
    const formData = new FormData()

    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('user_type', getUserType(user))
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', portfolioId)
    formData.append('selected_bg_ids', piece.bg_id)
    formData.append('retry_type', ARTWORK_MODPIECE_STATUS)

    const genOptions = {
      selected_bg_ids: piece.bg_id,
      ...afterPosInfo,
    }

    formData.append('gen_options', JSON.stringify(genOptions))
    // formData.append('options', JSON.stringify(afterPosInfo))

    // formData.append('user_id', user.id)
    // formData.append('username', user.username)
    // formData.append('artwork_id', artwork.id)
    // formData.append('portfolio_id', portfolioId)
    // formData.append('selected_bg_ids', piece.bg_id) // 배경아이디에 포함될일이 없는 독특한 구분자 .. (백엔드와 맞춤))
    // formData.append('retry_type', 'add_piece')

    // apis.appfront.retry(formData).finally(() => {})
    // if (response.data) {
    //   setOpen(false)
    //   console.log(response.data)
    //   setArtwork(response.data)
    // }

    apis.portfolio
      .updateArtworkFeedback(portfolioId, artwork.id, {
        feedback: ARTWORK_MODPIECE_STATUS,
        // feedback: 'add_piece',
        addPieceCount: 1,
        modPieceId: artwork.pieces[pieceIdx].id,
      })
      .then(response => {
        apis.appfront.retry(formData).finally(() => {})
        if (response.data) {
          setOpen(false)

          setArtwork(response.data)
        }
      })
  }

  useEffect(() => {
    if (!scale) return

    const info = {
      so_w: piecePos?.so_w * piecePosScale * scale,
      so_h: piecePos?.so_h * piecePosScale * scale,

      so_lr_x: (piecePos?.so_lr_x * piecePosScale - piecePosOffsetW) * scale,
      so_lr_y: (piecePos?.so_lr_y * piecePosScale - piecePosOffsetH) * scale,

      // so_w: piecePos?.so_w * 0.9887 * scale,
      // so_h: piecePos?.so_h * 0.9887 * scale,

      // so_lr_x: (piecePos?.so_lr_x * 0.9887 - (-456 * 0.9887) / 2) * scale,
      // so_lr_y: (piecePos?.so_lr_y * 0.9887 - piecePos.offset_h / 2) * scale,

      // so_h: Math.round(piecePos?.so_h * scale),
      // so_w: Math.round(piecePos?.so_w * scale),
      // so_lr_x: Math.round(piecePos?.so_lr_x * scale),
      // so_lr_y: Math.round(piecePos?.so_lr_y * scale),
    }

    setSoPosInfo(info)

    const initposInfo = {
      usermod_so_h: piecePos?.so_h,
      usermod_so_w: piecePos?.so_w,
      usermod_lr_x: piecePos?.so_lr_x,
      usermod_lr_y: piecePos?.so_lr_y,
      usermod_bg_h: piecePos?.bg_h,
      usermod_bg_w: piecePos?.bg_w,
    }
    // console.log('piecePos', piecePos)
    // console.log('initposInfo', initposInfo)
    setAfterPosInfo(initposInfo)
  }, [scale])

  useEffect(() => {
    // console.log(piecePosOffsetW, piecePosOffsetH, piecePosScale)
  })

  useEffect(() => {
    apis.appfront.croppedSod(artwork.id).then(res => {
      setSoBase64(`${IMG_BASE64}${res.data.img.body.replaceAll('"', '')}`)
    })
  }, [])

  // useEffect(() => {
  //   const contentBoxClintRect = contentRef.current?.getBoundingClientRect()
  //   const imgClintRect = imgRef.current?.getBoundingClientRect()

  //   const x = imgClintRect?.x - contentBoxClintRect?.x
  //   const y = imgClintRect?.y - contentBoxClintRect?.y

  //   console.log(x, y)
  // })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          overflowY: 'unset',
          borderRadius: '10px',
          margin: '22px',
          maxHeight: 'calc(100% - 44px)',
        },
      }}
    >
      <DialogTitle
        sx={{
          p: '42px 0 26px 0',
          minHeight: '129.5px',
          maxHeight: '129.5px',
          position: 'relative',
        }}
      >
        <CenterAlignStack>
          <Typography sx={{ fontSize: '25px', fontWeight: 700 }}>
            {t('control_size_dialog.title')}
          </Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
            {t('control_size_dialog.subtitle')}
          </Typography>
          <IconButton
            edge="end"
            onClick={handleClose}
            sx={{ margin: '10px', marginRight: '15px', position: 'absolute', top: 0, right: 0 }}
          >
            <CloseIcon sx={{ width: '12px', height: '12px' }} />
          </IconButton>
        </CenterAlignStack>
      </DialogTitle>
      <DialogContent
        ref={contentRef}
        sx={{
          p: 0,
          overflow: 'hidden',
          minWidth: uploadImgWidth,
          minHeight: uploadImgHeight,
          maxWidth: uploadImgWidth,
          maxHeight: uploadImgHeight,
          position: 'relative',
        }}
      >
        <img
          style={{ width: uploadImgWidth, height: uploadImgHeight, opacity: '0.3' }}
          src={checkedURLforNoCache(piecePath)}
          onLoad={loadHandler}
        />
        <div
          ref={imgRef}
          style={{
            position: 'absolute',
            top: soPosInfo?.so_lr_y,
            left: soPosInfo?.so_lr_x,
            width: soPosInfo?.so_w,
            height: soPosInfo?.so_h,
          }}
        >
          <img
            src={soBase64}
            onLoad={() => setClickMoveable(true)}
            style={{
              width: '100%',
              height: '100%',
              opacity: !clickMoveable ? 0 : 1,
              transition: 'opacity 0.3s ease-in',
            }}
          />
        </div>

        {!clickMoveable && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PuffLoader />
          </Box>
        )}

        {/* <ImageWithAlpha base64ImageData={soBase64} /> */}

        {clickMoveable && (
          <Moveable
            target={imgRef}
            scalable={true}
            keepRatio={true}
            draggable={true}
            snappable={true}
            origin={false}
            edgeDraggable={true}
            bounds={{
              left: Math.max(-piecePosOffsetW * scale, 0) + 1,
              top: Math.max(-piecePosOffsetH * scale, 0) + 1,
              bottom: Math.max(-piecePosOffsetH * scale, 0) + 1,
              right: Math.max(-piecePosOffsetW * scale, 0) + 1,
              position: 'css',
            }}
            onDrag={e => {
              e.target.style.transform = e.transform
            }}
            onDragEnd={updateAfterPosInfo}
            onScale={e => {
              const scalePattern = /scale\(([\d.-]+),\s*([\d.-]+)\)/
              const scaleMatch = e.drag.transform.match(scalePattern)
              let scaleX
              let scaleY

              if (scaleMatch) {
                // scaleMatch[0]은 전체 매치된 부분, scaleMatch[1]과 scaleMatch[2]는 각각 첫 번째와 두 번째 숫자입니다.
                scaleX = parseFloat(scaleMatch[1])
                scaleY = parseFloat(scaleMatch[2])
              }

              if (scaleX >= 0 && scaleY >= 0) {
                e.target.style.transform = e.drag.transform
              }
            }}
            onScaleEnd={updateAfterPosInfo}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: `95px`,
          maxHeight: `130px`,
          height: `${655 - uploadImgHeight}px`,
        }}
      >
        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" size="large" sx={{ width: '260px' }} onClick={sizeHandler}>
            {t('button.edit_complete')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

const ImageWithAlpha = ({ base64ImageData }) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    // 이미지 객체를 생성합니다.
    const image = new Image()

    // 이미지 로드가 완료되면 캔버스에 그립니다.
    image.onload = () => {
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0)

      // 이미지 데이터를 가져옵니다.
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)

      // 픽셀 데이터에 알파 채널 값을 포함하여 다시 그립니다.
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.putImageData(imageData, 0, 0)
    }

    // 이미지 객체의 src에 base64 데이터를 할당하여 로드합니다.
    image.src = base64ImageData
  }, [base64ImageData])

  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  )
}
