import { Box, Button, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, EffectCoverflow } from 'swiper'

// import 'swiper/swiper.scss'
// import 'swiper/components/navigation/navigation.scss'
// import 'swiper/components/pagination/pagination.scss'
// import 'swiper/components/effect-coverflow/effect-coverflow.scss'

export default function TestPage13() {
  const [numbers, setNumbers] = useState([])
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [removedNumbers, setRemovedNumbers] = useState([])

  useEffect(() => {
    setCurrentIndex(numbers.length - 1)
  }, [numbers])

  const handleNumberClick = number => {
    const newNumbers = [...numbers, number]
    setNumbers(newNumbers)
    setRemovedNumbers([]) // 새로운 숫자를 추가하면 빠진 숫자 초기화
  }

  const handleUndo = () => {
    if (currentIndex > -1) {
      const removedNumber = numbers[currentIndex]
      setRemovedNumbers([removedNumber, ...removedNumbers]) // 빠진 숫자 저장
      setNumbers(numbers.slice(0, currentIndex))
    }
  }

  const handleRedo = () => {
    if (removedNumbers.length > 0) {
      const newNumbers = [...numbers, removedNumbers[0]]
      setNumbers(newNumbers)
      setRemovedNumbers(removedNumbers.slice(1)) // 빠진 숫자에서 가져온 것 제거
      setCurrentIndex(currentIndex + 1)
    }
  }

  return (
    <div>
      <div>
        {numbers.map((number, index) => (
          <span key={index}>{number} </span>
        ))}
      </div>
      <button onClick={() => handleNumberClick(Math.floor(Math.random() * 100))}>
        Add Random Number
      </button>
      <button onClick={handleUndo} disabled={currentIndex === -1}>
        Undo
      </button>
      <button onClick={handleRedo} disabled={removedNumbers.length === 0}>
        Redo
      </button>
    </div>
  )
}

// const [step, setStep] = useState(0)

// const onClickHandler = state => () => {
//   if (state === 'prev') {
//     setStep(s => {
//       return Math.max(s - 1, 0)
//     })
//   }
//   if (state === 'next') {
//     setStep(s => {
//       return Math.min(s + 1, 2)
//     })
//   }
// }

// useEffect(() => {
//   console.log(step)
// })

// const earlyVariant = {
//   one:
//     step === 2
//       ? { x: '50%', opacity: 0, transition: { duration: 0.5 } }
//       : { x: '50%', opacity: 0, transition: { duration: 0.5 } },
//   two:
//     step === 2
//       ? { x: '0%', opacity: 1, transition: { duration: 0.5 } }
//       : { x: '50%', opacity: 1, transition: { duration: 0.5 } },

//   three: { x: '50%', opacity: 0, transition: { duration: 0.3 } },
//   four: { x: '0%', opacity: 1, transition: { duration: 0.3 } },
// }

// return (
//   <>
//     <Box>
//       <Button onClick={onClickHandler('prev')}>이전</Button>
//       <Button onClick={onClickHandler('next')}>다음</Button>
//     </Box>

//     <Stack
//       sx={{
//         p: '40px',
//         backgroundColor: '#f2f2f2',
//         width: '500px',
//         height: '500px',
//         flexDirection: 'row',
//         gap: '20px',
//       }}
//     >
//       <Stack sx={{ gap: '20px', alignItems: 'center' }}>
//         {step < 4 && (
//           <Box
//             component={motion.div}
//             sx={{
//               width: '200px',
//               height: '200px',
//               backgroundColor: '#ed9393',
//               color: 'white',
//               fontWeight: 800,
//             }}
//             initial="one"
//             animate="two"
//             variants={earlyVariant}
//           >
//             step 0
//           </Box>
//         )}
//         {step < 4 && step > 0 && (
//           <Box
//             component={motion.div}
//             sx={{ width: '200px', height: '200px', backgroundColor: 'red' }}
//             initial="one"
//             animate="two"
//             variants={earlyVariant}
//           >
//             step 1
//           </Box>
//         )}
//       </Stack>

//       {step < 4 && step > 1 && (
//         <Box
//           component={motion.div}
//           sx={{ width: '200px', height: '420px', backgroundColor: 'red' }}
//           initial="three"
//           animate="four"
//           variants={earlyVariant}
//         >
//           step 2
//         </Box>
//       )}
//     </Stack>
//     <SwiperCoverflow />
//   </>
// )
// }

// SwiperCore.use([Navigation, Pagination, EffectCoverflow])

export function SwiperCoverflow() {
  return (
    <div className="App">
      <Swiper
        modules={[Navigation, Pagination, EffectCoverflow]}
        navigation
        pagination={{ clickable: true }}
        effect="coverflow"
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        slidesPerView={3}
        centeredSlides={true}
        style={{ height: '500px' }}
      >
        <SwiperSlide
          style={{
            backgroundImage: 'url(https://swiperjs.com/demos/images/nature-1.jpg)',
          }}
        >
          Slide 1
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: 'url(/static/images/cellphone.png)',
          }}
        >
          Slide 2
        </SwiperSlide>
        <SwiperSlide>
          <img src="/static/images/cellphone.png" />
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: 'url(https://swiperjs.com/demos/images/nature-4.jpg)',
          }}
        >
          Slide 4
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: 'url(https://swiperjs.com/demos/images/nature-5.jpg)',
          }}
        >
          Slide 5
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: 'url(https://swiperjs.com/demos/images/nature-6.jpg)',
          }}
        >
          Slide 6
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: 'url(https://swiperjs.com/demos/images/nature-7.jpg)',
          }}
        >
          Slide 7
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: 'url(https://swiperjs.com/demos/images/nature-8.jpg)',
          }}
        >
          Slide 8
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: 'url(https://swiperjs.com/demos/images/nature-9.jpg)',
          }}
        >
          Slide 9
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: 'url(https://swiperjs.com/demos/images/nature-10.jpg)',
          }}
        >
          Slide 10
        </SwiperSlide>
      </Swiper>
    </div>
  )
}
