import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import {
  ArtworkFilters,
  ArtworkList,
  BannerConfigComponent,
  BannerInfoAndTemplate,
  BannerSize,
  BannerUploadImageDialog,
  CenterAlignBox,
  CenterAlignStack,
  CustomOutlinedInput,
  DevEndpointDialog,
  DialogButton,
  DragDropFullSize,
  EmptyArtworkList,
  GuideComponent,
  NaverProductButton,
  NaverProductListDialog,
  ScrollToTop,
  SingleSimpleDragDrop,
  UploadHeader,
  UploadImageDialog,
  UploadMannequinDialog,
  UploadWrapper,
  triggerGA4UploadEvent,
} from 'components'
import { DropdownArrowIcon, EditIcon2, PlusIcon, SettingIcon } from 'theme/icon'

import { apis } from 'apis'
import {
  PORTFOLIO_CONFIG_DEFAULT,
  artworkListsIsFoldedAtom,
  artworkViewConfigAtom,
  autoUploadAtom,
  unusualPortfolioConfigUpdateAtom,
  blockDragDropAtom,
  defaultPortfolioAtom,
  dragDropOpenAtom,
  endpointAtom,
  portfolioConfigAtom,
  portfolioDetailAtom,
  tutorialAtom,
  uploadDialogOpenAtom,
  userAtom,
  userLikedBgAtom,
  welcomeDialogAtom,
  bannerSizeRatioAtom,
  brandReadOnlyConfigAtom,
  brandConfigUpdateAtom,
  hasProductAtom,
  isnoSOHumanDepictAtom,
  loadingAtom,
  uploadDialogOpen2Atom,
  artworkPageAtom,
  uploadFilesAtom,
  loadingSubAtom,
  keepShowingConfigAtom,
} from 'atoms'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import {
  OutputConfig,
  PortfolioCategory,
  PortfolioConcept,
  UploadConfig,
} from 'components/portfolio/PortfolioConfigDialog'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PuffLoader, BarLoader as Loader } from 'react-spinners'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { isPortfolioOwnerSelector, portfolioUploadConfigSelector } from 'selector'
import { Tutorial } from 'theme/Tutorial'
import { getAccessToken } from 'utils/cookie'
import { PortfolioConfigTabs } from '../components/portfolio/PortfolioConfig'
import { PAGE_HEADER_HEIGHT, ScrollHandler } from './PortfolioDetail'
import { useLocation, useNavigate } from 'react-router-dom'
import { NHNProductButton, NHNProductContainedButton, NHNProductListDialog } from './NHNCommerce'
import { PORTFOLIO_CONFIG_DEFAULT_BACK, GUIDE_VIDEOS } from 'config'
import { BsFillXCircleFill } from 'react-icons/bs'
import { BannerInfo, BannerTemplate } from 'components/portfolio/BannerConfig'
import { getS3ImageSrc, imageUrlToFile, isKo } from 'utils/common'
import useBanner, { ImageChannelChecker } from 'hooks/useBanner'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { usePortfolio } from 'hooks/usePortfolio'
import { EmptyUploadHeader } from './PortfolioUpload'
import { getStaticResourceLanguage } from 'hooks/useLanguage'

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {},
}))

const PageHeaderStyle = styled(Box)(({ theme }) => ({
  top: 0,
  minHeight: 'auto',
  justifyContent: 'center',
  alignItems: 'end',

  bordercolor: theme.palette.common.black,
  background: theme.palette.common.white,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '3.5rem',
    minHeight: PAGE_HEADER_HEIGHT,

    paddingBottom: '3.8rem',
    display: 'flex',
    flexDirection: 'row',
  },
}))

const fontStyles = {
  exTitle: { fontSize: '14px', fontWeight: 700 },
  exNotice: { fontSize: '10px', fontWeight: 700, textAlign: 'center' },
}

const uploadImglimit = 20

export default function BannerUpload() {
  const tutorial = useRecoilValue(tutorialAtom)
  const { t, i18n } = useTranslation()

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)

  const [brandReadOnlyConfig, setBrandReadOnlyConfig] = useRecoilState(brandReadOnlyConfigAtom)
  const [brandConfig, setBrandConfig] = useRecoilState(brandConfigUpdateAtom)
  const [keepShowingConfig, setKeepShowingConfig] = useRecoilState(keepShowingConfigAtom)

  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    setKeepShowingConfig(true)
    return () => {
      setKeepShowingConfig(false)
    }
  }, [])

  const addTabsArray = [
    {
      default: t('upload_header.brand_menu'),
      mobile: t('upload_header.brand_menu'),
      component: <BrandConfig />,
    },
  ]

  // useEffect(() => {
  //   console.log('brandReadOnlyConfig', brandReadOnlyConfig)
  //   console.log('brandConfig', brandConfig)
  // }, [brandReadOnlyConfig, brandConfig])

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'banner', {})
    // ---------------------

    return () => {
      setBlockDragDrop(false)
    }
  }, [])

  const token = getAccessToken()
  if (!token)
    return (
      <RootStyle sx={{ pb: '15rem' }}>
        <Mobile>
          <EmptyPageHeader />
        </Mobile>

        <Desktop>
          <EmptyUploadHeader videoSrc={GUIDE_VIDEOS.banner} />
        </Desktop>
        <EmptyArtworkList />
      </RootStyle>
    )

  return (
    <>
      <RootStyle
        style={{
          // overflow: tutorial.step === 15 ? 'hidden' : 'scroll',
          zIndex: tutorial.step === 17 ? '-1' : null,
        }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()

          if (!blockDragDrop) {
            setDragDropOpen(true)
          }
        }}
      >
        <>
          <UploadWrapper>
            {isMobile ? (
              <PageHeader />
            ) : (
              <UploadHeader
                configComponent={<BannerConfigComponent />}
                uploadButtonComponent={<BannerConfigComponent uploadButtonOnly={true} />}
                gudieComponent={<GuideComponent />}
                multiple={false}
                uploadImglimit={1}
                addTabsArray={addTabsArray}
                configDelay={0.3}
                configWidth={isKo(i18n) ? false : '90.2rem'}
              />
            )}
            {/* <PageHeader /> */}
            {/* <Desktop>
              <ConfigAccordionGroup />
            </Desktop> */}
          </UploadWrapper>
          <Desktop>
            <ArtworkList upload={true} hideGroupHeader={false} />
          </Desktop>
          <Mobile>
            <EmptyArtworkList message={<MobileExample />} sx={{ pt: '3rem' }} />
          </Mobile>
        </>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />
      {dragDropOpen && !blockDragDrop && <DragDropFullSize />}

      {/* </Wrapper> */}
    </>
  )
}

function PageHeader() {
  const { t, i18n } = useTranslation()
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)
  const [user, setUser] = useRecoilState(userAtom)
  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)
  const [uploadOpen2, setUploadOpen2] = useRecoilState(uploadDialogOpen2Atom)

  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)

  const [hasProduct, setHasProduct] = useRecoilState(hasProductAtom)
  const [isnoSOHumanDepict, setIsnoSoHumanDepict] = useRecoilState(isnoSOHumanDepictAtom)

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)

  const [page, setPage] = useRecoilState(artworkPageAtom)

  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetBannerConfig = useResetRecoilState(unusualPortfolioConfigUpdateAtom)
  const resetUploadDialogOpen = useResetRecoilState(uploadDialogOpenAtom)
  const resetHasProduct = useResetRecoilState(hasProductAtom)
  const resetIsnoSOHumanDepict = useResetRecoilState(isnoSOHumanDepictAtom)
  const resetUploading = useResetRecoilState(loadingAtom)
  const resetUploadImage = useResetRecoilState(uploadFilesAtom)

  const [uploading, setUploading] = useRecoilState(loadingAtom)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [openEndpointDialog, setOpenEndpointDialog] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)

  const isMann2manType = bannerConfig.genType === 'mann2man'

  const { disabledButton } = useBanner()

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter])

  const handleUpload = async (files, option = {}, addForm = {}) => {
    const uploadFiles = await prepareUpload(files)

    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploadButtonDiasbled(true)

    setUploadOpen(false)
    setUploading(true)

    const formData = await makeUploadFormData(uploadFiles, option, addForm, 'banner')

    // ----- GA4 event -----
    const genType = bannerConfig?.genType?.toLowerCase()
    const eventParams = {
      output_size_width: bannerConfig?.outputW,
      output_size_height: bannerConfig?.outputH,
      config_object_category: genType !== 'no_so' && bannerConfig?.productCategory ? 'yes' : 'no',
      config_object_sub_category:
        genType === 'full'
          ? 'no'
          : genType === 'mann2man'
          ? 'wearing'
          : genType === 'side' || (genType === 'no_so' && bannerConfig?.humanDepict)
          ? 'model'
          : 'no',

      // image_type:
      //   genType === 'full' && bannerConfig?.geodaiMax === true
      //     ? 'on'
      //     : bannerConfig?.geodaiMax === false
      //     ? 'off'
      //     : null,

      menu: '',
      method: '',

      count: uploadFiles.length,
    }

    // 1) 상품포함 O, AI 모델 X (full) -> 이 때만 image_type 수집
    // 2) 상품포함 O, AI 모델 O (side)
    // 3) 상품포함 X, AI 모델 X
    // 4) 상품포함 X, 상품을 착용한 모델 (mann2man)
    // 5) 상품포함 X, AI 모델 O (side)

    if (hasProduct) {
      if (genType === 'full') {
        eventParams.image_type = bannerConfig?.geodaiMax ? 'on' : 'off'
      }
    }

    window.gtag('event', 'banner_upload', eventParams)

    // ---------------------

    apis.appfront
      .makeBanner(formData)
      .then(() => {
        checkUserCredit()
        setUploading(false)
        setUploadButtonDiasbled(false)
        refreshArtworks()

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }

        if (page === 1) {
          const anchor = document.querySelector(`#scroll-anchor`)

          if (anchor) {
            anchor.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            })
          }
        }
      })

      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  const isMobile = useMobileMediaQuery()

  return (
    <>
      <PageHeaderLayout
        headerProps={{
          sx: {
            opacity: isFolded.value ? 0 : 1,
            transition: 'all 0.2s ease',
          },
          zIndex: isFolded.value
            ? -1
            : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
            ? 'auto'
            : null,
        }}
        buttonSx={{ width: { lg: '26rem', xs: '16.8rem' } }}
        uploadButtonProps={{
          disabled: uploading || !isOwner || uploadButtonDisabled || disabledButton || isMobile,
          onClick: () => {
            // ----- GA4 event -----
            window.gtag('event', 'banner_begin', { menu: 'top' })
            // ---------------------
            const body = { config: bannerConfig }

            apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
              setPortfolioDetail({ ...portfolioDetail, ...response.data })
            })
            setUploadOpen(true)
          },
        }}
        uploading={uploading}
        uploadDialog={
          <>
            {uploadOpen && (
              <BannerUploadImageDialog
                title1={
                  isMann2manType
                    ? t('banner_config.upload_title_1_mann2man')
                    : t('banner_config.upload_title_1')
                }
                title2={
                  isMann2manType
                    ? t('banner_config.upload_title_2_mann2man')
                    : t('banner_config.upload_title_2')
                }
                actionText={isMann2manType ? t('banner_config.upload_title_button') : false}
                sampleNotice={isMann2manType ? <SampleNoticeContent /> : ''}
                narrowGap={isMann2manType}
                handleUpload={handleUpload}
              />
            )}
            {uploadOpen2 && (
              <UploadMannequinDialog
                firstNotice={
                  <CenterAlignStack sx={{ gap: '4px' }}>
                    <Typography fontSize="2.2rem" fontWeight={700} sx={{ lineHeight: 'normal' }}>
                      {t('banner_config.upload_title_2_dialog_title')}
                    </Typography>
                    <Typography
                      fontSize="1.5rem"
                      fontWeight={400}
                      sx={{ textAlign: 'center', lineHeight: 'normal' }}
                    >
                      {t('banner_config.upload_title_2_dialog_notice_1')} <br />
                      {t('banner_config.upload_title_2_dialog_notice_2')}
                    </Typography>
                  </CenterAlignStack>
                }
                usage={'banner'}
                useUploadOpen2={true}
              />
            )}
          </>
        }
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
          />
        }
        brandConfig={
          <Desktop>
            <BrandConfigDialog />
          </Desktop>
        }
      >
        <DevEndpointDialog open={openEndpointDialog} setOpen={setOpenEndpointDialog} />
      </PageHeaderLayout>
      <Desktop>
        {process.env.NODE_ENV === 'development' && (
          <CenterAlignBox sx={{ width: '100%', position: 'relative' }}>
            <IconButton
              onClick={() => {
                setOpenEndpointDialog(true)
              }}
              sx={{ position: 'absolute', bottom: '1rem' }}
            >
              <SettingIcon color={Object.keys(endpoint).length > 0 ? 'red' : null} />
            </IconButton>
          </CenterAlignBox>
        )}
      </Desktop>
    </>
  )
}

function ConfigAccordionGroup({ blockInteraction = false }) {
  const [value, setValue] = useState(0)
  const [isExpanded, setIsExpanded] = useState(true)
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  const token = getAccessToken()

  const bannerConfig = useRecoilValue(unusualPortfolioConfigUpdateAtom)
  const [bannerSizeRatio, setBannerSizeRatio] = useRecoilState(bannerSizeRatioAtom)

  const { t } = useTranslation()

  const disabledButton = token
    ? !(
        bannerConfig.productName &&
        bannerConfig.productFeature &&
        bannerConfig.productCategory &&
        bannerConfig.outputW &&
        bannerConfig.outputH
      )
    : false

  const tabs = [
    {
      default: `${t('banner_config.info_title')}`,
      mobile: `${t('banner_config.info_title')}`,
    },
    {
      default: `${t('banner_config.template_title_1')}`,
      mobile: `${t('banner_config.template_title_1')}`,
      disabled: disabledButton,
    },
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue)

    if (newValue === 1) {
      setValue(1)
      const ratio = bannerConfig.outputW / bannerConfig.outputH
      setBannerSizeRatio(ratio)
    }
  }

  useEffect(() => {
    if (!isExpanded) {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 250)
    }
  }, [isExpanded])

  return (
    <CenterAlignStack>
      <CenterAlignStack sx={{ width: '118.4rem' }}>
        <PortfolioConfigTabs value={value} onChange={handleChange} tabs={tabs} />

        <Collapse in={isExpanded} sx={{}}>
          <ConfigPanel value={value} setValue={setValue} blockInteraction={blockInteraction} />
        </Collapse>

        <Button
          sx={{
            width: { lg: '118.4rem', xs: '32rem' },
            height: { lg: '5.2rem', xs: '3.2rem' },
            backgroundColor: '#eeeeee',
            borderTopLeftRadius: '0rem',
            borderTopRightRadius: '0rem',
            borderBottomLeftRadius: '1rem',
            borderBottomRightRadius: '1rem',
            mb: { lg: '4rem', xs: '3.2rem' },
            transition: 'all 1s',
            '& svg': {
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(360deg)',
              transition: 'all 0.4s',
            },
          }}
          onClick={() => {
            setIsExpanded(i => !i)
          }}
        >
          <DropdownArrowIcon
            color={theme.palette.common.black}
            style={isMobile ? {} : { width: '3.2rem', height: '1.6rem' }}
          />
        </Button>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}

export function ConfigPanel({ value, setValue, blockInteraction }) {
  const { addClickInterceptor } = usePleaseLoginDialog()
  useEffect(() => {
    if (blockInteraction) {
      const target = document.querySelector('#config-panel-wrapper')
      if (target) addClickInterceptor(target)
    }
  }, [value])

  switch (value) {
    case 0:
      return (
        <Box id="config-panel-wrapper" sx={{ width: '100%' }}>
          <BannerInfo value={value} setValue={setValue} />
        </Box>
      )
    case 1:
      return (
        <Box id="config-panel-wrapper" sx={{ width: '100%' }}>
          <BannerTemplate />
        </Box>
      )
  }
}

export function PageHeaderLayout({
  headerProps,
  uploadButtonProps,
  uploading,
  uploadDialog,
  artworkFilter,
  secondButton,
  buttonSx = {},
  children,
  comment = null,
  brandConfig,
}) {
  const { t } = useTranslation()

  return (
    <>
      <PageHeaderStyle {...headerProps}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { lg: 'flex-start', md: 'center', sm: 'center', xs: 'center' },
            alignItems: 'center',
            width: { lg: '35rem', xs: '100%' },
            px: 1,
          }}
        >
          {' '}
          {brandConfig}
        </Box>

        <Box
          sx={{
            width: { lg: '50rem', xs: '100%' },
            mt: { lg: 0, xs: '2rem' },
            mb: { lg: 0, xs: '2rem' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <>
            <Tutorial step="14_4">
              <Tutorial step={14}>
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    width: { lg: '26rem', xs: '16.8rem' },
                    height: { lg: '6rem', xs: '4rem' },
                    fontSize: { lg: '2rem', xs: '1.8rem' },
                    fontWeight: 800,
                    borderWidth: { lg: '0.2rem', xs: '0.1rem' },
                    ...buttonSx,
                  }}
                  {...uploadButtonProps}
                >
                  {!uploading && t('button.upload_image')}
                  {uploading && <Loader />}
                </Button>
                {secondButton}
              </Tutorial>
            </Tutorial>
            {uploadDialog}
          </>
        </Box>
        <Desktop>{artworkFilter}</Desktop>
        {children}
      </PageHeaderStyle>
      {comment && <CenterAlignBox>{comment}</CenterAlignBox>}
    </>
  )
}

export function EmptyPageHeader() {
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  const { showPleaseLoginDialog, addClickInterceptor } = usePleaseLoginDialog()

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    const targets = document.querySelectorAll('.artwork-filter-component')
    targets.forEach(t => {
      addClickInterceptor(t)
    })
  }, [])

  return (
    <>
      <PageHeaderLayout
        uploadButtonProps={{
          onClick: () => {
            showPleaseLoginDialog()
          },
        }}
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
            disabled={true}
          />
        }
        comment={
          <Typography
            sx={{
              color: theme => theme.palette.draph.blue,
              fontSize: { lg: '1.6rem', xs: '1.3rem' },
              cursor: 'pointer',
              fontWeight: 700,
              position: 'relative',
              top: { lg: '-2.6rem', xs: '-1.5rem' },
              animation: 'fadeIn 1s infinite alternate',
            }}
            onClick={() => {
              // ----- GA4 event -----
              window.gtag('event', 'non_member_sign_up_now', {})
              // ---------------------
              navigate('/login')
            }}
          >
            {t('upload_header_comment')}
          </Typography>
        }
      />
    </>
  )
}

const BrandConfigDialog = () => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)

  // const [file, setFile] = useState(null)

  const [brandReadOnlyConfig, setBrandReadOnlyConfig] = useRecoilState(brandReadOnlyConfigAtom)
  const [brandConfig, setBrandConfig] = useRecoilState(brandConfigUpdateAtom)
  const [uploadingSub, setUploadingSub] = useRecoilState(loadingSubAtom)

  const { logoSaveApi } = useBanner()

  // const [brandReadOnlyConfig, setBrandReadOnlyConfig] = useState({})
  // // const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  // const [brandConfig, setBrandConfig] = useState({ logoImage: '', brandName: '', brandFeature: '' })

  const setFile = param => file => {
    setBrandConfig(prevState => ({
      ...prevState,
      [param]: file,
    }))
  }

  // const updateConfigAndRefresh = () => {
  //   apis.user.getUserLogoImage().then(async r => {
  //     const d = r.data

  //     if (d) {
  //       const imageFile = d.s3_url ? await imageUrlToFile(getS3ImageSrc(d.s3_url)) : null

  //       setBrandReadOnlyConfig({ ...d, logoImage: imageFile })
  //       setBrandConfig({
  //         logoImage: imageFile ? [imageFile] : [],
  //         brandName: d.brand_name,
  //         brandFeature: d.brand_concept,
  //         logoS3path: d.s3_url,
  //       })
  //     }
  //   })
  // }

  const handleImageClose = () => {
    setBrandConfig(prev => ({ ...prev, logoImage: [] }))
  }

  const handleClose = () => {
    // ----- GA4 event -----
    window.gtag('event', 'banner_brand_complete', {
      method: 'close',
    })
    // ---------------------
    setOpen(false)
    setBrandConfig(prev => ({
      logoImage: brandReadOnlyConfig?.logoImage ? [brandReadOnlyConfig.logoImage] : [],
      brandName: brandReadOnlyConfig?.brand_name ?? '',
      brandFeature: brandReadOnlyConfig?.brand_concept ?? '',
      logoS3path: brandReadOnlyConfig?.s3_url ?? '',
    }))
  }

  const isDisabled = Boolean(
    (brandReadOnlyConfig === null &&
      !brandConfig.logoImage.length &&
      !brandConfig.brandName &&
      !brandConfig.brandFeature) ||
      uploadingSub
  )

  // useEffect(() => {
  //   apis.user.getUserLogoImage().then(r => {
  //     console.log(r.data)
  //     const d = r.data
  //     setBrandReadOnlyConfig(d)
  //     if (d) {
  //       setBrandConfig({
  //         logoImage: getS3ImageSrc(d.s3_url),
  //         brandName: d.brand_name,
  //         brandFeature: d.brand_concept,
  //       })
  //     }
  //   })

  //   return () => {}
  // }, [])

  const handleAction = async () => {
    // const body = { config: bannerConfig }
    // apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
    //   setPortfolioDetail({ ...portfolioDetail, ...response.data })
    // })

    // ----- GA4 event -----
    window.gtag('event', 'banner_brand_complete', {
      method: 'apply',
    })
    // ---------------------

    const logo = await logoSaveApi()

    // setUploadingSub(false)
    // const formData = new FormData()

    // formData.append('image', brandConfig.logoImage[0] ?? '')
    // formData.append('name', brandConfig.brandName)
    // formData.append('concept', brandConfig.brandFeature)

    // apis.user.postUserLogoImage(formData).then(() => {
    //   updateConfigAndRefresh()
    // })

    // setBannerConfig(prev => ({
    //   ...prev,
    //   ...brandConfig,
    // }))
    setOpen(false)
  }

  return (
    <>
      <CenterAlignBox sx={{ display: 'flex', alignItems: 'center' }}>
        {brandReadOnlyConfig?.s3_url ? (
          <Box
            sx={{
              width: '4.8rem',
              height: '4.8rem',
              backgroundColor: '#EEEEEE',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={getS3ImageSrc(brandReadOnlyConfig?.s3_url)}
              style={{
                width: '4.4rem',
                height: '4.4rem',
                // backgroundColor: '#EEEEEE',
                borderRadius: '10px',
                objectFit: 'contain',
              }}
            />
          </Box>
        ) : (
          <IconButton
            sx={{
              width: '4.8rem',
              height: '4.8rem',
              backgroundColor: '#EEEEEE',
              borderRadius: '10px',
              '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                borderRadius: '10px',
              },
            }}
            onClick={() => {
              // ----- GA4 event -----
              window.gtag('event', 'banner_brand_begin', {
                method: 'plus',
              })
              // ---------------------
              setOpen(true)
            }}
          >
            <PlusIcon style={{ width: '2.4rem', height: '2.4rem' }} strokeWidth="5.5" />
          </IconButton>
        )}
        <Typography
          sx={{
            fontSize: '2rem',
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '14rem',
            ml: '1.2rem',
          }}
        >
          {brandReadOnlyConfig?.brand_name || '브랜드 정보'}
        </Typography>
        {(brandReadOnlyConfig?.brand_name ||
          brandReadOnlyConfig?.s3_url ||
          brandReadOnlyConfig?.brand_concept) && (
          <IconButton
            sx={{
              width: '2.8rem',
              height: '2.8rem',
              // backgroundColor: 'rgb(123,23,186,0.5)',
              borderRadius: '10px',
              '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                borderRadius: '10px',
              },
            }}
            onClick={() => {
              // ----- GA4 event -----
              window.gtag('event', 'banner_brand_begin', {
                method: 'edit',
              })
              // ---------------------
              setOpen(true)
            }}
          >
            <EditIcon2 />
          </IconButton>
        )}
        {/* <button onClick={updateConfigAndRefresh}>dd</button> */}
      </CenterAlignBox>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: { lg: 'auto', xs: '320px' },
              minWidth: { lg: '46.8rem', xs: '430px' },
              margin: { lg: '20px', xs: 0 },
              borderRadius: '20px',
              maxWidth: 'none',
            },
          }}
        >
          <DialogContent
            sx={{
              overflowY: 'hidden',
              padding: { lg: '3rem 2.9rem 3.5rem 2.9rem', xs: '33px 0 25px 0' },
            }}
          >
            <CenterAlignStack>
              <Typography
                sx={{ fontSize: '2rem', fontWeight: 700, lineHeight: 'normal', mb: '0.4rem' }}
              >
                {t('banner_config.logo_dialog_title')}
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  lineHeight: 'normal',
                  color: '#595959',
                  textAlign: 'center',
                }}
              >
                {t('banner_config.logo_dialog_notice_1')}
                <br />
                {t('banner_config.logo_dialog_notice_2')}
              </Typography>

              <Box sx={{ display: 'flex', p: '2.4rem 0 2.8rem 0', gap: '3.8rem' }}>
                <CenterAlignStack sx={{ position: 'relative' }}>
                  <Typography sx={{ fontSize: '1.6rem', fontWeight: 700, mb: '1.2rem' }}>
                    {t('banner_config.logo_dialog_subtitle_1')}
                  </Typography>
                  <SingleSimpleDragDrop
                    file={brandConfig.logoImage}
                    setFile={setFile('logoImage')}
                    width="116px"
                    height="116px"
                  />

                  {/* {brandConfig.logoImage.length && (
                    <IconButton
                      onClick={handleImageClose}
                      sx={{
                        position: 'absolute',
                        top: '1.7rem',
                        right: '-2rem',
                        fontSize: '2rem ',
                      }}
                    >
                      <BsFillXCircleFill
                        style={{
                          background: 'white',
                          borderRadius: '50px',
                          width: '2.4rem',
                          height: '2.4rem',
                          padding: '1px',
                        }}
                      />
                    </IconButton>
                  )} */}
                </CenterAlignStack>

                <Stack>
                  {/* <Typography
                    sx={{ fontSize: '1.6rem', fontWeight: 700, mb: '1.2rem', lineHeight: 'normal' }}
                  >
                    브랜드 정보를 입력해주세요.{' '}
                  </Typography> */}
                  <Typography
                    sx={{ fontWeight: 500, fontSize: '1.4rem', lineHeight: 'normal', mt: '1.4rem' }}
                  >
                    {t('banner_config.logo_dialog_subtitle_2')}
                  </Typography>
                  <CustomOutlinedInput
                    value={brandConfig.brandName}
                    onChange={e => {
                      setBrandConfig(prev => ({ ...prev, brandName: e.target.value }))
                    }}
                    sx={{
                      width: '24rem',
                      height: '4rem',
                      '& input': { textAlign: 'start' },
                      mt: '0.4rem',
                    }}
                  />
                  <Typography
                    sx={{ fontWeight: 500, fontSize: '1.4rem', mt: '1.4rem', lineHeight: 'normal' }}
                  >
                    {t('banner_config.logo_dialog_subtitle_3')}
                  </Typography>
                  <CustomOutlinedInput
                    value={brandConfig.brandFeature}
                    onChange={e => {
                      setBrandConfig(prev => ({ ...prev, brandFeature: e.target.value }))
                    }}
                    sx={{
                      width: '24rem',
                      height: '4rem',
                      '& input': { textAlign: 'start' },
                      mt: '0.4rem',
                    }}
                  />
                </Stack>
              </Box>

              <DialogButton
                actionText={
                  uploadingSub ? <PuffLoader size={35} /> : t('banner_config.logo_dialog_button')
                }
                cancelText={t('button.close')}
                handleAction={handleAction}
                handleClose={handleClose}
                actionprops={{ disabled: isDisabled }}
              />
            </CenterAlignStack>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

const SampleNoticeContent = () => {
  const { t, i18n } = useTranslation()

  return (
    <CenterAlignStack sx={{ mb: '20px' }}>
      <CenterAlignStack sx={{ gap: '4px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 700, lineHeight: 'normal' }}>
          {t('banner_config.upload_title_2_dialog_title')}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '14px',
            color: '#595959',
            lineHeight: 'normal',
          }}
        >
          {t('banner_config.upload_title_2_dialog_notice_1')} <br />
          {t('banner_config.upload_title_2_dialog_notice_2')}
        </Typography>
      </CenterAlignStack>
      <CenterAlignBox sx={{ gap: '20px', mt: '16px' }}>
        <CenterAlignStack
          sx={{
            p: '10px 0 11px 0',
            backgroundColor: '#EDF2FF',
            width: '204px',
            borderRadius: '10px',
          }}
        >
          <Typography sx={{ ...fontStyles.exTitle, color: '#4D80FF', m: '0px 0 8px 0' }}>
            {t('banner_config.upload_title_2_dialog_possible')}
          </Typography>
          <CenterAlignBox sx={{ gap: '12px' }}>
            <CenterAlignStack sx={{ gap: '6px', width: '8rem' }}>
              <img
                style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '10px',
                  objectFit: 'cover',
                }}
                src="/static/images/banner/banner_ex1_2.jpeg"
              />

              <Typography sx={fontStyles.exNotice}>
                {t('banner_config.upload_title_2_dialog_possible_ex_1')}
              </Typography>
            </CenterAlignStack>
            <CenterAlignStack sx={{ gap: '6px', width: '8rem' }}>
              <img
                style={{ width: '80px', height: '80px', borderRadius: '10px' }}
                src="/static/images/banner/banner_ex1_1.png"
              />

              <Typography sx={fontStyles.exNotice}>
                {t('banner_config.upload_title_2_dialog_possible_ex_2')}
              </Typography>
            </CenterAlignStack>
          </CenterAlignBox>
        </CenterAlignStack>

        <CenterAlignStack
          sx={{
            p: '10px 0 11px 0',
            backgroundColor: '#FFEBEB',
            width: '204px',
            borderRadius: '10px',
          }}
        >
          <Typography sx={{ ...fontStyles.exTitle, color: '#FF2323', m: '0px 0 8px 0' }}>
            {t('banner_config.upload_title_2_dialog_impossible')}
          </Typography>
          <CenterAlignBox sx={{ gap: '12px' }}>
            <CenterAlignStack sx={{ gap: '6px' }}>
              <img
                style={{ width: '80px', height: '80px', borderRadius: '10px' }}
                src="/static/images/banner/banner_ex2_1.jpeg"
              />
              <Typography sx={fontStyles.exNotice}>
                {t('banner_config.upload_title_2_dialog_impossible_ex_1')}
              </Typography>
            </CenterAlignStack>
            <CenterAlignStack sx={{ gap: '6px' }}>
              <img
                style={{ width: '80px', height: '80px', borderRadius: '10px' }}
                src="/static/images/banner/banner_ex2_2.png"
              />
              <Typography sx={fontStyles.exNotice}>
                {t('banner_config.upload_title_2_dialog_impossible_ex_2')}
              </Typography>
            </CenterAlignStack>
          </CenterAlignBox>
        </CenterAlignStack>
      </CenterAlignBox>
    </CenterAlignStack>
  )
}

const MobileExample = () => {
  const { t, i18n } = useTranslation()
  return (
    <CenterAlignStack>
      <Typography>{t('banner_config.only_PC')}</Typography>
      <Stack
        flexDirection="row"
        sx={{
          mt: '1.6rem',
          gap: '1.2rem',
          justifyContent: 'center',
          alignItems: 'center',
          '& .image': {
            width: '12.8rem',
            height: '12.8rem',
            objectFit: 'cover',
            borderRadius: '10px',
          },

          '& .image img': {
            borderRadius: '10px',
          },

          '& .arrow-image': {
            width: '4rem',
            height: ' 4rem',
          },
        }}
      >
        <Box className="image">
          <img src="/static/images/api_info/feature_1_before.png" />
        </Box>
        <Box className="arrow-image">
          <img src="/static/images/api_info/gradient_arrow.png" />
        </Box>
        <Box className="image">
          <img
            src={`/static/images/banner/square_full_${getStaticResourceLanguage(
              i18n.language
            )}.png`}
          />
        </Box>
      </Stack>

      <CenterAlignBox
        sx={{
          width: '32rem',
          minHeight: '6.8rem',
          py: '1.5rem',
          boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.2)',
          borderRadius: '1rem',
          mt: '1.6rem',
        }}
      >
        <Typography sx={{ textAlign: 'center', lineHeight: 'normal', fontSize: '1.4rem' }}>
          {t('banner_config.only_PC_notice_1')}
          <br />
          <span style={{ fontWeight: 600 }}> {t('banner_config.only_PC_notice_2')}</span>
        </Typography>
      </CenterAlignBox>
    </CenterAlignStack>
  )
}

const BrandConfig = () => {
  const { t, i18n } = useTranslation()

  const theme = useTheme()
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)

  const [brandReadOnlyConfig, setBrandReadOnlyConfig] = useRecoilState(brandReadOnlyConfigAtom)
  const [brandConfig, setBrandConfig] = useRecoilState(brandConfigUpdateAtom)
  const [uploadingSub, setUploadingSub] = useRecoilState(loadingSubAtom)

  const { logoSaveApi } = useBanner()

  const setFile = param => file => {
    setBrandConfig(prevState => ({
      ...prevState,
      [param]: file,
    }))
  }

  const handleImageClose = () => {
    setBrandConfig(prev => ({ ...prev, logoImage: [] }))
  }

  const handleClose = () => {
    // ----- GA4 event -----
    window.gtag('event', 'banner_brand_complete', {
      method: 'close',
    })
    // ---------------------

    setBrandConfig(prev => ({
      logoImage: brandReadOnlyConfig?.logoImage ? [brandReadOnlyConfig.logoImage] : [],
      brandName: brandReadOnlyConfig?.brand_name ?? '',
      brandFeature: brandReadOnlyConfig?.brand_concept ?? '',
      logoS3path: brandReadOnlyConfig?.s3_url ?? '',
    }))
  }

  const handleReset = () => {
    // ----- GA4 event -----
    window.gtag('event', 'banner_brand_complete', {
      method: 'close',
    })
    // ---------------------

    setBrandConfig(prev => ({
      logoImage: [],
      brandName: '',
      brandFeature: '',
      logoS3path: '',
    }))
  }

  const isDisabled = Boolean(
    (brandReadOnlyConfig === null &&
      !brandConfig.logoImage.length &&
      !brandConfig.brandName &&
      !brandConfig.brandFeature) ||
      uploadingSub
  )

  const handleAction = async () => {
    // ----- GA4 event -----
    window.gtag('event', 'banner_brand_complete', {
      method: 'apply',
    })
    // ---------------------

    const logo = await logoSaveApi()
  }

  return (
    <CenterAlignStack
      sx={{
        p: '3.6rem 3rem',
        backgroundColor: 'white',
        boxShadow: '4px 4px 20px 0px #0000001A',
        borderRadius: '0 0 10px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <CenterAlignStack sx={{ position: 'relative', gap: '1.2rem' }}>
          <SingleSimpleDragDrop
            file={brandConfig.logoImage}
            setFile={setFile('logoImage')}
            inputStyle={{ backgroundColor: theme.palette.common.black }}
            iconStyle={{ color: theme.palette.common.white }}
            width="9.2rem"
            height="9.2rem"
          />
          <Typography sx={{ fontSize: '1.6rem', fontWeight: 700 }}>
            {t('banner_config.logo_dialog_subtitle_1')}
          </Typography>
        </CenterAlignStack>

        <Divider flexItem orientation="vertical" sx={{ mx: '2rem' }} />

        <Stack>
          {/* <Typography
        sx={{ fontSize: '1.6rem', fontWeight: 700, mb: '1.2rem', lineHeight: 'normal' }}
      >
        브랜드 정보를 입력해주세요.{' '}
      </Typography> */}
          <Typography sx={{ fontWeight: 600 }}>{t('upload_header.brand_title')}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: isKo(i18n) ? '0.8rem' : '2.6rem',
              mt: '1.6rem',
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: '1.4rem', lineHeight: 'normal' }}>
              {t('banner_config.logo_dialog_subtitle_2')}
            </Typography>
            <CustomOutlinedInput
              value={brandConfig.brandName}
              onChange={e => {
                setBrandConfig(prev => ({ ...prev, brandName: e.target.value }))
              }}
              sx={{
                width: '21.2rem',
                height: '3.6rem',
                '& input': { textAlign: 'start' },
                mt: '0.4rem',

                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderWidth: '0.1rem',
                    borderColor: brandConfig.brandName ? theme.palette.common.black : '#D9E1EC',
                  },
                },
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem', mt: '1.2rem' }}>
            <Typography sx={{ fontWeight: 500, fontSize: '1.4rem', lineHeight: 'normal' }}>
              {t('banner_config.logo_dialog_subtitle_3')}
            </Typography>
            <CustomOutlinedInput
              value={brandConfig.brandFeature}
              onChange={e => {
                setBrandConfig(prev => ({ ...prev, brandFeature: e.target.value }))
              }}
              placeholder={t('banner_config.logo_dialog_ex1')}
              sx={{
                width: '21.2rem',
                height: '3.6rem',
                '& input': { textAlign: 'start' },
                mt: '0.4rem',

                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderWidth: '0.1rem',
                    borderColor: brandConfig.brandFeature ? theme.palette.common.black : '#D9E1EC',
                  },
                },
              }}
            />
          </Box>
        </Stack>
      </Box>

      <CenterAlignBox sx={{ mt: '2.8rem', gap: '0.8rem' }}>
        <Button
          variant="outlined"
          onClick={() => {
            handleReset()
          }}
          sx={{ width: '10.8rem', fontWeight: 600, fontSize: '1.4rem' }}
        >
          {t('button.reset')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleAction()
          }}
          disabled={isDisabled}
          sx={{ width: '10.8rem', fontWeight: 600, fontSize: '1.4rem' }}
        >
          {uploadingSub ? <PuffLoader size={23} /> : t('banner_config.logo_dialog_button')}
        </Button>
      </CenterAlignBox>
      {/* <DialogButton
        actionText={uploadingSub ? <PuffLoader size={35} /> : t('banner_config.logo_dialog_button')}
        cancelText={t('button.close')}
        handleAction={handleAction}
        handleClose={handleClose}
        actionprops={{ disabled: isDisabled }}
      /> */}
    </CenterAlignStack>
  )
}
