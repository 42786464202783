import { useTranslation } from 'react-i18next'
import { getCurrencyFormatter } from 'utils/common'

export function usePricing() {
  const { t, i18n } = useTranslation()
  const isKo = i18n.language === 'ko'

  const pricingPlanDict = {
    subscription: t('payment.subscription'),
    payGo: t('payment.payGo'),
    paygo: t('payment.paygo'),
    monthly: t('payment.monthly'),
  }

  const paymentMethodDict = {
    card: t('payment.card'),
    paypal: t('payment.paypal'),
  }

  const getPlanDisplayName = planId => {
    if (!planId) return
    let name = planId
    if (planId.includes('monthly')) {
      name = name.replace('monthly', `${pricingPlanDict.monthly} `)
    } else if (planId.includes('paygo')) {
      name = name.replace('paygo', `${pricingPlanDict.paygo} `)
    }
    return name
  }

  const getCurrencySymbol = (currency = isKo ? 'krw' : 'usd') => {
    const c = currency?.toLowerCase() ?? ''
    if (c === 'usd') {
      return '$'
    } else if (c === 'krw') {
      return '₩'
    }

    return ''
  }

  // 플랜에서 가격만
  const getPlanPrice = (plans, planId) => {
    if (!planId) return
    // 간이 예외처리 ㅠㅠ
    if (planId.includes('admin')) {
      const splitPlanId = planId.split('-')
      planId = splitPlanId[0]
    }

    const allPlans = [...plans.subscription, ...plans.payGo]
    const plan = allPlans.find(p => p.id === planId)
    if (!plan) return

    const currency = isKo ? 'krw' : 'usd'
    const currencyFormatter = getCurrencyFormatter(currency)
    const v = currencyFormatter.format(plan.price[currency]).slice(1)

    return v
  }

  return {
    pricingPlanDict,
    paymentMethodDict,
    getPlanDisplayName,
    getCurrencySymbol,
    getPlanPrice,
  }
}
