import { RecoilDebugButton } from '../../components'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import A from './A.js'
import B from './B.js'
import C from './C.js'
import { useTranslation } from 'react-i18next'

export default function TestPage2() {
  const navigate = useNavigate()
  const [render, setRender] = useState('B')
  const { t } = useTranslation()

  return (
    <>
      <h1>테스트퓨ㅔ이지 3</h1>
      <h2>{t('한국어')}</h2>

      {render === 'A' ? <A /> : render === 'B' ? <B /> : <C />}
      <br />
      <br />

      <button
        onClick={() => {
          setRender('A')
        }}
      >
        show A
      </button>
      <button
        onClick={() => {
          setRender('B')
        }}
      >
        show B
      </button>
      <button
        onClick={() => {
          setRender('C')
        }}
      >
        show C
      </button>
      <br />
      <button
        onClick={() => {
          navigate('/test')
        }}
      >
        navigate to /test
      </button>
    </>
  )
}
