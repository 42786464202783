import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  InputBase,
  Stack,
  useTheme,
  Button,
  ListItemButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Collapse,
  Tabs,
  Tab,
} from '@mui/material'
import { segmentStepAtom } from 'atoms'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { TokenDialogContent, langCodeSample } from './APIMain'
import { AnimatedHeightDialog } from 'components'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { enqueueSnackbar } from 'notistack'
import { TabPanel } from '@mui/lab'
import { useInView } from 'react-intersection-observer'
import { getAccessToken } from 'utils/cookie'
import { useNavigate } from 'react-router-dom'

const style = {
  contentBox: {
    p: '2rem',
  },
  contentTitle: {
    fontSize: '2.8rem',
    fontWeight: 700,
  },

  contentSubTitle: {
    fontSize: '2.2rem',
    fontWeight: 500,
  },

  contentNoticeTitle: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  contentNotice: {
    fontSize: '1.6rem',
  },
}

export const APIContent = ({ setSelectedCategory }) => {
  const [openTokenDialog, setOpenTokenDialog] = useState(false)
  const [page, setPage] = useRecoilState(segmentStepAtom)
  const [dialogSnakeBar, setDialogSnakeBar] = useState('')

  const [hover, setHover] = useState(false)
  const [value, setValue] = useState('remove')
  const [codelang, setCodeLang] = useState('python')

  const token = getAccessToken()
  const navigate = useNavigate()

  const handleClose = () => {
    setDialogSnakeBar('')
    setOpenTokenDialog(false)
    setPage(0)
  }

  const { ref, inView } = useInView({ threshold: 0.3 })

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleLangChange = (event, newValue) => {
    setCodeLang(newValue)
  }

  const tokenDialogClick = () => {
    if (token) {
      setOpenTokenDialog(true)
    } else {
      localStorage.setItem('landingPoint', '/api_main')
      navigate('/login')
    }
  }

  const pageToHeight = (page, add) => {
    const addHeight = add ? 2.4 : 0

    switch (page) {
      case 0:
        return 25.2 + addHeight + 'rem'
      case 1:
        return 35.2 + addHeight + 'rem'

      default:
        return 'auto'
    }
  }

  const handleCopyClipBoard = async copy => {
    try {
      await navigator.clipboard.writeText(copy)
      enqueueSnackbar('클립보드에 링크가 복사되었어요.', { variant: 'success' })
    } catch (err) {
      console.log(err)
    }
  }

  const headerTableBody = [
    {
      parameter: 'Authorization',
      type: 'str',
      description: ' • Bearer your-token-here',
    },
  ]

  const bodyTableBody = [
    {
      parameter: 'image',
      type: 'UploadFile',
      description: '업로드하는 이미지 파일',
    },
    // {
    //   parameter: 'user_id',
    //   type: 'int',
    //   description: (
    //     <>
    //       <Typography>• 유저의 고유 식별 번호</Typography>
    //       <Typography>• B2B의 경우, 발급된 user_id를 고정값으로 넣어서 요청</Typography>
    //     </>
    //   ),
    // },
    {
      parameter: 'username',
      type: 'str',
      description: (
        <>
          <Typography>• 유저의 고유 이름</Typography>
          <Typography>• B2B의 경우, 가입 시 요청한 username을 고정값으로 넣어서 요청</Typography>
        </>
      ),
    },
    {
      parameter: 'gen_type',
      type: 'str',
      description: (
        <>
          <Typography>• 생성하고자하는 이미지의 타입 - 총 4가지의 타입을 지정 가능</Typography>
          <Stack sx={{ pl: '2rem' }}>
            <Typography>
              <span style={{ fontWeight: 600 }}>• remove_bg</span> : 배경을 제거한 누끼 이미지를
              생성
            </Typography>
            <Typography>
              {' '}
              <span style={{ fontWeight: 600 }}>• color_bg</span> : 배경을 제거하고 단색 배경
              이미지를 생성
            </Typography>
            <Typography>
              {' '}
              <span style={{ fontWeight: 600 }}>• simple</span> : 상품에 어울리는 심플한 배경을 생성
            </Typography>
            <Typography>
              <span style={{ fontWeight: 600 }}>• concept (default)</span> : 직접 테마를 지정하여
              테마에 맞는 배경을 생성
            </Typography>
          </Stack>
        </>
      ),
    },
    {
      parameter: 'multiblob_sod',
      type: 'bool (optional)',
      description: (
        <>
          <Typography>• 입력 이미지에서 상품이 한개인지 여러개인지에 대한 옵션</Typography>
          <Typography>• default : False</Typography>
        </>
      ),
    },
    {
      parameter: 'output_w',
      type: 'int (optional)',
      description: (
        <>
          <Typography>• 결과 이미지의 너비를 지정</Typography>
          <Typography>• default: 1000 (pixel)</Typography>
          <Typography>• range: 200~2000</Typography>
        </>
      ),
    },
    {
      parameter: 'output_h',
      type: 'int (optional)',
      description: (
        <>
          <Typography>• 결과 이미지의 너비를 지정</Typography>
          <Typography>• default: 1000 (pixel)</Typography>
          <Typography>• range: 200~2000</Typography>
        </>
      ),
    },
    {
      parameter: 'bg_color_hex_code',
      type: 'str (optional)',
      description: (
        <>
          <Typography>• gen_type=“color_bg”인 경우 생성될 단색 배경 RGB의 hex code</Typography>
          <Typography>• default: #FFFFFF (white)</Typography>
          <Typography>• 예시) white의 경우 : #FFFFFF0</Typography>
        </>
      ),
    },
    {
      parameter: 'concept_option',
      type: 'str (optional)',
      description: (
        <>
          <>
            <Typography sx={{ textIndent: '-1rem', ml: '1rem' }}>
              • gen_type=“concept”인 경우 생성될 테마, 입력 상품의 카테고리, 결과 이미지 개수를
              지정하는 옵션
            </Typography>
            <Typography sx={{ textIndent: '-1rem', ml: '1rem' }}>
              • Json 형식 string으로 입력. 총 3개의 key-value
            </Typography>
            <Stack sx={{ pl: '2rem' }}>
              <Typography>
                <span style={{ fontWeight: 600 }}>• category: str (optional)</span>
              </Typography>
              <Stack sx={{ pl: '2rem' }}>
                <Typography sx={{ textIndent: '-1rem', ml: '1rem' }}>
                  • 입력 상품의 카테고리를 [“cosmetics”,”food”, “clothes”, “person”, “car”,
                  “others”] 중에서 선택, 입력하지 않을 경우 자동으로 감지하여 동작
                </Typography>
              </Stack>
              <Typography>
                <span style={{ fontWeight: 600 }}>• theme: str (optional)</span>
              </Typography>
              <Stack sx={{ pl: '2rem' }}>
                <Typography sx={{ textIndent: '-1rem', ml: '1rem' }}>
                  • 생성하고자 하는 배경에 대한 설명을 입력, 입력하지 않을 경우 적절하게 배경을
                  생성. 한국어, 영어만 지원 가능
                </Typography>
              </Stack>
              <Typography>
                <span style={{ fontWeight: 600 }}>• num_results: int (optional)</span>
              </Typography>
              <Stack sx={{ pl: '2rem' }}>
                <Typography sx={{ textIndent: '-1rem', ml: '1rem' }}>
                  • 생성하고자하는 결과 이미지 갯수.
                </Typography>
                <Typography sx={{ textIndent: '-1rem', ml: '1rem' }}>
                  • 1~4까지 입력가능하며 입력하지 않을 경우 1장만 반환
                </Typography>
              </Stack>
            </Stack>
          </>
        </>
      ),
    },
  ]

  const errorCase = [
    {
      title: '• 유효하지 않은 token이 입력된 경우',
      content: `API 호출 실패:
상태 코드: 500
응답 내용: {"detail":"token is invalid"}`,
    },
    {
      title: '• 크레딧이 부족한 경우',
      content: `API 호출 실패:
상태 코드: 500
응답 내용: 응답 내용: {"detail":"no available credit"}`,
    },
    {
      title: '• 유효하지 않은 gen_type이 입력된 경우',
      content: `API 호출 실패:
상태 코드: 500
응답 내용: {"detail":"gen_type : your_input, invalid gen_type"}`,
    },
    {
      title: '• output_w(or h)이 범위를 벗어난 경우 (4000을 입력으로 준 예시)',
      content: `API 호출 실패:
상태 코드: 500
응답 내용: {"detail":"output_w : 4000, output_w is out of range"}`,
    },
  ]

  const genTypeErrorCase = [
    {
      title: '• gen_type=“color_bg” 에러 케이스',
      notice: '• 유효하지않은 RGB hex code가 입력된 경우 (bg_color_hex_code = “#XYZXYZ”)',
      content: `API 호출 실패:
상태 코드: 500
응답 내용: {"detail":"hex code:#XYZXYZ, invalid hex code"}`,
    },
    {
      title: '• gen_type=”concept” 에러 케이스',
      notice: '• 유효하지 않은 category가 입력된 경우',
      content: `API 호출 실패:
상태 코드: 500
응답 내용: {"detail":"category: your_input_category is not in list"}`,
    },
    {
      title: '• num_results가 범위를 벗어난 경우 (num_results=20)',

      content: `API 호출 실패:
상태 코드: 500
응답 내용: {"detail":"category: your_input_category is not in list"}`,
    },
  ]

  const genTypeCodeSample = {
    remove: `data={
  "username": your_name,
  "gen_type": "remove_bg",
  "multiblob_sod": False,
  "output_w": 1200,
  "output_h": 800
}`,
    colorBg: `data={
  "username": your_name,
  "gen_type": "color_bg",
  "multiblob_sod": False,
  "output_w": 1200,
  "output_h": 800
}`,
    simple: `data={
  "username": your_name,
  "gen_type": "simple",
  "multiblob_sod": False,
  "output_w": 1200,
  "output_h": 800
}`,
  }

  const { ref: tokenRef, inView: tokenInView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })
  const { ref: apisRef, inView: apisInView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })
  const { ref: sub21Ref, inView: sub21InView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })
  const { ref: sub211Ref, inView: sub211InView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })
  const { ref: sub212Ref, inView: sub212InView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })
  const { ref: sub213Ref, inView: sub213InView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })
  const { ref: sub214Ref, inView: sub214InView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })
  const { ref: sub215Ref, inView: sub215InView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })
  const { ref: sub216Ref, inView: sub216InView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })
  const { ref: sampleRef, inView: sampleInView } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px 0px 0px',
  })

  useEffect(() => {
    if (tokenInView) setSelectedCategory('Token')
    else if (apisInView) setSelectedCategory('APIs')
    else if (sub21InView) setSelectedCategory('Sub 2-1')
    else if (sub211InView) setSelectedCategory('Sub 2-1-1')
    else if (sub212InView) setSelectedCategory('Sub 2-1-2')
    else if (sub213InView) setSelectedCategory('Sub 2-1-3')
    else if (sub214InView) setSelectedCategory('Sub 2-1-4')
    else if (sub215InView) setSelectedCategory('Sub 2-1-5')
    else if (sub216InView) setSelectedCategory('Sub 2-1-6')
    else if (sampleInView) setSelectedCategory('Sample')
  }, [
    tokenInView,
    apisInView,
    sub21InView,
    sub211InView,
    sub212InView,
    sub213InView,
    sub214InView,
    sub215InView,
    sub216InView,
    sampleInView,
  ])

  return (
    <Stack>
      <Box id="Token" ref={tokenRef} sx={{ ...style.contentBox }}>
        <Stack sx={{ gap: '1.6rem' }}>
          <Typography sx={{ ...style.contentTitle }}>1. API 토큰</Typography>
          <Stack sx={{}}>
            <Typography>
              - http://draph.art에 로그인 후 마이페이지-API-API Token 관리에서 생성하실 수 있습니다.
              <br />- 발급 받은 토큰은 이후 이미지 생성 api를 호출할 때 헤더에 필요
            </Typography>
          </Stack>
          <Button
            variant="contained"
            sx={{ width: '20rem', height: '4.8rem', fontSize: '1.8rem', fontWeight: 700 }}
            onClick={() => {
              tokenDialogClick()
            }}
          >
            API 토큰 관리하기
          </Button>

          {openTokenDialog && (
            <AnimatedHeightDialog
              open={openTokenDialog}
              setOpen={setOpenTokenDialog}
              paperStyle={{
                width: '40.6rem',
                padding: '2.8rem 2.8rem',
                height: pageToHeight(page, dialogSnakeBar),
              }}
              backdropClose={false}
              sx={{}}
            >
              <TokenDialogContent
                dialogSnakeBar={dialogSnakeBar}
                setDialogSnakeBar={setDialogSnakeBar}
                handleClose={handleClose}
              />
            </AnimatedHeightDialog>
          )}
        </Stack>
      </Box>

      <Stack sx={{ ...style.contentBox, gap: '1.6rem' }}>
        <Typography id="APIs" ref={apisRef} sx={{ ...style.contentTitle }}>
          2.API 명세
        </Typography>

        <Typography id="Sub 2-1" ref={sub21Ref} sx={{ ...style.contentSubTitle }}>
          • 이미지 생성 API
        </Typography>

        <Stack id="Sub 2-1-1" ref={sub211Ref} sx={{ gap: '0.4rem' }}>
          <Typography sx={{ ...style.contentNoticeTitle }}>URL</Typography>
          <Typography sx={{ ...style.contentNotice }}>
            https://api.draph.art/v1/generate/
          </Typography>
        </Stack>
        <Stack id="Sub 2-1-2" ref={sub212Ref} sx={{ gap: '0.4rem' }}>
          <Typography sx={{ ...style.contentNoticeTitle }}>Method</Typography>
          <Typography sx={{ ...style.contentNotice }}>Post</Typography>
        </Stack>
      </Stack>

      <Stack id="Sub 2-1-3" ref={sub213Ref}>
        <Typography sx={{ ...style.contentNoticeTitle, p: '1.2rem 2rem 1.6rem' }}>
          Header
        </Typography>

        <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: '#EEEEEE',
                  '& .MuiTableCell-root': {
                    fontSize: '1.6rem',
                    fontWeight: 600,
                    border: 'none',
                    verticalAlign: 'baseline',
                  },
                }}
              >
                <TableCell sx={{ pl: '2rem', pr: '6.5rem', width: '10%' }}>Parameter</TableCell>
                <TableCell sx={{ pl: 0, pr: '8rem', width: '10%' }}>Type</TableCell>
                <TableCell sx={{}}>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {headerTableBody.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '& .MuiTableCell-root': { fontSize: '1.6rem', border: 'none' } }}
                >
                  <TableCell sx={{ pl: '2rem' }}>{row.parameter}</TableCell>
                  <TableCell sx={{ pl: 0 }}>{row.type}</TableCell>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack id="Sub 2-1-4" ref={sub214Ref}>
        <Typography sx={{ ...style.contentNoticeTitle, p: '1.2rem 2rem 1.6rem' }}>
          Body Data
        </Typography>

        <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: '#EEEEEE',

                  '& .MuiTableCell-root': {
                    fontSize: '1.6rem',
                    fontWeight: 600,
                    border: 'none',
                  },
                }}
              >
                <TableCell sx={{ pl: '2rem', pr: '6.5rem', width: '10%' }}>Parameter</TableCell>
                <TableCell sx={{ pl: 0, pr: '8rem', width: '10%' }}>Type</TableCell>
                <TableCell sx={{}}>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bodyTableBody.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    verticalAlign: 'baseline',
                    '& .MuiTableCell-root': { fontSize: '1.6rem', border: 'none' },
                  }}
                >
                  <TableCell sx={{ pl: '2rem' }}>{row.parameter}</TableCell>
                  <TableCell sx={{ pl: 0 }}>{row.type}</TableCell>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack sx={{ ...style.contentBox, gap: '1.6rem' }}>
        <Stack id="Sub 2-1-5" ref={sub215Ref} sx={{ gap: '0.4rem' }}>
          <Typography sx={{ ...style.contentNoticeTitle }}>Response</Typography>
          <Typography sx={{ ...style.contentNotice }}>
            요청에 대한 응답은 byte image를 반환합니다.
          </Typography>
        </Stack>

        <Stack id="Sub 2-1-6" ref={sub216Ref} sx={{ gap: '0.4rem' }}>
          <Typography sx={{ ...style.contentNoticeTitle }}>Failed Response</Typography>
          <Stack sx={{ ml: '0.5rem', gap: '0.4rem' }}>
            <Typography sx={{ ...style.contentNotice, textIndent: '-1rem', ml: '1rem' }}>
              • 유효하지않은 입력이 오는 경우, error code 500과 함께 유효하지않은 입력에 대한 설명을
              포함한 response가 반환됩니다.
            </Typography>
            <Typography sx={{ ...style.contentNotice, textIndent: '-1rem', ml: '1rem' }}>
              • 크레딧은 소모되지 않습니다.
            </Typography>
            <Typography sx={{ ...style.contentNotice, textIndent: '-1rem', ml: '1rem' }}>
              • 올바른 요청에 대해서도 지속적으로 유효한 응답이 되지않는 경우 문의주시면 해결토록
              하겠습니다.
            </Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{
            p: '2.8rem 4.8rem',
            mt: '2.4rem',
            backgroundColor: '#EEEEEE',
            borderRadius: '10px',
            gap: '0.8rem',
          }}
        >
          <Typography sx={{ ...style.contentNoticeTitle }}>
            일반 에러 케이스 (위 예제 기반 출력 결과)
          </Typography>

          {errorCase.map(e => {
            return (
              <React.Fragment key={e.title}>
                <Typography
                  sx={{ ...style.contentNotice, fontWeight: 500, lineHeight: '3rem', mt: '0.8rem' }}
                >
                  {e.title}
                </Typography>
                <SyntaxHighlighter
                  language="json"
                  style={dracula}
                  customStyle={{
                    borderRadius: '10px',
                    margin: 0,
                    fontSize: '1.2rem',
                    padding: '1.6rem 2rem',
                  }}
                >
                  {e.content}
                </SyntaxHighlighter>
              </React.Fragment>
            )
          })}
        </Stack>

        <Stack
          sx={{
            p: '2.8rem 4.8rem',
            mt: '0.8rem',
            backgroundColor: '#EEEEEE',
            borderRadius: '10px',
            gap: '0.8rem',
          }}
        >
          <Typography sx={{ ...style.contentNoticeTitle }}>gen_type별 에러 케이스</Typography>

          {genTypeErrorCase.map(e => {
            return (
              <React.Fragment key={e.title}>
                {' '}
                <Typography
                  sx={{ ...style.contentNotice, fontWeight: 500, lineHeight: '3rem', mt: '0.8rem' }}
                >
                  {e.title}
                </Typography>
                <Typography
                  sx={{ ...style.contentNotice, fontWeight: 500, lineHeight: '3rem', ml: '1.2rem' }}
                >
                  {e.notice}
                </Typography>
                <SyntaxHighlighter
                  language="json"
                  style={dracula}
                  customStyle={{
                    borderRadius: '10px',
                    margin: 0,
                    fontSize: '1.2rem',
                    padding: '1.6rem 2rem',
                  }}
                >
                  {e.content}
                </SyntaxHighlighter>
              </React.Fragment>
            )
          })}
        </Stack>
      </Stack>

      <Stack sx={{ ...style.contentBox, gap: '1.6rem' }}>
        <Typography id="Sample" ref={sampleRef} sx={{ ...style.contentTitle }}>
          3. Sample code
        </Typography>

        <Stack sx={{ gap: '0.4rem' }}>
          <Typography sx={{ ...style.contentNoticeTitle }}>사용 예</Typography>
          <Typography sx={{ ...style.contentNotice }}>• gen_type=”concept” case</Typography>

          <Stack
            sx={{
              width: 'inherit',
              mt: '2rem',
              // backgroundColor: '#F8F8F8',
              // p: '1rem 2.25rem 0rem 2.25rem',
              borderRadius: '10px',
            }}
          >
            <Tabs
              value={codelang}
              onChange={handleLangChange}
              aria-label="code tabs"
              sx={{ borderBottom: '1px solid #E6E9F4;' }}
            >
              <Tab label="Python " value="python" />
              <Tab label="Node.js" value="javascript" />
              {/* <Tab label="JAVA" value="java" /> */}
            </Tabs>
            <Box sx={{ py: '2rem', position: 'relative' }}>
              <SyntaxHighlighter
                language={codelang}
                style={dracula}
                customStyle={{
                  borderRadius: '10px',
                  margin: 0,
                  fontSize: '1.2rem',
                  padding: '1.6rem 2rem',
                }}
              >
                {langCodeSample[codelang]}
              </SyntaxHighlighter>
              <Box sx={{ position: 'absolute', right: '1rem', top: '3rem' }}>
                <Button
                  style={{
                    backgroundColor: '#EEEEEE',
                    color: '#303030',
                    fontSize: '1.2rem',
                    padding: '0.4rem 0.6rem 0.6rem 0.6rem',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    minWidth: 'auto',
                    fontWeight: 400,
                  }}
                  onClick={() => handleCopyClipBoard(langCodeSample[codelang])}
                >
                  Copy
                </Button>
              </Box>
            </Box>
          </Stack>

          {/* <Stack
            sx={{ position: 'relative' }}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            <SyntaxHighlighter language="python" style={dracula}>
              {sampleCode.python}
            </SyntaxHighlighter>
  
            <Box sx={{ position: 'absolute', right: '1rem', top: '22.3rem' }}>
              <Button
                style={{
                  backgroundColor: '#EEEEEE',
                  color: '#303030',
                  fontSize: '1.2rem',
                  padding: '0.6rem 0.6rem',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  minWidth: 'auto',
                  fontWeight: 400,
                }}
                onClick={() => handleCopyClipBoard(sampleCode[1])}
              >
                Copy
              </Button>
            </Box>
          </Stack> */}

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="code tabs"
            sx={{ borderBottom: '1px solid #E6E9F4;', mt: '0rem' }}
          >
            <Tab label="gen_type = “remove_bg” " value="remove" />
            <Tab label="gen_type = “color_bg” " value="colorBg" />
            <Tab label="gen_type = “simple” " value="simple" />
          </Tabs>
          <Box sx={{ py: '2rem', position: 'relative' }}>
            <SyntaxHighlighter
              language="python"
              style={dracula}
              customStyle={{
                borderRadius: '10px',
                margin: 0,
                fontSize: '1.2rem',
                padding: '1.6rem 2rem',
              }}
            >
              {genTypeCodeSample[value]}
            </SyntaxHighlighter>

            <Box sx={{ position: 'absolute', right: '1rem', top: '3rem' }}>
              <Button
                style={{
                  backgroundColor: '#EEEEEE',
                  color: '#303030',
                  fontSize: '1.2rem',
                  padding: '0.4rem 0.6rem 0.6rem 0.6rem',
                  cursor: 'pointer',
                  borderRadius: '4px',
                  minWidth: 'auto',
                  fontWeight: 400,
                }}
                onClick={() => handleCopyClipBoard(genTypeCodeSample[value])}
              >
                Copy
              </Button>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
