// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.5rem 1.5rem',
          borderRadius: '0.6rem',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: '6rem',
          fontStyle: 'normal',
          fontWeight: 800,
          fontSize: '2rem',
          lineHeight: '2.4rem',
        },
        contained: {
          borderRadius: '0.6rem',
          padding: '0.6rem 1.6rem',
          backgroundColor: theme.palette.common.black,
          // boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.draph.blue,
          },
        },
        // containedPrimary: {
        //   boxShadow: theme.customShadows.primary,
        // },
        // containedSecondary: {
        //   boxShadow: theme.customShadows.secondary,
        // },
        outlined: {
          borderRadius: '0.6rem',
          border: `1px solid ${theme.palette.common.black}`,
          color: theme.palette.common.black,
          '&.MuiButton-root.Mui-disabled': { color: theme.palette.text.disabled },
          '&:hover': {
            // backgroundColor: theme.palette.action.hover,
            // backgroundColor: '#F5F5F5',
            // color: theme.palette.draph.blue,
            // border: `2px solid ${theme.palette.draph.blue}`,

            // backgroundColor: theme.palette.draph.blue,
            color: theme.palette.draph.blue,
            border: `1px solid ${theme.palette.draph.blue}`,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  }
}
