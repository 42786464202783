import {
  SwipeableDrawer,
  Button,
  Typography,
  Drawer,
  Box,
  Avatar,
  Checkbox,
  Divider,
  Stack,
} from '@mui/material'
import { MHidden } from 'components/@material-extend'
import { useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { TempLayoutDiv } from './TempLayoutDiv'
import { AiFillEdit, AiOutlineRight } from 'react-icons/ai'
import { removeUserCookie } from 'utils/cookie'

import { tutorialAtom, userAtom } from 'atoms'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'

import { styled, useTheme } from '@mui/material/styles'
import { USER_GRADE_DICT } from 'config'
import { getS3ImageSrc } from 'utils/common'
import { SettingIcon, DotIcon, CloseIcon } from 'theme/icon'
import LanguageSelect from './LanguageSelect'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'

const ContentStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  width: '33.2rem',
  alignItems: 'center',

  // overflow: 'hidden',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

const nonTutorialDrawerStyle = {
  '& .MuiPaper-root': { width: { xs: '100%', sm: '100%', md: '100%', lg: 'unset' } },
}
const tutorialDrawerStyle = {
  '& .MuiPaper-root': { flexDirection: 'row' },
  '& .MuiDrawer-paper': {
    backgroundColor: 'transparent',
  },
}

export function UserInfoDrawer() {
  const [open, setOpen] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const user = useRecoilValue(userAtom)
  const resetUser = useResetRecoilState(userAtom)
  const navigate = useNavigate()
  const theme = useTheme()

  const toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
    setOpen(open)
  }

  const logoutUser = () => {
    removeUserCookie()
    resetUser()
    window.location.reload()
  }

  return (
    <>
      <Desktop>
        <CenterAlignStack direction="row" onClick={toggleDrawer(true)} sx={{ cursor: 'pointer' }}>
          <Box sx={{ textAlign: 'right', p: 1 }}>
            <Typography color="common.white" fontSize="1.2rem" fontWeight={600}>
              {user.username} 님
            </Typography>
            <Typography color="common.white" fontSize="1.2rem" fontWeight={400}>
              {USER_GRADE_DICT[user.grade]}
            </Typography>
          </Box>

          <Avatar
            sx={{
              width: '5.2rem',
              height: '5.2rem',
              border: ['premium', 'admin'].includes(user.grade)
                ? `0.3rem solid ${theme.palette.draph.green}`
                : '',
            }}
            src={getS3ImageSrc(user.photo)}
          />
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack direction="row" onClick={toggleDrawer(true)} sx={{ cursor: 'pointer' }}>
          <Avatar
            sx={{
              width: '3rem',
              height: '3rem',
              border: ['premium', 'admin'].includes(user.grade)
                ? `0.3rem solid ${theme.palette.draph.green}`
                : '',
            }}
            src={getS3ImageSrc(user.photo)}
          />
        </CenterAlignStack>
      </Mobile>

      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        hideBackdrop={tutorial.step === 18 && true}
        sx={tutorial.step === 18 ? tutorialDrawerStyle : nonTutorialDrawerStyle}
      >
        {tutorial.step === 18 && <Box sx={{ width: 'calc(100vw - 33.2rem)' }}></Box>}
        <ContentStyle
          sx={
            tutorial.step === 18
              ? {
                  background: '#fff',
                  boxShadow:
                    ' 0px 8px 10px -5px rgba(145, 158, 171, 0.2), 0px 16px 24px 2px rgba(145, 158, 171, 0.14), 0px 6px 30px 5px rgba(145, 158, 171, 0.12)',
                }
              : {}
          }
        >
          <Box
            sx={{
              pr: '2.2rem',
              pt: '2.2rem',
              width: '100%',
              justifyContent: 'end',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                mr: 1,
              }}
              onClick={toggleDrawer(false)}
            >
              <Desktop>
                <CloseIcon size="medium" />
              </Desktop>
              <Mobile>
                <CloseIcon />
              </Mobile>
            </Box>
          </Box>

          <CenterAlignStack sx={{ pt: 1 }}>
            <Avatar
              sx={{
                mb: 1,
                width: '7rem',
                height: '7rem',
                border: ['premium', 'admin'].includes(user.grade)
                  ? `0.3rem solid ${theme.palette.draph.green}`
                  : '',
              }}
              src={getS3ImageSrc(user.photo)}
            />

            <Typography fontSize="1.9rem" fontWeight={700} color="black">
              {user.username} 님
            </Typography>
            <Typography fontSize="1.6rem" fontWeight={500} color="#6d6d6d">
              {user.company}
            </Typography>

            <CenterAlignBox
              alignItems="center"
              sx={{ mt: '1.6rem', cursor: 'pointer' }}
              onClick={() => {
                setOpen(false)
                navigate('/user/profile')
              }}
            >
              <SettingIcon />
              <Typography
                fontWeight={500}
                color={theme.palette.common.black}
                sx={{
                  ml: '0.6rem',
                  fontSize: { lg: '1.5rem', md: '1.2rem', sm: '1.2rem', xs: '1.2rem' },
                }}
              >
                회원정보 수정
              </Typography>
            </CenterAlignBox>
          </CenterAlignStack>

          <CenterAlignStack
            sx={{ width: '100%', px: { lg: '6rem', md: '7.4rem', sm: '7.4rem', xs: '7.4rem' } }}
          >
            <Divider
              sx={{
                mt: { lg: '3.8rem', xs: '2.5rem' },
                mb: '2.4rem',
                width: '100%',
                borderBottomWidth: '0.1rem',
                borderColor: theme.palette.common.gray,
              }}
            />
            <Tutorial step={18} setOpen={setOpen}>
              <CenterAlignStack>
                <Typography
                  fontSize="1.7rem"
                  fontWeight={500}
                  color={theme.palette.common.black}
                  sx={{ mb: '0.9rem' }}
                >
                  ㅇㅇ요금제 이용 중
                </Typography>
                <Typography
                  fontSize="1.3rem"
                  fontWeight={400}
                  color={theme.palette.common.gray}
                  sx={{
                    mb: '0.3rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <DotIcon fill={theme.palette.common.gray} style={{ marginRight: '0.3rem' }} />{' '}
                  크레딧: &nbsp;
                  {user.credit}
                </Typography>
                <Typography
                  fontSize="1.3rem"
                  lineHeight={{ lg: '1.5', xs: '1' }}
                  fontWeight={400}
                  color={theme.palette.common.gray}
                  sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                >
                  <DotIcon fill={theme.palette.common.gray} style={{ marginRight: '0.3rem' }} />
                  이용기간: 0000.00.00 ~ 0000.00.00
                </Typography>
              </CenterAlignStack>
            </Tutorial>
            <Divider
              sx={{
                mt: '2.7rem',
                width: '100%',
                borderBottomWidth: '0.1rem',
                borderColor: theme.palette.common.gray,
              }}
            />
          </CenterAlignStack>

          <PricingButton
            variant="outlined"
            sx={{
              mt: '2.4rem',
              '& .MuiButton-endIcon path': {
                transition: 'fill 0.3s',
              },
            }}
            onClick={() => {
              setOpen(false)
              navigate('/pricing')
            }}
            endIcon={<AiOutlineRight style={{ height: '1.5rem', width: '1.5rem' }} />}
          >
            요금제 더보기
          </PricingButton>

          <CenterAlignStack
            spacing="1.7rem"
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              py: '3.5rem',
            }}
          >
            <TextLink to="/board" title="공지사항" />
            <TextLink to="/" title="드랩 가이드" />
            <TextLink to="/FAQ" title="FAQ" />
            <TextLink to="/" title="1:1 문의" />
          </CenterAlignStack>

          <CenterAlignStack spacing={1}>
            <Button
              variant="outlined"
              onClick={logoutUser}
              sx={{ width: '13rem', height: '3.6rem', border: 1, fontSize: '1.6rem' }}
            >
              로그아웃
            </Button>
          </CenterAlignStack>
        </ContentStyle>
      </Drawer>
    </>
  )
}

const PricingButton = styled(Button)(({ theme }) => ({
  // width: '10.1rem',
  height: '3.6rem',
  fontSize: '1.5rem',
  lineHeight: 'normal',
  fontWeight: 600,
  paddingTop: '1.4rem',
  paddingBottom: '1.4rem',
  paddingLeft: '1.9rem',
  paddingRight: '1.3rem',
  borderWidth: '1px',
  borderColor: theme.palette.common.black,
  borderRadius: '20px',

  '&:hover': {
    backgroundColor: 'unset',
    color: theme.palette.draph.blue,
  },
}))

const TextLinkButton = styled(Button)(({ theme }) => ({
  height: '2.8rem',
  fontSize: '1.3rem',
  fontWeight: 700,
  color: theme.palette.common.black,
  textDecoration: 'none !important',

  '&:hover': {
    backgroundColor: 'unset',
    fontWeight: 900,
  },
}))

function TextLink(props) {
  return (
    <RouterLink style={{ textDecoration: 'none' }} {...props}>
      <TextLinkButton sx={{ fontSize: { lg: '1.6rem', md: '1.6rem', sm: '1.6rem', xs: '1.6rem' } }}>
        {props.title}
      </TextLinkButton>
    </RouterLink>
  )
}
