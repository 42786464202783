import {
  SwipeableDrawer,
  Button,
  Typography,
  Drawer,
  Box,
  Avatar,
  Checkbox,
  Divider,
  Stack,
} from '@mui/material'
import { CenterAlignStack, CenterAlignBox } from './CenterAlignContainer'
import { iOS } from 'utils/common'

export default function MobileSwipeableDrawer({ sx, children, ...props }) {
  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        sx={{
          '& .MuiDrawer-paperAnchorBottom': {
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px',
          },
          ...sx,
        }}
        disableDiscovery={iOS}
        disableSwipeToOpen={true}
        {...props}
      >
        <CenterAlignStack>
          <CenterAlignBox
            sx={{
              width: '4rem',
              height: '0.4rem',
              backgroundColor: '#BCBCBC',
              borderRadius: '20px',
              mt: '1.2rem',
              mb: '1.2rem',
            }}
          />
        </CenterAlignStack>

        {children}
      </SwipeableDrawer>
    </>
  )
}
