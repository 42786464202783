import React, { useState, useRef, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'

export default function AnimatedHeightDialog({
  open,
  setOpen,
  paperStyle = {},
  sx = {},
  backdropClose = true,
  children,
}) {
  const [height, setHeight] = useState('auto')
  const contentRef = useRef(null)

  const onClose = (e, r) => {
    if (!backdropClose && r === 'backdropClick') return

    setOpen(false)
  }

  // useEffect(() => {
  //   if (open && contentRef.current) {
  //     // Dialog가 열렸을 때 content의 height를 측정하여 state에 저장
  //     setHeight(contentRef.current.scrollHeight / 10 + 'rem')
  //   }
  // }, [open, children])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          transition: 'height 0.3s ease',
          height: height,
          ...paperStyle,
        },
      }}
      sx={{ ...sx }}
    >
      <DialogContent ref={contentRef} sx={{ overflow: 'hidden', p: 0 }}>
        {children}
      </DialogContent>
    </Dialog>
  )
}
