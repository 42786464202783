import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import {
  Link,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Divider,
  SwipeableDrawer,
} from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import {
  CenterAlignBox,
  CenterAlignStack,
  FormTextField,
  GoogleLogin,
  KakaoLogin,
  NaverLogin,
  StyledFormTextField,
  FormErrorMessage,
  RegisterBox,
  RegisterLabel,
  TextInput,
  FormCheckbox,
  AgreeDialog,
} from 'components'
import { apis } from 'apis'
import { AiFillEye as OpenedEyeIcon, AiFillEyeInvisible as ClosedEyeIcon } from 'react-icons/ai'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useRecoilState } from 'recoil'
import { tutorialAtom, userAtom } from 'atoms'
import { styled, useTheme } from '@mui/material/styles'
import {
  AgreeCheckedSquareIcon,
  AgreeSquareIcon,
  CheckedSquareIcon,
  CheckedSquareIcon2,
  SearchIcon,
  SquareIcon,
  SquareIcon2,
} from 'theme/icon'
import { motion } from 'framer-motion'
import { RiThermometerFill } from 'react-icons/ri'
import { MHidden } from 'components/@material-extend'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useSnackbar } from 'notistack'
import useConfirm from 'hooks/useConfirm'
import moment from 'moment'
import { iOS } from 'utils/common'
import { promotionInfo, promotionInfoKeys } from './promotionEvents/DomeggookEvent'
import { useNavigateForPromotion } from 'hooks/useNavigateWithQuery'
import { useTranslation } from 'react-i18next'
import { LinkSnsAccountDialog } from '../components/SocialLogin'
import { linkSnsAccountDialogAtom } from '../atoms'
import { Korean } from 'hooks/useLanguage'

const StyledCheckbox = styled(FormControlLabel)(({ theme }) => ({
  paddingLeft: '3px',

  '& .MuiCheckbox-root': {
    color: 'blue',
  },

  '& .MuiTypography-root': {
    fontSize: '12px',
    fontWeight: 400,
    // color: theme.palette.common.black,
    color: 'black',
  },
}))

const buttonStyle = {
  width: '384px',
}

export const buttonMobileStyle = {
  width: '320px',
  height: '48px',
  mt: '23px',
  fontSize: '18px',
  fontWeight: '800',
}

const TextButtonStyle = {
  fontSize: '12px',
  fontWeight: 400,
  color: 'black',
  cursor: 'pointer',
}

export function CommerceLogin({ titleAreaComponent, onSubmit }) {
  const initUsername = localStorage.getItem('rememberMe')
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const loginSchema = yup.object().shape({
    username: yup.string().required(t('register.id_error_1')),
    password: yup
      .string()
      .min(2, t('register.password_error_4'))
      .required(t('register.password_error_4')),
  })

  const [open, setOpen] = useState(false)
  const [openMergeDialog, setOpenMergeDialog] = useState(false)
  const [socialLogin, setSocialLogin] = useState({
    open: false,
    socialName: null,
    data: {},
    agreeInfo: null,
  })

  useEffect(() => {
    // 사용자의 지역 정보 가져오기
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     position => {
    //       console.log('User Location:', position.coords.latitude, position.coords.longitude)
    //       // 여기에서 얻은 위치 정보를 서버로 보내거나 활용할 수 있습니다.
    //     },
    //     error => {
    //       console.error('Error getting location:', error.message)
    //     }
    //   )
    // } else {
    //   console.error('Geolocation is not supported by this browser.')
    // }
    // console.log('User Region:', userRegion)
    // 여기에서 가져온 지역 정보를 활용하여 원하는 작업을 수행합니다.

    // ----- GA4 event -----
    window.gtag('event', 'login_begin', {})
    // ---------------------
  }, [])

  const [loading, setLoading] = useState(false)
  const [remember, setRemember] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const [user, setUser] = useRecoilState(userAtom)
  const promotionId = '4'

  const location = useLocation()
  const { navigateEvent, navigateWithQuery } = useNavigateForPromotion()

  useEffect(() => {
    if (localStorage.getItem('promotionQuery')) return

    for (const key of promotionInfoKeys) {
      if (promotionInfo[key].query === location.search) {
        localStorage.setItem('promotionQuery', promotionInfo[key].query)
        break
      }
    }
  }, [])

  const {
    formState: { isDirty, dirtyFields, errors },
    control,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit', // validation will trigger on submit
    defaultValues: {
      username: initUsername ?? '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  })

  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (initUsername) {
      setRemember(true)
    }
  }, [])

  const onSubmitDefault = values => {
    setLoading(true)
    apis.user
      .login(values)
      .then(response => {
        const d = response.data

        if (remember) {
          localStorage.setItem('rememberMe', values.username)
        } else {
          localStorage.removeItem('rememberMe')
        }
        const accessToken = d.access_token
        const refreshToken = d.refresh_token
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)

        // ----- GA4 event -----
        window.gtag('event', 'login', {
          method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
        })
        // ---------------------

        navigateEvent({ replace: true }, '/generate/upload')
      })
      .catch(e => {
        console.log(e)
        if (e.response.status === 401) {
          alert(t('login.login_error_1'))
        } else {
          alert(t('common.error'))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <motion.div
        initial={{ x: 10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Desktop>
          <CenterAlignStack sx={{ pt: '80px' }}>
            {/* 타이틀 영역 */}
            {titleAreaComponent}

            <form onSubmit={onSubmit ? handleSubmit(onSubmit) : handleSubmit(onSubmitDefault)}>
              <Stack sx={{ pt: '32.5px', pb: '46.5px' }}>
                <StyledFormTextField
                  placeholder={t('login.id')}
                  name="username"
                  autoComplete="username"
                  control={control}
                  errors={errors}
                  sx={{
                    marginTop: '10px !important',
                    '& .MuiInputBase-root': {
                      width: '384px !important',
                      height: '50px !important',
                      padding: '16px !important',
                      fontSize: '16px !important',
                    },
                  }}
                />

                {errors.username?.message.length > 0 && (
                  <FormErrorMessage message={errors.username?.message} />
                )}

                <StyledFormTextField
                  placeholder={t('login.password')}
                  name="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  control={control}
                  errors={errors}
                  englishMode={true}
                  sx={{
                    '& .MuiInputBase-root': {
                      width: '384px !important',
                      height: '50px !important',
                      padding: '16px !important',
                      fontSize: '16px !important',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowPassword(prev => !prev)}>
                          {showPassword ? (
                            <OpenedEyeIcon style={{ width: '16px', height: '16px' }} />
                          ) : (
                            <ClosedEyeIcon style={{ width: '16px', height: '16px' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.password?.message.length > 0 && (
                  <FormErrorMessage message={errors.password?.message} />
                )}

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%', mb: '10px' }}
                ></Stack>

                <LoadingButton
                  size="large"
                  type="submit"
                  // onClick={handleClick}
                  // endIcon={<SendIcon />}
                  loading={loading}
                  // loadingPosition="end"
                  variant="contained"
                  sx={{
                    width: '384px',
                    height: '60px',
                    fontWeight: 800,
                    fontSize: '20px',
                    lineHeight: '24px',
                  }}
                >
                  {t('button.login')}
                </LoadingButton>
              </Stack>
            </form>
          </CenterAlignStack>
        </Desktop>

        <Mobile>
          <CenterAlignStack sx={{ pt: '70px' }}>
            {/* 타이틀 영역 */}
            {titleAreaComponent}

            <form onSubmit={onSubmit ? handleSubmit(onSubmit) : handleSubmit(onSubmitDefault)}>
              <Stack sx={{ py: '20px' }}>
                <StyledFormTextField
                  placeholder={t('login.id')}
                  name="username"
                  autoComplete="username"
                  control={control}
                  errors={errors}
                />

                {errors.username?.message.length > 0 && (
                  <FormErrorMessage message={errors.username?.message} />
                )}

                <StyledFormTextField
                  placeholder={t('login.password')}
                  name="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  control={control}
                  errors={errors}
                  englishMode={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ '& .MuiButtonBase-root': { mr: '0 !important' } }}
                      >
                        <IconButton edge="end" onClick={() => setShowPassword(prev => !prev)}>
                          {showPassword ? <OpenedEyeIcon /> : <ClosedEyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {errors.password?.message.length > 0 && (
                  <FormErrorMessage message={errors.password?.message} />
                )}

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%', mb: '10px' }}
                ></Stack>

                <LoadingButton
                  size="large"
                  type="submit"
                  // onClick={handleClick}
                  // endIcon={<SendIcon />}
                  loading={loading}
                  // loadingPosition="end"
                  variant="contained"
                  sx={buttonMobileStyle}
                >
                  {t('button.login')}
                </LoadingButton>
              </Stack>
            </form>

            <Korean>
              <img
                src={'/static/images/event/short_event_banner_mo.png'}
                style={{ width: '32rem', cursor: 'pointer', margin: '4rem 0' }}
                onClick={() => {
                  navigate('/event/ko/normal/sns_review_event')
                }}
              />
            </Korean>
          </CenterAlignStack>
        </Mobile>
      </motion.div>

      <LinkSnsAccountDialog open={openMergeDialog} setOpen={setOpenMergeDialog} />
    </>
  )
}

function DividerIcon(props) {
  return (
    <svg
      width="1"
      height="8"
      viewBox="0 0 1 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line x1="0.25" y1="1.09278e-08" x2="0.25" y2="8" stroke="#808080" strokeWidth="0.5" />
    </svg>
  )
}
