import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isKo } from 'utils/common'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'

export default function ResetDialog({ open, setOpen, subTab, setSubTab }) {
  const { i18n, t } = useTranslation()
  return (
    <>
      <CenterAlignStack
        sx={{
          position: 'absolute',
          width: '40rem',
          height: '19rem',
          borderRadius: '2rem',
          boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.10)',
          background: '#fff',
          zIndex: 1000,
          top: 'calc(50% - 9.5rem)',
          left: 'calc(50% - 20rem)',
        }}
      >
        <CenterAlignBox
          sx={{ flex: 1, alignItems: 'center', fontSize: '1.8rem', fontWeight: 700, mx: '2rem' }}
        >
          {t('regenerate_dialog.mode_change_warning_1')} <br />
          {t('regenerate_dialog.mode_change_warning_2')}
        </CenterAlignBox>
        <CenterAlignBox sx={{ width: '100%' }}>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false)
            }}
            sx={{
              width: '50%',
              height: '4.8rem',
              backgroundColor: '#F8F8F8',
              borderRadius: 0,
              borderBottomLeftRadius: '2rem',
              color: theme => theme.palette.common.black,
              '&:hover': {
                backgroundColor: '#F8F8F8',
                color: theme => theme.palette.common.black,
              },
            }}
          >
            {t('button.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setSubTab(1)
              setOpen(false)
            }}
            sx={{
              ml: '0 !important',
              height: '4.8rem',
              borderRadius: 0,
              borderBottomRightRadius: '2rem',
              width: '50%',
              color: theme => theme.palette.common.white,
              '&:hover': {
                color: theme => theme.palette.common.white,
              },
            }}
          >
            {isKo(i18n) ? '확인' : 'OK'}
          </Button>
        </CenterAlignBox>
      </CenterAlignStack>
    </>
  )
}
