import { configData, DEFAULT_PORTFOLIO_TYPES } from 'config'
import { Box, Select, MenuItem, Typography } from '@mui/material'
import { SearchInput } from 'components'
import React, { useEffect, useState } from 'react'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { SelectSideIconThin } from 'theme/icon'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import { useRecoilState, useResetRecoilState, useRecoilValue } from 'recoil'
import { apis } from 'apis'
import {
  portfolioDetailAtom,
  portfolioArtworkAtom,
  userAtom,
  portfolioConfigAtom,
  artworkViewConfigAtom,
  userLikedBgAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  tutorialAtom,
  endpointAtom,
  artworkListsIsFoldedAtom,
  ZeroCreditWarningDialogAtom,
  uploadDialogOpenAtom,
  unusualPortfolioConfigUpdateAtom,
  brandConfigUpdateAtom,
  autoUploadAtom,
  uploadFilesAndUrlAtom,
} from 'atoms'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
} from 'selector'
import useConfirm from 'hooks/useConfirm'
import useCreditWarningDialog from 'hooks/useCreditWarningDialog'
import * as config from 'config'
import { getAccessToken } from 'utils/cookie'
import axios from 'axios'
import { resizeImage } from 'utils/common'
import { useTranslation } from 'react-i18next'
import { currentMenuAtom, portfolioTypeAtom } from '../../atoms'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'

import { useCheckImage } from 'hooks/useRefineUploadFiles'
import { useMenu } from 'hooks/useMenu'

const selectBoxStyle = {
  width: { lg: '12.4rem', xs: '7.6rem' },
  height: { lg: '3.6rem', xs: '2.8rem' },
}
export function ArtworkFilters({
  selectValue,
  setSelectValue,
  textValue,
  setTextValue,
  sx,
  disabled = false,
  ...props
}) {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const menuItemStyle = {
    fontSize: { lg: '1.5rem', xs: '1.2rem' },
    pl: { lg: '2.3rem' },
  }

  const { showPleaseLoginDialog } = usePleaseLoginDialog()

  const { getCurrentMenu } = useMenu()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { lg: 'flex-end', md: 'center', sm: 'center', xs: 'center' },
          alignItems: 'end',
          width: '35rem',
          pr: '2.2rem',
          position: 'relative',
          ...sx,
        }}
      >
        <Select
          value={selectValue}
          onChange={
            disabled
              ? () => {
                  showPleaseLoginDialog()
                }
              : e => {
                  setSelectValue(e.target.value)

                  // ----- GA4 event -----
                  window.gtag('event', 'artwork_view', {
                    menu: getCurrentMenu()?.id,
                    method: e.target.value,
                  })
                  // ---------------------
                }
          }
          IconComponent={isMobile ? () => <SelectSideIconThin size="small" /> : undefined}
          sx={{
            mr: '3.6rem',
            ...selectBoxStyle,
            '& .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
              width: { lg: 'unset', xs: '5rem' },
              pl: { lg: '2.4rem', xs: '1rem' },
              pr: { lg: '3.2rem', xs: 0 },
              pt: { lg: '1.6rem', xs: '0.7rem' },
              pb: { lg: '1.6rem', xs: '0.7rem' },
            },

            fontSize: { lg: '1.5rem', xs: '1.2rem' },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: { lg: '0.2rem', xs: '0.1rem' },
              p: { xs: 0 },
            },
            ...(isMobile && { position: 'absolute', top: '3.6rem', left: '2rem' }),
          }}
        >
          <MenuItem value="all" sx={menuItemStyle}>
            {t('artwork_list.show_all')}
          </MenuItem>
          <MenuItem value="downloaded" sx={menuItemStyle}>
            {t('artwork_list.download_true')}
          </MenuItem>
          <MenuItem value="notDownloaded" sx={menuItemStyle}>
            {t('artwork_list.download_false')}
          </MenuItem>
        </Select>

        <SearchInput
          className="artwork-filter-component"
          width="16.4rem"
          value={textValue}
          onChange={e => setTextValue(e.target.value)}
          onFocus={() => {
            // ----- GA4 event -----
            window.gtag('event', 'artwork_search', {
              menu: getCurrentMenu()?.id,
            })
            // ---------------------
          }}
        />
      </Box>
    </>
  )
}

const uploadImglimit = 20

export async function processInGroups(promises) {
  // console.log('promises', promises)
  for (let i = 0; i < promises.length; i += 4) {
    const group = promises.slice(i, i + 4)
    // console.log('group', group)

    await Promise.allSettled(group.map(func => func()))
    sleep(100)
  }
}

export function sleep(ms) {
  const start = new Date().getTime()
  const expire = start + ms
  while (new Date().getTime() < expire) {
    continue
  }
}

const uploadMaxSize = 3000

export function UploadWrapper({ children }) {
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [artworkList, setArtworkList] = useRecoilState(portfolioArtworkAtom)
  const [removeBgImageFile, setRemoveBgImageFile] = useRecoilState(autoUploadAtom)
  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)

  const checkImage = useCheckImage()

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION
  const isUnusualPage = portfolioType === config.PORTFOLIO_TYPE_BANNER

  useEffect(() => {
    if (!isUnusualPage && portfolioDetail.config) {
      // 업데이트로 인해 기존에 있던 설정이 제거된 경우, 기존 포트폴리오에 저장되어있던 설정 부분을 초기화 해주어야 함
      // 예) AI 배경 확장 기능 별도 메뉴로 빠지면서 기존 포트폴리오에서 AI 배경(캔버스)확장이 체크되어있는 채로 저장되었던 경우
      //    설정 UI가 사라져서 수정이 불가하므로 여기서 수동으로 세팅해줘야 함
      // 한번이라도 설정을 수정하여 저장하거나, (미분류의 경우)새로운 설정으로 업로드 하면 최신 설정으로 반영되므로 괜찮다
      const config = portfolioDetail.config

      const updateConfig = {}
      if (!isBgExpansionPage && config.flagBgExpansion) {
        // AI 배경확장 메뉴 아닌데 설정이 켜져있었던 경우..
        updateConfig.flagBgExpansion = false
        updateConfig.bgComplex = true
        updateConfig.bgGenerate = true
        updateConfig.bgSimple = false
        updateConfig.bgWhite = false
      }

      if (config.bgGenerate || config.bgComplex) {
        updateConfig.bgSimple = false
      }
      setPortfolioConfig({ ...portfolioDetail.config, ...updateConfig })
    } else {
      setPortfolioConfig({
        ...portfolioConfig,
        name: portfolioDetail.name,
        theme: portfolioDetail.theme,
      })
    }
  }, [portfolioDetail])

  useEffect(() => {
    if (removeBgImageFile.auto && artworkList) {
      checkImage([removeBgImageFile.file], files, setFiles, false, false, true)
    }
  }, [artworkList])

  //   const [removeBgImageFile, setRemoveBgImageFile] = useRecoilState(autoUploadAtom)
  // const artworkList = useRecoilValue(portfolioArtworkAtom)
  // const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  // useEffect(() => {
  //   if (removeBgImageFile.auto && artworkList && !files.length) {
  //     checkImage([removeBgImageFile.file], files, setFiles, false, false, true)
  //   }
  // }, [removeBgImageFile, artworkList])

  const renderChildren = () =>
    React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        artworkList,
      })
    })

  return renderChildren()
}

export const triggerGA4UploadEvent = (uploadConfig, user, count, eventName = 'upload') => {
  const bg = []
  if (uploadConfig.flag_white_cmp) bg.push('white')
  if (uploadConfig.flag_simple_cmp) bg.push('simple')
  if (uploadConfig.flag_complex_cmp) bg.push('complex')
  if (uploadConfig.flag_generate) bg.push('generate')

  const uploadType = uploadConfig?.has_ref_image
    ? 'reference'
    : !uploadConfig.flag_complex_cmp && !uploadConfig.flag_generate
    ? 'simple'
    : 'concept'

  window.gtag('event', eventName, {
    // event_category: config.GA4_EVENT_CATEGORY.portfolio,
    // user_id: user.id,
    count,

    config_gen_shadow: uploadType === 'simple' ? uploadConfig.gen_shadow?.toString() : 'auto',
    config_gen_face: uploadConfig.gen_face?.toString(),
    config_object_category: uploadConfig.object_category,
    config_object_sub_category: uploadConfig.object_sub_category,

    output_size_width: uploadConfig.output_size_w,
    output_size_height: uploadConfig.output_size_h,
    image_type: uploadType,

    theme:
      uploadType === 'reference'
        ? 'reference'
        : uploadType === 'simple'
        ? null
        : uploadConfig.theme_custom || uploadConfig.theme_template,
    removebg: uploadConfig.flag_white_cmp,
    multi_blob: uploadConfig.flag_multiblob_sod,
  })
}

export const triggerGA4UploadEventManualConfig = (config, eventName = 'multipack_photo_upload') => {
  window.gtag('event', eventName, config)
}

export const getGA4EventNameByPortfolioType = (portfolioType, eventType) => {
  let ev = ''
  if (portfolioType === config.PORTFOLIO_TYPE_REMOVEBG) {
    ev = 'removeg'
  } else if (portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN) {
    ev = 'mannequin'
  } else if (portfolioType === config.PORTFOLIO_TYPE_FAME) {
    ev = 'famous_person'
  } else if (portfolioType === config.PORTFOLIO_TYPE_BUNDLE) {
    ev = 'multipack_photo'
  } else if (portfolioType === config.PORTFOLIO_TYPE_MODELBG) {
    ev = 'modelbg'
  } else if (portfolioType === config.PORTFOLIO_TYPE_FACE) {
    ev = 'model_face'
  } else {
    ev = portfolioType
  }
  return `${ev}_${eventType}`
}

export const triggerGA4DownloadEvent = ({
  artworkConfig,
  artwork,
  user,
  count,
  eventName = 'download',
  params = null,
  method = null,
}) => {
  if (params) {
    window.gtag('event', eventName, { ...params, method })
  } else if (artworkConfig) {
    const bg = []
    if (artworkConfig.flag_white_cmp) bg.push('white')
    if (artworkConfig.flag_simple_cmp) bg.push('simple')
    if (artworkConfig.flag_complex_cmp) bg.push('complex')
    if (artworkConfig.flag_generate) bg.push('generate')

    const isSimpleConfig =
      !artworkConfig.flag_complex_cmp && !artworkConfig.flag_generate ? 'simple' : 'concept'

    window.gtag('event', eventName, {
      method,
      count,

      config_gen_shadow:
        isSimpleConfig === 'simple' ? artworkConfig.gen_shadow?.toString() : 'auto',
      config_gen_face: artworkConfig.gen_face?.toString(),
      config_object_category: artworkConfig.object_category,
      config_object_sub_category: artworkConfig.object_sub_category,

      output_size_width: artworkConfig.output_size_w,
      output_size_height: artworkConfig.output_size_h,
      image_type: isSimpleConfig,

      theme:
        isSimpleConfig === 'simple'
          ? null
          : artworkConfig.theme_custom || artworkConfig.theme_template,
      removebg: artworkConfig.flag_white_cmp,
      multi_blob: artworkConfig.flag_multiblob_sod,
    })
  }
}
