import {
  getAccessToken,
  getGuestAccess,
  getGuestSession,
  removeGuestCookie,
  setGuestAccess,
  setGuestSession,
} from 'utils/cookie'
import { apis } from 'apis'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  userStatusAtom,
  userAtom,
  creditPolicyAtom,
  userCreditAtom,
  userSubscriptionId,
  tutorialAtom,
  defaultPortfolioAtom,
  portfolioTypeAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  portfolioDetailAtom,
  portfolioConfigAtom,
  unusualPortfolioConfigUpdateAtom,
  userSubInfoAtom,
  brandReadOnlyConfigAtom,
  brandConfigUpdateAtom,
  portfolioAtom,
  guestUserAtom,
  guestPortfolioAtom,
} from 'atoms'
import { Navigate, useRoutes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useRef, useState } from 'react'

import * as layouts from 'layouts'
import * as config from 'config'
import * as pages from 'pages'
import { useTranslation } from 'react-i18next'
import { specifiedPortfolioDefaultConfigSelector } from 'selector'
import { BANNER_CONFIG_DEFAULT, BRAND_CONFIG_DEFAULT } from 'config'
import { getDefaultConfigByType, getS3ImageSrc, imageUrlToFile, isKo } from 'utils/common'
import { SUB_EVENT_ID } from 'pages/Pricing'

const promotionPages = {
  openevent: { open: true, element: <pages.OpenEvent /> },
  domeggook_event: { open: true, element: <pages.DomeggookEvent /> },
  oddoffice_event: { open: true, element: <pages.OddofficeEvent /> },

  // 헤더 없는 별도의 레이아웃 사용하므로 여기서는 주석
  // shoplinker_event: { open: true, element: <pages.ShoplinkerEvent /> },

  // coffeeEvent: { open: false, element: <pages.coffeeEvent /> },
  default: 'openevent',
}

export function Router() {
  const { i18n } = useTranslation()
  const userToken = getAccessToken()

  return useRoutes([
    {
      // 항상 맨 위에 두어야 / 가 home 으로 리다이렉트 됩니당
      path: '/',
      element: <layouts.MainLayout />,
      children: [
        { path: '/', element: <pages.Home /> },
        { path: '*', element: <Navigate to="/" /> },
      ],
    },
    {
      path: '/social',
      children: [
        { path: 'terms', element: <pages.Terms /> },
        { path: 'privacy', element: <pages.Privacy /> },
        { path: 'privacy/:version', element: <pages.Privacy /> },
        { path: 'agreePrivacy', element: <pages.AgreePrivacy /> },
        { path: 'marketing', element: <pages.Marketing /> },
        { path: 'advertisement', element: <pages.Advertisement /> },
      ],
    },
    {
      path: '/promotion',
      element: (
        <layouts.MainLayout paperColor="black" hideLineBanner appBarProps={{ bgColor: 'black' }} />
      ),
      children: [{ path: 'aithumbnail_ebook', element: <pages.LandingEbook /> }],
    },
    {
      path: '/overview/:featureId',
      element: (
        <layouts.MainLayout paperColor="black" hideLineBanner appBarProps={{ bgColor: 'black' }} />
      ),
      children: [{ path: '', element: <pages.LandingOverview /> }],
    },
    {
      path: '/test',
      element: <layouts.TestLayout />,
      children: process.env.NODE_ENV === 'development' && [
        // { path: '/test/', element: <Navigate to="/test/1" /> },
        // { path: '1', element: <pages.TestPage /> },
        // { path: '2', element: <pages.TestPage2 /> },
        // { path: '3', element: <pages.TestPage3 /> },
        // { path: '4', element: <pages.TestPage4 /> },
        { path: '5', element: <pages.TestPage5 /> },
        { path: '6', element: <pages.TestPage6 /> },
        { path: '7', element: <pages.TestPage7 /> },
        { path: '8', element: <pages.TestPage8 /> },
        { path: '9', element: <pages.TestPage9 /> },
        { path: '10', element: <pages.TestPage10 /> },
        { path: '11', element: <pages.TestPage11 /> },
        { path: '12', element: <pages.TestPage12 /> },
        { path: '13', element: <pages.TestPage13 /> },
        { path: '15', element: <pages.TestPage15 /> },
        { path: '16', element: <pages.TestPage16 /> },
        { path: '17', element: <pages.TestPage17 /> },
        // { path: 'fragment', element: <pages.TestPage14 /> },

        { path: 'ga0e', element: <pages.TestPageee /> },
      ],
    },

    {
      path: '/',
      element: (
        // <CheckLogin>
        <layouts.MainLayout />
        // </CheckLogin>
      ),
      children: [
        { path: 'login', element: <pages.Login /> },
        { path: 'findID', element: <pages.FindID /> },
        { path: 'findPW', element: <pages.FindPW /> },
        { path: 'resetPW', element: <pages.ResetPW /> },
        { path: 'register', element: <pages.Register /> },
        { path: 'join', element: <pages.Join /> },
        { path: 'api_info', element: <pages.APIInfo /> },
        { path: 'api_main', element: <pages.APIMain /> },
        { path: 'api', element: <pages.APIDoc /> },
        { path: 'blog/:pathnameLang', element: <pages.Blog /> },
        { path: 'blog/:pathnameLang/:category/:blogId', element: <pages.BlogPost /> },
        { path: 'blog/*', element: <Navigate to={`/blog/${i18n.language}`} /> },

        { path: 'event/:pathnameLang', element: <pages.Event /> },
        { path: 'event/:pathnameLang/:category/:eventId', element: <pages.EventPost /> },
        { path: 'event/*', element: <Navigate to={`/event/${i18n.language}`} /> },
        // { path: 'blog/:pathnameLang', element: <pages.Blog /> },
        // { path: 'blog/:pathnameLang/:category/:blogId', element: <pages.BlogPost /> },
      ],
    },
    { path: '/ncommerce_redirect', element: <pages.NaverCommerceRedirect /> },
    { path: '/ncommerce_register', element: <pages.NaverCommerceRegister /> },

    { path: '/nhncommerce', element: <pages.NHNCommerce /> }, // nhn 커머스 쇼핑몰 어드민에서 드랩아트 앱 실행시 진입점

    { path: '/cafe24_redirect', element: <pages.Cafe24 /> }, // cafe24 에서 드랩아트 앱 실행시 진입점
    { path: '/cafe24', element: <pages.Cafe24Commerce /> }, //
    {
      path: '/',
      element: (
        <SetData>
          <layouts.InfoLayout />
        </SetData>
      ),
      children: [
        { path: 'board', element: <pages.Board /> },
        { path: 'guide', element: <pages.Guide /> },
        { path: 'FAQ', element: <pages.FAQ /> },
        { path: 'inquiry', element: <pages.Inquiry /> },
      ],
    }, // 공지사항, 자주 묻는 질문 레이아웃 새롭게 뚞딱 나중에 카테고리에 잘 넣어야할 것 같습니다.
    {
      path: '/user/',
      element: (
        <RequireAuth>
          <SetUser>
            <layouts.MypageLayout />
          </SetUser>
        </RequireAuth>
      ),
      children: [
        { path: 'mypage', element: <pages.Mypage /> },
        { path: 'profile', element: <pages.UserProfile /> },
        { path: 'creditInfo', element: <pages.UserCreditInfo /> },
        { path: 'background', element: <pages.Background /> },
        { path: 'coupon', element: <pages.Coupon /> },
        { path: '', element: <Navigate to="/user/mypage" /> },
      ],
    },
    {
      path: '/',
      element: (
        <CheckLogin>
          <SetUser>
            <layouts.MainLayout />
          </SetUser>
        </CheckLogin>
      ),
      children: [
        { path: 'home', element: <pages.Home /> },
        { path: 'redirect', element: <pages.Redirect /> },
        { path: 'portfolio', element: <pages.Portfolio /> },
        {
          path: 'portfolio/:portfolioId',
          element: (
            <SetPortfolio>
              <pages.PortfolioDetail />
            </SetPortfolio>
          ),
        },
        {
          path: 'ads/banner',
          element: (
            <SetSpecifiedPortfolio type={config.PORTFOLIO_TYPE_BANNER}>
              <pages.BannerUpload />
            </SetSpecifiedPortfolio>
          ),
        },
        {
          path: 'generate/upload',
          element: (
            <SetDefaultPortfolio>
              <pages.PortfolioUpload />
            </SetDefaultPortfolio>
          ),
        },
        {
          path: 'generate/removebg',
          element: userToken ? (
            <SetDefaultPortfolio type={config.PORTFOLIO_TYPE_REMOVEBG}>
              <pages.RemoveBg />
            </SetDefaultPortfolio>
          ) : (
            // 비회원용 무료 누끼따기 페이지
            <SetGuestSession>
              <SetGuestPortfolio type={config.PORTFOLIO_TYPE_REMOVEBG}>
                <pages.GuestRemoveBg />
              </SetGuestPortfolio>
            </SetGuestSession>
          ),
        },
        {
          path: 'generate/canvas',
          element: (
            <SetDefaultPortfolio type={config.PORTFOLIO_TYPE_BGEXPANSION}>
              <pages.BgExpansion />
            </SetDefaultPortfolio>
          ),
        },
        {
          path: 'model/mannequin',
          element: (
            <SetDefaultPortfolio type={config.PORTFOLIO_TYPE_MANNEQUIN}>
              <pages.Mannequin />
            </SetDefaultPortfolio>
          ),
        },
        {
          path: 'model/modelBg',
          element: (
            <SetDefaultPortfolio type={config.PORTFOLIO_TYPE_MODELBG}>
              <pages.ModelBg />
            </SetDefaultPortfolio>
          ),
        },
        {
          path: 'model/face',
          element: (
            <SetDefaultPortfolio type={config.PORTFOLIO_TYPE_FACE}>
              <pages.ModelFace />
            </SetDefaultPortfolio>
          ),
        },
        {
          path: 'generate/bundle',
          element: (
            <SetDefaultPortfolio type={config.PORTFOLIO_TYPE_BUNDLE}>
              <pages.Bundle />
            </SetDefaultPortfolio>
          ),
        },
        // {
        //   path: 'fun/fame',
        //   element: (
        //     <SetDefaultPortfolio type={config.PORTFOLIO_TYPE_FAME}>
        //       <pages.Fame />
        //     </SetDefaultPortfolio>
        //   ),
        // },
        { path: 'portfolio/new', element: <pages.NewPortfolio /> },
        // { path: 'background', element: <pages.Background /> },
        { path: 'regenerate', element: <pages.Regenerate /> },
        { path: 'paid', element: <pages.Afterpayment /> },
      ],
    },
    {
      path: '/promotion/shoplinker_event',
      element: (
        <SetData>
          <pages.ShoplinkerEvent />
        </SetData>
      ),
    },
    {
      path: '/',
      element: (
        <SetUser>
          <layouts.MainLayout />
        </SetUser>
      ),
      children: [
        {
          path: 'pricing',
          element: <pages.Pricing />,
        },
        { path: 'promotion', element: <pages.Promotion promotionPages={promotionPages} /> },
        { path: 'promotion/:page', element: <pages.Promotion promotionPages={promotionPages} /> },
      ],
    },
    {
      path: '/admin',
      element: (
        <RequireAuth>
          <pages.AdminOnlyWrapper>
            <layouts.AdminLayout />
          </pages.AdminOnlyWrapper>
        </RequireAuth>
      ),
      children: [
        { path: '', element: <Navigate to="/admin/promotion" /> },
        { path: 'promotion', element: <pages.AdminPromotion /> },
        { path: 'coupon', element: <pages.AdminCoupon /> },
        { path: 'credit', element: <pages.AdminCredit /> },
        { path: 'credit_history', element: <pages.AdminCreditHistory /> },
        { path: 'subscription', element: <pages.AdminSubscription /> },
        { path: 'user', element: <pages.AdminUser /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" /> },
  ])
}

function RequireAuth({ children }) {
  // 로그인이 꼭 필요한 메뉴인 경우 (마이페이지 등)
  const token = getAccessToken()
  const location = useLocation()

  if (!token) {
    if (location.pathname === '/home') return children
    else return <Navigate to="/login" replace />
  }

  return children
}

function CheckLogin({ children }) {
  // 로그인 여부에 따라 다른 페이지를 렌더링해주려는 경우
  const token = getAccessToken()

  if (!token) {
    return children
  }

  return <SetData>{children}</SetData>
}

function SetData({ children }) {
  const token = getAccessToken()
  const [user, setUser] = useRecoilState(userAtom)
  const [creditPolicy, setCreditPolicy] = useRecoilState(creditPolicyAtom)

  useEffect(() => {
    if (token) {
      apis.user.getUser().then(response => {
        setUser(response.data)
      })
      apis.user.getCreditPolicy().then(response => {
        setCreditPolicy(response.data)
      })
    }
  }, [token])

  return children
}

function SetUser({ children }) {
  // 유저 정보를 세팅하기 전에는 children을 렌더링하지 않음
  const token = getAccessToken()
  const [user, setUser] = useRecoilState(userAtom)
  const [userSubInfo, setUserSubInfo] = useRecoilState(userSubInfoAtom)
  const [creditPolicy, setCreditPolicy] = useRecoilState(creditPolicyAtom)
  const [userPortfolio, setUserPortfolio] = useRecoilState(portfolioAtom)

  const resetUserSubInfo = useResetRecoilState(userSubInfoAtom)
  const { i18n } = useTranslation()

  useEffect(() => {
    if (token) {
      apis.user.getUser().then(response => {
        setUser(response.data)
      })
      apis.user.getCreditPolicy().then(response => {
        setCreditPolicy(response.data)
      })
      apis.user.getUserSubscription().then(response => {
        if (response.data) {
          setUserSubInfo(response.data)
        } else {
          setUserSubInfo({})
        }
      })

      apis.portfolio.getAllportfolio().then(response => {
        setUserPortfolio(response.data)
      })

      removeGuestCookie()
    } else {
      apis.user.getValidPromotion(SUB_EVENT_ID).then(response => {
        if (response.data?.valid) {
          setUserSubInfo({
            // 비로그인 유저에게 구독 프로모션 정보 노출
            subscription_event: SUB_EVENT_ID,
          })
        }
      })
    }
  }, [token, i18n.language])

  useEffect(() => {
    return () => {
      resetUserSubInfo()
    }
  }, [])

  if (!token) return children

  if ((token && !user.id) || (token && userSubInfo === null)) {
    return <></>
  }

  return children
}

function SetGuestSession({ children }) {
  // 비회원용 페이지에만 사용되므로, 회원 로그인이 확인된 경우 새로고침 처리
  const userToken = getAccessToken()
  if (userToken) {
    window.location.reload()
  }

  // 비회원 세션 구분용 데이터를 세팅함
  const tk1 = getGuestSession()
  const tk2 = getGuestAccess()

  const [guestUser, setGuestUser] = useRecoilState(guestUserAtom)
  const [isLoading, setIsLoading] = useState(true)

  const setToken = async () => {
    const res1 = await apis.guest.getGuestSession()
    if (res1.data) {
      if (res1.data.s) {
        setGuestSession(res1.data.s)
      }
    }

    const res2 = await apis.guest.getGuestAccess()
    if (res2.data?.x) {
      setGuestAccess(res2.data.x)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (!tk1 || !tk2) {
      setToken()
    } else {
      if (!guestUser.id) {
        apis.guest.getGuestUser().then(response => {
          setGuestUser({
            id: response.data.i,
            username: config.GUEST_USERNAME,
          })
        })
      }
      setIsLoading(false)
    }
  }, [tk1, tk2])

  if (isLoading) {
    return <></>
  }

  return children
}

function SetGuestPortfolio({ type = config.PORTFOLIO_TYPE_UPLOAD, children }) {
  const token = getGuestSession()

  const [isLoading, setLoading] = useState(true)
  const [defaultPortfolio, setDefaultPortfolio] = useRecoilState(guestPortfolioAtom)
  const [portfolioType, setPortfolioType] = useRecoilState(portfolioTypeAtom)

  const resetDefaultPortfloio = useResetRecoilState(guestPortfolioAtom)

  const { t } = useTranslation()

  useEffect(() => {
    if (token) {
      setPortfolioType(type)
      setLoading(true)

      apis.guest.getGuestPortfolio(type).then(response => {
        // const name = config.DEFAULT_PORTFOLIO_NAME_DICT[type]
        const d = response.data

        const name = t(`configOrDict.DEFAULT_PORTFOLIO_NAME_DICT.${type}`)
        setDefaultPortfolio({ ...response.data, created: '', name })

        const conf = {
          ...PORTFOLIO_CONFIG_DEFAULT,
          name: d.name,
          ...d.config,
        }

        setLoading(false)
      })
    }
    return () => {
      resetDefaultPortfloio()
    }
  }, [type])

  if (!token) {
    return children
  }

  if (isLoading || type !== portfolioType || !defaultPortfolio.id) {
    return <></>
  }

  return children
}

function SetPortfolio({ children }) {
  const token = getAccessToken()
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(true)
  const [defaultPortfolio, setDefaultPortfolio] = useRecoilState(defaultPortfolioAtom)
  const [portfolioType, setPortfolioType] = useRecoilState(portfolioTypeAtom)
  const resetPortfolioType = useResetRecoilState(portfolioTypeAtom)
  const resetDefaultPortfloio = useResetRecoilState(defaultPortfolioAtom)
  const resetDefaultPortfloioConfig = useResetRecoilState(portfolioConfigAtom)

  const setPortfolioDetail = useSetRecoilState(portfolioDetailAtom)
  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)

  const { t, i18n } = useTranslation()
  const { portfolioId } = useParams()

  useEffect(() => {
    setPortfolioType(null)
  }, [])

  useEffect(() => {
    if (token) {
      // setLoading(true)
      apis.portfolio
        .getPortfolio(portfolioId)
        .then(response => {
          const d = response.data

          if (d.is_default && d.type !== null) {
            // 포트폴리오 id로 접근 불가능한 포트폴리오임 (번들 등 메뉴별 포폴)
            navigate('/portfolio')
            return
          }

          setPortfolioDetail({
            id: portfolioId,
            name: d.name,
            theme: d.theme,
            stat: d.stat,
            user_id: d.user_id,
            config: { ...PORTFOLIO_CONFIG_DEFAULT, name: d.name, ...d.config },
            is_default: d.is_default,
            // 가입시 생성되는 최초의 포트폴리오 대응
          })
          // setLoading(false)
        })
        .catch(error => {
          // setLoading(false)
          console.log(error.response.status)
        })
    }
    // return () => {
    //   resetPortfolioDetail()
    // }
  }, [])

  if (!token) {
    return <Navigate to="/login" replace />
  }

  // if (isLoading) {
  //   return <></>
  // }

  return children
}

function SetDefaultPortfolio({ type = config.PORTFOLIO_TYPE_UPLOAD, children }) {
  const token = getAccessToken()

  const [isLoading, setLoading] = useState(true)
  const [defaultPortfolio, setDefaultPortfolio] = useRecoilState(defaultPortfolioAtom)
  const [portfolioType, setPortfolioType] = useRecoilState(portfolioTypeAtom)

  const [userPortfolio, setUserPortfolio] = useRecoilState(portfolioAtom)

  const resetPortfolioType = useResetRecoilState(portfolioTypeAtom)
  const resetDefaultPortfloio = useResetRecoilState(defaultPortfolioAtom)
  const resetDefaultPortfloioConfig = useResetRecoilState(portfolioConfigAtom)

  const setPortfolioDetail = useSetRecoilState(portfolioDetailAtom)

  const { t, i18n } = useTranslation()

  // useEffect(() => {
  //   if (token) {
  //     setPortfolioType(type)
  //     setLoading(true)
  //     apis.portfolio.getAllportfolio().then(response => {
  //       setUserPortfolio(response.data)
  //     })

  //     // if (type === config.PORTFOLIO_TYPE_MANNEQUIN || type === config.PORTFOLIO_TYPE_FACE) {
  //     //   // 기존에 분리되어있던 2개의 메뉴를 통합하여 보여주기 위함
  //     //   apis.portfolio.getDefaultPortfolioList(['mannequin', 'face']).then(response => {
  //     //     // const name = config.DEFAULT_PORTFOLIO_NAME_DICT[type]
  //     //     const d = response.data

  //     //     const mannequinPortfolio = d.filter(p => p.type === config.PORTFOLIO_TYPE_MANNEQUIN)
  //     //       ? d.filter(p => p.type === config.PORTFOLIO_TYPE_MANNEQUIN)[0]
  //     //       : null
  //     //     const facePortfolio = d.filter(p => p.type === config.PORTFOLIO_TYPE_FACE)
  //     //       ? d.filter(p => p.type === config.PORTFOLIO_TYPE_FACE)[0]
  //     //       : null

  //     //     const name = t(`configOrDict.DEFAULT_PORTFOLIO_NAME_DICT.${type}`)

  //     //     setDefaultPortfolio({ ...mannequinPortfolio, facePortfolio, created: '', name })

  //     //     const conf = {
  //     //       ...PORTFOLIO_CONFIG_DEFAULT,
  //     //       name: d.name,
  //     //       ...d.config,
  //     //     }

  //     //     setPortfolioDetail({
  //     //       id: mannequinPortfolio.id,
  //     //       name: mannequinPortfolio.name,
  //     //       theme: mannequinPortfolio.theme,
  //     //       stat: mannequinPortfolio.stat,
  //     //       user_id: mannequinPortfolio.user_id,
  //     //       config: conf,
  //     //       is_default: mannequinPortfolio.is_default,
  //     //       facePortfolio,
  //     //     })
  //     //     setLoading(false)
  //     //   })
  //     // } else {
  //     //   apis.portfolio.getDefaultPortfolio(type).then(response => {
  //     //     // const name = config.DEFAULT_PORTFOLIO_NAME_DICT[type]
  //     //     const d = response.data

  //     //     const name = t(`configOrDict.DEFAULT_PORTFOLIO_NAME_DICT.${type}`)
  //     //     setDefaultPortfolio({ ...response.data, created: '', name })

  //     //     const conf = {
  //     //       ...PORTFOLIO_CONFIG_DEFAULT,
  //     //       name: d.name,
  //     //       // bgWhite: true,
  //     //       // bgSimple: false,
  //     //       // bgComplex: false,
  //     //       // bgGenerate: false,
  //     //       ...d.config,
  //     //     }

  //     //     // if (type === config.PORTFOLIO_TYPE_BGEXPANSION) {
  //     //     //   conf = {
  //     //     //     ...conf,
  //     //     //     flag_generate: true,
  //     //     //     flag_complex_cmp: true,
  //     //     //     flag_simple_cmp: true,
  //     //     //     flag_white_cmp: true,
  //     //     //     output_size_w: 0,
  //     //     //     output_size_h: 0,
  //     //     //     flag_gen_compo: false,
  //     //     //     flag_bg_expansion: true,
  //     //     //     output_size_list: [{ w: null, h: null }], // TODO 직전에 사용한 사이즈 남겨주기 ?
  //     //     //   }
  //     //     // }
  //     //     // console.log(conf)

  //     //     setPortfolioDetail({
  //     //       id: d.id,
  //     //       name: d.name,
  //     //       theme: d.theme,
  //     //       stat: d.stat,
  //     //       user_id: d.user_id,
  //     //       config: conf,
  //     //       is_default: d.is_default,
  //     //     })
  //     //     setLoading(false)
  //     //   })
  //     // }

  //     apis.portfolio.getDefaultPortfolio(type).then(response => {
  //       // const name = config.DEFAULT_PORTFOLIO_NAME_DICT[type]
  //       const d = response.data

  //       const name = t(`configOrDict.DEFAULT_PORTFOLIO_NAME_DICT.${type}`)
  //       setDefaultPortfolio({ ...response.data, created: '', name })

  //       const conf = {
  //         ...PORTFOLIO_CONFIG_DEFAULT,
  //         name: d.name,
  //         ...d.config,
  //       }

  //       setPortfolioDetail({
  //         id: d.id,
  //         name: d.name,
  //         theme: d.theme,
  //         stat: d.stat,
  //         user_id: d.user_id,
  //         config: conf,
  //         is_default: d.is_default,
  //       })
  //       setLoading(false)
  //     })
  //   }
  //   return () => {
  //     resetDefaultPortfloio()
  //     resetPortfolioType()
  //     resetDefaultPortfloioConfig()
  //   }
  // }, [type])

  const latestType = useRef(type)

  const fetchPortfolioData = useCallback(
    async currentType => {
      if (!token) return

      setLoading(true)
      setPortfolioType(currentType)

      if (latestType.current !== currentType) return // 만약 타입이 다르다면 문제 발생할 수 있어 맞혀오는걸로

      try {
        const [portfolioResponse, defaultPortfolioResponse] = await Promise.all([
          apis.portfolio.getAllportfolio(),
          apis.portfolio.getDefaultPortfolio(currentType),
        ])

        setUserPortfolio(portfolioResponse.data)

        const d = defaultPortfolioResponse.data
        const name = t(`configOrDict.DEFAULT_PORTFOLIO_NAME_DICT.${currentType}`)
        setDefaultPortfolio({ ...d, created: '', name })

        const conf = {
          ...PORTFOLIO_CONFIG_DEFAULT,
          name: d.name,
          ...d.config,
        }

        setPortfolioDetail({
          id: d.id,
          name: d.name,
          theme: d.theme,
          stat: d.stat,
          user_id: d.user_id,
          config: conf,
          is_default: d.is_default,
        })
      } catch (error) {
        console.error(error)
      } finally {
        if (latestType.current === currentType) {
          setLoading(false)
        }
      }
    },
    [token]
  )

  useEffect(() => {
    latestType.current = type
    fetchPortfolioData(type)

    return () => {
      resetDefaultPortfloio()
      resetPortfolioType()
      resetDefaultPortfloioConfig()
    }
  }, [type])

  if (!token) {
    return children
  }

  if (isLoading || type !== portfolioType || !defaultPortfolio.id) {
    return <></>
  }

  return children
}

function SetSpecifiedPortfolio({ type = config.PORTFOLIO_TYPE_BANNER, children }) {
  const token = getAccessToken()

  const [isLoading, setLoading] = useState(true)
  const [defaultPortfolio, setDefaultPortfolio] = useRecoilState(defaultPortfolioAtom)
  const [portfolioType, setPortfolioType] = useRecoilState(portfolioTypeAtom)
  const [unusualPortfolioConfig, setUnusualPortfolioConfig] = useRecoilState(
    unusualPortfolioConfigUpdateAtom
  )
  const [brandReadOnlyConfig, setBrandReadOnlyConfig] = useRecoilState(brandReadOnlyConfigAtom)
  const [brandConfigUpdate, setBrandConfigUpdate] = useRecoilState(brandConfigUpdateAtom)

  const resetBrandReadOnlyConfig = useResetRecoilState(brandReadOnlyConfigAtom)
  const resetBrandConfigUpdate = useResetRecoilState(brandConfigUpdateAtom)
  const resetUnusualPortfolioConfig = useResetRecoilState(unusualPortfolioConfigUpdateAtom)
  const resetPortfolioType = useResetRecoilState(portfolioTypeAtom)
  const resetDefaultPortfloio = useResetRecoilState(defaultPortfolioAtom)
  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetDefaultPortfloioConfig = useResetRecoilState(portfolioConfigAtom)

  const setPortfolioDetail = useSetRecoilState(portfolioDetailAtom)

  const { t, i18n } = useTranslation()

  useEffect(() => {
    setLoading(true)

    if (token) {
      setPortfolioType(type)

      apis.portfolio.getDefaultPortfolio(type).then(response => {
        const d = response.data

        const conf = {
          ...getDefaultConfigByType(type),
          ...d.config,
        }

        const name = t(`configOrDict.DEFAULT_PORTFOLIO_NAME_DICT.${type}`)
        setDefaultPortfolio({
          ...getDefaultConfigByType(type),
          ...response.data,
          created: '',
          name,
        })

        setPortfolioDetail({
          id: d.id,
          name: d.name,
          theme: d.theme,
          stat: d.stat,
          user_id: d.user_id,
          config: conf,
          is_default: d.is_default,
        })

        // console.log('routes 에서 가져올 기본값 portofolioDetail', {
        //   id: d.id,
        //   name: d.name,
        //   theme: d.theme,
        //   stat: d.stat,
        //   user_id: d.user_id,
        //   config: conf,
        //   is_default: d.is_default,
        // })

        apis.user.getUserLogoImage().then(async r => {
          const d = r.data

          if (d) {
            const imageFile = d.s3_url ? await imageUrlToFile(getS3ImageSrc(d.s3_url)) : ''
            setBrandReadOnlyConfig({ ...d, logoImage: imageFile })

            setBrandConfigUpdate({
              logoImage: imageFile ? [imageFile] : [],
              brandName: d.brand_name,
              brandFeature: d.brand_concept,
              logoS3path: d.s3_url,
            })
          } else {
            setBrandReadOnlyConfig(d)
            setBrandConfigUpdate({ ...BRAND_CONFIG_DEFAULT })
          }

          setLoading(false)
        })

        // default 함수에 따라 분기되어 여러개 사용 가능
        // const defaultConfig = getDefaultConfigByType(type)

        setUnusualPortfolioConfig({ ...conf })
      })
    } else {
      setLoading(false)
      setUnusualPortfolioConfig({ ...BANNER_CONFIG_DEFAULT })
    }

    return () => {
      resetPortfolioType()
      resetDefaultPortfloio()
      resetPortfolioDetail()
      resetDefaultPortfloioConfig()
      resetUnusualPortfolioConfig()
      resetBrandReadOnlyConfig()
      resetBrandConfigUpdate()
    }
  }, [type])

  if (!token && !isLoading) {
    return children
  }

  if (isLoading || type !== portfolioType || !defaultPortfolio.id) {
    return <></>
  }

  return children
}

function SetMypageData({ children }) {
  const token = getAccessToken()
  const [user, setUser] = useRecoilState(userAtom)
  const [creditPolicy, setCreditPolicy] = useRecoilState(creditPolicyAtom)

  useEffect(() => {
    if (token) {
      apis.user.getUser().then(response => {
        setUser(response.data)
      })
      apis.user.getCreditPolicy().then(response => {
        setCreditPolicy(response.data)
      })

      // apis.user.getUserSubscription().then(response => {
      //   if (response.data?.plan_id) {
      //     setUserSubId(response.data.plan_id)
      //   }
      // })
    }
  }, [token])

  return children
}
