import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { confirmDialogAtom } from 'atoms'
import { alpha } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { isKo } from 'utils/common'
import { Box, CircularProgress } from '@mui/material'

export default function ConfirmDialog() {
  const [confirmDialog, setConfirmDialog] = useRecoilState(confirmDialogAtom)
  const { t, i18n } = useTranslation()
  const {
    open,
    alertOnly,
    title,
    content,
    sx,
    onCofirmTitle,
    onConfirm,
    onCancel,
    isHide,
    isLoading = false,
    keepDialogOnConfirm = false,
  } = confirmDialog
  const resetData = useResetRecoilState(confirmDialogAtom)

  const handleConfirm = () => {
    onConfirm()
    if (!keepDialogOnConfirm) {
      resetData()
    }
  }
  const handleCancel = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    if (!alertOnly) {
      onCancel()
    }
    resetData()
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ zIndex: 9999, '& .MuiDialog-paper': { width: '40rem', height: '20rem' }, ...sx }}
      hideBackdrop={isHide}
    >
      {isLoading && (
        <Box
          sx={{
            background: 'black',
            opacity: 0.3,
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 99,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <DialogTitle
        id="alert-dialog-title"
        sx={{ p: '3rem', pb: 0, fontSize: '2rem', fontWeight: 700 }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ p: '3rem', pt: '1.4rem', fontSize: '1.6rem', fontWeight: 400 }}>
        {content}
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        {!alertOnly && (
          <Button
            variant="contained"
            onClick={handleCancel}
            sx={{
              width: '50%',
              height: '4.8rem',
              backgroundColor: '#F8F8F8',
              borderRadius: '0',
              color: theme => theme.palette.common.black,
              '&:hover': {
                backgroundColor: '#F8F8F8',
                color: theme => theme.palette.common.black,
              },
            }}
          >
            {t('button.cancel')}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleConfirm}
          sx={{
            ml: '0 !important',
            height: '4.8rem',
            borderRadius: '0',
            ...(alertOnly ? { width: '100%' } : { width: '50%' }),
            color: theme => theme.palette.common.white,
            '&:hover': {
              color: theme => theme.palette.common.white,
            },
          }}
        >
          {onCofirmTitle || isKo(i18n) ? '확인' : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
