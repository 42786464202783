import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { ARTWORK_ADDPIECE_STATUS } from 'config'

import Swiper, { Pagination, Grid, Navigation } from 'swiper'
import { getS3ImageSrc } from 'utils/common'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { CenterAlignStack } from 'components'
import { apis } from 'apis'
import { SelectedIcon, CloseIcon } from 'theme/icon'
import { defaultPortfolioAtom, userAtom, portfolioTypeAtom } from 'atoms'
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom'
import { BarLoader as Loader, PuffLoader } from 'react-spinners'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { MOBILE_PORTFOLIO_WIDTH_LIMIT } from 'pages/Portfolio'
import { forEach } from 'lodash'
import { usePortfolioId } from 'hooks/usePortfoliId'
import { useTranslation } from 'react-i18next'
import { getUserType } from 'utils/user'

const MAX = 5

const dialogStyle = {
  mt: {
    lg: 0,
    xs: 'calc(100vh - 60rem)',
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    borderRadius: { lg: '2rem', xs: '3rem 3rem 0 0' },
    height: { lg: 'unset', xs: '60rem' },
  },

  '& .MuiDialog-container .MuiPaper-root': {
    width: '78rem',
  },
  '& .MuiDialogContent-root': {
    py: { lg: '5.6rem', xs: 0 },
    px: { lg: '7.2rem', xs: '1.5rem' },
  },
}

export default function AddPieceDialog({ open, setOpen, artwork, setArtwork, upload }) {
  const [checked, setChecked] = useState([])
  const [uploading, setUploading] = useState(false)
  const [artworkInfo, setArtworkInfo] = useState({})
  const [items, setItems] = useState([]) // [ [1, 2, 3], [4, 5, 6]]
  const [isLoading, setIsLoading] = useState(false)
  const { t, i18n } = useTranslation()

  const user = useRecoilValue(userAtom)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const isMobile = useMobileMediaQuery()

  const handleClose = () => {
    setOpen(false)

    const anchor = document.querySelector(
      `#a_1681713962682_food_box_ella-olsson-mV_fzXhwiOg-unsplash`
    )

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  const addPiece = () => {
    const formData = new FormData()

    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('user_type', getUserType(user))
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', portfolioId)
    formData.append('retry_type', ARTWORK_ADDPIECE_STATUS)

    // formData.append('selected_bg_ids', checked.join('-----'))

    const genOptions = {
      selected_bg_ids: checked.length ? checked.join('-----') : '', // 배경아이디에 포함될일이 없는 독특한 구분자 .. (백엔드와 맞춤))
    }
    formData.append('gen_options', JSON.stringify(genOptions))

    setUploading(true)

    apis.portfolio
      .updateArtworkFeedback(portfolioId, artwork.id, {
        feedback: ARTWORK_ADDPIECE_STATUS,
        addPieceCount: checked.length,
      })
      .then(response => {
        apis.appfront.retry(formData).finally(() => {
          setUploading(false)
        })
        if (response.data) {
          setOpen(false)

          setArtwork(response.data)

          const anchor = document.querySelector(`#a_${artwork.id}`)
          if (anchor) {
            anchor.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            })
          }
        }
      })

    // ----- GA4 event -----
    window.gtag(
      'event',
      !portfolioType ? 'portfolio_bg_regen_complete' : 'upload_bg_regen_complete',
      {
        count: checked.length,
      }
    )
    // ---------------------
  }

  useEffect(() => {
    const commonConfig = JSON.parse(artwork.config)
    const info = commonConfig
    setArtworkInfo(info)
  }, [])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" sx={dialogStyle} fullScreen={isMobile}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'end',
          pt: '3.5rem',
          pr: '3.6rem',
          pb: '1.4rem',
        }}
      >
        <Box
          sx={{
            width: 'fit-content',
            cursor: 'pointer',
            mr: 1,
          }}
          onClick={handleClose}
        >
          <CloseIcon size="medium" style={{ cursor: 'pointer', mr: 1 }} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 0 }}>
        <CenterAlignStack>
          <Typography fontSize={{ lg: '2rem', xs: '1.6rem' }} fontWeight={700}>
            {t('addpiece.title')}
          </Typography>
          <Typography fontSize={{ lg: '1.5rem', xs: '1.2rem' }} fontWeight={400}>
            {t('addpiece.subtitle')}
          </Typography>
          <BackgroundSwiper
            checked={checked}
            setChecked={setChecked}
            artworkInfo={artworkInfo}
            items={items}
            setItems={setItems}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            currentPieceCount={artwork.pieces?.length}
            artwork={artwork}
          />

          {isLoading ? (
            <PuffLoader />
          ) : items.length === 0 ? (
            <Typography
              fontSize="1.4rem"
              fontWeight={600}
              sx={{ mt: '2rem', textAlign: 'center', mb: '5.9rem' }}
            >
              {t('addpiece.empty_1')}
              <br /> {t('addpiece.empty_2_a')}
              <RouterLink
                to="/user/background"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'unset' }}
              >
                {t('addpiece.empty_2_b')}
              </RouterLink>
              {t('addpiece.empty_2_c')}
              {t('addpiece.empty_3')}
              <br />
            </Typography>
          ) : (
            <Typography fontSize="1.4rem" fontWeight={600} sx={{ mt: '2rem' }}>
              {checked.length > 0
                ? `${checked.length}${t('addpiece.selected_after')}`
                : t('addpiece.selected_before')}
            </Typography>
          )}

          <Button
            variant="contained"
            sx={{
              mt: '2rem',
              width: '18.4rem',
              height: '5rem',
              fontSize: '1.8rem',
              fontWeight: 800,
            }}
            onClick={addPiece}
            disabled={checked.length < 1 || uploading}
          >
            {!uploading && t('button.add_piece')}
            {uploading && <Loader />}
          </Button>
        </CenterAlignStack>
      </DialogContent>
    </Dialog>
  )
}

const swiperStyle = {
  position: 'relative',
  width: '100%',
  // height: '30.8rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '3.9rem',
}

const slideStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',

  fontSize: '2rem',
  textAlign: 'center',

  justifyContent: 'center',
  alignItems: 'center',
}

const swiperProps = {
  slidesPerView: 1,
  spaceBetween: 30,
  centeredSlides: true,
  loop: false,

  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  modules: [Pagination, Navigation],
}

const imageBoxStyle = {
  width: { lg: '14.4rem', xs: '12.8rem' },
  height: { lg: '14.4rem', xs: '12.8rem' },
  cursor: 'pointer',
  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.05)',

  marginRight: { lg: '2rem', xs: '1.5rem' },
  marginBottom: { lg: '2rem', xs: '1.5rem' },

  '&[idx-in-line="2"]': {
    marginRight: { lg: 0 },
  },
  '&[idx-in-line="1"]': {
    marginRight: { lg: '2rem', xs: 0 },
  },

  '&.dummy': {
    visibility: 'hidden',
  },
}
const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '1rem',
}

function BackgroundSwiper({
  checked,
  setChecked,
  artworkInfo,
  items,
  setItems,
  isLoading,
  setIsLoading,
  currentPieceCount,
  artwork,
}) {
  const [swiper, setSwiper] = useState(null)
  const [pageData, setPageData] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [page, setPage] = useState(1)
  const isMobile = useMobileMediaQuery()
  const pageSize = isMobile ? 4 : 6

  useEffect(() => {
    const params = new URLSearchParams()
    const s = new Swiper('.background-swiper', swiperProps)
    setSwiper(s)

    setPage(1)
    fetch()

    return () => {
      if (swiper) swiper.destroy()
    }
  }, [])

  useEffect(() => {
    // setPageData()
  }, [items, page])

  const handlePrevPage = () => {
    setPage(p => p - 1)
  }
  const handleNextPage = () => {
    setPage(p => p + 1)
  }

  const fetch = () => {
    setIsLoading(true)
    const params = new URLSearchParams()
    params.append('angle', artworkInfo.angle)

    apis.background.getBackgrounds(params).then(response => {
      setIsLoading(false)

      const d = response.data

      const resultArray = []

      for (const item1 of d) {
        let isDuplicate = false

        for (const item2 of artwork.pieces) {
          if (item1.id === item2.bg_id) {
            isDuplicate = true
            break
          }
        }

        if (!isDuplicate) {
          resultArray.push(item1)
        }
      }
      const t = Math.ceil(resultArray.length / pageSize)
      setTotalPage(t)

      const grouped = []

      for (let i = 0; i < t; i++) {
        const a = i * pageSize
        const b = (i + 1) * pageSize
        const chunk = resultArray.slice(a, b)
        grouped.push(chunk)
      }
      setItems(grouped)
    })
  }

  return (
    <>
      <div
        className="swiper background-swiper"
        style={{ ...swiperStyle, ...(isMobile && { width: MOBILE_PORTFOLIO_WIDTH_LIMIT }) }}
      >
        <div className="swiper-wrapper">
          {items.map((pageData, p) => {
            return (
              <div key={p} className="swiper-slide" style={slideStyle}>
                {pageData.slice(0, pageSize / 2).map((bg, idx) => (
                  <ImageItem
                    idx-in-line={
                      pageData.length < pageSize / 2 ? idx + (pageSize / 2 - pageData.length) : idx
                    }
                    key={bg.id}
                    bg={bg}
                    checked={checked}
                    setChecked={setChecked}
                    className="bgImage"
                    currentPieceCount={currentPieceCount}
                  />
                ))}

                {/* {pageData.length < 3 && <ImageItem idx-in-line={1} className="bgImage dummy" />}
                {pageData.length < 2 && <ImageItem idx-in-line={2} className="bgImage dummy" />} */}

                <div className="flex-linebreak" />

                {pageData.slice(pageSize / 2, pageSize).map((bg, idx) => (
                  <ImageItem
                    idx-in-line={
                      pageData.length < pageSize ? idx + (pageSize - pageData.length) : idx
                    }
                    key={bg.id}
                    bg={bg}
                    checked={checked}
                    setChecked={setChecked}
                    className="bgImage"
                    currentPieceCount={currentPieceCount}
                  />
                ))}
              </div>
            )
          })}
          {page < totalPage && <div className="swiper-slide" style={slideStyle}></div>}
        </div>
        <div
          className={`swiper-button-next icon-black ${
            page >= totalPage && 'swiper-button-disabled'
          }`}
          onClick={handleNextPage}
        ></div>
        <div className="swiper-button-prev icon-black" onClick={handlePrevPage}></div>
      </div>
    </>
  )
}

function ImageItem({ bg, checked, setChecked, currentPieceCount, ...props }) {
  const [check, setCheck] = useState(false)
  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    if (bg) {
      if (check) {
        setChecked(c => [...c, bg.id])
      } else {
        setChecked(c => c.filter(e => e !== bg.id))
      }
    }
  }, [check])

  const handleClick = () => {
    if (!check) {
      // off -> on
      if (checked.length >= MAX || currentPieceCount + checked.length >= 15) {
        console.log('최대 초과')
        return
      }
    }
    setCheck(c => !c)
  }

  return (
    <Box sx={imageBoxStyle} key={bg?.id ?? ''} onClick={handleClick} {...props}>
      {check && (
        <div
          style={{
            width: isMobile ? imageBoxStyle.width.xs : imageBoxStyle.width.lg,
            height: isMobile ? imageBoxStyle.height.xs : imageBoxStyle.height.lg,
            position: 'absolute',
            backgroundColor: 'rgba(48, 48, 48, 0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SelectedIcon sx={{ width: '6.2rem', height: '6.2rem' }} />
        </div>
      )}
      {bg && <img key={bg.id} src={getS3ImageSrc(bg.s3_url)} style={imageStyle} />}
    </Box>
  )
}
