import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { apis } from 'apis'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { useNavigate } from 'react-router-dom'
import useConfirm from 'hooks/useConfirm'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { linkSnsAccountDialogAtom, tutorialAtom } from 'atoms'
import { useNavigateForPromotion } from 'hooks/useNavigateWithQuery'
import { promotionInfo } from 'pages/promotionEvents/DomeggookEvent'
import MobileSwipeableDrawer from './MobileSwipeableDrawer'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { FormErrorMessage, TypoSpaceBtn } from './FormInput'
import { DialogButton } from './DialogButtons'
import { isKo } from 'utils/common'
import * as cookie from 'utils/cookie'

const promotionId = '4'

function decodeJwtResponse(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function GoogleLogin({
  setOpen,
  socialLogin,
  setSocialLogin,
  setOpenWithdrawInfo,
  setWithdrawInfo,
}) {
  const googleSignInButton = useRef(null)
  const { navigateEvent } = useNavigateForPromotion()
  const setTutorial = useSetRecoilState(tutorialAtom)
  const { showConfirm } = useConfirm()

  const onGoogleSignIn = async res => {
    const token = res.credential

    const d = decodeJwtResponse(token)
    apis.user
      .snsLogin({ sns: 'google', data: d })
      .then(res => {
        if (res.data.success && res.data.id && res.data.active === false) {
          setWithdrawInfo({
            ...res.data,
            sns: 'google',
            sns_data: d,
          })
          setOpenWithdrawInfo(true)
          return
        }

        if (!res.data.registered) {
          setSocialLogin(prev => ({
            ...prev,
            socialName: 'google',
            data: d,
          }))
          setOpen(!res.data.registered)
        } else {
          const d = res.data

          const accessToken = d.access_token
          const refreshToken = d.refresh_token
          setAccessToken(accessToken)
          setRefreshToken(refreshToken)

          // ----- GA4 event -----
          window.gtag('event', 'login', { method: 'google' })
          // ---------------------

          const redirect = cookie.getCookie(cookie.guestRedirectPath) ?? '/generate/upload'
          navigateEvent({ replace: true }, redirect)

          if (!localStorage.getItem('promotionQuery')) {
            apis.user.checkEvent(promotionId).then(response => {
              const isApply = response.data.applied
              // 튜토 OFF
              // setTutorial(prev => ({ step: 0, mode: !isApply }))
            })
          }
        }
      })
      .catch(() => {
        showConfirm({ content: '이미 드랩계정으로 등록된 이메일입니다.', alertOnly: true })
      })
  }

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_LOGIN,
        context: 'signin',
        callback: onGoogleSignIn,
      })
      // window.google.accounts.id.prompt() // 원탭인듯
      // https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton
      window.google.accounts.id.renderButton(
        googleSignInButton.current,
        {
          type: 'icon',
          theme: 'outline',
          shape: 'circle',
          size: 'large',
          click_listener: () => {
            // ----- GA4 event -----
            window.gtag('event', 'sign_up_method', { method: 'google' })
            // ---------------------
          },
        } // customization attributes
      )
    }

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <>
      <div ref={googleSignInButton} style={{ transform: 'scale(1.4)' }} onClick={() => {}}></div>
    </>
  )
}

export function NaverLogin({
  setOpen,
  socialLogin,
  setSocialLogin,
  setOpenWithdrawInfo,
  setWithdrawInfo,
}) {
  const naverRef = useRef()
  const navigate = useNavigate()
  const setTutorial = useSetRecoilState(tutorialAtom)

  const { showConfirm } = useConfirm()
  const { navigateEvent } = useNavigateForPromotion()

  const hash = window.location.hash.substring(1)
  const params = new URLSearchParams(hash)

  const accessToken = params.get('access_token')
  const stateToken = params.get('state')
  const tokenType = params.get('token_type')

  const handleNaverLogin = () => {
    naverRef.current.children[0].click()
    // ----- GA4 event -----
    window.gtag('event', 'sign_up_method', { method: 'naver' })
    // ---------------------
  }

  useEffect(() => {
    localStorage.removeItem('com.naver.nid.access_token')
    localStorage.removeItem('com.naver.nid.oauth.state_token')

    localStorage.setItem('com.naver.nid.access_token', tokenType + '.' + accessToken)
    localStorage.setItem('com.naver.nid.oauth.state_token', stateToken)

    const script = document.createElement('script')

    script.src = 'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.0.js'
    script.onload = () => {
      const { naver } = window
      const naverLogin = new naver.LoginWithNaverId({
        clientId: process.env.REACT_APP_NAVER_LOGIN,
        callbackUrl: `${window.location.origin}/login`,
        loginButton: { color: 'green', type: 1, height: 40, shape: 'circle' },
        // callbackHandle: true,
        // isPopup: true
      })
      naverLogin.init()

      naverLogin.getLoginStatus(function (status) {
        if (status) {
          setSocialLogin(prev => ({ ...prev, socialName: 'naver' }))
          apis.user
            .snsLogin({
              sns: 'naver',
              data: naverLogin.user,
              naver_token: naverLogin.accessToken.accessToken,
            })
            .then(res => {
              if (res.data.success && res.data.id && res.data.active === false) {
                setWithdrawInfo({
                  ...res.data,
                  sns: 'naver',
                  sns_data: naverLogin.user,
                })
                setOpenWithdrawInfo(true)
                return
              }

              if (!res.data.registered) {
                setOpen(!res.data.registered)
                setSocialLogin(prev => ({
                  ...prev,
                  socialName: 'naver',
                  data: {
                    ...naverLogin.user,
                    agreementInfos: res.data.naver_agreement_data.agreementInfos,
                  },
                  accessToken: naverLogin.accessToken.accessToken,
                }))
              } else {
                const d = res.data

                const accessToken = d.access_token
                const refreshToken = d.refresh_token
                setAccessToken(accessToken)
                setRefreshToken(refreshToken)

                const redirect = cookie.getCookie(cookie.guestRedirectPath) ?? '/generate/upload'
                navigateEvent({ replace: true }, redirect)

                if (!localStorage.getItem('promotionQuery')) {
                  apis.user.checkEvent(promotionId).then(response => {
                    const isApply = response.data.applied
                    // 튜토 OFF
                    // setTutorial(prev => ({ step: 0, mode: !isApply }))
                  })
                }

                window.gtag('event', 'login', { method: 'naver' })
              }
            })
            .catch(e => {
              console.log(e)
              // showConfirm({
              //   content: '이미 드랩계정으로 등록된 이메일입니다.',
              //   alertOnly: true,
              // })
            })
        }
      })
    }

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <>
      <div id="naverIdLogin" style={{ display: 'none' }} ref={naverRef} />
      <div style={{ transform: 'scale(1.4)' }}>
        <img
          onClick={handleNaverLogin}
          src="/static/images/logo/outside/naver_logo.png"
          style={{ width: '40px', cursor: 'pointer' }}
        />
      </div>
    </>
  )

  // const REDIRECT_URI = `${location.origin}/login`
  // const STATE = 'flase'
  // const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?client_id=${process.env.REACT_APP_NAVER_LOGIN}&state=${STATE}&redirect_uri=${REDIRECT_URI}&response_type=code&`

  // useEffect(() => {
  //   const params = new URL(document.location.toString()).searchParams
  //   const code = params.get('code') // 인가코드 받는 부분
  //   const state = params.get('state') // 인가코드 받는 부분

  //   const tokenParams = new URLSearchParams()
  //   tokenParams.append('grant_type', 'authorization_code')
  //   tokenParams.append('client_id', process.env.REACT_APP_NAVER_LOGIN)
  //   tokenParams.append('redirect_uri', REDIRECT_URI)
  //   tokenParams.append('state', STATE)
  //   tokenParams.append('code', code)
  // }, [])

  // return (
  //   <a href={NAVER_AUTH_URL} style={{ transform: 'scale(1.4)' }}>
  //     <img
  //       src="/static/images/logo/outside/naver_logo.png"
  //       style={{ width: '40px', cursor: 'pointer' }}
  //       alt="네이버 로그인 버튼"
  //     />
  //   </a>
  // )
}

export function KakaoLogin({
  setOpen,
  socialLogin,
  setSocialLogin,
  setOpenWithdrawInfo,
  setWithdrawInfo,
}) {
  const REDIRECT_URI = `${location.origin}/login`
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_LOGIN}&redirect_uri=${REDIRECT_URI}&response_type=code`
  const setTutorial = useSetRecoilState(tutorialAtom)
  const { navigateEvent } = useNavigateForPromotion()
  const navigate = useNavigate()
  const { showConfirm } = useConfirm()

  useEffect(() => {
    const params = new URL(document.location.toString()).searchParams
    const code = params.get('code') // 인가코드 받는 부분
    const state = params.get('state') // 인가코드 받는 부분

    const tokenParams = new URLSearchParams()
    tokenParams.append('grant_type', 'authorization_code')
    tokenParams.append('client_id', process.env.REACT_APP_KAKAO_LOGIN)
    tokenParams.append('redirect_uri', REDIRECT_URI)
    tokenParams.append('code', code)

    let kakaoAccessToken = null

    if (code && !state) {
      axios
        .post(`https://kauth.kakao.com/oauth/token?`, tokenParams, {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          },
        })
        .then(res => {
          kakaoAccessToken = res.data.access_token
          axios
            .get('https://kapi.kakao.com//v2/user/me', {
              headers: {
                Authorization: `Bearer ${res.data.access_token}`,
                'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
              },
            })
            .then(res => {
              setSocialLogin(prev => ({
                ...prev,
                data: res.data,
              }))

              const kakaoLoginData = res.data
              apis.user
                .snsLogin({ sns: 'kakao', data: { ...res.data, access_token: kakaoAccessToken } })
                .then(res => {
                  if (res.data.success && res.data.id && res.data.active === false) {
                    setWithdrawInfo({
                      ...res.data,
                      sns: 'kakao',
                      sns_data: { ...kakaoLoginData, access_token: kakaoAccessToken },
                    })
                    setOpenWithdrawInfo(true)
                    return
                  }
                  if (!res.data.registered) {
                    setOpen(!res.data.registered)
                  } else {
                    const d = res.data

                    const accessToken = d.access_token
                    const refreshToken = d.refresh_token
                    setAccessToken(accessToken)
                    setRefreshToken(refreshToken)

                    // navigate('/home', { replace: true })

                    const redirect =
                      cookie.getCookie(cookie.guestRedirectPath) ?? '/generate/upload'
                    navigateEvent({ replace: true }, redirect)

                    if (!localStorage.getItem('promotionQuery')) {
                      apis.user.checkEvent(promotionId).then(response => {
                        const isApply = response.data.applied
                        // 튜토 OFF
                        // setTutorial(prev => ({ step: 0, mode: !isApply }))
                      })
                    }

                    window.gtag('event', 'login', { method: 'kakao' })
                  }
                })
                .catch(() => {
                  showConfirm({
                    content: '이미 드랩계정으로 등록된 이메일입니다.',
                    alertOnly: true,
                  })
                })
            })

          // 가입 시
          axios
            .get('https://kapi.kakao.com/v1/user/service/terms', {
              headers: {
                Authorization: `Bearer ${res.data.access_token}`,
                'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
              },
            })
            .then(res => {
              setSocialLogin(prev => ({
                ...prev,
                socialName: 'kakao',
                agreeInfo: res.data.allowed_service_terms,
              }))
            })

          // res에 포함된 토큰 받아서 원하는 로직을 하면된다.
        })
    }
  }, [])

  return (
    <a
      href={KAKAO_AUTH_URL}
      style={{ transform: 'scale(1.4)' }}
      onClick={() => {
        // ----- GA4 event -----
        window.gtag('event', 'sign_up_method', { method: 'kakao' })
        // ---------------------
      }}
    >
      <img
        src="/static/images/logo/outside/kakao_logo.png"
        style={{ width: '40px' }}
        alt="카카오 로그인 버튼"
      />
    </a>
  )
}

export function LinkSnsAccountDialog({
  onCancel = () => {
    console.log('default cancel')
  },
  callback = () => {
    console.log('default callback')
  },
}) {
  const [d, setD] = useRecoilState(linkSnsAccountDialogAtom)
  const { open, userId, username } = d

  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const setOpen = o => {
    setD({ ...d, open: o })
  }

  const handleClose = () => {
    setD({ open: false })
  }

  const handleSubmit = () => {
    if (password.length < 1) {
      setError(true)
      return
    }

    apis.user
      .linkAccount({
        ...d,
        account: userId,
        pw: password,
        login_sns_id: d.sns === 'google' ? d.data.sub : d.data.id,
      })
      .then(response => {
        if (response.data.success) {
          alert('계정 연동이 완료되었습니다. 다시 로그인해 주세요.')
          setD({ open: false })
          // ----- GA4 event -----
          window.gtag('event', 'login_sns_link', {})
          // ---------------------
        } else {
          if (response.data.message) {
            alert(response.data.message)
          } else {
            alert('오류가 발생하였습니다.')
          }
        }
      })
      .finally(() => {})
  }

  const MiniSnsIcon = () => {
    let src = null
    if (d.existSns === 'google') {
      src = '/static/images/etc/mini_google_icon.png'
    } else if (d.existSns === 'naver') {
      src = '/static/images/etc/mini_naver_icon.png'
    } else if (d.existSns === 'kakao') {
      src = '/static/images/etc/mini_kakao_icon.png'
    }

    if (src) {
      return (
        <div style={{ width: '24px' }}>
          <img src={src} />
        </div>
      )
    }

    return <></>
  }

  const dialogContent = (
    <CenterAlignStack sx={{ width: '100%', pt: { lg: '2rem' }, pb: '3rem' }}>
      <CenterAlignStack
        sx={{
          width: '100%',
          justifyContent: 'center',
          '& .title': {
            fontSize: { lg: '2rem', xs: '1.6rem' },
            fontWeight: { lg: 700, xs: 800 },
            mb: '0.2rem',
          },
          '& .text': {
            fontSize: { lg: '1.4rem', xs: '1.2rem' },
            fontWeight: 400,
            lineHeight: 1.3,
          },
        }}
      >
        <Typography className="title">{t('login.link_sns_account_title')}</Typography>
        <Typography className="text">{t('login.link_sns_account_message_1')}</Typography>
        <Typography className="text" sx={{ textAlign: 'center', mx: isKo(i18n) ? '0' : '2rem' }}>
          {t('login.link_sns_account_message_2')}
        </Typography>
        <br />
        <CenterAlignBox sx={{ background: '#F8F8F8', width: '100%', py: '1.5rem' }}>
          <Typography
            className="text"
            sx={{
              '& span': {
                fontSize: '1.8rem',
                fontWeight: 700,
              },
            }}
          >
            <span>ID: {username}</span>
          </Typography>
        </CenterAlignBox>

        <CenterAlignStack sx={{ py: '2rem' }}>
          <CenterAlignStack direction="row" spacing={1}>
            <Typography sx={{ fontWeight: 500, fontSize: '1.6rem' }}>
              {t('password_dialog.password')}
            </Typography>
            <OutlinedInput
              placeholder={t('password_dialog.password_placeholder')}
              value={password}
              type="password"
              onChange={e => {
                if (error) {
                  setError(false)
                }
                setPassword(e.target.value)
              }}
              sx={{
                width: '25.2rem',
                height: '4rem',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme => theme.palette.common.black,
                },
                '& .MuiOutlinedInput-input': { padding: 0 },
              }}
            />
          </CenterAlignStack>
          {error && (
            <FormErrorMessage
              message={t('login.link_sns_account_pw_error_1')}
              style={{
                height: error ? '1.2rem' : '0',
              }}
            />
          )}
        </CenterAlignStack>
      </CenterAlignStack>

      <CenterAlignStack>
        <DialogButton
          cancelSx={{
            width: { lg: '12rem', xs: '8.8rem' },
            height: { lg: '5rem', xs: '4.8rem' },
            fontSize: { lg: '2rem', xs: '1.8rem' },
          }}
          actionSx={{
            width: { lg: '16rem', xs: '14.8rem' },
            minWidth: 'unset',
            fontSize: { lg: isKo(i18n) ? '2rem' : '1.7rem', xs: '1.8rem' },
            lineHeight: { lg: 0.9, xs: 'normal' },
          }}
          actionText={t('button.link_account')}
          handleAction={handleSubmit}
          handleClose={handleClose}
        />
      </CenterAlignStack>
    </CenterAlignStack>
  )

  const dialogContentForSns = (
    <CenterAlignStack sx={{ width: '100%', pt: { lg: '2rem' }, pb: '3rem' }}>
      <CenterAlignStack
        sx={{
          width: '100%',
          justifyContent: 'center',
          '& .title': {
            fontSize: { lg: '2rem', xs: '1.6rem' },
            fontWeight: { lg: 700, xs: 800 },
            mb: '0.2rem',
          },
          '& .text': {
            fontSize: { lg: '1.4rem', xs: '1.2rem' },
            fontWeight: 400,
            lineHeight: 1.3,
          },
        }}
      >
        <Typography className="title">{t('login.notice_sns_account_title2')}</Typography>
        <Typography className="text">{t('login.notice_sns_account_message_1')}</Typography>
        <Typography className="text">{t('login.notice_sns_account_message_2')}</Typography>
        <br />
        <CenterAlignStack sx={{ background: '#F8F8F8', width: '100%', py: '1.5rem' }}>
          <Stack
            direction="row"
            spacing={0.6}
            sx={{
              alignItems: 'center',
              '& .text': {
                fontSize: { lg: '2rem', xs: '2rem' },
                fontWeight: 700,
              },
              mb: '0.2rem',
            }}
          >
            <MiniSnsIcon />
            <Typography className="text">
              {d.existSns &&
                (d.existSns === 'naver'
                  ? d.existSns.toUpperCase()
                  : d.existSns.charAt(0).toUpperCase() + d.existSns.slice(1))}
            </Typography>
          </Stack>
          <Typography
            className="text"
            sx={{
              '& span': {
                fontSize: '1.8rem',
                fontWeight: 500,
              },
            }}
          >
            <span>{username}</span>
          </Typography>
        </CenterAlignStack>
      </CenterAlignStack>

      <CenterAlignStack sx={{ mt: '2rem' }}>
        <DialogButton
          cancelSx={{
            display: 'none',
          }}
          actionSx={{
            width: { lg: '12rem', xs: '8.8rem' },
            height: { lg: '5rem', xs: '4.8rem' },
            fontSize: { lg: '2rem', xs: '1.8rem' },
          }}
          handleClose={handleClose}
          actionText={t('button.confirmation')}
          handleAction={handleClose}
        />
      </CenterAlignStack>
    </CenterAlignStack>
  )

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          sx={{ '& .MuiPaper-root': { borderRadius: '2rem' } }}
        >
          <DialogContent sx={{ width: '41.2rem', p: 0 }}>
            {d.existSns ? dialogContentForSns : dialogContent}
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {}}
          onClose={() => {
            setOpen(false)
          }}
        >
          {d.existSns ? dialogContentForSns : dialogContent}
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}
