import { useRecoilCallback } from 'recoil'

export default function DebugButton() {
  const onClick = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        console.log(snapshot)
        console.debug('-----Atom values -----')
        for (const node of snapshot.getNodes_UNSTABLE()) {
          const value = await snapshot.getPromise(node)
          console.debug(node.key, value)
        }
        console.debug('------------------------')
      },
    []
  )

  return <button onClick={onClick}>Dump State</button>
}
