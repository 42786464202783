import { TextField, InputAdornment, IconButton, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { SearchIcon, SearchIconMobile } from 'theme/icon'

export const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    padding: 0,
    paddingRight: '1rem',
    width: '18rem',
    height: '3.6rem',

    '& fieldset': {
      borderWidth: '0.2rem',
      borderColor: theme.palette.common.black,
    },

    '&.Mui-focused fieldset': {
      borderColor: theme.palette.draph.blue,
    },
  },

  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    padding: '0.9rem 0 0.9rem 1rem',
  },
}))

export default function SearchInput({ sx, endAdornmentProps = {}, ...props }) {
  const width = props.width
  const height = props.height

  return (
    <StyledInput
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" {...endAdornmentProps}>
            <SearchIcon id="search-icon" />
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiInputBase-root': {
          width,
          height,
        },
        ...sx,
      }}
      {...props}
    />
  )
}
export function SearchInputMobile(props) {
  const width = props.width
  const height = props.height
  const [InputFieldOpen, setInputFieldOpen] = useState(false)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <TextField
        variant="standard"
        sx={{
          mr: '-3.5rem',
          mt: '0.6rem',
          '& input': { pr: '3rem' },
          width: InputFieldOpen ? '75%' : 0,
          transition: 'width 0.4s ease-out',
        }}
        inputRef={input => input && InputFieldOpen && input.focus()}
        {...props}
      />
      <IconButton
        sx={{
          px: '1rem',
          mt: '0.3rem',
          // transition: 'color 0.1s, background-color 0.1s',
          '&:active': {
            backgroundColor: 'transparent',
            color: theme => theme.palette.draph.blue,
            '& svg path': {
              transition: 'stroke 0.1s',
              stroke: theme => theme.palette.draph.blue,
            },
          },

          '&:hover': {
            backgroundColor: 'transparent',
            color: theme => theme.palette.draph.blue,
            '& svg path': {
              transition: 'stroke 0.1s',
              stroke: theme => theme.palette.draph.blue,
            },
          },
        }}
        onClick={e => {
          if (!InputFieldOpen) {
            setInputFieldOpen(true)
          }
        }}
      >
        <SearchIconMobile />
      </IconButton>
    </Box>
  )
}
