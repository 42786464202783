import {
  keepShowingConfigAtom,
  portfolioArtworkAtom,
  portfolioDetailAtom,
  portfolioTypeAtom,
  uploadDialogOpenAtom,
  uploadFilesAndUrlAtom,
  uploadFilesAtom,
} from 'atoms'
import { getImagePromise, useAlertModal } from 'components/portfolio/UploadImageDialog'
import { useRecoilState, useRecoilValue } from 'recoil'
import * as config from 'config'
import useConfirm from './useConfirm'
import { imgResizeAndBinary, useHandleGetNpy } from 'components/portfolio/UploadMannequinDialog'
import { useTranslation } from 'react-i18next'
import { allowedTypes, getS3ImageSrc, imageUrlToFile } from 'utils/common'
import { Typography } from '@mui/material'

const IMAGE_RATIO = 3

export const useRefineUploadFiles = (uploadImglimit = 20) => {
  const alertModal = useAlertModal()
  const { showConfirm } = useConfirm()
  const { t, i18n } = useTranslation()
  const [files, setFiles] = useRecoilState(uploadFilesAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const portfolioArtwork = useRecoilValue(portfolioArtworkAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const mannequinMode = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN

  const bannerMode = portfolioDetail.config?.genType === 'mann2man'

  const handleGetNpy = useHandleGetNpy()

  const portfolioArtworklimit = portfolioDetail.is_default ? Infinity : 200

  const refineUploadFiles = async (uploadFiles, editOnly = false) => {
    // const uploadFiles = Array.prototype.slice.call(uploadfiles)

    uploadFiles = uploadFiles.filter(
      f => f.name.split('.')[f.name.split('.').length - 1] !== 'jfif'
    )

    if (!portfolioArtwork) return

    let imageSizeTooSmall = false
    let imageRatioTooLarge = false
    let notAllowedExt = false

    let indexToRemove = []
    const indexArray = []
    const removedImg = []

    const promises = uploadFiles.map(async (f, i) => {
      if (!allowedTypes.includes(f.type)) {
        notAllowedExt = true
        indexArray.push(i)
      } else {
        const objectUrl = window.URL.createObjectURL(f)

        const [w, h] = await getImagePromise(objectUrl)

        if (w < 384 || h < 384) {
          imageSizeTooSmall = true
          indexArray.push(i)
        }

        if (mannequinMode && (w / h > IMAGE_RATIO || h / w > IMAGE_RATIO)) {
          imageRatioTooLarge = true
          indexArray.push(i)
        }
      }
    })

    await Promise.all(promises).catch(err => {
      console.log(err)
    })

    indexToRemove = [...new Set(indexArray)]

    // 앞에서 지울 경우 인덱스가 틀어져 오류 발생
    indexToRemove.sort((a, b) => b - a)

    for (const index of indexToRemove) {
      const elements = uploadFiles.splice(index, 1)
      removedImg.push(...elements)
    }

    const displayNotallowedImageURL = removedImg
      .filter(f => f.type.includes('image/'))
      .map(f => URL.createObjectURL(f))

    const revokeFunc = () => {
      displayNotallowedImageURL.forEach(url => URL.revokeObjectURL(url))
    }
    if (imageSizeTooSmall || imageRatioTooLarge || notAllowedExt) {
      alertModal(
        displayNotallowedImageURL,
        imageSizeTooSmall,
        imageRatioTooLarge,
        notAllowedExt,
        revokeFunc
      )
    }

    // if (uploadFiles.length > uploadImglimit) {
    //   uploadFiles = Array.prototype.slice.call(e.target.files, 0, uploadImglimit)
    // }

    // inputRef.current.value = ''
    if (editOnly) {
      uploadFiles = uploadFiles.splice(0, 1)

      if (uploadFiles.length) {
        const image = await imgResizeAndBinary(uploadFiles)
        // console.log('image', image)
        setFiles(image)
        handleGetNpy(image, 'fine')
        return
      }
    }

    if (bannerMode) {
      uploadFiles = uploadFiles.splice(0, 1)

      if (uploadFiles.length) {
        const image = await imgResizeAndBinary(uploadFiles)

        setFiles([image])
        // handleGetNpy(image, editOnly)
        return
      }
    }

    if (mannequinMode) {
      uploadFiles = uploadFiles.splice(0, 1)

      if (uploadFiles.length) {
        const image = await imgResizeAndBinary(uploadFiles)

        setFiles([image])
        handleGetNpy(image)

        if (uploadOpen === false) {
          setUploadOpen(true)
        }
      }
    } else {
      if (files.length + portfolioArtwork.length >= portfolioArtworklimit) {
        showConfirm({
          alertOnly: true,
          content: t('upload_dialog.warning_5'),
        })
      }

      setFiles(prev => {
        const prevCopy = [...prev]
        prevCopy.push(...uploadFiles)

        // console.log(prevCopy.length, portfolioArtwork.length, portfolioArtworklimit)
        // console.log(prevCopy.length + portfolioArtwork.length > portfolioArtworklimit)

        if (
          prevCopy.length > uploadImglimit ||
          prevCopy.length + portfolioArtwork.length > portfolioArtworklimit
        ) {
          return prevCopy.slice(
            0,
            Math.min(uploadImglimit, Math.max(portfolioArtworklimit - portfolioArtwork.length, 0))
          )
        }

        return prevCopy
      })
    }
  }
  return refineUploadFiles
}

export const useCheckImage = (uploadImglimit = 20) => {
  const alertModal = useAlertModal()
  const { showConfirm } = useConfirm()
  const { t, i18n } = useTranslation()

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  // 이걸로 Config 계속 오픈할 예정
  const [keepShowingConfig, setKeepShowingConfig] = useRecoilState(keepShowingConfigAtom)

  const portfolioArtwork = useRecoilValue(portfolioArtworkAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  // const mannequinMode = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN

  const bannerMode = portfolioDetail.config?.genType === 'mann2man'

  const handleGetNpy = useHandleGetNpy()

  const portfolioArtworklimit = portfolioDetail.is_default ? Infinity : 200

  const checkImage = async (
    uploadFiles,
    files,
    setFiles,
    mannequinMode = false,
    editOnly = false,
    keepShowingOn = true
  ) => {
    // const uploadFiles = Array.prototype.slice.call(uploadfiles)

    // uploadFiles = uploadFiles.filter(
    //   f => f.name.split('.')[f.name.split('.').length - 1] !== 'jfif'
    // )

    if (!portfolioArtwork) return

    let imageSizeTooSmall = false
    let imageRatioTooLarge = false
    let notAllowedExt = false

    let indexToRemove = []
    const indexArray = []
    const removedImg = []

    const promises = uploadFiles.map(async (f, i) => {
      if (
        !allowedTypes.includes(f.type) ||
        f.name.split('.')[f.name.split('.').length - 1] === 'jfif'
      ) {
        notAllowedExt = true
        indexArray.push(i)
      } else {
        const objectUrl = window.URL.createObjectURL(f)

        const [w, h] = await getImagePromise(objectUrl)

        if (w < 384 || h < 384) {
          imageSizeTooSmall = true
          indexArray.push(i)
        }

        if (mannequinMode && (w / h > IMAGE_RATIO || h / w > IMAGE_RATIO)) {
          imageRatioTooLarge = true
          indexArray.push(i)
        }

        URL.revokeObjectURL(objectUrl)
      }
    })

    await Promise.all(promises).catch(err => {
      console.log(err)
    })

    indexToRemove = [...new Set(indexArray)]

    // 앞에서 지울 경우 인덱스가 틀어져 오류 발생
    indexToRemove.sort((a, b) => b - a)

    for (const index of indexToRemove) {
      const elements = uploadFiles.splice(index, 1)
      removedImg.push(...elements)
    }

    const displayNotallowedImageURL = removedImg
      .filter(f => f.type.includes('image/'))
      .map(f => URL.createObjectURL(f))

    const revokeFunc = () => {
      displayNotallowedImageURL.forEach(url => URL.revokeObjectURL(url))
    }

    if (imageSizeTooSmall || imageRatioTooLarge || notAllowedExt) {
      alertModal(
        displayNotallowedImageURL,
        imageSizeTooSmall,
        imageRatioTooLarge,
        notAllowedExt,
        revokeFunc
      )
    }

    // if (uploadFiles.length > uploadImglimit) {
    //   uploadFiles = Array.prototype.slice.call(e.target.files, 0, uploadImglimit)
    // }

    // inputRef.current.value = ''

    // 추후 변경하자..

    if (!uploadFiles.length) return

    if (editOnly) {
      uploadFiles = uploadFiles.splice(0, 1)

      const image = await imgResizeAndBinary(uploadFiles)

      setFiles([{ file: image, url: URL.createObjectURL(image) }])
      handleGetNpy(image, 'fine')
      setKeepShowingConfig(keepShowingOn)
      return
    }

    if (bannerMode) {
      uploadFiles = uploadFiles.splice(0, 1)

      const image = await imgResizeAndBinary(uploadFiles)

      setFiles([{ file: image, url: URL.createObjectURL(image) }])
      setKeepShowingConfig(keepShowingOn)
      // handleGetNpy(image, editOnly)
      return
    }

    if (mannequinMode) {
      uploadFiles = uploadFiles.splice(0, 1)

      const image = await imgResizeAndBinary(uploadFiles)

      setFiles([{ file: image, url: URL.createObjectURL(image) }])
      setKeepShowingConfig(keepShowingOn)
      handleGetNpy(image)

      // if (uploadOpen === false) {
      //   setUploadOpen(true)
      // }
    } else {
      if (files.length + portfolioArtwork.length >= portfolioArtworklimit) {
        showConfirm({
          alertOnly: true,
          content: t('upload_dialog.warning_5'),
        })
      }

      if (files.length + uploadFiles.length > uploadImglimit) {
        showConfirm({
          alertOnly: true,
          content: (
            <Typography>한 번에 최대 {uploadImglimit}개의 이미지만 업로드 됩니다.</Typography>
          ),
        })
      }

      setFiles(prev => {
        const prevCopy = [...prev]

        const newImages = Array.from(uploadFiles).map(file => ({
          url: URL.createObjectURL(file),
          file,
        }))

        prevCopy.push(...newImages)

        if (
          prevCopy.length > uploadImglimit ||
          prevCopy.length + portfolioArtwork.length > portfolioArtworklimit
        ) {
          return prevCopy.slice(
            0,
            Math.min(uploadImglimit, Math.max(portfolioArtworklimit - portfolioArtwork.length, 0))
          )
        }

        return prevCopy
      })
      setKeepShowingConfig(keepShowingOn)
    }
  }

  return checkImage
}

export const useUploadHook = (atom = uploadFilesAndUrlAtom) => {
  const checkImage = useCheckImage()
  const [files, setFiles] = useRecoilState(atom)

  const editUploadFile = async (
    url,
    mode = { mannequinMode: false, editOnly: false, keepShowingOn: true }
  ) => {
    const uploadedImgURL = getS3ImageSrc(url)
    const Imagefile = await imageUrlToFile(uploadedImgURL)
    // console.log(Imagefile)

    // console.log(URL.createObjectURL(Imagefile))
    checkImage([Imagefile], files, setFiles, mode.mannequinMode, mode.editOnly, mode.keepShowingOn)
  }

  return { editUploadFile }
}

export const useCheckImageForGuest = (uploadImglimit = 1) => {
  const alertModal = useAlertModal()
  const { showConfirm } = useConfirm()
  const { t, i18n } = useTranslation()

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  // 이걸로 Config 계속 오픈할 예정
  const [keepShowingConfig, setKeepShowingConfig] = useRecoilState(keepShowingConfigAtom)

  const portfolioArtwork = useRecoilValue(portfolioArtworkAtom)

  const handleGetNpy = useHandleGetNpy()

  const portfolioArtworklimit = 20

  const checkImage = async (
    uploadFiles,
    files,
    setFiles,
    mannequinMode = false,
    editOnly = false,
    keepShowingOn = true
  ) => {
    // const uploadFiles = Array.prototype.slice.call(uploadfiles)

    // uploadFiles = uploadFiles.filter(
    //   f => f.name.split('.')[f.name.split('.').length - 1] !== 'jfif'
    // )

    let imageSizeTooSmall = false
    let imageRatioTooLarge = false
    let notAllowedExt = false

    let indexToRemove = []
    const indexArray = []
    const removedImg = []

    const promises = uploadFiles.map(async (f, i) => {
      if (
        !allowedTypes.includes(f.type) ||
        f.name.split('.')[f.name.split('.').length - 1] === 'jfif'
      ) {
        notAllowedExt = true
        indexArray.push(i)
      } else {
        const objectUrl = window.URL.createObjectURL(f)

        const [w, h] = await getImagePromise(objectUrl)

        if (w < 384 || h < 384) {
          imageSizeTooSmall = true
          indexArray.push(i)
        }

        if (mannequinMode && (w / h > IMAGE_RATIO || h / w > IMAGE_RATIO)) {
          imageRatioTooLarge = true
          indexArray.push(i)
        }

        URL.revokeObjectURL(objectUrl)
      }
    })

    await Promise.all(promises).catch(err => {
      console.log(err)
    })

    indexToRemove = [...new Set(indexArray)]

    // 앞에서 지울 경우 인덱스가 틀어져 오류 발생
    indexToRemove.sort((a, b) => b - a)

    for (const index of indexToRemove) {
      const elements = uploadFiles.splice(index, 1)
      removedImg.push(...elements)
    }

    const displayNotallowedImageURL = removedImg
      .filter(f => f.type.includes('image/'))
      .map(f => URL.createObjectURL(f))

    const revokeFunc = () => {
      displayNotallowedImageURL.forEach(url => URL.revokeObjectURL(url))
    }

    if (imageSizeTooSmall || imageRatioTooLarge || notAllowedExt) {
      alertModal(
        displayNotallowedImageURL,
        imageSizeTooSmall,
        imageRatioTooLarge,
        notAllowedExt,
        revokeFunc
      )
    }

    // if (uploadFiles.length > uploadImglimit) {
    //   uploadFiles = Array.prototype.slice.call(e.target.files, 0, uploadImglimit)
    // }

    // inputRef.current.value = ''

    // 추후 변경하자..

    if (!uploadFiles.length) return

    if (editOnly) {
      uploadFiles = uploadFiles.splice(0, 1)

      const image = await imgResizeAndBinary(uploadFiles)

      setFiles([{ file: image, url: URL.createObjectURL(image) }])
      handleGetNpy(image, 'fine')
      setKeepShowingConfig(keepShowingOn)
      return
    }

    if (mannequinMode) {
      uploadFiles = uploadFiles.splice(0, 1)

      const image = await imgResizeAndBinary(uploadFiles)

      setFiles([{ file: image, url: URL.createObjectURL(image) }])
      setKeepShowingConfig(keepShowingOn)
      handleGetNpy(image)

      // if (uploadOpen === false) {
      //   setUploadOpen(true)
      // }
    } else {
      if (files.length + portfolioArtwork.length >= portfolioArtworklimit) {
        showConfirm({
          alertOnly: true,
          content: t('upload_dialog.warning_5'),
        })
      }

      if (files.length + uploadFiles.length > uploadImglimit) {
        showConfirm({
          alertOnly: true,
          content: (
            <Typography>한 번에 최대 {uploadImglimit}개의 이미지만 업로드 됩니다.</Typography>
          ),
        })
      }

      setFiles(prev => {
        const prevCopy = [...prev]

        const newImages = Array.from(uploadFiles).map(file => ({
          url: URL.createObjectURL(file),
          file,
        }))

        prevCopy.push(...newImages)

        if (
          prevCopy.length > uploadImglimit ||
          prevCopy.length + portfolioArtwork.length > portfolioArtworklimit
        ) {
          return prevCopy.slice(
            0,
            Math.min(uploadImglimit, Math.max(portfolioArtworklimit - portfolioArtwork.length, 0))
          )
        }

        return prevCopy
      })
      setKeepShowingConfig(keepShowingOn)
    }
  }

  return checkImage
}
