import {
  Grid,
  Stack,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
import {
  CenterAlignStack,
  CenterAlignBox,
  TempLayoutDiv,
  RollingShowcase,
  GradationButton,
  StyledToggleButtonGroup,
  TwoImageSlider,
  RollingBanner,
  BestPracticeSection,
} from 'components'
import { userAtom, portfolioAtom, artworkDataAtom, promotionAnchorAtom, tutorialAtom } from 'atoms'
import { useRecoilValue, useRecoilState, useSetRecoilState, constSelector } from 'recoil'
import { showcaseImageSelector } from 'selector'
import { styled, useTheme } from '@mui/material/styles'
import * as config from 'config'
import { useEffect, useState } from 'react'
import { getAccessToken } from 'utils/cookie'
import MovingImg from './test/MovingImg'
import { apis } from 'apis'
import {
  useTransform,
  motion,
  useScroll,
  useMotionValueEvent,
  useAnimation,
  AnimatePresence,
} from 'framer-motion'
import { BlueCircleCheckIcon, RefreshIcon, SignUpArrowIcon } from 'theme/icon'
import { getS3ImageSrc, isKo, kFormatter, numberCommaFormatter } from 'utils/common'
import { Link, useFetcher, useNavigate } from 'react-router-dom'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useMediaQuery } from 'react-responsive'
import Marquee from 'react-fast-marquee'

// import 'swiper/css/pagination'
// import 'swiper/css/effect-coverflow'
// import 'swiper/css/effect-cards'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay, EffectCoverflow, EffectCards, Controller } from 'swiper'

import { setWith } from 'lodash'
import { PromotionPopup } from 'components/PromotionPopup'
import { bgRemovedStyle } from 'components/portfolio/ArtworkList'
import { StyledCircularProgress } from 'components/portfolio/ArtworkRow'
import { promtionWebBanners, promtionMobileBanners } from 'data.js'
import moment from 'moment'
import CountUp from 'react-countup'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider'
import { useInView } from 'react-intersection-observer'
import { StyledToggleButton } from 'components/CustomStyle'
import { PORTFOLIO_CATEGORY_DICT } from 'config'
import { useTranslation } from 'react-i18next'
import { English, getStaticResourceLanguage, Korean } from 'hooks/useLanguage'
import { MainStartButton, SolidColorReverseButton } from 'components/CustomButtons'

export default function Home() {
  const token = getAccessToken()

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'home', {})
    // ---------------------
  }, [])

  return <Home2309 />

  // if (!token) return <NonMemberHome />
  // else return <MemberHome />

  // return <MemberHome/>
}

const home2309Style = {
  width: '100%',

  '& .home-section': {
    width: '100%',
    pt: { lg: '14.4rem', xs: '7.2rem' },
    pb: { lg: '14.4rem', xs: '7.2rem' },

    '& .section-title-1': {
      textAlign: 'center',
      fontSize: { lg: '5rem', xs: '2.4rem' },
      fontWeight: 900,
      lineHeight: 1.3,
    },

    '&:nth-of-type(odd)': {
      background: 'white',
    },
  },

  '& .home-wrapper': {
    width: '100%',

    '& .home-section': {
      '&:nth-of-type(even)': {
        background: '#F8F8F8',
        // background: 'pink',
      },
      '&:nth-of-type(odd)': {
        // background: 'gold',

        background: 'white',
      },
    },
  },

  '& .section-title-1': {
    fontSize: { lg: '5rem', xs: '1.8rem' },
    fontWeight: 900,
    lineHeight: 1,
  },
  '& .section-subtitle-1': {
    fontSize: { lg: '2.8rem', xs: '1.3rem' },
    fontWeight: 500,
    marginTop: '1.2rem',
    lineHeight: 1.3,
  },
  '& .section-title-2': {
    textAlign: 'center',
    fontSize: { lg: '4rem', xs: '1.8rem' },
    fontWeight: 800,
    lineHeight: 1,
    mb: { lg: '1.6rem', xs: '0.9rem' },

    '&.mobile-align-left': {
      width: { lg: 'unset', xs: '32rem' },
      textAlign: { lg: 'center', xs: 'left' },
    },
  },
  '& .section-subtitle-2': {
    textAlign: 'center',
    fontSize: { lg: '2rem', xs: '1.3rem' },
    fontWeight: 400,
    lineHeight: 1.3,
    '&.mobile-align-left': {
      width: { lg: 'unset', xs: '32rem' },
      textAlign: { lg: 'center', xs: 'left' },
    },
  },
}

const sampleImages = [
  {
    sample: '/static/images/experience/cosmeticsV2/sample_cosmetics_1.png',
    category: 'cosmetics1',
    name: 'sunscreen',
    result: {
      nature: [
        '/static/images/experience/cosmeticsV2/concept1/main_cosmetics1_draphed_1.jpg',
        '/static/images/experience/cosmeticsV2/concept1/main_cosmetics1_draphed_2.jpg',
        '/static/images/experience/cosmeticsV2/concept1/main_cosmetics1_draphed_3.jpg',
        '/static/images/experience/cosmeticsV2/concept1/main_cosmetics1_draphed_4.jpg',
      ],
      studio: [
        '/static/images/experience/cosmeticsV2/concept2/main_cosmetics2_draphed_5.jpg',
        '/static/images/experience/cosmeticsV2/concept2/main_cosmetics2_draphed_6.jpg',
        '/static/images/experience/cosmeticsV2/concept2/main_cosmetics2_draphed_7.jpg',
        '/static/images/experience/cosmeticsV2/concept2/main_cosmetics2_draphed_8.jpg',
      ],
    },
  },
  {
    sample: '/static/images/experience/cosmeticsV2/sample_cosmetics_3.png',
    category: 'cosmetics2',
    name: 'pink_essence',
    result: {
      cliff: [
        '/static/images/experience/cosmeticsV2/concept3/main_cosmetics3_draphed_1.jpg',
        '/static/images/experience/cosmeticsV2/concept3/main_cosmetics3_draphed_2.jpg',
        '/static/images/experience/cosmeticsV2/concept3/main_cosmetics3_draphed_3.jpg',
        '/static/images/experience/cosmeticsV2/concept3/main_cosmetics3_draphed_4.jpg',
      ],
      maple: [
        '/static/images/experience/cosmeticsV2/concept4/main_cosmetics4_draphed_1.jpg',
        '/static/images/experience/cosmeticsV2/concept4/main_cosmetics4_draphed_2.jpg',
        '/static/images/experience/cosmeticsV2/concept4/main_cosmetics4_draphed_3.jpg',
        '/static/images/experience/cosmeticsV2/concept4/main_cosmetics4_draphed_4.jpg',
      ],
    },
  },
  {
    sample: '/static/images/experience/foodV2/sample_food_1.png',
    category: 'food',
    name: 'honey_bread',
    result: {
      cliff: [
        '/static/images/experience/foodV2/concept1/main_food1_draphed_1.jpg',
        '/static/images/experience/foodV2/concept1/main_food1_draphed_2.jpg',
        '/static/images/experience/foodV2/concept1/main_food1_draphed_3.jpg',
        '/static/images/experience/foodV2/concept1/main_food1_draphed_4.jpg',
      ],
      swimmingPool: [
        '/static/images/experience/foodV2/concept2/main_food2_draphed_1.jpg',
        '/static/images/experience/foodV2/concept2/main_food2_draphed_2.jpg',
        '/static/images/experience/foodV2/concept2/main_food2_draphed_3.jpg',
        '/static/images/experience/foodV2/concept2/main_food2_draphed_4.jpg',
      ],
    },
  },
  {
    sample: '/static/images/experience/humanV2/sample_human_1.png',
    category: 'person',
    name: 'woman',
    result: {
      simple: [
        '/static/images/experience/humanV2/concept1/main_human1_draphed_1.jpg',
        '/static/images/experience/humanV2/concept1/main_human1_draphed_2.jpg',
        '/static/images/experience/humanV2/concept1/main_human1_draphed_3.jpg',
        '/static/images/experience/humanV2/concept1/main_human1_draphed_4.jpg',
      ],
      naturalLight: [
        '/static/images/experience/humanV2/concept2/main_human2_draphed_1.jpg',
        '/static/images/experience/humanV2/concept2/main_human2_draphed_2.jpg',
        '/static/images/experience/humanV2/concept2/main_human2_draphed_3.jpg',
        '/static/images/experience/humanV2/concept2/main_human2_draphed_4.jpg',
      ],
    },
  },
]

const conceptImages = {
  cosmetics1: {
    nature: '/static/images/experience/cosmeticsV2/concept1/main_cosmetics1_origin.jpg',
    studio: '/static/images/experience/cosmeticsV2/concept2/main_cosmetics2_origin.jpg',
  },
  cosmetics2: {
    cliff: '/static/images/experience/cosmeticsV2/concept3/main_cosmetics3_origin.jpg',
    maple: '/static/images/experience/cosmeticsV2/concept4/main_cosmetics4_origin.jpg',
  },

  food: {
    cliff: '/static/images/experience/foodV2/concept1/main_food1_origin.jpg',
    swimmingPool: '/static/images/experience/foodV2/concept2/main_food2_origin.jpg',
  },

  person: {
    simple: '/static/images/experience/humanV2/concept1/main_human1_origin.jpg',
    naturalLight: '/static/images/experience/humanV2/concept2/main_human2_origin.png',
  },
}

const resultImages = []

function Home2309() {
  const navigate = useNavigate()
  const isMobile = useMobileMediaQuery()

  const [videoCategory, setVideoCategory] = useState('retry')
  const user = useRecoilValue(userAtom)
  const [ref1, inView1] = useInView()

  const token = getAccessToken()

  const { t, i18n } = useTranslation()

  const videoCategoryChange = (e, newVideoCategory) => {
    if (newVideoCategory !== null) {
      setVideoCategory(newVideoCategory)
    }

    // ----- GA4 event -----
    const name =
      newVideoCategory === 'retry'
        ? 'bg_regen'
        : newVideoCategory === 'resize'
        ? 'edit_size'
        : newVideoCategory === 'magicBrush'
        ? 'edit_nukki'
        : ''
    window.gtag('event', 'home_button', { menu: name })
    // ---------------------
  }

  useEffect(() => {
    if (user.is_admin) {
      apis.stat.getStat().then(response => {
        const ts = moment().local().format('YYYY년 MM월 DD일 HH:mm:ss ')
      })
    }
  }, [])

  const triggerGAEvent = () => {
    // ----- GA4 event -----
    window.gtag('event', 'home_sign_up', {})
    // ---------------------
  }

  return (
    <>
      <AnimatePresence>
        <Stack sx={{ background: isKo(i18n) ? '#F8F8F8' : '#FFF', ...home2309Style }}>
          <BestPracticeSection />

          <CenterAlignStack className="home-wrapper">
            <CenterAlignStack className="home-section" sx={{ position: 'relative' }}>
              <Typography className="section-title-1">{t('home.title_1')}</Typography>
              <Typography
                sx={{
                  fontSize: { lg: '2.8rem', xs: '1.6rem' },
                  fontWeight: 500,
                  lineHeight: 1.2,
                  mt: { lg: '2rem', xs: '1rem' },
                  textAlign: 'center',
                }}
              >
                {t('home.subtitle_1_1_a')} {isMobile && <br />} {t('home.subtitle_1_1_b')}
              </Typography>
              <Box sx={{ mt: { lg: '4rem', xs: '2rem' } }}>
                <BeforeAfterSlider hideText />
              </Box>

              <MainStartButton type="black-blue" sx={{ mt: { lg: '6.8rem', xs: '0.7rem' } }} />
            </CenterAlignStack>
            <CenterAlignStack className="home-section " sx={{ pb: { xs: '6rem !important' } }}>
              <Typography className="section-title-2 mobile-align-left">
                {t('home.title_2')}
              </Typography>

              <Typography className="section-subtitle-2 mobile-align-left">
                {t('home.subtitle_2_1_a')}
                {isMobile && <br />}
                {t('home.subtitle_2_1_b')}

                {isMobile ? ' ' : <br />}

                {t('home.subtitle_2_2_a')}
                {isMobile && <br />}
                {t('home.subtitle_2_2_b')}
              </Typography>

              <UsingDraph />
            </CenterAlignStack>

            <CenterAlignStack
              className="home-section"
              sx={{ minHeight: { lg: '57.8rem', xs: '51.3rem' } }}
            >
              <Typography className="section-title-2 mobile-align-left">
                {t('home.title3')}
              </Typography>

              <Typography className="section-subtitle-2 mobile-align-left">
                {t('home.subtitle_3_1_a')}
                {isMobile ? <br /> : ''}
                {t('home.subtitle_3_1_b')}
              </Typography>
              <Box>
                <PresetSamples />
              </Box>
            </CenterAlignStack>

            <CenterAlignStack className="home-section">
              <Typography className="section-title-2 mobile-align-left">
                {t('home.title4')}
              </Typography>
              <Box sx={{ position: 'relative' }}>
                <Typography className="section-subtitle-2 mobile-align-left" sx={{}}>
                  {t('home.subtitle_4_1_a')}
                  <br />
                  <span
                    style={{
                      fontWeight: 700,
                      position: 'relative',
                      zIndex: 1,
                      backgroundImage:
                        'linear-gradient(to top, rgba(0, 232, 185, 0.20) 36%, rgba(0, 0, 0, 0) 36%)',
                    }}
                  >
                    {t('home.subtitle_4_1_b')}
                  </span>{' '}
                  {t('home.subtitle_4_1_c')}
                </Typography>
                {/* <Box
                  sx={{
                    position: 'absolute',
                    bottom: '-0.6rem',
                    backgroundColor: 'rgba(0, 232, 185, 0.20)',
                    width: { lg: '33.6rem', xs: '21.8rem' },
                    height: { lg: '2.2rem', xs: '1.7rem' },
                  }}
                ></Box> */}
              </Box>

              <ClickArea />
            </CenterAlignStack>

            <CenterAlignStack
              className="home-section"
              sx={{
                minHeight: { lg: '100rem', xs: '75.6rem' },
              }}
            >
              <Typography className="section-title-2 mobile-align-left">
                {t('home.title5')}
              </Typography>
              <Typography className="section-subtitle-2 mobile-align-left">
                {t('home.subtitle_5_1_a')}
                {isMobile ? <br /> : ' '}
                {t('home.subtitle_5_1_b')}
              </Typography>

              <Box sx={{ mt: { lg: '5.8rem', xs: '2.9rem' } }}>
                <StyledToggleButtonGroup
                  value={videoCategory}
                  exclusive
                  onChange={videoCategoryChange}
                >
                  <StyledToggleButton
                    value="retry"
                    sx={{
                      borderRadius: '4rem !important',
                      fontSize: {
                        lg: isKo(i18n) ? '2.2rem' : '1.7rem',
                        xs: isKo(i18n) ? '1.2rem' : '1.2rem',
                      },
                      lineHeight: 'normal',
                      padding: { lg: isKo(i18n) ? '1.1rem' : '2.1rem' },
                    }}
                  >
                    {t('home.ai_bg_create')}
                  </StyledToggleButton>
                  <StyledToggleButton
                    value="resize"
                    sx={{
                      padding: { lg: isKo(i18n) ? '1.1rem' : '2.1rem' },
                      borderRadius: '4rem !important',
                      fontSize: isKo(i18n) ? '2.2rem' : '1.7rem',
                      lineHeight: 'normal',
                    }}
                  >
                    {t('home.resize_product_image')}
                  </StyledToggleButton>
                  <StyledToggleButton
                    sx={{
                      borderRadius: '4rem !important',
                      fontSize: isKo(i18n) ? '2.2rem' : '1.7rem',
                      lineHeight: 'normal',
                    }}
                    value="magicBrush"
                  >
                    {t('home.removebg_edit')}
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              </Box>
              <Box sx={{ mt: '4.5rem' }}>
                <AdvancedFeatures videoCategory={videoCategory} />
              </Box>
            </CenterAlignStack>
            {/* 
            <Korean>
              <Stack
                className="home-section"
                sx={{
                  alignItems: 'center',

                  background: theme => `${theme.palette.common.black} !important`,
                  // background:
                  //   'linear-gradient(180deg, rgba(248, 248, 248, 1.00) 90%, rgba(0, 0, 0, 0.10) 100%) !important',
                  pb: '0rem !important',
                  position: 'relative',
                  px: { xs: '2rem' },
                  '& .text': {
                    color: 'white',
                  },
                }}
              >
                <Typography className="text section-title-2" textAlign="center">
                  {t('home.title_6')}
                </Typography>
                <Typography className="text section-subtitle-2">
                  {t('home.subtitle_6_1_a')}
                  <br />
                  {t('home.subtitle_6_1_b')}
                </Typography>
                <Link
                  to="https://www.draph.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  onClick={() => {
                    // ----- GA4 event -----
                    window.gtag('event', 'home_button', { menu: 'learn_more_company' })
                    // ---------------------
                  }}
                >
                  <SolidColorReverseButton
                    variant="contained"
                    sx={{
                      mt: { lg: '3rem', xs: '2.5rem' },
                      boxShadow: 'none',
                      width: { lg: '40rem', xs: '32rem' },
                      lineHeight: 1.2,
                      py: '1rem',

                      color: theme => theme.palette.common.black,
                      background: 'white',
                      '&:hover': {
                        color: theme => theme.palette.draph.newblue,
                        background: '#E3ECFF',
                      },
                    }}
                  >
                    {t('button.learn_more')}
                  </SolidColorReverseButton>
                </Link>

                <CenterAlignStack
                  sx={{
                    width: '100vw',
                    position: 'relative',
                    '& .shadow': {
                      position: 'absolute',
                      zIndex: 5,
                      bottom: 0,
                      width: '100%',
                      height: { lg: '19.3rem' },
                      background:
                        'linear-gradient(180deg, rgba(0, 0, 0, 0) 61.35%, rgba(0, 0, 0, 0.2) 100%)',
                    },
                  }}
                >
                  <PhoneCarousel />
                  <Box className="shadow"></Box>
                </CenterAlignStack>
              </Stack>
            </Korean> */}

            <Stack
              className="home-section"
              sx={{
                zIndex: 1, // 위 섹션 하단부분(스마트폰 아래부분)을 가려주기 위해서
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                // px: { xs: '2rem' },
                '& .title': {
                  fontSize: { lg: '4rem', xs: '1.8rem' },
                  fontWeight: { lg: 800, xs: 800 },
                  lineHeight: 1.3,
                  textAlign: 'center',
                },
              }}
            >
              <Korean>
                <Typography className="title">{t('home.title_8_a')}</Typography>
                <Typography className="title" sx={{ mb: { lg: '5.2rem', xs: '2rem' } }}>
                  {t('home.title_8_b')}
                </Typography>
              </Korean>
              <English>
                <Typography
                  // className="title"
                  sx={{
                    fontSize: { lg: '3.3rem', xs: '1.4rem' },
                    fontWeight: 600,
                    textAlign: 'center',
                    mb: { lg: '1.5rem', xs: '1rem' },
                  }}
                >
                  Draph Art has been chosen by{' '}
                  <Mobile>
                    <br />
                  </Mobile>
                  multiple leading companies in Korea.
                </Typography>
                <Typography
                  sx={{
                    fontSize: { lg: '4rem', xs: '1.6rem' },
                    fontWeight: 800,
                    textAlign: 'center',
                    mb: { lg: '5.2rem', xs: '2rem' },
                  }}
                >
                  Use Draph Art’s API to conveniently integrate <br /> our beloved service to your
                  channel!
                </Typography>
              </English>

              <EnterpriseCustomerLogoMarquee />

              <Button
                variant="contained"
                sx={{
                  mt: { lg: '6.6rem', xs: '2.4rem' },
                  px: { lg: '5.2rem', xs: '2.2rem' },
                  py: { lg: '0.6rem', xs: '0.4rem' },
                  fontSize: { lg: '2.8rem', xs: '1.6rem' },
                  fontWeight: { lg: 800, xs: 800 },
                  borderRadius: '6rem',
                }}
                onClick={() => {
                  // ----- GA4 event -----
                  window.gtag('event', 'home_button', { menu: 'api' })
                  // ---------------------
                  navigate('/api_info')
                }}
              >
                {t('home.api_button')}
              </Button>
            </Stack>

            <CenterAlignStack
              className="home-section"
              sx={{
                py: { lg: '10.4rem !important', xs: '6rem !important' },
                zIndex: 1,
                background: theme => `${theme.palette.common.black} !important`,
                '& .text': { color: 'white' },
              }}
            >
              <Typography className="text section-title-2">{t('home.title_7')}</Typography>
              <Typography className="text section-subtitle-2">{t('home.subtitle_7_1')}</Typography>

              <MainStartButton sx={{ mt: { lg: '4rem', xs: '2rem' } }} />
            </CenterAlignStack>

            {/* {isMobile ? (
              !inView1 && (
                <Box
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  sx={{ position: 'sticky', bottom: 75, zIndex: 100 }}
                >
                  <MainStartButton />
                </Box>
              )
            ) : (
              <MainStartButton sx={{ position: 'sticky', bottom: 30, zIndex: 100 }} />
            )} */}

            {/* <div
              style={{
                width: '100%',
                height: isMobile ? '4rem' : '8.5rem',
                backgroundColor: isKo(i18n) ? '#F8F8F8' : '#FFF',
              }}
            ></div> */}
          </CenterAlignStack>
          {/* <PromotionPopup days={7} /> */}
        </Stack>
      </AnimatePresence>
    </>
  )
}

export function EnterpriseCustomerLogoMarquee({ src = false, sx = {} }) {
  const { t, i18n } = useTranslation()
  return (
    <Marquee speed={70}>
      <Box
        sx={{
          display: 'flex',
          height: { lg: '9.6rem', xs: '5.2rem' },
          pr: { lg: '4.8rem', xs: '2rem' },
          gap: { lg: '4.8rem', xs: '2rem' },
          ...sx,
        }}
      >
        <img
          src={
            src ||
            `/static/images/etc/logo_group_large_${getStaticResourceLanguage(i18n.language)}.png`
          }
          style={{ height: '100%' }}
        />
        <img
          src={
            src ||
            `/static/images/etc/logo_group_large_${getStaticResourceLanguage(i18n.language)}.png`
          }
          style={{ height: '100%' }}
        />
        <img
          src={
            src ||
            `/static/images/etc/logo_group_large_${getStaticResourceLanguage(i18n.language)}.png`
          }
          style={{ height: '100%' }}
        />
      </Box>
    </Marquee>
  )
}

export function ExperienceSection({ promotion }) {
  const images = [
    {
      title: '사람',
      thumbnail: '/static/images/experience/human/human_original.jpg',
      draphImg: [
        '/static/images/experience/human/human_draphed_1.jpg',
        '/static/images/experience/human/human_draphed_2.jpg',
        '/static/images/experience/human/human_draphed_3.jpg',
        '/static/images/experience/human/human_draphed_4.jpg',
        '/static/images/experience/human/human_draphed_5.jpg',
        '/static/images/experience/human/human_draphed_6.jpg',
        '/static/images/experience/human/human_draphed_7.jpg',
        '/static/images/experience/human/human_draphed_8.jpg',
        '/static/images/experience/human/human_draphed_9.jpg',
        '/static/images/experience/human/human_draphed_10.jpg',
        '/static/images/experience/human/human_draphed_11.jpg',
        '/static/images/experience/human/human_draphed_12.jpg',
        '/static/images/experience/human/human_draphed_13.jpg',
        '/static/images/experience/human/human_draphed_14.jpg',
        '/static/images/experience/human/human_draphed_15.jpg',
        '/static/images/experience/human/human_draphed_16.jpg',
        '/static/images/experience/human/human_draphed_17.jpg',
        '/static/images/experience/human/human_draphed_18.jpg',
        '/static/images/experience/human/human_draphed_19.jpg',
        '/static/images/experience/human/human_draphed_20.jpg',
      ],
    },
    {
      title: '식품',
      thumbnail: '/static/images/experience/food/food_original.jpeg',
      draphImg: [
        '/static/images/experience/food/food_draphed_1.jpg',
        '/static/images/experience/food/food_draphed_2.jpg',
        '/static/images/experience/food/food_draphed_3.jpg',
        '/static/images/experience/food/food_draphed_4.jpg',
        '/static/images/experience/food/food_draphed_5.jpg',
        '/static/images/experience/food/food_draphed_6.jpg',
        '/static/images/experience/food/food_draphed_7.jpg',
        '/static/images/experience/food/food_draphed_8.jpg',
        '/static/images/experience/food/food_draphed_9.jpg',
        '/static/images/experience/food/food_draphed_10.jpg',
      ],
    },
    {
      title: '화장품',
      thumbnail: '/static/images/experience/cosmetics/cosmetics_original.jpeg',
      draphImg: [
        '/static/images/experience/cosmetics/cosmetics_draphed_1.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_2.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_3.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_4.png',
        '/static/images/experience/cosmetics/cosmetics_draphed_5.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_6.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_7.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_8.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_9.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_10.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_11.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_12.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_13.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_14.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_15.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_16.jpg',
        '/static/images/experience/cosmetics/cosmetics_draphed_17.jpg',
      ],
    },
  ]

  const [selected, setSelected] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [randomImages, setRandomImages] = useState([])
  const isMobile = useMobileMediaQuery()

  const theme = useTheme()

  const clickHandle = idx => {
    setSelected(idx)
    setProgress(0)
    setLoading(true)
  }

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 50,
      transition: { ease: [0.78, 0.14, 0.15, 0.86] },
    },
    show: {
      opacity: 1,
      y: 0,
      transition: { ease: [0.78, 0.14, 0.15, 0.86] },
    },
  }
  const controls = useAnimation()

  useEffect(() => {
    setRandomImages(getRandomElements(selected, 4))
  }, [selected, progress])

  const getRandomElements = (idx, count) => {
    if (idx === false) return
    const result = []
    const len = images[idx].draphImg.length

    // 배열에서 중복 없이 요소 뽑기
    while (result.length < count) {
      const randomIndex = Math.floor(Math.random() * len)
      const randomElement = images[idx].draphImg[randomIndex]

      // 이미 뽑은 요소인지 확인
      if (!result.includes(randomElement)) {
        result.push(randomElement)
      }
    }

    return result
  }

  return (
    <>
      <CenterAlignStack
        sx={{ width: '100%', margin: { lg: '6.8rem 0 7.0rem 0', xs: '5.2rem 0 5.7rem 0' } }}
      >
        <Typography
          sx={{
            fontSize: { lg: '3.2rem', xs: '2rem' },
            fontWeight: 800,
            ...(promotion && { fontFamily: 'GmarketSans' }),
          }}
        >
          드랩아트를 체험해보세요.
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: '2.4rem', xs: '1.6rem' },
            fontWeight: 500,
            ...(promotion && { fontFamily: 'GmarketSans' }),
          }}
        >
          예시 이미지를 선택해주세요.
        </Typography>
        <CenterAlignBox
          sx={{ mt: { lg: '3.8rem', xs: '2.8rem' }, gap: { lg: '2rem ', xs: '1.6rem' } }}
        >
          {images.map((img, idx) => {
            return (
              <CenterAlignStack key={idx} sx={{ gap: { lg: '2rem', xs: '0.7rem' } }}>
                <Typography
                  sx={{
                    fontSize: { lg: '2.4rem', xs: '1.4rem' },
                    fontWeight: 600,
                    ...(promotion && { fontFamily: 'GmarketSans', fontWeight: 400 }),
                  }}
                >
                  {img.title}
                </Typography>

                <img
                  src={img.thumbnail}
                  onClick={() => clickHandle(idx)}
                  style={{
                    width: isMobile ? '8rem' : '20rem',
                    height: isMobile ? '8rem' : '20rem',
                    borderRadius: isMobile ? '10px' : '20px',
                    cursor: 'pointer',
                    objectFit: 'cover',
                    border:
                      selected === idx
                        ? isMobile
                          ? `3px solid ${theme.palette.draph.blue}`
                          : `5px solid ${theme.palette.draph.blue}`
                        : null,
                  }}
                />
              </CenterAlignStack>
            )
          })}
        </CenterAlignBox>
        {selected !== false && loading && progress < totalStage + 2 && (
          <CenterAlignStack sx={{ mt: '5rem' }}>
            <ProgressLayout progress={progress} setProgress={setProgress} />
          </CenterAlignStack>
        )}
        {progress === totalStage + 2 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Desktop>
              <CenterAlignBox sx={{ mt: '8.8rem' }} gap="2.4rem">
                {randomImages.map((img, idx) => {
                  return (
                    <CenterAlignStack key={idx}>
                      <img
                        src={img}
                        style={{
                          width: '24rem',
                          height: '24rem',
                          objectFit: 'cover',
                        }}
                      />
                    </CenterAlignStack>
                  )
                })}
              </CenterAlignBox>
            </Desktop>
            <Mobile>
              <CenterAlignStack sx={{ mt: '4.6rem' }} gap="2.4rem">
                <Grid container>
                  {randomImages.map((img, idx) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        key={idx}
                        sx={{
                          display: 'flex',
                          p: '0.6rem',
                          justifyContent: idx % 2 === 0 ? 'right' : 'left',
                        }}
                      >
                        <img
                          src={img}
                          style={{
                            width: '13.6rem',
                            height: '13.6rem',
                            objectFit: 'cover',
                          }}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </CenterAlignStack>
            </Mobile>
          </motion.div>
        )}{' '}
      </CenterAlignStack>
    </>
  )
}

function ThemeSection() {
  const user = useRecoilValue(userAtom)
  return (
    <CenterAlignStack
      spacing={5}
      sx={{
        width: '100%',
        p: 5,
        backgroundColor: 'whitesmoke',
      }}
    >
      <CenterAlignBox sx={{ width: '100%', overflow: 'scroll', py: 3, border: 1 }}>
        <Stack direction="row">
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
          <TempLayoutDiv width="15rem">테마</TempLayoutDiv>
        </Stack>
      </CenterAlignBox>

      <CenterAlignStack>
        <h2>봄테마</h2>
      </CenterAlignStack>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={{ xs: 2, lg: 5 }} columns={{ xs: 4, lg: 12 }}>
          <Grid item xs={4} lg={4}>
            <TempLayoutDiv width="100%" height="40rem">
              테마
            </TempLayoutDiv>
          </Grid>
          <Grid item xs={4} lg={4}>
            <TempLayoutDiv width="100%" height="40rem">
              테마
            </TempLayoutDiv>
          </Grid>
          <Grid item xs={4} lg={4}>
            <TempLayoutDiv width="100%" height="40rem">
              테마
            </TempLayoutDiv>
          </Grid>
        </Grid>
      </Box>
    </CenterAlignStack>
  )
}

function ShowcaseSection({ token }) {
  const user = useRecoilValue(userAtom)
  const showcaseImage = useRecoilValue(showcaseImageSelector)
  const [items, setItems] = useState([])
  const theme = useTheme()
  const MAX = 10

  useEffect(() => {
    if (token) {
      const showcastItems = showcaseImage?.map(a => ({
        portfolioId: a.portfolio_id,
        artworkId: a.id,
        image: a.pieces.path,
      }))

      setItems(showcastItems)
    }
  }, [showcaseImage])

  return (
    <>
      <Desktop>
        <CenterAlignStack
          width="100%"
          spacing={3}
          sx={{ pt: '5rem', pb: '8.2rem', backgroundColor: theme.palette.common.lightgray }}
        >
          <Typography
            sx={{
              pb: '1.3rem',
              fontSize: '4rem',
              fontWeight: 900,
              color: theme.palette.common.black,
            }}
          >
            SHOWCASE
          </Typography>

          <RollingShowcase items={items} />
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack
          width="100%"
          spacing={3}
          sx={{ pt: '5rem', pb: '8rem', backgroundColor: theme.palette.common.lightgray }}
        >
          <Typography sx={{ fontSize: '2rem', fontWeight: 900, color: theme.palette.common.black }}>
            SHOWCASE
          </Typography>

          <RollingShowcase items={items} />
        </CenterAlignStack>
      </Mobile>
    </>
  )
}

const totalStage = 3
const t0 = (2 * Math.PI) / (totalStage + 1)
const d0 = 360 / (totalStage + 1)

const ARTWORK_IN_PROGRESS_STATUS = ['uploaded', 'uploaded', 'analysis', 'prep']

function getRandomNumber() {
  return Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000
}

function ProgressLayout({ progress, setProgress }) {
  const { t, i18n } = useTranslation()
  const status = progress === 4 ? 'done' : ARTWORK_IN_PROGRESS_STATUS[progress]
  const isMobile = useMobileMediaQuery()
  const r = isMobile ? 6 : 10 // 원형 progress 의 반지름 길이 (단위는 rem)

  useEffect(() => {
    if (progress < totalStage + 2) {
      const timer = setInterval(
        () => {
          setProgress(prevProgress => prevProgress + 1)
        },
        progress === 4 ? 800 : getRandomNumber()
      )

      return () => {
        clearInterval(timer)
      }
    }
  }, [progress])

  return (
    <Box
      sx={{
        position: 'relative',
        width: `${2 * r}rem`,
        height: `${2 * r}rem`,
      }}
    >
      {/* 두꺼운 회색 바탕 원 */}
      <Box sx={{ position: 'absolute' }}>
        <CircularProgress
          variant="determinate"
          value={100}
          thickness={2.7}
          size={`${2 * r}rem`}
          sx={{
            color: '#EFEFEF',
          }}
        />
      </Box>

      <Typography
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: `${2 * r}rem`,
          textAlign: 'center',
        }}
        fontSize={{ lg: '1.8rem', xs: '1.5rem' }}
        fontWeight={700}
        color="#000000"
      >
        {progress >= 0 &&
          t(`configOrDict.ARTWORK_STATUS_DICT.${config.ARTWORK_STATUS_DICT[status]?.text}`)}
      </Typography>

      {progress >= 0 && (
        <>
          {/* 돌아가는 애니메이션 원 */}

          <Box
            sx={{
              position: 'absolute',
              transform: 'scaleX(-1)',
            }}
          >
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                // color: 'gray',
                // opacity: '0.5',
                color: '#C2D4FF',
                animationDuration: '1500ms',
              }}
              size={`${2 * r}rem`}
              thickness={1}
            />
          </Box>

          {/* 실제 progress 표시 원 */}

          <StyledCircularProgress
            variant="determinate"
            value={progress * 25}
            thickness={1}
            size={`${2 * r}rem`}
          />

          {config.ARTWORK_STATUS_DICT[status]?.progressIdx > 0 && (
            <Box
              sx={{
                position: 'relative',
                borderRadius: '50%',
                transform: `rotate(-${config.ARTWORK_STATUS_DICT[status]?.progressIdx * d0}deg)`,
                transition: 'transform 0.3s linear',
              }}
              width={`${2 * r}rem`}
              height={`${2 * r}rem`}
            >
              <CenterAlignBox
                sx={{
                  width: isMobile ? '3.8rem' : '4.5rem',
                  height: isMobile ? '3.8rem' : '4.5rem',
                  position: 'absolute',
                  borderRadius: '50%',
                  alignItems: 'center',
                  backgroundColor: theme => theme.palette.draph.blue,
                  transform: `rotate(${config.ARTWORK_STATUS_DICT[status]?.progressIdx * d0}deg)`,
                  left: `${r - 4.5 / 2}rem`,
                  top: `-${4.5 / 2}rem`,
                  transition: { lg: 'transform 0.3s linear', xs: 'transform 0.6s linear' },

                  boxShadow: '2px 2px 10px rgba(77, 128, 255, 0.4)',

                  // left: `${r + indexPosition[0] - 4.5 / 2}rem`,
                  // top: `${r - indexPosition[1] - 4.5 / 2}rem`,
                  // transition: 'left 1s, top 1s',
                }}
              >
                <Typography
                  sx={{ fontSize: { lg: '2rem', xs: '1.4rem' }, fontWeight: 800 }}
                  color="#FFFFFF"
                >
                  {config.ARTWORK_STATUS_DICT[status]?.progressIdx}
                </Typography>
              </CenterAlignBox>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export const mobileWidthVW = '85vw'

export function BeforeAfterSlider({ hideText }) {
  const initialPosition = 50
  const [pos, setPos] = useState(initialPosition)
  const [txt, setTxt] = useState(0)

  const [swiper, setSwiper] = useState(null)

  const [beforeImage, setBeforeImage] = useState('/static/images/main/mac_default.png')
  const [afterImage, setAfterImage] = useState('/static/images/main/mac_default.png')

  const isMobile = useMobileMediaQuery()

  // TODO 예시이미지 수정
  const imageSet = [
    ['/static/images/main/mac_cosmetics_origin.png', '/static/images/main/mac_cosmetics_draph.png'],
    ['/static/images/main/mac_food_origin.png', '/static/images/main/mac_food_draph.png'],
    ['/static/images/main/mac_human_origin.png', '/static/images/main/mac_human_draph.png'],
  ]

  useEffect(() => {
    const beforeAfter = imageSet[Math.floor(Math.random() * imageSet.length)]
    setBeforeImage(beforeAfter[0])
    setAfterImage(beforeAfter[1])
  }, [])

  useEffect(() => {
    if (swiper) {
      if (pos > 49) {
        swiper.slideTo(0)
      } else {
        swiper.slideTo(1)
      }
    }
  }, [pos])

  return (
    <Stack
      sx={{
        position: 'relative',
        width: '100%',
        height: { lg: '53rem' },
        display: 'flex',
        alignItems: 'center',
        mt: { xs: '2rem' },
      }}
    >
      {!hideText && (
        <Box
          sx={{
            position: { lg: 'absolute', xs: 'inherit' },
            left: { lg: 'calc(50% - 61rem)' },
            top: { lg: '19rem' },
            mt: { xs: '5rem' },
            '& .MuiTypography-root': {
              fontSize: { lg: '5rem', xs: '3.2rem' },
              fontWeight: 900,
            },
          }}
        >
          <Box
            sx={{
              width: { lg: '40rem', xs: '20rem' },
              height: { lg: '20rem', xs: '10rem' },
              textAlign: { lg: 'end', xs: 'center' },
            }}
          >
            <Swiper
              direction={'vertical'}
              slidesPerView={1}
              onSwiper={setSwiper}
              modules={[Autoplay, Pagination, Navigation]}
              allowTouchMove={false}
            >
              {[
                ['내가 원하는', '상품 사진을'],
                ['간단하게', '얻을 수 있어요'],
              ].map((lines, idx) => {
                return (
                  <SwiperSlide
                    key={idx}
                    style={{ width: '', alignItems: isMobile ? 'center' : 'end' }}
                  >
                    <Typography>
                      {lines.map(l => (
                        <span key={l}>
                          {l}
                          <br />
                        </span>
                      ))}
                    </Typography>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </Box>
        </Box>
      )}

      <CenterAlignStack
        className="laptop"
        sx={{
          width: { lg: '87.7rem', xs: mobileWidthVW },
          aspectRatio: { xs: '1.5 / 1' },
          ...(!hideText && {
            left: { lg: 'calc(50% + 20rem)', xs: 0 },
            transform: { lg: 'translate(-50%)' },
            position: { lg: 'absolute', xs: 'relative' },
            mt: { lg: '6rem', xs: '3rem' },
          }),
        }}
      >
        <Box
          className="laptop-content"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            zIndex: 3,
            left: '50.2%',
            transform: 'translate(-50%)',
            top: { lg: '1.8rem', xs: '4%' },

            width: {
              lg: '68rem',
              xs: '65vw',
            },
            height: { lg: '44rem' },
            // aspectRatio: '1.5 / 1',
            objectFit: 'cover',
            overflow: 'hidden',
            borderRadius: { lg: '10px 10px 0px 0px', xs: '5px 5px 0px 0px' },
            '& div[data-rcs="handle-container"]': {},
            '& .__rcs-handle-root, & .__rcs-handle-line, & .__rcs-handle-button': {
              cursor: 'pointer !important',
            },
            '& .__rcs-handle-button': {
              width: { lg: '56px !important', xs: '45px !important' },
              height: { lg: '56px !important', xs: '45px !important' },
            },
          }}
        >
          <ReactCompareSlider
            className="laptop-slider"
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            itemOne={<ReactCompareSliderImage src={beforeImage} />}
            itemTwo={<ReactCompareSliderImage src={afterImage} />}
            // handle={
            //   <div
            //     style={{
            //       display: 'grid',
            //       height: '100%',
            //       placeContent: 'center',
            //       ckgroundColor: 'red',
            //     }}
            //   >
            //     <button
            //       style={{ all: 'unset', cursor: 'pointer', borderRadius: '50%', fontSize: 56 }}
            //     >
            //       😇
            //     </button>
            //   </div>
            // }
            // onlyHandleDraggable={true}
            onPositionChange={p => {
              setPos(p)
              // ----- GA4 event -----
              // window.gtag('event', 'home_button', { menu: 'monitor' })
              // ---------------------
            }}
            position={initialPosition}
          />
        </Box>
        <img
          className="laptop-image"
          src="/static/images/laptop.png"
          style={{
            width: isMobile ? mobileWidthVW : '100%',
            position: 'absolute',
          }}
        />
      </CenterAlignStack>
    </Stack>
  )
}

export function PresetSamples({ sx }) {
  const [selectedOption, setselectedOption] = useState({
    sampleIdx: null,
    category: null,
    conceptIdx: null,
    concept: null,
  })
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const { t, i18n } = useTranslation()

  const isMobile = useMobileMediaQuery()

  const clickSample = idx => () => {
    setselectedOption(o => ({
      sampleIdx: idx,
      category: null,
      conceptIdx: null,
      concept: null,
    }))
    setTimeout(() => {
      setStep(1)
      setselectedOption(o => ({
        sampleIdx: idx,
        category: sampleImages[idx].category,
        conceptIdx: null,
        concept: null,
      }))
    }, 500)

    // ----- GA4 event -----
    window.gtag('event', 'home_button', {
      menu: sampleImages[idx]?.name,
    })
    // ---------------------
  }

  const CATEGORY_CONCEPT_DICT = {
    cosmetics1: { nature: t('home.concept_1_a'), studio: t('home.concept_1_b') },
    cosmetics2: {
      cliff: t('home.concept_2_a'),
      maple: t('home.concept_2_b'),
    },
    food: {
      cliff: t('home.concept_3_a'),
      swimmingPool: t('home.concept_3_b'),
    },
    person: {
      simple: t('home.concept_4_a'),
      naturalLight: t('home.concept_4_b'),
    },
  }

  const clickConcept = idx => () => {
    const conceptKeys = Object.keys(conceptImages[selectedOption.category])
    setselectedOption(o => ({ ...o, conceptIdx: idx, concept: conceptKeys[idx] }))
    setStep(2)
    setProgress(0)
    setLoading(true)

    // ----- GA4 event -----
    window.gtag('event', 'home_button', {
      menu: idx === 0 ? 'concept_01' : idx === 1 ? 'concept_02' : '',
    })
    // ---------------------
  }

  const resetAllstate = () => {
    setselectedOption({
      sampleIdx: null,
      category: null,
      conceptIdx: null,
      concept: null,
    })
    setStep(0)
    setLoading(false)
    setProgress(0)
  }

  // useEffect(() => {
  //   if (selectedOption.category) {
  //     window.scrollBy({ top: 200, behavior: 'smooth' })
  //   }
  // }, [selectedOption.category])

  const variants = {
    conceptBefore: selectedOption.concept
      ? { x: '80%', opacity: 1, transition: { duration: 0.5, ease: [0.17, 0.67, 0.83, 0.8] } }
      : { x: '80%', opacity: 0, transition: { duration: 0.5 } },
    conceptAfter: selectedOption.concept
      ? { x: '0%', opacity: 1, transition: { duration: 0.5, ease: [0.17, 0.67, 0.83, 0.8] } }
      : { x: '80%', opacity: 1, transition: { duration: 0.5 } },

    resultBefore: { x: '50%', opacity: 0, transition: { duration: 1, type: 'spring' } },
    resultAfter: { x: '0%', opacity: 1, transition: { duration: 1, type: 'spring' } },

    conceptBeforeMobile: { x: '0%', opacity: 0, transition: { duration: 0.5 } },
    conceptAfterMobile: { x: '0%', opacity: 1, transition: { duration: 0.5 } },

    exitSample: { opacity: 0, transition: { duration: 0.5 } },
  }

  const theme = useTheme()
  return (
    <>
      <Stack
        sx={{
          pt: { lg: '7.8rem', xs: '4.6rem' },
          flexDirection: 'row',
          gap: { lg: '5.6rem', xs: 0 },
          width: { lg: '101.6rem', xs: 'unset' },
          ...sx,
        }}
      >
        <Stack sx={{}}>
          <Desktop>
            <PresetSamplesStep1
              variants={variants}
              clickSample={clickSample}
              selectedOption={selectedOption}
            />
          </Desktop>
          <Mobile>
            {step === 0 && (
              <PresetSamplesStep1
                variants={variants}
                clickSample={clickSample}
                selectedOption={selectedOption}
              />
            )}
          </Mobile>
          <Desktop>
            {selectedOption.category && (
              <PresetSamplesStep2
                variants={variants}
                selectedOption={selectedOption}
                clickConcept={clickConcept}
                categoryConceptDict={CATEGORY_CONCEPT_DICT}
              />
            )}
          </Desktop>
          <Mobile>
            {step === 1 && (
              <PresetSamplesStep2
                variants={variants}
                selectedOption={selectedOption}
                clickConcept={clickConcept}
                categoryConceptDict={CATEGORY_CONCEPT_DICT}
              />
            )}
          </Mobile>
        </Stack>

        <Desktop>
          {selectedOption.concept && (
            <PresetSamplesStep3
              variants={variants}
              selectedOption={selectedOption}
              loading={loading}
              setLoading={setLoading}
              progress={progress}
              setProgress={setProgress}
              categoryConceptDict={CATEGORY_CONCEPT_DICT}
            />
          )}
        </Desktop>
        <Mobile>
          {step === 2 && (
            <PresetSamplesStep3
              variants={variants}
              selectedOption={selectedOption}
              loading={loading}
              setLoading={setLoading}
              progress={progress}
              setProgress={setProgress}
              categoryConceptDict={CATEGORY_CONCEPT_DICT}
            />
          )}
        </Mobile>
      </Stack>
      {step === 2 && (
        <Mobile>
          <CenterAlignStack sx={{ mt: '3rem', display: 'flex' }}>
            <Typography sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
              다른 결과물이 궁금하신가요?
            </Typography>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon size={'medium'} />}
              sx={{
                width: '10rem',
                height: '3.2rem',
                borderRadius: '2rem',
                borderWidth: '0.1rem',
                fontSize: '1.4rem',
                fontWeight: 600,
                backgroundColor: theme.palette.common.white,
                mt: '0.8rem',
                '&:hover': {
                  backgroundColor: theme => theme.palette.common.white,
                  borderWidth: '0.1rem',
                  color: theme => theme.palette.draph.blue,
                  '& .MuiButton-startIcon path': {
                    transition: 'fill 0.3s',
                    fill: theme => theme.palette.draph.blue,
                  },
                  '& .MuiButton-startIcon': {
                    marginRight: '0.6rem',
                  },
                },
                '& .MuiButton-startIcon': {
                  marginRight: '0.6rem',
                },
              }}
              onClick={resetAllstate}
            >
              돌아가기
            </Button>
          </CenterAlignStack>
        </Mobile>
      )}
    </>
  )
}

function UsingDraph() {
  const isMobile = useMobileMediaQuery()
  const { i18n } = useTranslation()

  // AI배경, 누끼수정, 매부 소개하는 섹션
  return (
    <>
      <Box sx={{ mt: { lg: '6.9rem', xs: '2.4rem' } }}>
        <img
          style={{ width: isMobile ? '35.1rem' : '127.7rem' }}
          src={
            isMobile
              ? i18n.language === 'ko'
                ? '/static/images/main/using/using_draph_mobile.png'
                : '/static/images/main/using/using_draph_mobile_en.png'
              : i18n.language === 'ko'
              ? '/static/images/main/using/using_draph_desktop.png'
              : '/static/images/main/using/using_draph_desktop_en.png'
          }
        />
      </Box>
    </>
  )
}

function ClickArea() {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        width: { lg: '127rem', xs: '36rem' },
        height: { lg: '44rem', xs: 'auto' },
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        mt: { lg: '5.1rem', xs: '2.3rem' },
        flexDirection: { lg: 'row', xs: 'column' },
      }}
    >
      <Stack
        sx={{
          width: { lg: '38.6rem', xs: '24.9rem' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pt: { lg: '1rem', xs: 0 },
        }}
      >
        <img src="/static/images/main/click/clickArea_origin.png" />
        <CenterAlignBox
          sx={{
            border: `2px solid ${theme.palette.draph.blue}`,
            width: { lg: '24rem', xs: '15rem' },
            height: { lg: 'auto', xs: '2.5rem' },
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#ffffff',
          }}
        >
          <Typography sx={{ fontSize: { lg: '1.7rem', xs: '1.1rem' } }}>
            {t('home.sample_theme')}
          </Typography>
        </CenterAlignBox>
      </Stack>
      <Box
        sx={{
          width: { lg: '10.4rem', xs: '4.4rem' },
          mx: { lg: '3.8rem', xs: '0' },
          my: { lg: '0', xs: '3rem' },
          transform: { lg: 'rotate(0deg)', xs: 'rotate(90deg)' },
        }}
      >
        <img src="/static/images/main/click/clickArea_arrow.png" />
      </Box>
      <Box sx={{ width: { lg: '67.6rem', xs: '32rem' }, height: { lg: '44rem', xs: 'auto' } }}>
        <img src="/static/images/main/click/clickArea_draph.jpg" style={{ borderRadius: '2rem' }} />
      </Box>
    </Box>
  )
}

function AdvancedFeatures({ videoCategory }) {
  const isMobile = useMobileMediaQuery()
  const { i18n } = useTranslation()
  const Retry = () => {
    return (
      <Box
        initial={{ opacity: 0, transition: { duration: 0.5 } }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        sx={{ width: { lg: '112rem', xs: '32rem' } }}
        component={motion.div}
      >
        <img
          src={
            isMobile
              ? i18n.language === 'ko'
                ? '/static/images/main/advanced/advanced_mobile_1.jpg'
                : '/static/images/main/advanced/advanced_mobile_1_en.png'
              : i18n.language === 'ko'
              ? '/static/images/main/advanced/advanced_1.jpg'
              : '/static/images/main/advanced/advanced_1_en.png'
          }
        />
      </Box>
    )
  }
  const Resize = () => {
    return (
      <Box
        sx={{ width: { lg: '120rem', xs: '28.6rem' } }}
        component={motion.div}
        initial={{ opacity: 0, transition: { duration: 0.5 } }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
      >
        <img
          src={
            isMobile
              ? i18n.language === 'ko'
                ? '/static/images/main/advanced/advanced_mobile_2.jpg'
                : '/static/images/main/advanced/advanced_mobile_2_en.png'
              : i18n.language === 'ko'
              ? '/static/images/main/advanced/advanced_2.jpg'
              : '/static/images/main/advanced/advanced_2_en.png'
          }
        />
      </Box>
    )
  }
  const MagicBrush = () => {
    const isMobile = useMobileMediaQuery()

    const image1 = isMobile
      ? i18n.language === 'ko'
        ? '/static/images/main/advanced/advanced_mobile_3.jpg'
        : '/static/images/main/advanced/advanced_mobile_3_en.png'
      : i18n.language === 'ko'
      ? '/static/images/main/advanced/advanced_3.jpg'
      : '/static/images/main/advanced/advanced_3_en.png'

    const image2 = isMobile
      ? i18n.language === 'ko'
        ? '/static/images/main/advanced/advanced_mobile_4.jpg'
        : '/static/images/main/advanced/advanced_mobile_4_en.png'
      : i18n.language === 'ko'
      ? '/static/images/main/advanced/advanced_4.jpg'
      : '/static/images/main/advanced/advanced_4_en.png'

    return (
      <TwoImageSlider
        size={{ width: { lg: '90.4rem', xs: '24rem' } }}
        image1={image1}
        image2={image2}
      />
    )
  }

  switch (videoCategory) {
    case 'retry':
      return <Retry />
    case 'resize':
      return <Resize />
    case 'magicBrush':
      return <MagicBrush />
  }
}

export function PhoneCarousel() {
  const [swiper, setSwiper] = useState(null)
  const isMobile = useMobileMediaQuery()

  const press = [
    {
      src: '/static/images/main/cellphone/cellphone_coupang.png',
      style: { cursor: 'pointer' },
      clickLink: () => {
        window.open('https://news.mt.co.kr/mtview.php?no=2023071210114850079', '_blank')
      },
    },
    {
      src: '/static/images/main/cellphone/cellphone_draph.png',
      style: { cursor: 'pointer' },
      clickLink: () => {
        window.open('https://www.donga.com/news/It/article/all/20230314/118322583/1', '_blank')
      },
    },
    {
      src: '/static/images/main/cellphone/cellphone_oliveyoung.png',
      style: { cursor: 'pointer' },
      clickLink: () => {
        window.open('https://www.ddaily.co.kr/page/view/2023071910001215286', '_blank')
      },
    },
  ]

  const secondScreen = useMediaQuery({ query: '(max-width: 1100px)' })
  return (
    <Box
      sx={{
        mt: { lg: '6rem', xs: '4.2rem' },
        // width: { lg: '105rem', xs: '32rem' },
        height: { lg: '59.3rem', xs: '25rem' },
      }}
    >
      <CenterAlignStack
        direction="row"
        sx={{
          height: '100%',
          position: 'relative',
          '& .swiper-button': {
            backgroundImage: 'none',
            position: 'unset',
            mt: 0,
          },
          '& .swiper-button-prev': {
            mr: { lg: '13.8rem' },
          },
          '& .swiper-button-next': {
            ml: { lg: '13.8rem' },
          },

          '& .homeSwiper img': {
            width: { lg: '40rem', xs: '16.6rem' },
          },
        }}
      >
        <Desktop>
          <div title="prev" className="swiper-button swiper-button-prev">
            <svg
              width="18"
              height="31"
              viewBox="0 0 18 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3333 28.6667L2 15.3333L15.3333 2"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </Desktop>
        <CenterAlignBox
          sx={{
            width: { lg: '88rem', xs: '32.7rem' },
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
          }}
        >
          <CenterAlignBox
            sx={{
              width: { lg: '112rem', xs: '60rem' },
              height: '100%',
              position: 'absolute',
              top: 0,
            }}
          >
            <Swiper
              modules={[Navigation, Pagination, EffectCoverflow, Controller]}
              pagination={{ clickable: true }}
              effect="coverflow"
              loop={true}
              coverflowEffect={{
                rotate: 0,
                stretch: 100,
                depth: 100,
                scale: 0.7,
                modifier: 1,
                slideShadows: false,
              }}
              slidesPerView={2.8}
              centeredSlides={true}
              // style={{ height: '52rem', zIndex: 2 }}
              onSwiper={setSwiper}
              initialSlide={1}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
            >
              {press.map((p, i) => {
                return (
                  <SwiperSlide className="homeSwiper" key={i}>
                    <img src={p.src} style={p.style} onClick={p.clickLink} />
                  </SwiperSlide>
                )
              })}
              {press.map((p, i) => {
                return (
                  <SwiperSlide className="homeSwiper" key={i}>
                    <img src={p.src} style={p.style} onClick={p.clickLink} />
                  </SwiperSlide>
                )
              })}
              {press.map((p, i) => {
                return (
                  <SwiperSlide className="homeSwiper" key={i}>
                    <img src={p.src} style={p.style} onClick={p.clickLink} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </CenterAlignBox>
        </CenterAlignBox>
        <Desktop>
          <div title="next" className="swiper-button swiper-button-next">
            <svg
              width="18"
              height="31"
              viewBox="0 0 18 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 28.6667L15.3333 15.3333L2 2"
                stroke="white"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </Desktop>
      </CenterAlignStack>
    </Box>
  )
}

const PresetSamplesStep1 = ({ variants, clickSample, selectedOption }) => {
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Stack
      component={motion.div}
      variants={variants}
      initial={isMobile ? 'conceptBeforeMobile' : 'conceptBefore'}
      animate={isMobile ? 'conceptAfterMobile' : 'conceptAfter'}
      exit={isMobile ? 'exitSample' : ''}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div className="scroll_sample_1" style={{ position: 'absolute', top: '8.5rem' }}></div>
      <Typography
        sx={{
          fontSize: { lg: '2.2rem', xs: '1.6rem' },
          fontWeight: 700,
          lineHeight: 1,
        }}
      >
        {t('home.sample_step1')}
      </Typography>

      <Grid
        container
        sx={{
          display: 'flex',
          width: { lg: '38rem', xs: '29.2rem' },
          justifyContent: 'flex-start',
          mt: { lg: '2rem', xs: '1.8rem' },
        }}
        columns={{ lg: 12 }}
        gap={{ lg: '2rem', xs: '1.2rem' }}
      >
        {sampleImages.map((i, idx) => {
          return (
            <Grid item key={idx}>
              <img
                src={i.sample}
                style={{
                  boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.05)',
                  cursor: 'pointer',
                  width: isMobile ? '14rem' : '18rem',
                  height: isMobile ? '14rem' : '18rem',
                  borderRadius: '2rem',
                  backgroundColor: theme.palette.common.white,
                  outline:
                    idx === selectedOption.sampleIdx ? `4px solid ${theme.palette.draph.blue}` : '',
                }}
                onClick={clickSample(idx)}
              />
            </Grid>
          )
        })}
      </Grid>
    </Stack>
  )
}

const PresetSamplesStep2 = ({ variants, clickConcept, selectedOption, categoryConceptDict }) => {
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Stack
      component={motion.div}
      variants={variants}
      initial={isMobile ? 'conceptBeforeMobile' : 'conceptBefore'}
      animate={isMobile ? 'conceptAfterMobile' : 'conceptAfter'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: { lg: '4rem', xs: '0' },
        cursor: 'pointer',
      }}
    >
      <Stack sx={{ alignItems: 'center' }}>
        <Typography
          sx={{ fontSize: { lg: '2.2rem', xs: '1.6rem' }, fontWeight: 700, lineHeight: 1 }}
        >
          {t('home.sample_step2')}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: '1.8rem', xs: '1.2rem' },
            fontWeight: 500,
            lineHeight: 1,
            mt: { lg: '0.8rem', xs: '0.4rem' },
            maxWidth: { lg: '35rem' },
            textAlign: 'center',
          }}
        >
          {t('home.sample_step2_1')}
        </Typography>
      </Stack>

      <Grid
        container
        sx={{
          display: 'flex',
          width: { lg: '38rem', xs: '29.2rem' },
          justifyContent: 'flex-start',
          mt: { lg: '2rem', xs: '1.8rem' },
        }}
        columns={{ lg: 12 }}
        gap={{ lg: '2rem', xs: '1.2rem' }}
      >
        {Object.values(conceptImages[selectedOption.category]).map((i, idx) => {
          return (
            <Grid item key={idx}>
              <Stack
                sx={{
                  position: 'relative',
                  alignItems: 'center',
                }}
              >
                <img
                  src={i}
                  style={{
                    cursor: 'pointer',
                    width: isMobile ? '14rem' : '18rem',
                    height: isMobile ? '14rem' : '18rem',
                    borderTopLeftRadius: '1rem',
                    borderTopRightRadius: '1rem',
                  }}
                />
                <Typography
                  sx={{
                    width: { lg: '18rem', xs: '14rem' },
                    height: { lg: '3.6rem', xs: '2.4rem' },
                    fontSize: {
                      lg:
                        categoryConceptDict[selectedOption.category][
                          Object.keys(conceptImages[selectedOption.category])[idx]
                        ].length < 19
                          ? '1.3rem'
                          : '1.2rem',
                      xs: '1rem',
                    },
                    fontWeight: 600,
                    backgroundColor:
                      idx === selectedOption.conceptIdx
                        ? theme.palette.draph.blue
                        : theme.palette.common.black,
                    color: theme.palette.common.white,
                    borderBottomLeftRadius: '1rem',
                    borderBottomRightRadius: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {
                    categoryConceptDict[selectedOption.category][
                      Object.keys(conceptImages[selectedOption.category])[idx]
                    ]
                  }
                </Typography>
                <Box
                  onClick={clickConcept(idx)}
                  sx={{
                    position: 'absolute',
                    width: '18rem',
                    height: '21.6rem',
                    borderRadius: '1rem',
                    top: 0,
                    border:
                      idx === selectedOption.conceptIdx
                        ? `4px solid ${theme.palette.draph.blue}`
                        : 'none',
                  }}
                ></Box>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    </Stack>
  )
}

const PresetSamplesStep3 = ({
  variants,
  selectedOption,
  loading,
  setLoading,
  progress,
  setProgress,
  categoryConceptDict,
}) => {
  const isMobile = useMobileMediaQuery()

  const theme = useTheme()
  const { t, i18n } = useTranslation()

  return (
    <Stack
      component={motion.div}
      variants={variants}
      initial="resultBefore"
      animate="resultAfter"
      sx={{
        width: { lg: '58rem', xs: 'auto' },
        height: { lg: '66rem', xs: 'auto' },
        boxShadow: { lg: '4px 4px 20px 0px rgba(0, 0, 0, 0.20)', xs: 0 },
        borderRadius: '2rem',
        mt: { lg: '4.2rem', xs: 0 },
        alignItems: 'center',
        p: { lg: '4.2rem 4rem', xs: 0 },
      }}
    >
      <Typography sx={{ fontSize: { lg: '2.2rem', xs: '1.6rem' }, fontWeight: 700, lineHeight: 1 }}>
        {t(`artwork_category.${selectedOption.category}`)}
        {/* {t(`artwork_category.${selectedOption.category}`)} */}
      </Typography>
      <Typography
        sx={{
          fontSize: { lg: '1.8rem', xs: '1.2rem' },
          fontWeight: 500,
          lineHeight: 1,
          mt: '0.8rem',
        }}
      >
        {categoryConceptDict[selectedOption.category][selectedOption.concept]}
      </Typography>

      {selectedOption.concept !== false && loading && progress < totalStage + 2 && (
        <CenterAlignStack
          sx={{
            mt: '2rem',
            justifyContent: 'center',
            alignItems: ' center',
            width: { lg: '50rem', xs: '32rem' },
            height: { lg: '50rem', xs: '32rem' },
          }}
        >
          <ProgressLayout progress={progress} setProgress={setProgress} />
        </CenterAlignStack>
      )}
      {progress === totalStage + 2 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              width: { lg: '50rem', xs: '32rem' },
              justifyContent: 'flex-start',
              mt: '2rem',
            }}
            columns={{ lg: 12 }}
            gap={{ lg: '2rem', xs: '0.8rem' }}
          >
            {sampleImages[selectedOption.sampleIdx].result[selectedOption.concept].map((i, idx) => {
              return (
                <Grid item key={idx}>
                  <img
                    src={i}
                    style={{
                      width: isMobile ? '15.6rem' : '24rem',
                      height: isMobile ? '15.6rem' : '24rem',
                    }}
                  />
                </Grid>
              )
            })}
          </Grid>
        </motion.div>
      )}
    </Stack>
  )
}
