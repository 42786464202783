import styled from '@emotion/styled'
import { motion } from 'framer-motion'

const CustomImg = styled(motion.img)`
  display: block;
  width: 100%;
`

const CustomLi = styled(motion.li)`
  width: 100%;
`

const CustomUl = styled(motion.ul)`
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
`
const CustomDiv = styled(motion.div)`
  width: 100%;
`

const Banner = () => {
  return (
    <CustomDiv>
      <CustomUl>
        <CustomLi>
          <CustomImg src="http://dummyimage.com/600x400/000000/aaaaaa.png" />
        </CustomLi>
        <CustomLi>
          <CustomImg src="http://dummyimage.com/600x400/eeeeee/bbbbbb.png" />
        </CustomLi>
        <CustomLi>
          <CustomImg src="http://dummyimage.com/600x400/000000/cccccc.png" />
        </CustomLi>
        <CustomLi>
          <CustomImg src="http://dummyimage.com/600x400/eeeeee/dddddd.png" />
        </CustomLi>
        <CustomLi>
          <CustomImg src="http://dummyimage.com/600x400/000000/aaaaaa.png" />
        </CustomLi>
        <CustomLi>
          <CustomImg src="http://dummyimage.com/600x400/eeeeee/ffffff.png" />
        </CustomLi>
      </CustomUl>
    </CustomDiv>
  )
}

const MovingImg = () => {
  return (
    <section style={{ width: '100%', overflow: 'hidden' }}>
      <motion.article
        style={{ display: 'flex', width: '200%' }}
        initial={{ x: 0 }}
        animate={{ x: '-50%' }}
        transition={{ ease: 'linear', duration: 10, repeat: Infinity }}
      >
        <Banner />
        <Banner />
      </motion.article>
    </section>
  )
}

export default MovingImg
