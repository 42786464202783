import axios from 'axios'
import crudAxios from './configs/crud'
import { configData } from 'config'

export const backgroundAPI = {
  getBackgrounds: params => {
    return crudAxios.get('/background', { params })
  },
  getBackgroundStat: () => {
    return crudAxios.get('/background/stat')
  },
  getBackgroundById: id => {
    return crudAxios.get(`/background/${id}`)
  },
  putBackground: (id, body) => {
    return crudAxios.put(`/background/${id}`, body)
  },
  deleteBackground: id => {
    return crudAxios.delete(`/background/${id}`)
  },
  createBackgrounds: (body, params) => {
    return axios.post(`${configData.IMGGEN_URL}/api/v1/bg_clone/`, body, {
      params,
    })
  },
  getLikedBackgrounds: params => {
    return crudAxios.get('/background/like')
  },
  likeBackground: (bgId, liked = null, pinned = null) => {
    return crudAxios.put(`/background/like`, {
      bg_id: bgId,
      liked,
      pinned,
    })
  },
}
