import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  confirmDialogAtom,
  pleaseLoginDialogAtom,
  uploadDialogOpenAtom,
  allowBrowserNotiDialogAtom,
  currentMenuAtom,
} from 'atoms'
import { alpha } from '@mui/material/styles'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { useNavigate } from 'react-router-dom'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { DialogButton } from './DialogButtons'
import { SwipeableDrawer } from '@mui/material'
import { iOS, initBrowserNotification } from 'utils/common'
import MobileSwipeableDrawer from './MobileSwipeableDrawer'
import { English, Korean } from 'hooks/useLanguage'

export default function AllowBrowserNotiDialog() {
  const [dialog, setDialog] = useRecoilState(allowBrowserNotiDialogAtom)
  const resetDialog = useResetRecoilState(allowBrowserNotiDialogAtom)

  const currentMenu = useRecoilValue(currentMenuAtom)

  const { open } = dialog

  // const { t, i18n } = useTranslation()

  useEffect(() => {}, [])

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', 'browser_popup_imp', {
        menu: currentMenu?.id === 'mannequin' ? 'model' : currentMenu?.id,
      })
      // ---------------------
    }
  }, [open])

  const handleClose = () => {
    resetDialog()
  }

  const requestPermission = () => {
    initBrowserNotification()
    handleClose()
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            zIndex: 9999,
            '& .MuiDialog-paper': {
              borderRadius: '2rem',
              position: 'absolute',
              top: 0,
              left: 0,
              my: 0,
            },
          }}
        >
          <DialogContent
            sx={{
              fontSize: '1.6rem',
              fontWeight: 400,
              pt: '2.8rem',
              pb: '2.2rem',
            }}
          >
            <CenterAlignStack
              sx={{
                justifyContent: 'center',
                '& .image': {
                  width: '41.2rem',
                },
                '& .blue': {
                  color: theme => theme.palette.draph.blue,
                },
              }}
            >
              <CenterAlignStack
                sx={{
                  '& .text': { fontSize: '2rem', fontWeight: 700, lineHeight: 1.2 },
                  mb: '1.8rem',
                }}
              >
                <Korean>
                  <Typography className="text">
                    이미지 생성이 끝날 때까지 기다리기 귀찮다면?
                  </Typography>
                  <Typography className="text">
                    <span className="blue">생성 완료 알림</span>을 받아 보세요!
                  </Typography>
                </Korean>
                <English>
                  <Typography className="text">Don’t want to wait for generation ?</Typography>
                  <Typography className="text">
                    Get <span className="blue">Generation Complete Notification!</span>
                  </Typography>
                </English>
              </CenterAlignStack>

              <Korean>
                <img className="image" src="/static/images/etc/allow_notification.png" />
              </Korean>
              <English>
                <img className="image" src="/static/images/etc/allow_notification_en.png" />
              </English>

              <CenterAlignStack
                sx={{
                  '& .text': {
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: 1.2,
                    textAlign: 'center',
                  },
                  my: '1.6rem',
                }}
              >
                <Korean>
                  <Typography className="text">
                    허용을 누르면 모니터 화면으로 알림을 보내드립니다.
                  </Typography>
                  <Typography className="text">
                    알림은 브라우저 설정에서 언제든 차단할 수 있습니다.
                  </Typography>
                </Korean>
                <English>
                  <Typography className="text">
                    If you select Allow, you&apos;ll be notified on your monitor screen.
                  </Typography>

                  <Typography className="text">
                    You can block notifications anytime in your browser setting.
                  </Typography>
                </English>
              </CenterAlignStack>

              <CenterAlignStack sx={{ width: '100%' }}>
                <Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    fontSize: '2rem',
                    fontWeight: 800,
                    background: theme => theme.palette.draph.blue,
                    '&:hover': { background: theme => theme.palette.common.black },
                  }}
                  onClick={() => {
                    // ----- GA4 event -----
                    window.gtag('event', 'browser_popup_click', {
                      menu: currentMenu?.id,
                      method: 'permit',
                    })
                    // ---------------------

                    requestPermission()
                  }}
                >
                  <Korean>알림 허용하기</Korean>
                  <English>Allow Notifications</English>
                </Button>

                <Typography
                  sx={{ cursor: 'pointer', mt: '1.2rem', fontSize: '1.6rem', fontWeight: 400 }}
                  onClick={() => {
                    // ----- GA4 event -----
                    window.gtag('event', 'browser_popup_click', {
                      menu: currentMenu?.id,
                      method: 'later',
                    })
                    // ---------------------
                    handleClose()
                  }}
                >
                  <Korean>나중에 하기</Korean>
                  <English>Later</English>
                </Typography>
              </CenterAlignStack>
            </CenterAlignStack>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        {/* 모바일에서는 이 다이얼로그를 표시하지 않음 (모바일 브라우저 알림은 없음) */}
      </Mobile>
    </>
  )
}
