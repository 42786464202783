import {
  FormControl,
  Button,
  Select,
  MenuItem,
  Box,
  Divider,
  Typography,
  Stack,
} from '@mui/material'

import { English, Korean } from 'hooks/useLanguage'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { CenterAlignStack, GradationButton, TypoSpaceBtn } from '../../components'
import { DropdownArrowIcon } from 'theme/icon'
import { getCurrencyFormatter, numberCommaFormatter } from 'utils/common'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel } from 'swiper'

// TODO 가격 세팅
const pricing = [
  {
    id: 'concept',
    label: (
      <>
        <Korean>제품 콘셉트 이미지</Korean>
      </>
    ),
    price: {
      original: { krw: 100000 },
      final: { krw: 50000 },
    },
  },
  {
    id: 'model',
    label: (
      <>
        <Korean>모델 + 상품 공간 배치</Korean>
      </>
    ),
    price: {
      original: { krw: 120000 },
      final: { krw: 70000 },
    },
  },
  {
    id: 'model_interaction',
    label: (
      <>
        <Korean>제품을 들고 있는 모델</Korean>
      </>
    ),
    price: {
      original: { krw: 150000 },
      final: { krw: 120000 },
    },
  },
]

const getDiscountPrice = (original, final) => {
  if (!isNaN(original) && !isNaN(final)) {
    const d = original - final
    const dr = (d / original) * 100
    return { price: d, rate: Math.trunc(dr) }
  }
  return { price: 0, rate: 0 }
}

export default function BespokeService() {
  const navigate = useNavigate()

  return (
    <>
      <CenterAlignStack sx={{ pt: '6.4rem' }}>
        <CenterAlignStack sx={{ width: '100rem' }}>
          <PricingCard />

          <SampleRequests />
        </CenterAlignStack>
      </CenterAlignStack>
    </>
  )
}

function PricingCard() {
  const [selectType, setSelectType] = useState(pricing[0])
  const [selectOpen, setSelectOpen] = useState(false)
  const [currency, setCurrency] = useState('krw')
  const { i18n } = useTranslation()

  const currencyFormatter = getCurrencyFormatter(currency)

  useEffect(() => {
    if (i18n.language === 'en') {
      setCurrency('usd')
    } else {
      setCurrency('krw')
    }
  }, [i18n.language])

  const handleChange = event => {
    const p = pricing.filter(x => x.id === event.target.value)[0]
    if (p) {
      setSelectType(p)
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        borderRadius: '1rem',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        '& .box': {
          py: '5.4rem',
          backgroundColor: '#303030',
          borderRadius: '1.1rem',
        },
      }}
    >
      {/* 왼쪽 박스 */}
      <Box
        className="box"
        sx={{
          pl: '5.2rem',
          width: '46.6rem',
        }}
      >
        <Typography sx={{ color: 'white', fontSize: '2.8rem', fontWeight: 800, mb: '2.4rem' }}>
          <Korean>
            AI 상품 썸네일, AI 모델 이미지
            <br /> 고품질 제작
          </Korean>
        </Typography>

        <FormControl
          variant="outlined"
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },

            '.MuiInputBase-root': {
              borderTopLeftRadius: '2.6rem',
              borderTopRightRadius: '2.6rem',
              borderBottomLeftRadius: selectOpen ? 0 : '2.6rem',
              borderBottomRightRadius: selectOpen ? 0 : '2.6rem',
              transition: 'all 0.1s ease-in-out',
              width: 'auto',
              height: 'auto',
            },
            '& .MuiSelect-select': {
              fontSize: '2rem',
              fontWeight: 700,
              color: '#161616',
              height: '4.8rem !important',
              display: 'flex',
              alignItems: 'center',
              py: 0,
              pl: '2.8rem',
              pr: '1rem !important',
            },
          }}
        >
          <Select
            value={selectType.id}
            onChange={handleChange}
            open={selectOpen}
            onClose={() => {
              setSelectOpen(false)
            }}
            onOpen={() => {
              setSelectOpen(true)
            }}
            displayEmpty
            MenuProps={{
              PaperProps: {
                sx: {
                  '& .MuiList-root': { py: 0 },
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: '2.6rem',
                  borderBottomLeftRadius: '2.6rem',
                },
              },
            }}
            IconComponent={() => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '2.4rem',
                  height: '2.4rem',
                  mr: '1.6rem',
                }}
              >
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.292893 0.292893C-0.0675907 0.653377 -0.0953203 1.22061 0.209705 1.6129L0.292893 1.70711L5.29289 6.70711C5.65338 7.06759 6.22061 7.09532 6.6129 6.7903L6.70711 6.70711L11.7071 1.70711C12.0976 1.31658 12.0976 0.683418 11.7071 0.292893C11.3466 -0.0675907 10.7794 -0.0953203 10.3871 0.209705L10.2929 0.292893L6 4.585L1.70711 0.292893C1.34662 -0.0675907 0.779392 -0.0953203 0.387101 0.209705L0.292893 0.292893Z"
                    fill="#161616"
                  />
                </svg>
              </Box>
            )}
          >
            {pricing.map(item => (
              <MenuItem
                key={item.id}
                value={item.id}
                className="thumb-type-menu-item"
                sx={{
                  height: '4.8rem',
                  fontSize: '2rem',
                  fontWeight: 500,
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#BABABA',
                  '&:hover': { background: '#BABABA' },
                  '&.Mui-selected': {
                    background: '#BABABA !important',
                    '&:hover': { background: '#BABABA' },
                  },
                }}
              >
                <span> {item.label}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* 세로 점선 및 움푹 들어간 부분 */}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'stretch',
          backgroundColor: '#303030',
          width: '10px',
          justifyContent: 'center',
        }}
      >
        {/* 움푹 들어간 부분 위 */}
        <Box
          sx={{
            width: '10px',
            height: '13px',
            backgroundColor: 'black',
            borderBottomLeftRadius: '3px',
            borderBottomRightRadius: '3px',
            position: 'absolute',
            top: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1,
          }}
        />

        {/* 세로 점선 */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderImage:
              'repeating-linear-gradient(to bottom, #989898 0, #989898 6px, transparent 6px, transparent 12px) 1',
            // background: '#222222',
          }}
        />

        {/* 움푹 들어간 부분 아래 */}
        <Box
          sx={{
            width: '10px',
            height: '13px',
            backgroundColor: 'black',
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
            position: 'absolute',
            bottom: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1,
          }}
        />
      </Box>

      {/* 오른쪽 박스 */}
      <Box
        className="box"
        sx={{
          flex: 1,
          pl: '6.5rem',
        }}
      >
        {/* 가격 정보 */}
        <Stack
          sx={{
            width: '40rem',
            px: '0.6rem',
            '& .price-text': {
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',

              '& .text': {
                color: '#BBBBBB',
                fontSize: '2rem',
                '&.field': { fontWeight: 600 },
                '&.value': { fontWeight: 700 },
              },
            },
          }}
        >
          <Stack className="price-text" sx={{ mb: '1.2rem' }}>
            <Typography className="field text">
              <Korean>정가</Korean>
            </Typography>
            <Typography className="value text" sx={{ textDecoration: 'line-through' }}>
              {selectType.price.original[currency]
                ? numberCommaFormatter(selectType.price.original[currency])
                : '-'}
              <Korean>원</Korean>
            </Typography>
          </Stack>

          <Stack className="price-text">
            <Typography className="field text">
              <Korean>할인 금액</Korean>
            </Typography>
            <Typography
              className="value text"
              sx={{ '& .rate': { color: '#00F99F', mr: '1.2rem' } }}
            >
              <span className="rate">
                (-
                {
                  getDiscountPrice(
                    selectType.price.original[currency],
                    selectType.price.final[currency]
                  ).rate
                }
                %)
              </span>
              <span>
                {numberCommaFormatter(
                  getDiscountPrice(
                    selectType.price.original[currency],
                    selectType.price.final[currency]
                  ).price
                )}
                <Korean>원</Korean>
              </span>
            </Typography>
          </Stack>

          <Divider flexItem sx={{ borderColor: '#989898', my: '2.8rem' }} />

          <Stack className="price-text">
            <Typography className="field text">
              <Korean>현재 판매가</Korean>
            </Typography>
            <Typography className="value text" sx={{}}>
              {selectType.price.final[currency]
                ? numberCommaFormatter(selectType.price.final[currency])
                : '-'}
              <Korean>원</Korean>
            </Typography>
          </Stack>

          <Stack sx={{ width: '100%', color: '#A2A2A2', alignItems: 'end', mt: '0.8rem' }}>
            <Korean>* 콘셉트 상품 이미지 제작시</Korean>
          </Stack>

          <GradationButton
            sx={{
              width: '100%',
              borderRadius: '0.4rem',
              fontSize: '2rem',
              fontWeight: 800,
              color: 'white',
              mt: '5.6rem',
            }}
          >
            <Korean>혜택 받고 상담하기</Korean>
          </GradationButton>
        </Stack>
      </Box>

      {/* 움푹 들어간 스타일 (fake inset) */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '-10px',
          width: '10px',
          backgroundColor: '#222222',
          borderRadius: '0 0 50% 50%',
          boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.7)',
        }}
      />
    </Box>
  )
}

function SampleRequests() {
  const boxHeight = 52
  const boxMargin = 10
  const totalBoxes = 7

  // 박스 데이터를 생성합니다.
  const boxes = Array.from({ length: totalBoxes }, (_, index) => `박스 ${index + 1}`)

  return (
    <Box
      sx={{
        width: '1000px',
        height: '300px',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #ccc',
        margin: '0 auto',
        userSelect: 'none',
      }}
    >
      {/* 상단 그라데이션 오버레이 */}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          height: '50px',
          background: 'linear-gradient(to bottom, rgba(255,255,255,0.8), rgba(255,255,255,0))',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      />

      {/* 하단 그라데이션 오버레이 */}
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '50px',
          background: 'linear-gradient(to top, rgba(255,255,255,0.8), rgba(255,255,255,0))',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      />

      {/* Swiper 설정 */}
      <Swiper
        direction="vertical"
        loop={true}
        mousewheel={true}
        modules={[Mousewheel]}
        slidesPerView={Math.floor(300 / (boxHeight + boxMargin))} // 한 번에 보여질 슬라이드 수
        spaceBetween={boxMargin}
        style={{
          width: '100%',
          height: '100%',
          paddingTop: '50px', // 상단 그라데이션 높이만큼 패딩
          paddingBottom: '50px', // 하단 그라데이션 높이만큼 패딩
        }}
      >
        {boxes.map((box, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                width: '100%',
                height: `${boxHeight}px`,
                backgroundColor: '#1976d2',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#fff',
                cursor: 'grab',
                userSelect: 'none',
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
                '&:active': {
                  transform: 'scale(0.98)',
                },
              }}
            >
              {box}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}
