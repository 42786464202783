import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material'
import { bgConfigAtom } from 'atoms'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import useConfirm from 'hooks/useConfirm'
import { AutoBackgroundUpload, BackgroundUpload, ManualBackgroundUpload } from 'pages'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClose } from 'react-icons/ai'
import { useSetRecoilState } from 'recoil'
import { CloseIcon } from 'theme/icon'

const uploadMaxSize = 3000
const uploadMinSize = 500

export function AddBackgroundModal({ open, setOpen }) {
  const [manualBgUploadOpen, setManualBgUploadOpen] = useState(false)
  const [autoBgUploadOpen, setAutolBgUploadOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const isKo = i18n.language === 'ko'

  const [imageData, setImageData] = useState({})
  const { showConfirm } = useConfirm()

  const manualFileInput = useRef()
  const autoFileInput = useRef()

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', 'background_add_begin', {})
      // ---------------------
    }
  }, [open])

  const handleClose = e => {
    setOpen(false)
  }

  const resizeImage = f => {
    const reader = new FileReader()
    const image = new Image()
    const canvas = document.createElement('canvas')

    const dataURItoBlob = function (dataURI) {
      const bytes =
        dataURI.split(',')[0].indexOf('base64') >= 0
          ? window.atob(dataURI.split(',')[1])
          : window.decodeURI(dataURI.split(',')[1])
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
      const max = bytes.length
      const ia = new Uint8Array(max)
      for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)
      return new Blob([ia], { type: 'image/jpeg' })
    }

    const resize = function () {
      let width = image.width
      let height = image.height
      if (width > height) {
        if (width > uploadMaxSize) {
          height *= uploadMaxSize / width
          width = uploadMaxSize
        }
      } else {
        if (height > uploadMaxSize) {
          width *= uploadMaxSize / height
          height = uploadMaxSize
        }
      }
      canvas.width = width
      canvas.height = height
      canvas.getContext('2d').drawImage(image, 0, 0, width, height)
      const dataUrl = canvas.toDataURL('image/jpeg')
      return dataURItoBlob(dataUrl)
    }

    return new Promise(function (resolve, reject) {
      if (!f.type.match(/image.*/)) {
        reject(new Error('Not an image'))
        return
      }
      reader.onload = function (readerEvent) {
        image.onload = function () {
          return resolve(resize())
        }
        image.src = readerEvent.target.result
      }
      reader.readAsDataURL(f)
    })
  }

  const addImages = method => async event => {
    const f = event.target.files[0]
    let imageSizeTooSmall = false

    let imageData = f

    const promise = async f => {
      const image = new Image()
      const objectUrl = window.URL.createObjectURL(f)
      image.src = objectUrl
      await image.decode()
      if (image.width > uploadMaxSize || image.height > uploadMaxSize) {
        // console.log(f.name, '..resizing image')
        const smallerImage = await resizeImage(f)
        // console.log('smallerImage', smallerImage)
        smallerImage.lastModified = new Date()
        smallerImage.name = f.name
        imageData = new File([smallerImage], f.name)
      }
      if (image.width < uploadMinSize && image.height < uploadMinSize) {
        imageSizeTooSmall = true
      }
    }

    await promise(f)

    if (imageSizeTooSmall) {
      return showConfirm({
        alertOnly: true,
        content: (
          <>
            <Typography>{t('upload_dialog.warning_1')}</Typography>
            <Typography>{t('upload_dialog.warning_2')}</Typography>
          </>
        ),
      })
    }

    const currentImageUrl = URL.createObjectURL(imageData)

    const d = { name: imageData.name, url: currentImageUrl, file: imageData, data: {} }

    setImageData(d)
    setOpen(false)

    if (method === 'manual') {
      setManualBgUploadOpen(true)
    } else {
      setAutolBgUploadOpen(true)
    }

    event.target.value = null
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '584px',
            minHeight: '459px',
            borderRadius: '20px',
          },
        }}
      >
        <DialogTitle
          justifyContent="flex-end"
          alignItems="center"
          sx={{ display: 'flex', px: '43.75px', py: '42px', height: '70px' }}
        >
          <IconButton edge="end" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CenterAlignBox sx={{ alignItems: 'flex-end' }}>
            <CenterAlignStack>
              <Typography sx={{ mb: 1, fontWeight: 700, fontSize: '20px', pb: '23px' }}>
                {t('button.add_background')}
              </Typography>
              <img
                style={{
                  width: '208px',
                  height: '246px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                  marginBottom: '1.3rem',
                }}
                src="/static/images/bg_add/addBg1.png"
                onClick={() => manualFileInput.current.click()}
              />
              <input
                id="upload-bg-file"
                accept="image/*"
                onChange={addImages('manual')}
                type="file"
                hidden
                ref={manualFileInput}
              />
            </CenterAlignStack>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: '3rem', border: '1px solid #909090', height: '246px', mt: '65px' }}
            />
            <CenterAlignStack sx={{ mb: isKo ? 1 : 0 }}>
              <Typography
                sx={{
                  mb: 1,
                  fontWeight: 700,
                  fontSize: '20px',
                  textAlign: 'center',
                  pb: isKo ? '23px' : '0',
                }}
              >
                {t('button.add_background_auto')}
                <br /> {!isKo && t('button.add_background_auto_1')}
              </Typography>

              <img
                style={{
                  width: '208px',
                  height: '246px',
                  objectFit: 'cover',
                  cursor: 'wait',
                  filter: 'grayscale(1)',
                }}
                src="/static/images/bg_add/addBg2.gif"
                // 피사체 삭제 일시 중단 !
                // onClick={() => autoFileInput.current.click()}
                onClick={null}
              />
              <input
                id="upload-bg-file"
                accept="image/*"
                onChange={addImages('auto')}
                type="file"
                hidden
                ref={autoFileInput}
              />
            </CenterAlignStack>
          </CenterAlignBox>
        </DialogContent>
      </Dialog>
      <ManualBackgroundUpload
        bgUploadOpen={manualBgUploadOpen}
        setBgUploadOpen={setManualBgUploadOpen}
        imageData={imageData}
        setImageData={setImageData}
      />
      <AutoBackgroundUpload
        bgUploadOpen={autoBgUploadOpen}
        setBgUploadOpen={setAutolBgUploadOpen}
        imageData={imageData}
        setImageData={setImageData}
      />
    </>
  )
}
