import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { checkedURLforNoCache } from './ArtworkList'
import { getDownloadPieceFileName, getPieceType, getS3ImageSrc } from 'utils/common'
import { useEffect, useRef, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  bannerTextFontAtom,
  portfolioDetailAtom,
  portfolioTypeAtom,
  unusualPortfolioConfigUpdateAtom,
  userAtom,
} from 'atoms'
import { DownloadIcon, PlusIcon } from 'theme/icon'
import { apis } from 'apis'
import { useTranslation } from 'react-i18next'
import useConfirm from 'hooks/useConfirm'
import { creditPolicyDictSelector, isPortfolioOwnerSelector } from 'selector'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { getGA4EventNameByPortfolioType, triggerGA4DownloadEvent } from './Header'
import * as config from 'config'
import { NO_SO_UPLOAD_NAME } from 'config'

export default function BannerGeneratedImageLayout({
  refreshArtworks,
  artwork,
  setArtwork,
  setSelectedPiece,
  checked,
  setChecked,
  pieceIdx,
  setPieceIdx,
  setOpenImageDialog,
}) {
  const IMG_WIDTH_LIMIT = 578
  const IMG_HEIGHT_LIMIT = 380

  const [imgSizeInfo, setImgSizeInfo] = useState({ width: 0, height: 0, scale: 0 })
  const [imgSrc, setImgSrc] = useState(artwork ? getS3ImageSrc(`${artwork.pieces[0].path}`) : '')
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [fontInfo, setFontInfo] = useRecoilState(bannerTextFontAtom)
  const [user, setUser] = useRecoilState(userAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isOwner = useRecoilValue(isPortfolioOwnerSelector)

  const canvasRef = useRef(null)
  const { showConfirm } = useConfirm()
  const { t, i18n } = useTranslation()
  const creditPolicy = useRecoilValue(creditPolicyDictSelector)

  const openImageSwiper = e => {
    window.history.pushState({ popup: true }, '')
    setOpenImageDialog(true)
  }

  // useEffect(() => {
  //   console.log(pieceIdx)
  // }, [pieceIdx])

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  useEffect(() => {
    setImgSrc(getS3ImageSrc(`${artwork.pieces[pieceIdx ?? 0].path}`))
  }, [artwork])

  const downloadImage = async piece => {
    // ----- GA4 event -----

    const ev = getGA4EventNameByPortfolioType(portfolioType, 'download')
    triggerGA4DownloadEvent({
      eventName: ev,
      params: { count: 1 },
      method: 'thumbnail',
    })
    // ---------------------

    if (user.use_credit_on.includes('artwork_download')) {
      const credit = await checkUserCredit()

      if (credit < creditPolicy.artwork_download) {
        showConfirm({
          alertOnly: true,
          content: <Typography>{t('common.credit_warning')}</Typography>,
        })
        return
      }
    }

    const path = piece.path
    const artworkName = artwork.name.replace('.' + _.last(artwork.name.split('.')), '')
    const url = checkedURLforNoCache(getS3ImageSrc(path))

    const img = new Image()
    img.src = url

    const blob = await fetch(url + `?w=${Date.now().toString()}`).then(r => r.blob())

    const ext = _.last(_.first(_.last(url.split('/')).split('?')).split('.'))
    const idx = 1

    const fileName =
      piece.result_filename?.length > 0
        ? piece.result_filename
        : artworkName === NO_SO_UPLOAD_NAME
        ? getDownloadPieceFileName({
            prefix: 'bannerResult',
            idx: idx,
            width: img.width,
            height: img.height,
            ext: ext,
          })
        : getDownloadPieceFileName({
            prefix: artworkName,
            idx: idx,
            width: img.width,
            height: img.height,
            ext: ext,
          })
    saveAs(blob, fileName)

    if (isOwner) {
      apis.portfolio
        .updateArtworkDownload(portfolioDetail.id, artwork.id, { paths: [path] })
        .then(response => {
          const d = response.data.total_download
          checkUserCredit()
          if (d) setArtwork({ ...artwork, download: d })
        })
    }

    // const artworkConfig = JSON.parse(artwork.config)

    // // ----- GA4 event -----
    // if (config.DEFAULT_PORTFOLIO_TYPES.includes(portfolioType)) {
    //   const ev = getGA4EventNameByPortfolioType(portfolioType, 'download')
    //   triggerGA4DownloadEvent({
    //     eventName: ev,
    //     params: { count: 1 },
    //     method: 'dialog',
    //   })
    // } else if (artworkConfig.flag_bg_expansion) {
    //   triggerGA4DownloadEvent({
    //     eventName: 'ai_canvas_exp_download',
    //     params: { count: 1 },
    //     method: 'dialog',
    //   })
    // } else if (!portfolioType) {
    //   triggerGA4DownloadEvent({
    //     eventName: 'portfolio_download',
    //     artworkConfig,
    //     user,
    //     artwork,
    //     count: 1,
    //     method: 'dialog',
    //   })
    // } else {
    //   triggerGA4DownloadEvent({
    //     artworkConfig,
    //     user,
    //     artwork,
    //     count: 1,
    //     method: 'dialog',
    //   })
    // }

    // ---------------------
  }

  // const downloadImage = () => {
  //   const canvas = canvasRef.current
  //   console.log(canvas)
  //   const dataUrl = canvas.toDataURL('image/jpeg')
  //   const link = document.createElement('a')
  //   link.href = dataUrl
  //   link.download = 'image_with_text.jpg'
  //   link.click()
  // }

  // const thumbnailImageLoad = e => {
  //   const realWidth = e.target.naturalWidth
  //   const realHeight = e.target.naturalHeight

  //   const widthScale = IMG_WIDTH_LIMIT / realWidth
  //   const heightScale = IMG_HEIGHT_LIMIT / realHeight //  높이 / 너비 너비 비율

  //   if (widthScale >= heightScale) {
  //     setImgSizeInfo({
  //       width: realWidth * heightScale,
  //       height: realHeight * heightScale,
  //       scale: heightScale,
  //     })
  //   }

  //   if (widthScale < heightScale) {
  //     setImgSizeInfo({
  //       width: realWidth * widthScale,
  //       height: realHeight * widthScale,
  //       scale: widthScale,
  //     })
  //   }
  // }

  // useEffect(() => {
  //   const canvas = canvasRef.current
  //   const ctx = canvas.getContext('2d')
  //   const img = new Image()
  //   img.crossOrigin = 'Anonymous'
  //   img.src = imgSrc
  //   img.onload = e => {
  //     if (
  //       !bannerConfig.bannerMainText1 &&
  //       !bannerConfig.bannerSubText1 &&
  //       bannerConfig.bannerMainTextIdx === 0 &&
  //       bannerConfig.bannerSubTextIdx === 0
  //     ) {
  //       return
  //     }

  //     const realWidth = e.target.naturalWidth
  //     const realHeight = e.target.naturalHeight

  //     const widthScale = IMG_WIDTH_LIMIT / realWidth
  //     const heightScale = IMG_HEIGHT_LIMIT / realHeight //  높이 / 너비 너비 비율

  //     if (widthScale >= heightScale) {
  //       setImgSizeInfo({
  //         width: realWidth * heightScale,
  //         height: realHeight * heightScale,
  //         scale: heightScale,
  //       })
  //     }

  //     if (widthScale < heightScale) {
  //       setImgSizeInfo({
  //         width: realWidth * widthScale,
  //         height: realHeight * widthScale,
  //         scale: widthScale,
  //       })
  //     }
  //     canvas.width = img.width
  //     canvas.height = img.height

  //     ctx.drawImage(img, 0, 0)
  //     ctx.font = `bold 150px ${fontInfo.font}`
  //     ctx.fillStyle = `rgba(${fontInfo.fontColor.r},${fontInfo.fontColor.g},${fontInfo.fontColor.b},${fontInfo.fontColor.a})` // 텍스트 색상 설정
  //     ctx.fillText(bannerConfig.bannerMainText1, 100, canvas.height - 250) // 텍스트 위치 설정
  //     ctx.font = `500 70px ${fontInfo.font}`
  //     ctx.fillStyle = `rgba(${fontInfo.fontColor.r},${fontInfo.fontColor.g},${fontInfo.fontColor.b},${fontInfo.fontColor.a})` // 텍스트 색상 설정
  //     ctx.fillText(bannerConfig.bannerSubText1, 100, canvas.height - 150) // 텍스트 위치 설정

  //     // const dataUrl = canvas.toDataURL('image/jpeg')
  //     // console.log(dataUrl)
  //   }
  // }, [
  //   imgSrc,
  //   bannerConfig.bannerMainText1,
  //   bannerConfig.bannerSubText1,
  //   fontInfo.font,
  //   fontInfo.fontColor,
  // ])

  const handleLoad = e => {
    // if (
    //   !bannerConfig.bannerMainText1 &&
    //   !bannerConfig.bannerSubText1 &&
    //   bannerConfig.bannerMainTextIdx === 0 &&
    //   bannerConfig.bannerSubTextIdx === 0
    // ) {
    //   return
    // }

    const realWidth = e.target.naturalWidth
    const realHeight = e.target.naturalHeight

    const widthScale = IMG_WIDTH_LIMIT / realWidth
    const heightScale = IMG_HEIGHT_LIMIT / realHeight //  높이 / 너비 너비 비율

    if (widthScale >= heightScale) {
      setImgSizeInfo({
        width: realWidth * heightScale,
        height: realHeight * heightScale,
        scale: heightScale,
      })
    }

    if (widthScale < heightScale) {
      setImgSizeInfo({
        width: realWidth * widthScale,
        height: realHeight * widthScale,
        scale: widthScale,
      })
    }
  }

  // useEffect(() => {
  //   console.log('change artwork')
  // }, [artwork])

  return (
    <CenterAlignBox sx={{ gap: '2rem' }}>
      <CenterAlignStack sx={{ gap: '2rem' }}>
        {artwork.pieces?.map((piece, idx) => {
          const path = piece.path

          return (
            <img
              key={piece.id}
              src={getS3ImageSrc(`${path}`)}
              style={{ width: '8rem', height: '8rem', objectFit: 'cover', cursor: 'pointer' }}
              onClick={() => {
                setImgSrc(getS3ImageSrc(`${artwork.pieces[idx].path}`))
                setPieceIdx(idx)
              }}
            />
          )
        })}
      </CenterAlignStack>

      <Box
        sx={{
          minWidth: '57.8rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: `${imgSizeInfo.width / 10}rem`,
            height: `${imgSizeInfo.height / 10}rem`,
            position: 'relative',
          }}
        >
          <img
            src={imgSrc}
            onLoad={handleLoad}
            style={{
              width: `${imgSizeInfo.width / 10}rem`,
              height: `${imgSizeInfo.height / 10}rem`,
              // cursor: 'pointer',
            }}
            onClick={openImageSwiper}
          />
          {/* <canvas
            ref={canvasRef}
            style={{ width: imgSizeInfo.width, height: imgSizeInfo.height }}
            onClick={openImageSwiper}
          /> */}
          <Box sx={{ position: 'absolute', bottom: '1.2rem', right: '1.2rem' }}>
            <IconButton
              sx={{
                width: '4.8rem',
                height: '4.8rem',
                borderRadius: '10px',
                backgroundColor: '#000000',
                opacity: 0.3,
                '&:hover': { backgroundColor: '#000000', opacity: 0.5 },
                transition: 'all 0.2s ease-in-out',
              }}
              onClick={() => downloadImage(artwork?.pieces[pieceIdx ?? 0])}
            >
              <DownloadIcon size="large" color="#fff" />
            </IconButton>
          </Box>
        </Box>
        {/* <img
          src={imgSrc}
          onLoad={thumbnailImageLoad}
          style={{ width: imgSizeInfo.width, height: imgSizeInfo.height }}
        /> */}
      </Box>
    </CenterAlignBox>
  )
}
