import {
  AnimatedHeightDialog,
  CenterAlignBox,
  CenterAlignStack,
  MobileSwipeableDrawer,
  OpacityMotionDiv,
  PortfolioConfigTabs,
} from 'components'
import { fontSize } from './test/Toolbar'
import {
  Box,
  Button,
  IconButton,
  Input,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  styled,
  useStepContext,
  useTheme,
} from '@mui/material'
import { Form, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  APICopyIcon,
  APIGuideIcon,
  APIPlusIcon,
  CirclePlusIcon,
  ClipBoardIcon,
  CloseIcon,
  CopyIcon,
  PlusIcon,
  TrashCanIcon,
} from 'theme/icon'
import { PuffLoader } from 'react-spinners'
import { apis } from 'apis'
import { useRecoilState } from 'recoil'
import { segmentStepAtom } from 'atoms'
import moment from 'moment'
import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { getAccessToken } from 'utils/cookie'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { enqueueSnackbar } from 'notistack'
import { getStaticResourceLanguage } from 'hooks/useLanguage'

export const langCodeSample = {
  python: `import requests
import cv2
import base64, io
import traceback
import json
from PIL import Image

url = "https://api.draph.art/v1/generate/"
img_path = "IMAGE_PATH_TO_UPLOAD"

with open(f'{img_path}','rb') as img_obj:
image = {"image":("filename.jpg", img_obj, "image/jpg")}
headers = {
  "Authorization": "Bearer YOUR_TOKEN_HERE"
}

data={
  "username": your_name,
  "gen_type": "concept",
  "multiblob_sod": False,
  "output_w": 1200,
  "output_h": 800,
  "concept_option":'{"category": "car", "theme": "winter", "num_results": 2}
}

response = requests.post(url=url, files=image, data=data, headers=headers)

if response.status_code == 200:
print("API 호출 성공!")
print("응답 결과:", response)
res = json.loads(response.content)
base64_data = res

try:
  for i,res_img in enumerate(base64_data):
    # image = Image.fromarray(array_data)
    image = Image.open(io.BytesIO(base64.b64decode(res_img.split(',',1)[0])))
    image.save(f"concept_{i}.png")
  except Exception as e:
    traceback.print_exc()
else:
  print("API 호출 실패:")
  print("상태 코드:", response.status_code)
  print("응답 내용:", response.text)
  `,

  javascript: `const axios = require('axios');
const fs = require('fs');
const base64js = require('base64-js');

const imgPath = 'IMAGE_PATH_TO_UPLOAD';
const url = 'https://api.draph.art/v1/generate/';

const imgFile = fs.readFileSync(imgPath);

const formData = new FormData();

formData.append('image', imgFile, 'filename.jpg');
formData.append('username', 'your_name');
formData.append('gen_type', 'concept');
formData.append('multiblob_sod', 'false');
formData.append('output_w', '1200');
formData.append('output_h', '800');
formData.append('concept_option', JSON.stringify({
  category: 'car',
  theme: 'winter',
  num_results: 2
}));

const headers = {
  'Authorization': 'Bearer YOUR_TOKEN_HERE',
  ...formData.getHeaders()
};

axios.post(url, formData, { headers: headers })
  .then(response => {
      if (response.status === 200) {
          console.log('API call successful!');
          console.log('Response:', response.data);
          const base64Data = response.data;

          try {
              base64Data.forEach((res_img, i) => {
                  const base64Image = res_img.split(',', 1)[0];
                  const buffer = base64js.toByteArray(base64Image);
                  fs.writeFileSync(\`concept_\${i}.png\`, buffer);
              });
          } catch (e) {
              console.error('Error processing image:', e);
          }
      } else {
          console.error('API call failed:');
          console.error('Status code:', response.status);
          console.error('Response data:', response.data);
      }
  })
  .catch(error => {
      console.error('Error making API call:', error);
  });
`,
}

const style = {
  title: { fontSize: { lg: '4rem', xs: '2.8rem' }, fontWeight: 800 },
  subtitle: { fontSize: { lg: '2.4rem', xs: '1.8rem' }, fontWeight: 500 },
  inputfont: { fontSize: '1.6rem', fontWeight: 400 },

  topTwoButton: {
    pt: { lg: '2.8rem', xs: '3.2rem' },
    gap: { lg: '1.6rem', xs: '1.2rem' },
    flexDirection: { lg: 'row', xs: 'column' },
  },
  topButton: {
    minWidth: { lg: '20rem', xs: '32rem' },
    p: '0 2.4rem',
    fontSize: { lg: '1.8rem', xs: '1.6rem' },
    fontWeight: 700,
    height: { lg: '4.8rem', xs: '4rem' },
  },

  tabMenu: {
    fontSize: { lg: '1.8rem', xs: '1.3rem' },
    fontWeight: { lg: 500, xs: 500 },
    height: { lg: '4.4rem !important', xs: '4rem !important' },
  },

  dialogContentButton: {
    fontSize: { lg: '1.6rem', xs: '1.6rem' },
    fontWeight: 500,
    borderRadius: '4px',
  },

  apiMainTitle: {
    fontSize: { lg: '2.8rem', xs: '1.8rem' },
    fontWeight: 700,
  },

  apiMainSubTitle: {
    fontSize: { lg: '2rem', xs: '1.4rem' },
  },
}

export default function APIMain() {
  const tabsArray = [
    { default: '설명', mobile: '설명' },
    { default: '상세 기능', mobile: '상세 기능' },
    { default: '활용 예시', mobile: '활용 예시' },
    {
      default: (
        <Typography sx={{ display: 'flex', alignItems: 'center', ...style.tabMenu, gap: '0.3rem' }}>
          사용 가이드 <APIGuideIcon />
        </Typography>
      ),
      mobile: (
        <Typography sx={{ display: 'flex', alignItems: 'center', ...style.tabMenu, gap: '0.3rem' }}>
          가이드 <APIGuideIcon sx={{ width: '1rem', height: '1rem' }} />
        </Typography>
      ),
    },
  ]
  const navigate = useNavigate()
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const token = getAccessToken()

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    console.log(newValue)

    if (newValue === tabsArray.length - 1) {
      const newWindow = window.open('/api', '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    } else {
      setValue(newValue)
    }
  }

  const [openTokenDialog, setOpenTokenDialog] = useState(false)
  const [page, setPage] = useRecoilState(segmentStepAtom)
  const [dialogSnakeBar, setDialogSnakeBar] = useState('')

  useEffect(() => {
    return () => {
      setPage(0)
    }
  }, [])

  const handleClose = () => {
    setDialogSnakeBar('')
    setOpenTokenDialog(false)
    setPage(0)
  }

  const pageToHeight = (page, add) => {
    const addHeight = add ? 2.4 : 0

    switch (page) {
      case 0:
        return 25.2 + addHeight + 'rem'
      case 1:
        return 35.2 + addHeight + 'rem'

      default:
        return 'auto'
    }
  }

  const tokenDialogClick = () => {
    if (token) {
      setOpenTokenDialog(true)
    } else {
      localStorage.setItem('landingPoint', '/api_main')
      navigate('/login')
    }
  }

  return (
    <CenterAlignStack>
      <CenterAlignStack
        sx={{ pt: { lg: '7.6rem', xs: '4rem' }, width: { lg: '96rem', xs: '32rem' } }}
      >
        <Typography sx={{ ...style.title }}>Draph Art API</Typography>
        <Typography sx={{ ...style.subtitle }}>3초만에 만드는 상업용 상품 이미지</Typography>

        <CenterAlignBox sx={{ ...style.topTwoButton }}>
          <Button
            variant="contained"
            sx={{ ...style.topButton }}
            onClick={() => {
              tokenDialogClick()
            }}
          >
            API 토큰 관리하기
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/pricing')
            }}
            sx={{
              ...style.topButton,
              border: `1px solid ${theme.palette.common.black}`,
              '&:hover': {
                border: `1px solid ${theme.palette.draph.blue}`,
              },
            }}
          >
            요금제
          </Button>
        </CenterAlignBox>

        <CenterAlignStack sx={{ width: 'inherit', pt: { lg: '6.8rem', xs: '4.4rem' } }}>
          <PortfolioConfigTabs
            value={value}
            onChange={handleChange}
            tabs={tabsArray}
            tabSx={{
              ...style.tabMenu,
              '&.Mui-selected': {
                color: `${theme.palette.common.white} !important`,
                backgroundColor: `${theme.palette.common.black} !important`,
                '& p': {
                  color: `${theme.palette.common.white} !important`,
                },
                '& p svg path': {
                  stroke: `${theme.palette.common.white} !important`,
                },
              },
            }}
          />
        </CenterAlignStack>

        <TabsPanel value={value} />
      </CenterAlignStack>
      <Desktop>
        {openTokenDialog && (
          <AnimatedHeightDialog
            open={openTokenDialog}
            setOpen={setOpenTokenDialog}
            paperStyle={{
              width: '40.6rem',
              padding: '2.8rem 2.8rem',
              height: pageToHeight(page, dialogSnakeBar),
            }}
            backdropClose={false}
            sx={{}}
          >
            <TokenDialogContent
              dialogSnakeBar={dialogSnakeBar}
              setDialogSnakeBar={setDialogSnakeBar}
              handleClose={handleClose}
            />
          </AnimatedHeightDialog>
        )}
      </Desktop>
      <Mobile>
        {openTokenDialog && (
          <MobileSwipeableDrawer
            open={openTokenDialog}
            onOpen={() => {
              return openTokenDialog
            }}
            onClose={handleClose}
          >
            <CenterAlignStack sx={{ mb: '2.8rem' }}>
              <TokenDialogContent
                dialogSnakeBar={dialogSnakeBar}
                setDialogSnakeBar={setDialogSnakeBar}
                handleClose={handleClose}
              />
            </CenterAlignStack>
          </MobileSwipeableDrawer>
        )}
      </Mobile>
    </CenterAlignStack>
  )
}

const TabsPanel = ({ value }) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  switch (value) {
    case 0:
      return (
        <OpacityMotionDiv animationKey="description">
          <img src={`/static/images/api/api_01_${getStaticResourceLanguage(i18n.language)}.png`} />
          <img src={`/static/images/api/api_02_${getStaticResourceLanguage(i18n.language)}.png`} />
          <img src={`/static/images/api/api_03_${getStaticResourceLanguage(i18n.language)}.png`} />
          <img src={`/static/images/api/api_04_${getStaticResourceLanguage(i18n.language)}.png`} />
          <img src={`/static/images/api/api_05_${getStaticResourceLanguage(i18n.language)}.png`} />
        </OpacityMotionDiv>
      )
    case 1:
      return (
        <OpacityMotionDiv animationKey="features">
          <Features />
        </OpacityMotionDiv>
      )

    case 2:
      return (
        <OpacityMotionDiv animationKey="example">
          <Example />
        </OpacityMotionDiv>
      )
    case 3:
      return <OpacityMotionDiv animationKey="guide">가이드</OpacityMotionDiv>
  }
}

export const TokenDialogContent = ({ dialogSnakeBar, setDialogSnakeBar, handleClose }) => {
  const [page, setPage] = useRecoilState(segmentStepAtom)
  const [allToken, setAllToken] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const refreshToken = () => {
    apis.user
      .getAPIToken()
      .then(r => {
        const d = r.data

        setAllToken(d.tokens)
      })
      .catch(() => {
        alert('토큰 불러오기 실패')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    refreshToken()

    return () => {
      handleClose()
    }
  }, [])

  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { lg: 'space-between', xs: 'center' },
          mb: dialogSnakeBar ? { lg: 0, xs: 0 } : { lg: '2.4rem', xs: '2rem' },
          alignItems: 'center',
        }}
      >
        {' '}
        <Typography sx={{ fontSize: { lg: '2.4rem', xs: '1.6rem' }, fontWeight: 700 }}>
          API 토큰 관리
        </Typography>
        <Desktop>
          <IconButton
            sx={{ p: 0.4, '&:hover': { backgroundColor: 'transparent' } }}
            onClick={() => {
              handleClose()
            }}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <CloseIcon size="medium" style={{ width: '1.3rem', height: '1.3rem' }} />
          </IconButton>
        </Desktop>
      </Box>

      <DialogState dialogSnakeBar={dialogSnakeBar} />
      <ContentPages
        allToken={allToken}
        setAllToken={setAllToken}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        dialogSnakeBar={dialogSnakeBar}
        setDialogSnakeBar={setDialogSnakeBar}
        refreshToken={refreshToken}
      />
    </Stack>
  )
}

const ContentPages = ({
  allToken,
  setAllToken,
  isLoading,
  setIsLoading,
  dialogSnakeBar,
  setDialogSnakeBar,
  refreshToken,
}) => {
  const theme = useTheme()
  const isMobile = useMobileMediaQuery()
  const [page, setPage] = useRecoilState(segmentStepAtom)
  const inputFontStyle = { fontSize: '1.6rem' }
  const [selectedToken, setSelectedToken] = useState({ name: '', token: '' })

  const { showConfirm } = useConfirm()

  const inputRef = useRef(null)

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  const createToken = async () => {
    try {
      const res = await apis.user.addAPIToken({
        name: `Untitled API Token ${moment(new Date()).format('YYYY-MM-DD HH:MM:SS')}`,
      })

      const tokensData = res.data.tokens
      const recenctTokenData = tokensData[tokensData.length - 1]

      setSelectedToken({ ...recenctTokenData })
      setDialogSnakeBar('success')
      setPage(1)
    } catch (error) {
      showConfirm({ alertOnly: true, content: '토큰 발급 중 오류가 발생하였습니다.' })
    }
  }

  const titleChange = e => {
    setSelectedToken(p => ({ ...p, name: e.target.value }))
  }

  const handleCopyClipBoard = async token => {
    try {
      await navigator.clipboard.writeText(token)
      setDialogSnakeBar('copy')
    } catch (err) {
      console.log(err)
    }
  }

  const deleteToken = async () => {
    try {
      await apis.user.deleteAPIToken(selectedToken.id)
      refreshToken()
      setDialogSnakeBar('delete')
      setSelectedToken({ name: '', token: '' })
      setPage(0)
    } catch (err) {
      console.log(err)
    }
  }

  const updateToken = async () => {
    try {
      console.log(selectedToken)
      await apis.user.updateAPIToken(selectedToken.id, selectedToken)
      setPage(0)
      refreshToken()
      setDialogSnakeBar('update')
      setSelectedToken({ name: '', token: '' })
    } catch (err) {
      console.log(err)
    }
  }

  switch (page) {
    case 0:
      return (
        <Stack>
          {!isLoading ? (
            <OpacityMotionDiv>
              {' '}
              <Stack sx={{ gap: '0.4rem' }}>
                <Typography sx={{ fontSize: '1.4rem' }}>발급받은 API 토큰 정보</Typography>

                <Select
                  // displayEmpty={!allToken.length}
                  displayEmpty
                  value={selectedToken.token}
                  onChange={e => {
                    const tokenValue = e.target.value
                    const filteredToken = allToken.find(t => t.token === tokenValue)

                    setSelectedToken(filteredToken)
                  }}
                  sx={{
                    width: { lg: '35rem', xs: '32rem' },
                    height: '4rem',
                    p: 0,
                    background: ' white',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderWidth: '1px',
                      borderColor: '#D9E1EC',
                    },
                    ...inputFontStyle,
                  }}
                  disabled={!allToken.length}
                  renderValue={selected => {
                    if (!allToken.length) {
                      return (
                        <Typography
                          sx={{
                            ...inputFontStyle,
                            color: '#878787',
                            width: { lg: '30.6rem', xs: '27.6rem' },
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          발급받은 API 토큰이 없습니다.
                        </Typography>
                      )
                    } else {
                      if (selected.length !== 0) {
                        return (
                          <Typography
                            sx={{
                              ...inputFontStyle,

                              width: { lg: '30.6rem', xs: '27.6rem' },
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {selectedToken.name}
                          </Typography>
                        )
                      } else {
                        return (
                          <Typography
                            sx={{
                              ...inputFontStyle,
                              color: '#878787',
                              width: { lg: '30.6rem', xs: '27.6rem' },
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            선택된 토큰이 없습니다.
                          </Typography>
                        )
                      }
                    }
                  }}
                >
                  {allToken?.map(t => (
                    <MenuItem value={t.token} key={t.token} sx={{ px: '1rem', ...inputFontStyle }}>
                      {t.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Box sx={{ width: 'inherit', display: 'flex', mt: '2.8rem', gap: '3.2rem' }}>
                <Button
                  startIcon={<TrashCanIcon color={theme.palette.common.black} />}
                  sx={{
                    ...style.dialogContentButton,
                    color: '#989898',
                    p: '0.8rem 0.4rem',
                    height: '4rem',

                    ' & .MuiButton-startIcon.MuiButton-iconSizeMedium': { ml: 0 },

                    '&.Mui-disabled': { color: '#989898' },

                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: theme.palette.common.red,
                      '& svg path': {
                        transition: 'stroke 0.3s',
                        stroke: theme.palette.common.red,
                      },
                    },
                    '& svg path': {
                      transition: 'stroke 0.3s',
                      stroke: '#989898',
                    },
                  }}
                  disabled={!selectedToken}
                  onClick={deleteToken}
                >
                  토큰 삭제
                </Button>

                <Box sx={{ display: 'flex', gap: '1.2rem' }}>
                  <Button
                    variant="contained"
                    sx={{ ...style.dialogContentButton, width: { lg: '16rem', xs: '14rem' } }}
                    disabled={!selectedToken.name.length}
                    onClick={() => {
                      setDialogSnakeBar('')
                      setPage(1)
                    }}
                  >
                    API 토큰 정보
                  </Button>
                  <IconButton
                    sx={{
                      ...style.dialogContentButton,
                      p: '1.3rem',
                      backgroundColor: theme.palette.common.black,
                      '&:hover': { backgroundColor: theme.palette.draph.blue },
                    }}
                    onClick={createToken}
                  >
                    <APIPlusIcon />
                  </IconButton>
                </Box>
              </Box>
            </OpacityMotionDiv>
          ) : (
            <CenterAlignStack>
              <PuffLoader />
            </CenterAlignStack>
          )}
        </Stack>
      )

    case 1:
      return (
        <Stack>
          <OpacityMotionDiv>
            <Stack sx={{ gap: '0.4rem' }}>
              <Typography sx={{ display: isMobile && dialogSnakeBar ? 'none' : 'inherit' }}>
                API 토큰 이름 :{' '}
              </Typography>
              <OutlinedInput
                value={selectedToken.name}
                onChange={titleChange}
                sx={{
                  ...style.inputfont,
                  // border: '1px solid #D9E1EC',
                  width: { lg: '35rem', xs: '32rem' },
                  height: { lg: '4.8rem', xs: '3.6rem' },
                  fontSize: { lg: '1.6rem', xs: '1.4rem' },
                  '& .MuiInputBase-input': {
                    p: 0,
                  },
                }}
              />
            </Stack>

            <Stack sx={{ gap: '0.4rem', mt: '1.6rem' }}>
              <Typography>API 토큰 : </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
                <OutlinedInput
                  inputRef={inputRef}
                  value={selectedToken.token}
                  readOnly
                  onClick={handleFocus}
                  sx={{
                    ...style.inputfont,
                    backgroundColor: '#f8f8f8',
                    // border: '1px solid #D9E1EC',
                    width: { lg: '28.2rem', xs: '25.2rem' },
                    height: { lg: '4.8rem', xs: '3.6rem' },
                    fontSize: { lg: '1.6rem', xs: '1.4rem' },
                    p: '1.2rem 1.6rem',
                    '& .MuiInputBase-input': {
                      p: 0,
                    },

                    '&:hover': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.draph.blue,
                      },
                    },
                  }}
                />

                <Button
                  variant="outlined"
                  sx={{
                    p: '0',
                    width: '6rem',
                    height: { lg: '4.8rem', xs: '3.6rem' },
                    fontSize: '1.6rem',
                    fontWeight: 500,
                    minWidth: 'auto',
                    '&:hover': {
                      backgroundColor: '#F2F6FF',
                      border: '1px solid #3770FF',
                      color: '#3770FF',
                    },
                  }}
                  onClick={() => handleCopyClipBoard(selectedToken.token)}
                >
                  복사
                </Button>
              </Box>
            </Stack>

            <Box
              sx={{
                width: 'inherit',
                display: 'flex',
                mt: '2.8rem',
                justifyContent: 'space-between',
              }}
            >
              <Button
                startIcon={<TrashCanIcon color={theme.palette.common.black} />}
                sx={{
                  ...style.dialogContentButton,
                  color: '#989898',
                  p: '0.8rem 0.4rem',
                  height: '4rem',

                  ' & .MuiButton-startIcon.MuiButton-iconSizeMedium': { ml: 0 },

                  '&.Mui-disabled': { color: '#989898' },

                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme.palette.common.red,
                    '& svg path': {
                      transition: 'stroke 0.3s',
                      stroke: theme.palette.common.red,
                    },
                  },
                  '& svg path': {
                    transition: 'stroke 0.3s',
                    stroke: '#989898',
                  },
                }}
                disabled={!selectedToken}
                onClick={deleteToken}
              >
                토큰 삭제
              </Button>

              <Box sx={{ display: 'flex', gap: '1.2rem' }}>
                <Button
                  variant="contained"
                  sx={{ ...style.dialogContentButton, width: { lg: '16rem', xs: '14rem' } }}
                  disabled={!selectedToken}
                  onClick={updateToken}
                >
                  확인
                </Button>
              </Box>
            </Box>
          </OpacityMotionDiv>
        </Stack>
      )
  }
}

const DialogState = ({ dialogSnakeBar }) => {
  const theme = useTheme()
  const snakeStyle = {
    fontStyle: {
      fontSize: '1.6rem',
      fontWeight: 600,
      color: theme.palette.common.white,
    },

    boxStyle: {
      height: '3.6rem',
      display: 'flex',
      alignItems: 'center',
      px: '1.2rem',
      borderRadius: '4px',
      mt: '0.8rem',
      mb: '0.4rem',
    },
  }
  if (!dialogSnakeBar) return

  if (dialogSnakeBar === 'success') {
    return (
      <Box
        sx={{
          ...snakeStyle.boxStyle,
          backgroundColor: theme.palette.draph.darkblue,
        }}
      >
        <Typography
          sx={{ mx: '0.8rem', fontSize: '1.6rem', fontWeight: 600, color: snakeStyle.fontStyle }}
        >
          API 토큰이 성공적으로 생성되었습니다!
        </Typography>
      </Box>
    )
  }

  if (dialogSnakeBar === 'copy') {
    return (
      <Box
        sx={{
          ...snakeStyle.boxStyle,
          backgroundColor: '#06A561',
        }}
      >
        <APICopyIcon />
        <Typography
          sx={{ mx: '0.8rem', fontSize: '1.6rem', fontWeight: 600, color: snakeStyle.fontStyle }}
        >
          API 토큰이 성공적으로 복사되었습니다!
        </Typography>
      </Box>
    )
  }

  if (dialogSnakeBar === 'delete') {
    return (
      <Box
        sx={{
          ...snakeStyle.boxStyle,
          backgroundColor: '#F12B5B',
        }}
      >
        <TrashCanIcon color={theme.palette.common.white} />
        <Typography
          sx={{ mx: '0.8rem', fontSize: '1.6rem', fontWeight: 600, color: snakeStyle.fontStyle }}
        >
          API 토큰이 성공적으로 삭제되었습니다!
        </Typography>
      </Box>
    )
  }

  if (dialogSnakeBar === 'update') {
    return (
      <Box
        sx={{
          ...snakeStyle.boxStyle,
          backgroundColor: theme.palette.draph.darkblue,
        }}
      >
        <Typography
          sx={{ mx: '0.8rem', fontSize: '1.6rem', fontWeight: 600, color: snakeStyle.fontStyle }}
        >
          API 토큰이 성공적으로 수정되었습니다.
        </Typography>
      </Box>
    )
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  width: '24rem',
  height: '5.2rem',
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '1.6rem',
  fontWeight: 600,
  borderBottom: '1px solid #D9D9D9',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    width: '9.6rem',
    height: '4rem',
    padding: 0,
  },
}))

const RegularTableCell = styled(TableCell)(({ theme }) => ({
  width: '47.2rem',
  height: '5.2rem',
  textAlign: 'center',
  verticalAlign: 'middle',
  backgroundColor: ' #F8F8F8',
  fontSize: '1.6rem',
  fontWeight: 500,
  borderBottom: '1px solid #D9D9D9',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    width: '22.4rem',
    height: '4rem',
    padding: 0,
  },
}))

const NoBorderBottomTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    borderBottom: 0,
  },
}))

const Features = () => {
  const isMobile = useMobileMediaQuery()
  return (
    <CenterAlignStack
      sx={{
        p: { lg: '5.2rem 0 3rem 0', xs: '3.6rem 0 4rem 0' },
        width: { lg: '71.5rem', xs: '32rem' },
      }}
    >
      <Typography sx={{ ...style.apiMainTitle }}>상세 기능</Typography>
      <Typography sx={{ ...style.apiMainSubTitle }}>
        Draph Art의 API에 대한 기능 설명입니다.
      </Typography>

      <Stack sx={{ gap: '0.4rem', mt: { lg: '4rem', xs: '3.2rem' }, width: 'inherit' }}>
        <Typography sx={{ fontSize: { lg: '2.4rem', xs: '1.6rem' }, fontWeight: 600 }}>
          지원 기능
        </Typography>
        <Typography sx={{ fontSize: { lg: '1.6rem', xs: '1.3rem' } }}>
          Draph Art는 상업용 이미지를 제작에 필수적인 배경 생성, 모델 생성 등 다양한 AI기능을
          제공합니다.{isMobile && <br />}또한 이용자가 원하는 배경의 테마를 선택하거나 직접 입력하여
          상품의 톤과 무드에 맞춘 배경을 생성할 수 있습니다.
        </Typography>
      </Stack>

      <Stack sx={{ mt: '2.4rem' }}>
        <TableContainer component={Paper} sx={{ borderRadius: { lg: '20px', xs: '12px' } }}>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell>광고</StyledTableCell>
                <RegularTableCell>광고 소재 생성</RegularTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>상품</StyledTableCell>
                <RegularTableCell>배경 생성, 배경 제거, 묶음 상품, 배경 연장</RegularTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>모델</StyledTableCell>
                <RegularTableCell>마네킹 &rarr; 모델, 모델 생성, 얼굴 변경</RegularTableCell>
              </TableRow>
              <NoBorderBottomTableRow>
                <StyledTableCell>포트폴리오</StyledTableCell>
                <RegularTableCell>포트폴리오</RegularTableCell>
              </NoBorderBottomTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </CenterAlignStack>
  )
}

const Example = () => {
  const isMobile = useMobileMediaQuery()

  const [value, setValue] = useState('python')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleCopyClipBoard = async copy => {
    try {
      await navigator.clipboard.writeText(copy)
      enqueueSnackbar('클립보드에 링크가 복사되었어요.', { variant: 'success' })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <CenterAlignStack
      sx={{
        p: { lg: '5.2rem 0 3rem 0', xs: '3.6rem 0 4rem 0' },
        width: { lg: '71.5rem', xs: '32rem' },
      }}
    >
      <Typography sx={{ ...style.apiMainTitle }}>활용 예시</Typography>
      <Typography sx={{ ...style.apiMainSubTitle }}>
        Draph Art의 API를 활용한 다양한 예시입니다.
      </Typography>

      <Stack sx={{ gap: '0.4rem', mt: { lg: '4rem', xs: '3.2rem' }, width: 'inherit' }}>
        <Typography sx={{ fontSize: { lg: '2.4rem', xs: '1.6rem' }, fontWeight: 600 }}>
          개발 언어 설정
        </Typography>
        <Typography sx={{ fontSize: { lg: '1.6rem', xs: '1.3rem' } }}>
          상단 탭을 클릭하여 개발 환경을 선택해 보세요.
        </Typography>
      </Stack>

      <Stack
        sx={{
          width: 'inherit',
          mt: '2.4rem',
          backgroundColor: '#F8F8F8',
          p: '1rem 2.25rem 0rem 2.25rem',
          borderRadius: '10px',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="code tabs"
          sx={{ borderBottom: '1px solid #E6E9F4;' }}
        >
          <Tab label="Python " value="python" />
          <Tab label="Node.js" value="javascript" />
          {/* <Tab label="JAVA" value="java" /> */}
        </Tabs>
        <Box sx={{ py: '2rem', position: 'relative' }}>
          <SyntaxHighlighter
            language={value}
            style={dracula}
            customStyle={{
              borderRadius: '10px',
              margin: 0,
              fontSize: '1.2rem',
              padding: '1.6rem 2rem',
            }}
          >
            {langCodeSample[value]}
          </SyntaxHighlighter>

          <Box sx={{ position: 'absolute', right: '1rem', top: '3rem' }}>
            <Button
              style={{
                backgroundColor: '#EEEEEE',
                color: '#303030',
                fontSize: '1.2rem',
                padding: '0.4rem 0.6rem 0.6rem 0.6rem',
                cursor: 'pointer',
                borderRadius: '4px',
                minWidth: 'auto',
                fontWeight: 400,
              }}
              onClick={() => handleCopyClipBoard(langCodeSample[value])}
            >
              Copy
            </Button>
          </Box>
        </Box>
      </Stack>
    </CenterAlignStack>
  )
}
