import Box from '@mui/material/Box'
import { useEffect, useRef, useState } from 'react'
import { ThumbnailImage } from './ArtworkRowImageSwiper'
import { getS3ImageSrc } from 'utils/common'
import { checkedURLforNoCache } from 'utils/artwork'
import { Grid } from '@mui/material'

export function PieceGallery({
  artwork,
  checked,
  handleCheck,
  open,
  setOpen,
  isRemovedBg,
  isBundlePage,
  sx,
  previewSwiper,
  handleClcikImage,
  ...props
}) {
  const ref = useRef(null)

  const [h, setH] = useState(0)

  useEffect(() => {
    setH(ref.current.clientHeight)
  }, [open, ref?.current?.clientHeight])

  return (
    <Box
      component="div"
      ref={ref}
      sx={{
        // background: 'gold',
        background: 'white',
        position: 'absolute',
        width: '100%',
        // maxHeight: '100%',
        maxHeight: 'calc(100% - 3rem)',
        overflowY: 'auto',
        top: open ? `calc(100% - ${h}px)` : '100%',
        transition: 'top 0.5s ease',
        zIndex: 2,
        pb: 'calc(32px + 10px)', // 전체보기/접기 버튼 높이 + 간격
        pt: '1.6rem',
        boxShadow: open ? '0px -10px 20px 0px #0000001A' : '',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        px: '1.2rem',

        ...sx,
      }}
    >
      <Grid
        container
        sx={{
          // background: 'pink',
          '& .MuiGrid-item': {
            display: 'flex',
            mb: '0.8rem',

            '&:nth-of-type(3n-2)': {
              justifyContent: 'start',
            },
            '&:nth-of-type(3n-1)': {
              justifyContent: 'center',
            },
            '&:nth-of-type(3n)': {
              justifyContent: 'end',
            },
          },
        }}
      >
        {artwork?.pieces?.map((piece, idx) => {
          const path = piece.path
          const img = getS3ImageSrc(checkedURLforNoCache(path))

          return (
            <Grid item key={idx} xl={4} lg={4} md={4} xs={4}>
              <Box
                component="div"
                sx={{
                  width: '12rem',
                  height: '12rem',
                  '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  },
                }}
                // onClick={() => {
                //   const ck =
                //     checked?.length > 0 && typeof checked[idx] !== typeof undefined
                //       ? checked[idx]
                //       : false
                //   handleCheck(idx, !ck)
                // }}
                // onClick={() => {
                //   if (previewSwiper) {
                //     setOpen(false)
                //     previewSwiper.slideTo(idx, 200)
                //   }
                // }}
                onClick={() => {
                  handleClcikImage(idx)
                }}
              >
                <ThumbnailImage
                  img={img}
                  handleCheck={ck => {
                    handleCheck(idx, ck)
                  }}
                  checked={
                    checked?.length > 0 && typeof checked[idx] !== typeof undefined
                      ? checked[idx]
                      : false
                  }
                  isRemovedBg={isRemovedBg}
                  darkCheckbox={isBundlePage}
                />
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
