import {
  Box,
  Typography,
  styled,
  useTheme,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Slide,
  CircularProgress,
} from '@mui/material'
import { Stack } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import { apis } from 'apis'
import { userAtom, userCreditAtom, userSubscriptionIdAtom } from 'atoms'
import { CenterAlignBox, CenterAlignStack, CustomPagination } from 'components'
import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { MYPAGE_PERCENTAGE_WIDHT, MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import moment from 'moment'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
  getCurrencyFormatter,
  isKo,
  numberCommaFormatter,
  trasnferDate,
  utcToLocal,
} from 'utils/common'
import useConfirm from 'hooks/useConfirm'
import { useTranslation } from 'react-i18next'
import { usePricing } from 'hooks/usePricing'
import { English, Korean } from 'hooks/useLanguage'

export const HeaderName = styled(Typography)(({ theme }) => ({
  fontSize: '1.7rem',

  fontWeight: 700,
  color: theme.palette.common.white,
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    fontWeight: 700,
  },
}))

export const CellTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 300,
  color: theme.palette.common.black,
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    fontWeight: 400,
  },

  '&.canceled': {
    textDecoration: 'line-through',
    textDecorationThickness: '0.1rem',
  },
}))

export const footerStyle = {
  fontWeight: 400,
  fontSize: '1.6rem',
}

export const mobileFooterStyle = {
  fontWeight: 400,
  fontSize: '1.2rem',
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.black,
  border: 0,
  width: '88.4rem',
  minHeight: '41rem',
  paddingBottom: '3rem',
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100% !important',
  },

  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
    // margin: '0 1.3rem',

    '&.MuiDataGrid-cell:last-child': {
      width: '0 !important',
      margin: '0 !important',
      padding: '0 !important',
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    borderBottom: '0.1rem solid',
    backgroundColor: theme.palette.common.black,
    borderRadius: 0,
    justifyContent: 'center',

    '& .MuiDataGrid-columnHeader': {
      '&:focus-within': { outline: 'none !important' },
    },
  },

  '& .MuiDataGrid-row': {
    // padding: '0 7.6rem',
    width: '100% !important',
    minHeight: '5.8rem !important',
    justifyContent: 'center',

    borderBottom: '0.05rem solid #8F8F8F',

    '&:hover': {
      background: 'transparent',
      // background: '#F8F8F8',
    },

    '&.Mui-selected': {
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
  },

  '& .MuiDataGrid-cell': {
    borderBottom: 0,

    '&:focus-within': { outline: 'none !important' },
    '&:last-child': {
      // width: '24.6rem !important',
      // minWidth: '24.6rem !important',
      // maxWidth: '24.6rem !important',
    },
  },

  '& .MuiDataGrid-columnSeparator': { display: 'none' },

  [theme.breakpoints.down('lg')]: {
    '& .MuiDataGrid-columnHeaders': {
      minHeight: '4rem !important',
      maxHeight: '4rem !important',
      lineHeight: '4rem !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '4rem !important', // '& .MuiDataGrid-columnHeaders' 높이와 맞춰야함
    },
    '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
      margin: '0rem',
      padding: 0,
    },
  },
}))

const StyledDataGridMobile = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.black,
  border: 0,
  width: '100%',
  minHeight: '41rem',
  paddingBottom: '3rem',
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100% !important',
  },

  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
    // margin: '0 1.3rem',

    '&.MuiDataGrid-cell:last-child': {
      width: '0 !important',
      margin: '0 !important',
      padding: '0 !important',
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    borderBottom: '0.1rem solid',
    backgroundColor: theme.palette.common.black,
    borderRadius: 0,
    justifyContent: 'center',

    '& .MuiDataGrid-columnHeader': {
      '&:focus-within': { outline: 'none !important' },
    },
  },

  '& .MuiDataGrid-row': {
    // padding: '0 7.6rem',
    width: '100% !important',
    minHeight: '5.6rem !important',
    justifyContent: 'center',
    borderBottom: '0.05rem solid #8F8F8F',

    '&:hover': {
      background: 'transparent',
    },

    '&.Mui-selected': {
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
      },
    },
  },

  '& .MuiDataGrid-cell': {
    borderBottom: 0,

    '&:focus-within': { outline: 'none !important' },
    '&:last-child': {
      // width: '24.6rem !important',
      // minWidth: '24.6rem !important',
      // maxWidth: '24.6rem !important',
    },
  },

  '& .MuiDataGrid-columnSeparator': { display: 'none' },

  [theme.breakpoints.down('lg')]: {
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '5.6rem !important', // '& .MuiDataGrid-columnHeaders' 높이와 맞춰야함
    },
    '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
      margin: '0rem',
      padding: 0,
    },
  },
}))

export default function UserCharge({ currentSubscription, refreshSubscription }) {
  const postsPerPage = 10
  const theme = useTheme()

  const { getPlanDisplayName } = usePricing()
  const { t, i18n } = useTranslation()

  const [page, setPage] = useState(1)
  const [userCredit, setUserCredit] = useRecoilState(userCreditAtom)
  const [visiblePosts, setVisiblePosts] = useState([])
  const [creditInfo, setCreditInfo] = useState(null)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null)

  const isMobile = useMobileMediaQuery()

  const columns = [
    {
      field: 'date',
      flex: 1.5,
      headerName: t('credit.charged_date'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        return params.value ? (
          <CellTypography className={`${params.row.payment_canceled ? 'canceled' : ''}`}>
            <Desktop>{trasnferDate(moment.utc(params.value).toDate(), 'dot', i18n)}</Desktop>
            <Mobile>{trasnferDate(moment.utc(params.value).toDate(), 'dotM', i18n)}</Mobile>
          </CellTypography>
        ) : (
          <></>
        )
      },
    },
    {
      field: 'type',
      headerName: t('credit.charged_details'),
      align: 'center',
      headerAlign: 'center',
      flex: 1.5,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        const creditTypeArr = params.value.split('_')
        let value = params.value
        if (['paygo', 'subscription'].includes(creditTypeArr[0])) {
          value = getPlanDisplayName(creditTypeArr[1], i18n.language)
        } else if (creditTypeArr[0].includes('coupon')) {
          value = isKo(i18n) ? params.row.note : 'coupon'
        } else if (creditTypeArr[0].includes('admin')) {
          value = isKo(i18n) ? params.row.note : 'admin'
        }
        return (
          <CellTypography className={`${params.row.payment_canceled ? 'canceled' : ''}`}>
            {value}
          </CellTypography>
        )
      },
    },
    {
      field: 'amount',
      headerName: t('credit.charged_credit'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      flex: 1.5,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => (
        <CellTypography className={`${params.row.payment_canceled ? 'canceled' : ''}`}>
          {params.value}
        </CellTypography>
      ),
    },
    {
      field: 'paid_price',
      headerName: t('credit.charged_paid'),
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        const currency = params.row.currency ?? 'krw'
        const currencyFormatter = getCurrencyFormatter(currency)
        const v = currencyFormatter.format(params.value)

        const withComma = numberCommaFormatter(params.value)

        return (
          <CellTypography className={`${params.row.payment_canceled ? 'canceled' : ''}`}>
            <English>{!isNaN(params.value) ? v : '-'}</English>
            <Korean>{!isNaN(params.value) ? withComma : '-'} 원</Korean>
          </CellTypography>
        )
      },
    },
    {
      field: 'expires',
      headerName: t('credit.charged_expired'),
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        //   params.value ? (
        //   <CellTypography className={`${params.row.payment_canceled ? 'canceled' : ''}`}>
        //     <Desktop>{utcToLocal(params.value, 'YYYY.MM.DD')}</Desktop>
        //     <Mobile>{utcToLocal(params.value, 'YY.MM.DD')}</Mobile>
        //   </CellTypography>
        // ) : (
        //   <></>
        // ),

        const creditType = params.row.type
        let content = params.value ? utcToLocal(params.value, 'YY.MM.DD') : ''
        let contentEn = params.value ? utcToLocal(params.value, 'YY.MM.DD') : ''

        if (creditType.includes('subscription') || params.row.subscription_id) {
          // TODO 번역
          content = '구독 종료시'
          contentEn = 'Until Subs Ends'
        } else {
          if (params.row.date && params.value) {
            const d1 = moment.utc(params.row.date)
            const d2 = moment.utc(params.value)

            const d = moment.duration(d2.diff(d1)).asMonths().toFixed()

            if (d === '1') {
              content = '1개월'
              contentEn = '1 Month'
            } else if (d === '24') {
              content = '2년'
              contentEn = '2 Years'
            }
          }
        }

        return (
          <CellTypography
            className={`${params.row.payment_canceled ? 'canceled' : ''}`}
            sx={{ lineHeight: 1.1 }}
          >
            <Korean>{content}</Korean>
            <English>{contentEn}</English>
          </CellTypography>
        )
      },
    },
    {
      field: 'etc',
      headerName: t('credit.charged_remark'),
      headerAlign: 'center',
      align: 'center',
      hide: isMobile,
      flex: 1.5,
      sortable: false,
      renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
      renderCell: params => {
        const dPlus7 = moment.utc(params.row.date).add(7, 'days').toDate()
        const now = new Date()
        const condition =
          params.row.amount === params.row.remain &&
          dPlus7 > now &&
          params.row.paid_price &&
          params.row.order_id?.length > 0

        return condition ? (
          <Typography
            fontSize="1.3rem"
            color={theme => theme.palette.common.gray}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setSelectedPaymentInfo(params.row)
              setOpenCancelDialog(true)
            }}
          >
            {t('credit.canceling_payment')}
          </Typography>
        ) : params.row.payment_canceled ? (
          <Typography fontSize="1.3rem" color={theme => theme.palette.common.gray}>
            {t('credit.cancel_complete')}
          </Typography>
        ) : (
          <></>
        )
      },
    },
  ]

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const refreshData = () => {
    apis.user.getCredit().then(response => {
      setUserCredit(response.data.credit)
    })
    apis.user.getCreditHistory('charge').then(response => {
      setCreditInfo(response.data)
    })

    refreshSubscription()
  }

  useEffect(() => {
    refreshData()
  }, [])

  useEffect(() => {
    if (creditInfo) {
      const startIndex = (page - 1) * postsPerPage
      const endIndex = startIndex + postsPerPage
      setVisiblePosts(creditInfo.slice(startIndex, endIndex))
    }
  }, [creditInfo, page])

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ width: MYPAGE_PERCENTAGE_WIDHT, pl: MYPAGE_SIDE_MARGIN }}>
          <CenterAlignStack sx={{ width: '88.4rem', mb: isKo(i18n) ? '2.6rem' : '1.5rem' }}>
            <Typography sx={{ fontWeight: 800, fontSize: '3rem' }}>
              {t('mypage_menu.charged_credit')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                mt: isKo(i18n) ? 0 : '2rem',
              }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: '1.6rem' }}>
                {t('credit.my_credit')}
                <span
                  style={{ fontWeight: 800, fontSize: '2rem', color: theme.palette.draph.blue }}
                >
                  {userCredit ? numberCommaFormatter(userCredit) : 0} {t('credit.credit')}
                </span>
              </Typography>
              {currentSubscription?.id && (
                <Box sx={{ border: '2px solid #4D80FF', borderRadius: '20px' }}>
                  <Typography
                    sx={{
                      margin: '0.5rem 1.8rem',
                      fontWeight: 700,
                      fontSize: '1.4rem',
                    }}
                  >
                    {getPlanDisplayName(currentSubscription?.plan_id, i18n.language)}{' '}
                    {t('credit.subscribed')} (
                    {trasnferDate(moment.utc(currentSubscription?.last_paid).toDate(), 'dot', i18n)}
                    ~ )
                  </Typography>
                </Box>
              )}
            </Box>
          </CenterAlignStack>

          <StyledDataGrid
            disableColumnMenu
            getRowHeight={() => 'auto'}
            rows={visiblePosts ?? []}
            components={{
              NoRowsOverlay: () => (
                <>
                  <CenterAlignBox sx={{ py: '3rem' }}>{t('credit.empty_charged')}</CenterAlignBox>
                </>
              ),
            }}
            keepNonExistentRowsSelected
            columns={columns}
            pageSize={postsPerPage}
            // rowsPerPageOptions={[5, 10, 15]}
            autoHeight
            hideFooter
            initialState={{
              sorting: {},
            }}
          />

          <CustomPagination
            count={Math.ceil((creditInfo?.length ?? 0) / postsPerPage)}
            page={page}
            onChange={(e, value) => {
              setPage(value)
            }}
          />
        </CenterAlignStack>
      </Desktop>

      <CancelDialog
        open={openCancelDialog}
        setOpen={setOpenCancelDialog}
        paymentInfo={selectedPaymentInfo}
        refreshData={refreshData}
      />
      <Mobile>
        <CenterAlignStack sx={{ width: '100%' }}>
          <CenterAlignStack sx={{ mb: '2.8rem' }}>
            <Typography sx={{ fontSize: ' 1.2rem', fontWeight: 500, mt: '3.4rem' }}>
              {t('credit.my_credit')}
              <span
                style={{
                  fontWeight: 800,
                  fontSize: '2rem',
                  color: theme.palette.draph.blue,
                  marginLeft: '1rem',
                }}
              >
                {userCredit ? numberCommaFormatter(userCredit) : 0} {t('credit.credit')}
              </span>
            </Typography>
            {currentSubscription?.id && (
              <Box
                sx={{
                  border: '2px solid #4D80FF',
                  borderRadius: '20px',
                  mt: '1rem',
                }}
              >
                <Typography
                  sx={{
                    margin: '0.4rem 1.3rem',
                    fontWeight: 700,
                    fontSize: '1.2rem',
                  }}
                >
                  {getPlanDisplayName(currentSubscription?.plan_id, i18n.language)}{' '}
                  {t('credit.subscribed')} (
                  {trasnferDate(moment.utc(currentSubscription?.last_paid).toDate(), 'dotM', i18n)}~
                  )
                </Typography>
              </Box>
            )}
          </CenterAlignStack>
          <StyledDataGridMobile
            disableColumnMenu
            getRowHeight={() => 'auto'}
            rows={visiblePosts ?? []}
            components={{
              NoRowsOverlay: () => (
                <>
                  <CenterAlignBox sx={{ py: '3rem' }}>{t('credit.empty_charged')}</CenterAlignBox>
                </>
              ),
            }}
            keepNonExistentRowsSelected
            columns={columns}
            pageSize={postsPerPage}
            // rowsPerPageOptions={[5, 10, 15]}
            autoHeight
            hideFooter
            initialState={{
              sorting: {},
            }}
          />

          <CustomPagination
            count={Math.ceil((creditInfo?.length ?? 0) / postsPerPage)}
            page={page}
            onChange={(e, value) => {
              setPage(value)
            }}
          />
        </CenterAlignStack>
      </Mobile>
    </>
  )
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

function CancelDialog({ open, setOpen, paymentInfo, refreshData }) {
  const [currencyFormatter, setCurrencyFormatter] = useState(getCurrencyFormatter('krw'))
  const [openSecondDialog, setOpenSecondDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [refundPrice, setRefundPrice] = useState()
  const [remainCredit, setRemainCredit] = useState()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()

  const { showConfirm } = useConfirm()
  const { paymentMethodDict } = usePricing()

  useEffect(() => {
    if (paymentInfo) {
      // console.log(paymentInfo)
      setCurrencyFormatter(getCurrencyFormatter(paymentInfo?.currency ?? 'krw'))
    }
  }, [paymentInfo])
  useEffect(() => {
    if (open) {
      setIsLoading(true)
      apis.user
        .getRefundInfo(paymentInfo.id)
        .then(response => {
          const d = response.data
          setRefundPrice(d.refund_price)
          setRemainCredit(d.remain_credit)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [open])

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    setOpen(false)
    setRefundPrice(null)
    setRemainCredit(null)
  }

  const secondDialogHandleClose = () => {
    setOpenSecondDialog(false)
  }

  const handleCanclePayment = () => {
    setIsLoading(true)

    apis.payment
      .cancel({ id: paymentInfo.id })
      .then(response => {
        if (response.data.success) {
          showConfirm({
            alertOnly: true,
            content: (
              <Typography>
                {paymentInfo.subscription_id && paymentInfo.subscription_valid
                  ? '결제 및 구독 취소 요청이 완료되었습니다.'
                  : '결제 취소 요청이 완료되었습니다.'}
              </Typography>
            ),
            onConfirm: () => {
              handleClose()
            },
          })
          refreshData()
        } else {
          let message = response.data.message ?? '오류가 발생하였습니다'
          message = `[${response.data.error}] ${message}`

          alert(message)
          handleClose()
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: '20px',
            boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        {isLoading && (
          <Box
            sx={{
              background: 'black',
              opacity: 0.3,
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 99,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
        <DialogContent sx={{ width: { lg: '400px' }, padding: '35px 0 30px 0' }}>
          <CenterAlignStack>
            <Typography sx={{ fontWeight: 700, fontSize: '20px', textAlign: 'center' }}>
              {t('credit.cancel_payment_title_a')}
              {isKo(i18n) && <br />}
              {t('credit.cancel_payment_title_b')}
            </Typography>

            <CenterAlignStack sx={{ mt: '23px', alignItems: 'flex-start' }}>
              <Typography sx={{ fontSize: '16px' }}>
                <span style={{ fontWeight: 700 }}>&#183; {t('credit.cancel_payment_table_1')}</span>{' '}
                : {currencyFormatter?.format(refundPrice).slice(1)}
                <Korean>원</Korean>
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                <span style={{ fontWeight: 700 }}>&#183; {t('credit.cancel_payment_table_2')}</span>{' '}
                : {remainCredit} {t('credit.charged_credit')}
              </Typography>
              <Typography sx={{ fontSize: '16px' }}>
                <span style={{ fontWeight: 700 }}>&#183; {t('credit.cancel_payment_table_3')}</span>{' '}
                : {paymentMethodDict[paymentInfo?.payment_method]}
              </Typography>
            </CenterAlignStack>

            <Stack
              sx={{
                width: '100%',
                height: '11.5rem',
                backgroundColor: theme.palette.common.lightgray,
                mt: '25px',
                px: '3.4rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                component="ul"
                sx={{
                  justifyContent: 'center',
                  pl: '0.5rem',
                  '& li': {
                    fontSize: '1.2rem',
                    fontWeight: 400,
                    listStyleType: 'none',
                    textIndent: '-0.8rem',
                    lineHeight: { lg: '14px', xs: '13px' },
                    '&::before': {
                      fontSize: '1.2rem',
                      content: '"•"',
                      marginRight: '0.3rem',
                    },
                  },
                }}
              >
                {paymentInfo?.subscription_id && paymentInfo?.subscription_valid ? (
                  <Typography component="li">
                    {t('credit.cancel_payment_notice_1_a')}
                    <span style={{ color: '#FF2323' }}>
                      {t('credit.cancel_payment_notice_1_b')}
                    </span>{' '}
                  </Typography>
                ) : (
                  <Typography component="li">
                    {t('credit.cancel_payment_notice_2_a')}
                    {!isKo(i18n) && <br />}
                    {t('credit.cancel_payment_notice_2_b')}
                  </Typography>
                )}
                <Typography component="li" sx={{ mt: '0.7rem' }}>
                  {t('credit.cancel_payment_notice_3_a')}

                  <br />
                  {t('credit.cancel_payment_notice_3_b')}
                </Typography>
              </Box>
            </Stack>
          </CenterAlignStack>
        </DialogContent>

        <DialogActions sx={{ p: 0 }}>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              width: '50%',
              height: '48px',
              fontSize: '16px',
              fontWeight: 600,
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              '&:hover': {
                color: theme.palette.common.white,
              },
              borderRadius: 0,
            }}
          >
            {t('button.close')}
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              // handleClose()
              // setOpenSecondDialog(true)
              handleCanclePayment()
            }}
            sx={{
              ml: '0 !important',
              width: '50%',
              height: '48px',
              fontSize: '16px',
              fontWeight: 600,
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.black,
              '&:hover': {
                backgroundColor: '#F8F8F8',
                color: theme => theme.palette.common.black,
              },
              borderRadius: 0,
            }}
          >
            {t('button.cancel_payment')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSecondDialog}
        onClose={secondDialogHandleClose}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': {
            borderRadius: '20px',
            boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
          },
        }}
        TransitionComponent={Transition}
      >
        <DialogContent sx={{ width: { lg: '400px' }, padding: '35px 0 30px 0' }}>
          <CenterAlignStack>
            <Typography sx={{ fontWeight: 700, fontSize: '18px', textAlign: 'center' }}>
              {t('credit.cancel_second_comment_1')}
              <br />
              {t('credit.cancel_second_comment_2')}
            </Typography>
          </CenterAlignStack>
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <Button
            variant="contained"
            onClick={secondDialogHandleClose}
            sx={{
              width: '50%',
              height: '48px',
              fontSize: '16px',
              fontWeight: 600,
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
              '&:hover': {
                color: theme.palette.common.white,
              },
            }}
          >
            {t('button.close')}
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              navigate('/inquiry')
            }}
            sx={{
              ml: '0 !important',
              width: '50%',
              height: '48px',
              fontSize: '16px',
              fontWeight: 600,
              backgroundColor: theme.palette.common.white,
              color: theme.palette.common.black,
              '&:hover': {
                backgroundColor: '#F8F8F8',
                color: theme => theme.palette.common.black,
              },
            }}
          >
            {t('button.inquiry')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
