import { FadeLoader } from 'react-spinners'
import { CenterAlignBox } from './CenterAlignContainer'
import { useTheme } from '@mui/material'

export default function Loading() {
  const theme = useTheme()
  return (
    <CenterAlignBox
      sx={{
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.5)',
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
        alignItems: 'center',
        zIndex: 4000,
      }}
    >
      <FadeLoader color={theme.palette.common.white} height={25} margin={16} radius={9} width={6} />
    </CenterAlignBox>
  )
}
