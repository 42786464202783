import { useEffect, useState } from 'react'
import { Outlet, useLocation, matchPath } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import MainAppBar, {
  APPBAR_DESKTOP,
  APPBAR_MOBILE,
  APPBAR_NONMEMBER_MOBILE,
  LINEBANNER_HEIGHT,
  APPBAR_NONMEMBER_DESKTOP,
  APPBAR_SUBMENU,
} from './MainAppBar'
import { ConfirmDialog } from 'components'
import { SnackbarProvider } from 'notistack'
import { StyledMaterialDesignContent } from 'components/CustomStyle'
import { CheckedCircleIconNotistack, ErrorTriangleIconNotistack } from 'theme/icon'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Tutorial } from 'theme/Tutorial'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import MainFooter, { FOOTER_DESKTOP, FOOTER_MOBILE } from './MainFooter'
import { Stack } from '@mui/material'
import MainDownBar from './MainDownBar'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import { artworkListsIsFoldedAtom, noticeBannersAtom, serverStatusAtom, tutorialAtom } from 'atoms'
import { getAccessToken } from 'utils/cookie'
import { useMenu } from 'hooks/useMenu'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    // overflowX: 'hidden',
  },
}))

const MainStyle = styled('div')(({ theme }) => ({
  // display: 'flex',
  // flexDirection: 'column',
  // height: '100vh',

  [theme.breakpoints.up('lg')]: {
    // paddingTop: APPBAR_DESKTOP,
    // overflow: 'hidden',
  },
  [theme.breakpoints.down('lg')]: {
    // paddingBottom: APPBAR_MOBILE,
    // scorll 생기는거 가리기 위해서 (전체 다시확인해야함.)
    // overflowX: 'hidden',
  },
}))

const OutletStyle = styled('div')(({ theme }) => ({
  minHeight: `calc(100vh - ${APPBAR_MOBILE})`,
  [theme.breakpoints.up('lg')]: {
    minHeight: `calc(100vh - ${APPBAR_DESKTOP})`,
  },
}))

const Wrapper = styled(Stack)(({ theme }) => ({}))

const snackbarProps = {
  maxSnack: 5,
  autoHideDuration: 1000,
  // style: {
  //   fontSize: '1.2rem',
  // },
}

export const ARTWORKLISTS_FOLDED_SPEED = 10

export default function MainLayout({ ...props }) {
  const { pathname } = useLocation()

  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)
  const showFooter = !matchPath('portfolio/:portfolioId', pathname)
  const isMobile = useMobileMediaQuery()
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const isHome = pathname === '/home' || pathname === '/'

  const noticeBanner = useRecoilValue(noticeBannersAtom)

  const token = getAccessToken()

  const showLineBanner = (isHome || !token || noticeBanner.length > 0) && !props?.hideLineBanner

  const { MAINTAIN_MENU } = useMenu()

  const [open, setOpen] = useState(false)

  const getAppBarHeight = () => {
    if (isMobile) {
      return showLineBanner ? APPBAR_NONMEMBER_MOBILE : APPBAR_MOBILE
    } else {
      if (isFolded.value) {
        return 0
      }

      if (showLineBanner && MAINTAIN_MENU.some(path => pathname.includes(path))) {
        return `calc(${APPBAR_DESKTOP} + ${LINEBANNER_HEIGHT} + ${APPBAR_SUBMENU})`
      }

      if (showLineBanner) {
        return `calc(${APPBAR_DESKTOP} + ${LINEBANNER_HEIGHT} )`
      }

      if (MAINTAIN_MENU.some(path => pathname.includes(path))) {
        return `calc(${APPBAR_DESKTOP} + ${APPBAR_SUBMENU})`
      }

      return APPBAR_DESKTOP
    }
  }

  return (
    <RootStyle
      style={tutorial.mode && tutorial.step === 0 && isMobile ? { overflowX: 'hidden' } : {}}
    >
      <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          alert: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <CheckedCircleIconNotistack style={isMobile ? { width: '2.2rem' } : null} />,
          error: <ErrorTriangleIconNotistack style={isMobile ? { width: '2.2rem' } : null} />,
        }}
        {...snackbarProps}
      >
        <MainAppBar
          onOpenSidebar={() => setOpen(true)}
          hideLineBanner={!showLineBanner}
          {...props.appBarProps}
        />
        <MainStyle
          sx={{
            paddingTop: getAppBarHeight(),
          }}
        >
          <Wrapper>
            <OutletStyle
              style={
                tutorial.mode
                  ? {
                      minHeight: `calc(100vh - ${APPBAR_DESKTOP} - ${FOOTER_DESKTOP})`,
                    }
                  : { background: props.paperColor ?? 'unset' }
              }
            >
              <Outlet />
            </OutletStyle>
            <MainFooter />
          </Wrapper>
        </MainStyle>

        <Mobile>{<MainDownBar />}</Mobile>
      </SnackbarProvider>
    </RootStyle>
  )
}
