import { useCallback, useEffect, useState } from 'react'

import {
  clicksAtom,
  imageAtom,
  mannequinLoadingAtom,
  maskImgArrayAtom,
  maskImgAtom,
  nukkiSAMModeAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  handleImageScale,
  imageData2DToImage,
  selectedMaskImageByposition,
} from 'components/fragment/fragmentHelpers'

import { Stage } from 'components'

export default function FragmentImages({
  files,
  uploadImgWidth,
  uploadImgHeight,
  usage = 'default',
}) {
  const clicks = useRecoilValue(clicksAtom)
  const setImage = useSetRecoilState(imageAtom)
  const setMaskImg = useSetRecoilState(maskImgAtom)
  const mode = useRecoilValue(nukkiSAMModeAtom)
  const [maskImgArray, setMaskImgArray] = useRecoilState(maskImgArrayAtom)
  const [mannequinLoading, setMannequinLoading] = useRecoilState(mannequinLoadingAtom)

  useEffect(() => {
    if (!clicks || mannequinLoading) return

    const imageArray = selectedMaskImageByposition(maskImgArray, clicks[0].y, clicks[0].x)

    setMaskImg(imageData2DToImage(imageArray, mode))
  }, [clicks])

  useEffect(() => {
    loadImage(files?.url)
  }, [])

  const loadImage = async url => {
    try {
      const img = new Image()
      img.src = url
      img.onload = () => {
        const { height, width, samScale } = handleImageScale(img)

        img.width = width
        img.height = height
        setImage(img)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return <Stage uploadImgWidth={uploadImgWidth} uploadImgHeight={uploadImgHeight} usage={usage} />
}
