import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function Promotion({ promotionPages }) {
  const { page } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    // if (page === undefined) navigate(`/promotion/${promotionPages.default}`)

    if (promotionPages[page] === undefined) navigate(`/promotion/${promotionPages.default}`)
  }, [page])

  let currentPage = promotionPages[page]

  if (!currentPage || !currentPage.open) {
    currentPage = promotionPages[promotionPages.default]
  }

  return currentPage.element
}
