import { configData } from 'config'
import { Box, Typography, Button, IconButton, Select, MenuItem } from '@mui/material'
import {
  ScrollToTop,
  ArtworkList,
  DevEndpointDialog,
  SearchInput,
  UploadImageDialog,
  ArtworkFilters,
  UploadWrapper,
  DragDropFullSize,
  EmptyArtworkList,
  UploadHeader,
  PortfolioUploadConfigComponent,
  GuideComponent,
  RemoveBgConfigComponent,
  GuestArtworkList,
  CenterAlignStack,
  CenterAlignBox,
} from 'components'
import { SelectSideIconThin, SettingIcon } from 'theme/icon'
import { styled, useTheme } from '@mui/material/styles'

import { useEffect, useState } from 'react'
import moment from 'moment'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  portfolioDetailAtom,
  portfolioArtworkAtom,
  userAtom,
  portfolioConfigAtom,
  artworkViewConfigAtom,
  userLikedBgAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  tutorialAtom,
  endpointAtom,
  artworkListsIsFoldedAtom,
  defaultPortfolioAtom,
  uploadDialogOpenAtom,
  dragDropOpenAtom,
  blockDragDropAtom,
  portfolioLastArtwork,
} from 'atoms'
import { apis } from 'apis'
import { BarLoader as Loader, PuffLoader } from 'react-spinners'
import {
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
} from 'selector'
import useConfirm from 'hooks/useConfirm'
import _, { debounce } from 'lodash'
import axios from 'axios'
import { getAccessToken } from 'utils/cookie'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { APPBAR_DESKTOP, APPBAR_MOBILE } from 'layouts/main/MainAppBar'
import MainFooter from 'layouts/main/MainFooter'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import * as config from 'config'
import { useMotionValueEvent, useScroll } from 'framer-motion'

import { PAGE_HEADER_HEIGHT, ScrollHandler } from '../PortfolioDetail'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { EmptyPageHeader, PageHeaderLayout } from '../PortfolioUpload'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { usePortfolio } from 'hooks/usePortfolio'
import { English, Korean } from 'hooks/useLanguage'
import { RemoveBgGuestConfigComponent } from 'components/portfolio/ConfigComponent'

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    // alignItems: 'center',
  },
}))

const uploadImglimit = 1

export default function GuestRemoveBg() {
  const theme = useTheme()
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)

  const token = getAccessToken()

  useEffect(() => {
    // ----- GA4 event -----
    // TODO
    // window.gtag('event', 'removebg', {})
    // ---------------------

    return () => {
      setBlockDragDrop(false)
    }
  }, [])

  if (token) {
    // 비회원 페이지이므로 회원은 나가주세요
    window.location.reload()
    return <></>
  }

  return (
    <>
      <RootStyle
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()

          if (!blockDragDrop) {
            setDragDropOpen(true)
          }
        }}
      >
        <>
          <UploadWrapper portfolioId={defaultPortfolio.id}>
            <Desktop>
              <CenterAlignStack
                sx={{
                  width: '100%',
                  position: 'relative',
                  background: theme => theme.palette.common.lightgray,
                }}
              >
                <UploadHeader
                  multiple={false}
                  uploadImglimit={uploadImglimit}
                  configComponent={<RemoveBgGuestConfigComponent uploadButtonOnly={true} />}
                  uploadButtonComponent={<RemoveBgGuestConfigComponent uploadButtonOnly={true} />}
                  gudieComponent={<GuideComponent />}
                  noConfigComponentMode={true}
                />
                <CenterAlignStack
                  sx={{
                    borderRadius: '3rem',
                    py: '0.95rem',
                    position: 'relative',
                    top: '-5rem',
                  }}
                >
                  <video
                    src="/static/video/guide/removebg_ko.mp4"
                    loop
                    autoPlay
                    style={{ width: '47.8rem' }}
                    muted
                  >
                    Your browser does not support the video tag.
                  </video>

                  <CenterAlignBox
                    sx={{
                      p: '1.2rem 1.6rem',
                      background: '#EEEEEE',
                      borderRadius: '3rem',
                      py: '0.95rem',
                      position: 'relative',
                      top: '2.8rem',

                      // mb: '2.8rem',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.4rem',
                        fontWeight: 600,
                        gap: '0.8rem',
                      }}
                    >
                      <BulbIcon />

                      <Korean>
                        드랩아트의 배경 제거 기능은{' '}
                        <span
                          style={{
                            background: theme.palette.draph.blue,
                            color: theme.palette.common.white,
                            padding: '0.3rem 0.8rem',
                            borderRadius: '4px',
                            fontSize: '1.3rem',
                            alignItems: 'center',
                          }}
                        >
                          무료
                        </span>{' '}
                        로 제공됩니다.
                      </Korean>
                      <English>
                        Draph Art&apos;s background removal feature is available for{' '}
                        <span
                          style={{
                            background: theme.palette.draph.blue,
                            color: theme.palette.common.white,
                            padding: '0.3rem 0.8rem',
                            borderRadius: '4px',
                            fontSize: '1.3rem',
                            alignItems: 'center',
                          }}
                        >
                          free
                        </span>
                      </English>
                    </Box>
                  </CenterAlignBox>
                </CenterAlignStack>
              </CenterAlignStack>
            </Desktop>
            <Mobile>
              <PageHeader />
            </Mobile>
          </UploadWrapper>

          <Desktop>
            <GuestArtworkList upload={true} hideGroupHeader={true} />
          </Desktop>
          <Mobile>
            <EmptyArtworkList
              message={
                <Typography
                  sx={{
                    color: theme => theme.palette.draph.blue,
                    fontSize: { lg: '1.6rem', xs: '1.3rem' },
                    cursor: 'pointer',
                    fontWeight: 700,
                    position: 'relative',
                    top: { lg: '-2.6rem', xs: '-1.5rem' },
                    animation: 'fadeIn 1s infinite alternate',
                  }}
                >
                  {/* TODO TODO TODO */}
                  <Korean>PC버전에서 지금 바로 무료로 사용해보세요!</Korean>
                  <English>Supported on the PC version only.</English>
                </Typography>
              }
            />
          </Mobile>
        </>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />

      {dragDropOpen && !blockDragDrop && <DragDropFullSize uploadImgLimit={uploadImglimit} />}
    </>
  )
}

function PageHeader() {
  const { t } = useTranslation()
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()

  const isOwner = useRecoilValue(isPortfolioOwnerSelector)

  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)

  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetPortfolioConfig = useResetRecoilState(portfolioConfigAtom)
  const resetUploadDialogOpen = useResetRecoilState(uploadDialogOpenAtom)

  const [uploading, setUploading] = useState(false)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [openEndpointDialog, setOpenEndpointDialog] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  // 누끼메뉴에 한하여.. 마지막 업로드한 아트워크 상태에 따라 업로드 버튼 비활성화 등의 처리해주기 위해
  const [nukkiUploadBlock, setNukkiUploadBlock] = useState(true)
  const lastArtwork = useRecoilValue(portfolioLastArtwork)

  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)

  const [intervalId, setIntervalId] = useState()

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  useEffect(() => {
    if (uploadOpen) {
      // ----- GA4 event -----
      window.gtag('event', 'removebg_begin', {})
      // ---------------------
    }
  }, [uploadOpen])

  useEffect(() => {
    if (lastArtwork) {
      const c = JSON.parse(lastArtwork.config)
      const diff = moment.duration(moment().diff(moment(lastArtwork.created)))
      const diffMinutes = parseInt(diff.asMinutes()) % 60

      if (
        c &&
        c.flag_white_cmp &&
        !c.flag_generate &&
        !c.flag_complex_cmp &&
        !c.flag_simple_cmp &&
        !c?.upload_group_uuid
      ) {
        if (config.ARTWORK_IN_PROGRESS_STATUS.includes(lastArtwork.status)) {
          // 가장 최근 아트워크가 진행중이면, (백엔드 이슈 등으로) 생성이 지연되고있을 때 시간 초과에 따른 버튼 활성화 해주기 위해 인터벌 세팅
          if (!intervalId) {
            const timerId = setInterval(() => {
              const diff = moment.duration(moment().diff(moment(lastArtwork.created)))
              const diffMinutes = parseInt(diff.asMinutes()) % 60
              if (
                !config.ARTWORK_IN_PROGRESS_STATUS.includes(lastArtwork.status) ||
                diffMinutes >= 1
              ) {
                setNukkiUploadBlock(false)
                clearInterval(timerId)
              }
            }, 60 * 1000)
            setIntervalId(timerId)
          }
        }

        if (!config.ARTWORK_IN_PROGRESS_STATUS.includes(lastArtwork.status) || diffMinutes >= 1) {
          setNukkiUploadBlock(false)
        } else {
          setNukkiUploadBlock(true)
        }
      } else {
        setNukkiUploadBlock(false)
      }
    } else {
      setNukkiUploadBlock(false)
    }
  }, [lastArtwork])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter])

  const handleUpload = async files => {
    // 누끼따기 메뉴의 경우, 한꺼번에 1장만 업로드했을 때 무료, 한꺼번에 2장 이상 업로드시 크레딧을 차감하는데
    // 차감하는 방법은...
    //    - n장중 앞쪽 (n-1)장은 일반적으로 업로드
    //    - 마지막 1장에는 '아트워크 설정보다 우선시 적용되는 크레딧 차감 플래그' 를 설정하여 따로 업로드
    // 즉, 업로드 api 호출을 2번 따로 한다

    let uploadFiles = []
    if (files.length > 1) {
      uploadFiles = await prepareUpload(files, null, 'artwork_create_nukki_bulk', true)
    } else {
      uploadFiles = await prepareUpload(files)
    }
    if (!uploadFiles) return

    setUploadButtonDiasbled(true)
    setUploading(true)

    // ----- GA4 event -----
    window.gtag('event', 'removebg_upload', { count: uploadFiles.length })
    // ---------------------

    if (uploadFiles.length > 1) {
      const groupUid = uuidv4()
      const addOption = { upload_group_uuid: groupUid }

      const group1 = uploadFiles.slice(0, uploadFiles.length - 1)
      const group2 = [uploadFiles[uploadFiles.length - 1]]

      // group1 업로드
      const formData = await makeUploadFormData(group1, addOption)
      const formData2 = await makeUploadFormData(group2, {
        ...addOption,
        charge_credit_priority: 'artwork_create_nukki_bulk',
      })
      setUploadOpen(false)
      console.log('--------- start uploading')

      await apis.appfront.upload(formData).catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })

      // group2 (1개) 업로드
      apis.appfront
        .upload(formData2)
        .then(() => {
          checkUserCredit()
          setUploading(false)
          setUploadButtonDiasbled(false)
          refreshArtworks()

          if ('Notification' in window && Notification.permission === 'default') {
            showBrowserNotificationDialog()
          }
        })
        .catch(() => {
          setUploading(false)
          setUploadButtonDiasbled(false)
        })
    } else {
      // 1장 무료 업로드

      const formData = await makeUploadFormData(uploadFiles)
      console.log('--------- start uploading')
      setUploading(true)
      setUploadOpen(false)

      apis.appfront
        .upload(formData)
        .then(() => {
          checkUserCredit()
          setUploading(false)
          setUploadButtonDiasbled(false)
          refreshArtworks()

          if ('Notification' in window && Notification.permission === 'default') {
            showBrowserNotificationDialog()
          }
        })
        .catch(() => {
          setUploading(false)
          setUploadButtonDiasbled(false)
        })
    }
  }

  return (
    <>
      <PageHeaderLayout
        headerProps={{
          sx: {
            opacity: isFolded.value ? 0 : 1,
            transition: 'all 0.2s ease',
          },
          zIndex: isFolded.value ? -1 : null,
        }}
        uploadButtonProps={{
          disabled: uploading || !isOwner || uploadButtonDisabled || nukkiUploadBlock,
          onClick: () => {
            tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_1' }))
            setUploadOpen(true)
          },
        }}
        uploading={uploading || nukkiUploadBlock}
        uploadDialog={
          uploadOpen && (
            <UploadImageDialog
              uploadImage={handleUpload}
              uploadButtonTitle={t('button.nukki_upload')}
            />
          )
        }
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
          />
        }
        comment={
          nukkiUploadBlock ? (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: { lg: '1.4rem', xs: '1.2rem' },
                fontWeight: 400,
                position: 'relative',
                top: { lg: '-2.6rem', xs: '-1.5rem' },
              }}
            >
              {t('upload_header_nukki_comment_a')}
              <Mobile>
                <br />
              </Mobile>
              {t('upload_header_nukki_comment_b')}
            </Typography>
          ) : (
            <Typography
              sx={{
                color: theme => theme.palette.draph.blue,
                fontSize: { lg: '1.6rem', xs: '1.3rem' },
                cursor: 'pointer',
                fontWeight: 700,
                position: 'relative',
                top: { lg: '-2.6rem', xs: '-1.5rem' },
                animation: 'fadeIn 1s infinite alternate',
              }}
              onClick={() => {
                // ----- GA4 event -----
                // TODO
                // window.gtag('event', 'non_member_sign_up_now', {})
                // ---------------------
              }}
            ></Typography>
          )
        }
      />
    </>
  )
}

const BulbIcon = () => (
  <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 10.6C8.66667 9.8 9.08333 9.24 9.75 8.6C10.5833 7.88 11 6.84 11 5.8C11 4.52696 10.4732 3.30606 9.53553 2.40589C8.59785 1.50571 7.32608 1 6 1C4.67392 1 3.40215 1.50571 2.46447 2.40589C1.52678 3.30606 1 4.52696 1 5.8C1 6.6 1.16667 7.56 2.25 8.6C2.83333 9.16 3.33333 9.8 3.5 10.6M3.5 13.8H8.5M4.33333 17H7.66667"
      stroke="#2C4DFF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
const FreeBadge = () => (
  <CenterAlignBox
    sx={{
      borderRadius: '4px',
      background: theme => theme.palette.draph.newblue,
      color: 'white',
      fontWeight: 600,
      fontSize: '1.2rem',
      p: '0.3rem 0.75rem',
    }}
  >
    FREE
  </CenterAlignBox>
)
