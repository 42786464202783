import crudAxios from './configs/crud'

export const blogAPI = {
  getArticles: lang => {
    return crudAxios.get(`/blog/article?lang=${lang}`)
  },
  getArticle: id => {
    return crudAxios.get(`/blog/article/${id}`)
  },

  getEvents: lang => {
    return crudAxios.get(`/event/?lang=${lang}`)
  },
  getEvent: id => {
    return crudAxios.get(`/event/${id}`)
  },
}
