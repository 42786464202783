import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import {
  defaultPortfolioAtom,
  endpointAtom,
  portfolioDetailAtom,
  portfolioTypeAtom,
  userAtom,
} from 'atoms'
import { CenterAlignStack } from 'components'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { Box, Paper, Stack } from '@mui/material'
import { apis } from 'apis'
import useConfirm from 'hooks/useConfirm'
import { useParams } from 'react-router-dom'
import { portfolioUploadConfigSelector } from 'selector'
import { getImagePromises } from 'test'
import * as testConfig from 'test_config'
import axios from 'axios'
import { getUserType } from 'utils/user'

export default function DevOptionDialog({ open, setOpen }) {
  const [tab, setTab] = useState('option')

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const params = useParams()
  const portfolioId = portfolioType ? defaultPortfolio.id : params.portfolioId

  useEffect(() => {
    // setTab('testcase')
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiDialog-paper': { maxWidth: 'fit-content', minWidth: '80rem' } }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              p: '4px',
              width: '100%',
              borderBottom: '1px solid gray',
              background: 'white',
              '& .MuiTypography-root': {
                fontSize: '1.8rem',
                fontWeight: 600,
                cursor: 'pointer',

                '&.selected': {
                  color: theme => theme.palette.draph.blue,
                },
              },
            }}
          >
            <Typography
              {...(tab === 'option' && { className: 'selected' })}
              onClick={() => {
                setTab('option')
              }}
            >
              포트폴리오 옵션 설정
            </Typography>
            <Typography
              {...(tab === 'testcase' && { className: 'selected' })}
              onClick={() => {
                setTab('testcase')
              }}
            >
              테스트케이스 업로드
            </Typography>
          </Stack>
        </DialogTitle>

        {tab === 'option' ? (
          <OptionConfig />
        ) : tab === 'testcase' ? (
          <>
            <TestCaseUpload portfolioId={portfolioId} />
          </>
        ) : (
          <></>
        )}
      </Dialog>
    </>
  )
}

function OptionConfig() {
  const [text, setText] = useState('')

  const [jsonInput, setJsonInput] = useState('') // JSON 형태의 문자열을 저장할 상태
  const [jsonObject, setJsonObject] = useState(null) // 파싱된 JSON 객체를 저장할 상태

  const [deleteInput, setDeleteInput] = useState('')
  const [deleteKeys, setdeleteKeys] = useState([])

  const [valid, setValid] = useState(true)
  const [errorText, setErrorText] = useState('')
  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)

  const { showConfirm } = useConfirm()

  const reset = () => {
    setJsonInput('')
    setJsonObject(null)
    setDeleteInput('')
    setdeleteKeys([])
  }

  useEffect(() => {
    // console.log(portfolioDetail.config)
    const option = {
      endpoints: endpoint,
      ...uploadConfig,
    }
    // console.log(JSON.stringify(option))
    setText(JSON.stringify(option))
  }, [uploadConfig])

  useEffect(() => {}, [open])

  const onChangeText = e => {
    const inputText = e.target.value
    setJsonInput(inputText)

    try {
      const parsedObject = JSON.parse(inputText)
      setJsonObject(parsedObject)
    } catch (error) {
      // JSON 파싱 오류 처리
      setJsonObject(null)
    }
  }
  const onChangeDeleteText = e => {
    const deleteInput = e.target.value
    setDeleteInput(deleteInput)

    const deletekeys = deleteInput.split(',')
    setdeleteKeys(deletekeys)
  }

  const changeConfig = () => {
    const defaultOption = portfolioDetail.config
    const addOption = jsonObject

    const merge = { ...defaultOption, ...addOption }

    showConfirm({
      sx: { '& .MuiDialog-paper': { width: '40rem', height: '50rem' } },
      content: (
        <>
          <CenterAlignStack sx={{ gap: '1rem' }}>
            <Typography>추가 삭제될 옵션을 다시 확인해주세요.</Typography>
            <AddComponent jsonObject={jsonObject} sx={{ width: '100%' }} />
            <DeleteComponent deleteKeys={deleteKeys} sx={{ width: '100%' }} />
          </CenterAlignStack>
        </>
      ),

      onConfirm: () => {
        const body = {
          ...portfolioDetail,
          config: merge,
        }

        Object.keys(merge).forEach(key => {
          if (deleteKeys.includes(key)) {
            delete merge[key]
          }
        })

        apis.portfolio.updatePortfolio(portfolioDetail.id, body).then(response => {
          setPortfolioDetail({ ...portfolioDetail, ...response.data })
        })
        reset()
      },
    })
  }

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack>
            <Typography sx={{ fontSize: '1.7rem', fontWeight: 600 }}>
              현재 포트폴리오 Option
            </Typography>
            <PrettyObject data={parserFunc(text)} />
          </Stack>

          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '8rem', fontWeight: 500 }}>+</Typography>
              <Stack>
                <TextField
                  label="JSON"
                  placeholder="JSON 형태로 작성 시, 현재 Option에 덮어쓰기 됩니다. "
                  multiline
                  rows={10}
                  sx={{ m: 2, width: '40rem' }}
                  InputLabelProps={{ shrink: true }}
                  value={jsonInput}
                  onChange={onChangeText}
                  error={!valid}
                  helperText={errorText}
                ></TextField>
                <AddComponent jsonObject={jsonObject} typeData="add" />
              </Stack>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '8rem', fontWeight: 500, width: '5rem' }}>-</Typography>
              <Stack>
                <TextField
                  label="JSON"
                  placeholder="key값을 적어주세요. ex)test1,test2,test3 "
                  multiline
                  rows={4}
                  sx={{ m: 2, width: '40rem' }}
                  InputLabelProps={{ shrink: true }}
                  value={deleteInput}
                  onChange={onChangeDeleteText}
                  error={!valid}
                  helperText={errorText}
                ></TextField>
                <DeleteComponent deleteKeys={deleteKeys} />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={saveEndpointConfig} disabled={!valid}>
          리셋
        </Button> */}
        <Button onClick={changeConfig} disabled={!valid}>
          옵션 변경
        </Button>
      </DialogActions>
    </>
  )
}

const PrettyObject = ({ data }) => {
  const prettyText = JSON.stringify(data, null, 2)
  return <pre style={{ fontSize: '1.3rem' }}>{prettyText}</pre>
}

const parserFunc = data => {
  try {
    const jsonData = JSON.parse(data)
    // JSON 데이터가 유효한 경우
    return jsonData
  } catch {
    return null
  }
}

const AddComponent = ({ jsonObject, sx = {} }) => {
  return (
    <Paper elevation={5} sx={{ mx: '2.5rem', p: '1rem', width: '-webkit-fill-available', ...sx }}>
      <Typography
        sx={{
          backgroundColor: 'rgb(183, 239, 255, 0.6)',
          width: 'fit-content',
          fontSize: '1.4rem',
          fontWeight: 600,
        }}
      >
        추가될 option
      </Typography>
      <br />
      <pre style={{ fontSize: '1.3rem' }}>{JSON.stringify(jsonObject, null, 2)}</pre>
    </Paper>
  )
}

const DeleteComponent = ({ deleteKeys, sx = {} }) => {
  return (
    <Paper elevation={5} sx={{ mx: '2.5rem', p: '1rem', width: '-webkit-fill-available', ...sx }}>
      <Typography
        sx={{
          backgroundColor: 'rgb(255, 206, 206, 0.6)',
          width: 'fit-content',
          fontSize: '1.4rem',
          fontWeight: 600,
        }}
      >
        삭제될 key value
      </Typography>
      <br />
      <pre style={{ fontSize: '1.3rem' }}>{JSON.stringify(deleteKeys, null, 2)}</pre>
    </Paper>
  )
}

function TestCaseUpload({ portfolioId }) {
  const user = useRecoilValue(userAtom)

  const [dirs, setDirs] = useState([])
  const [uploadTargets, setUploadTargets] = useState([])
  const [uploading, setUploading] = useState(false)

  const [log, setLog] = useState([])

  const [q, setQ] = useState([])

  const [stopFlag, setStopFlag] = useState(false)

  const PAUSE_ON_EVERY = 2

  // useEffect(() => {
  //   // getImageFiles()
  // }, [])

  useEffect(() => {
    const dirs = testConfig.images.map(path =>
      path.replace('/testcase', '').replace(path.split('/').at(-1), '')
    )
    setDirs([...new Set(dirs)])
  }, [testConfig.images])

  useEffect(() => {
    // TODO 중지는 안됨 ... async doIt 때문인듯 ......
    // if (stopFlag && q.length > 0) {
    //   setLog(prev => [...prev, `중지`])
    //   setQ([])
    //   return
    // }
    // if (stopFlag && q.length < 1) {
    //   setStopFlag(false)
    //   return
    // }

    if (q.length < 1) {
      setUploading(false)
    }
    if (q.length < 1 || uploading) return

    async function doIt() {
      setUploading(true)
      const cp = [...q]
      const path = cp.shift()

      const promises = getImagePromises(path)
      const results = await Promise.all(promises)

      const axiosResults = await Promise.allSettled(
        results.map(item => {
          const formData = new FormData()
          const upConf = item.config

          formData.append('images', item.file) // config 가 모두 달라 한장씩 업로드 해야함

          formData.append('user_id', user.id)
          formData.append('username', user.username)
          formData.append('user_type', getUserType(user))
          formData.append('portfolio_id', portfolioId)

          formData.append('gen_options', JSON.stringify(upConf.gen_options))
          formData.append('options', JSON.stringify(upConf.options))
          return apis.appfront.upload(formData)
        })
      )

      setLog(prev => [...prev, `${path} ........... 업로드 완료`])
      console.log(axiosResults)
      console.log('~~~~~~~~~~~~~~~~~')

      setQ(cp)
      setUploading(false)
    }

    doIt()
  }, [q, uploading, stopFlag])

  const uploadAll = () => {
    setQ(dirs)
    // q를 보고 있는 useEffect에서 처리됨
  }

  // const startUploading = async (includePath = '') => {
  //   const promises = getImagePromises(includePath)

  //   Promise.all(promises).then(results => {
  //     setUploadTargets(results)

  //     let count = 0

  //     for (let i = 0; i < results.length; i++) {
  //       const item = results[i]
  //       count++

  //       const formData = new FormData()
  //       const upConf = item.config

  //       formData.append('images', item.file) // config 가 모두 달라 한장씩 업로드 해야함

  //       formData.append('user_id', user.id)
  //       formData.append('username', user.username)
  //       formData.append('portfolio_id', portfolioId)

  //       formData.append('gen_options', JSON.stringify(upConf.gen_options))

  //       console.log(item, formData)

  //       console.log('업로드..... ', item.orgPath)

  //       apis.appfront.upload(formData).then(() => {})
  //     }
  //   })
  // }

  return (
    <>
      <DialogContent sx={{ mt: '10px' }}>
        <Box
          sx={{
            '& .group': { alignItems: 'center' },
            '& .MuiTypography-root': { width: '120px', fontWeight: 600, fontSize: '16px' },
          }}
        >
          <Stack direction="row" className="group">
            <Typography>포트폴리오</Typography>
            <span>{portfolioId}</span>
          </Stack>
          <Stack direction="row" className="group">
            <Typography>총 테스트 이미지</Typography>
            <span>{testConfig.images.length} 장</span>
          </Stack>
        </Box>
        <br />

        {/* <Stack>
          {testConfig.images.map(path => (
            <span key={path}>{path}</span>
          ))}
        </Stack> */}

        <Stack direction="row" spacing={1}>
          <Stack
            sx={{
              width: '320px',
              height: '700px',
              overflow: 'scroll',
              '& .group:nth-of-type(even)': { background: '#eeeeee' },
            }}
          >
            {dirs.map(path => (
              <Stack
                className="group"
                direction="row"
                key={path}
                sx={{
                  alignItems: 'center',
                  '& .MuiTypography-root': { fontSize: '15px', width: '200px' },
                  '& span': { width: '30px', color: 'skyblue', fontWeight: 600, ml: '20px' },
                }}
              >
                <Typography>{path}</Typography>
                <span>{testConfig.images.filter(p => p.includes(path)).length}</span>
                <Button
                  disabled={uploading}
                  // disabled={q.includes(path)}
                  sx={{}}
                  onClick={e => {
                    setQ(prev => [...prev, path])
                  }}
                >
                  업로드
                </Button>
              </Stack>
            ))}
          </Stack>

          <Box
            sx={{
              width: '500px',
              height: '700px',
              background: '#e2e2e2',
              p: '2rem',
              overflow: 'scroll',

              '& #notice': {
                color: 'gray',
                fontSize: '18px',
                mb: '20px',
              },
            }}
          >
            <p id="notice">업로드 완료 후 새로고침 해주세요 :D</p>
            {log.map((l, i) => (
              <p key={i}>{l}</p>
            ))}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        {/* <Button
          variant="contained"
          onClick={e => {
            startUploading()
          }}
        >
          전체 동시 업로드 ({testConfig.images.length}장)
        </Button> */}
        <Button
          variant="contained"
          onClick={e => {
            uploadAll() // 그룹별 순차 업로드
          }}
        >
          전체 업로드 ({testConfig.images.length}장)
        </Button>
        {/* <Button
          variant="contained"
          onClick={e => {
            setStopFlag(true)
          }}
        >
          중지
        </Button> */}
      </DialogActions>
    </>
  )
}
