import React, { useEffect, useRef, useState } from 'react'

import 'react-image-crop/dist/ReactCrop.css'

// import required modules
import { Navigation, Swiper, Keyboard, Controller } from 'swiper'

import _, { slice, truncate } from 'lodash'
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Typography,
  Paper,
  Divider,
  SwipeableDrawer,
  makeStyles,
  Switch,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  GIFstandardDate,
  convertPriceSymbolToWord,
  getPieceType,
  getS3ImageSrc,
  iOS,
} from 'utils/common'
import { apis } from 'apis'
import { enqueueSnackbar } from 'notistack'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { imageDialogDiaplayModeAtom, pleaseLoginDialogAtom } from 'atoms'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'

import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'

import { useTranslation } from 'react-i18next'
import { English, Korean } from 'hooks/useLanguage'
import MobileSwipeableDrawer from 'components/MobileSwipeableDrawer'
import zIndex from '@mui/material/styles/zIndex'

const DialogButton = styled(Button)(({ theme }) => ({
  boxShadow: '4px 4px 20px 0px #00000040',
  background: '#FFFFFFD9',
  fontSize: '1.8rem',
  fontWeight: 600,
  color: theme.palette.common.black,
  paddingTop: '0.9rem',
  paddingBottom: '0.9rem',
  borderRadius: '3rem',
  opacity: 0.85,

  '&:hover': {
    background: '#FFFFFFD9',
    opacity: 1,
  },
}))

export function ImageDetailDialog({ open, setOpen, items, currentItemIdx, setCurrentItemIdx }) {
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const [swiper, setSwiper] = useState()
  const [displayMode, setDisplayMode] = useRecoilState(imageDialogDiaplayModeAtom)

  // -- 원본 이미지 사이즈가 아닌 화면에 표시되는 사이즈 --
  const [beforeImageW, setBeforeImageW] = useState()
  const [beforeImageH, setBeforeImageH] = useState()
  const [afterImageW, setAfterImageW] = useState()
  const [afterImageH, setAfterImageH] = useState()
  // -- 원본 이미지 사이즈가 아닌 화면에 표시되는 사이즈 --

  const [mobileToggleBefore, setMobileToggleBefore] = useState(false) // true: 비포를 크게

  const [dialogWidth, setDialogWidth] = useState()
  const [dialogHeight, setDialogHeight] = useState(0)

  const MAX_HEIGHT = isMobile ? '320px !important' : '448px !important'
  // const MAX_HEIGHT = 'calc(100vh - 240px) !important'
  const MAX_WIDTH = isMobile ? '320px !important' : 'calc(100vw - 240px) !important'

  const [maxHeightPx, setMaxHeightPx] = useState(isMobile ? 320 : 448)
  const [maxWidthPx, setMaxWidthPx] = useState(
    isMobile ? 320 : document.documentElement.clientWidth - 240
  )

  const currentItem = items ? items[currentItemIdx] : null

  const [currentItemImage, setCurrentItemImage] = useState({})

  useEffect(() => {
    if (!isMobile) {
      // 1. 원본이미지 높이, 생성이미지 높이, MAX HEIGHT 중 최소값으로 dialogHeight (dh) 결정
      let dh = maxHeightPx
      let bh = Infinity
      let ah = 0
      if (currentItem && currentItemImage?.beforeH && currentItemImage?.afterH) {
        dh = Math.min(currentItemImage.beforeH, currentItemImage.afterH, maxHeightPx)
      }

      if (displayMode === 'compare') {
        bh = dh
      }

      ah = dh

      // 2. dh 기준으로 beforeImage 너비, afterImage 너비 계산
      let bw = 0
      let aw = maxWidthPx
      if (displayMode === 'compare' && currentItemImage?.beforeW && currentItemImage?.beforeH) {
        const w = currentItemImage.beforeW
        const ratio = dh / currentItemImage.beforeH
        bw = w * ratio
      }

      if (currentItem && currentItemImage?.afterW && currentItemImage?.afterH) {
        const w = currentItemImage.afterW
        const h = currentItemImage.afterH
        const ratio = dh / h
        aw = w * ratio
      }

      // 3. dialogWidth(dw)는 (bw + aw) 인데 (bw + aw) 가 다이얼로그 최대 너비를 넘어가면 너비와 높이 모두 재조정
      let dw = bw + aw
      if (dw > maxWidthPx) {
        const ratio = maxWidthPx / (bw + aw)
        if (!isNaN(ratio)) {
          dh = dh * ratio

          bw = bw * ratio
          aw = aw * ratio

          bh = bh * ratio
          ah = ah * ratio
        }
        dw = maxWidthPx
      }

      setDialogWidth(dw)
      setDialogHeight(dh)

      setBeforeImageW(bw)
      setBeforeImageH(bh)

      setAfterImageW(aw)
      setAfterImageH(ah)

      // console.log('dialog', dw, dh)
      // console.log('before', bw, bh)
      // console.log('after', aw, ah)
      // console.log('~~~~~~~')
    } else {
      // 모바일은 작은 썸네일과 큰 메인이미지가 비포/애프터 토글임
    }
  }, [
    mobileToggleBefore,
    maxHeightPx,
    maxWidthPx,
    currentItemIdx,
    currentItemImage?.afterW,
    currentItemImage?.beforeW,
    displayMode,
  ])

  useEffect(() => {
    if (open) {
      // 스와이퍼를 init 하려는데 setTimeout 없이는 정상 작동이 안돼서 불가피하게 ..
      setTimeout(() => {
        const sw = new Swiper(`#image-dialog-swiper-container`, {
          autoHeight: isMobile,
          navigation: {
            nextEl: `#image-swiper-dialog .swiper-button-next`,
            prevEl: `#image-swiper-dialog .swiper-button-prev`,
          },
          modules: [Navigation, Controller, Keyboard],
          keyboard: {
            enabled: true,
            onlyInViewport: false,
          },
          // slidesPerView: 'auto',
          slidesPerView: 1,
          on: {
            slideChange: function (s) {
              setCurrentItemIdx(sw.realIndex)
            },
          },
          loop: true,
          spaceBetween: 10,
        })
        if (_.isArray(sw)) {
          setSwiper(sw[0])
        } else {
          setSwiper(sw)
        }
      }, 200)
    }
  }, [open])

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(currentItemIdx)
    }
  }, [swiper])

  useEffect(() => {
    function handleResize() {
      setMaxWidthPx(isMobile ? 320 : document.documentElement.clientWidth - 240)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // console.log('currentItem', currentItem)

    if (currentItem?.original_image_path) {
      setDisplayMode('compare')
    } else {
      setDisplayMode('result')
    }
  }, [currentItem])

  useEffect(() => {
    // console.log('displayMode', displayMode)
  }, [displayMode])

  const handleClose = () => {
    setOpen(false)
  }

  const onLoadAfter = e => {
    // console.log('AFTER', e.target.naturalWidth, e.target.naturalHeight)

    setCurrentItemImage(x => ({
      ...x,
      afterW: e.target.naturalWidth,
      afterH: e.target.naturalHeight,
    }))
  }
  const onLoadBefore = e => {
    // console.log('BEFORE', e.target.naturalWidth, e.target.naturalHeight)

    setCurrentItemImage(x => ({
      ...x,
      beforeW: e.target.naturalWidth,
      beforeH: e.target.naturalHeight,
    }))
  }

  const copyPrompt = async () => {
    if (currentItem?.prompt) {
      try {
        await navigator.clipboard.writeText(currentItem.prompt)

        enqueueSnackbar(
          <>
            <Korean>클립보드에 복사되었습니다</Korean>
            <English>Copied to clipboard</English>
          </>,
          { variant: 'success' }
        )
      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          id="image-swiper-dialog"
          // fullWidth
          maxWidth={'xl'}
          sx={{
            '& .MuiModal-backdrop': {
              backgroundColor: '#30303099',
              backdropFilter: 'blur(20px)',
            },
            '& .MuiPaper-root': {
              m: 0,

              // width: dialogWidth,
              // height: dialogHeight,
              '& #content-wrapper': {
                width: dialogWidth,
                height: dialogHeight,
              },
              maxWidth: MAX_WIDTH,
              maxHeight: '1px',
              // maxHeight: MAX_HEIGHT,

              position: 'unset',

              overflow: 'visible',

              background: 'transparent',
              // background: 'pink', // 영역 확인용
              boxShadow: 'none',

              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },

            '& #image-dialog-swiper-box': {
              maxHeight: '100%',
            },

            '& .swiper-button': {
              display: '',
              backgroundImage: 'none',
              position: 'absolute',
              '&.swiper-button-disabled': {
                pointerEvents: 'unset !important',
                '& .button-box': {
                  pointerEvents: 'none',
                },
              },

              '&.swiper-button-prev': {},
              '&.swiper-button-next': {},
            },
          }}
        >
          {/* <Box
            sx={{
              width: MAX_WIDTH,
              height: MAX_HEIGHT,
              position: 'absolute',
              background: 'gold',
              top: '50%',
              transform: 'translateY(-50%)',
              opacity: 0.5,
            }}
          >
            최대 영역 확인용 박스
          </Box> */}

          {/* 이전 / 다음 화살표 버튼 */}
          <>
            <Box className={`swiper-button swiper-button-prev`}>
              <SwiperPrevButton />
            </Box>
            <Box className={`swiper-button swiper-button-next`}>
              <SwiperNextButton />
            </Box>
          </>

          <Box
            id="content-wrapper"
            sx={{
              maxHeight: '100%',
              position: 'relative',
            }}
          >
            <CenterAlignStack
              id="image-dialog-swiper-box"
              direction="row"
              sx={{
                position: 'relative',

                maxHeight: '100%',

                '& .height100': {
                  maxHeight: '100%',
                  height: '100%',
                },

                '& .swiper-container': {
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',

                  '& .swiper-wrapper': {},

                  '& .swiper-slide': {
                    background: 'transparent',
                    width: `${dialogWidth}px !important`,
                    '& .before-after-wrapper': {
                      '& .image-box ': {
                        position: 'relative',
                        '& img': {
                          maxHeight: MAX_HEIGHT,
                        },
                      },
                      borderRadius: '2rem',
                      overflow: 'hidden',
                    },
                  },
                },
              }}
            >
              <Box id={`image-dialog-swiper-container`} className="swiper-container height100">
                <div className="swiper-wrapper height100">
                  <>
                    {items?.map((item, idx) => {
                      const path = item.path
                      const img = item.static ? path : getS3ImageSrc(path)

                      return (
                        <div key={idx} className={`swiper-slide height100 swiper-slide-${idx}`}>
                          <CenterAlignStack
                            direction="row"
                            className="before-after-wrapper height100"
                            sx={{
                              '& img': {
                                height: dialogHeight,
                              },

                              '& .uploaded-image-box img': {
                                width: beforeImageW ? `${beforeImageW}px !important` : 'auto',
                                maxWidth: 'unset',
                              },
                              '& .result-image-box img': {
                                width: afterImageW ? `${afterImageW}px !important` : 'auto',
                                maxWidth: 'unset',
                              },
                            }}
                          >
                            {beforeImageW > 0 && displayMode === 'compare' && (
                              <Box className="image-box uploaded-image-box height100">
                                <BeforeBox sx={{ position: 'absolute', top: '2rem', left: '2rem' }}>
                                  Before
                                </BeforeBox>
                                <img
                                  src={
                                    item?.original_image_path
                                      ? item.static
                                        ? item.original_image_path
                                        : getS3ImageSrc(item.original_image_path)
                                      : ''
                                  }
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',

                                    right: '-24px', // 화살표 이미지(아이콘) 의 width / 2
                                    zIndex: 3,
                                  }}
                                >
                                  <BeforeAfterArrow />
                                </Box>
                              </Box>
                            )}

                            <Box className="image-box result-image-box height100">
                              {displayMode === 'compare' && (
                                <AfterBox sx={{ position: 'absolute', top: '2rem', right: '2rem' }}>
                                  After
                                </AfterBox>
                              )}

                              <img src={img} />
                            </Box>
                          </CenterAlignStack>
                        </div>
                      )
                    })}
                  </>
                </div>
              </Box>

              {currentItem && (
                <img
                  src={currentItem.static ? currentItem.path : getS3ImageSrc(currentItem.path)}
                  onLoad={onLoadAfter}
                  style={{ display: 'none' }}
                />
              )}
              {currentItem && currentItem.original_image_path && (
                <img
                  src={
                    currentItem.static
                      ? currentItem.original_image_path
                      : getS3ImageSrc(currentItem.original_image_path)
                  }
                  onLoad={onLoadBefore}
                  style={{ display: 'none' }}
                />
              )}
            </CenterAlignStack>
          </Box>

          {/* 이미지 하단 영역 */}
          <CenterAlignStack
            id=""
            sx={{
              // position: 'absolute',
              justifyContent: 'center',
              zIndex: 9,
              mt: '2rem',

              // border: '1px dashed gold',
            }}
          >
            <Stack
              direction="row"
              sx={{
                height: '20.6rem',
                '& .box': {
                  px: '2.8rem',
                  pt: '2.8rem',
                  background: '#00000080',
                  borderRadius: '2rem',
                  height: '100%',
                },

                '& .text': {
                  color: 'white',
                  '&.title': { fontSize: '2rem', lineHeight: 1.2, fontWeight: 700 },
                  '&.prompt': { fontSize: '1.4rem', lineHeight: 1.2, fontWeight: 400 },
                  '&.config': { textAlign: 'center' },
                },
                '& .divider': {
                  mt: '1.6rem',
                  mb: '2.4rem',
                },
              }}
            >
              <CenterAlignStack
                className="box"
                sx={{
                  width: '23.2rem',
                  mr: '1.6rem',
                  '& .text.config': {
                    fontSize: '1.4rem',
                    lineHeight: '1.8rem',
                    fontWeight: 600,
                  },
                }}
              >
                <Typography className="text title">
                  <Korean>생성 이미지 설정 정보</Korean>
                  <English>Configurations</English>
                </Typography>
                <Divider flexItem className="divider" />
                {currentItem && (
                  <Stack
                    sx={{
                      width: '100%',
                      justifyContent: currentItem.config?.theme_template
                        ? 'space-between'
                        : 'center',
                      // background: 'pink',
                    }}
                    direction="row"
                  >
                    <CenterAlignStack
                      sx={{ width: '6rem', '& img': { width: '100%' } }}
                      spacing="8px"
                    >
                      <img src={CONFIG_INFO[currentItem.config?.type ?? 'default']?.icon} />
                      <Typography className="text config">
                        {CONFIG_INFO[currentItem.config?.type ?? 'default']?.label}
                      </Typography>
                    </CenterAlignStack>

                    {currentItem.config?.theme_template && (
                      <>
                        <CenterAlignBox
                          sx={{
                            width: '2.4rem',
                            height: '6rem',
                            alignItems: 'center',
                          }}
                        >
                          <TinyArrowRight />
                        </CenterAlignBox>
                        <CenterAlignStack
                          sx={{
                            width: '6rem',
                            '& img': {
                              width: '100%',
                              '&.theme-image': {
                                borderRadius: '1rem',
                              },
                            },
                          }}
                          spacing="8px"
                        >
                          {currentItem.config?.theme_template === 'custom' ? (
                            <>
                              <img src="/static/images/main/icon/pencil_on.png" />
                            </>
                          ) : (
                            <img
                              className="theme-image"
                              src={`/static/images/theme_template/${currentItem.config?.theme_template}/default.png`}
                            />
                          )}
                          <Typography className="text config">
                            {t(`theme_template.${currentItem.config?.theme_template}`, '')}
                          </Typography>
                        </CenterAlignStack>
                      </>
                    )}
                  </Stack>
                )}
              </CenterAlignStack>
              <Stack
                className="box"
                sx={{
                  width: '64.8rem',
                }}
              >
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                  <Typography className="text title">
                    <Korean>입력 정보</Korean>
                    <English>Prompt</English>
                  </Typography>
                  <Box sx={{ cursor: 'pointer' }} onClick={copyPrompt}>
                    {currentItem?.prompt && <CopyIcon />}
                  </Box>
                </Stack>
                <Divider flexItem className="divider" />
                <Typography className="text prompt" sx={{ '& .bold': { fontWeight: 800 } }}>
                  {currentItem.config?.theme_template &&
                  currentItem.config?.theme_template !== 'custom' ? (
                    <>
                      <Korean>
                        <span className="bold">
                          {t(`theme_template.${currentItem.config?.theme_template}`, '')}
                        </span>{' '}
                        배경 테마 직접 선택
                      </Korean>
                      <English>
                        Select{' '}
                        <span className="bold">
                          {t(`theme_template.${currentItem.config?.theme_template}`, '')}
                        </span>{' '}
                        theme
                      </English>
                    </>
                  ) : (
                    (currentItem?.config?.prompt
                      ? currentItem.config.prompt[i18n.language]
                      : currentItem?.prompt) ?? currentItem?.prompt
                  )}
                </Typography>
              </Stack>
            </Stack>
          </CenterAlignStack>
        </Dialog>
      </Desktop>

      {/*  */}
      {/*  */}

      <Mobile>
        <MobileSwipeableDrawer
          id="image-swiper-dialog"
          open={open}
          onOpen={() => {}}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': {
              m: 0,

              borderRadius: '2rem',

              borderTop: '1px solid rgba(255, 255, 255, 0.4)',

              // '&::before': {
              //   content: '""',
              //   position: 'absolute',
              //   top: 0,
              //   left: 0,
              //   right: 0,
              //   height: '1px',
              //   // height: '2rem',
              //   borderRadius: '2rem',
              //   background:
              //     'linear-gradient(to right, rgba(255, 255, 255, 0.6), rgba(0, 0, 0, 0.4))',
              //   zIndex: 1,
              // },

              background: '#00000066',
              backdropFilter: 'blur(20px)',

              height: 'calc(100vh - 52px)',
            },

            '& .swiper-button': {
              display: '',
              backgroundImage: 'none',
              position: 'absolute',
              '&.swiper-button-disabled': {
                pointerEvents: 'unset !important',
                '& .button-box': {
                  pointerEvents: 'none',
                },
              },

              '&.swiper-button-prev': {
                left: '6px !important',
              },
              '&.swiper-button-next': {
                right: '6px !important',
              },
            },
          }}
        >
          <Box className="border-box"></Box>
          <CenterAlignStack
            sx={{
              height: '100%',
              maxHeight: 'calc(100vh-28px)',
              overflowY: 'auto',
              pb: '3.2rem',
            }}
          >
            <CenterAlignStack
              id="image-dialog-swiper-box"
              direction="row"
              sx={{
                mt: '2.4rem',

                position: 'relative',
                justifyContent: 'center',

                // background: 'pink',
                '& #image-dialog-swiper-container': {
                  // background: 'gold',
                },

                '& .swiper-container': {
                  width: '32rem !important',
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',

                  '& .swiper-wrapper': {},

                  '& .swiper-slide': {
                    // border: '2px dashed red',
                    background: 'transparent',

                    '& .before-after-wrapper': {},
                  },
                },
              }}
            >
              <Box id={`image-dialog-swiper-container`} className="swiper-container">
                <>
                  <Box className={`swiper-button swiper-button-prev`}>
                    <SwiperPrevButton />
                  </Box>
                  <Box className={`swiper-button swiper-button-next`}>
                    <SwiperNextButton />
                  </Box>
                </>

                <div className="swiper-wrapper">
                  <>
                    {items?.map((item, idx) => {
                      const path = item.path
                      const img = item.static ? path : getS3ImageSrc(path)

                      return (
                        <div key={idx} className={`swiper-slide swiper-slide-${idx}`}>
                          <Stack
                            direction="row"
                            className="before-after-wrapper"
                            sx={{
                              position: 'relative',
                              maxWidth: '32rem',
                              // maxHeight: '32rem',
                              '& img': {},
                              '& .placeholder-box': {
                                width: '100%',
                                height: '100%',
                                '& img': {
                                  opacity: 0,
                                },
                              },
                              '& .image-box': {
                                // transition: 'width 0.5s, height 0.5s, opacity 0.5s',
                                transition: 'all 0.5s',
                              },
                              '& .before-box': {
                                position: 'absolute',

                                top: '1.2rem',
                                left: '1.2rem',
                                width: '8rem',
                                height: '8rem',
                                zIndex: 2,
                                opacity: 0.7,

                                ...(mobileToggleBefore && {
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  zIndex: 1,
                                  opacity: 1,
                                }),

                                '& img': {
                                  borderRadius: '1.6rem',
                                  ...(!mobileToggleBefore && {
                                    borderRadius: '1rem',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }),
                                },
                              },
                              '& .after-box': {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                                opacity: 1,

                                ...(mobileToggleBefore && {
                                  width: '8rem',
                                  height: '8rem',
                                  zIndex: 2,
                                  top: '1.2rem',
                                  left: '1.2rem',
                                  opacity: 0.7,
                                }),
                                '& img': {
                                  borderRadius: '1rem',
                                  ...(mobileToggleBefore && {
                                    borderRadius: '1.6rem',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }),
                                },
                              },
                            }}
                          >
                            <Box className="placeholder-box">
                              {mobileToggleBefore ? (
                                <img
                                  src={
                                    item?.original_image_path
                                      ? item.static
                                        ? item.original_image_path
                                        : getS3ImageSrc(item.original_image_path)
                                      : ''
                                  }
                                />
                              ) : (
                                <img src={img} />
                              )}
                            </Box>
                            {displayMode === 'compare' && (
                              <Box
                                className="image-box before-box"
                                onClick={() => {
                                  setMobileToggleBefore(true)
                                }}
                              >
                                <img
                                  src={
                                    item?.original_image_path
                                      ? item.static
                                        ? item.original_image_path
                                        : getS3ImageSrc(item.original_image_path)
                                      : ''
                                  }
                                />
                              </Box>
                            )}
                            <Box
                              className="image-box after-box"
                              onClick={() => {
                                setMobileToggleBefore(false)
                              }}
                            >
                              <img src={img} />
                            </Box>

                            <BeforeBox
                              sx={{
                                position: 'absolute',
                                top: '1.2rem',
                                right: '1.2rem',
                                opacity: mobileToggleBefore ? 1 : 0,
                                transition: 'all 0.2s',
                              }}
                            >
                              Before
                            </BeforeBox>
                            <AfterBox
                              sx={{
                                position: 'absolute',
                                top: '1.2rem',
                                right: '1.2rem',
                                opacity: mobileToggleBefore ? 0 : 1,
                                transition: 'all 0.2s',
                              }}
                            >
                              After
                            </AfterBox>
                          </Stack>
                        </div>
                      )
                    })}
                  </>
                </div>
              </Box>

              {currentItem && (
                <img
                  src={currentItem.static ? currentItem.path : getS3ImageSrc(currentItem.path)}
                  onLoad={onLoadAfter}
                  style={{ display: 'none' }}
                />
              )}
              {currentItem && currentItem.original_image_path && (
                <img
                  src={
                    currentItem.static
                      ? currentItem.original_image_path
                      : getS3ImageSrc(currentItem.original_image_path)
                  }
                  onLoad={onLoadBefore}
                  style={{ display: 'none' }}
                />
              )}
            </CenterAlignStack>

            {/* 이미지 하단 영역 */}
            <Stack
              id=""
              sx={{
                mt: '8px',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                zIndex: 9,
                width: '32rem',
                // border: '1px dashed gold',

                '& .box': {
                  pt: '2rem',
                  pb: '2rem',
                  px: '2rem',
                  background: '#00000080',
                  borderRadius: '1.6rem',
                },

                '& .text': {
                  color: 'white',
                  '&.title': { fontSize: '1.5rem', fontWeight: 700 },
                  '&.config': { fontSize: '1.4rem', fontWeight: 600 },
                  '&.prompt': { fontSize: '1.3rem', fontWeight: 400, lineHeight: 1.2 },
                },
                '& .divider': {
                  borderColor: 'white',
                  mt: '1.2rem',
                  mb: '1.4rem',
                },
              }}
            >
              <Stack
                className="box"
                sx={{
                  width: '100%',
                  mb: '0.8rem',
                }}
              >
                <Typography className="text title">
                  <Korean>생성 이미지 설정 정보</Korean>
                  <English>Configurations</English>
                </Typography>
                <Divider flexItem className="divider" />
                {currentItem && (
                  <Stack
                    sx={{
                      width: '100%',
                    }}
                    direction="row"
                    spacing="12px"
                  >
                    <CenterAlignStack
                      spacing="6px"
                      sx={{ '& img': { width: '2rem' } }}
                      direction="row"
                    >
                      <img src={CONFIG_INFO[currentItem.config?.type ?? 'default']?.icon} />
                      <Typography className="text config">
                        {CONFIG_INFO[currentItem.config?.type ?? 'default']?.label}
                      </Typography>
                    </CenterAlignStack>

                    {currentItem.config?.theme_template && (
                      <>
                        <CenterAlignBox
                          sx={{
                            width: '2.4rem',
                            height: '2.4rem',
                            alignItems: 'center',
                          }}
                        >
                          <TinyArrowRight />
                        </CenterAlignBox>
                        <CenterAlignStack
                          sx={{
                            '& img': {
                              width: '2rem',
                              '&.theme-image': {
                                borderRadius: '0.3rem',
                              },
                            },
                          }}
                          spacing="6px"
                          direction="row"
                        >
                          {currentItem.config?.theme_template === 'custom' ? (
                            <>
                              <img src="/static/images/main/icon/pencil_on.png" />
                            </>
                          ) : (
                            <img
                              className="theme-image"
                              src={`/static/images/theme_template/${currentItem.config?.theme_template}/default.png`}
                            />
                          )}
                          <Typography className="text config">
                            {t(`theme_template.${currentItem.config?.theme_template}`, '')}
                          </Typography>
                        </CenterAlignStack>
                      </>
                    )}
                  </Stack>
                )}
              </Stack>
              <Stack className="box" sx={{ minHeight: '11rem' }}>
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                  <Typography className="text title">
                    <Korean>입력 정보</Korean>
                    <English>Prompt</English>
                  </Typography>
                  <Box sx={{ cursor: 'pointer' }} onClick={copyPrompt}>
                    {currentItem?.prompt && <CopyIcon />}
                  </Box>
                </Stack>
                <Divider flexItem className="divider" />
                <Typography className="text prompt" sx={{ '& .bold': { fontWeight: 800 } }}>
                  {currentItem.config?.theme_template &&
                  currentItem.config?.theme_template !== 'custom' ? (
                    <>
                      <Korean>
                        <span className="bold">
                          {t(`theme_template.${currentItem.config?.theme_template}`, '')}
                        </span>{' '}
                        배경 테마 직접 선택
                      </Korean>
                      <English>
                        Select{' '}
                        <span className="bold">
                          {t(`theme_template.${currentItem.config?.theme_template}`, '')}
                        </span>{' '}
                        theme
                      </English>
                    </>
                  ) : (
                    currentItem?.prompt
                  )}
                </Typography>
              </Stack>
            </Stack>
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

const SwiperNavButtonBox = styled(Box)(({ theme }) => ({
  minWidth: '4rem',
  width: '4rem',
  height: '4rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0.4rem',
  background: 'rgba(255, 255, 255, 0.3)',

  // opacity: 0.3,

  '&:hover': {
    background: 'rgba(255, 255, 255, 0.5)',
  },
}))

const SwiperPrevButton = () => (
  <SwiperNavButtonBox className="button-box">
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5125 0.488155C10.9117 -0.112651 9.9663 -0.158867 9.31249 0.349508L9.15547 0.488155L0.82214 8.82149C0.221333 9.4223 0.175117 10.3677 0.683492 11.0215L0.82214 11.1785L9.15547 19.5118C9.80635 20.1627 10.8616 20.1627 11.5125 19.5118C12.1133 18.911 12.1595 17.9657 11.6511 17.3118L11.5125 17.1548L4.35898 10L11.5125 2.84518C12.1133 2.24437 12.1595 1.29899 11.6511 0.645168L11.5125 0.488155Z"
        fill="white"
      />
    </svg>
  </SwiperNavButtonBox>
)

const SwiperNextButton = () => (
  <SwiperNavButtonBox className="button-box">
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.488155 0.488155C1.08896 -0.112651 2.03435 -0.158867 2.68817 0.349508L2.84518 0.488155L11.1785 8.82149C11.7793 9.4223 11.8255 10.3677 11.3172 11.0215L11.1785 11.1785L2.84518 19.5118C2.1943 20.1627 1.13903 20.1627 0.488155 19.5118C-0.112651 18.911 -0.158867 17.9657 0.349508 17.3118L0.488155 17.1548L7.64167 10L0.488155 2.84518C-0.112651 2.24437 -0.158867 1.29899 0.349508 0.645168L0.488155 0.488155Z"
        fill="white"
      />
    </svg>
  </SwiperNavButtonBox>
)

const BeforeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alighItems: 'center',
  width: '10rem',
  color: 'white',
  fontSize: '1.6rem',
  fontWeight: 600,
  paddingTop: '0.8rem',
  paddingBottom: '0.8rem',
  background: '#30303080',
  borderRadius: '1rem',
  zIndex: 10,

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.4rem',
    width: '7.2rem',
    paddingTop: '0.65rem',
    paddingBottom: '0.65rem',
  },
}))

const AfterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alighItems: 'center',
  width: '10rem',
  color: 'white',
  fontSize: '1.6rem',
  fontWeight: 600,
  paddingTop: '0.8rem',
  paddingBottom: '0.8rem',
  background: '#2C4DFF',
  borderRadius: '1rem',
  zIndex: 10,

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.4rem',
    width: '6.1rem',
    paddingTop: '0.65rem',
    paddingBottom: '0.65rem',
  },
}))

function DownloadButton(props) {
  const { t } = useTranslation()
  return (
    <>
      <DialogButton
        variant="contained"
        startIcon={
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.5 11C16.0128 11 16.4355 11.386 16.4933 11.8834L16.5 12V16C16.5 16.5128 16.114 16.9355 15.6166 16.9933L15.5 17H1.5C0.987164 17 0.564493 16.614 0.506728 16.1166L0.5 16V12C0.5 11.4477 0.947715 11 1.5 11C2.01284 11 2.43551 11.386 2.49327 11.8834L2.5 12V15H14.5V12C14.5 11.4872 14.886 11.0645 15.3834 11.0067L15.5 11ZM8.5 0C9.05229 0 9.5 0.447715 9.5 1L9.501 9.584L12.7929 6.29289C13.1534 5.93241 13.7206 5.90468 14.1129 6.2097L14.2071 6.29289C14.5676 6.65338 14.5953 7.22061 14.2903 7.6129L14.2071 7.70711L9.20711 12.7071C8.84662 13.0676 8.27939 13.0953 7.8871 12.7903L7.79289 12.7071L2.79289 7.70711C2.40237 7.31658 2.40237 6.68342 2.79289 6.29289C3.15338 5.93241 3.72061 5.90468 4.1129 6.2097L4.20711 6.29289L7.501 9.586L7.5 1C7.5 0.447715 7.94772 0 8.5 0Z"
              fill="#303030"
            />
          </svg>
        }
        sx={{
          px: '7.8rem',
          minWidth: { lg: '24rem' },
        }}
        {...props}
      >
        {t('button.download')}
      </DialogButton>
    </>
  )
}

const BeforeAfterArrow = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_10830_5089)" />
    <path d="M34 24L19 32.6603L19 15.3397L34 24Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_10830_5089"
        x1="0"
        y1="24"
        x2="48"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C4DFF" />
        <stop offset="1" stopColor="#8D00FC" />
      </linearGradient>
    </defs>
  </svg>
)

const CONFIG_INFO = {
  default: {
    label: (
      <>
        <Korean>배경 생성</Korean>
        <English>BG Generation</English>
      </>
    ),
    icon: '/static/images/main/icon/concept_on.png',
  },
  normal: {
    label: (
      <>
        <Korean>배경 생성</Korean>
        <English>BG Generation</English>
      </>
    ),
    icon: '/static/images/main/icon/concept_on.png',
  },
  model_mann2man: {
    label: (
      <>
        <Korean>모델 변경</Korean>
        <English>Model Change</English>
      </>
    ),
    icon: '/static/images/main/icon/change_model_on.png',
  },
  human_background: {
    label: (
      <>
        <Korean>모델 생성</Korean>
        <English>Model Creation</English>
      </>
    ),
    icon: '/static/images/main/icon/ai_model_on.png',
  },
}

const TinyArrowRight = () => (
  <>
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.292893 0.292893C0.653377 -0.0675907 1.22061 -0.0953203 1.6129 0.209705L1.70711 0.292893L6.70711 5.29289C7.06759 5.65338 7.09532 6.22061 6.7903 6.6129L6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0675907 11.3466 -0.0953203 10.7794 0.209705 10.3871L0.292893 10.2929L4.585 6L0.292893 1.70711C-0.0675907 1.34662 -0.0953203 0.779392 0.209705 0.387101L0.292893 0.292893Z"
        fill="white"
      />
    </svg>
  </>
)

const CopyIcon = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C11.1046 0 12 0.89543 12 2V4H14C15.1046 4 16 4.89543 16 6V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V14H2C0.89543 14 0 13.1046 0 12V2C0 0.89543 0.89543 0 2 0H10ZM6 16H14V6H6V16ZM10 4V2H2V12H4V6C4 4.89543 4.89543 4 6 4H10Z"
      fill="#808080"
    />
  </svg>
)
