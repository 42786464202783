import { height } from '@mui/system'
import { BorderInput, SpanDraphBlue } from 'components/CustomStyle'

import { getS3ImageSrc } from 'utils/common'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Stack,
  Button,
  Box,
  CardMedia,
  Card,
  CardContent,
  IconButton,
  useTheme,
} from '@mui/material'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  backgroundAtom,
  backgroundFetchInfoAtom,
  backgroundFilterAtom,
  loadingAtom,
  userAtom,
} from 'atoms'
import { apis } from 'apis'
import axios from 'axios'
import { BackIcon, CloseIcon, SelectedIcon, RefreshIcon } from 'theme/icon'
import { refreshBackground } from 'pages/Background'
import { PuffLoader } from 'react-spinners'
import useConfirm from 'hooks/useConfirm'
import { creditPolicyDictSelector } from 'selector'
import useBackground from 'hooks/useBackground'
import { useTranslation } from 'react-i18next'

let originalImageBlob = null
const USE_CREDIT_BG_GEN = 'bg_generate'

export function CreateBackground({ open, setOpen, selectedImage }) {
  const nPrompt = ''
  const repetition = 4 // 몇장 생성할지

  const [placeholder, setPlaceholder] = useState('예) 숲, 산, 노을, 해변, 식탁')
  const [placeholder1, setPlaceholder1] = useState('예) 여름, 상큼한, 파란색')
  const [backgroundPrompt, setBackgroundPrompt] = useState('')
  const [themePrompt, setThemePrompt] = useState('')
  const [imageList, setImageList] = useState([])
  const [imagePathList, setImagePathList] = useState([])
  const [selectedIdxList, setSelectedIdxList] = useState([])
  const [clonedImageTags, setClonedImageTags] = useState({})

  const [cloneButtonDisabled, setCloneButtonDisabled] = useState(false)
  const [genButtonDisabled, setGenButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [showingBackgroundDialog, setShowingBackgroundDialog] = useState(false)

  const setBackgroundList = useSetRecoilState(backgroundAtom)
  const [isBgAddLoading, setIsAddLoading] = useRecoilState(loadingAtom)
  const [fetchInfo, setFetchInfo] = useRecoilState(backgroundFetchInfoAtom)
  const filter = useRecoilValue(backgroundFilterAtom)

  const { refreshBackground } = useBackground()

  const { id } = useRecoilValue(userAtom)
  const { t, i18n } = useTranslation()

  const resetFetchInfo = useResetRecoilState(backgroundFetchInfoAtom)
  const resetIsBgAddLoading = useResetRecoilState(loadingAtom)

  const creditPolicy = useRecoilValue(creditPolicyDictSelector)
  const [user, setUser] = useRecoilState(userAtom)

  const { showConfirm } = useConfirm()

  const theme = useTheme()

  const resetData = () => {
    setImageList([])
    setImagePathList([])
    setSelectedIdxList([])
    setClonedImageTags({})
    setBackgroundPrompt('')
    setThemePrompt('')

    setCloneButtonDisabled(false)
    setGenButtonDisabled(true)
  }

  useEffect(() => {
    return () => {
      resetData()
    }
  }, [])

  const cloneBackground = async () => {
    // axios 문제로 바로 넘어감.
    const res = await axios.get(
      getS3ImageSrc(selectedImage.s3_url) + `?${selectedImage.id}` + `?w=${Date.now().toString()}`,
      {
        headers: {
          withCredentials: false,
        },
        responseType: 'blob',
      }
    )
    originalImageBlob = res.data

    setSelectedIdxList([])
    setClonedImageTags({})
    setCloneButtonDisabled(true)
    setGenButtonDisabled(true)
    setLoading(true)

    const formData = new FormData()
    formData.append('image', originalImageBlob)
    // formData.append('prompt', prompt)
    formData.append('n_prompt', nPrompt)
    // formData.append('repetition', repetition)
    formData.append('theme_air', themePrompt)
    formData.append('theme_background', backgroundPrompt)

    const params = {
      user_id: id,
      repetition,
      theme_air: themePrompt,
      theme_background: backgroundPrompt,
    }

    apis.background
      .createBackgrounds(formData, params)
      .then(response => {
        const imageBytes = JSON.parse(response.data.img_byte_list.body)
        // console.log(imageBytes[0])
        const images = imageBytes.map(b => `data:image/png;base64,${b}`)
        // console.log(images)
        setImageList(images)
        setImagePathList(response.data.img_path_list)
        setShowingBackgroundDialog(true)
        setOpen(false)
        setGenButtonDisabled(false)
      })
      .catch(() => {
        alert('다시 시도해주세요.')
      })
      .finally(() => {
        setCloneButtonDisabled(false)
        setLoading(false)
      })
  }

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const genBackground = async () => {
    if (selectedIdxList.length < 1) return

    setLoading2(true)
    setGenButtonDisabled(true)
    setCloneButtonDisabled(true)

    const tagList = []

    const price = selectedIdxList.length * creditPolicy[USE_CREDIT_BG_GEN]
    const userCredit = await checkUserCredit()
    if (userCredit < price) {
      showConfirm({
        alertOnly: true,
        content: <Typography>{t('common.credit_warning')}</Typography>,
      })
      return
    }

    const selectedImageBytes = imageList?.filter((p, idx) => {
      if (selectedIdxList.includes(idx)) {
        tagList.push(clonedImageTags[idx]?.length > 0 ? clonedImageTags[idx] : selectedImage.tag)
      }
      return selectedIdxList.includes(idx)
    })

    const editedImageBytes = selectedImageBytes.map((byte, idx) => {
      return byte.replace('data:image/png;base64,', '')
    })

    const d = selectedImage

    const params = {
      user_id: id,

      category: d.category.toString(),
      sub_category: d.sub_category.toString(),
      reflect: d.reflect,
      cast_shadow: d.cast_shadow,
      angle: d.angle,

      pos_w: d.pos_w ?? null,
      pos_h: d.pos_h ?? null,
      h_standard: d.h_standard ?? -1,
      name: d.name ?? null,

      light_src: d.light_src,

      left_top_w: d.left_top_w ? Math.round(d.left_top_w) : -1,
      left_top_h: d.left_top_h ? Math.round(d.left_top_h) : -1,
      right_bottom_w: d.right_bottom_w ? Math.round(d.right_bottom_w) : -1,
      right_bottom_h: d.right_bottom_h ? Math.round(d.right_bottom_h) : -1,

      pos_left_w: d.pos_left_w ?? null,
      pos_right_w: d.pos_right_w ?? null,

      blur: d.blur,

      image_filename: d.filepath.split('/').slice(-1)[0],

      original_bg_id: selectedImage.id,
    }

    apis.bg
      .uploadBgSD(
        {
          sd_bg_path_list: editedImageBytes,
          tag_list: tagList,
          use_credit_type: USE_CREDIT_BG_GEN,
        },
        { params }
      )
      .then(() => {
        setShowingBackgroundDialog(false)
        setIsAddLoading(true)
        refreshBackground()
        resetData()
      })
      .catch(() => {
        setLoading2(false)

        showConfirm({
          alertOnly: true,
          content: <Typography>{t('common.error')}</Typography>,
        })
      })
      .finally(() => {
        setLoading2(false)
        setGenButtonDisabled(false)
        setThemePrompt('')
        setBackgroundPrompt('')
        setTimeout(() => {
          resetIsBgAddLoading()
        }, 300)
      })
  }

  const handleClose = check => () => {
    if (check === 'create') {
      setOpen(false)
      resetData()
    }
    if (check === 'showing') {
      setShowingBackgroundDialog(false)
      resetData()
    }
  }

  const backward = () => {
    setOpen(true)
    setShowingBackgroundDialog(false)
  }

  const onFocus = idx => () => {
    if (idx === 0) setPlaceholder('')
    if (idx === 1) setPlaceholder1('')
  }
  const onBlur = idx => () => {
    if (idx === 0) setPlaceholder(t('background_item.ex1'))
    if (idx === 1) setPlaceholder1(t('background_item.ex2'))
  }

  useEffect(() => {
    setPlaceholder(t('background_item.ex1'))
    setPlaceholder1(t('background_item.ex2'))
  }, [i18n.language])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose('create')}
        sx={{
          '& .MuiDialog-paper': { minHeight: '64.5rem', minWidth: '58.4rem' },
        }}
      >
        <DialogTitle sx={{ pt: '6.8rem' }}>
          <CenterAlignBox>
            <CenterAlignStack>
              <Typography sx={{ fontWeight: 700, fontSize: '2.5rem' }}>
                {t('background_item.AI_bg')}
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '1.6rem' }}>
                {t('background_item.AI_bg_comment')}
              </Typography>
            </CenterAlignStack>
            <IconButton
              onClick={handleClose('create')}
              sx={{ position: 'absolute', right: '26px', top: '25px' }}
            >
              <CloseIcon sx={{ width: '12px', height: '12px' }} />
            </IconButton>
            {/* <Button onClick={setBginfo}>aasdf</Button> */}
          </CenterAlignBox>
        </DialogTitle>
        <DialogContent sx={{ px: '10rem', pb: '4rem' }}>
          <CenterAlignStack>
            <img
              src={getS3ImageSrc(selectedImage?.s3_url)}
              style={{
                width: '38.4rem',
                height: '24.0rem',
                marginTop: '1.6rem',
                marginBottom: '2.2rem',
                objectFit: 'cover',
              }}
            />
          </CenterAlignStack>
          <Stack>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '1.6rem',
                mb: '1.1rem',
              }}
            >
              <SpanDraphBlue> {t('background_item.AI_bg_text_1_a')}</SpanDraphBlue>
              {t('background_item.AI_bg_text_1_b')}
            </Typography>
            <TextField
              onFocus={onFocus(0)}
              onBlur={onBlur(0)}
              placeholder={placeholder}
              multiline
              maxRows={3}
              value={backgroundPrompt}
              onChange={e => setBackgroundPrompt(e.target.value)}
              type="text"
              inputProps={{ maxLength: 120 }}
              sx={{
                pb: '2.2rem',
                fontSize: '1.4rem',
                fontWeight: 400,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.common.black} !important`,
                },
              }}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '1.6rem',
                mb: '1.1rem',
              }}
            >
              <SpanDraphBlue>{t('background_item.AI_bg_text_2_a')}</SpanDraphBlue>
              {t('background_item.AI_bg_text_2_b')}
            </Typography>

            <TextField
              onFocus={onFocus(1)}
              onBlur={onBlur(1)}
              placeholder={placeholder1}
              multiline
              maxRows={3}
              value={themePrompt}
              onChange={e => setThemePrompt(e.target.value)}
              type="text"
              inputProps={{ maxLength: 120 }}
              sx={{
                pb: '4rem',
                fontSize: '1.4rem',
                fontWeight: 400,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.common.black} !important`,
                },
              }}
            />
          </Stack>
          <CenterAlignStack>
            <Button
              size="large"
              variant="contained"
              sx={{ width: '26rem' }}
              onClick={cloneBackground}
              disabled={cloneButtonDisabled}
            >
              {loading ? <PuffLoader /> : t('background_item.regenerate')}
            </Button>
          </CenterAlignStack>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showingBackgroundDialog}
        onClose={handleClose('showing')}
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: '58.8rem',
            minHeight: '58.8rem',
            minWidth: '98.4rem',
            borderRadius: '20px',
          },
        }}
      >
        <DialogTitle sx={{ mt: '6.8rem', p: 0 }}>
          {' '}
          <IconButton
            onClick={handleClose('showing')}
            sx={{ position: 'absolute', right: '26px', top: '25px' }}
          >
            <CloseIcon sx={{ width: '12px', height: '12px' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pb: '5.1rem', mx: '5rem', px: 0, overflow: 'hidden' }}>
          <CenterAlignStack>
            <Typography sx={{ fontWeight: 700, fontSize: '2.5rem' }}>
              {t('background_item.AI_bg_completed')}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '1.4rem' }}>
              {t('background_item.AI_bg_completed_comment_1_a')}
              <span style={{ fontWeight: 'bold' }}>
                {t('background_item.AI_bg_completed_comment_1_b')}
                {creditPolicy[USE_CREDIT_BG_GEN] ?? 1}{' '}
                {t('background_item.AI_bg_completed_comment_1_c')}
              </span>
              {t('background_item.AI_bg_completed_comment_1_d')}
            </Typography>
            <Box
              sx={{
                mt: '4.6rem',
                mb: '5rem',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {imageList.map((img, idx) => (
                <ImageBox
                  key={idx}
                  idx={idx}
                  img={img}
                  selectedIdxList={selectedIdxList}
                  setSelectedIdxList={setSelectedIdxList}
                  clonedImageTags={clonedImageTags}
                  setClonedImageTags={setClonedImageTags}
                  defaultTag={selectedImage.tag}
                />
              ))}
            </Box>
          </CenterAlignStack>

          <CenterAlignStack>
            <CenterAlignBox
              gap={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
              }}
            >
              <IconButton onClick={backward}>
                <BackIcon style={{ height: '6rem' }} />
              </IconButton>
              <Button
                size="large"
                variant="contained"
                sx={{ width: '26rem' }}
                onClick={genBackground}
                disabled={genButtonDisabled || selectedIdxList.length === 0}
              >
                {loading2 || genButtonDisabled ? <PuffLoader /> : '추가하기'}
              </Button>
              <Box sx={{ position: 'absolute', bottom: '3.2rem', right: '5.8rem' }}>
                <Button
                  onClick={cloneBackground}
                  endIcon={<RefreshIcon />}
                  variant="outlined"
                  sx={{
                    width: '10.4rem',
                    height: '3.8rem',
                    borderRadius: '20px',

                    '&:hover': {
                      backgroundColor: theme => theme.palette.common.white,
                      color: theme => theme.palette.draph.blue,
                      '& .MuiButton-endIcon path': {
                        transition: 'fill 0.3s',
                        fill: theme => theme.palette.draph.blue,
                      },
                    },
                    '& .MuiButton-endIcon path': {
                      transition: 'fill 0.3s',
                    },
                  }}
                >
                  <Typography sx={{ fontSize: '1.4rem', fontWeight: 600, color: 'inherit' }}>
                    재생성
                  </Typography>
                </Button>{' '}
              </Box>
            </CenterAlignBox>
          </CenterAlignStack>
        </DialogContent>
      </Dialog>
    </>
  )
}

function ImageBox({
  idx,
  img,
  defaultTag,
  selectedIdxList,
  setSelectedIdxList,
  clonedImageTags,
  setClonedImageTags,
}) {
  const [selected, setSelected] = useState(false)
  const [tag, setTag] = useState(defaultTag)

  const toggleHandle = () => setSelected(prev => !prev)

  // useEffect(() => {
  //   console.log(selectedIdxList)
  // })

  useEffect(() => {
    if (selected && !selectedIdxList.includes(idx)) {
      setSelectedIdxList([...selectedIdxList, idx])
      return
    }

    if (!selected && selectedIdxList.includes(idx)) {
      setSelectedIdxList([...selectedIdxList?.filter(i => i !== idx)])
    }
  }, [selected])

  useEffect(() => {
    const t = { ...clonedImageTags }
    t[idx] = tag
    setClonedImageTags(t)
  }, [tag])

  return (
    <Stack direction="column" sx={{ position: 'relative', m: '0.85rem' }}>
      <Card
        sx={{ width: '20.4rem', height: '244px', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.07);' }}
      >
        <CardMedia
          image={img}
          sx={
            selectedIdxList.includes(idx)
              ? { height: '170px', filter: ' brightness(80%)', cursor: 'pointer' }
              : { height: '170px', cursor: 'pointer' }
          }
          onClick={toggleHandle}
        />
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',

            width: '204px',
            height: '74px',
            padding: '8px 4px',
          }}
        >
          <TextField
            multiline
            variant="standard"
            InputProps={{
              disableUnderline: true, // 라인 제거 설정
            }}
            sx={{
              width: '204px',
              height: '74px',
              padding: '0 4px ',
              '& .MuiInput-root': {
                fontWeight: 400,
                fontSize: '1.2rem',
                '& .MuiInput-input': { height: '55px !important' },
              },
            }}
            rows={2}
            placeholder=" 새로운 태그 입력 (미입력시 기존 배경과 동일)"
            disabled={!selectedIdxList.includes(idx)}
            onChange={e => {
              const value = e.target.value
              setTag(value)
            }}
          />
        </CardContent>
      </Card>
      {selectedIdxList.includes(idx) && (
        <Box
          sx={{
            position: 'absolute',
            top: '45px',
            left: '62px',
          }}
        >
          <SelectedIcon style={{ width: '8rem', height: '8rem' }} onClick={toggleHandle} />
        </Box>
      )}
    </Stack>
  )
}
