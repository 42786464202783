import { useCallback, useEffect, useRef, useState } from 'react'

import { Button, ButtonGroup, Box, Typography, Slider, Circle, LinearProgress } from '@mui/material'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import CircularSlider from '@fseehawer/react-circular-slider'
import { useRecoilState, useRecoilValue } from 'recoil'
import { bgConfigAtom, lightDirectionAtom, touchHandleAtom } from 'atoms'
import { debounce } from 'lodash'
import { BsFillLightningChargeFill } from 'react-icons/bs'

const LIGHT_RANGE_PERPEN = 392.625

const Circular = ({ setAngleValue }) => {
  const [bgConfig, setBgConfig] = useRecoilState(bgConfigAtom)
  const [touchHandle, setTouchHandle] = useRecoilState(touchHandleAtom)

  return (
    <CircularSlider
      labelColor="#005a58"
      knobColor="#868686"
      knobPosition="left"
      progressColorFrom="#5B5B5B"
      progressColorTo="#5B5B5B"
      progressSize={1}
      trackColor="#5B5B5B"
      trackSize={1}
      dataIndex={0}
      knobSize={50}
      knobDraggable={bgConfig.cast_shadow || false}
      hideLabelValue={true}
      width={220}
      min={0}
      max={6282}
      onChange={value => {
        setAngleValue(value / 1000)
      }}
      isDragging={value => {
        setTouchHandle(true)
      }}
      continuous={{
        enabled: true,
        clicks: 100,
        increment: 1,
      }}
    >
      <div></div>
    </CircularSlider>
  )
}

const CircleWithShadow = ({ x, setX, y, setY, angleValue }) => {
  const lightDirection = useRecoilValue(lightDirectionAtom)
  const [bgConfig, setBgConfig] = useRecoilState(bgConfigAtom)

  useEffect(() => {
    shadowMove()
  }, [angleValue])

  const shadowMove = e => {
    const newX = Math.cos(angleValue) * Math.min(12.5)
    const newY = Math.sin(angleValue) * Math.min(12.5)

    setX(newX)
    setY(newY)
  }

  return (
    <motion.div style={{ position: 'relative' }}>
      <div
        style={{
          width: '140px',
          height: '140px',
          borderRadius: '50%',
          backgroundColor: '#EAEAEA',
          boxShadow:
            bgConfig.cast_shadow === true
              ? lightDirection === 'horizontal'
                ? `${x}px ${y}px 10px rgba(0, 0, 0, 0.5)`
                : 'rgba(0, 0, 0, 0.70) 0px 3px 70px 2px'
              : null,
        }}
      />
    </motion.div>
  )
}

export function PerpenShadow() {
  const [bgConfig, setBgConfig] = useRecoilState(bgConfigAtom)
  const lightDirection = useRecoilValue(lightDirectionAtom)

  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [angleValue, setAngleValue] = useState(0)

  // useEffect(() => {
  //   setAngleValue(0)
  // }, [lightDirection])

  const translateValue = value => {
    if (value >= LIGHT_RANGE_PERPEN * 1 && value < LIGHT_RANGE_PERPEN * 3) {
      return 5
    }
    if (value >= LIGHT_RANGE_PERPEN * 3 && value < LIGHT_RANGE_PERPEN * 5) {
      return 4
    }
    if (value >= LIGHT_RANGE_PERPEN * 5 && value < LIGHT_RANGE_PERPEN * 7) {
      return 3
    }
    if (value >= LIGHT_RANGE_PERPEN * 7 && value < LIGHT_RANGE_PERPEN * 9) {
      return 2
    }
    if (value >= LIGHT_RANGE_PERPEN * 9 && value < LIGHT_RANGE_PERPEN * 11) {
      return 1
    }
    if (value >= LIGHT_RANGE_PERPEN * 11 && value < LIGHT_RANGE_PERPEN * 13) {
      return 0
    }
    if (value >= LIGHT_RANGE_PERPEN * 13 && value < LIGHT_RANGE_PERPEN * 15) {
      return 7
    }
    if (value >= LIGHT_RANGE_PERPEN * 15 || value < LIGHT_RANGE_PERPEN * 1) {
      return 6
    }
  }

  useEffect(() => {
    if (lightDirection === 'vertical') {
      const updateBgConfig = { ...bgConfig }
      updateBgConfig.light_src = 8

      setBgConfig(updateBgConfig)
    } else {
      const updateBgConfig = { ...bgConfig }

      updateBgConfig.light_src = translateValue(angleValue * 1000)
      setBgConfig(updateBgConfig)
    }
  }, [angleValue, lightDirection])

  return (
    <div style={{ height: '100%', display: 'flex' }}>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {lightDirection === 'horizontal' ? <Circular setAngleValue={setAngleValue} /> : null}
        <div style={{ position: 'absolute', top: '41px' }}>
          <CircleWithShadow x={x} y={y} setX={setX} setY={setY} angleValue={angleValue} />
        </div>
      </div>
    </div>
  )
}
