import { styled } from '@mui/material'

const defaultStyle = {
  color: 'black',
  border: '1px dashed gray',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

export function TempLayoutDiv({
  width = 100,
  height = 'unset',
  content = '',
  style = {},
  children,
  ...props
}) {
  return (
    <div
      style={{ ...defaultStyle, width: width, height: height, ...style }}
      {...props}
    >
      {content}
      {children}
    </div>
  )
}
