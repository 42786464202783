// material
import { alpha, styled, useTheme } from '@mui/material/styles'
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Skeleton,
  Divider,
} from '@mui/material'

import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'

import { MdMenuOpen } from 'react-icons/md'

import {
  TempLayoutDiv,
  UserInfoDrawer,
  CenterAlignBox,
  LanguageSelect,
  MenuDrawer,
  ServiceTitle,
  StyledLink,
  BackgroundSkeleton,
  CenterAlignStack,
} from 'components'

import { appMenuAtom, userAtom, languageAtom, tutorialAtom } from 'atoms'
import { useRecoilValue, useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil'

import { removeUserCookie, getAccessToken } from 'utils/cookie'
import { MHidden } from 'components/@material-extend'
import { BsBorderWidth } from 'react-icons/bs'
import { useEffect } from 'react'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { MypageIcon, PricingIcon } from 'theme/icon'
import { APPBAR_MOBILE } from './main/MainAppBar'
import { MOBILE_SECOND_APPBAR } from './InfoSideBar'
import { useTranslation } from 'react-i18next'

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.common.white,
  top: APPBAR_MOBILE,
  boxShadow: 'none',
  borderBottom: '1px solid black',
  zIndex: 1,
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexDirection: 'row',
  minHeight: `${MOBILE_SECOND_APPBAR} !important`,
  paddingLeft: '1rem',
  paddingRight: '1rem',
}))

export default function MobileMypageAppbar() {
  const { t, i18n } = useTranslation()
  const mypageMenu = [
    {
      id: 'mypage',
      title: t('menu.mypage'),
      path: '/user/mypage',
    },
    {
      id: 'credit',
      title: t('mypage_menu.credit'),
      path: '/user/creditInfo',
    },
    {
      id: 'background',
      title: t('mypage_menu.background'),
      path: '/user/background',
    },
    {
      id: 'coupon',
      title: t('mypage_menu.coupon'),
      path: '/user/coupon',
    },
    {
      id: 'userInfo',
      title: t('mypage_menu.user_info_change'),
      path: '/user/profile',
    },
  ]

  const location = useLocation()
  const theme = useTheme()

  return (
    <>
      <RootStyle>
        {' '}
        <ToolbarStyle>
          <Stack sx={{ width: '100%', backgroundColor: theme.palette.common.white }}>
            <CenterAlignBox>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {mypageMenu.map((item, idx) => (
                  <StyledLink key={item.id} to={item.path}>
                    <CenterAlignBox>
                      <Typography
                        fontWeight={700}
                        fontSize="1.4rem"
                        sx={{
                          color: location.pathname.includes(item.path)
                            ? theme.palette.draph.blue
                            : theme.palette.common.black,
                        }}
                      >
                        {item.title}
                      </Typography>
                    </CenterAlignBox>
                  </StyledLink>
                ))}
              </Box>
            </CenterAlignBox>
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    </>
  )
}
