import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import {
  Link,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Divider,
  SwipeableDrawer,
} from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import {
  CenterAlignBox,
  CenterAlignStack,
  FormTextField,
  GoogleLogin,
  KakaoLogin,
  NaverLogin,
  StyledFormTextField,
  FormErrorMessage,
  RegisterBox,
  RegisterLabel,
  TextInput,
  FormCheckbox,
  AgreeDialog,
} from 'components'
import { apis } from 'apis'
import { AiFillEye as OpenedEyeIcon, AiFillEyeInvisible as ClosedEyeIcon } from 'react-icons/ai'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useRecoilState } from 'recoil'
import { tutorialAtom, userAtom } from 'atoms'
import { styled, useTheme } from '@mui/material/styles'
import {
  AgreeCheckedSquareIcon,
  AgreeSquareIcon,
  CheckedSquareIcon,
  CheckedSquareIcon2,
  SearchIcon,
  SquareIcon,
  SquareIcon2,
} from 'theme/icon'
import { motion } from 'framer-motion'
import { RiThermometerFill } from 'react-icons/ri'
import { MHidden } from 'components/@material-extend'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useSnackbar } from 'notistack'
import useConfirm from 'hooks/useConfirm'
import moment from 'moment'
import { iOS } from 'utils/common'

import { useNavigateForPromotion, useNavigateWithQuery } from 'hooks/useNavigateWithQuery'
import { promotionInfo } from 'pages/promotionEvents/DomeggookEvent'
import NaverCommerceRegister from 'pages/NaverCommerceRegister'
import Register from 'pages/Register'

const StyledCheckbox = styled(FormControlLabel)(({ theme }) => ({
  paddingLeft: '3px',

  '& .MuiCheckbox-root': {
    color: 'blue',
  },

  '& .MuiTypography-root': {
    fontSize: '12px',
    fontWeight: 400,
    // color: theme.palette.common.black,
    color: 'black',
  },
}))

const buttonStyle = {
  width: { lg: '38.4rem', xs: '30rem' },
  height: { lg: '6rem', xs: '4.9rem' },
  fontSize: { lg: '2rem', xs: '1.5rem' },
}

export const buttonMobileStyle = {
  width: '320px',
  height: '48px',
  mt: '23px',
  fontSize: '18px',
  fontWeight: '800',
}

const TextButtonStyle = {
  fontSize: '12px',
  fontWeight: 400,
  color: 'black',
  cursor: 'pointer',
}

const loginSchema = yup.object().shape({
  username: yup.string().required('아이디를 입력해주세요'),
  password: yup.string().min(2, '비밀번호를 입력해주세요').required('비밀번호를 입력해주세요'),
})

export default function TestPage9() {
  const navigate = useNavigate()
  const [showRegisterForm, setShowRegisterForm] = useState(false)
  useEffect(() => {
    checkUser()
  }, [])

  const checkUser = async () => {
    const url = new URL(window.location.href)
    console.log(url)
    const token = url.searchParams.get('token')
    if (token) {
      const res = await apis.naverCommerce.login({ jwe: token })
      console.log(res.data)

      if (res.data.success) {
        if (res.data.registered) {
          // 로그인 처리
          const d = res.data

          const accessToken = d.access_token
          const refreshToken = d.refresh_token
          setAccessToken(accessToken)
          setRefreshToken(refreshToken)

          window.gtag('event', 'login', {
            method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
          })

          navigate('/home', { replace: true })
        } else {
          // 회원가입 입력창 띄우기 (페이지 또는 팝업)
          setShowRegisterForm(true)
        }
      }
    }
  }
  const theme = useTheme()

  return (
    <>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.common.black,
          height: '8rem',
        }}
      >
        <img src="/static/images/logo/logo_h_white.png" style={{ height: '4.9rem' }} />
      </Stack>

      {showRegisterForm && <Register naverCommerce={true} />}
    </>
  )
}

function DividerIcon(props) {
  return (
    <svg
      width="1"
      height="8"
      viewBox="0 0 1 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line x1="0.25" y1="1.09278e-08" x2="0.25" y2="8" stroke="#808080" strokeWidth="0.5" />
    </svg>
  )
}

const LoginAddinfoDialog = ({ open, setOpen, socialLogin, setSocialLogin }) => {
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const promotionId = '4'
  const { showConfirm } = useConfirm()

  const { navigateEvent } = useNavigateForPromotion()
  const { enqueueSnackbar } = useSnackbar()

  const RegisterSchema = yup.object({
    company: yup.string().required('소속을 입력해주세요.'),
    category: yup
      .array()
      .min(1, '카테고리를 하나 이상 선택해주세요.')
      .of(yup.string().required())
      .required(' '),
    agreeAge: socialLogin.socialName === 'google' && yup.boolean().oneOf([true], ' ').required(' '),
  })

  const {
    formState: { isDirty, dirtyFields, errors, isSubmitting },
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    setFocus,
    register,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues: {
      company: '',
      category: [],
      agreeAge: false,
      agreeTerms: false,
      agreeData: false,
      agreeMarketing: false,
      agreeAdvertisement: false,
    },
    resolver: yupResolver(RegisterSchema),
    mode: 'onSubmit',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'category',
  })

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', 'sign_up_begin', {})
      // ---------------------
    }
    apis.user
      .getCategory()
      .then(response => {
        const categories = response.data
        setCategories(categories)
      })
      .catch(() => {
        enqueueSnackbar('오류가 발생하였습니다', { variant: 'error' })
      })
  }, [])

  const handleCategoryChange = (category, isChecked) => {
    if (isChecked) {
      append(category.id)
    } else {
      const selectedArray = getValues('category')
      const index = selectedArray.findIndex(item => item === category.id)
      remove(index)
    }
  }

  const onSubmit = values => {
    const apisValues = { ...values }

    if (socialLogin.socialName === 'kakao') {
      const agreeArray = socialLogin.agreeInfo

      // id / snsName 기입

      apisValues.login_sns = socialLogin.socialName
      apisValues.login_sns_id = socialLogin.data.id

      agreeArray.forEach((info, idx) => {
        if (info.tag === 'user_age_check') {
          apisValues.agreeAge = true
        } else {
          const infoName = info.tag.split('_')[0]
          apisValues[`agree${infoName}`] = true
        }
      })

      apisValues.email = socialLogin.data.kakao_account.email
    }

    if (socialLogin.socialName === 'google') {
      apisValues.agreeTerms = true
      apisValues.agreeData = true
      apisValues.login_sns = socialLogin.socialName
      apisValues.login_sns_id = socialLogin.data.sub
      apisValues.email = socialLogin.data.email
    }

    if (socialLogin.socialName === 'naver') {
      apisValues.agreeTerms = true
      apisValues.agreeData = true
      apisValues.agreeAge = true
      apisValues.login_sns = socialLogin.socialName
      apisValues.login_sns_id = socialLogin.data.id
      apisValues.email = socialLogin.data.email
      apisValues.gender = socialLogin.data.gender
      apisValues.accessToken = socialLogin.accessToken
    }
    apis.user
      .createSnsUser(apisValues)
      .then(response => {
        const d = response.data

        const accessToken = d.access_token
        const refreshToken = d.refresh_token
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)

        // ----- GA4 event -----
        window.gtag('event', 'sign_up', {
          method: socialLogin.socialName,
          date: moment().local().format('YYYY-MM-DD'),
        })
        window.gtag_report_conversion_register()
        // ---------------------
        // ----- Naver Ads event -----
        var _nasa = {}
        if (window.wcs) _nasa.cnv = window.wcs.cnv('register', 0)
        if (window.runNaScript) window.runNaScript()
        // ----------------------------

        navigateEvent(promotionInfo.name, promotionInfo.promotionUrl, promotionInfo.default, {
          replace: true,
        })

        if (!localStorage.getItem(promotionInfo.name)) {
          apis.user.checkEvent(promotionId).then(response => {
            const isApply = response.data.applied
            setTutorial(prev => ({ step: 0, mode: !isApply }))
          })
        }
      })
      .catch(e => {
        console.log(e)
        if (e.response.status === 400) {
          showConfirm({
            alertOnly: true,
            content: <Typography>이미 가입된 메일입니다.</Typography>,
          })
        } else {
          showConfirm({
            alertOnly: true,
            content: <Typography>오류가 발생하였습니다.</Typography>,
          })
        }
      })

    setOpen(false)
  }

  const onChange = event => {
    const state = event.target.checked

    setValue('agreeAge', state)
    setValue('agreeMarketing', state)
    setValue('agreeAdvertisement', state)
  }

  const theme = useTheme()
  const titleStyle = { fontSize: { lg: '2.6rem', xs: '2rem' }, fontWeight: 700 }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: '90rem',
              maxWidth: '90rem',
              borderRadius: '20px',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <DialogContent sx={{ p: { lg: '3rem 0 3rem 0' } }}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <CenterAlignStack>
                <Typography sx={{ ...titleStyle }}>추가 정보</Typography>
                <Stack>
                  <CenterAlignBox
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: '2rem',
                      mb: errors?.company ? '0.4rem' : '2.8rem',
                    }}
                  >
                    <Typography sx={{ mx: '2.9rem', fontSize: '2rem', fontWeight: 500 }}>
                      소속{' '}
                      <sup
                        style={{
                          color: theme.palette.error.main,
                          fontWeight: 500,
                          fontSize: '2rem',
                          lineHeight: '2.4rem',
                          verticalAlign: 'text-top',
                        }}
                      >
                        &#42;
                      </sup>
                    </Typography>
                    <TextInput
                      control={control}
                      name="company"
                      error={!!errors.company}
                      sx={{}}
                      placeholder="소속을 기재해주세요."
                    />
                  </CenterAlignBox>
                  {errors?.company && (
                    <FormErrorMessage
                      message={errors.company?.message}
                      style={{ lineHeight: '1.2rem' }}
                    />
                  )}
                </Stack>
                <CenterAlignStack
                  sx={{
                    width: '100%',
                    // height: '26.3rem',
                    backgroundColor: '#FAFAFA',
                    justifyContent: 'center',
                    mt: '0.5rem',
                    pt: '3.8rem',
                    pb: '3.8rem',
                    mb: '2.0rem',
                  }}
                >
                  <CenterAlignStack sx={{ width: '100%' }}>
                    <Typography sx={{ ...titleStyle }}>
                      상품 카테고리{' '}
                      <sup
                        style={{
                          color: theme.palette.error.main,
                          fontWeight: 500,
                          fontSize: '2rem',
                          lineHeight: '2.4rem',
                          verticalAlign: 'text-top',
                        }}
                      >
                        &#42;
                      </sup>
                    </Typography>
                    <p
                      style={{
                        color: errors.category ? theme.palette.error.main : 'unset',
                        fontWeight: 300,
                        fontSize: '1.6rem',
                      }}
                    >
                      생성 예정인 상품 카테고리를 선택해주세요. (중복 선택 가능)
                    </p>

                    <CenterAlignStack sx={{ mt: '4rem', mx: '5.2rem', width: '100%' }}>
                      <Grid container rowSpacing={1} columns={{ lg: 20 }} sx={{ width: '82rem' }}>
                        {categories.map((category, idx) => {
                          return (
                            <Grid
                              item
                              lg={4}
                              key={idx}
                              sx={{ pl: '3rem', pt: '0 !important', display: 'flex' }}
                            >
                              <FormControlLabel
                                key={category.id}
                                label={category.name}
                                control={
                                  <Checkbox
                                    icon={<SquareIcon2 />}
                                    checkedIcon={<CheckedSquareIcon2 />}
                                    name={`category[${idx}].id`}
                                    defaultChecked={false}
                                    disableRipple
                                    onChange={e => handleCategoryChange(category, e.target.checked)}
                                  />
                                }
                              />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </CenterAlignStack>
                  </CenterAlignStack>
                </CenterAlignStack>{' '}
                {socialLogin.socialName === 'google' && (
                  <CenterAlignStack sx={{ mb: '2.3rem' }}>
                    <Stack sx={{ width: '40rem' }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontWeight: 500, fontSize: '2.0rem' }}>
                            &nbsp;&nbsp;모두 동의합니다.
                          </Typography>
                        }
                        control={
                          <Checkbox
                            icon={<AgreeSquareIcon />}
                            checkedIcon={<AgreeCheckedSquareIcon />}
                            checked={
                              watch('agreeAge') &&
                              watch('agreeMarketing') &&
                              watch('agreeAdvertisement')
                            }
                            indeterminate={
                              !(
                                watch('agreeAge') &&
                                watch('agreeMarketing') &&
                                watch('agreeAdvertisement')
                              )
                              // watch('agreeAge') !== watch('agreeTerms') ||
                              // watch('agreeTerms') !== watch('agreeData') ||
                              // watch('agreeData') !== watch('agreeAge') ||
                              // watch('agreeMarketing') !== watch('agreeAge') ||
                              // watch('agreeMarketing') !== watch('agreeTerms') ||
                              // watch('agreeMarketing') !== watch('agreeData')
                            }
                            indeterminateIcon={<AgreeSquareIcon />}
                            onChange={onChange}
                          />
                        }
                      />
                      <Divider sx={{ my: '1.3rem', borderColor: '#BBBBBB' }} />

                      <FormCheckbox
                        name="agreeAge"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '2rem',
                                fontWeight: 300,
                              },
                              errors.agreeAge
                                ? { color: theme.palette.common.red }
                                : { color: 'black' }
                            )}
                          >
                            &nbsp;[필수] 만 14세 이상입니다.
                          </Typography>
                        }
                        checked={getValues('agreeAge')}
                        sx={{ mb: '0.5rem' }}
                      />

                      <FormCheckbox
                        name="agreeMarketing"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '2rem',
                                fontWeight: 300,
                              },
                              errors.agreeMarketing ? { color: 'red' } : { color: 'black' }
                            )}
                          >
                            &nbsp;[선택] 생성물에 대한 마케팅 활용 동의
                            <AgreeDialog
                              category="agreeMarketing"
                              openButton={
                                <IconButton>
                                  <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                                </IconButton>
                              }
                            />
                          </Typography>
                        }
                        checked={getValues('agreeMarketing')}
                        sx={{ mb: '0.5rem' }}
                      />

                      <FormCheckbox
                        name="agreeAdvertisement"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '2rem',
                                fontWeight: 300,
                              },
                              errors.agreeAdvertisement ? { color: 'red' } : { color: 'black' }
                            )}
                          >
                            &nbsp;[선택] 광고성 정보 수신 동의
                            <AgreeDialog
                              category="agreeAdvertisement"
                              openButton={
                                <IconButton>
                                  <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                                </IconButton>
                              }
                            />
                          </Typography>
                        }
                        checked={getValues('agreeAdvertisement')}
                        sx={{ mb: '0.4rem' }}
                      />
                    </Stack>
                  </CenterAlignStack>
                )}
                <LoadingButton
                  size="large"
                  type="submit"
                  // onClick={handleClick}
                  // endIcon={<SendIcon />}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  // loadingPosition="end"
                  variant="contained"
                  sx={{
                    width: '32rem',
                    fontSize: '2.2rem',
                    fontWeight: 800,
                    color: theme.palette.common.white,
                  }}
                >
                  가입하기
                </LoadingButton>
              </CenterAlignStack>
            </form>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={() => {}}
          anchor="bottom"
          sx={{
            '& .MuiBackdrop-root': {
              background: 'rgba(0, 0, 0, 0.5) ',
            },
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <DialogContent sx={{ p: '3.3rem 0 4.3rem 0' }}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <CenterAlignStack>
                <Typography sx={{ ...titleStyle }}>추가 정보</Typography>
                <Stack>
                  <CenterAlignBox
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: '2.5rem',
                      mb: errors?.company ? '0.6rem' : '3rem',
                    }}
                  >
                    <Typography sx={{ mr: '1.8rem', fontSize: '1.8rem', fontWeight: 500 }}>
                      소속{' '}
                      <sup
                        style={{
                          color: theme.palette.error.main,
                          fontWeight: 500,
                          fontSize: '2rem',
                          lineHeight: '2.4rem',
                          verticalAlign: 'text-top',
                        }}
                      >
                        &#42;
                      </sup>
                    </Typography>
                    <TextInput
                      control={control}
                      name="company"
                      error={!!errors.company}
                      sx={{
                        width: '22.4rem !important',
                        height: '4rem !important',
                        minWidth: '22.4rem !important ',
                        m: '0 !important ',
                      }}
                      placeholder="소속을 기재해주세요."
                    />
                  </CenterAlignBox>
                  {errors?.company && (
                    <FormErrorMessage
                      message={errors.company?.message}
                      style={{ lineHeight: '1.2rem' }}
                    />
                  )}
                </Stack>

                <CenterAlignStack
                  sx={{
                    width: '100%',
                    height: '47.8rem',
                    backgroundColor: '#FAFAFA',
                    justifyContent: 'center',
                    mb: '3rem',
                  }}
                >
                  <CenterAlignStack>
                    <Typography sx={{ fontWeight: '700', fontSize: '2rem', pb: '1.4rem' }}>
                      {' '}
                      상품 카테고리{' '}
                      <sup
                        style={{
                          color: theme.palette.error.main,
                          fontWeight: 600,
                          fontSize: '2.5rem',
                          lineHeight: '2.9rem',
                          verticalAlign: 'text-top',
                        }}
                      >
                        &#42;
                      </sup>
                    </Typography>
                    <p
                      style={{
                        color: errors.category ? theme.palette.error.main : 'unset',
                        fontWeight: 300,
                        fontSize: '1.6rem',
                      }}
                    >
                      생성 예정인 상품 카테고리를 선택해주세요.
                    </p>
                    <p
                      style={{
                        color: errors.category ? theme.palette.error.main : 'unset',
                        fontWeight: 300,
                        fontSize: '1.6rem',
                      }}
                    >
                      (중복 선택 가능)
                    </p>
                    <CenterAlignStack sx={{ mt: '3vh', width: '35rem' }}>
                      <Grid container rowSpacing={2}>
                        {categories.map((category, idx) => {
                          return (
                            <Grid item xs={6} key={idx} sx={{ pl: '4rem', display: 'flex' }}>
                              <FormControlLabel
                                key={category.id}
                                label={category.name}
                                control={
                                  <Checkbox
                                    icon={<SquareIcon2 />}
                                    checkedIcon={<CheckedSquareIcon2 />}
                                    name={`category[${idx}].id`}
                                    defaultChecked={false}
                                    disableRipple
                                    onChange={e => handleCategoryChange(category, e.target.checked)}
                                  />
                                }
                              />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </CenterAlignStack>
                  </CenterAlignStack>
                </CenterAlignStack>

                {socialLogin.socialName === 'google' && (
                  <CenterAlignStack sx={{ mb: '2.3rem' }}>
                    <Stack sx={{ width: '32rem' }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontWeight: 500, fontSize: '1.6rem' }}>
                            &nbsp;&nbsp;모두 동의합니다.
                          </Typography>
                        }
                        control={
                          <Checkbox
                            icon={<SquareIcon2 />}
                            checkedIcon={<CheckedSquareIcon2 />}
                            checked={
                              watch('agreeAge') &&
                              watch('agreeMarketing') &&
                              watch('agreeAdvertisement')
                            }
                            indeterminate={
                              !(
                                watch('agreeAge') &&
                                watch('agreeMarketing') &&
                                watch('agreeAdvertisement')
                              )
                              // watch('agreeAge') !== watch('agreeTerms') ||
                              // watch('agreeTerms') !== watch('agreeData') ||
                              // watch('agreeData') !== watch('agreeAge') ||
                              // watch('agreeMarketing') !== watch('agreeAge') ||
                              // watch('agreeMarketing') !== watch('agreeTerms') ||
                              // watch('agreeMarketing') !== watch('agreeData')
                            }
                            indeterminateIcon={<SquareIcon2 />}
                            onChange={onChange}
                          />
                        }
                      />
                      <Divider sx={{ my: '1.3rem', borderColor: '#BBBBBB' }} />

                      <FormCheckbox
                        name="agreeAge"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '1.6rem',
                                fontWeight: 300,
                              },
                              errors.agreeAge
                                ? { color: theme.palette.common.red }
                                : { color: 'black' }
                            )}
                          >
                            &nbsp;[필수] 만 14세 이상입니다.
                          </Typography>
                        }
                        checked={getValues('agreeAge')}
                        sx={{ mb: '0.5rem' }}
                      />

                      <FormCheckbox
                        name="agreeMarketing"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '1.6rem',
                                fontWeight: 300,
                              },
                              errors.agreeMarketing ? { color: 'red' } : { color: 'black' }
                            )}
                          >
                            &nbsp;[선택] 생성물에 대한 마케팅 활용 동의
                            <AgreeDialog
                              category="agreeMarketing"
                              openButton={
                                <IconButton>
                                  <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                                </IconButton>
                              }
                            />
                          </Typography>
                        }
                        checked={getValues('agreeMarketing')}
                        sx={{ mb: '0.5rem' }}
                      />

                      <FormCheckbox
                        name="agreeAdvertisement"
                        control={control}
                        label={
                          <Typography
                            sx={Object.assign(
                              {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                fontSize: '1.6rem',
                                fontWeight: 300,
                              },
                              errors.agreeAdvertisement ? { color: 'red' } : { color: 'black' }
                            )}
                          >
                            &nbsp;[선택] 광고성 정보 수신 동의
                            <AgreeDialog
                              category="agreeAdvertisement"
                              openButton={
                                <IconButton>
                                  <SearchIcon style={{ width: '1.6rem', height: '1.6rem' }} />
                                </IconButton>
                              }
                            />
                          </Typography>
                        }
                        checked={getValues('agreeAdvertisement')}
                        sx={{ mb: '0.4rem' }}
                      />
                    </Stack>
                  </CenterAlignStack>
                )}
                <LoadingButton
                  size="large"
                  type="submit"
                  // onClick={handleClick}
                  // endIcon={<SendIcon />}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  // loadingPosition="end"
                  variant="contained"
                  sx={{
                    width: '32rem',
                    fontSize: '2.2rem',
                    fontWeight: 800,
                    color: theme.palette.common.white,
                  }}
                >
                  가입하기
                </LoadingButton>
              </CenterAlignStack>
            </form>
          </DialogContent>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}
