import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Link,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  TextField,
  Stack,
  Box,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import {
  CenterAlignBox,
  CenterAlignStack,
  FormErrorMessage,
  FormTextField,
  GoogleLogin,
  KakaoLogin,
  NaverLogin,
  StyledFormTextField,
} from 'components'
import { apis } from 'apis'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useRecoilState } from 'recoil'
import { userAtom } from 'atoms'
import { styled, useTheme } from '@mui/material/styles'
import { CheckedSquareIcon, SquareIcon, VerticalDividerIcon } from 'theme/icon'
import { motion } from 'framer-motion'
import { useSnackbar } from 'notistack'
import { PWD_REGEX } from 'utils/common'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { buttonMobileStyle } from './Login'
import { useTranslation } from 'react-i18next'

const buttonStyle = {
  width: '38.4rem',
}

const TextButtonStyle = {
  fontSize: '1.2rem',
  fontWeight: 400,
  color: 'black',
  cursor: 'pointer',
}

export default function ResetPW() {
  const [reset, setReset] = useState(true)
  const [result, setResult] = useState(false)

  return (
    <motion.div
      initial={{ x: 10, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {reset ? (
        <ResetForm find={find} setReset={setReset} setResult={setResult} />
      ) : result ? (
        <Result result={result} />
      ) : (
        <></>
      )}
    </motion.div>
  )
}

function ResetForm({ setReset, setResult }) {
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const username = params.get('username')
  const email = params.get('email')

  const { t, i18n } = useTranslation()

  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    checkedPassword: false,
  })
  const navigate = useNavigate()
  const theme = useTheme()

  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const resetPWSchema = yup.object().shape({
    newPassword: yup
      .string()
      .matches(PWD_REGEX, t('register.password_error_1'))
      .min(4, t('register.password_error_2'))
      .max(20, t('register.password_error_3'))
      .required(t('register.password_error_4')),
    checkedPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], t('register.password_check_error_1')),
  })

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      newPassword: '',
      checkedPassword: '',
    },
    resolver: yupResolver(resetPWSchema),
  })

  useEffect(() => {}, [])

  const onSubmit = values => {
    setLoading(true)

    apis.user
      .updatePassword({
        username: username,
        email: email,
        new_password: values.newPassword,
        token: token,
      })
      .then(() => {
        setReset(false)
        setResult(true)
      })
      .catch(() => {
        enqueueSnackbar(t('reset_password.reset_error_1'), { variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <CenterAlignStack sx={{ pt: '8rem' }}>
      <Typography fontSize="3rem" fontWeight={800}>
        {t('reset_password.reset')}
      </Typography>
      <Typography color={theme.palette.text.lightgrey}>
        {' '}
        {t('reset_password.reset_notice_1')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CenterAlignStack sx={{ py: 5 }}>
          <StyledFormTextField
            sx={{ mb: '0 !important' }}
            placeholder={t('profile.new_password')}
            name="newPassword"
            type={showPassword.newPassword ? 'text' : 'password'}
            control={control}
            errors={errors}
            autoComplete="new-password"
            englishMode={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() =>
                      setShowPassword(prev => ({
                        ...prev,
                        newPassword: !prev.newPassword,
                      }))
                    }
                  >
                    {showPassword.newPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.newPassword?.message.length > 0 && (
            <FormErrorMessage message={errors.newPassword?.message} />
          )}

          <StyledFormTextField
            sx={{ mb: '0 !important' }}
            placeholder={t('profile.new_password_check')}
            name="checkedPassword"
            type={showPassword.checkedPassword ? 'text' : 'password'}
            control={control}
            errors={errors}
            autoComplete="new-password"
            englishMode={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() =>
                      setShowPassword(prev => ({
                        ...prev,
                        checkedPassword: !prev.checkedPassword,
                      }))
                    }
                  >
                    {showPassword.checkedPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <FormErrorMessage message={errors.checkedPassword?.message} />
          </Box>
          <Stack sx={{ py: '1rem' }}></Stack>
          <Desktop>
            <LoadingButton
              size="large"
              type="submit"
              // onClick={handleClick}
              // endIcon={<SendIcon />}
              loading={loading}
              // loadingPosition="end"
              variant="contained"
              sx={buttonStyle}
            >
              {t('reset_password.apply')}
            </LoadingButton>
          </Desktop>
          <Mobile>
            <LoadingButton
              size="large"
              type="submit"
              // onClick={handleClick}
              // endIcon={<SendIcon />}
              loading={loading}
              // loadingPosition="end"
              variant="contained"
              sx={buttonMobileStyle}
            >
              {t('reset_password.apply')}
            </LoadingButton>
          </Mobile>
        </CenterAlignStack>
      </form>
    </CenterAlignStack>
  )
}

function Result({ result }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {}, [])

  return (
    <CenterAlignStack sx={{ pt: '8rem' }}>
      <Typography fontSize="3rem" fontWeight={800}></Typography>
      <CenterAlignStack sx={{ py: 5 }}>
        <Typography fontSize="2.4rem" fontWeight={600}>
          {t('reset_password.complete')}
        </Typography>
        <Stack sx={{ py: '2rem' }}></Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          sx={{ width: '100%', mb: '3rem' }}
        ></Stack>
        <Desktop>
          <LoadingButton
            size="large"
            type="submit"
            onClick={() => navigate('/login')}
            variant="contained"
            sx={buttonStyle}
          >
            {t('reset_password.back_login_button')}
          </LoadingButton>
        </Desktop>

        <Mobile>
          <LoadingButton
            size="large"
            type="submit"
            onClick={() => navigate('/login')}
            variant="contained"
            sx={buttonMobileStyle}
          >
            {t('reset_password.back_login_button')}
          </LoadingButton>
        </Mobile>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}
