import { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric'
import { AlignGuidelines } from 'fabric-guideline-plugin'
import FontFaceObserver from 'fontfaceobserver'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { CenterAlignBox, CenterAlignStack } from 'components'
import {
  BlueCircleCheckIcon,
  CheckedCircleIcon,
  CheckedCircleIconNotistack,
  CircleCheckIcon,
  CloseCuteIcon,
  DropdownArrowIcon,
  PlusIcon,
  PlusIcon2,
  PlusMobileIcon,
  RegenerateIcon,
} from 'theme/icon'
import { motion, AnimatePresence } from 'framer-motion'
import { fontSize } from './Toolbar'

const layers = {
  canvasSize: {
    width: 500,
    height: 500,
  },
  whiteRect: {
    x: 150,
    y: 110,
    width: 500,
    height: 500,
    id: 'whiteRect',
  },
  shapes: [
    {
      id: 'a45b89e1-31f2-4475-8d1a-b9911dfd8130',
      x: 0,
      y: 0,
      width: 500,
      height: 500,
      fill: 'rgb(170, 186, 83)',
      text: 'background 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'background',
        background_type: 'inpaint',
        bg_color: '',
        bg_blur_intensity: 0,
        bg_inpaint_image_url:
          'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
        bg_inpaint_prompt: '',
        bg_inpaint_image_bbox: [294, 292, 471, 469],
        bg_inpaint_background_image_url: '',
        bg_inpaint_background_image_bbox: [],
        bg_inpaint_background_mask_bbox: [],
        bg_concept_prompt: '',
        bg_reference_image_url: '',
        bg_reference_image_bbox: [],
        bbox: [0, 0, 500, 500],
      },
    },
    {
      id: '04673144-ae4a-4c5c-8442-5aa3abb51ff6',
      x: 294,
      y: 292,
      width: 177,
      height: 177,
      fill: 'rgba(255, 255, 255, 0.6)',
      text: 'inpaint 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'inpaint',
        bg_inpaint_image_url:
          'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
        imageUrl:
          'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
        bbox: [294, 292, 471, 469],
      },
    },
    {
      id: '9880271c-879b-42f4-8bba-9b203f5070a1',
      x: 29,
      y: 33,
      width: 442,
      height: 100,
      fill: 'rgb(169, 35, 169)',
      text: 'text 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'text',
        text_type: 'copy',
        text_copy_type: 'info',
        text_price_prev_price: '',
        text_price_post_price: '',
        text_custom: '',
        text_role: 'title',
        linebreak: 'false',
        text_font: 'auto',
        text_size: 'auto',
        text_align: 'center',
        text_vertical_align: 'center',
        text_color: [50, 50, 50, 1],
        text_bg_color: [255, 255, 255, 1],
        bbox: [29, 33, 471, 133],
      },
    },
    {
      id: 'edc71199-85d6-4deb-9e51-2d8514fc9ced',
      x: 29,
      y: 145,
      width: 173,
      height: 175,
      fill: 'rgb(187, 30, 177)',
      text: 'image 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'image',
        has_main_image: false,
        image_type: 'paste',
        image_human_prompt: '',
        image_concept_prompt: '',
        image_reference_image: '',
        image_frame_form: 'nukki',
        image_frame_beyond: false,
        image_blur_intensity: 0,
        image_gradation_direction: 'None',
        image_inpaint_img_url: '',
        image_inpaint_bbox: [],
        image_inpaint_prompt: '',
        bbox: [29, 145, 202, 320],
      },
    },
    {
      id: 'f9f97835-bc88-4ca0-927b-74f2893afa36',
      x: 29,
      y: 330,
      width: 139,
      height: 139,
      fill: 'rgb(67, 193, 236)',
      text: 'logo 1',
      result_s3_url:
        'https://upload-pipeline-data.s3.us-west-2.amazonaws.com/59e936f7-743f-4369-88a1-e1cad4c6ba51.jpeg',
      config: {
        type: 'logo',
        logo_image_url: '',
        bbox: [29, 330, 168, 469],
      },
    },
  ],
  defaultConfig: {
    product_name: '',
    product_feature: '',
    brand_name: '',
    brand_feature: '',
    filter_grain_flag: false,
    filter_grain_intensity: 0,
    filter_sharpen_flag: false,
    filter_whitebalance_flag: false,
    filter_whitebalance_temperature: 0,
  },
}
const EDITOR_LISTS = [
  {
    avatar: '/static/images/test_sample/a2.webp',
    title: '기본 설정 레이어',
    subtitle: '상품-스튜디오',
  },
  { avatar: '/static/images/test_sample/a3.webp', title: '메인 배경', subtitle: '상품' },
  { avatar: '/static/images/test_sample/a4.jpeg', title: '텍스트', subtitle: '카피라이트' },
  { avatar: '/static/images/test_sample/a3.webp', title: '로고', subtitle: '로고 이미지' },
  {
    avatar: '/static/images/test_sample/a5.png',
    title: '디자인 요소',
    subtitle: '상품-상품 장식',
  },
  {
    avatar: '/static/images/test_sample/a4.jpeg',
    title: '필터 레이어',
    subtitle: '상품-상품 장식',
  },
]

export default function TestPage18() {
  return (
    <>
      <Test1 />
    </>
  )
}

const Test1 = () => {
  const [open, setOpen] = useState(true)

  const handleClose = (e, r) => {
    if (r === 'backdropClick') return

    setOpen(false)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiModal-backdrop': {
          backgroundColor: '#30303099',
          backdropFilter: 'blur(20px)',
        },
        '& .MuiDialog-paper': {
          minWidth: '1280px',
          minHeight: '808px',
          borderRadius: '16px',
        },
      }}
    >
      <CenterAlignStack sx={{ width: '100%' }}>
        <EditorBar handleClose={handleClose} />
        <Box sx={{ display: 'flex', width: '100%' }}>
          <LayerPart />
          <CanvasPart />
        </Box>
      </CenterAlignStack>
    </Dialog>
  )
}

const EditorBar = ({ handleClose, title = '닥터지 바쿠치올 포어젯 세럼' }) => {
  return (
    <CenterAlignBox
      sx={{
        justifyContent: 'space-between',
        height: '6rem',
        alignItems: 'center',
        width: '100%',
        p: '1.8rem 2rem',
        '& img': {
          width: '2.8rem',
        },

        '& .logo': {
          display: 'flex',
          gap: '0.8rem',
        },

        '& .close': {
          display: 'flex',
          width: '15.348rem',
          justifyContent: 'end',
        },

        '& .title': {
          fontSize: '1.8rem',
          fontWeight: 600,
        },
      }}
    >
      <Box className="logo">
        <img src="/static/images/logo/logo_image.png" />
        <Typography sx={{ fontSize: '2rem', fontWeight: 700 }}>Draph Editor</Typography>
      </Box>

      <Typography className="title">{title}</Typography>

      <Box className="close">
        <IconButton sx={{ p: '0.8rem' }}>
          <CloseCuteIcon />
        </IconButton>
      </Box>
    </CenterAlignBox>
  )
}

const LayerPart = () => {
  const theme = useTheme()
  const [selectedLayer, setSelectedLayer] = useState(null)

  return (
    <Stack
      sx={{
        width: '38.4rem',
        position: 'relative',
        overflow: 'hidden',
        // backgroundColor: 'rgba(41,163,27,0.3)',
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },

        '& .MuiListItemButton-root.default': {
          height: '6rem',
          p: '1.2rem 2.4rem 1.2rem 2rem',

          '&:hover': {
            backgroundColor: theme.palette.draph.lighterblue, // 호버 시 배경색 변경
          },

          '& .MuiListItemAvatar-root': {
            mr: '1.2rem',

            '& .MuiAvatar-root': {
              width: '3.6rem',
              height: '3.6rem',
            },
          },

          '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: '1.6rem',
            lineHeight: 'normal',
            mb: '0.2rem',
          },

          '& .MuiListItemText-secondary': {
            fontWeight: 400,
            fontSize: '1.2rem',
            lineHeight: 'normal',
          },
        },
      }}
    >
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: selectedLayer ? -384 : 0 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <List disablePadding>
          {EDITOR_LISTS.map((l, i) => (
            <ListItemButton
              className="default"
              disableGutters
              disableRipple
              key={i}
              onClick={() => setSelectedLayer(l)}
            >
              <ListItemAvatar>
                <Avatar>
                  <img src={l.avatar} alt={l.title} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={l.title} secondary={l.subtitle} />
              <Box sx={{ transform: 'rotate(-90deg)' }}>
                <DropdownArrowIcon size="xs" color={theme.palette.common.black} />
              </Box>
            </ListItemButton>
          ))}
          <ListItemButton className="default">
            <CenterAlignBox
              sx={{
                backgroundColor: '#989898',
                width: '3.6rem',
                height: '3.6rem',
                borderRadius: '50%',
                alignItems: 'center',
                mr: '1.2rem',
              }}
            >
              <PlusMobileIcon style={{ width: '1.8rem', height: '1.8rem' }} color="white" />
            </CenterAlignBox>
            <Typography sx={{ fontWeight: 700, fontSize: '1.6rem', lineHeight: 'normal' }}>
              구성 요소 추가
            </Typography>
          </ListItemButton>
        </List>
      </motion.div>
      <AnimatePresence>
        {selectedLayer && (
          <motion.div
            initial={{ x: 384 }}
            animate={{ x: 0 }}
            exit={{ x: 384 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <LayerSettings layer={selectedLayer} onBack={() => setSelectedLayer(null)} />
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  )
}

const CanvasPart = () => {
  return (
    <CenterAlignStack
      sx={{
        width: '100%',
        flex: 1,
        backgroundColor: 'rgba(76,13,127,0.3)',
        minHeight: '748px',
      }}
    >
      dd
    </CenterAlignStack>
  )
}

const LayerSettings = ({ layer, onBack }) => {
  const theme = useTheme()
  return (
    <Stack
      sx={{
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },

        '& .MuiListItemButton-root.default': {
          height: '6rem',
          p: '1.2rem 2.8rem 1.2rem 2.8rem',

          '&:hover': {
            backgroundColor: theme.palette.draph.lighterblue, // 호버 시 배경색 변경
          },

          '& .MuiListItemAvatar-root': {
            mr: '1.2rem',

            '& .MuiAvatar-root': {
              width: '3.6rem',
              height: '3.6rem',
            },
          },

          '& .MuiListItemText-primary': {
            fontWeight: 700,
            fontSize: '1.6rem',
            lineHeight: 'normal',
            mb: '0.2rem',
          },

          '& .MuiListItemText-secondary': {
            fontWeight: 400,
            fontSize: '1.2rem',
            lineHeight: 'normal',
          },
        },
      }}
    >
      <ListItemButton
        className="default"
        disableGutters
        disableRipple
        onClick={onBack}
        selected={true}
      >
        <Box sx={{ transform: 'rotate(90deg)', ml: '1.2rem' }}>
          <DropdownArrowIcon size="xs" color={theme.palette.common.black} />
        </Box>
        <ListItemAvatar sx={{ ml: '1.2rem' }}>
          <Avatar>
            <img src={layer.avatar} alt={layer.title} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={layer.title} secondary={layer.subtitle} />
      </ListItemButton>

      <CenterAlignBox
        sx={{
          my: '2.4rem',
          gap: '0.8rem',
          '& .box': {
            width: '16.8rem',
            height: '4rem',
            fontSize: '1.6rem',
            fontWeight: 600,
            alignItems: 'center',
            borderRadius: '4px',
            cursor: 'pointer',

            '& .content': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
              transition: 'transform 0.3s ease-in-out', // transition 추가
            },

            '&.regenerate': {
              color: theme.palette.draph.blue,
              backgroundColor: theme.palette.draph.lighterblue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },

            '&.accept': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.draph.blue,

              '&:hover .content': {
                transform: 'scale(1.05)', // hover 시 글자와 아이콘 크기 증가
              },
            },
          },
        }}
      >
        <CenterAlignBox className="box regenerate">
          <Box className="content">
            <RegenerateIcon color={theme.palette.draph.blue} />
            재생성
          </Box>
        </CenterAlignBox>

        <CenterAlignBox className="box accept">
          <Box className="content">
            <CircleCheckIcon color={theme.palette.common.white} />
            적용
          </Box>
        </CenterAlignBox>
      </CenterAlignBox>

      {layer.title === '기본 설정 레이어' && <CanvasSetting />}
    </Stack>

    //     {layer.title === '메인 배경' && <BackgroundSettings />}
    //     {layer.title === '텍스트' && <TextSettings />}
  )
}

const CanvasSetting = () => {}
