import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { apis } from 'apis'
import { CenterAlignBox } from 'components/CenterAlignContainer'
import { useTemplates } from 'hooks/useReactQuery'
import { useEffect, useRef, useState } from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { Group, Layer, Rect, Stage, Text } from 'react-konva'
import { OpenedEyeIcon } from 'theme/icon'
import { isDev } from 'utils/common'

const DEFAULT_IMAGE_URL = 'https://i.pinimg.com/564x/e2/55/4d/e2554dea5499f88c242178ce7ed568d9.jpg'
const MAX_SELECTIONS = 1

export default function BannerTemplatesList({ selectedIds, setSelectedIds, size }) {
  console.log('?')
  const {
    templates,
    isLoading,
    isError,
    goToNextPage,
    goToPreviousPage,
    hasPreviousPage,
    hasNextPage,
  } = useTemplates()

  const handleSelect = id => {
    setSelectedIds(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(selectedId => selectedId !== id)
      } else {
        return [id]
      }
    })
  }

  const isMaxSelected = selectedIds.length >= MAX_SELECTIONS

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <CircularProgress />
      </Box>
    )
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Typography color="error">
          데이터를 불러오는 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.
        </Typography>
      </Box>
    )
  }

  return (
    <CenterAlignBox>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button
          onClick={goToPreviousPage}
          variant="outlined"
          disabled={!hasPreviousPage}
          sx={{ mr: 1 }}
        >
          <BsChevronLeft className="mr-2 h-4 w-4" /> Previous
        </Button>
      </Box>
      <CenterAlignBox sx={{ p: 2, width: '78rem' }}>
        <Grid container spacing={2}>
          {templates.map((t, index) => (
            <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
              <Box sx={{ position: 'relative' }}>
                <TemplateCard
                  template={t}
                  isSelected={selectedIds.includes(t.banner_id)}
                  onSelect={() => handleSelect(t.banner_id)}
                  isMaxSelected={isMaxSelected}
                  size={size}
                />
                {isDev && (
                  <Box sx={{ position: 'absolute', top: '0.5rem' }}>
                    <TemplatePreviewDialog data={JSON.parse(t.template.data)} />
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </CenterAlignBox>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button onClick={goToNextPage} variant="outlined" disabled={!hasNextPage} sx={{ ml: 1 }}>
          Next <BsChevronRight className="ml-2 h-4 w-4" />
        </Button>
      </Box>
    </CenterAlignBox>
  )
}
function TemplateCard({ template, isSelected, onSelect, isMaxSelected, size }) {
  const templateData = JSON.parse(template.template.data)
  const templateId = template.banner_id
  const imageUrl = templateData.templateImage || DEFAULT_IMAGE_URL

  return (
    <Box
      sx={{
        width: size.width,
        height: size.height,
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={() => {
        onSelect()
      }}
    >
      <Card
        sx={{
          width: '100%',
          height: '100%',
          // position: 'absolute',
          top: 0,
          left: 0,
          opacity: isMaxSelected && !isSelected ? 0.5 : 1,
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: isMaxSelected && !isSelected ? 'grayscale(100%)' : 'none',
          }}
          image={imageUrl}
          alt={templateData.name || 'Template image'}
        />
      </Card>
      {isSelected && (
        <Box
          sx={{
            position: 'absolute',
            top: -3,
            left: -3,
            right: -3,
            bottom: -3,
            border: '3px solid #1976d2',
            borderRadius: '19px',
            boxShadow: '0 0 10px rgba(25, 118, 210, 0.5)',
            pointerEvents: 'none',
          }}
        />
      )}
    </Box>
  )
}

export const TemplatePreviewDialog = ({ data }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <IconButton
        onClick={() => {
          setOpen(true)
        }}
      >
        <OpenedEyeIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        sx={{
          overflow: 'auto',
          '& .MuiDialog-paper': {
            maxHeight: 'none',
            maxWidth: 'none',
          },
        }}
      >
        <TemplatePiece data={data} />
      </Dialog>
    </>
  )
}

function TemplatePiece({ data }) {
  const [shapes, setShapes] = useState([])
  const [whiteRect, setWhiteRect] = useState(null)

  const [adjustedSize, setAdjustedSize] = useState({ width: 0, height: 0 })
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 })

  const [scale, setScale] = useState(1)

  const stageRef = useRef(null)
  const shapeRef = useRef(null)
  const theme = useTheme()

  // useEffect(() => {
  //   console.log(JSON.parse(templateInfo.template.data), 'templateInfo')
  //   console.log(selectedTemplate?.banner_id)
  //   console.log(templateInfo?.banner_id)
  // }, [selectedTemplate])

  useEffect(() => {
    const { whiteRect: whiteRectData, shapes: shapesData, canvasSize: canvasSizeData } = data
    console.log(data)

    setWhiteRect(whiteRectData)
    setCanvasSize(canvasSizeData)

    const { width, height, scale } = calculateAdjustedSize(canvasSizeData)
    setAdjustedSize({ width, height })
    setScale(scale)

    const adjustedShapes = shapesData.map(shape => ({
      ...shape,
      x: shape.x * scale,
      y: shape.y * scale,
      width: shape.width * scale,
      height: shape.height * scale,
    }))
    setShapes(adjustedShapes)
  }, [data])

  // useEffect(() => {
  //   const stage = stageRef.current
  //   stage.scale({ x: 0.1, y: 0.1 })
  // }, [])

  const calculateAdjustedSize = size => {
    const maxSize = 500
    const aspectRatio = size.width / size.height
    let newWidth, newHeight, scale

    if (size.width > size.height) {
      newWidth = Math.min(size.width, maxSize)
      newHeight = newWidth / aspectRatio
      scale = newWidth / size.width
    } else {
      newHeight = Math.min(size.height, maxSize)
      newWidth = newHeight * aspectRatio
      scale = newHeight / size.height
    }

    return { width: Math.round(newWidth), height: Math.round(newHeight), scale }
  }

  const handleWheel = e => {
    if (e.evt.ctrlKey) {
      e.evt.preventDefault()
      const scaleBy = 1.1
      const stage = stageRef.current
      const oldScale = stage.scaleX()

      let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy
      newScale = Math.max(0.1, Math.min(newScale, 1))

      stage.scale({ x: newScale, y: newScale })

      stage.position({
        x: (150 - newScale * 150) / 2,
        y: (150 - newScale * 150) / 2,
      })

      stage.batchDraw()
    }
  }

  return (
    <Stage
      width={adjustedSize.width}
      height={adjustedSize.height}
      ref={stageRef}
      // onWheel={handleWheel}
      style={{
        backgroundColor: 'rgba(128, 128, 128, 0.3)',
      }}
    >
      <Layer>
        {whiteRect && (
          <Rect
            x={whiteRect.x * scale}
            y={whiteRect.y * scale}
            width={whiteRect.width * scale}
            height={whiteRect.height * scale}
            fill="white"
            stroke="gray"
          />
        )}
        {shapes.map((shape, i) => (
          <Group ref={shapeRef} {...shape} key={i}>
            <Rect width={shape.width} height={shape.height} fill={shape.fill} />
            <Text
              text={shape.text}
              width={shape.width}
              height={shape.height}
              align="center"
              verticalAlign="middle"
              fontSize={shape.width * 0.16}
            />
          </Group>
        ))}
      </Layer>
    </Stage>
  )
}
