import { Box, Modal, Typography } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const imageStyle = {
  maxWidth: '50vw',
  maxHeight: '80vh',
}

export function ViewModal({ open, setOpen, image }) {
  const handleClose = () => {
    setOpen(false)
  }
  return <div></div>
}
