import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { CenterAlignStack } from 'components/CenterAlignContainer'
import { isArray } from 'lodash'
import { useTranslation } from 'react-i18next'
import EditCanvas from './EditCanvas'
import { CheckedCircleIcon, CircleIcon } from 'theme/icon'
import { useEffect, useRef, useState } from 'react'
import { OpacityMotionDiv } from 'components/CustomStyle'
import { bannerTextFontAtom, pieceConfigAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import { fabric } from 'fabric'
import { AlignGuidelines } from 'fabric-guideline-plugin'
import { getS3ImageSrc, rgbToHex } from 'utils/common'
import { FONT_INFO, FONT_DICT } from 'config'

const fontStyles = {
  title: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  subtitle: {
    fontSize: '13px',
    lineHeight: 'normal',
    mt: '4px',
  },
  copyTitle: {
    fontSize: '13px',
    fontWeight: 600,
    // mb: '8px',
    lineHeight: 'normal',
  },
  copy: {
    fontSize: '12px',
    lineHeight: '14px',
  },
}

export default function TextEditor({
  canvasRef,
  canvas,
  setCanvas,
  artwork,
  copy,
  currentPiece,
  scale,
  setScale,
}) {
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  const [pieceConfig, setPieceConfig] = useRecoilState(pieceConfigAtom)
  const [fontInfo, setFontInfo] = useRecoilState(bannerTextFontAtom)
  const [displayFontSize, setDisplayFontSize] = useState({ fontSize: 40, scaleX: 1 })
  const [alignContent, setAlignContent] = useState('center')

  const artworkConfig = JSON.parse(artwork.config)
  // const [selectedTitleIdx, setSelectedTitleIdx] = useState(0)
  // const [selectedsubTitleIdx, setSelectedSubTitleIdx] = useState(0)
  const [manualTitleInput, setManualTitleInput] = useState('')
  const [manualSubtitleInput, setManualSubtitleInput] = useState('')
  // const [selectedTitle, setSelectedTitle] = useState('')
  // const [selectedSubtitle, setSelectedSubtitle] = useState('')

  const textDefaultOption = (pieceConfig, id, addOption) => {
    if (!pieceConfig) return {}

    if (id === 'title') {
      return {
        left: pieceConfig.main_text_lu_pt[0],
        top: pieceConfig.main_text_lu_pt[1],
        fontFamily:
          FONT_INFO[FONT_DICT[currentPiece.config.main_text_font]]?.fontFamily ??
          FONT_INFO['Pretendard Regular'].fontFamily,
        fontSize: pieceConfig.main_text_size,
        fill: rgbToHex(pieceConfig.main_text_color),
        angle: pieceConfig.main_text_angle ?? 0,
        id: 'title',
        ...addOption,
      }
    } else {
      return {
        left: pieceConfig.sub_text_lu_pt[0],
        top:
          pieceConfig.sub_text_lu_pt[1] === pieceConfig.main_text_lu_pt[1]
            ? pieceConfig.sub_text_lu_pt[1] + (pieceConfig.main_text_size * 2 + 30)
            : pieceConfig.sub_text_lu_pt[1],
        fontFamily:
          FONT_INFO[FONT_DICT[currentPiece.config.sub_text_font]]?.fontFamily ??
          FONT_INFO['Pretendard Regular'].fontFamily,
        fontSize: pieceConfig.sub_text_size,
        fill: rgbToHex(pieceConfig.sub_text_color),
        angle: pieceConfig.sub_text_angle ?? 0,
        id: 'subtitle',
        ...addOption,
      }
    }
  }

  const createText = (canvasInstance, text, option) => {
    // 가장 긴 줄의 너비 맞추기 ...

    let maxWidth = 0
    const textLines = text.split('\n')

    // 각 줄의 너비 계산
    textLines.forEach(line => {
      const tempText = new fabric.Text(line, {
        ...option,
      })
      if (tempText.width > maxWidth) {
        maxWidth = tempText.width
      }
    })

    const textbox = new fabric.Textbox(text, {
      width: maxWidth + 10,
      fontFamily: 'Arial',
      fontSize: 22,
      fill: '#000000',
      fixedWidth: maxWidth + 10,
      ...option,
      // backgroundColor: 'rgba(123,45,26,0.7)',
    })

    textbox.setControlsVisibility({
      mt: false, // 상단 중앙 조절점 비활성화
      mb: false, // 하단 중앙 조절점 비활성화
    })

    textbox.on('deselected', function () {
      if (this.text === '') {
        canvasInstance.remove(this)
      }
    })

    // if (id === 'main') {
    //   mainTextboxRef.current = textbox
    // } else {
    //   subTextboxRef.current = textbox
    // }
    textbox.on('modified', e => {
      if (e.action === 'scale') {
        const textbox = e.target

        setFontInfo({
          ...fontInfo,
          font: textbox.fontFamily,
          fontSize: Math.round(textbox.fontSize),
          scaleX: textbox.scaleX,
          fontColor: textbox.fill,
        })

        setDisplayFontSize({
          fontSize: Math.round(textbox.fontSize * textbox.scaleX),
          scaleX: textbox.scaleX,
        })
      }
    })

    return textbox
  }

  // useEffect(() => {
  //   console.log('fontSize', fontInfo.fontSize)
  //   console.log('fontdisplay Size', displayFontSize.fontSize)
  // })

  useEffect(() => {
    if (canvas) {
      canvas.on('text:changed', e => {
        const { target } = e

        const textLines = target.textLines
        const firstElement = textLines[0] + '\n'
        let secondElement = ''

        for (let i = 1; i < textLines.length; i++) {
          if (i !== textLines.length - 1) {
            secondElement += textLines[i] + '\n'
          } else if (textLines[i] === '') {
            break
          }
        }

        if (target.id === 'title') {
          // const editText = target.text.split('\n')
          // const firstElement = editText[0]
          // const secondElement = editText.slice(1).join('\n')

          setPieceConfig(prev => ({
            ...prev,
            main_text_idx: Number(idxSorter(target.id, 'manual')),
            main_text_line_1: firstElement,
            main_text_line_2: secondElement,
          }))
        }

        if (target.id === 'subtitle') {
          // const editText = target.text.split('\n')
          // const firstElement = editText[0]
          // const secondElement = editText.slice(1).join('\n')

          setPieceConfig(prev => ({
            ...prev,
            sub_text_idx: Number(idxSorter(target.id, 'manual')),
            sub_text_line_1: firstElement,
            sub_text_line_2: secondElement,
          }))
        }
      })
    }
  }, [canvas])

  useEffect(() => {
    // console.log('first ??')
    const canvasInstance = new fabric.Canvas(canvasRef.current, { width: 540, height: 540 })

    const imageUrl = getS3ImageSrc(currentPiece.config.base_image_s3_url)
    setCanvas(canvasInstance)

    const guideline = new AlignGuidelines({
      canvas: canvasInstance,
      ignoreObjTypes: [{ key: 'hasGuideLine', value: 'no' }],
      // verticalLines: [23, 40, 20],
    })

    guideline.init()

    fabric.Object.prototype.set({
      snapThreshold: 5,
      snapAngle: 90,
    })

    fabric.Image.fromURL(
      `${imageUrl}?w=${Date.now().toString()}`,
      function (img) {
        // 이미지 원본 크기
        const imgWidth = img.width
        const imgHeight = img.height

        // 캔버스 최대 크기
        const maxCanvasWidth = 540
        const maxCanvasHeight = 540

        // 이미지 비율 계산
        const widthRatio = maxCanvasWidth / imgWidth
        const heightRatio = maxCanvasHeight / imgHeight

        // 가장 작은 비율을 기준으로 이미지 크기 조정
        const scale = Math.min(widthRatio, heightRatio)

        // 이미지 크기 조정
        // img.scale(scale)
        setScale(scale)
        // 캔버스 크기 조정
        canvasInstance.setWidth(img.width * scale)
        canvasInstance.setHeight(img.height * scale)
        canvasInstance.setZoom(scale)

        // 캔버스에 이미지 추가
        canvasInstance.setBackgroundImage(img, canvasInstance.renderAll.bind(canvasInstance))

        const isNothingIdxTitle =
          Number(idxSorter('title', 'nothing')) === pieceConfig.main_text_idx
        const isNothingIdxSubtitle =
          Number(idxSorter('subtitle', 'nothing')) === pieceConfig.sub_text_idx

        let text1, text2

        if (!isNothingIdxTitle) {
          text1 = createText(
            canvasInstance,
            `${pieceConfig.main_text_line_1}\n${pieceConfig.main_text_line_2}`,
            textDefaultOption(pieceConfig, 'title')
          )
          canvasInstance.add(text1)
        }

        if (!isNothingIdxSubtitle) {
          text2 = createText(
            canvasInstance,
            `${pieceConfig.sub_text_line_1}\n${pieceConfig.sub_text_line_2}`,
            textDefaultOption(pieceConfig, 'subtitle')
          )
          canvasInstance.add(text2)
        }

        const points = [
          { x1: img.width / 2, y1: 0, x2: img.width / 2, y2: 0 },
          { x1: img.width / 2, y1: img.height, x2: img.width / 2, y2: img.height },
          { x1: 0, y1: img.height / 2, x2: 0, y2: img.height / 2 },
          { x1: img.width, y1: img.height / 2, x2: img.width, y2: img.height / 2 },
        ]

        points.forEach(point => {
          const line = new fabric.Line([point.x1, point.y1, point.x2, point.y2], {
            stroke: 'black', // 선의 색상
            strokeWidth: 0.1, // 선의 너비
            selectable: false, // 선(점)을 선택할 수 없도록 설정
          })

          canvasInstance.add(line)
        })

        // 조건에 따라 편집 모드로 설정할 텍스트 객체 결정
        if (text1) {
          canvasInstance.setActiveObject(text1)
          text1.enterEditing()
        } else if (text2) {
          canvasInstance.setActiveObject(text2)
          text2.enterEditing()
        }

        const angleDisplay = new fabric.Text('', {
          fontSize: 14,
          originX: 'center',
          originY: 'center',
          fill: '#ffffff',
          fontFamily: 'Pretendard Regular',
          visible: false, // 초기에는 보이지 않게 설정
          hasGuideLine: 'no',
        })

        const background = new fabric.Rect({
          width: angleDisplay.getScaledWidth() + 30, // 텍스트의 스케일된 너비에 여백 추가
          height: angleDisplay.getScaledHeight() + 20, // 텍스트의 스케일된 높이에 여백 추가
          rx: 10, // x축 방향 둥근 모서리 반지름
          ry: 10, // y축 방향 둥근 모서리 반지름
          fill: '#4d80ff', // 배경색
          opacity: '0.8',
          originX: 'center',
          originY: 'center',
          hasGuideLine: 'no',
          // visible: false,
        })

        const group = new fabric.Group([background, angleDisplay], {
          visible: false,
          hasGuideLine: 'no',
        })

        canvasInstance.add(group)

        // 객체가 회전할 때 실행할 이벤트 핸들러
        canvasInstance.on('object:rotating', function (e) {
          const target = e.target
          const angle = target.angle.toFixed(0)

          // mtr 핸들 기본 위치와 추가 거리 계산
          const offset = 80 // mtr 핸들에서 각도 표시까지의 추가 거리
          const rotatePoint = target.getPointByOrigin('center', 'top')
          const radianAngle = fabric.util.degreesToRadians(target.angle)
          const dx = (target.height / 2 + offset) * Math.sin(radianAngle)
          const dy = (target.height / 2 + offset) * Math.cos(radianAngle)

          angleDisplay.set({
            text: `${angle}°`,
            visible: true,
          })
          background.set({
            width: angleDisplay.getScaledWidth() + 30,
          })
          group.set({
            left: rotatePoint.x + dx,
            top: rotatePoint.y - dy,
            visible: true,
          })

          group.setCoords()
          canvasInstance.bringToFront(group)
          canvasInstance.renderAll()
        })

        // 마우스 놓았을 때 각도 표시 숨김
        canvasInstance.on('mouse:up', function () {
          group.set('visible', false)
          canvasInstance.renderAll()
        })

        if (artworkConfig.logoS3path) {
          fabric.Image.fromURL(
            `${getS3ImageSrc(artworkConfig.logoS3path)}?w=${Date.now().toString()}`,
            function (img) {
              const imgWidth = img.width
              const imgHeight = img.height

              // 캔버스 최대 크기
              const maxCanvasWidth = pieceConfig.logo_size[0]
              const maxCanvasHeight = pieceConfig.logo_size[1]

              // 이미지 비율 계산
              const widthRatio = maxCanvasWidth / imgWidth
              const heightRatio = maxCanvasHeight / imgHeight

              // 가장 작은 비율을 기준으로 이미지 크기 조정
              const scale = Math.min(widthRatio, heightRatio)

              // 캔버스 크기 조정
              // canvasInstance.setWidth(img.width * scale)
              // canvasInstance.setHeight(img.height * scale)

              img.set({
                left: pieceConfig.logo_lu_pt[0], // x 위치
                top: pieceConfig.logo_lu_pt[1], // y 위치
                scaleX: scale,
                scaleY: scale,
              })

              img.setControlsVisibility({
                mtr: false,
              })

              // 캔버스에 이미지 추가
              canvasInstance.add(img)
            },
            { crossOrigin: 'anonymous' }
          )
        }
      },

      { crossOrigin: 'anonymous' }
    )

    canvasInstance.on('selection:created', e => {
      // console.log('selection', e.selected)
      const obj = e.selected

      if (obj.length === 1 && obj[0].type === 'textbox') {
        setFontInfo({
          ...fontInfo,
          font: obj[0].fontFamily,
          fontSize: Math.round(obj[0].fontSize),
          fontColor: obj[0].fill,
          scaleX: obj[0].scaleX,
        })
        setDisplayFontSize({
          fontSize: Math.round(obj[0].fontSize * obj[0].scaleX),
          scaleX: obj[0].scaleX,
        })

        setAlignContent(obj[0].textAlign)
      }
    })

    canvasInstance.on('selection:updated', e => {
      // console.log('updated', e.selected)
      const obj = e.selected

      if (obj.length === 1 && obj[0].type === 'textbox') {
        setFontInfo({
          ...fontInfo,
          font: obj[0].fontFamily,
          fontSize: Math.round(obj[0].fontSize),
          fontColor: obj[0].fill,
          scaleX: obj[0].scaleX,
        })
        setDisplayFontSize({
          fontSize: Math.round(obj[0].fontSize * obj[0].scaleX),
          scaleX: obj[0].scaleX,
        })
      }
    })

    // 두 개의 텍스트 박스 생성

    const handleKeyDown = e => {
      if (e.key === 'Delete') {
        const activeObject = canvasInstance.getActiveObject()

        if (activeObject && !activeObject.isEditing) {
          // 단일 객체 삭제
          if (!activeObject.type || activeObject.type !== 'activeSelection') {
            if (activeObject.id === 'title') {
              setPieceConfig(prev => ({
                ...prev,
                main_text_idx: Number(idxSorter('title', 'nothing')),
                main_text_line_1: '',
                main_text_line_2: '',
              }))
            }

            if (activeObject.id === 'subtitle') {
              setPieceConfig(prev => ({
                ...prev,
                sub_text_idx: Number(idxSorter('subtitle', 'nothing')),
                sub_text_line_1: '',
                sub_text_line_2: '',
              }))
            }

            canvasInstance.remove(activeObject)
          } else {
            // 그룹화된 객체 삭제
            activeObject.forEachObject(obj => {
              canvasInstance.remove(obj)
            })
            setPieceConfig(prev => ({
              ...prev,
              main_text_idx: Number(idxSorter('title', 'nothing')),
              main_text_line_1: '',
              main_text_line_2: '',
              sub_text_idx: Number(idxSorter('subtitle', 'nothing')),
              sub_text_line_1: '',
              sub_text_line_2: '',
            }))
            // 그룹 해제
            canvasInstance.discardActiveObject()
          }
          // canvasInstance.requestRenderAll()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    // 컴포넌트 언마운트 시 이벤트 리스너 제거

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const updateOrCreateTextBox = (text, id, isManualIdx = false, isNothingIdx = false) => {
    let textBox = canvas.getObjects().find(obj => obj.id === id)
    if (textBox) {
      const addOption = getTextBoxSettings(textBox)

      canvas.remove(textBox)

      if (!isNothingIdx) {
        textBox = createText(
          canvas,
          isManualIdx ? '' : text,
          textDefaultOption(pieceConfig, id, addOption)
        )
        canvas.add(textBox)
        canvas.setActiveObject(textBox)
        // 첫 번째 텍스트 박스를 편집 모드로 설정
        textBox.enterEditing()
      }
    } else if (!isNothingIdx) {
      textBox = createText(canvas, isManualIdx ? '' : text, textDefaultOption(pieceConfig, id))
      canvas.add(textBox)

      canvas.setActiveObject(textBox)
      // 첫 번째 텍스트 박스를 편집 모드로 설정
      textBox.enterEditing()
    }
    canvas.renderAll()
  }

  // useEffect(() => {
  //   if (canvas) {
  //     canvas.on('text:changed', e => {
  //       console.log('e', e)
  //     })
  //   }
  // }, [canvas])

  const idxSorter = (state, type = 'manual') => {
    const manualIdx = (copy[state].length - 2).toString()
    const nothingIdx = (copy[state].length - 1).toString()

    if (type === 'manual') {
      return manualIdx
    } else if (type === 'nothing') {
      return nothingIdx
    }
  }

  const handleText = type => e => {
    const { value } = e.target

    if (type === 'title') {
      // console.log('canvasintitle', canvas)

      // const textboxs = canvas.getObjects()

      // textboxs.forEach(textbox => {
      //   if (textbox.id === 'main') {
      //     textbox.set('text', `${copy.title[value][0]}\n${copy.title[value][1]}`)
      //     textbox.on('changed', function (e) {
      //       const editText = this.text.split('\n')
      //       const firstElement = editText[0]
      //       const secondElement = editText.slice(1).join('\n')

      //       // setPieceConfig(prev => ({
      //       //   ...prev,
      //       //   main_text_line_1: firstElement,
      //       //   main_text_line_2: secondElement,
      //       // }))
      //     })
      //   }
      // })

      // canvas.renderAll()

      const isManualIdx = idxSorter(type, 'manual') === value
      const isNothingIdx = idxSorter(type, 'nothing') === value

      updateOrCreateTextBox(
        `${copy.title[value][0]}\n${copy.title[value][1]}`,
        type,
        isManualIdx,
        isNothingIdx
      )

      setPieceConfig(prev => ({
        ...prev,
        main_text_idx: Number(value),
        main_text_line_1: isManualIdx ? '' : copy.title[value][0],
        main_text_line_2: isManualIdx ? '' : copy.title[value][1],
      }))
    }
    if (type === 'subtitle') {
      const isManualIdx = idxSorter(type, 'manual') === value
      const isNothingIdx = idxSorter(type, 'nothing') === value

      updateOrCreateTextBox(
        `${copy.subtitle[value][0]}\n${copy.subtitle[value][1]}`,
        type,
        isManualIdx,
        isNothingIdx
      )

      setPieceConfig(prev => ({
        ...prev,
        sub_text_idx: Number(value),
        sub_text_line_1: isManualIdx ? '' : copy.subtitle[value][0],
        sub_text_line_2: isManualIdx ? '' : copy.subtitle[value][1],
      }))
    }
  }
  const handleDownload = () => {
    const currentZoom = canvas.getZoom()
    const originalWidth = canvas.getWidth()
    const originalHeight = canvas.getHeight()
    // 캔버스 zoom을 원본 크기(1.0)로 설정
    canvas.setZoom(1)
    canvas.setWidth(originalWidth / scale)
    canvas.setHeight(originalHeight / scale)

    // 변경된 zoom으로 캔버스를 이미지로 변환
    const dataURL = canvas.toDataURL({
      format: 'png',
      quality: 1.0,
    })

    // 데이터 URL을 이용해 이미지 다운로드 생성
    const link = document.createElement('a')
    link.download = 'canvas-image.png'
    link.href = dataURL
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    // 다운로드 후 캔버스 zoom을 원래대로 복구
    canvas.setZoom(currentZoom)
    canvas.setWidth(originalWidth)
    canvas.setHeight(originalHeight)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Stack sx={{ width: '292px', p: '20px 24px 19px 24px' }}>
        {' '}
        {/* <button onClick={handleDownload}>다운로드</button> */}
        <Typography sx={fontStyles.title}>{t('banner_config.editor_title_1')}</Typography>
        <Typography sx={fontStyles.subtitle}>{t('banner_config.editor_subtitle_1')}</Typography>
        <Divider flexItem sx={{ my: '15px' }} />
        <Typography sx={fontStyles.title}>{t('banner_config.editor_title_2')}</Typography>
        <Typography sx={fontStyles.subtitle}>
          {t('banner_config.editor_subtitle_2')}
          <br /> {t('banner_config.editor_subtitle_3')}
        </Typography>
        <CenterAlignStack
          sx={{
            mt: '12px',
            backgroundColor: '#F1F1F1',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            py: '6px',
            alignItems: 'center',
          }}
        >
          <Typography sx={fontStyles.copyTitle}>{t('banner_config.copy_title')} </Typography>
        </CenterAlignStack>
        <Stack
          sx={{
            backgroundColor: '#F9F9F9',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            gap: '8px',
            py: '12px',
            pl: '16px',
          }}
        >
          {copy.title.map((t, i) => {
            return (
              <FormControlLabel
                key={Number(i)}
                control={
                  <Checkbox
                    // sx={{ p: 0, mr: '0.5rem' }}
                    icon={
                      <CircleIcon
                        style={{ width: '12px', height: '12px' }}
                        color={'#ABABAB'}
                        fill={'#FFFFFF'}
                      />
                    }
                    checkedIcon={
                      <CheckedCircleIcon
                        strokeColor={theme.palette.draph.blue}
                        checkColor={theme.palette.draph.blue}
                        style={{ width: '12px', height: '12px' }}
                      />
                    }
                    value={Number(i)}
                    checked={pieceConfig.main_text_idx === i}
                    onClick={handleText('title')}
                  />
                }
                sx={{
                  ml: 0,
                  mr: 0,
                  width: 'fit-content',
                  minHeight: '15px',
                  '& span.MuiTypography-root': { ...fontStyles.copy, ml: '6px' },
                  '& .MuiButtonBase-root.MuiCheckbox-root': {
                    p: 0,
                  },
                }}
                label={
                  isArray(t) ? (
                    t.map((c, i) => {
                      return (
                        <Typography key={i} sx={fontStyles.copy}>
                          {c}
                        </Typography>
                      )
                    })
                  ) : (
                    <span key={i} style={{ ...fontStyles.copy, height: '20px' }}>
                      {t}
                    </span>
                  )
                }
              />
            )
          })}
        </Stack>
        {/* <FormControl sx={{ width: '100%' }}>
            <RadioGroup
              // value={selectedTitleIdx}
              // onChange={handleRadio('title')}
              sx={{ gap: '4px' }}
            >
              {copy.title.map((t, i) => {
                console.log(t, typeof t, isArray(t))

                return (
                  <FormControlLabel
                    key={i}
                    value={i}
                    sx={{
                      ml: '-2px',
                      mr: 0,
                      width: 'fit-content',
                      '& .MuiFormControlLabel-label': {
                        fontSize: '12px',
                        ml: '4px',
                        lineHeight: 'normal',
                      },

                      '& svg': {
                        width: 12,
                        height: 12,
                      },
                    }}
                    control={<Radio sx={{ padding: '2px' }} />}
                    label={
                      // selectedTitleIdx === i.toString() && t === '직접입력' ? (
                      //   <OpacityMotionDiv initialProps={{ x: 8 }} animateProps={{ x: 0 }}>
                      //     <OutlinedInput
                      //       // value={manualTitleInput}
                      //       sx={{
                      //         width: '18.5rem',
                      //         height: '2rem',
                      //         backgroundColor: theme.palette.common.white,
                      //         fontSize: '1.2rem',
                      //         p: '0rem 1rem',
                      //         '& .MuiOutlinedInput-input': {
                      //           p: 0,
                      //         },
                      //       }}
                      //       // onChange={e => {
                      //       //   setManualTitleInput(e.target.value)
                      //       //   setBannerConfig({ ...bannerConfig, bannerMainText1: e.target.value })
                      //       // }}
                      //     />
                      //   </OpacityMotionDiv>
                      // ) :
                      isArray(t)
                        ? t.map((c, i) => {
                            return (
                              <Typography key={i} sx={fontStyles.copy}>
                                {c}
                              </Typography>
                            )
                          })
                        : t
                    }
                  />
                )
              })}
            </RadioGroup>
          </FormControl> */}
        <CenterAlignStack
          sx={{
            mt: '12px',
            backgroundColor: '#F1F1F1',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            py: '6px',
            alignItems: 'center',
          }}
        >
          <Typography sx={fontStyles.copyTitle}>{t('banner_config.copy_subtitle')} </Typography>
        </CenterAlignStack>
        <Stack
          sx={{
            backgroundColor: '#F9F9F9',
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            gap: '8px',
            py: '12px',
            pl: '16px',
          }}
        >
          {copy.subtitle.map((t, i) => {
            return (
              <FormControlLabel
                key={Number(i)}
                control={
                  <Checkbox
                    // sx={{ p: 0, mr: '0.5rem' }}

                    icon={
                      <CircleIcon
                        style={{ width: '12px', height: '12px' }}
                        color={'#ABABAB'}
                        fill={'#FFFFFF'}
                      />
                    }
                    checkedIcon={
                      <CheckedCircleIcon
                        strokeColor={theme.palette.draph.blue}
                        checkColor={theme.palette.draph.blue}
                        style={{ width: '12px', height: '12px' }}
                      />
                    }
                    value={Number(i)}
                    checked={pieceConfig.sub_text_idx === i}
                    onClick={handleText('subtitle')}
                  />
                }
                sx={{
                  ml: 0,
                  mr: 0,
                  width: 'fit-content',
                  minHeight: '15px',
                  '& span.MuiTypography-root': { ...fontStyles.copy, ml: '6px' },
                  '& .MuiButtonBase-root.MuiCheckbox-root': {
                    p: 0,
                  },
                }}
                label={
                  isArray(t) ? (
                    t.map((c, i) => {
                      return (
                        <Typography key={i} sx={fontStyles.copy}>
                          {c}
                        </Typography>
                      )
                    })
                  ) : (
                    <span key={i} style={{ ...fontStyles.copy, height: '20px' }}>
                      {t}
                    </span>
                  )
                }
              />
            )
          })}
        </Stack>
        {/* <FormControl sx={{ width: '100%', mt: '18px' }}>
          <Typography sx={fontStyles.copyTitle}>{t('banner_config.copy_subtitle')} </Typography>
          <RadioGroup
            // value={selectedTitleIdx}
            // onChange={handleRadio('title')}
            sx={{ gap: '4px' }}
          >
            {copy.subtitle.map((t, i) => {
              return (
                <FormControlLabel
                  key={i}
                  value={i}
                  sx={{
                    ml: '-0.2rem',
                    mr: 0,
                    width: 'fit-content',
                    '& .MuiFormControlLabel-label': {
                      fontSize: '12px',
                      ml: '4px',
                      lineHeight: 'normal',
                    },

                    '& svg': {
                      width: 12,
                      height: 12,
                    },
                  }}
                  control={<Radio sx={{ padding: '0.2rem' }} />}
                  label={
                    // selectedTitleIdx === i.toString() && t === '직접입력' ? (
                    //   <OpacityMotionDiv initialProps={{ x: 8 }} animateProps={{ x: 0 }}>
                    //     <OutlinedInput
                    //       // value={manualTitleInput}
                    //       sx={{
                    //         width: '18.5rem',
                    //         height: '2rem',
                    //         backgroundColor: theme.palette.common.white,
                    //         fontSize: '1.2rem',
                    //         p: '0rem 1rem',
                    //         '& .MuiOutlinedInput-input': {
                    //           p: 0,
                    //         },
                    //       }}
                    //       // onChange={e => {
                    //       //   setManualTitleInput(e.target.value)
                    //       //   setBannerConfig({ ...bannerConfig, bannerMainText1: e.target.value })
                    //       // }}
                    //     />
                    //   </OpacityMotionDiv>
                    // ) :
                    isArray(t)
                      ? t.map((c, i) => {
                          return (
                            <Typography key={i} sx={fontStyles.copy}>
                              {c}
                            </Typography>
                          )
                        })
                      : t
                  }
                />
              )
            })}
          </RadioGroup>
        </FormControl> */}
      </Stack>
      <Divider orientation="vertical" flexItem />
      <CenterAlignStack sx={{ width: '540px', minHeight: '540px', justifyContent: ' center' }}>
        <EditCanvas
          canvasRef={canvasRef}
          canvas={canvas}
          setCanvas={setCanvas}
          artwork={artwork}
          currentPiece={currentPiece}
          displayFontSize={displayFontSize}
          setDisplayFontSize={setDisplayFontSize}
          alignContent={alignContent}
          setAlignContent={setAlignContent}

          // selectedTitleIdx={selectedTitleIdx}
          // setSelectedTitleIdx={setSelectedTitleIdx}
          // selectedsubTitleIdx={selectedsubTitleIdx}
          // setSelectedSubTitleIdx={setSelectedSubTitleIdx}
          // manualTitleInput={manualTitleInput}
          // setManualTitleInput={setManualTitleInput}
          // manualSubtitleInput={manualSubtitleInput}
          // setManualSubtitleInput={setManualSubtitleInput}
          // mainTextboxRef={mainTextboxRef}
          // subTextboxRef={subTextboxRef}
          // selectedTitle={selectedTitle}
          // setSelectedTitle={setSelectedTitle}
          // selectedSubtitle={selectedSubtitle}
          // setSelectedSubtitle={setSelectedSubtitle}
        />
      </CenterAlignStack>
    </Box>
  )
}

function getTextBoxSettings(textBox) {
  return {
    fontSize: textBox.fontSize,
    fontFamily: textBox.fontFamily,
    fontWeight: textBox.fontWeight,
    fill: textBox.fill,
    textAlign: textBox.textAlign,
    width: textBox.width,
    height: textBox.height,
    left: textBox.left,
    top: textBox.top,
    // 추가로 필요한 다른 속성들도 여기에 포함시킬 수 있습니다.
  }
}
