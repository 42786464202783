/* eslint-disable camelcase */
export const bgf_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bgf_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const nukki_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const nukki_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const nukki_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const nukki_3 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const nukki_4 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const nukki_5 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const pass_sod_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"pass_sod","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const pass_sod_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"pass_sod","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const pass_sod_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"pass_sod","output_size_list":[{"output_w": 1000, "output_h": 1000}, {"output_w": 370, "output_h": 370}, {"output_w": 265, "output_h": 265}, {"output_w": 147, "output_h": 147}, {"output_w": 72, "output_h": 72}],"simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const back_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const back_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const back_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const back_side_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const back_side_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const back_side_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const front_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const front_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const front_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const front_side_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const front_side_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const front_side_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"charancha","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_10001000_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_10001000_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":true,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_1080660_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1080, "output_h": 660}], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_1080660_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":true,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1080, "output_h": 660}], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_3 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_4 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_5 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_6 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_6601080_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 660, "output_h": 1080}], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_6601080_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":true,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 660, "output_h": 1080}], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_7 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_no_simple_8 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_10001000_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1000, "output_h": 1000}], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_1080660_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 1080, "output_h": 660}], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_3 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_4 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_5 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_6 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":true,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const bg_expansion_simple_6601080_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":false,"gen_face":false,"object_category":"food","object_sub_category":"plate","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":false,"flag_bg_expansion":true,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[{"output_w": 660, "output_h": 1080}], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const face_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_face": true},
    "gen_options": {}
}
export const face_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_face": true},
    "gen_options": {}
}
export const nation_china_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "china"},
    "gen_options": {}
}
export const nation_china_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "china"},
    "gen_options": {}
}
export const nation_europe_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "europe"},
    "gen_options": {}
}
export const nation_europe_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "europe"},
    "gen_options": {}
}
export const nation_japan_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "japan"},
    "gen_options": {}
}
export const nation_japan_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "japan"},
    "gen_options": {}
}
export const nation_northamerica_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "north_america"},
    "gen_options": {}
}
export const nation_northamerica_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "north_america"},
    "gen_options": {}
}
export const nation_southamerica_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "south_america"},
    "gen_options": {}
}
export const nation_southamerica_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "south_america"},
    "gen_options": {}
}
export const nation_southeastasia_0 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "southeast_asia"},
    "gen_options": {}
}
export const nation_southeastasia_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {"hddfs_nation_preset": "southeast_asia"},
    "gen_options": {}
}
export const car_angle_diag_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_angle_floor_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_angle_perpen_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car1_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the golden gate bridge","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car2_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1000_1000_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1000_1000_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1000_1000_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1000_1000_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1000_1000_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1080_660_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1800,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1080_660_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1800,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1080_660_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1800,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1080_660_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1800,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_1080_660_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1800,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_660_1080_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_660_1080_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_660_1080_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_660_1080_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"golden gate bridge","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_imgsize_660_1080_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"car","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_nukkionly1_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_query_auto_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the road in front of the Cafe, in the Manhattan","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_query_floor_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the road in front of the Cafe, in the Manhattan","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const car_query_perpen_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the road in front of the Cafe, in the Manhattan","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_angle_diag_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_angle_floor_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_angle_perpen_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes1_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"in the dressing room","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes2_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1000_1000_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1000_1000_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1000_1000_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1000_1000_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1000_1000_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1080_660_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1080_660_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1080_660_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1080_660_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_1080_660_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_660_1080_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_660_1080_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_660_1080_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"in the dressing room","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_660_1080_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_imgsize_660_1080_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_nukkionly1_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_query_auto_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"in front of the glass wall of the cafe","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_query_floor_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"in front of the glass wall of the cafe","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const clothes_query_perpen_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"in front of the glass wall of the cafe","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_angle_diag_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_angle_floor_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_angle_perpen_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the table in the hanok","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics1_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics2_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1000_1000_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1000_1000_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1000_1000_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1000_1000_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1000_1000_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1080_660_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1080_660_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1080_660_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1080_660_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_1080_660_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_660_1080_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_660_1080_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the table, in front of the hanok, korea","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_660_1080_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_660_1080_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_imgsize_660_1080_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"cosmetics","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_nukkionly1_1080660_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_query_auto_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the table in the dessert, Pyramid in the background","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_query_floor_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the table in the dessert, Pyramid in the background","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const cosmetics_query_perpen_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the table in the dessert, Pyramid in the background","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_angle_diag_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_angle_floor_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_angle_perpen_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"clothes","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food1_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the table with some wine","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food2_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1000_1000_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1000_1000_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1000_1000_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1000_1000_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1000_1000_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1080_660_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1080_660_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1080_660_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1080_660_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_1080_660_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_660_1080_2_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_660_1080_2_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"landmakr","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_660_1080_2_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_660_1080_2_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the table in the kitchen","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_imgsize_660_1080_2_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"food","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_nukki_1fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_nukki_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_nukki_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_nukki_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_nukkionly1_nukki_center = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_query_auto_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the living room table, Television in the background","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_query_floor_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the living room table, Television in the background","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const food_query_perpen_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the living room table, Television in the background","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_whole_angle_diag_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"diag","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_whole_angle_floor_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_whole_angle_perpen_2 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_body_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_nukki_body_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_nukki_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_nukki_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_nukki_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_nukki_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person1_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the road in the sibuya","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_body_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_nukki_body_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_nukki_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_nukki_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_nukki_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_nukki_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person2_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1000_1000_2_body_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"studio","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1000_1000_2_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"living_room","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1000_1000_2_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"office","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1000_1000_2_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"city","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1000_1000_2_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1000,"output_size_h":1000,"selected_bg_ids":"","endpoints":{},"theme_template":"outdoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1080_660_2_body_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"spring","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1080_660_2_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"summer","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1080_660_2_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"fall","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1080_660_2_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"winter","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_1080_660_2_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_660_1080_2_body_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"indoor","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_660_1080_2_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"landmark","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_660_1080_2_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"season_christmas","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_660_1080_2_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"on the road in the sibuya","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_imgsize_660_1080_2_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"person","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":660,"output_size_h":1080,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_body_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_nukki_body_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_nukki_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_nukki_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_nukki_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_nukki_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_body_3fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_nukki_body_2fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_nukki_body_4fit = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_nukki_lower = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_nukki_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_nukki_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_torso = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_nukkionly1_1080660_whole = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":false,"flag_complex_cmp":false,"flag_simple_cmp":false,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":1080,"output_size_h":660,"selected_bg_ids":"","endpoints":{},"theme_template":"auto","theme_custom":"","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_whole_query_auto_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"auto","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"in front of the pyramid, in the desert","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_whole_query_floor_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"floor","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"in front of the pyramid, in the desert","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const person_whole_query_perpen_1 = {
    "request": "",
    "images": [],
    "background_tasks": "",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiMzciLCJpYXQiOjE2OTg5NzM0NzYsImV4cCI6MTY5ODk5NTA3NiwidXNlcm5hbWUiOiJkYWVoYW4ifQ.VEWJ5M3RZEhovD1M_qn_dpjwrQa37JGRLAr3oEayaVY",
    "user_id": 37,
    "portfolio_id": "1363",
    "username": "daehan",
    "webhook_url": "",
    "use_credit_type": "",
    "gen_type": "initial",
    "options": {},
    "gen_options": {"gen_shadow":true,"gen_face":false,"object_category":"auto","object_sub_category":"auto","object_boundary":"none","object_angle":"perpen","flag_generate":true,"flag_complex_cmp":true,"flag_simple_cmp":true,"flag_white_cmp":true,"flag_bg_expansion":false,"output_size_w":0,"output_size_h":0,"selected_bg_ids":"","endpoints":{},"theme_template":"custom","theme_custom":"in front of the pyramid, in the desert","output_size_list":[], "simple_bg_color_list":[[255,255,255],[242,242,244]]}
}
export const images = [
	"/testcase/biz/bgf/bgf_0.jpg",
	"/testcase/biz/bgf/bgf_1.jpg",
	"/testcase/biz/bgf/nukki_0.png",
	"/testcase/biz/bgf/nukki_1.png",
	"/testcase/biz/bgf/nukki_2.png",
	"/testcase/biz/bgf/nukki_3.png",
	"/testcase/biz/bgf/nukki_4.png",
	"/testcase/biz/bgf/nukki_5.png",
	"/testcase/biz/bgf/pass_sod_0.png",
	"/testcase/biz/bgf/pass_sod_1.png",
	"/testcase/biz/bgf/pass_sod_2.png",
	"/testcase/biz/charancha/back_0.jpg",
	"/testcase/biz/charancha/back_1.jpg",
	"/testcase/biz/charancha/back_2.jpg",
	"/testcase/biz/charancha/back_side_0.jpg",
	"/testcase/biz/charancha/back_side_1.jpg",
	"/testcase/biz/charancha/back_side_2.jpg",
	"/testcase/biz/charancha/front_0.jpg",
	"/testcase/biz/charancha/front_1.jpg",
	"/testcase/biz/charancha/front_2.jpg",
	"/testcase/biz/charancha/front_side_0.jpg",
	"/testcase/biz/charancha/front_side_1.jpg",
	"/testcase/biz/charancha/front_side_2.jpg",
	"/testcase/biz/coupang/bg_expansion_no_simple_0.jpeg",
	"/testcase/biz/coupang/bg_expansion_no_simple_1.jpeg",
	"/testcase/biz/coupang/bg_expansion_no_simple_10001000_0.jpeg",
	"/testcase/biz/coupang/bg_expansion_no_simple_10001000_1.jpg",
	"/testcase/biz/coupang/bg_expansion_no_simple_1080660_0.jpeg",
	"/testcase/biz/coupang/bg_expansion_no_simple_1080660_1.jpg",
	"/testcase/biz/coupang/bg_expansion_no_simple_2.jpeg",
	"/testcase/biz/coupang/bg_expansion_no_simple_3.jpeg",
	"/testcase/biz/coupang/bg_expansion_no_simple_4.jpg",
	"/testcase/biz/coupang/bg_expansion_no_simple_5.jpeg",
	"/testcase/biz/coupang/bg_expansion_no_simple_6.jpeg",
	"/testcase/biz/coupang/bg_expansion_no_simple_6601080_0.jpeg",
	"/testcase/biz/coupang/bg_expansion_no_simple_6601080_1.jpg",
	"/testcase/biz/coupang/bg_expansion_no_simple_7.jpg",
	"/testcase/biz/coupang/bg_expansion_no_simple_8.jpg",
	"/testcase/biz/coupang/bg_expansion_simple_0.jpg",
	"/testcase/biz/coupang/bg_expansion_simple_1.jpg",
	"/testcase/biz/coupang/bg_expansion_simple_10001000_0.jpg",
	"/testcase/biz/coupang/bg_expansion_simple_1080660_0.jpg",
	"/testcase/biz/coupang/bg_expansion_simple_2.jpeg",
	"/testcase/biz/coupang/bg_expansion_simple_3.jpeg",
	"/testcase/biz/coupang/bg_expansion_simple_4.jpeg",
	"/testcase/biz/coupang/bg_expansion_simple_5.jpg",
	"/testcase/biz/coupang/bg_expansion_simple_6.jpg",
	"/testcase/biz/coupang/bg_expansion_simple_6601080_0.jpg",
	"/testcase/biz/hddfs/face_0.jpeg",
	"/testcase/biz/hddfs/face_1.jpeg",
	"/testcase/biz/hddfs/nation_china_0.jpeg",
	"/testcase/biz/hddfs/nation_china_1.png",
	"/testcase/biz/hddfs/nation_europe_0.jpeg",
	"/testcase/biz/hddfs/nation_europe_1.png",
	"/testcase/biz/hddfs/nation_japan_0.jpeg",
	"/testcase/biz/hddfs/nation_japan_1.png",
	"/testcase/biz/hddfs/nation_northamerica_0.jpeg",
	"/testcase/biz/hddfs/nation_northamerica_1.png",
	"/testcase/biz/hddfs/nation_southamerica_0.jpeg",
	"/testcase/biz/hddfs/nation_southamerica_1.png",
	"/testcase/biz/hddfs/nation_southeastasia_0.jpeg",
	"/testcase/biz/hddfs/nation_southeastasia_1.png",
	"/testcase/car_angle/car_angle_diag_2.jpeg",
	"/testcase/car_angle/car_angle_floor_2.jpeg",
	"/testcase/car_angle/car_angle_perpen_2.jpeg",
	"/testcase/car_default/car1_1fit.jpg",
	"/testcase/car_default/car1_2fit.jpg",
	"/testcase/car_default/car1_3fit.jpg",
	"/testcase/car_default/car1_4fit.jpg",
	"/testcase/car_default/car1_center.jpg",
	"/testcase/car_default/car1_nukki_1fit.png",
	"/testcase/car_default/car1_nukki_2fit.png",
	"/testcase/car_default/car1_nukki_3fit.png",
	"/testcase/car_default/car1_nukki_4fit.png",
	"/testcase/car_default/car1_nukki_center.png",
	"/testcase/car_default/car2_1fit.jpeg",
	"/testcase/car_default/car2_2fit.jpeg",
	"/testcase/car_default/car2_3fit.jpeg",
	"/testcase/car_default/car2_4fit.jpeg",
	"/testcase/car_default/car2_center.jpeg",
	"/testcase/car_default/car2_nukki_1fit.png",
	"/testcase/car_default/car2_nukki_2fit.png",
	"/testcase/car_default/car2_nukki_3fit.png",
	"/testcase/car_default/car2_nukki_4fit.png",
	"/testcase/car_default/car2_nukki_center.png",
	"/testcase/car_imgsize/car_imgsize_1000_1000_2_1fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_1000_1000_2_2fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_1000_1000_2_3fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_1000_1000_2_4fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_1000_1000_2_center.jpeg",
	"/testcase/car_imgsize/car_imgsize_1080_660_2_1fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_1080_660_2_2fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_1080_660_2_3fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_1080_660_2_4fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_1080_660_2_center.jpeg",
	"/testcase/car_imgsize/car_imgsize_660_1080_2_1fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_660_1080_2_2fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_660_1080_2_3fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_660_1080_2_4fit.jpeg",
	"/testcase/car_imgsize/car_imgsize_660_1080_2_center.jpeg",
	"/testcase/car_nukkionly/car_nukkionly1_1fit.jpg",
	"/testcase/car_nukkionly/car_nukkionly1_2fit.jpg",
	"/testcase/car_nukkionly/car_nukkionly1_3fit.jpg",
	"/testcase/car_nukkionly/car_nukkionly1_4fit.jpg",
	"/testcase/car_nukkionly/car_nukkionly1_center.jpg",
	"/testcase/car_nukkionly/car_nukkionly1_nukki_1fit.png",
	"/testcase/car_nukkionly/car_nukkionly1_nukki_2fit.png",
	"/testcase/car_nukkionly/car_nukkionly1_nukki_3fit.png",
	"/testcase/car_nukkionly/car_nukkionly1_nukki_4fit.png",
	"/testcase/car_nukkionly/car_nukkionly1_nukki_center.png",
	"/testcase/car_query/car_query_auto_1.jpg",
	"/testcase/car_query/car_query_floor_1.jpg",
	"/testcase/car_query/car_query_perpen_1.jpg",
	"/testcase/clothes_angle/clothes_angle_diag_2.png",
	"/testcase/clothes_angle/clothes_angle_floor_2.png",
	"/testcase/clothes_angle/clothes_angle_perpen_2.png",
	"/testcase/clothes_default/clothes1_1fit.png",
	"/testcase/clothes_default/clothes1_2fit.png",
	"/testcase/clothes_default/clothes1_3fit.png",
	"/testcase/clothes_default/clothes1_4fit.png",
	"/testcase/clothes_default/clothes1_center.png",
	"/testcase/clothes_default/clothes1_nukki_1fit.png",
	"/testcase/clothes_default/clothes1_nukki_2fit.png",
	"/testcase/clothes_default/clothes1_nukki_3fit.png",
	"/testcase/clothes_default/clothes1_nukki_4fit.png",
	"/testcase/clothes_default/clothes1_nukki_center.png",
	"/testcase/clothes_default/clothes2_1fit.png",
	"/testcase/clothes_default/clothes2_2fit.png",
	"/testcase/clothes_default/clothes2_3fit.png",
	"/testcase/clothes_default/clothes2_4fit.png",
	"/testcase/clothes_default/clothes2_center.png",
	"/testcase/clothes_default/clothes2_nukki_1fit.png",
	"/testcase/clothes_default/clothes2_nukki_2fit.png",
	"/testcase/clothes_default/clothes2_nukki_3fit.png",
	"/testcase/clothes_default/clothes2_nukki_4fit.png",
	"/testcase/clothes_default/clothes2_nukki_center.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1000_1000_2_1fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1000_1000_2_2fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1000_1000_2_3fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1000_1000_2_4fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1000_1000_2_center.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1080_660_2_1fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1080_660_2_2fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1080_660_2_3fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1080_660_2_4fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_1080_660_2_center.png",
	"/testcase/clothes_imgsize/clothes_imgsize_660_1080_2_1fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_660_1080_2_2fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_660_1080_2_3fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_660_1080_2_4fit.png",
	"/testcase/clothes_imgsize/clothes_imgsize_660_1080_2_center.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_1fit.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_2fit.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_3fit.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_4fit.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_center.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_nukki_1fit.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_nukki_2fit.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_nukki_3fit.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_nukki_4fit.png",
	"/testcase/clothes_nukkionly/clothes_nukkionly1_nukki_center.png",
	"/testcase/clothes_query/clothes_query_auto_1.png",
	"/testcase/clothes_query/clothes_query_floor_1.png",
	"/testcase/clothes_query/clothes_query_perpen_1.png",
	"/testcase/cosmetics_angle/cosmetics_angle_diag_2.jpeg",
	"/testcase/cosmetics_angle/cosmetics_angle_floor_2.jpeg",
	"/testcase/cosmetics_angle/cosmetics_angle_perpen_2.jpeg",
	"/testcase/cosmetics_default/cosmetics1_1fit.jpeg",
	"/testcase/cosmetics_default/cosmetics1_2fit.jpeg",
	"/testcase/cosmetics_default/cosmetics1_3fit.jpeg",
	"/testcase/cosmetics_default/cosmetics1_4fit.jpeg",
	"/testcase/cosmetics_default/cosmetics1_center.jpeg",
	"/testcase/cosmetics_default/cosmetics1_nukki_1fit.png",
	"/testcase/cosmetics_default/cosmetics1_nukki_2fit.png",
	"/testcase/cosmetics_default/cosmetics1_nukki_3fit.png",
	"/testcase/cosmetics_default/cosmetics1_nukki_4fit.png",
	"/testcase/cosmetics_default/cosmetics1_nukki_center.png",
	"/testcase/cosmetics_default/cosmetics2_1fit.jpeg",
	"/testcase/cosmetics_default/cosmetics2_2fit.jpeg",
	"/testcase/cosmetics_default/cosmetics2_3fit.jpeg",
	"/testcase/cosmetics_default/cosmetics2_4fit.jpeg",
	"/testcase/cosmetics_default/cosmetics2_center.jpeg",
	"/testcase/cosmetics_default/cosmetics2_nukki_1fit.png",
	"/testcase/cosmetics_default/cosmetics2_nukki_2fit.png",
	"/testcase/cosmetics_default/cosmetics2_nukki_3fit.png",
	"/testcase/cosmetics_default/cosmetics2_nukki_4fit.png",
	"/testcase/cosmetics_default/cosmetics2_nukki_center.png",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1000_1000_2_1fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1000_1000_2_2fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1000_1000_2_3fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1000_1000_2_4fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1000_1000_2_center.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1080_660_2_1fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1080_660_2_2fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1080_660_2_3fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1080_660_2_4fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_1080_660_2_center.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_660_1080_2_1fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_660_1080_2_2fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_660_1080_2_3fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_660_1080_2_4fit.jpeg",
	"/testcase/cosmetics_imgsize/cosmetics_imgsize_660_1080_2_center.jpeg",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_1fit.jpeg",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_2fit.jpeg",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_3fit.jpeg",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_4fit.jpeg",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_center.jpeg",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_nukki_1fit.png",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_nukki_2fit.png",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_nukki_3fit.png",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_nukki_4fit.png",
	"/testcase/cosmetics_nukkionly/cosmetics_nukkionly1_nukki_center.png",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_1fit.jpeg",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_2fit.jpeg",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_3fit.jpeg",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_4fit.jpeg",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_center.jpeg",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_nukki_1fit.png",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_nukki_2fit.png",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_nukki_3fit.png",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_nukki_4fit.png",
	"/testcase/cosmetics_nukkionly_imgsize/cosmetics_nukkionly1_1080660_nukki_center.png",
	"/testcase/cosmetics_query/cosmetics_query_auto_1.jpeg",
	"/testcase/cosmetics_query/cosmetics_query_floor_1.jpeg",
	"/testcase/cosmetics_query/cosmetics_query_perpen_1.jpeg",
	"/testcase/food_angle/food_angle_diag_2.jpg",
	"/testcase/food_angle/food_angle_floor_2.jpg",
	"/testcase/food_angle/food_angle_perpen_2.jpg",
	"/testcase/food_default/food1_1fit.jpeg",
	"/testcase/food_default/food1_2fit.jpeg",
	"/testcase/food_default/food1_3fit.jpeg",
	"/testcase/food_default/food1_4fit.jpeg",
	"/testcase/food_default/food1_center.jpeg",
	"/testcase/food_default/food1_nukki_1fit.png",
	"/testcase/food_default/food1_nukki_2fit.png",
	"/testcase/food_default/food1_nukki_3fit.png",
	"/testcase/food_default/food1_nukki_4fit.png",
	"/testcase/food_default/food1_nukki_center.png",
	"/testcase/food_default/food2_nukki_1fit.png",
	"/testcase/food_default/food2_nukki_2fit.png",
	"/testcase/food_default/food2_nukki_3fit.png",
	"/testcase/food_default/food2_nukki_4fit.png",
	"/testcase/food_default/food2_nukki_center.png",
	"/testcase/food_nukkionly/food_nukkionly1_1fit.jpeg",
	"/testcase/food_nukkionly/food_nukkionly1_2fit.jpeg",
	"/testcase/food_nukkionly/food_nukkionly1_3fit.jpeg",
	"/testcase/food_nukkionly/food_nukkionly1_4fit.jpeg",
	"/testcase/food_nukkionly/food_nukkionly1_center.jpeg",
	"/testcase/food_nukkionly/food_nukkionly1_nukki_1fit.png",
	"/testcase/food_nukkionly/food_nukkionly1_nukki_2fit.png",
	"/testcase/food_nukkionly/food_nukkionly1_nukki_3fit.png",
	"/testcase/food_nukkionly/food_nukkionly1_nukki_4fit.png",
	"/testcase/food_nukkionly/food_nukkionly1_nukki_center.png",
	"/testcase/food_query/food_query_auto_1.jpeg",
	"/testcase/food_query/food_query_floor_1.jpeg",
	"/testcase/food_query/food_query_perpen_1.jpeg",
	"/testcase/person_angle/person_whole_angle_diag_2.jpeg",
	"/testcase/person_angle/person_whole_angle_floor_2.jpeg",
	"/testcase/person_angle/person_whole_angle_perpen_2.jpeg",
	"/testcase/person_default/person1_body_3fit.jpeg",
	"/testcase/person_default/person1_body_4fit.jpeg",
	"/testcase/person_default/person1_lower.jpeg",
	"/testcase/person_default/person1_nukki_body_2fit.png",
	"/testcase/person_default/person1_nukki_body_4fit.png",
	"/testcase/person_default/person1_nukki_lower.png",
	"/testcase/person_default/person1_nukki_torso.png",
	"/testcase/person_default/person1_nukki_whole.png",
	"/testcase/person_default/person1_torso.jpeg",
	"/testcase/person_default/person1_whole.jpeg",
	"/testcase/person_default/person2_body_3fit.jpeg",
	"/testcase/person_default/person2_body_4fit.jpeg",
	"/testcase/person_default/person2_lower.jpeg",
	"/testcase/person_default/person2_nukki_body_3fit.png",
	"/testcase/person_default/person2_nukki_body_4fit.png",
	"/testcase/person_default/person2_nukki_lower.png",
	"/testcase/person_default/person2_nukki_torso.png",
	"/testcase/person_default/person2_nukki_whole.png",
	"/testcase/person_default/person2_torso.jpeg",
	"/testcase/person_default/person2_whole.jpeg",
	"/testcase/person_imgsize/person_imgsize_1000_1000_2_body_3fit.jpeg",
	"/testcase/person_imgsize/person_imgsize_1000_1000_2_body_4fit.jpeg",
	"/testcase/person_imgsize/person_imgsize_1000_1000_2_lower.jpeg",
	"/testcase/person_imgsize/person_imgsize_1000_1000_2_torso.jpeg",
	"/testcase/person_imgsize/person_imgsize_1000_1000_2_whole.jpeg",
	"/testcase/person_imgsize/person_imgsize_1080_660_2_body_3fit.jpeg",
	"/testcase/person_imgsize/person_imgsize_1080_660_2_body_4fit.jpeg",
	"/testcase/person_imgsize/person_imgsize_1080_660_2_lower.jpeg",
	"/testcase/person_imgsize/person_imgsize_1080_660_2_torso.jpeg",
	"/testcase/person_imgsize/person_imgsize_1080_660_2_whole.jpeg",
	"/testcase/person_imgsize/person_imgsize_660_1080_2_body_3fit.jpeg",
	"/testcase/person_imgsize/person_imgsize_660_1080_2_body_4fit.jpeg",
	"/testcase/person_imgsize/person_imgsize_660_1080_2_lower.jpeg",
	"/testcase/person_imgsize/person_imgsize_660_1080_2_torso.jpeg",
	"/testcase/person_imgsize/person_imgsize_660_1080_2_whole.jpeg",
	"/testcase/person_nukkionly/person_nukkionly1_body_3fit.jpeg",
	"/testcase/person_nukkionly/person_nukkionly1_body_4fit.jpeg",
	"/testcase/person_nukkionly/person_nukkionly1_lower.jpeg",
	"/testcase/person_nukkionly/person_nukkionly1_nukki_body_2fit.png",
	"/testcase/person_nukkionly/person_nukkionly1_nukki_body_4fit.png",
	"/testcase/person_nukkionly/person_nukkionly1_nukki_lower.png",
	"/testcase/person_nukkionly/person_nukkionly1_nukki_torso.png",
	"/testcase/person_nukkionly/person_nukkionly1_nukki_whole.png",
	"/testcase/person_nukkionly/person_nukkionly1_torso.jpeg",
	"/testcase/person_nukkionly/person_nukkionly1_whole.jpeg",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_body_3fit.jpeg",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_body_4fit.jpeg",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_lower.jpeg",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_nukki_body_2fit.png",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_nukki_body_4fit.png",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_nukki_lower.png",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_nukki_torso.png",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_nukki_whole.png",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_torso.jpeg",
	"/testcase/person_nukkionly_imgsize/person_nukkionly1_1080660_whole.jpeg",
	"/testcase/person_query/person_whole_query_auto_1.jpeg",
	"/testcase/person_query/person_whole_query_floor_1.jpeg",
	"/testcase/person_query/person_whole_query_perpen_1.jpeg",
]
