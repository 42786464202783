import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getAccessToken } from 'utils/cookie'
import { Korean, English } from 'hooks/useLanguage'
import { useTheme } from '@emotion/react'

export const GradationButton = styled(Button)(({ theme }) => ({
  color: 'white',
  width: '26rem',
  height: '6rem',
  background: 'linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',

  borderRadius: '60px',
  fontSize: '2.8rem',
  fontWeight: 800,
  '&:hover': {
    boxShadow: '2px 2px 15px 0px #00E8B94D',
  },

  [theme.breakpoints.down('lg')]: {
    width: '12.8rem',
    height: '3.6rem',
    fontSize: '1.6rem',
    fontWeight: 700,
    padding: 0,
  },
}))

export const GradationOutlineButton = styled(Button)(({ theme }) => ({
  color: 'white',
  width: '12rem',
  height: '4rem',

  fontSize: '1.8rem',
  fontWeight: 700,

  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,

  borderRadius: '60px',
  border: '2px solid transparent',
  backgroundImage:
    'linear-gradient(#303030, #303030),linear-gradient(90deg, #2C4DFF 0%, #8D00FC 100%)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',

  '&:hover': {
    boxShadow: '2px 2px 15px 0px #00E8B94D',
  },

  [theme.breakpoints.down('lg')]: {
    width: '7.2rem',
    height: '2.8rem',
    fontSize: '1.4rem',
  },
}))

export const SolidColorReverseButton = styled(Button)(({ theme }) => ({
  color: 'white',
  width: '12rem',
  lineHeight: 1.4,

  fontSize: '2rem',
  fontWeight: 700,

  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,

  borderRadius: '30px',
  border: '2px solid transparent',

  background: theme.palette.draph.newblue,

  '&:hover': {
    background: 'white',
    color: theme.palette.draph.newblue,

    '& svg path': {
      fill: theme.palette.draph.newblue,
      transition: 'all 0.2s',
    },
  },
  '& svg path': {
    transition: 'all 0.2s',
  },

  transition: 'all 0.2s',

  [theme.breakpoints.down('lg')]: {
    width: '8.4rem',
    fontSize: '1.6rem',
  },
}))

export function MainStartButton({ type = null, sx }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const token = getAccessToken()
  const theme = useTheme()

  const style = {
    'black-blue': {
      color: 'white',
      background: theme.palette.common.black,
      '& svg path': {
        fill: 'white',
      },
      '&:hover': {
        color: 'white',
        background: theme.palette.draph.newblue,
        '& svg path': {
          fill: 'white',
        },
      },
    },
  }

  return (
    <>
      <SolidColorReverseButton
        onClick={() => {
          if (token) {
            navigate('/generate/upload?pages=1')
          } else {
            navigate('/join')
            // ----- GA4 event -----
            window.gtag('event', 'home_sign_up', {})
            // ---------------------
          }
        }}
        sx={{
          py: '1rem',
          width: { lg: '40rem', xs: '32rem' },

          ...(type && style[type]),

          ...sx,
        }}
      >
        <Korean> {t('button.get_started')}</Korean>
        {/* <Korean>{token ? t('button.get_started') : '지금 사용하기'}</Korean> */}
        <English>{token ? t('button.get_started') : t('button.start_free')}</English>
        &nbsp;&nbsp;
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.465 0.244655C11.0074 -0.111207 10.3456 -0.0788559 9.92504 0.341709L9.82799 0.451617C9.47213 0.90929 9.50448 1.57106 9.92504 1.99162L13.766 5.83333H1.41667C0.772334 5.83333 0.25 6.35567 0.25 7C0.25 7.64433 0.772334 8.16667 1.41667 8.16667H13.766L9.92504 12.0084L9.82799 12.1183C9.47213 12.576 9.50448 13.2377 9.92504 13.6583C10.3807 14.1139 11.1193 14.1139 11.575 13.6583L17.4083 7.82496L17.5053 7.71505C17.8612 7.25738 17.8289 6.59561 17.4083 6.17504L11.575 0.341709L11.465 0.244655Z"
            fill="white"
          />
        </svg>
      </SolidColorReverseButton>
    </>
  )
}

export function MainToIndexPageButton({ type = null, sx }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const token = getAccessToken()
  const theme = useTheme()

  const style = {
    'black-blue': {
      color: 'white',
      background: theme.palette.common.black,
      '& svg path': {
        fill: 'white',
      },
      '&:hover': {
        color: 'white',
        background: theme.palette.draph.newblue,
        '& svg path': {
          fill: 'white',
        },
      },
    },
  }

  return (
    <>
      <SolidColorReverseButton
        onClick={() => {
          navigate('/overview/bg_generation')
        }}
        sx={{
          py: '1rem',
          width: { lg: '40rem', xs: '32rem' },

          ...(type && style[type]),

          ...sx,
        }}
      >
        <Korean>모든 기능 확인하기</Korean>
        <English>Explore All Features</English>
        &nbsp;&nbsp;
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.465 0.244655C11.0074 -0.111207 10.3456 -0.0788559 9.92504 0.341709L9.82799 0.451617C9.47213 0.90929 9.50448 1.57106 9.92504 1.99162L13.766 5.83333H1.41667C0.772334 5.83333 0.25 6.35567 0.25 7C0.25 7.64433 0.772334 8.16667 1.41667 8.16667H13.766L9.92504 12.0084L9.82799 12.1183C9.47213 12.576 9.50448 13.2377 9.92504 13.6583C10.3807 14.1139 11.1193 14.1139 11.575 13.6583L17.4083 7.82496L17.5053 7.71505C17.8612 7.25738 17.8289 6.59561 17.4083 6.17504L11.575 0.341709L11.465 0.244655Z"
            fill="white"
          />
        </svg>
      </SolidColorReverseButton>
    </>
  )
}
