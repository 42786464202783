import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import * as config from 'config'
import { useEffect, useState } from 'react'

import { apis } from 'apis'
import { CenterAlignStack, CenterAlignBox, DialogButton, CustomTooltip } from 'components'

import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'

import { t } from 'i18next'
import { PORTFOLIO_CONFIG_DEFAULT, portfolioAtom } from 'atoms'
import { userPortfolioNamesSelector } from 'selector'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { SwipeableDrawer } from '@mui/material'
import useConfirm from 'hooks/useConfirm'
import { useTranslation } from 'react-i18next'
import { iOS } from 'utils/common'

export default function CreatePortfolioWithConfigDialog({
  open,
  setOpen,
  commonConfig,
  setCommonConfig,
  createPortfolio,
}) {
  useEffect(() => {}, [])

  const handleClose = (e, reason) => {
    // ----- GA4 event -----
    if (reason && reason === 'backdropClick') {
      console.log('dim click ')
      window.gtag('event', 'suggest_portfolio_popup', { method: 'dimmed' })
    } else {
      console.log('cancel close ')
      window.gtag('event', 'suggest_portfolio_popup', { method: 'cancel' })
    }
    // ---------------------
    setOpen(false)
    setCommonConfig({})
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          sx={{
            '& .MuiDialog-paper': {
              py: '2rem',
              px: 0,
              borderRadius: '2rem',
              width: '42rem',
            },
          }}
        >
          <DialogTitle sx={{ py: '1rem' }}>
            <CenterAlignBox>
              <Typography fontSize="1.8rem" fontWeight={700} sx={{}}>
                {t('create_portfolio_with_setting_dialog.title')}
              </Typography>
            </CenterAlignBox>
          </DialogTitle>
          <DialogContent sx={{ px: 0 }}>
            <CenterAlignStack
              sx={{ '& .comment': { fontSize: '1.3rem', lineHeight: 1.3 }, mb: '1.4rem' }}
            >
              <Typography sx={{}} className="comment">
                {t('create_portfolio_with_setting_dialog.comment_1')}
              </Typography>
              <Typography sx={{ '& span': { fontWeight: 600 } }} className="comment">
                {t('create_portfolio_with_setting_dialog.comment_2_a')}
                <span>{t('create_portfolio_with_setting_dialog.comment_2_b')}</span>
              </Typography>
              <Typography className="comment">
                {t('create_portfolio_with_setting_dialog.comment_2_c')}
              </Typography>
              <Typography sx={{ '& span': { fontWeight: 600 } }} className="comment">
                <span>{t('create_portfolio_with_setting_dialog.comment_2_d')}</span>
              </Typography>
            </CenterAlignStack>

            <CenterAlignStack
              sx={{
                // background: 'gold',
                background: '#F8F8F8',
                py: '1.5rem',
                '& .wrapper': {
                  // background: 'gold',
                  // width: '25rem',
                  '& .key, & .value': { lineHeight: 1.3 },
                },
                '& .key': {
                  // ml: '7rem',
                  fontSize: '1.3rem',
                  fontWeight: 500,
                  mr: '0.7rem',
                  width: '5rem',
                },
                '& .value': {
                  fontSize: '1.3rem',
                  fontWeight: 700,
                  maxWidth: '17rem',
                  // border: '1px dashed red',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 700, mb: '0.5rem' }}>
                {t('create_portfolio_with_setting_dialog.common_setting')}
              </Typography>

              <Stack>
                <Stack direction="row" className="wrapper">
                  <Typography className="key">
                    {t('create_portfolio_with_setting_dialog.category')}
                  </Typography>
                  <Typography className="value">
                    {commonConfig?.object_category &&
                      t(`artwork_category.${commonConfig?.object_category}`)}
                    {commonConfig?.object_category !== 'auto' &&
                      commonConfig?.object_sub_category && (
                        <>
                          {' - '} {t(`artwork_category.${commonConfig?.object_sub_category}`)}
                        </>
                      )}
                  </Typography>
                </Stack>
                <Stack direction="row" className="wrapper">
                  <Typography className="key">
                    {t('create_portfolio_with_setting_dialog.bg_theme')}
                  </Typography>

                  {commonConfig?.theme_template?.length > 0 && (
                    <CustomTooltip
                      title={
                        commonConfig?.theme_template === 'custom'
                          ? commonConfig.theme_custom
                          : t(`theme_template.${commonConfig?.theme_template}`)
                      }
                    >
                      <Typography className="value">
                        {commonConfig?.theme_template?.length > 0
                          ? commonConfig?.theme_template === 'custom'
                            ? commonConfig.theme_custom
                            : t(`theme_template.${commonConfig?.theme_template}`)
                          : null}
                      </Typography>
                    </CustomTooltip>
                  )}
                </Stack>
              </Stack>

              <Typography
                sx={{ fontSize: '1.1rem', fontWeight: 500, color: '#525252', mt: '0.5rem' }}
              >
                {t('create_portfolio_with_setting_dialog.common_setting_comment_1')}
              </Typography>
            </CenterAlignStack>
          </DialogContent>

          <DialogActions>
            <CenterAlignBox sx={{ width: '100%' }}>
              <DialogButton
                actionText={t('create_portfolio_with_setting_dialog.create')}
                handleClose={handleClose}
                handleAction={() => {
                  // ----- GA4 event -----
                  window.gtag('event', 'suggest_portfolio_popup', { method: 'created' })
                  // ---------------------
                  createPortfolio()
                }}
              />
            </CenterAlignBox>
          </DialogActions>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={open}
          onClose={handleClose}
          onOpen={() => {
            return open
          }}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignStack sx={{ pt: '1rem' }}>
            <CenterAlignBox
              sx={{
                width: '4rem',
                height: '0.4rem',
                backgroundColor: '#BCBCBC',
                borderRadius: '20px',
                mt: '1.2rem',
                mb: '1.2rem',
              }}
            ></CenterAlignBox>

            <CenterAlignBox>
              <Typography fontSize="1.6rem" fontWeight={700} sx={{ mb: '1.3rem' }}>
                {t('create_portfolio_with_setting_dialog.title')}
              </Typography>
            </CenterAlignBox>

            <CenterAlignStack
              sx={{ '& .comment': { fontSize: '1.2rem', lineHeight: 1.3 }, mb: '1.3rem' }}
            >
              <Typography sx={{}} className="comment">
                {t('create_portfolio_with_setting_dialog.comment_1')}
              </Typography>
              <Typography sx={{ '& span': { fontWeight: 600 } }} className="comment">
                {t('create_portfolio_with_setting_dialog.comment_2_a')}
                <span>{t('create_portfolio_with_setting_dialog.comment_2_b')}</span>
              </Typography>
              <Typography className="comment">
                {t('create_portfolio_with_setting_dialog.comment_2_c')}
              </Typography>
              <Typography sx={{ '& span': { fontWeight: 600 } }} className="comment">
                <span>{t('create_portfolio_with_setting_dialog.comment_2_d')}</span>
              </Typography>
            </CenterAlignStack>

            <CenterAlignStack
              sx={{
                background: '#F8F8F8',
                width: '100%',
                py: '1.5rem',
                '& .wrapper': {
                  // background: 'gold',
                  width: '25rem',
                  '& .key, & .value': { lineHeight: 1.3 },
                },
                '& .key': {
                  ml: '7rem',
                  fontSize: '1.3rem',
                  fontWeight: 500,
                  mr: '0.7rem',
                  width: '5rem',
                },
                '& .value': {
                  fontSize: '1.3rem',
                  fontWeight: 700,
                  maxWidth: '11rem',
                  // border: '1px dashed red',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 700, mb: '0.5rem' }}>
                {t('create_portfolio_with_setting_dialog.common_setting')}
              </Typography>

              <Stack direction="row" className="wrapper">
                <Typography className="key">
                  {t('create_portfolio_with_setting_dialog.category')}
                </Typography>
                <Typography className="value">
                  {commonConfig?.object_category &&
                    t(`artwork_category.${commonConfig?.object_category}`)}
                  {commonConfig?.object_category !== 'auto' &&
                    commonConfig?.object_sub_category && (
                      <>
                        {' - '} {t(`artwork_category.${commonConfig?.object_sub_category}`)}
                      </>
                    )}
                </Typography>
              </Stack>
              <Stack direction="row" className="wrapper">
                <Typography className="key">
                  {t('create_portfolio_with_setting_dialog.bg_theme')}
                </Typography>

                {commonConfig?.theme_template?.length > 0 && (
                  <CustomTooltip
                    title={
                      commonConfig?.theme_template === 'custom'
                        ? commonConfig.theme_custom
                        : t(`theme_template.${commonConfig?.theme_template}`)
                    }
                  >
                    <Typography className="value">
                      {commonConfig?.theme_template?.length > 0
                        ? commonConfig?.theme_template === 'custom'
                          ? commonConfig.theme_custom
                          : t(`theme_template.${commonConfig?.theme_template}`)
                        : null}
                    </Typography>
                  </CustomTooltip>
                )}
              </Stack>

              <Typography
                sx={{ fontSize: '1.1rem', fontWeight: 500, color: '#525252', mt: '0.5rem' }}
              >
                {t('create_portfolio_with_setting_dialog.common_setting_comment_1')}
              </Typography>
            </CenterAlignStack>

            <CenterAlignBox sx={{ width: '100%', py: '2rem' }}>
              <DialogButton
                actionText={t('create_portfolio_with_setting_dialog.create')}
                handleClose={handleClose}
                handleAction={() => {
                  // ----- GA4 event -----
                  window.gtag('event', 'suggest_portfolio_popup', { method: 'created' })
                  // ---------------------
                  createPortfolio()
                }}
              />
            </CenterAlignBox>
          </CenterAlignStack>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}
