import {
  styled,
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'
import { CenterAlignStack, FormErrorMessage, TextInput } from 'components'
import { Desktop, Mobile, useBreakPoint } from 'hooks/useMediaQuery'
import { useEffect, useRef, useState } from 'react'
import { apis } from 'apis'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { DataGrid } from '@mui/x-data-grid'

export const MiniButton = styled(Button)(({ theme }) => ({
  background: 'lightblue',
  fontSize: '1.2rem',
  fontWeight: 400,
  padding: 0,
  color: 'black',
}))

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    position: 'sticky',
    backgroundColor: 'white',
    zIndex: 99,
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0 !important',
  },
  '& .MuiDataGrid-main': {
    overflow: 'visible',
  },
}))

export const columns = [
  {
    field: 'id',
    width: 50,
    headerName: 'ID',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'name',
    width: 200,
    headerName: 'name',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'start_date',
    width: 200,
    headerName: `시작 (${moment.tz(moment.tz.guess()).zoneName()})`,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => (
      <>{moment(moment.utc(params.value).toDate()).local().format('YYYY-MM-DDTHH:mm:ss')}</>
    ),
  },
  {
    field: 'end_date',
    width: 200,
    headerName: `종료 (${moment.tz(moment.tz.guess()).zoneName()})`,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => {
      const now = moment.utc().format('YYYY-MM-DDTHH:mm:ss')
      return (
        <span style={{ color: now < params.value ? 'unset' : 'red' }}>
          {moment(moment.utc(params.value).toDate()).local().format('YYYY-MM-DDTHH:mm:ss')}
        </span>
      )
    },
  },
  {
    field: 'expired',
    width: 100,
    headerName: `만료여부`,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'promotion_type',
    width: 100,
    headerName: `종류`,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => <>{params.value === 'event' && '응모 이벤트'}</>,
  },
]
const today = moment().local().format('YYYY-MM-DD')
const weekLater = moment().add(1, 'W').local().format('YYYY-MM-DD')
const monthLater = moment().add(1, 'M').local().format('YYYY-MM-DD')
const yearLater = moment().add(1, 'Y').local().format('YYYY-MM-DD')

const defaultStartTime = '09:00:00'
const defaultEndTime = '23:59:59'

export default function AdminPromotion() {
  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [list, setList] = useState([])
  const [promotionType, setPromotionType] = useState('default')

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setStartDate(`${today}T${defaultStartTime}`)
    setEndDate(`${weekLater}T${defaultEndTime}`)
    refreshPromotionList()
  }, [])

  const refreshPromotionList = () => {
    apis.admin.getPromotion().then(response => {
      setList(response.data)
    })
  }

  const handleSubmit = () => {
    if (!name || !startDate || !endDate) {
      alert('값을 모두 채워주세요')
      return
    }

    const c = confirm(`[${name}] 을 등록합니다`)
    if (!c) return

    const startUtc = moment.utc(new Date(startDate)).format('YYYY-MM-DDTHH:mm:ss')
    const endUtc = moment.utc(new Date(endDate)).format('YYYY-MM-DDTHH:mm:ss')

    apis.admin
      .createPromotion({
        name,
        start_date: startUtc,
        end_date: endUtc,
        promotion_type: promotionType === 'default' ? null : promotionType,
      })
      .then(response => {
        setName('')
        enqueueSnackbar('등록되었습니다', { variant: 'success' })
        apis.admin.getPromotion().then(response => {
          setList(response.data)
        })
      })
      .catch(() => {
        enqueueSnackbar('오류', { variant: 'error' })
      })
  }

  return (
    <div>
      <CenterAlignStack sx={{ width: '100%' }} spacing={1}>
        <Typography sx={{ fontWeight: 800, fontSize: '3rem', mb: 4 }}>프로모션 관리</Typography>
        <Stack spacing={1}>
          <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
            <Typography variant="h5">제목</Typography>
            <TextField
              sx={{ width: '50rem', '& input': { p: 0 } }}
              autoComplete="off"
              value={name}
              onChange={e => setName(e.target.value)}
            ></TextField>
          </Stack>
          <Stack direction="row" sx={{ alignItems: 'center', height: '40px' }} spacing={1}>
            <Typography variant="h5">
              시작 날짜 ({moment.tz(moment.tz.guess()).zoneName()})
            </Typography>
            <input
              type="datetime-local"
              id="start"
              value={startDate}
              min={moment().local().format('YYYY-MM-DDTHH:mm:ss')}
              name="start-date"
              onChange={e => {
                setStartDate(e.target.value)
              }}
            />

            <Stack direction="row" spacing="0.2rem" sx={{ m: '1rem', alignItems: 'center' }}>
              <MiniButton
                onClick={() => {
                  setStartDate(`${today}T${defaultStartTime}`)
                }}
              >
                오늘
              </MiniButton>
              <MiniButton
                onClick={() => {
                  setStartDate(`${weekLater}T${defaultStartTime}`)
                }}
              >
                1주후
              </MiniButton>
              <MiniButton
                onClick={() => {
                  setStartDate(`${monthLater}T${defaultStartTime}`)
                }}
              >
                1달후
              </MiniButton>
              <MiniButton
                onClick={() => {
                  setStartDate(`${yearLater}T${defaultStartTime}`)
                }}
              >
                1년후
              </MiniButton>
            </Stack>
          </Stack>

          <Stack direction="row" sx={{ alignItems: 'center', height: '40px' }} spacing={1}>
            <Typography variant="h5">
              종료 날짜 ({moment.tz(moment.tz.guess()).zoneName()})
            </Typography>
            <input
              type="datetime-local"
              id="end"
              value={endDate}
              min={moment().local().format('YYYY-MM-DDTHH:mm:ss')}
              name="end-date"
              onChange={e => {
                setEndDate(e.target.value)
              }}
            />

            <Stack direction="row" spacing="0.2rem" sx={{ m: '1rem' }}>
              <MiniButton
                onClick={() => {
                  setEndDate(`${today}T${defaultEndTime}`)
                }}
              >
                오늘
              </MiniButton>
              <MiniButton
                onClick={() => {
                  setEndDate(`${weekLater}T${defaultEndTime}`)
                }}
              >
                1주후
              </MiniButton>
              <MiniButton
                onClick={() => {
                  setEndDate(`${monthLater}T${defaultEndTime}`)
                }}
              >
                1달후
              </MiniButton>
              <MiniButton
                onClick={() => {
                  setEndDate(`${yearLater}T${defaultEndTime}`)
                }}
              >
                1년후
              </MiniButton>
            </Stack>
          </Stack>

          <Stack direction="row">
            <Typography variant="h5" sx={{ mr: '20px' }}>
              종류
            </Typography>
            <FormControl>
              <InputLabel shrink id="demo-simple-select-label" sx={{ backgroundColor: 'white' }}>
                종류
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                label="종류"
                sx={{ width: '20rem' }}
                value={promotionType}
                onChange={e => {
                  setPromotionType(e.target.value)
                }}
              >
                <MenuItem value={'default'}>일반</MenuItem>
                <MenuItem value={'event'}>응모 이벤트</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <Button variant="outlined" onClick={handleSubmit} sx={{ my: '30px !important' }}>
            프로모션 등록
          </Button>
        </Stack>

        <Box sx={{ width: '90rem', height: '50rem', overflow: 'scroll' }}>
          <StyledDataGrid disableColumnMenu rows={list} columns={columns} autoHeight hideFooter />
        </Box>

        <Button
          variant="outlined"
          onClick={() => {
            apis.admin.cleanExpiredPromotion().then(() => {
              enqueueSnackbar('완료', { variant: 'success' })
              refreshPromotionList()
            })
          }}
        >
          기간이 지난 프로모션 만료처리
        </Button>
      </CenterAlignStack>
    </div>
  )
}
