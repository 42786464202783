import { useRecoilState } from 'recoil'
import { ZeroCreditWarningDialogAtom } from 'atoms'

export default function useCreditWarningDialog() {
  const [dialog, setDialog] = useRecoilState(ZeroCreditWarningDialogAtom)

  const showCreditWarningDialog = props => {
    setDialog({ ...dialog, open: true, ...props })
  }

  return {
    dialog,
    setDialog,
    showCreditWarningDialog,
  }
}
