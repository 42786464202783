import * as React from 'react'
import { styled as styledMUI, useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { AiOutlineDown, AiOutlineSearch } from 'react-icons/ai'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  ListItemButton,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  SvgIcon,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { MaterialDesignContent } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { RadioChecked1, RadioDefaultIcon } from 'theme/icon'

// export const Accordion = styledMUI(props => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   '&:not(:last-child)': {
//     borderBottom: 0,
//   },
//   '&:before': {
//     display: 'none',
//   },
// }))

export const CustomAccordion = styledMUI(Accordion)(({ theme }) => ({
  margin: 0,
  padding: 0,
  borderRadius: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '&:last-child': {
    borderBottom: '1px solid rgb(128, 128, 128, 0.4)',
    borderBottomRightRadius: '0px',
  },
}))

// export const AccordionSummary = styledMUI(props => (
//   <MuiAccordionSummary expandIcon={<AiOutlineSearch sx={{ fontSize: '0.9rem' }} />} {...props} />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
//   flexDirection: 'row-reverse',
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)',
//   },
//   '& .MuiAccordionSummary-content': {
//     marginLeft: theme.spacing(1),
//   },
// }))

export const CustomAccordionSummary = styledMUI(props => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
    height: '5.6rem',

    '&.Mui-expanded': {
      minHeight: '5.6rem',
      borderTop: '1px solid rgb(128, 128, 128, 0.4)',
    },
    '& .MuiAccordionSummary-content': {
      fontWeight: 'bold', // 글꼴 굵기를 변경합니다.
    },
  })
)

export const CustomAccordionDetails = styledMUI(AccordionDetails)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.common.lightgray,
}))

export const CustomOutlinedInput = styledMUI(props => <OutlinedInput {...props} />)(
  ({ theme }) => ({
    width: '41.6rem',
    height: '4rem',

    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '0.1rem',
        borderColor: '#D9E1EC',
      },
      '&:hover fieldset': {
        borderColor: '#000000',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.draph.newBlue,
        borderWidth: '0.1rem',
      },
      '&.Mui-focused.Mui-error fieldset': {
        borderColor: 'red',
        borderWidth: '0.1rem',
      },
    },

    '& input': {
      textAlign: 'center',
      padding: 0,
      fontSize: '1.6rem',
      fontWeight: 400,
      '&::placeholder': {
        color: 'rgb(114, 114, 114, 0.8)',
      },
    },

    [theme.breakpoints.down('lg')]: {
      width: '28rem',
      height: '3.6rem',
      '& input': {
        fontSize: '1.3rem',
      },
    },
  })
)

export const StyledToggleButtonGroup = styledMUI(ToggleButtonGroup)(({ theme }) => ({
  gap: '2.4rem',

  [theme.breakpoints.down('lg')]: {
    gap: '0.9rem',
  },

  // '& .MuiToggleButtonGroup-grouped': {
  //   margin: 0,

  //   '&.Mui-disabled': {
  //     border: 0,
  //   },
  //   '&:not(:first-of-type)': {
  //     borderRadius: theme.shape.borderRadius,
  //   },
  //   '&:first-of-type': {
  //     borderRadius: theme.shape.borderRadius,
  //   },
  // },
}))

export const StyledToggleButton = styledMUI(ToggleButton)(({ theme }) => ({
  // borderRadius: '4rem !important',
  background: '#FFF',
  boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.25)',
  transition: 'all 0.5s',
  color: theme.palette.common.black,
  width: '19rem',
  height: '5.2rem',
  fontSize: '2.2rem',
  fontWeight: 700,
  border: 0,

  [theme.breakpoints.down('lg')]: {
    fontWeight: 600,
    width: '9.3rem',
    height: '2.4rem',
    fontSize: '1.1rem',
    padding: '0',
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.draph.blue,
    color: theme.palette.common.white,
    boxShadow: '2px 2px 10px 0px rgba(77, 128, 255, 0.30)',
    '&:hover': {
      backgroundColor: theme.palette.draph.blue,
      color: theme.palette.common.white,
    },
  },

  '&:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}))

export const SampleImg = styled.img`
  width: 40px;
  height: auto;
  object-fit: contain;
  display: inline;
`
export const BorderInput = styledMUI(props => (
  <OutlinedInput
    {...props}
    sx={{ border: `1px solid black`, borderRadius: '0px', height: '4rem', width: '17rem' }}
    endAdornment={<AiOutlineSearch size="3rem" />}
  />
))(({ theme }) => ({}))

export const BlueSwitch = styledMUI(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: '5.614rem',
  height: '2.74rem',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(2.87rem)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.draph.blue,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    // border: '1px solid #D4D4D4',
    width: '2.75rem',
    height: '2.75rem',
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 50,
    backgroundColor: '#D9E1EC',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export const StyledCommonMenu = styledMUI(MenuItem)(({ theme }) => ({
  width: '10.rem',
  height: '3.4rem',
  padding: '0.9rem 0.7rem',
  fontSize: '1.5rem',
  fontWeight: 500,

  '&.MuiMenuItem-root.Mui-selected': {
    backgroundColor: 'red',
  },
  '&.MuiMenuItem-root:hover': {
    backgroundColor: 'yellow',
    color: 'green',
  },
}))

export const StyledCommonSelect = styledMUI(Select)(({ theme }) => ({
  width: '8.4rem',
  height: '3.6rem',
  padding: '1px 0 0 0',

  color: theme.palette.common.black,
  fontSize: '1.5rem',
  fontWeight: 600,

  ' & .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.common.black}`,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `2px solid ${theme.palette.draph.blue}`,
  },

  '& .MuiSelect-select.MuiInputBase-input.MuiSelect-outlined	': {
    padding: '0 3.8rem 0 2.3rem',
  },
}))

// ManualBg에서 사용

export const BgOntlinedInput = styledMUI(OutlinedInput)(({ theme }) => ({
  backgroundColor: theme.palette.common.lightgray,
  padding: '0px 0px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&:hover  .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.draph.blue}`,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.draph.blue}`,
  },
}))

export const StyledMaterialDesignContent = styledMUI(MaterialDesignContent)(({ theme }) => {
  const isMobile = useMobileMediaQuery()
  return {
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.common.white,
      borderRadius: '10px',
      color: theme.palette.common.black,

      height: '6rem',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 800,
      fontSize: isMobile ? '1.4rem' : '1.6rem',
      padding: isMobile ? '0px 2.2rem' : '0px 4rem',
      '& .MuiSvgIcon-root': { marginRight: '0.8rem' },
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.common.white,
      borderRadius: '10px',
      color: theme.palette.common.black,

      height: '6rem',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 800,
      fontSize: isMobile ? '1.4rem' : '1.6rem',
      padding: isMobile ? '0px 2.2rem' : '0px 4rem',
      '& .MuiSvgIcon-root': { marginRight: '0.8rem' },
    },

    '&.notistack-MuiContent-alert': {
      top: '25% !important',
      background: '#303030CC',

      borderRadius: '10px',
      color: 'white',

      display: 'flex',
      justifyContent: 'center',
      fontWeight: 800,
      fontSize: isMobile ? '1.4rem' : '1.6rem',
      padding: isMobile ? '6px 1.8rem' : '7px 2rem',
      '& .MuiSvgIcon-root': { marginRight: '0.8rem' },
      '& .notistack-snackbar': { padding: '0 !important' },
    },
  }
})

export const StyledLink = styled(RouterLink)(() => ({
  textDecoration: 'none',
  '&:focus, &:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
}))

export const SpanDraphBlue = styled('span')(() => {
  const theme = useTheme()
  return { color: theme.palette.draph.newBlue }
})

export const SmallTextField = styledMUI(TextField)(({ theme }) => ({
  width: '16.1rem',

  '& input': {
    textAlign: 'right',
    padding: 0,

    fontSize: '1.6rem',
    fontWeight: 400,
    color: theme.palette.common.black,

    '&::placeholder': {
      color: theme.palette.common.gray,
    },
  },

  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0.1rem',
    borderColor: theme.palette.draph.newBlue,
  },

  '& fieldset': {
    borderWidth: '0.1rem',

    '&.MuiOutlinedInput-notchedOutline': {
      borderColor: '#D9E1EC',
    },
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    padding: '1rem 1.2rem',
  },

  '& .MuiInputAdornment-root': {
    '& .MuiTypography-root': {
      fontSize: '1.6rem',
      fontWeight: 400,
    },

    '&.MuiInputAdornment-positionEnd .MuiTypography-root': {
      // lineHeight: 0,
      color: theme.palette.common.gray,
    },
  },

  [theme.breakpoints.down('lg')]: {
    width: '12rem',
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: '1rem 1.2rem',
    },
    '& input': {
      fontSize: '1.3rem',
      fontWeight: 400,
    },
    '& fieldset': {},
    '& .MuiInputAdornment-root': {
      '& .MuiTypography-root': {
        fontSize: '1.3rem',
        fontWeight: 400,
      },
    },
  },
}))

export const ShadowButton = styledMUI(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
  '&:hover': { boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)', color: theme.palette.draph.blue },
  '&.Mui-disabled': {
    color: '#909090',
    backgroundColor: '#dedede',
  },
}))

export const OpacityMotionDiv = ({
  children,
  initialProps = {},
  animateProps = {},
  style = {},
  animationKey = 'default',
  duration = 0.3,
  delay = 0,
}) => {
  return (
    <motion.div
      key={animationKey}
      initial={{ opacity: 0, ...initialProps }}
      animate={{ opacity: 1, ...animateProps }}
      transition={{ duration, delay }}
      style={{ ...style }}
    >
      {children}
    </motion.div>
  )
}

export const CustomRadio1 = styledMUI(props => (
  <Radio {...props} icon={<RadioDefaultIcon />} checkedIcon={<RadioChecked1 />} />
))(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    '& .MuiSvgIcon-root': {
      // MUI의 기본 아이콘 클래스에 스타일을 적용합니다.
      width: '1.6rem', // 원하는 크기로 조절하세요.
      height: '1.6rem', // 원하는 크기로 조절하세요.
    },
  },
}))

export const CustomeListItemButton = styledMUI(ListItemButton)(({ theme }) => ({
  padding: '1.6rem 0.8rem',

  '&.Mui-selected': {
    '& .MuiTypography-root': {
      color: theme.palette.draph.blue,
      fontWeight: 600,
    },
    backgroundColor: theme.palette.draph.lighterblue,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.draph.lighterblue,
  },

  '&:hover': {
    '& .MuiTypography-root': {
      color: theme.palette.draph.blue,
    },
    backgroundColor: theme.palette.draph.lighterblue,
  },

  '&:active': {
    '& .MuiTypography-root': {
      color: theme.palette.draph.darkblue, // 리플 이펙트 중 폰트 weight 변경
    },
    background: '#E3ECFF',
  },

  '& .MuiTouchRipple-root': {
    display: 'none', // 터치 리플 이펙트 비활성화 시 이 줄을 추가
  },
}))

export const CreateButton = styledMUI(Button)(({ theme }) => ({
  background: 'linear-gradient(90deg, #2C4DFF, #8D00FC, #2C4DFF)',
  backgroundSize: '200% 200%',
  color: theme.palette.common.white,
  display: 'flex',
  height: '5.2rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0 0 10px 10px',
  fontSize: '2rem',
  fontWeight: 700,
  overflow: 'hidden',
  width: '100%',
  '&.Mui-disabled': {
    background: '#BBBBBB',

    '#tag': {
      color: '#BBBBBB',
    },
  },

  '&:hover': {
    animation: 'gradientAnimation 4s ease infinite',
  },
  transition: 'transform 0.3s ease-in-out', // transition 추가
  '&:hover .content': {
    transform: 'scale(1.1)', // hover 시 글자와 아이콘 크기 증가
  },
}))
