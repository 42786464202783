import { useCallback, useEffect, useRef, useState } from 'react'

import { Button, ButtonGroup, Box, Typography, Slider, Circle } from '@mui/material'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import CircularSlider from '@fseehawer/react-circular-slider'

const Circular = ({ setAngleValue }) => {
  return (
    <CircularSlider
      labelColor="#005a58"
      knobColor="#005a58"
      knobPosition="right"
      progressColorFrom="#eeeeee"
      progressColorTo="#eeeeee"
      progressSize={4}
      trackColor="#eeeeee"
      trackSize={4}
      dataIndex={0}
      hideLabelValue={true}
      min={0}
      max={6282}
      onChange={value => {
        console.log(value / 1000)
        setAngleValue(value / 1000)
      }}
      isDragging={value => {
        console.log(value)
      }}
    />
  )
}

const CircleWithShadow = ({ x, setX, y, setY, angleValue }) => {
  useEffect(() => {
    shadowMove()
  }, [angleValue])

  const shadowMove = e => {
    const newX = Math.cos(angleValue) * Math.min(12.5)
    const newY = Math.sin(angleValue) * Math.min(12.5)

    setX(newX)
    setY(newY)
  }

  return (
    <motion.div
      style={{ position: 'relative' }}
      // onMouseMove={handleMouseMove}
    >
      <div
        style={{
          width: '200px',
          height: '200px',
          borderRadius: '50%',
          backgroundColor: 'white',
          boxShadow: `${-x}px ${-y}px 10px rgba(0, 0, 0, 0.6)`,
        }}
      />
    </motion.div>
  )
}

function TestPage7() {
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [angleValue, setAngleValue] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    console.log(x, y)
  })

  return (
    <div>
      <div
        style={{
          width: '500px',
          height: '500px',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Circular setAngleValue={setAngleValue} />
        <div style={{ position: 'absolute', left: '150px', top: '150px', zIndex: 99 }}>
          <CircleWithShadow x={x} y={y} setX={setX} setY={setY} angleValue={angleValue} />
        </div>
      </div>
    </div>
  )
}

export default TestPage7
