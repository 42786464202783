import { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric'
import { AlignGuidelines } from 'fabric-guideline-plugin'
import FontFaceObserver from 'fontfaceobserver'
import { Box, MenuItem, Select } from '@mui/material'
import useGridHandler from 'hooks/editor/useGridHandler'
import { useGuidelines } from 'hooks/editor/useGuidelineHandler'
import { CenterAlignStack } from 'components'
import { scalingLogic, snapLogic } from 'utils/editorHelper'

export default function TestPage17() {
  return (
    <>
      <Test1 />
      {/* <Test2 /> */}
    </>
  )
}

const Test1 = () => {
  const canvasRef = useRef(null)
  const [canvas, setCanvas] = useState(null)
  const [fontFamily, setFontFamily] = useState('Arial')
  const [fontSize, setFontSize] = useState(22)
  const [fontColor, setFontColor] = useState('#000000')

  const addText = () => {
    const text = createText(canvas, 'Add', 100, 200)

    canvas.add(text)
  }

  useEffect(() => {
    const canvasInstance = new fabric.Canvas(canvasRef.current, {})

    const imageUrl = 'https://cdn.newspenguin.com/news/photo/202003/1400_3528_3311.jpg'

    setCanvas(canvasInstance)

    const guideline = new AlignGuidelines({
      canvas: canvasInstance,
    })

    guideline.init()
    fabric.Image.fromURL(
      imageUrl,
      function (img) {
        // 이미지 원본 크기
        const imgWidth = img.width
        const imgHeight = img.height

        // 캔버스 최대 크기
        const maxCanvasWidth = 500
        const maxCanvasHeight = 400

        // 이미지 비율 계산
        const widthRatio = maxCanvasWidth / imgWidth
        const heightRatio = maxCanvasHeight / imgHeight

        // 가장 작은 비율을 기준으로 이미지 크기 조정
        const scale = Math.min(widthRatio, heightRatio)

        canvasInstance.setZoom(scale)

        // 이미지 크기 조정
        // img.scale(scale)

        // 캔버스 크기 조정
        // canvasInstance.setWidth(img.width * scale)
        // canvasInstance.setHeight(img.height * scale)

        // 캔버스에 이미지 추가
        canvasInstance.setBackgroundImage(img, canvasInstance.renderAll.bind(canvasInstance))

        // 이미지와 가이드라인 설정
      },
      { crossOrigin: 'anonymous' }
    )
    // 두 개의 텍스트 박스 생성
    const text1 = createText(canvasInstance, 'Click to Edit', 100, 100)
    const text2 = createText(canvasInstance, 'Another Text', 100, 200)

    canvasInstance.add(text1, text2)
    canvasInstance.setActiveObject(text1)
    // 첫 번째 텍스트 박스를 편집 모드로 설정
    text1.enterEditing()

    const handleKeyDown = e => {
      if (e.key === 'Delete') {
        const activeObject = canvasInstance.getActiveObject()
        console.log(activeObject, activeObject.type)
        if (activeObject && !activeObject.isEditing) {
          // 단일 객체 삭제
          if (!activeObject.type || activeObject.type !== 'activeSelection') {
            canvasInstance.remove(activeObject)
          } else {
            // 그룹화된 객체 삭제
            activeObject.forEachObject(obj => {
              canvasInstance.remove(obj)
            })
            // 그룹 해제
            canvasInstance.discardActiveObject()
          }
          // canvasInstance.requestRenderAll()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    // 컴포넌트 언마운트 시 이벤트 리스너 제거

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    const activeObject = canvas?.getActiveObject()
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set({
        fontFamily,
        fontSize,
        fill: fontColor,
      })
      canvas.requestRenderAll()
    }
  }, [fontFamily, fontSize, fontColor, canvas])

  const alignLeft = () => {
    const activeObject = canvas.getActiveObject()
    const activeObjectLeft = -(activeObject.width / 2)

    activeObject.forEachObject(obj => {
      obj.set({
        left: activeObjectLeft,
      })
      obj.setCoords()
      canvas.requestRenderAll()
    })
  }

  const alignCenter = () => {
    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.type === 'activeSelection') {
      activeObject.forEachObject(obj => {
        obj.set({
          left: 0 - (obj.width * obj.scaleX) / 2,
        })
        obj.setCoords()
      })
    }
    canvas.requestRenderAll()
  }
  const alignRight = () => {
    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.type === 'activeSelection') {
      const activeObjectLeft = activeObject.width / 2
      activeObject.forEachObject(obj => {
        obj.set({
          left: activeObjectLeft - obj.width * obj.scaleX,
        })
        obj.setCoords()
      })
    }
    canvas.requestRenderAll()
  }

  const handleDownload = () => {
    const originalOpacity = canvas.backgroundImage.opacity
    canvas.backgroundImage.set('opacity', 1)
    canvas.renderAll()

    // 캔버스 상태를 이미지로 변환하여 다운로드

    const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    const link = document.createElement('a')
    link.download = 'my-canvas-image.png'
    link.href = image
    link.click()

    // 이미지 다운로드 후 원래 투명도로 되돌림
    canvas.backgroundImage.set('opacity', originalOpacity)
    canvas.renderAll()
  }

  return (
    <div>
      <button onClick={handleDownload}>다운로드</button>
      <button onClick={addText}>Add Text</button>
      <button onClick={alignLeft}>좌</button>
      <button onClick={alignCenter}>중</button>
      <button onClick={alignRight}>우</button>
      <br />
      <label>
        Font Family:
        <input value={fontFamily} onChange={e => setFontFamily(e.target.value)} />
      </label>
      <br />
      <label>
        Font Size:
        <input
          type="number"
          value={fontSize}
          onChange={e => setFontSize(parseInt(e.target.value, 10))}
        />
      </label>
      <br />
      <label>
        Font Color:
        <input type="color" value={fontColor} onChange={e => setFontColor(e.target.value)} />
      </label>
      <br />
      <Box sx={{ width: '10rem' }}>
        <canvas ref={canvasRef} />
      </Box>
    </div>
  )
}

const createText = (canvasInstance, text, left, top) => {
  const textbox = new fabric.Textbox(text, {
    left: left,
    top: top,
    // width: 300,
    fontFamily: 'Arial',
    fontSize: 22,
    fill: '#000000',

    // backgroundColor: 'rgba(123,45,26,0.7)',
  })

  textbox.setControlsVisibility({
    mt: false, // 상단 중앙 조절점 비활성화
    mb: false, // 하단 중앙 조절점 비활성화
  })

  textbox.on('deselected', function () {
    if (this.text === '') {
      canvasInstance.remove(this)
    }
  })

  return textbox
}
