import { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric'
import { AlignGuidelines } from 'fabric-guideline-plugin'
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material'
import { bannerTextFontAtom, pieceConfigAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import { getS3ImageSrc, rgbToHex } from 'utils/common'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { FONT_DICT, FONT_INFO } from 'config'
import {
  AlignBoxCenterIcon,
  AlignBoxLeftIcon,
  AlignBoxRightIcon,
  AlignContentCenterIcon,
  AlignContentLeftIcon,
  AlignContentRightIcon,
  SelectSideIconThin,
} from 'theme/icon'
import { ChromePicker } from 'react-color'

export const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: '12px',
  // backgroundImage: `linear-gradient(to right, #fff 13.6rem, #f6f6f6 1rem)`,
  minWidth: '156px',
  height: '28px',
  borderRadius: '4px',

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#303030',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
  '& .MuiSvgIcon-root': {
    right: '6px',
    width: '9px',
    height: '11px',
  },
}))

const AlignToggleButton = styled(ToggleButton)(({ theme }) => ({
  background: '#FFF',
  borderColor: 'transparent',
  marginRight: '1rem',
  borderRadius: '4px !important',
  padding: '7px 5px',

  '&.Mui-selected': {
    background: '#FFF',
    borderColor: `${theme.palette.draph.blue} !important`,
    borderWidth: '1px !important',

    '& svg path': {
      stroke: theme.palette.draph.blue,
    },

    '&:hover': {},
  },
}))

export default function EditCanvas({
  canvasRef,
  canvas,
  setCanvas,
  artwork,
  currentPiece,
  manualTitleInput,
  setManualTitleInput,
  manualSubtitleInput,
  setManualSubtitleInput,
  mainTextboxRef,
  subTextboxRef,

  displayFontSize,
  setDisplayFontSize,
  alignContent,
  setAlignContent,
}) {
  const [pieceConfig, setPieceConfig] = useRecoilState(pieceConfigAtom)
  const [fontInfo, setFontInfo] = useRecoilState(bannerTextFontAtom)

  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }
  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleColorChange = newColor => {
    // console.log(newColor.rgb)
    setFontInfo(prev => ({
      ...prev,
      fontColor: rgbToHex([Number(newColor.rgb.r), Number(newColor.rgb.g), Number(newColor.rgb.b)]),
    }))
  }

  // useEffect(() => {
  //   console.log(fontInfo)
  // }, [fontInfo])

  // useEffect(() => {
  //   console.log('displayFontSize', displayFontSize)
  // }, [displayFontSize])

  const handleFontChange = event => {
    const fontFamily = event.target.value
    setFontInfo({ ...fontInfo, font: fontFamily })
  }

  useEffect(() => {
    const activeObject = canvas?.getActiveObject()
    if (activeObject && activeObject.type === 'textbox') {
      // console.log(fontInfo.fontSize, fontInfo.scaleX)
      activeObject.set({
        fontFamily: fontInfo.font,
        fontSize: fontInfo.fontSize,
        fill: fontInfo.fontColor,
      })
      canvas.requestRenderAll()
    }
  }, [fontInfo, canvas])

  const fontSizes = [
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '38',
    '40',
    '42',
    '44',
    '46',
    '48',
    '50',
    '60',
    '80',
    '100',
    '120',
  ]

  const [open, setOpen] = useState(false)
  const [alignBox, setAlignBox] = useState('left')
  const inputRef = useRef(null)

  const handleIconClick = () => {
    setOpen(true) // 아이콘 클릭 시 메뉴 열기
    // 입력 필드에 포커스 주기
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const alignContentHandler = direction => {
    const activeObject = canvas.getActiveObject()

    if (!activeObject) {
      return // 선택된 객체가 없는 경우 함수 종료
    }

    // ActiveSelection (그룹 선택)인 경우
    if (activeObject.type === 'activeSelection') {
      activeObject.forEachObject(obj => {
        obj.set('textAlign', direction)
      })
    } else {
      // 단일 객체 선택인 경우
      if (activeObject.type === 'textbox' || activeObject.type === 'i-text') {
        activeObject.set('textAlign', direction)
      }
    }
    canvas.requestRenderAll()
  }

  const alignBoxHandler = direction => {
    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.type === 'activeSelection') {
      activeObject.forEachObject(obj => {
        let newPos
        switch (direction) {
          case 'left':
            newPos = -(activeObject.width / 2)
            break
          case 'center':
            newPos = 0 - (obj.width * obj.scaleX) / 2
            break
          case 'right':
            newPos = activeObject.width / 2 - obj.width * obj.scaleX
            break
          default:
            return
        }

        obj.set({ left: newPos })
        obj.setCoords()
      })
      canvas.requestRenderAll()
    }
  }

  return (
    <>
      <CenterAlignBox sx={{ height: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <CenterAlignBox
          sx={{ height: '40px', justifyContent: 'center', alignItems: 'center', gap: '8px' }}
        >
          <FormControl sx={{ position: 'relative' }}>
            <StyledSelect
              value={fontInfo.font}
              onChange={handleFontChange}
              sx={{ fontFamily: fontInfo.font }}
              IconComponent={SelectSideIconThin}
              inputProps={{ sx: { p: '0 16px 0 7px !important' } }}
              // MenuProps={{ sx: { backgroundColor: 'blue' } }}
            >
              {Object.values(FONT_INFO).map((f, i) => {
                return (
                  <MenuItem
                    key={f.fontFamily}
                    value={f.fontFamily}
                    sx={{ fontSize: 12, paddingLeft: '7px', fontFamily: f.fontFamily }}
                  >
                    {f.label}
                  </MenuItem>
                )
              })}
            </StyledSelect>
          </FormControl>

          <Autocomplete
            freeSolo
            value={displayFontSize.fontSize.toString()}
            onChange={(event, newValue) => {
              // console.log('newValue', newValue)

              setDisplayFontSize({
                ...displayFontSize,
                fontSize: Math.round(Number(newValue)),
              })
              setFontInfo({
                ...fontInfo,
                fontSize: Math.round(Number(newValue / fontInfo.scaleX)),
              })
            }}
            inputValue={displayFontSize.fontSize.toString()}
            onInputChange={(event, newInputValue) => {
              setDisplayFontSize({
                ...displayFontSize,
                fontSize: Math.round(Number(newInputValue)),
              })
              setFontInfo({
                ...fontInfo,
                fontSize: Math.round(Number(newInputValue / fontInfo.scaleX)),
              })
            }}
            id="controllable-states-demo"
            options={fontSizes}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            sx={{
              fontSize: '12px',
              // backgroundImage: `linear-gradient(to right, #fff 13.6rem, #f6f6f6 1rem)`,
              minWidth: '47px',
              height: '28px',

              '& .MuiOutlinedInput-root': {
                p: '0 4px 0 7px!important',
                borderRadius: '4px',
              },

              '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                p: 0,
                height: '28px',
                // fontFamily: fontInfo.font,
                fontSize: '12px',
                minWidth: '20px',
              },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: '#303030',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
              },
              '& .MuiSvgIcon-root': {
                right: '6px',
                width: '9px',
                height: '11px',
              },
            }}
            ListboxProps={{
              sx: {
                fontSize: 12, // 폰트 크기 설정
                '& .MuiAutocomplete-option': {
                  p: '6px',
                },
              },
            }}
            filterOptions={(options, { inputValue }) => {
              return options
            }}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <InputAdornment position="end" sx={{ ml: 0 }}>
                          <IconButton onClick={handleIconClick} sx={{ p: '1px' }}>
                            <SelectSideIconThin />
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              )
            }}
          />

          <Box
            sx={{
              width: '40px',
              height: '28px',
              borderRadius: '4px',
              border: '1px solid #303030',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              cursor: 'pointer',
            }}
            onClick={handleClick}
          >
            <Box
              sx={{
                borderRadius: '4px',
                width: '16px',
                height: '16px',
                backgroundColor: fontInfo.fontColor,
                border: '1px solid #303030',
                mr: '4px',
              }}
            ></Box>
            <SelectSideIconThin style={{ width: '9px', height: '11px' }} />
          </Box>
          {displayColorPicker && (
            <Box
              sx={{
                position: 'absolute',
                zIndex: '2',
                top: '140px',
                // right: '0px',
                // bottom: '0px',
                left: '330px',
              }}
            >
              <Box
                sx={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                  zIndex: -1,
                }}
                onClick={handleClose}
              />
              <ChromePicker
                color={fontInfo.fontColor}
                onChange={handleColorChange}
                disableAlpha={true}
              />
            </Box>
          )}
        </CenterAlignBox>
        <Divider orientation="vertical" flexItem sx={{ m: '6px 3px 6px 16px' }} />
        <CenterAlignBox
          sx={{ height: '40px', justifyContent: 'center', alignItems: 'center', gap: '8px' }}
        >
          <ToggleButtonGroup
            value={alignContent}
            exclusive
            onChange={(e, v) => {
              // if (!v) return
              setAlignContent(v)
              alignContentHandler(v)
            }}
          >
            <AlignToggleButton value="left">
              <AlignContentLeftIcon />
            </AlignToggleButton>
            <AlignToggleButton value="center">
              <AlignContentCenterIcon />
            </AlignToggleButton>
            <AlignToggleButton value="right">
              <AlignContentRightIcon />
            </AlignToggleButton>
          </ToggleButtonGroup>
        </CenterAlignBox>
        <CenterAlignBox
          sx={{ height: '40px', justifyContent: 'center', alignItems: 'center', gap: '8px' }}
        >
          <ToggleButtonGroup
            value={alignBox}
            exclusive
            onChange={(e, v) => {
              alignBoxHandler(v)
              setAlignBox(v)
            }}
          >
            <AlignToggleButton value="left">
              <AlignBoxLeftIcon />
            </AlignToggleButton>
            <AlignToggleButton value="center">
              <AlignBoxCenterIcon />
            </AlignToggleButton>
            <AlignToggleButton value="right">
              <AlignBoxRightIcon />
            </AlignToggleButton>
          </ToggleButtonGroup>
        </CenterAlignBox>
      </CenterAlignBox>
      <CenterAlignStack sx={{ minHeight: '540px', justifyContent: 'center' }}>
        <canvas ref={canvasRef} />
      </CenterAlignStack>
    </>
  )
}
