// import { useCallback, useEffect, useState } from 'react'

// import { clicksAtom, imageAtom, maskImgAtom } from 'atoms'
// import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
// import { handleImageScale, modelData, onnxMaskToImage } from 'components/fragment/fragmentHelpers'

// import { InferenceSession } from 'onnxruntime-web'
// import npyjs from 'npyjs'
// import { Stage } from 'components'

// // import * as ort from 'onnxruntime-web/webgpu';
// import * as ort from 'onnxruntime-web'

// const IMAGE_PATH = '/static/data/mnq3.jpg'
// const IMAGE_EMBEDDING = '/static/data/mnq3.npy'
// const MODEL_DIR = '/static/model/sam_onnx_quantized_example.onnx'

// export default function TestPage14() {
//   const clicks = useRecoilValue(clicksAtom)
//   const setImage = useSetRecoilState(imageAtom)
//   const [maskImg, setMaskImg] = useRecoilState(maskImgAtom)

//   const [model, setModel] = useState(null) // ONNX model
//   const [tensor, setTensor] = useState(null) // Image embedding tensor

//   // The ONNX model expects the input to be rescaled to 1024.
//   // The modelScale state variable keeps track of the scale values.
//   const [modelScale, setModelScale] = useState(null)

//   // Initialize the ONNX model. load the image, and load the SAM
//   // pre-computed image embedding
//   useEffect(() => {
//     // Initialize the ONNX model
//     console.log('난 단한번만 실행해야 ~')
//     const initModel = async () => {
//       try {
//         console.log('MODEL_DIR', MODEL_DIR)
//         if (MODEL_DIR === undefined) return
//         const URL = MODEL_DIR

//         const model = await InferenceSession.create(URL)
//         setModel(model)
//       } catch (e) {
//         console.log(e)
//       }
//     }

//     initModel()

//     // Load the image
//     const url = new URL(IMAGE_PATH, location.origin)

//     loadImage(url)

//     // Load the Segment Anything pre-computed embedding
//     console.time('임베딩 ~')
//     Promise.resolve(loadNpyTensor(IMAGE_EMBEDDING, 'float32')).then(embedding =>
//       setTensor(embedding)
//     )
//     console.timeEnd('임베딩 ~')
//   }, [])

//   const loadImage = async url => {
//     try {
//       const img = new Image()
//       img.src = url.href
//       img.onload = () => {
//         const { height, width, samScale } = handleImageScale(img)
//         setModelScale({
//           height: height, // original image height
//           width: width, // original image width
//           samScale: samScale, // scaling factor for image which has been resized to longest side 1024
//         })
//         img.width = width
//         img.height = height
//         setImage(img)
//       }
//     } catch (error) {
//       console.log(error)
//     }
//   }

//   // Decode a Numpy file into a tensor.
//   const loadNpyTensor = async (tensorFile, dType) => {
//     // eslint-disable-next-line
//     const npLoader = new npyjs()
//     const npArray = await npLoader.load(tensorFile)
//     console.log(npArray)
//     const tensor = new ort.Tensor(dType, npArray.data, npArray.shape)
//     console.log(tensor)
//     return tensor
//   }

//   // Run the ONNX model every time clicks has changed
//   useEffect(() => {
//     runONNX()
//   }, [clicks])

//   const runONNX = async () => {
//     try {
//       if (model === null || clicks === null || tensor === null || modelScale === null) return
//       else {
//         // Preapre the model input in the correct format for SAM.
//         // The modelData function is from onnxModelAPI.tsx.
//         const feeds = modelData({
//           clicks,
//           tensor,
//           modelScale,
//         })

//         if (feeds === undefined) return
//         // Run the SAM ONNX model with the feeds returned from modelData()
//         const results = await model.run(feeds)
//         const output = results[model.outputNames[0]]
//         // The predicted mask returned from the ONNX model is an array which is
//         // rendered as an HTML image using onnxMaskToImage() from maskUtils.tsx.
//         console.log('model', output)
//         setMaskImg(onnxMaskToImage(output.data, output.dims[2], output.dims[3]))
//       }
//     } catch (e) {
//       console.log(e)
//     }
//   }

//   return <Stage />
// }
