import { useRecoilState, useRecoilValue, atom } from 'recoil'
import { testAtom } from './TestPage'
import { RecoilDebugButton } from '../../components'
import { useState, useEffect } from 'react'

const localAtom = atom({
  key: 'c-local-atom',
  default: 0,
})
export default function C() {
  const [c, setC] = useRecoilState(localAtom)

  useEffect(() => {
    setC([1, 2, 3, 4, 5, 6, 7, 8, 9])

    return () => {
      setC(-1)
    }
  }, [])

  return (
    <>
      <h1>CCCCC Page</h1>
      <RecoilDebugButton />
      <br />
      <br />
      <SSETest />

      <br />
    </>
  )
}

function SSETest() {
  const [data, setData] = useState('')
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('No message')
  const [log, setLog] = useState('')

  useEffect(() => {
    // const sse = new EventSource('http://127.0.0.1:8000/db/user/stream_test')
    const sse = new EventSource(
      // 'http://127.0.0.1:8000/db/user/stream_test'
      // 'https://crud-dev.draph.art/db/user/stream_test'
      'https://api-dev.draph.art/stream/'
    )

    console.log('useEffect!!!!!!!')

    sse.onopen = e => {
      const l = 'connection opened'
      console.log(l)
      setStatus(l)
    }

    sse.addEventListener('abc', e => {
      console.log('ABC zzz')
    })

    sse.onmessage = async e => {
      console.log('get message')
      setMessage('메세지 수신!!')

      console.log(e)
      setData(e)

      setLog(prev => `${new Date().toISOString()}  ${e.data} \n` + prev)
    }

    sse.onerror = async e => {
      // can close stream and 'stall' ??
      console.log('error!!', e)
      sse.close()
      console.log('eventsource closed')
      setStatus('ERROR')
    }

    return () => {
      sse.close()
      console.log('eventsource closed')
    }
  }, [])

  return (
    <>
      <ul>
        <li>상태: {status}</li>
        <li>메세지 수신여부: {message}</li>
        <li>
          데이터:
          <textarea
            value={log}
            disabled
            style={{ width: '700px', height: '500px' }}
          ></textarea>
          <br />
        </li>
      </ul>
      <br />

      {/* <textarea disabled value={log}></textarea> */}

      <br />
    </>
  )
}

function SSEExample() {
  useEffect(() => {
    const sse = new EventSource('https://api-dev.draph.art/stream/')

    sse.onopen = e => {
      // 스트림 서버에 연결된 시점의 이벤트
    }

    sse.onmessage = async e => {
      // e.data 에 데이터가 들어있습니다
      console.log(e.data)
    }

    sse.onerror = async e => {
      sse.close()
    }

    return () => {
      sse.close()
    }
  }, [])

  return <></>
}
