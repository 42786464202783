import { promotionInfo, promotionInfoKeys } from 'pages/promotionEvents/DomeggookEvent'
import { useNavigate, useLocation } from 'react-router-dom'

export const useNavigateForPromotion = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const navigateWithQuery = (path, object = {}) => {
    const fullPath = path + location.search
    navigate(fullPath, object)
  }

  const navigateCheckpromotion = (promotionUrl, defaultUrl, option = {}) => {
    const promotionQuery = localStorage.getItem('promotionQuery') ?? false

    let currentPromotion

    for (const keys of promotionInfoKeys) {
      if (promotionInfo[keys].query === promotionQuery) {
        currentPromotion = keys
        break
      }
    }

    if (promotionQuery && currentPromotion) {
      navigate(promotionUrl + promotionQuery, option)
    } else {
      navigate(defaultUrl, option)
    }
  }

  const navigateEvent = (option = {}, navigateTo = null) => {
    const promotionQuery = localStorage.getItem('promotionQuery') ?? false

    let currentPromotion

    for (const keys of promotionInfoKeys) {
      if (promotionInfo[keys].query === promotionQuery) {
        currentPromotion = keys
        break
      }
    }

    if (promotionQuery && currentPromotion) {
      navigate(promotionInfo[currentPromotion].promotionUrl + promotionQuery, option)
    } else {
      navigate(navigateTo ?? '/home', option)
    }
  }

  return { navigateWithQuery, navigateEvent, navigateCheckpromotion }
}

export const handleOpenInNewWindow = url => {
  const newWindow = window.open(url, '_blank')
  if (newWindow) {
    newWindow.focus()
  }
}
