import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  PORTFOLIO_CONFIG_DEFAULT,
  artworkRegenCountAtom,
  endpointAtom,
  portfolioConfigAtom,
  portfolioDetailAtom,
  uploadOptionAtom,
} from 'atoms'
import { CenterAlignStack, ShadowButton } from 'components'
import { useEffect, useState } from 'react'

import { apis } from 'apis'
import useConfirm from 'hooks/useConfirm'
import { useParams } from 'react-router-dom'
import { portfolioUploadConfigSelector } from 'selector'
import { Box, Paper, Stack } from '@mui/material'

export default function OptionViewerDialog({ open, setOpen, artwork }) {
  const [text, setText] = useState('')
  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)
  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const propertiesToRemove = [
      'token',
      'webhook_url',
      'use_credit_type',
      'query',
      'output_w',
      'output_h',
      'input_filename',
      'used_bg_ids',
      'bg_search_filter',
      'category',
      'sub_category',
      'angle',
    ]

    const config = JSON.parse(artwork.config)

    const removeProperties = (config, propertiesToRemove) => {
      for (const property of propertiesToRemove) {
        delete config[property]
      }
    }
    removeProperties(config, propertiesToRemove)

    const option = {
      endpoints: endpoint,
      ...config,
    }

    // console.log(JSON.stringify(option))
    setText(JSON.stringify(option))
  }, [uploadConfig])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { maxWidth: 'fit-content' } }}
    >
      <DialogTitle id="alert-dialog-title">포트폴리오 옵션 설정</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack>
            <Typography sx={{ fontSize: '1.7rem', fontWeight: 600 }}>
              현재 아트워크 Option
            </Typography>
            <PrettyObject data={parserFunc(text)} />
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const PrettyObject = ({ data }) => {
  const prettyText = JSON.stringify(data, null, 2)
  return <pre style={{ fontSize: '1.3rem' }}>{prettyText}</pre>
}

const parserFunc = data => {
  try {
    const jsonData = JSON.parse(data)
    // JSON 데이터가 유효한 경우
    return jsonData
  } catch {
    return null
  }
}
