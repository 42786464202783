import axios from 'axios'
import { configData } from 'config'
import * as cookie from 'utils/cookie'
import moment from 'moment'
import { sendApiErrorAlert } from 'utils/common'

let isTokenRefreshing = false

const crudAxios = axios.create({
  baseURL: configData.DB_URL,
  withCredentials: true,
  headers: {
    time_delta: moment().utcOffset() / 60,
  },
})

crudAxios.interceptors.request.use(config => {
  const accessToken = cookie.getAccessToken()
  config.headers.Authorization = `Bearer ${accessToken}`
  return config
})

crudAxios.interceptors.response.use(
  response => response,
  async error => {
    const { response } = error

    console.log(error)

    if (response && response.status && response.status === 401) {
      if (response.data?.detail === 'new_login_detected') {
        window.location.reload()
        alert('로그인 정보가 변경되었습니다. 확인을 누르신 후 다시 진행해주세요.')
        return
      }
      if (!isTokenRefreshing) {
        const accessToken = cookie.getAccessToken()
        const refreshToken = cookie.getRefreshToken()

        if (accessToken && refreshToken) {
          isTokenRefreshing = true

          try {
            const response = await axios.post(
              `${configData.DB_URL}/user/refresh_token`,
              {
                access_token: accessToken,
                refresh_token: refreshToken,
              },
              { headers: { 'Content-type': 'application/json' } }
            )

            cookie.setAccessToken(response.data.access_token)
            cookie.setRefreshToken(response.data.refresh_token)
            isTokenRefreshing = false
            window.location.reload()
          } catch (error) {
            cookie.removeUserCookie()
            window.location.href = '/login'
          } finally {
            isTokenRefreshing = false
          }
        } else {
          cookie.removeUserCookie()
        }
      }
    } else if (response && response.status && response.status === 500) {
      sendApiErrorAlert({
        path: error.request.responseURL,
        httpStatus: response.status,
        message: error.message,
        detail: response,
      })
    }
    return Promise.reject(error)
  }
)
export default crudAxios
