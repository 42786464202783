import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import backend from 'i18next-xhr-backend'

const languageDetectorOptions = {
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // Specify function to normalize the language codes
  checkWhitelist: true, // If you want to enforce whitelist of available languages
  caches: ['localStorage'], // options: 'localStorage', 'cookie'
  convertDetectedLanguage: lng => {
    if (lng.includes('ko') || lng.includes('en') || lng.includes('ja')) {
      return lng.split('-')[0]
    } else {
      return 'en'
    }
  },
  // Custom function to normalize the language codes
}

i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: ['en', 'ko', 'ja'],

    // backend: {
    //   // 원하는 경로를 설정해준다.
    //   // (중요) 참고로 아래 경로는 빌드 된 파일 기준이라서 CRA로 앱을 만든 경우 /public 폴더 안에 넣어줘야 한다.
    //   // ex) client/public/locales/en.json
    //   loadPath: '/locales/{{lng}}.json',
    // },
    // // 주소 변경 시 언어 초기값으로 돌아가는 것 방지
    // // lng: window.localStorage.getItem('i18nextLng') || 'ko',

    // interpolation: {
    //   escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    // },

    // detection: languageDetectorOptions,
    // fallbackLng: 'en', // 다른 언어인 경우 기본적으로 'en'으로 fallback

    // // 언어를 자동으로 감지해서 로컬 스토리지에 저장
    // // localStorage에 저장된 값이 없다면 'en'으로 fallback
    // lng: localStorage.getItem('i18nextLng') || 'en',

    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
    detection: languageDetectorOptions,
    fallbackLng: 'en',

    // saveMissing: true,
  })

export default i18n
