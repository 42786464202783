import { Box, Card, Typography } from '@mui/material'
import { CenterAlignBox } from 'components/CenterAlignContainer'
import { English, Korean } from 'hooks/useLanguage'
import { useNavigate } from 'react-router-dom'

export function DelayInfoForSubscriber() {
  return (
    <>
      <CenterAlignBox>
        <Card sx={{ boxShadow: '4px 4px 20px 0px #0000001A', borderRadius: '1rem' }}>
          <CenterAlignBox
            sx={{ width: '100%', background: theme => theme.palette.draph.newblue, py: '0.7rem' }}
          >
            <Typography
              sx={{ fontSize: { lg: '1.6rem', xs: '1.4rem' }, fontWeight: 700, color: 'white' }}
            >
              <Korean>구독자 혜택이 적용되었습니다.</Korean>
              <English>Subscriber benefits applied</English>
            </Typography>
          </CenterAlignBox>
          <CenterAlignBox
            sx={{
              width: '100%',
              background: 'white',
              py: '1rem',
              px: '1.4rem',
              alignItems: 'center',
            }}
            gap={'0.6rem'}
          >
            <Box sx={{ background: '#E3ECFF', px: '1rem', py: '0.3rem', borderRadius: '0.4rem' }}>
              <Typography sx={{ fontSize: { lg: '1.3rem', xs: '1.1rem' }, fontWeight: 600 }}>
                <Korean>크레딧 미차감</Korean>
                <English>Free</English>
              </Typography>
            </Box>
            <Typography sx={{ fontSize: { lg: '1.4rem', xs: '1.2rem' }, fontWeight: 600 }}>
              <Korean>이번 작업에 대해서는 크레딧이 차감되지 않습니다.</Korean>
              <English>Your credit will not be deducted for this image.</English>
            </Typography>
          </CenterAlignBox>
        </Card>
      </CenterAlignBox>
    </>
  )
}

export function DelayInfoForNonSubscriber() {
  const navigate = useNavigate()
  return (
    <>
      <CenterAlignBox
        sx={{
          background: theme => theme.palette.draph.newblue,
          alignItems: 'center',
          borderRadius: '0.4rem',
          py: '1.5rem',
          width: { lg: '40rem', xs: 'unset' },
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate('/pricing')
        }}
      >
        <svg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.66667 0H12.303L7.63636 6.3H14L3.81818 18L5.93939 9.45H0L4.66667 0Z"
            fill="#1FFF50"
          />
        </svg>

        <Korean>
          <Typography
            sx={{
              ml: '0.6rem',
              fontSize: { lg: '1.8rem', xs: '1.4rem' },
              fontWeight: 600,
              color: 'white',
            }}
          >
            구독하고 우선 생성 혜택 받기
          </Typography>
        </Korean>
        <English>
          <Typography
            sx={{
              ml: '0.6rem',
              fontSize: { lg: '1.5rem', xs: '1.2rem' },
              fontWeight: 600,
              color: 'white',
            }}
          >
            Subscribe and receive priority generation benefits
          </Typography>
        </English>
      </CenterAlignBox>
    </>
  )
}
