import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import { promotionAnchorAtom } from 'atoms'
import {
  CenterAlignBox,
  CenterAlignStack,
  CustomOutlinedInput,
  ScrollToTop,
  TextInput,
} from 'components'
import { useScroll } from 'framer-motion'
import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import {
  APPBAR_DESKTOP,
  APPBAR_MOBILE,
  LINEBANNER_HEIGHT,
  LINEBANNER_HEIGHT_MOBILE,
} from 'layouts/main/MainAppBar'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { CloseIcon } from 'theme/icon'
import { URL_REGEX, isKo } from 'utils/common'
import { getAccessToken } from 'utils/cookie'

export default function OpenEvent() {
  const navigate = useNavigate()
  const { showConfirm } = useConfirm()

  const [promotionAnchor, setPromotionAnchor] = useRecoilState(promotionAnchorAtom)
  const resetPromotionAnchor = useResetRecoilState(promotionAnchorAtom)

  const [seller, setSeller] = useState({ enrollNumber: '', url: '' })
  const [errorUrl, serErrorUrl] = useState(false)
  const [load, setLoad] = useState(false)
  const [open, setOpen] = useState(false)
  const [y, setY] = useState(0)

  const isMobile = useMobileMediaQuery()
  const token = getAccessToken()
  const { t, i18n } = useTranslation()

  // const { scrollY, scrollYProgress } = useScroll({ layoutEffect: false })

  // useEffect(() => {
  //   scrollY.onChange(() => {
  //     setY(scrollY.get())
  //   })
  //   console.log(y)
  // })

  useEffect(() => {
    if (promotionAnchor === null || promotionAnchor === 0) return

    const anchor = document?.querySelector(`#anchor_promotion_${promotionAnchor}`)

    anchor.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
    return () => {
      resetPromotionAnchor()
    }
  }, [load])

  const onLoadHandler = () => {
    setLoad(true)
  }

  const handleChange = pointer => e => {
    if (pointer === 'enrollNumber') {
      const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
      setSeller(p => ({ ...p, enrollNumber: onlyNumber }))
    }

    if (pointer === 'url') {
      setSeller(p => ({ ...p, url: e.target.value }))
    }
  }

  useEffect(() => {
    const enrollFormatter = seller.enrollNumber
      .replace(/[^0-9]/g, '')
      .replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3')
      .replace(/(-{1,2})$/g, '')
    setSeller(p => ({ ...p, enrollNumber: enrollFormatter }))
  }, [seller.enrollNumber])

  const submit = () => {
    const businessNumber = seller.enrollNumber.replace(/-/g, '')
    const isUrl = URL_REGEX.test(seller.url)

    if (isUrl) {
      serErrorUrl(false)
      apis.user.applyEvent(5, { businessNumber, website: seller.url }).then(response => {
        // const isSuccess = response.data.success
        const isSuccess = true
        if (isSuccess) {
          setOpen(true)
        } else {
          showConfirm({
            alertOnly: true,
            content: <Typography>{response.data.message}</Typography>,
          })
        }
      })
    } else {
      return serErrorUrl(true)
    }
  }

  const tabButton = [{ title: t('promotion.title_1') }]

  const tabClickHandler = idx => () => {
    const anchor = document.querySelector(`#anchor_promotion_${idx + 1}`)

    anchor.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }
  return (
    <>
      <CenterAlignStack>
        <CenterAlignStack sx={{ width: '100%', position: 'relative' }}>
          <CenterAlignBox
            sx={{
              width: '100%',
              background: 'linear-gradient(to right, #303030 50%, #7AEBFE 50%)',
            }}
          >
            {isMobile ? (
              isKo(i18n) ? (
                <img
                  src="/static/images/promotion/pageImage/promotionPage_MO_top.jpg"
                  style={{ width: '128rem', height: '100%', objectFit: 'cover' }}
                />
              ) : (
                <img
                  src="/static/images/promotion/pageImage/promotionPage_MO_top_en.jpg"
                  style={{ width: '128rem', height: '100%', objectFit: 'cover' }}
                />
              )
            ) : isKo(i18n) ? (
              <img
                src="/static/images/promotion/pageImage/promotionPage_PC_top.jpg"
                style={{ width: '128rem', height: '100%', objectFit: 'cover' }}
                onLoad={onLoadHandler}
              />
            ) : (
              <img
                src="/static/images/promotion/pageImage/promotionPage_PC_top_en.jpg"
                style={{ width: '128rem', height: '100%', objectFit: 'cover' }}
                onLoad={onLoadHandler}
              />
            )}
          </CenterAlignBox>

          <CenterAlignBox
            sx={{
              width: '100%',
              backgroundColor: '#303030',
              gap: { lg: '5.4rem', xs: '2.4rem' },
              height: { lg: '8rem', xs: '5.2rem' },
            }}
          >
            {tabButton.map((tab, idx) => {
              return (
                <Button
                  key={idx}
                  onClick={tabClickHandler(idx)}
                  sx={{
                    color: '#fff',
                    fontSize: { lg: '2.4rem', xs: '1.8rem' },
                    fontWeight: 600,
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  {tab.title}
                </Button>
              )
            })}
          </CenterAlignBox>
        </CenterAlignStack>

        {/* 프로모션 1 구역 */}
        <CenterAlignStack sx={{ width: '100%', background: '#EAF7FF', position: 'relative' }}>
          <Box
            id={`anchor_promotion_1`}
            sx={{
              position: 'absolute',
              top: {
                lg: `calc(-${
                  token
                    ? parseFloat(APPBAR_DESKTOP)
                    : parseFloat(APPBAR_DESKTOP) + parseFloat(LINEBANNER_HEIGHT)
                }rem)`,
                xs: `calc(-${
                  token
                    ? parseFloat(APPBAR_MOBILE)
                    : parseFloat(APPBAR_MOBILE) + parseFloat(LINEBANNER_HEIGHT_MOBILE)
                }rem)`,
              },
            }}
          ></Box>
          {isMobile ? (
            isKo(i18n) ? (
              <img src="/static/images/promotion/pageImage/promotionPage_MO_P_1.jpg" />
            ) : (
              <img src="/static/images/promotion/pageImage/promotionPage_MO_P_1_en.jpg" />
            )
          ) : isKo(i18n) ? (
            <img
              src="/static/images/promotion/pageImage/promotionPage_PC_P_1.jpg"
              style={{ width: '128rem', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <img
              src="/static/images/promotion/pageImage/promotionPage_PC_P_1_en.jpg"
              style={{ width: '128rem', height: '100%', objectFit: 'cover' }}
            />
          )}

          <Button
            variant="contained"
            sx={{
              width: { lg: '30rem', xs: '26rem' },
              height: { lg: '6rem', xs: '4.8rem' },
              fontSize: { lg: '2.4rem', xs: '1.8rem' },
              fontWeight: 800,
              position: 'absolute',
              bottom: { lg: '5rem', xs: '3.75rem' },
            }}
            onClick={() => {
              navigate('/login')
            }}
          >
            {t('promotion.register')}
          </Button>
        </CenterAlignStack>

        {/* 프로모션 2 구역 */}
        {/* <CenterAlignStack
          sx={{
            width: '100%',
            background: '#EAF7FF',
            position: 'relative',
            pb: { lg: '9.4rem', xs: '2.4rem' },
          }}
        >
          {isMobile ? (
            isKo(i18n) ? (
              <img src="/static/images/promotion/pageImage/promotionPage_MO_P_2.jpg" />
            ) : (
              <img src="/static/images/promotion/pageImage/promotionPage_MO_P_2_en.jpg" />
            )
          ) : isKo(i18n) ? (
            <img
              src="/static/images/promotion/pageImage/promotionPage_PC_P_2.jpg"
              style={{ width: '128rem', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <img
              src="/static/images/promotion/pageImage/promotionPage_PC_P_2_en.jpg"
              style={{ width: '128rem', height: '100%', objectFit: 'cover' }}
            />
          )}

          <Typography
            sx={{
              fontSize: { lg: '3rem', xs: '2rem' },
              fontWeight: 800,
              lineHeight: 'normal',
              mt: { xs: '1.6rem' },
            }}
          >
            {t('promotion.seller')}
          </Typography>
          <Stack gap="1.6rem" sx={{ margin: { lg: '3rem 0', xs: '2rem 0 2.4rem' } }}>
            <CustomOutlinedInput
              sx={{
                width: { lg: '40rem', xs: '30rem' },
                height: { lg: '6rem', xs: '4.8rem' },
                backgroundColor: '#FFF',
                borderRadius: '5px',
              }}
              placeholder={t('promotion.seller_placeholder_1')}
              value={seller.enrollNumber}
              onChange={handleChange('enrollNumber')}
              inputProps={{ maxLength: 12 }}
            />
            <CustomOutlinedInput
              sx={{
                width: { lg: '40rem', xs: '30rem' },
                height: { lg: '6rem', xs: '4.8rem' },
                backgroundColor: '#FFF',
                borderRadius: '5px',
              }}
              placeholder={t('promotion.seller_placeholder_2')}
              value={seller.url}
              onChange={handleChange('url')}
              error={errorUrl}
            />
          </Stack>

          <Button
            variant="contained"
            sx={{
              width: { lg: '40rem', xs: '30rem' },
              height: { lg: '6rem', xs: '4.8rem' },
              fontSize: {
                lg: isKo(i18n) ? '2.4rem' : '2rem',
                xs: isKo(i18n) ? '1.8rem' : '1.5rem',
              },
              fontWeight: 800,
              lineHeight: 'normal',
            }}
            onClick={() => {
              submit()
            }}
          >
            {t('promotion.seller_check')}
          </Button>
          <Box
            id={`anchor_promotion_3`}
            sx={{ position: 'absolute', bottom: { lg: APPBAR_DESKTOP, xs: APPBAR_MOBILE } }}
          ></Box>
        </CenterAlignStack> */}

        <CenterAlignBox sx={{ width: '100%' }}>
          <Desktop>
            {isKo(i18n) ? (
              <img src="/static/images/promotion/pageImage/promotionPage_PC_bottom.jpg" />
            ) : (
              <img src="/static/images/promotion/pageImage/promotionPage_PC_bottom_en.jpg" />
            )}
          </Desktop>
          <Mobile>
            {isKo(i18n) ? (
              <img src="/static/images/promotion/pageImage/promotionPage_MO_bottom.jpg" />
            ) : (
              <img src="/static/images/promotion/pageImage/promotionPage_MO_bottom_en.jpg" />
            )}
          </Mobile>
        </CenterAlignBox>

        <CenterAlignBox
          sx={{
            width: '100%',
            backgroundColor: ' #F8F8F8',
            p: { lg: '4.7rem 0', xs: '2.8rem 0 3.4rem 0' },
          }}
        >
          <CenterAlignStack sx={{ maxWidth: '63.5rem' }}>
            <Typography
              lineHeight="normal"
              fontSize={{ lg: '2rem', xs: '1.4rem' }}
              fontWeight={500}
              sx={{ mb: { lg: '2.2rem', xs: '1.6rem' }, alignSelf: 'center' }}
            >
              {t('promotion.notice_title')}
            </Typography>
            <Box
              component="ul"
              sx={{
                padding: { xs: '0 2rem 0 2.5rem', lg: '0 0 0 2.5rem' },
                maxWidth: { lg: 'none', xs: '36rem' },

                '& li': {
                  fontSize: { lg: '1.6rem', xs: '1rem' },
                  fontWeight: 400,
                  lineHeight: { lg: '2.5rem' },
                  mb: { xs: '1rem' },
                  listStyleType: 'none',
                  textIndent: { lg: '-1.7rem', xs: '-1.2rem' },
                  '&::before': {
                    content: '"•"',
                    marginRight: '0.6rem',
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <Typography component="li">{t('promotion.notice_1_comment')}</Typography>

                <Typography component="li">{t('promotion.notice_2_comment')}</Typography>
                <Typography component="li">
                  {t('promotion.notice_3_comment_a')}{' '}
                  <span style={{ borderBottom: '1px solid' }}>
                    {' '}
                    {t('promotion.notice_3_comment_b')}
                  </span>
                </Typography>
              </Box>
              {/* <Typography component="li">
              <span>월정액 구독은 언제든지 요금제 변경이 가능합니다.</span>
            </Typography>
            <Typography component="li">
              <span>
                월정액 구독의 경우 다음 결제 때 결제일(예: 31일)에 해당하는 날이 없는 달에는 그달의
                말일에 결제가 이루어집니다.
              </span>
            </Typography>

            <Typography component="li">
              <span>월정액 구독으로 충전된 크레딧의 경우 유효 기간은 1개월입니다.</span>
              {isMobile ? <br /> : ' '}
              <span>
                단, 구독을 연장하는 경우 전월에 미사용한 크레딧의 유효 기간 역시 자동 연장됩니다.
              </span>
            </Typography>
            <Typography component="li">
              <span>건별 결제로 충전된 크레딧의 경우 유효 기간은 2년입니다. </span>
            </Typography>
            <Typography component="li">
              그 외 문의사항은{' '}
              <RouterLink
                to="route"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'unset' }}
              >
                FAQ
              </RouterLink>{' '}
              및 1:1문의를 이용해 주세요.
            </Typography> */}
            </Box>
          </CenterAlignStack>
        </CenterAlignBox>
      </CenterAlignStack>
      <PromotionDialog open={open} setOpen={setOpen} />
      <ScrollToTop upside={true} />
    </>
  )
}

const PromotionDialog = ({ open, setOpen }) => {
  const navigate = useNavigate()
  const theme = useTheme()

  const handleCancel = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    navigate('/portfolio')
  }

  const isMobile = useMobileMediaQuery()
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        sx={{
          '& .MuiDialog-paper': {
            width: { lg: '42rem', xs: '32rem' },
            height: { lg: '22.8rem', xs: '20.7rem' },
            borderRadius: '20px',
            margin: { xs: '1.4rem' },
          },
        }}
      >
        <IconButton
          onClick={handleCancel}
          sx={{
            position: 'absolute',
            top: '0.8rem',
            right: '1.2rem',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <CloseIcon
            style={{
              width: '1.4rem',
              height: '1.4rem',
            }}
          />
        </IconButton>

        <DialogTitle
          sx={{
            p: { lg: '3rem 3rem 0 3rem', xs: '2.6rem 2.6rem 0 2.6rem' },

            fontSize: { lg: '2rem', xs: '1.9rem' },
            fontWeight: 700,
            color: theme.palette.draph.blue,
            textAlign: 'center',
          }}
        >
          크레딧 충전 완료
        </DialogTitle>
        <DialogContent sx={{ p: { lg: '3rem', xs: '0 0 2.4rem 0' }, fontSize: '1.8rem' }}>
          <Typography
            sx={{
              textAlign: 'center',
              pt: { lg: '1.4rem', xs: '0.4rem' },
              pb: { lg: '2rem', xs: '1.4rem' },
              fontWeight: 500,
              lineHeight: 'normal',
            }}
          >
            10 크레딧이 회원님의 계정에 충전되었습니다. <br />
            지금 바로 포트폴리오를 만들어 보세요.
          </Typography>

          <Typography
            sx={{ fontSize: '1.3rem', fontWeight: 400, textAlign: 'center', lineHeight: 'normal' }}
          >
            [마이페이지] - [충전 내역]에서 {isMobile ? <br /> : null}충전된 크레딧을 확인하실 수
            있습니다.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <Button
            variant="contained"
            onClick={handleConfirm}
            sx={{
              ml: '0 !important',
              height: { lg: '4.8rem', xs: '4rem' },
              width: '100%',
              fontSize: { lg: '2rem', xs: '1.6rem' },
              fontWeight: 600,
              color: theme => theme.palette.common.white,
              '&:hover': {
                color: theme => theme.palette.common.white,
              },
            }}
          >
            포트폴리오 만들러 가기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
