import { useTheme } from '@emotion/react'
import { Box } from '@mui/material'

const AIIcon = ({ color = '#303030' }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: { lg: '6.4rem', xs: '4.6rem' },
        height: { lg: '2.8rem', xs: '2rem' },
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '1rem',
        color: theme.palette.common.white,
        fontWeight: 700,
        fontSize: { lg: '1.6rem', xs: '1.4rem' },
      }}
    >
      AI
    </Box>
  )
}

export const configData = {
  ML_URL: process.env.REACT_APP_ML_URL,
  DB_URL: process.env.REACT_APP_DB_URL,
  STATIC_URL: process.env.REACT_APP_STATIC_URL,
  MAILER_URL: process.env.REACT_APP_MAILER_URL,
  // MAILER_URL: 'http://localhost:4000',
  IMGGEN_URL: process.env.REACT_APP_IMGGEN_URL,
  COMMON_URL: process.env.REACT_APP_ML_COMMON_URL,
  COMMERCE_URL: process.env.REACT_APP_COMMERCE_URL,
}

export const GUEST_USERNAME = 'draph_guest_user_n5gIUpDOhPVrqZu2g0cbwX3y0CFIqPqR'

export const PAYMENT_REDIRECT_ENDPOINT = `${configData.DB_URL}/payment/paid_direct`

export const USER_GRADE_DICT = {
  welcome: '일반',
  basic: '베이직',
  pro: '프로',
  enterprise: '엔터프라이즈',
  betatester: '베타테스터',
  premium: '프리미엄',
  admin: '관리자',
}
export const PORTFOLIO_CATEGORIES = [
  {
    id: 'auto',
    name: '자동',
    children: [
      { id: 'auto', name: '자동', example: '/static/images/subCategory/picto/autoGif.gif' },
    ],
  },
  {
    id: 'person',
    name: '모델',
    children: [
      { id: 'auto', name: '자동', example: <AIIcon /> },
      { id: 'whole', name: '전신', example: '/static/images/subCategory/human/whole.jpeg' },
      { id: 'torso', name: '상반신', example: '/static/images/subCategory/human/torso.jpeg' },
      { id: 'lower', name: '하반신', example: '/static/images/subCategory/human/lower.jpeg' },
      { id: 'body', name: '몸통', example: '/static/images/subCategory/human/body.jpeg' },
      { id: 'detail', name: '상세', example: '/static/images/subCategory/human/detail.jpeg' },
    ],
  },
  {
    id: 'clothes',
    name: '의류',
    children: [
      { id: 'auto', name: '자동', example: <AIIcon /> },
      { id: 'clothing', name: '옷', example: '/static/images/subCategory/clothes/clothing.jpeg' },
      { id: 'etc', name: '잡화/기타', example: '/static/images/subCategory/clothes/etc.jpeg' },
      { id: 'detail', name: '상세', example: '/static/images/subCategory/clothes/detail.jpeg' },
    ],
  },
  {
    id: 'food',
    name: '식품',
    children: [
      { id: 'auto', name: '자동', example: <AIIcon /> },
      { id: 'plate', name: '접시,그릇', example: '/static/images/subCategory/food/plate.jpeg' },
      { id: 'box', name: '포장', example: '/static/images/subCategory/food/box.jpeg' },
      { id: 'raw', name: '식재료', example: '/static/images/subCategory/food/raw.jpeg' },
    ],
  },
  {
    id: 'cosmetics',
    name: '화장품',
    children: [
      { id: 'auto', name: '자동', example: '/static/images/subCategory/picto/cosmetics.png' },
    ],
  },
  {
    id: 'car',
    name: '자동차',
    children: [{ id: 'auto', name: '자동', example: '/static/images/subCategory/picto/car.png' }],
  },
  // {
  //   id: 'misc',
  //   name: '생활잡화',
  //   children: [{ id: 'auto', name: '자동', example: '/static/images/subCategory/picto/car.png' }],
  // },
]

export const MUSER_SUBCATEGORIES = {
  // 쿠팡이츠
  1233: {
    food: [
      // { id: 'auto', name: '자동', example: '/static/images/subCategory/picto/food.png' },
      // { id: 'plate', name: '접시,그릇', example: '/static/images/subCategory/food/plate.jpeg' },
      // { id: 'box', name: '포장', example: '/static/images/subCategory/food/box.jpeg' },
      // { id: 'raw', name: '식재료', example: '/static/images/subCategory/food/raw.jpeg' },
      { id: 'western', name: '양식' },
      { id: 'oriental', name: '한식/중식' },
      { id: 'japanese', name: '일식' },
      { id: 'dessert', name: '디저트/카페' },
    ],
  },
}

export const PORTFOLIO_CATEGORY_DICT = {
  auto: '자동',
  person: '모델',
  clothes: '의류',
  food: '식품',
  cosmetics: '화장품',
  car: '자동차',
  others: '기타',
  common: '공용',

  whole: '전신',
  torso: '상반신',
  body: '몸통',
  lower: '하반신',
  detail: '상세',
  clothing: '옷',
  etc: '잡화/기타',
  plate: '접시,그릇',
  box: '포장',
  raw: '식재료',

  western: '양식',
  oriental: '한식/중식',
  japanese: '일식',
  dessert: '디저트/카페',
}

export const OBJECT_BOUNDARY = [
  { id: 'none', name: '자동' },
  { id: 'strict', name: '엄격' },
  { id: 'normal', name: '적당히' },
  { id: 'natural', name: '자연스럽게' },
]

export const OBJECT_ANGLE = [
  { id: 'auto', name: '자동' },
  { id: 'perpen', name: '수직' },
  { id: 'floor', name: '측면' },
]

export const ARTWORK_REFRESH_STATUS = 'need_refresh'
export const ARTWORK_DONE_STATUS = 'done'
export const ARTWORK_ERROR_STATUS = ['error', 'failed', 'Failed', 'Delayed', 'delayed']
export const ARTWORK_ADDPIECE_STATUS = 'add_piece'
export const ARTWORK_ADDPIECE_ERROR_STATUS = 'add_piece_error'
export const ARTWORK_MODPIECE_STATUS = 'mod_piece'
export const ARTWORK_MODPIECE_ERROR_STATUS = 'mod_piece_error'
export const ARTWORK_MODPIECE_DONE_STATUS = 'mod_piece_complete'
export const ARTWORK_REGEN_STATUS = [
  'regenerate_auto',
  'regenerate',
  ARTWORK_ADDPIECE_STATUS,
  ARTWORK_MODPIECE_STATUS,
  'retry',
]
export const ARTWORK_REGEN_AUTO_COMPLETE = 'regenerate_auto_complete'
export const ARTWORK_IN_PROGRESS_STATUS = [
  '',
  'face',
  'sr',
  'sod',
  'sod_tagging',
  'composite',
  'assessment',
  'luxify',
  'shadow',
  'retry',
  'uploaded',
  'analysis',
  'prep',
  'gen',
  'gen_start',
]

const getProgress = stage => {
  const totalStage = 3
  // return `${stage} / ${totalStage}`
  return (stage / (totalStage + 1)) * 100
}

export const ARTWORK_STATUS_DICT = {
  uploaded: { text: 'uploaded', progress: getProgress(1), progressIdx: 1 },
  analysis: { text: 'analysis', progress: getProgress(2), progressIdx: 2 },
  prep: { text: 'prep', progress: getProgress(3), progressIdx: 3 },
  gen: { text: 'gen', progress: getProgress(3), progressIdx: 3 },
  gen_start: { text: 'gen_start', progress: getProgress(3), progressIdx: 3 },

  // face: { text: '가상 모델 생성', progress: getProgress(1), progressIdx: 1, next: 'sr' },
  // sr: {
  //   text: '사진을 더 선명하게',
  //   progress: getProgress(2),
  //   progressIdx: 2,
  //   prev: 'face',
  //   next: 'sod',
  // },
  // sod: {
  //   text: '상품 추출',
  //   progress: getProgress(3),
  //   progressIdx: 3,
  //   prev: 'sr',
  //   next: 'sod_tagging',
  // },
  // sod_tagging: {
  //   text: '상품 분석',
  //   progress: getProgress(4),
  //   progressIdx: 4,
  //   prev: 'sod',
  //   next: 'composite',
  // },
  // composite: {
  //   text: '상품 사진 합성',
  //   progress: getProgress(5),
  //   progressIdx: 5,
  //   prev: 'sod_tagging',
  //   next: 'assessment',
  // },
  // assessment: {
  //   text: 'AI 사진 심사',
  //   progress: getProgress(6),
  //   progressIdx: 6,
  //   prev: 'composite',
  //   next: 'luxify',
  // },
  // luxify: {
  //   text: '새로운 조명 생성',
  //   progress: getProgress(7),
  //   progressIdx: 7,
  //   prev: 'assessment',
  //   next: 'shadow',
  // },
  // shadow: { text: '그림자 생성', progress: getProgress(8), progressIdx: 8, prev: 'luxify' },
  // 'whole_tagging': '사진 분석',
  //
  done: { text: 'done' },
  error: { text: 'error' },
  Failed: { text: 'Failed' },
  failed: { text: 'failed' },
  retry: { text: 'retry' },
  regenerate_auto: { text: 'regenerate_auto' },
  regenerate: { text: 'regenerate' },
  need_refresh: { text: 'need_refresh' },
}

export const ARTWORK_REGEN_REQ_MAX = 5
export const ARTWORK_ADDPIECE_PREFIX = '_addpiecetemp'

export const GA4_EVENT_CATEGORY = {
  portfolio: 'portfolio',
}

// export const PAGE_TITLE = {
//   terms: '이용약관',
//   privacy: '개인정보 처리방침',
//   marketing: '생성물 활용 동의',
//   Advertisement: '광고 동의',
//   login: '로그인',
//   findID: '아이디 찾기',
//   findPW: '비밀번호 찾기',
//   resetPW: '비밀번호 초기화',
//   Register: '회원가입',
//   Board: '공지사항',
//   guide: '드랩 가이드',
//   FAQ: 'FAQ',
//   inquiry: '문의',
//   user: '유저 정보',
//   home: '드랩아트',
//   portfolio: '포트폴리오',
//   background: '배경관리',
//   paid: '결제완료',
//   pricing: '요금제',
//   admin: '관리자페이지',
//   'generate/upload': '이미지 생성',
//   'generate/removebg': '누끼따기',
//   'generate/mannequin': '마네킹 → 모델',
//   'generate/bundle': '대량(번들) 상품',
//   'fun/fame': 'FUN&AI(유명인)',

//   // 하위를 나중에 확인함으로
//   promotion: '프로모션',
//   'promotion/domeggook_event': '드랩아트X도매꾹 이벤트',
//   'promotion/oddoffice_event': '드랩아트X이상한사무실 이벤트',
//   default: '드랩아트',
// }

export const PORTFOLIO_TYPE_NORMAL = null // 유저가 생성하여 사용하는 일반 포트폴리오
export const PORTFOLIO_TYPE_REMOVEBG = 'removebg' // 누끼생성 메뉴 전용 포트폴리오
export const PORTFOLIO_TYPE_UPLOAD = 'upload' // 이미지생성 메뉴 전용 포트폴리오
export const PORTFOLIO_TYPE_MANNEQUIN = 'mannequin' // 마네킹(마네킹 to 사람) 메뉴 전용 포트폴리오
export const PORTFOLIO_TYPE_MODELBG = 'modelbg' // 모델이 있는 배경
export const PORTFOLIO_TYPE_FACE = 'face' // 모델 얼굴 변경
export const PORTFOLIO_TYPE_FAME = 'fame' // fun - 유명인 합성 메뉴 전용 포트폴리오
export const PORTFOLIO_TYPE_BUNDLE = 'bundle' // bundle - 번들전용 포트폴리오
export const PORTFOLIO_TYPE_BGEXPANSION = 'bg_expansion' // 배경 연장(캔버스 확장) 전용 포트폴리오
export const PORTFOLIO_TYPE_BANNER = 'banner' // 광고용 배너 전용 포트폴리오

export const DEFAULT_PORTFOLIO_TYPES = [
  PORTFOLIO_TYPE_REMOVEBG,
  PORTFOLIO_TYPE_MANNEQUIN,
  PORTFOLIO_TYPE_MODELBG,
  PORTFOLIO_TYPE_FACE,
  PORTFOLIO_TYPE_FAME,
  PORTFOLIO_TYPE_BUNDLE,
  PORTFOLIO_TYPE_BGEXPANSION,
  PORTFOLIO_TYPE_BANNER,
]

export const UNUSUAL_PORTFOLIO_TYPES = [PORTFOLIO_TYPE_BANNER]

export const DEFAULT_PORTFOLIO_NAME_DICT = {
  upload: '미분류',
  removebg: '누끼',
  mannequin: '마네킹',
  fun_fame: 'FUN&AI',
  fame: 'FUN&AI',
  bundle: '번들',
  bg_expansion: '캔버스확장',
  face: '모델얼굴변경',
}

export const UNUSUAL_PORTFOLIO_NAME_DICT = {
  banner: '배너',
}

export const BG_THEME_TEMPLATES = [
  { id: 'auto', fixInPage: 'first' },

  { id: 'city', sample: { default: 'default.png', person: 'person.png' } }, // 도시 (도심, 골목, 공원)
  { id: 'outdoor', sample: { default: 'default.png' } }, // 교외 (시골, 숲, 정원)
  { id: 'landmark', sample: { default: 'default.png' } }, // 명소
  { id: 'indoor', sample: { default: 'default.png' } }, // 카페, 주방, 바
  { id: 'office', sample: { default: 'default.png' } }, // 오피스
  { id: 'living_room', sample: { default: 'default.png' } }, // 거실
  { id: 'studio', sample: { default: 'default.png', person: 'person.png' } }, // 스튜디오
  { id: 'spring', sample: { default: 'default.png' } },
  { id: 'summer', sample: { default: 'default.png' } },
  { id: 'fall', sample: { default: 'default.png' } },
  { id: 'winter', sample: { default: 'default.png' } },

  { id: 'custom', fixInPage: 'last' }, // 직접입력

  // { id: 'season_christmas', sample: { default: 'default.png', person: 'person.png' } },
  // { id: 'season_new_year', sample: { default: 'default.png', person: 'person.png' } },

  // { id: 'dummy1' },
  // { id: 'dummy2' },
  // { id: 'dummy3' },
  // { id: 'dummy4' },
  // { id: 'dummy5' },
  // { id: 'dummy6' },
  // { id: 'dummy7' },
  // { id: 'dummy8' },
  // { id: 'dummy9' },
]
export const BG_THEME_ORDER = {
  default: [
    'spring',
    'summer',
    'city',
    'outdoor',
    'landmark',
    'indoor',
    'office',
    'living_room',
    'studio',
    'fall',
    'winter',
  ],
  nukkiUsage: [
    'summer',
    'city',
    'outdoor',
    'studio',
    'landmark',
    'indoor',
    'office',
    'living_room',
  ],
  // clothes: [
  //   'spring',
  //   'summer',
  //   'studio',
  //   'living_room',
  //   'office',
  //   'city',
  //   'outdoor',
  //   'indoor',
  //   'fall',
  //   'winter',
  //   'landmark',
  // ],
  // food: [
  //   'spring',
  //   'summer',
  //   'indoor',
  //   'outdoor',
  //   'studio',
  //   'living_room',
  //   'fall',
  //   'winter',
  //   'office',
  //   'city',
  //   'landmark',
  // ],
  // cosmetics: [
  //   'spring',
  //   'summer',
  //   'studio',
  //   'living_room',
  //   'office',
  //   'outdoor',
  //   'indoor',
  //   'fall',
  //   'winter',
  //   'city',
  //   'landmark',
  // ],
  // car: [
  //   'spring',
  //   'summer',
  //   'city',
  //   'outdoor',
  //   'landmark',
  //   'fall',
  //   'winter',
  //   'studio',
  //   'living_room',
  //   'office',
  //   'indoor',
  // ],
}

export const ARTWORK_ERROR_CODE = {
  101: 'upload image error',
  201: 'retry error',
  301: 'bg generate error',
  401: 'gen_compo error',
  402: 'analysis pipeline connection error',
  403: 'sod error',
  404: 'out of memory error',
  405: 'other issues in analysis pipeline',
  501: 'prep pipeline connection error',
  502: 'elastic search api call error',
  601: 'gen_agg error',
  701: 'nukki task error',
  801: 'imggen task error',
  901: 'composite task error',
}

export const PORTFOLIO_CONFIG_DEFAULT_BACK = {
  gen_shadow: true,
  gen_face: true,
  object_category: 'auto',
  object_sub_category: 'auto',
  object_boundary: 'none',
  object_angle: 'auto',
  model_gender: 'auto',
  flag_generate: true,
  flag_complex_cmp: true,
  flag_simple_cmp: true, // 단색
  flag_white_cmp: true, // 누끼
  output_size_w: 0,
  output_size_h: 0, // 0 means auto
  flag_gen_compo: false,
  flag_bg_expansion: false,
  flag_multiblob_sod: false,
  selected_bg_ids: '',

  flag_human_background: false,
  SO_length_scale: 'auto',

  flag_facemorphing: false,
  facemorphing_race: 'asian',
  facemorphing_gender: 'none',

  theme: '',
  theme_background: '',
  theme_template: 'auto',
  theme_custom: '',

  output_size_list: [],
  simple_bg_color_list: [
    [255, 255, 255],
    [242, 242, 244],
  ],
}

export const BANNER_CONFIG_DEFAULT = {
  // image: '',
  // logoImage: '',
  productName: '',
  productFeature: '',
  productCategory: '',
  brandName: '',
  brandFeature: '',
  bannerMainText1: '',
  bannerMainTextIdx: 0,
  bannerMainText2: '',
  bannerSubText1: '',
  bannerSubTextIdx: 0,
  bannerSubText2: '',
  outputW: 1024,
  outputH: 1024,
  humanDepict: '',
  humanInteractionFlag: false,
  mann2manImageS3UrlList: JSON.stringify([]),
  genType: 'full',
  geodaiMax: false,
  productDepict: '',
  bgDepict: '',
  bannerStyle: 'image_hooking',
  copyStyle: 'question',
  prevPrice: '',
  postPrice: '',
  // mann2manImage: '',
  // binaryMask: '',
  // selectedExternalSize: null,
}

// BANNER 용
export const BRAND_CONFIG_DEFAULT = {
  logoImage: [],
  brandName: '',
  brandFeature: '',
  logoS3path: '',
}

// font

export const FONT_DICT = {
  'pretendard_subtitle.otf': 'Pretendard Medium',
  'pretendard_title.otf': 'Pretendard SemiBold',
  'gmarketsans_subtitle.ttf': 'gmarket Sans',
  'gmarketsans_title.ttf': 'gmarket Sans Bold',
  'scdream_title.otf': 'scdream Bold',
  'scdream_subtitle.otf': 'scdream Regular',
  'jalnan_title.ttf': 'jalnan',
  'chosun_title.ttf': 'chosun',
  'heynovember_title.ttf': 'heynovember',
  'didot_title.otf': 'didot',

  'Pretendard Thin': 'Pretendard Thin',
  'Pretendard ExtraLight': 'Pretendard ExtraLight',
  'Pretendard Light': 'Pretendard Light',
  'Pretendard Regular': 'Pretendard Regular',
  'Pretendard Medium': 'Pretendard Medium',
  'Pretendard SemiBold': 'Pretendard SemiBold',
  'Pretendard Bold': 'Pretendard Bold',
  'Pretendard ExtraBold': 'Pretendard ExtraBold',
  'Pretendard Black': 'Pretendard Black',

  chosun: 'chosun',
  jalnan: 'jalnan',
  'gmarket Sans': 'gmarket Sans',
  'gmarket Sans Bold': 'gmarket Sans Bold',
  'scdream Regular': 'scdream Regular',
  'scdream Bold': 'scdream Bold',
  heynovember: 'heynovember',
  didot: 'didot',

  'font_bukk.ttf': 'bukk',
  'font_partialsans.otf': 'partialsans',
  'font_eulyoo1945.otf': 'eulyoo1945',
  'font_hambaknoon.ttf': 'hambaknoon',
  'font_onemobilepop.otf': 'onemobilepop',
  'font_onguelryutung.ttf': 'onguelryutung',
  'font_RixInooAriDuri.otf': 'RixInooAriDuri',
  'font_vitrocore.otf': 'vitrocore',
  'font_yes24myeongjo.ttf': 'yes24myeongjo',

  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
  // 'pretendard_subtitle.otf': 'Pretendard ???',
}

export const FONT_INFO = {
  'Pretendard Thin': { fontFamily: 'Pretendard Thin', label: '프리텐다드 Thin' },
  'Pretendard ExtraLight': { fontFamily: 'Pretendard ExtraLight', label: '프리텐다드 ExtraLight' },
  'Pretendard Light': { fontFamily: 'Pretendard Light', label: '프리텐다드 Light' },
  'Pretendard Regular': { fontFamily: 'Pretendard Regular', label: '프리텐다드 Regular' },
  'Pretendard Medium': { fontFamily: 'Pretendard Medium', label: '프리텐다드 Medium' },
  'Pretendard SemiBold': { fontFamily: 'Pretendard SemiBold', label: '프리텐다드 SemiBold' },
  'Pretendard Bold': { fontFamily: 'Pretendard Bold', label: '프리텐다드 Bold' },
  'Pretendard ExtraBold': { fontFamily: 'Pretendard ExtraBold', label: '프리텐다드 ExtraBold' },
  'Pretendard Black': { fontFamily: 'Pretendard Black', label: '프리텐다드 Black' },

  'gmarket Sans': { fontFamily: 'gmarket Sans', label: 'G마켓 산스 Medium' },
  'gmarket Sans Bold': { fontFamily: 'gmarket Sans Bold', label: 'G마켓 산스 Bold' },
  'scdream Regular': { fontFamily: 'scdream Regular', label: '에스코어 드림 4' },
  'scdream Bold': { fontFamily: 'scdream Bold', label: '에스코어 드림 7' },
  chosun: { fontFamily: 'chosun', label: '조선일보명조' },
  jalnan: { fontFamily: 'jalnan', label: '여기어때 잘난체' },
  heynovember: { fontFamily: 'heynovember', label: 'heynovember' },
  didot: { fontFamily: 'didot', label: 'didot' },

  bukk: { fontFamily: 'bukk', label: '부크크' },
  partialsans: { fontFamily: 'partialsans', label: '파셜산스' },
  eulyoo1945: { fontFamily: 'eulyoo1945', label: '을유 1945' },
  hambaknoon: { fontFamily: 'hambaknoon', label: '함박눈' },
  onemobilepop: { fontFamily: 'onemobilepop', label: 'ONE 모바일 POP' },
  onguelryutung: { fontFamily: 'onguelryutung', label: '온글잎 류뚱체' },
  RixInooAriDuri: { fontFamily: 'RixInooAriDuri', label: 'Rix이누아리두리' },
  vitrocore: { fontFamily: 'vitrocore', label: '비트로 코어' },
  yes24myeongjo: { fontFamily: 'yes24myeongjo', label: '예스 명조' },
}

export const NO_SO_UPLOAD_NAME = 'no_SO_no__SO_no___SO'

export const BANNER_ARTWORK_CONFIG = {
  logoS3path: null,
  humanDepict: '',
  bgDepict: '',
}

export const LANGUAGES = ['ko', 'en'] // 안정적으로 번역, 이미지리소스 등이 모두 마련된 경우 여기로 ..
export const ENGLISH_LANGUAGES = ['en', 'ja'] // 일부만 번역되어 베이스는 영어로 제공되어야하는 경우 여기에만

export const IMP_CODE = 'imp68713786' // 포트원 가맹점 코드
export const IMP_PG_ACCOUNT_ID = process.env.REACT_APP_IMP_PG_ACCOUNT_ID
export const IMP_NOTICE_URL = `${configData.DB_URL}/payment/imp_paid` // 포트원 웹훅 url

export const GUIDE_IMAGES = {
  upload: {
    original: '/static/images/배경생성_2.gif',
    draphed: ['/static/images/0-1.gif', '/static/images/0-1.gif', '/static/images/0-1.gif'],
  },

  removebg: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/0-1.gif',
      '/static/images/showcase/showcase5.png',
    ],
  },

  bundle: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/0-1.gif',
      '/static/images/showcase/showcase5.png',
    ],
  },

  bg_expansion: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  modelbg: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  face: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  mannequin: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  banner: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },

  default: {
    original: '/static/images/model_example/face_after.png',
    draphed: [
      '/static/images/model_example/female_asian.png',
      '/static/images/model_example/male_caucasian.png',
      '/static/images/showcase/showcase5.png',
    ],
  },
}

// 가로형
export const GUIDE_VIDEOS = {
  upload: {
    ko: '/static/video/guide/upload_ko.mp4',
    en: '/static/video/guide/upload_en.mp4',
    ja: '/static/video/guide/upload_en.mp4',
  },
  removebg: {
    ko: '/static/video/guide/removebg_ko.mp4',
    en: '/static/video/guide/removebg_ko.mp4',
    ja: '/static/video/guide/removebg_ko.mp4',
  },
  bundle: {
    ko: '/static/video/guide/bundle_ko.mp4',
    en: '/static/video/guide/bundle_en.mp4',
    ja: '/static/video/guide/bundle_en.mp4',
  },
  bg_expansion: {
    ko: '/static/video/guide/bg_expansion_ko.mp4',
    en: '/static/video/guide/bg_expansion_ko.mp4',
    ja: '/static/video/guide/bg_expansion_ko.mp4',
  },
  modelbg: {
    ko: '/static/video/guide/modelbg_ko.mp4',
    en: '/static/video/guide/modelbg_en.mp4',
    ja: '/static/video/guide/modelbg_en.mp4',
  },
  mannequin: {
    ko: '/static/video/guide/mannequin_ko.mp4',
    en: '/static/video/guide/mannequin_en.mp4',
    ja: '/static/video/guide/mannequin_en.mp4',
  },
  face: {
    ko: '/static/video/guide/face_ko.mp4',
    en: '/static/video/guide/face_en.mp4',
    ja: '/static/video/guide/face_en.mp4',
  },
  face_blur: {
    ko: '/static/video/guide/face_blur_ko.mp4',
    en: '/static/video/guide/face_blur_en.mp4',
    ja: '/static/video/guide/face_blur_en.mp4',
  },
  banner: {
    ko: '/static/video/guide/banner_ko.mp4',
    en: '/static/video/guide/banner_en.mp4',
    ja: '/static/video/guide/banner_en.mp4',
  },

  default: {
    ko: '/static/video/guide/upload_ko.mp4',
    en: '/static/video/guide/upload_en.mp4',
    ja: '/static/video/guide/upload_en.mp4',
  },
}

export const MODEL = {
  male: [{ id: 'achs' }, { id: 'gion' }],
  female: [{ id: 'ega' }, { id: 'bunk' }],
}
