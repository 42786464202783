import { v4 as uuidv4 } from 'uuid'

export const isRemovedBg = path => path?.includes('bgremoved')
export const bgRemovedStyle = {
  background:
    'linear-gradient(45deg, lightgray 25%, transparent 25%), linear-gradient(-45deg, lightgray 25%, transparent 25%), linear-gradient(45deg, transparent 75%, lightgray 75%), linear-gradient(-45deg, white 75%, lightgray 75%)',
  backgroundSize: '20px 20px' /* 크기 조정 */,
  backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0',
}

export const getUploadUUID = () => {
  // 하이픈 포함 36자. 디비에서 40자가 넘어가면 안됨~
  return uuidv4()
}

export const ADDPIECE_SELECTOR = 'addpiecetemp'
export const checkedURLforNoCache = path => {
  if (path?.includes(ADDPIECE_SELECTOR)) {
    return `${path}?w=${new Date()}`
  }
  return path
}
