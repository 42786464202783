// 포트폴리오 수정 다이얼로그

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import styled from '@emotion/styled'
import * as config from 'config'
import {
  portfolioDetailAtom,
  portfolioConfigAtom,
  userAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  isErrorMessageAtom,
  refImageAtom,
} from 'atoms'
import { cloneElement, useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil'

import { PORTFOLIO_CATEGORIES, OBJECT_ANGLE, OBJECT_BOUNDARY } from 'config'
import { apis } from 'apis'
import { useParams } from 'react-router-dom'
import { CenterAlignStack, CenterAlignBox } from 'components'
import { portfolioBgTypeSelector, userPortfolioNamesSelector } from 'selector'

import { CloseIcon } from 'theme/icon'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import {
  PortfolioConfigTabs,
  SetPortfolioName,
  SetPortfolioCategory,
  SetPortfolioConcept,
  SetPortfolioOutput,
  SetPortfolioAdvanced,
} from './PortfolioConfig'
import { t } from 'i18next'

const StyledTab = styled(props => <Tab {...props} />)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 600,
  width: `20rem`,
  color: theme.palette.common.black,
  // height: '5rem',
  '&.Mui-selected': {
    fontWeight: 700,
  },

  [theme.breakpoints.down('lg')]: {
    '&.Mui-selected': {
      color: theme.palette.common.black,
      fontWeight: 800,
    },
    fontSize: '1.5rem',
    width: '14rem',
  },
}))

const ApplyButton = styled(Button)(({ theme }) => ({
  width: '20rem',
  height: '4.8rem',
  // marginTop: '1rem',
  fontSize: '2rem',
  fontWeight: 800,

  [theme.breakpoints.down('lg')]: {
    width: '20rem',
    height: '4rem',
    fontSize: '1.6rem',
  },
}))

const tabContentMinHeight = '30rem'

export default function PortfolioConfigDialog({ open, setOpen }) {
  const { portfolioId } = useParams()
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)

  const resetConfig = useResetRecoilState(portfolioConfigAtom)
  const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)

  const [value, setValue] = useState(0)
  const [error, setError] = useState(false)

  const contentRef = useRef(null)

  useEffect(() => {
    setError(
      portfolioConfig.name?.length < 1 ||
        (portfolioConfig.name !== portfolioDetail.name &&
          userPortfolioNames?.includes(portfolioConfig.name))
    )
  }, [portfolioConfig.name])

  const handleChange = (event, newValue) => {
    setValue(newValue)

    // TODO 가운데로 보내고싶은데 죽어도 안됨
    const scrollParent = document.querySelector(`#mobile-tabs .MuiTabs-scroller`)
    const target = document.querySelector(`#mobile-tab-${newValue}`)

    if (scrollParent) {
      let to = 0
      to = 100

      //   setTimeout(() => {
      //     scrollParent.scrollTo({
      //       left: to,
      //       behavior: 'smooth',
      //     })
      //   }, 2000)
    }
  }

  const handleClose = () => {
    setPortfolioConfig(portfolioDetail.config)
    setOpen(false)
    setValue(0)
  }

  const updatePortfolio = () => {
    if (portfolioConfig.saveOutputConfig) {
      const body = {}
      const bg = []

      body.output_size_w =
        Number.isInteger(portfolioConfig.outputWidth) && portfolioConfig.outputWidth > 0
          ? portfolioConfig.outputWidth
          : null
      body.output_size_h =
        Number.isInteger(portfolioConfig.outputHeight) && portfolioConfig.outputHeight > 0
          ? portfolioConfig.outputHeight
          : null

      if (portfolioConfig.bgWhite) bg.push('white')
      if (portfolioConfig.bgSimple) bg.push('simple')
      if (portfolioConfig.bgComplex) bg.push('complex')
      if (portfolioConfig.bgGenerate) bg.push('generate')
      body.bg_type = bg
      apis.user.updateUserPreference(body)
    }

    // 배경 사이즈 멀티로 넣을 시, 뒤에 null 값은 삭제해서 넣음 + 2개 이상 멀티로 지정 시 배경 흰색 고정
    const editedConfigForbody = { ...portfolioConfig }
    let configOutputlist = [...editedConfigForbody.outputSizeList] ?? []

    if (!portfolioConfig.outputWidth || !portfolioConfig.outputHeight) {
      editedConfigForbody.outputHeight = null
      editedConfigForbody.outputWidth = null
    }

    if (configOutputlist.length !== 0) {
      configOutputlist = configOutputlist.filter(o => o.w !== null && o.h !== null)
    }

    if (configOutputlist.length > 1) {
      editedConfigForbody.simpleBgColorList = [[255, 255, 255]]
    } else {
      editedConfigForbody.simpleBgColorList = PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList
    }

    editedConfigForbody.outputSizeList = configOutputlist

    const body = {
      new_name: portfolioConfig.name,
      theme: portfolioConfig.theme,
      config: { ...editedConfigForbody },
    }

    apis.portfolio.updatePortfolio(portfolioId, body).then(response => {
      setPortfolioDetail(response.data)
      handleClose()

      // ----- GA4 event -----

      const bg = []
      if (portfolioConfig.bgWhite) bg.push('white')
      if (portfolioConfig.bgSimple) bg.push('simple')
      if (portfolioConfig.bgComplex) bg.push('complex')
      if (portfolioConfig.bgGenerate) bg.push('generate')

      window.gtag('event', 'portfolio_setting_modify', {
        config_object_category: portfolioConfig.category,
        config_object_sub_category: portfolioConfig.subCategory,
        output_size_width: portfolioConfig.outputWidth,
        output_size_height: portfolioConfig.outputHeight,
        image_type: bg,
        config_gen_shadow: portfolioConfig.genShadow.toString(),
        config_gen_face: portfolioConfig.genFace.toString(),

        theme: portfolioConfig.theme_template,
        multi_blob: portfolioConfig.flagMultiblobSod,
      })
      // ---------------------
    })
  }

  useEffect(() => {
    const contentElement = contentRef.current

    if (value !== 1) {
      return
    }

    if (value === 1) {
      if (contentRef.current) {
        // console.log(contentElement.scrollHeight)

        contentElement.scrollTo({
          top: 1000,
          behavior: 'smooth',
        })
      }
    }
  }, [value])

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={false}
          // sx={{
          //   '& .MuiPaper-root.MuiDialog-paper': {
          //     transition: 'height 0.5s ease-in-out',
          //   },
          // }}
          sx={{
            '& .MuiDialog-paper': {
              height: [1, 2, 3, 4].includes(value) ? '77rem' : '65rem',
              // height: value === 1 || value === 2 || value === 3 ? '74.5rem' : '67.7rem',
              transition: 'all 0.8s ease-in-out',
              borderRadius: '2rem',
              width: '115rem',
            },
          }}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'end',
              pt: '3.5rem',
              pr: '3.6rem',
              pb: '0rem',
            }}
          >
            <Box
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                mr: 1,
              }}
              onClick={handleClose}
            >
              <CloseIcon size="medium" onClick={handleClose} style={{ cursor: 'pointer', mr: 1 }} />
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{ py: '6rem', px: '1rem', height: value === 3 ? '80vh' : '60rem' }}
            ref={contentRef}
          >
            <CenterAlignBox>
              <Typography fontSize="2.5rem" fontWeight={700} sx={{ mb: '2.7rem' }}>
                포트폴리오 설정
              </Typography>
            </CenterAlignBox>

            <CenterAlignStack sx={{ width: '100%' }}>
              <PortfolioConfigTabs
                small
                value={value}
                onChange={handleChange}
                variant="scrollable"
                centered={false}
                scrollButtons="auto"
              />

              <TabContent value={value} setError={setError} />
            </CenterAlignStack>

            <CenterAlignStack>
              <ApplyButton
                variant="contained"
                onClick={updatePortfolio}
                disabled={
                  error ||
                  portfolioConfig.name?.length < 1 ||
                  !portfolioConfig.category ||
                  !portfolioConfig.subCategory
                }
              >
                {t('button.edit_complete')}
              </ApplyButton>
            </CenterAlignStack>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen
          id="mobile-dialog"
          sx={{ maxHeight: '100vh' }}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'end',
              pt: '3rem',
              pr: '3rem',
              pb: '1.4rem',
            }}
          >
            <Box
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CloseIcon size="medium" onClick={handleClose} style={{ cursor: 'pointer', mr: 1 }} />
            </Box>
          </DialogTitle>
          <DialogContent sx={{ p: 0, height: value === 3 ? '80vh' : 'unset' }}>
            <CenterAlignBox>
              <Typography fontSize="2rem" fontWeight={800} sx={{ mb: '4rem' }}>
                포트폴리오 설정
              </Typography>
            </CenterAlignBox>

            <CenterAlignStack sx={{ width: '100%' }}>
              <PortfolioConfigTabs
                small
                value={value}
                onChange={handleChange}
                variant="scrollable"
                centered={false}
                scrollButtons="auto"
                mobileSmall
              />
              <TabContent value={value} setError={setError} />
              <CenterAlignStack>
                <ApplyButton
                  variant="contained"
                  onClick={updatePortfolio}
                  disabled={
                    error ||
                    portfolioConfig.name?.length < 1 ||
                    !portfolioConfig.category ||
                    !portfolioConfig.subCategory
                  }
                  sx={{ mb: '4rem' }}
                >
                  {t('button.edit_complete')}
                </ApplyButton>
              </CenterAlignStack>
            </CenterAlignStack>
          </DialogContent>
        </Dialog>
      </Mobile>
    </>
  )
}

export function TabContent({ value }) {
  switch (value) {
    case 0:
      return <PortfolioName />
    case 1:
      return <PortfolioConcept />
    case 2:
      return <PortfolioCategory />
    case 3:
      return <OutputConfig />
    case 4:
      return <UploadConfig />
  }
}

const ALL_SUBCATEGORIES = []
for (const [key, value] of Object.entries(PORTFOLIO_CATEGORIES)) {
  ALL_SUBCATEGORIES.push(...value.children)
}

function PortfolioName() {
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const [placeholder, setPlaceholder] = useState('[예시] 220601, 23FW, 접시, 화장품 등')
  const [errorMessage, setErrorMessage] = useRecoilState(isErrorMessageAtom)

  const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)

  const handleChange = ({ target }) => {
    if (target.value !== portfolioDetail.name && userPortfolioNames?.includes(target.value)) {
      setErrorMessage('다른 포트폴리오의 제목과 중복되었습니다.')
    } else {
      setErrorMessage('')
    }

    setPortfolioConfig({ ...portfolioConfig, name: target.value })
  }

  const onFocus = () => {
    setPlaceholder('')
  }
  const onBlur = () => {
    setPlaceholder('[예시] 220601, 23FW, 접시, 화장품 등')
  }

  return (
    <SetPortfolioName
      sx={{ my: { lg: 3 } }}
      value={portfolioConfig.name}
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      errorMessage={errorMessage}
    />
  )
}

export function PortfolioCategory() {
  const user = useRecoilValue(userAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  const onClickMain = category => event => {
    setPortfolioConfig({
      ...portfolioConfig,
      category: category.id,
      subCategory: 'auto',
    })
  }

  return (
    <SetPortfolioCategory
      setConfig={setPortfolioConfig}
      config={portfolioConfig}
      onClickMain={onClickMain}
    />
  )
}

export function PortfolioConcept({ isUploadPortfolio = false }) {
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [files, setFiles] = useRecoilState(refImageAtom)

  return (
    <SetPortfolioConcept
      config={portfolioConfig}
      setConfig={setPortfolioConfig}
      files={files}
      setFiles={setFiles}
    />
  )
}

export function OutputConfig() {
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const isOutputSizeValid =
    portfolioConfig?.outputSizeList.length > 1 &&
    portfolioConfig?.outputSizeList[1].w !== null &&
    portfolioConfig?.outputSizeList[1].h !== null

  // useEffect(() => {
  //   if (!portfolioConfig.outputSizeList.length) {
  //     setPortfolioConfig(prevState => ({
  //       ...prevState,
  //       outputSizeList: [
  //         ...prevState.outputSizeList,
  //         { w: portfolioConfig.outputWidth, h: portfolioConfig.outputHeight },
  //       ],
  //     }))
  //   }
  // }, [portfolioConfig])

  useEffect(() => {
    if (portfolioConfig.outputSizeList.length) {
      const firstOutputList = portfolioConfig.outputSizeList[0]
      setPortfolioConfig(prev => ({
        ...prev,
        outputWidth: firstOutputList.w,
        outputHeight: firstOutputList.h,
      }))
    }
  }, [portfolioConfig.outputSizeList])

  return <SetPortfolioOutput config={portfolioConfig} setConfig={setPortfolioConfig} />
}

export function UploadConfig() {
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  return (
    <SetPortfolioAdvanced
      portfolioConfig={portfolioConfig}
      setPortfolioConfig={setPortfolioConfig}
    />
  )
}
