import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { ZeroCreditWarningDialogAtom } from 'atoms'
import { CenterAlignStack } from 'components'
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { currentMenuAtom } from '../../atoms'

export default function ZeroCreditWarningDialog() {
  const [dialog, setDialog] = useRecoilState(ZeroCreditWarningDialogAtom)
  const resetDialog = useResetRecoilState(ZeroCreditWarningDialogAtom)
  const { open } = dialog
  const { t } = useTranslation()

  const currentMenu = useRecoilValue(currentMenuAtom)

  //
  useEffect(() => {}, [])

  useEffect(() => {}, [open])

  const handleClose = () => {
    resetDialog()
  }

  const handleClickButton = () => {
    // ----- GA4 event -----
    window.gtag('event', 'not_enough_credit_click', {
      menu: currentMenu?.id,
      metohd: 'see_the_plan',
    })
    // ---------------------
    window.open(`${window.location.origin}/pricing`, '_blank').focus()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: 9999,
        '& .MuiDialog-paper': {
          width: { lg: '50rem', xs: '35rem' },
          height: { lg: '23rem', xs: '17rem' },
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          mt: '1rem',
          p: { xs: '1rem' },
          fontSize: { lg: '2.3rem !important', xs: '1.5rem !important' },
        }}
      >
        {t(`credit_warning_dialog.title`)}
      </DialogTitle>
      <DialogContent sx={{ p: { xs: '1rem' } }}>
        <CenterAlignStack
          sx={{
            height: '100%',
            justifyContent: 'center',
            '& .MuiTypography-root': {
              fontSize: { lg: '1.5rem', xs: '1.3rem' },
            },
          }}
        >
          <Typography>{t(`credit_warning_dialog.comment_1`)}</Typography>
          <Typography>{t(`credit_warning_dialog.comment_2`)}</Typography>
        </CenterAlignStack>
      </DialogContent>
      {/* 
      <Button
        variant="contained"
        sx={{ width: '100%', fontSize: '1.7rem', borderRadius: ' 0px 0px 8px 8px', height: '5rem' }}
        onClick={handleClickButton}
      >
        {t(`credit_warning_dialog.go_to_pricing`)}
      </Button> */}

      <DialogActions sx={{ p: 0 }}>
        <Button
          variant="contained"
          onClick={handleClickButton}
          sx={{
            width: '50%',
            height: { lg: '48px', xs: '40px' },
            fontSize: { lg: '16px', xs: '14px' },
            fontWeight: 600,
            backgroundColor: theme => theme.palette.common.black,
            borderRadius: 0,
            color: theme => theme.palette.common.white,
            '&:hover': {
              color: theme => theme.palette.common.white,
            },
          }}
        >
          {t(`credit_warning_dialog.go_to_pricing`)}
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            // ----- GA4 event -----
            window.gtag('event', 'not_enough_credit_click', {
              menu: currentMenu?.id,
              metohd: 'go_to_my_page',
            })

            // ---------------------

            window.open(`${window.location.origin}/user/mypage`, '_blank').focus()
            handleClose()
          }}
          sx={{
            ml: '0 !important',
            width: '50%',
            height: { lg: '48px', xs: '40px' },
            fontSize: { lg: '16px', xs: '14px' },
            fontWeight: 600,
            backgroundColor: theme => theme.palette.common.white,
            borderRadius: 0,
            color: theme => theme.palette.common.black,
            '&:hover': {
              backgroundColor: '#F8F8F8',
              color: theme => theme.palette.common.black,
            },
          }}
        >
          {t(`credit_warning_dialog.go_to_mypage`)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
