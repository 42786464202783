import { fabric } from 'fabric'

export function snapLogic(canvasInstance, movedObject, snapDistance = 8, name) {
  const movedBounds = movedObject.getBoundingRect()
  const movedWidth = movedBounds.width
  const movedHeight = movedBounds.height

  if (name === 'moving') {
    canvasInstance.forEachObject(obj => {
      if (obj === movedObject || obj.type === 'line') return null

      const isPartOfMovedObject = movedObject?._objects?.some(item => item === obj)
      if (isPartOfMovedObject) return

      const objBounds = obj.getBoundingRect()

      const objWidth = objBounds.width
      const objHeight = objBounds.height

      const targetCenterX = movedObject.left + movedWidth / 2
      const targetCenterY = movedObject.top + movedHeight / 2
      const objCenterX = obj.left + objWidth / 2
      const objCenterY = obj.top + objHeight / 2

      if (Math.abs(movedObject.left - obj.left) < snapDistance) {
        // 좌 - 좌
        movedObject.left = obj.left
        // snapMovement(movedObject, 'left')
        checkAndDrawGuide(
          movedObject.left,
          obj.left,
          true,
          movedObject.top + movedHeight <= obj.top
            ? movedObject.top
            : movedObject.top + movedHeight,
          movedObject.top + movedHeight <= obj.top ? obj.top + objHeight : obj.top,
          'left-left'
        )
      } else if (Math.abs(movedObject.left - (obj.left + objWidth)) < snapDistance) {
        // 좌 - 우
        movedObject.left = obj.left + objWidth
        checkAndDrawGuide(
          movedObject.left,
          obj.left + objWidth,
          true,
          movedObject.top + movedHeight <= obj.top
            ? movedObject.top
            : movedObject.top + movedHeight,
          movedObject.top + movedHeight <= obj.top ? obj.top + objHeight : obj.top,
          'left-right'
        )
        // snapMovement(movedObject, 'left')
      } else if (
        Math.abs(movedObject.left - obj.left) > snapDistance &&
        Math.abs(movedObject.left - objCenterX) < snapDistance
      ) {
        // 좌 - 중
        movedObject.left = objCenterX
        checkAndDrawGuide(
          movedObject.left,
          objCenterX,
          true,
          movedObject.top + movedHeight <= obj.top
            ? movedObject.top
            : movedObject.top + movedHeight,
          movedObject.top + movedHeight <= obj.top ? obj.top + objHeight : obj.top,
          'left-center'
        )
        // snapMovement(movedObject, 'left')
      }

      // 우 - 좌
      if (Math.abs(movedObject.left + movedWidth - obj.left) < snapDistance) {
        movedObject.left = obj.left - movedWidth
        // snapMovement(movedObject, 'right')
        checkAndDrawGuide(
          movedObject.left + movedWidth,
          obj.left,
          true,
          movedObject.top + movedHeight <= obj.top
            ? movedObject.top
            : movedObject.top + movedHeight,
          movedObject.top + movedHeight <= obj.top ? obj.top + objHeight : obj.top,
          'right-left'
        )
      } else if (
        // 우 - 우
        Math.abs(movedObject.left + movedWidth - (obj.left + objWidth)) < snapDistance
      ) {
        movedObject.left = obj.left + objWidth - movedWidth
        // snapMovement(movedObject, 'right')
        checkAndDrawGuide(
          movedObject.left + movedWidth,
          obj.left + objWidth,
          true,
          movedObject.top + movedHeight <= obj.top
            ? movedObject.top
            : movedObject.top + movedHeight,
          movedObject.top + movedHeight <= obj.top ? obj.top + objHeight : obj.top,
          'right-right'
        )
      } else if (Math.abs(movedObject.left + movedWidth - objCenterX) < snapDistance) {
        // 우 - 중
        movedObject.left = objCenterX - movedWidth
        checkAndDrawGuide(
          movedObject.left + movedWidth,
          objCenterX,
          true,
          movedObject.top + movedHeight <= obj.top
            ? movedObject.top
            : movedObject.top + movedHeight,
          movedObject.top + movedHeight <= obj.top ? obj.top + objHeight : obj.top,
          'right-center'
        )
        // snapMovement(movedObject, 'right')
      }

      // 중 - 좌
      if (Math.abs(targetCenterX - obj.left) < snapDistance) {
        movedObject.left = obj.left - movedWidth / 2
        checkAndDrawGuide(
          targetCenterX,
          obj.left,
          true,
          movedObject.top + movedHeight <= obj.top
            ? movedObject.top
            : movedObject.top + movedHeight,
          movedObject.top + movedHeight <= obj.top ? obj.top + objHeight : obj.top,
          'center-left'
        )
      } else if (Math.abs(targetCenterX - (obj.left + objWidth)) < snapDistance) {
        // 중 - 우
        movedObject.left = obj.left + objWidth - movedWidth / 2
        checkAndDrawGuide(
          targetCenterX,
          obj.left + objWidth,
          true,
          movedObject.top + movedHeight <= obj.top
            ? movedObject.top
            : movedObject.top + movedHeight,
          movedObject.top + movedHeight <= obj.top ? obj.top + objHeight : obj.top,
          'center-right'
        )
      } else if (Math.abs(targetCenterX - objCenterX) < snapDistance) {
        // 중 - 중
        movedObject.left = objCenterX - movedWidth / 2
        checkAndDrawGuide(
          targetCenterX,
          objCenterX,
          true,
          movedObject.top + movedHeight <= obj.top
            ? movedObject.top
            : movedObject.top + movedHeight,
          movedObject.top + movedHeight <= obj.top ? obj.top + objHeight : obj.top,
          'center-center'
        )
      }

      // 상 - 상
      if (Math.abs(movedObject.top - obj.top) < snapDistance) {
        // Center to center horizontally
        movedObject.top = obj.top
        checkAndDrawGuide(
          movedObject.top,
          obj.top,
          false,
          movedObject.left + movedWidth <= obj.left
            ? movedObject.left
            : movedObject.left + movedWidth,
          movedObject.left + movedWidth <= obj.left ? obj.left + objWidth : obj.left,
          'up-up'
        )
      } else if (Math.abs(movedObject.top - (obj.top + objHeight)) < snapDistance) {
        // 상 - 하
        movedObject.top = obj.top + objHeight
        checkAndDrawGuide(
          movedObject.top,
          obj.top + objHeight,
          false,
          movedObject.left + movedWidth <= obj.left
            ? movedObject.left
            : movedObject.left + movedWidth,
          movedObject.left + movedWidth <= obj.left ? obj.left + objWidth : obj.left,
          'up-bottom'
        )
      } else if (Math.abs(movedObject.top - objCenterY) < snapDistance) {
        // 상 - 중
        movedObject.top = objCenterY
        checkAndDrawGuide(
          movedObject.top,
          objCenterY,
          false,
          movedObject.left + movedWidth <= obj.left
            ? movedObject.left
            : movedObject.left + movedWidth,
          movedObject.left + movedWidth <= obj.left ? obj.left + objWidth : obj.left,
          'up-center'
        )
      }

      // 하 - 상
      if (Math.abs(movedObject.top + movedHeight - obj.top) < snapDistance) {
        movedObject.top = obj.top - movedHeight
        checkAndDrawGuide(
          movedObject.top + movedHeight,
          obj.top,
          false,
          movedObject.left + movedWidth <= obj.left
            ? movedObject.left
            : movedObject.left + movedWidth,
          movedObject.left + movedWidth <= obj.left ? obj.left + objWidth : obj.left,
          'bottom-up'
        )
      } else if (
        // 하 - 하
        Math.abs(movedObject.top + movedHeight - (obj.top + objHeight)) < snapDistance
      ) {
        movedObject.top = obj.top + objHeight - movedHeight
        checkAndDrawGuide(
          movedObject.top + movedHeight,
          obj.top + objHeight,
          false,
          movedObject.left + movedWidth <= obj.left
            ? movedObject.left
            : movedObject.left + movedWidth,
          movedObject.left + movedWidth <= obj.left ? obj.left + objWidth : obj.left,
          'bottom-bottom'
        )
      } else if (Math.abs(movedObject.top + movedHeight - objCenterY) < snapDistance) {
        // 하 - 중
        movedObject.top = objCenterY - movedHeight
        checkAndDrawGuide(
          movedObject.top + movedHeight,
          objCenterY,
          false,
          movedObject.left + movedWidth <= obj.left
            ? movedObject.left
            : movedObject.left + movedWidth,
          movedObject.left + movedWidth <= obj.left ? obj.left + objWidth : obj.left,
          'bottom-center'
        )
      }

      // 중 - 좌
      if (Math.abs(targetCenterY - obj.top) < snapDistance) {
        movedObject.top = obj.top - movedHeight / 2
        checkAndDrawGuide(
          targetCenterY,
          obj.top,
          false,
          movedObject.left + movedWidth <= obj.left
            ? movedObject.left
            : movedObject.left + movedWidth,
          movedObject.left + movedWidth <= obj.left ? obj.left + objWidth : obj.left,
          'center-up'
        )
      } else if (Math.abs(targetCenterY - (obj.top + objHeight)) < snapDistance) {
        // 중 - 우
        movedObject.top = obj.top + objHeight - movedHeight / 2
        checkAndDrawGuide(
          targetCenterY,
          obj.top + objHeight,
          false,
          movedObject.left + movedWidth <= obj.left
            ? movedObject.left
            : movedObject.left + movedWidth,
          movedObject.left + movedWidth <= obj.left ? obj.left + objWidth : obj.left,
          'center-bottom'
        )
      } else if (Math.abs(targetCenterY - objCenterY) < snapDistance) {
        // 중 - 중
        movedObject.top = objCenterY - movedHeight / 2
        checkAndDrawGuide(
          targetCenterY,
          objCenterY,
          false,
          movedObject.left + movedWidth <= obj.left
            ? movedObject.left
            : movedObject.left + movedWidth,
          movedObject.left + movedWidth <= obj.left ? obj.left + objWidth : obj.left,
          'center-center'
        )
      }
    })
  }

  function checkAndDrawGuide(movedLine, staticLine, isVertical, start, end, name) {
    const startPoints = start > end ? end : start
    const endPotints = start >= end ? start : end
    if (isVertical) {
      drawGuideLine(staticLine, startPoints, staticLine, endPotints)
    } else {
      drawGuideLine(startPoints, staticLine, endPotints, staticLine)
    }
  }

  function drawGuideLine(x1, y1, x2, y2) {
    const line = new fabric.Line([x1, y1, x2, y2], {
      stroke: 'rgba(0,255,255,0.8)',
      selectable: false,
      evented: false,
      guideline: true,
      strokeDashArray: [10],
      strokeWidth: 1,
    })
    canvasInstance.add(line)
    canvasInstance.sendToBack(line)
  }
}

export function scalingLogic(canvasInstance, scalingObject, snapXDistance = 8, snapYDistance) {
  const origLeft = scalingObject.left
  const origTop = scalingObject.top
  const origScaleX = scalingObject.scaleX
  const origScaleY = scalingObject.scaleY

  const origWidth = scalingObject.width * origScaleX
  const origHeight = scalingObject.height * origScaleY
  const origBounds = scalingObject.getBoundingRect()

  // Scale 후의 객체 크기와 위치 계산

  // console.log(origBounds.width, origWidth, scalingObject)
  // console.log('ratio', scalingObject.scaleX / scalingObject.scaleY)
  // console.log(scalingObject.getBoundingRect().left, origLeft)
  // console.log(origWidth / scalingObject.width, scalingObject.scaleX) // 이거야
  // console.log(origBounds.width / scalingObject.width, scalingObject.scaleX)

  canvasInstance.forEachObject(obj => {
    if (obj === scalingObject || obj.type === 'line') return

    const objBounds = obj.getBoundingRect()
    const objWidth = obj.width * obj.scaleX
    const objHeight = obj.height * obj.scaleY

    // 대각선 방향에 따른 Snap 계산 로직
    // 예: br 핸들러 사용 시
    const objRight = obj.left + objWidth
    const objBottom = obj.top + objHeight

    // 우측 하단(br) - 우측
    if (Math.abs(origLeft + origWidth - objRight) < snapXDistance) {
      const newScaleX = (objRight - origLeft) / scalingObject.width
      // 비율 유지를 위해 scaleY도 동일한 비율로 조정

      scalingObject.set({
        scaleX: newScaleX,
        scaleY: newScaleX,
      })
    } else if (Math.abs(origTop + origHeight - objBottom) < snapYDistance) {
      const newScaleY = (objBottom - origTop) / scalingObject.height
      // 비율 유지를 위해 scaleX도 동일한 비율로 조정

      scalingObject.set({
        scaleX: newScaleY,
        scaleY: newScaleY,
      })
    }

    // 다른 대각선 핸들러의 경우 여기에 로직 추가...
  })
}
