import {
  Button,
  Typography,
  Stack,
  Box,
  Grid,
  TextField,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  Collapse,
} from '@mui/material'
import { apis } from 'apis'
import crudAxios from 'apis/configs/crud'
import { creditPlansAtom, languageAtom, userAtom } from 'atoms'
import axios from 'axios'
import {
  CenterAlignStack,
  NaverProductButton,
  LanguageSelect,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from 'components'
import { generatePaymentUid } from 'pages/Pricing'
import { getNextPaidDay } from 'pricing'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import moment from 'moment'
import { getS3ImageSrc, numberCommaFormatter } from 'utils/common'
import { useNavigate } from 'react-router-dom'
import { NaverCommerceRegister } from 'pages/NaverCommerceRegister'
import { BlogSample } from './BlogSample'
import { LineBannerSwiper } from '../../components/main/LineBannerSwiper'
import {
  AlignBoxCenterIcon,
  AlignBoxLeftIcon,
  AlignBoxRightIcon,
  AlignContentCenterIcon,
  AlignContentLeftIcon,
  AlignContentRightIcon,
  ArrowUpInCircleIcon,
  CoinStackIcon,
  CouponIcon2,
  ImageIcon,
  LanguageIcon,
  LogoutIcon2,
  MypageIcon,
  MypageIcon2,
} from 'theme/icon'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { removeUserCookie } from 'utils/cookie'

const tagUrl = 'http://100.109.2.26:6902/tag_emb/?stnc='
const IMG_BASE64 = 'data:image/png;base64,'

const body = {
  // artwork_id: '',
  user_id: 2,
  category: 'food',
  sub_category: 'plate',
  angle: 'perpen',
  so_size: 'small',
  output_w: 500,
  output_h: 1500,
}

function TestPageee() {
  useEffect(() => {}, [])
  return (
    <>
      <Box sx={{ background: 'gray', p: '2rem' }}>
        <CardMenu />
      </Box>
    </>
  )
}

function CardMenu({ setOpen }) {
  const { t, i18n } = useTranslation()
  const [credit, setCredit] = useState()
  const [lang, setLang] = useRecoilState(languageAtom)
  const resetUser = useResetRecoilState(userAtom)

  const [langExpand, setLangExpand] = useState(false)

  const navigate = useNavigate()
  const logoutUser = () => {
    // removeUserCookie()
    // resetUser()
    // window.location.reload()
    // navigate('/home')
  }

  const dropdownGAEvent = menu => {
    const EVENT_NAME = 'mypage_dropdown'
    // ----- GA4 event -----
    window.gtag('event', EVENT_NAME, {
      menu,
    })
    // ---------------------
  }

  const menuItems = [
    {
      to: '/user/profile',
      icon: <MypageIcon2 />,
      title: t('mypage_menu.user_info'),
      menu_name: 'profile',
    },
    {
      to: '/user/background',
      icon: <ImageIcon />,
      title: t('mypage_menu.my_image'),
      menu_name: 'my_image',
    },
    {
      to: '/user/mypage',
      icon: <CoinStackIcon />,
      title: t('mypage_menu.credit_and_subscriptions'),
      menu_name: 'credit',
      endAdornment: (
        <Box
          sx={{
            background: theme => theme.palette.draph.blue,
            color: 'white',
            px: '0.6rem',
            py: '0.2rem',
            borderRadius: '4px',
            fontSize: '1.2rem',
            fontWeight: 700,
            maxWidth: '6.5rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {!isNaN(credit) && numberCommaFormatter(credit)}
        </Box>
      ),
    },
    {
      to: '/user/coupon',
      icon: <CouponIcon2 />,
      title: t('mypage_menu.coupon'),
      menu_name: 'coupon',
    },
    {
      to: null,
      icon: <LanguageIcon />,
      title: t('mypage_menu.language'),
      endAdornment: (
        <Stack
          direction="row"
          sx={{
            background: '#EEEEEE',
            minWidth: 0,
            px: '0.6rem',
            py: '0.2rem',
            borderRadius: '4px',

            '& .text': {
              fontSize: '1.2rem',

              fontWeight: 700,
              color: '#989898',
              // background: 'gold',
              '&.on': {
                color: theme => theme.palette.common.black,
              },
            },
          }}
          // gap={'0.8rem'}
        >
          <Collapse
            in={langExpand}
            orientation="horizontal"
            sx={{
              '& .text': {
                mr: '0.6rem',
              },
            }}
          >
            {i18n?.language === 'en' && (
              <Typography
                className={`text ${i18n?.language === 'ko' && 'on'}`}
                onClick={e => {
                  e.stopPropagation()
                  setLangExpand(false)
                  onClickLanguage('ko')
                }}
              >
                KR
              </Typography>
            )}
            {i18n?.language === 'ko' && (
              <Typography
                className={`text ${i18n?.language === 'en' && 'on'}`}
                onClick={e => {
                  e.stopPropagation()
                  setLangExpand(false)
                  onClickLanguage('en')
                }}
              >
                ENG
              </Typography>
            )}
          </Collapse>
          {i18n?.language === 'ko' && (
            <Typography
              className={`text ${i18n?.language === 'ko' && 'on'}`}
              onClick={e => {
                e.stopPropagation()
                setLangExpand(true)
              }}
            >
              KR
            </Typography>
          )}
          {i18n?.language === 'en' && (
            <Typography
              className={`text ${i18n?.language === 'en' && 'on'}`}
              onClick={e => {
                e.stopPropagation()
                setLangExpand(true)
              }}
            >
              ENG
            </Typography>
          )}
        </Stack>
      ),
    },
    {
      to: '',
      icon: <LogoutIcon2 />,
      title: t('mypage_menu.logout'),
      endAdornment: <></>,
      onClick: () => {
        dropdownGAEvent('logout')
        logoutUser()
      },
    },
  ]

  useEffect(() => {
    apis.user.getCredit().then(response => {
      setCredit(response.data.credit ?? 0)
    })
  }, [])

  const cardStyle = {
    boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.15)',

    width: '23.4rem',
    borderRadius: '1rem',

    '& .icon-wrapper': {
      width: '3.2rem',
      height: '3.2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .item': {
      height: '4rem',
      px: '1.2rem',
      cursor: 'pointer',
      '&:hover': { background: '#F2F6FF' },
      '& svg path': { stroke: theme => theme.palette.common.black },
      '& .title': {
        fontSize: '1.4rem',
        fontWeight: 700,
      },
    },
  }

  const onClickLanguage = newLang => {
    dropdownGAEvent(`language_${lang}_${newLang}`)

    setLang(newLang)
    i18n.changeLanguage(newLang)
    window.localStorage.setItem('i18nextLng', newLang)
  }

  return (
    <>
      <Card sx={cardStyle}>
        <Stack>
          {menuItems.map((item, idx) => (
            <CenterAlignStack
              direction="row"
              className="item"
              key={idx}
              sx={{ justifyContent: 'space-between' }}
              onClick={() => {
                if (item.onClick) {
                  item.onClick()
                } else {
                  if (item.menu_name) {
                    dropdownGAEvent(item.menu_name)
                  }
                  navigate(item.to)
                  setOpen(false)
                }
              }}
            >
              <CenterAlignStack direction="row">
                <Box className="icon-wrapper">{item.icon}</Box>
                <Typography className="title">{item.title}</Typography>
              </CenterAlignStack>

              {item.endAdornment}
            </CenterAlignStack>
          ))}
        </Stack>
      </Card>
    </>
  )
}

const AlignToggleButton = styled(ToggleButton)(({ theme }) => ({
  background: '#FFF',
  borderColor: 'transparent',
  marginRight: '1rem',
  borderRadius: '4px !important',

  '&.Mui-selected': {
    background: '#FFF',
    borderColor: `${theme.palette.draph.blue} !important`,
    borderWidth: '1px !important',

    '& svg path': {
      stroke: theme.palette.draph.blue,
    },

    '&:hover': {},
  },
}))
function MakeIcon() {
  const [alignContent, setAlignContent] = useState('left')
  const [alignBox, setAlignBox] = useState('center')

  return (
    <>
      <Stack sx={{ m: '3rem', p: '3rem', border: '1px dashed gray' }} spacing={2}>
        <ToggleButtonGroup
          value={alignContent}
          exclusive
          onChange={(e, v) => {
            if (!v) return
            setAlignContent(v)
          }}
        >
          <AlignToggleButton value="left">
            <AlignContentLeftIcon />
          </AlignToggleButton>
          <AlignToggleButton value="center">
            <AlignContentCenterIcon />
          </AlignToggleButton>
          <AlignToggleButton value="right">
            <AlignContentRightIcon />
          </AlignToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup
          value={alignBox}
          exclusive
          onChange={(e, v) => {
            if (!v) return
            setAlignBox(v)
          }}
        >
          <AlignToggleButton value="left">
            <AlignBoxLeftIcon />
          </AlignToggleButton>
          <AlignToggleButton value="center">
            <AlignBoxCenterIcon />
          </AlignToggleButton>
          <AlignToggleButton value="right">
            <AlignBoxRightIcon />
          </AlignToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </>
  )
}

function BytesToImage() {
  return (
    <>
      <CenterAlignStack
        sx={{ border: '1px dashed red', background: 'pink', p: '3rem', height: '10rem' }}
      >
        <LanguageSelect />
      </CenterAlignStack>
      <CenterAlignStack sx={{ border: '1px dashed red', p: '3rem', height: '10rem' }}>
        <LineBannerSwiper />
      </CenterAlignStack>
    </>
  )
}

function NaverCommerceTest() {
  const [productList, setProductList] = useState([])

  const [selected, setSelected] = useState()
  const [optionImages, setOptionImages] = useState([])
  const [mainImage, setMainImage] = useState()
  const [elapsed, setElapsed] = useState()
  const [draphed, setDraphed] = useState([])

  const getProductList = () => {
    apis.naverCommerce.getProductList(true).then(response => {
      // if (response.data.contents) setProductList(response.data.contents)
      console.log(response.data.products)
      setProductList(response.data.products)
    })
  }

  const getProductDetail = productNo => {
    const start = Date.now()

    setElapsed('불러오는중....')
    setMainImage()
    setOptionImages([])

    apis.naverCommerce
      .getProductDetail(productNo, true)
      .then(response => {
        const finish = Date.now()
        const el = (finish - start) / 1000
        setElapsed(`${el} 초`)
        const mainImageBytes = response.data.main_image
        const imageSrc = `${IMG_BASE64}${mainImageBytes.replaceAll('"', '')}`
        setMainImage(imageSrc)

        const optionImageBytes = response.data.option_images
        setOptionImages(optionImageBytes.map(imgB => `${IMG_BASE64}${imgB.replaceAll('"', '')}`))
      })
      .catch(() => {
        setElapsed('에러')
      })
  }

  const getArtwork = () => {
    apis.portfolio
      .getArtwork(1118, '1693793778879_pexels-denys-mikhalevych-11935670')
      .then(response => {
        console.log(response.data)
        if (response.data.pieces) {
          setDraphed(response.data.pieces.map(p => p.path))
        }
      })
  }

  const uploadImage = () => {
    const images = draphed.map(path => getS3ImageSrc(path))
    console.log(images)
    apis.naverCommerce.uploadImages({ images, origin_product_no: selected.originProductNo })
  }

  return (
    <>
      <Box sx={{ p: 5 }}>
        <NaverProductButton text="상품 불러오기" />
        <Typography>테수투</Typography>
        <Button variant="outlined" onClick={getProductList}>
          상품목록 불러오기
        </Button>
        <Button variant="outlined" onClick={getArtwork}>
          드랩생성이미지 불러오기
        </Button>

        <Stack direction="row">
          {draphed.map(path => (
            <div key={path}>
              <img src={getS3ImageSrc(path)} style={{ width: '100px' }} />
            </div>
          ))}
          <button onClick={uploadImage}>업로드</button>
        </Stack>

        <Box sx={{ display: 'flex' }}>
          <Stack sx={{ border: '1px solid gray' }}>
            {productList.map(p => (
              <Box key={p.originProductNo} sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
                {p.originProductNo}

                <img
                  src={`${IMG_BASE64}${p.main_image_b.replaceAll('"', '')}`}
                  style={{ objectFit: 'contain', width: '100%', height: '250px' }}
                />
                <Button
                  onClick={() => {
                    setSelected(p)
                    getProductDetail(p.originProductNo)
                  }}
                >
                  상세
                </Button>
              </Box>
            ))}
          </Stack>

          {/* <Stack>
            <Typography variant="h2">{selected?.originProductNo}</Typography>
            <Typography variant="h3">
              상세정보조회 소요시간~ <span style={{ color: 'red' }}>{elapsed}</span>
            </Typography>
            <CenterAlignStack sx={{ width: '400px' }}>
              <Typography variant="h5">메인이미지</Typography>
              <img
                src={mainImage}
                style={{ objectFit: 'contain', width: '100%', height: '250px' }}
              />
            </CenterAlignStack>
            <Stack sx={{ mt: '100px' }}>
              <Typography variant="h5">
                옵션이미지{' '}
                <span style={{ color: 'blue' }}>
                  {optionImages.length > 0 && `${optionImages.length}장`}
                </span>
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                sx={{ width: '1300px', border: '1px solid', padding: '30px', overflow: 'scroll' }}
              >
                {optionImages.map((src, idx) => (
                  <img
                    key={idx}
                    src={src}
                    style={{ objectFit: 'contain', width: '100%', height: '250px' }}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack> */}
        </Box>
      </Box>
    </>
  )
}

function BackgroundSearch() {
  const [bgs, setBgs] = useState([])
  const [tag, setTag] = useState('')

  const [body, setBody] = useState({
    // artwork_id: '',
    user_id: 2,
    category: 'person',
    sub_category: 'whole',
    angle: 'floor',
    so_size: 'medium',
    output_w: 500,
    output_h: 1500,
  })

  useEffect(() => {}, [])

  const searchBackground = async () => {
    const res = await axios.get(tagUrl + tag)

    axios
      .post('http://localhost:8000/db/background/search_test', {
        ...body,
        output_h: body.output_h.length > 0 ? body.output_h : null,
        output_w: body.output_w.length > 0 ? body.output_w : null,
        query_vector: res.data.emb,
      })
      .then(response => {
        setBgs(response.data)
      })
  }
  return (
    <>
      <CenterAlignStack>
        <br />
        <Typography variant="h2">배경검색 테스트</Typography>

        <br />

        <Stack direction="row">
          <Box
            sx={{
              overflowWrap: 'break-word',
              m: 3,
              p: 1,
              border: '1px solid gray',
            }}
          >
            {Object.keys(body).map(k => (
              <Stack direction="row" key={k} sx={{ justifyContent: 'space-between' }}>
                <Typography sx={{ width: '120px', fontWeight: 500 }}>{k}</Typography>
                {/* <Typography sx={{ fontSize: '16px', fontWeight: 700, color: 'navy' }}>
                  {body[k]}
                </Typography> */}
                <TextField
                  sx={{
                    width: '100px',
                    '& .MuiInputBase-root.MuiOutlinedInput-root, & input': { p: '2px' },
                  }}
                  value={body[k]}
                  autoComplete="off"
                  onChange={e => {
                    const b = { ...body }
                    b[k] = e.target.value
                    setBody(b)
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      searchBackground()
                    }
                  }}
                ></TextField>
              </Stack>
            ))}
            <br />
            <br />
            {/* <Typography sx={{ background: 'pink' }}>{tag}</Typography> */}
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 500 }}>tag</Typography>
              <TextField
                sx={{ '& .MuiInputBase-root.MuiOutlinedInput-root, & input': { p: '2px' } }}
                value={tag}
                autoComplete="off"
                onChange={e => {
                  setTag(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    searchBackground()
                  }
                }}
              />
            </Stack>
            <br />
            <br />

            <Button variant="contained" onClick={searchBackground} sx={{ width: '100%' }}>
              검색
            </Button>
          </Box>
          <Grid container spacing={2} columns={5}>
            {bgs.map(b => (
              <Grid item key={b.id}>
                <Stack sx={{ width: '200px', fontSize: '' }} lg={3}>
                  <img src={getS3ImageSrc(b.s3_url)} />
                  <span
                    style={{
                      color: 'gray',
                      fontSize: '11px',
                      width: '100%',
                      overflow: 'hidden',
                      wordWrap: 'break-word',
                    }}
                  >
                    {b.id}
                  </span>
                  <span style={{ color: 'coral', fontWeight: 700 }}>{b.score}</span>
                  <span>{b.tag}</span>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </CenterAlignStack>
    </>
  )
}

export default TestPageee
