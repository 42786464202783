import { Box, Container, Typography } from '@mui/material'
import { English, Korean } from 'hooks/useLanguage'

const serviceH3Style = {
  fontSize: 20,
  marginTop: '32px',
  marginBottom: '15px',
  marginLeft: '14px',
  fontWeight: 600,
}
const serviceH4Style = {
  marginBottom: '8px',
  marginTop: '12px',
  fontSize: 17.5,
}
const servicePStyle = { paddingLeft: '10px', fontSize: 15.5 }
const serviceOlStyle = { listStyleType: 'decimal', marginLeft: '3rem' }

const serviceOlStyleEN = {
  listStyleType: 'decimal',
  '& li': { paddingBottom: '8px', paddingTop: '8px' },
}
const privacyH3Style = {
  marginTop: '32px',
  marginBottom: '5px',
  fontWeight: 600,
}
const privacyLiStyle1 = { listStyleType: 'decimal', paddingLeft: '20px' }
const privacyLiStyle2 = { listStyleType: 'decimal', paddingLeft: '18px' }

const nestedLiStyle = { marginLeft: '1.5rem' }
export default function Terms() {
  return (
    <>
      <Korean>
        <Container sx={{ mt: '3rem' }}>
          <h2
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              fontWeight: 800,
            }}
          >
            서비스 이용약관
          </h2>
          <h3 style={serviceH3Style}>제1장 총칙</h3>
          <h4 style={serviceH4Style}>제 1 조 목적 </h4>
          <p style={servicePStyle}>
            본 약관은 주식회사 드랩(이하 “회사”라 합니다)이 제공하는 제반 서비스 이용과 관련하여
            회사와 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다. 본
            약관은 이용자가 회사가 제공하는 스마트폰, 태블릿기기용 앱 등을 이용하는 경우에도 그
            성질에 반하지 않는 한 준용됩니다.
          </p>
          <h4 style={serviceH4Style}>제 2 조 용어의 정의</h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”이란 “회사”가 “서비스”를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를
              이용하여 거래할 수 있도록 설정한 가상의 영업장을 말합니다.{' '}
            </li>
            <li>“서비스”란 “플랫폼”에서 제공하는 콘텐츠 제작·가공 등 일체의 서비스를 말합니다. </li>
            <li>
              “콘텐츠 제작·가공”이란 “플랫폼”이 AI를 이용하여 이용자가 제공한 사진을 커머스 등의
              목적에 맞추어 최적화하는 업무를 말합니다.{' '}
            </li>
            <li>
              ”이용자”란 “회사”의 “플랫폼”에 접속하여 본 약관에 따라 “플랫폼”이 제공하는 “서비스”를
              이용하는 회원 및 비회원을 말합니다.{' '}
            </li>
            <li>
              “회원”이란 “플랫폼”에 회원가입을 하여 “이용자” 아이디(ID)를 부여받은 “이용자”로서,
              계속적으로 “플랫폼”이 제공하는 “서비스”를 이용할 수 있는 자를 말합니다.{' '}
            </li>
            <li>
              “비회원”이란 “회원”으로 가입하지 않고 “플랫폼”이 제공하는 서비스를 이용하는 자를
              말합니다.{' '}
            </li>
            <li>
              “아이디(ID)”란 “회원”의 식별과 서비스이용을 위하여 “회원”이 정하고 “플랫폼”이 승인하는
              문자 또는 숫자의 조합을 말합니다.{' '}
            </li>
            <li>
              “비밀번호(PASSWORD)”란 “회원”이 부여받은 “아이디”와 일치되는 “회원”임을 확인하고
              비밀보호를 위해 “회원”자신이 정한 문자, 숫자 또는 특수문자의 조합을 말합니다.{' '}
            </li>
            <li>
              “정기결제”는 이용자가 서비스를 결제한 기간 동안 이용할 수 있는 결제방식을 말합니다.
              “플랫폼”은 결제 주기에 따라 서비스 및 편의를 제공합니다.{' '}
            </li>
            <li>
              “건별결제”는 “정기결제”를 통하지 않고 서비스 이용시마다 결제하는 결제방식을
              의미합니다.{' '}
            </li>
            <li>
              “크레딧”은 “플랫폼”에서 “서비스”를 구매하고 그 대가를 지급하는데 사용할 수 있는
              선불전자지급수단을 말하며 아래와 같이 구분될 수 있습니다.
              <ul style={nestedLiStyle}>
                <li>유료 크레딧: “이용자”가 직접 구매한 크레딧</li>
                <li>무료 크레딧: “회사”가 무상으로 제공한 크레딧</li>
              </ul>
            </li>
            <li>
              전항 각 호에 해당하는 정의 이외의, 기타 용어의 정의에 대하여는 거래 관행 및 관계
              법령에 따릅니다.
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 3 조 약관 등의 명시와 설명 및 개정 </h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 본 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을
              처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 이메일 주소, 사업자등록번호,
              통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 “플랫폼”의 초기
              서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수
              있도록 할 수 있습니다.{' '}
            </li>
            <li>
              “플랫폼은 이용자가 약관에 동의하기에 앞서 약관에 정해져 있는 내용 중 청약철회,
              배송책임, 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면
              또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.{' '}
            </li>
            <li>
              본 약관의 내용은 서비스의 화면에 게시하거나 기타의 방법으로 공지하고, 본 약관에 동의한
              이용자 모두에게 그 효력이 발생합니다.{' '}
            </li>
            <li>
              ”플랫폼”은 필요한 경우 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에
              관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」,
              「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」,
              「소비자기본법」 등 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수
              있습니다. 본 약관이 변경되는 경우 ”플랫폼”은 변경사항을 시행일자 7일 전부터 이용자에게
              서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 피치 못하게 이용자에게
              불리한 내용으로 변경할 경우에는 최소한 그 시행일자 30일 전부터 제7조에 규정한 방법으로
              개별적으로 알려 드리겠습니다.{' '}
            </li>
            <li>
              ”플랫폼”은 전 항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일
              7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게
              고지하였음에도 이용자의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다.{' '}
            </li>
            <li>
              “플랫폼”은 본 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는
              계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로
              적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을
              제3항에 의한 개정약관의 공지기간 내에 “플랫폼”에 송신하여 “플랫폼”의 동의를 받은
              경우에는 개정약관 조항이 적용됩니다.{' '}
            </li>
            <li>
              이용자는 개정약관에 동의하지 않을 권리가 있으며 개정약관에 동의하지 않을 경우 서비스의
              이용을 중단하고 이용계약의 해지를 선택할 수 있습니다. 단, 개정약관에 동의하지 않을
              경우 개정약관의 적용을 받는 서비스의 이용이 불가능할 수 있습니다.{' '}
            </li>
            <li>
              본 약관은 이용자가 본 약관에 동의한 날로부터 이용계약의 해지 시까지 적용하는 것을
              원칙으로 합니다. 단, 본 약관의 일부 조항은 이용계약의 해지 후에도 유효하게 적용될 수
              있습니다.{' '}
            </li>
            <li>
              본 약관에서 정하지 아니한 사항 및 해석에 관하여는 약관의 규제 등에 관한 법률 및 기타
              서비스와 관련된 관계법령 또는 상관례에 따릅니다.{' '}
            </li>
          </ol>
          <h3 style={serviceH3Style}>제2장 이용계약 및 정보보호</h3>
          <h4 style={serviceH4Style}>제 4 조 회원가입</h4>
          <p style={servicePStyle}>
            회원으로 가입하고자 하는 이용자가 ”플랫폼”이 정한 가입 양식에 정해진 사항을 기입한 후 본
            약관 및 개인정보수집∙이용동의서 등 회원의 동의가 필요한 사항에 대하여 동의한다는
            의사표시를 하고, “플랫폼”이 이를 승낙함으로써 회원과 “플랫폼” 사이에 이용계약이
            체결됩니다.{' '}
          </p>
          <h4 style={serviceH4Style}>제 5 조 회원가입 제한 등</h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 아래 각 호의 경우에는 회원가입을 승낙하지 않거나 사후적으로 이용계약을 해지
              또는 제13조에 따른 제재를 할 수 있습니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>타인의 명의나 이메일 주소 등 타인의 개인정보를 이용한 경우 </li>
              <li>
                “플랫폼”로부터 서비스 이용정지 조치 등 제재를 받은 자가 그 조치기간에 서비스
                이용계약을 임의로 해지하고 재가입하고자 하는 경우{' '}
              </li>
              <li>회원가입시 양식에 기입한 내용에 허위, 기재누락, 오기가 있는 경우 </li>
              <li>부정한 용도로 서비스를 이용하고자 하는 경우 </li>
              <li>
                법령 위반 또는 본 약관에 위배되거나 기타 부당한 회원가입임이 “플랫폼”의 합리적인
                판단에 비추어 인정되는 경우{' '}
              </li>
              <li>만 14세 미만인 자가 법정대리인의 동의 없이 가입한 것으로 확인되는 경우 </li>
            </ol>

            <li>“플랫폼”은 아래 각 호의 경우에는 회원가입의 승낙을 유보할 수 있습니다. </li>
            <ol style={serviceOlStyle}>
              <li>서비스 제공 설비 용량에 여유가 없는 경우 </li>
              <li>서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우 </li>
              <li>기타 “플랫폼”이 재정적, 기술적으로 필요하다고 인정하는 경우 </li>
            </ol>
            <li>
              회원은 회원가입 시 등록한 사항에 변경이 있는 경우 상당한 기간 이내에 “플랫폼”에
              회원정보 수정 등의 방법으로 그 변경사항을 알려야 하며, 변경된 회원정보를 “플랫폼”에
              알리지 않아 발생하는 손해에 대한 책임은 이용자에게 있습니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 6 조 회원의 아이디 및 비밀번호에 대한 의무 </h4>
          <ol style={serviceOlStyle}>
            <li>아이디와 비밀번호에 관한 관리책임은 이용자에게 있습니다. </li>
            <li>이용자는 자신의 아이디 및 비밀번호를 제3자에 이용하게 해서는 안 됩니다. </li>
            <li>
              이용자가 자신의 아이디 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한
              경우에는 바로 “플랫폼”에 통보하고 “플랫폼”의 안내 또는 조치가 있는 경우에는 그에
              따라야 하며, “플랫폼”의 안내 또는 조치를 따르지 않음으로써 발생하는 손해에 대한 책임은
              이용자에게 있습니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 7 조 이용자에 대한 통지</h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”이 이용자에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 이용자가
              제공한 이메일주소, (휴대)전화번호, 로그인 시 알림창, 전자쪽지 등 서비스 내 각종 통지
              수단으로 할 수 있습니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자의 권리에 중대한 영향을 미치지 않는 사항에 대해서는 7일 이상
              “플랫폼”이 제공하는 서비스에 공지함으로써 제1항의 통지에 갈음할 수 있습니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 8 조 개인정보보호</h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 서비스 제공을 위하여 이용자의 개인정보를 수집할 수 있으며, 이 경우 필요한
              최소한의 범위 내에서 개인정보를 수집합니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자의 개인정보 보호를 위하여 [개인정보처리방침]을 수립하고,
              [개인정보처리방침] 및 개인정보 보호법, 정보통신망 이용촉진 및 정보보호 등에 관한
              법률(이하 ‘정보통신망법’) 등 관계 법령에 따라 개인정보를 보호하기 위하여 노력합니다.
              개인정보 보호와 관련한 상세한 사항은 [개인정보처리방침]을 참고하시기 바랍니다.{' '}
            </li>
          </ol>
          <h3 style={serviceH3Style}>제3장 서비스 이용</h3>
          <h4 style={serviceH4Style}>제 9 장 서비스의 제공 및 변경</h4>
          <ol style={serviceOlStyle}>
            <li>“플랫폼”은 이용자에게 아래와 같은 서비스를 제공합니다. </li>
            <ol style={serviceOlStyle}>
              <li>재화 또는 서비스에 대한 정보 제공 및 구매계약의 체결 </li>
              <li>구매계약이 체결된 서비스의 제공 </li>
              <li>기타 “플랫폼”이 정하는 업무 </li>
            </ol>

            <li>
              {' '}
              “플랫폼”은 재화 또는 서비스의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는
              계약에 의해 제공할 재화 또는 서비스의 내용을 변경할 수 있습니다. 이 경우에는 변경된
              재화 또는 서비스의 내용 및 제공일자를 명시하여 현재의 재화 또는 서비스의 내용을 게시한
              곳에 즉시 공지합니다.{' '}
            </li>
            <li>
              “플랫폼”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 또는 서비스의 품절
              또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 제7조의 방법으로 사전에
              공지하며, 서비스의 변경이 이용자에게 중대한 영향을 미치는 경우 이용자의 이메일 주소로
              메일을 발송하거나 휴대전화번호로 문자메시지를 전송하는 방법으로 개별적으로 공지합니다.{' '}
            </li>
            <li>
              전항의 경우 “플랫폼”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “플랫폼”이
              고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 10 조 서비스 중단</h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 서비스의 제공과 관련한 “플랫폼”의 정책 변경, 서비스의 기술적 사양의 변경
              필요성이 있는 경우 또는 다음 각호의 사유를 포함한 기타 상당한 사유가 있는 경우에는
              서비스의 전부 또는 일부의 내용을 중단할 수 있습니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>서비스용 설비의 유지·보수 등을 위한 정기 또는 임시 점검의 경우 </li>
              <li>
                정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는
                경우{' '}
              </li>
              <li>
                관계사와의 계약 종료, 정부의 명령/규제 등 “플랫폼”의 제반 사정으로 서비스의 전부
                또는 일부를 유지할 수 없는 경우{' '}
              </li>
              <li>기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우 </li>
            </ol>
            <li>
              이 경우 중단되는 서비스의 내용은 제7조의 방법으로 사전에 공지하며, 서비스의 변경이
              이용자에게 중대한 영향을 미치는 경우 이용자의 이메일 주소로 메일을 발송하거나
              휴대전화번호로 문자메시지를 전송하는 방법으로 개별적으로 공지합니다. 다만, “플랫폼”이
              예측할 수 없거나 통제할 수 없는 사유(“플랫폼”의 과실이 없는 디스크 내지 서버 장애,
              시스템 다운 등)로 인해 사전 통지 내지 공지가 불가능한 경우에는 사후에 공지할 수
              있습니다.{' '}
            </li>
            <li>
              “플랫폼”은 제1항의 사유로 서비스가 중단되는 경우 최대한 빠른 시일 내에 서비스를
              재개하도록 노력하겠습니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 11 조 “플랫폼“의 의무 </h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이
              정하는 바에 따라 지속적이고, 안정적으로 재화∙서비스를 제공하는데 최선을 다하여야
              합니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의
              개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.{' '}
            </li>
            <li>
              “플랫폼”이 상품이나 서비스에 대하여 「표시∙광고의 공정화에 관한 법률」 제3조 소정의
              부당한 표시∙광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 12 조 이용자의 의무</h4>

          <p style={servicePStyle}>
            이용자는 서비스를 이용하며 다음 각 호의 행위를 하여서는 아니되며, “플랫폼”은 해당
            이용자에 대하여 제13조에 따른 제재를 하거나 민형사상 책임을 물을 수 있습니다.
          </p>
          <ol style={{ ...serviceOlStyle, marginLeft: '40px' }}>
            <li>신청 또는 변경 시 허위내용의 등록 </li>
            <li>타인의 정보도용 </li>
            <li>“플랫폼”이 게시한 정보의 변경 </li>
            <li>“플랫폼”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시 </li>
            <li>“플랫폼”와 기타 제3자의 저작권 등 지식재산권에 대한 침해 </li>
            <li>“플랫폼” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 </li>
            <li>
              외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개
              또는 게시하는 행위{' '}
            </li>
            <li>“플랫폼”의 동의 없이 영리를 목적으로 서비스를 사용하는 행위 </li>
            <li>
              기타 법령, 선량한 풍속 기타 사회통념에 반하거나 본 약관 및 “플랫폼”의 정책을 위반하는
              행위{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 13 조 회원탈퇴 및 이용제한</h4>
          <ol style={serviceOlStyle}>
            <li>
              회원은 “플랫폼”에 언제든지 탈퇴를 요청할 수 있으며 “플랫폼”은 즉시 회원탈퇴를
              처리합니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자가 본 약관 및 “플랫폼”이 서비스의 운영을 위하여 정하는 정책에 따른
              의무를 위반하거나 서비스의 정상적인 운영을 방해하거나 다음 각 호에 해당하는 경우,
              사전통보 후 경고, 일시정지, 영구이용정지 등으로 서비스 이용을 제한할 수 있습니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>가입 신청 시에 허위 내용을 등록한 경우 </li>
              <li>
                “플랫폼”을 이용하여 구입한 재화 또는 서비스의 대금, 기타 “플랫폼” 이용에 관련하여
                회원이 부담하는 채무를 기일에 지급하지 않는 경우{' '}
              </li>
              <li>
                다른 사람의 “플랫폼” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를
                위협하는 경우{' '}
              </li>
              <li>
                “플랫폼”을 이용하여 법령 또는 본 약관이 금지하거나 공서양속에 반하는 행위를 하는
                경우{' '}
              </li>
            </ol>

            <li>
              “플랫폼”은 제2항에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 저작권법 및
              컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한
              불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한
              경우에는 즉시 영구이용정지를 하거나 이용계약을 해지할 수 있습니다.{' '}
            </li>
            <li>
              “플랫폼”이 회원 자격을 제한한 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그
              사유가 시정되지 아니하는 경우 “플랫폼”은 회원자격을 상실시킬 수 있습니다.{' '}
            </li>
            <li>
              “플랫폼”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를
              통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
              부여합니다.{' '}
            </li>
            <li>
              “플랫폼”은 회원이 계속해서 1년 이상 로그인을 하지 않는 경우 회원정보의 보호를 위하여
              이용을 제한할 수 있습니다.{' '}
            </li>
            <li>
              본 조에 따른 이용제한 제재를 받은 이용자가 이의를 제기하고자 할 경우 “플랫폼”의
              고객센터에 문의할 수 있습니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 14 조 저작권의 귀속 및 게시물 관련 </h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼“이 “서비스” 내에서 작성한 저작물에 대한 저작권 기타 지식재산권 일체는
              ”플랫폼“에 귀속합니다. 단 “플랫폼”이 “콘텐츠 제작·가공” 서비스를 통해 이용자의
              저작물을 가공 등 한 경우 해당 결과물의 저작권 기타 지식재산권 일체는 이용자에게
              귀속합니다.{' '}
            </li>
            <li>
              이용자는 “플랫폼”을 이용함으로써 얻은 정보 중 “플랫폼”에게 지식재산권이 귀속된 정보를
              “플랫폼”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로
              이용하거나 제3자에게 이용하게 하여서는 안 됩니다.{' '}
            </li>
            <li>
              “플랫폼”이 서비스를 통해 이용자가 게시물을 게재할 수 있는 서비스를 제공하는 경우, 해당
              게시물의 저작권은 이를 게시한 이용자에게 귀속됩니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자의 게시물에 대하여 서비스 내에서 이를 노출할 필요가 있는 경우,
              서비스의 개선 등을 위한 필요한 최소한의 범위에서만 저작권법 등 관련 법령의 제한에 따라
              이용할 수 있으며, 부득이 이러한 범위를 넘어 이용자의 게시물을 이용하고자 할 경우
              사전에 이용 목적에 대한 설명을 드린 후 동의를 얻어 이용할 수 있습니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자가 “콘텐츠 제작·가공” 서비스를 위해 제공한 사진 등 저작물과 해당
              저작물의 “콘텐츠 제작·가공” 서비스 결과물을 “플랫폼”의 서비스 개발 및 홍보 등 목적으로
              사용할 수 있습니다.{' '}
            </li>
            <li>
              이용자는 “플랫폼“의 서비스를 이용해 자신이 작성∙제공한 게시물에 관해, 이를
              수정∙격리∙삭제할 수 있는 권리를 포함하여,
              복제권∙공연권∙공중송신권∙전시권∙배포권∙대여권∙2차적저작물작성권 등 저작재산권 일체에
              대한 권리를 “플랫폼“ 에게 부여하는 것에 동의합니다. “플랫폼“과 서비스 제공에 대한
              계약상 관계가 있는 제3자에 대하여도 해당 서비스 제공의 목적으로 하는 경우 동일합니다.{' '}
            </li>
            <li>
              본 약관에서 “플랫폼“에 부여된 권리를 제외하고 이용자는 이용자의 게시물에 대한 모든
              권리를 보유합니다. 이용자의 동의 없이 “플랫폼“은 이용자의 게시물을 공개할 수 없습니다.
              단, 이용자가 공유를 목적으로 배포한 게시물 또는 이용자의 동의를 얻은 게시물에 한해서는
              이용자의 게시물을 이용, 전시, 배포 및 수정하는 것에 동의한 것으로 봅니다.{' '}
            </li>
            <li>
              이용자가 게시물이 법령에 위반되는 내용을 포함하는 경우, 권리자는 “플랫폼”에 관련
              법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며,
              “플랫폼”은 관련 법령에 따라 조치를 취할 수 있습니다.{' '}
            </li>
            <li>
              “플랫폼”은 권리자의 요청이 없는 경우라도 다음 각호의 사유의 경우에는 사전통지 없이
              해당 게시물에 대해 삭제 또는 임시조치 등을 취할 수 있습니다.
            </li>
            <ol style={serviceOlStyle}>
              <li>법령을 위반하거나 범죄 행위에 결부된다고 인정되는 경우 </li>
              <li>“플랫폼” 또는 제3자의 권리를 침해한다고 인정될 만한 사유가 있는 경우 </li>
              <li>기타 “플랫폼”의 정책에 위반되는 경우 </li>
            </ol>
          </ol>
          <h4 style={serviceH4Style}>제 15 조 유료서비스 관련 </h4>
          <ol style={serviceOlStyle}>
            <li>“플랫폼”은 서비스의 전부 또는 일부에 대하여 이를 유료로 제공할 수 있습니다. </li>
            <li>
              “플랫폼”이 유료서비스를 제공하는 경우 해당 서비스의 이용요금, 납부방법, 교환, 대금
              환불 등 절차에 관한 [유료서비스운영정책]을 별도로 제정하여 이를 이용자에게 제공할 수
              있습니다.{' '}
            </li>
          </ol>
          <h3 style={serviceH3Style}>제4장 콘텐츠 제작·가공 서비스(통신판매업) </h3>
          <h4 style={serviceH4Style}>제 16 조 크레딧</h4>
          <ol style={serviceOlStyle}>
            <li>
              “회원”은 “회사”가 정한 대가를 지불하고 “크레딧”을 취득할 수 있습니다. “회사”는
              “크레딧”의 구매 방법, 구매 단위, 결제 방법 등을 “서비스” 내에 게시합니다.
            </li>
            <li>
              “회사”는 다음 각 호의 방법으로 “크레딧”을 판매할 수 있으며, 판매 방식은 변경되거나
              추가될 수 있습니다.
            </li>
            <ol style={serviceOlStyle}>
              <li>
                건별 결제 상품: “회원”이 결제하는 금액에 따라 “크레딧”을 차등하여 지급하는 상품
              </li>
              <li>
                정기 결제 상품: “회원”이 1개월마다 “회원”이 선정한 결제수단을 통해 “크레딧”을
                정기적으로 구매할 것을 “회사”와 사전에 약정하고, “회사”는 정기적으로 “회원”과 약정한
                바에 따라 “크레딧”을 지급하는 상품
              </li>
            </ol>

            <li>
              “크레딧“의 부여 및 사용에 관한 상세한 사항은 “플랫폼“이 정한 정책에 따르며, “플랫폼“은
              서비스 페이지에 관련 링크 등을 통하여 이를 회원에게 안내합니다. 이는 모든 회원에게
              동일하게 적용되며, 휴면회원에 한하여 관계법령에 따라 고지, 처리 등이 제한될 수
              있습니다.
            </li>

            <li>무상으로 적립된 “크레딧”은 현금으로 전환하거나 환불하는 것이 불가합니다. </li>
            <li>
              다음 각 호에 해당하는 경우 해당 “크레딧”이 소멸되며, 어떠한 경우에도 복구되지
              않습니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>“크레딧”의 소멸시효가 경과하는 경우 </li>
              <li>
                서비스의 이용계약이 종료(회원탈퇴, 해지 포함)되는 경우 기 보유한 무상의 “크레딧”{' '}
              </li>
            </ol>

            <li>
              회원은 “크레딧”을 본인의 거래에 대해서만 사용할 수 있으며, 어떠한 경우라도 타인에게
              매매 또는 양도하거나, 실질적으로 매매 또는 양도와 동일하게 볼 수 있는 행위를 하여서는
              아니 됩니다.{' '}
            </li>
            <li>
              회원이 부당 또는 부정하게 “크레딧”을 취득한 경우 회원은 “크레딧“을 사용할 수 없으며
              “플랫폼“은 이를 회수할 수 있습니다.{' '}
            </li>
            <li>
              회원이 부정한 목적이나 용도로 “크레딧”을 사용하는 경우 “플랫폼“은 회원의 “크레딧”
              사용을 제한하거나 “크레딧”을 사용한 구매신청을 취소하거나, 제13조에 따른 제재를 하거나
              민형사상 책임을 물을 수 있습니다.{' '}
            </li>
            <li>
              회원이 재화 등 구매나 이벤트 등을 통하여 “플랫폼“으로부터 무상으로 제공받은 “크레딧”은
              “플랫폼“이 정하여 고지한 유효기간 내에만 이용이 가능합니다.
            </li>
            <li>
              “크레딧”의 유효기간은 기본적으로 아래와 같습니다. 단, “플랫폼“이 별도로 고지한
              유효기간이 있는 경우 그 유효기간에 따릅니다.
            </li>
            <ol style={serviceOlStyle}>
              <li>건별 결제로 충전된 유료 크레딧: 충전된 날부터 2년 </li>
              <li>“플랫폼”이 제공한 무료 크레딧: 제공된 날부터 2년 </li>
              <li>
                월정액 구독으로 충전된 유료 크레딧: 충전된 날부터 1개월. 단, 월정액 구독을 해지하지
                않을 경우 유효 기간은 자동으로 1개월씩 연장{' '}
              </li>
            </ol>

            <li>유효 기간이 빠른 “크레딧”이 먼저 사용/소멸됩니다.</li>
          </ol>
          <h4 style={serviceH4Style}>제 17 조 구매신청</h4>
          <ol style={serviceOlStyle}>
            <li>본 장의 규정 일체는 본 장에만 적용됩니다. </li>
            <li>
              회원은 “플랫폼”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “플랫폼”은
              회원이 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>구매하고자 하는 “서비스”의 검색 및 선택 </li>
              <li>회원이 제작·가공 요청하고자 하는 콘텐츠 등의 입력 </li>
              <li>
                약관내용, 청약철회권이 제한되는 서비스 등의 비용부담과 관련한 내용에 대한 확인{' '}
              </li>
              <li>본 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭) </li>
              <li>“서비스”의 구매신청 및 이에 관한 확인 또는 “플랫폼”의 확인에 대한 동의 </li>
              <li>결제방법의 선택 </li>
            </ol>
          </ol>
          <h4 style={serviceH4Style}>제 18 조 계약의 성립</h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 제17조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수
              있습니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>신청 내용에 허위, 기재누락, 오기가 있는 경우 </li>
              <li>
                기타 구매신청에 승낙하는 것이 “플랫폼” 기술상 현저히 지장이 있다고 판단하는 경우{' '}
              </li>
            </ol>
            <li>
              “플랫폼”의 승낙이 제20조 제1항의 수신확인통지형태로 회원에게 도달한 시점에 계약이
              성립한 것으로 봅니다.{' '}
            </li>
            <li>
              “플랫폼”의 승낙의 의사표시에는 회원의 구매 신청에 대한 확인 및 판매가능 여부,
              구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 19 조 지급방법</h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”이 제공하는 “서비스”에 대한 대금지급방법은 아래 방법으로 할 수 있습니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체 </li>
              <li>선불카드, 직불카드, 신용카드 등의 각종 카드 결제 </li>
              <li>온라인무통장입금 </li>

              <li>“플랫폼”과 계약을 맺었거나 “플랫폼”이 인정한 상품권에 의한 결제 </li>
              <li>기타 전자적 지급 방법에 의한 대금 지급 등 </li>
            </ol>
          </ol>
          <h4 style={serviceH4Style}>제 20 조 수신확인통지, 구매신청 변경 및 취소 </h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 회원의 구매신청이 있는 경우 수신확인통지를 합니다. 이 때 수신확인 통지는
              ‘서비스 구매 신청이 완료되었다’는 취지의 “플랫폼” 메시지를 통해 전달됩니다.{' '}
            </li>
            <li>
              수신확인통지를 받은 회원은 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후
              즉시 구매신청 변경 및 취소를 요청할 수 있고 “플랫폼”은 ”콘텐츠”의 제작·가공이 시작되기
              전에 회원의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미
              대금을 지불한 경우에는 제22조의 청약철회 등에 관한 규정에 따릅니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 21 조 환급</h4>
          <p style={servicePStyle}>
            “플랫폼”은 회원이 구매신청한 “서비스”를 제공을 할 수 없을 때에는 지체 없이 그 사유를
            회원에게 통지하고 사전에 서비스 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일
            이내에 환급하거나 환급에 필요한 조치를 취합니다.
          </p>
          <h4 style={serviceH4Style}>제 22 조 청약철회 등</h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”이 제공하는 “콘텐츠 제작·가공” 서비스는 구매 회원의 주문 의뢰 내용에 맞추어
              결과물을 제공하는 맞춤형 용역 상품으로서 가분하기 어려운 성격의 상품입니다. 따라서
              “플랫폼”이 “건별결제” 상품의 주문 의뢰에 따라 용역의 제공을 개시하기 전까지는 회원의
              청약 철회 또는 계약의 해제(이하 “청약철회 등”이라고 한다)가 가능하나, 용역의 제공이
              개시된 이후에는 전자상거래 등에서의 소비자보호에 관한 법률 제17조 제2항 제5호 및
              제6호에 의해 원칙적으로 회원의 청약철회 등이 제한됩니다.{' '}
            </li>
            <li>
              회원은 “콘텐츠”의 내용이 표시·광고 내용과 객관적으로 다르거나 계약내용과 객관적으로
              다르게 이행된 경우에는 당해 “콘텐츠”를 제공받은 날로부터 3월 이내, 그 사실을 안 날
              또는 알 수 있었던 날로부터 30일 이내에 예외적으로 청약철회 등을 할 수 있습니다.{' '}
            </li>
            <li>
              청약철회가 가능한 경우 회원은 구두 또는 서면(전자문서를 포함한다)으로 청약철회 등을 할
              수 있습니다.{' '}
            </li>
            <li>
              회원이 청약철회 등을 서면(전자문서를 포함한다)으로 하는 경우 그 의사표시가 기재된
              서면(전자문서를 포함한다)을 발송한 날에 그 효력이 발생합니다.{' '}
            </li>
            <li>
              환불 요청을 받은 “플랫폼”은 본 약관의 규정에 따라 환불을 진행합니다. 다만 “플랫폼”은
              다음 각 호의 어느 하나에 해당하는 경우 회원의 환불요청을 거부할 수 있습니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>“플랫폼”의 회원을 탈퇴한 후 환불을 요구할 경우 </li>
              <li>기타 정당한 환불 사유로 판명되지 않은 경우 </li>
            </ol>
          </ol>

          <h4 style={serviceH4Style}>제 23 조 크레딧의 환불</h4>
          <ol style={serviceOlStyle}>
            <li>
              회원”이 유료로 결제한 “크레딧”의 경우 기본적인 환불규정은 아래와 같습니다. 단, 특정
              상품에 한 해 “플랫폼“이 별도로 고지한 환불 규정이 있는 경우 그 규정을 따릅니다.
            </li>
            <ol style={serviceOlStyle}>
              <li>유료 결제하여 충전된 크레딧을 한 번도 사용하지 않은 경우 </li>
              <ol style={serviceOlStyle}>
                <li>결제일 기준 7일 이내에 결제 취소 요청시 결제 금액의 100% 환불 </li>

                <li>결제일 기준 7일 이후 결제 취소 요청 시 환불 불가 </li>
              </ol>
              <li>유료 결제하여 충전된 크레딧을 한 번이라도 사용한 경우 </li>
              <ol style={serviceOlStyle}>
                <li>크레딧을 한 번이라도 사용한 경우 환불 불가 </li>

                <li>단, “정기결제”의 경우 구체적인 환불규정은 제25조 7항의 내용을 따름 </li>
              </ol>
            </ol>
          </ol>

          <h4 style={serviceH4Style}>제 24 조 청약철회 등의 효과 </h4>
          <ol style={serviceOlStyle}>
            <li>
              청약철회 등을 한 경우 회원이 이미 제공받은 “콘텐츠 제작·가공” 결과물이 있다면 이를
              반환하여야 하며, 다운로드 받은 결과물이 있는 경우 이를 삭제하여야 합니다.{' '}
            </li>
            <li>
              “플랫폼”은 회원이 청약철회를 한 날 또는 회원이 콘텐츠 회원으로부터 “콘텐츠 제작·가공”
              결과물을 받은 경우에는 이를 반환 받은 날부터 3영업일 이내에 회원에게 지급받은 대금을
              환급합니다. 이 경우 “플랫폼”이 회원에게 환급을 지연한때에는 그 지연기간에 대하여
              「전자상거래 등에서의 소비자보호에 관한 법률 시행령」 제21조의3에서 정하는
              지연이자율을 곱하여 산정한 지연이자를 지급합니다. 단, “플랫폼”의 귀책사유 없이
              금융기관의 전산오류 내지는 마비 등 외부적 사정에 의하여 지연된 경우 “플랫폼”은 그
              책임을 부담하지 않습니다.{' '}
            </li>
            <li>
              “플랫폼”은 위 대금을 환급함에 있어서 회원이 신용카드 등의 결제수단으로 대금을 지급한
              때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지
              또는 취소하도록 요청합니다.{' '}
            </li>
            <li>
              “플랫폼”이 결제업자로부터 대금을 지급받은 때에는 지체없이 이를 결제업자에게 환급하고,
              그 사실을 회원에게 통지하여야 합니다. 이 경우 대금을 환급 받은 결제업자는 지체없이
              대금을 지급한 회원에게 이를 환급하거나 이에 필요한 조치를 취하여야 합니다.{' '}
            </li>
            <li>
              “플랫폼”은 반환된 결과물이 일부 사용 또는 일부 소비된 경우 그 사용 또는 일부 소비에
              의하여 회원이 얻은 이익 또는 용역의 제공에 소요된 비용에 상당하는 금액(“콘텐츠”의
              사용으로 인하여 소모성 부품의 재판매가 곤란하거나 재판매가격이 현저히 하락하는
              경우에는 당해 소모성 부품의 제공에 소요된 비용 또는 다수의 동일한 가분물로 구성된
              “콘텐츠”의 경우에는 회원의 일부소비로 인하여 소비된 부분의 제공에 소요된 비용)의
              범위내에서 회원에게 청구할 수 있습니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 25 조 정기결제 서비스의 특칙 </h4>
          <ol style={serviceOlStyle}>
            <li>
              정기결제 서비스는 회원과 “플랫폼“이 합의한 약정 조건에 따라 정기결제 서비스를 통한
              “콘텐츠 제작·가공” 용역의 공급 방식이 결정됩니다. 기본적으로는 최초 정기결제 서비스
              결제 동의 후 1개월 단위로 제공됩니다.{' '}
            </li>
            <li>
              회원이 결제에 사용하는 카드 한도 초과 등으로 “서비스”의 결제가 이루어지지 않을 경우,
              해당 회차의 “콘텐츠 제작·가공” 용역 공급이 이루어지지 않을 수 있으며, 위와 같은 상황이
              2회 이상 지속되는 경우 “플랫폼“은 회원과의 해당 정기결제 서비스 이용계약을 해지할 수
              있습니다.{' '}
            </li>
            <li>
              “플랫폼“이 “콘텐츠 제작·가공” 용역을 더이상 제공할 수 없거나 해당 “콘텐츠 제작·가공”
              용역을 정기결제 서비스로 제공할 수 없는 사유가 있을 경우, “플랫폼”은 해당 “콘텐츠
              제작·가공” 용역에 대한 정기결제 서비스 이용계약을 해지하거나 해당 회차의 “콘텐츠
              제작·가공” 용역을 공급하지 않을 수 있습니다.{' '}
            </li>
            <li>
              회원은 정규 영업시간 외에도 회원은 “플랫폼“에 통지함으로써 정기결제의 철회, 취소,
              청약철회, 계약의 해제⋅해지 등을 신청할 수 있습니다.{' '}
            </li>
            <li>
              “플랫폼”은 정기결제 철회, 취소, 계약의 해제⋅해지에 따른 환불대금을 다음 각 호에 따른
              방법으로 환불하며, 환불 방법을 해당 정기결제 서비스에서만 사용할 수 있는 포인트 등으로
              제한하는 등 회원의 선택권을 부당하게 제한하지 않습니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>
                관련 법령 등에 따라 이용일수 또는 이용회차에 비례하여 공정한 환불가격을 산정 가능한
                경우: 이용일수 또는 이용회차에 비례한 금액을 공제한 금액. 다만, 산정의 근거가 된
                법령 등에 이와 다른 규정이 있는 경우에는 이에 따릅니다.{' '}
              </li>
              <li>
                관련 법령 등에 따라 이용일수 또는 이용회차에 비례하여 공정한 환불가격을 산정 가능한
                경우: 이용일수 또는 이용회차에 비례한 금액을 공제한 금액. 다만, 산정의 근거가 된
                법령 등에 이와 다른 규정이 있는 경우에는 이에 따릅니다.{' '}
              </li>
            </ol>

            <li>
              서비스 이용의 제한에 대한 구체적인 기준은 본 약관 및 내부 운영정책에 따라 적용됩니다.{' '}
            </li>
            <li>
              “정기결제”의 경우 구체적인 환불규정은 아래와 같습니다(정기결제 가격은 프로모션에 따라
              달라질 수 있습니다).{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>가장 최신의 정기 결제로 충전된 크레딧을 한 번도 사용하지 않은 경우 </li>
              <ol style={serviceOlStyle}>
                <li>결제일 기준 7일 이내에 결제 취소 요청 시 결제 금액의 100% 환불</li>
                <li>결제일 기준 7일 이후 결제 취소 요청 시 환불 불가</li>
              </ol>
              <li>가장 최신의 정기 결제로 충전된 크레딧을 한 번이라도 사용한 경우 </li>
              <ol style={serviceOlStyle}>
                <li>환불 불가</li>
              </ol>
            </ol>
            {/* <table
        style={{
          border: '1px',
          borderStyle: 'groove',
          marginLeft: '-18px',
          marginTop: '10px',
          width: '100 %',
        }}
        border={1}
      >
        <tbody>
          <tr>
            <th style={{ width: '32%' }}>구분</th>
            <th>내용</th>
          </tr>
          <tr style={{ height: '120px' }}>
            <td style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              정기결제의 종료 시기 전 <br /> 잔여 크레딧에 대한 환불{' '}
            </td>
            <td style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              결제 금액에서 해지일까지 사용한 크레딧 수만큼 금액을 <br />
              공제한 후, 단, 계약체결일 또는 서비스 <br />
              이용가능일로부터 7일 이내에 해지를 요구하는 경우 그 <br />
              나머지 금액에 대해 환불수수료 10%를 공제한 후 환불.
            </td>
          </tr>
        </tbody>
      </table> */}
          </ol>
          <h4 style={serviceH4Style}>제 26 조 “플랫폼”의 면책 </h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는
              경우에는 관련 책임이 면제됩니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지
              않습니다.{' '}
            </li>
          </ol>
          <h3 style={serviceH3Style}>제5장 이용계약의 해지</h3>
          <h4 style={serviceH4Style}>제 27 조 이용계약 혜지</h4>
          <ol style={serviceOlStyle}>
            <li>
              이용자가 서비스의 이용을 더 이상 원하지 않을 경우 언제든지 이용계약의 해지 신청을 할
              수 있으며, “플랫폼”은 관련 법령이 정하는 바에 따라 이를 신속히 처리하도록 하겠습니다.
              단, 이용자가 법령을 위반하였거나 위반하였다고 판단되는 경우, “플랫폼” 또는 다른
              이용자와의 분쟁이 있는 경우에는 이용계약의 해지가 상당 기간 동안 제한될 수 있습니다.{' '}
            </li>
            <li>
              이용자가 이용계약을 해지할 경우 관련 법령에 따라 “플랫폼”이 이용자의 정보를 일정 기간
              보유하여야 하는 경우를 제외하고는 해지 즉시 이용자가 등록한 정보 및 게시물 등 모든
              데이터는 삭제됩니다. 다만, 다른 이용자의 정상적 서비스 이용을 위하여 필요한 범위 내의
              데이터, 법령상 “플랫폼”의 보관의무가 있는 데이터는 삭제되지 않습니다.{' '}
            </li>
          </ol>
          <h3 style={serviceH3Style}>제6장 기타</h3>
          <h4 style={serviceH4Style}>제 28 조 연결“플랫폼”과 피연결“플랫폼” 간의 관계 </h4>
          <ol style={serviceOlStyle}>
            <li>
              상위 “플랫폼”과 하위 “플랫폼”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및
              동화상, 각종 광고 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 “플랫폼”(웹
              사이트)이라고 하고 후자를 피연결 “플랫폼”(웹사이트)이라고 합니다.{' '}
            </li>
            <li>
              연결“플랫폼”은 피연결“플랫폼”이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는
              거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결“플랫폼”의 초기화면 또는 연결되는
              시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.{' '}
            </li>
            <li>
              “플랫폼”의 공식 사이트 이외의 피연결”플랫폼”에서는 “플랫폼”의 본 약관과
              개인정보처리방침이 적용되지 않습니다. 피연결”플랫폼”의 서비스 이용 및 재화 등 구매에
              관하여는 해당 피연결”플랫폼”의 약관 및 개인정보처리방침을 확인하여야 하며, 그렇지
              않음으로 인하여 발생한 책임은 이용자에게 있고 “플랫폼”은 이에 대하여 책임을 부담하지
              않습니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 29 조 개별서비스에 대한 이용 약관 </h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”이 서비스의 제공과 관련한 별도의 서비스를 제공하게 될 경우 해당 서비스를 위한
              약관 및 정책을 둘 수 있습니다.{' '}
            </li>
            <li>
              “플랫폼”이 전항에 따른 개별서비스를 이용약관을 둘 경우 이용자가 최초로 해당 서비스를
              이용할 때에 동의 절차를 거치도록 하며, 개별 서비스에 대한 이용약관이 본 약관에
              우선합니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 30 조 책임제한</h4>
          <p style={servicePStyle}>
            “플랫폼”은 이용자에게 본 약관에 명시되지 않는 사항에 대한 약정이나 보증을 하지 않습니다.
            단, 회사 및 “플랫폼”의 임직원, 대리인의 고의 또는 중대한 과실이 인정되는 경우에는
            그러하지 아니합니다.
          </p>
          <h4 style={serviceH4Style}>제 31 조 손해배상 </h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 “플랫폼”의 고의, 과실로 인하여 회원이 손해를 입게 될 경우 본 약관 및 관련
              법령에 따라 회원의 손해를 배상하겠습니다. 다만 “플랫폼”은 “플랫폼”의 고의, 과실 없이
              발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한 “플랫폼”은 법률상
              허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에
              대한 책임을 부담하지 않습니다.{' '}
            </li>
            <ol style={serviceOlStyle}>
              <li>천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해 </li>
              <li>이용자의 귀책사유로 서비스 이용에 장애가 발생한 경우 </li>
              <li>서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해 </li>
              <li>
                제3자가 불법적으로 “플랫폼”의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해{' '}
              </li>
              <li>
                제3자가 “플랫폼” 서버에 대한 전송 또는 “플랫폼” 서버로부터의 전송을 방해함으로써
                발생하는 손해{' '}
              </li>
              <li>제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해 </li>
              <li>
                전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를
                이용하는 과정에서 발생된 손해{' '}
              </li>
              <li>제10조 제1항에 따라 이용자 또는 제3자가 입은 손해 </li>
              <li>기타 “플랫폼”의 고의 또는 과실이 없는 사유로 인해 발생한 손해 </li>
            </ol>

            <li>
              “플랫폼”은 “플랫폼”의 고의, 과실이 없는 한 이용자 상호간 또는 이용자와 제3자 상호간에
              서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할
              책임도 없습니다.{' '}
            </li>
            <li>
              “플랫폼”은 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는
              보증을 하지 않으며 이로 인해 발생한 이용자의 손해에 대하여는 책임을 부담하지
              아니합니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해
              얻은 자료를 이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.{' '}
            </li>
            <li>
              이용자가 본 약관의 규정 또는 관련 법령을 위반하여 “플랫폼”에 손해가 발생한 경우
              “플랫폼”은 이용자에 대하여 손해배상을 청구할 수 있습니다. 이 경우 이용자는 고의,
              과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.{' '}
            </li>
            <li>
              “플랫폼”은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는
              경우에는 관련 책임이 면제됩니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지
              않습니다.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 32 조 분쟁해결 </h4>
          <ol style={serviceOlStyle}>
            <li>
              “플랫폼”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기
              위하여 피해보상처리기구를 설치∙운영합니다.{' '}
            </li>
            <li>
              “플랫폼”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다.
              다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해
              드립니다.{' '}
            </li>
            <li>
              “플랫폼”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는
              경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수
              있습니다.
            </li>
          </ol>
          <h4 style={serviceH4Style}>제 33 조 준거법 및 재판권 </h4>
          <ol style={serviceOlStyle}>
            <li>본 약관은 대한민국법령에 의하여 규정되고 이행됩니다. </li>
            <li>
              “플랫폼”은 이용자와의 분쟁이 발생할 경우 이의 해결을 위해 성실히 협의할 것이나,
              그럼에도 불구하고 분쟁이 해소되지 않으면 이에 관한 소송은 민사소송법상의 관할법원에
              제기할 수 있습니다.
            </li>
          </ol>
          <h4 style={{ ...serviceH4Style, textAlign: 'center' }}> 부칙 </h4>
          <p style={servicePStyle}>본 약관은 2022. 12. 12.부터 적용됩니다.</p>
        </Container>
      </Korean>
      <English>
        <Container sx={{ mt: '3rem', p: 0 }}>
          <h2
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              fontWeight: 800,
            }}
          >
            AGREEMENT TO OUR LEGAL TERMS
          </h2>
          <p style={{ textAlign: 'end' }}>Last updated December 12, 2022 </p>
          <br />
          <p style={servicePStyle}>
            We are Draph, Inc (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot;
            &quot;our&quot;), a company registered in South Korea at 180, Yeoksam-ro, Gangnam-gu,
            Seoul, Republic of Korea 06248. Our VAT number is 541-88-02453.
          </p>
          <br />
          <p style={servicePStyle}>
            We operate the website draph.art (the &quot;Site&quot;), as well as any other related
            products and services that refer or link to these legal terms (the &quot;Legal
            Terms&quot;) (collectively, the &quot;Services&quot;).
          </p>
          <br />
          <p style={servicePStyle}>
            You can contact us by phone at 82-70-8065-4414, email at hi@draph.ai, or by mail to 180,
            Yeoksam-ro, Gangnam-gu, Seoul, Republic of Korea 06248, South Korea.
          </p>
          <br />
          <p style={servicePStyle}>
            These Legal Terms constitute a legally binding agreement made between you, whether
            personally or on behalf of an entity (&quot;you&quot;), and Draph, Inc, concerning your
            access to and use of the Services. You agree that by accessing the Services, you have
            read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT
            AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
            SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </p>
          <br />
          <p style={servicePStyle}>
            We will provide you with prior notice of any scheduled changes to the Services you are
            using. The modified Legal Terms will become effective upon posting or notifying you by
            hi@draph.ai, as stated in the email message. By continuing to use the Services after the
            effective date of any changes, you agree to be bound by the modified terms.
          </p>
          <br />
          <p style={servicePStyle}>
            The Services are intended for users who are at least 18 years old. Persons under the age
            of 18 are not permitted to use or register for the Services.
          </p>
          <br />
          <p style={servicePStyle}>
            We recommend that you print a copy of these Legal Terms for your records.
          </p>
          <h2
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              fontWeight: 800,
              marginTop: '20px',
            }}
          >
            TABLE OF CONTENTS
          </h2>
          <Box component={'ol'} sx={{ ml: '3rem', ...serviceOlStyleEN }}>
            <li>OUR SERVICES</li>
            <li>INTELLECTUAL PROPERTY RIGHTS </li>
            <li>USER REPRESENTATIONS</li>
            <li>USER REGISTRATION</li>
            <li>PURCHASES AND PAYMENT</li>
            <li>CANCELLATION </li>
            <li>PROHIBITED ACTIVITIES</li>
            <li> USER GENERATED CONTRIBUTIONS </li>
            <li>CONTRIBUTION LICENSE</li>
            <li>SOCIAL MEDIA</li>
            <li>CONTRIBUTION LICENSE</li>
            <li>THIRD-PARTY WEBSITES AND CONTENT </li>
            <li>SERVICES MANAGEMENT </li>

            <li>PRIVACY POLICY </li>
            <li>TERM AND TERMINATION </li>
            <li>MODIFICATIONS AND INTERRUPTIONS </li>
            <li>GOVERNING LAW </li>
            <li> DISPUTE RESOLUTION </li>
            <li>CORRECTIONS </li>
            <li>DISCLAIMER </li>
            <li>LIMITATIONS OF LIABILITY </li>
            <li>INDEMNIFICATION </li>
            <li>USER DATA </li>
            <li>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES </li>
            <li> CALIFORNIA USERS AND RESIDENTS</li>
            <li>MISCELLANEOUS </li>
            <li>CONTACT US </li>
          </Box>
          <h4 style={serviceH4Style}>1. OUR SERVICES </h4>
          <p style={serviceOlStyleEN}>
            The information provided when using the Services is not intended for distribution to or
            use by any person or entity in any jurisdiction or country where such distribution or
            use would be contrary to law or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those persons who choose
            to access the Services from other locations do so on their own initiative and are solely
            responsible for compliance with local laws, if and to the extent local laws are
            applicable.{' '}
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            The Services are not tailored to comply with industry-specific regulations (Health
            Insurance Portability and Accountability Act (HIPAA), Federal Information Security
            Management Act (FISMA), etc.), so if your interactions would be subjected to such laws,
            you may not use the Services. You may not use the Services in a way that would violate
            the Gramm-Leach-Bliley Act (GLBA).{' '}
          </p>
          <h4 style={serviceH4Style}>2. INTELLECTUAL PROPERTY RIGHTS </h4>
          <h4>Our intellectual property </h4>
          <p style={serviceOlStyleEN}>
            We are the owner or the licensee of all intellectual property rights in our Services,
            including all source code, databases, functionality, software, website designs, audio,
            video, text, photographs, and graphics in the Services (collectively, the
            &quot;Content&quot;), as well as the trademarks, service marks, and logos contained
            therein (the &quot;Marks&quot;).
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Our Content and Marks are protected by copyright and trademark laws (and various other
            intellectual property rights and unfair competition laws) and treaties in the United
            States and around the world.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            The Content and Marks are provided in or through the Services &quot;AS IS&quot; for your
            personal, non-commercial use or internal business purpose only.
          </p>
          <br />
          <h4>Your use of our Services </h4>
          <p style={serviceOlStyleEN}>
            Subject to your compliance with these Legal Terms, including the &quot;PROHIBITED
            ACTIVITIES&quot; section below, we grant you a non-exclusive, non-transferable,
            revocable license to:
          </p>
          <ol>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              access the Services; and
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              download or print a copy of any portion of the Content to which you have properly
              gained access.{' '}
            </li>
            solely for your personal, non-commercial use or internal business purpose.
          </ol>
          <br />
          <p style={serviceOlStyleEN}>
            Except as set out in this section or elsewhere in our Legal Terms, no part of the
            Services and no Content or Marks may be copied, reproduced, aggregated, republished,
            uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed,
            sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            If you wish to make any use of the Services, Content, or Marks other than as set out in
            this section or elsewhere in our Legal Terms, please address your request to:
            hi@draph.ai. If we ever grant you the permission to post, reproduce, or publicly display
            any part of our Services or Content, you must identify us as the owners or licensors of
            the Services, Content, or Marks and ensure that any copyright or proprietary notice
            appears or is visible on posting, reproducing, or displaying our Content.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We reserve all rights not expressly granted to you in and to the Services, Content, and
            Marks.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Any breach of these Intellectual Property Rights will constitute a material breach of
            our Legal Terms and your right to use our Services will terminate immediately.
          </p>
          <br />
          <h4>Your submissions and contributions </h4>
          <p style={serviceOlStyleEN}>
            Please review this section and the &quot;PROHIBITED ACTIVITIES&quot; section carefully
            prior to using our Services to understand the (a) rights you give us and (b) obligations
            you have when you post or upload any content through the Services.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Submissions: By directly sending us any question, comment, suggestion, idea, feedback,
            or other information about the Services (&quot;Submissions&quot;), you agree to assign
            to us all intellectual property rights in such Submission. You agree that we shall own
            this Submission and be entitled to its unrestricted use and dissemination for any lawful
            purpose, commercial or otherwise, without acknowledgment or compensation to you.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Contributions: The Services may invite you to chat, contribute to, or participate in
            blogs, message boards, online forums, and other functionality during which you may
            create, submit, post, display, transmit, publish, distribute, or broadcast content and
            materials to us or through the Services, including but not limited to text, writings,
            video, audio, photographs, music, graphics, comments, reviews, rating suggestions,
            personal information, or other material (&quot;Contributions&quot;). Any Submission that
            is publicly posted shall also be treated as a Contribution.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Submissions: By directly sending us any question, comment, suggestion, idea, feedback,
            or other information about the Services (&quot;Submissions&quot;), you agree to assign
            to us all intellectual property rights in such Submission. You agree that we shall own
            this Submission and be entitled to its unrestricted use and dissemination for any lawful
            purpose, commercial or otherwise, without acknowledgment or compensation to you.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            You understand that Contributions may be viewable by other users of the Services and
            possibly through third-party websites.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            When you post Contributions, you grant us a license (including use of your name,
            trademarks, and logos): By posting any Contributions, you grant us an unrestricted,
            unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free,
            fully-paid, worldwide right, and license to: use, copy, reproduce, distribute, sell,
            resell, publish, broadcast, retitle, store, publicly perform, publicly display,
            reformat, translate, excerpt (in whole or in part), and exploit your Contributions
            (including, without limitation, your image, name, and voice) for any purpose,
            commercial, advertising, or otherwise, to prepare derivative works of, or incorporate
            into other works, your Contributions, and to sublicense the licenses granted in this
            section. Our use and distribution may occur in any media formats and through any media
            channels.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            This license includes our use of your name, company name, and franchise name, as
            applicable, and any of the trademarks, service marks, trade names, logos, and personal
            and commercial images you provide.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            You are responsible for what you post or upload: By sending us Submissions and/or
            posting Contributions through any part of the Services or making Contributions
            accessible through the Services by linking your account through the Services to any of
            your social networking accounts, you:
          </p>
          <ol>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              confirm that you have read and agree with our &quot;PROHIBITED ACTIVITIES&quot; and
              will not post, send, publish, upload, or transmit through the Services any Submission
              nor post any Contribution that is illegal, harassing, hateful, harmful, defamatory,
              obscene, bullying, abusive, discriminatory, threatening to any person or group,
              sexually explicit, false, inaccurate, deceitful, or misleading;
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              confirm that you have read and agree with our &quot;PROHIBITED ACTIVITIES&quot; and
              will not post, send, publish, upload, or transmit through the Services any Submission
              nor post any Contribution that is illegal, harassing, hateful, harmful, defamatory,
              obscene, bullying, abusive, discriminatory, threatening to any person or group,
              sexually explicit, false, inaccurate, deceitful, or misleading;
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              warrant that any such Submission and/or Contributions are original to you or that you
              have the necessary rights and licenses to submit such Submissions and/or Contributions
              and that you have full authority to grant us the above-mentioned rights in relation to
              your Submissions and/or Contributions; and
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              warrant and represent that your Submissions and/or Contributions do not constitute
              confidential information.
            </li>
            You are solely responsible for your Submissions and/or Contributions and you expressly
            agree to reimburse us for any and all losses that we may suffer because of your breach
            of (a) this section, (b) any third party’s intellectual property rights, or (c)
            applicable law.
          </ol>
          <br />
          <p style={serviceOlStyleEN}>
            <span style={{ fontWeight: 600 }}>We may remove or edit your Content:</span> Although we
            have no obligation to monitor any Contributions, we shall have the right to remove or
            edit any Contributions at any time without notice if in our reasonable opinion we
            consider such Contributions harmful or in breach of these Legal Terms. If we remove or
            edit any such Contributions, we may also suspend or disable your account and report you
            to the authorities.
          </p>
          <h4 style={serviceH4Style}>3. USER REPRESENTATIONS </h4>
          <p style={serviceOlStyleEN}>
            By using the Services, you represent and warrant that: (1) all registration information
            you submit will be true, accurate, current, and complete; (2) you will maintain the
            accuracy of such information and promptly update such registration information as
            necessary; (3) you have the legal capacity and you agree to comply with these Legal
            Terms; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not
            access the Services through automated or non-human means, whether through a bot, script
            or otherwise; (6) you will not use the Services for any illegal or unauthorized purpose;
            and (7) your use of the Services will not violate any applicable law or regulation.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            If you provide any information that is untrue, inaccurate, not current, or incomplete,
            we have the right to suspend or terminate your account and refuse any and all current or
            future use of the Services (or any portion thereof).
          </p>
          <h4 style={serviceH4Style}>4. USER REGISTRATION </h4>
          <p style={serviceOlStyleEN}>
            You may be required to register to use the Services. You agree to keep your password
            confidential and will be responsible for all use of your account and password. We
            reserve the right to remove, reclaim, or change a username you select if we determine,
            in our sole discretion, that such username is inappropriate, obscene, or otherwise
            objectionable.
          </p>
          <h4 style={serviceH4Style}>5. PURCHASES AND PAYMENT </h4>
          <ol>
            We accept the following forms of payment:
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>Visa</li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>Mastercard</li>
            You agree to provide current, complete, and accurate purchase and account information
            for all purchases made via the Services. You further agree to promptly update account
            and payment information, including email address, payment method, and payment card
            expiration date, so that we can complete your transactions and contact you as needed.
            Sales tax will be added to the price of purchases as deemed required by us. We may
            change prices at any time. All payments shall be in US dollars.
          </ol>
          <br />
          <p style={serviceOlStyleEN}>
            You agree to pay all charges at the prices then in effect for your purchases and any
            applicable shipping fees, and you authorize us to charge your chosen payment provider
            for any such amounts upon placing your order. If your order is subject to recurring
            charges, then you consent to our charging your payment method on a recurring basis
            without requiring your prior approval for each recurring charge, until such time as you
            cancel the applicable order. We reserve the right to correct any errors or mistakes in
            pricing, even if we have already requested or received payment.
          </p>
          <p style={serviceOlStyleEN}>
            We reserve the right to refuse any order placed through the Services. We may, in our
            sole discretion, limit or cancel quantities purchased per person, per household, or per
            order. These restrictions may include orders placed by or under the same customer
            account, the same payment method, and/or orders that use the same billing or shipping
            address. We reserve the right to limit or prohibit orders that, in our sole judgment,
            appear to be placed by dealers, resellers, or distributors.
          </p>
          <h4 style={serviceH4Style}>6. CANCELLATION </h4>
          <p style={serviceOlStyleEN}>
            You can cancel your subscription at any time by logging into your account. Your
            cancellation will take effect at the end of the current paid term.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            If you are unsatisfied with our Services, please email us at hi@draph.ai or call us at
            82-70-8065-4414.
          </p>
          <h4 style={serviceH4Style}>7. PROHIBITED ACTIVITIES </h4>
          <p style={serviceOlStyleEN}>
            You may not access or use the Services for any purpose other than that for which we make
            the Services available. The Services may not be used in connection with any commercial
            endeavors except those that are specifically endorsed or approved by us.
          </p>
          <ol>
            As a user of the Services, you agree not to:
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              {' '}
              Systematically retrieve data or other content from the Services to create or compile,
              directly or indirectly, a collection, compilation, database, or directory without
              written permission from us.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Trick, defraud, or mislead us and other users, especially in any attempt to learn
              sensitive account information such as user passwords.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Circumvent, disable, or otherwise interfere with security-related features of the
              Services, including features that prevent or restrict the use or copying of any
              Content or enforce limitations on the use of the Services and/or the Content contained
              therein.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              {' '}
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Use any information obtained from the Services in order to harass, abuse, or harm
              another person.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Make improper use of our support services or submit false reports of abuse or
              misconduct.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Use the Services in a manner inconsistent with any applicable laws or regulations.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Engage in unauthorized framing of or linking to the Services.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or
              other material, including excessive use of capital letters and spamming (continuous
              posting of repetitive text), that interferes with any party’s uninterrupted use and
              enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with
              the use, features, functions, operation, or maintenance of the Services.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Engage in any automated use of the system, such as using scripts to send comments or
              messages, or using any data mining, robots, or similar data gathering and extraction
              tools.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Delete the copyright or other proprietary rights notice from any Content.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Attempt to impersonate another user or person or use the username of another user.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Upload or transmit (or attempt to upload or to transmit) any material that acts as a
              passive or active information collection or transmission mechanism, including without
              limitation, clear graphics interchange formats (&quot;gifs&quot;), 1x1 pixels, web
              bugs, cookies, or other similar devices (sometimes referred to as &quot;spyware&quot;
              or &quot;passive collection mechanisms&quot; or &quot;pcms&quot;).{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Interfere with, disrupt, or create an undue burden on the Services or the networks or
              services connected to the Services.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Harass, annoy, intimidate, or threaten any of our employees or agents engaged in
              providing any portion of the Services to you.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              {' '}
              Attempt to bypass any measures of the Services designed to prevent or restrict access
              to the Services, or any portion of the Services.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Copy or adapt the Services software, including but not limited to Flash, PHP, HTML,
              JavaScript, or other code.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Except as permitted by applicable law, decipher, decompile, disassemble, or reverse
              engineer any of the software comprising or in any way making up a part of the
              Services.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Except as may be the result of standard search engine or Internet browser usage, use,
              launch, develop, or distribute any automated system, including without limitation, any
              spider, robot, cheat utility, scraper, or offline reader that accesses the Services,
              or use or launch any unauthorized script or other software.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              {' '}
              Use a buying agent or purchasing agent to make purchases on the Services.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Make any unauthorized use of the Services, including collecting usernames and/or email
              addresses of users by electronic or other means for the purpose of sending unsolicited
              email, or creating user accounts by automated means or under false pretenses.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Use the Services as part of any effort to compete with us or otherwise use the
              Services and/or the Content for any revenue-generating endeavor or commercial
              enterprise.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Sell or otherwise transfer your profile.{' '}
            </li>
          </ol>
          <h4 style={serviceH4Style}>8. USER GENERATED CONTRIBUTIONS </h4>
          <ol>
            The Services may invite you to chat, contribute to, or participate in blogs, message
            boards, online forums, and other functionality, and may provide you with the opportunity
            to create, submit, post, display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Services, including but not limited to text,
            writings, video, audio, photographs, graphics, comments, suggestions, or personal
            information or other material (collectively, &quot;Contributions&quot;). Contributions
            may be viewable by other users of the Services and through third-party websites. As
            such, any Contributions you transmit may be treated as non-confidential and
            non-proprietary. When you create or make available any Contributions, you thereby
            represent and warrant that:
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              The creation, distribution, transmission, public display, or performance, and the
              accessing, downloading, or copying of your Contributions do not and will not infringe
              the proprietary rights, including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third party.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              You are the creator and owner of or have the necessary licenses, rights, consents,
              releases, and permissions to use and to authorize us, the Services, and other users of
              the Services to use your Contributions in any manner contemplated by the Services and
              these Legal Terms.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              You have the written consent, release, and/or permission of each and every
              identifiable individual person in your Contributions to use the name or likeness of
              each and every such identifiable individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Services and these Legal Terms.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Your Contributions are not false, inaccurate, or misleading.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              {' '}
              Your Contributions are not unsolicited or unauthorized advertising, promotional
              materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of
              solicitation.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing,
              libelous, slanderous, or otherwise objectionable (as determined by us).{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              {' '}
              Your Contributions are not used to harass or threaten (in the legal sense of those
              terms) any other person and to promote violence against a specific person or class of
              people.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Your Contributions do not violate any applicable law, regulation, or rule.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Your Contributions do not violate the privacy or publicity rights of any third party.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              {' '}
              Your Contributions do not violate any applicable law concerning child pornography, or
              otherwise intended to protect the health or well-being of minors.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              Your Contributions do not include any offensive comments that are connected to race,
              national origin, gender, sexual preference, or physical handicap.{' '}
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              {' '}
              Your Contributions do not otherwise violate, or link to material that violates, any
              provision of these Legal Terms, or any applicable law or regulation.{' '}
            </li>
            Any use of the Services in violation of the foregoing violates these Legal Terms and may
            result in, among other things, termination or suspension of your rights to use the
            Services.
          </ol>
          <h4 style={serviceH4Style}>9. CONTRIBUTION LICENSE </h4>
          <p style={serviceOlStyleEN}>
            By posting your Contributions to any part of the Services or making Contributions
            accessible to the Services by linking your account from the Services to any of your
            social networking accounts, you automatically grant, and you represent and warrant that
            you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual,
            non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to
            host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle,
            archive, store, cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such Contributions (including,
            without limitation, your image and voice) for any purpose, commercial, advertising, or
            otherwise, and to prepare derivative works of, or incorporate into other works, such
            Contributions, and grant and authorize sublicenses of the foregoing. The use and
            distribution may occur in any media formats and through any media channels.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            {' '}
            This license will apply to any form, media, or technology now known or hereafter
            developed, and includes our use of your name, company name, and franchise name, as
            applicable, and any of the trademarks, service marks, trade names, logos, and personal
            and commercial images you provide. You waive all moral rights in your Contributions, and
            you warrant that moral rights have not otherwise been asserted in your Contributions.{' '}
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We do not assert any ownership over your Contributions. You retain full ownership of all
            of your Contributions and any intellectual property rights or other proprietary rights
            associated with your Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area on the Services. You
            are solely responsible for your Contributions to the Services and you expressly agree to
            exonerate us from any and all responsibility and to refrain from any legal action
            against us regarding your Contributions.{' '}
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We have the right, in our sole and absolute discretion, (1) to edit, redact, or
            otherwise change any Contributions; (2) to re-categorize any Contributions to place them
            in more appropriate locations on the Services; and (3) to pre-screen or delete any
            Contributions at any time and for any reason, without notice. We have no obligation to
            monitor your Contributions.{' '}
          </p>
          <h4 style={serviceH4Style}>10. SOCIAL MEDIA </h4>
          <p style={serviceOlStyleEN}>
            As part of the functionality of the Services, you may link your account with online
            accounts you have with third-party service providers (each such account, a
            &quot;Third-Party Account&quot;) by either: (1) providing your Third-Party Account login
            information through the Services; or (2) allowing us to access your Third-Party Account,
            as is permitted under the applicable terms and conditions that govern your use of each
            Third-Party Account. You represent and warrant that you are entitled to disclose your
            Third-Party Account login information to us and/or grant us access to your Third-Party
            Account, without breach by you of any of the terms and conditions that govern your use
            of the applicable Third-Party Account, and without obligating us to pay any fees or
            making us subject to any usage limitations imposed by the third-party service provider
            of the Third-Party Account. By granting us access to any Third-Party Accounts, you
            understand that (1) we may access, make available, and store (if applicable) any content
            that you have provided to and stored in your Third-Party Account (the &quot;Social
            Network Content&quot;) so that it is available on and through the Services via your
            account, including without limitation any friend lists and (2) we may submit to and
            receive from your Third-Party Account additional information to the extent you are
            notified when you link your account with the Third-Party Account. Depending on the
            Third-Party Accounts you choose and subject to the privacy settings that you have set in
            such Third-Party Accounts, personally identifiable information that you post to your
            Third-Party Accounts may be available on and through your account on the Services.
            Please note that if a Third-Party Account or associated service becomes unavailable or
            our access to such Third-Party Account is terminated by the third-party service
            provider, then Social Network Content may no longer be available on and through the
            Services. You will have the ability to disable the connection between your account on
            the Services and your Third-Party Accounts at any time. PLEASE NOTE THAT YOUR
            RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY
            ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
            PROVIDERS. We make no effort to review any Social Network Content for any purpose,
            including but not limited to, for accuracy, legality, or non-infringement, and we are
            not responsible for any Social Network Content. You acknowledge and agree that we may
            access your email address book associated with a Third-Party Account and your contacts
            list stored on your mobile device or tablet computer solely for purposes of identifying
            and informing you of those contacts who have also registered to use the Services. You
            can deactivate the connection between the Services and your Third-Party Account by
            contacting us using the contact information below or through your account settings (if
            applicable). We will attempt to delete any information stored on our servers that was
            obtained through such Third-Party Account, except the username and profile picture that
            become associated with your account.
          </p>
          <h4 style={serviceH4Style}>11. THIRD-PARTY WEBSITES AND CONTENT </h4>
          <p style={serviceOlStyleEN}>
            The Services may contain (or you may be sent via the Site) links to other websites
            (&quot;Third-Party Websites&quot;) as well as articles, photographs, text, graphics,
            pictures, designs, music, sound, video, information, applications, software, and other
            content or items belonging to or originating from third parties (&quot;Third-Party
            Content&quot;). Such Third-Party Websites and Third-Party Content are not investigated,
            monitored, or checked for accuracy, appropriateness, or completeness by us, and we are
            not responsible for any Third-Party Websites accessed through the Services or any
            Third-Party Content posted on, available through, or installed from the Services,
            including the content, accuracy, offensiveness, opinions, reliability, privacy
            practices, or other policies of or contained in the Third-Party Websites or the
            Third-Party Content. Inclusion of, linking to, or permitting the use or installation of
            any Third-Party Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Services and access the
            Third-Party Websites or to use or install any Third-Party Content, you do so at your own
            risk, and you should be aware these Legal Terms no longer govern. You should review the
            applicable terms and policies, including privacy and data gathering practices, of any
            website to which you navigate from the Services or relating to any applications you use
            or install from the Services. Any purchases you make through Third-Party Websites will
            be through other websites and from other companies, and we take no responsibility
            whatsoever in relation to such purchases which are exclusively between you and the
            applicable third party. You agree and acknowledge that we do not endorse the products or
            services offered on Third-Party Websites and you shall hold us blameless from any harm
            caused by your purchase of such products or services. Additionally, you shall hold us
            blameless from any losses sustained by you or harm caused to you relating to or
            resulting in any way from any Third-Party Content or any contact with Third-Party
            Websites.
          </p>
          <h4 style={serviceH4Style}>12. SERVICES MANAGEMENT </h4>
          <p style={serviceOlStyleEN}>
            We reserve the right, but not the obligation, to: (1) monitor the Services for
            violations of these Legal Terms; (2) take appropriate legal action against anyone who,
            in our sole discretion, violates the law or these Legal Terms, including without
            limitation, reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit the availability
            of, or disable (to the extent technologically feasible) any of your Contributions or any
            portion thereof; (4) in our sole discretion and without limitation, notice, or
            liability, to remove from the Services or otherwise disable all files and content that
            are excessive in size or are in any way burdensome to our systems; and (5) otherwise
            manage the Services in a manner designed to protect our rights and property and to
            facilitate the proper functioning of the Services.
          </p>
          <h4 style={serviceH4Style}>13. PRIVACY POLICY </h4>
          <p style={serviceOlStyleEN}>
            We care about data privacy and security. By using the Services, you agree to be bound by
            our Privacy Policy posted on the Services, which is incorporated into these Legal Terms.
            Please be advised the Services are hosted in South Korea. If you access the Services
            from any other region of the world with laws or other requirements governing personal
            data collection, use, or disclosure that differ from applicable laws in South Korea,
            then through your continued use of the Services, you are transferring your data to South
            Korea, and you expressly consent to have your data transferred to and processed in South
            Korea.
          </p>
          <h4 style={serviceH4Style}>14. TERM AND TERMINATION </h4>
          <p style={serviceOlStyleEN}>
            These Legal Terms shall remain in full force and effect while you use the Services.
            WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN
            OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
            SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
            NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
            COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
            TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
            DISCRETION.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            If we terminate or suspend your account for any reason, you are prohibited from
            registering and creating a new account under your name, a fake or borrowed name, or the
            name of any third party, even if you may be acting on behalf of the third party. In
            addition to terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing civil, criminal, and
            injunctive redress.
          </p>
          <h4 style={serviceH4Style}>15. MODIFICATIONS AND INTERRUPTIONS </h4>
          <p style={serviceOlStyleEN}>
            We reserve the right to change, modify, or remove the contents of the Services at any
            time or for any reason at our sole discretion without notice. However, we have no
            obligation to update any information on our Services. We will not be liable to you or
            any third party for any modification, price change, suspension, or discontinuance of the
            Services.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We cannot guarantee the Services will be available at all times. We may experience
            hardware, software, or other problems or need to perform maintenance related to the
            Services, resulting in interruptions, delays, or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the Services at any time or
            for any reason without notice to you. You agree that we have no liability whatsoever for
            any loss, damage, or inconvenience caused by your inability to access or use the
            Services during any downtime or discontinuance of the Services. Nothing in these Legal
            Terms will be construed to obligate us to maintain and support the Services or to supply
            any corrections, updates, or releases in connection therewith.
          </p>
          <h4 style={serviceH4Style}>16. GOVERNING LAW </h4>
          <p style={serviceOlStyleEN}>
            These Legal Terms and your use of the Services are governed by and construed in
            accordance with the laws of the State of California applicable to agreements made and to
            be entirely performed within the State of California, without regard to its conflict of
            law principles.
          </p>
          <h4 style={serviceH4Style}>17. DISPUTE RESOLUTION </h4>
          <br />
          <h4>Informal Negotiations </h4>
          <p style={serviceOlStyleEN}>
            To expedite resolution and control the cost of any dispute, controversy, or claim
            related to these Legal Terms (each a &quot;Dispute&quot; and collectively, the
            &quot;Disputes&quot;) brought by either you or us (individually, a &quot;Party&quot; and
            collectively, the &quot;Parties&quot;), the Parties agree to first attempt to negotiate
            any Dispute (except those Disputes expressly provided below) informally for at least
            thirty (30) days before initiating arbitration. Such informal negotiations commence upon
            written notice from one Party to the other Party.
          </p>
          <br />
          <h4> Binding Arbitration </h4>
          <p style={serviceOlStyleEN}>
            Any dispute arising out of or in connection with these Legal Terms, including any
            question regarding its existence, validity, or termination, shall be referred to and
            finally resolved by the International Commercial Arbitration Court under the European
            Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of
            this ICAC, which, as a result of referring to it, is considered as the part of this
            clause. The number of arbitrators shall be three (3). The seat, or legal place, or
            arbitration shall be Seoul, South Korea. The language of the proceedings shall be
            Korean. The governing law of these Legal Terms shall be substantive law of South Korea.
          </p>
          <br />
          <h4> Restrictions </h4>
          <p style={serviceOlStyleEN}>
            The Parties agree that any arbitration shall be limited to the Dispute between the
            Parties individually. To the full extent permitted by law, (a) no arbitration shall be
            joined with any other proceeding; (b) there is no right or authority for any Dispute to
            be arbitrated on a class-action basis or to utilize class action procedures; and (c)
            there is no right or authority for any Dispute to be brought in a purported
            representative capacity on behalf of the general public or any other persons.
          </p>
          <br />
          <h4> Exceptions to Informal Negotiations and Arbitration </h4>
          <p style={serviceOlStyleEN}>
            The Parties agree that the following Disputes are not subject to the above provisions
            concerning informal negotiations binding arbitration: (a) any Disputes seeking to
            enforce or protect, or concerning the validity of, any of the intellectual property
            rights of a Party; (b) any Dispute related to, or arising from, allegations of theft,
            piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive
            relief. If this provision is found to be illegal or unenforceable, then neither Party
            will elect to arbitrate any Dispute falling within that portion of this provision found
            to be illegal or unenforceable and such Dispute shall be decided by a court of competent
            jurisdiction within the courts listed for jurisdiction above, and the Parties agree to
            submit to the personal jurisdiction of that court.
          </p>{' '}
          <h4 style={serviceH4Style}>18. CORRECTIONS </h4>
          <p style={serviceOlStyleEN}>
            There may be information on the Services that contains typographical errors,
            inaccuracies, or omissions, including descriptions, pricing, availability, and various
            other information. We reserve the right to correct any errors, inaccuracies, or
            omissions and to change or update the information on the Services at any time, without
            prior notice.
          </p>
          <h4 style={serviceH4Style}>19. DISCLAIMER </h4>
          <p style={serviceOlStyleEN}>
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF
            THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
            DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR
            USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
            REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES&lsquo; CONTENT OR THE
            CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME
            NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT
            AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
            RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR
            USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
            INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
            THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED
            TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
            CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
            USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE
            DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
            ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR
            ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE
            WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
            BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR
            BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
          </p>
          <h4 style={serviceH4Style}>20. LIMITATIONS OF LIABILITY </h4>
          <p style={serviceOlStyleEN}>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD
            PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
            PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
            ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
            LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL
            AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING
            THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $100.00 USD. CERTAIN US
            STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
            EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF
            THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
            RIGHTS.
          </p>
          <h4 style={serviceH4Style}>21. INDEMNIFICATION </h4>
          <p style={serviceOlStyleEN}>
            You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
            affiliates, and all of our respective officers, agents, partners, and employees, from
            and against any loss, damage, liability, claim, or demand, including reasonable
            attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your
            Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach
            of your representations and warranties set forth in these Legal Terms; (5) your
            violation of the rights of a third party, including but not limited to intellectual
            property rights; or (6) any overt harmful act toward any other user of the Services with
            whom you connected via the Services. Notwithstanding the foregoing, we reserve the
            right, at your expense, to assume the exclusive defense and control of any matter for
            which you are required to indemnify us, and you agree to cooperate, at your expense,
            with our defense of such claims. We will use reasonable efforts to notify you of any
            such claim, action, or proceeding which is subject to this indemnification upon becoming
            aware of it.
          </p>
          <h4 style={serviceH4Style}>22. USER DATA </h4>
          <p style={serviceOlStyleEN}>
            We will maintain certain data that you transmit to the Services for the purpose of
            managing the performance of the Services, as well as data relating to your use of the
            Services. Although we perform regular routine backups of data, you are solely
            responsible for all data that you transmit or that relates to any activity you have
            undertaken using the Services. You agree that we shall have no liability to you for any
            loss or corruption of any such data, and you hereby waive any right of action against us
            arising from any such loss or corruption of such data.
          </p>
          <h4 style={serviceH4Style}>
            23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES{' '}
          </h4>
          <p style={serviceOlStyleEN}>
            Visiting the Services, sending us emails, and completing online forms constitute
            electronic communications. You consent to receive electronic communications, and you
            agree that all agreements, notices, disclosures, and other communications we provide to
            you electronically, via email and on the Services, satisfy any legal requirement that
            such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
            CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
            AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby
            waive any rights or requirements under any statutes, regulations, rules, ordinances, or
            other laws in any jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting of credits by any
            means other than electronic means.
          </p>
          <h4 style={serviceH4Style}>24. CALIFORNIA USERS AND RESIDENTS </h4>
          <p style={serviceOlStyleEN}>
            If any complaint with us is not satisfactorily resolved, you can contact the Complaint
            Assistance Unit of the Division of Consumer Services of the California Department of
            Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento,
            California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
          </p>
          <h4 style={serviceH4Style}>25. MISCELLANEOUS </h4>
          <p style={serviceOlStyleEN}>
            These Legal Terms and any policies or operating rules posted by us on the Services or in
            respect to the Services constitute the entire agreement and understanding between you
            and us. Our failure to exercise or enforce any right or provision of these Legal Terms
            shall not operate as a waiver of such right or provision. These Legal Terms operate to
            the fullest extent permissible by law. We may assign any or all of our rights and
            obligations to others at any time. We shall not be responsible or liable for any loss,
            damage, delay, or failure to act caused by any cause beyond our reasonable control. If
            any provision or part of a provision of these Legal Terms is determined to be unlawful,
            void, or unenforceable, that provision or part of the provision is deemed severable from
            these Legal Terms and does not affect the validity and enforceability of any remaining
            provisions. There is no joint venture, partnership, employment or agency relationship
            created between you and us as a result of these Legal Terms or use of the Services. You
            agree that these Legal Terms will not be construed against us by virtue of having
            drafted them. You hereby waive any and all defenses you may have based on the electronic
            form of these Legal Terms and the lack of signing by the parties hereto to execute these
            Legal Terms.
          </p>
          <h4 style={serviceH4Style}>26. CONTACT US </h4>
          <p style={serviceOlStyleEN}>
            In order to resolve a complaint regarding the Services or to receive further information
            regarding use of the Services, please contact us at:
            <ol style={{ listStyleType: 'none' }}>
              <li>Draph, Inc </li>
              <li>180, Yeoksam-ro, Gangnam-gu </li>
              <li>Seoul, Republic of Korea 06248 </li>
              <li>South Korea </li>
              <li> Phone: 82-70-8065-4414 </li>
              <li> hi@draph.ai </li>
            </ol>
          </p>
        </Container>
      </English>
    </>
  )
}
