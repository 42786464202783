import {
  Container,
  Grid,
  Pagination,
  Typography,
  Paper,
  Box,
  Divider,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  styled,
} from '@mui/material'
import { useTheme } from '@mui/system'
import { userAtom } from 'atoms'
import {
  CenterAlignBox,
  FlexBasis,
  CenterAlignStack,
  CustomPagination,
  CustomAccordionSummary,
  CustomAccordion,
  CustomAccordionDetails,
  SingleImageDialog,
} from 'components'
import { YouTubeVideo } from 'components/YouTubeVideo'
import { GuideImageDialog } from 'components/portfolio/SingleImageDialog'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import i18n from 'i18n'
import { INFO_PERCENTAGE_WIDHT } from 'layouts/InfoLayout'
import { DRAWER_WIDTH_PERCENTAGE } from 'layouts/InfoSideBar'
import { MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { DropdownArrowIcon } from 'theme/icon'
import { isKo } from 'utils/common'

const postStyle = { fontSize: '1.5rem', fontWeight: 400 }

const DetailTypoStyle = styled(Typography)(({ theme }) => ({
  alignSelf: 'flex-start',
}))

const olStyle = {
  marginLeft: '1.8rem',
}

export default function Guide() {
  const theme = useTheme()
  const { id } = useRecoilValue(userAtom)
  const [open, setOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const { t, i18n } = useTranslation()

  const headerStyle = { fontSize: '1.7rem', fontWeight: 700 }

  const guideImages = {
    guide1: [
      {
        detail: (
          <DetailTypoStyle>
            상품 이미지를 업로드하면 AI가 자동으로 상품을 분류하여 고품질의 추천 배경을 생성합니다.
            더 세밀한 작업을 위해 사용자가 직접 상품 카테고리 분류, 원하는 배경 테마 설정, 이미지
            사이즈 조정, 기타 옵션 조정을 할 수 있습니다. 또한, 결과물이 마음에 들지 않을 경우
            재생성을 할 수 있으며, 재생성은 횟수 제한없이 무료로 가능합니다.{' '}
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/1/1-1.jpg',
      },
      { image: '/static/images/guide/1/1-2.jpg' },
      { image: '/static/images/guide/1/1-3.jpg' },
      { image: '/static/images/guide/1/1-4.jpg' },
      { image: '/static/images/guide/1/1-5.jpg' },
      { image: '/static/images/guide/1/1-6.jpg' },
      { image: '/static/images/guide/1/1-7.jpg' },
      { image: '/static/images/guide/1/1-8.jpg' },
      { image: '/static/images/guide/1/1-9.jpg' },
      { image: '/static/images/guide/1/1-10.jpg' },
      { image: '/static/images/guide/1/1-11.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            상품 사진 생성 완료 후 생성된 사진을 클릭하면 간단한 이미지 편집이 가능합니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/1/1-12.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            상품 사이즈 수정 및 재배치, 이미지 자르기, GIF 만들기 기능 등이 제공됩니다. (단, 생성된
            사진의 배경 종류에 따라 일부 기능이 지원되지 않을 수 있습니다.) 생성된 사진을 클릭하면
            다양한 이미지 편집 옵션이 나타납니다. <br />
            <br />
            <span style={{ fontWeight: 600 }}>&lt;상품 사이즈 수정하기&gt;</span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/1/1-13.jpg',
      },
      { image: '/static/images/guide/1/1-14.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            [상품 사이즈 수정]을 누르면 박스가 나타납니다. 박스를 움직여 상품의 크기와 위치를 조절한
            후 수정하기를 누르면 상품 사이즈 수정이 완료됩니다. <br />
            <br />
            <span style={{ fontWeight: 600 }}>&lt;이미지 자르기&gt;</span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/1/1-15.jpg',
      },
      { image: '/static/images/guide/1/1-16.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            [이미지 자르기]를 누르면 박스가 나타납니다. 박스를 움직여 사진을 원하는 사이즈로 설정 후
            [확인]을 누르면 이미지 자르기가 완료됩니다. <br />
            <br />
            <span style={{ fontWeight: 600 }}>&lt;GIF 생성 기능 사용하기&gt;</span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/1/1-17.jpg',
      },
    ],
    guide2: [
      {
        detail: (
          <DetailTypoStyle>누끼따기는 사진의 기존 배경을 제거해주는 기능입니다.</DetailTypoStyle>
        ),
        image: '/static/images/guide/2/2-1.jpg',
      },
      { image: '/static/images/guide/2/2-2.jpg' },
      { image: '/static/images/guide/2/2-3.jpg' },
      { image: '/static/images/guide/2/2-4.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            <span style={{ fontWeight: 600 }}>&lt;누끼수정&gt;</span>
            <br />
            결과물 생성 후에도 [누끼 수정] 기능을 통해 더욱 완성도 있는 상품 사진을 얻을 수
            있습니다.{' '}
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/2/2-5.jpg',
      },
      { image: '/static/images/guide/2/2-6.jpg' },
      {
        detail: (
          <>
            {' '}
            <ol style={{ paddingLeft: '4rem' }}>
              <li>이미지 확대/축소: 이미지를 확대하거나 축소할 수 있습니다. </li>
              <li>
                브러쉬/지우개 선택: 지우개를 선택하면 이미지에 그린 브러쉬를 지울 수 있습니다. 해당
                아이콘을 다시 누르면 브러쉬 기능으로 되돌아갑니다.
              </li>
              <li>제거: 남아있는 영역(흰색)을 추가로 지울 수 있는 기능입니다. </li>
              <li>추가: 지워진 영역(검은색)을 복원할 수 있는 기능입니다. </li>
              <li>
                브러쉬/지우개 사이즈: 브러쉬/지우개 사이즈를 크게 혹은 작게 조정할 수 있습니다.{' '}
              </li>
              <li>실행 취소: 이전 작업을 취소합니다. </li>
              <li>다시 실행: 이전 작업을 다시 실행합니다. </li>
              <li>초기화: 작업한 모든 것이 초기화되며, 원본 누끼 이미지로 돌아갑니다. </li>
              <li>다듬기: 브러쉬로 그린 영역이 그대로 이미지에 반영됩니다. </li>
              <li>
                원터치 수정: 브러쉬로 그린 영역을 AI가 자동으로 자연스럽게 이미지에 반영합니다.{' '}
              </li>
            </ol>{' '}
            <br />
            <br />{' '}
            <span style={{ fontWeight: 600 }}>
              &lt;누끼 수정 기능으로 남아있는 이미지 제거하기&gt;
            </span>
            <br />
          </>
        ),
        image: '/static/images/guide/2/2-7.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            [제거] 버튼을 클릭하면 브러쉬 색깔이 빨간색으로 변경됩니다. 브러쉬 사이즈를 조절한 후,
            사진에서 추가로 제거하고 싶은 영역을 브러쉬로 선택합니다.{' '}
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/2/2-8.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            [다듬기] 또는 [원터치 수정] 버튼을 누르면 빨간색 브러쉬로 선택한 영역이 추가로
            제거됩니다.
            <br />
            <br />{' '}
            <span style={{ fontWeight: 600 }}>
              &lt;누끼 수정 기능으로 제거된 이미지 복원(추가) 하기&gt;
            </span>{' '}
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/2/2-9.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            [추가] 버튼을 클릭하면 브러쉬 색깔이 녹색으로 변경됩니다. 브러쉬 사이즈를 조절한 후,
            사진에서 복원하고 싶은 영역을 브러쉬로 선택합니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/2/2-10.jpg',
      },
    ],

    guide3: [
      '/static/images/guide/3/3-1.jpg',
      '/static/images/guide/3/3-2.jpg',
      '/static/images/guide/3/3-3.jpg',
      '/static/images/guide/3/3-4.jpg',
      '/static/images/guide/3/3-5.jpg',
    ],
    guide4: [
      {
        detail: (
          <DetailTypoStyle>
            포트폴리오는 배경 테마, 사진 사이즈 등의 생성 옵션이 동일한 상태에서 상품 사진을
            다량으로 생성할 때 사용하기 적합한 기능입니다. 특정 상품 카테고리에 어울리는 배경 테마와
            생성 설정을 하나의 포트폴리오로 저장해 놓으면, 상품 사진을 생성할 때마다 설정을 변경할
            필요가 없어 상품 사진 생성을 쉽고 간편하게 할 수 있습니다. (포트폴리오 설정은 언제든지
            변경 가능합니다)
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/4/4-1.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            * [미분류] 포트폴리오는 모든 사용자에게 자동으로 만들어지는 포트폴리오로, [상품 사진]
            메뉴에서 생성한 상품 사진들을 해당 포트폴리오에서 볼 수 있습니다. [미분류] 포트폴리오는
            포트폴리오 설정을 변경할 수 없습니다.{' '}
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/4/4-2.jpg',
      },
      { image: '/static/images/guide/4/4-3.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            *Tip. 포트폴리오의 제목을 해당 포트폴리오의 컨셉과 상품 종류에 맞게 설정하면 좀 더
            편리하게 포트폴리오를 관리할 수 있습니다. 예시) 봄 배경 테마로 설정한 포트폴리오의 제목:
            봄, 의류만 모아놓은 포트폴리오의 제목: 의류
            <br /> <br />
            제목 설정이 끝나면 상품 사진을 생성할 때와 동일한 설정 과정을 거치게 됩니다.{' '}
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/4/4-4.jpg',
      },
      { image: '/static/images/guide/4/4-5.jpg' },
      { image: '/static/images/guide/4/4-6.jpg' },
      { image: '/static/images/guide/4/4-7.jpg' },
      { image: '/static/images/guide/4/4-8.jpg' },
      { image: '/static/images/guide/4/4-9.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            이렇게 만들어진 포트폴리오를 통해 동일한 컨셉의 상품 사진을 번거롭지 않게 반복 생산할 수
            있습니다. 또한 식품, 패션, 전자기기 등 상품 카테고리 별로 생성 결과물을 정리하여 볼 수
            있습니다.
            <br />
            <br />{' '}
            <span style={{ fontWeight: 600 }}>&lt;포트폴리오 다운로드, 복사, 삭제하기&gt;</span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/4/4-10.jpg',
      },
      {
        detail: (
          <>
            <DetailTypoStyle>
              생성된 포트폴리오는 다운로드, 복사, 삭제가 가능합니다.{' '}
            </DetailTypoStyle>
            <ol style={{ paddingLeft: '4rem' }}>
              <li>
                다운로드: 해당 포트폴리오 안에 있는 모든 생성 결과물을 한꺼번에 다운받을 수
                있습니다.
              </li>
              <li>
                복사: 버튼을 누르면 해당 포트폴리오와 동일한 설정의 포트폴리오가 추가로 생성됩니다.
                (단, 기존 포트폴리오에 들어 있는 생성 이미지는 복사되지 않습니다.)
              </li>
              <li> 삭제: 해당 포트폴리오를 삭제할 수 있습니다.</li>
            </ol>
            <br />

            <DetailTypoStyle>
              {' '}
              <span style={{ fontWeight: 600 }}>&lt;포트폴리오에서 상품 사진 생성&gt;</span>
              <br /> 포트폴리오에서 상품 사진을 생성하는 과정은 1. 상품 사진 생성 과정과 동일합니다.{' '}
            </DetailTypoStyle>
          </>
        ),
        image: '/static/images/guide/4/4-11.jpg',
      },
      { image: '/static/images/guide/4/4-12.jpg' },
      { image: '/static/images/guide/4/4-13.jpg' },
      { image: '/static/images/guide/4/4-14.jpg' },
      { image: '/static/images/guide/4/4-15.jpg' },
      { image: '/static/images/guide/4/4-16.jpg' },
      { image: '/static/images/guide/4/4-17.jpg' },
    ],
    guide5: [
      {
        detail: (
          <DetailTypoStyle>
            모델 생성은 AI가 자동으로 마네킹 사진을 사람 모델로 바꾸어 주는 기능입니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/5/5-1.jpg',
      },
      { image: '/static/images/guide/5/5-2.jpg' },
      { image: '/static/images/guide/5/5-3.jpg' },
      {
        detail: (
          <>
            <DetailTypoStyle>
              원하는 마네킹 이미지를 업로드하면 위와 같은 창이 보입니다.
            </DetailTypoStyle>

            <ol style={{ paddingLeft: '4rem' }}>
              <li>
                유지할 옷(제품) 영역 선택: 마우스 커서로 유지하고자 하는 부분을 클릭하면 선택된
                영역이 녹색으로 표시됩니다.{' '}
              </li>
              <li>배경 입력: 원하는 배경의 느낌을 키워드로 입력합니다. </li>
              <li>모델 성별 선택: 여성/남성 중 하나를 선택합니다. </li>
            </ol>
          </>
        ),
        image: '/static/images/guide/5/5-4.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            모델 설정을 마치면 위와 같이 화면이 변합니다. [완료]를 누르면 모델 생성이 시작됩니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/5/5-5.jpg',
      },
      { image: '/static/images/guide/5/5-6.jpg' },
    ],
    guide6: [
      '/static/images/guide/6/6-1.jpg',
      '/static/images/guide/6/6-2.jpg',
      '/static/images/guide/6/6-3.jpg',
      '/static/images/guide/6/6-4.jpg',
      '/static/images/guide/6/6-5.jpg',
    ],

    guide7: [
      {
        detail: (
          <DetailTypoStyle>
            드랩아트는 사용자가 갖고 있는 배경 이미지로도 상품 사진을 생성할 수 있습니다. 관련
            기능은 [마이페이지]-[내 이미지]-[배경] 메뉴에서 사용할 수 있습니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-1.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            사용자는 드랩아트에서 제공하는 배경을 선호/고정 배경으로 추가하거나 원하는 배경 이미지를
            직접 업로드하여 상품 사진 생성에 사용할 수 있습니다. <br />
            <br />
            <span style={{ fontWeight: 600 }}>
              1) 드랩아트에서 제공하는 배경 중 원하는 이미지를 선호/고정 배경으로 추가해서 사용하는
              방법
            </span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-2.jpg',
      },
      {
        detail: <DetailTypoStyle>생성 이미지의 썸네일을 선택합니다.</DetailTypoStyle>,
        image: '/static/images/guide/7/7-3.jpg',
      },
      { image: '/static/images/guide/7/7-4.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            팝업창 우측 상단의 [선호/고정 배경 추가]를 선택하면 해당 사진에 합성된 배경이 [배경]
            메뉴에 추가됩니다. (단, AI가 만들어 낸 배경은 현재 해당 기능이 활성화되어 있지 않아
            선호/고정 배경으로 추가되지 않습니다) <br /> <br />{' '}
            <span style={{ fontWeight: 600 }}>1-1) 선호 배경 사용 방법</span> <br /> 선호 배경으로
            지정한 배경 이미지는 상품 사진 생성이 끝난 후 추가로 이미지를 생성하고 싶을 때 사용할 수
            있습니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-5.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            생성 이미지 썸네일 리스트 마지막에 있는 + 버튼을 누릅니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-6.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            팝업창에는 사용자가 지정해 놓은 선호/고정 배경의 리스트가 보여집니다. 추가 생성에
            사용하고자 하는 배경 사진을 선택한 후 [추가 생성]을 누릅니다.
            <br />
            <br /> <span style={{ fontWeight: 600 }}>1-2) 고정 배경 사용 방법</span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-7.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            고정 배경의 경우, 새 이미지를 생성할 때마다 무조건 해당 배경 사진을 활용한 생성 사진이
            만들어집니다. 고정 배경은 최대 5개까지 선택할 수 있습니다.
            <br />
            <br /> <span style={{ fontWeight: 600 }}>2) 사용자가 배경 이미지 직접 추가하기</span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-8.jpg',
      },
      {
        detail: <DetailTypoStyle>[배경] 메뉴에서 [배경 추가하기]를 클릭합니다.</DetailTypoStyle>,
        image: '/static/images/guide/7/7-9.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            팝업에서 [배경 추가하기]를 선택 후 배경 사진을 업로드합니다. [피사체 삭제 후 추가]
            기능은 배경으로 사용하고자 하는 사진에 피사체가 있는 경우, 피사체를 삭제한 후 배경을
            추가하는 기능으로 업데이트 예정입니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-10.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            업로드 된 배경을 사용하고자 하는 [카테고리]를 선택한 후, 배경에 들어갈 사물의 예시
            이미지를 적당한 크기로 조정하여 배경 사진 안에 원하는 위치에 배치합니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-11.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            그림자 생성 옵션을 설정합니다. 그림자의 반사 강도뿐만 아니라 오른쪽 화면에서 피사체를
            둘러싼 공을 움직여 빛과 그림자의 방향을 정할 수 있습니다. 수직 조명을 선택하면 빛이
            위에서 아래로 비추는 것처럼 그림자가 생성됩니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-12.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            배경 제목과 키워드를 입력한 후 [배경 추가를 누릅니다. 여기에서 입력한 키워드는
            포트폴리오 설정 옵션 중 [배경 컨셉]에 반영됩니다. 즉, 배경 컨셉 입력란에 사용자가 개인
            배경을 추가할 때 입력한 키워드를 넣으면 해당 배경 사진을 적용하여 생성 사진이
            만들어집니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-13.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            배경 추가가 완료되면 [배경] 메뉴에 해당 이미지가 추가됩니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/7/7-14.jpg',
      },
      { image: '/static/images/guide/7/7-15.jpg' },
    ],
    guide8: [
      {
        detail: (
          <DetailTypoStyle>
            생성 완료 후 마음에 드는 생성 이미지가 없다면 다시 이미지를 생성할 수 있습니다. 재생성은
            원본 사진에 들어있는 상품의 종류, 촬영 각도, 누끼 등을 좀 더 세밀하게 설정하여 더욱
            정확도 높은 생성 이미지를 얻을 수 있도록 도와주는 기능입니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/8/8-1.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            생성 이미지 리스트 우측 하단에 있는 [재생성]을 누릅니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/8/8-2.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            상품 카테고리, 촬영 각도, 배경 테마 등을 다시 설정할 수 있습니다. 상품 카테고리, 촬영
            각도 등을 최대한 원본 상품 사진과 유사하게 선택하는 것이 좋습니다.
            <br />
            <br />
            <span style={{ fontWeight: 600 }}>&lt;재생성 시 누끼 수정하기&gt;</span>
            <br /> 만약 누끼가 제대로 되지 않았다면 누끼 수정을 한 후 재생성을 할 수도 있습니다.
          </DetailTypoStyle>
        ),
        image: '/static/images/guide/8/8-3.jpg',
      },
    ],
    guide9: ['/static/images/guide/9,10/9-1.png', '/static/images/guide/9,10/10-1.png'],
  }

  const guideImagesEn = {
    guide1: [
      {
        detail: (
          <DetailTypoStyle>
            When you upload a product image, Draph Art’s AI automatically classifies the product and
            generates a high-quality, recommended background. For more customization, users can
            manually classify the product category, set background themes, adjust image sizes, and
            adjust other options. Additionally, if the generated result is not satisfactory, users
            can request to regenerate the photos at no cost, and there is no limit to the number of
            regeneration attempts.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/1/1-1.jpg',
      },
      { image: '/static/images/guideEn/1/1-2.jpg' },
      { image: '/static/images/guideEn/1/1-3.jpg' },
      { image: '/static/images/guideEn/1/1-4.jpg' },
      { image: '/static/images/guideEn/1/1-5.jpg' },
      { image: '/static/images/guideEn/1/1-6.jpg' },
      { image: '/static/images/guideEn/1/1-7.jpg' },
      { image: '/static/images/guideEn/1/1-8.jpg' },
      { image: '/static/images/guideEn/1/1-9.jpg' },
      { image: '/static/images/guideEn/1/1-10.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            When product photo generation is complete, click on the generated image for simple image
            editing.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/1/1-11.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            Features such as resizing and rearranging product sizes, cropping images, and creating
            GIFs are provided. (Note: Some features may not be supported depending on the types of
            the generated background) When you click on the generated image, various editing options
            will appear. <br />
            <br />
            <span style={{ fontWeight: 600 }}>&lt;Resizing Object&gt;</span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/1/1-12.jpg',
      },
      {
        image: '/static/images/guideEn/1/1-13.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            Click on [Resize Object] and move the box to adjust the size and position of the object.
            After adjusting, click [Submit] to complete object resizing. <br />
            <br />
            <span style={{ fontWeight: 600 }}>&lt;Cropping Image&gt;</span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/1/1-14.jpg',
      },
      {
        image: '/static/images/guideEn/1/1-15.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            Click on [Crop] and move the box to adjust the size of the image. Then click [Submit] to
            complete the image cropping. <br />
            <br />
            <span style={{ fontWeight: 600 }}>&lt;Creating GIFs&gt;</span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/1/1-16.jpg',
      },
    ],
    guide2: [
      {
        detail: (
          <DetailTypoStyle>
            The [BG Removal] function removes the original background of an image.{' '}
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/2/2-1.jpg',
      },
      { image: '/static/images/guideEn/2/2-2.jpg' },
      { image: '/static/images/guideEn/2/2-3.jpg' },
      { image: '/static/images/guideEn/2/2-4.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            <span style={{ fontWeight: 600 }}>&lt;BG Removal Edit&gt;</span>
            <br />
            After generating the result, you can get a more refined product photo by using the
            [Edit] function.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/2/2-5.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            You can add/remove areas either through selecting pieces or using brush. <br />
            <br />
            <span style={{ fontWeight: 600 }}>[Editing by pieces] </span>
            <br />
            Click [remove/add] to edit the area by pieces. When you click on the piece of the image
            that you want to add/remove, the area turns into green(add)/red(remove). After selecting
            the area, click [Apply]. For added area, the area turns white and for deleted area, the
            area turns dark.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/2/2-6.jpg',
      },
      { image: '/static/images/guideEn/2/2-7.jpg' },
      { image: '/static/images/guideEn/2/2-8.jpg' },
      { image: '/static/images/guideEn/2/2-9.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            <span style={{ fontWeight: 600 }}>[Editing with brush] </span>
            <br />
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/2/2-10.jpg',
      },
      {
        detail: (
          <>
            {' '}
            <ol style={{ paddingLeft: '4rem' }}>
              <li>
                <span style={{ fontWeight: 600 }}>Image Zoom In/Out</span> You can zoom in or out of
                the image.{' '}
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Eraser Selection</span>: Selecting the eraser
                allows you to erase the brush strokes drawn on the image. You can go back to the
                brush function by pressing the eraser icon again.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Remove</span>: This function allows you to
                additionally erase remaining areas.{' '}
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Add</span>: This function allows you to restore
                erased areas (black).{' '}
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Brush Size</span>: Adjust the brush/eraser size to
                make it larger or smaller.
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Undo</span>: Undo the previous operation.{' '}
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Redo</span>: Redo the previous operation.{' '}
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Reset</span>: Resets all the work, returning to
                the original BG removed image.{' '}
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>Done</span>: The areas drawn with the brush are
                directly reflected in the image,{' '}
              </li>
              <li>
                <span style={{ fontWeight: 600 }}>AI Assist</span>: The areas drawn with the brush
                are automatically and smoothly integrated into the image by AI.
              </li>
            </ol>{' '}
            <br />
            <br /> <span style={{ fontWeight: 600 }}>&lt;How to remove the remaining area&gt;</span>
            <br />
          </>
        ),
        image: '/static/images/guideEn/2/2-11.jpg',
      },

      {
        detail: (
          <DetailTypoStyle>
            When you click the [Remove] button, the brush color changes to red. After adjusting the
            brush size, select the additional areas you want to remove from the photo using the
            brush.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/2/2-12.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            Click [Done] or [AI Assist] button and the selected areas will be removed.
            <br />
            <br />{' '}
            <span style={{ fontWeight: 600 }}>
              &lt;How to restore (add) the area that has been removed&gt;
            </span>{' '}
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/2/2-13.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            When you click the [Add] button, the brush color changes to green. After adjusting the
            brush size, select the areas you want to restore in the photo using the brush.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/2/2-14.jpg',
      },
    ],

    guide3: [
      '/static/images/guideEn/3/3-1.jpg',
      '/static/images/guideEn/3/3-2.jpg',
      '/static/images/guideEn/3/3-3.jpg',
      '/static/images/guideEn/3/3-4.jpg',
      '/static/images/guideEn/3/3-5.jpg',
    ],
    guide4: [
      {
        detail: (
          <DetailTypoStyle>
            Portfolio is a convenient feature for producing a large quantity of product photos using
            the same theme. By saving background themes and photo settings suitable for a specific
            product category in a portfolio, you can easily create product photos with the desired
            mood without the need to change settings each time. (Portfolio settings can be modified
            at any time)
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/4/4-1.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            *[Unclassified] portfolio is automatically created as a default before users manually
            create their portfolios. In this portfolio, product photos generated through the
            [Product Photo] feature are collected. Therefore, if you have not created a portfolio in
            advance and have generated product photos, you can check those photos in the
            [Unclassified] portfolio.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/4/4-2.jpg',
      },
      { image: '/static/images/guideEn/4/4-3.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            Tip: Setting the title of the portfolio to match the concept and type of products makes
            it convenient for future management of product photos.
            <br /> <br />
            Once the title is set, you’ll go through the same setting process as when using the
            [Product photo] function.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/4/4-4.jpg',
      },
      { image: '/static/images/guideEn/4/4-5.jpg' },
      { image: '/static/images/guideEn/4/4-6.jpg' },
      { image: '/static/images/guideEn/4/4-7.jpg' },
      { image: '/static/images/guideEn/4/4-8.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            With the portfolio, you can efficiently produce product photos with the same concept
            repetitively. Additionally, you can organize the generated photos by product categories
            such as food, fashion, electronics, and more, managing the images more conveniently.
            <br />
            <br /> The generated portfolios can be downloaded, copied, or deleted.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/4/4-9.jpg',
      },
      {
        detail: (
          <>
            <ol style={{ paddingLeft: '4rem' }}>
              <li>
                Download: You can download all the generated results within the portfolio at once.
              </li>
              <li>
                Copy: Click the button to create an additional portfolio with the same settings as
                the selected one. (The contents of the portfolio will not be copied)
              </li>
              <li> Delete: You can delete the selected portfolio. </li>
            </ol>
            <br />

            <DetailTypoStyle>
              {' '}
              <span style={{ fontWeight: 600 }}>
                &lt;Generating product photo within a portfolio&gt;
              </span>
              <br />
              <br />
              The process of generating product photos within a portfolio is identical to the
              [Product Photo] generation process.
            </DetailTypoStyle>
          </>
        ),
        image: '/static/images/guideEn/4/4-10.jpg',
      },
      {
        image: '/static/images/guideEn/4/4-11.jpg',
      },
      { image: '/static/images/guideEn/4/4-12.jpg' },
      { image: '/static/images/guideEn/4/4-13.jpg' },
      { image: '/static/images/guideEn/4/4-14.jpg' },
      { image: '/static/images/guideEn/4/4-15.jpg' },
      { image: '/static/images/guideEn/4/4-16.jpg' },
    ],
    guide5: [
      {
        detail: (
          <>
            <DetailTypoStyle>
              In the [Model] tab, you can replace mannequins with human models and create
              backgrounds with AI models.
            </DetailTypoStyle>
            <br />

            <DetailTypoStyle>
              <span style={{ fontWeight: 600 }}>&lt;Turning mannequins into human models&gt;</span>
            </DetailTypoStyle>
          </>
        ),
        image: '/static/images/guideEn/5/5-1.jpg',
      },
      { image: '/static/images/guideEn/5/5-2.jpg' },
      { image: '/static/images/guideEn/5/5-3.jpg' },
      {
        detail: (
          <>
            <DetailTypoStyle>
              When you upload an image of a mannequin, you will see the screen above.
            </DetailTypoStyle>

            <ol style={{ paddingLeft: '4rem' }}>
              <li>
                Select the area to maintain as the clothing (product): Click on the clothes
                (product) and the selected area will be highlighted in green.
              </li>
              <li>Background: Enter the desired background themes using keywords. </li>
              <li>Select model gender: Select between female/male. </li>
            </ol>
          </>
        ),
        image: '/static/images/guideEn/5/5-4.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            Once you finish configuring model settings, the screen will change as shown above. Click
            [Done] and the model generation process will begin.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/5/5-5.jpg',
      },
      { image: '/static/images/guideEn/5/5-6.jpg' },
      {
        detail: (
          <DetailTypoStyle>
            <span style={{ fontWeight: 600 }}>&lt;Creating background with AI Model&gt;</span>
            <br />
            <br />
            In the [BG with AI Model] tab, you can generate a product photo with an AI model in it.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/5/5-7.jpg',
      },
      { image: '/static/images/guideEn/5/5-8.jpg' },
    ],
    guide6: [
      '/static/images/guideEn/6/6-1.jpg',
      '/static/images/guideEn/6/6-2.jpg',
      '/static/images/guideEn/6/6-3.jpg',
      '/static/images/guideEn/6/6-4.jpg',
      '/static/images/guideEn/6/6-5.jpg',
    ],

    guide7: [
      {
        detail: (
          <DetailTypoStyle>
            Draph Art users can generate product photos using not only AI-generated images but also
            custom background images. Relevant functions can be managed under the &quot;My
            Page&quot; → &quot;My Image&quot; → [Background] menu.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-1.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            Users can add the AI-generated backgrounds from Draph Art as personal/pinned backgrounds
            or directly upload their desired background images to use in the product photo
            generation. <br />
            <br />
            <span style={{ fontWeight: 600 }}>
              1) How to add AI-generated backgrounds from Draph Art as personal/pinned background
            </span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-2.jpg',
      },
      {
        detail: <DetailTypoStyle>Select the thumbnail of the generated images. </DetailTypoStyle>,
        image: '/static/images/guideEn/7/7-3.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            The assigned backgrounds will appear in the [Background] menu. (Note. AI-generated
            backgrounds can’t be added as a personal/pinned background as this feature is not
            currently activated.){' '}
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-4.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            <span style={{ fontWeight: 600 }}>1-1) How to use Personal Background </span> <br />
            <br /> Background images that are selected as personal backgrounds can be used when you
            want to generate additional images after the product photo generation is complete.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-5.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            Press the [+] button at the end of the thumbnail list of generated images.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-6.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            The popup window displays the list of personal/pinned backgrounds assigned by the user.
            After selecting the background you want to use in the regeneration, click [Add].
            <br />
            <br /> <span style={{ fontWeight: 600 }}>1-2) How to use Pinned Background </span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-7.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            For pinned backgrounds, photos that are made using the selected background will be
            included in every new creation. Users can choose up to five fixed backgrounds.
            <br />
            <br />{' '}
            <span style={{ fontWeight: 600 }}>2) How to directly add a desired background </span>
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-8.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>Click [Add Background] in the [Background] menu. </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-9.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            After selecting [Add Background] in the popup, upload the background photo. The [Object
            Removal] function, which allows you to remove objects from the photo before adding the
            background, is planned to be updated soon.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-10.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            Select the Category of the uploaded background. Adjust the example image of the object
            to an appropriate size and place it in the desired position .
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-11.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            Set the shadow generation options. You can not only adjust the strength of the shadow’s
            reflection but also move the sphere around to determine the direction of the light and
            shadow. If you choose vertical lighting, the shadow will be generated as if the light is
            shining from top to bottom.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-12.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            After typing the background title and keywords, click on [Add Background]. The keywords
            entered here will be reflected in the [Background Theme] option in the portfolio
            settings.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-13.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            When adding background is complete, the image will be added to your [background] menu.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/7/7-14.jpg',
      },
      { image: '/static/images/guideEn/7/7-15.jpg' },
    ],
    guide8: [
      {
        detail: (
          <DetailTypoStyle>
            If you are not satisfied with the generated images after completion, you can regenerate
            them. Regeneration allows you to set more detailed parameters such as the type of
            product in the original photo, shooting angles, and background removal for more accurate
            and refined generated images.
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/8/8-1.jpg',
      },
      {
        detail: <DetailTypoStyle>Click [redo].</DetailTypoStyle>,
        image: '/static/images/guideEn/8/8-2.jpg',
      },
      {
        detail: (
          <DetailTypoStyle>
            You can change the generating settings and edit background removal areas. When adjusting
            [Angle], it is advisable to choose an angle that closely resembles the original product
            photo
          </DetailTypoStyle>
        ),
        image: '/static/images/guideEn/8/8-3.jpg',
      },
    ],
    guide9: ['/static/images/guideEn/9,10/9-1.jpg', '/static/images/guideEn/9,10/10-1.jpg'],
  }

  const posts = isKo(i18n)
    ? [
        {
          id: 1,
          title: '상품 사진 생성하기',
          date: '24.01.31',
          details: (
            <CenterAlignStack gap="1.5rem">
              {guideImages.guide1.map((guide, idx) => {
                return (
                  <Box key={idx}>
                    {guide.detail}
                    {guide.detail && <br />}
                    <img
                      src={guide.image}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide.image)
                      }}
                    />
                  </Box>
                )
              })}
              <DetailTypoStyle>
                만들어진 상품 사진은 무료로 GIF 생성 기능을 사용할 수 있습니다. 상품 사진 우측
                상단에 뜨는 [GIF 생성] 버튼을 누르면 단 몇 초 만에 움직이는 상품 사진을 얻을 수
                있습니다.{' '}
              </DetailTypoStyle>
            </CenterAlignStack>
          ),
        },
        {
          id: 2,
          title: '상품 사진 누끼따기',
          date: '24.01.31',
          details: (
            <CenterAlignStack gap="1.5rem">
              {guideImages.guide2.map((guide, idx) => {
                return (
                  <Box key={idx}>
                    {guide.detail}
                    {guide.detail && <br />}
                    <img
                      src={guide.image}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide.image)
                      }}
                    />
                  </Box>
                )
              })}
              <DetailTypoStyle>
                [다듬기] 또는 [원터치 수정] 버튼을 누르면 녹색 브러쉬로 선택한 영역이 복원됩니다.{' '}
              </DetailTypoStyle>
            </CenterAlignStack>
          ),
        },
        {
          id: 3,
          title: '대량(번들) 상품 사진 생성하기 ',
          date: '24.01.31',
          details: (
            <>
              <DetailTypoStyle>
                대량(번들) 상품은 사진 속 상품의 개수를 원하는 수량만큼 늘려주는 기능입니다.
              </DetailTypoStyle>
              <br />
              <CenterAlignStack gap="1.5rem">
                {guideImages.guide3.map((guide, idx) => {
                  return (
                    <img
                      key={idx}
                      src={guide}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide)
                      }}
                    />
                  )
                })}
                <DetailTypoStyle>
                  번들 이미지는 한 번 생성 시 서로 다른 구성의 상품 사진 두 장이 제공됩니다. 구성은
                  다르지만 사진에서 보이는 상품의 개수는 설정한 개수와 동일합니다.
                </DetailTypoStyle>
              </CenterAlignStack>
            </>
          ),
        },
        {
          id: 4,
          title: '포토폴리오 사용하기',
          date: '24.01.31',
          details: (
            <>
              <CenterAlignStack gap="1.5rem">
                {guideImages.guide4.map((guide, idx) => {
                  return (
                    <Box key={idx}>
                      {guide.detail}
                      {guide.detail && <br />}
                      <img
                        src={guide.image}
                        loading="lazy"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true)
                          setSelectedImage(guide.image)
                        }}
                      />
                    </Box>
                  )
                })}
              </CenterAlignStack>
            </>
          ),
        },
        {
          id: 5,
          title: '마네킹을 사람 모델로 바꾸기',
          date: '24.01.31',
          details: (
            <CenterAlignStack gap="1.5rem">
              {guideImages.guide5.map((guide, idx) => {
                return (
                  <Box key={idx}>
                    {guide.detail}
                    {guide.detail && <br />}
                    <img
                      src={guide.image}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide.image)
                      }}
                    />
                  </Box>
                )
              })}
              <DetailTypoStyle></DetailTypoStyle>
            </CenterAlignStack>
          ),
        },
        {
          id: 6,
          title: '상품 사진 배경 확장하기(AI 배경 확장)',
          date: '24.01.31',
          details: (
            <>
              <CenterAlignStack gap="1.5rem">
                <DetailTypoStyle>
                  AI 배경 확장 메뉴에서는 기존 상품 사진의 배경을 연장할 수 있습니다.
                </DetailTypoStyle>

                {guideImages.guide6.map((guide, idx) => {
                  return (
                    <img
                      key={idx}
                      src={guide}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide)
                      }}
                    />
                  )
                })}
              </CenterAlignStack>
            </>
          ),
        },
        {
          id: 7,
          title: '내 배경 이미지 추가해서 상품 사진 생성하기 ',
          date: '24.01.31',
          details: (
            <>
              <CenterAlignStack gap="1.5rem">
                {guideImages.guide7.map((guide, idx) => {
                  return (
                    <Box key={idx}>
                      {guide.detail}
                      {guide.detail && <br />}
                      <img
                        src={guide.image}
                        loading="lazy"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true)
                          setSelectedImage(guide.image)
                        }}
                      />
                    </Box>
                  )
                })}
                <DetailTypoStyle>
                  추가된 배경 사진을 클릭하면 위와 같은 팝업이 나타납니다. 팝업의 우측 상단에서 해당
                  이미지를 선호/고정 배경으로 지정할 수 있습니다.
                </DetailTypoStyle>
              </CenterAlignStack>
            </>
          ),
        },
        {
          id: 8,
          title: '생성 이미지 재생성하기 ',
          date: '24.01.31',
          details: (
            <>
              <CenterAlignStack gap="1.5rem">
                {guideImages.guide8.map((guide, idx) => {
                  return (
                    <Box key={idx}>
                      {guide.detail}
                      {guide.detail && <br />}
                      <img
                        src={guide.image}
                        loading="lazy"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true)
                          setSelectedImage(guide.image)
                        }}
                      />
                    </Box>
                  )
                })}
                <DetailTypoStyle>
                  [누끼 수정] 이용 방법은{' '}
                  <span style={{ fontWeight: 600 }}>2. 상품사진 누끼따기</span> 파트를 참고해주세요.
                  모든 설정이 끝나면 [재생성]을 눌러 이미지 재생성을 완료합니다.{' '}
                </DetailTypoStyle>
              </CenterAlignStack>
            </>
          ),
        },

        {
          id: 9,
          title: '상품 카테고리 선택 시 권장사항 ',
          date: '23.03.01',
          details: (
            <CenterAlignStack gap="1.5rem">
              <DetailTypoStyle>
                포트폴리오를 만들 때 업로드할 원본 상품 사진과 가장 비슷한 상품 카테고리를
                선택하시면 드랩아트의 AI가 원본 상품 사진을 더 잘 분석하여 원하시는 컨셉과 분위기에
                맞는 생성 사진을 만들어낼 수 있습니다.
                <br /> <br /> 현재 상품 카테고리는 자동, 사람, 의류, 식품, 화장품, 자동차 등 총
                6가지가 있으며(추후 카테고리 추가 예정), 다양한 카테고리의 상품을 업로드하실 때는
                [자동]으로 설정해 주세요. 그 외에 다른 카테고리에 해당하는 상품을 업로드하는
                경우에는 꼭 해당 카테고리를 선택해 주세요.
                <br /> <br /> 또한, 현재 사람, 의류, 식품 카테고리는 상품이 촬영된 각도, 상품 종류
                등에 따른 소분류도 선택할 수 있습니다. 만약 소분류에서도 해당하는 카테고리가 없다면
                [자동]을 선택해 주세요.
              </DetailTypoStyle>

              <img
                src={guideImages.guide9[0]}
                loading="lazy"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpen(true)
                  setSelectedImage(guideImages.guide9[0])
                }}
              />
            </CenterAlignStack>
          ),
        },
        {
          id: 10,
          title: '원본 상품 사진 업로드 시 권장사항',
          date: '23.03.01',
          details: (
            <CenterAlignStack gap="1.5rem">
              <DetailTypoStyle>
                원본 상품 사진을 업로드할 때 아래의 권장사항을 참고하면 훨씬 더 만족스러운 생성
                이미지를 얻을 수 있습니다. 아래 예시 이미지와 비슷한 상품 사진들은 드랩아트에
                업로드하는 것을 권장하지 않습니다.
              </DetailTypoStyle>
              <img
                src={guideImages.guide9[1]}
                loading="lazy"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpen(true)
                  setSelectedImage(guideImages.guide9[1])
                }}
              />
            </CenterAlignStack>
          ),
        },
      ]
    : [
        {
          id: 1,
          title: 'How to generate [Product Photo]',
          date: '24.01.31',
          details: (
            <CenterAlignStack gap="1.5rem">
              {guideImagesEn.guide1.map((guide, idx) => {
                return (
                  <Box key={idx}>
                    {guide.detail}
                    {guide.detail && <br />}
                    <img
                      src={guide.image}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide.image)
                      }}
                    />
                  </Box>
                )
              })}
              <DetailTypoStyle>
                Products created with AI generated background come with a free GIF feature. Simply
                click the [Create GIF] button and get a dynamic product photo within a few seconds.
              </DetailTypoStyle>
            </CenterAlignStack>
          ),
        },
        {
          id: 2,
          title: 'How to use [Background Removal]',
          date: '24.01.31',
          details: (
            <CenterAlignStack gap="1.5rem">
              {guideImagesEn.guide2.map((guide, idx) => {
                return (
                  <Box key={idx}>
                    {guide.detail}
                    {guide.detail && <br />}
                    <img
                      src={guide.image}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide.image)
                      }}
                    />
                  </Box>
                )
              })}
              <DetailTypoStyle>
                Click [Done] or [AI Assist] button and the selected areas will be restored.
              </DetailTypoStyle>
            </CenterAlignStack>
          ),
        },
        {
          id: 3,
          title: 'How to generate [Multipack Photo]',
          date: '24.01.31',
          details: (
            <>
              <DetailTypoStyle>
                Multipack feature helps create a bulk photo of a product. Even with only one
                product, you could create a bundle image of the product.
              </DetailTypoStyle>
              <br />
              <CenterAlignStack gap="1.5rem">
                {guideImagesEn.guide3.map((guide, idx) => {
                  return (
                    <img
                      key={idx}
                      src={guide}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide)
                      }}
                    />
                  )
                })}
                <DetailTypoStyle>
                  [Multipack photo] provides two different final images in a single generation.
                  While the configuration of the images differ, the number of products in the photos
                  is consistent with the required quantity.
                </DetailTypoStyle>
              </CenterAlignStack>
            </>
          ),
        },
        {
          id: 4,
          title: 'How to use [Portfolio]',
          date: '24.01.31',
          details: (
            <>
              <CenterAlignStack gap="1.5rem">
                {guideImagesEn.guide4.map((guide, idx) => {
                  return (
                    <Box key={idx}>
                      {guide.detail}
                      {guide.detail && <br />}
                      <img
                        src={guide.image}
                        loading="lazy"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true)
                          setSelectedImage(guide.image)
                        }}
                      />
                    </Box>
                  )
                })}
              </CenterAlignStack>
            </>
          ),
        },
        {
          id: 5,
          title: 'How to create AI models inside product photos ',
          date: '24.01.31',
          details: (
            <CenterAlignStack gap="1.5rem">
              {guideImagesEn.guide5.map((guide, idx) => {
                return (
                  <Box key={idx}>
                    {guide.detail}
                    {guide.detail && <br />}
                    <img
                      src={guide.image}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide.image)
                      }}
                    />
                  </Box>
                )
              })}
              <DetailTypoStyle></DetailTypoStyle>
            </CenterAlignStack>
          ),
        },
        {
          id: 6,
          title: 'How to extend product photo background [AI Image Extender]',
          date: '24.01.31',
          details: (
            <>
              <CenterAlignStack gap="1.5rem">
                <DetailTypoStyle>
                  In the [AI Image Extender] menu, you can extend the background of the original
                  product photo.
                </DetailTypoStyle>

                {guideImagesEn.guide6.map((guide, idx) => {
                  return (
                    <img
                      key={idx}
                      src={guide}
                      loading="lazy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpen(true)
                        setSelectedImage(guide)
                      }}
                    />
                  )
                })}
              </CenterAlignStack>
            </>
          ),
        },
        {
          id: 7,
          title: 'How to use personal background to create product photo',
          date: '24.01.31',
          details: (
            <>
              <CenterAlignStack gap="1.5rem">
                {guideImagesEn.guide7.map((guide, idx) => {
                  return (
                    <Box key={idx}>
                      {guide.detail}
                      {guide.detail && <br />}
                      <img
                        src={guide.image}
                        loading="lazy"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true)
                          setSelectedImage(guide.image)
                        }}
                      />
                    </Box>
                  )
                })}
                <DetailTypoStyle>
                  When you click on the added background, the screen above appears. At the upper
                  right corner of the popup window, you can assign the image as personal/pinned
                  background.
                </DetailTypoStyle>
              </CenterAlignStack>
            </>
          ),
        },
        {
          id: 8,
          title: 'How to regenerate image ',
          date: '24.01.31',
          details: (
            <>
              <CenterAlignStack gap="1.5rem">
                {guideImagesEn.guide8.map((guide, idx) => {
                  return (
                    <Box key={idx}>
                      {guide.detail}
                      {guide.detail && <br />}
                      <img
                        src={guide.image}
                        loading="lazy"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setOpen(true)
                          setSelectedImage(guide.image)
                        }}
                      />
                    </Box>
                  )
                })}
                <DetailTypoStyle>
                  For instructions on how to use [Edit Area], please refer to
                  <span style={{ fontWeight: 600 }}>2. How to use [Background Removal].</span> Once
                  all settings are complete, press [Redo] to finish image regeneration.
                </DetailTypoStyle>
              </CenterAlignStack>
            </>
          ),
        },

        {
          id: 9,
          title: 'Tips for product category selection',
          date: '23.03.01',
          details: (
            <CenterAlignStack gap="1.5rem">
              <DetailTypoStyle>
                When creating a portfolio, selecting the most relevant product category with the
                original item in the photo will enable Draph Art’s AI to analyze the original
                product photo more effectively and generate images that align with your desired
                concept.
                <br /> <br /> Currently, there are four main categories: Apparel, Food, Cosmetic,
                Car/vehicle. When uploading products from various categories, please set the
                category to [Auto]. For items within a specific category, be sure to select the
                corresponding category.
                <br /> <br /> Additionally, for Apparel and Food categories, you can further specify
                types based on different factors of the original item in the photo. If there isn’t a
                suitable type, please select [Auto].
              </DetailTypoStyle>

              <img
                src={guideImagesEn.guide9[0]}
                loading="lazy"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpen(true)
                  setSelectedImage(guideImagesEn.guide9[0])
                }}
              />
            </CenterAlignStack>
          ),
        },
        {
          id: 10,
          title: 'Tips for uploading original product photos ',
          date: '23.03.01',
          details: (
            <CenterAlignStack gap="1.5rem">
              <DetailTypoStyle>
                When uploading original product photos, following the recommended guidelines below
                will result in more satisfying generated images.{' '}
                <span style={{ fontWeight: 600 }}>
                  It is not advisable to upload product photos that are similar to the example
                  images below.
                </span>
                <br />
                <br />
                Please select the most appropriate category and type to enhance the AI’s ability to
                create images that match your preference.
              </DetailTypoStyle>
              <img
                src={guideImagesEn.guide9[1]}
                loading="lazy"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpen(true)
                  setSelectedImage(guideImagesEn.guide9[1])
                }}
              />
            </CenterAlignStack>
          ),
        },
      ]

  const [page, setPage] = useState(1)
  const postsPerPage = 10
  const startIndex = (page - 1) * postsPerPage
  const endIndex = startIndex + postsPerPage
  const visiblePosts = posts.slice(startIndex, endIndex)

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ width: INFO_PERCENTAGE_WIDHT, pl: MYPAGE_SIDE_MARGIN }}>
          <Box
            sx={{
              height: '100%',
              width: '88.4rem',
              minHeight: '57rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FlexBasis flexBasis={['15%', '64%', '19%']}>
              <Box>
                <CenterAlignBox sx={{ justifyContent: 'space-between' }}>
                  <Typography sx={{ fontWeight: 800, fontSize: '3rem', pb: '3.4rem' }}>
                    {t('info_side_menu.guide')}
                  </Typography>
                  {/* {id === 2 && <Button>asdf</Button>} */}
                </CenterAlignBox>
                <Divider sx={{ border: `3px solid ${theme.palette.common.black}` }} />
                <CenterAlignBox sx={{ height: '5.8rem' }}>
                  <FlexBasis
                    flexBasis={['15%', '70%', '15%']}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Typography sx={headerStyle}> {t('info_side_menu.number')}</Typography>
                    <Typography sx={headerStyle}>{t('info_side_menu.title')}</Typography>
                    <Typography sx={headerStyle}>{t('info_side_menu.date')}</Typography>
                  </FlexBasis>
                </CenterAlignBox>
                <Divider sx={{ border: `1px solid ${theme.palette.common.black}` }} />
              </Box>
              <Box sx={{ minHeight: '7.7rem' }}>
                {visiblePosts.map((post, idx) => {
                  return (
                    <Post
                      key={idx}
                      id={post.id}
                      title={post.title}
                      date={post.date}
                      details={post.details}
                    />
                  )
                })}

                {/* <Grid container spacing={2}>
        {visiblePosts.map(post => (
          <Grid item xs={12} key={post.id}>
            <Post title={post.title} />
          </Grid>
        ))}
      </Grid> */}
              </Box>
              <CenterAlignStack
                sx={{
                  pt: '5.5rem',
                  mb: '3rem',
                }}
              >
                {posts.length > postsPerPage && (
                  <CustomPagination
                    count={Math.ceil(posts.length / postsPerPage)}
                    page={page}
                    onChange={handlePageChange}
                  />
                )}
              </CenterAlignStack>
            </FlexBasis>
          </Box>
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack sx={{ mb: '6.4rem' }}>
          <Typography sx={{ pt: '3.4rem', mb: '2.4rem', fontSize: '2.4rem', fontWeight: 800 }}>
            {t('info_side_menu.guide')}
          </Typography>
          {posts.map((post, idx) => {
            return (
              <MobilePost
                key={idx}
                id={post.id}
                title={post.title}
                date={post.date}
                details={post.details}
              />
            )
          })}
        </CenterAlignStack>
      </Mobile>
      <GuideImageDialog open={open} setOpen={setOpen} image={selectedImage} />
    </>
  )
}

const Post = ({ id, title, date, details }) => {
  return (
    <>
      <CustomAccordion>
        <CustomAccordionSummary>
          <CenterAlignBox sx={{ flexGrow: 1 }}>
            <FlexBasis
              flexBasis={['15%', '70%', '15%']}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Typography sx={postStyle}>{id}</Typography>
              <Typography sx={postStyle}>{title}</Typography>
              <Typography sx={postStyle}>{date}</Typography>
            </FlexBasis>
            <Divider />
          </CenterAlignBox>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Box sx={{ px: '5%', py: '3%' }}>{details}</Box>
        </CustomAccordionDetails>
      </CustomAccordion>
    </>
  )
}

const MobilePost = ({ id, title, date, details }) => {
  return (
    <>
      <CustomAccordion sx={{ width: '100%' }}>
        <CustomAccordionSummary
          expandIcon={<DropdownArrowIcon color="black" />}
          sx={{
            mr: '2rem',
            height: '6.4rem',
            '&.Mui-expanded': {
              borderTop: '1px solid rgb(128, 128, 128, 0.4)',
            },
          }}
        >
          <CenterAlignBox
            sx={{
              display: 'flex',
              mx: '2rem',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <FlexBasis flexBasis={['15%', '85%']}>
              <Typography sx={{ fontWeight: 500, fontSize: '1.3rem' }}>{id}</Typography>
              <Box>
                <Typography sx={{ fontWeight: 500, fontSize: '1.3rem' }}>{title}</Typography>
                <Typography sx={{ fontWeight: 400, fontSize: '1.2rem', color: '#989898' }}>
                  {date}
                </Typography>
              </Box>
            </FlexBasis>
          </CenterAlignBox>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Box sx={{ px: '5rem', py: '3.5rem', fontWeight: 400, fontSize: '1.5rem' }}>
            {details}
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
    </>
  )
}
