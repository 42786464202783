import {
  f1Atom,
  f2Atom,
  f3Atom,
  indexAtom,
  mannequinLoadingAtom,
  maskImgArrayAtom,
  removeMaskImgAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  selectedMaskImgAtom,
  uploadImgHeightAtom,
  uploadImgWidthAtom,
} from 'atoms'
import { useResetRecoilState } from 'recoil'

export function useResetNpy() {
  const resetSelectedMaskImg = useResetRecoilState(selectedMaskImgAtom)
  const resetMannequinLoading = useResetRecoilState(mannequinLoadingAtom)
  const resetSegmentLoading = useResetRecoilState(segmentLoadingAtom)
  const resetSegmentStep = useResetRecoilState(segmentStepAtom)
  const resetMaskImgArray = useResetRecoilState(maskImgArrayAtom)
  const resetUploadImgHeight = useResetRecoilState(uploadImgHeightAtom)
  const resetUploadImgWidth = useResetRecoilState(uploadImgWidthAtom)
  const resetRemoveMaskImg = useResetRecoilState(removeMaskImgAtom)
  const resetIndex = useResetRecoilState(indexAtom)
  const resetF1 = useResetRecoilState(f1Atom)
  const resetF2 = useResetRecoilState(f2Atom)
  const resetF3 = useResetRecoilState(f3Atom)

  const resetAllState = () => {
    resetSelectedMaskImg()
    resetMannequinLoading()
    resetSegmentLoading()
    resetSegmentStep()
    resetMaskImgArray()
    resetUploadImgHeight()
    resetUploadImgWidth()
    resetRemoveMaskImg()
    resetIndex()
    resetF1()
    resetF2()
    resetF3()
  }

  return { resetAllState }
}

// const { abortApi } = useApiHandler({ apiKey: 'getNpyMaps', apis: apis.appfront })
// const { resetAllState } = useResetNpy()

// useEffect(() => {
//   return () => {
//     abortApi()
//     resetAllState()
//   }
// }, [])
