import { useEffect, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import QuillToolbar, { formats, modules } from './Toolbar'

function TestPage10() {
  const [value, setValue] = useState('')

  useEffect(() => {
    console.log(value)
  })

  return (
    <>
      <QuillToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
      />
    </>
  )
}

export default TestPage10
