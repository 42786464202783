import {
  SwipeableDrawer,
  Button,
  Typography,
  Drawer,
  Box,
  Avatar,
  Checkbox,
  Divider,
  Stack,
} from '@mui/material'
import { MHidden } from 'components/@material-extend'
import { useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { TempLayoutDiv } from './TempLayoutDiv'
import { AiFillEdit } from 'react-icons/ai'
import { removeUserCookie } from 'utils/cookie'

import { appMenuAtom, tutorialAtom, userAtom } from 'atoms'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'

import { styled, useTheme } from '@mui/material/styles'
import { USER_GRADE_DICT } from 'config'
import { getS3ImageSrc } from 'utils/common'
import { SettingIcon, DotIcon, CloseIcon, SearchIcon, StarFilledIcon, DrawerIcon } from 'theme/icon'
import LanguageSelect from './LanguageSelect'
import { StyledLink } from './CustomStyle'
import { Tutorial } from 'theme/Tutorial'
import { useMenu } from 'hooks/useMenu'

const ContentStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  width: '100%',
  alignItems: 'center',
  // overflow: 'hidden',
}))

export function MenuDrawer() {
  const [open, setOpen] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const { menu } = useMenu()
  const theme = useTheme()

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(open)
  }

  return (
    <>
      <CenterAlignStack direction="row" onClick={toggleDrawer(true)} sx={{ cursor: 'pointer' }}>
        {/* 여기부터 작성해야함. */}
        <DrawerIcon />
      </CenterAlignStack>

      <SwipeableDrawer
        anchor="left"
        open={open}
        onOpen={() => {}}
        onClose={toggleDrawer(false)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        PaperProps={{ sx: { width: '100%' } }}
      >
        <ContentStyle>
          <Box
            sx={{
              width: '100%',
              justifyContent: 'end',
              display: 'flex',
              mb: '8.4rem',
            }}
          >
            <Box
              sx={{
                width: 'fit-content',
                cursor: 'pointer',
                mt: '2rem',
                mr: '3rem',
              }}
              onClick={toggleDrawer(false)}
            >
              <CloseIcon />
            </Box>
          </Box>
          <CenterAlignStack gap="6.8rem">
            {menu.map((item, idx) =>
              idx % 2 === 0 ? (
                <StyledLink key={item.id} to={item.path} onClick={() => setOpen(false)}>
                  <Typography
                    sx={{
                      fontWeight: 800,
                      fontSize: '3rem',
                      color: location.pathname.includes(item.path)
                        ? theme.palette.draph.blue
                        : theme.palette.common.black,
                    }}
                  >
                    {item.title}
                  </Typography>
                </StyledLink>
              ) : (
                <Tutorial key={item.id} step={1}>
                  <StyledLink
                    key={item.id}
                    to={item.path}
                    onClick={() => {
                      tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 0.5 }))
                      setOpen(false)
                    }}
                  >
                    <Typography
                      sx={{
                        width: !tutorial.mode ? null : '100vw',
                        textAlign: !tutorial.mode ? null : 'center',
                        fontWeight: 800,
                        fontSize: '3rem',
                        color: location.pathname.includes(item.path)
                          ? theme.palette.draph.blue
                          : theme.palette.common.black,
                      }}
                    >
                      {item.title}
                    </Typography>
                  </StyledLink>
                </Tutorial>
              )
            )}
          </CenterAlignStack>
        </ContentStyle>
      </SwipeableDrawer>
    </>
  )
}

const PricingButton = styled(Button)(({ theme }) => ({
  // width: '10.1rem',
  height: '2.8rem',
  fontSize: '1.1rem',
  fontWeight: 600,
  paddingTop: '0.7rem',
  paddingBottom: '0.7rem',
  paddingLeft: '1.7rem',
  paddingRight: '1.3rem',
  borderWidth: '0.1rem',
  borderColor: theme.palette.common.black,
  borderRadius: '2rem',

  '&:hover': {
    backgroundColor: 'unset',
  },
}))

const TextLinkButton = styled(Button)(({ theme }) => ({
  height: '2.8rem',
  fontSize: '1.3rem',
  fontWeight: 700,
  color: theme.palette.common.black,
  textDecoration: 'none !important',

  '&:hover': {
    backgroundColor: 'unset',
  },
}))

function TextLink(props) {
  return (
    <RouterLink style={{ textDecoration: 'none' }} {...props}>
      <TextLinkButton>{props.title}</TextLinkButton>
    </RouterLink>
  )
}
