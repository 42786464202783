//   {
//     image: '/static/images/banner/concept.png',
//     style: {
//       width: '14rem',
//       height: '14rem',
//       cursor: 'pointer',
//       borderRadius: '1rem',
//       border:
//         bannerConfig.genType === 'no_SO'
//           ? `3px solid ${theme.palette.draph.blue}`
//           : `3px solid rgba(0,0,0,0)`,
//       transition: 'border 0.3s',
//       boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.1)',
//     },
//     config: 'no_SO',
//     sourceStyle: {
//       alignItems: 'center',
//       width: '14rem',
//       height: '2.8rem',
//       position: 'absolute',
//       borderBottomLeftRadius: '1rem',
//       borderBottomRightRadius: '1rem',
//       bottom: 0,
//       backgroundColor:
//         bannerConfig.genType === 'no_SO' ? theme.palette.draph.blue : theme.palette.common.white,
//       color:
//         bannerConfig.genType === 'no_SO'
//           ? theme.palette.common.white
//           : theme.palette.common.black,
//       transition: 'all 0.3s',
//       fontSize: '1.3rem',
//       lineHeight: 1,
//       fontWeight: 400,
//     },
//     source: 'AI 컨셉 이미지',
//   },

import { Box, useTheme } from '@mui/material'
import { CenterAlignBox } from './CenterAlignContainer'

export default function OutlinedImage({
  imageSrc,
  imageStyleAdd,
  valueKey,
  value,
  boxStyleAdd,
  source,
  sourceStyleAdd,
  config,
  setConfig,
  borderWidth = '3',
  isDisabled = false,
  clickAdd = () => {},
}) {
  const theme = useTheme()

  const handleClick = value => () => {
    if (isDisabled) return
    clickAdd()

    if (!setConfig) return
    if (typeof config === 'object') {
      setConfig(prev => ({ ...prev, [valueKey]: value }))
    } else {
      setConfig(value)
    }
  }

  const diviergingPoint = typeof config === 'object' ? config[valueKey] === value : config === value

  const boxStyle = {
    width: '12rem',
    height: '13.2rem',
    cursor: isDisabled ? 'auto' : 'pointer',
    borderRadius: '1rem',
    border: diviergingPoint
      ? `${borderWidth}px solid ${theme.palette.draph.newBlue}`
      : `${borderWidth}px solid rgba(0,0,0,0)`,
    transition: 'border 0.3s',
    // boxShadow: '4px 4px 10px 0px rgba(0, 0, 0, 0.1)',
    ...boxStyleAdd,
  }
  const sourceStyle = {
    cursor: 'pointer',
    alignItems: 'center',
    width: '12rem',
    height: '2.8rem',
    // position: 'absolute',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    // bottom: 0,
    backgroundColor: diviergingPoint ? theme.palette.draph.newBlue : theme.palette.common.black,
    color: theme.palette.common.white,
    transition: 'all 0.3s',
    fontSize: '1.3rem',
    lineHeight: 1,
    fontWeight: 700,
    ...sourceStyleAdd,
  }

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <img
          src={imageSrc}
          style={{
            width: '12rem',
            height: '10.4rem',
            // height: '14rem',
            cursor: 'pointer',
            borderRadius: '1rem 1rem 0 0',
            objectFit: 'cover',

            ...imageStyleAdd,
          }}
        />
        <Box
          style={boxStyle}
          sx={{ position: 'absolute', top: 0 }}
          onClick={handleClick(value)}
        ></Box>
        <CenterAlignBox sx={sourceStyle} onClick={handleClick(value)}>
          {source}
        </CenterAlignBox>
      </Box>
    </>
  )
}
