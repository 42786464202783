import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Pagination,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { PortfolioCard, PortfolioTable, CustomPagination } from 'components'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { defaultPortfolioAtom, portfolioAtom, viewModeAtom } from 'atoms'
import { useEffect, useRef, useState } from 'react'
import { PuffLoader as Loader } from 'react-spinners'
import { useTheme } from '@mui/material/styles'
import { filteredPortfolioSelector } from 'selector'
import { useInView } from 'react-intersection-observer'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination as swiperPagination, Scrollbar } from 'swiper'

import 'swiper/css/scrollbar'
import 'swiper/css'
import 'assets/css/App.css'
import { Desktop, Mobile, useDesktopMediaQuery } from 'hooks/useMediaQuery'
import { MOBILE_PORTFOLIO_WIDTH_LIMIT } from 'pages/Portfolio'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'

function ThumbnailView({ setIsLoading, setPortfolio }) {
  const PORTFOLIOS_PER_PAGE = 20
  const filteredPortfolios = useRecoilValue(filteredPortfolioSelector)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [viewMode, setViewMode] = useRecoilState(viewModeAtom)

  const [page, setPage] = useState(1)
  const [pageData, setPageData] = useState(null)
  const isDesktop = useDesktopMediaQuery()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const pages = queryParams.get('pages') || 1
  const navigate = useNavigate()

  useEffect(() => {
    setPage(Number(pages))
  }, [pages])

  useEffect(() => {
    if (filteredPortfolios) {
      const p = defaultPortfolio.id
        ? [{ ...defaultPortfolio }, ...filteredPortfolios]
        : filteredPortfolios
      const a = (page - 1) * PORTFOLIOS_PER_PAGE
      const b = page * PORTFOLIOS_PER_PAGE
      const pData = p?.slice(a, b) ?? []
      setPageData(pData)
    } else {
      setPageData(null)
    }
  }, [defaultPortfolio, filteredPortfolios, page])

  useEffect(() => {
    if (viewMode.type === 'thumbnail' && viewMode.page) {
      setPage(viewMode.page)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setViewMode(m => ({ ...m, page }))
  }, [page])

  const handlePageChange = (event, value) => {
    setPage(value)
    navigate(`/portfolio?pages=${value}`)
  }
  return (
    <CenterAlignStack sx={{ pb: 5 }}>
      <Grid
        container
        columns={12}
        spacing={0}
        sx={{
          py: { lg: '3.7rem', md: '3.5rem', sm: '3.5rem', xs: '3.5rem' },
          width: isDesktop ? '119rem' : MOBILE_PORTFOLIO_WIDTH_LIMIT,
          maxWidth: isDesktop ? '119rem' : '36rem',
          // px: { lg: '5.7rem', md: '2rem', sm: '2rem', xs: '2rem' },
        }}
      >
        {!pageData ? (
          <CenterAlignBox sx={{ width: '100%' }}>
            <Loader />
          </CenterAlignBox>
        ) : pageData.length < 1 ? (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography>포트폴리오가 없습니다.</Typography>
          </Grid>
        ) : (
          pageData?.map((data, idx) => {
            const thumbImages = data?.thumb
              .filter(x => x)
              .slice(0, 4)
              .map(l => l.replace('/shadow_results/', '/thumb/'))
            return (
              <Grid
                item
                lg={3}
                md={6}
                sm={6}
                xs={6}
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                key={idx}
                sx={{
                  // px: { lg: '2.8rem', md: '1.4rem', sm: '1rem', xs: '0.8rem' },
                  // px: '2.8rem',
                  py: { lg: '2.8rem', md: '1.2rem', sm: '1.2rem', xs: '1.2rem' },
                }}
              >
                <PortfolioCard
                  data={data}
                  userId={data.user_id}
                  username={data.username}
                  portfolioName={data.name}
                  portfolioId={data.id}
                  img={data.img}
                  title={data.name}
                  date={data.created.slice(0, 10)}
                  thumbImages={thumbImages}
                  setIsLoading={setIsLoading}
                  setPortfolio={setPortfolio}
                  idx={idx}
                />
              </Grid>
            )
          })
        )}
      </Grid>

      {filteredPortfolios?.length > PORTFOLIOS_PER_PAGE && (
        <CustomPagination
          count={Math.ceil(filteredPortfolios.length / PORTFOLIOS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </CenterAlignStack>
  )
}

function DateSortedView({ setIsLoading, setPortfolio }) {
  const portfolio = useRecoilValue(portfolioAtom)
  const filteredPortfolios = useRecoilValue(filteredPortfolioSelector)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [sortedPortfolio, setSortedPortfolio] = useState([])
  const [visiblePortfolio, setVisiblePortfolio] = useState(null)
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [portfoliosByDate, setPortfoliosByDate] = useState({})
  const [viewMode, setViewMode] = useRecoilState(viewModeAtom)

  const [count, setcount] = useState(0)

  const [ref, inView] = useInView()

  const theme = useTheme()

  const PORTFOLIOS_PER_PAGE = 2

  useEffect(() => {
    if (!filteredPortfolios) return
    const portfolioData = [defaultPortfolio, ...filteredPortfolios]

    const copyPortfoliosByDate = {}

    portfolioData.forEach(p => {
      const date = p.created.slice(0, 10)

      if (!copyPortfoliosByDate[date]) {
        copyPortfoliosByDate[date] = []
      }
      copyPortfoliosByDate[date].push(p)
    })
    setPortfoliosByDate(copyPortfoliosByDate)

    const sorted = Object.keys(copyPortfoliosByDate).sort((a, b) => new Date(b) - new Date(a))
    setSortedPortfolio(sorted)
  }, [portfolio, filteredPortfolios])

  useEffect(() => {
    if (inView && hasNextPage) {
      setPage(page + 1)
    }

    const nextVisible = sortedPortfolio.slice(0, page * PORTFOLIOS_PER_PAGE)
    setHasNextPage(nextVisible.length < sortedPortfolio.length)
    setVisiblePortfolio(nextVisible)
  }, [inView, hasNextPage, page, sortedPortfolio, portfoliosByDate])

  return (
    <CenterAlignStack sx={{ width: '100%', pb: '5rem' }}>
      {!visiblePortfolio ? (
        <></>
      ) : visiblePortfolio.length < 1 ? (
        <CenterAlignStack sx={{ py: '3.7rem' }}>
          <Typography>포트폴리오가 없습니다.</Typography>
        </CenterAlignStack>
      ) : (
        <>
          <Desktop>
            {visiblePortfolio.map((date, i) => {
              const scrollbarClassName = 'portfolio-date-swiper-scrollbar-' + i
              return (
                <CenterAlignStack
                  key={date}
                  sx={{
                    width: 'inherit',
                    pt: '6rem',
                    px: '5rem',
                    ...swiperScrollbarStyle,
                  }}
                >
                  <Typography fontSize="4rem" fontWeight={900}>
                    {date ? moment(moment(date)).format('YYYY.MM.DD') : ''}
                  </Typography>
                  <Swiper
                    className="portfolio-date-swiper"
                    scrollbar={{
                      el: '.' + scrollbarClassName,
                      hide: false,
                      draggable: true,
                    }}
                    // pagination={{ clickable: true }}
                    slidesPerView={'auto'}
                    spaceBetween={1}
                    modules={[Scrollbar]}
                    style={{ width: '100%' }}
                  >
                    {portfoliosByDate[date]?.map((p, idx) => (
                      <SwiperSlide key={idx} style={{ width: 'auto' }}>
                        <PortfolioCard
                          data={p}
                          userId={p.user_id}
                          portfolioId={p.id}
                          img={p.img}
                          title={p.name}
                          date={p.created}
                          theme={p.theme}
                          setIsLoading={setIsLoading}
                          setPortfolio={setPortfolio}
                          idx={idx}
                          thumbImages={p.thumb
                            .filter(x => x)
                            .slice(0, 4)
                            .map(l => l.replace('/shadow_results/', '/thumb/'))}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div
                    className={'swiper-scrollbar swiper-scrollbar-horizontal ' + scrollbarClassName}
                    style={{ position: 'relative' }}
                  />
                </CenterAlignStack>
              )
            })}
          </Desktop>
          <Mobile>
            {visiblePortfolio.map((date, i) => {
              return (
                <CenterAlignStack
                  key={date}
                  sx={{
                    width: 'inherit',
                    pt: '3.9rem',
                  }}
                >
                  <Typography fontSize="2rem" fontWeight={900} sx={{ mb: '0.8rem' }}>
                    {moment(moment(date)).format('YYYY.MM.DD')}
                  </Typography>

                  {portfoliosByDate[date]?.map((p, idx) => (
                    <PortfolioCard
                      key={idx}
                      data={p}
                      userId={p.user_id}
                      portfolioId={p.id}
                      img={p.img}
                      title={p.name}
                      date={p.created}
                      theme={p.theme}
                      setIsLoading={setIsLoading}
                      setPortfolio={setPortfolio}
                      idx={idx}
                      thumbImages={p.thumb
                        .filter(x => x)
                        .slice(0, 4)
                        .map(l => l.replace('/shadow_results/', '/thumb/'))}
                    />
                  ))}
                </CenterAlignStack>
              )
            })}
          </Mobile>
        </>
      )}

      {hasNextPage && (
        <div ref={ref}>
          <CenterAlignBox sx={{ width: '100%', mt: '3.7rem' }}>
            <Loader />
          </CenterAlignBox>
        </div>
      )}
    </CenterAlignStack>
  )
}

const swiperScrollbarStyle = {
  '& .swiper-scrollbar': {
    height: '7px !important',
    background: '#F2F2F2',
  },
  '& .swiper-scrollbar-drag': {
    background: '#97B4FD',
  },
}

export function ShowingPortfolio({ setIsLoading, setPortfolio }) {
  const viewMode = useRecoilValue(viewModeAtom)

  switch (viewMode.type) {
    case 'thumbnail':
      return <ThumbnailView setIsLoading={setIsLoading} setPortfolio={setPortfolio} />
    case 'date':
      return <DateSortedView setIsLoading={setIsLoading} setPortfolio={setPortfolio} />
    case 'table':
      return <PortfolioTable setIsLoading={setIsLoading} setPortfolio={setPortfolio} />
  }
}
