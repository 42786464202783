import { Container, Box } from '@mui/material'
import { DialogButton, ShadowButton } from 'components'
import { English, Korean } from 'hooks/useLanguage'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const serviceH3Style = {
  fontSize: 20,
  marginTop: '32px',
  marginBottom: '15px',
  marginLeft: '14px',
  fontWeight: 600,
}
const serviceH4Style = {
  marginBottom: '8px',
  marginTop: '12px',
  fontSize: 17.5,
}
const servicePStyle = { paddingLeft: '10px', fontSize: 15.5 }
const serviceOlStyle = { listStyleType: 'decimal', marginLeft: '3rem' }

const serviceOlStyleEN = {
  listStyleType: 'decimal',
  '& li': { paddingBottom: '8px', paddingTop: '8px' },
}

const privacyH3Style = {
  marginTop: '32px',
  marginBottom: '5px',
  fontWeight: 600,
}
const privacyLiStyle1 = { listStyleType: 'decimal', paddingLeft: '20px' }
const privacyLiStyle2 = { listStyleType: 'decimal', paddingLeft: '18px' }

const nestedLiStyle = { marginLeft: '1.5rem' }
export default function Privacy() {
  const { version } = useParams()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const privacyVersion = {
    1: (
      <Container sx={{ py: '3rem' }}>
        <Korean>
          <h2
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              fontWeight: 800,
            }}
          >
            주식회사 드랩 개인정보 처리방침
          </h2>
          <p>
            “회사”는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의
            목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」
            제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.{' '}
          </p>
          {/* 1조 */}
          <h3 style={privacyH3Style}>제1조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(개인정보의 처리목적)</h3>
          <p>
            회사”는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적
            이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」
            제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
          </p>
          <ol style={{ listStyleType: 'decimal', paddingLeft: '35px' }}>
            <li>
              서비스 회원가입 및 관리: 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
              회원자격 유지·관리, 서비스 부정이용 방지합니다.{' '}
            </li>
            <li>
              재화 또는 서비스 제공: 물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공,
              요금결제·정산을 목적으로 개인정보를 처리합니다.{' '}
            </li>
            <li>
              마케팅 및 광고에의 활용: 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성
              정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 ,
              서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로
              개인정보를 처리합니다.{' '}
            </li>
          </ol>
          {/* 2조 */}
          <h3 style={privacyH3Style}>
            제2조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(개인정보의 보유 및 이용 기간)
          </h3>
          <ol style={privacyLiStyle1}>
            <li>
              “회사”는 법령에 따른 개인정보의 보유 및 이용기간 또는 정보주체로부터 개인정보를 수집
              시에 동의받은 개인정보의 보유 및 이용기간 내에서 개인정보를 보유 및 이용합니다.{' '}
            </li>
            <li>각각의 개인정보 보유 및 이용 기간은 다음과 같습니다. </li>
            <ol style={privacyLiStyle2}>
              <li>
                서비스 회원가입 및 관리: 회원 탈퇴시까지. 다만, 다음의 사유에 해당하는 경우에는 해당
                기간 종료시까지
              </li>
              <ol style={privacyLiStyle2}>
                <li>
                  관계 법령 위반에 따른 수사·조사 등이 진행중인 경우: 해당 수사·조사 종료시까지{' '}
                </li>
                <li>서비스 이용에 따른 채권·채무관계 잔존시: 해당 채권·채무관계 정산시까지 </li>
              </ol>
              <li>
                재화 또는 서비스 제공: 재화 또는 서비스의 공급 완료 및 관련 요금의 결제·정산
                완료시까지. 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지
              </li>
              <ol style={privacyLiStyle2}>
                <li>
                  「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행
                  등 거래에 관한 기록
                </li>
                <ol style={privacyLiStyle2}>
                  <li>표시·광고에 관한 기록: 6개월 </li>
                  <li>계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년 </li>
                  <li>소비자 불만 또는 분쟁처리에 관한 기록: 3년 </li>
                </ol>

                <li>「통신비밀보호법」에 따른 통신사실확인자료 보관</li>
                <ol style={privacyLiStyle2}>
                  <li>
                    가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국
                    위치추적자료: 1년{' '}
                  </li>
                  <li>컴퓨터통신, 인터넷 로그기록자료, 접속자 추적자료: 3개월 </li>
                </ol>
              </ol>

              <li>마케팅 및 광고에의 활용: 회원 탈퇴시까지</li>
            </ol>
          </ol>
          {/* 3조 */}
          <h3 style={privacyH3Style}>
            제3조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(처리하는 개인정보의 항목)
          </h3>
          <p>“회사”는 다음의 개인정보 항목을 처리하고 있습니다.</p>
          <ol style={{ listStyleType: 'decimal', paddingLeft: '35px' }}>
            <li>홈페이지 회원가입 및 관리</li>

            <ol style={privacyLiStyle2}>
              <li>필수항목 : 이메일, 비밀번호, 로그인ID, 성별, 이름, 회사명 </li>
              <li>
                선택항목 : 휴대전화번호, 회사전화번호, 법정대리인 이름, 법정대리인 자택 전화번호,
                법정대리인 자택 주소, 법정대리인 휴대전화번호{' '}
              </li>
            </ol>
            <li>재화 또는 서비스 제공</li>

            <ol style={privacyLiStyle2}>
              <li>필수항목 : 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록</li>
              <li>선택항목 : 신용카드정보, 은행계좌정보</li>
            </ol>
            <li>마케팅 및 광고에의 활용</li>
            <ol style={privacyLiStyle2}>
              <li>필수항목 : 없음 </li>
              <li>
                선택항목 : 이메일, 휴대전화번호, 회사전화번호, 서비스 이용 기록, 접속 로그, 쿠키,
                접속 IP 정보, 결제기록, 생성물
              </li>
            </ol>
          </ol>

          {/* 6조 */}
          <h3 style={privacyH3Style}>
            제4조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(개인정보처리의 위탁에 관한 사항)
          </h3>
          <ol style={privacyLiStyle1}>
            <li>
              “회사”는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고
              있습니다.{' '}
            </li>
            <ol style={privacyLiStyle2}>
              <li>개인정보처리를 위탁받는 자: (주)슬로워크(스티비), 문자발송업체</li>
              <ol style={privacyLiStyle2}>
                <li>
                  위탁하는 업무의 내용: 본인인증(금융거래, 금융서비스), 요금 결제 및 추심, 전자적
                  전송 매체(이메일, SMS(MMS), SNS 메시지, 전화 등)를 활용한 뉴스레터 및 마케팅
                  컨텐츠 발송
                </li>
                <li>위탁기간: 위탁업무 종료 후 지체없이 파기 </li>
              </ol>
            </ol>

            <li>
              “회사”는 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외
              개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독,
              손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게
              처리하는지를 감독하고 있습니다.{' '}
            </li>
            <li>
              위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여
              공개하도록 하겠습니다.{' '}
            </li>
          </ol>
          {/* 7조 */}
          <h3 style={privacyH3Style}>
            제5조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (개인정보의 국외 이전에 관한 사항)
          </h3>
          <p>해당 사항 없음</p>

          {/* 8조 */}
          <h3 style={privacyH3Style}>
            제6조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(개인정보의 파기절차 및 파기방법)
          </h3>
          <ol style={privacyLiStyle1}>
            <li>
              “회사”는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을
              때에는 지체없이 해당 개인정보를 파기합니다.{' '}
            </li>
            <li>
              정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도
              불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를
              별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.{' '}
            </li>
            <li>개인정보 파기의 절차 및 방법은 다음과 같습니다. </li>
            <ol style={privacyLiStyle2}>
              <li>
                파기절차: “회사”는 파기 사유가 발생한 개인정보를 선정하고, “회사”의 개인정보
                보호책임자의 승인을 받아 개인정보를 파기합니다.{' '}
              </li>
              <li>
                파기방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.{' '}
              </li>
            </ol>
          </ol>

          <h3 style={privacyH3Style}>
            제7조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(정보주체와 법정대리인의 권리·의무 및 그 행사방법에
            관한 사항)
          </h3>
          <ol style={privacyLiStyle1}>
            <li>
              정보주체는 “회사”에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를
              행사할 수 있습니다.
            </li>
            <li>
              전항에 따른 권리 행사는 “회사”에 대해 「개인정보 보호법 시행령」 제41조제1항에 따라
              서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 “회사”는 이에 대해 지체 없이
              조치하겠습니다.
            </li>
            <li>
              제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여
              하실 수 있습니다. 이 경우 「개인정보 처리 방법에 관한 고시(제2020-7호) 」 별지 제11호
              서식에 따른 위임장을 제출하셔야 합니다.
            </li>
            <li>
              본조에 따른 권리행사는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 제한될
              수 있습니다.
            </li>
            <li>
              개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
              경우 제한될 수 있습니다.{' '}
            </li>
            <li>
              “회사”는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구시 열람 등
              요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.{' '}
            </li>
          </ol>
          {/* 11조 */}
          <h3 style={privacyH3Style}>
            제8조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(개인정보의 안전성 확보조치에 관한 사항)
          </h3>
          <p>“회사”는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
          <ol style={{ listStyleType: 'decimal', paddingLeft: '35px' }}>
            <li>관리적 조치 </li>
            <ol style={privacyLiStyle2}>
              <li>
                정기적인 자체 감사 실시: 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로
                자체 감사를 실시하고 있습니다.{' '}
              </li>
              <li>
                개인정보 취급 직원의 최소화 및 교육: 개인정보를 취급하는 직원을 지정하고 담당자에
                한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.{' '}
              </li>
              <li>
                내부관리계획의 수립 및 시행: 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고
                시행하고 있습니다.{' '}
              </li>
            </ol>
            <li>기술적 조치</li>
            <ol style={privacyLiStyle2}>
              <li>
                해킹 등에 대비한 기술적 대책: “회사”는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보
                유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며
                외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적·물리적으로 감시 및 차단하고
                있습니다.{' '}
              </li>
              <li>
                개인정보의 암호화: 이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어,
                본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금
                기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.{' '}
              </li>
              <li>
                접속기록의 보관 및 위변조 방지: 개인정보처리시스템에 접속한 기록을 최소 1년 이상
                보관, 관리하고 있으며, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나,
                고유식별정보 또는 민감정보를 처리하는 경우에는 2년 이상 보관, 관리하고 있습니다.
                또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.{' '}
              </li>
            </ol>
            <li>물리적 조치 </li>
            <ol style={privacyLiStyle2}>
              <li>
                개인정보에 대한 접근 제한: 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                부여·변경·말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며
                침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.{' '}
              </li>
              <li>
                문서보안을 위한 잠금장치 사용: 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가
                있는 안전한 장소에 보관하고 있습니다.{' '}
              </li>
              <li>
                비인가자에 대한 출입통제: 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고
                이에 대해 출입통제 절차를 수립, 운영하고 있습니다.{' '}
              </li>
            </ol>
          </ol>
          {/* 12조 */}
          <h3 style={privacyH3Style}>
            제9조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그
            거부에 관한 사항)
          </h3>
          <ol style={privacyLiStyle1}>
            <li>
              “회사”는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로
              불러오는 ‘쿠키(cookie)’를 사용합니다.{' '}
            </li>
            <li>
              쿠키는 웹사이트를 운영하는데 이용되는 서버가 다음과 같이 이용자의 컴퓨터 브라우저에게
              보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.{' '}
            </li>
            <ol style={privacyLiStyle2}>
              <li>
                쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태,
                인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해
                사용됩니다.{' '}
              </li>
              <li>
                쿠키의 설치·운영 및 거부: 아래 방법을 통해 쿠키 저장을 거부할 수 있습니다. <br />
                [web] <br /> - Internet Explorer 웹 브라우저의 경우 : 웹브라우저 상단의 도구 &gt;
                인터넷 옵션 &gt;개인정보 메뉴의 옵션 설정 <br />
                - Microsoft Edge 웹 브라우저의 경우 : 웹브라우저 상단 메뉴 &gt; 설정 &gt; 고급 설정
                보기 &gt; 쿠키 메뉴의 옵션 설정 <br />
                - Chrome 웹브라우저의 경우 : 웹브라우저 상단 메뉴 &gt; 설정 &gt; 고급 &gt; 콘텐츠
                설정 &gt; 쿠키 메뉴의 옵션 설정 <br />
                - Chrome 모바일의 경우 : 크롬 App &gt; 오른쪽상단 더보기 &gt; 방문 기록 인터넷 사용
                기록 삭제 &gt; 기간선택 &gt; 쿠키 및 사이트 데이터와 &quot;캐시된 이미지 또는
                파일&quot; 옆의 체크박스를 선택 &gt; 인터넷 사용기록 삭제 <br />- Safari 모바일의
                경우 : Safari App &gt; 방문기록 및 웹사이트 데이터 지우기 &gt; 확인 <br />
                - Naver 모바일의 경우 : Naver App &gt; 설정 &gt; 캐시삭제 + 인터넷 사용 기록 &gt;
                쿠키삭제 <br />
              </li>
              <li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다. </li>
            </ol>
          </ol>
          {/* 13조 */}
          <h3 style={privacyH3Style}>
            제10조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)
          </h3>
          <ol style={privacyLiStyle1}>
            <li>
              “회사”는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인
              맞춤형 광고 등을 제공하기 위하여 행태정보를 수집·이용하고 있습니다.{' '}
            </li>
            <li>“회사”는 다음과 같이 행태정보를 수집합니다. </li>
            <ol style={privacyLiStyle2}>
              <li>
                수집하는 행태정보의 항목: 이용자의 웹사이트/앱 내 방문, 검색, 구매 등 서비스
                이용이력{' '}
              </li>
              <li>행태정보 수집 방법: 이용자의 웹사이트/앱 이용시 자동으로 수집 </li>
              <li>
                행태정보 수집 목적: 이용자의 관심, 성향에 기반한 개인 맞춤형
                상품추천서비스(광고포함)의 제공{' '}
              </li>
              <li>행태정보 보유·이용기간 및 이후 정보처리 방법: 회원 탈퇴시 파기 </li>
            </ol>

            <li>
              “회사”는 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집·처리하도록 허용하고
              있습니다.{' '}
            </li>
            <ol style={privacyLiStyle2}>
              <li>행태정보를 수집 및 처리하려는 광고 사업자: </li>
              <li>
                행태정보 수집 방법: 이용자가 당사 웹사이트를 방문하거나 앱을 실행할 때 자동 수집 및
                전송{' '}
              </li>
              <li>수집·처리되는 행태정보 항목: 이용자의 웹/앱 방문이력, 검색이력, 구매이력 </li>
              <li>보유·이용기간: </li>
            </ol>
            <li>
              “회사”는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념,
              가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을
              뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.{' '}
            </li>
            <li>
              “회사”는 모바일 앱에서 온라인 맞춤형 광고를 위하여 광고식별자를 수집·이용합니다.
              정보주체는 모바일 단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단·허용할 수
              있습니다.{' '}
            </li>
            <li>
              정보주체는 웹브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 광고를 일괄적으로
              차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의
              이용에 영향을 미칠 수 있습니다.{' '}
            </li>
          </ol>
          {/* 14조 */}
          <h3 style={privacyH3Style}>
            제11조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(추가적인 이용·제공 판단기준)
          </h3>
          <p>
            “회사”는 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣
            제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할
            수 있습니다. 이에 따라 “회사”가 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서
            다음과 같은 사항을 고려하였습니다.{' '}
          </p>
          <ol style={{ listStyleType: 'decimal', paddingLeft: '35px' }}>
            <li>
              개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부{' '}
            </li>
            <li>
              개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측
              가능성이 있는지 여부{' '}
            </li>
            <li>개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부 </li>
            <li>가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부 </li>
          </ol>
          {/* 15조 */}
          <h3 style={privacyH3Style}>
            제12조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(개인정보 보호책임자에 관한 사항)
          </h3>
          <ol style={privacyLiStyle1}>
            <li>
              “회사”는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
              정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고
              있습니다.{' '}
            </li>
            <Box sx={{ mr: 1, pl: 1, mb: 1 }}>
              개인정보 보호책임자
              <ul style={{ listStyleType: 'circle', paddingLeft: '22px' }}>
                <li>부서명: 데이터팀 </li>
                <li>담당자: 김태훈 </li>
                <li>연락처: hi@draph.ai</li>
              </ul>
            </Box>
            <li>
              정보주체는 “회사”의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 문의,
              불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수
              있습니다. “회사”는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.{' '}
            </li>
          </ol>
          <h3 style={privacyH3Style}>
            제13조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(개인정보의 열람청구를 접수·처리하는 부서)
          </h3>
          <p>
            정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람청구를 전조의 개인정보보호
            담당부서에 할 수 있습니다. “회사”는 정보주체의 개인정보 열람청구가 신속하게 처리되도록
            노력하겠습니다.
          </p>
          <ul style={{ listStyleType: 'circle', paddingLeft: '22px' }}>
            <li>부서명: 데이터팀 </li>
            <li>담당자: 김태훈 </li>
            <li>연락처: hi@draph.ai</li>
          </ul>
          <h3 style={privacyH3Style}>
            제14조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(정보주체의 권익침해에 대한 구제방법)
          </h3>
          <ol style={privacyLiStyle1}>
            <li>
              정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회,
              한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
              이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
            </li>
            <ol style={privacyLiStyle2}>
              <li>개인정보분쟁조정위원회: (국번없이) 1833-6972, www.kopico.go.kr </li>
              <li>개인정보침해신고센터: (국번없이) 118, privacy.kisa.or.kr </li>
              <li>대검찰청: (국번없이) 1301, www.spo.go.kr </li>
              <li>경찰청: (국번없이) 182, ecrm.cyber.go.kr </li>
            </ol>

            <li>
              “회사”는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해
              구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 제12조의 개인정보보호
              담당부서로 연락해 주시기 바랍니다.{' '}
              <ul style={{ listStyleType: 'circle', paddingLeft: '22px' }}>
                <li>부서명: 데이터팀 </li>
                <li>담당자: 김태훈 </li>
                <li>연락처: hi@draph.ai</li>
              </ul>
            </li>
          </ol>

          <h3 style={privacyH3Style}>
            제15조&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(개인정보 처리방침의 변경에 관한 사항)
          </h3>
          <ol style={privacyLiStyle1}>
            <li>이 개인정보처리방침은 2022년 12월 12부터 적용됩니다. </li>
          </ol>
        </Korean>

        <English>
          <h2
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              fontWeight: 800,
            }}
          >
            AGREEMENT TO OUR LEGAL TERMS
          </h2>
          <p style={{ textAlign: 'end' }}>Last updated December 12, 2022 </p>
          <br />
          <p>
            This privacy notice for Draph, Inc (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;),
            describes how and why we might collect, store, use, and/or share (&quot;process&quot;)
            your information when you use our services (&quot;Services&quot;), such as when you:{' '}
            <br /> <br />
            Questions or concerns? Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and practices, please do not
            use our Services.
          </p>
          <br />
          <h3> SUMMARY OF KEY POINTS </h3>
          <p>
            This summary provides key points from our privacy notice, but you can find out more
            details about any of these topics by clicking the link following each key point or by
            using our table of contents below to find the section you are looking for.
          </p>
          <br />
          <p>
            What personal information do we process? When you visit, use, or navigate our Services,
            we may process personal information depending on how you interact with us and the
            Services, the choices you make, and the products and features you use. Learn more about
            personal information you disclose to us.
          </p>
          <br />
          <p>
            Do we process any sensitive personal information? We do not process sensitive personal
            information.
          </p>
          <br />
          <p>
            Do we receive any information from third parties? We may receive information from public
            databases, marketing partners, social media platforms, and other outside sources. Learn
            more about information collected from other sources.
          </p>
          <br />
          <p>
            How do we process your information? We process your information to provide, improve, and
            administer our Services, communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other purposes with your
            consent. We process your information only when we have a valid legal reason to do so.
            Learn more about how we process your information.
          </p>
          <br />
          <p>
            In what situations and with which parties do we share personal information? We may share
            information in specific situations and with specific third parties. Learn more about
            when and with whom we share your personal information.
          </p>
          <br />
          <p>
            What are your rights? Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your personal information. Learn
            more about your privacy rights.
          </p>
          <br />
          <p>
            How do you exercise your rights? The easiest way to exercise your rights is by
            submitting a data subject access request, or by contacting us. We will consider and act
            upon any request in accordance with applicable data protection laws.
          </p>
          <br />
          <p>
            Want to learn more about what we do with any information we collect? Review the privacy
            notice in full.
          </p>
          <br />
          <h2
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              fontWeight: 800,
              marginTop: '20px',
            }}
          >
            TABLE OF CONTENTS
          </h2>
          <Box component={'ol'} sx={{ ml: '3rem', ...serviceOlStyleEN }}>
            <li>WHAT INFORMATION DO WE COLLECT? </li>
            <li> HOW DO WE PROCESS YOUR INFORMATION? </li>
            <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? </li>
            <li> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </li>
            <li> HOW DO WE HANDLE YOUR SOCIAL LOGINS? </li>
            <li> IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? </li>
            <li>HOW LONG DO WE KEEP YOUR INFORMATION? </li>
            <li> DO WE COLLECT INFORMATION FROM MINORS? </li>
            <li>WHAT ARE YOUR PRIVACY RIGHTS? </li>
            <li> CONTROLS FOR DO-NOT-TRACK FEATURES </li>
            <li> DO WE MAKE UPDATES TO THIS NOTICE? </li>
            <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </li>
            <li> HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? </li>
          </Box>

          <h4 style={serviceH4Style}>1. WHAT INFORMATION DO WE COLLECT?</h4>
          <br />
          <h5 style={serviceH4Style}>Personal information you disclose to us </h5>
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> We collect personal information that you provide to us.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We collect personal information that you voluntarily provide to us when you register on
            the Services, express an interest in obtaining information about us or our products and
            Services, when you participate in activities on the Services, or otherwise when you
            contact us.
          </p>

          <br />
          <p style={serviceOlStyleEN}>
            Sensitive Information. We do not process sensitive information.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            All personal information that you provide to us must be true, complete, and accurate,
            and you must notify us of any changes to such personal information.
          </p>
          <br />
          <h5 style={serviceH4Style}>Information automatically collected </h5>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> Some information — such as your Internet Protocol (IP)
            address and/or browser and device characteristics — is collected automatically when you
            visit our Services.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We automatically collect certain information when you visit, use, or navigate the
            Services. This information does not reveal your specific identity (like your name or
            contact information) but may include device and usage information, such as your IP
            address, browser and device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about how and when you use
            our Services, and other technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our internal analytics and
            reporting purposes.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Like many businesses, we also collect information through cookies and similar
            technologies.
          </p>
          <br />

          <h4 style={serviceH4Style}>2. HOW DO WE PROCESS YOUR INFORMATION? </h4>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> We process your information to provide, improve, and
            administer our Services, communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other purposes with your
            consent.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We process your personal information for a variety of reasons, depending on how you
            interact with our Services, including:
          </p>
          <br />

          <h4 style={serviceH4Style}>
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?{' '}
          </h4>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> We may share information in specific situations described in
            this section and/or with the following third parties.
          </p>
          <br />
          <ol>
            We may need to share your personal information in the following situations:
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              <strong>Business Transfers.</strong> We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business to another company.
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              <strong>Affiliates.</strong> We may share your information with our affiliates, in
              which case we will require those affiliates to honor this privacy notice. Affiliates
              include our parent company and any subsidiaries, joint venture partners, or other
              companies that we control or that are under common control with us.
            </li>
            <li style={{ listStyleType: 'circle', marginLeft: '20px' }}>
              <strong>Business Partners.</strong>We may share your information with our business
              partners to offer you certain products, services, or promotions.
            </li>
          </ol>
          <br />

          <h4 style={serviceH4Style}>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> We may use cookies and other tracking technologies to collect
            and store your information.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We may use cookies and similar tracking technologies (like web beacons and pixels) to
            access or store information. Specific information about how we use such technologies and
            how you can refuse certain cookies is set out in our Cookie Notice.
          </p>
          <br />

          <h4 style={serviceH4Style}>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </h4>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> If you choose to register or log in to our Services using a
            social media account, we may have access to certain information about you.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Our Services offer you the ability to register and log in using your third-party social
            media account details (like your Facebook or Twitter logins). Where you choose to do
            this, we will receive certain profile information about you from your social media
            provider. The profile information we receive may vary depending on the social media
            provider concerned, but will often include your name, email address, friends list, and
            profile picture, as well as other information you choose to make public on such a social
            media platform.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We will use the information we receive only for the purposes that are described in this
            privacy notice or that are otherwise made clear to you on the relevant Services. Please
            note that we do not control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend that you review
            their privacy notice to understand how they collect, use, and share your personal
            information, and how you can set your privacy preferences on their sites and apps.
          </p>
          <br />

          <h4 style={serviceH4Style}>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h4>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> We may transfer, store, and process your information in
            countries other than your own.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Our servers are located in. If you are accessing our Services from outside, please be
            aware that your information may be transferred to, stored, and processed by us in our
            facilities and by those third parties with whom we may share your personal information
            (see &quot;WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?&quot; above), in
            and other countries.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or
            Switzerland, then these countries may not necessarily have data protection laws or other
            similar laws as comprehensive as those in your country. However, we will take all
            necessary measures to protect your personal information in accordance with this privacy
            notice and applicable law.
          </p>
          <br />

          <h4 style={serviceH4Style}>7. HOW LONG DO WE KEEP YOUR INFORMATION? </h4>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> We keep your information for as long as necessary to fulfill
            the purposes outlined in this privacy notice unless otherwise required by law.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We will only keep your personal information for as long as it is necessary for the
            purposes set out in this privacy notice, unless a longer retention period is required or
            permitted by law (such as tax, accounting, or other legal requirements).
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            When we have no ongoing legitimate business need to process your personal information,
            we will either delete or anonymize such information, or, if this is not possible (for
            example, because your personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from any further processing
            until deletion is possible.
          </p>
          <br />

          <h4 style={serviceH4Style}>8. DO WE COLLECT INFORMATION FROM MINORS? </h4>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> We do not knowingly collect data from or market to children
            under 18 years of age.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We do not knowingly solicit data from or market to children under 18 years of age. By
            using the Services, you represent that you are at least 18 or that you are the parent or
            guardian of such a minor and consent to such minor dependent’s use of the Services. If
            we learn that personal information from users less than 18 years of age has been
            collected, we will deactivate the account and take reasonable measures to promptly
            delete such data from our records. If you become aware of any data we may have collected
            from children under age 18, please contact us by phone at 82-70-8065-4414, email at
            hi@draph.ai, or by mail to 180, Yeoksam-ro, Gangnam-gu, Seoul, Republic of Korea 06248,
            South Korea.
          </p>
          <br />

          <h4 style={serviceH4Style}>9. WHAT ARE YOUR PRIVACY RIGHTS? </h4>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> You may review, change, or terminate your account at any
            time.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Withdrawing your consent: If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending on the applicable
            law, you have the right to withdraw your consent at any time. You can withdraw your
            consent at any time by contacting us by using the contact details provided in the
            section &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot; below.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            However, please note that this will not affect the lawfulness of the processing before
            its withdrawal nor, when applicable law allows, will it affect the processing of your
            personal information conducted in reliance on lawful processing grounds other than
            consent.
          </p>

          <br />
          <h5 style={serviceH4Style}>Account Information </h5>
          <br />
          <p style={serviceOlStyleEN}>
            If you would at any time like to review or change the information in your account or
            terminate your account, you can:
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            Upon your request to terminate your account, we will deactivate or delete your account
            and information from our active databases. However, we may retain some information in
            our files to prevent fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal requirements.
          </p>
          <br />

          <h4 style={serviceH4Style}>10. CONTROLS FOR DO-NOT-TRACK FEATURES </h4>
          <br />
          <p style={serviceOlStyleEN}>
            Most web browsers and some mobile operating systems and mobile applications include a
            Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your
            privacy preference not to have data about your online browsing activities monitored and
            collected. At this stage no uniform technology standard for recognizing and implementing
            DNT signals has been finalized. As such, we do not currently respond to DNT browser
            signals or any other mechanism that automatically communicates your choice not to be
            tracked online. If a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version of this privacy
            notice.
          </p>
          <br />
          <h4 style={serviceH4Style}>11. DO WE MAKE UPDATES TO THIS NOTICE? </h4>
          <br />
          <p style={serviceOlStyleEN}>
            <strong>In Short:</strong> Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </p>
          <br />
          <p style={serviceOlStyleEN}>
            We may update this privacy notice from time to time. The updated version will be
            indicated by an updated &quot;Revised&quot; date and the updated version will be
            effective as soon as it is accessible. If we make material changes to this privacy
            notice, we may notify you either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this privacy notice
            frequently to be informed of how we are protecting your information.
          </p>
          <br />

          <h4 style={serviceH4Style}>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </h4>
          <br />
          <p style={serviceOlStyleEN}>
            If you have questions or comments about this notice, you may contact us by post at:
          </p>
          <br />
          <ol style={{ listStyleType: 'none' }}>
            <li style={{ fontWeight: 600 }}>Draph, Inc </li>
            <li style={{ fontWeight: 600 }}>180, Yeoksam-ro, Gangnam-gu </li>
            <li style={{ fontWeight: 600 }}>Seoul, Republic of Korea 06248 </li>
            <li style={{ fontWeight: 600 }}>South Korea </li>
            <li style={{ fontWeight: 600 }}>Phone: 82-70-8065-4414 </li>
            <li style={{ fontWeight: 600 }}> hi@draph.ai </li>
          </ol>
          <br />
          <h4 style={serviceH4Style}>
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?{' '}
          </h4>
          <br />
          <p style={serviceOlStyleEN}>
            Based on the applicable laws of your country, you may have the right to request access
            to the personal information we collect from you, change that information, or delete it.
            To request to review, update, or delete your personal information, please fill out and
            submit a data subject access request.
          </p>
          <br />
        </English>
        {/* <ShadowButton
          sx={{ mt: '3rem' }}
          onClick={() => {
            navigate(`/social/privacy/${versionInfo - 1}`)
          }}
          disabled={() => {
            if (versionInfo === 1) return true
          }}
        >
          이전 개인정보처리방침
        </ShadowButton> */}
      </Container>
    ),
  }
  const versionInfo = version || Object.keys(privacyVersion).length

  return privacyVersion[versionInfo]
}
