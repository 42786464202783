import { useEffect, useRef, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { apis } from 'apis'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Button,
  Link,
  IconButton,
  InputAdornment,
  FormGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
  Stack,
  CircularProgress,
  TextField,
  Typography,
  Box,
  Grid,
  Divider,
  MenuItem,
  Dialog,
} from '@mui/material'
import {
  AgreeDialog,
  CenterAlignBox,
  CenterAlignStack,
  FlexBasis,
  RegisterBox,
  TempLayoutDiv,
} from '../components'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

import styled from '@emotion/styled'
import * as yup from 'yup'
import { FormTextField, FormSelect } from 'components'
import { Controller, useFieldArray, useForm, useController } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { EMAIL_REGEX, PHONE_REGEX, PWD_REGEX, isKo } from 'utils/common'

import {
  AgreeCheckedSquareIcon,
  AgreeSquareIcon,
  CheckedSquareIcon,
  CheckedSquareIcon2,
  CheckedSquareIcon3,
  ClosedEyeIcon,
  OpenedEyeIcon,
  SearchIcon,
  SquareIcon,
  SquareIcon2,
} from 'theme/icon'
import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import moment from 'moment'
import { motion } from 'framer-motion'
import { useNavigateForPromotion } from 'hooks/useNavigateWithQuery'
import { promotionInfo } from './promotionEvents/DomeggookEvent'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { English, Korean } from 'hooks/useLanguage'
import {
  AgreementReadButton,
  FormCheckbox,
  FormErrorMessage,
  PhoneInput,
  REGISTER_LABEL_WIDTH_DESKTOP,
  RegisterInputButton,
  RegisterLabel,
  SelectInput,
  TextInput,
} from 'components/RegisterComponents'

// styled(FormTextField)(({ theme }) => ({
//   '& .MuiInputBase-root': {
//     height: '4.6rem',
//   },
// }))

export function CommerceRegisterForm({
  commerceType,
  titleAreaComponent,
  onSubmit,
  showLogin,
  sx,
}) {
  return (
    <>
      <Desktop>
        <Dialog
          open={true}
          sx={{ '& .MuiPaper-root': { my: '6.5rem', maxHeight: 'calc(100% - 130px)' } }}
        >
          <RegisterFormContent
            sx={sx}
            commerceType={commerceType}
            titleAreaComponent={titleAreaComponent}
            onSubmit={onSubmit}
            showLogin={showLogin}
          />
        </Dialog>
      </Desktop>
      <Mobile>
        <CenterAlignStack sx={{ width: '100%' }}>
          <RegisterFormContent
            sx={sx}
            commerceType={commerceType}
            titleAreaComponent={titleAreaComponent}
            onSubmit={onSubmit}
            showLogin={showLogin}
          />
        </CenterAlignStack>
      </Mobile>
    </>
  )
}

function RegisterFormContent({ commerceType, titleAreaComponent, onSubmit, showLogin, sx }) {
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()

  const [categories, setCategories] = useState([])
  const [showPassword, setShowPassword] = useState({
    password: false,
    checkedPassword: false,
  })
  const [accessEmail, setAccessEmail] = useState('')
  const { navigateEvent } = useNavigateForPromotion()

  const [loading, setLoading] = useState(false)
  const [isSendEmail, setIsSendEmail] = useState(false)
  const [emailVerified, setEmailVerified] = useState(false)

  const [idCheckloading, setIdCheckLoading] = useState(false)
  const [idDuplicated, setIdDuplicated] = useState(null)

  const [time, setTime] = useState(180)

  const { enqueueSnackbar } = useSnackbar()

  const theme = useTheme()
  const { showConfirm } = useConfirm()
  const ref = useRef(null)

  // useEffect(() => {
  //   apis.user
  //     .getCategory()
  //     .then(response => {
  //       const categories = response.data
  //       setCategories(categories)
  //     })
  //     .catch(() => {
  //       enqueueSnackbar(t('common.error'), { variant: 'error' })
  //     })
  // }, [])

  // useEffect(() => {
  //   console.log(renderCategories)
  // })

  const RegisterSchema = yup.object({
    username: yup
      .string()
      .required(t('register.id_error_1'))
      .matches(/^[a-zA-Z0-9]+$/, t('register.id_error_2'))
      .test('no-spaces', t('register.id_error_3'), value => !/\s/.test(value))
      .test('id-not-checked', t('register.id_error_4'), value => idDuplicated !== null)
      .test('id-duplicated', t('register.id_error_5'), value => idDuplicated === false)
      .min(2, t('register.id_error_6'))
      .max(20, t('register.id_error_7')),
    email: yup
      .string()
      .matches(EMAIL_REGEX, t('register.email_error_1'))
      // .email('올바른 이메일 형식으로 입력해주세요.')
      .required(t('register.email_error_2')),
    password: yup
      .string()
      .matches(PWD_REGEX, t('register.password_error_1'))
      .min(4, t('register.password_error_2'))
      .max(20, t('register.password_error_3'))
      .required(t('register.password_error_4')),
    checkedPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], t('register.password_check_error_1'))
      .required(' '),
    // company: yup.string().required(t('register.affiliation_error_1')),
    // gender: yup.string().required('성별을 선택해주세요.'),
    phone: yup.string().matches(PHONE_REGEX, {
      message: t('register.mobile_error_1'),
      excludeEmptyString: true,
    }),
    website: yup.string(),
    // category: yup
    //   .array()
    //   .min(1, t('register.category_error_1'))
    //   .of(yup.string().required())
    //   .required(' '),
    agreeAge: yup.boolean().oneOf([true], ' ').required(' '),
    agreeTerms: yup.boolean().oneOf([true], ' ').required(' '),
    agreeData: yup.boolean().oneOf([true], ' ').required(' '),
  })

  const {
    formState: { isDirty, dirtyFields, errors, isSubmitting },
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    setFocus,
    register,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues: {
      username: '',
      email: '',
      emailCodeAccess: '',
      password: '',
      checkedPassword: '',
      nickname: '',
      company: '',
      gender: '',
      phone: '',
      phone1: '010',
      phone2: '',
      phone3: '',
      website: '',
      category: [],
      agreeAge: false,
      agreeTerms: false,
      agreeData: false,
      agreeMarketing: false,
      agreeAdvertisement: false,
    },
    resolver: yupResolver(RegisterSchema),
    mode: 'onSubmit',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'category',
  })

  const onSubmitDefault = values => {
    const createValues = { ...values, language: i18n.language }

    delete createValues.checkedPassword
    delete createValues.phone1
    delete createValues.phone2
    delete createValues.phone3

    if (!emailVerified) {
      showConfirm({ content: '이메일 인증을 진행해주세요.', alertOnly: true })
      return
    }
    // 일반유저 가입
    apis.user
      .createUser(createValues)
      .then(response => {
        // ----- GA4 event -----
        const cateNames = categories
          .filter(c => values.category.includes(c.id.toString()))
          .map(c => c.name)
          .join(',')
        window.gtag('event', 'sign_up', {
          method: 'general',
          date: moment().local().format('YYYY-MM-DD'),
          // user_category: cateNames, // 보류
        })

        window.gtag_report_conversion_register()
        // ---------------------
        // ----- Naver Ads event -----
        var _nasa = {}
        if (window.wcs) _nasa.cnv = window.wcs.cnv('register', 0)
        if (window.runNaScript) window.runNaScript()
        // ----------------------------
        // ----- Meta(facebook) pixel event -----
        window.fbq('track', 'CompleteRegistration')
        // --------------------------------------
        // ----- linkedin event -----
        window.lintrk('track', { conversion_id: 17109481 })
        // --------------------------

        // 로그인 처리
        const d = response.data
        const accessToken = d.access_token
        const refreshToken = d.refresh_token
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)
        navigate('/generate/upload', {
          replace: true,
          state: {
            isNewUser: true,
          },
        })
      })
      .catch(() => {
        // if (error.response.data && error.response.data.detail) {
        //   alert(error.response.data.detail)
        // } else {
        showConfirm({ content: t('common.error'), alertOnly: true })
        // }
      })
  }

  const handleCategoryChange = (category, isChecked) => {
    if (isChecked) {
      append(category.id)
    } else {
      const selectedArray = getValues('category')
      const index = selectedArray.findIndex(item => item === category.id)
      remove(index)
    }
  }

  const onChange = event => {
    const state = event.target.checked

    if (state === true) {
      clearErrors('agreeAge')
      clearErrors('agreeData')
      clearErrors('agreeTerms')
    }

    setValue('agreeAge', state)
    setValue('agreeData', state)
    setValue('agreeTerms', state)
    setValue('agreeMarketing', state)
    setValue('agreeAdvertisement', state)
  }

  useEffect(() => {
    setValue('phone2', getValues('phone2').replace(/[^0-9]/g, ''))
    setValue('phone3', getValues('phone3').replace(/[^0-9]/g, ''))

    if (watch('phone2') === '' && watch('phone3') === '') {
      setValue('phone', '')
    }
  }, [watch('phone2'), watch('phone3')])

  const onClickEmailCode = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      await apis.user.checkDuplicetUser({ email: getValues('email') })
    } catch (error) {
      showConfirm({ content: t('register.email_code_error_1'), alertOnly: true })
      setLoading(false)
      return
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    apis.common
      .sendRegisterCodeMail({
        email: getValues('email'),
        language: window.localStorage.getItem('i18nextLng') ?? 'ko',
      })
      .then(res => {
        if (res.data.status === 'duplicated') {
          setError('emailCodeAccess', {
            type: 'manual',
            name: 'email',
            message: t('register.email_code_error_2'),
          })
        } else {
          setTime(180)
          showConfirm({ content: t('register.email_code_error_3'), alertOnly: true })
        }

        setIsSendEmail(true)
        setLoading(false)

        setFocus('emailCodeAccess')
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        showConfirm({ content: t('register.email_code_error_4'), alertOnly: true })
      })
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  }
  const handleChange = (e, field) => {
    if (e.target.value.toString().length < 6) {
      // field.onChange(e)
      setValue('emailCodeAccess', e.target.value.toString())
    }
  }

  const onClickCodeCheck = () => {
    apis.common
      .verifyRegisterCode({
        email: getValues('email'),
        code: getValues('emailCodeAccess'),
      })
      .then(res => {
        const status = res.data.status

        if (status === 'ok') {
          setValue('emailCodeAccess', true)
          setAccessEmail(getValues('email'))
          setIsSendEmail(false)

          setEmailVerified(true)
        } else {
          setError('emailCodeAccess', {
            type: 'manual',
            name: 'emailCodeAccess',
            message: t('register.email_code_error_6'),
          })
        }
      })
      .catch(error => {
        console.log(error)

        setError('emailCodeAccess', {
          type: 'manual',
          name: 'emailCodeAccess',
          message: t('register.email_code_error_6'),
        })
      })
  }

  const checkDuplicateId = async () => {
    const idCheckSchema = yup
      .string()
      .required(t('register.id_error_1'))
      .matches(/^[a-zA-Z0-9]+$/, t('register.id_error_2'))
      .test('no-spaces', t('register.id_error_3'), value => !/\s/.test(value))

    try {
      idCheckSchema.validateSync(getValues('username'))
    } catch (e) {
      setError('username', { message: e.message })
      return
    }

    setIdCheckLoading(true)

    try {
      await apis.user.checkDuplicetUser({ username: getValues('username') })
    } catch (error) {
      setError('username', { message: t('register.id_error_5') })
      setIdDuplicated(true)
      setIdCheckLoading(false)
      return
    }
    showConfirm({ content: t('register.id_check_complete'), alertOnly: true })
    clearErrors('username')
    setIdDuplicated(false)
    setIdCheckLoading(false)
  }

  const submitWrapper = values => {
    // TODO 주석해제
    // const createValues = { ...values }

    // delete createValues.checkedPassword
    // delete createValues.phone1
    // delete createValues.phone2
    // delete createValues.phone3

    // if (!emailVerified) {
    //   showConfirm({ content: t('register.email_code_error_7'), alertOnly: true })
    //   return
    // }

    onSubmit(values)
  }

  return (
    <>
      <motion.div
        initial={{ x: 10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <form onSubmit={handleSubmit(submitWrapper)} autoComplete="off">
          <CenterAlignStack
            sx={{
              width: { lg: '50rem', xs: '36rem' },
              py: '3.2rem',
              mx: { lg: '4.4rem' },
              ...sx,
            }}
          >
            {/* 타이틀 영역 */}
            {titleAreaComponent}

            <Stack
              direction="row"
              sx={{ width: '100%', alignItems: 'end', justifyContent: 'space-between' }}
            >
              <Typography
                sx={{ fontSize: { lg: '2.4rem', xs: '2rem' }, fontWeight: 700, lineHeight: 1 }}
              >
                {t('register.register_title')}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '1.4rem', xs: '1.2rem' },
                  fontWeight: 500,
                  lineHeight: 1,
                  cursor: 'pointer',
                }}
                onClick={showLogin}
              >
                <Korean>드랩의 기존 회원이신가요 ?</Korean>
                {/* TODO 번역 */}
                <English>Already a member of Draph?</English>
              </Typography>
            </Stack>

            <Divider
              flexItem
              sx={{
                borderColor: theme => theme.palette.common.black,
                mt: '1.2rem',
                mb: '2.4rem',
              }}
            />

            <Stack
              sx={{
                width: '100%',
                '& .input-row-container': {
                  // position: 'relative',
                  // mb: '1.6rem',
                },
                '& .input-row': {
                  justifyContent: 'center',
                  alignItems: { lg: 'center', xs: 'start' },
                  width: '100%',
                  flexDirection: { lg: 'row', xs: 'column' },
                },
                '& .input-wrapper': {
                  width: { lg: `calc(100% - ${REGISTER_LABEL_WIDTH_DESKTOP})`, xs: '100%' },
                },
              }}
            >
              {/* 1. 아이디 */}
              <Box className="input-row-container">
                <CenterAlignStack className="input-row">
                  <RegisterLabel required={true} label={t('register.id')} />
                  <CenterAlignStack direction="row" className="input-wrapper">
                    <TextInput
                      control={control}
                      name="username"
                      placeholder={t('register.id')}
                      onChange={e => {
                        setIdDuplicated(null)
                        setValue('username', e.target.value)
                      }}
                      error={!!errors.username}
                    />
                    {idCheckloading ? (
                      <RegisterInputButton variant="contained" disabled>
                        <CircularProgress size={23} />
                      </RegisterInputButton>
                    ) : (
                      <RegisterInputButton variant="contained" onClick={checkDuplicateId}>
                        {t('register.id_checked')}
                      </RegisterInputButton>
                    )}
                  </CenterAlignStack>
                </CenterAlignStack>
                <FormErrorMessage message={errors.username?.message} />
              </Box>

              {/* 2. 이메일 */}
              <Box className="input-row-container">
                <CenterAlignStack className="input-row">
                  <RegisterLabel required={true} label={t('register.email')} />
                  <CenterAlignStack direction="row" className="input-wrapper">
                    <TextInput
                      control={control}
                      name="email"
                      placeholder={t('register.email')}
                      error={!!errors.email}
                      disabled={emailVerified}
                    />

                    {loading ? (
                      <RegisterInputButton variant="contained" disabled>
                        <CircularProgress size={23} />
                      </RegisterInputButton>
                    ) : (
                      <RegisterInputButton
                        variant="contained"
                        onClick={onClickEmailCode}
                        disabled={!EMAIL_REGEX.test(watch('email')) || emailVerified}
                      >
                        {emailVerified
                          ? t('register.email_button_1')
                          : isSendEmail
                          ? t('register.email_button_2')
                          : t('register.email_button_3')}
                      </RegisterInputButton>
                    )}
                  </CenterAlignStack>
                </CenterAlignStack>
                <FormErrorMessage message={errors.email?.message} />
              </Box>

              {/* 2-1. 인증번호 이메일 발송시 입력칸 */}
              {isSendEmail && (
                <Box className="input-row-container">
                  <CenterAlignStack direction="row" className="input-row">
                    <RegisterLabel required={true} label={t('register.email_code')} />
                    <CenterAlignStack direction="row" className="input-wrapper">
                      <TextInput
                        control={control}
                        style={{ width: '40rem' }}
                        name="emailCodeAccess"
                        placeholder={t('register.email_code')}
                        error={!!errors.emailCodeAccess}
                        onChange={e => handleChange(e)}
                      />

                      <RegisterInputButton variant="contained" onClick={onClickCodeCheck}>
                        {t('register.email_code_button')}
                      </RegisterInputButton>
                    </CenterAlignStack>
                  </CenterAlignStack>

                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      ml: { lg: REGISTER_LABEL_WIDTH_DESKTOP },
                      pl: '0.1rem',
                    }}
                  >
                    {isSendEmail && !errors.emailCodeAccess && (
                      <Timer time={time} setTime={setTime} />
                    )}
                    <FormErrorMessage
                      message={errors.emailCodeAccess?.message}
                      style={{
                        marginLeft: 0,
                      }}
                    />
                  </Box>
                </Box>
              )}

              {/* 3. 비밀번호 */}
              <Box className="input-row-container">
                <CenterAlignStack direction="row" className="input-row">
                  <RegisterLabel required={true} label={t('register.password')} />
                  <CenterAlignStack direction="row" className="input-wrapper">
                    <TextInput
                      name="password"
                      autoComplete="current-password"
                      type={showPassword.password ? 'text' : 'password'}
                      control={control}
                      error={!!errors.password}
                      englishMode={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() =>
                                setShowPassword(prev => ({
                                  ...prev,
                                  password: !prev.password,
                                }))
                              }
                              sx={{ fontSize: '2.4rem' }}
                            >
                              {showPassword.password ? <OpenedEyeIcon /> : <ClosedEyeIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('register.password')}
                    />
                  </CenterAlignStack>
                </CenterAlignStack>
                <FormErrorMessage message={errors.password?.message} />
              </Box>

              {/* 4. 비밀번호 확인 */}
              <Box className="input-row-container">
                <CenterAlignStack direction="row" className="input-row">
                  <RegisterLabel required={true} label={t('register.password_check')} />
                  <CenterAlignStack direction="row" className="input-wrapper">
                    <TextInput
                      name="checkedPassword"
                      autoComplete="current-password"
                      type={showPassword.checkedPassword ? 'text' : 'password'}
                      control={control}
                      error={!!errors.checkedPassword}
                      englishMode={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() =>
                                setShowPassword(prev => ({
                                  ...prev,
                                  checkedPassword: !prev.checkedPassword,
                                }))
                              }
                              sx={{ fontSize: '2.4rem' }}
                            >
                              {showPassword.checkedPassword ? <OpenedEyeIcon /> : <ClosedEyeIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('register.password_check')}
                    />
                  </CenterAlignStack>
                </CenterAlignStack>
                <FormErrorMessage message={errors.checkedPassword?.message} />
              </Box>

              {/* 5. 닉네임 */}
              <Box className="input-row-container">
                <CenterAlignStack direction="row" className="input-row">
                  <RegisterLabel required={false} label={t('register.nickname')} />
                  <CenterAlignStack direction="row" className="input-wrapper">
                    <TextInput
                      control={control}
                      name="nickname"
                      error={!!errors.nickname}
                      style={{ width: '40rem' }}
                      placeholder={t('register.nickname')}
                    />
                  </CenterAlignStack>
                </CenterAlignStack>
                <FormErrorMessage message={errors.nickname?.message} />
              </Box>

              {/* 6. 성별 */}
              <Box className="input-row-container">
                <CenterAlignStack direction="row" className="input-row">
                  <RegisterLabel required={false} label={t('register.gender')} />
                  <CenterAlignStack direction="row" className="input-wrapper">
                    <SelectInput
                      items={[
                        { value: 'F', text: t('register.gender_female') },
                        { value: 'M', text: t('register.gender_male') },
                        { value: 'A', text: t('register.gender_other') },
                        { value: 'B', text: t('register.gender_whatever') },
                      ]}
                      control={control}
                      name="gender"
                      error={!!errors.gender}
                    />
                  </CenterAlignStack>
                </CenterAlignStack>
                <FormErrorMessage message={errors.gender?.message} />
              </Box>

              {/* 7. 휴대폰 번호 */}
              <Box className="input-row-container">
                <CenterAlignStack direction="row" className="input-row">
                  <RegisterLabel required={false} label={t('register.mobile')} />
                  <CenterAlignStack direction="row" className="input-wrapper">
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: '2rem' }}>
                      <PhoneInput
                        control={control}
                        name="phone1"
                        // sx={{ width: '10rem', height: '4rem' }}
                        inputProps={{ maxLength: 3 }}
                        error={!!errors.phone1}
                        placeholder="010"
                        disabled
                      />
                      <Typography px={1.45}>ㅡ</Typography>
                      <PhoneInput
                        control={control}
                        name="phone2"
                        inputProps={{ maxLength: 4 }}
                        error={!!errors.phone2}
                        // sx={{ width: '10rem', height: '4rem' }}
                        onChange={e => {
                          setValue('phone2', e.target.value)
                          setValue('phone', watch('phone1') + e.target.value + watch('phone3'))
                        }}
                      />
                      <Typography px={1.45}>ㅡ</Typography>
                      <PhoneInput
                        control={control}
                        name="phone3"
                        // sx={{ width: '10rem', height: '4rem' }}
                        inputProps={{ maxLength: 4 }}
                        error={!!errors.phone3}
                        onChange={e => {
                          setValue('phone3', e.target.value)
                          setValue('phone', watch('phone1') + watch('phone2') + e.target.value)
                        }}
                      />
                    </Box>
                  </CenterAlignStack>
                </CenterAlignStack>
                <FormErrorMessage message={errors.phone?.message} />
              </Box>

              {/* 8. 웹사이트 */}
              <Box className="input-row-container">
                <CenterAlignStack direction="row" className="input-row">
                  <RegisterLabel required={false} label={t('register.website')} />
                  <CenterAlignStack direction="row" className="input-wrapper">
                    <TextInput
                      style={{ width: '40rem' }}
                      control={control}
                      name="website"
                      error={!!errors.website}
                      placeholder={t('register.website_placeholder')}
                    />
                  </CenterAlignStack>
                </CenterAlignStack>
                <FormErrorMessage message={errors.website?.message} />
              </Box>

              {/* ----------------- */}
              {/* ----------------- */}
              {/* ----------------- */}

              <FormControlLabel
                sx={{
                  mx: 0,
                  '& .checkbox': {
                    pl: '0 !important',
                  },
                }}
                label={
                  <Typography sx={{ fontWeight: 700, fontSize: { lg: '1.8rem', xs: '1.6rem' } }}>
                    &nbsp;{t('register.agree_all')}
                  </Typography>
                }
                control={
                  <Checkbox
                    className="checkbox"
                    checkedIcon={<CheckedSquareIcon3 sx={{ width: '2rem', height: '2rem' }} />}
                    checked={
                      watch('agreeAge') &&
                      watch('agreeData') &&
                      watch('agreeTerms') &&
                      watch('agreeMarketing') &&
                      watch('agreeAdvertisement')
                    }
                    indeterminate={
                      !(
                        watch('agreeAge') &&
                        watch('agreeData') &&
                        watch('agreeTerms') &&
                        watch('agreeMarketing') &&
                        watch('agreeAdvertisement')
                      )
                    }
                    icon={<SquareIcon2 />}
                    indeterminateIcon={<SquareIcon2 />}
                    onChange={onChange}
                  />
                }
              />

              <Divider
                flexItem
                sx={{
                  borderColor: '#BBBBBB',
                  mt: '0.6rem',
                  mb: '0.6rem',
                }}
              />

              <FormCheckbox
                name="agreeAge"
                control={control}
                label={
                  <Box className="label">
                    <Typography
                      className="text"
                      sx={errors.agreeAge ? { color: 'red' } : { color: 'black' }}
                    >
                      {t('register.over_14')}
                    </Typography>
                  </Box>
                }
                checked={getValues('agreeAge')}
              />

              <FormCheckbox
                name="agreeTerms"
                control={control}
                label={
                  <Box
                    className="label"
                    sx={Object.assign({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    })}
                  >
                    <Typography
                      className="text"
                      sx={errors.agreeTerms ? { color: 'red' } : { color: 'black' }}
                    >
                      {t('register.agree_term')}
                    </Typography>
                    <AgreeDialog
                      category="Terms"
                      openButton={
                        <AgreementReadButton variant="contained">
                          {t('common.read_on')}
                        </AgreementReadButton>
                      }
                    />
                  </Box>
                }
                checked={getValues('agreeTerms')}
              />
              <FormCheckbox
                name="agreeData"
                control={control}
                label={
                  <Box
                    className="label"
                    sx={Object.assign({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    })}
                  >
                    <Typography
                      className="text"
                      sx={errors.agreeData ? { color: 'red' } : { color: 'black' }}
                    >
                      {t('register.agree_privacy')}
                    </Typography>
                    <AgreeDialog
                      category={isKo(i18n) ? 'agreePrivacy' : 'Privacy'}
                      openButton={
                        <AgreementReadButton variant="contained">
                          {t('common.read_on')}
                        </AgreementReadButton>
                      }
                    />
                  </Box>
                }
                checked={getValues('agreeData')}
              />

              <FormCheckbox
                sx={isKo(i18n) ? {} : { my: '0.5rem' }}
                name="agreeMarketing"
                control={control}
                label={
                  <Box
                    className="label"
                    sx={Object.assign({
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    })}
                  >
                    <Typography
                      className="text"
                      sx={errors.agreeMarketing ? { color: 'red' } : { color: 'black' }}
                    >
                      {t('register.agree_marketing')}
                    </Typography>
                    <AgreeDialog
                      category="agreeMarketing"
                      openButton={
                        <AgreementReadButton variant="contained">
                          {t('common.read_on')}
                        </AgreementReadButton>
                      }
                    />
                  </Box>
                }
                checked={getValues('agreeMarketing')}
              />

              <FormCheckbox
                sx={isKo(i18n) ? {} : { my: '0.5rem' }}
                name="agreeAdvertisement"
                control={control}
                label={
                  <Box
                    className="label"
                    sx={Object.assign(
                      {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      },
                      errors.agreeAdvertisement ? { color: 'red' } : { color: 'black' }
                    )}
                  >
                    <Typography className="text">{t('register.agree_ads')}</Typography>
                    <AgreeDialog
                      category="agreeAdvertisement"
                      openButton={
                        <AgreementReadButton variant="contained">
                          {t('common.read_on')}
                        </AgreementReadButton>
                      }
                    />
                  </Box>
                }
                checked={getValues('agreeAdvertisement')}
              />
            </Stack>

            <LoadingButton
              size="large"
              type="submit"
              className="submit-button"
              // onClick={handleClick}
              // endIcon={<SendIcon />}
              loading={isSubmitting}
              // loadingPosition="end"
              variant="contained"
              sx={{
                width: '18rem',
                height: '4rem',
                color: theme.palette.common.white,
                fontWeight: 500,
                fontSize: '1.6rem',
                mt: '2rem',
                boxShadow: 'none',
              }}
            >
              {t('register.sign_up')}
            </LoadingButton>
          </CenterAlignStack>
        </form>
      </motion.div>
    </>
  )
}

const Timer = ({ time, setTime, style }) => {
  const theme = useTheme()

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(prevTime => prevTime - 1)
      } else {
        clearInterval(interval)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const formatTime = time => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return time > 0 ? `${minutes}:${seconds.toString().padStart(2, '0')}` : '0:00'
  }

  return (
    <span
      style={{
        color: '#FF2323',
        fontWeight: 600,
        fontSize: '1.3rem',
        lineHeight: '1.6rem',
        height: '2.4rem',
        marginRight: '2rem',
        paddingTop: '0.2rem',
        paddingBottom: '0.5rem',
        ...style,
      }}
    >
      {formatTime(time)}
    </span>
  )
}
