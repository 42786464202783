import { apis } from 'apis'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import {
  atom,
  constSelector,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import MovingImg from './MovingImg'
import axios from 'axios'
import HoverSpinning from './HoverSpinning'

function KaKaoLogin() {
  const REDIRECT_URI = 'http://localhost:3000/test/4'
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_LOGIN}&redirect_uri=${REDIRECT_URI}&response_type=code`

  useEffect(() => {
    const params = new URL(document.location.toString()).searchParams
    const code = params.get('code') // 인가코드 받는 부분
    console.log(code)

    const tokenParams = new URLSearchParams()
    tokenParams.append('grant_type', 'authorization_code')
    tokenParams.append('client_id', process.env.REACT_APP_KAKAO_LOGIN)
    tokenParams.append('redirect_uri', REDIRECT_URI)
    tokenParams.append('code', code)

    if (code) {
      axios
        .post(`https://kauth.kakao.com/oauth/token?`, tokenParams, {
          headers: {
            'Content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          },
        })
        .then(res => {
          console.log(res.data.access_token)
          axios
            .get('https://kapi.kakao.com//v2/user/me', {
              headers: {
                Authorization: `Bearer ${res.data.access_token}`,
                'Content-type':
                  'application/x-www-form-urlencoded;charset=utf-8',
              },
            })
            .then(res => {
              console.log(res)
            })
          // res에 포함된 토큰 받아서 원하는 로직을 하면된다.
        })
    }
  }, [])

  return (
    <a href={KAKAO_AUTH_URL}>
      <img
        src="https://k.kakaocdn.net/14/dn/btroDszwNrM/I6efHub1SN5KCJqLm1Ovx1/o.jpg"
        width="222"
        alt="카카오 로그인 버튼"
      />
    </a>
  )
}

export default function TestPage4() {
  const [category, setCategory] = useState([])

  const variant = {
    animate: {
      x: [0, '-50%'],
      transition: {
        x: {
          repeat: Infinity,

          duration: 5,
          ease: 'linear',
        },
      },
    },
  }

  useEffect(() => {
    apis.user.getCategory().then(response => {
      console.log(response)
      setCategory(response.data)
    })
  }, [])

  const onclickHandle = () => {
    apis.bg.masking({ user: 'asd', password: 'cvcv}' })
  }

  // const { t } = useTranslation('nest', {
  //   keyPrefix: 'very.deeply.nested',
  // })

  const { t, i18n, ready } = useTranslation()

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
    window.localStorage.setItem('i18nextLng', lng)
  }

  const username = { ko: '진호', en: 'JinHo' }

  return (
    <>
      <ul>
        {' '}
        {category.map((category, idx) => {
          return <li key={idx}>{category.name}</li>
        })}
      </ul>
      <button onClick={onclickHandle}>이벤트 확인용</button>
      {/* <h2>{t('key')}</h2> */}
      <div>
        <h1>{t('인사말', { username })}</h1>
        <h2>{t('안녕')}</h2>
        <button onClick={() => changeLanguage('ko')}>ko</button>
        <button onClick={() => changeLanguage('en')}>en</button>
        <div>{t('환영합니다.')}</div>
        <button onClick={() => console.log('아아아아')}>
          {t('button.저장')}
        </button>
      </div>
      {/* <motion.section style={{ width: '100%', overflow: 'hidden' }}>
        <motion.article
          style={{ display: 'flex', width: '200%' }}
          initial={{ x: 0 }}
          animate={{ x: '-50%' }}
          transition={{ ease: 'linear', duration: 10, repeat: Infinity }}
        >
          <motion.div style={{ width: '100%' }}>
            <motion.ul
              style={{
                display: 'flex',
                listStyleType: 'none',
                paddingLeft: 0,
                margin: 0,
              }}
            >
              <motion.li style={{ width: '100%' }}>
                <motion.img
                  src="http://dummyimage.com/600x400/000000/ffffff.png"
                  style={{ display: 'block', width: '100%' }}
                />
              </motion.li>
              <motion.li style={{ width: '100%' }}>
                <motion.img
                  src="http://dummyimage.com/600x400/eeeeee/cccccc.png"
                  style={{ display: 'block', width: '100%' }}
                />
              </motion.li>
              <motion.li style={{ width: '100%' }}>
                <motion.img
                  src="http://dummyimage.com/600x400/000000/ffffff.png"
                  style={{ display: 'block', width: '100%' }}
                />
              </motion.li>
              <motion.li style={{ width: '100%' }}>
                <motion.img
                  src="http://dummyimage.com/600x400/eeeeee/cccccc.png"
                  style={{ display: 'block', width: '100%' }}
                />
              </motion.li>
            </motion.ul>
          </motion.div>
          <motion.div style={{ width: '100%' }}>
            <motion.ul
              style={{
                display: 'flex',
                listStyleType: 'none',
                paddingLeft: 0,
                margin: 0,
              }}
            >
              <motion.li style={{ width: '100%' }}>
                <motion.img
                  src="http://dummyimage.com/600x400/000000/ffffff.png"
                  style={{ display: 'block', width: '100%' }}
                />
              </motion.li>
              <motion.li style={{ width: '100%' }}>
                <motion.img
                  src="http://dummyimage.com/600x400/eeeeee/cccccc.png"
                  style={{ display: 'block', width: '100%' }}
                />
              </motion.li>
              <motion.li style={{ width: '100%' }}>
                <motion.img
                  src="http://dummyimage.com/600x400/000000/ffffff.png"
                  style={{ display: 'block', width: '100%' }}
                />
              </motion.li>
              <motion.li style={{ width: '100%' }}>
                <motion.img
                  src="http://dummyimage.com/600x400/eeeeee/cccccc.png"
                  style={{ display: 'block', width: '100%' }}
                />
              </motion.li>
            </motion.ul>
          </motion.div>
        </motion.article>
      </motion.section> */}

      <MovingImg />
      <KaKaoLogin />
      <HoverSpinning />
    </>
  )
}
