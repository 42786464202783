import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import MainAppBar, { APPBAR_DESKTOP, APPBAR_MOBILE } from './main/MainAppBar'
import InfoSideBar from './InfoSideBar'
import { SnackbarProvider } from 'notistack'

import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { Box, Card, Stack } from '@mui/material'
import AdminSideBar from 'pages/admin/AdminSideBar'
import MainFooter, { FOOTER_DESKTOP, FOOTER_MOBILE } from './main/MainFooter'
import { StyledMaterialDesignContent } from 'components/CustomStyle'
import MainDownBar from './main/MainDownBar'
import MobileMypageAppbar from './MobileMypageAppbar'

export const MYPAGE_SIDE_MARGIN = '17rem'
export const MYPAGE_APPBAR_MOBILE = '10.4rem'

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',

  [theme.breakpoints.down('lg')]: {
    paddingTop: MYPAGE_APPBAR_MOBILE,
    paddingBottom: APPBAR_MOBILE,
  },
}))

const MainStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  flexDirection: 'column',

  [theme.breakpoints.down('lg')]: {
    height: '100%',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: APPBAR_DESKTOP,
  },
}))

const OutletStyle = styled('div')(({ theme }) => ({
  minHeight: `calc(100vh - ${FOOTER_MOBILE} - ${APPBAR_MOBILE})`,
  [theme.breakpoints.up('lg')]: {
    minHeight: `calc(100vh - ${FOOTER_DESKTOP} - ${APPBAR_DESKTOP})`,
  },
  paddingTop: '7.8rem',
  paddingBottom: '7.8rem',
}))

const MypagesStyle = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
}))

export default function AdminLayout() {
  const [open, setOpen] = useState(false)
  return (
    <RootStyle>
      <SnackbarProvider autoHideDuration={1000}>
        <MainAppBar />

        <MainStyle>
          <Desktop>
            <AdminSideBar />
            <MypagesStyle>
              <OutletStyle>
                <Outlet />
              </OutletStyle>
              <MainFooter />
            </MypagesStyle>
          </Desktop>
        </MainStyle>
      </SnackbarProvider>
    </RootStyle>
  )
}
