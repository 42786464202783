import { Suspense, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import MainAppBar, { APPBAR_DESKTOP, APPBAR_MOBILE } from './main/MainAppBar'
import InfoSideBar from './InfoSideBar'

import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { Box, Card, Stack } from '@mui/material'
import MypagesSideBar from './MypagesSideBar'
import MainFooter, { FOOTER_DESKTOP, FOOTER_MOBILE } from './main/MainFooter'
import MainDownBar from './main/MainDownBar'
import MobileMypageAppbar from './MobileMypageAppbar'
import { SnackbarProvider } from 'notistack'
import { StyledMaterialDesignContent } from 'components/CustomStyle'
import { CheckedCircleIconNotistack, ErrorTriangleIconNotistack } from 'theme/icon'
import { CenterAlignBox } from 'components'

export const MYPAGE_SIDE_MARGIN = '0rem'
export const MYPAGE_APPBAR_MOBILE = '10.1rem'
export const MYPAGE_OUTLET_TOPMARGIN_DESKTOP = '7.8rem'
export const MYPAGE_PERCENTAGE_WIDHT = '91%'

const snackbarProps = {
  maxSnack: 5,
  autoHideDuration: 1000,
  // style: {
  //   fontSize: '1.2rem',
  // },
}

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('lg')]: {
    paddingTop: MYPAGE_APPBAR_MOBILE,
  },
}))

const MainStyle = styled('div')(({ theme }) => ({
  display: 'flex',

  alignItems: 'center',
  flexDirection: 'column',

  [theme.breakpoints.down('lg')]: {
    height: '100%',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: APPBAR_DESKTOP,
  },
}))

const OutletStyle = styled('div')(({ theme }) => ({
  // 하단 푸터, 상단 앱바 두개, 그리고 하단 앱바 하나
  minHeight: `calc(100vh - ${FOOTER_MOBILE} - ${MYPAGE_APPBAR_MOBILE} - ${APPBAR_MOBILE})`,
  paddingTop: '3.4rem',

  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    height: 'auto',
  },

  [theme.breakpoints.up('lg')]: {
    minHeight: `calc(100vh - ${FOOTER_DESKTOP} - ${APPBAR_DESKTOP})`,
    paddingTop: MYPAGE_OUTLET_TOPMARGIN_DESKTOP,
  },
}))

const MypagesStyle = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
}))

export default function MypageLayout() {
  const [open, setOpen] = useState(false)

  const { pathname } = useLocation()
  const smallPadding = pathname === '/user/background'

  return (
    <RootStyle>
      <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <CheckedCircleIconNotistack />,
          error: <ErrorTriangleIconNotistack />,
        }}
        {...snackbarProps}
      >
        <MainAppBar />

        <MainStyle>
          <Desktop>
            {' '}
            <CenterAlignBox sx={{ width: '128rem', pb: '7rem' }}>
              <MypagesSideBar />
              <MypagesStyle>
                <OutletStyle
                  style={{
                    paddingLeft: smallPadding ? '3.6rem' : '6.4rem',
                  }}
                >
                  <Outlet />
                </OutletStyle>
              </MypagesStyle>
            </CenterAlignBox>
            <MainFooter />
          </Desktop>

          <Mobile>
            <MobileMypageAppbar />
            <OutletStyle>
              <Outlet />
            </OutletStyle>
            <MainFooter />
          </Mobile>
        </MainStyle>

        <Mobile>
          <MainDownBar />
        </Mobile>
      </SnackbarProvider>
    </RootStyle>
  )
}
