import { useEffect, useRef, useState } from 'react'
import { PlusIcon } from 'theme/icon'
import { allowedTypes } from 'utils/common'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { IconButton } from '@mui/material'
import { BsFillXCircleFill } from 'react-icons/bs'
import _ from 'lodash'
import { useRefineUploadFiles } from 'hooks/useRefineUploadFiles'

export default function SingleSimpleDragDrop({
  file,
  setFile,
  isUpload = false,
  inputStyle = {},
  iconStyle = {},
  ...props
}) {
  // drag state
  const [dragActive, setDragActive] = useState(false)
  // ref
  const inputRef = useRef(null)

  const [currentImageUrl, setCurrentImageUrl] = useState('')
  const refineUploadFiles = useRefineUploadFiles()

  const uuid = Math.ceil(Math.random() * 1000)

  const bgRemovedStyle = {
    background:
      'linear-gradient(45deg, lightgray 25%, transparent 25%), linear-gradient(-45deg, lightgray 25%, transparent 25%), linear-gradient(45deg, transparent 75%, lightgray 75%), linear-gradient(-45deg, white 75%, lightgray 75%)',
    backgroundSize: '20px 20px' /* 크기 조정 */,
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0',
  }

  useEffect(() => {
    if (file.length) {
      const url = URL.createObjectURL(file[0])
      setCurrentImageUrl(url)
    } else if (!file.length) {
      setCurrentImageUrl('')
    }

    // if (!_.isArray(file) && file) {
    //   const url = URL.createObjectURL(file)
    //   setCurrentImageUrl(url)
    // } else if (!_.isArray(file) && file === '') {
    //   setCurrentImageUrl('')
    // }
  }, [file])

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files)

      const uploadFiles = Array.prototype.slice.call(e.dataTransfer.files, 0, 1)
      isUpload ? refineUploadFiles(uploadFiles) : setFile(uploadFiles)
    }
  }

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault()
    const imageFile = e.target.files
    if (e.target.files && imageFile.length) {
      // handleFiles(e.target.files);

      console.log('by click', e.target.files)
      const uploadFiles = Array.prototype.slice.call(e.target.files, 0, 1)
      isUpload ? refineUploadFiles(uploadFiles) : setFile(uploadFiles)
    }
  }

  const handleImageClose = () => {
    setFile([])
  }

  return (
    <CenterAlignStack>
      {file.length ? (
        <CenterAlignBox sx={{ position: 'relative' }}>
          <img
            className="image-thumbnail"
            src={currentImageUrl}
            style={{
              width: props.width ?? '10rem',
              height: props.height ?? '10rem',
              borderRadius: '20px',
              objectFit: 'contain',
              ...bgRemovedStyle,
            }}
          />
          {Boolean(file.length) && (
            <IconButton
              onClick={handleImageClose}
              sx={{
                position: 'absolute',
                top: '-2rem',
                right: '-2rem',
                fontSize: '2rem ',
              }}
            >
              <BsFillXCircleFill
                style={{
                  background: 'white',
                  borderRadius: '50px',
                  width: '2.4rem',
                  height: '2.4rem',
                  padding: '1px',
                }}
              />
            </IconButton>
          )}
        </CenterAlignBox>
      ) : (
        <form
          className="form-file-upload"
          id={`form-file-upload-${uuid}`}
          onDragEnter={handleDrag}
          onSubmit={e => e.preventDefault()}
          style={{
            width: props.width ?? '10rem',
            height: props.height ?? '10rem',
          }}
        >
          <input
            ref={inputRef}
            type="file"
            className="input-file-upload"
            id={`input-file-upload-${uuid}`}
            accept={allowedTypes.join(', ')}
            multiple={false}
            onChange={handleChange}
          />

          <label
            id="label-file-upload"
            htmlFor={`input-file-upload-${uuid}`}
            className={dragActive ? 'drag-active' : ''}
            style={{ ...inputStyle }}
          >
            {currentImageUrl ? (
              <img
                className="image-thumbnail"
                src={currentImageUrl}
                style={{ objectFit: 'contain' }}
              />
            ) : (
              <div>
                <PlusIcon color={iconStyle.color ?? '#6D6D6D'} />
              </div>
            )}
          </label>

          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </form>
      )}
    </CenterAlignStack>
  )
}
