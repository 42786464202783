import { Grow, styled, useTheme } from '@mui/material'
import { StyledMaterialDesignContent } from 'components/CustomStyle'
import { MaterialDesignContent, SnackbarProvider } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { constSelector } from 'recoil'
import {
  CheckedCircleIcon,
  CheckedCircleIconNotistack,
  ErrorTriangleIcon,
  ErrorTriangleIconNotistack,
  StarFilledIcon,
} from 'theme/icon'

export default function TestLayout() {
  const { t, i18n } = useTranslation()

  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
    window.localStorage.setItem('i18nextLng', lng)
  }

  console.log(i18n.language)

  const theme = useTheme()

  return (
    <>
      {/* <SnackbarProvider
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <CheckedCircleIconNotistack />,
          error: <ErrorTriangleIconNotistack />,
        }}
        style={{ marginTop: '28rem' }}
        TransitionComponent={Grow}
      >
        <button onClick={() => changeLanguage('ko')}>ko</button>
        <button onClick={() => changeLanguage('en')}>en</button> */}
      <Outlet />
      {/* </SnackbarProvider> */}
    </>
  )
}
