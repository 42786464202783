import {
  Container,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

const serviceH3Style = {
  fontSize: 20,
  marginTop: '32px',
  marginBottom: '15px',
  marginLeft: '14px',
  fontWeight: 600,
}
const serviceH4Style = {
  marginBottom: '8px',
  marginTop: '12px',
  fontSize: 17.5,
}
const servicePStyle = { paddingLeft: '10px', fontSize: 15.5 }
const serviceOlStyle = { listStyleType: 'decimal', marginLeft: '3rem' }

const privacyH3Style = {
  marginTop: '32px',
  marginBottom: '5px',
  fontWeight: 600,
}
const privacyLiStyle1 = { listStyleType: 'decimal', paddingLeft: '20px' }
const privacyLiStyle2 = { listStyleType: 'decimal', paddingLeft: '18px' }

const nestedLiStyle = { marginLeft: '1.5rem' }
export default function Marketing() {
  const { t, i18n } = useTranslation()

  return (
    <Container sx={{ m: '0 auto', p: '0 !important', mt: '3rem' }}>
      <h2
        style={{
          textAlign: 'center',
          marginBottom: '20px',
          fontWeight: 800,
        }}
      >
        {t('agree.agreeMarketing')}
      </h2>
      <Table
        sx={{
          '& .MuiTableCell-root': { border: '1.5px solid rgb(195 195 195)' },
          '& .MuiTableCell-root.MuiTableCell-body': {
            p: '16px',
            fontSize: '1.3rem',
            wordBreak: 'keep-all',
          },
        }}
      >
        <TableHead sx={{ backgroundColor: 'rgb(102, 146, 255, 0.1)' }}>
          <TableRow sx={{ fontSize: '1.5rem' }}>
            <TableCell align="center" sx={{ width: '25%' }}>
              {t('agree.items')}
            </TableCell>
            <TableCell align="center" sx={{ width: '30%' }}>
              {t('agree.purposes')}
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: 700, width: '40%' }}>
              {t('agree.duration')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell valign="center" align="center">
              {t('agree.items_comment')}
            </TableCell>
            <TableCell valign="center">{t('agree.purposes_comment')}</TableCell>
            <TableCell sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
              {t('agree.duration_comment')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography
        sx={{
          mt: '1rem',
          fontSize: '1.2rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          textIndent: '-0.9rem',
        }}
      >
        {t('agree.notice_1')}
      </Typography>
      <Typography
        sx={{
          fontSize: '1.2rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          textIndent: '-0.9rem',
        }}
      >
        {t('agree.notice_2')}
      </Typography>
    </Container>
  )
}
