import { motion } from 'framer-motion'
import { useRef } from 'react'

function HoverSpinning() {
  const divRef = useRef(null)
  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      {' '}
      <motion.div
        ref={divRef}
        style={{
          marginTop: '200vmin',
          marginBottom: '10vmin',
          width: '40vmin',
          height: '40vmin',
        }}
        initial={{ opacity: 0.2 }}
        whileInView={{
          opacity: 1,
          type: 'ease',
          rotate: [0, 360],
          borderRadius: ['20%', '50%'],
          transition: { delay: 0.2 },
        }}
      >
        <motion.img
          whileHover={{
            opacity: 1,
            type: 'ease',
            rotate: [0, 360],
            // borderRadius: ['20%', '50%'],
            transition: { delay: 0.2 },
          }}
          src="https://pbs.twimg.com/media/FNTpfMgaIAAalKu.jpg"
          style={{ objectFit: 'cover' }}
        />
      </motion.div>
    </>
  )
}

export default HoverSpinning
