import { MHidden } from 'components/@material-extend'
import {
  Box,
  Typography,
  Checkbox,
  Grid,
  Divider,
  IconButton,
  Stack,
  TextField,
  Pagination,
  Button,
  Select,
  MenuItem,
  ToggleButton,
  Backdrop,
  CircularProgress,
  SvgIcon,
  Tooltip,
} from '@mui/material'
import {
  CenterAlignBox,
  CenterAlignStack,
  TempLayoutDiv,
  FlexBasis,
  ArtworksImageSwiperSlideDialog,
  RegenerateDialog,
  AddPieceDialog,
  SingleImageDialog,
  CustomPagination,
  SearchInputMobile,
} from 'components'

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { artworkPagingSelector, creditPolicyDictSelector, isPortfolioOwnerSelector } from 'selector'
import {
  portfolioAtom,
  portfolioDetailAtom,
  portfolioArtworkAtom,
  artworkInViewAtom,
  userAtom,
  confirmDialogAtom,
  artworkViewConfigAtom,
  artworkRegenCountAtom,
  fakeArtworksListAtom,
  tutorialAtom,
} from 'atoms'
import { useEffect, useState, useRef, useCallback } from 'react'
import { styled, useTheme, alpha } from '@mui/material/styles'
import { getQueryParam, getS3ImageSrc, padZeros, setWatermark, utcToLocal } from 'utils/common'
import { useParams, useSearchParams } from 'react-router-dom'

import { motion, useAnimation, AnimateSharedLayout, AnimatePresence } from 'framer-motion'

import {
  MdOutlineExpandMore as MoreIcon,
  MdOutlineExpandLess as LessIcon,
  MdOutlineSearch as SearchIcon,
} from 'react-icons/md'
import { AiFillEdit as EditIcon, AiFillSave as SaveIcon, AiOutlineConsoleSql } from 'react-icons/ai'
import { BsFillStarFill as StarIcon, BsFillPlusSquareFill as PlusIcon } from 'react-icons/bs'
import * as config from 'config'

import { PuffLoader } from 'react-spinners'

import { useInView } from 'react-intersection-observer'
import { apis } from 'apis'

import JSZip from 'jszip'
import axios from 'axios'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import useConfirm from 'hooks/useConfirm'
import {
  DotIcon,
  DownloadIcon,
  TrashCanIcon,
  PencilIcon,
  CircleIcon,
  CheckedCircleIcon,
  CirclePlusIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
  RefreshIcon,
  SelectSideIcon,
  SelectSideIconThin,
  ErrorTriangleIcon,
} from 'theme/icon'
import { PAGE_HEADER_HEIGHT, PAGE_HEADER_HEIGHT_MOBILE } from 'pages/PortfolioDetail'
import { fontWeight } from '@mui/system'

import CircularSlider from '@fseehawer/react-circular-slider'
import { useSnackbar } from 'notistack'
import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { MOBILE_PORTFOLIO_WIDTH_LIMIT } from 'pages/Portfolio'
import { Tutorial } from 'theme/Tutorial'

const MotionGrid = motion(Grid)

const MOBILE_MARGIN_X = '2rem'

const StyledArtworkListBox = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('lg')]: {},
}))

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  borderBottom: '0.1rem solid',

  width: '100%',
  minHeight: '3rem',
  display: 'flex',
  alignItems: 'center',
  height: '7rem',

  [theme.breakpoints.down('lg')]: {
    height: '5rem',
    justifyContent: 'center',
    top: 0,
    zIndex: 3,
    backgroundColor: theme.palette.common.white,
  },
}))

const StyledGroupHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '7rem',
  background: '#F8F8F8',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
  borderBottom: '0.05rem solid #909090',

  top: PAGE_HEADER_HEIGHT,

  zIndex: 2,

  [theme.breakpoints.down('lg')]: {
    top: '5rem', // StyledHeaderBox 의 down lg height 와 맞춰야 함
    height: 'unset',
    // height: '10.9rem',
  },
}))

const GroupHeaderText1 = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 500,
  color: '#525252',
  marginRight: '0.3rem',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: theme.palette.common.gray,
  },
}))

const GroupHeaderText2 = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 700,
  color: theme.palette.common.black,
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    fontWeight: 600,
    color: theme.palette.common.gray,
  },
}))

// 체크박스 | 원본사진 | 생성사진 | 컨트롤박스
const COLUMN_WIDTH = ['5rem', '25rem', '60rem', '30rem'] // 가로 1200px 이상인 데스크탑용 뷰에서 사용

const DESKTOP_FLEX_BASIS = ['10%', '15%', '50%', '25%']
const MOBILE_FLEX_BASIS = DESKTOP_FLEX_BASIS

const getArtworkArray = o => {
  let arr = []
  Object.entries(o).forEach(([key, v]) => {
    arr = arr.concat(v)
  })
  return arr
}

const notify = message => {
  if ('Notification' in window && Notification.permission === 'granted' && message) {
    return new Notification(message)
  }
}

export function TutorialArtworkList(props) {
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const artworkPageList = useRecoilValue(artworkPagingSelector) // [페이지&그룹]으로 묶은 아트워크 목록 [{}, {}, {}, ...]

  const viweConfig = useRecoilValue(artworkViewConfigAtom)

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom) // 현재 포트폴리오 정보
  const [portfolio, setPortfolio] = useRecoilState(portfolioAtom) // 유저의 모든 포트폴리오 목록
  const [user, setUser] = useRecoilState(userAtom)

  const [artworkInView, setArtworkInView] = useRecoilState(artworkInViewAtom) // 현재 보고있는 아트워크 (artworkPageList 중 하나의 object)
  const [artworkList, setArtworkList] = useRecoilState(fakeArtworksListAtom) // 포트폴리오의 전체 아트워크 목록
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)
  const [textFilter, setTextFilter] = useState('') // 모바일에서만 사용
  const [downloadedFilter, setDownloadedFilter] = useState('all') // 모바일에서만 사용

  const resetArtworkInView = useResetRecoilState(artworkInViewAtom)
  const resetPortfolio = useResetRecoilState(portfolioAtom)
  const resetArtworkList = useResetRecoilState(fakeArtworksListAtom)

  const [page, setPage] = useState(1)
  const [checked, setChecked] = useState([false])

  const { portfolioId } = useParams()
  const { showConfirm } = useConfirm()

  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()

  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter]) // 모바일

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter]) // 모바일

  useEffect(() => {
    apis.portfolio
      .getPortfolio(portfolioId)
      .then(response => {
        const d = response.data
        setPortfolioDetail({
          id: portfolioId,
          name: d.name,
          theme: d.theme,
          stat: d.stat,
          user_id: d.user_id,
          config: { name: d.name, ...d.config },
          is_default: d.is_default,
        })
      })
      .catch(error => {
        console.log(error.response.status)
      })
    return () => {
      resetArtworkInView()
      resetPortfolio()
      resetArtworkList()
    }
  }, [])

  useEffect(() => {
    setArtworkInView(artworkList)
  }, [page, artworkPageList])

  useEffect(() => {
    if (artworkPageList === null) return
    if (artworkPageList.length > 0) {
      const pageData = artworkPageList[page - 1]
      setArtworkInView(pageData)
    } else {
      setArtworkInView([])
    }
  }, [page, artworkPageList])

  const handlePage = (e, p) => {
    setPage(p)
  }

  const toggleCheckAll = (event, c) => {
    setChecked(Array(checked.length).fill(c))
  }

  return (
    <StyledArtworkListBox>
      <Mobile>
        <span id="scroll-anchor" />
      </Mobile>

      <StyledHeaderBox id={isMobile ? undefined : 'scroll-anchor'}>
        <Desktop>
          <Stack
            direction="row"
            sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
          >
            <CenterAlignBox width={COLUMN_WIDTH[0]}>
              <Checkbox checked={checked.every(v => v)} onChange={toggleCheckAll}></Checkbox>
            </CenterAlignBox>

            <CenterAlignBox width={COLUMN_WIDTH[1]}>
              <Typography fontSize="1.8rem" fontWeight={600}>
                원본
              </Typography>
            </CenterAlignBox>
            <CenterAlignBox width={COLUMN_WIDTH[2]}>
              <Typography fontSize="1.8rem" fontWeight={600}>
                생성 이미지
              </Typography>
            </CenterAlignBox>

            <CenterAlignBox width={COLUMN_WIDTH[3]}>
              {checked.every(v => !v) ? (
                <CustomPagination
                  size="small"
                  count={artworkPageList?.length}
                  showFirstButton={false}
                  showLastButton={false}
                  boundaryCount={1}
                  siblingCount={1}
                  page={page}
                  onChange={handlePage}
                />
              ) : (
                <Stack direction="row">
                  <Button
                    startIcon={<DownloadIcon />}
                    sx={{
                      fontSize: '1.5rem',
                      fontWeight: 400,
                      color: theme.palette.common.black,
                      mr: '3.5rem',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.draph.blue,
                        '& svg path': {
                          transition: 'stroke 0.3s',
                          stroke: theme.palette.draph.blue,
                        },
                      },
                      '& svg path': {
                        transition: 'stroke 0.3s',
                      },
                    }}
                  >
                    선택 다운로드
                  </Button>
                  <Button
                    startIcon={<TrashCanIcon color={theme.palette.common.black} />}
                    sx={{
                      fontSize: '1.5rem',
                      fontWeight: 400,
                      color: theme.palette.common.black,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.common.red,
                        '& svg path': {
                          transition: 'stroke 0.3s',
                          stroke: theme.palette.common.red,
                        },
                      },
                      '& svg path': {
                        transition: 'stroke 0.3s',
                      },
                    }}
                  >
                    선택 삭제
                  </Button>
                </Stack>
              )}
            </CenterAlignBox>
          </Stack>
        </Desktop>

        <Mobile>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              width: MOBILE_PORTFOLIO_WIDTH_LIMIT,
              zIndex: 'auto',
              pl: MOBILE_MARGIN_X,
            }}
          >
            <Select
              value={downloadedFilter}
              onChange={e => setDownloadedFilter(e.target.value)}
              IconComponent={() => <SelectSideIconThin size="small" />}
              sx={{
                width: '7.6rem',
                height: '2.8rem',
                '& .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
                  width: '5rem',
                  pl: '1rem',
                  pr: 0,
                  pt: '0.7rem',
                  pb: '0.7rem',
                },

                fontSize: { lg: '1.5rem', xs: '1.2rem' },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '0.1rem',
                  p: 0,
                },
              }}
            >
              <MenuItem value="all" sx={{ fontSize: { lg: '1.5rem', xs: '1.2rem' } }}>
                모두 보기
              </MenuItem>
              <MenuItem value="downloaded" sx={{ fontSize: { lg: '1.5rem', xs: '1.2rem' } }}>
                다운 된
              </MenuItem>
              <MenuItem value="notDownloaded" sx={{ fontSize: { lg: '1.5rem', xs: '1.2rem' } }}>
                다운 안된
              </MenuItem>
            </Select>

            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'end', pr: '' }}>
              <Box sx={{ width: '15rem', mb: '0.2rem' }}>
                <SearchInputMobile
                  value={textFilter}
                  onChange={e => setTextFilter(e.target.value)}
                />
              </Box>
              <IconButton
                sx={{
                  '&:active': {
                    backgroundColor: 'transparent',
                    color: theme.palette.draph.blue,
                    '& svg path': {
                      transition: 'stroke 0.3s',
                      stroke: theme.palette.draph.blue,
                    },
                  },
                  '& svg path': {
                    stroke: theme.palette.common.gray,
                    transition: 'stroke 0.3s',
                  },
                }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                sx={{
                  mr: '0.5rem',
                  '&:active': {
                    backgroundColor: 'transparent',
                    color: theme.palette.common.red,
                    '& svg path': {
                      transition: 'stroke 0.3s',
                      stroke: theme.palette.common.red,
                    },
                  },
                  '& svg path': {
                    stroke: theme.palette.common.gray,
                    transition: 'stroke 0.3s',
                  },
                }}
              >
                <TrashCanIcon color={theme.palette.common.black} />
              </IconButton>
            </Stack>
          </Stack>
        </Mobile>
      </StyledHeaderBox>

      <Box>
        <StyledGroupHeaderBox>
          {/* 데스크탑용 레이아웃 */}
          <Desktop>
            <CenterAlignBox sx={{ width: '100%', justifyContent: 'center' }}>
              <CenterAlignStack direction="row" width="60rem" sx={{ px: 2 }}>
                <Typography
                  fontSize="1.5rem"
                  fontWeight={500}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {portfolioDetail.theme
                    ?.split(',')
                    .map((q, i) => (q.trim() ? `# ${q.trim()}  ` : null))}
                </Typography>
              </CenterAlignStack>

              <CenterAlignStack direction="row" width="60rem" justifyContent="end" sx={{ px: 2 }}>
                <GroupHeaderText1>카테고리</GroupHeaderText1>
                <GroupHeaderText2>
                  {config.PORTFOLIO_CATEGORY_DICT[portfolioDetail.config?.category]} -{' '}
                  {config.PORTFOLIO_CATEGORY_DICT[portfolioDetail.config?.subCategory]}
                </GroupHeaderText2>
                <DotIcon />

                <GroupHeaderText1>그림자</GroupHeaderText1>
                <GroupHeaderText2>
                  {portfolioDetail.config?.genShadow ? 'ON' : 'OFF'}
                </GroupHeaderText2>

                <DotIcon />

                <GroupHeaderText1>얼굴 변경</GroupHeaderText1>
                <GroupHeaderText2>
                  {portfolioDetail.config?.genFace ? 'ON' : 'OFF'}
                </GroupHeaderText2>
                <DotIcon />

                <GroupHeaderText1>경계면</GroupHeaderText1>
                <GroupHeaderText2>{portfolioDetail.config?.objectBoundary}</GroupHeaderText2>
                <DotIcon />

                <GroupHeaderText1>촬영 각도</GroupHeaderText1>
                <GroupHeaderText2>{portfolioDetail.config?.objectAngle}</GroupHeaderText2>
              </CenterAlignStack>
            </CenterAlignBox>
          </Desktop>

          {/* 모바일용 레이아웃 */}
          {/* <Mobile>
            <Box
              sx={{
                width: MOBILE_PORTFOLIO_WIDTH_LIMIT,
                height: '100%',
                py: '1.3rem',
                px: MOBILE_MARGIN_X,
              }}
            >
              {commonConfig?.query?.trim().length > 0 && (
                <Typography
                  fontSize="1.2rem"
                  fontWeight={400}
                  sx={{
                    mb: '0.4rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: theme => theme.palette.common.gray,
                  }}
                >
                  {commonConfig?.query
                    .split(',')
                    .map((q, i) => (q.trim() ? `# ${q.trim()}  ` : null))}
                </Typography>
              )}

              <CenterAlignStack
                direction="row"
                sx={{
                  width: '100%',
                  '& svg': { fill: theme => theme.palette.common.gray },
                }}
              >
                <GroupHeaderText1>카테고리</GroupHeaderText1>
                <GroupHeaderText2>
                  {config.PORTFOLIO_CATEGORY_DICT[commonConfig?.object_category]} -{' '}
                  {config.PORTFOLIO_CATEGORY_DICT[commonConfig?.object_sub_category]}
                </GroupHeaderText2>
                <DotIcon />
                <GroupHeaderText1>그림자</GroupHeaderText1>
                <GroupHeaderText2>{commonConfig?.gen_shadow ? 'ON' : 'OFF'}</GroupHeaderText2>
              </CenterAlignStack>
              <CenterAlignStack
                direction="row"
                sx={{
                  width: '100%',
                  '& svg': { fill: theme => theme.palette.common.gray },
                }}
              >
                <GroupHeaderText1>얼굴 변경</GroupHeaderText1>
                <GroupHeaderText2>{commonConfig?.gen_face ? 'ON' : 'OFF'}</GroupHeaderText2>
                <DotIcon />
                <GroupHeaderText1>경계면</GroupHeaderText1>
                <GroupHeaderText2>{configText.object_boundary?.name}</GroupHeaderText2>
                <DotIcon />

                <GroupHeaderText1>촬영 각도</GroupHeaderText1>
                <GroupHeaderText2>{configText.object_angle?.name}</GroupHeaderText2>
              </CenterAlignStack>
            </Box>
          </Mobile> */}
        </StyledGroupHeaderBox>
        {tutorial.step > 14 &&
          artworkList.map(artwork => {
            return (
              <ArtworkRow
                key={artwork.id}
                idx={0} // 페이지의 전체 아트워크 중 몇번째인지
                initArtwork={artwork}
                isOwner={isOwner}
                checked={checked}
                setChecked={setChecked}
              />
            )
          })}
      </Box>

      <CenterAlignStack sx={{ width: '100%', pt: '5rem', pb: '7rem' }}>
        <CustomPagination
          count={artworkPageList?.length}
          showFirstButton
          showLastButton
          page={page}
          onChange={handlePage}
        />
      </CenterAlignStack>

      <Box id="scroll-anchor-bottom"></Box>
    </StyledArtworkListBox>
  )
}

const uploadedImageBoxStyle = {
  position: 'relative',
  width: { lg: '13rem', xs: '16.5rem' },
  height: { lg: '13rem', xs: '16.5rem' },
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
}
const uploadedImageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}

// TODO 애니메이션 조정
const itemVariants = {
  hidden: {
    opacity: 0,
    y: 50,
    transition: { ease: [0.78, 0.14, 0.15, 0.86] },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { ease: [0.78, 0.14, 0.15, 0.86] },
  },
}

const GENERATED_IMAGE_IN_ROW = 3
const GENERATED_IMAGE_LIMIT = GENERATED_IMAGE_IN_ROW * 2

function ArtworkRow({ idx, initArtwork, isOwner, checked, setChecked }) {
  const [artwork, setArtwork] = useState(initArtwork)
  const [selectedPiece, setSelectedPiece] = useState([])
  const user = useRecoilValue(userAtom)

  const { portfolioId } = useParams()

  const controls = useAnimation()
  const [ref, inView] = useInView()
  const theme = useTheme()

  useEffect(() => {
    setArtwork(initArtwork)
  }, [initArtwork])

  useEffect(() => {
    if (inView) {
      controls.start('show')
    }
  }, [controls, inView])

  const handleCheck = e => {
    const c = [...checked]
    c[idx] = e.target.checked
    setChecked(c)
  }

  return (
    <motion.div variants={itemVariants} initial="hidden" animate={controls} ref={ref}>
      <div style={{ position: 'relative' }}>
        <span id={`a_${artwork.id}`} style={{ position: 'absolute', top: '-20rem' }}>
          {/* scroll anchor */}
        </span>
      </div>

      <CenterAlignBox
        sx={{
          py: { lg: '3.5rem', xs: '3.2rem' },
          borderBottom: '0.05rem solid',
          borderColor: theme.palette.common.black,
          backgroundColor: checked[idx]
            ? theme => theme.palette.draph.lighterblue
            : theme => theme.palette.common.white,
        }}
      >
        {/* 데스크탑용 레이아웃 */}
        <Desktop>
          <Stack
            direction="row"
            sx={{ width: '100%', justifyContent: 'center', alignItems: 'start ' }}
          >
            {/* COLUMN 0: 체크박스 */}
            <CenterAlignStack width={COLUMN_WIDTH[0]}>
              <Checkbox checked={checked[idx] ?? false} onChange={handleCheck}></Checkbox>
            </CenterAlignStack>

            {/* COLUMN 1: 원본 이미지와 아트워크 이름 */}
            <CenterAlignBox width={COLUMN_WIDTH[1]}>
              <UploadedImage initArtwork={artwork} />
            </CenterAlignBox>

            {/* COLUMN 2: 생성된 이미지 */}
            <Tutorial step={15}>
              <motion.div variants={itemVariants} initial="hidden" animate={controls}>
                <CenterAlignBox width={COLUMN_WIDTH[2]}>
                  <GeneratedImageLayout
                    artwork={artwork}
                    setArtwork={setArtwork}
                    setSelectedPiece={setSelectedPiece}
                  />
                </CenterAlignBox>
              </motion.div>
            </Tutorial>

            {/* COLUMN 3: 다운로드 등 기능 버튼 */}
            {config.ARTWORK_DONE_STATUS === artwork.status ? (
              <CenterAlignBox width={COLUMN_WIDTH[3]}>
                <ControlBoxLayout
                  artwork={artwork}
                  setArtwork={setArtwork}
                  selectedPiece={selectedPiece}
                  isOwner={isOwner}
                />
              </CenterAlignBox>
            ) : (
              <CenterAlignBox width={COLUMN_WIDTH[3]} sx={{ height: '100%', alignItems: 'center' }}>
                {config.ARTWORK_ERROR_STATUS.includes(artwork.status) ? (
                  <Button
                    color="warning"
                    variant="outlined"
                    component="span"
                    sx={{ width: '15rem', height: '4rem' }}
                  >
                    재시도
                  </Button>
                ) : (
                  <></>
                )}
              </CenterAlignBox>
            )}
          </Stack>
        </Desktop>

        {/* 모바일용 레이아웃 */}
        <Mobile>
          <Stack sx={{ maxWidth: MOBILE_PORTFOLIO_WIDTH_LIMIT }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{
                width: MOBILE_PORTFOLIO_WIDTH_LIMIT,
                px: MOBILE_MARGIN_X,
                height: '24rem',
              }}
            >
              <UploadedImage initArtwork={artwork} />
              <Stack sx={{ width: '100%', height: '100%', alignItems: 'end' }}>
                <Checkbox checked={checked[idx] ?? false} onChange={handleCheck}></Checkbox>

                <Box
                  sx={{
                    height: '16.5rem', // 업로드 이미지 height와 맞춤
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ControlBoxLayout
                    artwork={artwork}
                    setArtwork={setArtwork}
                    selectedPiece={selectedPiece}
                    isOwner={isOwner}
                  />
                </Box>
              </Stack>
            </Stack>
            <Tutorial step={15}>
              <motion.div variants={itemVariants} initial="hidden" animate={controls}>
                <CenterAlignBox width="100%">
                  <GeneratedImageLayout
                    artwork={artwork}
                    setArtwork={setArtwork}
                    setSelectedPiece={setSelectedPiece}
                  />
                </CenterAlignBox>
              </motion.div>
            </Tutorial>
          </Stack>
        </Mobile>
      </CenterAlignBox>
      <Divider flexItem />
    </motion.div>
  )
}

function UploadedImage({ initArtwork }) {
  const [artwork, setArtwork] = useState({})
  const [nameEdit, setNameEdit] = useState(false)
  const [hover, setHover] = useState(false)
  const [openImageDialog, setOpenImageDialog] = useState(false)
  const isDesktop = useDesktopMediaQuery()

  const { portfolioId } = useParams()

  const user = useRecoilValue(userAtom)

  useEffect(() => {
    setArtwork(initArtwork)
  }, [initArtwork])

  return (
    <CenterAlignStack>
      <CenterAlignStack direction="row" sx={{ mb: { lg: '2rem', xs: 0 }, height: '3.6rem' }}>
        <Box
          sx={{
            display: 'flex',
            width: '14rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            pl: '2rem',
          }}
        >
          <Typography noWrap sx={{ width: 'min-content' }} fontSize="1.3rem" fontWeight={600}>
            {artwork.name}
          </Typography>
        </Box>

        {config.ARTWORK_DONE_STATUS === artwork.status && (
          <IconButton color="primary">
            <PencilIcon />
          </IconButton>
        )}
      </CenterAlignStack>
      <Box
        sx={uploadedImageBoxStyle}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpenImageDialog(true)
          }}
        >
          {/* <SearchIcon size="2rem" /> */}
        </Box>
        {artwork.uploaded && <img src={artwork.uploaded} style={uploadedImageStyle} />}
      </Box>

      <Typography
        fontSize={{ lg: '1.5rem', xs: '1.2rem' }}
        fontWeight={500}
        sx={{ mt: { lg: '1.6rem', xs: '0.5rem' } }}
      >
        {utcToLocal(artwork.created, 'YYYY/MM/DD HH:mm')}
      </Typography>
      {user.is_admin && isDesktop && (
        <Button
          variant="outlined"
          sx={{ mt: 2 }}
          onClick={() => {
            alert(
              JSON.stringify(JSON.parse(artwork.config)?.bg_search_filter)?.replaceAll(',', '\n')
            )
          }}
        >
          정보
        </Button>
      )}
      <SingleImageDialog
        open={openImageDialog}
        setOpen={setOpenImageDialog}
        image={artwork.uploaded}
      />
    </CenterAlignStack>
  )
}

function GeneratedImageLayout({ artwork, setArtwork, setSelectedPiece }) {
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)

  const [expand, setExpand] = useState(true)
  const [openImageDialog, setOpenImageDialog] = useState(false)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const [selectedPieceIdx, setSelectedPieceIdx] = useState(0)
  const [checked, setChecked] = useState([])

  useEffect(() => {
    setChecked(Array(artwork?.pieces?.length).fill(false))
  }, [artwork])

  useEffect(() => {
    const p = []
    checked.forEach((c, i) => {
      if (c) {
        p.push(artwork.pieces.map(piece => piece.path)[i])
      }
    })
    setSelectedPiece(p)
  }, [checked])

  const openImageSwiper = imageIdx => e => {
    setTutorial(prev => ({ ...prev, step: tutorial.step + 0.5 }))
    setSelectedPieceIdx(imageIdx)
    setOpenImageDialog(true)
  }

  const gridProps = {
    columns: 12,
    spacing: { lg: 1.6, xs: 1 }, // rem 은 알아먹질않아서 ..
    justifyContent: 'center',
  }

  return (
    <CenterAlignStack sx={{ width: '100%', justifyContent: 'center' }}>
      <motion.div
        style={{ overflow: 'hidden' }}
        animate={{
          height: expand ? 'auto' : '35rem',
        }}
        transition={{ duration: 0.5 }}
      >
        <Grid container {...gridProps} sx={{ pb: 2 }}>
          {artwork.pieces?.map((piece, idx) => {
            const path = piece.path
            return (
              <GeneratedImage
                key={idx}
                idx={idx}
                path={path}
                openImageSwiper={openImageSwiper(idx)}
                checked={checked}
                setChecked={setChecked}
              />
            )
          })}

          {/* 추가 피스 생성 버튼 */}
          <Grid item>
            <Box
              sx={{
                ...generatedImageStyle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: '4px 4px 20px 0px #0000000D',
                cursor: 'pointer',
                position: 'relative',
              }}
              onClick={
                isOwner
                  ? () => {
                      setOpenAddDialog(true)
                    }
                  : null
              }
            >
              {artwork.feedback_status === config.ARTWORK_ADDPIECE_ERROR_STATUS && (
                <Stack
                  direction="row"
                  sx={{
                    position: 'absolute',
                    top: { lg: '1.4rem', xs: '0.4rem' },
                    alignItems: 'center',
                  }}
                >
                  <Desktop>
                    <ErrorTriangleIcon />
                  </Desktop>
                  <Typography
                    color="#FF2323B2"
                    fontSize={{ lg: '1.2rem', xs: '1rem' }}
                    fontWeight={500}
                    sx={{ ml: '0.4rem', mt: { lg: 0, xs: '0.5rem' } }}
                  >
                    다시 시도해주세요
                  </Typography>
                </Stack>
              )}
              <CirclePlusIcon />
            </Box>
          </Grid>
          {artwork.pieces?.length % GENERATED_IMAGE_IN_ROW < 2 && <GeneratedImage path="" />}
          {artwork.pieces?.length % GENERATED_IMAGE_IN_ROW === 0 && <GeneratedImage path="" />}
        </Grid>
      </motion.div>

      {/* 접기 폐지 */}
      {/* {artwork.pieces?.length > GENERATED_IMAGE_LIMIT && (
        <CenterAlignBox
          onClick={() => {
            setExpand(v => !v)
          }}
          sx={{
            width: '100%',
            opacity: 0.8,
            height: '3rem',
            cursor: 'pointer',
            backgroundColor: '#eee',
            // position: 'relative',
            // top: -20,
          }}
        >
          {expand ? <LessIcon /> : <MoreIcon />}
        </CenterAlignBox>
      )} */}

      <ArtworksImageSwiperSlideDialog
        open={openImageDialog}
        setOpen={setOpenImageDialog}
        items={artwork.pieces ?? []}
        selectedIdx={selectedPieceIdx}
        artwork={artwork}
        setArtwork={setArtwork}
      />
    </CenterAlignStack>
  )
}

const ImageCheckbox = styled(Checkbox)(({ theme }) => ({
  cursor: 'pointer',
  position: 'absolute',
  bottom: 0,
  right: 0,
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
    borderRadius: '2rem',
  },
  '&:hover': {
    '& .MuiSvgIcon-root': {
      boxShadow: '0px 0px 4px rgba(0, 232, 185, 0.4)',
    },
  },
}))

const generatedImageStyle = {
  width: { lg: '16rem', xs: '10rem' },
  height: { lg: '16rem', xs: '10rem' },
  objectFit: 'cover',
}

function GeneratedImage({ idx, path, openImageSwiper, checked, setChecked }) {
  const [hover, setHover] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const [src, setSrc] = useState('')

  const isDesktop = useDesktopMediaQuery()

  const handleCheck = e => {
    const c = [...checked]
    c[idx] = e.target.checked
    setChecked(c)
  }

  useEffect(() => {
    // setWatermark(getS3ImageSrc(path).replace('/shadow_results/', '/thumb/')).then(url => {
    //   setSrc(url)
    // })

    setSrc(path.replace('/shadow_results/', '/thumb/'))
  }, [path])

  return (
    <Grid item>
      {path ? (
        <Box sx={{ position: 'relative', cursor: 'pointer' }}>
          <Box onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            {/* hover 할때 돋보기 아이콘 나오는 것 없애고 클릭가능한 껍데기?항상 표시 */}
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                // backdropFilter: 'saturate(50%) blur(1px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={!isDesktop && openImageSwiper}
            >
              {/* <SearchIcon size="2rem" /> */}
            </Box>
            <Box
              component="img"
              src={src}
              // src={getS3ImageSrc(path).replace('/shadow_results/', '/thumb/')}
              sx={generatedImageStyle}
            />
          </Box>
          <Desktop>
            <ImageCheckbox
              disableRipple
              checked={checked?.length > 0 ? checked[idx] : false}
              onChange={handleCheck}
              icon={<CircleIcon color="common.white" fill="#FFFFFF50" />}
              checkedIcon={<CheckedCircleIcon />}
              sx={{
                '& .MuiSvgIcon-root': {
                  boxShadow: hover ? '0px 0px 4px rgba(0, 232, 185, 0.4)' : '',
                },
              }}
            />
          </Desktop>
        </Box>
      ) : (
        <Box component="div" sx={{ ...generatedImageStyle, cursor: 'unset' }}></Box>
      )}
    </Grid>
  )
}

// TODO transition 조정
function EmptyRow() {
  const [message, setMessage] = useState('생성된 이미지가 없습니다')

  const artworkList = useRecoilValue(fakeArtworksListAtom) // 포트폴리오의 전체 아트워크 목록

  return (
    <motion.div
      initial={{ x: 30, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{
        duration: 0.8,
        // delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <CenterAlignBox
        sx={{
          pt: 5,
          pb: 1,
          // borderBottom: '0.1rem solid',
          // borderColor: theme => theme.palette.common.gray,
        }}
      >
        {message}
      </CenterAlignBox>
    </motion.div>
  )
}

function ControlBoxLayout({ artwork, setArtwork, selectedPiece, isOwner }) {
  const { portfolioId } = useParams()

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom) // 현재 포트폴리오 정보
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const portfolio = useRecoilValue(portfolioAtom) // 유저의 모든 포트폴리오 목록

  const { showConfirm } = useConfirm()

  const [likeSelected, setLikeSelected] = useState(false)
  const [dislikeSelected, setDislikeSelected] = useState(false)
  const [redoButtonClass, setRedoButtonClass] = useState('')
  const [selectedPortfolio, setSelectedPortfolio] = useState('')

  const [openRegenDialog, setOpenRegenDialog] = useState(false)

  const isMobile = useMobileMediaQuery()

  const theme = useTheme()
  const likeButtonStyle = {
    backgroundColor: theme.palette.common.white,
    borderColor: '#D9D9D9',
    borderRadius: '0.5rem',
    '&:hover': {
      borderColor: '#00BF99',
      backgroundColor: 'transparent',
      '& svg path': {
        stroke: '#00BF99',
      },
    },

    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'transparent',
      borderColor: '#00BF99',
      '& svg path': {
        stroke: '#00BF99',
      },
    },
    width: { lg: 'unset', xs: '2.4rem' },
    height: { lg: 'unset', xs: '2.4rem' },
    p: { lg: '1.1rem', xs: '0.6rem' },
  }

  const dislikeButtonStyle = {
    backgroundColor: theme.palette.common.white,
    borderColor: '#D9D9D9',
    borderRadius: '0.5rem',

    '&:hover': {
      borderColor: '#FF2323',
      backgroundColor: 'transparent',
      '& svg path': {
        stroke: '#FF2323',
      },
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'transparent',
      borderColor: '#FF2323',
      '& svg path': {
        stroke: '#FF2323',
      },
    },
    width: { lg: 'unset', xs: '2.4rem' },
    height: { lg: 'unset', xs: '2.4rem' },
    p: { lg: '1.1rem', xs: '0.6rem' },
  }

  useEffect(() => {
    const feedbackStatus = artwork.feedback_status
    if (feedbackStatus === 'like') {
      setLikeSelected(true)
      setDislikeSelected(false)
    } else if (feedbackStatus === 'dislike') {
      setLikeSelected(false)
      setDislikeSelected(true)
    }
  }, [artwork])

  useEffect(() => {
    if (likeSelected) {
      setDislikeSelected(false)
    }
  }, [likeSelected])

  useEffect(() => {
    if (dislikeSelected) {
      setLikeSelected(false)
    }
  }, [dislikeSelected])

  const download = async () => {
    const selected = selectedPiece.map(l => l)

    if (selected.length < 1) {
      showConfirm({
        alertOnly: true,
        content: <Typography>이미지를 선택 후 다운로드해주세요.</Typography>,
      })
      return
    }

    const promises = []
    const paths = []
    const ax = axios.create()
    const artworkName = artwork.name.replace('.' + _.last(artwork.name.split('.')), '')

    if (selected.length === 1) {
      const url = selected[0]
      const blob = await fetch(url + `?w=${Date.now().toString()}`).then(r => r.blob())

      const ext = _.last(_.first(_.last(url.split('/')).split('?')).split('.'))
      const idx = 1
      const fileName = `${artworkName}_draphed_${padZeros(idx)}.${ext}`

      saveAs(blob, fileName)
      paths.push(selectedPiece[0])
    } else {
      selected.forEach((url, i) => {
        paths.push(selectedPiece[i])
        if (!url) return
        promises.push(ax.get(url + `?w=${Date.now().toString()}`, { responseType: 'blob' }))
      })

      const zip = new JSZip()
      const dirName = artworkName
      zip.folder(dirName)

      Promise.all(promises).then(results => {
        results.forEach((response, idx) => {
          const ext = _.last(_.first(_.last(response.config.url.split('/')).split('?')).split('.'))
          const fileName = `${artworkName}_draphed_${padZeros(idx + 1)}.${ext}`

          zip.folder(dirName).file(fileName, response.data)
        })

        zip.generateAsync({ type: 'blob' }).then(blob => {
          saveAs(blob, `${dirName}.zip`)
        })
      })
    }
  }

  return (
    <CenterAlignStack>
      <Desktop>
        <Button
          variant="contained"
          sx={{
            fontSize: '1.8rem',
            fontWeight: 800,
            width: '25.4rem',
            height: '5.2rem',
            zIndex: tutorial.step === 15 ? 4000 : null,
            ...(artwork.download > 0
              ? { backgroundColor: theme => theme.palette.common.black }
              : {
                  backgroundColor: theme => theme.palette.common.white,
                  border: 1,
                  borderWidth: '0.2rem',
                  borderColor: theme => theme.palette.draph.blue,
                  color: theme => theme.palette.draph.blue,

                  '&:hover': {
                    backgroundColor: theme => theme.palette.draph.blue,
                    border: 0,
                    color: theme => theme.palette.common.white,
                  },
                }),
          }}
          onClick={download}
        >
          다운로드
          {/* {artwork.download > 0 && artwork.download} */}
        </Button>

        <Divider flexItem sx={{ m: '2.2rem 0', borderColor: '#B5B5B5' }} />
      </Desktop>

      <CenterAlignStack sx={{}}>
        <Typography
          fontSize={{ lg: '1.6rem', xs: '1.2rem' }}
          fontWeight={600}
          sx={{ mb: '0.7rem' }}
        >
          다른 포트폴리오로 이동
        </Typography>
        <Select
          IconComponent={isMobile ? () => <SelectSideIconThin size="small" /> : SelectSideIconThin}
          size="small"
          value={selectedPortfolio}
          sx={{
            width: { lg: '25.4rem', xs: '13.2rem' },
            height: { lg: '3.6rem', xs: '2rem' },
            '& .MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
              width: { lg: '22rem', xs: '9.3rem' },
              pl: { lg: '1.4rem', xs: '1rem' },
              pr: { lg: '3.2rem', xs: '1rem' },
              pt: { lg: '1.6rem', xs: '0.7rem' },
              pb: { lg: '1.6rem', xs: '0.7rem' },
            },
            '&:not(.Mui-disabled).Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderWidth: '0.1rem',
            },
          }}
          MenuProps={{
            sx: {
              maxHeight: { lg: '30rem', xs: '25rem' },
              // width: '25.4rem',
            },
          }}
          disabled={!isOwner}
        >
          {portfolio?.filter(p => `${p.id}` !== portfolioId).length > 0 ? (
            portfolio
              .filter(p => `${p.id}` !== portfolioId)
              .map(p => {
                return (
                  <MenuItem
                    key={p.id}
                    value={p}
                    sx={{
                      fontSize: { lg: '1.6rem', xs: '1.2rem' },
                      width: { lg: '25.4rem', xs: '12rem' },
                      maxHeight: { lg: '30rem', xs: '20rem' },
                    }}
                  >
                    {p.name}
                    {/* TODO: 포트폴리오 이름이 길어서 넘치는 경우 ... 표시 (지금은 그냥 잘림) */}
                  </MenuItem>
                )
              })
          ) : (
            <MenuItem disabled>이동할 포트폴리오가 없습니다.</MenuItem>
          )}
        </Select>
      </CenterAlignStack>

      <Divider flexItem sx={{ m: { lg: '2.2rem 0', xs: '1.6rem 0' }, borderColor: '#B5B5B5' }} />

      <CenterAlignStack direction="row" spacing={{ lg: 1, xs: 0.5 }} sx={{}}>
        <ToggleButton value="like" selected={likeSelected} sx={likeButtonStyle} disabled={!isOwner}>
          <ThumbsUpIcon size={isMobile ? 'small' : null} />
        </ToggleButton>
        <ToggleButton
          value="dislike"
          selected={dislikeSelected}
          sx={dislikeButtonStyle}
          disabled={!isOwner}
        >
          <ThumbsDownIcon size={isMobile ? 'small' : null} />
        </ToggleButton>
        <Tutorial step={16}>
          <Button
            variant="outlined"
            endIcon={<RefreshIcon size={isMobile ? 'small' : 'medium'} />}
            className={redoButtonClass}
            sx={{
              backgroundColor: theme => theme.palette.common.white,
              width: { lg: '10.4rem', xs: '6.8rem' },
              height: { lg: '3.8rem', xs: '2.4rem' },
              px: { lg: null, xs: 0 },
              borderWidth: '0.1rem',
              borderRadius: '2rem',
              fontSize: { lg: '1.4rem', xs: '1.1rem' },
              fontWeight: 600,
              lineHeight: { lg: '1.5rem', xs: '1.2rem' },
              '&:hover': {
                backgroundColor: theme => theme.palette.common.white,
                borderWidth: '0.1rem',
                color: theme => theme.palette.draph.blue,
                '& .MuiButton-endIcon path': {
                  transition: 'fill 0.3s',
                  fill: theme => theme.palette.draph.blue,
                },
              },
              '& .MuiButton-endIcon path': {
                transition: 'fill 0.3s',
              },

              '& .MuiButton-endIcon': {
                marginLeft: { lg: '0.7rem', xs: '0.3rem' },
              },
              '@keyframes tilt-shaking': {
                '0%': { transform: 'rotate(0deg)' },
                '25%': { transform: 'rotate(3deg)' },
                '50%': { transform: 'rotate(0eg)' },
                '75%': { transform: 'rotate(-3deg)' },
                '100%': { transform: 'rotate(0deg)' },
              },
              '&.shake': { animation: 'tilt-shaking 0.2s linear 3' },
            }}
            disabled={!isOwner}
          >
            재생성
          </Button>
        </Tutorial>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  '&.MuiCircularProgress-root': {
    color: '#7DA3FF',
  },

  '& .MuiCircularProgress-svg': {
    transform: 'scaleY(-1)',

    '& .MuiCircularProgress-circle': {
      '&::after': {
        content: '" *"',
        color: theme => theme.palette.common.red,
      },
    },
  },
}))
