import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { bannerTextFontAtom, unusualPortfolioConfigUpdateAtom } from 'atoms'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { CustomOutlinedInput, OpacityMotionDiv, SpanDraphBlue } from 'components/CustomStyle'
import { useEffect, useMemo, useState } from 'react'
import { ChromePicker, SketchPicker } from 'react-color'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { SelectSideIcon, SelectSideIconThin } from 'theme/icon'
import { bannerCopyReprocessing, isKo, rgbToHex } from 'utils/common'
import BannerEditor from './BannerEditor'
import { TestPage17 } from 'pages'
import { FONT_DICT, FONT_INFO } from 'config'

export default function BannerControlBoxLayout({
  artwork,
  setArtwork,
  selectedPiece,
  isOwner,
  pieceIdx,
  currentConfig,
  redoAnimationStart,
}) {
  const fontStyles = {
    title: {
      fontSize: '1.3rem',
      fontWeight: 600,
    },
    copy: {
      fontSize: '1.4rem',
      fontWeight: 600,
    },
    fontInfoTitle: {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    fontInfo: {
      fontSize: '1.2rem',
    },
  }

  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const [editorOpen, setEditorOpen] = useState(false)

  const currentPiece = artwork.pieces[pieceIdx ?? 0]
  const artworkCopy = useMemo(
    () => bannerCopyReprocessing(JSON.parse(artwork.config).copy_recomm_result),
    [artwork]
  )

  // useEffect(() => {
  //   console.log('currentPiece', currentPiece)
  //   // console.log(artworkCopy)
  // }, [currentPiece])

  if (!currentPiece.config) return

  // const textEx = [
  //   [
  //     '이미지로 말하는 AI 혁신',
  //     '드랩, 상품의 이야기를 담다',
  //     '상품 사진, 이제는 AI 시대',
  //     '드랩으로 차원이 다른 경험을',
  //     'AI가 그리는 상품의 미래',
  //     '드랩과 함께하는 창조적 여정',
  //   ],
  //   [
  //     '사진 한 장의 강력한 변화',
  //     '당신의 상품을 빛나게 할 시간',
  //     '눈길을 사로잡는 비주얼 제작',
  //     '단 몇 초, 화려한 변신 시작',
  //     '사진으로 스토리를 전달하다',
  //     '감각적인 이미지로 승부하라',
  //   ],
  // ]
  // textEx[0].push('직접입력', '없음')
  // textEx[1].push('직접입력', '없음')
  // const theme = useTheme()
  // const [selectedTitleIdx, setSelectedTitleIdx] = useState(0)
  // const [selectedsubTitleIdx, setSelectedSubTitleIdx] = useState(0)
  // const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  // const [fontInfo, setFontInfo] = useRecoilState(bannerTextFontAtom)
  // const [manualTitleInput, setManualTitleInput] = useState('')
  // const [manualSubtitleInput, setManualSubtitleInput] = useState('')
  // const handleRadio = type => e => {
  //   const { value } = e.target
  //   const setManualInput = (state, setInput) => {
  //     const manualIdx = (textEx[state].length - 2).toString()
  //     const nothingIdx = (textEx[state].length - 1).toString()
  //     const isManualIdx = manualIdx === value
  //     const isNothingIdx = nothingIdx === value
  //     if (!isManualIdx) setInput('')
  //     return isManualIdx || isNothingIdx
  //   }
  //   if (type === 'title') {
  //     const isManualIdx = setManualInput(0, setManualTitleInput)
  //     setSelectedTitleIdx(value)
  //     setBannerConfig({
  //       ...bannerConfig,
  //       bannerMainText1: isManualIdx ? '' : textEx[0][value],
  //       bannerMainTextIdx: value,
  //     })
  //   }
  //   if (type === 'subtitle') {
  //     const isManualIdx = setManualInput(1, setManualSubtitleInput)
  //     setSelectedSubTitleIdx(value)
  //     setBannerConfig({
  //       ...bannerConfig,
  //       bannerSubText1: isManualIdx ? '' : textEx[1][value],
  //       bannerSubTextIdx: value,
  //     })
  //   }
  // }
  // useEffect(() => {
  //   setBannerConfig({
  //     ...bannerConfig,
  //     bannerMainText1: textEx[0][bannerConfig.bannerMainTextIdx],
  //     bannerSubText1: textEx[1][bannerConfig.bannerSubTextIdx],
  //   })
  //   setSelectedTitleIdx(bannerConfig.bannerMainTextIdx)
  //   setSelectedSubTitleIdx(bannerConfig.bannerSubTextIdx)
  // }, [])
  // const handleChange = e => {
  //   setFontInfo(prev => ({ ...prev, font: e.target.value }))
  // }
  // const [displayColorPicker, setDisplayColorPicker] = useState(false)
  // const handleClick = () => {
  //   setDisplayColorPicker(!displayColorPicker)
  // }
  // const handleClose = () => {
  //   setDisplayColorPicker(false)
  // }
  // const handleColorChange = newColor => {
  //   console.log(newColor)
  //   setFontInfo(prev => ({ ...prev, fontColor: newColor.rgb }))
  // }
  // const menuItemStyle = { fontSize: '1.2rem', paddingLeft: '0.7rem' }
  // const fontFamily = [
  //   { value: 'Pretendard', label: 'Pretendard', style: menuItemStyle },
  //   { value: 'Arial', label: 'Arial', style: menuItemStyle },
  //   { value: 'Roboto', label: 'Roboto', style: menuItemStyle },
  //   { value: 'Helvetica', label: 'Helvetica', style: menuItemStyle },
  //   { value: 'Georgia', label: 'Georgia', style: menuItemStyle },
  //   { value: 'Times New Roman', label: 'Times New Roman', style: menuItemStyle },
  //   { value: 'Nanum Gothic', label: '나눔 고딕', style: menuItemStyle },
  //   { value: 'Noto Sans', label: '노토 산스!', style: menuItemStyle },
  //   { value: 'GmarketSans', label: '지마켓 산스~', style: menuItemStyle },
  // ]
  // return (
  //   <CenterAlignStack>
  //     <Box sx={{ display: 'flex', gap: '1.2rem', mb: '0.8rem', width: '100%' }}>
  // <FormControl sx={{}}>
  //   <Select
  //     labelId="font-select-label"
  //     id="font-select"
  //     value={fontInfo.font}
  //     onChange={handleChange}
  //     sx={{
  //       fontFamily: fontInfo.font,
  //       fontSize: '1.2rem',
  //       backgroundImage: `linear-gradient(to right, #fff 13.6rem, #f6f6f6 1rem)`,
  //       minWidth: '15.2rem',
  //       height: '2.8rem',
  //       '& .MuiOutlinedInput-notchedOutline': {
  //         borderColor: '#BABCBE',
  //       },
  //       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
  //         borderWidth: '1px',
  //       },
  //       '& .MuiSvgIcon-root': {
  //         right: '0.35rem',
  //         width: '1rem',
  //         height: '1.1rem',
  //       },
  //     }}
  //     IconComponent={SelectSideIconThin}
  //     inputProps={{ sx: { p: '0 1.6rem 0 0.7rem !important' } }}
  //     // MenuProps={{ sx: { backgroundColor: 'blue' } }}
  //   >
  //     {fontFamily.map((f, i) => {
  //       return (
  //         <MenuItem key={f.value} value={f.value} sx={{ ...f.style, fontFamily: f.value }}>
  //           {f.label}
  //         </MenuItem>
  //       )
  //     })}
  //   </Select>
  // </FormControl>
  //       <Box sx={{ border: `1px solid #BABCBE`, display: 'flex' }} onClick={handleClick}>
  //         <CenterAlignStack sx={{ justifyContent: 'space-between' }}>
  //           <Typography
  //             sx={{
  //               fontFamily: fontInfo.font,
  //               fontSize: '1.6rem',
  //               lineHeight: 'normal',
  //               pt: '0.1rem',
  //             }}
  //           >
  //             A
  //           </Typography>
  //           <Box
  //             sx={{
  //               width: '2.4rem',
  //               height: '0.7rem',
  //               background: `rgba(${fontInfo.fontColor.r},${fontInfo.fontColor.g},${fontInfo.fontColor.b},${fontInfo.fontColor.a})`,
  //             }}
  //           ></Box>
  //         </CenterAlignStack>
  //         <Box
  //           sx={{
  //             width: '1.6rem',
  //             height: '2.8rem',
  //             backgroundColor: '#F6f6f6',
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //           }}
  //         >
  //           <SelectSideIconThin size="small" />
  //         </Box>
  //       </Box>
  //       {displayColorPicker && (
  //         <Box
  //           sx={{
  //             position: 'absolute',
  //             zIndex: '2',
  //             top: '4rem',
  //             right: '0px',
  //             bottom: '0px',
  //             left: '0px',
  //           }}
  //         >
  //           <Box
  //             sx={{
  //               position: 'fixed',
  //               top: '0px',
  //               right: '0px',
  //               bottom: '0px',
  //               left: '0px',
  //               zIndex: -1,
  //             }}
  //             onClick={handleClose}
  //           />
  //           <ChromePicker
  //             color={fontInfo.fontColor}
  //             onChange={handleColorChange}
  //             disableAlpha={true}
  //           />
  //         </Box>
  //       )}
  //       {/* <Box>
  //         <Box
  //           sx={{
  //             padding: '5px',
  //             background: '#fff',
  //             borderRadius: '1px',
  //             boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
  //             display: 'inline-block',
  //             cursor: 'pointer',
  //           }}
  //           onClick={handleClick}
  //         >
  //           <Box
  //             sx={{
  //               width: '36px',
  //               height: '14px',
  //               borderRadius: '2px',
  //               background: `rgba(${fontInfo.fontColor.r},${fontInfo.fontColor.g},${fontInfo.fontColor.b},${fontInfo.fontColor.a})`,
  //             }}
  //           />
  //         </Box>
  //         {displayColorPicker && (
  //           <Box
  //             sx={{
  //               position: 'absolute',
  //               zIndex: '2',
  //               top: '0px',
  //               right: '0px',
  //               bottom: '0px',
  //               left: '0px',
  //             }}
  //           >
  //             <ChromePicker
  //               color={fontInfo.fontColor}
  //               onChange={handleColorChange}
  //               disableAlpha={true}
  //             />
  //           </Box>
  //         )}
  //       </Box> */}
  //     </Box>
  //     <CenterAlignBox
  //       sx={{
  //         minWidth: '45.6rem',
  //         minHeight: '24rem',
  //         backgroundColor: '#F9F9F9',
  //         borderRadius: '10px',
  //         p: '2.2rem 0rem 2.2rem 1.9rem',
  //       }}
  //     >
  //       <CenterAlignStack sx={{ width: '50%' }}>
  //         {' '}
  // <FormControl sx={{ width: '100%' }}>
  //   <Typography sx={{ fontSize: '1.4rem', fontWeight: 600 }}>제목</Typography>
  //   <RadioGroup
  //     aria-labelledby="demo-controlled-radio-buttons-group"
  //     name="controlled-radio-buttons-group"
  //     value={selectedTitleIdx}
  //     onChange={handleRadio('title')}
  //     sx={{ gap: '0.8rem', mt: '1.1rem' }}
  //   >
  //     {textEx[0].map((t, i) => {
  //       return (
  //         <FormControlLabel
  //           key={i}
  //           value={i}
  //           sx={{
  //             ml: '-0.2rem',
  //             mr: 0,
  //             width: 'fit-content',
  //             '& .MuiFormControlLabel-label': { fontSize: '1.2rem' },
  //           }}
  //           control={<Radio sx={{ padding: '0.2rem' }} />}
  //           label={
  //             selectedTitleIdx === i.toString() && t === '직접입력' ? (
  //               <OpacityMotionDiv initialProps={{ x: 8 }} animateProps={{ x: 0 }}>
  //                 <OutlinedInput
  //                   value={manualTitleInput}
  //                   sx={{
  //                     width: '18.5rem',
  //                     height: '2rem',
  //                     backgroundColor: theme.palette.common.white,
  //                     fontSize: '1.2rem',
  //                     p: '0rem 1rem',
  //                     '& .MuiOutlinedInput-input': {
  //                       p: 0,
  //                     },
  //                   }}
  //                   onChange={e => {
  //                     setManualTitleInput(e.target.value)
  //                     setBannerConfig({ ...bannerConfig, bannerMainText1: e.target.value })
  //                   }}
  //                 />
  //               </OpacityMotionDiv>
  //             ) : (
  //               t
  //             )
  //           }
  //         />
  //       )
  //     })}
  //   </RadioGroup>
  // </FormControl>
  //       </CenterAlignStack>
  //       <CenterAlignStack sx={{ width: '50%' }}>
  //         {' '}
  //         <FormControl sx={{ width: '100%' }}>
  //           <Typography sx={{ fontSize: '1.4rem', fontWeight: 600 }}>부제목</Typography>
  //           <RadioGroup
  //             aria-labelledby="demo-controlled-radio-buttons-group"
  //             name="controlled-radio-buttons-group"
  //             value={selectedsubTitleIdx}
  //             onChange={handleRadio('subtitle')}
  //             sx={{ gap: '0.8rem', mt: '1.1rem' }}
  //           >
  //             {textEx[1].map((t, i) => {
  //               return (
  //                 <FormControlLabel
  //                   key={i}
  //                   value={i}
  //                   sx={{
  //                     ml: '-0.2rem',
  //                     mr: 0,
  //                     width: 'fit-content',
  //                     '& .MuiFormControlLabel-label': { fontSize: '1.2rem' },
  //                   }}
  //                   control={<Radio sx={{ padding: '0.2rem' }} />}
  //                   label={
  //                     selectedsubTitleIdx === i.toString() && t === '직접입력' ? (
  //                       <OpacityMotionDiv initialProps={{ x: 8 }} animateProps={{ x: 0 }}>
  //                         <OutlinedInput
  //                           value={manualSubtitleInput}
  //                           sx={{
  //                             width: '18.5rem',
  //                             height: '2rem',
  //                             backgroundColor: theme.palette.common.white,
  //                             fontSize: '1.2rem',
  //                             p: '0rem 1rem',
  //                             '& .MuiOutlinedInput-input': {
  //                               p: 0,
  //                             },
  //                           }}
  //                           onChange={e => {
  //                             setManualSubtitleInput(e.target.value)
  //                             setBannerConfig({ ...bannerConfig, bannerSubText1: e.target.value })
  //                           }}
  //                         />
  //                       </OpacityMotionDiv>
  //                     ) : (
  //                       t
  //                     )
  //                   }
  //                 />
  //               )
  //             })}
  //           </RadioGroup>
  //         </FormControl>
  //       </CenterAlignStack>
  //     </CenterAlignBox>
  //   </CenterAlignStack>
  // )

  return !Object.keys(currentPiece.config).length ? (
    <></>
  ) : (
    <CenterAlignStack sx={{ width: '100%', ml: '4rem' }}>
      <Stack sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', gap: '1.2rem', position: 'relative' }}>
          <Typography
            sx={{
              ...fontStyles.title,
              '&:after': {
                right: '0',
                // transform: 'translateX(50%)',
                content: '""',
                position: 'absolute',
                top: '50%',
                width: isKo(i18n) ? 'calc(100% - 8rem)' : 'calc(100% - 18rem)', // 여백을 고려하여 너비 계산
                height: '1px',
                backgroundColor: 'black',
              },
            }}
          >
            {t('banner_config.control_title_1')}
          </Typography>
        </Box>

        <Typography
          sx={{
            ...fontStyles.copy,
          }}
        >
          <SpanDraphBlue>{currentPiece.config.main_text_line_1}</SpanDraphBlue>
          <br />
          <SpanDraphBlue>{currentPiece.config.main_text_line_2}</SpanDraphBlue>
        </Typography>
        <Box
          sx={{
            width: 'inherit',
            backgroundColor: '#F9F9F9',
            justifyContent: 'space-between',
            display: 'flex',
            p: '1rem 1.2rem',
            mt: '0.6rem',
            borderRadius: '6px',
          }}
        >
          <Stack>
            <Typography sx={fontStyles.fontInfoTitle}>
              {t('banner_config.control_font_class')}
            </Typography>
            <Typography
              sx={{
                ...fontStyles.fontInfo,
                ...FONT_INFO[FONT_DICT[currentPiece.config.main_text_font]],
              }}
            >
              {FONT_INFO[FONT_DICT[currentPiece.config.main_text_font]]?.label}
            </Typography>
          </Stack>

          <Stack>
            <Typography sx={fontStyles.fontInfoTitle}>
              {t('banner_config.control_font_color')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '0.4rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: '1.2rem',
                  height: '1.2rem',
                  backgroundColor: rgbToHex(currentPiece.config.main_text_color),
                  border: '1px solid #303030',
                }}
              ></Box>
              <Typography sx={fontStyles.fontInfo}>
                {rgbToHex(currentPiece.config.main_text_color)}
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Box sx={{ display: 'flex', gap: '1.2rem', position: 'relative', mt: '1.6rem' }}>
          <Typography
            sx={{
              ...fontStyles.title,
              '&:after': {
                right: '0',
                // transform: 'translateX(50%)',
                content: '""',
                position: 'absolute',
                top: '50%',
                width: isKo(i18n) ? 'calc(100% - 8rem)' : 'calc(100% - 20rem)', // 여백을 고려하여 너비 계산
                height: '1px',
                backgroundColor: 'black',
              },
            }}
          >
            {t('banner_config.control_title_2')}
          </Typography>
        </Box>

        <Typography
          sx={{
            ...fontStyles.copy,
          }}
        >
          {currentPiece.config.sub_text_line_1 ? (
            <>
              <SpanDraphBlue>{currentPiece.config.sub_text_line_1}</SpanDraphBlue>
              <br />
              <SpanDraphBlue>{currentPiece.config.sub_text_line_2}</SpanDraphBlue>
            </>
          ) : (
            <span style={{ color: '#808080' }}>{t('banner_config.no_text')}</span>
          )}
        </Typography>
        {currentPiece.config.sub_text_line_1 && (
          <Box
            sx={{
              width: 'inherit',
              backgroundColor: '#F9F9F9',
              justifyContent: 'space-between',
              display: 'flex',
              p: '1rem 1.2rem',
              mt: '0.6rem',
              borderRadius: '6px',
            }}
          >
            <Stack>
              <Typography sx={fontStyles.fontInfoTitle}>
                {t('banner_config.control_font_class')}
              </Typography>
              <Typography
                sx={{
                  ...fontStyles.fontInfo,
                  ...FONT_INFO[FONT_DICT[currentPiece.config.sub_text_font]],
                }}
              >
                {FONT_INFO[FONT_DICT[currentPiece.config.sub_text_font]]?.label}
              </Typography>
            </Stack>

            <Stack>
              <Typography sx={fontStyles.fontInfoTitle}>
                {t('banner_config.control_font_color')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '0.4rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '1.2rem',
                    height: '1.2rem',
                    backgroundColor: rgbToHex(currentPiece.config.sub_text_color),
                    border: '1px solid #303030',
                  }}
                ></Box>
                <Typography sx={fontStyles.fontInfo}>
                  {rgbToHex(currentPiece.config.sub_text_color)}
                </Typography>
              </Box>
            </Stack>
          </Box>
        )}

        <CenterAlignStack sx={{ mt: '2rem' }}>
          <Button
            variant="outlined"
            sx={{
              width: '22rem',
              height: '4.4rem',
              fontSize: { lg: '1.8rem', xs: '1.3rem' },
              fontWeight: { lg: 800, xs: 700 },

              //   zIndex: tutorial.step === 15 ? 4000 : null,
              lineHeight: 'normal',
              boxShadow: {
                lg: '2px 2px 10px 0px rgba(77, 128, 255, 0.20)',
                xs: ' 1px 1px 4px 0px rgba(77, 128, 255, 0.20)',
              },

              backgroundColor: theme => theme.palette.common.white,
              border: 1,
              borderWidth: { lg: '0.2rem', xs: '0.1rem' },
              borderColor: theme => theme.palette.draph.blue,
              color: theme => theme.palette.draph.blue,
              borderRadius: '10px',

              '&:hover': {
                backgroundColor: theme => theme.palette.draph.blue,

                color: theme => theme.palette.common.white,
              },
            }}
            onClick={() => {
              // ----- GA4 event -----
              window.gtag('event', 'banner_edit_begin', {})
              // ---------------------
              setEditorOpen(true)
            }}
          >
            {t('banner_config.control_banner_editor')}
          </Button>

          <Typography sx={{ fontSize: '1.3rem', fontWeight: 500, mt: '0.8rem' }}>
            {t('banner_config.control_banner_notice')}
          </Typography>
        </CenterAlignStack>
      </Stack>

      {editorOpen && (
        <BannerEditor
          open={editorOpen}
          setOpen={setEditorOpen}
          artwork={artwork}
          setArtwork={setArtwork}
          currentPiece={currentPiece}
          copy={artworkCopy}
        />
      )}
    </CenterAlignStack>
  )
}
