import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Container,
  Divider,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  ToggleButton,
  Typography,
  Paper,
  Grid,
  CardMedia,
  Card,
  ImageList,
  ImageListItem,
  Switch,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { useTheme } from '@mui/system'
import {
  bgConfigStepAtom,
  bgConfigAtom,
  selectedSampleAtom,
  lightDirectionAtom,
  userAtom,
  loadingAtom,
  backgroundAtom,
  backgroundFetchInfoAtom,
  touchHandleAtom,
  backgroundFilterAtom,
} from 'atoms'
import {
  CenterAlignBox,
  FlexBasis,
  CenterAlignStack,
  PerpenShadow,
  FloorShadow,
  SampleImg,
  StyledToggleButtonGroup,
  BlueSwitch,
} from 'components'
import { useCallback, useState, useEffect, useRef } from 'react'
import Draggable from 'react-draggable'
import {
  AiOutlineArrowLeft,
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlineVerticalAlignBottom,
} from 'react-icons/ai'

import 'assets/css/react-moveable.css'
import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { apis } from 'apis'
import { refreshBackground } from './Background'
import { BackIcon, CheckedCircleIcon, CircleIcon, CloseIcon, RefreshIcon } from 'theme/icon'
import Moveable from 'react-moveable'
import { BgOntlinedInput } from 'components/CustomStyle'
import useConfirm from 'hooks/useConfirm'
import useBackground from 'hooks/useBackground'
import { useTranslation } from 'react-i18next'
import { isKo } from 'utils/common'
import { getAccessToken } from 'utils/cookie'

const selectStyle = {
  width: 110,
  height: 30,
  '.MuiSelect-select': {
    paddingX: 0.6,
    paddingY: 0.5,
  },
}

const samplesPerson = [
  {
    path: '/static/sample/person/boy.png',
    horizontal: false,
    angle: 'floor',
    category: 'person',
    subCategory: 'whole',
    actualLenV: 180,
    actualLenH: 50,
  },
  {
    path: '/static/sample/person/girl.png',
    horizontal: false,
    angle: 'floor',
    category: 'person',
    subCategory: 'whole',
    actualLenV: 160,
    actualLenH: 40,
  },
]
const samplesFood = [
  {
    path: '/static/sample/food/bottle_1.png',
    horizontal: false,
    angle: 'floor',
    category: 'food',
    subCategory: 'auto',
    actualLenV: 12,
    actualLenH: 5,
  },
  {
    path: '/static/sample/food/bottle_2.png',
    horizontal: false,
    angle: 'floor',
    category: 'food',
    subCategory: 'auto',
    actualLenV: 30,
    actualLenH: 11,
  },
  {
    path: '/static/sample/food/food_front.png',
    horizontal: false,
    angle: 'floor',
    category: 'food',
    subCategory: 'auto',
    actualLenV: 10,
    actualLenH: 14,
  },
  {
    path: '/static/sample/food/plate_perpen_1.png',
    horizontal: true,
    angle: 'perpen',
    category: 'food',
    subCategory: 'auto',
    actualLenV: 24,
    actualLenH: 24,
  },
  {
    path: '/static/sample/food/plate_floor.png',
    horizontal: false,
    angle: 'diag',
    category: 'food',
    subCategory: 'auto',
    actualLenV: 18,
    actualLenH: 24,
  },
]
const samplesClothes = [
  {
    path: '/static/sample/clothes/clothing_1.png',
    horizontal: true,
    angle: 'perpen',
    category: 'clothes',
    subCategory: 'clothing',
    actualLenV: 68,
    actualLenH: 100,
  },
  {
    path: '/static/sample/clothes/clothing_2.png',
    horizontal: true,
    angle: 'perpen',
    category: 'clothes',
    subCategory: 'clothing',
    actualLenV: 100,
    actualLenH: 50,
  },
  {
    path: '/static/sample/clothes/clothing_3.png',
    horizontal: true,
    angle: 'perpen',
    category: 'clothes',
    subCategory: 'clothing',
    actualLenV: 36,
    actualLenH: 49,
  },
  {
    path: '/static/sample/clothes/shoes_1.png',
    horizontal: false,
    angle: 'floor',
    category: 'clothes',
    subCategory: 'etc',
    actualLenV: 20,
    actualLenH: 33,
  },
  {
    path: '/static/sample/clothes/shoes_2.png',
    horizontal: false,
    angle: 'floor',
    category: 'clothes',
    subCategory: 'etc',
    actualLenV: 10,
    actualLenH: 27,
  },
  {
    path: '/static/sample/clothes/shoes_3.png',
    horizontal: true,
    angle: 'perpen',
    category: 'clothes',
    subCategory: 'etc',
    actualLenV: 27,
    actualLenH: 18,
  },
  {
    path: '/static/sample/clothes/clothing_4.png',
    horizontal: true,
    angle: 'perpen',
    category: 'clothes',
    subCategory: 'clothing',
    actualLenV: 115,
    actualLenH: 43,
  },
  {
    path: '/static/sample/clothes/etc_1.png',
    horizontal: false,
    angle: 'floor',
    category: 'clothes',
    subCategory: 'etc',
    actualLenV: 34,
    actualLenH: 41,
  },
  {
    path: '/static/sample/clothes/etc_2.png',
    horizontal: false,
    angle: 'floor',
    category: 'clothes',
    subCategory: 'etc',
    actualLenV: 53,
    actualLenH: 26,
  },
]

const samplesCar = [
  {
    path: '/static/sample/car/car_1.png',
    horizontal: false,
    angle: 'floor',
    category: 'car',
    subCategory: 'auto',
    actualLenV: 150,
    actualLenH: 186,
  },
  {
    path: '/static/sample/car/car_2.png',
    horizontal: false,
    angle: 'floor',
    category: 'car',
    subCategory: 'auto',
    actualLenV: 155,
    actualLenH: 480,
  },
  {
    path: '/static/sample/car/car_3.png',
    horizontal: false,
    angle: 'floor',
    category: 'car',
    subCategory: 'auto',
    actualLenV: 130,
    actualLenH: 452,
  },
]

const samplesCosmetics = [
  {
    path: '/static/sample/cosmetics/cosmetics_1.png',
    horizontal: false,
    angle: 'floor',
    category: 'cosmetics',
    subCategory: 'auto',
    actualLenV: 9,
    actualLenH: 3,
  },

  {
    path: '/static/sample/cosmetics/cosmetics_2.png',
    horizontal: false,
    angle: 'floor',
    category: 'cosmetics',
    subCategory: 'auto',
    actualLenV: 27,
    actualLenH: 10,
  },
  {
    path: '/static/sample/cosmetics/cosmetics_3.png',
    horizontal: false,
    angle: 'floor',
    category: 'cosmetics',
    subCategory: 'auto',
    actualLenV: 25,
    actualLenH: 9,
  },
  {
    path: '/static/sample/cosmetics/cosmetics_4.png',
    horizontal: true,
    angle: 'perpen',
    category: 'cosmetics',
    subCategory: 'auto',
    actualLenV: 10,
    actualLenH: 10,
  },
  {
    path: '/static/sample/cosmetics/cosmetics_5.png',
    horizontal: false,
    angle: 'floor',
    category: 'cosmetics',
    subCategory: 'auto',
    actualLenV: 6,
    actualLenH: 8,
  },
  {
    path: '/static/sample/cosmetics/cosmetics_6.png',
    horizontal: false,
    angle: 'diag',
    category: 'cosmetics',
    subCategory: 'auto',
    actualLenV: 8,
    actualLenH: 15,
  },
]
const samplesOthers = []

const samples = {
  all: [...samplesPerson, ...samplesFood, ...samplesClothes, ...samplesOthers],
  person: samplesPerson,
  food: samplesFood,
  clothes: samplesClothes,
  car: samplesCar,
  cosmetics: samplesCosmetics,
  others: samplesOthers,
}

// const fireMouseUp = () => {
//   let event
//   if (document.createEvent) {
//     event = document.createEvent('HTMLEvents')
//     event.initEvent('mouseup', true, true)
//     event.eventName = 'mouseup'
//     document.dispatchEvent(event)
//   } else {
//     event = document.createEventObject()
//     event.eventName = 'mouseup'
//     event.eventType = 'mouseup'
//     document.fireEvent('on' + event.eventType, event)
//   }
// }

// 사진 들어가는 요소의 계산하여 나온 값.. 자동으로 바뀌지 않아서 혹시 변경되면 수동으로 바꿔야함..
const standardWidth = 436
const standardHeight = 436.5
const titleHeight = 140.5
let realWidth = 0
let realHeight = 0
let pastedImageWidth = 0
let pastedImageHeight = 0
const coveredThreshold = 0.4
const coverdBoxRatio = 1.7
let initWidth
let initHeight

export default function ManualBackgroundUpload({
  bgUploadOpen,
  setBgUploadOpen,
  imageData,
  setImageData,
}) {
  const user = useRecoilValue(userAtom)

  const [step, setStep] = useRecoilState(bgConfigStepAtom)
  const [bgConfig, setBgConfig] = useRecoilState(bgConfigAtom)
  const [selectedSample, setSelectedSample] = useRecoilState(selectedSampleAtom)
  const [isBgAddLoading, setIsAddLoading] = useRecoilState(loadingAtom)

  const { refreshBackground } = useBackground()
  const { t } = useTranslation()

  const resetFetchInfo = useResetRecoilState(backgroundFetchInfoAtom)
  const resetBgConfigStep = useResetRecoilState(bgConfigStepAtom)
  const resetBgConfig = useResetRecoilState(bgConfigAtom)
  const resetSelectedSample = useResetRecoilState(selectedSampleAtom)
  const resetIsBgAddLoading = useResetRecoilState(loadingAtom)
  const resetLightDirection = useResetRecoilState(lightDirectionAtom)
  const resetTouchHandle = useResetRecoilState(touchHandleAtom)
  const lightDirection = useRecoilValue(lightDirectionAtom)

  const [markerTop, setMarkerTop] = useState(0)
  const [markerBottom, setMarkerBottom] = useState(0)
  const [markerLeft, setMarkerLeft] = useState(0)
  const [markerRight, setMarkerRight] = useState(0)

  const [uploadImgHeight, setUploadImgHeight] = useState(0)
  const [uploadImgWidth, setUploadImgWidth] = useState(0)

  const [dragBoxHeight, setDragBoxHeight] = useState(0)
  const [spaceHeight, setSpaceHeight] = useState(0)
  const [touchHandle, setTouchHandle] = useRecoilState(touchHandleAtom)

  const { showConfirm } = useConfirm()
  const token = getAccessToken()

  useEffect(() => {
    setSpaceHeight((standardHeight - uploadImgHeight) / 2)
  }, [uploadImgHeight])

  const handleClose = e => {
    // 백드롭 클릭 시 다이얼로그 꺼지는 것을 방지하기 위해서 분기처리함.
    if (e.target.tagName !== 'DIV') {
      setBgUploadOpen(false)
      resetBgConfigStep()
      resetBgConfig()
      resetSelectedSample()
      resetTouchHandle()
    }
  }

  const onClickForward = () => {
    if (step === 0 && selectedSample.path.length > 0) {
      setBginfo()
      setStep(prev => prev + 1)
      return
    }

    if (step === 1 && bgConfig.reflect !== '' && bgConfig.cast_shadow !== '' && bgConfig.angle) {
      setStep(prev => prev + 1)
      resetTouchHandle()
      return
    }
    if (step === 2 && bgConfig.name !== '' && bgConfig.tag !== '') {
      setIsAddLoading(true)
      setBgUploadOpen(false)
      return
    }

    if (step === 0) {
      showConfirm({
        alertOnly: true,
        content: <Typography>{t('add_background_manual.warning_1')}</Typography>,
      })
    } else if (step === 2) {
      showConfirm({
        alertOnly: true,
        content: <Typography>{t('add_background_manual.warning_2')}</Typography>,
      })
    }
  }

  const onClickBackward = () => {
    if (step !== 0) {
      setSelectedSample(() => ({ path: '' }))
    }
    if (step === 1) {
      resetLightDirection()
      resetTouchHandle()
    }
    if (step === 2) {
      resetLightDirection()
      resetTouchHandle()
    }
    setStep(prev => prev - 1)
  }

  useEffect(() => {
    if (
      isBgAddLoading === true &&
      bgConfig.name !== '' &&
      (bgConfig.pos_h !== -1 || bgConfig.pos_left_w !== -1)
    ) {
      const formData = new FormData()
      formData.append('image', imageData.file)

      const params = {
        user_id: user.id,
        username: user.username,
        ...bgConfig,
      }

      apis.bg
        .uploadBgManual(formData, { params })
        .then(res => {
          // ----- GA4 event -----
          window.gtag('event', 'background_add_complete', {
            bg_category: bgConfig.category,
            config_gen_shadow: bgConfig.cast_shadow.toString(),
            bg_reflect: bgConfig.reflect.toString(),
          })
          // ---------------------

          refreshBackground()
          resetBgConfigStep()
          resetBgConfig()
          resetSelectedSample()
          resetTouchHandle()
        })
        .catch(err => {
          console.log(err)
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('upload_dialog.warning')}</Typography>,
          })
          resetBgConfigStep()
          resetBgConfig()
          resetSelectedSample()
          setTimeout(() => {
            resetIsBgAddLoading()
          }, 300)
        })
        .finally(() => {
          setTimeout(() => {
            resetIsBgAddLoading()
          }, 300)
        })
    }
  }, [bgConfig, isBgAddLoading])

  //     apis.bg
  //       .uploadBgManual(formData, { params })
  //       .then(res => {
  //         refreshBackground(fetchInfo, setFetchInfo, setBackgroundList)
  //         resetBgConfigStep()
  //         resetBgConfig()
  //         resetSelectedSample()

  //         apis.background
  //           .putBackground(res.data.id, { name: bgConfig.name })
  //           .then(() => {
  //             refreshBackground(fetchInfo, setFetchInfo, setBackgroundList)
  //             resetBgConfigStep()
  //             resetBgConfig()
  //             resetSelectedSample()
  //             resetIsBgAddLoading()
  //           })
  //           .finally(() => {
  //             resetIsBgAddLoading()
  //           })
  //       })
  //       .catch(err => {
  //         console.log(err)
  //         resetIsBgAddLoading()
  //       })
  //       .finally(() => {
  //         resetIsBgAddLoading()
  //       })
  //   }
  // }, [isBgAddLoading])

  const onLoadImage = e => {
    // 이미지 비율에 따라 높이를 최대로 너비를 최대로 할지 결정 후 비율 맞혀서 div값을 지정.
    realWidth = e.target.naturalWidth
    realHeight = e.target.naturalHeight

    const realRatio = realHeight / realWidth
    const standardRatio = standardHeight / standardWidth

    if (realRatio >= standardRatio) {
      setUploadImgWidth((realWidth * standardHeight) / realHeight)
      setUploadImgHeight(standardHeight)
    }

    if (realRatio < standardRatio) {
      setUploadImgWidth(standardWidth)
      setUploadImgHeight((realHeight * standardWidth) / realWidth)
    }
  }

  const setMarkerInfo = markers => {
    const copyData = {
      pos_w: -1,
      pos_h: -1,
      h_standard: -1,

      left_top_w: -1,
      left_top_h: -1,
      right_bottom_w: -1,
      right_bottom_h: -1,

      pos_left_w: -1,
      pos_right_w: -1,
    }

    markers.forEach(({ name, position, hStandard }) => {
      if (name === 'bottom') {
        copyData.pos_w = position.w
        copyData.pos_h = position.h
      }

      if (name === 'top') {
        copyData.h_standard = hStandard
      }

      if (name === 'leftTop') {
        copyData.left_top_w = position.w
        copyData.left_top_h = position.h
      }

      if (name === 'rightBottom') {
        copyData.right_bottom_w = position.w
        copyData.right_bottom_h = position.h
      }

      if (name === 'left') {
        copyData.pos_left_w = position.w
      }

      if (name === 'right') {
        copyData.pos_right_w = position.w
      }
    })
    // console.log(copyData)

    setBgConfig(prev => ({ ...prev, ...copyData }))
  }

  const calculatePixelPosition = pointInfo => {
    // console.log(realWidth, uploadImgWidth)
    const posW = (pointInfo[0] * realWidth) / uploadImgWidth
    const posH = (pointInfo[1] * realHeight) / uploadImgHeight
    return { w: Math.floor(posW), h: Math.floor(posH) }
  }

  const setBginfo = async () => {
    const moveableEl = document.getElementById('moveableBox')
    const boxEl = document.getElementById('fakeBox')

    const moveableElClientRect = moveableEl.getBoundingClientRect()
    const boxElClientRect = boxEl.getBoundingClientRect()

    // console.log(moveableEl.getBoundingClientRect().x)
    // console.log(
    //   'width',
    //   moveableEl.getBoundingClientRect().x,
    //   boxEl.getBoundingClientRect().x,
    //   moveableEl.getBoundingClientRect().x - boxEl.getBoundingClientRect().x
    // )
    const regex = /-?\d*\.?\d+/g // 숫자를 찾기 위한 정규식

    const arr = moveableEl.style.transform.match(regex).map(Number)

    // const transform = elem.style.transform.split('(')[1].split(')')[0].split(',')
    // const l = Number(transform[0].replace('px', '').trim())
    // const t = Number(transform[1].replace('px', '').trim())
    const l = moveableElClientRect.x - boxElClientRect.x
    const t = moveableElClientRect.y - boxElClientRect.y
    const w = moveableElClientRect.width
    const h = selectedSample.horizontal
      ? moveableElClientRect.height / 2
      : moveableElClientRect.height
    // console.log(l, t, w, h)

    let hStandard = -1

    let bottom = { dotName: 'bottom', x: l + w / 2, y: t + h }

    let top = { dotName: 'top', x: l + w / 2, y: t, hStandard }
    let left = { dotName: 'left', x: l, y: t + h }
    let right = { dotName: 'right', x: l + w, y: t + h }

    const lt = { dotName: 'leftTop', x: null, y: null }
    const rb = { dotName: 'rightBottom', x: null, y: null }

    let dots = []
    // horizontal=false 이미지는 배치 위치 점(top, bottom) 을 저장한다
    // horizontal=true 이미지는 가로 범위 점(left, right) 을 저장한다
    if (selectedSample.horizontal) {
      dots = [left, right, bottom]
    } else {
      dots = [bottom, top]
    }

    // 카테고리 서브 카테고리 지정
    const category = bgConfig.category
    let subcategory = selectedSample.subCategory
    // 한계점을 넘을 경우, 좌표 변경
    if (category === 'person' && t < 0) {
      top = { dotName: 'top', x: l + w / 2, y: 0, hStandard }
    }

    if (category === 'person' && t + h > uploadImgHeight) {
      bottom = { dotName: 'bottom', x: l + w / 2, y: uploadImgHeight }
      left = { dotName: 'left', x: l, y: uploadImgHeight }
      right = { dotName: 'right', x: l + w, y: uploadImgHeight }
    }

    // threshold 만들어 넘을 경우 서브 카테고리 변경
    if (category === 'person' && t < -(dragBoxHeight * coveredThreshold)) {
      subcategory = 'lower'
      top = { dotName: 'top', x: l + w / 2, y: 0, hStandard }
    }

    if (category === 'person' && t + h > uploadImgHeight + dragBoxHeight * coveredThreshold) {
      subcategory = 'torso'
      bottom = { dotName: 'bottom', x: l + w / 2, y: uploadImgHeight }
      left = { dotName: 'left', x: l, y: uploadImgHeight }
      right = { dotName: 'right', x: l + w, y: uploadImgHeight }
    }

    if (
      category === 'person' &&
      t + h > uploadImgHeight + dragBoxHeight * 0.2 &&
      t < -(dragBoxHeight * 0.2)
    ) {
      subcategory = 'body'
      bottom = { dotName: 'bottom', x: l + w / 2, y: uploadImgHeight }
      top = { dotName: 'top', x: l + w / 2, y: 0, hStandard }
      left = { dotName: 'left', x: l, y: uploadImgHeight }
      right = { dotName: 'right', x: l + w, y: uploadImgHeight }
    }

    const btw = bottom.y - top.y
    hStandard = Math.round((btw * realHeight) / uploadImgHeight)

    setMarkerTop([top.x, top.y])
    setMarkerBottom([bottom.x, bottom.y])
    setMarkerLeft([left.x, left.y])
    setMarkerRight([right.x, right.y])

    const copyConfig = { ...bgConfig }

    copyConfig.sub_category = subcategory
    setBgConfig(copyConfig)

    setMarkerInfo(
      dots.map(d => ({
        name: d.dotName,
        position: calculatePixelPosition([d.x, d.y]),
        hStandard,
      }))
    )
  }

  return (
    <>
      <Dialog
        open={bgUploadOpen}
        fullWidth={true}
        maxWidth="lg"
        onClose={handleClose}
        sx={{
          overflow: 'hidden',
          py: 2,
          '& .MuiDialog-paper': {
            overflowY: 'hidden',
            maxHeight: '727px',
            minHeight: '727px',
            maxWidth: '984px',
            minWidth: '984px',
            borderRadius: '20px',
          },
        }}
      >
        <DialogTitle
          sx={{
            position: 'relative',
            pt: '58px',
            pb: '45px',
            overflowY: 'hidden',
            backgroundColor: 'white',
            zIndex: 9999,
          }}
        >
          <CenterAlignBox>
            <Typography sx={{ textAlign: 'center', fontSize: '25px', fontWeight: '700' }}>
              {t('add_background_manual.title')}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', right: '26px', top: '25px' }}
            >
              <CloseIcon sx={{ width: '12px', height: '12px' }} />
            </IconButton>
            {user.is_admin && <Button onClick={setBginfo}>점 찍기</Button>}
          </CenterAlignBox>
        </DialogTitle>

        <DialogContent
          sx={{
            mx: '56px',
            overflow: 'hidden',
            p: 0,
            maxHeight: standardHeight,
            minHeight: standardHeight,
          }}
        >
          <CenterAlignBox height="100%">
            <FlexBasis flexBasis={['50%', '0%', '50%']} justifyContent="center" alignItems="center">
              <Stack>
                <FlexBasis flexBasis={['33.3%', '33.3%', '33.3%']}>
                  <div
                    style={{
                      backgroundColor: '#FFF',
                      height: spaceHeight,
                      zIndex: 10,
                    }}
                  ></div>
                  <div
                    style={{
                      backgroundImage: `url(${imageData.url})`,
                      width: uploadImgWidth,
                      height: uploadImgHeight,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  >
                    {selectedSample?.path?.length > 0 && step === 0 && (
                      <div>
                        {user.is_admin && (
                          <svg
                            style={{
                              height: uploadImgHeight,
                              width: uploadImgWidth,
                              position: 'absolute',
                            }}
                          >
                            <circle
                              key="1"
                              fill="red"
                              stroke="black"
                              strokeWidth="1"
                              r="5"
                              cx={markerTop[0]}
                              cy={markerTop[1]}
                            />
                            <circle
                              key="2"
                              fill="skyblue"
                              stroke="black"
                              strokeWidth="1"
                              r="5"
                              cx={markerBottom[0]}
                              cy={markerBottom[1]}
                            />
                            <circle
                              key="3"
                              fill="yellow"
                              stroke="black"
                              strokeWidth="1"
                              r="5"
                              cx={markerLeft[0]}
                              cy={markerLeft[1]}
                            />
                            <circle
                              key="4"
                              fill="green"
                              stroke="black"
                              strokeWidth="1"
                              r="5"
                              cx={markerRight[0]}
                              cy={markerRight[1]}
                            />
                          </svg>
                        )}
                        <ResizableImage
                          key={selectedSample.path}
                          setSelectedSample={setSelectedSample}
                          src={selectedSample.path}
                          category={selectedSample.category}
                          parentWidth={uploadImgWidth}
                          parentHeight={uploadImgHeight}
                          dragBoxHeight={dragBoxHeight}
                          setDragBoxHeight={setDragBoxHeight}
                          // resetMarkers={resetMarkers}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      backgroundColor: '#FFF',
                      height: spaceHeight,
                      zIndex: 10,
                    }}
                  ></div>
                </FlexBasis>
              </Stack>

              <img src={imageData.url} style={{ display: 'none' }} onLoad={onLoadImage} />

              <BackgroundConfig
                selectedFileURL={imageData.url}
                uploadImgWidth={uploadImgWidth}
                uploadImgHeight={uploadImgHeight}
                setDragBoxHeight={setDragBoxHeight}
              />
            </FlexBasis>
          </CenterAlignBox>
        </DialogContent>

        <CenterAlignBox
          gap={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            pt: '40px',
            pb: '50px',
            backgroundColor: 'white',
            zIndex: 9999,
          }}
        >
          {step !== 0 ? (
            <IconButton onClick={onClickBackward}>
              <BackIcon style={{ height: '40px' }} />
            </IconButton>
          ) : (
            <Box sx={{ width: '37px', height: '55.99px' }}></Box>
          )}

          <Button
            variant="contained"
            size="large"
            sx={{ width: '260px', height: '60px' }}
            onClick={onClickForward}
            disabled={step === 1 && !touchHandle}
          >
            {step !== 2 ? t('button.next') : t('button.add_background_s')}
          </Button>
          <Box sx={{ width: '37px', height: '55.99px' }}></Box>
        </CenterAlignBox>
      </Dialog>
    </>
  )
}

function ResizableImage({
  parentWidth,
  parentHeight,
  src,
  setSelectedSample,
  resetMarkers,
  dragBoxHeight,
  setDragBoxHeight,
  category,
}) {
  const bgConfig = useRecoilValue(bgConfigAtom)
  const [maxSizeWidth, setMaxSizeWidth] = useState(Math.floor(parentWidth))
  const [maxSizeHeight, setMaxSizeHeight] = useState(Math.floor(parentHeight + dragBoxHeight * 2))
  const [limitedWidth, setLimitedWidth] = useState(maxSizeWidth)
  const [limitedHeight, setLimitedHeight] = useState(maxSizeHeight)

  const imgRef = useRef()

  useEffect(() => {
    if (category !== 'person') {
      setDragBoxHeight(0)
    }
  }, [category, initHeight])

  useEffect(() => {
    const widthCompared = maxSizeWidth / initWidth
    const HeightCompared = maxSizeHeight / initHeight

    if (widthCompared >= HeightCompared) {
      setLimitedHeight(parentHeight + dragBoxHeight * 2)
      setLimitedWidth(((parentHeight + dragBoxHeight * 2) / initHeight) * initWidth)
    } else {
      setLimitedWidth(maxSizeWidth)
      setLimitedHeight((parentWidth / initWidth) * initHeight)
    }
  }, [maxSizeWidth, maxSizeHeight])

  return (
    <div
      style={{
        height: category === 'person' ? parentHeight + dragBoxHeight * 2 : parentHeight,
        width: parentWidth,
        position: 'absolute',
        // top: `${dragBoxHeight / 2}px`,
        top:
          category === 'person'
            ? (standardHeight - parentHeight) / 2 - dragBoxHeight + titleHeight + 'px'
            : null,
        // border: '2px dashed lime'
        zIndex: 5,
      }}
    >
      <div
        id="fakeBox"
        style={{
          position: 'absolute',
          top: category === 'person' ? dragBoxHeight + 'px' : null,
          left: '0px',
          display: 'inline-block',
          width: initWidth,
          height: initHeight,
          transform: 'translate(0px, 0px) scale(1, 1)',
        }}
      ></div>
      <div
        ref={imgRef}
        id="moveableBox"
        style={{
          position: 'absolute',
          top: category === 'person' ? dragBoxHeight + 'px' : null,
          left: '0px',
          display: 'inline-block',
          width: initWidth,
          height: initHeight,
          transform: 'translate(0px, 0px) scale(1, 1)',
        }}
      >
        <div style={{ position: 'relative', width: initWidth, height: initHeight }}>
          <img
            src={src}
            style={{
              width: '100%',
              height: '100%',
              opacity: 0.8,
              // width: 'inherit', height: 'inherit'
            }}
          />
          {/* <IconButton
            onClick={() => {
              setSelectedSample({ path: '' })
              // resetMarkers()
            }}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: 0,
              '&:hover': { opacity: 0.5 },
              zIndex: 99,
            }}
          >
            <AiOutlineCloseCircle style={{ width: '15px', height: '15px' }} />
          </IconButton> */}
        </div>
      </div>
      <Moveable
        target={imgRef}
        scalable={true}
        keepRatio={true}
        draggable={true}
        snappable={true}
        origin={false}
        edgeDraggable={true}
        bounds={{ left: 0, top: 0, bottom: 0, right: 0, position: 'css' }}
        onDrag={e => {
          e.target.style.transform = e.transform
        }}
        onScale={e => {
          const imgWidth = e.scale[0] * e.offsetWidth
          const imgHeigth = e.scale[1] * e.offsetHeight
          const imgWidth1 = e.scale[0] * e.offsetWidth - e.offsetWidth
          const imgHeigth1 = e.scale[1] * e.offsetHeight - e.offsetHeight

          if (bgConfig.category === 'person' && imgWidth >= initWidth && imgHeigth >= initHeight) {
            e.target.style.transform = e.drag.transform
            // setDragBoxHeight((imgHeigth / 2) * coverdBoxRatio)
          } else if (
            bgConfig.category !== 'person' &&
            imgWidth >= initWidth * 0.3 &&
            imgHeigth >= initHeight * 0.3
          ) {
            e.target.style.transform = e.drag.transform
          }

          // const imgHeigth = e.scale[1] * e.offsetHeight
          // setDragBoxHeight((imgHeigth / 2) * coverdBoxRatio)

          // setDragBoxHeight((data.size.height / 2) * coverdBoxRatio)
        }}
        // onScaleEnd={e => {
        //   console.log(e)
        //   console.log(
        //     Math.min(e.lastEvent.scale[1], 1),
        //     e.lastEvent.offsetHeight,
        //     e.lastEvent.scale[1] * e.lastEvent.offsetHeight,
        //     e.target
        //   )
        //   const imgHeigth = e.lastEvent.scale[1] * e.lastEvent.offsetHeight
        //   console.log('end')
        //   setDragBoxHeight((imgHeigth / 2) * coverdBoxRatio)
        // }}
      />
    </div>
  )
}

function BackgroundConfig({ selectedFileURL, uploadImgWidth, uploadImgHeight, setDragBoxHeight }) {
  const step = useRecoilValue(bgConfigStepAtom)

  switch (step) {
    case 0:
      return (
        <BgPosInfo
          selectedFileURL={selectedFileURL}
          parentWidth={uploadImgWidth}
          parentHeight={uploadImgHeight}
          setDragBoxHeight={setDragBoxHeight}
        />
      )
    case 1:
      return <BgInfo />
    case 2:
      return <BgNameTag />
  }
}

function BgNameTag() {
  const { t } = useTranslation()
  const [bgConfig, setBgConfig] = useRecoilState(bgConfigAtom)
  const [namePlaceholder, setNamePlaceholder] = useState(
    t('add_background_manual.step_2_placeholder_1')
  )
  const [tagPlaceholder, setTagPlaceholder] = useState(
    t('add_background_manual.step_2_placeholder_2')
  )

  const onChangHandle = key => e => {
    if (key === 'name') {
      setBgConfig(prev => {
        return { ...prev, name: e.target.value }
      })
    }

    if (key === 'tag') {
      setBgConfig(prev => {
        return { ...prev, tag: e.target.value }
      })
    }
  }

  return (
    <Box sx={{ pl: '37px' }}>
      <Typography sx={{ fontWeight: 600, fontSize: '18px', pb: '4px' }}>
        {t('add_background_manual.step_2_title_1')}
      </Typography>
      <Typography sx={{ fontWeight: 400, fontSize: '14px', pb: '15px' }}>
        {t('add_background_manual.step_2_comment_1')}
      </Typography>
      <BgOntlinedInput
        sx={{ width: '100%', height: '37px', mb: 4 }}
        value={bgConfig.name}
        onChange={onChangHandle('name')}
        placeholder={namePlaceholder}
        onFocus={() => {
          setNamePlaceholder('')
        }}
        type="text"
        inputProps={{ maxLength: 20 }}
        onBlur={() => {
          setNamePlaceholder(t('add_background_manual.step_2_placeholder_1'))
        }}
      />

      <Typography sx={{ fontWeight: 600, fontSize: '18px', pb: '4px' }}>
        {t('add_background_manual.step_2_title_2')}
      </Typography>
      <Typography sx={{ fontWeight: 400, fontSize: '14px', pb: '15px' }}>
        {t('add_background_manual.step_2_comment_2')}
      </Typography>
      <BgOntlinedInput
        sx={{ width: '100%', height: '37px', mb: 4 }}
        value={bgConfig.tag}
        onChange={onChangHandle('tag')}
        placeholder={tagPlaceholder}
        type="text"
        inputProps={{ maxLength: 40 }}
        onFocus={() => {
          setTagPlaceholder('')
        }}
        onBlur={() => {
          setTagPlaceholder(t('add_background_manual.step_2_placeholder_2'))
        }}
      />
    </Box>
  )
}

const CATEGORIES = [
  { value: 'person', text: '모델' },
  { value: 'clothes', text: '의류' },
  { value: 'food', text: '식품' },
  { value: 'car', text: '자동차' },
  { value: 'cosmetics', text: '화장품' },
  // { value: 'others', text: '그 외' },
]

const OPEN = [
  { value: true, text: 'true' },
  { value: false, text: 'false' },
] // 배경 뒤가 뚫려있는지 여부

function BgInfo() {
  const [bgConfig, setBgConfig] = useRecoilState(bgConfigAtom)
  const [shadowMode, setShadowMode] = useState('perpen')
  const [isVertical, setIsVertical] = useState(false)
  const [lightDirection, setLightDirection] = useRecoilState(lightDirectionAtom)
  const [touchHandle, setTouchHandle] = useRecoilState(touchHandleAtom)

  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const setValueHandle = key => e => {
    if (key === 'angle') {
      setShadowMode(e.target.value, key)
    }
    const updateBgConfig = { ...bgConfig }

    updateBgConfig[key] = e.target.value
    setBgConfig(updateBgConfig)
  }

  const lightDirectionHandle = e => {
    if (e.target.checked) {
      setLightDirection('vertical')
    } else {
      setLightDirection('horizontal')
    }
    setTouchHandle(true)
    setIsVertical(e.target.checked)
  }

  // useEffect(() => {
  //   if (bgConfig.light_src === 8) {
  //     setIsVertical(true)
  //   }
  // }, [])

  const REFLECT = [
    { value: 0, text: t('add_background_manual.step_1_reflect_select_1') },
    { value: 1, text: t('add_background_manual.step_1_reflect_select_2') },
    { value: 2, text: t('add_background_manual.step_1_reflect_select_3') },
  ]

  return (
    <Stack
      sx={{
        width: '100%',
        height: standardHeight,
        minHeight: standardHeight,
        maxHeight: standardHeight,
        ml: 2,
      }}
    >
      <Stack spacing={1}>
        <CenterAlignBox sx={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {t('add_background_manual.step_1_subtitle_1')}
            </Typography>
            <BlueSwitch
              checked={bgConfig.cast_shadow}
              onChange={e => {
                setBgConfig(prev => ({ ...prev, cast_shadow: e.target.checked }))
              }}
            />
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
              {t('add_background_manual.step_1_subtitle_2')}
            </Typography>
            <Select
              value={bgConfig.reflect}
              onChange={setValueHandle('reflect')}
              sx={{
                width: '100px',
                height: '28px',
                textAlign: 'center',
              }}
              defaultValue={t('add_background_manual.step_1_reflect_select_1')}
            >
              {REFLECT.map(item => {
                return (
                  <MenuItem value={item.value} key={item.value}>
                    {item.text}
                  </MenuItem>
                )
              })}
            </Select>
          </Stack>
        </CenterAlignBox>
        <CenterAlignStack>
          <Divider flexItem sx={{ width: '85%', mt: '25px !important', alignSelf: 'center' }} />
        </CenterAlignStack>
      </Stack>
      <Box sx={{ ml: '10px' }}>
        <CenterAlignStack sx={{ pt: '23px' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '20px' }}>
            {t('add_background_manual.step_1_title')}
          </Typography>
        </CenterAlignStack>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: '15px' }}>
          {' '}
          <FormControlLabel
            control={
              <Checkbox
                icon={<CircleIcon style={{ width: '17px', height: '17px' }} />}
                checkedIcon={<CheckedCircleIcon style={{ width: '17px', height: '17px' }} />}
              />
            }
            onChange={lightDirectionHandle}
            checked={isVertical}
            label={t('add_background_manual.step_1_option')}
          />
        </Box>
        <Stack sx={{ display: 'flex', alignItems: 'center', mt: '10px', mb: '20px' }}>
          <Box
            sx={{
              width: isKo(i18n) ? '312px' : '356px',
              height: '32px',
              border: `1px solid ${theme.palette.draph.blue}`,
              borderRadius: '1rem',
              boxShadow: '4px 4px 10px 0px rgba(77, 128, 255, 0.10)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 600,
                textAlign: 'center',
                color: theme.palette.draph.blue,
              }}
            >
              {t('add_background_manual.step_1_comment')}
            </Typography>
          </Box>
        </Stack>
        <CenterAlignStack sx={{ alignItems: 'center' }}>
          {bgConfig.angle === 'perpen' ? <PerpenShadow /> : <FloorShadow />}
        </CenterAlignStack>
      </Box>
      {/* <Paper
          elevation={1}
          sx={{
            display: 'flex',
            border: theme => `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
          }}
        >
          <StyledToggleButtonGroup value={lightDirection} exclusive onChange={lightDirectionHandle}>
            <ToggleButton value="horizontal">측면</ToggleButton>
            <ToggleButton value="vertical">
              <AiOutlineVerticalAlignBottom size="2rem" />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Paper> */}
    </Stack>
  )
}

function InputWithLabel({ items, label, value, setValue }) {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography variant="subtitle1">{label}</Typography>

      <Select size="small" value={value} onChange={setValue}>
        {items.map(item => {
          if (typeof item === 'object') {
            return (
              <MenuItem value={item.value} key={item.value}>
                {item.text}
              </MenuItem>
            )
          }
          return (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          )
        })}
      </Select>
    </Stack>
  )
}

// 모든 아톰 꼭 끝기 !
function BgPosInfo({ selectedFileURL, parentWidth, parentHeight, setDragBoxHeight }) {
  const [bgConfig, setBgConfig] = useRecoilState(bgConfigAtom)
  const [selectedSample, setSelectedSample] = useRecoilState(selectedSampleAtom)
  const imageRefs = useRef([])
  const theme = useTheme()
  const { t } = useTranslation()

  const onChange = e => {
    const updateBgConfig = { ...bgConfig }

    updateBgConfig.category = e.target.value
    setBgConfig(updateBgConfig)
  }

  const onLoad = category => e => {
    if (category === 'car') {
      if (e.target.offsetWidth > e.target.offsetHeight) {
        e.target.style.width = '70%'
        e.target.style.height = 'auto'
      } else {
        e.target.style.width = 'auto'
        e.target.style.height = '87%'
      }
    } else if (category === 'clothes') {
      if (e.target.offsetWidth > e.target.offsetHeight) {
        e.target.style.width = '98%'
        e.target.style.height = 'auto'
      } else {
        e.target.style.width = 'auto'
        e.target.style.height = '78%'
      }
    } else if (category === 'food') {
      if (e.target.offsetWidth > e.target.offsetHeight) {
        e.target.style.width = '90%'
        e.target.style.height = 'auto'
      } else {
        e.target.style.width = 'auto'
        e.target.style.height = '75%'
      }
    } else {
      if (e.target.offsetWidth > e.target.offsetHeight) {
        e.target.style.width = '90%'
        e.target.style.height = 'auto'
      } else {
        e.target.style.width = 'auto'
        e.target.style.height = '90%'
      }
    }
  }

  const onClick = image => e => {
    setSelectedSample(image)

    pastedImageWidth = e.target.offsetWidth
    pastedImageHeight = e.target.offsetHeight

    if (pastedImageWidth > pastedImageHeight) {
      initWidth = parentWidth / 2
      initHeight = (initWidth * pastedImageHeight) / pastedImageWidth
    } else {
      initHeight = parentHeight / 2
      initWidth = (initHeight * pastedImageWidth) / pastedImageHeight
    }

    setDragBoxHeight((initHeight / 2) * coverdBoxRatio)
  }

  useEffect(() => {
    const updateBgConfig = { ...bgConfig }

    updateBgConfig.angle = selectedSample.angle
    updateBgConfig.actual_len_v = selectedSample.actualLenV
    updateBgConfig.actual_len_h = selectedSample.actualLenH
    setBgConfig(updateBgConfig)
  }, [selectedSample])

  const CATEGORIES = [
    { value: 'person', text: t('artwork_category.model') },
    { value: 'clothes', text: t('artwork_category.clothing') },
    { value: 'food', text: t('artwork_category.food') },
    { value: 'car', text: t('artwork_category.car') },
    { value: 'cosmetics', text: t('artwork_category.cosmetics') },
    // { value: 'others', text: '그 외' },
  ]

  return (
    <Box id="bg" gap={2} sx={{ height: standardHeight, ml: '31px' }}>
      <CenterAlignStack sx={{ alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 600, fontSize: '16px', pb: '2px' }}>
          {t('add_background_manual.step_0_title')}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
          {t('add_background_manual.step_0_comment')}
        </Typography>

        <CenterAlignBox
          sx={{
            display: 'flex',
            width: '405px',
            gap: '12.5px',
            py: '25px',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>
            {t('upload_config.product_category_s')}
          </Typography>
          <Select
            value={bgConfig.category}
            onChange={onChange}
            sx={{
              width: '100px',
              height: '28px',
              textAlign: 'center',
            }}
          >
            {CATEGORIES.map(item => {
              return (
                <MenuItem value={item.value} key={item.value}>
                  {item.text}
                </MenuItem>
              )
            })}
          </Select>
          <Box sx={{ position: 'absolute', right: '0px' }}>
            <Button
              variant="text"
              sx={{
                p: 0,
                borderBottom: `2px solid ${theme.palette.common.black}`,
                color: theme.palette.common.black,
                fontWeight: 500,
                width: '60px',
                minWidth: '60px',
                height: '28px',
                borderRadius: 0,
                '&:hover': {
                  // backgroundColor: theme.palette.action.hover,
                  backgroundColor: '#F5F5F5',

                  borderBottom: `2px solid ${theme.palette.draph.blue}`,
                },
              }}
              onClick={() => {
                setSelectedSample({ path: '' })
              }}
            >
              {t('button.reset')} &nbsp;
              <RefreshIcon />
            </Button>
          </Box>
        </CenterAlignBox>
        <Box sx={{ height: '314px', overflow: 'auto', pb: 1 }}>
          <Grid container direction="row" alignItems="flex-end" px={0.5} spacing={1}>
            {samples[bgConfig.category]?.map((image, idx) => {
              if (bgConfig.category === 'person') {
                return (
                  <Grid
                    item
                    key={image.path}
                    lg={6}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Paper
                      sx={{
                        width: '200px',
                        height: '301px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.07)',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.7,
                        },
                      }}
                      onClick={() => imageRefs.current[idx]?.click()}
                    >
                      <SampleImg
                        ref={el => (imageRefs.current[idx] = el)} // 각 이미지에 대한 독립적인 ref 설정
                        src={image.path}
                        alt={image.path}
                        onLoad={onLoad(bgConfig.category)}
                        onClick={onClick(image)}
                      />
                    </Paper>
                    <Divider />
                  </Grid>
                )
              }

              if (bgConfig.category === 'food') {
                return (
                  <Grid
                    item
                    key={image.path}
                    lg={
                      idx % samples[bgConfig.category].length === 3 ||
                      idx % samples[bgConfig.category].length === 4
                        ? 6
                        : 4
                    }
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Paper
                      sx={{
                        width: '100%',
                        height: '135px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.07)',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.7,
                        },
                      }}
                      onClick={() => imageRefs.current[idx]?.click()}
                    >
                      <SampleImg
                        ref={el => (imageRefs.current[idx] = el)}
                        src={image.path}
                        alt={image.path}
                        onClick={onClick(image)}
                        onLoad={onLoad(bgConfig.category)}
                      />
                    </Paper>
                  </Grid>
                )
              }

              if (bgConfig.category === 'car') {
                return (
                  <Grid
                    item
                    key={image.path}
                    lg={
                      idx % samples[bgConfig.category].length === 1 ||
                      idx % samples[bgConfig.category].length === 0
                        ? 6
                        : 12
                    }
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Paper
                      sx={{
                        width: '100%',
                        height: '137px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.07)',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.7,
                        },
                      }}
                      onClick={() => imageRefs.current[idx]?.click()}
                    >
                      <SampleImg
                        ref={el => (imageRefs.current[idx] = el)}
                        src={image.path}
                        alt={image.path}
                        onClick={onClick(image)}
                        onLoad={onLoad(bgConfig.category)}
                      />
                    </Paper>
                    <Divider />
                  </Grid>
                )
              }

              if (bgConfig.category === 'clothes') {
                return (
                  <Grid
                    item
                    key={image.path}
                    lg={4}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Paper
                      sx={{
                        width: '127px',
                        height: '178px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.07)',
                        borderRadius: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.7,
                        },
                      }}
                      onClick={() => imageRefs.current[idx]?.click()}
                    >
                      <SampleImg
                        ref={el => (imageRefs.current[idx] = el)}
                        src={image.path}
                        alt={image.path}
                        onClick={onClick(image)}
                        onLoad={onLoad(bgConfig.category)}
                      />
                    </Paper>
                    <Divider />
                  </Grid>
                )
              }

              return (
                <Grid
                  item
                  key={image.path}
                  lg={4}
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Paper
                    sx={{
                      width: '127px',
                      height: '144px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.07)',
                      borderRadius: '10px',
                      cursor: 'pointer',
                      '&:hover': {
                        opacity: 0.7,
                      },
                    }}
                    onClick={() => imageRefs.current[idx]?.click()}
                  >
                    <SampleImg
                      ref={el => (imageRefs.current[idx] = el)}
                      src={image.path}
                      alt={image.path}
                      onClick={onClick(image)}
                      onLoad={onLoad(bgConfig.category)}
                    />
                  </Paper>
                  <Divider />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </CenterAlignStack>
    </Box>
  )
}
