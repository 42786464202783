import { apis } from 'apis'
import {
  PORTFOLIO_CONFIG_DEFAULT,
  brandConfigUpdateAtom,
  currentMenuAtom,
  defaultPortfolioAtom,
  endpointAtom,
  portfolioArtworkAtom,
  portfolioConfigAtom,
  portfolioDetailAtom,
  portfolioTypeAtom,
  unusualPortfolioConfigUpdateAtom,
  userAtom,
  userLikedBgAtom,
} from 'atoms'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { creditPolicyDictSelector, portfolioUploadConfigSelector } from 'selector'
import useCreditWarningDialog from './useCreditWarningDialog'
import { DEFAULT_PORTFOLIO_TYPES } from 'config'
import { getUploadUUID } from 'utils/artwork'
import * as config from 'config'
import useConfirm from './useConfirm'
import { Typography } from '@mui/material'
import { resizeImage } from 'utils/common'
import { processInGroups } from 'components'
import { getAccessToken } from 'utils/cookie'

const uploadImglimit = 20
const uploadMaxSize = 3000

export function usePortfolio() {
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)

  const uploadConfig = useRecoilValue(portfolioUploadConfigSelector)
  const creditPolicy = useRecoilValue(creditPolicyDictSelector)
  const [user, setUser] = useRecoilState(userAtom)

  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)
  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const portfolioId = portfolioDetail.id

  const [artworkList, setArtworkList] = useRecoilState(portfolioArtworkAtom)

  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [brandConfig, setBrandConfig] = useRecoilState(brandConfigUpdateAtom)

  const { t, i18n } = useTranslation()

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isDefaultType = DEFAULT_PORTFOLIO_TYPES.includes(portfolioType)
  const isBgExpansionPage = portfolioType === config.PORTFOLIO_TYPE_BGEXPANSION

  const { showConfirm } = useConfirm()
  const { showCreditWarningDialog } = useCreditWarningDialog()
  const currentMenu = useRecoilValue(currentMenuAtom)

  const updatePortfolioDetail = async (addOption = {}) => {
    const editedConfigForbody = { ...portfolioConfig }
    let configOutputlist = [...editedConfigForbody.outputSizeList] ?? []

    if (!portfolioConfig.outputWidth || !portfolioConfig.outputHeight) {
      editedConfigForbody.outputHeight = null
      editedConfigForbody.outputWidth = null
    }

    if (configOutputlist.length !== 0) {
      configOutputlist = configOutputlist.filter(o => o.w !== null && o.h !== null)
    }

    if (configOutputlist.length > 1) {
      editedConfigForbody.simpleBgColorList = [[255, 255, 255]]
    } else {
      editedConfigForbody.simpleBgColorList = PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList
    }

    editedConfigForbody.outputSizeList = configOutputlist

    const body = {
      new_name: user.username,
      theme: portfolioConfig.theme,
      config: { ...editedConfigForbody, ...addOption },
    }

    return apis.portfolio.updatePortfolio(defaultPortfolio.id, body).then(response => {
      setPortfolioDetail({ ...portfolioDetail, ...response.data })
      return { ...portfolioDetail, ...response.data }
    })
  }

  const refreshArtworks = callback => {
    // apis.portfolio.getAllPortfolioArtwork(portfolioId).then(response => {
    //   setArtworkList(response.data)
    // })

    // if (portfolioId) {
    //   if (defaultPortfolio.type === 'mannequin') {
    //     console.log(defaultPortfolio)
    //     const pIds = [portfolioId]
    //     if (defaultPortfolio.facePortfolio) {
    //       pIds.push(defaultPortfolio.facePortfolio.id)
    //     }

    //     apis.portfolio.getAllPortfolioArtwork(pIds).then(response => {
    //       setArtworkList(response.data)
    //       if (typeof callback === 'function') {
    //         callback()
    //       }
    //     })
    //   } else {
    //     apis.portfolio.getAllPortfolioArtwork(portfolioId).then(response => {
    //       setArtworkList(response.data)
    //       if (typeof callback === 'function') {
    //         callback()
    //       }
    //     })
    //   }
    // }
    apis.portfolio.getAllPortfolioArtwork(portfolioId).then(response => {
      setArtworkList(response.data)
      if (typeof callback === 'function') {
        callback()
      }
    })
  }

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const prepareUpload = async (
    files,
    _ = null,
    creditType = null,
    checkCreditByGroup = false,
    isMannequin = false
  ) => {
    const uploadFiles = [...files]
    let checkCreditType = null

    // if (portfolioConfig.theme_template === 'custom' && portfolioConfig.theme_custom?.length < 1) {
    //   showConfirm({
    //     alertOnly: true,
    //     content: <Typography>배경 테마를 입력해 주세요</Typography>,
    //   })
    //   return
    // }

    // 통합
    // START 크레딧 확인 ----------------------------------------------------
    if (!creditType) {
      if (portfolioType === config.PORTFOLIO_TYPE_FAME) {
        checkCreditType = 'artwork_create_fame'
      } else if (portfolioType === config.PORTFOLIO_TYPE_FACE) {
        checkCreditType = 'artwork_create'
      } else if (portfolioType === config.PORTFOLIO_TYPE_BUNDLE) {
        checkCreditType = 'artwork_create_bundle'
      } else if (portfolioType === config.PORTFOLIO_TYPE_MODELBG) {
        checkCreditType = 'artwork_create_modelbg'
      } else if (portfolioType === config.PORTFOLIO_TYPE_BANNER) {
        checkCreditType = 'artwork_create_banner'
      } else if (portfolioConfig.outputSizeList?.length > 1) {
        checkCreditType = 'artwork_create_multi_size'
      } else if (!uploadConfig.flag_generate && !uploadConfig.flag_complex_cmp) {
        // 생성 배경과 합성 배경 사용하지 않는 경우
        if (!uploadConfig.flag_simple_cmp) {
          checkCreditType = 'artwork_create_remove_bg_only'
        } else {
          checkCreditType = 'artwork_create_simple_only'
        }
      } else {
        checkCreditType = 'artwork_create'
      }
    } else {
      checkCreditType = creditType
    }

    if (isMannequin) {
      checkCreditType = 'artwork_create_mannequin_model'
    }

    const price = checkCreditByGroup
      ? creditPolicy[checkCreditType] ?? 0
      : creditPolicy[checkCreditType] * (uploadFiles.length || 1)

    if (user.use_credit_on.includes(checkCreditType)) {
      const credit = await checkUserCredit()

      if (credit < price) {
        // ----- GA4 event -----
        window.gtag('event', 'not_enough_credit_imp', {
          menu: currentMenu?.id,
        })
        // ---------------------

        showCreditWarningDialog()
        return
      }
    }
    // END 크레딧 확인 ----------------------------------------------------

    if (isMannequin) {
      return { success: true }
    }

    // if (['admin', 'enterprise'].includes(user.grade)) {
    //   //
    // } else if (files.length > uploadImglimit) {
    //   showConfirm({
    //     alertOnly: true,
    //     content: <Typography>한 번에 최대 20개의 이미지만 업로드 됩니다.</Typography>,
    //   })
    //   uploadFiles = Array.prototype.slice.call(files, 0, uploadImglimit)
    // }

    return uploadFiles
  }

  const triggerGa4InputImageSize = (width, height) => {
    // ----- GA4 event -----
    window.gtag('event', 'input_size', {
      // 매개변수 신규등록 없이 기존것 재활용 하기 위해 이름만 output_size_xxxx
      output_size_width: width,
      output_size_height: height,
    })
  }

  const makeUploadFormData = async (
    uploadFiles,
    addGenOption = {},
    addFormData = {},
    mode = 'default'
  ) => {
    const formData = new FormData()
    const token = getAccessToken()
    const uploadGroupUid = getUploadUUID()
    // const uploadGroupUid = uuidv4() // 한꺼번에 업로드된 아트워크 묶음 구분

    switch (mode) {
      case 'banner': {
        const genOptions = { logoS3path: brandConfig.logoS3path, ...addGenOption }

        formData.append('user_id', user.id)
        formData.append('username', user.username)
        formData.append('portfolio_id', portfolioId)

        formData.append('image', uploadFiles[0] ?? '')
        formData.append('image_filename', uploadFiles[0]?.name ?? '')
        formData.append('logo_image', brandConfig.logoImage[0] ?? '')
        formData.append('product_name', bannerConfig.productName)
        formData.append('product_feature', bannerConfig.productFeature)
        formData.append('product_category', bannerConfig.productCategory)
        formData.append('brand_name', brandConfig.brandName)
        formData.append('brand_feature', brandConfig.brandFeature)
        formData.append('banner_main_text_1', '')
        formData.append('banner_main_text_2', '')
        formData.append('banner_sub_text_1', '')
        formData.append('banner_sub_text_2', '')
        formData.append('output_w', bannerConfig.outputW ?? 1024)
        formData.append('output_h', bannerConfig.outputH ?? 1024)
        formData.append('geodai_max_flag', bannerConfig.geodaiMax ?? false)
        formData.append('human_depict', bannerConfig.humanDepict ?? '')
        formData.append('product_depict', bannerConfig.productDepict ?? '')
        formData.append('bg_depict', bannerConfig.bgDepict ?? '')
        formData.append('human_interaction_flag', bannerConfig.humanInteractionFlag)
        formData.append('mann2man_image_s3_url_list', JSON.stringify([]))
        formData.append('gen_type', bannerConfig.genType ?? 'no_SO')

        formData.append('banner_style', bannerConfig.bannerStyle ?? 'image_hooking')
        formData.append('copy_style', bannerConfig.copyStyle ?? 'question')
        formData.append('prev_price', bannerConfig.prevPrice ?? '')
        formData.append('post_price', bannerConfig.postPrice ?? '')

        formData.append('mann2man_image', '')
        formData.append('binary_mask', '')
        formData.append('mann2man_is_male', '')
        formData.append('mann2man_age', '')

        if (Object.keys(addFormData).length !== 0) {
          Object.keys(addFormData).forEach(k => {
            formData.delete(k)
            formData.append(k, addFormData[k])
          })
        }

        formData.append('gen_options', JSON.stringify(genOptions))

        return formData
      }

      case 'mannequin': {
        formData.append('image', uploadFiles[0])
        formData.append('user_id', user.id)
        formData.append('username', user.username)
        formData.append('gen_type', 'initial')
        formData.append('portfolio_id', portfolioDetail.id)
        formData.append('artwork_id', '')

        if (Object.keys(addFormData).length !== 0) {
          Object.keys(addFormData).forEach(k => {
            formData.delete(k)
            formData.append(k, addFormData[k])
          })
        }

        return formData
      }

      case 'modelFace': {
        formData.append('image', uploadFiles[0])
        formData.append('user_id', user.id)
        formData.append('username', user.username)
        formData.append('gen_type', 'initial')
        formData.append('portfolio_id', portfolioDetail.id)
        formData.append('artwork_id', '')
        formData.append('age', 'adult')

        if (Object.keys(addFormData).length !== 0) {
          Object.keys(addFormData).forEach(k => {
            formData.delete(k)
            formData.append(k, addFormData[k])
          })
        }

        return formData
      }

      case 'default': {
        console.log('--------- start appending images')

        const promises = uploadFiles.map(f => {
          const func = async () => {
            try {
              const image = new Image()
              const objectUrl = window.URL.createObjectURL(f)
              let smallImgFile = null

              image.src = objectUrl
              await image.decode()

              triggerGa4InputImageSize(image.width, image.height)

              if (image.width > uploadMaxSize || image.height > uploadMaxSize) {
                console.log(f.name, '..resizing image')
                const smallerImage = await resizeImage(f, uploadMaxSize)
                // console.log('smallerImage', smallerImage)
                smallerImage.lastModified = new Date()
                smallerImage.name = f.name
                smallImgFile = new File([smallerImage], f.name)

                formData.append('images', smallImgFile)
              } else {
                console.log(f.name)
                formData.append('images', f)
              }
            } catch (e) {
              console.log(e, 'ERROR ', f.name)
            }
          }

          return func
        })

        await processInGroups(promises)

        console.log('--------- END appending images')

        const selectedBgIds = userLikedBg.pinned ?? []

        //
        const genOptions = {
          ...uploadConfig,
          flag_generate: uploadConfig.flag_generate ?? true,
          flag_complex_cmp: uploadConfig.flag_complex_cmp ?? true,
          flag_simple_cmp: uploadConfig.flag_simple_cmp ?? false,
          flag_white_cmp: uploadConfig.flag_white_cmp ?? true,

          output_size_w: uploadConfig.output_size_w ?? 0,
          output_size_h: uploadConfig.output_size_h ?? 0,

          selected_bg_ids: portfolioConfig.flagMultiblobSod
            ? ''
            : selectedBgIds
            ? selectedBgIds.join('-----')
            : '',
          endpoints: endpoint ?? {},
          gen_shadow: uploadConfig.gen_shadow,
          gen_face: uploadConfig.gen_face,
          object_category: uploadConfig.object_category ?? 'auto',
          object_sub_category: uploadConfig.object_sub_category ?? 'auto',
          object_boundary: uploadConfig.object_boundary ?? 'none',
          object_angle: 'auto', // 포트폴리오 설정에서 각도 옵션을 삭제하여서, 최초 업로드시 무조건 자동으로 하도록 수정

          flag_gen_compo: portfolioConfig.flagGenCompo,
          flag_bg_expansion: portfolioConfig.flagBgExpansion,
          flag_multiblob_sod: portfolioConfig.flagMultiblobSod,

          output_size_list: uploadConfig.output_size_list ?? [],
          simple_bg_color_list: uploadConfig.simple_bg_color_list ?? [[255, 255, 255]],

          //
          theme_template: uploadConfig.theme_template ?? 'auto',
          theme_custom: uploadConfig.theme_custom ?? '',

          //
          flag_human_background: false,
          SO_length_scale: 'auto',
          //

          //
          flag_facemorphing: false,
          facemorphing_race: 'asian',
          facemorphing_gender: 'none',
          //

          // 항상 마지막에 -----
          ...addGenOption,
          // ---------------

          // 포트폴리오가 from_zip true 여도 일반 업로드에서는 꺼줘야 아트워크 설정에서 구분 가능
          from_zip: false,
          zip_path: null,

          //
          upload_id: uploadGroupUid,
        }

        formData.append('user_id', user.id)
        formData.append('username', user.username)
        formData.append('portfolio_id', portfolioId)

        if (Object.keys(addFormData).length !== 0) {
          Object.keys(addFormData).forEach(k => {
            formData.delete(k)
            formData.append(k, addFormData[k])
          })
        }

        formData.append('gen_options', JSON.stringify(genOptions))

        // ------------------------------------------------------------------------------------------

        return formData
      }
      // ---------------
    }
  }

  return {
    refreshArtworks,
    checkUserCredit,
    prepareUpload,
    makeUploadFormData,
    updatePortfolioDetail,
  }
}
