import { Typography } from '@mui/material'
import { Desktop, Mobile } from 'hooks/useMediaQuery'

export default function ServiceTitle(props) {
  return (
    <>
      <Desktop>
        <Typography color="white" fontSize="2.8rem" fontWeight={800} {...props}>
          DRAPH ART
        </Typography>
      </Desktop>
      <Mobile>
        <Typography color="white" fontSize="2rem" fontWeight={600} {...props}>
          DRAPH ART
        </Typography>
      </Mobile>
    </>
  )
}
