import axios from 'axios'
import { configData } from 'config'
import { sendApiErrorAlert } from 'utils/common'
import * as cookie from 'utils/cookie'

export const mlAxios = axios.create({
  // baseURL: 'http://100.109.2.26:6913/api/v1/sod',
  baseURL: configData.ML_URL,
  // withCredentials: true,
  headers: {},
})

mlAxios.interceptors.request.use(config => {
  if (!config.headers.Authorization) {
    const accessToken = cookie.getAccessToken()

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
  }

  return config
})

mlAxios.interceptors.response.use(
  response => response,
  async error => {
    console.log(error)

    const { response } = error

    if (response && response.status && response.status === 500) {
      sendApiErrorAlert({
        path: error.request.responseURL,
        httpStatus: response.status,
        message: error.message,
        detail: response,
      })
    } else if (response && response.status && response.status === 401) {
      if (response.data?.detail === 'new_login_detected') {
        window.location.reload()
        alert('로그인 정보가 변경되었습니다. 확인을 누르신 후 다시 진행해주세요.')
        return
      }
    }

    return Promise.reject(error)
  }
)

export const mlStgAxios = axios.create({
  baseURL: 'https://api-stg.draph.art',
  // withCredentials: true,
  headers: {},
})

mlStgAxios.interceptors.request.use(config => {
  const accessToken = cookie.getAccessToken()
  config.headers.Authorization = `Bearer ${accessToken}`
  return config
})

mlStgAxios.interceptors.response.use(
  response => response,
  async error => {
    console.log(error)
    // TODO
    return Promise.reject(error)
  }
)

export const mlPrevAxios = axios.create({
  baseURL: 'https://api-prev.draph.art',
  // withCredentials: true,
  headers: {},
})

mlPrevAxios.interceptors.request.use(config => {
  const accessToken = cookie.getAccessToken()
  config.headers.Authorization = `Bearer ${accessToken}`
  return config
})

mlPrevAxios.interceptors.response.use(
  response => response,
  async error => {
    console.log(error)
    // TODO
    return Promise.reject(error)
  }
)
