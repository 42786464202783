import {
  CenterAlignBox,
  CenterAlignStack,
  TempLayoutDiv,
  BackgroundItem,
  AddBackgroundModal,
  BackgroundSkeleton,
  BackgroundImageSwiperSlideDialog,
  FlexBasis,
  SearchInput,
  ScrollToTop,
  SearchInputMobile,
} from 'components'
import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  OutlinedInput,
  Input,
  Checkbox,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  Grow,
  Select,
  MenuItem,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  backgroundAtom,
  backgroundDialogAtom,
  backgroundFetchInfoAtom,
  backgroundNameFilterAtom,
  loadingAtom,
  backgroundFilterAtom,
  userLikedBgAtom,
} from 'atoms'

import { backgroundFilterSelector } from 'selector'

import { useEffect, useState, useCallback, useRef } from 'react'
import { apis } from 'apis'

// for infinite scroll

import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { BorderInput, StyledMaterialDesignContent } from 'components/CustomStyle'
import {
  CheckedCircleIcon,
  CheckedCircleIconNotistack,
  CircleIcon,
  ErrorTriangleIconNotistack,
  SearchIcon,
  SelectSideMobileIcon,
} from 'theme/icon'
import { PuffLoader } from 'react-spinners'
import { SnackbarProvider } from 'notistack'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import useBackground from 'hooks/useBackground'
import { useTranslation } from 'react-i18next'

const allFalse = { user: false, pinned: false }
const onlyUserFalse = { user: false, pinned: true }
const onlyPinnedFalse = { user: true, pinned: true }

const backgroundSelect = [
  { value: allFalse, text: '모두 보기' },
  { value: onlyUserFalse, text: '고정 배경' },
  { value: onlyPinnedFalse, text: '개인 배경' },
]

// page를 2개 이상일 때 2개 이상분을 가져와 새로고침한다 ?!
// export const refreshBackground = (
//   fetchInfo,
//   setFetchInfo,
//   setBackgroundList,
//   setUserLikedBg,
//   filter
// ) => {
//   const pageSize = window.devicePixelRatio < 1 ? 70 : 30
//   const params = new URLSearchParams()
//   if (pageSize * (fetchInfo.page - 1) === 0) return
//   setFetchInfo(prev => ({ ...prev, isLoading: true }))

//   console.log('refreshing')
//   params.append('page', 1)
//   params.append('page_size', pageSize * (fetchInfo.page - 1))
//   params.append('pinned', filter.pinned)
//   params.append('user', filter.user)

//   apis.background
//     .getBackgrounds(params)
//     .then(response => {
//       setBackgroundList(response.data)

//       setFetchInfo(prev => {
//         // console.log(response.data.length, pageSize)
//         const hasNextPage = Boolean(response.data.length >= pageSize)

//         return { ...prev, hasNextPage }
//       })
//     })
//     .catch(() => {
//       setFetchInfo(prev => ({ ...prev, hasNextPage: false }))
//     })
//     .finally(() => {
//       setFetchInfo(prev => ({ ...prev, isLoading: false }))
//     })

//   if (setUserLikedBg) {
//     apis.background.getLikedBackgrounds().then(response => {
//       setUserLikedBg(response.data)
//     })
//   }
// }

// export const fetchBackground = (fetchInfo, setFetchInfo, setBackgroundList, filter) => {
//   const pageSize = window.devicePixelRatio < 1 ? 70 : 30
//   console.log('fetchLoading', fetchInfo.isLoading)
//   setFetchInfo(prev => ({ ...prev, isLoading: true }))
//   // console.log('배경 가져오기')
//   const params = new URLSearchParams()
//   params.append('page', fetchInfo.page)
//   params.append('page_size', pageSize)
//   params.append('pinned', filter.pinned)
//   params.append('user', filter.user)

//   apis.background
//     .getBackgrounds(params)
//     .then(response => {
//       setBackgroundList(b => {
//         if (b) {
//           return b.concat(response.data)
//         } else {
//           return response.data
//         }
//       })
//       setFetchInfo(prev => {
//         const afterPage = prev.page + 1
//         const hasNextPage = Boolean(response.data.length === pageSize)

//         return { ...prev, page: afterPage, hasNextPage }
//       })
//     })
//     .catch(() => {
//       setFetchInfo(prev => ({ ...prev, hasNextPage: false }))
//     })
//     .finally(() => {
//       setFetchInfo(prev => ({ ...prev, isLoading: false }))
//     })
// }

export default function Background() {
  const [open, setOpen] = useState(false)
  const [selectValue, setSelectValue] = useState(allFalse)
  const [ref, inView] = useInView({})
  const theme = useTheme()
  const { t } = useTranslation()

  const [fetchInfo, setFetchInfo] = useRecoilState(backgroundFetchInfoAtom)
  const [backgroundList, setBackgroundList] = useRecoilState(backgroundAtom)
  const [nameFilter, setNameFilter] = useRecoilState(backgroundNameFilterAtom)
  const [filter, setFilter] = useRecoilState(backgroundFilterAtom)
  const isBgAddLoading = useRecoilValue(loadingAtom)

  const { refreshBackground, fetchBackground } = useBackground()

  const filteredBackgroundList = useRecoilValue(backgroundFilterSelector)

  const resetFetchInfo = useResetRecoilState(backgroundFetchInfoAtom)
  const resetBackgroundList = useResetRecoilState(backgroundAtom)
  const resetIsBgAddLoading = useResetRecoilState(loadingAtom)
  const resetBackgroundDialog = useResetRecoilState(backgroundDialogAtom)
  const resetNameFilter = useResetRecoilState(backgroundNameFilterAtom)
  const resetFilter = useResetRecoilState(backgroundFilterAtom)

  const onFilterName = event => {
    setNameFilter(event.target.value.toLowerCase())
  }

  const snackbarProps = {
    maxSnack: 1,
    autoHideDuration: 1000,
  }

  // useEffect(() => {
  //   console.log('fetchInfo', fetchInfo)
  // })

  useEffect(() => {
    return () => {
      // console.log('end')
      resetBackgroundList()
      resetFetchInfo()
      resetIsBgAddLoading()
      resetBackgroundDialog()
      resetNameFilter()
      resetFilter()
    }
  }, [])

  useEffect(() => {
    apis.background.getBackgroundStat().then(response => {
      // ----- GA4 event -----
      window.gtag('event', 'background_manage', {
        count: response.data.total,
        bg_liked: response.data.liked,
        bg_pinned: response.data.pinned,
      })
      // ---------------------
    })
  }, [])

  // useEffect(() => {
  //   window.addEventListener('resize', handleScroll)
  //   return () => {
  //     window.removeEventListener('resize', handleScroll)
  //   }
  // }, [])

  useEffect(() => {
    // console.log(inView, fetchInfo.hasNextPage, !fetchInfo.isLoading)
    if (inView && fetchInfo.hasNextPage && !fetchInfo.isLoading) {
      fetchBackground(fetchInfo, setFetchInfo, setBackgroundList, filter)
    }
  }, [inView, fetchInfo.hasNextPage, backgroundList])

  useEffect(() => {
    refreshBackground()
  }, [filter.pinned, filter.user])

  const handleUserBg = e => {
    const checked = e.target.checked
    setFilter(f => ({ ...f, user: checked }))
  }

  const handlePinnedBg = e => {
    const checked = e.target.checked
    setFilter(f => ({ ...f, pinned: checked }))
  }

  const handleSelectBgFilter = e => {
    const user = e.target.value.user
    const pinned = e.target.value.pinned

    setSelectValue(() => e.target.value)
    setFilter(f => ({ ...f, user, pinned }))
  }

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ overflowX: 'visible' }}>
          <CenterAlignBox
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            sx={{ width: '100%', mb: '3.8rem', maxWidth: '104.3rem' }}
          >
            <FlexBasis flexBasis={['30%', '40%', '30%']} display="flex">
              <Box sx={{ ml: '1rem' }}>
                <FormGroup sx={{ display: 'flex', flexDirection: 'inherit' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={
                          <CircleIcon sx={{ width: '2rem', height: '2rem', fontSize: '2rem' }} />
                        }
                        checkedIcon={<CheckedCircleIcon />}
                      />
                    }
                    onChange={handlePinnedBg}
                    label={
                      <Typography sx={{ fontSize: '2rem', fontWeight: 600, mr: '2rem' }}>
                        {t('background.pinned_background')}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox icon={<CircleIcon />} checkedIcon={<CheckedCircleIcon />} />}
                    onChange={handleUserBg}
                    label={
                      <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>
                        {' '}
                        {t('background.personal_background')}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {' '}
                <Button
                  variant="outlined"
                  sx={{ width: '26rem', height: '6rem', fontSize: '2rem', fontWeight: 800 }}
                  onClick={() => setOpen(!open)}
                  disabled={isBgAddLoading}
                >
                  {t('button.add_background')}
                </Button>
              </Box>

              <Box
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                // sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <SearchInput
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={nameFilter}
                  onChange={onFilterName}
                />
              </Box>
            </FlexBasis>
          </CenterAlignBox>

          <AddBackgroundModal open={open} setOpen={setOpen} />

          {filteredBackgroundList === null ? (
            <BackgroundLoading />
          ) : (
            <Grid
              container
              gap={{ xs: 2, lg: 2.21 }}
              columns={{ xs: 4, lg: 12 }}
              justifyContent="flex-start"
              width={{ lg: '104.3rem' }}
              sx={{ pb: '7.5rem' }}
            >
              <BackgroundLoading />
            </Grid>
          )}

          {filteredBackgroundList === null && (nameFilter || filter.pinned || filter.user) ? (
            <motion.div
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Grid item sx={{ marginLeft: 0 }}>
                <Typography variant="body1" sx={{ fontSize: '2.7rem' }}>
                  {t('background.empty_filtered_background')}
                </Typography>
              </Grid>
            </motion.div>
          ) : (
            //
            <div ref={ref}>
              {!filteredBackgroundList ? (
                fetchInfo.hasNextPage ? (
                  <PuffLoader />
                ) : null
              ) : fetchInfo.hasNextPage ? (
                <PuffLoader />
              ) : null}
            </div>
          )}

          {/* </InfiniteScroll> */}
        </CenterAlignStack>
      </Desktop>
      <Mobile>
        <CenterAlignStack sx={{ pb: '3.5rem', overflowX: 'hidden' }}>
          <Typography fontSize="2.4rem" fontWeight={800}>
            {t('background.background_management')}
          </Typography>
          <Typography fontSize="1.4rem" fontWeight={400}>
            {t('background.mobile_info')}
          </Typography>

          <CenterAlignBox
            sx={{
              width: '100%',
              height: '5rem',
              borderColor: theme.palette.common.black,
              mt: '3.3rem',
              borderTop: 1,
              borderTopWidth: '0.2rem',
              borderBottom: 1,
              borderBottomWidth: '0.1rem',
            }}
          >
            <Stack
              sx={{ width: '36rem', px: '1.9rem' }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center">
                <Select
                  value={selectValue}
                  onChange={handleSelectBgFilter}
                  sx={{
                    width: '9.4rem',
                    height: '2.8rem',
                    '& .MuiOutlinedInput-input.MuiSelect-select': {
                      paddingLeft: '1rem',
                      paddingRight: '1rem !important',
                    },
                    '& .MuiSelect-icon': {
                      top: 'calc(50% - 0.5em)',
                      right: '7px',
                    },
                  }}
                  IconComponent={SelectSideMobileIcon}
                >
                  <MenuItem value={allFalse} selected>
                    {t('background.show_all')}
                  </MenuItem>
                  <MenuItem value={onlyUserFalse}> {t('background.pinned_background')}</MenuItem>
                  <MenuItem value={onlyPinnedFalse}>{t('background.personal_background')}</MenuItem>
                </Select>
              </Stack>

              <Stack direction="row" alignItems="center" sx={{}}>
                <SearchInputMobile value={nameFilter} onChange={onFilterName} />
              </Stack>
            </Stack>
          </CenterAlignBox>

          {filteredBackgroundList === null ? (
            <BackgroundLoading />
          ) : (
            <Grid
              container
              gap={{ xs: 2 }}
              columns={{ xs: 4 }}
              justifyContent="flex-start"
              paddingY="3.25rem"
              width="32.9rem"
            >
              <BackgroundLoading />
            </Grid>
          )}

          {nameFilter || filter.pinned || filter.user ? (
            <motion.div
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Grid item sx={{ marginLeft: 0 }}>
                <Typography variant="body1" sx={{ fontSize: '1.55rem' }}>
                  {filteredBackgroundList === null
                    ? t('background.empty_filtered_background')
                    : ' '}
                </Typography>
              </Grid>
            </motion.div>
          ) : (
            <div ref={ref}>
              {!filteredBackgroundList ? (
                fetchInfo.hasNextPage ? (
                  <PuffLoader />
                ) : null
              ) : fetchInfo.hasNextPage ? (
                <PuffLoader />
              ) : null}
            </div>
          )}
        </CenterAlignStack>
      </Mobile>
      <ScrollToTop upside={true} />

      <SnackbarProvider
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <CheckedCircleIconNotistack />,
          error: <ErrorTriangleIconNotistack />,
        }}
        style={{ marginTop: '17.5rem' }}
        {...snackbarProps}
        TransitionComponent={Grow}
      >
        <BackgroundImageSwiperSlideDialog />
      </SnackbarProvider>
    </>
  )
}
const BackgroundLoading = () => {
  // 임시 세팅
  // const [tempBg, setTempBg] = useState([])

  // useEffect(() => {
  //   const params = new URLSearchParams()

  //   params.append('page', 1)
  //   params.append('page_size', 2)
  //   apis.background.getBackgrounds(params).then(response => {
  //     setTempBg(response.data)
  //   })
  // }, [])
  // 임시 세팅
  const fetchInfo = useRecoilValue(backgroundFetchInfoAtom)
  const isBgAddLoading = useRecoilValue(loadingAtom)
  const filteredBackgroundList = useRecoilValue(backgroundFilterSelector)
  const [filter, setFilter] = useRecoilState(backgroundFilterAtom)
  const [nameFilter, setNameFilter] = useRecoilState(backgroundNameFilterAtom)
  const { t } = useTranslation()
  // console.log('isBgAddLoading', isBgAddLoading)

  switch (true) {
    case fetchInfo.isLoading === true && isBgAddLoading === false:
      return (
        <>
          {filteredBackgroundList?.map((bg, idx) => {
            return <BackgroundItem key={`${bg.id}_${idx}`} bg={bg} idx={idx} />
          })}
          {filteredBackgroundList && filteredBackgroundList.length > 0 && (
            <>
              <BackgroundSkeleton />
              <BackgroundSkeleton />
              <BackgroundSkeleton />
              <BackgroundSkeleton />
            </>
          )}
        </>
      )

    case isBgAddLoading === true:
      return (
        <>
          <BackgroundSkeleton />
          {filteredBackgroundList?.map((bg, idx) => {
            return <BackgroundItem key={`${bg.id}_${idx}`} bg={bg} idx={idx} />
          })}
        </>
      )
    default:
      return filteredBackgroundList === null ? (
        <motion.div
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Desktop>
            <CenterAlignStack sx={{ mt: 4 }}>
              {nameFilter || filter.pinned || filter.user ? null : (
                <Typography variant="body1" sx={{ fontSize: '2.7rem' }}>
                  {t('background.empty_background_1_a')}
                  <span style={{ fontWeight: 'bold' }}>{t('background.empty_background_1_b')}</span>
                  {t('background.empty_background_1_c')}
                </Typography>
              )}
            </CenterAlignStack>
          </Desktop>
          <Mobile>
            <CenterAlignStack sx={{ mt: 4 }}>
              {nameFilter || filter.pinned || filter.user ? null : (
                <Typography variant="body1" sx={{ fontSize: '1.55rem' }}>
                  {t('background.empty_background_1_a')}
                  <span style={{ fontWeight: 'bold' }}>{t('background.empty_background_1_b')}</span>
                  {t('background.empty_background_1_c')}
                </Typography>
              )}
            </CenterAlignStack>
          </Mobile>
        </motion.div>
      ) : (
        filteredBackgroundList?.map((bg, idx) => {
          return (
            <>
              <BackgroundItem key={`${bg.id}_${idx}`} bg={bg} idx={idx} />
            </>
          )
        })
      )
  }
}
