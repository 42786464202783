import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Stack,
  Button,
  IconButton,
  Divider,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import {
  userAtom,
  viewModeAtom,
  portfolioDeleteModeAtom,
  dragAutoUploadAtom,
  uploadDialogOpenAtom,
  currentMenuAtom,
} from 'atoms'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil'
import {
  CenterAlignBox,
  CenterAlignStack,
  TempLayoutDiv,
  FlexBasis,
  triggerGA4DownloadEvent,
} from 'components'
import { getPieceType, getS3ImageSrc, isKo, padZeros } from 'utils/common'
import { useEffect, useState } from 'react'
import {
  CopyIcon,
  DownloadIcon,
  DropdownArrowIcon,
  SelectedIcon,
  ThumbsDownIcon,
  TrashCanIcon,
} from 'theme/icon'
import { motion } from 'framer-motion'
import { useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import _, { replace } from 'lodash'
import { apis } from 'apis'
import * as config from 'config'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import axios from 'axios'
import { checkedURLforNoCache, processImages } from './ArtworkList'
import { enqueueSnackbar } from 'notistack'
import {
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  userPortfolioNamesSelector,
} from 'selector'
import useConfirm from 'hooks/useConfirm'
import { useTranslation } from 'react-i18next'
import { confirmDialogAtom } from '../../atoms'

export function PortfolioCard({
  userId,
  username,
  portfolioId,
  portfolioName,
  img,
  title,
  date,
  theme,
  themeBackground,
  thumbImages,
  setIsLoading,
  setPortfolio,
  data,
  idx,
}) {
  const viewMode = useRecoilValue(viewModeAtom)
  const currentUser = useRecoilValue(userAtom)
  const [deleteMode, setDeleteMode] = useRecoilState(portfolioDeleteModeAtom)
  const [user, setUser] = useRecoilState(userAtom)
  const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)
  const isMobile = useMobileMediaQuery()

  const creditPolicy = useRecoilValue(creditPolicyDictSelector)
  const isDesktop = useDesktopMediaQuery()

  const { t, i18n } = useTranslation()
  const { showConfirm } = useConfirm()
  const themeColor = useTheme()

  const [dragActive, setDragActive] = useState(false)
  const [dragAutoUpload, setDragAutoUpload] = useRecoilState(dragAutoUploadAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)
  const currentMenu = useRecoilValue(currentMenuAtom)

  const [confirmDialog, setConfirmDialog] = useRecoilState(confirmDialogAtom)
  const resetConfirmDialog = useResetRecoilState(confirmDialogAtom)

  const imageAreaHeight = isDesktop ? '20rem' : '12.6rem'

  const fillLogo = '/static/images/logo/logo_image.png'
  // const thumbImagesFilled = thumbImages.fill(fillLogo, thumbImages.length, 5)
  const thumbImagesFilled = Array(4).fill(fillLogo)
  thumbImages?.forEach((l, i) => {
    thumbImagesFilled[i] = getS3ImageSrc(l)
  })

  const navigate = useNavigate()

  const selectPortfolio = () => {
    navigate(`/portfolio/${portfolioId}?pages=1`)
  }

  useEffect(() => {}, [deleteMode])

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const downloadAllTest = async () => {
    const artworksData = await apis.portfolio.getAllPortfolioArtwork(portfolioId)
    const artworks = artworksData.data
    const paths = []

    artworks.forEach(artwork => {
      if (config.ARTWORK_DONE_STATUS !== artwork.status) return

      const target = artwork.pieces.map((piece, idx) => {
        return piece.path
      })

      target.forEach(path => {
        const url = getS3ImageSrc(checkedURLforNoCache(path))
        if (!url) return

        paths.push(getS3ImageSrc(path))
      })
    })

    axios
      .post('http://localhost:4000/download-images/', paths, {
        responseType: 'blob', // 응답 데이터 타입을 blob으로 설정
        headers: {
          'Content-Type': 'application/json', // 요청 본문의 타입을 JSON으로 명시
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/zip' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'images.zip') // 다운로드 파일 이름 설정
        document.body.appendChild(link)
        link.click()

        // 생성한 링크 삭제
        link.parentNode.removeChild(link)
        window.URL.revokeObjectURL(url) // URL 객체 해제
      })
  }

  const downloadAll = async e => {
    const x = 1

    const zip = new JSZip()
    const dirName = title
    const ax = axios.create()

    zip.folder(dirName)

    const promises = []
    const downloadInfos = []
    const fileCount = {}

    let hasErrorOccurred = false
    const abortController = new AbortController()

    const artworksData = await apis.portfolio.getAllPortfolioArtwork(portfolioId)
    const artworks = artworksData.data

    let pCount = 0
    artworks.forEach(a => {
      pCount += a.pieces.length
    })

    showConfirm({
      title: t('portfolio_card.download_all'),
      content: (
        <Typography>
          {t('portfolio_card.download_all_warning_2_a')} {pCount}
          {t('portfolio_card.download_all_warning_2_b')}{' '}
        </Typography>
      ),
      keepDialogOnConfirm: true,
      onConfirm: async () => {
        setConfirmDialog(c => {
          return { ...c, open: true, isLoading: true }
        })

        artworks.forEach(artwork => {
          if (hasErrorOccurred) return

          const paths = []
          if (config.ARTWORK_DONE_STATUS !== artwork.status) return

          const artworkName = artwork.name
            .replace('.' + _.last(artwork.name.split('.')), '')
            .replaceAll(' ', '_')

          const target = artwork.pieces.map((piece, idx) => {
            return piece.path
          })

          target.forEach(path => {
            fileCount[artworkName] = fileCount[artworkName] ? fileCount[artworkName] + 1 : 1
            const fileIdx = fileCount[artworkName]
            const url = getS3ImageSrc(checkedURLforNoCache(path))
            if (!url) return

            paths.push(path)
            promises.push(
              ax
                .get(url + `?w=${Date.now().toString()}`, { responseType: 'blob' })
                .then(response => {
                  return { response, artworkName, fileIdx }
                })
                .catch(err => {
                  if (!hasErrorOccurred) {
                    hasErrorOccurred = true
                    abortController.abort()
                    resetConfirmDialog()
                    alert(
                      isKo(i18n) ? '브라우저 메모리가 부족합니다.' : 'Memory out error occurred!'
                    )
                  }
                  return { error: err, artworkName, fileIdx }
                })
            )
          })

          const artworkConfig = JSON.parse(artwork.config)

          downloadInfos.push({ artwork_id: artwork.id, paths })
        })

        if (downloadInfos.length < 1) {
          enqueueSnackbar(t('portfolio_card.download_all_warning_1'), { variant: 'error' })
          return
        }

        if (artworks.length > 200 && user.grade !== 'enterpriseB') {
          enqueueSnackbar(t('portfolio_card.download_all_warning_3'), {
            variant: 'error',
          })
          return
        }

        // 크레딧 계산 시 사용
        let artworkCount = 0

        const allPieces = []

        artworks.forEach(artwork => {
          allPieces.push(...artwork.pieces)
          if (artwork.download < 1 && artwork.pieces[0]?.path) {
            artworkCount += 1
          }

          // ----- GA4 event -----
          const artworkConfig = JSON.parse(artwork.config)
          triggerGA4DownloadEvent({
            artworkConfig,
            artwork,
            user,
            count: artwork.pieces.length,
            eventName: 'portfolio_download',
            method: 'port_thumb',
          })
          // ---------------------
        })

        if (user.use_credit_on.includes('artwork_download')) {
          const credit = await checkUserCredit()

          if (credit < creditPolicy.artwork_download * artworkCount) {
            showConfirm({
              alertOnly: true,
              content: <Typography>{t('common.credit_warning')}</Typography>,
            })
            return
          }
        }
        await Promise.all(promises).then(results => {
          if (hasErrorOccurred) return
          apis.portfolio.updateMultiArtworkDownload(portfolioId, downloadInfos).then(response => {
            // 싹다 업데이트 ?
          })

          processImages(results, dirName, null, allPieces)
        })
        resetConfirmDialog()
      },
      onCancel: () => {},
    })

    // -----------------------------------------------
    if (x < 2) {
      return
    }

    // TODO ㅇㅕ기 정리 ~~~ ^^;; 아래는 원본 코드임 ~~

    // -----------------------------------------------

    artworks.forEach(artwork => {
      const paths = []
      if (config.ARTWORK_DONE_STATUS !== artwork.status) return

      const artworkName = artwork.name
        .replace('.' + _.last(artwork.name.split('.')), '')
        .replaceAll(' ', '_')

      const target = artwork.pieces.map((piece, idx) => {
        return piece.path
      })

      target.forEach(path => {
        fileCount[artworkName] = fileCount[artworkName] ? fileCount[artworkName] + 1 : 1
        const fileIdx = fileCount[artworkName]
        const url = getS3ImageSrc(checkedURLforNoCache(path))
        if (!url) return

        paths.push(path)
        promises.push(
          ax.get(url + `?w=${Date.now().toString()}`, { responseType: 'blob' }).then(response => {
            return { response, artworkName, fileIdx }
          })
        )
      })

      const artworkConfig = JSON.parse(artwork.config)

      downloadInfos.push({ artwork_id: artwork.id, paths })
    })

    if (downloadInfos.length < 1) {
      enqueueSnackbar(t('portfolio_card.download_all_warning_1'), { variant: 'error' })
      return
    }

    showConfirm({
      title: t('portfolio_card.download_all'),
      content: (
        <Typography>
          {t('portfolio_card.download_all_warning_2_a')} {promises.length}
          {t('portfolio_card.download_all_warning_2_b')}{' '}
        </Typography>
      ),
      onConfirm: async () => {
        if (artworks.length > 200 && user.grade !== 'enterpriseB') {
          enqueueSnackbar(t('portfolio_card.download_all_warning_3'), {
            variant: 'error',
          })
          return
        }

        // 크레딧 계산 시 사용
        let artworkCount = 0

        const allPieces = []

        artworks.forEach(artwork => {
          allPieces.push(...artwork.pieces)
          if (artwork.download < 1 && artwork.pieces[0]?.path) {
            artworkCount += 1
          }

          // ----- GA4 event -----
          const artworkConfig = JSON.parse(artwork.config)

          triggerGA4DownloadEvent({
            artworkConfig,
            artwork,
            user,
            count: artwork.pieces.length,
            eventName: 'portfolio_download',
            method: 'port_thumb',
          })
          // ---------------------
        })

        if (user.use_credit_on.includes('artwork_download')) {
          const credit = await checkUserCredit()

          if (credit < creditPolicy.artwork_download * artworkCount) {
            showConfirm({
              alertOnly: true,
              content: <Typography>{t('common.credit_warning')}</Typography>,
            })
            return
          }
        }
        Promise.allSettled(promises).then(results => {
          apis.portfolio.updateMultiArtworkDownload(portfolioId, downloadInfos).then(response => {
            // 싹다 업데이트 ?
          })

          processImages(results, dirName, null, allPieces)
        })
      },
      onCancel: () => {},
    })
  }

  const refreshPortfolio = () => {
    apis.portfolio
      .getAllportfolio()
      .then(response => {
        setIsLoading(true)
        setPortfolio(response.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteAll = e => {
    showConfirm({
      title: t('portfolio_card.delete_all'),
      content: <Typography>{t('portfolio_card.delete_all_warning')}</Typography>,
      onConfirm: () => {
        // ----- GA4 event -----
        window.gtag('event', 'portfolio_delete', { method: 'port_thumb', count: 1 })
        // ---------------------
        apis.portfolio.deletePortfolio(portfolioId).then(() => {
          refreshPortfolio()
        })
      },
    })
  }

  const copyPortofolio = () => {
    showConfirm({
      title: t('portfolio_card.copy_config'),
      content: <Typography>{t('portfolio_card.copy_config_warning')}</Typography>,
      onConfirm: () => {
        // ----- GA4 event -----
        window.gtag('event', 'portfolio_copy', {})
        // ---------------------

        // name 처리

        const pattern = new RegExp(`${data.name} copy_(\\d+)`)
        const numbers = userPortfolioNames
          .filter(name => pattern.test(name))
          .map(name => parseInt(name.match(pattern)[1]))

        const maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0

        const nameCopy = `${data.name} copy_${maxNumber + 1}`
        const configCopy = { ...data.config, name: nameCopy }
        const themeCopy = data.theme

        const body = {
          name: nameCopy,
          theme: themeCopy,
          config: configCopy,
        }

        const bg = []

        if (configCopy.bgWhite) bg.push('white')
        if (configCopy.bgSimple) bg.push('simple')
        if (configCopy.bgComplex) bg.push('complex')
        if (configCopy.bgGenerate) bg.push('generate')

        apis.portfolio
          .createPortfoilo(body)
          .then(response => {
            const bg = []
            if (configCopy.bgWhite) bg.push('white')
            if (configCopy.bgSimple) bg.push('simple')
            if (configCopy.bgComplex) bg.push('complex')
            if (configCopy.bgGenerate) bg.push('generate')
            // 설정복사로 생성된 경우 ga4 포폴생성이벤트는 발생시키지 않음

            if (!response.data.success) {
              showConfirm({
                alertOnly: true,
                content: <Typography>{response.data.message}</Typography>,
                // TODO 안내 문구 다국어 지원하려면 ?
              })
            }

            refreshPortfolio()
          })
          .catch(error => {
            console.log(error)
          })
      },
    })
  }

  const handleCheck = e => {
    e.stopPropagation()

    if (userId !== currentUser.id) {
      console.log('다른 유저의 포트폴리오')
      return
    }
    if (!data.is_default) {
      if (deleteMode.ids.includes(portfolioId)) {
        setDeleteMode(d => ({ ...d, ids: d.ids.filter(e => e !== portfolioId) }))
      } else {
        setDeleteMode(d => ({ ...d, ids: [...d.ids, portfolioId] }))
      }
    }
  }

  const timeFormatTransfer = date => {
    return padZeros(date.getHours()) + ':' + padZeros(date.getMinutes()) + ' KST'
  }

  const handleDrag = e => {
    e.preventDefault()
    e.stopPropagation()

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    navigate(`/portfolio/${portfolioId}?pages=1`)
    setUploadOpen(true)
    if (e.dataTransfer.files) {
      const uploadFiles = Array.prototype.slice.call(e.dataTransfer.files)
      setDragAutoUpload({ auto: true, file: uploadFiles })
    }

    // ----- GA4 event -----
    window.gtag('event', 'drag_and_drop', {
      menu: currentMenu?.id,
    })
    // ---------------------
  }

  useEffect(() => {
    // console.log(portfolioId)
  })

  switch (viewMode.type) {
    case 'thumbnail':
      return (
        <Card
          sx={
            isDesktop
              ? {
                  width: '24rem',
                  minWidth: '24rem',
                  height: '30.6rem',
                  scale: dragActive ? '1.02' : ' 1',
                  '&.MuiPaper-root.MuiCard-root': {
                    borderRadius: '1rem',
                    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.07)',
                  },
                  backgroundColor: dragActive
                    ? themeColor.palette.draph.lighterblue
                    : themeColor.palette.common.white,
                  transition: 'all 0.3s',
                }
              : {
                  width: '15.2rem',
                  minWidth: '15.2rem',
                  height: '22.1rem',

                  '&.MuiPaper-root.MuiCard-root': {
                    borderRadius: '1rem',
                    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.07)',
                  },
                }
          }
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={selectPortfolio}>
            <Box
              sx={{
                px: { lg: '1.8rem', md: '1.5rem', sm: '1.5rem', xs: '1.3rem' },
                py: { lg: '1.6rem', md: '1.2rem', sm: '1.2rem', xs: '1.2rem' },
                minHeight: '6.4rem',
              }}
            >
              <Typography
                fontWeight={800}
                fontSize={{ lg: '1.3rem', xs: '1.3rem' }}
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  color: data.is_default ? 'gray' : '',
                }}
              >
                {currentUser.is_admin && (
                  <>
                    <span style={{ color: 'orange' }}>[포폴 ID] {portfolioId}</span> (
                    {data.is_default ? (
                      data.type ? (
                        <span>{config.DEFAULT_PORTFOLIO_NAME_DICT[data.type]}</span>
                      ) : (
                        <span>이미지생성</span>
                      )
                    ) : (
                      <span>일반포트폴리오</span>
                    )}
                    )
                    <br />
                    [유저] {userId} ({username})
                    <br />
                  </>
                )}

                {title.trim().length ? title : <span style={{ color: 'transparent' }}></span>}
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={{ lg: '1rem', xs: '1.2rem' }}
                lineHeight="normal"
                color="text.secondary"
              >
                {date.slice(0, 10).replace(/-/g, '.')}
              </Typography>
            </Box>
            {/* <CardMedia component="img" alt="cute animal" height="65%" image={img} /> */}
            <Box
              sx={{
                width: '100%',
                height: imageAreaHeight,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {deleteMode.on && (
                  <motion.div
                    onClick={handleCheck}
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      background: `linear-gradient(rgba(48, 48, 48, 0.3) ${imageAreaHeight}, transparent 0)`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      transition: 'background 1s',
                    }}
                    animate={{
                      background:
                        idx === 0
                          ? ''
                          : [
                              `linear-gradient(rgba(48, 48, 48, 0.2) ${imageAreaHeight}, transparent 0)`,
                              `linear-gradient(rgba(48, 48, 48, 0.4) ${imageAreaHeight}, transparent 0)`,
                              `linear-gradient(rgba(48, 48, 48, 0.2) ${imageAreaHeight}, transparent 0)`,
                            ],
                    }}
                    transition={{
                      repeat: Infinity,
                      repeatDelay: 0,

                      ease: 'easeOut',
                      duration: 1.5,
                    }}
                  >
                    <CenterAlignBox
                      width="100%"
                      height={imageAreaHeight}
                      sx={{ position: 'absolute', top: 0, alignItems: 'center' }}
                    >
                      {deleteMode.ids.includes(portfolioId) && (
                        <SelectedIcon
                          sx={{
                            width: { lg: '11rem', xs: '8rem' },
                            height: { lg: '11rem', xs: '8rem' },
                          }}
                        />
                      )}
                    </CenterAlignBox>
                  </motion.div>
                )}

                {thumbImages.length > 0 ? (
                  thumbImagesFilled.map((l, i) => (
                    <Box
                      key={i}
                      sx={{
                        width: '50%',
                        height: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {l === fillLogo ? (
                        <img
                          src={l}
                          style={{
                            height: '50%',
                            objectFit: 'contain',
                            opacity: 0.3,
                            zIndex: -1,
                          }}
                        />
                      ) : (
                        <img
                          src={l}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src="/static/images/logo/logo_image.png"
                      style={{
                        height: '50%',
                        objectFit: 'contain',
                        opacity: 0.3,
                        zIndex: -1,
                      }}
                    />
                  </Box>
                )}
              </div>
            </Box>
          </Box>
          <CenterAlignBox
            sx={{
              height: { lg: '4.25rem', xs: '3.7rem' },
              alignItems: 'center',
            }}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <DownloadButton clickHandler={downloadAll} deleteMode={deleteMode} />
            {!data.is_default && (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  variant="middle"
                  sx={{ mx: { lg: '0.8rem', xs: '0.5rem' }, my: { lg: '1.125rem', xs: '1.05rem' } }}
                />
                <CopyButton clickHandler={copyPortofolio} deleteMode={deleteMode} />
                <Divider
                  flexItem
                  orientation="vertical"
                  variant="middle"
                  sx={{ mx: { lg: '0.8rem', xs: '0.5rem' }, my: { lg: '1.125rem', xs: '1.05rem' } }}
                />
                <DeleteButton
                  clickHandler={deleteAll}
                  isOwner={user.id === userId}
                  deleteMode={deleteMode}
                />
              </>
            )}
          </CenterAlignBox>
        </Card>
      )
    case 'date':
      return (
        <Card
          sx={{
            width: { lg: '40rem', md: '32rem', sm: '32rem', xs: '32rem' },
            height: { lg: '20.8rem', md: '16rem', sm: '16rem', xs: '17rem' },
            display: 'flex',
            my: { lg: 3 },
            mt: { md: '1.6rem', sm: '1.6rem', xs: '1.6rem' },
            mx: 2,
            borderRadius: '10px',
          }}
        >
          <Box
            onClick={selectPortfolio}
            sx={{
              width: { lg: '40%', xs: '37.5%' },
              cursor: 'pointer',
            }}
          >
            {deleteMode.on && (
              <motion.div
                onClick={handleCheck}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  background: `linear-gradient(90deg, rgba(48, 48, 48, 0.3) ${
                    isMobile ? '37.5%' : '40%'
                  }, transparent 0)`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                animate={{
                  background: [
                    `linear-gradient(90deg, rgba(48, 48, 48, 0.2) ${
                      isMobile ? '37.5%' : '40%'
                    }, transparent 0)`,
                    `linear-gradient(90deg, rgba(48, 48, 48, 0.4) ${
                      isMobile ? '37.5%' : '40%'
                    }, transparent 0)`,
                    `linear-gradient(90deg, rgba(48, 48, 48, 0.2) ${
                      isMobile ? '37.5%' : '40%'
                    }, transparent 0)`,
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  repeatDelay: 0,

                  ease: 'easeOut',
                  duration: 1.5,
                }}
              >
                <CenterAlignBox
                  height="100%"
                  sx={{
                    position: 'absolute',
                    left: 0,
                    alignItems: 'center',
                    width: { lg: '40%', xs: '37.5%' },
                  }}
                >
                  {deleteMode.ids.includes(portfolioId) && (
                    <SelectedIcon
                      sx={{
                        width: { lg: '11rem', xs: '8rem' },
                        height: { lg: '11rem', xs: '8rem' },
                      }}
                    />
                  )}
                </CenterAlignBox>
              </motion.div>
            )}
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {thumbImages?.length > 0 ? (
                <img
                  src={getS3ImageSrc(thumbImages[0])}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <img
                  src="/static/images/logo/logo_v.png"
                  style={{
                    height: '55%',
                    objectFit: 'contain',
                    opacity: 0.5,
                    zIndex: -1,
                  }}
                />
              )}
            </Box>
          </Box>

          <CardContent
            sx={{
              width: { lg: '60%', xs: '62.5%' },
              height: '100%',
              p: 0,
            }}
          >
            <Stack
              sx={{
                width: '100%',
                px: { lg: '2.6rem', xs: '2rem' },
                pt: { lg: '2.6rem', xs: '1.6rem' },
                cursor: 'pointer',
                minHeight: '13.4rem',
              }}
              onClick={selectPortfolio}
            >
              <Typography
                fontSize={{ lg: '1.8rem', xs: '1.6rem' }}
                fontWeight={800}
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  lineHeight: 'normal',
                  color: data.is_default ? 'gray' : '',
                }}
              >
                {title.trim().length ? title : <span style={{ color: 'transparent' }}>null</span>}
              </Typography>
              <Typography fontSize={{ lg: '1.4rem', xs: '1.2rem' }} fontWeight={400}>
                {date.slice(11)}
              </Typography>
              <Box
                sx={{
                  mt: { lg: '1rem', xs: '0.7rem' },
                  display: 'flex',
                  alignItems: 'center',
                  height: '5.5rem',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    maxHeight: '5.5rem',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-all',
                    overflow: 'hidden',
                  }}
                >
                  {(theme?.split(',') ?? []).concat(themeBackground?.split(',') ?? []).map((t, i) =>
                    t.length > 0 ? (
                      <Typography
                        key={i}
                        component="span"
                        fontWeight={400}
                        sx={{
                          color: '#505050',
                          display: 'inline-block',
                          lineHeight: { lg: '1.6rem', xs: '1.3rem' },
                          fontSize: { lg: '1.4rem', xs: '1.3rem' },
                        }}
                      >
                        #{t.replace(' ', '')}&nbsp;
                      </Typography>
                    ) : null
                  )}
                </Box>
              </Box>
            </Stack>
            <Divider
              flexItem
              sx={{ margin: { lg: '1.5rem 2.6rem 0 2.6rem', xs: '1.5rem 2.6rem 0 2.6rem' } }}
            />
            <CenterAlignBox
              sx={{
                height: { lg: '4.45rem', xs: '3.7rem' },
                margin: { lg: '0.3rem' },
                alignItems: 'center',
              }}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <DownloadButton clickHandler={downloadAll} />
              {!data.is_default && (
                <>
                  <Divider
                    flexItem
                    orientation="vertical"
                    variant="middle"
                    sx={{
                      mx: { lg: '0.8rem', xs: '0.5rem' },
                      my: { lg: '1.125rem', xs: '1.05rem' },
                    }}
                  />
                  <CopyButton clickHandler={copyPortofolio} />
                  <Divider
                    flexItem
                    orientation="vertical"
                    variant="middle"
                    sx={{
                      mx: { lg: '0.8rem', xs: '0.5rem' },
                      my: { lg: '1.125rem', xs: '1.05rem' },
                    }}
                  />
                  <DeleteButton clickHandler={deleteAll} />
                </>
              )}
            </CenterAlignBox>
          </CardContent>
        </Card>
      )

    default:
      break
  }
}

export const DownloadButton = ({ clickHandler, color, isTable = false, deleteMode }) => {
  const themeColor = useTheme()
  const isMobile = useMobileMediaQuery()

  const tableStyle = { display: 'flex', flexDirection: 'column', gap: '0.9rem' }
  return (
    <Button
      // disabled={deleteMode?.on}
      sx={{
        '&.Mui-disabled': {
          '& svg': {
            stroke: '#DFE3E8',
          },
        },
        width: isTable ? 'max-content' : 'auto',
        height: 'auto',
        minWidth: 'auto',
        borderRadius: '50%',
        fontSize: '1.1rem',
        fontWeight: 400,
        color: '#303030',

        padding: { lg: '6px 8px', xs: '3px 3px' },
        '& svg': {
          transition: 'all 0.3s',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: themeColor.palette.draph.blue,

          '& svg': {
            stroke: themeColor.palette.draph.blue, // 마우스 오버 시 변경할 색상
          },
        },
        ' & .MuiButton-startIcon': {
          margin: 0,
        },
        ...(isTable && tableStyle),
      }}
      onClick={clickHandler}
      startIcon={
        <DownloadIcon color={color ?? '#989898'} style={isMobile ? { height: '1.6rem' } : {}} />
      }
    >
      {isTable && '다운로드'}
    </Button>
  )
}

export const CopyButton = ({ clickHandler, color, isTable = false, deleteMode }) => {
  const themeColor = useTheme()
  const isMobile = useMobileMediaQuery()
  const tableStyle = { display: 'flex', flexDirection: 'column', gap: '0.9rem' }

  return (
    <Button
      // disabled={deleteMode?.on}
      sx={{
        '&.Mui-disabled': {
          '& svg': {
            stroke: '#DFE3E8',
          },
        },
        width: isTable ? 'max-content' : 'auto',
        fontSize: '1.1rem',
        fontWeight: 400,
        color: '#303030',
        height: 'auto',
        minWidth: 'auto',
        borderRadius: '50%',
        padding: { lg: '6px 8px', xs: '3px 3px' },
        '& svg': {
          transition: 'all 0.3s',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: themeColor.palette.draph.blue,
          '& svg': {
            stroke: themeColor.palette.draph.blue, // 마우스 오버 시 변경할 색상
          },
        },
        ' & .MuiButton-startIcon': {
          margin: 0,
        },
        ...(isTable && tableStyle),
      }}
      onClick={clickHandler}
      startIcon={<CopyIcon color={color} style={isMobile ? { height: '1.6rem' } : {}} />}
    >
      {isTable && '설정 복사'}
    </Button>
  )
}

export const DeleteButton = ({ clickHandler, color, isTable = false, isOwner, deleteMode }) => {
  const themeColor = useTheme()
  const isMobile = useMobileMediaQuery()
  const tableStyle = { display: 'flex', flexDirection: 'column', gap: '0.9rem' }

  return (
    <Button
      sx={{
        '&.Mui-disabled': {
          '& svg': {
            stroke: '#DFE3E8',
          },
          color: '#DFE3E8',
        },
        width: isTable ? 'max-content' : 'auto',
        fontSize: '1.1rem',
        fontWeight: 400,
        color: '#303030',
        height: 'auto',
        minWidth: 'auto',
        borderRadius: '50%',
        padding: { lg: '6px 8px', xs: '3px 3px' },
        // color: themeColor.palette.error.main,
        '& svg': {
          transition: 'all 0.3s',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: themeColor.palette.error.main,
          '& svg': {
            stroke: themeColor.palette.error.main, // 마우스 오버 시 변경할 색상
          },
        },
        '& .MuiButton-startIcon': {
          margin: 0,
        },
        ...(isTable && tableStyle),
      }}
      disabled={!isOwner || deleteMode?.on}
      onClick={clickHandler}
      startIcon={
        <TrashCanIcon color={color} style={isMobile ? { height: '1.7rem' } : { height: '2rem' }} />
      }
    >
      {isTable && '삭제'}
    </Button>
  )
}
