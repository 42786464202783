// 네이버 커머스 관련 api

import axios from 'axios'
import crudAxios from './configs/crud'

const path = '/cafe24_commerce'

export const cafe24CommerceAPI = {
  checkUser: body => {
    return crudAxios.post(`${path}/check_user`, body)
  },
  login: body => {
    return crudAxios.post(`${path}/login_user`, body)
  },
  createUser: body => {
    return crudAxios.post(`${path}/user`, body)
  },
  linkUser: body => {
    // 기존에 존재하던 드랩아트 회원 계정을 연동하려는 경우
    return crudAxios.post(`${path}/link_user`, body)
  },
  getProductList: ({ search = '', page = 1, pageSize = 10, withCancelToken = false }) => {
    const config = {}
    let cToken

    if (withCancelToken) {
      cToken = axios.CancelToken.source()
      config.cancelToken = cToken.token
    }

    return [
      crudAxios.get(
        `${path}/product_list?&search=${search}&page=${page}&page_size=${pageSize}`,
        config
      ),
      cToken,
    ]
  },
  getProductDetail: productNo => {
    return crudAxios.get(`${path}/product/${productNo}`)
  },
  getImage: body => {
    return crudAxios.post(`${path}/get_image`, body)
  },
  updateProductImage: body => {
    return crudAxios.post(`${path}/product/image`, body)
  },
  authRequest: body => {
    // 최초 진입시 /cafe24_redirect 에 쿼리 파라미터로 전달된 hmac 검증
    return crudAxios.post(`${path}/auth`, body)
  },
}
