import { styled } from '@mui/material/styles'
import { Pagination, PaginationItem } from '@mui/material'

import { useMobileMediaQuery } from 'hooks/useMediaQuery'

const PrevPageIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7071 6.29289C14.3466 5.93241 13.7794 5.90468 13.3871 6.2097L13.2929 6.29289L8.29289 11.2929C7.93241 11.6534 7.90468 12.2206 8.2097 12.6129L8.29289 12.7071L13.2929 17.7071C13.6834 18.0976 14.3166 18.0976 14.7071 17.7071C15.0676 17.3466 15.0953 16.7794 14.7903 16.3871L14.7071 16.2929L10.415 12L14.7071 7.70711C15.0676 7.34662 15.0953 6.77939 14.7903 6.3871L14.7071 6.29289Z"
        fill="#808080"
      />
    </svg>
  )
}

const NextPageIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.29289 6.29289C9.65338 5.93241 10.2206 5.90468 10.6129 6.2097L10.7071 6.29289L15.7071 11.2929C16.0676 11.6534 16.0953 12.2206 15.7903 12.6129L15.7071 12.7071L10.7071 17.7071C10.3166 18.0976 9.68342 18.0976 9.29289 17.7071C8.93241 17.3466 8.90468 16.7794 9.2097 16.3871L9.29289 16.2929L13.585 12L9.29289 7.70711C8.93241 7.34662 8.90468 6.77939 9.2097 6.3871L9.29289 6.29289Z"
        fill="#808080"
      />
    </svg>
  )
}

const FirstPageIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2071 6.29289C10.8466 5.93241 10.2794 5.90468 9.8871 6.2097L9.79289 6.29289L4.79289 11.2929C4.43241 11.6534 4.40468 12.2206 4.7097 12.6129L4.79289 12.7071L9.79289 17.7071C10.1834 18.0976 10.8166 18.0976 11.2071 17.7071C11.5676 17.3466 11.5953 16.7794 11.2903 16.3871L11.2071 16.2929L6.915 12L11.2071 7.70711C11.5676 7.34662 11.5953 6.77939 11.2903 6.3871L11.2071 6.29289Z"
        fill="#808080"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2071 6.29289C17.8466 5.93241 17.2794 5.90468 16.8871 6.2097L16.7929 6.29289L11.7929 11.2929C11.4324 11.6534 11.4047 12.2206 11.7097 12.6129L11.7929 12.7071L16.7929 17.7071C17.1834 18.0976 17.8166 18.0976 18.2071 17.7071C18.5676 17.3466 18.5953 16.7794 18.2903 16.3871L18.2071 16.2929L13.915 12L18.2071 7.70711C18.5676 7.34662 18.5953 6.77939 18.2903 6.3871L18.2071 6.29289Z"
        fill="#808080"
      />
    </svg>
  )
}

const LastPageIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.79289 6.29289C6.15338 5.93241 6.72061 5.90468 7.1129 6.2097L7.20711 6.29289L12.2071 11.2929C12.5676 11.6534 12.5953 12.2206 12.2903 12.6129L12.2071 12.7071L7.20711 17.7071C6.81658 18.0976 6.18342 18.0976 5.79289 17.7071C5.43241 17.3466 5.40468 16.7794 5.7097 16.3871L5.79289 16.2929L10.085 12L5.79289 7.70711C5.43241 7.34662 5.40468 6.77939 5.7097 6.3871L5.79289 6.29289Z"
        fill="#808080"
      />
      <path
        d="M12.7929 6.29289C13.1534 5.93241 13.7206 5.90468 14.1129 6.2097L14.2071 6.29289L19.2071 11.2929C19.5676 11.6534 19.5953 12.2206 19.2903 12.6129L19.2071 12.7071L14.2071 17.7071C13.8166 18.0976 13.1834 18.0976 12.7929 17.7071C12.4324 17.3466 12.4047 16.7794 12.7097 16.3871L12.7929 16.2929L17.085 12L12.7929 7.70711C12.4324 7.34662 12.4047 6.77939 12.7097 6.3871L12.7929 6.29289Z"
        fill="#808080"
      />
    </svg>
  )
}

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    fontSize: '1.6rem',
    fontWeight: 400,
    color: '#808080',
    borderRadius: '0.5rem',
    '&:hover': {},
  },

  '& .MuiPaginationItem-root.Mui-selected': {
    background: theme.palette.draph.lighterblue,

    color: '#2C4DFF',
    // '&:after': {
    //   position: 'absolute',
    //   content: '""',
    //   display: 'block',
    //   width: '9px',
    //   bottom: '4px',
    //   left: '12px',
    //   borderBottom: '2px solid',
    //   borderColor: theme.palette.common.black,
    // },
  },

  [theme.breakpoints.down('lg')]: {
    '& .MuiPaginationItem-root': {
      fontSize: '1.3rem',
    },
  },
}))

const smallSizeStyle = {
  '& .MuiPaginationItem-root': {
    fontSize: '1.2rem',
    minWidth: '2.2rem',
  },
  '& .MuiPaginationItem-root.Mui-selected:after': {
    width: '9px',
    bottom: '4px',
    left: '7px',
    borderBottom: '1px solid',
  },
}

export default function CustomPagination({ sx, ...props }) {
  const isMobile = useMobileMediaQuery()

  return (
    <StyledPagination
      showFirstButton
      showLastButton
      siblingCount={isMobile ? 0 : 2}
      boundaryCount={1}
      renderItem={item => (
        <PaginationItem
          slots={{
            previous: PrevPageIcon,
            next: NextPageIcon,
            first: FirstPageIcon,
            last: LastPageIcon,
          }}
          {...item}
        />
      )}
      {...props}
      sx={{
        ...(props.size === 'small' && {
          ...smallSizeStyle,
        }),
        ...sx,
      }}
    ></StyledPagination>
  )
}
