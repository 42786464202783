import { apis } from 'apis'
import {
  brandConfigUpdateAtom,
  brandReadOnlyConfigAtom,
  hasProductAtom,
  isnoSOHumanDepictAtom,
  loadingAtom,
  loadingSubAtom,
  unusualPortfolioConfigUpdateAtom,
} from 'atoms'
import { useRecoilState } from 'recoil'
import { base64ToBlob, getS3ImageSrc, imageUrlToFile } from 'utils/common'
import useConfirm from './useConfirm'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef } from 'react'
import { useSnackbar } from 'notistack'

export default function useBanner() {
  const [bannerConfig, setBannerConfig] = useRecoilState(unusualPortfolioConfigUpdateAtom)
  const [brandConfig, setBrandConfig] = useRecoilState(brandConfigUpdateAtom)
  const [brandReadOnlyConfig, setBrandReadOnlyConfig] = useRecoilState(brandReadOnlyConfigAtom)
  const [uploadingSub, setUploadingSub] = useRecoilState(loadingSubAtom)

  const [hasProduct, setHasProduct] = useRecoilState(hasProductAtom)
  const [isnoSOHumanDepict, setIsnoSoHumanDepict] = useRecoilState(isnoSOHumanDepictAtom)
  const { enqueueSnackbar } = useSnackbar()

  const { showConfirm } = useConfirm()
  const { t, i18n } = useTranslation()

  const disabledButton =
    !bannerConfig.productName ||
    !bannerConfig.productFeature ||
    !bannerConfig.productCategory ||
    !bannerConfig.outputW ||
    !bannerConfig.outputH ||
    (bannerConfig.genType === 'side' && !bannerConfig.humanDepict) ||
    (bannerConfig.genType === 'no_SO' && isnoSOHumanDepict && !bannerConfig.humanDepict)

  const logoSaveApi = async () => {
    setUploadingSub(true)
    let logoSod

    if (brandConfig.logoImage[0]) {
      try {
        logoSod = await logoSodImage(brandConfig.logoImage[0])
      } catch (error) {
        // showConfirm({
        //   alertOnly: true,
        //   content: <Typography>{t('upload_dialog.warning')}</Typography>,
        // })
        logoSod = error

        setUploadingSub(false)
      }
    }

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('image', brandConfig.logoImage[0] ? logoSod : '')
      formData.append('name', brandConfig.brandName)
      formData.append('concept', brandConfig.brandFeature)

      apis.user
        .postUserLogoImage(formData)
        .then(async r => {
          const d = r.data

          if (d) {
            const imageFile = d.s3_url ? await imageUrlToFile(getS3ImageSrc(d.s3_url)) : null

            setBrandReadOnlyConfig({
              ...brandReadOnlyConfig,
              brand_concept: brandConfig.brandFeature,
              brand_name: brandConfig.brandName,
              s3_url: d.s3_url,
              logoImage: imageFile,
            })
            setBrandConfig(p => ({
              ...p,
              logoImage: imageFile ? [imageFile] : [],
              // brandName: d.brand_name,
              // brandFeature: d.brand_concept,
              logoS3path: d.s3_url,
            }))
            setUploadingSub(false)
            enqueueSnackbar('적용되었습니다.', { variant: 'success' })
            resolve({ logoS3path: d.s3_url, logo_image: [imageFile] })
          }
        })
        .catch(() => {
          setUploadingSub(false)
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('upload_dialog.warning')}</Typography>,
          })
        })
        .finally(() => {
          setUploadingSub(false)
        })

      // apis.user.getUserLogoImage().then(async r => {
      //   const d = r.data

      //   if (d) {
      //     const imageFile = d.s3_url ? await imageUrlToFile(getS3ImageSrc(d.s3_url)) : null

      //     setBrandReadOnlyConfig({ ...d, logoImage: imageFile })
      //     setBrandConfig({
      //       logoImage: imageFile ? [imageFile] : [],
      //       brandName: d.brand_name,
      //       brandFeature: d.brand_concept,
      //       logoS3path: d.s3_url,
      //     })
      //   }
      // })
      // setUploadingSub(false)
    })
  }

  return { disabledButton, logoSaveApi }
}

const logoSodImage = image => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('logo_image', image)

    apis.appfront
      .getLogoSod(formData)
      .then(d => {
        const base64Image = d.data.sod_result
        const blobImage = base64ToBlob(base64Image, 'image/png')

        resolve(blobImage)
      })
      .catch(() => {
        reject(image)
      })
  })
}
