import { Button } from '@mui/material'
import { apis } from 'apis'
import axios from 'axios'
import React, { useState, useRef, useEffect } from 'react'
import Moveable from 'react-moveable'
import { Link } from 'react-router-dom'
import { setAccessTokenNaver } from 'utils/cookie'

export function NaverCommerceRedirect() {
  const linkRef = useRef()

  useEffect(() => {
    linkRef.current.click()
  }, [])

  return (
    <Link
      ref={linkRef}
      to="https://sell.smartstore.naver.com/#/member/auth?interlockType=solution_auth&returnUrl=https:%2F%2Fsolution.smartstore.naver.com%2Fko%2Foauth%2Fredirect%3FsolutionId%3D58Nq2wGFKw3xJbhdHXTiST"
    ></Link>
  )
}
