import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  OutlinedInput,
  Slide,
  Stack,
  Typography,
  styled,
  useTheme,
  Box,
  Divider,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
} from '@mui/material'
import { apis } from 'apis'
import { userAtom, userCreditAtom } from 'atoms'
import {
  CenterAlignBox,
  CenterAlignStack,
  CheckPasswordDialog,
  DialogButton,
  LeaveNoticeDialog,
  MobileSwipeableDrawer,
  ShowUserState,
  SpanDraphBlue,
} from 'components'
import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { MYPAGE_PERCENTAGE_WIDHT, MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import { getCreditAmount, getNextPaidDay } from 'pricing'
import { forwardRef, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { motion } from 'framer-motion'
import moment from 'moment'
import { CircleWarningIcon } from 'theme/icon'
import { creditPlansAtom, userSubInfoAtom } from '../atoms'
import { MypageSkeleton } from 'components/Skeleton'
import { CircleLoader, PuffLoader, RingLoader, RotateLoader } from 'react-spinners'
import { usePricing } from 'hooks/usePricing'
import { useTranslation } from 'react-i18next'
import { isKo, nullZero, trasnferDate } from 'utils/common'
import { t } from 'i18next'
import i18n from 'i18n'
import { English, Korean } from 'hooks/useLanguage'
import { SUB_EVENT_ID, generatePaymentUid } from './Pricing'
import { utcToLocal } from '../utils/common'
import { LeaveDialog, SelectReasonDialog } from 'components/ShowUserState'

const titleStyle = { fontWeight: 800, fontSize: '3rem' }
const subStyle = { fontWeight: 700, fontSize: '2.6rem' }

const TextButton = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.common.black,
  textDecorationLine: 'underline',
  fontSize: '1.8rem',
  fontWeight: 500,

  '&:hover': {
    backgroundColor: 'transparent',
  },

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.4rem',
  },
}))

const MainButton = styled(Button)(({ theme }) => ({
  width: '28rem',
  fontSize: '2.4rem',
  fontWeight: 800,
  borderRadius: '50px',

  [theme.breakpoints.down('lg')]: {
    width: '18rem',
    height: '4rem',

    fontSize: '1.8rem',
  },
}))

export const CardTitleTypography = styled(Typography)(({ theme }) => ({
  width: '100%',
  height: '7.6rem',
  backgroundColor: theme.palette.draph.blue,
  fontSize: '2.6rem',
  fontWeight: 700,
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('lg')]: {
    height: '4.8rem',
    fontSize: '2rem',
    fontWeight: 700,
  },
}))

const CardContent = styled(CenterAlignStack)(({ theme }) => ({
  margin: '3.7rem 0 3.4rem 0',

  [theme.breakpoints.down('lg')]: {
    margin: '2.8rem 0 2rem 0',
  },
}))

const CancelDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root.MuiDialog-paper': {
    width: '400px',
    margin: '20px',
    borderRadius: '20px',
    // minHeight: surveyCheckbox.etc.state ? '60rem' : '52rem',
    // transition: 'all .3s ease-in-out',
  },

  [theme.breakpoints.down('lg')]: {
    margin: 0,
    '& .MuiPaper-root.MuiDialog-paper': {
      width: '320px',
      // minHeight: surveyCheckbox.etc.state ? '60rem' : '52rem',
      // transition: 'all .3s ease-in-out',
    },
  },
}))

const DialogContentTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'center',

  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
    lineHeight: '23px',
  },
}))

const DialogActionButton = styled(Button)(({ theme }) => ({
  width: '50%',
  height: '48px',
  fontSize: '16px',
  fontWeight: 600,

  [theme.breakpoints.down('lg')]: {
    height: '40px',
    fontSize: '14px',
  },
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

export const checkAppliedSubEvent = currentSubscription => {
  if (currentSubscription.last_paid && currentSubscription.start_date) {
    const a = moment(currentSubscription.last_paid)
    const b = moment(currentSubscription.start_date)

    const diff = moment.duration(a.diff(b)).asMonths()

    if (diff < 1 && currentSubscription.applied_event === SUB_EVENT_ID) {
      return true
    }
  }
  return false
}

const AppliedEventBadge = ({ sx }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box
      sx={{
        border: `2px solid ${theme.palette.draph.blue}`,
        borderRadius: '2rem',
        px: { lg: '1.5rem', xs: '1rem' },
        py: { lg: '0.4rem', xs: '0.3rem' },
        ...sx,
      }}
    >
      <Typography sx={{ fontSize: { lg: '2rem' }, fontWeight: 700 }}>
        {t('mypage.first_month_free_badge_a')}
        <span style={{ color: theme.palette.draph.blue }}>
          {t('mypage.first_month_free_badge_b')}
        </span>
        {t('mypage.first_month_free_badge_c')}
      </Typography>
    </Box>
  )
}

export default function Mypage() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const options = [
    { id: 1, label: t('select_reason_dialog.reasons_1') },
    { id: 2, label: t('select_reason_dialog.reasons_2') },
    { id: 3, label: t('select_reason_dialog.reasons_3') },
    { id: 4, label: t('select_reason_dialog.reasons_4') },
    { id: 5, label: t('select_reason_dialog.reasons_5') },
    { id: 6, label: t('select_reason_dialog.reasons_6') },
    { id: 7, label: t('select_reason_dialog.reasons_7') },
    { id: 999, label: t('select_reason_dialog.reasons_8') },
  ]

  const [currentSubscription, setCurrentSubscription] = useRecoilState(userSubInfoAtom)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [openCheckPasswardDialog, setOpenCheckPasswordDialog] = useState(false)
  const [openLeaveNoticeDialog, setOpenLeaveNoticeDialog] = useState(false)
  const [openSelectReasonDialog, setOpenSelectReasonDialog] = useState(false)
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false)

  const [selectedOption, setSelectedOption] = useState('')
  const [etcText, setEtcText] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [showAppliedEventBadge, setShowAppliedEventBadge] = useState(false)

  const [userCredit, setUserCredit] = useRecoilState(userCreditAtom)
  const creditPlans = useRecoilValue(creditPlansAtom)
  const user = useRecoilValue(userAtom)

  const refreshSubscription = () => {
    apis.user.getUserSubscription().then(response => {
      setIsLoading(false)
      setCurrentSubscription(response.data)
    })
  }

  useEffect(() => {
    apis.user.getCredit().then(response => {
      // console.log(response.data.credit)
      setUserCredit(response.data.credit ?? 0)
    })
  }, [])

  const theme = useTheme()

  useEffect(() => {
    setShowAppliedEventBadge(checkAppliedSubEvent(currentSubscription))
  }, [currentSubscription])

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ width: MYPAGE_PERCENTAGE_WIDHT, pl: MYPAGE_SIDE_MARGIN }}>
          <CenterAlignStack sx={{ width: '91.8rem', mb: '5.3rem' }}>
            <Typography sx={{ ...titleStyle, mb: '2.6rem' }}> {t('mypage_menu.mypage')}</Typography>
            <Stack direction="row" sx={{ width: '100%', alignItems: 'center', mb: '2.8rem' }}>
              <Typography sx={{ ...subStyle, textAlign: 'left' }}>
                {t('mypage.welcome_a')}
                <span style={{ color: theme.palette.draph.blue }}>{user.username}</span>
                {t('mypage.welcome_b')}
              </Typography>
              {showAppliedEventBadge && <AppliedEventBadge sx={{ ml: { lg: '2rem' } }} />}
            </Stack>
            <CenterAlignBox
              gap="3.2rem"
              // 배너 빠지면 여기로 !
              // sx={{ mb: currentSubscription?.plan_id ? '21.3rem' : 'unset' }}
              sx={{ mb: '7.9rem' }}
            >
              {isLoading ? (
                <MypageSkeleton />
              ) : (
                <>
                  <MypageSubscriptionCard
                    setCancelDialogOpen={setCancelDialogOpen}
                    currentSubscription={currentSubscription}
                    user={user}
                  />
                  <MypageCreditCard userCredit={userCredit} />
                </>
              )}
            </CenterAlignBox>
            <Korean>
              <img
                src={'/static/images/event/long_event_banner_pc.png'}
                style={{ width: '91.8rem', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/event/ko/normal/sns_review_event')
                }}
              />
            </Korean>
          </CenterAlignStack>
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack sx={{ width: '100%', mb: '8rem' }}>
          <CenterAlignStack sx={{ width: '32rem' }}>
            <Typography sx={{ ...titleStyle, fontSize: '2.4rem' }}>
              {' '}
              {t('mypage_menu.mypage')}
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '1.6rem',
                textAlign: 'center',
                width: '100%',
                mt: '1.8rem',
                mb: '0.7rem',
              }}
            >
              {t('mypage.welcome_a')}
              <span style={{ color: theme.palette.draph.blue }}>{user.username}</span>
              {t('mypage.welcome_b')}
            </Typography>
            {showAppliedEventBadge && (
              <AppliedEventBadge sx={{ ml: { lg: '2rem' }, mb: { xs: '1.5rem' } }} />
            )}
            <CenterAlignStack gap="4rem" sx={{ mt: '1.5rem' }}>
              <MypageSubscriptionCard
                setCancelDialogOpen={setCancelDialogOpen}
                currentSubscription={currentSubscription}
                user={user}
              />
              <MypageCreditCard userCredit={userCredit} />
            </CenterAlignStack>
            <Korean>
              <img
                src={'/static/images/event/short_event_banner_mo.png'}
                style={{ width: '32rem', cursor: 'pointer', margin: '4rem 0 0 0' }}
                onClick={() => {
                  navigate('/event/ko/normal/sns_review_event')
                }}
              />
            </Korean>
          </CenterAlignStack>
        </CenterAlignStack>
      </Mobile>

      {
        <ShowUserState
          refreshSubscription={refreshSubscription}
          currentSubscription={currentSubscription}
          open={cancelDialogOpen}
          setOpen={setCancelDialogOpen}
          openNextDialog={openCheckPasswardDialog}
          setOpenNextDialog={setOpenCheckPasswordDialog}
          setOpenNextDialogforSNS={setOpenLeaveNoticeDialog}
          userCredit={userCredit}
          type="unsubscribe"
        />
      }

      {openCheckPasswardDialog && (
        <CheckPasswordDialog
          open={openCheckPasswardDialog}
          setOpen={setOpenCheckPasswordDialog}
          openNextDialog={openLeaveNoticeDialog}
          setOpenNextDialog={setOpenLeaveNoticeDialog}
          type="unsubscribe" // GA4용.. 이 페이지(마이페이지)에서 비밀번호 입력하는 경우가 구독취소밖에 없어서 이렇게 처리! 변경사항 있으면 수정해야합니다
        />
      )}

      <LeaveNoticeDialog
        open={openLeaveNoticeDialog}
        setOpen={setOpenLeaveNoticeDialog}
        openNextDialog={openSelectReasonDialog}
        setOpenNextDialog={setOpenSelectReasonDialog}
        currentUser={user}
        currentSubscription={currentSubscription}
        userCredit={userCredit}
        type="unsubscribe"
      />

      {
        <SelectReasonDialog
          options={options}
          open={openSelectReasonDialog}
          setOpen={setOpenSelectReasonDialog}
          openNextDialog={openLeaveDialog}
          setOpenNextDialog={setOpenLeaveDialog}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          etcText={etcText}
          setEtcText={setEtcText}
          refreshSubscription={refreshSubscription}
          currentSubscription={currentSubscription}
          type="unsubscribe"
        />
      }

      {openLeaveDialog && (
        <LeaveDialog
          options={options}
          open={openLeaveDialog}
          setOpen={setOpenLeaveDialog}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          etcText={etcText}
          setEtcText={setEtcText}
          currentSubscription={currentSubscription}
          type="unsubscribe"
        />
      )}
    </>
  )
}

const MypageSubscriptionCard = ({ currentSubscription, setCancelDialogOpen }) => {
  const planId = currentSubscription?.plan_id
  const lastPaid = currentSubscription?.last_paid
  const creditPlans = useRecoilValue(creditPlansAtom)
  const user = useRecoilValue(userAtom)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const [buttonLoading, setButtonLoading] = useState(false)

  const { getPlanDisplayName, getPlanPrice, getCurrencySymbol } = usePricing()

  const [openChangePaymentMethodDialog, setOpenChangePaymentMethodDialog] = useState(false)

  const theme = useTheme()

  const setting = planId
    ? currentSubscription.end_date && !currentSubscription.next_subscription?.id
      ? 'cancel'
      : 'planId'
    : ''

  const cancelSubscriptionHandle = () => {
    // ----- GA4 event -----
    window.gtag('event', 'unsubscribe_begin', {})
    // ---------------------
    setCancelDialogOpen(true)
  }

  const cancelUpdateSubscription = () => {
    setButtonLoading(true)
    apis.user
      .cancelChangeSubscription({
        current_subscription_id: currentSubscription.id,
        next_subscription_id: currentSubscription.next_subscription.id,
      })
      .then(response => {
        if (response.data.success) {
          window.location.reload()
        }
      })
  }

  const reSubscribe = () => {
    apis.user.reSubscribe({ current_subscription_id: currentSubscription.id }).then(response => {
      if (response.data.success) {
        window.location.reload()
      }
    })
  }

  const handleChangePaymentMethod = () => {
    // ----- GA4 event -----
    window.gtag('event', 'payment_change_begin', {})
    // ---------------------

    const orderId = generatePaymentUid()

    const requestData = {
      PCD_PAY_TYPE: 'card',
      PCD_PAY_WORK: 'AUTH',
      PCD_CARD_VER: '01',

      PCD_PAY_OID: orderId,
      PCD_PAYER_NO: user.id,
      PCD_PAYER_NAME: user.username,
      PCD_PAYER_HP: user.phone,
      PCD_PAYER_EMAIL: user.email,
      PCD_USER_DEFINE1: orderId,
      PCD_USER_DEFINE2: '',

      callbackFunction: res => {
        if (res.PCD_PAY_RST === 'success') {
          // ----- GA4 event -----
          window.gtag('event', 'payment_change_complete', {})
          // ---------------------
          alert(res.PCD_PAY_MSG)
          window.location.reload()
        }
      },

      // ----
      payment_type: 'subscription_change_payment_method',
      order_id: orderId,
    }

    apis.payment
      .auth({ ...requestData })
      .then(response => {
        requestData.PCD_AUTH_KEY = response.data.PCD_AUTH_KEY
        requestData.PCD_PAY_URL = response.data.PCD_PAY_URL

        window.PaypleCpayAuthCheck(requestData)
      })
      .catch(() => {
        alert(t('payment.fail'))
      })
  }

  const selectCard = () => {
    // if (planId) {
    if (setting === 'planId') {
      return (
        <Card sx={{ width: { lg: '44.3rem', xs: '32rem' }, height: 'fit-content' }}>
          <CenterAlignStack>
            <CardTitleTypography>{t('mypage.my_subscription')}</CardTitleTypography>
            <CardContent>
              {currentSubscription.next_subscription?.id ? (
                <>
                  <Stack direction="row">
                    <CenterAlignStack>
                      <Typography fontSize="1.8rem" fontWeight={700}>
                        {t('mypage.subscription_status_1')}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: '3rem', xs: '2.2rem' },
                          lineHeight: { lg: '4.2rem', xs: '3rem' },
                          fontWeight: 800,
                        }}
                      >
                        {getPlanDisplayName(planId)?.includes('admin')
                          ? getPlanDisplayName(planId).replace('-admin', ' (관리자)')
                          : getPlanDisplayName(planId)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: '2.2rem', xs: '1.7rem' },
                          lineHeight: { lg: '3.2rem', xs: '2rem' },

                          fontWeight: 600,
                        }}
                      >
                        {getPlanPrice(creditPlans, planId)}
                        {t('mypage.monthly_price')}
                      </Typography>
                    </CenterAlignStack>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ height: '75px', mx: { lg: '20px', xs: '10px' }, mt: '10px' }}
                    />
                    <CenterAlignStack>
                      <Typography fontSize="1.8rem" fontWeight={700}>
                        예정 구독
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: '3rem', xs: '2.2rem' },
                          lineHeight: { lg: '4.2rem', xs: '3rem' },
                          fontWeight: 800,
                          color: theme.palette.draph.blue,
                        }}
                      >
                        {getPlanDisplayName(currentSubscription.next_subscription.plan_id)}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: '2.2rem', xs: '1.7rem' },
                          lineHeight: { lg: '3.2rem', xs: '2rem' },

                          fontWeight: 600,
                        }}
                      >
                        {getPlanPrice(creditPlans, currentSubscription.next_subscription.plan_id)}
                        {t('mypage.monthly_price')}
                      </Typography>
                    </CenterAlignStack>
                  </Stack>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: { lg: '3.4rem', xs: '2.6rem' },
                      lineHeight: { lg: '4.4rem', xs: '3.1rem' },
                      fontWeight: 800,
                      color: theme.palette.draph.blue,
                    }}
                  >
                    {getPlanDisplayName(planId)?.includes('admin')
                      ? getPlanDisplayName(planId).replace('-admin', ' (관리자)')
                      : getPlanDisplayName(planId)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { lg: '2.6rem', xs: '1.9rem' },
                      lineHeight: { lg: '3.7rem', xs: '2.3rem' },

                      fontWeight: 600,
                    }}
                  >
                    {getPlanPrice(creditPlans, planId)} {t('mypage.monthly_price')}
                  </Typography>
                </>
              )}

              {currentSubscription.next_subscription?.id ? (
                <Typography
                  sx={{
                    fontSize: { lg: '1.8rem', xs: '1.4rem' },
                    fontWeight: 400,
                    mt: { lg: '2.4rem', xs: '2.5rem' },
                    mb: { lg: '2.2rem', xs: '1.8rem' },
                    textAlign: 'center',
                  }}
                >
                  {t('mypage.chagne_plan_comment')}
                  <br />
                  <span style={{ fontWeight: 700 }}>{t('mypage.next_paid_day')}</span>
                  {trasnferDate(getNextPaidDay(lastPaid), 'detail', i18n)}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: { lg: '1.8rem', xs: '1.4rem' },
                    fontWeight: 400,
                    mt: { lg: '2.4rem', xs: '2.5rem' },
                    mb: { lg: '2.2rem', xs: '1.8rem' },
                    textAlign: 'center',
                  }}
                >
                  {t('mypage.credit_charge_comment_1')}
                  {getCreditAmount(creditPlans, planId)}
                  {t('mypage.credit_charge_comment_2')}
                  <br />
                  <span style={{ fontWeight: 700 }}>{t('mypage.next_paid_day')}</span>
                  {lastPaid ? trasnferDate(getNextPaidDay(lastPaid), 'detail', i18n) : '-'}
                </Typography>
              )}

              <Korean>
                {/* 우선 국내결제만 지원 */}
                <TextButton onClick={handleChangePaymentMethod}>
                  {t('mypage.change_payment_method')}
                </TextButton>
              </Korean>

              {currentSubscription.payment_status === 'failed' && (
                <>
                  <CenterAlignStack
                    direction="row"
                    sx={{ cursor: 'pointer', mt: '0.6rem' }}
                    onClick={() => {
                      setOpenChangePaymentMethodDialog(true)
                    }}
                  >
                    <CircleWarningIcon
                      sx={{
                        mr: isKo(i18n) ? '0.4rem' : '1.4rem',
                        width: { xs: '1.4rem' },
                        height: { xs: '1.4rem' },
                      }}
                    />
                    <Typography
                      sx={{
                        color: '#FF2323',
                        fontSize: { lg: '1.6rem', xs: '1.2rem' },
                        fontWeight: 600,
                      }}
                    >
                      <Korean>
                        {currentSubscription.payment_method_update_exp &&
                          utcToLocal(
                            currentSubscription.payment_method_update_exp,
                            'YYYY/MM/DD HH:mm'
                          ) + t('mypage.change_payment_method_notice_1')}
                        {t('mypage.change_payment_method_notice_2')}
                      </Korean>
                      <English>
                        {currentSubscription.payment_method_update_exp &&
                          'Please change your payment method'}
                        <br />
                        {currentSubscription.payment_method_update_exp &&
                          'by ' +
                            utcToLocal(
                              currentSubscription.payment_method_update_exp,
                              'YYYY/MM/DD HH:mm'
                            )}
                      </English>
                    </Typography>
                  </CenterAlignStack>
                </>
              )}

              {currentSubscription.next_subscription?.id ? (
                <MainButton
                  variant="contained"
                  size="large"
                  onClick={cancelUpdateSubscription}
                  disabled={buttonLoading}
                  sx={{ mt: { lg: '2.4rem', xs: '2rem' } }}
                >
                  {buttonLoading ? (
                    <Box sx={{ '& span': { width: '40px !important', height: '40px !important' } }}>
                      <PuffLoader />
                    </Box>
                  ) : (
                    t('button.cancel_plan')
                  )}
                </MainButton>
              ) : (
                <MainButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    navigate('/pricing')
                  }}
                  sx={{ mt: { lg: '2.4rem', xs: '2rem' } }}
                >
                  {t('button.change_plan')}
                </MainButton>
              )}

              <TextButton
                sx={{
                  fontSize: { lg: '1.5rem', xs: '1.2rem' },
                  fontWeight: 500,
                  color: theme.palette.common.gray,
                  mt: { lg: '2.8rem', xs: '2.2rem' },
                }}
                onClick={cancelSubscriptionHandle}
              >
                {t('button.cancel_subscription')}
              </TextButton>
            </CardContent>
          </CenterAlignStack>
        </Card>
      )
    } else if (setting === 'cancel') {
      return (
        <Card sx={{ width: { lg: '44.3rem', xs: '32rem' }, height: 'fit-content' }}>
          <CenterAlignStack>
            <CardTitleTypography
              sx={{
                backgroundColor: theme.palette.common.black,
              }}
            >
              {t('mypage.my_subscription')}
            </CardTitleTypography>
            <CardContent sx={{ margin: { xs: '2.8rem 0 2.5rem 0' } }}>
              <Typography
                sx={{
                  fontSize: { lg: '3.4rem', xs: '2.6rem' },
                  lineHeight: { lg: '4.4rem', xs: '3.1rem' },
                  fontWeight: 800,
                  color: theme.palette.draph.blue,
                }}
              >
                {getPlanDisplayName(planId)}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '2.6rem', xs: '1.9rem' },
                  lineHeight: { lg: '3.7rem', xs: '2.3rem' },
                  fontWeight: 600,
                }}
              >
                ({getPlanPrice(creditPlans, planId)} {t('mypage.monthly_price')})
              </Typography>

              <Typography
                sx={{
                  fontSize: { lg: '1.8rem', xs: '1.6rem' },
                  fontWeight: 400,
                  mt: '2.5rem',
                  mb: '1.6rem',
                  textAlign: 'center',
                }}
              >
                {' '}
                {t('mypage.retry_subscription_comment_0')}
                {trasnferDate(currentSubscription.end_date, 'detail', i18n)}
                {t('mypage.retry_subscription_comment_1')}
                <br /> {t('mypage.retry_subscription_comment_2')}
              </Typography>

              <Typography
                sx={{
                  fontSize: { lg: '2.2rem', xs: '1.7rem' },
                  mb: { lg: '3.9rem', xs: '2.4rem' },

                  fontWeight: 700,
                  color: theme.palette.draph.blue,
                }}
              >
                {t('mypage.retry_subscription_comment_3')}
              </Typography>

              <MainButton variant="contained" size="large" onClick={reSubscribe}>
                {t('button.retry_subscription')}
              </MainButton>
            </CardContent>
          </CenterAlignStack>
        </Card>
      )
    } else {
      return (
        <Card sx={{ width: { lg: '44.3rem', xs: '32rem' }, height: 'fit-content' }}>
          <CenterAlignStack>
            <CardTitleTypography
              sx={{
                backgroundColor: theme.palette.common.black,
              }}
            >
              {t('mypage.my_subscription')}
            </CardTitleTypography>
            <CardContent sx={{ margin: { lg: '3.7rem 0 3.8rem 0', xs: '2.1rem 0 2.5rem 0' } }}>
              <Typography
                sx={{
                  mt: { lg: '0.2rem' },
                  fontSize: { lg: '2rem', xs: '1.7rem' },
                  fontWeight: 400,
                  lineHeight: { lg: '2.4rem', xs: '2.3rem' },
                  mb: { lg: '3.9rem', xs: '2.1rem' },
                  textAlign: 'center',
                }}
              >
                {t('mypage.none_subscription_comment_1')} <br />
                {t('mypage.none_subscription_comment_2_a')}
                <span style={{ fontWeight: 700 }}>
                  {' '}
                  {t('mypage.none_subscription_comment_2_b')}
                </span>
                {t('mypage.none_subscription_comment_2_c')}
              </Typography>
              <MainButton
                variant="contained"
                size="large"
                onClick={() => {
                  navigate('/pricing')
                }}
              >
                {t('button.subscription')}
              </MainButton>
            </CardContent>
          </CenterAlignStack>
        </Card>
      )
    }
  }

  return (
    <>
      <Desktop>{selectCard()}</Desktop>
      <Mobile>{selectCard()}</Mobile>
      <SubscriptionPaymtneMethodDialog
        open={openChangePaymentMethodDialog}
        setOpen={setOpenChangePaymentMethodDialog}
        subInfo={currentSubscription}
        handleChangePaymentMethod={handleChangePaymentMethod}
      />
    </>
  )
}

const MypageCreditCard = ({ userCredit }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const selectCard = () => {
    if (userCredit !== 0) {
      return (
        <Card
          sx={{ width: { lg: '44.3rem', xs: '32rem' }, height: { lg: '30rem', xs: 'fit-content' } }}
        >
          <CenterAlignStack>
            <CardTitleTypography>{t('mypage.my_credit')}</CardTitleTypography>
            <CardContent sx={{ margin: { lg: '3.7rem 0 3.8rem 0', xs: '2.8rem 0 2.5rem 0' } }}>
              <Typography
                sx={{
                  fontSize: { lg: '4.4rem', xs: '2.6rem' },
                  lineHeight: { lg: '4.4rem', xs: '3.1rem' },
                  mb: { lg: '4.1rem', xs: '2.8rem' },
                  fontWeight: 800,
                  color: theme.palette.draph.blue,
                }}
              >
                {userCredit}
              </Typography>

              <MainButton
                variant="contained"
                size="large"
                onClick={() => {
                  navigate('/pricing')
                }}
              >
                {t('button.charge_credit')}
              </MainButton>
            </CardContent>
          </CenterAlignStack>
        </Card>
      )
    } else {
      return (
        <Card sx={{ width: { lg: '44.3rem', xs: '32rem' }, height: 'fit-content' }}>
          <CenterAlignStack>
            <CardTitleTypography
              sx={{
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
              }}
            >
              {t('mypage.my_credit')}
            </CardTitleTypography>
            <CardContent sx={{ margin: { lg: '3.7rem 0 3.8rem 0', xs: '2.8rem 0 2.5rem 0' } }}>
              <Typography
                sx={{
                  fontSize: { lg: '4.4rem', xs: '2.6rem' },
                  lineHeight: { lg: '4.4rem', xs: '3.1rem' },
                  fontWeight: 800,
                  color: theme.palette.common.black,
                  mb: { lg: '1.6rem', xs: '2rem' },
                }}
              >
                {userCredit}
              </Typography>

              <Typography
                sx={{
                  fontSize: '1.8rem',
                  fontWeight: 400,
                  mb: { lg: '4rem', xs: '3rem' },
                  textAlign: 'center',
                }}
              >
                {t('mypage.no_credit_comment_1')}
                <br />
                <span style={{ fontWeight: 800 }}>
                  {t('mypage.no_credit_comment_2_a')}
                  <br /> {t('mypage.no_credit_comment_2_b')}
                </span>
              </Typography>

              <MainButton
                variant="contained"
                size="large"
                sx={{ mb: { xs: '0.5rem' } }}
                onClick={() => {
                  navigate('/pricing')
                }}
              >
                {t('button.charge_credit')}
              </MainButton>
            </CardContent>
          </CenterAlignStack>
        </Card>
      )
    }
  }

  return (
    <>
      <Desktop>{selectCard()}</Desktop>
      <Mobile>{selectCard()}</Mobile>
    </>
  )
}

// const CancelSubscriptionDialog = ({
//   open,
//   setOpen,
//   openNextDialog,
//   setOpenNextDialog,
//   setOpenNextDialogforSNS,
//   user,
//   currentSubscription,
//   refreshSubscription,
// }) => {
//   const navigate = useNavigate()
//   const theme = useTheme()
//   const isMobile = useMobileMediaQuery()
//   const { t, i18n } = useTranslation()
//   const [userAtomState, setUserAtomState] = useRecoilState(userAtom)

//   // page 0 일 때 조건(구독하고 7일 전 / 해당 크레딧 사용 전[크레딧 정책에 따라..])
//   // page 1 소멸 예정 크레딧 넣어야함 => 구독 취소누르면 page 3으로 가서 설문하고 끝
//   const [page, setPage] = useState(1)

//   useEffect(() => {
//     if (open) {
//       // ----- GA4 event -----
//       window.gtag('event', 'unsubscribe_process', {
//         menu: currentSubscription.refundable ? 'refund_unsubscribe' : 'next_unsubscribe',
//       })
//       // ---------------------
//     }
//   }, [open])

//   useEffect(() => {
//     setPage(1)
//   }, [currentSubscription])

//   const handleClose = () => {
//     setOpen(false)
//   }

//   const requestCancelSubscription = () => {
//     setOpen(false)

//     if (!userAtomState.login_sns) {
//       setOpenNextDialog(true)
//     } else {
//       setOpenNextDialogforSNS(true)
//     }
//   }

//   return (
//     <>
//       <CancelDialog
//         sx={{
//           '& .MuiPaper-root.MuiDialog-paper': {
//             // height: page === 0 ? '48.8rem' : 'auto',
//           },
//         }}
//         open={open}
//         onClose={handleClose}
//       >
//         <DialogContent sx={{ p: { lg: '28px 30px 20px 30px', xs: '29px 19px 17px 19px' } }}>
//           <CenterAlignStack>
//             <img
//               src="/static/images/etc/cryEmoji.png"
//               style={{ width: isMobile ? '135px' : '168px' }}
//             />
//             <CenterAlignStack
//               sx={{
//                 margin: { lg: '10px 0 20px 0', xs: page === 0 ? '13px 0 17px 0' : '13px 0 18px 0' },
//               }}
//             >
//               <DialogContentTitleTypography>
//                 <span style={{ color: theme.palette.draph.blue }}>{user.username}</span>
//                 <Korean>님</Korean>, <br />
//                 {page === 0
//                   ? t('mypage.cancel_subscription_commnet_1')
//                   : t('mypage.cancel_subscription_warning_2')}
//               </DialogContentTitleTypography>
//             </CenterAlignStack>
//             {innerComponents(page, currentSubscription)}
//           </CenterAlignStack>
//         </DialogContent>
//         <DialogActions sx={{ p: 0 }}>
//           <DialogActionButton
//             variant="contained"
//             onClick={requestCancelSubscription}
//             sx={{
//               ml: '0 !important',
//               backgroundColor: theme.palette.common.white,
//               color: theme.palette.common.black,
//               borderRadius: '0 !important',
//               width: '50%',
//               '&:hover': {
//                 backgroundColor: theme.palette.common.white,
//                 color: theme.palette.common.black,
//               },
//             }}
//           >
//             {t('button.cancel_subscription_l')}
//           </DialogActionButton>
//           <DialogActionButton
//             variant="contained"
//             onClick={handleClose}
//             sx={{
//               backgroundColor: theme.palette.draph.blue,
//               color: theme.palette.common.white,
//               borderRadius: '0 !important',
//               width: '50%',
//               m: '0 !important',
//               '&:hover': {
//                 backgroundColor: theme.palette.draph.blue,
//                 color: theme.palette.common.white,
//               },
//             }}
//           >
//             {t('button.stay_subscription')}
//           </DialogActionButton>
//         </DialogActions>
//       </CancelDialog>
//     </>
//   )
// }

// function SelectReasonDialog1({
//   options,
//   open,
//   setOpen,
//   selectedOption,
//   setSelectedOption,
//   openNextDialog,
//   setOpenNextDialog,
//   etcText,
//   setEtcText,
//   refreshSubscription,
//   currentSubscription,
// }) {
//   const theme = useTheme()

//   const handleAction = async () => {
//     // ----- GA4 event -----
//     const txt = options.filter(o => {
//       return o.id === selectedOption
//     })[0]?.label
//     window.gtag('event', 'unsubscribe_survey', { method: selectedOption === 999 ? etcText : txt })
//     // ---------------------

//     const reason =
//       selectedOption === 999
//         ? `(기타) ${etcText}`
//         : options.filter(o => o.id === selectedOption)[0].label

//     await apis.user.sendCancelSubscriptionFeedback({ message: reason })

//     await apis.user
//       .cancelSubscription({ current_subscription_id: currentSubscription.id })
//       .then(() => {
//         refreshSubscription()
//         setOpen(false)

//         // ----- GA4 event -----
//         window.gtag('event', 'unsubscribe', {})
//         // ---------------------
//       })
//     setOpen(false)
//     setOpenNextDialog(true)
//   }

//   useEffect(() => {
//     setSelectedOption('')
//     setEtcText('')

//     if (open) {
//       // ----- GA4 event -----
//       window.gtag('event', 'unsubscribe_process', { menu: 'survey' })
//       // ---------------------
//     }
//   }, [open])

//   const handleClose = () => {
//     setOpen(false)
//   }

//   const inputFontStyle = { fontSize: '1.4rem', fontWeight: 500 }
//   const { t, i18n } = useTranslation()

//   return (
//     <>
//       <Desktop>
//         <Dialog
//           open={open}
//           onClose={handleClose}
//           TransitionComponent={Transition}
//           sx={{
//             '& .MuiPaper-root.MuiDialog-paper': {
//               width: { lg: 'auto', xs: '320px' },
//               minWidth: { lg: '40rem', xs: '430px' },
//               minHeight: { lg: '27.7rem', xs: '430px' },
//               margin: { lg: '20px', xs: 0 },
//               borderRadius: '10px',
//               maxWidth: '80rem',
//             },
//           }}
//         >
//           <DialogContent
//             sx={{
//               overflowY: 'hidden',
//               padding: { lg: '2.8rem 3rem', xs: '33px 0 25px 0' },
//             }}
//           >
//             <CenterAlignStack>
//               <CenterAlignStack>
//                 <Typography sx={{ fontSize: { lg: '2rem', xs: '17px' }, fontWeight: 700 }}>
//                   {t('mypage.reason_dialog_title')}
//                 </Typography>
//                 <Typography
//                   sx={{
//                     fontSize: { lg: '1.2rem', xs: '11px' },
//                     fontWeight: 400,
//                     textAlign: 'center',
//                   }}
//                 >
//                   {t('mypage.reason_dialog_subtitle_1')}
//                   <br />
//                   {t('mypage.reason_dialog_subtitle_2')}
//                   <br />
//                   {t('mypage.reason_dialog_subtitle_3')}
//                 </Typography>
//               </CenterAlignStack>

//               <CenterAlignStack
//                 sx={{
//                   width: '100%',
//                   height: {
//                     lg: selectedOption === 999 ? '19.8rem' : '14.2rem',
//                   },
//                   padding: { lg: '3rem 0 3.2rem 0', xs: '21px 0 26px 0' },
//                   gap: '1.6rem',
//                   transition: 'all .3s ease-in-out',
//                 }}
//               >
//                 <Typography sx={{ fontSize: { lg: '1.6rem', xs: '15px' }, fontWeight: 700 }}>
//                   {t('mypage.reason_dialog_boxtitle')}{' '}
//                   <sup
//                     style={{
//                       color: theme.palette.error.main,
//                       fontWeight: 500,
//                       verticalAlign: 'text-top',
//                     }}
//                   >
//                     &#42;
//                   </sup>
//                 </Typography>

//                 <Select
//                   displayEmpty
//                   value={selectedOption}
//                   onChange={e => {
//                     setSelectedOption(e.target.value)
//                   }}
//                   sx={{
//                     width: '30.2rem',
//                     height: '4rem',
//                     background: ' white',
//                     '& .MuiOutlinedInput-notchedOutline': {
//                       borderWidth: '2px',
//                       borderColor: theme.palette.common.black,
//                     },
//                     ...inputFontStyle,
//                   }}
//                   renderValue={selected => {
//                     if (selected.length === 0) {
//                       return (
//                         <Typography
//                           sx={{
//                             ...inputFontStyle,
//                             color: '#878787',
//                             width: '25.6rem',
//                             textOverflow: 'ellipsis',
//                             whiteSpace: 'nowrap',
//                             overflow: 'hidden',
//                           }}
//                         >
//                           {t('select_reason_dialog.select')}
//                         </Typography>
//                       )
//                     } else {
//                       return (
//                         <Typography
//                           sx={{
//                             ...inputFontStyle,

//                             width: '25.6rem',
//                             textOverflow: 'ellipsis',
//                             whiteSpace: 'nowrap',
//                             overflow: 'hidden',
//                           }}
//                         >
//                           {
//                             options.filter(o => {
//                               return o.id === selected
//                             })[0]?.label
//                           }
//                         </Typography>
//                       )
//                     }
//                   }}
//                 >
//                   {options.map(o => (
//                     <MenuItem value={o.id} key={o.id} sx={{ px: '1rem', ...inputFontStyle }}>
//                       {o.label}{' '}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {selectedOption === 999 && (
//                   <motion.div
//                     initial={{ opacity: 0 }}
//                     animate={{ opacity: 1 }}
//                     transition={{ duration: 0.3 }}
//                   >
//                     <OutlinedInput
//                       // disabled={}
//                       placeholder={t('select_reason_dialog.input')}
//                       autoFocus
//                       value={etcText}
//                       onChange={e => {
//                         setEtcText(e.target.value)
//                       }}
//                       sx={{
//                         width: '30.2rem',
//                         height: '4rem',
//                         background: ' white',

//                         '& .MuiOutlinedInput-input': { padding: 0 },
//                         '& .MuiOutlinedInput-notchedOutline': {
//                           borderWidth: '2px',
//                           borderColor: theme.palette.common.black,
//                         },
//                         ...inputFontStyle,
//                       }}
//                     />
//                   </motion.div>
//                 )}
//               </CenterAlignStack>

//               <DialogButton
//                 cancelText={t('button.confirmation')}
//                 handleClose={handleAction}
//                 cancelSx={{ width: '14rem' }}
//                 cancelprops={{ disabled: !selectedOption }}
//                 actionText={t('button.cancel')}
//                 handleAction={handleClose}
//                 actionSx={{ width: '14rem', minWidth: 'none' }}
//               />
//             </CenterAlignStack>
//           </DialogContent>
//         </Dialog>
//       </Desktop>

//       <Mobile>
//         <MobileSwipeableDrawer
//           open={open}
//           onOpen={() => {
//             return open
//           }}
//           onClose={handleClose}
//         >
//           <CenterAlignStack sx={{ mt: '0.8rem', mb: '2.2rem' }}>
//             <CenterAlignStack>
//               <Typography sx={{ fontSize: '1.6rem', fontWeight: 700 }}>
//                 {t('mypage.reason_dialog_title')}
//               </Typography>
//               <Typography
//                 sx={{
//                   fontSize: { lg: '1.2rem', xs: '1.3rem' },
//                   fontWeight: 400,
//                   textAlign: 'center',
//                   color: '#595959',
//                   my: '1.6rem',
//                 }}
//               >
//                 {t('mypage.reason_dialog_subtitle_1_m')}
//                 <br />
//                 {!isKo(i18n) && <br />}
//                 {t('mypage.reason_dialog_subtitle_2_m')}
//                 <br />
//                 {isKo(i18n) && <br />}
//                 {t('mypage.reason_dialog_subtitle_3_m')}
//                 <br />
//                 {isKo(i18n) && t('mypage.reason_dialog_subtitle_4_m')}
//                 {isKo(i18n) && <br />}
//                 {t('mypage.reason_dialog_subtitle_3')}
//               </Typography>
//             </CenterAlignStack>

//             <CenterAlignStack
//               sx={{
//                 width: '100%',
//                 height: {
//                   lg: selectedOption === 999 ? '19.8rem' : '14.2rem',
//                 },
//                 padding: { lg: '3rem 0 3.2rem 0', xs: '1.6rem 0 4rem 0' },
//                 gap: '1.6rem',
//                 transition: 'all .3s ease-in-out',
//               }}
//             >
//               <Typography sx={{ fontSize: { lg: '1.6rem', xs: '15px' }, fontWeight: 700 }}>
//                 {t('mypage.reason_dialog_boxtitle')}{' '}
//                 <sup
//                   style={{
//                     color: theme.palette.error.main,
//                     fontWeight: 500,
//                     verticalAlign: 'text-top',
//                   }}
//                 >
//                   &#42;
//                 </sup>
//               </Typography>

//               <Select
//                 displayEmpty
//                 value={selectedOption}
//                 onChange={e => {
//                   setSelectedOption(e.target.value)
//                 }}
//                 sx={{
//                   width: '30.2rem',
//                   height: '4rem',
//                   background: ' white',
//                   '& .MuiOutlinedInput-notchedOutline': {
//                     borderWidth: '2px',
//                     borderColor: theme.palette.common.black,
//                   },
//                   ...inputFontStyle,
//                 }}
//                 renderValue={selected => {
//                   if (selected.length === 0) {
//                     return (
//                       <Typography
//                         sx={{
//                           ...inputFontStyle,
//                           color: '#878787',
//                           width: '25.6rem',
//                           textOverflow: 'ellipsis',
//                           whiteSpace: 'nowrap',
//                           overflow: 'hidden',
//                         }}
//                       >
//                         {t('select_reason_dialog.select')}
//                       </Typography>
//                     )
//                   } else {
//                     return (
//                       <Typography
//                         sx={{
//                           ...inputFontStyle,

//                           width: '25.6rem',
//                           textOverflow: 'ellipsis',
//                           whiteSpace: 'nowrap',
//                           overflow: 'hidden',
//                         }}
//                       >
//                         {
//                           options.filter(o => {
//                             return o.id === selected
//                           })[0]?.label
//                         }
//                       </Typography>
//                     )
//                   }
//                 }}
//               >
//                 {options.map(o => (
//                   <MenuItem value={o.id} key={o.id} sx={{ px: '1rem', ...inputFontStyle }}>
//                     {o.label}{' '}
//                   </MenuItem>
//                 ))}
//               </Select>
//               {selectedOption === 999 && (
//                 <motion.div
//                   initial={{ opacity: 0 }}
//                   animate={{ opacity: 1 }}
//                   transition={{ duration: 0.3 }}
//                 >
//                   <OutlinedInput
//                     // disabled={}
//                     placeholder={t('select_reason_dialog.input')}
//                     autoFocus
//                     value={etcText}
//                     onChange={e => {
//                       setEtcText(e.target.value)
//                     }}
//                     sx={{
//                       width: '30.2rem',
//                       height: '4rem',
//                       background: ' white',

//                       '& .MuiOutlinedInput-input': { padding: 0 },
//                       '& .MuiOutlinedInput-notchedOutline': {
//                         borderWidth: '2px',
//                         borderColor: theme.palette.common.black,
//                       },
//                       ...inputFontStyle,
//                     }}
//                   />
//                 </motion.div>
//               )}
//             </CenterAlignStack>

//             <DialogButton
//               cancelText={t('button.confirmation')}
//               handleClose={handleAction}
//               cancelSx={{ width: '12rem' }}
//               cancelprops={{ disabled: !selectedOption }}
//               actionText={t('button.cancel')}
//               handleAction={handleClose}
//               actionSx={{ width: '12rem', minWidth: 'none' }}
//             />
//           </CenterAlignStack>
//         </MobileSwipeableDrawer>
//       </Mobile>
//     </>
//   )
// }

// function LeaveDialog1({
//   options,
//   open,
//   setOpen,
//   selectedOption,
//   setSelectedOption,
//   etcText,
//   setEtcText,
//   refreshSubscription,
//   currentSubscription,
// }) {
//   const { t, i18n } = useTranslation()
//   const user = useRecoilValue(userAtom)
//   const [userStat, setUserStat] = useState(null)

//   const theme = useTheme()

//   useEffect(() => {
//     if (open) {
//       // ----- GA4 event -----
//       window.gtag('event', 'unsubscribe_process', { menu: 'last' })
//       // ---------------------
//     }
//   }, [open])

//   const handleAction = async () => {
//     const reason =
//       selectedOption === 999
//         ? `(기타) ${etcText}`
//         : options.filter(o => o.id === selectedOption)[0].label

//     await apis.user.sendCancelSubscriptionFeedback({ message: reason })

//     await apis.user
//       .cancelSubscription({ current_subscription_id: currentSubscription.id })
//       .then(() => {
//         refreshSubscription()
//         setOpen(false)

//         // ----- GA4 event -----
//         window.gtag('event', 'unsubscribe', {})
//         // ---------------------
//       })
//   }

//   const handleClose = () => {
//     setOpen(false)
//   }

//   const isUserIdlong = Boolean(user.username.length > 5)

//   useEffect(() => {
//     apis.user.getUserStat().then(r => {
//       const stat = r.data

//       setUserStat(stat)
//     })
//   }, [])

//   return (
//     <>
//       <Desktop>
//         <Dialog
//           open={open}
//           onClose={handleClose}
//           TransitionComponent={Transition}
//           sx={{
//             '& .MuiPaper-root.MuiDialog-paper': {
//               width: { lg: 'auto', xs: '320px' },
//               minWidth: { lg: '40rem', xs: '430px' },
//               margin: { lg: '20px', xs: 0 },
//               borderRadius: '20px',
//             },
//           }}
//         >
//           <DialogContent
//             sx={{
//               overflowY: 'hidden',
//               padding: { lg: '2.2rem 0 3.2rem 0', xs: '33px 0 25px 0' },
//             }}
//           >
//             <CenterAlignStack sx={{ p: '1.2rem 0 2rem 0' }}>
//               <img src="/static/images/etc/cryEmoji.png" />

//               <Typography sx={{ fontSize: '2rem', fontWeight: 700, textAlign: 'center' }}>
//                 <SpanDraphBlue>{user.username}</SpanDraphBlue>
//                 {t('mypage.cancel_dialog_sir')} <br /> {t('mypage.cancel_dialog_title')}
//                 {!isKo(i18n) && <br />}
//                 {t('mypage.cancel_dialog_title_1')}
//               </Typography>
//             </CenterAlignStack>
//             <CenterAlignStack
//               sx={{
//                 p: isKo(i18n) ? '2.6rem 3rem 2.5rem 3rem' : '2.6rem 5rem 2.5rem 5rem',
//                 width: '100%',
//                 backgroundColor: '#F8F8F8',
//               }}
//             >
//               <Korean>
//                 <Typography sx={{ fontSize: '1.6rem', fontWeight: 700, textAlign: 'center' }}>
//                   <SpanDraphBlue>{user.username}</SpanDraphBlue>
//                   {t('mypage.cancel_dialog_subtitle_1')}
//                   <SpanDraphBlue>{trasnferDate(user.created, 'detail', i18n)}</SpanDraphBlue>
//                   {t('mypage.cancel_dialog_subtitle_2')}
//                   <br />
//                   {t('mypage.cancel_dialog_subtitle_3')}
//                   <SpanDraphBlue>{nullZero(userStat?.piece[0]?.piece)}장</SpanDraphBlue>
//                   {t('mypage.cancel_dialog_subtitle_4')}
//                   <br />
//                   <SpanDraphBlue>99%</SpanDraphBlue>
//                   {t('mypage.cancel_dialog_subtitle_5')}
//                 </Typography>
//               </Korean>
//               <English>
//                 <Typography
//                   sx={{
//                     fontSize: '1.6rem',
//                     fontWeight: 700,
//                     textAlign: 'start',
//                     wordBreak: 'break-word',
//                     width: '41rem',
//                   }}
//                 >
//                   <SpanDraphBlue>Dear {user.username}, </SpanDraphBlue>
//                   you have generated a total of <br />
//                   <SpanDraphBlue>{nullZero(userStat?.piece[0]?.piece)} images </SpanDraphBlue>
//                   from <SpanDraphBlue>
//                     {trasnferDate(user.created, 'detail', i18n)}
//                   </SpanDraphBlue>{' '}
//                   with Draph Art and <br />
//                   saved <SpanDraphBlue>99% </SpanDraphBlue>
//                   of the cost compared to actual studio photoshooting.
//                 </Typography>
//               </English>
//             </CenterAlignStack>

//             <Typography
//               sx={{ fontSize: '2rem', fontWeight: 700, textAlign: 'center', my: '2.4rem' }}
//             >
//               {t('mypage.cancel_dialog_last')}
//             </Typography>
//             <CenterAlignBox
//               sx={{
//                 alignItems: 'end',
//                 justifyContent: 'center',
//                 mx: '2.7rem',
//                 position: 'relative',
//               }}
//             >
//               {/* <Box sx={{ width: '4.9rem' }}></Box> */}
//               <Button
//                 variant="contained"
//                 sx={{
//                   width: '14rem',
//                   height: '5rem',
//                   fontSize: '2rem',
//                   fontWeight: 800,
//                   mx: '5.4rem',
//                 }}
//                 onClick={handleClose}
//               >
//                 {t('button.cancel')}
//               </Button>
//               <Button
//                 variant="text"
//                 sx={{
//                   position: 'absolute',
//                   color: '#808080',
//                   borderBottom: '1px solid #808080',
//                   width: 'auto',
//                   right: 0,
//                   // width: '4.9rem',
//                   fontWeight: 400,
//                   fontSize: '1.4rem',
//                   borderRadius: 0,
//                   padding: '0',

//                   '&:hover': {
//                     backgroundColor: 'transparent',
//                   },
//                 }}
//                 onClick={handleAction}
//               >
//                 &nbsp;{t('button.cancel_subscription')}&nbsp;
//               </Button>
//             </CenterAlignBox>
//           </DialogContent>
//         </Dialog>
//       </Desktop>

//       <Mobile>
//         <MobileSwipeableDrawer
//           open={open}
//           onOpen={() => {
//             return open
//           }}
//           onClose={handleClose}
//         >
//           <CenterAlignStack sx={{ mb: '2.2rem' }}>
//             <CenterAlignStack sx={{ p: '1.2rem 0 2rem 0' }}>
//               <img src="/static/images/etc/cryEmoji.png" style={{ width: '14rem' }} />

//               <Typography
//                 sx={{ fontSize: '1.8rem', fontWeight: 700, textAlign: 'center', p: '0 2rem' }}
//               >
//                 <SpanDraphBlue>{user.username}</SpanDraphBlue>
//                 {t('mypage.cancel_dialog_sir')} <br /> {t('mypage.cancel_dialog_title')}
//                 {!isKo(i18n) && <br />}
//                 {t('mypage.cancel_dialog_title_1')}
//               </Typography>
//             </CenterAlignStack>
//             <CenterAlignStack
//               sx={{
//                 p: isUserIdlong ? '2.6rem 2rem 2.5rem 3.2rem' : '2.6rem 1rem 2.5rem 1rem',
//                 width: '100%',
//                 backgroundColor: '#F8F8F8',
//               }}
//             >
//               {' '}
//               <Typography
//                 sx={{
//                   fontSize: '1.5rem',
//                   fontWeight: 600,
//                   textAlign: isUserIdlong ? 'start' : 'center',
//                   wordBreak: 'keep-all',
//                 }}
//               >
//                 <Korean>
//                   <SpanDraphBlue>{user.username}</SpanDraphBlue>
//                   {t('mypage.cancel_dialog_subtitle_1')}
//                   <SpanDraphBlue>{trasnferDate(user.created, 'detail', i18n)}</SpanDraphBlue>
//                   {t('mypage.cancel_dialog_subtitle_2')}
//                   {!isUserIdlong && <br />}
//                   {isUserIdlong && ' '}
//                   {t('mypage.cancel_dialog_subtitle_3')}
//                   <SpanDraphBlue>{nullZero(userStat?.piece[0]?.piece)}장</SpanDraphBlue>
//                   {t('mypage.cancel_dialog_subtitle_4')}
//                   {!isUserIdlong && <br />}
//                   <SpanDraphBlue>99%</SpanDraphBlue>
//                   {t('mypage.cancel_dialog_subtitle_5')}
//                 </Korean>
//                 <English>
//                   You have generated a total of {!isUserIdlong && <br />}
//                   <SpanDraphBlue>{nullZero(userStat?.piece[0]?.piece)} images </SpanDraphBlue>
//                   from <SpanDraphBlue>
//                     {trasnferDate(user.created, 'detail', i18n)}
//                   </SpanDraphBlue>{' '}
//                   {!isUserIdlong && <br />}
//                   with Draph Art and saved <SpanDraphBlue> 99% </SpanDraphBlue>
//                   of the cost compared to actual studio photoshooting.
//                 </English>
//               </Typography>
//             </CenterAlignStack>

//             <Typography
//               sx={{ fontSize: '1.8rem', fontWeight: 700, textAlign: 'center', my: '2.4rem' }}
//             >
//               {t('mypage.cancel_dialog_last')}
//             </Typography>
//             <CenterAlignBox
//               sx={{ alignItems: 'end', justifyContent: 'space-between', mx: '2.7rem' }}
//             >
//               <Box sx={{ width: '7.7rem' }}></Box>
//               <Button
//                 variant="contained"
//                 sx={{
//                   width: '12rem',
//                   height: '5rem',
//                   fontSize: '2rem',
//                   fontWeight: 800,
//                   mx: '3.4rem',
//                   borderRadius: '10px',
//                 }}
//                 onClick={handleClose}
//               >
//                 {t('button.cancel')}
//               </Button>
//               <Button
//                 variant="text"
//                 sx={{
//                   color: '#808080',
//                   borderBottom: '1px solid #808080',

//                   width: '7.7rem',
//                   fontWeight: 400,
//                   fontSize: '1.4rem',
//                   borderRadius: 0,
//                   padding: '0',

//                   '&:hover': {
//                     backgroundColor: 'transparent',
//                   },
//                 }}
//                 onClick={handleAction}
//               >
//                 {t('button.cancel_subscription_l')}
//               </Button>
//             </CenterAlignBox>
//           </CenterAlignStack>
//         </MobileSwipeableDrawer>
//       </Mobile>
//     </>
//   )
// }

const InnerMainStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  // height: '96px',
  margin: '0 38px',
  padding: '15px 20px',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.lightgray,

  [theme.breakpoints.down('lg')]: { padding: '17px 11px' },
}))

const innerComponents = (page, currentSubscription) => {
  const theme = useTheme()

  const userCredit = useRecoilValue(userCreditAtom)
  const lastPaid = currentSubscription?.last_paid
  const isMobile = useMobileMediaQuery()

  const cardCreditStyle = { fontSize: '2rem', lineHeight: '2.4rem', fontWeight: 700 }

  switch (page) {
    case 0:
      return <></>

    case 1:
      return (
        <motion.div
          style={{ width: '100%' }}
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <CenterAlignStack sx={{}}>
            <CenterAlignBox gap={isMobile ? '16px' : '24px'}>
              <DialogCreditCard sx={{}}>
                <CenterAlignStack>
                  <Typography
                    sx={{
                      fontSize: { lg: '16px', xs: '15px' },
                      lineHeight: { lg: '19px', xs: '18px' },
                      fontWeight: 600,
                      color: theme.palette.draph.blue,
                      mb: '4px',
                    }}
                  >
                    {t('mypage.cancel_subscription_table_title_1')}
                  </Typography>
                  <Typography sx={{ ...cardCreditStyle }}>{userCredit}</Typography>
                </CenterAlignStack>
              </DialogCreditCard>
              <DialogCreditCard sx={{ border: `1px solid ${theme.palette.common.red}` }}>
                <CenterAlignStack>
                  <Typography
                    sx={{
                      fontSize: { lg: '12px', xs: '11px' },
                      fontWeight: 500,
                      lineHeight: { lg: '14px', xs: '13px' },
                      color: theme.palette.common.gray,
                      mb: '4px',
                    }}
                  >
                    {trasnferDate(getNextPaidDay(lastPaid), 'detail', i18n)}
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.common.red,
                      fontSize: { lg: '16px', xs: '15px' },
                      fontWeight: 600,
                      lineHeight: { lg: '19px', xs: '18px' },
                      mb: '4px',
                    }}
                  >
                    {t('mypage.cancel_subscription_table_title_2')}
                  </Typography>
                  <Typography sx={{ ...cardCreditStyle }}>
                    {currentSubscription.subscription_credit ?? 0}
                  </Typography>
                </CenterAlignStack>
              </DialogCreditCard>
            </CenterAlignBox>
            {currentSubscription.refundable && (
              <InnerMainStack
                sx={{
                  height: { lg: '17.2rem', xs: '16.1rem' },
                  mt: { lg: '2.7rem', xs: '2.2rem' },
                }}
              >
                <Typography
                  fontWeight={700}
                  sx={{
                    fontSize: { lg: '1.6rem', xs: '1.4rem' },
                    width: '100%',
                    textAlign: 'center',
                    mb: '1rem',
                  }}
                >
                  {t('mypage.cancel_subscription_refund')}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '400',
                    // wordBreak: 'keep-all',
                    fontSize: { lg: '12px', xs: '11px' },
                    lineHeight: { lg: '13px', xs: '13px' },
                  }}
                >
                  {t('mypage.cancel_subscription_refund_comment_1')}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '400',
                    // wordBreak: 'keep-all',
                    fontSize: { lg: '12px', xs: '11px' },
                    lineHeight: { lg: '13px', xs: '13px' },
                    color: theme => theme.palette.common.red,
                    mt: { lg: '0.6rem', xs: '0.4rem' },
                  }}
                >
                  {t('mypage.cancel_subscription_refund_comment_2')}
                </Typography>
              </InnerMainStack>
            )}
            <Typography
              sx={{
                mt: { lg: '17px', xs: '16px' },
                fontSize: { lg: '16px', xs: '14px' },
                fontWeight: 500,
              }}
            >
              {t('mypage.cancel_subscription_refund_comment_3')}
            </Typography>
          </CenterAlignStack>
        </motion.div>
      )
  }
}

const DialogCreditCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: '16px',
  width: '147px',
  height: '94px',

  [theme.breakpoints.down('lg')]: { width: '132px', height: '88px' },
}))

function SubscriptionPaymtneMethodDialog({ open, setOpen, subInfo, handleChangePaymentMethod }) {
  // 구독 정기 결제가 실패하여 구독정보 카드에 '결제수단을 변경해달라'는 경고문구 표시된 경우
  // 그 문구를 클릭하면 열리는 다이얼로그

  const { t, i18n } = useTranslation()

  const [creditExpDate, setCreditExpDate] = useState()

  useEffect(() => {
    if (subInfo.sub_credit_expires) {
      const utcDate = moment.utc(subInfo.sub_credit_expires).toDate()
      const oneDayLater = moment(utcDate).add(1, 'days')

      const localDateString = oneDayLater.local().format('YYYY/MM/DD')

      setCreditExpDate(localDateString)
    }
  }, [subInfo])

  const handleClick = () => {
    setOpen(false)
    handleChangePaymentMethod()
  }

  // utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
        >
          <DialogContent sx={{ width: '50rem', py: '3rem', px: 0 }}>
            <CenterAlignStack>
              <CenterAlignStack
                sx={{
                  '& .text': {
                    fontSize: '2rem',

                    fontWeight: 700,
                    lineHeight: 1.2,
                    textAlign: 'center',
                    '& .blue': { color: theme => theme.palette.draph.blue },
                  },
                  mb: '2rem',
                }}
              >
                <Korean>
                  <Typography className="text">
                    월정액 자동 결제가 되지 않았습니다.
                    <br />
                    <span className="blue">결제 수단을 변경</span>해주세요.
                  </Typography>
                </Korean>

                <English>
                  <Typography className="text">
                    There was an issue with your payment method <br />
                    so the automatic payment did not go through.
                    <br />
                    <span className="blue">Please change your payment method.</span>
                  </Typography>
                </English>
              </CenterAlignStack>

              <CenterAlignStack
                sx={{
                  '& .text': {
                    lineHeight: 1.3,
                    textAlign: 'center',
                    fontSize: '1.6rem',
                    fontWeight: 600,
                    '& .blue': { color: theme => theme.palette.draph.blue },
                  },
                  background: '#F8F8F8',
                  px: '3.2rem',
                  py: '2.1rem',
                  mb: '2rem',
                }}
              >
                <Korean>
                  <Typography className="text">
                    드랩아트에 등록된 결제 수단에 문제가 발생하여 자동 결제가 되지 않았습니다.{' '}
                    {subInfo.temp_expired_credit_sum && (
                      <>
                        이에 월정액으로 충전된 크레딧이 임시로 소멸 조치되었습니다.
                        <br />
                      </>
                    )}
                    {subInfo.payment_method_update_exp && (
                      <>
                        <span className="blue">
                          {utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')}
                        </span>{' '}
                        까지{' '}
                      </>
                    )}
                    {subInfo.temp_expired_credit_sum ? (
                      <>
                        결제 수단을 변경하시면 드랩아트 월정액을 계속 구독하실 수 있으며, 임시로
                        소멸된 크레딧도 모두 복구됩니다. <br />
                        <br />
                        단, 기한 내에 결제 수단을 변경하지 않는 경우 월정액 구독이 자동 취소되며,
                        임시로 소멸 조치된 크레딧 역시 복구되지 않습니다.
                      </>
                    ) : (
                      <>
                        결제 수단을 변경하시면 드랩아트 월정액을 계속 구독하실 수 있습니다.
                        <br />
                        <br />
                        만약 기한 내에 결제 수단을 변경하지 않는 경우 월정액 구독이 자동 취소되며,
                        구독이 취소되면 월정액 구독으로 충전된 크레딧도 모두 사라집니다.
                      </>
                    )}
                  </Typography>
                </Korean>
                <English>
                  <Typography className="text">
                    There was an issue with your payment method so the automatic payment did not go
                    through.{' '}
                    {subInfo.temp_expired_credit_sum && (
                      <>
                        Hence your charged credits are now temporarily expired.
                        <br />
                      </>
                    )}
                    If you update your payment method{' '}
                    {subInfo.payment_method_update_exp && (
                      <>
                        by{' '}
                        <span className="blue">
                          {utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')}
                        </span>{' '}
                      </>
                    )}
                    {subInfo.temp_expired_credit_sum ? (
                      <>
                        your subscription will continue and the expired credits will be restored.{' '}
                        <br />
                        <br />
                        However, if the payment method update is not proceeded within the given
                        period, your monthly subscription will be canceled automatically and the
                        expired credits will not be restored.
                      </>
                    ) : (
                      <>
                        your subscription will continue.
                        <br />
                        <br />
                        However, if the payment method update is not proceeded within the given
                        period, your monthly subscription will be canceled automatically and the
                        credits charged from your subscription will also expire.
                      </>
                    )}
                  </Typography>
                </English>
              </CenterAlignStack>

              {(subInfo.temp_expired_credit_sum || subInfo.subscription_credit) && (
                <CenterAlignStack
                  sx={{
                    '& .text': {
                      color: '#FF2323',
                      lineHeight: 1.3,
                      textAlign: 'center',
                      fontSize: '2rem',
                      fontWeight: 500,
                      '&.large': {
                        fontSize: '2.4rem',
                      },
                      '&.bold': {
                        fontWeight: 700,
                      },
                    },
                    mb: '2rem',
                  }}
                >
                  <Korean>
                    {subInfo.temp_expired_credit_sum ? (
                      <Typography className="text">임시 소멸 조치된 크레딧</Typography>
                    ) : subInfo.subscription_credit ? (
                      <Typography className="text">소멸 예정 크레딧</Typography>
                    ) : (
                      <></>
                    )}

                    <Typography className="text large bold">
                      {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} 크레딧
                    </Typography>
                  </Korean>
                  <English>
                    {subInfo.temp_expired_credit_sum ? (
                      <Typography className="text">Temporarily expired</Typography>
                    ) : subInfo.subscription_credit ? (
                      <Typography className="text">Expected to expire</Typography>
                    ) : (
                      <></>
                    )}

                    <Typography className="text large bold">
                      {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} Credits
                    </Typography>
                  </English>
                </CenterAlignStack>
              )}

              <Button
                variant="contained"
                sx={{
                  width: isKo(i18n) ? '22rem' : '30rem',
                  height: '5rem',
                  fontSize: '2rem',
                  fontWeight: 800,
                  borderRadius: '1rem',
                }}
                onClick={handleClick}
              >
                {t('mypage.change_payment_method_dialog_button')}
              </Button>
            </CenterAlignStack>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {}}
          onClose={() => {
            setOpen(false)
          }}
        >
          <CenterAlignStack sx={{ pb: '2.2rem' }}>
            <CenterAlignStack
              sx={{
                '& .text': {
                  textAlign: 'center',
                  fontSize: '1.8rem',
                  fontWeight: 700,
                  lineHeight: 1.2,
                  '& .blue': { color: theme => theme.palette.draph.blue },
                },
                mt: '1rem',
                mb: '2rem',
              }}
            >
              <Korean>
                <Typography className="text">
                  월정액 자동 결제가 되지 않았습니다.
                  <br />
                  <span className="blue">결제 수단을 변경</span>해주세요.
                </Typography>
              </Korean>

              <English>
                <Typography className="text">
                  There was an issue with your payment method so the automatic payment did not go
                  through.
                  <br />
                  <span className="blue">Please change your payment method.</span>
                </Typography>
              </English>
            </CenterAlignStack>

            <CenterAlignStack
              sx={{
                '& .text': {
                  lineHeight: 1.3,
                  textAlign: 'center',
                  fontSize: '1.4rem',
                  fontWeight: 500,
                  '& .blue': { color: theme => theme.palette.draph.blue },
                },
                background: '#F8F8F8',
                px: '1.8rem',
                py: '2.4rem',
                mb: '2rem',
              }}
            >
              <Korean>
                <Typography className="text">
                  드랩아트에 등록된 결제 수단에 문제가 발생하여 자동 결제가 되지 않았습니다.{' '}
                  {subInfo.temp_expired_credit_sum && (
                    <>
                      이에 월정액으로 충전된 크레딧이 임시로 소멸 조치되었습니다.
                      <br />
                    </>
                  )}
                  {subInfo.payment_method_update_exp && (
                    <>
                      <span className="blue">
                        {utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')}
                      </span>{' '}
                      까지{' '}
                    </>
                  )}
                  {subInfo.temp_expired_credit_sum ? (
                    <>
                      결제 수단을 변경하시면 드랩아트 월정액을 계속 구독하실 수 있으며, 임시로
                      소멸된 크레딧도 모두 복구됩니다. <br />
                      <br />
                      단, 기한 내에 결제 수단을 변경하지 않는 경우 월정액 구독이 자동 취소되며,
                      임시로 소멸 조치된 크레딧 역시 복구되지 않습니다.
                    </>
                  ) : (
                    <>
                      결제 수단을 변경하시면 드랩아트 월정액을 계속 구독하실 수 있습니다.
                      <br />
                      <br />
                      만약 기한 내에 결제 수단을 변경하지 않는 경우 월정액 구독이 자동 취소되며,
                      구독이 취소되면 월정액 구독으로 충전된 크레딧도 모두 사라집니다.
                    </>
                  )}
                </Typography>
              </Korean>
              <English>
                <Typography className="text">
                  There was an issue with your payment method so the automatic payment did not go
                  through.{' '}
                  {subInfo.temp_expired_credit_sum && (
                    <>
                      Hence your charged credits are now temporarily expired.
                      <br />
                    </>
                  )}
                  If you update your payment method{' '}
                  {subInfo.payment_method_update_exp && (
                    <>
                      by{' '}
                      <span className="blue">
                        {utcToLocal(subInfo.payment_method_update_exp, 'YYYY/MM/DD HH:mm')}
                      </span>{' '}
                    </>
                  )}
                  {subInfo.temp_expired_credit_sum ? (
                    <>
                      your subscription will continue and the expired credits will be restored.{' '}
                      <br />
                      <br />
                      However, if the payment method update is not proceeded within the given
                      period, your monthly subscription will be canceled automatically and the
                      expired credits will not be restored.
                    </>
                  ) : (
                    <>
                      your subscription will continue.
                      <br />
                      <br />
                      However, if the payment method update is not proceeded within the given
                      period, your monthly subscription will be canceled automatically and the
                      credits charged from your subscription will also expire.
                    </>
                  )}
                </Typography>
              </English>
            </CenterAlignStack>

            {(subInfo.temp_expired_credit_sum || subInfo.subscription_credit) && (
              <CenterAlignStack
                sx={{
                  '& .text': {
                    color: '#FF2323',
                    lineHeight: 1.3,
                    textAlign: 'center',
                    fontSize: '1.8rem',
                    fontWeight: 500,
                    '&.large': {
                      fontSize: '2.2rem',
                    },
                    '&.bold': {
                      fontWeight: 700,
                    },
                  },
                  mb: '2rem',
                }}
              >
                <Korean>
                  {subInfo.temp_expired_credit_sum ? (
                    <Typography className="text">임시 소멸 조치된 크레딧</Typography>
                  ) : subInfo.subscription_credit ? (
                    <Typography className="text">소멸 예정 크레딧</Typography>
                  ) : (
                    <></>
                  )}

                  <Typography className="text large bold">
                    {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} 크레딧
                  </Typography>
                </Korean>
                <English>
                  {subInfo.temp_expired_credit_sum ? (
                    <Typography className="text">Temporarily expired</Typography>
                  ) : subInfo.subscription_credit ? (
                    <Typography className="text">Expected to expire</Typography>
                  ) : (
                    <></>
                  )}

                  <Typography className="text large bold">
                    {subInfo.temp_expired_credit_sum ?? subInfo.subscription_credit} Credits
                  </Typography>
                </English>
              </CenterAlignStack>
            )}

            <Button
              variant="contained"
              sx={{
                width: '32rem',
                height: '4.8rem',
                fontSize: '1.8rem',
                fontWeight: 800,
                borderRadius: '1rem',
              }}
              onClick={handleClick}
            >
              {t('mypage.change_payment_method_dialog_button')}
            </Button>
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}
