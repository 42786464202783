import { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Typography,
  Stack,
  IconButton,
  Card,
  CardHeader,
  Avatar,
  CardMedia,
  CardContent,
  TextField,
  Icon,
} from '@mui/material'
import { ViewModal } from './ViewModal'

import { FiEdit3 as EditIcon, FiSearch as SearchIcon } from 'react-icons/fi'

import { getS3ImageSrc } from 'utils/common'
import {
  BackgroundImageSwiperSlideDialog,
  BgOntlinedInput,
  CenterAlignBox,
  CenterAlignStack,
} from 'components'
import {
  backgroundAtom,
  backgroundDialogAtom,
  backgroundFetchInfoAtom,
  backgroundFilterAtom,
  userAtom,
  userLikedBgAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useSnackbar } from 'notistack'
import { apis } from 'apis'
import {
  AiFillPushpin,
  AiOutlineClose,
  AiOutlinePushpin,
  AiOutlineSave,
  AiTwotonePushpin,
} from 'react-icons/ai'
import useConfirm from 'hooks/useConfirm'
import { refreshBackground } from 'pages/Background'
import { CloseIcon, StarFilledIcon, StarOutlinedIcon } from 'theme/icon'
import { styled, useTheme } from '@mui/material/styles'
import { ref } from 'yup'
import { height } from '@mui/system'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { PORTFOLIO_CATEGORY_DICT } from 'config'
import useBackground from 'hooks/useBackground'
import { useTransform } from 'framer-motion'
import { useTranslation } from 'react-i18next'

const imageBoxStyle = {
  position: 'relative',
  width: '100%',
  height: '32rem',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
}

const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}
const iconBgColor = '#00000030'
const iconColor = '#ffffff90'

const imageBoxIconStyle = {
  position: 'absolute',
  color: iconColor,
  p: 0.4,
  borderRadius: 'unset',
}

const idxTypoStyle = {
  paddingLeft: '1rem',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '18px',
  paddingBottom: '1rem',
}

const idxTypoMobileStyle = {
  paddingLeft: '0.3rem',
  fontWeight: '700',
  fontSize: '1.3rem',
  lineHeight: '1.6rem',
  paddingBottom: '0.4rem',
}

export const BackgroundCard = styled(Card)(({ theme }) => ({
  '& .MuiCardHeader-action': {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingRight: '1.1rem',
  },
}))

export function BackgroundItem({ bg, idx }) {
  const tags = bg.tag.split(',')
  const setBackgroundDialog = useSetRecoilState(backgroundDialogAtom)
  const user = useRecoilValue(userAtom)

  const setBackgroundList = useSetRecoilState(backgroundAtom)
  const [fetchInfo, setFetchInfo] = useRecoilState(backgroundFetchInfoAtom)
  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)
  const filter = useRecoilValue(backgroundFilterAtom)

  const { refreshBackground } = useBackground()

  const [hover, setHover] = useState(false)
  const [nameEditMode, setNameEditMode] = useState(false)
  const [editNameText, setEditNameText] = useState('')

  const { showConfirm } = useConfirm()
  const { enqueueSnackbar } = useSnackbar()
  const { t, i18n } = useTranslation()
  const theme = useTheme()

  const handleStar = bg => () => {
    apis.background.likeBackground(bg.id, !bg.liked).then(response => {
      if (bg.liked) {
        enqueueSnackbar(t('background_item.personal_removed'), { variant: 'error' })
      }
      if (!bg.liked) {
        enqueueSnackbar(t('background_item.personal_added'), { variant: 'success' })
      }
      refreshBackground()
    })
  }

  const handlePin = bg => () => {
    apis.background.likeBackground(bg.id, null, !bg.pinned).then(response => {
      if (!response.data.success && response.data.message.toLowerCase() === 'pin_max') {
        enqueueSnackbar(t('background_item.max_pinned_added'), { variant: 'error' })
      } else {
        if (bg.pinned) {
          enqueueSnackbar(t('background_item.pinned_removed'), { variant: 'error' })
        }
        if (!bg.pinned) {
          enqueueSnackbar(t('background_item.pinned_added'), { variant: 'success' })
        }
        refreshBackground()
      }
    })
  }

  const handlePinStar = bg => () => {
    apis.background.likeBackground(bg.id, false, false).then(response => {
      if (bg.pinned || bg.liked) {
        enqueueSnackbar(t('background_item.personal_pinned_removed'), { variant: 'error' })
      }

      refreshBackground()
    })
  }

  const deleteBg = (background, idx) => () => {
    showConfirm({
      title: t('background_item.background_delete'),
      content: <p>{t('background_item.background_delete_comment')}</p>,
      onConfirm: () => {
        apis.background.deleteBackground(background.id).then(() => {
          // console.log('delete 성공')
          refreshBackground()
        })
      },
      onCancel: () => {},
    })
  }

  const editBackgroundName = bg => () => {
    setEditNameText(bg.name)
    setNameEditMode(true)
  }

  const saveBackgroundName = bg => () => {
    if (!editNameText.trim()) {
      setNameEditMode(false)
    } else {
      apis.background
        .putBackground(bg.id, { name: editNameText })
        .then(() => {
          refreshBackground()
        })
        .finally(() => setNameEditMode(false))
    }
  }

  const onChangeName = event => {
    setEditNameText(event.target.value)
  }

  const handleEditNameKeyEvent = bg => e => {
    if (e.key === 'Enter') {
      // console.log('enter on')
      saveBackgroundName(bg)()
    }
  }
  const categortForAdmin = bg.category
    .split(',')
    .map(key => PORTFOLIO_CATEGORY_DICT[key])
    .join(',')

  // useEffect(() => {
  //   console.log(user)
  // })

  return (
    <>
      <Desktop>
        <Grid item>
          {/* <TempLayoutDiv width="36rem" height="50rem"></TempLayoutDiv> */}
          <Stack sx={{ width: '24rem' }}>
            <BackgroundCard
              width="100%"
              height="100%"
              sx={{ height: '31.6rem', borderRadius: '10px' }}
            >
              <CardHeader
                sx={{ py: '0rem', pr: '0.2rem', pl: '1.2rem' }}
                avatar={
                  bg.own ? (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          sx={{ width: '2rem', height: '2rem' }}
                          src={getS3ImageSrc(user.photo)}
                        />
                        <CenterAlignBox
                          sx={{
                            ml: '0.8rem',
                            minWidth: '5.4rem',
                            width: 'auto',
                            px: '0.6rem',
                            height: '2.4rem',
                            borderRadius: '30px',
                            border: `1.6px solid ${theme.palette.draph.blue}`,
                            color: theme.palette.draph.blue,
                            fontWeight: 700,
                            fontSize: user.is_admin
                              ? categortForAdmin.length < 4
                                ? '1.3rem'
                                : '1rem'
                              : '1.3rem',
                            alignItems: 'center',
                            lineHeight: 'initial',
                          }}
                        >
                          {user.is_admin
                            ? categortForAdmin
                            : bg.original_bg_own
                            ? t(`artwork_category.${bg.category}`)
                            : bg.original_bg_id
                            ? t('artwork_category.auto')
                            : t(`artwork_category.${bg.category}`)}
                          {/* {bg.original_bg_own
                            ? user.is_admin
                              ? bg.category
                                  .split(',')
                                  .map(key => PORTFOLIO_CATEGORY_DICT[key])
                                  .join(',')
                              : PORTFOLIO_CATEGORY_DICT[bg.category]
                            : bg.original_bg_id
                            ? user.is_admin
                              ? bg.category
                                  .split(',')
                                  .map(key => PORTFOLIO_CATEGORY_DICT[key])
                                  .join(',')
                              : '자동'
                            : PORTFOLIO_CATEGORY_DICT[bg.category]} */}
                        </CenterAlignBox>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{ width: '2rem', height: '2.4rem' }}
                        src={'/static/images/logo/logo_Avatar2.png'}
                      />
                      <CenterAlignBox
                        sx={{
                          ml: '0.8rem',
                          width: '5.4rem',
                          px: '1rem',
                          height: '2.4rem',
                          borderRadius: '30px',
                          border: `1.6px solid ${theme.palette.common.black}`,
                          fontWeight: 700,
                          fontSize: '1.3rem',
                          alignItems: 'center',
                        }}
                      >
                        {t('artwork_category.auto')}
                      </CenterAlignBox>
                    </Box>
                  )
                }
                action={
                  <CenterAlignBox>
                    <IconButton
                      size="large"
                      onClick={handleStar(bg)}
                      sx={{ p: 0, width: '2.5rem', height: '2.5rem', mr: '0.4rem' }}
                    >
                      {!bg.liked ? (
                        <StarOutlinedIcon
                          sx={{
                            width: '2rem',
                            height: '2rem',
                            fill: 'none',
                            stroke: theme.palette.common.black,
                            strokeWidth: '1.8',
                          }}
                        />
                      ) : (
                        <StarFilledIcon sx={{ width: '2rem', height: '2rem', fill: 'none' }} />
                      )}
                    </IconButton>
                    <IconButton
                      size="large"
                      onClick={handlePin(bg)}
                      sx={{ p: 0, width: '2.5rem', height: '2.5rem', mr: '0.4rem' }}
                    >
                      {!bg.pinned ? (
                        <AiOutlinePushpin
                          style={{ width: '2.2rem', height: '2.2rem' }}
                          color={theme.palette.common.black}
                        />
                      ) : (
                        <AiFillPushpin
                          style={{ width: '2.2rem', height: '2.2rem' }}
                          color={theme.palette.draph.blue}
                        />
                      )}
                    </IconButton>

                    <IconButton
                      size="large"
                      onClick={bg.own ? deleteBg(bg) : handlePinStar(bg)}
                      sx={{ p: 0, width: '2.5rem', height: '2.5rem', mr: '0.4rem' }}
                    >
                      <CloseIcon style={{ width: '1.5rem', height: '1.5rem' }} />
                    </IconButton>
                  </CenterAlignBox>
                }
              />
              <CardMedia
                component="div"
                sx={{ height: '18rem', width: '24rem' }}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
              >
                <CenterAlignStack>
                  <img
                    src={getS3ImageSrc(bg.s3_url)}
                    style={{
                      height: '18rem',
                      width: '24rem',
                      opacity: hover ? 0.8 : 1,
                      cursor: 'pointer',
                      objectFit: 'cover',
                    }}
                    onClick={() => {
                      setBackgroundDialog(prev => ({ ...prev, open: true, idx }))
                    }}
                  />
                </CenterAlignStack>
              </CardMedia>

              {/* <IconButton
            size="large"
            sx={{ ...imageBoxIconStyle, right: '1rem', top: '28rem', opacity: 1 }}
            onClick={e => {
              setBackgroundDialog(prev => ({ ...prev, open: true, idx }))
            }}
          >
            <SearchIcon />
          </IconButton> */}

              <CardContent sx={{ p: '0.8rem 1.2rem 1rem 1.2rem' }}>
                <Box
                  width="100%"
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {bg.own ? (
                      nameEditMode ? (
                        <BgOntlinedInput
                          sx={{
                            width: '25.5rem',
                            height: '3.1rem',
                          }}
                          onChange={onChangeName}
                          onKeyPress={handleEditNameKeyEvent(bg)}
                          value={editNameText}
                          type="text"
                          inputProps={{ maxLength: 20 }}
                          autoFocus
                        />
                      ) : (
                        <Typography
                          noWrap
                          sx={{ width: 'min-content', fontSize: '1.3rem', fontWeight: 800 }}
                        >
                          {bg.name}
                        </Typography>
                      )
                    ) : (
                      <Typography
                        noWrap
                        sx={{ width: 'min-content', fontSize: '1.3rem', fontWeight: 800 }}
                      >
                        DRAPH
                      </Typography>
                    )}
                  </Box>

                  {bg.own &&
                    (nameEditMode ? (
                      <IconButton onClick={saveBackgroundName(bg)} sx={{ p: 0 }}>
                        <AiOutlineSave />
                      </IconButton>
                    ) : (
                      <IconButton onClick={editBackgroundName(bg)} sx={{ p: 0 }}>
                        <EditIcon />
                      </IconButton>
                    ))}
                </Box>
                <Box width="100%" lineHeight={tags.join(',').length < 40 ? 'unset' : '1'}>
                  {tags.map((t, idx) => (
                    <span key={idx} style={{ fontSize: '1.1rem', lineHeight: '1' }}>
                      #{t}{' '}
                    </span>
                  ))}
                </Box>
              </CardContent>
            </BackgroundCard>
          </Stack>
        </Grid>
      </Desktop>

      <Mobile>
        <Grid item>
          {/* <TempLayoutDiv width="36rem" height="50rem"></TempLayoutDiv> */}
          <Stack sx={{ width: '15.2rem' }}>
            <BackgroundCard
              width="100%"
              height="100%"
              sx={{ height: '24rem', borderRadius: '10px' }}
            >
              <CardHeader
                sx={{ px: '0.7rem', py: '0.7rem', height: '2.7rem' }}
                avatar={
                  bg.own ? (
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          sx={{ width: '1.6rem', height: '1.6rem' }}
                          src={getS3ImageSrc(user.photo)}
                        />
                        <CenterAlignBox
                          sx={{
                            ml: '0.5rem',
                            width: categortForAdmin.length < 4 ? '4.4rem' : '6.4rem',
                            height: '1.8rem',
                            borderRadius: '30px',
                            border: `1px solid ${theme.palette.draph.blue}`,
                            color: theme.palette.draph.blue,
                            fontWeight: 700,
                            fontSize: '1rem',
                            alignItems: 'center',
                          }}
                        >
                          {user.is_admin
                            ? categortForAdmin
                            : bg.original_bg_own
                            ? PORTFOLIO_CATEGORY_DICT[bg.category]
                            : bg.original_bg_id
                            ? '자동'
                            : PORTFOLIO_CATEGORY_DICT[bg.category]}
                          {/* {bg.original_bg_own
                            ? user.is_admin
                              ? bg.category
                                  .split(',')
                                  .map(key => PORTFOLIO_CATEGORY_DICT[key])
                                  .join(',')
                              : PORTFOLIO_CATEGORY_DICT[bg.category]
                            : bg.original_bg_id
                            ? user.is_admin
                              ? bg.category
                                  .split(',')
                                  .map(key => PORTFOLIO_CATEGORY_DICT[key])
                                  .join(',')
                              : '자동'
                            : PORTFOLIO_CATEGORY_DICT[bg.category]} */}
                        </CenterAlignBox>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{ width: '1.6rem', height: '1.6rem' }}
                        src={'/static/images/logo/logo_Avatar2.png'}
                      />
                      <CenterAlignBox
                        sx={{
                          ml: '0.5rem',
                          width: '4.4rem',
                          height: '1.8rem',
                          borderRadius: '30px',
                          border: `1px solid ${theme.palette.common.black}`,
                          fontWeight: 700,
                          fontSize: '1rem',
                          alignItems: 'center',
                        }}
                      >
                        자동
                      </CenterAlignBox>
                    </Box>
                  )
                }
              />

              <CardMedia
                component="div"
                sx={{ height: '11.8rem', width: '15.2rem' }}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
              >
                <CenterAlignStack>
                  <img
                    src={getS3ImageSrc(bg.s3_url)}
                    style={{
                      height: '11.8rem',
                      width: '15.2rem',
                      objectFit: 'cover',
                      opacity: hover ? 0.8 : 1,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setBackgroundDialog(prev => ({ ...prev, open: true, idx }))
                    }}
                  />
                </CenterAlignStack>
              </CardMedia>

              {/* <IconButton
            size="large"
            sx={{ ...imageBoxIconStyle, right: '1rem', top: '28rem', opacity: 1 }}
            onClick={e => {
              setBackgroundDialog(prev => ({ ...prev, open: true, idx }))
            }}
          >
            <SearchIcon />
          </IconButton> */}

              <CardContent sx={{ px: '1.4rem', pt: '1.8rem', pb: '2.1rem' }}>
                <Box
                  sx={{
                    display: 'flex',
                    width: '90%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography
                    noWrap
                    sx={{ width: 'min-content', fontSize: '1.3rem', fontWeight: 800 }}
                  >
                    {bg.name}
                  </Typography>
                </Box>

                <Box
                  variant="body"
                  sx={{
                    display: 'flex',
                    width: '90%',
                    height: '2.7rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <span
                    style={{
                      fontSize: '1.1rem',
                      lineHeight: '1.3rem',
                      display: '-webkit-box',
                      WebkitLineClamp: '2',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    #{tags.join().replace(/,/g, ' #')}
                  </span>
                  {/* {tags.map((t, idx) => (
                    <span key={idx} style={{ fontSize: '1.1rem', lineHeight: '1.3rem' }}>
                      #{t}{' '}
                    </span>
                  ))} */}
                </Box>
              </CardContent>
            </BackgroundCard>
          </Stack>
        </Grid>
      </Mobile>
    </>
  )
}
