import { Box, Button, Container, TextField, Tabs, Tab, Typography, IconButton } from '@mui/material'
import { CenterAlignBox, CenterAlignStack } from 'components'
import { useEffect, useRef, useState } from 'react'
import { allowedTypes, getS3ImageSrc } from 'utils/common'
import { Stage, Layer, Rect, Transformer, Group, Circle, Text } from 'react-konva'
import Konva from 'konva'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { CloseIcon, EditIcon2, HappyIcon } from 'theme/icon'

const codeExamples = {
  javascript: `function greet() {
  console.log("Hello, world!");
}`,
  python: `def greet():
  print("Hello, world!")`,
  java: `public class Main {
  public static void main(String[] args) {
    System.out.println("Hello, world!");
  }
}`,
}

const CodeBlock = ({ code, language }) => {
  const copyToClipboard = text => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(
        () => {
          alert('Copied to clipboard!')
        },
        err => {
          console.error('Could not copy text: ', err)
        }
      )
    }
  }

  return (
    <Box position="relative">
      <IconButton
        color="primary"
        size="small"
        // style={{ position: 'absolute', right: 0, top: 0 }}
        onClick={() => copyToClipboard(code)}
      >
        복사
        <EditIcon2 />
      </IconButton>
      <SyntaxHighlighter language={language} style={dracula}>
        {code}
      </SyntaxHighlighter>
    </Box>
  )
}

export default function TestPage15() {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs value={value} onChange={handleChange} aria-label="code tabs">
        <Tab label="JavaScript" />
        <Tab label="Python" />
        <Tab label="Java" />
      </Tabs>
      {value === 0 && (
        <TabPanel value={value} index={0}>
          <CodeBlock code={codeExamples.javascript} language="javascript" />
        </TabPanel>
      )}
      {value === 1 && (
        <TabPanel value={value} index={1}>
          <CodeBlock code={codeExamples.python} language="python" />
        </TabPanel>
      )}
      {value === 2 && (
        <TabPanel value={value} index={2}>
          <CodeBlock code={codeExamples.java} language="java" />
        </TabPanel>
      )}
    </Box>
  )
}

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

// export default function TestPage15() {
//   const [text, setText] = useState('')
//   const [text1, setText1] = useState('')
//   const [url, setUrl] = useState(
//     getS3ImageSrc(
//       '1706082981463_먹지마/shadow/imggen_result_2_1706082997.2964783_1706083004.1695898.jpg'
//     )
//   )
//   const canvasRef = useRef(null)

//   const downloadImage = () => {
//     const canvas = canvasRef.current
//     console.log(canvas)
//     const dataUrl = canvas.toDataURL('image/jpeg')
//     const link = document.createElement('a')
//     link.href = dataUrl
//     link.download = 'image_with_text.jpg'
//     link.click()
//   }

//   useEffect(() => {
//     const canvas = canvasRef.current
//     const ctx = canvas.getContext('2d')
//     const img = new Image()
//     img.src = url
//     img.crossOrigin = 'Anonymous'
//     img.onload = () => {
//       canvas.width = img.width
//       canvas.height = img.height
//       ctx.drawImage(img, 0, 0)
//       ctx.font = 'bold 150px serif'
//       ctx.fillStyle = 'white' // 텍스트 색상 설정
//       ctx.fillText(text, 100, canvas.height - 250) // 텍스트 위치 설정
//       ctx.font = '500 70px Pretendard'
//       ctx.fillStyle = 'white' // 텍스트 색상 설정
//       ctx.fillText(text1, 100, canvas.height - 150) // 텍스트 위치 설정

//       const dataUrl = canvas.toDataURL('image/jpeg')
//       console.log(dataUrl)
//     }
//   }, [url, text, text1])

//   const imagePieces = [
//     {
//       id: 1,
//       path: '1706083315756_how_to_create_an_attractive_shopping_mall_03_생성/shadow/imggen_result_1_1706083325.007368_1706083328.4533513.jpg',
//     },
//     {
//       id: 2,
//       path: '1706083315756_how_to_create_an_attractive_shopping_mall_03_생성/shadow/imggen_result_2_1706083324.9738762_1706083329.9630358.jpg',
//     },
//     {
//       id: 3,
//       path: '1706083315756_how_to_create_an_attractive_shopping_mall_03_생성/shadow/imggen_result_0_1706083324.9302166_1706083333.7507691.jpg',
//     },
//     {
//       id: 4,
//       path: '1706082981463_먹지마/shadow/imggen_result_2_1706082997.2964783_1706083004.1695898.jpg',
//     },
//   ]

//   return (
//     <>
//       <Box sx={{ p: '3rem' }}>
//         <CenterAlignBox sx={{ gap: '1.5rem' }}>
//           <TextField
//             value={text}
//             onChange={e => {
//               setText(e.target.value)
//             }}
//           ></TextField>
//           <TextField
//             value={text1}
//             onChange={e => {
//               setText1(e.target.value)
//             }}
//           ></TextField>
//           <TextField
//             value={url}
//             onChange={e => {
//               setUrl(e.target.value)
//             }}
//           ></TextField>
//         </CenterAlignBox>

//         <CenterAlignBox>
//           <img src={url} style={{ width: '72.5rem' }} />
//           <canvas ref={canvasRef} style={{ width: '72.5rem', height: 'auto' }} />
//           {/* <img src={canvasRef?.current?.toDataURL('image/jpeg')} />
//           {url && (
//             <ImageWithTextOverlay
//               originalImageUrl={url}
//               text={text}
//               text1={text1}
//               canvasRef={canvasRef}
//             />
//           )} */}
//         </CenterAlignBox>

//         <button onClick={downloadImage}>이미지 다운로드</button>

//         <Box sx={{ width: '100%', height: '0.4rem', backgroundColor: 'rgba(23,56,266,0.3)' }}></Box>
//       </Box>

//       <Box>
//         <KonvaTest1 />
//         <KonvaTest2 />
//         <KonvaTest3 />
//         <MyCanvas />
//       </Box>
//     </>
//   )
// }

// function ImageWithTextOverlay({ originalImageUrl, text, text1, canvasRef }) {
//   useEffect(() => {
//     const canvas = canvasRef.current
//     const ctx = canvas.getContext('2d')
//     const img = new Image()
//     img.src = originalImageUrl
//     img.crossOrigin = 'Anonymous'
//     img.onload = () => {
//       canvas.width = img.width
//       canvas.height = img.height
//       ctx.drawImage(img, 0, 0)
//       ctx.font = 'bold 150px serif'
//       ctx.fillStyle = 'white' // 텍스트 색상 설정
//       ctx.fillText(text, 100, canvas.height - 250) // 텍스트 위치 설정
//       ctx.font = '500 70px Pretendard'
//       ctx.fillStyle = 'white' // 텍스트 색상 설정
//       ctx.fillText(text1, 100, canvas.height - 150) // 텍스트 위치 설정
//     }
//   }, [originalImageUrl, text, text1])

//   return (
//     <div>
//       <canvas ref={canvasRef} style={{ width: '50rem', height: 'auto' }} />
//     </div>
//   )
// }

// const KonvaTest1 = () => {
//   const rectRef = useRef(null)
//   const trRef = useRef(null)
//   const MAX_WIDTH = 200

//   useEffect(() => {
//     if (trRef.current && rectRef.current) {
//       console.log(trRef.current)
//       trRef.current.nodes([rectRef.current])
//     }
//   }, [])

//   const handleDragBound = (oldBoundBox, newBoundBox) => {
//     if (Math.abs(newBoundBox.width) > MAX_WIDTH) {
//       return oldBoundBox
//     }
//     return newBoundBox
//   }

//   return (
//     <Stage width={window.innerWidth} height={window.innerHeight}>
//       <Layer>
//         <Rect
//           x={160}
//           y={60}
//           width={100}
//           height={90}
//           fill="red"
//           name="rect"
//           stroke="black"
//           draggable
//           ref={rectRef}
//         />
//         <Transformer ref={trRef} boundBoxFunc={handleDragBound} />
//       </Layer>
//     </Stage>
//   )
// }

// const SelectableRect = ({ shapeProps, isSelected, onSelect, onChange }) => {
//   const shapeRef = useRef()
//   const trRef = useRef()

//   useEffect(() => {
//     if (isSelected) {
//       // we need to attach transformer manually
//       trRef.current.nodes([shapeRef.current])
//       trRef.current.getLayer().batchDraw()
//     }
//   }, [isSelected])

//   return (
//     <>
//       <Rect
//         onClick={onSelect}
//         onTap={onSelect}
//         ref={shapeRef}
//         {...shapeProps}
//         draggable
//         onDragEnd={e => {
//           onChange({
//             ...shapeProps,
//             x: e.target.x(),
//             y: e.target.y(),
//           })
//         }}
//         onTransformEnd={() => {
//           // transformer is changing scale of the node
//           // and NOT its width or height
//           // but in the store we have only width and height
//           // to match the data better we will reset scale on transform end
//           const node = shapeRef.current
//           const scaleX = node.scaleX()
//           const scaleY = node.scaleY()

//           // we will reset it back
//           node.scaleX(1)
//           node.scaleY(1)
//           onChange({
//             ...shapeProps,
//             x: node.x(),
//             y: node.y(),
//             // set minimal value
//             width: Math.max(5, node.width() * scaleX),
//             height: Math.max(node.height() * scaleY),
//           })
//         }}
//       />
//       {isSelected && (
//         <Transformer
//           ref={trRef}
//           boundBoxFunc={(oldBox, newBox) => {
//             // limit resize
//             if (newBox.width < 5 || newBox.height < 5) {
//               return oldBox
//             }
//             return newBox
//           }}
//         />
//       )}
//     </>
//   )
// }

// const KonvaTest2 = () => {
//   const [rectangles, setRectangles] = useState([
//     {
//       x: 50,
//       y: 50,
//       width: 100,
//       height: 50,
//       fill: 'blue',
//       id: 'rect1',
//     },
//     {
//       x: 200,
//       y: 100,
//       width: 80,
//       height: 120,
//       fill: 'red',
//       id: 'rect2',
//     },
//   ])
//   const [selectedId, selectShape] = useState(null)

//   const checkDeselect = e => {
//     // deselect when clicked on empty area
//     const clickedOnEmpty = e.target === e.target.getStage()
//     if (clickedOnEmpty) {
//       selectShape(null)
//     }
//   }

//   return (
//     <Stage
//       width={window.innerWidth}
//       height={window.innerHeight}
//       onMouseDown={checkDeselect}
//       onTouchStart={checkDeselect}
//     >
//       <Layer>
//         {rectangles.map((rect, i) => {
//           return (
//             <SelectableRect
//               key={i}
//               shapeProps={rect}
//               isSelected={rect.id === selectedId}
//               onSelect={() => {
//                 selectShape(rect.id)
//               }}
//               onChange={newAttrs => {
//                 const rects = rectangles.slice()
//                 rects[i] = newAttrs
//                 setRectangles(rects)
//               }}
//             />
//           )
//         })}
//       </Layer>
//     </Stage>
//   )
// }

// const MyCanvas = () => {
//   const stageRef = useRef(null)
//   const [selectionRectangle, setSelectionRectangle] = useState({
//     x: 0,
//     y: 0,
//     width: 0,
//     height: 0,
//     visible: false,
//   })
//   const [selecting, setSelecting] = useState(false)
//   const [selectedNodes, setSelectedNodes] = useState([])

//   const handleMouseDown = e => {
//     if (e.target === stageRef.current) {
//       e.evt.preventDefault()
//       const pointer = e.target.getStage().getPointerPosition()
//       setSelectionRectangle({
//         ...selectionRectangle,
//         x: pointer.x,
//         y: pointer.y,
//         width: 0,
//         height: 0,
//         visible: true,
//       })
//       setSelecting(true)
//     }
//   }

//   const handleMouseMove = e => {
//     if (!selecting) return
//     const pointer = e.target.getStage().getPointerPosition()
//     setSelectionRectangle({
//       ...selectionRectangle,
//       width: pointer.x - selectionRectangle.x,
//       height: pointer.y - selectionRectangle.y,
//     })
//   }

//   const handleMouseUp = e => {
//     setSelecting(false)
//     if (!selectionRectangle.visible) return
//     const stage = e.target.getStage()
//     const shapes = stage.find('.rect')
//     const box = {
//       x: selectionRectangle.x,
//       y: selectionRectangle.y,
//       width: selectionRectangle.width,
//       height: selectionRectangle.height,
//     }
//     const selected = shapes.filter(shape => Konva.Util.haveIntersection(box, shape.getClientRect()))
//     setSelectedNodes(selected)
//     setSelectionRectangle({
//       ...selectionRectangle,
//       visible: false,
//     })
//   }

//   const handleShapeClick = e => {
//     // if (selectionRectangle.visible) return
//     const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey
//     const isSelected = selectedNodes.indexOf(e.target) >= 0

//     if (!metaPressed && !isSelected) {
//       setSelectedNodes([e.target])
//     } else if (metaPressed && isSelected) {
//       setSelectedNodes(selectedNodes.filter(node => node !== e.target))
//     } else if (metaPressed && !isSelected) {
//       setSelectedNodes([...selectedNodes, e.target])
//     }
//   }

//   const handleTextTransform = index => newProps => {
//     const newTextNodes = [...selectedNodes]
//     newTextNodes[index] = { ...newTextNodes[index], ...newProps }
//     setSelectedNodes(newTextNodes)
//   }

//   const renderTextInputs = () => {
//     return selectedNodes.map((node, index) => {
//       const pos = node.absolutePosition()
//       return (
//         <input
//           key={index}
//           style={{
//             position: 'absolute',
//             left: pos.x,
//             top: pos.y,
//           }}
//           value={node.text()}
//           onChange={e => handleTextTransform(index)({ text: e.target.value })}
//         />
//       )
//     })
//   }

//   return (
//     <div style={{ position: 'relative' }}>
//       <Stage
//         ref={stageRef}
//         style={{ border: '2px solid blue', width: '50rem', height: '50rem' }}
//         width={500}
//         height={500}
//         onMouseDown={handleMouseDown}
//         onMouseMove={handleMouseMove}
//         onMouseUp={handleMouseUp}
//         onTouchStart={handleMouseDown}
//         onTouchMove={handleMouseMove}
//         onTouchEnd={handleMouseUp}
//       >
//         <Layer>
//           <Rect
//             x={60}
//             y={60}
//             width={100}
//             height={90}
//             fill="red"
//             name="rect"
//             draggable
//             onClick={handleShapeClick}
//             onDragStart={handleShapeClick}
//           />
//           <Rect
//             x={250}
//             y={100}
//             width={150}
//             height={90}
//             fill="green"
//             name="rect"
//             draggable
//             onClick={handleShapeClick}
//             onDragStart={handleShapeClick}
//           />
//           <Circle
//             x={250}
//             y={100}
//             width={150}
//             height={90}
//             fill="yellow"
//             name="rect"
//             draggable
//             onClick={handleShapeClick}
//             onDragStart={handleShapeClick}
//           />
//           {/* <Text
//             x={200}
//             y={200}
//             text="Sample Text"
//             fill="black"
//             draggable
//             onClick={handleShapeClick}
//             onDragStart={handleShapeClick}
//             name="text"
//           /> */}
//           <Transformer nodes={selectedNodes} />
//           <Rect {...selectionRectangle} fill="rgba(0,0,255,0.5)" />
//         </Layer>
//       </Stage>
//       {/* {renderTextInputs()} */}
//     </div>
//   )
// }

// const KonvaTest3 = () => {
//   const [text, setText] = useState('Edit me')
//   const [isSelected, setSelected] = useState(false)
//   const [editing, setEditing] = useState(false)
//   const textRef = useRef()
//   const inputRef = useRef()

//   const handleTextChange = e => {
//     setText(e.target.value)
//   }

//   const handleDblClick = () => {
//     setSelected(true)
//     setEditing(true)
//   }

//   const handleBlur = () => {
//     setEditing(false)
//     setSelected(false)
//   }

//   const handleKeyDown = e => {
//     if (e.key === 'Enter') {
//       setEditing(false)
//       setSelected(false)
//     }
//   }

//   return (
//     <Stage width={window.innerWidth} height={window.innerHeight}>
//       <Layer>
//         {editing ? (
//           <input
//             ref={inputRef}
//             value={text}
//             onChange={handleTextChange}
//             onBlur={handleBlur}
//             onKeyDown={handleKeyDown}
//             style={{
//               position: 'absolute',
//               top: textRef.current.getClientRect().top,
//               left: textRef.current.getClientRect().left,
//               width: textRef.current.width(),
//               height: textRef.current.height(),
//               padding: 0,
//               margin: 0,
//               border: 'none',
//               fontSize: textRef.current.fontSize(),
//               fontFamily: textRef.current.fontFamily(),
//               lineHeight: textRef.current.lineHeight(),
//             }}
//             autoFocus
//           />
//         ) : (
//           <Text
//             text={text}
//             draggable
//             ref={textRef}
//             onDblClick={handleDblClick}
//             onClick={() => setSelected(true)}
//           />
//         )}
//         {isSelected && (
//           <Transformer
//             nodeRef={textRef}
//             rotateEnabled={false}
//             keepRatio={false}
//             boundBoxFunc={(oldBox, newBox) => {
//               if (newBox.width < 10 || newBox.height < 10) {
//                 return oldBox
//               }
//               return newBox
//             }}
//           />
//         )}
//       </Layer>
//     </Stage>
//   )
// }
