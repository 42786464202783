import { Box, Divider, useTheme } from '@mui/material'
import { portfolioTypeAtom } from 'atoms'
import { CenterAlignBox } from 'components/CenterAlignContainer'
import { GUIDE_IMAGES, GUIDE_VIDEOS } from 'config'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { fontSize } from 'pages/test/Toolbar'

export const GuideComponent = ({ videoSrc }) => {
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [isClick, setIsClick] = useState(false)

  const type = portfolioType || 'default'

  const images = GUIDE_IMAGES[type]
  const videos = videoSrc || GUIDE_VIDEOS[type]

  const containerVariants = {
    initial: { width: '48rem' },
    expanded: { width: '62.4rem' },
  }

  const imageVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  }

  return (
    <motion.div
      initial="initial"
      animate={isClick ? 'expanded' : 'initial'}
      variants={containerVariants}
      transition={{ duration: 0.2 }}
      style={{
        padding: '4.4rem 4rem',
        backgroundColor: 'white',
        boxShadow: '4px 4px 20px 0px #0000001A',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <video src={videos[i18n.language]} loop autoPlay style={{ height: '18.4rem' }} muted>
        Your browser does not support the video tag.
      </video>

      {/* <Box sx={{ position: 'relative' }}>
        {' '}
        <img
          src={images.original}
          style={{
            width: '12rem',
            height: '12rem',
            objectFit: 'cover',
            borderRadius: '10px',
          }}
          onClick={() => {
            setIsClick(true)
          }}
        />
        <CenterAlignBox
          sx={{
            position: 'absolute',
            bottom: 0,
            background: theme.palette.draph.newBlue,
            color: theme.palette.common.white,
            height: '2.4rem',
            borderRadius: '0 0 10px 10px',
            alignItems: 'center',
            width: '100%',
            fontSize: '1.2rem',
            fontWeight: 600,
          }}
        >
          {t('upload_header.click')}
        </CenterAlignBox>
      </Box>

      <AnimatePresence>
        {isClick && (
          <>
            <Divider orientation="vertical" sx={{ mx: '2rem' }} flexItem />
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                hidden: {},
                visible: {
                  transition: {
                    staggerChildren: 0.3,
                    delayChildren: 0.2,
                  },
                },
              }}
              style={{ display: 'flex', gap: '1.2rem' }}
            >
              {images.draphed.map((i, index) => (
                <motion.img
                  key={index}
                  src={i}
                  style={{
                    width: '12rem',
                    height: '12rem',
                    objectFit: 'cover',
                    borderRadius: '10px',
                  }}
                  variants={imageVariants}
                />
              ))}
            </motion.div>
          </>
        )}
      </AnimatePresence> */}
    </motion.div>
  )
}
