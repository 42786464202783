import { configData } from 'config'
import { Box, Typography, Button, Stack, Select, MenuItem, Card } from '@mui/material'
import {
  ScrollToTop,
  ArtworkList,
  ArtworkFilters,
  UploadWrapper,
  CenterAlignStack,
  DragDropFullSize,
  UploadImageDialog,
  triggerGA4UploadEvent,
  EmptyArtworkList,
  GoodBadExample,
} from 'components'
import { BeforeAfterArrow, SettingIcon } from 'theme/icon'
import { styled, useTheme } from '@mui/material/styles'

import { useEffect, useState } from 'react'

import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  portfolioDetailAtom,
  portfolioArtworkAtom,
  userAtom,
  portfolioConfigAtom,
  artworkViewConfigAtom,
  userLikedBgAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  tutorialAtom,
  endpointAtom,
  artworkListsIsFoldedAtom,
  defaultPortfolioAtom,
  portfolioAtom,
  autoUploadAtom,
  dragDropOpenAtom,
  uploadDialogOpenAtom,
  portfolioTypeAtom,
  uploadFilesAtom,
} from 'atoms'
import { apis } from 'apis'
import { BarLoader as Loader, PuffLoader } from 'react-spinners'
import {
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
} from 'selector'
import useConfirm from 'hooks/useConfirm'
import _, { debounce } from 'lodash'
import axios from 'axios'
import { getAccessToken } from 'utils/cookie'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { APPBAR_DESKTOP, APPBAR_MOBILE } from 'layouts/main/MainAppBar'
import MainFooter from 'layouts/main/MainFooter'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import * as config from 'config'
import { useMotionValueEvent, useScroll, useTransform } from 'framer-motion'

import { PAGE_HEADER_HEIGHT, ScrollHandler } from './PortfolioDetail'
import { useTranslation } from 'react-i18next'
import { EmptyPageHeader, PageHeaderLayout } from './PortfolioUpload'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'
import { usePortfolio } from 'hooks/usePortfolio'

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    // alignItems: 'center',
  },
}))

const PageHeaderStyle = styled(Box)(({ theme }) => ({
  top: 0,
  minHeight: 'auto',
  justifyContent: 'center',
  alignItems: 'end',

  bordercolor: theme.palette.common.black,
  background: theme.palette.common.white,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '5.4rem',
    minHeight: PAGE_HEADER_HEIGHT,

    paddingBottom: '3.8rem',
    display: 'flex',
    flexDirection: 'row',
  },
}))

export default function Fame() {
  const tutorial = useRecoilValue(tutorialAtom)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [uploadFiles, setUploadFiles] = useRecoilState(uploadFilesAtom)

  const token = getAccessToken()
  if (!token)
    return (
      <RootStyle>
        <>
          <EmptyPageHeader />
          <Guide />
          <EmptyArtworkList />
        </>
      </RootStyle>
    )

  return (
    <>
      <RootStyle
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()
          setDragDropOpen(true)
        }}
      >
        <>
          <UploadWrapper portfolioId={defaultPortfolio.id}>
            <PageHeader />
          </UploadWrapper>
          <Guide />

          <ArtworkList upload={true} hideGroupHeader={true} />
        </>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />
      {/* {dragDropOpen && uploadFiles.length === 0 && <DragDropFullSize uploadImgLimit={1} />} */}
    </>
  )
}

function PageHeader() {
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()
  const [user, setUser] = useRecoilState(userAtom)
  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)
  const { t } = useTranslation()

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)

  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetPortfolioConfig = useResetRecoilState(portfolioConfigAtom)

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [uploading, setUploading] = useState(false)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [openEndpointDialog, setOpenEndpointDialog] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')

  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)

  const isMobile = useMobileMediaQuery()
  const queryParams = new URLSearchParams(location.search)

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, downloaded: downloadedFilter },
    })
  }, [downloadedFilter])

  useEffect(() => {
    setViewConfig({
      ...viewConfig,
      filters: { ...viewConfig.filters, name: textFilter },
    })
  }, [textFilter])

  const handleUpload = async (files, eventName = 'famous_person_upload') => {
    const uploadFiles = await prepareUpload(files, config.PORTFOLIO_TYPE_FAME)
    if (!uploadFiles) {
      setUploading(false)
      return
    }
    setUploading(true)
    setUploadButtonDiasbled(true)

    setUploadOpen(false)

    const formData = new FormData()

    formData.append('image', files[0])
    formData.append('user_id', user.id)
    formData.append('portfolio_id', portfolioDetail.id)
    formData.append('username', user.username)
    formData.append('gen_type', 'initial')

    formData.append('artwork_id', '')
    formData.append('prompt', '')

    // ----- GA4 event -----
    triggerGA4UploadEvent({}, user, uploadFiles.length, eventName)
    // ---------------------

    apis.appfront.getFunCeleb(formData).then(response => {
      checkUserCredit()
      setUploadOpen(false)
      setUploadButtonDiasbled(false)
      setUploading(false)
      refreshArtworks()
    })
  }

  return (
    <PageHeaderLayout
      headerProps={{
        sx: {
          opacity: isFolded.value ? 0 : 1,
          transition: 'all 0.2s ease',
        },
        zIndex: isFolded.value
          ? -1
          : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
          ? 'auto'
          : null,
      }}
      uploadButtonProps={{
        disabled: uploading || !isOwner || uploadButtonDisabled,
        onClick: () => {
          tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_1' }))
          setUploadOpen(true)
        },
      }}
      uploading={uploading}
      uploadDialog={
        uploadOpen && (
          <UploadImageDialog
            uploadImage={handleUpload}
            swipeableDrawerSx={{
              '& .MuiDrawer-paper': {
                top: 'auto',
              },
            }}
            title={
              <Typography fontWeight={700} sx={{ fontSize: { lg: '2.2rem', xs: '1.6rem' } }}>
                {t('fun_fame.upload_image')}
              </Typography>
            }
            notice={
              <CenterAlignStack
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: { lg: '1.5rem', xs: '1.2rem' },
                    fontWeight: 400,
                    color: '#595959',
                  },
                }}
              >
                <Desktop>
                  <Typography>{t('fun_fame.upload_notice_1')}</Typography>
                  <Typography>{t('fun_fame.upload_notice_2')}</Typography>
                </Desktop>
                <Mobile>
                  <Typography>{t('fun_fame.upload_notice_mobile_1')}</Typography>
                  <Typography>{t('fun_fame.upload_notice_mobile_2')}</Typography>
                </Mobile>
              </CenterAlignStack>
            }
            multiple={false}
            uploadImglimit={1}
            example={<UploadExample />}
            uploadButtonTitle={t('button.fame_upload')}
          />
        )
      }
      artworkFilter={
        <ArtworkFilters
          selectValue={downloadedFilter}
          setSelectValue={setDownloadedFilter}
          textValue={textFilter}
          setTextValue={setTextFilter}
        />
      }
    />
  )

  // return (
  //   <>
  //     <CenterAlignStack>
  //       <PageHeaderStyle
  //         sx={{
  //           opacity: isFolded.value ? 0 : 1,
  //           transition: 'all 0.2s ease',
  //         }}
  //         zIndex={
  //           isFolded.value
  //             ? -1
  //             : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
  //             ? 'auto'
  //             : null
  //         }
  //       >
  //         <Box
  //           sx={{
  //             display: 'flex',
  //             justifyContent: { lg: 'flex-start', md: 'center', sm: 'center', xs: 'center' },
  //             alignItems: 'center',
  //             width: { lg: '35rem', xs: '100%' },
  //             px: 1,
  //           }}
  //         >
  //           <>
  //             <Box
  //               sx={{
  //                 display: 'flex',
  //                 width: { lg: '28rem', xs: '25.5rem' },
  //                 overflow: 'hidden',
  //                 textOverflow: 'ellipsis',
  //                 alignItems: 'center',
  //                 minHeight: { lg: '3.8rem', xs: 0 },
  //               }}
  //             ></Box>
  //           </>
  //         </Box>

  //         <Box
  //           sx={{
  //             width: { lg: '50rem', xs: '100%' },
  //             mt: { lg: 0, xs: '2.5rem' },
  //             mb: { lg: 0, xs: '2.2rem' },
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             position: 'relative',
  //           }}
  //         >
  //           <>
  //             <Tutorial step="14_4">
  //               <Tutorial step={14}>
  //                 <Button
  //                   variant="contained"
  //                   component="span"
  //                   sx={{
  //                     width: { lg: '26rem', xs: '18rem' },
  //                     height: { lg: '6rem', xs: '4.2rem' },
  //                     fontSize: { lg: '2rem', xs: '1.5rem' },
  //                     fontWeight: 800,
  //                     borderWidth: { lg: '0.2rem', xs: '0.1rem' },
  //                   }}
  //                   disabled={uploading || !isOwner || uploadButtonDisabled}
  //                   onClick={() => {
  //                     tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_1' }))
  //                     setUploadOpen(true)
  //                   }}
  //                 >
  //                   {!uploading && t('button.upload_image')}
  //                   {uploading && <Loader />}
  //                 </Button>
  //               </Tutorial>
  //             </Tutorial>
  //             {uploadOpen && (
  //               <UploadImageDialog
  //                 uploadImage={handleUpload}
  //                 swipeableDrawerSx={{
  //                   '& .MuiDrawer-paper': {
  //                     top: 'auto',
  //                   },
  //                 }}
  //                 title={
  //                   <Typography fontWeight={700} sx={{ fontSize: { lg: '2.2rem', xs: '1.6rem' } }}>
  //                     {t('fun_fame.upload_image')}
  //                   </Typography>
  //                 }
  //                 notice={
  //                   <CenterAlignStack
  //                     sx={{
  //                       '& .MuiTypography-root': {
  //                         fontSize: { lg: '1.5rem', xs: '1.2rem' },
  //                         fontWeight: 400,
  //                         color: '#595959',
  //                       },
  //                     }}
  //                   >
  //                     <Desktop>
  //                       <Typography>{t('fun_fame.upload_notice_1')}</Typography>
  //                       <Typography>{t('fun_fame.upload_notice_2')}</Typography>
  //                     </Desktop>
  //                     <Mobile>
  //                       <Typography>{t('fun_fame.upload_notice_mobile_1')}</Typography>
  //                       <Typography>{t('fun_fame.upload_notice_mobile_2')}</Typography>
  //                     </Mobile>
  //                   </CenterAlignStack>
  //                 }
  //                 multiple={false}
  //                 uploadImglimit={1}
  //                 example={UploadExample}
  //               />
  //             )}
  //           </>
  //         </Box>
  //         <Desktop>
  //           <ArtworkFilters
  //             selectValue={downloadedFilter}
  //             setSelectValue={setDownloadedFilter}
  //             textValue={textFilter}
  //             setTextValue={setTextFilter}
  //           />
  //         </Desktop>
  //       </PageHeaderStyle>
  //     </CenterAlignStack>
  //     {/* </Desktop> */}

  //     <Stack
  //       direction="row"
  //       sx={{ alignItems: 'center', justifyContent: 'center', mt: '1rem', mb: '4rem' }}
  //     >
  //       <ExampleImage />
  //       <ExampleText />
  //     </Stack>
  //   </>
  // )
}

const ExampleImage = () => (
  <>
    <Desktop>
      <Stack direction="row" sx={{ alignItems: 'center' }} spacing="1.5rem">
        <img src="/static/images/fun_fame_before.png" style={{ width: '10rem' }} />
        <BeforeAfterArrow />
        <img src="/static/images/fun_fame_after.png" style={{ width: '20rem' }} />
      </Stack>
    </Desktop>
    <Mobile>
      <img src="/static/images/fun_fame_mobile.png" style={{ width: '11.3rem' }} />
    </Mobile>
  </>
)

const ExampleText = ({ sx }) => {
  const { t } = useTranslation()
  return (
    <Card
      sx={{
        borderRadius: '1rem',
        ml: { lg: '4rem !important', xs: '1.7rem' },
        px: { lg: '2.5rem', xs: '1.5rem' },
        '& .MuiTypography-root': {
          fontSize: { lg: '2rem', xs: '1.2rem' },
          fontWeight: 500,
          textAlign: { xs: 'center' },
          lineHeight: { lg: 'unset', xs: '1.5rem' },
        },
        boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.2)',
        height: { lg: '12rem', xs: '7.4rem' },
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Stack sx={{ height: '100%', justifyContent: 'center', alignItems: 'start' }}>
        <Typography>{t('fun_fame.example_text_1')}</Typography>
        <Typography>{t('fun_fame.example_text_2')}</Typography>
        <Desktop>
          <Typography sx={{ '& span': { fontWeight: 700 } }}>
            <span>{t('fun_fame.example_text_3_a')}</span>
            {t('fun_fame.example_text_3_b')}
          </Typography>
        </Desktop>
        <Mobile>
          <Typography sx={{ '& span': { fontWeight: 700 } }}>
            <span>{t('fun_fame.example_text_3_mobile')}</span>
          </Typography>
        </Mobile>
      </Stack>
    </Card>
  )
}

const Guide = () => (
  <Stack
    direction="row"
    sx={{ alignItems: 'center', justifyContent: 'center', mt: '1rem', mb: '4rem' }}
  >
    <ExampleImage />
    <ExampleText />
  </Stack>
)

// -----------------------

export function UploadExample() {
  const { t } = useTranslation()
  return (
    <CenterAlignStack sx={{ background: '#F8F8F8', pt: '2.2rem', pb: '2rem', width: '100%' }}>
      <Typography
        sx={{
          fontSize: { lg: '1.6rem', xs: '1.4rem' },
          fontWeight: 700,
          '& span': { color: theme => theme.palette.draph.blue },
        }}
      >
        <span>{t('fun_fame.upload_example_title_1_a')}</span>
        {t('fun_fame.upload_example_title_1_b')}
      </Typography>
      <GoodBadExample
        good="/static/images/fun_fame_example_good.png"
        bad="/static/images/fun_fame_example_bad.png"
      />

      {/* <Stack
        direction="row"
        spacing={1}
        sx={{
          py: '1.4rem',
          '& .shadow-rect': {
            position: 'absolute',
            width: '100%',
            height: '3.2rem',
            background: 'rgba(0, 0, 0, 0.6)',
            bottom: 0,
            borderRadius: '0 0 1rem 1rem',
            alignItems: 'center',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: { lg: '16rem', xs: '15.2rem' },
          }}
        >
          <img src="/static/images/fun_fame_example_good.png" />
          <CenterAlignBox className="shadow-rect">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                stroke="#00E8B9"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </CenterAlignBox>
        </Box>
        <Box sx={{ position: 'relative' }}>
          <img src="/static/images/fun_fame_example_bad.png" style={{ width: '16rem' }} />
          <CenterAlignBox className="shadow-rect">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 2L2 18"
                stroke="#FFA3A3"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
              <path
                d="M2 2L18 18"
                stroke="#FFA3A3"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </CenterAlignBox>
        </Box>
      </Stack> */}

      <Box
        sx={{
          px: '2.5rem',
          '& .MuiTypography-root': {
            // fontSize: { lg: '1.4rem', xs: '1.2rem' },
            '& span': {
              fontWeight: 700,
              color: theme => theme.palette.draph.blue,
            },
          },

          '& li': {
            fontSize: { lg: '1.4rem', xs: '1.2rem' },
            listStyleType: 'none',
            textIndent: { lg: '', xs: '-1rem' },
            '&::before': {
              content: '"•"',
              marginRight: '0.3rem',
            },
          },
        }}
      >
        <ul>
          <Typography>
            <li>
              <span>{t('fun_fame.upload_example_comment_1_a')}</span>
              {t('fun_fame.upload_example_comment_1_b')}
            </li>
          </Typography>
          <Typography>
            <li>
              {t('fun_fame.upload_example_comment_2_a')}
              <span>{t('fun_fame.upload_example_comment_2_b')}</span>
              {t('fun_fame.upload_example_comment_2_c')}
            </li>
          </Typography>
        </ul>
      </Box>
    </CenterAlignStack>
  )
}
