import { Container, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const serviceH3Style = {
  fontSize: 20,
  marginTop: '32px',
  marginBottom: '15px',
  marginLeft: '14px',
  fontWeight: 600,
}
const serviceH4Style = {
  marginBottom: '8px',
  marginTop: '12px',
  fontSize: 17.5,
}
const servicePStyle = { paddingLeft: '10px', fontSize: 15.5 }
const serviceOlStyle = { listStyleType: 'decimal', marginLeft: '3rem' }

const privacyH3Style = {
  marginTop: '32px',
  marginBottom: '5px',
  fontWeight: 600,
}
const privacyLiStyle1 = { listStyleType: 'decimal', paddingLeft: '20px' }
const privacyLiStyle2 = { listStyleType: 'decimal', paddingLeft: '18px' }

const nestedLiStyle = { marginLeft: '1.5rem' }
export default function Advertisement() {
  const { t, i18n } = useTranslation()
  return (
    <Container sx={{ mt: '3rem', p: '15px !important' }}>
      <h3
        style={{
          textAlign: 'center',
          marginBottom: '32px',
          fontWeight: 600,
        }}
      >
        {t('agree.agreeAdvertisement')}
      </h3>

      <p>{t('agree.ads_comment_1')}</p>
      {/* 1조 */}
      <br />
      <p>{t('agree.ads_comment_2')}</p>

      <ol style={{ listStyleType: 'disc', paddingLeft: '35px' }}>
        <li>{t('agree.ads_notice')}</li>
      </ol>
    </Container>
  )
}
