import { testAtom } from './TestPage'
import { RecoilDebugButton } from '../../components'
import { useRecoilState } from 'recoil'
import { useEffect, useState, useRef } from 'react'

export default function B() {
  const [count, setCount] = useRecoilState(testAtom)

  useEffect(() => {
    console.log('showing B ')

    return () => {
      console.log('byebye B')
    }
  }, [])

  return (
    <>
      <h1>BBBBBBB Page</h1>
      {count}
      <button
        onClick={() => {
          setCount(prev => prev + 1)
        }}
      >
        {' '}
        increase
      </button>
      <br />
      <br />
      <hr />
      {/* <SSETest id="1679299986008_IMG_2370123" /> */}
      {/* <SSETest id="1679300105020_IMG_2367" />
      <SSETest id="1679300103906_IMG_2366" />
      <SSETest id="1679300102615_IMG_2377" /> */}

      <RecoilDebugButton />
    </>
  )
}

function SSETest({ id }) {
  const [data, setData] = useState('')
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('No message')
  const [log, setLog] = useState('')

  const [es, setES] = useState(null)

  useEffect(() => {
    subscribe()
  }, [])

  useEffect(() => {
    if (es) {
      setStatus('시작중..')
    } else {
      setStatus('끊김')
    }
  }, [es])

  const subscribe = () => {
    const sse = new EventSource(
      // 'http://127.0.0.1:8000/db/user/stream_test?user_id=' + 'sub1'
      'https://api-dev.draph.art/stream/?artwork_id=' + id
    )
    setES(sse)

    sse.onopen = e => {
      const l = 'connection opened'
      console.log(l)
      setStatus(l)
    }

    sse.onmessage = e => {
      console.log('get message')
      setMessage('메세지 수신!!')

      console.log(e.data)
      setData(e)

      setLog(prev => `${new Date().toISOString()}  ${e.data} \n` + prev)

      console.log(sse)
      // sse.close()
      // setES(null)
    }

    sse.onerror = e => {
      console.log('error!!', e)
      sse.close()
      console.log('eventsource closed')

      setES(null)
      setStatus('ERROR')
    }
  }

  const unsubscribe = () => {
    console.log(es)
    es.close()
    setES(null)
    console.log('eventsource closed')
    setStatus('끊김')
  }

  return (
    <>
      <h3>{id}</h3>
      <button onClick={subscribe}>구독</button>
      <button onClick={unsubscribe}>구독취소</button>
      <ul>
        <li>상태: {status}</li>
        <li>메세지 수신여부: {message}</li>
        <li>
          데이터:
          <textarea value={log} disabled style={{ width: '700px', height: '500px' }}></textarea>
          <br />
        </li>
      </ul>
      <br />

      {/* <textarea disabled value={log}></textarea> */}

      <br />
    </>
  )
}

function SSEExample() {
  useEffect(() => {
    subscribe()
  }, [])

  const subscribe = () => {
    // 업로드 api 호출 후 리턴받은 artworkId 를 붙여주세요
    const artworkId = 'temp_artwork_id'
    const sse = new EventSource('https://api-dev.draph.art/stream/?artwork_id=' + artworkId)

    sse.onopen = e => {
      // 커넥션이 맺어졌을 때 발생하는 이벤트입니다
    }

    sse.onmessage = e => {
      // e.data 에 데이터가 들어있습니다
      // 데이터를 꺼내 사용하시고 스트리밍은 close 해주세요
      console.log(e.data)
      sse.close()
    }

    sse.onerror = e => {
      console.log('error', e)
      sse.close()
    }
  }

  return <></>
}
