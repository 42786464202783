import { atom, RecoilRoot, useRecoilState } from 'recoil'
import { RecoilDebugButton } from '../../components'
import A from './A.js'
import B from './B.js'
import C from './C.js'
import { useNavigate } from 'react-router-dom'

import React, { useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
// import 'swiper/css'
// import 'swiper/css/scrollbar'

// import './test.css'

// import required modules
import { Scrollbar } from 'swiper'

export const testAtom = atom({
  key: 'testAtom',
  default: 0,
})

export const t2 = atom({
  key: 't2',
  default: 0,
})

export default function TestPage() {
  const navigate = useNavigate()

  return (
    <div style={{ paddingLeft: 60 }}>
      <h1>Test Page</h1>
      <h1>Hello World</h1>
      <RecoilDebugButton />

      <A />
      <A />

      <hr />
      <h5>Recoli Root here</h5>
      <RecoilRoot>
        <B />
      </RecoilRoot>

      <hr />

      {/* <C /> */}

      <button
        onClick={() => {
          navigate('/test/2')
        }}
      >
        navigate
      </button>

      {/* <SwiperTest /> */}
    </div>
  )
}

// function SwiperTest() {
//   return (
//     <>
//       <Swiper
//         scrollbar={{
//           hide: false,
//         }}
//         modules={[Scrollbar]}
//         className="mySwiper"
//       >
//         <SwiperSlide>Slide 1</SwiperSlide>
//         <SwiperSlide>Slide 2</SwiperSlide>
//         <SwiperSlide>Slide 3</SwiperSlide>
//         <SwiperSlide>Slide 4</SwiperSlide>
//         <SwiperSlide>Slide 5</SwiperSlide>
//         <SwiperSlide>Slide 6</SwiperSlide>
//         <SwiperSlide>Slide 7</SwiperSlide>
//         <SwiperSlide>Slide 8</SwiperSlide>
//         <SwiperSlide>Slide 9</SwiperSlide>
//       </Swiper>
//     </>
//   )
// }
