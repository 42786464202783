import crudAxios from './configs/crud'

export const paymentAPI = {
  prepare: body => {
    return crudAxios.post('/payment/prepare', body)
  },
  subscribe: body => {
    return crudAxios.post('/payment/subscribe', body)
  },
  auth: body => {
    return crudAxios.post('/payment/auth', body)
  },
  cancel: body => {
    return crudAxios.post('/payment/cancel', body)
  },
  test: body => {
    return crudAxios.post('/payment/test', body)
  },
  imp_prepare: body => {
    return crudAxios.post('/payment/imp_prepare', body)
  },
  imp_billing: body => {
    return crudAxios.post('/payment/imp_billing', body)
  },
}
