import {
  endpointAtom,
  mannequinLoadingAtom,
  maskImageAtom,
  nukkiSAMOriSizeAtom,
  nukkiSAMSizeAtom,
  regenerateUploadFilesAndUrlAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  selectedMaskImgAtom,
  uploadFilesAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { useRefineUploadFiles } from 'hooks/useRefineUploadFiles'
import { FadeLoader } from 'react-spinners'
import { useEffect, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { getS3ImageSrc, imageTransparent, imageUrlToFile } from 'utils/common'
import FragmentImages from './fragment/FragmentImages'
import { apis } from 'apis'
import { IMG_BASE64 } from './portfolio/RegenerateDialog'
import { useTranslation } from 'react-i18next'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Segment } from './fragment/Segment'

let realWidth
let realHeight

export default function SegmentArea({ artwork }) {
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()

  const [nukkiSAMOriSize, setNukkiSAMOriSize] = useRecoilState(nukkiSAMOriSizeAtom)

  const [maskImage, setMaskImage] = useRecoilState(maskImageAtom)

  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [segmentStep, setSegmentStep] = useRecoilState(segmentStepAtom)
  const endpoint = useRecoilValue(endpointAtom)

  const loadImage = () => {
    apis.appfront
      .editMask({ artworkId: artwork.id, sodUrl: endpoint.sod_url })
      .then(async response => {
        const maskB = response.data.mask.body

        const postProcessingImage = await imageTransparent(
          `${IMG_BASE64}${maskB.replaceAll('"', '')}`
        )
        setMaskImage(postProcessingImage)
        // setMaskImage(`${IMG_BASE64}${maskB.replaceAll('"', '')}`)
      })
      .catch(err => {
        console.log(err)
        alert(t('common.error'))
      })
  }

  useEffect(() => {
    if (!maskImage) loadImage()
  }, [])

  // useEffect(() => {
  //   console.log('maskimgge', maskImage)
  // })

  return (
    <>
      {' '}
      <CenterAlignStack
        sx={{
          width: '100%',
          justifyContent: 'center',
          background: '#6D6D6D',

          // borderRight: { lg: '1px solid #AFAFAF', xs: 0 },
        }}
      >
        <Segment
          widthLimit={isMobile ? 360 : 440}
          heightLimit={isMobile ? 369 : 520}
          usage="nukki"
          setOriSize={setNukkiSAMOriSize}
          fileAtom={regenerateUploadFilesAndUrlAtom}
        />
        {/* <AutoUploadSegment
          artwork={artwork}
          files={images}
          maskImage={maskImage}
          setFiles={setImages}
          segmentLoading={segmentLoading}
          setSegmentLoading={setSegmentLoading}
        /> */}
      </CenterAlignStack>
    </>
  )
}

const AutoUploadSegment = ({
  files,
  setFiles,
  segmentLoading,
  setSegmentLoading,
  artwork,
  maskImage,
  ...props
}) => {
  const isMobile = useMobileMediaQuery()

  const imgBoxMaxWidth = isMobile ? 360 : 573
  const imgBoxMaxHeight = isMobile ? 369 : 555

  const [segmentStep, setSegmentStep] = useRecoilState(segmentStepAtom)

  const theme = useTheme()

  const [nukkiSAMSize, setnukkiSAMSize] = useRecoilState(nukkiSAMSizeAtom)
  const [nukkiSAMOriSize, setNukkiSAMOriSize] = useRecoilState(nukkiSAMOriSizeAtom)
  const [imageSrc, setImageSrc] = useState('')

  const loadHandler = e => {
    realWidth = e.target.naturalWidth
    realHeight = e.target.naturalHeight

    setNukkiSAMOriSize({ w: realWidth, h: realHeight })

    const widthScale = imgBoxMaxWidth / realWidth
    const heightScale = imgBoxMaxHeight / realHeight //  높이 / 너비 너비 비율

    if (widthScale >= heightScale) {
      setnukkiSAMSize({
        w: `${(realWidth * heightScale) / 10}rem`,
        h: `${(realHeight * heightScale) / 10}rem`,
      })
    }

    if (widthScale < heightScale) {
      setnukkiSAMSize({
        w: `${(realWidth * widthScale) / 10}rem`,
        h: `${(realHeight * widthScale) / 10}rem`,
      })
    }
  }

  useEffect(() => {
    if (files.length !== 0) {
      const imageUrl = URL.createObjectURL(files)
      setImageSrc(imageUrl)

      return () => {
        URL.revokeObjectURL(files)
      }
    }
  }, [files])

  return (
    <>
      {files.length !== 0 && segmentStep !== 1 && (
        <CenterAlignStack
          sx={{ width: nukkiSAMSize.w, height: nukkiSAMSize.h, position: 'relative' }}
        >
          <img
            src={imageSrc}
            style={{ width: nukkiSAMSize.w, height: nukkiSAMSize.h }}
            onLoad={loadHandler}
          />
        </CenterAlignStack>
      )}
      {segmentLoading && (
        <CenterAlignBox
          sx={{
            position: 'absolute',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.50)',
            width: nukkiSAMSize.w,
            height: nukkiSAMSize.h,
            zIndex: 1,
          }}
        >
          <FadeLoader
            color={theme.palette.common.white}
            height={25}
            margin={16}
            radius={9}
            width={6}
          />
        </CenterAlignBox>
      )}

      {segmentStep === 1 && (
        <FragmentImages
          files={files}
          uploadImgHeight={nukkiSAMSize.h}
          uploadImgWidth={nukkiSAMSize.w}
          usage="nukki"
        />
      )}
      {/* <img
            src={maskImage}
            style={{
              width: nukkiSAMSize.w,
              height: nukkiSAMSize.h,
              position: 'absolute',
              opacity: 0.7,
              top: 0,
            }}
          /> */}
    </>
  )
}
