import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Container,
} from '@mui/material'
import { Desktop, isDesktopOrLaptop, Mobile } from 'hooks/useMediaQuery'
import { useMediaQuery } from 'react-responsive'
import { AiOutlineFileExclamation } from 'react-icons/ai'
import { SearchIcon } from 'theme/icon'
import { Advertisement, AgreePrivacy, Marketing, Privacy, Terms } from 'pages'
import { useTranslation } from 'react-i18next'

const errorColor = '#FF615C'

export function AgreeDialog({ category, openButton }) {
  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState('paper')
  const { t, i18n } = useTranslation()

  const serviceH3Style = {
    fontSize: 20,
    marginTop: '32px',
    marginBottom: '15px',
    marginLeft: '14px',
    fontWeight: 600,
  }
  const serviceH4Style = {
    marginBottom: '8px',
    marginTop: '12px',
    fontSize: 17.5,
  }
  const servicePStyle = { paddingLeft: '10px', fontSize: 15.5 }
  const serviceOlStyle = { listStyleType: 'decimal', marginLeft: '3rem' }

  const privacyH3Style = {
    marginTop: '32px',
    marginBottom: '5px',
    fontWeight: 600,
  }
  const privacyLiStyle1 = { listStyleType: 'decimal', paddingLeft: '20px' }
  const privacyLiStyle2 = { listStyleType: 'decimal', paddingLeft: '18px' }

  const nestedLiStyle = { marginLeft: '1.5rem' }

  const handleClickOpen = scrollType => () => {
    setOpen(true)
    setScroll(scrollType)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <>
      {openButton && (
        <span style={{ cursor: 'pointer' }} onClick={handleClickOpen('paper')}>
          {openButton}
        </span>
      )}
      {category === 'Terms' && (
        <Dialog open={open} onClose={handleClose} scroll={scroll} fullWidth={true} maxWidth={'md'}>
          <DialogTitle>{t('footer.terms')}</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <Container ref={descriptionElementRef}>
              <Terms />
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('button.close')}</Button>
          </DialogActions>
        </Dialog>
      )}

      {category === 'agreePrivacy' && (
        <Dialog open={open} onClose={handleClose} scroll={scroll} fullWidth={true} maxWidth={'md'}>
          <DialogTitle>개인정보 수집 및 이용 동의</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <Container ref={descriptionElementRef}>
              <AgreePrivacy />
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('button.close')}</Button>
          </DialogActions>
        </Dialog>
      )}

      {category === 'agreeAdvertisement' && (
        <Dialog open={open} onClose={handleClose} scroll={scroll} fullWidth={true} maxWidth={'md'}>
          <DialogTitle>{t('agree.agreeAdvertisement')}</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <Container ref={descriptionElementRef}>
              <Advertisement />
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('button.close')}</Button>
          </DialogActions>
        </Dialog>
      )}

      {category === 'agreeMarketing' && (
        <Dialog open={open} onClose={handleClose} scroll={scroll} fullWidth={true} maxWidth={'md'}>
          <DialogTitle>{t('agree.agreeMarketing')}</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <Container ref={descriptionElementRef}>
              <Marketing />
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('button.close')}</Button>
          </DialogActions>
        </Dialog>
      )}

      {category === 'Privacy' && (
        <Dialog open={open} onClose={handleClose} scroll={scroll} fullWidth={true} maxWidth={'md'}>
          <DialogTitle>{t('footer.privacy')}</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <Container ref={descriptionElementRef}>
              <Privacy />
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('button.close')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
