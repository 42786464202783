import styled from '@emotion/styled'
import {
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Stack,
  Typography,
  RadioGroup,
  Radio,
} from '@mui/material'
import { Children, useEffect } from 'react'
import { useController } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import { CenterAlignBox } from './CenterAlignContainer'
import {
  AgreeCheckedSquareIcon,
  AgreeSquareIcon,
  CheckedSquareIcon,
  CheckedSquareIcon2,
  SquareIcon,
  SquareIcon2,
} from 'theme/icon'
import { motion } from 'framer-motion'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useMediaQuery } from 'react-responsive'

export const TextFieldStyle = styled(FormTextField)(({ theme }) => ({
  marginTop: '1rem',

  '& .MuiInputBase-root': {
    width: '38.4rem',
    height: '5rem',
    padding: '1.6rem',
  },

  [theme.breakpoints.down('lg')]: {
    marginTop: '1.2rem',
    '& .MuiInputBase-root': {
      width: '32rem',
      height: '4rem',
      padding: '1.6rem 0.8rem 1.6rem 1.6rem',
    },
  },

  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.draph.blue,
      borderWidth: '0.2rem',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#303030',
      borderWidth: '0.2rem',
    },

    '&.Mui-focused fieldset': {},

    '& .MuiOutlinedInput-input': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
  },

  '& .MuiInputAdornment-root .MuiButtonBase-root': {
    marginRight: '0.5rem',
  },
}))

export function StyledFormTextField(props) {
  return <TextFieldStyle {...props} />
}

export function FormTextField({ control, name, rules = {}, englishMode, ...props }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
  })

  const handleChange = e => {
    const regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]+/ // 한글 입력 여부를 검사하는 정규식

    if (regex.test(e.target.value)) {
      return // 한글 입력을 막고, 입력된 값을 지움
    }
    field.onChange(e.target.value)
  }

  if (englishMode === true) {
    return <TextField {...field} {...props} onChange={handleChange} />
  }

  return <TextField {...field} {...props} />
}

export function FormSelect({ items, control, name, rules = {}, ...props }) {
  // items: { value: 'X', text: '텍스트' }
  const {
    field,
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <>
      <TextField {...field} {...props} select defaultChecked="">
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}

export const FormCheckbox = ({ control, name, rules = {}, checked, smallIcon, ...props }) => {
  const {
    field: { ref, ...fieldprops },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <>
      <Desktop>
        <FormControlLabel
          {...props}
          sx={{ ml: '-8px' }}
          control={
            <Checkbox
              icon={smallIcon ? <SquareIcon2 /> : <AgreeSquareIcon />}
              checkedIcon={smallIcon ? <CheckedSquareIcon2 /> : <AgreeCheckedSquareIcon />}
              {...fieldprops}
              checked={checked}
              inputRef={ref}
            />
          }
        />
      </Desktop>

      <Mobile>
        <FormControlLabel
          {...props}
          control={
            <Checkbox
              icon={<SquareIcon2 />}
              checkedIcon={<CheckedSquareIcon2 />}
              {...fieldprops}
              checked={checked}
              inputRef={ref}
            />
          }
        />
      </Mobile>
    </>
  )
}

export const RegisterBox = styled(Box)(({ theme }) => ({
  marginTop: '0.8rem',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',

  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  //   justifyContent: 'flex-start',
  //   alignItems: 'center',
  //   marginTop: '0.8rem',
  // },
}))

export const FlexBasis = ({ flexBasis = [], children, ...props }) => {
  return (
    <>
      {Children.map(children, (child, index) => (
        <Stack flexBasis={flexBasis[index]} {...props}>
          {child}
        </Stack>
      ))}
    </>
  )
}

export function TypoSpaceBtn({ children, ...props }) {
  return (
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}

export const RegisterLabel = ({ required, label, timer, ...style }) => {
  const theme = useTheme()

  return (
    <>
      <Desktop>
        <p
          style={{
            width: '20rem',
            fontWeight: 500,
            fontSize: '1.8rem',
            lineHeight: '2.4rem',
            minWidth: '22rem',
            ...style,
          }}
        >
          {label}&nbsp;
          {required && (
            <sup
              style={{
                color: theme.palette.error.main,
                fontWeight: 500,
                fontSize: '2rem',
                lineHeight: '2.4rem',
                verticalAlign: 'text-top',
              }}
            >
              &#42;
            </sup>
          )}
        </p>
      </Desktop>

      <Mobile>
        <span
          style={{
            fontWeight: 500,
            fontSize: '1.8rem',
            ...style,
          }}
        >
          {label}&nbsp;
          {required && (
            <sup
              style={{
                color: theme.palette.error.main,
                fontWeight: 500,
                fontSize: '1.8rem',
                lineHeight: '2.1rem',
                verticalAlign: 'text-top',
              }}
            >
              &#42;&nbsp;
            </sup>
          )}
        </span>
        {timer}
      </Mobile>
    </>
  )
}

export const FormErrorMessage = ({
  message,
  style,
  width = '100%',
  motionStyle = {},
  ...props
}) => {
  const theme = useTheme()

  return (
    <motion.div
      initial={{ x: 3, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}
      style={{ display: 'flex', flex: 1, width, ...motionStyle }}
    >
      <p
        style={{
          color: theme.palette.error.main,
          fontWeight: 400,
          fontSize: '1.3rem',
          lineHeight: '1.6rem',
          width: '100%',
          height: '2.4rem',
          textAlign: 'end',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',

          ...style,
        }}
      >
        {message}
      </p>
    </motion.div>
  )
}

export const FormRadioGroup = ({ name, control, options, rules = {}, ...props }) => {
  const theme = useTheme()
  const {
    field: { ref, ...fieldprops },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <RadioGroup {...props} {...fieldprops}>
      <Box
        sx={{
          height: '4.6rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          width: '38.4rem',
        }}
      >
        {options?.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={
              <Radio
                sx={{
                  '&.Mui-checked': {
                    color: theme.palette.common.black,
                  },
                }}
                checkedIcon={<AgreeCheckedSquareIcon />}
                icon={<AgreeSquareIcon />}
                inputRef={ref}
              />
            }
            label={
              <Typography sx={{ fontWeight: 400, fontSize: '2rem' }}>{option.label}</Typography>
            }
          />
        ))}
      </Box>
    </RadioGroup>
  )
}

// 이하 Register 에서 사용하는 Form

export const TextInput = styled(FormTextField)(({ theme }) => ({
  width: '38.4rem',
  minWidth: '38.4rem',

  [theme.breakpoints.up('lg')]: {
    marginRight: '2rem',
  },

  [theme.breakpoints.down('lg')]: {
    width: '32rem',
    minWidth: '32rem',
    marginBottom: '1.8rem',
  },

  '& .MuiInputBase-root': {
    padding: 0,
    height: '4rem',
    fontWeight: 400,
    fontSize: '1.8rem',
    lineHeight: '2.1rem',
  },

  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.draph.blue,
      borderWidth: '0.2rem',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#303030',
      borderWidth: '0.1rem',
    },

    '&.Mui-focused fieldset': {},

    '& .MuiOutlinedInput-input': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      padding: '0rem  1.4rem ',
    },
  },

  '& .MuiInputAdornment-root .MuiButtonBase-root': {
    marginRight: '0.5rem',
  },
}))

export const PhoneInput = styled(FormTextField)(({ theme }) => ({
  width: '10rem',
  height: '4rem',
  [theme.breakpoints.down('lg')]: {
    width: '8.0rem',
    minWidth: '8.0rem',
    marginBottom: '1.8rem',
  },
  '& .MuiInputBase-root': {
    padding: 0,
    height: '4.6rem',
    fontWeight: 400,
    fontSize: '1.8rem',
    lineHeight: '2.1rem',
  },

  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.draph.blue,
      borderWidth: '0.2rem',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#303030',
      borderWidth: '0.1rem',
    },

    '&.Mui-error fieldset': {
      borderColor: theme.palette.error.main,
    },

    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      WebkitBoxShadow: '0 0 0 1000px white inset',
      padding: '0rem  1.4rem ',
    },
  },

  '& .MuiInputAdornment-root .MuiButtonBase-root': {
    marginRight: '0.5rem',
  },
}))

export const SelectInput = styled(FormSelect)(({ theme }) => ({
  width: '40rem',
  '& svg': {
    top: 'calc(50% - 0.4rem)',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },

  [theme.breakpoints.up('lg')]: {
    marginRight: '2rem',
  },

  [theme.breakpoints.down('lg')]: {
    width: '32rem',
    minWidth: '32rem',
    marginBottom: '1.8rem',
  },

  '& .MuiInputBase-root': {
    padding: 0,
    height: '4rem',
  },

  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.draph.blue,
      borderWidth: '0.2rem',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#303030',
      borderWidth: '0.1rem',
    },

    '&.Mui-focused fieldset': {},

    '& .MuiOutlinedInput-input': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
  },

  '& .MuiInputAdornment-root .MuiButtonBase-root': {
    marginRight: '0.5rem',
  },
}))

// Background 관리에서 사용하는 TextField

export const BgTextInput = styled(FormTextField)(({ theme }) => ({
  width: '38.4rem',
  minWidth: '38.4rem',

  '& .MuiInputBase-root': {
    padding: 0,
    height: '4.6rem',
    fontWeight: 400,
    fontSize: '1.8rem',
    lineHeight: '2.1rem',
  },

  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.draph.blue,
      borderWidth: '0.2rem',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#303030',
      borderWidth: '0.1rem',
    },

    '&.Mui-focused fieldset': {},

    '& .MuiOutlinedInput-input': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      padding: '0rem  1.4rem ',
    },
  },

  '& .MuiInputAdornment-root .MuiButtonBase-root': {
    marginRight: '0.5rem',
  },
}))
