import React, { useEffect, useMemo, useState } from 'react'
import { TableRow, TableCell, Checkbox, Box, Typography } from '@mui/material'
import { getS3ImageSrc } from 'utils/common'
import * as config from 'config'
import { useParams } from 'react-router-dom'
import { defaultPortfolioAtom, portfolioTypeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { useArtworkPolling } from 'hooks/useReactQuery'
import { ShapesDisplay } from './ShapesDisplay'

const BannerArtworkRow = ({ initArtwork, isChecked, onCheckboxChange, idx }) => {
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const [artwork, setArtwork] = useState(initArtwork)

  const shouldPoll = useMemo(
    () => config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status),
    [artwork.status]
  )

  const { individualArtwork, error, modPieceError } = useArtworkPolling(
    portfolioId,
    initArtwork.id,
    shouldPoll
  )

  useEffect(() => {
    if (individualArtwork) {
      setArtwork(individualArtwork)
    }
  }, [individualArtwork, individualArtwork?.status, individualArtwork?.pieces])

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox checked={isChecked} onChange={onCheckboxChange} />
      </TableCell>
      <TableCell>
        {artwork?.pieces.map(p => {
          return <img key={p.path} src={getS3ImageSrc(p.path)} />
        })}
      </TableCell>
      <TableCell>
        <Typography variant="body1">아트워크 이름: {artwork.name}</Typography>
        <Typography variant="body2">ID: {artwork.id}</Typography>
        <Typography variant="body2">순서: {idx}</Typography>
      </TableCell>
    </TableRow>
  )
}

export default BannerArtworkRow
