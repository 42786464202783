import { useRecoilState, atom } from 'recoil'
import { testAtom } from './TestPage'
import { RecoilDebugButton } from 'components'
import { useEffect, useState } from 'react'

export default function A() {
  const [count, setCount] = useRecoilState(testAtom)

  useEffect(() => {
    console.log('showing A ')
    return () => {
      console.log('byebye A')
    }
  }, [])

  return (
    <>
      <h1>AAAAAA Page</h1>
      {count}
      <button
        onClick={() => {
          setCount(prev => prev + 1)
        }}
      >
        {' '}
        increase
      </button>
      <br />

      <br />

      <RecoilDebugButton />
    </>
  )
}
