import { SquareIcon3, CheckedSquareIcon2, CheckedSquareIcon3 } from 'theme/icon'

export default function Checkbox(theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <SquareIcon3 />,
        checkedIcon: <CheckedSquareIcon3 />,
        indeterminateIcon: <SquareIcon3 />,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      },
    },
  }
}
