import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom'
// material
import { styled, useTheme } from '@mui/material/styles'

import { CenterAlignBox, CenterAlignStack, TempLayoutDiv } from 'components'
import { Box, Card, Divider, Stack, Typography } from '@mui/material'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { userCreditInfoAtom } from 'atoms'

// ----------------------------------------------------------------------

export const DRAWER_WIDTH_PERCENTAGE = '20'

const RootStyle = styled('div')(({ theme }) => ({
  width: 0,
  height: 0,
  position: 'relative',
}))

export const MypagesMenu = styled(Card)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  width: '20rem',
  height: '52rem',
  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',

  top: '8rem',
  left: '-64.25rem',
}))
export const MypagesRouterLink = styled(RouterLink)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  marginBottom: '0.8rem',
  fontWeight: 500,
  fontSize: '2rem',

  '&:hover': {
    backgroundColor: 'unset',
    opacity: 0.8,
  },
}))

const titleStyle = {
  fontWeight: 700,
  fontSize: '2.4rem',
  lineHeight: '2.9rem',
}

const dividerStyle = {
  my: '2.4rem',
  border: '0.5px solid #808080',
}

// ----------------------------------------------------------------------

export default function AdminSideBar() {
  const { pathname } = useLocation()
  const theme = useTheme()
  const [userCreditInfo, setUserCreditInfo] = useRecoilState(userCreditInfoAtom)

  const styledList = { paddingBottom: '2rem', fontWeight: 500, fontSize: '18px' }

  return (
    <>
      <Desktop>
        <RootStyle>
          <MypagesMenu>
            <Stack sx={{ margin: '4.4rem 3.2rem', height: '100%' }}>
              <MypagesRouterLink
                to="/admin/promotion"
                style={{ marginBottom: 0, ...titleStyle }}
                sx={{
                  color:
                    pathname === '/admin/promotion'
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                }}
              >
                프로모션 관리
              </MypagesRouterLink>

              <Divider flexItem sx={{ ...dividerStyle }} />
              <MypagesRouterLink
                to="/admin/coupon"
                style={{ marginBottom: 0, ...titleStyle }}
                sx={{
                  color:
                    pathname === '/admin/coupon'
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                }}
              >
                쿠폰 관리
              </MypagesRouterLink>

              <Divider flexItem sx={{ ...dividerStyle }} />

              <Typography sx={{ mb: '1.6rem', ...titleStyle }}>크레딧 관리</Typography>

              <MypagesRouterLink
                to="/admin/credit"
                onClick={() => {
                  setUserCreditInfo(0)
                }}
                sx={{
                  color:
                    pathname === '/admin/credit' && userCreditInfo === 0
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                }}
              >
                - 지급
              </MypagesRouterLink>
              <MypagesRouterLink
                to="/admin/credit_history"
                style={{ marginBottom: 0 }}
                onClick={() => {
                  setUserCreditInfo(1)
                }}
                sx={{
                  color:
                    pathname === '/admin/credit_history' && userCreditInfo === 1
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                }}
              >
                - 크레딧 내역
              </MypagesRouterLink>

              <Divider flexItem sx={{ ...dividerStyle }} />
              <MypagesRouterLink
                to="/admin/subscription"
                style={{ marginBottom: 0, ...titleStyle }}
                sx={{
                  color:
                    pathname === '/admin/subscription'
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                }}
              >
                구독 관리
              </MypagesRouterLink>

              <Divider flexItem sx={{ ...dividerStyle }} />

              <MypagesRouterLink
                to="/admin/user"
                style={{ marginBottom: 0, ...titleStyle }}
                sx={{
                  color:
                    pathname === '/admin/user'
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                }}
              >
                유저 관리
              </MypagesRouterLink>
            </Stack>
          </MypagesMenu>
        </RootStyle>
      </Desktop>
      {/* <Mobile>
        <CenterAlignBox sx={{ width: '100%', mt: '3.8rem', gap: '5.3rem' }}>
          <Typography sx={{ ...styledList, fontWeight: pathname === '/board' ? 800 : 500 }}>
            <RouterLink to="/board" style={{ color: 'inherit', textDecoration: 'none' }}>
              공지사항
            </RouterLink>
          </Typography>
          <Typography sx={{ ...styledList, fontWeight: pathname === '/board' ? 800 : 500 }}>
            <RouterLink to="/board" style={{ color: 'inherit', textDecoration: 'none' }}>
              드랩 가이드
            </RouterLink>
          </Typography>
          <Typography sx={{ ...styledList, fontWeight: pathname === '/FAQ' ? 800 : 500 }}>
            <RouterLink to="/FAQ" style={{ color: 'inherit', textDecoration: 'none' }}>
              FAQ
            </RouterLink>
          </Typography>
        </CenterAlignBox>
        <Divider sx={{ mt: '0.4rem', backgroundColor: 'black' }} />
      </Mobile> */}
    </>
  )
}

// <CenterAlignStack sx={{ display: 'flex', py: '14.6rem' }}>
// <ul>
//   <li style={{ ...styledList, fontWeight: pathname === '/board' ? 800 : 500 }}>
//     <RouterLink to="/board" style={{ color: 'inherit', textDecoration: 'none' }}>
//       공지사항
//     </RouterLink>
//   </li>
//   {/* 드랩가이드는 아직 개설 전 */}
//   <li style={{ ...styledList, fontWeight: pathname === '/board' ? 800 : 500 }}>
//     <RouterLink to="/board" style={{ color: 'inherit', textDecoration: 'none' }}>
//       드랩 가이드
//     </RouterLink>
//   </li>
//   <li style={{ ...styledList, fontWeight: pathname === '/FAQ' ? 800 : 500 }}>
//     <RouterLink to="/FAQ" style={{ color: 'inherit', textDecoration: 'none' }}>
//       FAQ
//     </RouterLink>
//   </li>
// </ul>
// </CenterAlignStack>
