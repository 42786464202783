import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import {
  Link,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Divider,
  SwipeableDrawer,
} from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import {
  CenterAlignBox,
  CenterAlignStack,
  FormErrorMessage,
  TextInput,
  FormCheckbox,
  AgreeDialog,
  StyledFormTextField,
} from 'components'
import { apis } from 'apis'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useRecoilState } from 'recoil'
import { tutorialAtom, userAtom } from 'atoms'
import { styled, useTheme } from '@mui/material/styles'
import {
  AgreeCheckedSquareIcon,
  AgreeSquareIcon,
  CheckedSquareIcon,
  CheckedSquareIcon2,
  SearchIcon,
  SquareIcon,
  SquareIcon2,
} from 'theme/icon'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useSnackbar } from 'notistack'
import useConfirm from 'hooks/useConfirm'
import moment from 'moment'
import { iOS } from 'utils/common'

import { useNavigateForPromotion, useNavigateWithQuery } from 'hooks/useNavigateWithQuery'
import { promotionInfo } from 'pages/promotionEvents/DomeggookEvent'
import Register from 'pages/Register'
import Login from 'pages/Login'

export const buttonMobileStyle = {
  width: '320px',
  height: '48px',
  mt: '23px',
  fontSize: '18px',
  fontWeight: '800',
}

export function NaverCommerceRegister() {
  const navigate = useNavigate()
  const [showRegisterForm, setShowRegisterForm] = useState(true)
  // const [showRegisterForm, setShowRegisterForm] = useState(false)
  useEffect(() => {
    checkUser()

    // ----- GA4 event -----
    window.gtag('event', 'sign_up_begin', {})
    // ---------------------
  }, [])

  const checkUser = async () => {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')
    if (token) {
      const res = await apis.naverCommerce.login({ jwe: token })
      console.log(res.data)

      if (res.data.success) {
        if (res.data.registered) {
          // 로그인 처리
          const d = res.data

          const accessToken = d.access_token
          const refreshToken = d.refresh_token
          setAccessToken(accessToken)
          setRefreshToken(refreshToken)

          window.gtag('event', 'login', {
            method: d.login_sns && d.login_sns.length > 1 ? d.login_sns : 'general',
          })

          navigate('/home', { replace: true })
        } else {
          // 회원가입 입력창 띄우기 (페이지 또는 팝업)
          setShowRegisterForm(true)
        }
      }
    }
  }
  const theme = useTheme()

  const showLogin = () => {
    setShowRegisterForm(false)
  }
  const showRegister = () => {
    setShowRegisterForm(true)
  }

  return (
    <>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.common.black,
          height: '8rem',
        }}
      >
        <img src="/static/images/logo/logo_h_white.png" style={{ height: '4.9rem' }} />
      </Stack>

      {showRegisterForm ? (
        <Register naverCommerce={true} naverCommerceHandleLogin={showLogin} />
      ) : (
        <Login naverCommerce={true} naverCommerceHandleRegister={showRegister} />
      )}
    </>
  )
}
