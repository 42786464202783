import { Box, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
})

const StyledStack = styled(Stack)({
  alignItems: 'center',
})

export function CenterAlignBox({ children, ...props }) {
  return <StyledBox {...props}>{children}</StyledBox>
}

export function CenterAlignStack({ children, ...props }) {
  return <StyledStack {...props}>{children}</StyledStack>
}
