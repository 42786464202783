import crudAxios from './configs/crud'

export const statAPI = {
  getStat: () => {
    return crudAxios.get('/stat')
  },
  getUserStat: () => {
    return crudAxios.get('/stat/total')
  },
}
