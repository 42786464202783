import {
  Button,
  Divider,
  Box,
  Typography,
  Stack,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  listItemTextClasses,
  SwipeableDrawer,
} from '@mui/material'
import { CenterAlignBox, CenterAlignStack, PricingCard, AgreeDialog, Loading } from 'components'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { getNextPaidDay } from 'pricing'
import { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper'
import { IMP_CODE, IMP_PG_ACCOUNT_ID, IMP_NOTICE_URL } from 'config'

import 'moment-timezone'
import {
  getCurrencyFormatter,
  isDev,
  numberCommaFormatter,
  padZeros,
  iOS,
  trasnferDate,
} from 'utils/common'
import { userAtom, userSubInfoAtom } from 'atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { apis } from 'apis'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { CloseIcon, CheckIcon } from 'theme/icon'
import { useSnackbar } from 'notistack'
import { creditPlansAtom } from '../atoms'
import { useTranslation } from 'react-i18next'
import { English, getStaticResourceLanguage, Korean } from 'hooks/useLanguage'
import { usePricing } from 'hooks/usePricing'
import { getAccessToken } from 'utils/cookie'
import { EnterpriseCustomerLogoMarquee } from './Home'
import { generatePaymentUid } from '../hooks/usePricing'

const PAYPAL_PG = IMP_PG_ACCOUNT_ID ? `paypal_v2.${IMP_PG_ACCOUNT_ID}` : 'paypal_v2'
const IMP = window.IMP
IMP.init(IMP_CODE)

export const addSessionStorageData = paymentInfo => {
  // 세션스토리지에 보관된 정보 중 결제시 담아보내야 하는 값이 있으면 추가해주는 함수..

  const keys = ['cafe24_mall_id', 'cafe24_mall_user_id']

  keys.forEach(k => {
    const v = sessionStorage.getItem(`${k}`)
    if (v) {
      paymentInfo[k] = v
    }
  })
}

// ---- 이벤트를  내리거나 수정할 때 이 변수 사용하는 곳, 표시되는 문구 등 전부 검수해야함 -----
export const SUB_EVENT_ID = 'f2402_2d82f9e7ce76'
// -------------------------------------------------------------------------

export default function Pricing() {
  const { requestPay } = usePricing()
  const { t, i18n } = useTranslation()
  const isKo = i18n.language === 'ko'
  const token = getAccessToken()

  const [openNonMemberDialog, setOpenNonMemberDialog] = useState(false)
  const [swiper, setSwiper] = useState(null)
  const [realIdx, setRealIdx] = useState(0)
  const [userSubId, setUserSubId] = useState(null)
  const [userSubInfo, setUserSubInfo] = useRecoilState(userSubInfoAtom)

  const [openPaymentInfoDialog, setOpenPaymentInfoDialog] = useState(false)
  const [paymentInfo, setPaymentInfo] = useState({})
  const [paygoEvent, setPaygoEvent] = useState()

  const plans = useRecoilValue(creditPlansAtom)

  const userInfo = useRecoilValue(userAtom)
  const navigate = useNavigate()
  const isMobile = useMobileMediaQuery()

  const { enqueueSnackbar } = useSnackbar()
  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  useEffect(() => {
    // 로그인 상태일 경우 route 에서 유저정보, 유저 구독정보가 세팅된 채로 넘어옴
    if (userInfo.id) {
      //   apis.user.getUserSubscription().then(response => {
      //     if (response.data?.plan_id) {
      //       setUserSubId(response.data.plan_id)
      //       setUserSubInfo(response.data)
      //     }
      //     if (!response.data?.plan_id && response.data?.subscription_event) {
      //       // 현재 구독중이 아닌 경우
      //       setSubEvent(response.data?.subscription_event)
      //     } else {
      //       // 현재 접속한 유저가 프로모션 대상이 아님
      //       setSubEvent()
      //     }
      //   })
      if (userSubInfo?.plan_id) {
        setUserSubId(userSubInfo.plan_id)
      }
    }
  }, [userInfo, userSubInfo])

  const requestPaypal = (
    paymentInfo // { paymentType, plan, price, currency }
  ) => {
    setPaymentInfo(paymentInfo)
    setOpenPaymentInfoDialog(true)
  }

  return (
    <>
      <CenterAlignStack sx={{ mb: { lg: '0.8rem', xs: '1.2rem' }, px: { lg: 0, xs: '2rem' } }}>
        <Typography
          sx={{
            pt: { lg: '5.2rem', xs: '3.6rem' },
            lineHeight: 'normal',
            pb: '1.6rem',
            fontSize: { lg: '3.6rem', xs: isKo ? '2.2rem' : '2.1rem' },
            fontWeight: 800,
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
          }}
          component="div"
        >
          {t('pricing.title_1') + (isMobile ? '\n' : '') + t('pricing.title_2')}
        </Typography>
        <Box
          sx={{
            mb: { lg: '3rem', xs: '1.6rem' },
            display: 'flex',
            flexDirection: 'column',
            gap: { lg: '0.3rem', xs: '0.2rem' },
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: '1.6rem', xs: '1.2rem' },
              fontWeight: 400,
              lineHeight: 'normal',
              '& span': { fontWeight: 600 },
            }}
          >
            <CheckIcon />
            <span
              style={{
                display: 'inline-block',
                width: isMobile ? '0.3rem' : '0.8rem',
              }}
            />
            <Korean>
              {t('pricing.subtitle_1_a')}
              <span>
                {t('pricing.subtitle_1_b')}
                {t('pricing.subtitle_1_c')}
              </span>
              {t('pricing.subtitle_1_d')}
            </Korean>

            <English>
              {t('pricing.subtitle_1_a')}
              {isMobile && <br />}
              <span style={{ fontWeight: 400, marginLeft: isMobile ? '1.7rem' : '0' }}>
                {t('pricing.subtitle_1_b')}
              </span>
              <span>{t('pricing.subtitle_1_c')}</span>
              {t('pricing.subtitle_1_d')}
            </English>
          </Typography>
          <Typography
            sx={{
              fontSize: { lg: '1.6rem', xs: '1.2rem' },
              fontWeight: 400,
              '& span': { fontWeight: 600 },
            }}
            component="div"
          >
            <CheckIcon />

            <Korean>
              <span
                style={{
                  display: 'inline-block',
                  width: isMobile ? '0.3rem' : '0.8rem',
                }}
              />
              {t('pricing.subtitle_2_a')} <span> {t('pricing.subtitle_2_b')} </span>{' '}
              {t('pricing.subtitle_2_c')}
              {isMobile ? <br /> : ' '}
              <span style={{ marginLeft: isMobile ? '1.7rem' : 0, fontWeight: 400 }}>
                {t('pricing.subtitle_2_d')}
              </span>
            </Korean>
            <English>
              <span
                style={{
                  display: 'inline-block',
                  width: isMobile ? '0.3rem' : '0.8rem',
                }}
              />
              <span>{t('pricing.subtitle_2_a')}</span> {t('pricing.subtitle_2_b')}
              {isMobile ? <br /> : ' '}
              <span style={{ marginLeft: isMobile ? '1.7rem' : 0, fontWeight: 400 }}>
                {t('pricing.subtitle_2_c')}
              </span>
              {isMobile ? <br /> : ' '}
              <span style={{ marginLeft: isMobile ? '1.7rem' : 0, fontWeight: 400 }}>
                {t('pricing.subtitle_2_d')}
              </span>
            </English>
          </Typography>
        </Box>

        <Desktop>
          <CenterAlignBox gap={4} sx={{ mt: '2rem', mb: '4.5rem' }}>
            {Object.keys(plans).map(p => (
              <PricingCard
                key={p}
                name={p}
                plan={plans[p]}
                requestPay={requestPay}
                userSubId={userSubId}
                userSubInfo={userSubInfo}
                setOpenNonMemberDialog={setOpenNonMemberDialog}
                highlight={p === 'subscription'}
                requestPaypal={requestPaypal}
                eventId={
                  p === 'subscription'
                    ? userSubInfo?.subscription_event
                    : p === 'paygo'
                    ? paygoEvent
                    : null
                }
              />
            ))}
          </CenterAlignBox>
        </Desktop>

        <Mobile>
          <Stack sx={{ mt: '2rem', mb: '4.5rem' }} spacing="2.8rem">
            {Object.keys(plans).map(p => (
              <PricingCard
                key={p}
                name={p}
                plan={plans[p]}
                requestPay={requestPay}
                userSubId={userSubId}
                userSubInfo={userSubInfo}
                setOpenNonMemberDialog={setOpenNonMemberDialog}
                highlight={p === 'subscription'}
                requestPaypal={requestPaypal}
                eventId={
                  p === 'subscription'
                    ? userSubInfo?.subscription_event
                    : p === 'paygo'
                    ? paygoEvent
                    : null
                }
              />
            ))}
          </Stack>
        </Mobile>

        <Typography
          fontSize={{ lg: '1.8rem', xs: '1.4rem' }}
          fontWeight={400}
          component="div"
          sx={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
        >
          {t('pricing.enterprise_comment_a')}
          {isMobile ? '\n' : ' '}
          {t('pricing.enterprise_comment_b')}
          <span
            onClick={() =>
              window
                .open(
                  isKo
                    ? 'https://forms.gle/NtFizsUdmcT4JDDT9'
                    : 'https://docs.google.com/forms/d/e/1FAIpQLSf4h-eA90_L_w0-qr0h0Rb04t2Dou4326Cvl7j91crajeO8HA/viewform?usp=sf_link',
                  '_blank'
                )
                .focus()
            }
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              color: '#4D80FF',
              fontWeight: 600,
            }}
          >
            {t('pricing.enterprise_comment_c')}
          </span>
          {t('pricing.enterprise_comment_d')}
        </Typography>
      </CenterAlignStack>

      <CenterAlignStack
        sx={{
          // background: 'pink',
          pb: { lg: '4.4rem', xs: '4rem' },
          '& .title': {
            fontSize: { lg: '2.6rem', xs: '1.6rem' },
            fontWeight: 600,
          },
        }}
      >
        <EnterpriseCustomerLogoMarquee
          sx={{
            height: { lg: '4.8rem', xs: '4rem' },
            pr: { lg: '4rem', xs: '2rem' },
            gap: { lg: '4rem', xs: '2rem' },
          }}
          src={`/static/images/etc/logo_group_small_${getStaticResourceLanguage(
            i18n.language
          )}.png`}
        />

        <Typography
          className="title"
          sx={{ textAlign: 'center', mt: { lg: '3.2rem', xs: '2.4rem' } }}
        >
          {t('home.title_8_a')} <br />
          {t('home.title_8_b')}
        </Typography>

        <Button
          variant="contained"
          sx={{
            mt: { lg: '2rem', xs: '1.6rem' },
            fontSize: { lg: '2rem', xs: '1.6rem' },
            fontWeight: { lg: 800, xs: 800 },
            borderRadius: '1rem',
            px: { lg: '5.5rem', xs: '2.2rem' },
            py: { lg: '0.7rem', xs: '0.4rem' },
          }}
          onClick={() => {
            // ----- GA4 event -----
            window.gtag('event', 'pricing_api', {})
            // ---------------------
            navigate('/api_info')
          }}
        >
          {t('home.api_button')}
        </Button>
      </CenterAlignStack>

      <CenterAlignBox
        id="payment-notice"
        sx={{
          width: '100%',
          background: theme => theme.palette.common.lightgray,
          py: { lg: '5.5rem', xs: '3rem' },
        }}
      >
        <Stack>
          <Typography
            fontSize={{ lg: '2rem', xs: '1.4rem' }}
            fontWeight={500}
            sx={{ mb: { lg: '2rem', xs: '1.6rem' }, alignSelf: { lg: 'center', xs: 'center' } }}
          >
            {t('pricing.notice')}
          </Typography>
          <Box
            component="ul"
            sx={{
              padding: { xs: '0 1.1rem 0 1.7rem', lg: '0 0 0 0.5rem' },
              maxWidth: { lg: 'none', xs: '36rem' },

              '& li': {
                fontSize: { lg: '1.6rem', xs: '1rem' },
                fontWeight: 400,
                lineHeight: { lg: '2.3rem', xs: '1.2rem' },
                mb: { xs: '0.2rem' },
                listStyleType: 'none',
                textIndent: isKo ? '-0.8rem' : '-1.1rem',
                '&::before': {
                  content: '"•"',
                  marginRight: '0.3rem',
                },
              },
            }}
          >
            {userSubInfo?.subscription_event && t('pricing.sub_event_comment_1') && (
              <>
                <Typography component="li" sx={{ '& span': { fontWeight: 700 } }}>
                  <span>{t('pricing.sub_event_comment_1')}</span>
                </Typography>
                <Typography component="li" sx={{ '& span': { fontWeight: 700 } }}>
                  <span>{t('pricing.sub_event_comment_2')}</span>
                </Typography>
              </>
            )}
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography component="li">
                <span>{t('pricing.notice_1_comment_a')}</span>
                {isMobile && isKo ? <br /> : ' '}
                <span>{t('pricing.notice_1_comment_b')}</span>
                <AgreeDialog
                  category="Terms"
                  openButton={
                    <span style={{ textDecoration: 'underline' }}>
                      {t('pricing.notice_1_comment_c')}
                    </span>
                  }
                />
                <span>{t('pricing.notice_1_comment_d')}</span>
              </Typography>
            </Box>

            <Typography component="li">
              <span>{t('pricing.notice_2_comment')}</span>
            </Typography>
            <Typography component="li">
              <span>{t('pricing.notice_3_comment')}</span>
            </Typography>
            <Typography component="li">
              <span>{t('pricing.notice_4_comment')}</span>
            </Typography>

            <Typography component="li">
              <span>{t('pricing.notice_5_comment_a')}</span>
              {isMobile || !isKo ? <br /> : ' '}
              <span>{t('pricing.notice_5_comment_b')}</span>
            </Typography>
            <Typography component="li">
              <span>{t('pricing.notice_6_comment')}</span>
            </Typography>
            <Typography component="li">
              {t('pricing.notice_7_comment_a')}
              <RouterLink
                to="/FAQ"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'unset' }}
              >
                {t('pricing.notice_7_comment_b')}
              </RouterLink>{' '}
              {t('pricing.notice_7_comment_c')}
            </Typography>
          </Box>
        </Stack>
      </CenterAlignBox>
      <NonMemberDialog open={openNonMemberDialog} setOpen={setOpenNonMemberDialog} />
      <PaymentInfoDialog
        open={openPaymentInfoDialog}
        setOpen={setOpenPaymentInfoDialog}
        paymentInfo={paymentInfo}
      />
    </>
  )
}

function NonMemberDialog({ open, setOpen }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          borderRadius: '20px',
          boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogContent
        sx={{
          width: { lg: '400px', xs: '320px' },
          p: { lg: '41px 0 33px 0', xs: '30px 0 26px 0' },
        }}
      >
        <CenterAlignStack gap="18px">
          <Typography sx={{ fontWeight: 700, fontSize: { lg: '20px', xs: '17px' } }}>
            {t('pricing.nonmemeber_dialog_title')}
          </Typography>
          <Typography
            sx={{ textAlign: 'center', fontWeight: 500, fontSize: { lg: '18px', xs: '15px' } }}
          >
            {t('pricing.nonmemeber_dialog_comment_1')} <br />{' '}
            {t('pricing.nonmemeber_dialog_comment_2')}
          </Typography>
        </CenterAlignStack>

        {/* <Stack
          direction="row"
          sx={{ width: '100%', justifyContent: 'center', py: '2rem' }}
          spacing="2rem"
        >
          <Button
            variant="contained"
            onClick={() => {
              navigate('/login')
            }}
          >
            로그인 하기
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate('/register')
            }}
          >
            회원가입 하기
          </Button>
        </Stack> */}
      </DialogContent>

      <DialogActions sx={{ p: 0 }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate('/login')
          }}
          sx={{
            width: '50%',
            height: { lg: '48px', xs: '40px' },
            fontSize: { lg: '16px', xs: '14px' },
            fontWeight: 600,
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            borderRadius: 0,
            '&:hover': {
              color: theme.palette.common.white,
            },
          }}
        >
          {t('button.login')}
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            navigate('/register')
          }}
          sx={{
            ml: '0 !important',
            width: '50%',
            height: { lg: '48px', xs: '40px' },
            fontSize: { lg: '16px', xs: '14px' },
            fontWeight: 600,
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            borderRadius: 0,
            '&:hover': {
              backgroundColor: '#F8F8F8',
              color: theme => theme.palette.common.black,
            },
          }}
        >
          {t('button.register')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function PaymentInfoDialog({ open, setOpen, paymentInfo }) {
  // 포트원 페이팔 (해외결제) 에서만 사용됨
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const { getPlanDisplayName, getCurrencySymbol } = usePricing()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const user = useRecoilValue(userAtom)
  const { paymentType, plan, price, currency } = paymentInfo

  const scriptId = `paypal-fraudnet-script`

  useEffect(() => {
    if (!plan?.id || !open) return
    initPayment(paymentInfo)

    // 버튼 렌더링 이후 금액 등을 수정할 때
    // IMP.updateLoadUIRequest('paypal-spb', requestData)
  }, [open, paymentInfo])

  useEffect(() => {
    if (!open) {
      const fraudnetScript = document.getElementById(scriptId)
      if (fraudnetScript) document.getElementsByTagName('head')[0].removeChild(fraudnetScript)
    }
  }, [open])

  const initPayment = ({ paymentType, plan, price, currency }) => {
    // 구매 데이터 준비
    const uid = generatePaymentUid()
    const customPaymentInfo = {
      payment_type: paymentType.toLowerCase(),
      payment_cycle: plan.cycle,
      order_id: uid,
      credit_amount: plan.creditAmount,
      plan_id: plan.id,
      plan_name: getPlanDisplayName(plan.id),
      user_id: user.id,
      amount: price, // 금액
      currency: currency.toUpperCase(),
      plan_product_name: 'Draph Art - ' + getPlanDisplayName(plan.id),
    }
    addSessionStorageData(customPaymentInfo)

    apis.payment.imp_prepare(customPaymentInfo).then(response => {
      if (!response.data.success) {
        // TODO 영문화
        alert('결제 준비 도중 에러가 발생하였습니다.')
        handleClose()
        return
      }

      // ----- GA4 event -----
      window.gtag('event', 'begin_checkout', {
        item_category: paymentType, // subscription || paygo
        item_id: plan.id,
        item_name: getPlanDisplayName(plan.id),
        price,
        currency,
      })
      // ---------------------

      let requestData = {
        merchant_uid: uid,

        pg: PAYPAL_PG,
        pay_method: 'paypal',

        notice_url: IMP_NOTICE_URL,

        bypass: {
          paypal_v2: {
            additional_data: [
              {
                key: 'sender_account_id', // 구매자 계정 아이디
                value: user.username,
              },
              // {
              //   key: 'sender_first_name', // 가맹점의 account에 등록 된 구매자의 이름
              //   value: user.username,
              // },
              // {
              //   key: 'sender_last_name', // 가맹점의 account에 등록 된 구매자의 이름
              //   value: user.username,
              // },
              {
                key: 'sender_email', // 가맹점의 account에 등록 된 구매자의 이메일 주소
                value: user.email ?? '',
              },
              {
                key: 'sender_phone', // 가맹점의 account에 등록 된 구매자의 연락처
                value: user.phone ?? '',
              },
              {
                key: 'sender_country_code', // 가맹점의 account에 등록 된 국가 코드
                value: window.localStorage.getItem('country_code') ?? '', // ISO Alpha-2 형식 국가 코드
              },
              {
                key: 'sender_create_date', // 가맹점의 account에 등록 된 구매자의 계정 생성일
                value: user.created ?? '', // IOS8601 형식
              },
            ],
          },
        },
      }
      const uiType = paymentType?.toLowerCase() === 'subscription' ? 'paypal-rt' : 'paypal-spb'

      if (paymentType?.toLowerCase() === 'subscription') {
        const customerUid = `${user.id}-${uid}`
        const firstPaymentUid = generatePaymentUid() // loadUI 콜백 안에서 생성하면 절대 안됨! 중복 결제 위험

        // 페이팔 [Manges & Fraudnet 이상 거래 대응] 스크립트
        const fraudnetScript = document.createElement('script')

        fraudnetScript.setAttribute('id', scriptId)
        fraudnetScript.setAttribute('type', 'application/json')
        fraudnetScript.setAttribute('fncls', 'fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99')
        fraudnetScript.setAttribute('s', 'subscription-checkout-dialog')
        fraudnetScript.setAttribute('f', firstPaymentUid)
        fraudnetScript.setAttribute('sandbox', isDev)
        document.getElementsByTagName('head')[0].appendChild(fraudnetScript)
        // -------------------------------------------

        requestData = {
          ...requestData,
          name: 'Draph Art - ' + getPlanDisplayName(plan.id),
          customer_uid: customerUid,
          customer_id: user.id,
          custom_data: customPaymentInfo,
        }

        IMP.loadUI(uiType, requestData, async r => {
          if (r.error_code?.length > 0) {
            const msg = r?.error_msg
            console.log(uiType, r)
            console.log(`페이팔 결제 오류 또는 결제 취소 ${msg && ' - ' + msg}`)
            console.log('requset data ', requestData)
            return
          }
          // 페이팔은 빌링키 발급시 결제가 되지 않고, 0원짜리 결제 기록만 남음
          // 따라서 첫번째 결제 요청을 따로 진행해준다
          setLoading(true)

          const res = await apis.payment.imp_billing({
            customer_uid: customerUid,
            merchant_uid: firstPaymentUid,
            billing_key_merchant_uid: r.merchant_uid,
            currency: currency.toUpperCase(),
            payment_method: 'paypal',
            custom_data: customPaymentInfo,
            notice_url: IMP_NOTICE_URL,
          })
          setLoading(false)
          paymentCallback(r, customPaymentInfo, firstPaymentUid)
        })
      } else {
        requestData = {
          ...requestData,
          name: 'Draph Art - ' + getPlanDisplayName(plan.id),
          amount: price, // 금액
          currency: currency.toUpperCase(),
          custom_data: customPaymentInfo,
        }

        IMP.loadUI(uiType, requestData, r => {
          // 결제 종료 후 실행되는 콜백 (비정상 종료, 오류 등 발생해도 호출됨)
          if (r.error_code?.length > 0) {
            const msg = r?.error_msg
            console.log(uiType, r)
            console.log(`페이팔 결제 오류 또는 결제 취소 ${msg && ' - ' + msg}`)
            console.log('requset data ', requestData)
            return
          }
          paymentCallback(r, customPaymentInfo, uid)
        })
      }

      setLoading(false)
    })
  }

  const paymentCallback = (response, paymentInfo, orderId) => {
    setLoading(true)
    // ----- GA4 event -----
    window.gtag('event', 'purchase', {
      item_category: paymentInfo.payment_type, // subscription || paygo
      item_id: paymentInfo.plan_id,
      item_name: getPlanDisplayName(paymentInfo.plan_id),
      price,
      currency,
      transaction_id: orderId,
    })
    // ---------------------

    // ----- Google Ads event -----
    window.gtag_report_conversion_purchase(price, orderId, currency)
    // ----------------------------
    // ----- Naver Ads event -----
    var _nasa = {}
    if (window.wcs) _nasa.cnv = window.wcs.cnv('purchase', price)
    if (window.runNaScript) window.runNaScript()
    // ----------------------------
    // ----- Meta(facebook) pixel event -----
    window.fbq('track', 'Purchase', {
      value: price,
      currency,
    })
    // --------------------------------------
    // ----- linkedin event -----
    window.lintrk('track', { conversion_id: 17109489 })
    // --------------------------

    setOpen(false)

    console.log(response)
    setLoading(false)

    navigate('/paid', {
      state: {
        paymentResponse: {
          // 결제완료 페이지에서 사용하는 데이터 포맷에 맞게...
          PCD_PAY_TOTAL: price,
          PCD_PAY_TIME: new Date(),
        },
        paymentInfo,
      },
    })
  }

  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return
    setOpen(false)
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              maxWidth: 'unset',
              borderRadius: '20px',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <DialogContent sx={{ p: 0 }}>
            {loading && <Loading />}
            <CenterAlignStack
              spacing={2}
              sx={{
                pt: '3.5rem',
                width: '36rem',
              }}
            >
              <Typography sx={{ fontSize: '2.4rem', fontWeight: 800 }}>
                {t('payment.complete_purchase')}
              </Typography>

              <Stack
                sx={{
                  width: '100%',
                  background: '#F8F8F8',
                  height: '16rem',
                  py: '2rem',
                  px: '3rem',
                }}
              >
                <Stack
                  direction="row"
                  sx={{ fontSize: '1.6rem', fontWeight: 400, justifyContent: 'space-between' }}
                >
                  <span>
                    {t('payment.plan')}: {getPlanDisplayName(plan?.id)}
                  </span>
                  <span style={{ color: theme.palette.draph.blue }}>
                    {getCurrencySymbol(currency)} {price} {currency?.toUpperCase()}
                  </span>
                </Stack>
                <Divider
                  sx={{
                    mt: '6rem',
                    mb: '1rem',
                    borderColor: '#303030',
                    borderBottomWidth: '2px',
                  }}
                />
                <Stack
                  direction="row"
                  sx={{ fontSize: '1.6rem', fontWeight: 700, justifyContent: 'space-between' }}
                >
                  <span>{t('payment.total')}</span>
                  <span style={{ color: theme.palette.draph.blue }}>
                    {getCurrencySymbol(currency)} {price} {currency?.toUpperCase()}
                  </span>
                </Stack>
              </Stack>

              <Box sx={{}}>
                {paymentType?.toLowerCase() === 'subscription' ? (
                  <div className="portone-ui-container" data-portone-ui-type="paypal-rt"></div>
                ) : (
                  <div className="portone-ui-container" data-portone-ui-type="paypal-spb"></div>
                )}
              </Box>

              <Typography
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: '#808080',
                  fontSize: '1.4rem',
                  fontWeight: 500,
                  pb: '2rem',
                }}
                onClick={handleClose}
              >
                {t('payment.cancel_purchase')}
              </Typography>
            </CenterAlignStack>
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={open}
          onOpen={() => {}}
          onClose={handleClose}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignBox
            sx={{
              mt: '1.6rem',
              mb: '4rem',
            }}
          >
            <Box
              sx={{
                width: '4rem',
                height: '0.4rem',
                backgroundColor: '#BCBCBC',
                borderRadius: '20px',
              }}
            ></Box>
          </CenterAlignBox>
          {loading && <Loading />}
          <CenterAlignStack
            spacing={2}
            sx={{
              width: '36rem',
            }}
          >
            <Typography sx={{ fontSize: '2.4rem', fontWeight: 800 }}>
              {t('payment.complete_purchase')}
            </Typography>

            <Stack
              sx={{
                width: '100%',
                background: '#F8F8F8',
                height: '16rem',
                py: '2rem',
                px: '3rem',
              }}
            >
              <Stack
                direction="row"
                sx={{ fontSize: '1.6rem', fontWeight: 400, justifyContent: 'space-between' }}
              >
                <span>
                  {t('payment.plan')}: {getPlanDisplayName(plan?.id)}
                </span>
                <span style={{ color: theme.palette.draph.blue }}>
                  {getCurrencySymbol(currency)} {price} {currency?.toUpperCase()}
                </span>
              </Stack>
              <Divider
                sx={{
                  mt: '6rem',
                  mb: '1rem',
                  borderColor: '#303030',
                  borderBottomWidth: '2px',
                }}
              />
              <Stack
                direction="row"
                sx={{ fontSize: '1.6rem', fontWeight: 700, justifyContent: 'space-between' }}
              >
                <span>{t('payment.total')}</span>
                <span style={{ color: theme.palette.draph.blue }}>
                  {getCurrencySymbol(currency)} {price} {currency?.toUpperCase()}
                </span>
              </Stack>
            </Stack>

            <Box sx={{}}>
              {paymentType?.toLowerCase() === 'subscription' ? (
                <div className="portone-ui-container" data-portone-ui-type="paypal-rt"></div>
              ) : (
                <div className="portone-ui-container" data-portone-ui-type="paypal-spb"></div>
              )}
            </Box>

            <Typography
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                color: '#808080',
                fontSize: '1.4rem',
                fontWeight: 500,
                pb: '2rem',
              }}
              onClick={handleClose}
            >
              {t('payment.cancel_purchase')}
            </Typography>
          </CenterAlignStack>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}
