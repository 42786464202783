import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Link,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  TextField,
  Stack,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { CenterAlignBox, CenterAlignStack, StyledFormTextField, FormErrorMessage } from 'components'
import { apis } from 'apis'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useRecoilState } from 'recoil'
import { userAtom } from 'atoms'
import { styled, useTheme } from '@mui/material/styles'
import { CheckedSquareIcon, SquareIcon, VerticalDividerIcon } from 'theme/icon'
import { motion } from 'framer-motion'
import { useSnackbar } from 'notistack'
import { EMAIL_REGEX } from 'utils/common'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { buttonMobileStyle } from './Login'
import useConfirm from 'hooks/useConfirm'
import { useTranslation } from 'react-i18next'

const buttonStyle = {
  width: '38.4rem',
  height: '5.2rem',
  my: '1rem',
  mt: '2.4rem',
}

const buttonStyle2 = {
  width: '26rem',
  height: '4.8rem',
  my: '1rem',
  mt: '2.4rem',
}

const TextButtonStyle = {
  fontSize: '1.2rem',
  fontWeight: 400,
  color: 'black',
  cursor: 'pointer',
}

export default function FindID() {
  const [find, setFind] = useState(true)
  const [result, setResult] = useState('')
  const [info, setInfo] = useState({})

  return (
    <motion.div
      initial={{ x: 10, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {find ? (
        <FindForm find={find} setFind={setFind} setResult={setResult} setInfo={setInfo} />
      ) : (
        <Result result={result} info={info} />
      )}
    </motion.div>
  )
}

function FindForm({ setFind, setResult, setInfo }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { showConfirm } = useConfirm()

  const { t, i18n } = useTranslation()

  const [loading, setLoading] = useState(false)

  const findIDSchema = yup.object().shape({
    email: yup
      .string()
      .matches(EMAIL_REGEX, t('register.email_error_1'))
      .required(t('register.email_error_2')),
  })

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(findIDSchema),
  })

  useEffect(() => {}, [])

  const onSubmit = values => {
    setLoading(true)

    apis.user
      .findUser(values.email)
      .then(response => {
        const d = response.data
        setFind(false)
        setResult(d.username)
        setInfo(d)
      })
      .catch(error => {
        if (error.response.status === 404) {
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('reset_password.error_1')}</Typography>,
          })
          setFind(true)
          setResult('')
        }
      })
      .finally(() => {
        setLoading(false)
      })

    // ----- GA4 event -----
    window.gtag('event', 'find_id', { method: 'complete' })
    // ---------------------
  }

  return (
    <>
      <CenterAlignStack sx={{ pt: '8rem' }}>
        <Typography fontSize="3rem" fontWeight={800}>
          {t('find_id.find')}
        </Typography>
        <Typography color={theme.palette.text.lightgrey}>{t('find_id.notice_1')}</Typography>
        <Desktop>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack sx={{ py: '2rem' }}>
              <StyledFormTextField
                placeholder={t('register.email')}
                name="email"
                control={control}
                errors={errors}
              />

              {errors.email?.message.length > 0 && (
                <FormErrorMessage message={errors.email?.message} />
              )}

              <LoadingButton
                size="large"
                type="submit"
                // onClick={handleClick}
                // endIcon={<SendIcon />}
                loading={loading}
                // loadingPosition="end"
                variant="contained"
                sx={buttonStyle}
              >
                {t('find_id.find')}
              </LoadingButton>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                sx={{ width: '100%', mb: '3rem' }}
              >
                <Typography sx={TextButtonStyle} onClick={() => navigate('/login')}>
                  {t('reset_password.back_login_button')}
                </Typography>
              </Stack>
            </Stack>
          </form>
        </Desktop>

        <Mobile>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack sx={{ py: 5 }}>
              <StyledFormTextField
                placeholder={t('register.email')}
                name="email"
                control={control}
                errors={errors}
              />

              {errors.email?.message.length > 0 && (
                <FormErrorMessage message={errors.email?.message} />
              )}

              <LoadingButton
                size="large"
                type="submit"
                // onClick={handleClick}
                // endIcon={<SendIcon />}
                loading={loading}
                // loadingPosition="end"
                variant="contained"
                sx={buttonMobileStyle}
              >
                {t('find_id.find')}
              </LoadingButton>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                sx={{ width: '100%', mt: '0.7rem' }}
              >
                <Typography sx={TextButtonStyle} onClick={() => navigate('/login')}>
                  {t('reset_password.back_login_button')}
                </Typography>
              </Stack>
            </Stack>
          </form>
        </Mobile>
      </CenterAlignStack>
    </>
  )
}

function Result({ result, info }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const [snsName, setSnsName] = useState('')

  useEffect(() => {
    if (info.login_sns === 'naver') {
      setSnsName('네이버')
    } else if (info.login_sns === 'kakao') {
      setSnsName('카카오')
    } else if (info.login_sns === 'google') {
      setSnsName(t('find_id.google'))
    }
  }, [info.login_sns, i18n.language])

  return (
    <CenterAlignStack sx={{ pt: '8rem' }}>
      <Typography fontSize="3rem" fontWeight={800}>
        {t('find_id.notice_2')}
      </Typography>
      <Typography color={theme.palette.text.lightgrey}>{t('find_id.notice_3')}</Typography>
      <CenterAlignStack sx={{ py: '3rem' }}>
        <CenterAlignBox
          sx={{
            width: '100%',
            height: { lg: '8rem', xs: '5rem' },
            alignItems: 'center',
          }}
        >
          {['naver', 'kakao', 'google'].includes(info.login_sns) ? (
            <CenterAlignStack>
              <Typography
                fontSize="1.8rem"
                fontWeight={500}
                color={theme => theme.palette.common.black}
                sx={{ mb: '1rem' }}
              >
                {`${info.email}`}
              </Typography>
              <Typography
                fontSize="2rem"
                fontWeight={500}
                color={theme => theme.palette.draph.blue}
                sx={{ mb: '1rem' }}
              >
                {`[${snsName}] ${t('find_id.notice_4')}`}
              </Typography>
            </CenterAlignStack>
          ) : (
            <Typography
              fontSize="2.4rem"
              fontWeight={500}
              color={theme => theme.palette.draph.blue}
            >
              {result}
            </Typography>
          )}
        </CenterAlignBox>

        <LoadingButton
          size="large"
          type="submit"
          onClick={() => navigate('/login')}
          variant="contained"
          sx={buttonStyle2}
        >
          {t('menu.login')}
        </LoadingButton>
        {!['naver', 'kakao', 'google'].includes(info.login_sns) && (
          <LoadingButton
            size="large"
            type="submit"
            onClick={() => navigate('/findPW')}
            variant="outlined"
            sx={{ ...buttonStyle2, mt: '0.4rem' }}
          >
            {t('reset_password.reset')}
          </LoadingButton>
        )}
      </CenterAlignStack>
    </CenterAlignStack>
  )
}
