import { useRecoilState, useRecoilValue } from 'recoil'
import { currentMenuAtom, pleaseLoginDialogAtom } from 'atoms'

import { useMenu } from './useMenu'

export default function usePleaseLoginDialog() {
  const [dialog, setDialog] = useRecoilState(pleaseLoginDialogAtom)
  const { getCurrentMenu } = useMenu()

  const showPleaseLoginDialog = props => {
    setDialog({ ...dialog, open: true, ...props })
  }

  const addClickInterceptor = target => {
    target.addEventListener('click', e => {
      // 처리
      // console.log(e)
      showPleaseLoginDialog()

      e.stopPropagation()
      e.preventDefault()

      const currentMenu = getCurrentMenu()

      // 각 메뉴의 상단 업로드 영역에서 탭 안쪽을 클릭하면 다이얼로그가 뜨면서 GA 이벤트 수집
      // ----- GA4 event -----
      window.gtag('event', 'non_member_try_to_upload', { menu: currentMenu.id })
      // ---------------------
    })
  }

  return {
    dialog,
    setDialog,
    showPleaseLoginDialog,
    addClickInterceptor,
  }
}
