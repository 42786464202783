import { useEffect, useRef, useState } from 'react'
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom'
import { apis } from 'apis'
import {
  dateFilterAtom,
  dateFilterOnAtom,
  loadingAtom,
  portfolioAtom,
  searchPortfolioAtom,
  themeFilterAtom,
  viewModeAtom,
  portfolioDeleteModeAtom,
  tutorialAtom,
  searchUserPortfolioAtom,
  userAtom,
  defaultPortfolioAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  CenterAlignBox,
  CenterAlignStack,
  FlexBasis,
  ShowingPortfolio,
  SearchInput,
  ScrollToTop,
  SearchInputMobile,
} from 'components'
import {
  Button,
  Divider,
  Fade,
  IconButton,
  TextField,
  Popover,
  Popper,
  Typography,
  Stack,
  InputAdornment,
  Input,
} from '@mui/material'
import { getAccessToken } from 'utils/cookie'

import { DateRange } from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { useTranslation } from 'react-i18next'
import { filteredPortfolioSelector } from 'selector'
import Paper from 'theme/overrides/Paper'
import { Box } from '@mui/system'
import {
  PortfolioThumbnailViewIcon,
  TrashCanIcon,
  PortfolioDateViewIcon,
  PortfolioTableViewIcon,
  VerticalDividerIcon,
  SearchIconMobile,
  TrashCanIconMobile,
  CalendarIcon,
  RefreshIcon,
  ArtworkGrayCloseIcon,
  CloseIcon,
} from 'theme/icon'
import { styled, useTheme, alpha } from '@mui/material/styles'
import { padZeros } from 'utils/common'
import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { PopupComponent, Tutorial } from 'theme/Tutorial'
import { useGridStrategyProcessing } from '@mui/x-data-grid/hooks/core/strategyProcessing'
import usePleaseLoginDialog from '../hooks/usePleaseLoginDialog'
import { languageAtom } from '../atoms'

const viewButtonPadding = '1.2rem'
export const MOBILE_PORTFOLIO_WIDTH_LIMIT = '36rem'

const NewPortfolioButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  width: '26rem',
  height: '6rem',
  fontSize: '2rem',
  fontWeight: 800,
}))
const NewPortfolioButtonMobile = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  width: '18rem',
  height: '4.2rem',
  fontSize: '1.5rem',
  fontWeight: 800,
  borderWidth: '0.1rem',
}))

// TODO 달력 디자인 다시 맞춰야함
const StyledDateRange = styled(DateRange)(({ theme }) => ({
  '& .rdrDayToday .rdrDayNumber span:after': {
    // 오늘 날짜 밑줄
    bottom: '5px',
    width: '12px',
    height: '1px',
    borderRadius: '2px',
    background: theme.palette.draph.lightblue,
  },
  '&  .rdrDayNumber': { bottom: 0, top: 0, '& span': { marginBottom: '12px' } },
  '& .rdrDayStartPreview, & .rdrDayEndPreview, & .rdrDayInPreview': {
    // hover 시 날짜 테두리 컬러
    borderColor: alpha(theme.palette.draph.blue, 0.6),

    top: 0,
    bottom: 0,

    '&.rdrDayStartPreview': {
      left: '2px',
    },

    '&.rdrDayEndPreview': {
      right: '2px',
    },
  },

  '& .rdrStartEdge, & .rdrInRange, & .rdrEndEdge': {
    background: theme.palette.draph.blue,

    top: 0,
    bottom: 0,
  },

  '& span.rdrMonthAndYearPickers': {
    fontSize: '1.6rem',
    fontWeight: 600,
  },

  '& .rdrNextPrevButton': {
    background: 'transparent',
    '&:hover': {
      background: 'transparent',
    },

    '&.rdrPprevButton i': {
      borderColor: 'transparent black transparent transparent',
    },

    '&.rdrNextButton i': {
      borderColor: 'transparent transparent transparent black',
    },
  },
  '&.rdrCalendarWrapper': {},

  '& .rdrWeekDays .rdrWeekDay': {
    fontSize: '1.4rem',
    fontWeight: 400,
    color: 'black',

    '&:first-of-type': {
      color: theme.palette.common.red,
    },
    '&:last-child': {
      color: theme.palette.draph.blue,
    },
  },

  '& .rdrDays': {
    fontSize: '1.4rem',
    fontWeight: 300,
    '& .rdrDay': {
      // width: '3.5rem',
      height: '3.3rem',
    },
    '& .rdrDay span': {
      height: '3rem',
    },

    '& .rdrDay.rdrDayStartOfWeek.rdrDayPassive .rdrDayNumber span': {
      color: alpha(theme.palette.common.red, 0.4),
    },
    '& .rdrDay.rdrDayStartOfWeek:not(.rdrDayPassive) .rdrDayNumber span': {
      color: theme.palette.common.red,
    },

    '& .rdrDay.rdrDayEndOfWeek.rdrDayPassive .rdrDayNumber span': {
      color: alpha(theme.palette.draph.blue, 0.4),
    },
    '& .rdrDay.rdrDayEndOfWeek:not(.rdrDayPassive) .rdrDayNumber span': {
      color: theme.palette.draph.blue,
    },

    '.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span':
      {
        color: theme.palette.common.white,
      },
  },
}))

export default function Portfolio() {
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const [portfolio, setPortfolio] = useRecoilState(portfolioAtom)
  const [dateRange, setDateRange] = useRecoilState(dateFilterAtom)

  // 포폴받는 중일 때 분기를 막기 위해
  const [isLoading, setIsLoading] = useRecoilState(loadingAtom)
  const filteredPortfolios = useRecoilValue(filteredPortfolioSelector)
  const [searchPortfolio, setSearchPortfolio] = useRecoilState(searchPortfolioAtom)
  const [searchUserPortfolio, setSearchUserPortfolio] = useRecoilState(searchUserPortfolioAtom)
  const [defaultPortfolio, setDefaultPortfolio] = useRecoilState(defaultPortfolioAtom)
  const user = useRecoilValue(userAtom)

  const resetPortfolio = useResetRecoilState(portfolioAtom)
  const resetDateFilter = useResetRecoilState(dateFilterAtom)
  const resetIsLoading = useResetRecoilState(loadingAtom)
  const resetFilterOn = useResetRecoilState(dateFilterOnAtom)

  const [filterOn, setFilterOn] = useRecoilState(dateFilterOnAtom)
  const [viewMode, setViewMode] = useRecoilState(viewModeAtom)
  const { t, i18n } = useTranslation()

  const [startDateString, setStartDateString] = useState('')
  const [endDateString, setEndDateString] = useState('')
  const [popperOpen, setPopperOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const [deleteMode, setDeleteMode] = useRecoilState(portfolioDeleteModeAtom)
  const resetDeleteMode = useResetRecoilState(portfolioDeleteModeAtom)

  const scrollRef = useRef()

  const theme = useTheme()
  const navigate = useNavigate()

  const { showConfirm } = useConfirm()
  const token = getAccessToken()

  const { addClickInterceptor } = usePleaseLoginDialog()

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'portfolio', {})
    // ---------------------

    if (!token) {
      const targets = document.querySelectorAll('.portfolio-list-wrapper')
      targets.forEach(t => {
        addClickInterceptor(t)
      })
    }
    return () => {
      resetDeleteMode()
    }
  }, [])

  useEffect(() => {
    setStartDateString(
      dateRange[0].startDate.getFullYear() +
        '.' +
        padZeros(dateRange[0].startDate.getMonth() + 1) +
        '.' +
        padZeros(dateRange[0].startDate.getDate())
    )

    setEndDateString(
      dateRange[0].endDate.getFullYear() +
        '.' +
        padZeros(dateRange[0].endDate.getMonth() + 1) +
        '.' +
        padZeros(dateRange[0].endDate.getDate())
    )
  }, [dateRange])

  const handleClick = mode => () => {
    setViewMode(m => ({ type: mode, page: 1 }))
    navigate(`/portfolio?pages=1`)
    // ----- GA4 event -----
    window.gtag('event', 'portfolio_view', { method: mode })
    // ---------------------
  }

  const refreshPortfolio = () => {
    apis.portfolio
      .getAllportfolio()
      .then(response => {
        setIsLoading(true)
        setPortfolio(response.data)

        const children = []
        response.data.forEach(p => {
          children.push({
            id: p.id,
            title: p.name,
            path: `/portfolio/${p.id}`,
            user: p.user,
          })
        })

        //
      })
      .finally(() => {
        setIsLoading(false)
      })

    apis.portfolio.getDefaultPortfolio().then(response => {
      setDefaultPortfolio({
        ...response.data,
        created: '',
        name: t(`configOrDict.DEFAULT_PORTFOLIO_NAME_DICT.upload`),
      })
    })
  }

  useEffect(() => {
    if (token) {
      refreshPortfolio()
    }

    return () => {
      resetPortfolio()
      resetDateFilter()
      resetIsLoading()
      resetFilterOn()
    }
  }, [])

  const searchPortfolioOnChange = e => {
    setSearchPortfolio(e.target.value)
  }

  const searchUserPortfolioOnChange = e => {
    setSearchUserPortfolio(e.target.value)
  }

  const handleDelete = method => {
    if (deleteMode.on) {
      if (deleteMode.ids.length > 0) {
        showConfirm({
          title: t('portfolio.delete'),
          content: (
            <Typography>
              {t('portfolio.delete_warning_a')}
              {deleteMode.ids.length}
              {t('portfolio.delete_warning_b')}
            </Typography>
          ),
          onConfirm: () => {
            // ----- GA4 event -----
            window.gtag('event', 'portfolio_delete', { method, count: deleteMode.ids.length })
            // ---------------------

            apis.portfolio.deleteMultiPortfolio(deleteMode.ids).then(() => {
              refreshPortfolio()
              resetDeleteMode()
            })
          },
        })
      }

      //
    } else {
      setDeleteMode({ on: true, ids: [] })
    }
  }

  const deleteModeOff = () => {
    resetDeleteMode()
  }

  const cancelDelete = () => {
    resetDeleteMode()
  }

  return (
    <>
      <Popover
        open={popperOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setPopperOpen(prev => !prev)
          setAnchorEl(null)
        }}
        placement="bottom-end"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CenterAlignStack>
          <StyledDateRange
            editableDateInputs={false}
            onChange={item => {
              setFilterOn(true)
              setDateRange([item.selection])
            }}
            showMonthAndYearPickers={false}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            locale={locales[i18n.language]}
            direction="vertical"
            // scroll={{ enabled: true }}
            showDateDisplay={false}
          />
          <Button
            variant="outlined"
            endIcon={<RefreshIcon />}
            sx={{
              mb: '2.9rem',
              width: '7.8rem',
              borderWidth: '0.1rem',
              '& .MuiButton-endIcon': {
                ml: '0.4rem',
              },

              '&:hover': {
                backgroundColor: theme => theme.palette.common.white,
                borderWidth: '0.1rem',
                color: theme => theme.palette.draph.blue,
                '& .MuiButton-endIcon path': {
                  transition: 'fill 0.3s',
                  fill: theme => theme.palette.draph.blue,
                },
              },
              '& .MuiButton-endIcon path': {
                transition: 'fill 0.3s',
              },
            }}
            onClick={() => {
              setDateRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }])
              setFilterOn(false)
              setSearchPortfolio('')
            }}
          >
            <Typography sx={{ fontSize: '1.2rem', fontWeight: 600, color: 'inherit' }}>
              {t('button.reset')}
            </Typography>
          </Button>
        </CenterAlignStack>
      </Popover>
      <Desktop>
        <CenterAlignStack forwardref={scrollRef}>
          <CenterAlignStack className="portfolio-list-wrapper" style={{ width: '100%' }}>
            <CenterAlignBox
              display="flex"
              justifyContent="space-between"
              alignItems="end"
              sx={{
                width: '100%',
                mt: '6.8rem',
                mb: '3.8rem',
                maxWidth: '130rem',
                px: '8.5rem',
              }}
            >
              <CenterAlignBox flexBasis="35%" sx={{ justifyContent: 'flex-start' }}>
                {user.is_admin && (
                  <SearchInput
                    value={searchUserPortfolio}
                    onChange={searchUserPortfolioOnChange}
                    placeholder="유저 포폴 검색"
                  />
                )}
              </CenterAlignBox>

              <Tutorial step={1}>
                <CenterAlignBox flexBasis="30%">
                  <NewPortfolioButton
                    variant="outlined"
                    onClick={() => {
                      navigate('/portfolio/new')
                      // ----- GA4 event -----
                      window.gtag('event', 'portfolio_new_create', {})
                      // ---------------------
                    }}
                  >
                    {t('button.create_new_portfolio')}
                  </NewPortfolioButton>
                </CenterAlignBox>
              </Tutorial>
              <CenterAlignBox flexBasis="35%" sx={{ justifyContent: 'flex-end' }}>
                <Typography
                  sx={{
                    border: 1,
                    borderWidth: '0.2rem',
                    borderColor: theme.palette.common.black,
                    px: '0.9rem',
                    width: '18rem',
                    height: '3.6rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mr: '1.6rem',
                  }}
                  fontSize="1.1rem"
                >
                  {filterOn
                    ? `${startDateString} ~ ${endDateString}`
                    : t('new_portfolio.entire_period')}
                  <IconButton
                    onClick={e => {
                      setAnchorEl(anchorEl ? null : e.currentTarget)
                      setPopperOpen(prev => !prev)
                      // ----- GA4 event -----
                      window.gtag('event', 'portfolio_search', { method: 'calendar' })
                      // ---------------------
                    }}
                    sx={{ padding: 0 }}
                  >
                    <CalendarIcon />
                  </IconButton>
                </Typography>

                <SearchInput
                  value={searchPortfolio}
                  onChange={searchPortfolioOnChange}
                  onFocus={() => {
                    // ----- GA4 event -----
                    window.gtag('event', 'portfolio_search', { method: 'keyword' })
                    // ---------------------
                  }}
                />
              </CenterAlignBox>
            </CenterAlignBox>

            <CenterAlignBox
              sx={{
                width: '100%',
                height: '7rem',
                borderColor: theme.palette.common.black,
                borderTop: 1,
                borderTopWidth: '0.2rem',
                borderBottom: 1,
                borderBottomWidth: '0.1rem',
              }}
            >
              <Box display="flex" sx={{ px: '8.5rem', width: '100%', maxWidth: '130rem' }}>
                <FlexBasis
                  flexBasis={['40%', '20%', '40%']}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ width: '100%', ml: '-1.8rem' }}
                  >
                    <IconButton
                      sx={{ width: '4.4rem', height: '4.4rem', p: viewButtonPadding }}
                      onClick={handleClick('thumbnail')}
                    >
                      <PortfolioThumbnailViewIcon
                        color={viewMode.type === 'thumbnail' ? 'draph.lightblue' : ''}
                      />
                    </IconButton>

                    <VerticalDividerIcon />

                    <IconButton
                      sx={{ width: '4.4rem', height: '4.4rem', p: viewButtonPadding }}
                      onClick={handleClick('date')}
                    >
                      <PortfolioDateViewIcon
                        color={viewMode.type === 'date' ? 'draph.lightblue' : ''}
                      />
                    </IconButton>

                    <VerticalDividerIcon />

                    <IconButton
                      sx={{ width: '4.4rem', height: '4.4rem', p: viewButtonPadding }}
                      onClick={handleClick('table')}
                    >
                      <PortfolioTableViewIcon
                        color={viewMode.type === 'table' ? 'draph.lightblue' : ''}
                      />
                    </IconButton>
                  </Stack>

                  <div></div>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ width: '100%' }}
                  >
                    {deleteMode.on && (
                      <Typography
                        color="text.lightgrey"
                        fontSize="1.5rem"
                        fontWeight={400}
                        sx={{ mr: '3.6rem', cursor: 'pointer' }}
                        onClick={cancelDelete}
                      >
                        {t('button.cancel')}
                      </Typography>
                    )}
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      onClick={() => {
                        handleDelete('header')
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TrashCanIcon color={deleteMode.on ? 'common.red' : 'text.lightgrey'} />
                      <Typography
                        color={deleteMode.on ? 'common.red' : 'text.lightgrey'}
                        fontSize="1.5rem"
                        fontWeight={400}
                        sx={{ ml: '0.7rem' }}
                      >
                        {t('portfolio.delete_comment_a')}
                        {/* {deleteMode.on && deleteMode.ids.length > 0 && `${deleteMode.ids.length}개 `}삭제 */}
                        {deleteMode.on
                          ? `${deleteMode.ids.length}` + t('portfolio.delete_comment_c')
                          : t('portfolio.delete_comment_b')}
                      </Typography>
                    </Stack>
                  </Stack>
                </FlexBasis>
              </Box>
            </CenterAlignBox>
          </CenterAlignStack>

          {token ? (
            <ShowingPortfolio
              deleteMode={deleteMode}
              setIsLoading={setIsLoading}
              setPortfolio={setPortfolio}
            />
          ) : (
            <EmptyList />
          )}
          <ScrollToTop upside={true} />
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <div className="portfolio-list-wrapper" style={{ width: '100%' }}>
          <CenterAlignStack sx={{ pt: '4.7rem', pb: '2.6rem' }}>
            <Typography fontSize="2.4rem" fontWeight={800}>
              {t('menu.portfolio')}
            </Typography>
            <Tutorial step={1}>
              <NewPortfolioButtonMobile
                variant="outlined"
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: 2 }))
                  navigate('/portfolio/new')
                  // ----- GA4 event -----
                  window.gtag('event', 'portfolio_new_create', {})
                  // ---------------------
                }}
                sx={{ mt: tutorial.mode ? null : '3.1rem' }}
              >
                {t('button.create_new_portfolio')}
              </NewPortfolioButtonMobile>
            </Tutorial>
          </CenterAlignStack>

          <CenterAlignBox
            sx={{
              width: '100%',
              height: '5rem',
              borderColor: theme.palette.common.black,
              borderTop: 1,
              borderTopWidth: '0.2rem',
              borderBottom: 1,
              borderBottomWidth: '0.1rem',
            }}
          >
            <Stack
              sx={{ width: '100%', maxWidth: MOBILE_PORTFOLIO_WIDTH_LIMIT }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" sx={{ ml: '0.8rem' }}>
                <IconButton
                  sx={{ width: '4.4rem', height: '4.4rem', p: viewButtonPadding }}
                  onClick={handleClick('thumbnail')}
                >
                  <PortfolioThumbnailViewIcon
                    color={viewMode.type === 'thumbnail' ? 'draph.lightblue' : ''}
                  />
                </IconButton>

                <VerticalDividerIcon />

                <IconButton
                  sx={{ width: '4.4rem', height: '4.4rem', p: viewButtonPadding }}
                  onClick={handleClick('date')}
                >
                  <PortfolioDateViewIcon
                    color={viewMode.type === 'date' ? 'draph.lightblue' : ''}
                  />
                </IconButton>

                <VerticalDividerIcon />

                <IconButton
                  sx={{ width: '4.4rem', height: '4.4rem', p: viewButtonPadding }}
                  onClick={handleClick('table')}
                >
                  <PortfolioTableViewIcon
                    color={viewMode.type === 'table' ? 'draph.lightblue' : ''}
                  />
                </IconButton>
              </Stack>

              <Stack direction="row" alignItems="center" sx={{ pr: '1rem' }}>
                {!deleteMode.on ? (
                  <>
                    <SearchInputMobile
                      value={searchPortfolio}
                      onChange={searchPortfolioOnChange}
                      onFocus={() => {
                        // ----- GA4 event -----
                        window.gtag('event', 'portfolio_search', { method: 'keyword' })
                        // ---------------------
                      }}
                    />
                    <IconButton
                      sx={{ px: '1rem' }}
                      onClick={e => {
                        setAnchorEl(anchorEl ? null : e.currentTarget)
                        setPopperOpen(prev => !prev)
                        // ----- GA4 event -----
                        window.gtag('event', 'portfolio_search', { method: 'calendar' })
                        // ---------------------
                      }}
                    >
                      <CalendarIcon color="#808080" />
                    </IconButton>
                  </>
                ) : (
                  <IconButton onClick={deleteModeOff} sx={{ mt: '0.2rem', p: '0.4rem' }}>
                    <CloseIcon style={{ width: '1.8rem', height: '1.8rem' }} color="#808080" />
                  </IconButton>
                )}
                <IconButton
                  sx={{ px: '1rem' }}
                  onClick={() => {
                    handleDelete('header')
                  }}
                >
                  <TrashCanIcon
                    style={{ width: '2rem', height: '2.3rem' }}
                    color={deleteMode.on ? 'common.red' : '#808080'}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </CenterAlignBox>
        </div>

        {token ? (
          <ShowingPortfolio
            deleteMode={deleteMode}
            setIsLoading={setIsLoading}
            setPortfolio={setPortfolio}
          />
        ) : (
          <EmptyList />
        )}
        <ScrollToTop upside={true} />
      </Mobile>
      {tutorial.mode && tutorial.step === 17 && <PopupComponent />}
    </>
  )
}

function EmptyList() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const lang = useRecoilValue(languageAtom)
  return (
    <CenterAlignStack sx={{ pt: '7rem' }}>
      <Typography
        sx={{
          fontSize: { lg: '1.7rem', xs: '1.3rem' },
          '& span': {
            color: theme => theme.palette.draph.blue,
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        }}
      >
        {lang === 'ko' ? (
          <>
            {t('artwork_list.message.login_and_enjoy_a')}
            <span
              onClick={() => {
                navigate('/login')
              }}
            >
              {t('artwork_list.message.login_and_enjoy_login')}
            </span>
            {t('artwork_list.message.login_and_enjoy_b')}
          </>
        ) : (
          <>
            <span
              onClick={() => {
                navigate('/login')
              }}
            >
              {t('artwork_list.message.login_and_enjoy_login')}
            </span>
            {t('artwork_list.message.login_and_enjoy_a')}
            {t('artwork_list.message.login_and_enjoy_b')}
          </>
        )}
      </Typography>
    </CenterAlignStack>
  )
}
