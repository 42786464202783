import { useMediaQuery } from 'react-responsive'

export const useDesktopMediaQuery = () => useMediaQuery({ query: '(min-width: 800px)' })
export const useMobileMediaQuery = () => useMediaQuery({ query: '(max-width: 799.5px)' })
export const useBreakPoint = () => useMediaQuery({ query: '(min-width: 545px)' })

export const Desktop = ({ children }) => {
  const isDesktop = useDesktopMediaQuery()
  return isDesktop ? children : null
}

export const Mobile = ({ children }) => {
  const isMobile = useMobileMediaQuery()
  return isMobile ? children : null
}
