import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  SwipeableDrawer,
  Typography,
  useTheme,
} from '@mui/material'
import { apis } from 'apis'
import { portfolioAtom, portfolioDetailAtom, portfolioTypeAtom, userAtom } from 'atoms'
import axios from 'axios'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { DialogButton } from 'components/DialogButtons'
import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { set } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PuffLoader } from 'react-spinners'
import { useRecoilValue } from 'recoil'
import ThemeConfig from 'theme'
import { getS3ImageSrc, iOS } from 'utils/common'

export default function GIFDialog({
  usePieces,
  open,
  setOpen,
  artworkId,
  refreshArtworks,
  refreshCallback = null,
}) {
  const [selectedPiece, setSelectedPiece] = useState([])
  const [loading, setLoading] = useState(false)
  const { showConfirm } = useConfirm()
  const { t } = useTranslation()
  const user = useRecoilValue(userAtom)
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const theme = useTheme()

  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return

    setOpen(false)
    setSelectedPiece([])
  }

  const GIFUpload = () => {
    const genImgUrlList = selectedPiece.map(p => getS3ImageSrc(p.path))
    const formData = new FormData()

    formData.append('user_id', user.id)
    formData.append('portfolio_id', portfolioDetail.id)
    formData.append('artwork_id', artworkId)
    formData.append('gen_img_url_list', genImgUrlList)

    const body = {
      user_id: user.id.toString(),
      portfolio_id: portfolioDetail.id,
      artwork_id: artworkId,
      gen_img_url_list: genImgUrlList,
    }

    setLoading(true)

    // ----- GA4 event -----
    window.gtag('event', portfolioType ? 'gif_complete' : 'portfolio_gif_complete', {
      count: genImgUrlList?.length,
    })
    // ---------------------

    apis.appfront
      .getAnimatedFile(body)
      .then(res => {
        setLoading(false)
        if (res.data.code === 200) {
          setOpen(false)
          refreshArtworks(refreshCallback)
        } else {
          return showConfirm({
            alertOnly: true,
            content: (
              <>
                <Typography>{t('upload_dialog.warning')}</Typography>
              </>
            ),
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })

    // axios.get(
    //   `/animated_img/`,
    //   {
    //     params,
    //   },
    //   {
    //     paramsSerializer: params => {
    //       return qs.stringify(params, { arrayFormat: 'brackets' })
    //     },
    //   }
    // )
  }

  // {
  //   userId: user.id,
  //   portfolioId: portfolioDetail.id,
  //   artworkId,
  //   genImgUrlList: JSON.stringify(genImgUrlList),
  // }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': {
              minWidth: '47.6rem',
              p: '3.8rem 4.5rem 3.4rem 4.5rem',
              borderRadius: '2rem',
              maxWidth: '65.4rem',
            },
          }}
        >
          <CenterAlignStack>
            <Typography sx={{ fontSize: '2rem', fontWeight: 700, lineHeight: 'normal' }}>
              움직이는 이미지 생성하기
            </Typography>
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 400 }}>
              AI로 새로 생성된 이미지를 움직이는 gif 파일로 만들어드립니다.
            </Typography>
          </CenterAlignStack>

          <CenterAlignBox>
            <CenterAlignBox
              sx={{
                p: '2.6rem 0 3.1rem 0',
                gap: '1.6rem',
                justifyContent: usePieces.length > 5 ? 'flex-start' : 'center',
                flexFlow: 'wrap',
              }}
            >
              {/* <Grid container spacing="1" columns={10}>
            {usePieces.map((p, i) => {
              const hasPieces = selectedPiece.some(piece => piece.id === p.id)
              return (
                <Grid item key={i} lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={getS3ImageSrc(p.path)}
                    onClick={() => {
                      if (hasPieces) {
                        setSelectedPiece([...selectedPiece].filter(piece => piece.id !== p.id))
                      } else {
                        setSelectedPiece([...selectedPiece, p])
                      }
                    }}
                    style={{
                      width: '10rem',
                      height: '10rem',
                      borderRadius: '1rem',
                      objectFit: 'cover',
                      outline: hasPieces ? `3px solid ${theme.palette.draph.blue}` : 'none',
                    }}
                  />
                </Grid>
              )
            })}
          </Grid> */}
              {usePieces.map((p, i) => {
                const hasPieces = selectedPiece.some(piece => piece.id === p.id)
                return (
                  <Box
                    key={i}
                    sx={{
                      position: 'relative',
                      width: '10rem',
                      height: '10rem',
                    }}
                  >
                    <img
                      src={getS3ImageSrc(p.path)}
                      // onClick={() => {
                      //   if (hasPieces) {
                      //     setSelectedPiece([...selectedPiece].filter(piece => piece.id !== p.id))
                      //   } else {
                      //     setSelectedPiece([...selectedPiece, p])
                      //   }
                      // }}
                      style={{
                        width: '10rem',
                        height: '10rem',
                        borderRadius: '1rem',
                        objectFit: 'cover',

                        // border: hasPieces ? `3px solid ${theme.palette.draph.blue}` : 'none',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        width: '10rem',
                        height: '10rem',
                        borderRadius: '1rem',
                        cursor: 'pointer',
                        top: 0,
                        border: hasPieces ? `3px solid ${theme.palette.draph.blue}` : 'none',
                      }}
                      onClick={() => {
                        if (hasPieces) {
                          setSelectedPiece([...selectedPiece].filter(piece => piece.id !== p.id))
                        } else {
                          setSelectedPiece([...selectedPiece, p])
                        }
                      }}
                    ></Box>
                  </Box>
                )
              })}
            </CenterAlignBox>
          </CenterAlignBox>

          <CenterAlignBox>
            <DialogButton
              actionText={loading ? <PuffLoader size="3.5rem" color="white" /> : '생성하기'}
              handleClose={handleClose}
              handleAction={GIFUpload}
              actionprops={{ disabled: !selectedPiece.length || loading }}
              cancelprops={{ disabled: loading }}
            />
          </CenterAlignBox>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={() => {
            setOpen(false)
          }}
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
          }}
          anchor="bottom"
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignStack sx={{ m: '0 3.4rem 2.7rem 3.4rem' }}>
            <CenterAlignBox
              sx={{
                mt: '1.6rem',
                mb: '4rem',
              }}
            >
              <Box
                sx={{
                  width: '4rem',
                  height: '0.4rem',
                  backgroundColor: '#BCBCBC',
                  borderRadius: '20px',
                }}
              ></Box>
            </CenterAlignBox>

            <CenterAlignStack>
              <Typography sx={{ fontSize: '1.6rem', fontWeight: 800, lineHeight: 'normal' }}>
                움직이는 이미지 생성하기
              </Typography>
              <Typography sx={{ fontSize: '1.2rem' }}>
                AI로 새로 생성된 이미지를 움직이는 gif 파일로 만들어드립니다.
              </Typography>

              <Box
                sx={{
                  p: '2.4rem 0 3.4rem 0',
                  width: '29.4rem',
                  display: 'flex',
                  flexFlow: 'wrap',
                  gap: '1.4rem',
                }}
              >
                {usePieces.map((p, i) => {
                  const hasPieces = selectedPiece.some(piece => piece.id === p.id)
                  return (
                    <img
                      key={i}
                      src={getS3ImageSrc(p.path)}
                      onClick={() => {
                        if (hasPieces) {
                          setSelectedPiece([...selectedPiece].filter(piece => piece.id !== p.id))
                        } else {
                          setSelectedPiece([...selectedPiece, p])
                        }
                      }}
                      style={{
                        width: '14rem',
                        height: '14rem',
                        borderRadius: '1rem',
                        objectFit: 'cover',
                        outline: hasPieces ? `3px solid ${theme.palette.draph.blue}` : 'none',
                      }}
                    />
                  )
                })}
              </Box>
            </CenterAlignStack>

            <Button
              variant="contained"
              onClick={GIFUpload}
              sx={{
                width: '20rem',
                height: '4.8rem',
                borderRadius: '1rem',
                fontSize: '1.8rem',
                fontWeight: 800,
              }}
              actionprops={{ disabled: !selectedPiece.length }}
              disabled={loading}
            >
              {loading ? <PuffLoader size={40} /> : '업로드'}
            </Button>
          </CenterAlignStack>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}
