import { Box, Stack, Typography } from '@mui/material'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { useEffect, useState } from 'react'
import { Swiper, Autoplay, FreeMode } from 'swiper'
import { Korean, English } from 'hooks/useLanguage'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import {
  MainStartButton,
  MainToIndexPageButton,
  SolidColorReverseButton,
} from 'components/CustomButtons'
import { getAccessToken } from 'utils/cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { apis } from 'apis'
import { ButtonTabs, ImageCarouselSwiper } from 'components/landing/LandingCommonComponent'
import { getS3ImageSrc, numberCommaFormatter, shuffle } from 'utils/common'
import { ImageDetailDialog } from './ImageDetailDialog'

const sampleImagesBefore = [
  '/static/images/main/before_after/1_before.png',
  '/static/images/main/before_after/2_before.png',
  '/static/images/main/before_after/3_before.png',
  '/static/images/main/before_after/4_before.png',
  '/static/images/main/before_after/5_before.png',
  '/static/images/main/before_after/6_before.png',
  // '/static/images/main/before_after/7_before.png',
  '/static/images/main/before_after/8_before.png',
  '/static/images/main/before_after/9_before.png',
  '/static/images/main/before_after/10_before.png',
  '/static/images/main/before_after/11_before.png',
]
const sampleImagesAfter = [
  '/static/images/main/before_after/1_after.png',
  '/static/images/main/before_after/2_after.png',
  '/static/images/main/before_after/3_after.png',
  '/static/images/main/before_after/4_after.png',
  '/static/images/main/before_after/5_after.png',
  '/static/images/main/before_after/6_after.png',
  // '/static/images/main/before_after/7_after.png',
  '/static/images/main/before_after/8_after.png',
  '/static/images/main/before_after/9_after.png',
  '/static/images/main/before_after/10_after.png',
  '/static/images/main/before_after/11_after.png',
]

const MAIN_FEATURE = [
  {
    id: 'concept',
    title: (
      <>
        <Korean>상품 배경 생성</Korean>
        <English>Background Generation</English>
      </>
    ),
    content: (
      <>
        <Korean>
          기본 상품이미지를 등록하고
          <Desktop>
            <br />
          </Desktop>
          원하는 배경을 선택하거나 입력하기만 하면,
          <Desktop>
            <br />
          </Desktop>
          드랩 AI가 그림자, 조명 등을 세밀하게 조정하여 컨셉 상품 배경을 생성합니다.
        </Korean>
        <English>
          Register the basic product image and simply select or enter your desired background.
          <Desktop>
            <br />
          </Desktop>
          Draph AI will then fine-tune shadows, lighting, and other details to generate a concept
          product image background.
        </English>
      </>
    ),
    image: '/static/images/main/feature/feature_concept.png',
  },
  {
    id: 'ai_model',
    title: (
      <>
        <Korean>AI 모델 생성</Korean>
        <English>Creating an AI Model</English>
      </>
    ),
    content: (
      <>
        <Korean>
          기본 상품 이미지를 등록하면,
          <Desktop>
            <br />
          </Desktop>
          해당 상품과 가장 잘 어울리는 AI모델을 생성하여
          <Desktop>
            <br />
          </Desktop>
          상품을 잘 표현하는 모델 촬영 컷으로 제작합니다.
        </Korean>
        <English>
          By registering the basic product image,
          <Desktop>
            <br />
          </Desktop>
          Draph AI will create an AI model that best complements the product
          <Desktop>
            <br />
          </Desktop>
          and it will be produced as a model shot that effectively represents the product.
        </English>
      </>
    ),
    image: '/static/images/main/feature/feature_ai_model.png',
  },
  {
    id: 'ads',
    title: (
      <>
        <Korean>광고 소재 생성</Korean>
        <English>Creating ads</English>
      </>
    ),
    content: (
      <>
        <Korean>
          브랜드 정보와 기본 상품이미지를 등록하면,
          <Desktop>
            <br />
          </Desktop>
          해당 제품을 가장 잘 나타낼 수 있는 카피라이트와 이미지를 생성하여
          <Desktop>
            <br />
          </Desktop>
          다양한 매체에 사용할 수 있는 광고 소재를 생성합니다.
        </Korean>
        <English>
          By registering your brand information and basic product images,
          <Desktop>
            <br />
          </Desktop>
          Draph AI generate ads that includes the most suitable copy and images
          <Desktop>
            <br />
          </Desktop>
          to best represent your product, which can then be used across various media platforms.
        </English>
      </>
    ),
    image: '/static/images/main/feature/feature_ads.png',
  },
  {
    id: 'change_model',
    title: (
      <>
        <Korean>마네킹 모델 생성</Korean>
        <English>Mannequin to AI Model</English>
      </>
    ),
    content: (
      <>
        <Korean>
          마네킹이 착장한 옷을 유지한 채로 실제 모델로 변경합니다.
          <Desktop>
            <br />
          </Desktop>
          또는 일반 모델이 촬영된 이미지 또한,
          <Desktop>
            <br />
          </Desktop>
          변경하지 않을 영역만 선택 후 생성을 클릭하면 전문 모델로 변경합니다.
        </Korean>
        <English>
          You can replace the mannequin with a real model while keeping the outfit intact.
          <Desktop>
            <br />
          </Desktop>
          If you select the areas you don’t want to change in an image featuring a non-professional
          model
          <Desktop>
            <br />
          </Desktop>
          and request generation, it will be replaced with a professional model.
        </English>
      </>
    ),
    image: '/static/images/main/feature/feature_change_model.png',
  },
]

const motionProps = {
  initial: { y: 10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  transition: { duration: 0.5 },
}

// SHUTTER_TEXT_XX 배열 길이 대충 맞춰서 쓰는걸로  ..
const SHUTTER_TEXT_KO = ['상품사진', '모델', '광고']
const SHUTTER_TEXT_EN = ['Product Images', 'Product Images', 'Product Images']

export function BestPracticeSection() {
  const token = getAccessToken()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const isMobile = useMobileMediaQuery()

  const [selectedFeature, setSelectedFeature] = useState(MAIN_FEATURE[0])
  const [stat, setStat] = useState({})

  const [shutterTextIdx, setShutterTextIdx] = useState(0)
  const [intervalId, setIntervalId] = useState()

  const changeFeature = f => {
    setSelectedFeature(f)
  }

  useEffect(() => {
    apis.stat.getStat().then(response => {
      setStat(response.data)
    })

    // 셔터 애니메이션 최초 시작 딜레이에 맞춰주기위해 timeout 2100ms
    setTimeout(() => {
      setTimeout(() => {
        setShutterTextIdx(i => (i + 1) % SHUTTER_TEXT_KO.length)
      }, 150)

      // 최초 셔터 애니메이션 이후 5초 주기 반복에 맞추어 바꾸기
      const iId = setInterval(() => {
        setTimeout(() => {
          setShutterTextIdx(i => (i + 1) % SHUTTER_TEXT_KO.length)
        }, 150)
      }, 5000)
      setIntervalId(iId)
    }, 2100)

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [i18n.language])

  useEffect(() => {}, [shutterTextIdx])

  return (
    <>
      <CenterAlignStack
        sx={{
          background: '#161616',
          pt: { lg: '7.2rem', xs: '8.4rem' },
        }}
      >
        <CenterAlignStack
          sx={{
            pb: { lg: '5.6rem', xs: '4.8rem' },
            '& .text-title': {
              display: 'flex',
              color: 'white',
              fontSize: { lg: '4rem', xs: '2.4rem' },
              lineHeight: 1.2,
              fontWeight: 700,
              mb: { lg: '0.6rem', xs: '0.4rem' },
              '& .shutter-text': {
                minWidth: { lg: '16.3rem', xs: '9.7rem' },
                mx: { lg: '0.3rem', xs: '0.1rem' },
                px: { lg: '1.2rem', xs: '0.7rem' },
                py: { lg: '0.2rem', xs: '0.1rem' },
                background: 'white',
                color: theme => theme.palette.draph.newblue,

                position: 'relative',
                '& .shutter': {
                  width: '100%',
                  animation: 'shutter 0.1s infinite',
                  animationDuration: '5s',
                  animationDelay: '2s',
                  height: 0,
                  position: 'absolute',
                  background: '#161616',
                  left: 0,
                  top: 0,
                  // transition: 'height 0.2s',
                },
              },
            },
            '& .text-subtitle': {
              color: 'white',
              textAlign: 'center',
              fontSize: { lg: '2rem', xs: '1.4rem' },
              lineHeight: 1.4,
              fontWeight: 500,
            },
          }}
        >
          <CenterAlignBox className="text-title">
            <Korean>
              드랩이&nbsp;
              <CenterAlignBox className="shutter-text">
                {SHUTTER_TEXT_KO[shutterTextIdx]}
                <span className="shutter"></span>
              </CenterAlignBox>
              &nbsp;만들게요.
            </Korean>
            <English>
              Draph will make you &nbsp;
              <CenterAlignBox className="shutter-text">
                {SHUTTER_TEXT_EN[shutterTextIdx]}
                <span className="shutter"></span>
              </CenterAlignBox>
            </English>
          </CenterAlignBox>

          <Typography className="text-title">
            <Korean>돈 많이 버는 건 누가 할래요?</Korean>
            <English>Who wants to make money?</English>
          </Typography>

          <Typography className="text-subtitle" sx={{ mt: '1.6rem' }}>
            <Korean>
              드랩아트는 생성형 AI 기술을 활용하여{' '}
              <Mobile>
                <br />
              </Mobile>
              상업용 상품사진 및 콘텐츠를
              <br />
              쉽고 간편하게 생산하는 솔루션입니다.
            </Korean>
            <English>
              Draph Art is easy & convenient AI Visual Solution
              <br /> that help you create commercial product images and contents.
            </English>
          </Typography>

          <MainToIndexPageButton sx={{ mt: { lg: '2.8rem', xs: '4.4rem' } }} />
        </CenterAlignStack>

        <CenterAlignStack sx={{ position: 'relative', width: { xs: '100vw' } }}>
          <Box
            sx={{
              width: { lg: '100vw', xs: '100%' },
              overflow: 'hidden',
              position: 'absolute',
              bottom: 0,
            }}
          >
            <ImageFlatCarouselSwiper images={sampleImagesBefore} sx={{}} />
          </Box>

          <CenterAlignStack
            sx={{
              position: 'relative',
              width: {
                lg: '43.1rem',
                xs: '20rem',
              },
              height: { lg: '51.2rem', xs: '25.5rem' },
              overflow: 'hidden',

              justifyContent: 'end',
            }}
          >
            <Box
              className="window"
              sx={{
                width: { lg: '39.3rem', xs: '18.4rem' },
                height: { lg: '42.4rem', xs: '21.6rem' },
                position: 'relative',
                // overflow: 'hidden',
                zIndex: 2,
                background: '#161616',
              }}
            >
              <Box
                sx={{
                  width: { lg: '100vw', xs: '100vw' },
                  position: 'absolute',
                  left: '50%',
                  transform: 'translate(-50%)',
                  bottom: 0,
                  zIndex: 3,
                }}
              >
                <ImageFlatCarouselSwiper images={sampleImagesAfter} sx={{}} />
              </Box>
            </Box>

            <Box
              sx={{
                position: 'absolute',
                top: 0,

                width: '100%',
                height: '100%',
                '& img': {
                  zIndex: 3,
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                },
                '& .bg': {
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  borderTopLeftRadius: { lg: '7.5rem', xs: '5.5rem' },
                  borderTopRightRadius: { lg: '7.5rem', xs: '5.5rem' },
                  background: 'white',
                },
              }}
            >
              <div className="bg"></div>
              <img src="/static/images/main/cellphone/empty_phone.png" />
            </Box>
          </CenterAlignStack>
        </CenterAlignStack>

        <Stack sx={{ pt: { lg: '16.8rem', xs: '8.8rem' } }}>
          <motion.div key={selectedFeature.id} {...motionProps}>
            <Stack
              direction="row"
              sx={{ mb: { lg: '5.2rem', xs: '2.4rem' }, alignItems: { lg: '', xs: 'center' } }}
            >
              <Box
                sx={{
                  width: { lg: '13.2rem', xs: '6.8rem' },
                  height: { lg: '13.2rem', xs: '6.8rem' },
                  '& img': {
                    width: '100%',
                  },
                }}
              >
                <img src={`/static/images/main/icon/${selectedFeature.id}_on.png`} />
              </Box>

              <Stack
                sx={{
                  ml: { lg: '2.6rem', xs: '1.2rem' },
                  '& .title': {
                    color: 'white',
                    fontSize: { lg: '4rem', xs: '1.8rem' },
                    fontWeight: 700,
                    lineHeight: 1.18,
                    mb: { lg: '1.2rem', xs: '0.5rem' },
                  },
                  '& .content': {
                    color: 'white',
                    fontSize: { lg: '2rem', xs: '1.2rem' },
                    fontWeight: 500,
                    lineHeight: 1.18,

                    width: { lg: 'unset', xs: '24rem' },
                  },
                }}
              >
                <Typography className="title">{selectedFeature.title}</Typography>
                <Typography className="content">{selectedFeature.content}</Typography>
              </Stack>
            </Stack>
          </motion.div>

          <Stack sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column-reverse' } }}>
            <motion.div key={selectedFeature.id} {...motionProps}>
              <Box
                sx={{
                  background: 'gray',
                  overflow: 'hidden',
                  width: { lg: '103.6rem', xs: '32rem' },
                  height: { lg: '40rem', xs: '' },
                  // height: { lg: '40rem', xs: '32rem' },
                  borderTopLeftRadius: { lg: '10rem', xs: '2rem' },
                  borderBottomRightRadius: { lg: '10rem', xs: '2rem' },
                  // borderTopLeftRadius: { lg: '10rem', xs: '5rem' },
                  // borderBottomRightRadius: { lg: '10rem', xs: '5rem' },
                  '& img': {
                    width: '100%',
                    // height: '100%',
                    // objectFit: 'cover',
                  },
                }}
              >
                <img src={selectedFeature.image} />
              </Box>
            </motion.div>

            <Stack
              sx={{
                ml: { lg: '4.4rem', xs: 0 },
                mb: { lg: 0, xs: '0.7rem' },

                display: 'flex',
                flexDirection: { lg: 'column', xs: 'row' },
                alignItems: { lg: 'start', xs: 'center' },
                justifyContent: { xs: 'space-between' },

                width: { lg: 'unset', xs: '32rem' },
                flexWrap: { lg: 'unset', xs: 'wrap' },
              }}
              spacing={{ lg: '3.6rem', xs: 0 }}
            >
              {MAIN_FEATURE.map(f => (
                <CenterAlignStack
                  key={f.id}
                  direction="row"
                  sx={{
                    cursor: 'pointer',
                    width: { lg: 'unset', xs: '50%' },
                    pl: { lg: 0, xs: '2.2rem' },
                    pb: { lg: 0, xs: '1.6rem' },

                    '& .title': {
                      color: selectedFeature.id === f.id ? 'white' : '#BBBBBB',
                      fontWeight: selectedFeature.id === f.id ? 700 : 500,
                      fontSize: { lg: '2.4rem', xs: '1.4rem' },
                      ml: { lg: '1.6rem', xs: '0.6rem' },
                    },
                  }}
                  onClick={() => {
                    changeFeature(f)
                  }}
                >
                  <Box sx={{ width: { lg: '4.8rem', xs: '2rem' } }}>
                    <img
                      src={`/static/images/main/icon/${f.id}_${
                        selectedFeature.id === f.id ? 'on' : 'off'
                      }.png`}
                    />
                  </Box>
                  <Typography className="title">{f.title}</Typography>
                </CenterAlignStack>
              ))}
            </Stack>
          </Stack>
        </Stack>

        <CenterAlignStack
          sx={{
            width: '100%',
            pt: { lg: '18rem', xs: '8.8rem' },
            pb: { lg: '12rem', xs: '8.8rem' },
          }}
        >
          {stat?.total_p && (
            <Typography
              sx={{
                color: 'white',
                fontSize: { lg: '2.4rem', xs: '1.4rem' },
                fontWeight: 600,
                lineHeight: 1.2,
                mb: { lg: '0.8rem', xs: '0.4rem' },
              }}
            >
              <Korean>
                지금까지 드랩이 생성한 이미지 수 {numberCommaFormatter(stat.total_p)} 개!
              </Korean>
              <English>
                {numberCommaFormatter(stat.total_p)} images were created with Draph AI so far !
              </English>
            </Typography>
          )}
          <Typography
            sx={{
              color: 'white',
              fontSize: { lg: '4.4rem', xs: '2.4rem' },
              fontWeight: 700,
              lineHeight: 1.2,
            }}
          >
            <Korean>생성 결과물을 확인해 보세요!</Korean>
            <English>Check out the results here</English>
          </Typography>

          <BestCarousel />
        </CenterAlignStack>
      </CenterAlignStack>
    </>
  )
}

function ImageFlatCarouselSwiper({ images, sx }) {
  const uuid = Math.ceil(Math.random() * 1000)

  useEffect(() => {
    const sw = new Swiper(`.image-flat-carousel-swiper #swiper-container-${uuid}`, {
      modules: [Autoplay],
      spaceBetween: 20,
      slidesPerView: 'auto',
      centeredSlides: true,

      // speed: 1000,
      speed: 5000,

      //   FreeMode: false,
      //   enteredSlides: true,

      //   loop 되려면  슬라이드 수가 slidesPerView 보다 2배 이상이어야 함
      loop: true,
      //   grabCursor: true,
      autoplay: { delay: 1 },
      preventInteractionOnTransition: true,
    })
  }, [])
  return (
    <>
      <Box
        className="image-flat-carousel-swiper"
        sx={{
          '& .swiper-wrapper': {
            // marquee 처럼 흘러가도록 하기위해
            // WebkitTransitionTimingFunction: 'linear !important',
            // transitionTimingFunction: 'linear !important',
          },

          '& .swiper-slide': {
            width: { lg: '40rem', xs: '20rem' },
            height: { lg: '40rem', xs: '20rem' },
            position: 'relative',
            '& h1': {
              background: 'white',
              p: '15px',
              position: 'absolute',
              left: 0,
              top: '40px',
            },
            '& .slide-image': {
              borderRadius: '2rem 2rem 0 0',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            },
          },
          ...sx,
        }}
      >
        <Box id={`swiper-container-${uuid}`} sx={{ width: '100%' }}>
          <Box component="div" className="swiper-wrapper" sx={{}}>
            {/* loop 되려면  슬라이드 수가 slidesPerView 보다 2배 이상이어야해서 이미지 넉넉하게 뿌려주기  */}
            {[...images, ...images, ...images].map((path, i) => (
              <div className="swiper-slide" key={i}>
                {/* 개발시 이미지 확인용 */}
                {/* <h1>{i}</h1> */}
                <img className="slide-image" src={path} />
              </div>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

const BEST_PRACTICE_DEFAULT_ITEMS = [
  {
    static: true,
    path: '/static/images/best_practice/1_after.png',
    original_image_path: '/static/images/best_practice/1_before.png',
    config: {},
    prompt:
      'room diffuser set against an organic backdrop of moss covered rocks and wood. The green background adds depth to the scene, creating contrast with the dark hues of the product. A minimalist aesthetic is featured in high resolution, capturing every detail on the black plastic cosmetic cap. This presentation style emphasizes modern design and simplicity in the style of minimalism',
  },
  {
    static: true,
    path: '/static/images/best_practice/2_after.png',
    original_image_path: '/static/images/best_practice/2_before.png',
    config: {},
    prompt:
      'A can of craft beer, next to the pool in an exotic environment, rendered in  the style of cinema 4d, orange color scheme, playful use of shapes, organic  architecture, cartoon realism, rendered with octane, pastel tones, low  angle shot, floating stone background, tropical landscape, product  photography, bright daylight, minimalistic, light and shadow play, natural  sunlight, green water',
  },
  {
    static: true,
    path: '/static/images/best_practice/3_after.png',
    original_image_path: '/static/images/best_practice/3_before.png',
    config: {},
    prompt: 'coffee pot, green leaves, a cup of coffee, film grain, super resolution',
  },
  {
    static: true,
    path: '/static/images/best_practice/4_after.png',
    original_image_path: '/static/images/best_practice/4_before.png',
    config: {},
    prompt:
      '(a jar of honey:1.3), on the white table, (with honey comb and flying bees:1.4), high resoulution, 8k, studio lighting, emauromin style, finely detailed, purism, minimalism, octane render',
  },
  {
    static: true,
    path: '/static/images/best_practice/5_after.png',
    original_image_path: '/static/images/best_practice/5_before.png',
    config: {},
    prompt:
      'Product photography of Nike sneakers floating in mid-air, (background of  clouds:1.4), high resolution, insanely detailed, hyperrealistic, with soft natural lighting highlighting the sneakers.',
  },
  {
    static: true,
    path: '/static/images/best_practice/6_after.png',
    original_image_path: '/static/images/best_practice/6_before.png',
    config: {},
    prompt:
      'Product photography, a bottle of blue perfume on the water surface, surrounded in the style of waves and splashes, clean background, soft light, closeup shot, minimalist style, surreal scene, water reflection, high resolution, depth of field effect, high details, best quality.',
  },
  {
    static: true,
    path: '/static/images/best_practice/8_after.png',
    original_image_path: '/static/images/best_practice/8_before.png',
    config: {},
    prompt:
      'A car on the moon, product photography, high resolution, dark background, studio lighting, commercial photography, highly detailed, high quality, sharp focus, wide angle lens, cinematic',
  },
  {
    static: true,
    path: '/static/images/best_practice/9_after.png',
    original_image_path: '/static/images/best_practice/9_before.png',
    config: {},
    prompt:
      'standing on top of an abstract textured stone pedestal, eucalyptus leaves in the background, neutral tones, minimalist aesthetic, shadowplay, studio photography, product photography, high resolution in the style of minimalist aesthetic.',
  },
  {
    static: true,
    path: '/static/images/best_practice/10_after.png',
    original_image_path: '/static/images/best_practice/10_before.png',
    config: {},
    prompt: '해변가',
  },
  {
    static: true,
    path: '/static/images/best_practice/11_after.png',
    original_image_path: '/static/images/best_practice/11_before.png',
    config: {},
    prompt:
      'cosmetic mockup, soft light and shadow, green leaves and flowers in the background, minimalistic background, green color palette, photorealism, volumetric light, blurred background, in the style of green leaves and flowers in the background',
  },
]

function BestCarousel() {
  const MIN_IMAGE_COUNT = 10

  const [images, setImages] = useState([])
  const [items, setItems] = useState(shuffle(BEST_PRACTICE_DEFAULT_ITEMS))
  const [currentItemIdx, setCurrentItemIdx] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)

  // const tabs = [
  //   {
  //     id: 'all',
  //     text: (
  //       <>
  //         <Korean>All</Korean>
  //         <English>All</English>
  //       </>
  //     ),
  //     content: <ImageCarouselSwiper images={images} />,
  //   },
  // ]
  // const [selectedTab, setSelectedTab] = useState(tabs[0])

  useEffect(() => {
    apis.common.getBestPracticeImages().then(response => {
      if (response.data) {
        let items = shuffle(response.data)
        if (items.length < MIN_IMAGE_COUNT) {
          items = [
            ...items,
            ...shuffle(BEST_PRACTICE_DEFAULT_ITEMS).slice(items.length - MIN_IMAGE_COUNT),
          ]
        }
        setItems(items)
      }
    })
  }, [])

  useEffect(() => {
    if (items) {
      const paths = items.map(item => (item.static ? item.path : getS3ImageSrc(item.path)))
      setImages(paths)
    }
  }, [items])

  useEffect(() => {}, [images])

  const onClickSlide = path => {
    const i = items.findIndex(x => {
      if (x.static) {
        return x.path === path
      } else {
        return path === getS3ImageSrc(x.path)
      }
    })

    if (Number.isInteger(i)) {
      setCurrentItemIdx(i)
      setOpenDialog(true)
    }
  }

  return (
    <>
      <CenterAlignStack sx={{ width: '100vw', pt: { lg: '6rem', xs: '3rem' } }}>
        {/* <ButtonTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          sx={{ width: '100%', '& .content': { width: '100%' } }}
        /> */}

        <Box sx={{ width: '100%' }}>
          <ImageCarouselSwiper
            images={images}
            onClickSlide={onClickSlide}
            sx={{ '& .swiper-slide': { cursor: 'pointer' } }}
          />
        </Box>
      </CenterAlignStack>

      <ImageDetailDialog
        open={openDialog}
        setOpen={setOpenDialog}
        items={items}
        currentItemIdx={currentItemIdx}
        setCurrentItemIdx={setCurrentItemIdx}
      />
    </>
  )
}
