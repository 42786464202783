import {
  Container,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Typography,
} from '@mui/material'

export default function AgreePrivacy() {
  const theme = useTheme()
  return (
    <Container sx={{ m: '0 auto', p: '0 !important', mt: '3rem' }}>
      <h2
        style={{
          textAlign: 'center',
          marginBottom: '20px',
          fontWeight: 800,
        }}
      >
        개인정보 수집 및 이용 동의약관
      </h2>
      <Table
        sx={{
          '& .MuiTableCell-root': { border: '1.5px solid rgb(195 195 195)' },
          '& .MuiTableCell-root.MuiTableCell-body': {
            p: '13px',
            fontSize: '1.3rem',
            wordBreak: 'keep-all',
          },
        }}
      >
        <TableHead sx={{ backgroundColor: 'rgb(102, 146, 255, 0.1)' }}>
          <TableRow sx={{ fontSize: '1.5rem' }}>
            <TableCell align="center">항목</TableCell>
            <TableCell align="center">목적</TableCell>
            <TableCell align="center" sx={{ fontWeight: 700 }}>
              보유 및 이용 기간
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              아이디, 이메일, 닉네임, 이름, 휴대폰번호, 비밀번호, 소속, 웹사이트
            </TableCell>
            <TableCell>회원 가입 및 이용자 식별, 회원관리, 서비스 통계 관리 </TableCell>
            <TableCell rowSpan={2} sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
              회원탈퇴 시 90일간 보관 후 파기{' '}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>소속, 웹사이트, 성별, 상품 카테고리 </TableCell>
            <TableCell>개인화 서비스 제공, 서비스 개선 </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>부정행위 탐지된 이메일, 휴대폰번호, 부정행위 기록 </TableCell>
            <TableCell>부정행위 방지 </TableCell>
            <TableCell sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
              회원탈퇴 시 180일간 <br />
              보관 후 파기{' '}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>입력물, 결과물 </TableCell>
            <TableCell>서비스 개선 </TableCell>
            <TableCell sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
              회원탈퇴 시 익명화되어 영구 보관 이외 별도 요청 시 파기{' '}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography sx={{ my: '1rem', fontSize: '1.2rem' }}>
        - 동의를 거부할 수 있으나 동의 거부 시 서비스 이용이 제한됩니다.
      </Typography>
    </Container>
  )
}
