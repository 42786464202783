import styled from '@emotion/styled'
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Button,
} from '@mui/material'
import {
  tutorialAtom,
  newPortfolioAtom,
  portfolioConfigAtom,
  portfolioAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  isErrorMessageAtom,
} from 'atoms'
import { CenterAlignStack, CreatingPortfolio, CenterAlignBox } from 'components'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { APPBAR_MOBILE } from 'layouts/main/MainAppBar'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { CloseIcon } from 'theme/icon'
import {
  PortfolioConfigTabs,
  SetPortfolioName,
  SetPortfolioCategory,
  SetPortfolioConcept,
  SetPortfolioOutput,
  SetPortfolioAdvanced,
} from 'components/portfolio/PortfolioConfig'
import { Tutorial } from 'theme/Tutorial'
import { portfolioBgTypeSelector, userPortfolioNamesSelector } from 'selector'
import { apis } from 'apis'
import moment from 'moment'
import useConfirm from 'hooks/useConfirm'
import { useTranslation } from 'react-i18next'

const totalTab = 5

export default function NewPortfolio() {
  const { t } = useTranslation()
  const [value, setValue] = useRecoilState(newPortfolioAtom)
  const resetStep = useResetRecoilState(newPortfolioAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  const isMobile = useMobileMediaQuery()
  const [mOpen, setMOpen] = useState(isMobile)

  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const getMobileLabel = t => `${t} ( ${value + 1} / ${totalTab} )`

  useEffect(() => {
    setMOpen(isMobile)
  }, [isMobile])

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ pt: '6.9rem' }}>
          <Typography sx={{ mb: '4rem', fontSize: '2.5rem', fontWeight: 700 }}>
            {t('button.create_new_portfolio')}
          </Typography>

          <CenterAlignStack sx={{ width: '100%' }}>
            <CenterAlignStack sx={{ width: { lg: '118.4rem', xs: '32rem' } }}>
              <PortfolioConfigTabs value={value} onChange={handleChange} />
              <CenterAlignStack sx={{ width: '100%', pb: '4rem' }}>
                <TabContent />
              </CenterAlignStack>
            </CenterAlignStack>
          </CenterAlignStack>
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <Dialog open={mOpen} fullScreen>
          <DialogContent sx={{ px: 0, overflowX: 'hidden' }}>
            <CenterAlignStack>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end',
                  pr: '3rem',
                }}
              >
                <IconButton
                  onClick={() => {
                    setMOpen(false)
                    navigate(-1)
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Typography fontSize="2rem" fontWeight={800} sx={{ mb: '4rem' }}>
                {t('button.create_new_portfolio')}
              </Typography>
            </CenterAlignStack>

            <PortfolioConfigTabs value={value} onChange={handleChange} mobileSmall />
            <TabContent />
          </DialogContent>
        </Dialog>
      </Mobile>
    </>
  )
}

const invisibleTabStyle = {
  width: 0,
  minWidth: 0,
  maxWidth: 0,
  p: 0,
}

function TabContent() {
  const value = useRecoilValue(newPortfolioAtom)
  const tutorial = useRecoilValue(tutorialAtom)
  const resetStep = useResetRecoilState(newPortfolioAtom)
  const reset = useResetRecoilState(portfolioConfigAtom)

  const [outputConfigModified, setOutputConfigModified] = useState(false)

  useEffect(() => {
    return () => {
      reset()
      !tutorial.mode && resetStep()
    }
  }, [])

  switch (value) {
    case 0:
      return <SetName />
    case 1:
      return <SetConcept />
    case 2:
      return <SetCategory />
    case 3:
      return <SetOutput modified={outputConfigModified} setModified={setOutputConfigModified} />
    // case 4:
    //   return <SetAdvanced />
  }
}

function SetName() {
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const setValue = useSetRecoilState(newPortfolioAtom)
  const setPortfolio = useSetRecoilState(portfolioAtom)
  const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)
  const { t, i18n } = useTranslation()

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [placeholder, setPlaceholder] = useState(t('upload_config.portfolio_title_placeholder'))

  const navigate = useNavigate()

  useEffect(() => {
    apis.portfolio.getAllportfolio().then(response => {
      setPortfolio(response.data)
    })
  }, [])

  const handleChange = ({ target }) => {
    if (userPortfolioNames?.includes(target.value)) {
      setError(true)
      setErrorMessage(t('upload_config.portfolio_title_warning'))
    } else {
      setError(false)
      setErrorMessage('')
    }
    setPortfolioConfig({ ...portfolioConfig, name: target.value })
  }

  const onFocus = () => {
    setPlaceholder('')
  }
  const onBlur = () => {
    setPlaceholder(t('upload_config.portfolio_title_placeholder'))
  }

  const moveStep = () => {
    setValue(1)

    // ----- GA4 event -----
    window.gtag('event', 'portfolio_new_title', {})
    // ---------------------
  }

  return (
    <>
      <SetPortfolioName
        // sx={{ mt: { xs: !tutorial.mode ? '6rem' : null } }}
        value={portfolioConfig.name}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        errorMessage={errorMessage}
      />

      <PrevNextButtonArea>
        <Desktop>
          <PrevIconBox
            onClick={e => {
              navigate(-1)
              tutorial.mode && setTutorial(prev => ({ ...prev, step: 1 }))
            }}
          />
        </Desktop>
        <NextButton variant="contained" disabled={error} onClick={moveStep}>
          {t('button.next')}
        </NextButton>
      </PrevNextButtonArea>
    </>
  )
}

function SetCategory() {
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const setValue = useSetRecoilState(newPortfolioAtom)

  const { t, i18n } = useTranslation()

  const isMobile = useMobileMediaQuery()

  const moveStep = () => {
    setValue(3)

    // ----- GA4 event -----
    window.gtag('event', 'portfolio_new_category', {})
    // ---------------------
  }

  const onClickMain = category => event => {
    if (tutorial.mode && tutorial.step === 3) {
      setTutorial(prev => ({ ...prev, step: '3_1' }))
    }
    setPortfolioConfig({
      ...portfolioConfig,
      category: category.id,
      subCategory: 'auto',
    })
  }

  useEffect(() => {}, [])

  return (
    <CenterAlignStack
    // sx={{ my: tutorial.step === 3 ? '2.75rem' : 3 }}
    >
      <SetPortfolioCategory
        setConfig={setPortfolioConfig}
        config={portfolioConfig}
        onClickMain={onClickMain}
      />

      <PrevNextButtonArea>
        <Desktop>
          <PrevIconBox
            onClick={e => {
              setValue(1)
            }}
          />
        </Desktop>
        <NextButton
          variant="contained"
          disabled={!portfolioConfig.category || !portfolioConfig.subCategory}
          onClick={moveStep}
        >
          {t('button.next')}
        </NextButton>
      </PrevNextButtonArea>
    </CenterAlignStack>
  )
}

function SetConcept() {
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const setValue = useSetRecoilState(newPortfolioAtom)
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)

  const { t, i18n } = useTranslation()

  const moveStep = () => {
    setValue(2)

    // ----- GA4 event -----
    window.gtag('event', 'portfolio_new_concept', {})
    // ---------------------
  }

  return (
    <>
      <SetPortfolioConcept config={portfolioConfig} setConfig={setPortfolioConfig} />

      <PrevNextButtonArea>
        <Desktop>
          <PrevIconBox
            onClick={() => {
              setValue(0)
            }}
          />
        </Desktop>
        <NextButton variant="contained" onClick={moveStep}>
          {t('button.next')}
        </NextButton>
      </PrevNextButtonArea>
    </>
  )
}

function SetOutput() {
  const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)
  const [errorMessage, setErrorMessage] = useRecoilState(isErrorMessageAtom)

  const setValue = useSetRecoilState(newPortfolioAtom)
  const navigate = useNavigate()
  const resetStep = useResetRecoilState(newPortfolioAtom)
  const reset = useResetRecoilState(portfolioConfigAtom)

  const { showConfirm } = useConfirm()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    return () => {
      setErrorMessage('')
    }
  }, [])

  const isSimpleConfig =
    !portfolioConfig.bgComplex && !portfolioConfig.bgGenerate ? 'simple' : 'concept'

  useEffect(() => {
    if (portfolioConfig.outputSizeList.length) {
      const firstOutputList = portfolioConfig.outputSizeList[0]

      setPortfolioConfig(prev => ({
        ...prev,
        outputWidth: firstOutputList.w,
        outputHeight: firstOutputList.h,
      }))
    }
    // else {

    //   setPortfolioConfig(prev => ({
    //     ...prev,
    //     outputSizeList: [
    //       ...(prev.outputSizeList ? prev.outputSizeList : []),
    //       { w: portfolioConfig.outputWidth, h: portfolioConfig.outputHeight },
    //     ],
    //   }))
    // }
  }, [portfolioConfig.outputSizeList])

  useEffect(() => {
    if (!portfolioConfig.name) {
      const today = moment(new Date()).format('YYYY.MM.DD')
      const filterArray = userPortfolioNames?.filter(name => name.includes(today))
      // if (!filterArray) return

      const pattern = /\((\d+)\)/
      const numbers = filterArray?.map(item => {
        const match = item.match(pattern)
        return match ? parseInt(match[1]) : 0
      })

      const maxNumber = Math.max(...numbers)

      if (filterArray.length === 0) {
        setPortfolioConfig(prev => ({ ...prev, name: today }))
      } else {
        userPortfolioNames.includes(today + ` (${filterArray.length})`)
        setPortfolioConfig(prev => ({ ...prev, name: today + ` (${maxNumber + 1})` }))
      }
    }

    return () => {}
  }, [])

  const createPortfolio = () => {
    const bg = []
    if (portfolioConfig.bgWhite) bg.push('white')
    if (portfolioConfig.bgSimple) bg.push('simple')
    if (portfolioConfig.bgComplex) bg.push('complex')
    if (portfolioConfig.bgGenerate) bg.push('generate')

    if (portfolioConfig.saveOutputConfig) {
      const body = {}

      body.output_size_w =
        Number.isInteger(portfolioConfig.outputWidth) && portfolioConfig.outputWidth > 0
          ? portfolioConfig.outputWidth
          : null
      body.output_size_h =
        Number.isInteger(portfolioConfig.outputHeight) && portfolioConfig.outputHeight > 0
          ? portfolioConfig.outputHeight
          : null

      body.bg_type = bg
      apis.user.updateUserPreference(body)
    }

    // 배경 사이즈 멀티로 넣을 시, 뒤에 null 값은 삭제해서 넣음 + 2개 이상 멀티로 지정 시 배경 흰색 고정
    const editedConfigForbody = { ...portfolioConfig }

    let configOutputlist = [...editedConfigForbody.outputSizeList]

    if (!portfolioConfig.outputWidth || !portfolioConfig.outputHeight) {
      editedConfigForbody.outputHeight = null
      editedConfigForbody.outputWidth = null
    }
    if (configOutputlist.length !== 0) {
      configOutputlist = configOutputlist.filter(o => o.w !== null && o.h !== null)
    }

    if (configOutputlist.length > 1) {
      editedConfigForbody.simpleBgColorList = [[255, 255, 255]]
    } else {
      editedConfigForbody.simpleBgColorList = PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList
    }

    editedConfigForbody.outputSizeList = configOutputlist

    // config에 theme이 따로 있어서 config에 다시 넣음
    const body = {
      name: portfolioConfig.name,
      theme: portfolioConfig.theme,
      config: { ...editedConfigForbody },
    }

    apis.portfolio
      .createPortfoilo(body)
      .then(response => {
        // ----- GA4 event -----
        window.gtag('event', 'portfolio_new_complete', {
          config_object_category: portfolioConfig.category,
          config_object_sub_category: portfolioConfig.subCategory,
          output_size_width: portfolioConfig.outputWidth,
          output_size_height: portfolioConfig.outputHeight,
          image_type: isSimpleConfig,
          config_gen_shadow:
            isSimpleConfig === 'simple' ? portfolioConfig.genShadow.toString() : 'auto',
          config_gen_face: portfolioConfig.genFace.toString(),

          theme:
            isSimpleConfig === 'simple'
              ? null
              : portfolioConfig.theme_custom || portfolioConfig.theme_template,
          removebg: portfolioConfig.bgWhite,
          multi_blob: portfolioConfig.flagMultiblobSod,
        })
        // ---------------------

        const portfolioID = response.data.id
        if (!response.data.success) {
          showConfirm({
            alertOnly: true,
            content: <Typography>{response.data.message}</Typography>,
            // TODO 안내 문구 다국어 지원하려면 ?
          })
          return
        }

        navigate(`/portfolio/${portfolioID}?pages=1`, { replace: true })
        reset()
        resetStep()
      })
      .catch(error => {
        console.log(error)
      })

    // ----- GA4 event -----
    window.gtag('event', 'portfolio_new_advanced_setting', {})
    // ---------------------
  }

  return (
    <>
      <SetPortfolioOutput config={portfolioConfig} setConfig={setPortfolioConfig} />

      <PrevNextButtonArea>
        <Desktop>
          <PrevIconBox
            onClick={e => {
              setValue(2)
            }}
          />
        </Desktop>
        <NextButton variant="contained" onClick={createPortfolio}>
          {t('button.create')}
        </NextButton>
      </PrevNextButtonArea>
    </>
  )
}

// function SetAdvanced() {
//   const [portfolioConfig, setPortfolioConfig] = useRecoilState(portfolioConfigAtom)
//   const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
//   const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)
//   const setValue = useSetRecoilState(newPortfolioAtom)
//   const navigate = useNavigate()
//   const resetStep = useResetRecoilState(newPortfolioAtom)
//   const reset = useResetRecoilState(portfolioConfigAtom)

//   const { showConfirm } = useConfirm()
//   const { t, i18n } = useTranslation()

//   useEffect(() => {
//     if (!portfolioConfig.name) {
//       const today = moment(new Date()).format('YYYY.MM.DD')
//       const filterArray = userPortfolioNames?.filter(name => name.includes(today))
//       // if (!filterArray) return

//       const pattern = /\((\d+)\)/
//       const numbers = filterArray?.map(item => {
//         const match = item.match(pattern)
//         return match ? parseInt(match[1]) : 0
//       })

//       const maxNumber = Math.max(...numbers)

//       if (filterArray.length === 0) {
//         setPortfolioConfig(prev => ({ ...prev, name: today }))
//       } else {
//         userPortfolioNames.includes(today + ` (${filterArray.length})`)
//         setPortfolioConfig(prev => ({ ...prev, name: today + ` (${maxNumber + 1})` }))
//       }
//     }

//     // ----- GA4 event -----
//     window.gtag('event', 'portfolio_new_advanced_setting', {})
//     // ---------------------
//   }, [])

//   return (
//     <>
//       <SetPortfolioAdvanced
//         portfolioConfig={portfolioConfig}
//         setPortfolioConfig={setPortfolioConfig}
//       />
//       <PrevNextButtonArea>
//         <Desktop>
//           <PrevIconBox
//             onClick={e => {
//               tutorial.mode && setTutorial(prev => ({ ...prev, step: 9 }))
//               setValue(3)
//             }}
//           />
//         </Desktop>
//         <Tutorial step={13}>
//           <NextButton
//             variant="contained"
//             onClick={createPortfolio}
//             // disabled={!portfolioConfig.name || !portfolioConfig.subCategory}
//             // style={{ marginTop: !(tutorial.step === 13) ? '5.2rem' : 0 }}
//           >
//             {t('button.create')}
//           </NextButton>
//         </Tutorial>
//       </PrevNextButtonArea>
//     </>
//   )
// }

// ---------------

const PrevNextButtonArea = styled(CenterAlignBox)(({ theme }) => ({
  width: '35rem',
  height: '10rem',
  position: 'relative',
  alignItems: 'center',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
    height: 'unset',
  },
}))

const PrevIconBox = props => {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        // height: { lg: '6rem', xs: '4.8rem' },
        display: 'flex',
      }}
      {...props}
    >
      <Desktop>
        <svg
          width="21"
          height="41"
          viewBox="0 0 21 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer' }}
        >
          <path
            d="M18 38L2 20.5L18 3"
            stroke="#808080"
            strokeWidth="3"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      </Desktop>

      <Mobile>
        <svg
          width="18"
          height="33"
          viewBox="0 0 18 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer' }}
        >
          <path
            d="M15 30L2 16.5L15 3"
            stroke="#808080"
            strokeWidth="3"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      </Mobile>
    </Box>
  )
}

const NextButton = styled(Button)(({ theme }) => ({
  width: '20rem',
  height: '4.8rem',
  fontSize: '2rem',
  fontWeight: 800,

  [theme.breakpoints.down('lg')]: {
    width: '32rem',
    height: '4rem',
    fontSize: '1.6rem',
    marginBottom: '4rem',
  },
}))
