import React, { useState } from 'react'
import { Box, Button, Paper, Collapse, Typography } from '@mui/material'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { SelectSideIcon } from 'theme/icon'
import moment from 'moment'

const ConfigViewer = ({ config, keysToRemove = ['token', 'id', 'upload_id'], time }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const removeKeys = (data, keysToRemove) => {
    if (typeof data !== 'object' || data === null) {
      return data
    }

    if (Array.isArray(data)) {
      return data
    }

    return Object.fromEntries(Object.entries(data).filter(([key]) => !keysToRemove.includes(key)))
  }

  const processedConfig = removeKeys(config, keysToRemove)
  const configString = JSON.stringify(processedConfig, null, 2)

  return (
    <Paper elevation={3}>
      <Box p={2} sx={{ width: '40rem' }}>
        <Button onClick={toggleExpand} fullWidth variant="contained">
          {expanded ? '설정 접기' : '설정 펼치기'}
        </Button>
        <Collapse in={expanded}>
          <Box mt={2} borderRadius={1} overflow="hidden" maxWidth="100%">
            {time && <Typography>Time : {moment(time).format('YYYY.MM.DD HH:mm')}</Typography>}
            <SyntaxHighlighter
              language="json"
              style={vscDarkPlus}
              customStyle={{
                margin: 0,
                maxWidth: '100%',
                overflowX: 'auto',
              }}
              wrapLines={true}
              wrapLongLines={true}
            >
              {configString}
            </SyntaxHighlighter>
          </Box>
        </Collapse>
      </Box>
    </Paper>
  )
}

export default ConfigViewer
