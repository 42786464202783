import { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric'
import FontFaceObserver from 'fontfaceobserver'
import { Box, MenuItem, Select } from '@mui/material'
import useGridHandler from 'hooks/editor/useGridHandler'
import { useGuidelines } from 'hooks/editor/useGuidelineHandler'
import { CenterAlignStack } from 'components'
import { scalingLogic, snapLogic } from 'utils/editorHelper'
import { Group, Image, Layer, Rect, Stage, Text, Transformer } from 'react-konva'

export default function TestPage16() {
  return (
    <>
      {/* <Test1 /> */}
      {/* <Test2 /> */}
      <Test3 />
    </>
  )
}

const Test1 = () => {
  const [canvas, setCanvas] = useState(null)
  const [selectedFontFamily, setSelectedFontFamily] = useState('Arial')

  const canvasRef = useRef(null)

  useEffect(() => {
    // 캔버스 초기화 및 TextBox 한 개 추가
    const initCanvas = new fabric.Canvas(canvasRef.current)
    const textbox = new fabric.Textbox('Hello, Fabric!', {
      left: 50,
      top: 50,
      fontFamily: 'Arial',
      fontSize: 20,
    })
    initCanvas.add(textbox)
    setCanvas(initCanvas)
  }, [])

  const handleFontChange = e => {
    const newFontFamily = e.target.value
    setSelectedFontFamily(newFontFamily)

    const activeObject = canvas?.getActiveObject()

    if (activeObject && activeObject.type === 'textbox') {
      // 선택된 TextBox의 폰트만 변경
      activeObject.set({ fontFamily: newFontFamily })
      canvas.requestRenderAll()
    }
  }

  return (
    <div>
      <Select onChange={handleFontChange} value={selectedFontFamily}>
        <MenuItem value="Arial">Arial</MenuItem>
        <MenuItem value="Courier New">Courier New</MenuItem>
        <MenuItem value="Times New Roman">Times New Roman</MenuItem>
        {/* 여기에 추가 폰트 옵션 */}
      </Select>
      <canvas ref={canvasRef} width={400} height={600} />
    </div>
  )
}

const Test2 = () => {
  const canvasRef = useRef(null)
  const [canvas, setCanvas] = useState(null)
  const [fontFamily, setFontFamily] = useState('Arial')
  const [fontSize, setFontSize] = useState(22)
  const [fontColor, setFontColor] = useState('#000000')

  const gridOptions = {
    enabled: true,
    grid: 5,
    lineColor: '#ebebeb',
    borderColor: '#cccccc',
    snapToGrid: true,
  }
  const guidelineOptions = {
    enabled: true,
    aligningLineColor: 'rgb(255, 0, 0)',
    // 여기에 더 많은 설정을 추가할 수 있습니다.
  }

  const snapDistance = 8

  // 스냅 함수
  const snapToGrid = value => {
    return Math.round(value / snapDistance) * snapDistance
  }

  // 스냅 이동 함수
  const snapMovement = (object, side) => {
    switch (side) {
      case 'left':
        break
      case 'right':
        object.left = snapToGrid(object.left)
        break
      case 'top':
        break
      case 'bottom':
        object.top = snapToGrid(object.top)
        break
      default:
        break
    }
  }

  // Canvas 초기화
  useEffect(() => {
    const canvasInstance = new fabric.Canvas(canvasRef.current)

    setCanvas(canvasInstance)
    // 두 개의 텍스트 박스 생성
    const text1 = createText(canvasInstance, 'Click to Edit', 100, 100)
    const text2 = createText(canvasInstance, 'Another Text', 100, 200)

    canvasInstance.add(text1, text2)
    canvasInstance.setActiveObject(text1)
    // 첫 번째 텍스트 박스를 편집 모드로 설정
    text1.enterEditing()
    // text1.selectAll()

    const handleObjectMoving = e => {
      const movedObject = e.target
      canvasInstance.getObjects('line').forEach(function (line) {
        if (line.guideline) canvasInstance.remove(line)
      })
      snapLogic(canvasInstance, movedObject, snapDistance, 'moving')
    }

    const handleObjectScaling = e => {
      const movedObject = e.target
      canvasInstance.getObjects('line').forEach(function (line) {
        if (line.guideline) canvasInstance.remove(line)
      })
      scalingLogic(canvasInstance, movedObject, 4, 20)
    }

    canvasInstance.on('object:moving', handleObjectMoving)
    canvasInstance.on('object:scaling', handleObjectScaling)

    const handleKeyDown = e => {
      if (e.key === 'Delete') {
        const activeObject = canvasInstance.getActiveObject()
        console.log(activeObject, activeObject.type)
        if (activeObject && !activeObject.isEditing) {
          // 단일 객체 삭제
          if (!activeObject.type || activeObject.type !== 'activeSelection') {
            canvasInstance.remove(activeObject)
          } else {
            // 그룹화된 객체 삭제
            activeObject.forEachObject(obj => {
              canvasInstance.remove(obj)
            })
            // 그룹 해제
            canvasInstance.discardActiveObject()
          }
          // canvasInstance.requestRenderAll()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    // 컴포넌트 언마운트 시 이벤트 리스너 제거

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const createText = (canvasInstance = canvas, text, left, top) => {
    const textbox = new fabric.Textbox(text, {
      left: left,
      top: top,
      width: 300,
      fontFamily: 'Arial',
      fontSize: 22,
      fill: '#000000',
      backgroundColor: 'rgba(123,45,26,0.7)',
    })

    textbox.setControlsVisibility({
      mt: false, // 상단 중앙 조절점 비활성화
      mb: false, // 하단 중앙 조절점 비활성화
    })

    textbox.on('deselected', function () {
      if (this.text === '') {
        canvasInstance.remove(this)
      }
    })

    return textbox
  }

  // 텍스트 추가 기능
  const addText = () => {
    const text = createText(canvas, 'Add', 100, 200)

    canvas.add(text)
  }

  // 입력 값에 따라 Canvas 내 선택된 텍스트 객체의 속성 업데이트
  useEffect(() => {
    const activeObject = canvas?.getActiveObject()
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set({
        fontFamily,
        fontSize,
        fill: fontColor,
      })
      canvas.requestRenderAll()
    }
  }, [fontFamily, fontSize, fontColor, canvas])

  const alignLeft = () => {
    const activeObject = canvas.getActiveObject()
    const activeObjectLeft = -(activeObject.width / 2)

    activeObject.forEachObject(obj => {
      obj.set({
        left: activeObjectLeft,
      })
      obj.setCoords()
      canvas.requestRenderAll()
    })
  }

  const alignCenter = () => {
    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.type === 'activeSelection') {
      activeObject.forEachObject(obj => {
        obj.set({
          left: 0 - (obj.width * obj.scaleX) / 2,
        })
        obj.setCoords()
      })
    }
    canvas.requestRenderAll()
  }
  const alignRight = () => {
    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.type === 'activeSelection') {
      const activeObjectLeft = activeObject.width / 2
      activeObject.forEachObject(obj => {
        obj.set({
          left: activeObjectLeft - obj.width * obj.scaleX,
        })
        obj.setCoords()
      })
    }
    canvas.requestRenderAll()
  }

  return (
    <div>
      <button onClick={addText}>Add Text</button>
      <button onClick={alignLeft}>좌</button>
      <button onClick={alignCenter}>중</button>
      <button onClick={alignRight}>우</button>
      <br />
      <label>
        Font Family:
        <input value={fontFamily} onChange={e => setFontFamily(e.target.value)} />
      </label>
      <br />
      <label>
        Font Size:
        <input
          type="number"
          value={fontSize}
          onChange={e => setFontSize(parseInt(e.target.value, 10))}
        />
      </label>
      <br />
      <label>
        Font Color:
        <input type="color" value={fontColor} onChange={e => setFontColor(e.target.value)} />
      </label>
      <br />

      <canvas ref={canvasRef} width={1000} height={400} style={{}} />
    </div>
  )
}
const Test3 = () => {
  const [image, setImage] = useState(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [cropBox, setCropBox] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const cropBoxRef = useRef(null)
  const transformerRef = useRef(null)

  const handleImageUpload = event => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = e => {
      const img = new window.Image()
      img.src = e.target.result
      img.onload = () => {
        let width = img.width
        let height = img.height

        // 이미지 크기가 2000px을 넘을 때 조정
        if (width > 2000 || height > 2000) {
          if (width > height) {
            height = (height / width) * 2000
            width = 2000
          } else {
            width = (width / height) * 2000
            height = 2000
          }
        }

        const scaledWidth = width / 5
        const scaledHeight = height / 5
        setDimensions({ width: scaledWidth, height: scaledHeight })
        setCropBox({
          x: (400 - scaledWidth) / 2,
          y: (400 - scaledHeight) / 2,
          width: scaledWidth,
          height: scaledHeight,
        })
        setImage(img)
      }
    }

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if (transformerRef.current) {
      transformerRef.current.nodes([cropBoxRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
  }, [cropBox])

  const renderClippedRect = () => {
    return (
      <Rect
        x={0}
        y={0}
        width={400}
        height={400}
        fill="rgba(0,0,0,0.5)"
        clipFunc={ctx => {
          ctx.rect(cropBox.x, cropBox.y, cropBox.width, cropBox.height)
          ctx.clip()
        }}
      />
    )
  }

  return (
    <div>
      <input type="file" onChange={handleImageUpload} />
      <div>
        Actual Width: {dimensions.width * 5}px, Actual Height: {dimensions.height * 5}px
      </div>
      <Stage width={400} height={400}>
        <Layer>
          <Rect x={0} y={0} width={400} height={400} fill="lightgray" />
          {image && (
            <>
              <Image
                x={(400 - dimensions.width) / 2}
                y={(400 - dimensions.height) / 2}
                width={dimensions.width}
                height={dimensions.height}
                image={image}
              />
              <Group>
                {renderClippedRect()}
                <Rect
                  ref={cropBoxRef}
                  x={cropBox.x}
                  y={cropBox.y}
                  width={cropBox.width}
                  height={cropBox.height}
                  fill="transparent"
                  stroke="red"
                  strokeWidth={2}
                  draggable
                  onDragEnd={e => {
                    setCropBox({
                      ...cropBox,
                      x: e.target.x(),
                      y: e.target.y(),
                    })
                  }}
                  onTransformEnd={e => {
                    const node = cropBoxRef.current
                    const scaleX = node.scaleX()
                    const scaleY = node.scaleY()

                    // 새로운 크기로 업데이트
                    setCropBox({
                      x: node.x(),
                      y: node.y(),
                      width: node.width() * scaleX,
                      height: node.height() * scaleY,
                    })

                    // 스케일 초기화
                    node.scaleX(1)
                    node.scaleY(1)
                  }}
                />
                <Transformer ref={transformerRef} />
              </Group>
            </>
          )}
        </Layer>
      </Stage>
    </div>
  )
}
