import { Box, Stack } from '@mui/material'
import { CenterAlignBox } from './CenterAlignContainer'

export default function GoodBadExample({
  good,
  bad,
  width = { lg: '16rem', xs: '15.2rem' },
  iconSize = '2rem',
  sx = { py: '1.4rem', gap: '1.25rem' },
  rectsx = {},
}) {
  return (
    <Stack
      direction="row"
      sx={{
        ...sx,
        '& .shadow-rect': {
          position: 'absolute',
          width: '100%',
          height: '3.2rem',
          background: 'rgba(0, 0, 0, 0.6)',
          bottom: 0,
          borderRadius: '0 0 1rem 1rem',
          alignItems: 'center',
          ...rectsx,
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width,
        }}
      >
        <img src={good} style={{ borderRadius: '1rem 1rem 1rem 1rem' }} />
        <CenterAlignBox className="shadow-rect">
          <svg
            width={iconSize}
            height={iconSize}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
              stroke="#00E8B9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </CenterAlignBox>
      </Box>
      <Box sx={{ position: 'relative', width }}>
        <img src={bad} style={{ borderRadius: '1rem 1rem 1rem 1rem' }} />
        <CenterAlignBox className="shadow-rect">
          <svg
            width={iconSize}
            height={iconSize}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 2L2 18"
              stroke="#FFA3A3"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
            <path
              d="M2 2L18 18"
              stroke="#FFA3A3"
              strokeWidth="2"
              strokeLinecap="square"
              strokeLinejoin="round"
            />
          </svg>
        </CenterAlignBox>
      </Box>
    </Stack>
  )
}
