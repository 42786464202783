import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Container,
  Divider,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  ToggleButton,
  Typography,
  Paper,
  Grid,
  ListSubheader,
} from '@mui/material'
import { useTheme } from '@mui/system'
import {
  bgConfigStepAtom,
  bgConfigAtom,
  selectedSampleAtom,
  lightDirectionAtom,
  userAtom,
  loadingAtom,
  backgroundAtom,
  backgroundFetchInfoAtom,
  autoBgInfoAtom,
  backgroundFilterAtom,
} from 'atoms'
import {
  CenterAlignBox,
  FlexBasis,
  CenterAlignStack,
  PerpenShadow,
  FloorShadow,
  SampleImg,
  StyledToggleButtonGroup,
  BgOntlinedInput,
} from 'components'
import { useCallback, useState, useEffect, useRef } from 'react'
import Draggable from 'react-draggable'
import {
  AiOutlineArrowLeft,
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlineVerticalAlignBottom,
} from 'react-icons/ai'
import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { apis } from 'apis'
import { getS3ImageSrc } from 'utils/common'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop from 'react-image-crop'
import { refreshBackground } from './Background'
import useConfirm from 'hooks/useConfirm'
import { CloseIcon } from 'theme/icon'
import { PuffLoader } from 'react-spinners'
import useBackground from 'hooks/useBackground'
import { useTranslation } from 'react-i18next'

const selectStyle = {
  width: 120,
  mb: 4,
  '.MuiSelect-select': {
    paddingX: 0.5,
    paddingY: 0.5,
  },
}
const CATEGORIES = [
  { value: 'person', text: '모델' },
  { value: 'clothes', text: '의류' },
  { value: 'food', text: '식품' },
  { value: 'cosmetics', text: '화장품' },
  { value: 'car', text: '자동차' },
  { value: 'others', text: '그 외' },
]
const SUBCATEGORIES = {
  person: ['whole', 'torso', 'body', 'lower', 'detail', 'simple', '없음'],
  clothes: ['detail', 'clothing', 'etc', '없음'],
  food: ['plate', 'box', 'raw', 'simple', '없음'],
  others: ['simple', '없음'],
}

// 사진 들어가는 요소의 계산하여 나온 값.. 자동으로 바뀌지 않아서 혹시 변경되면 수동으로 바꿔야함..
const standardWidth = 436
const standardHeight = 436.5
let realWidth = 0
let realHeight = 0

export default function AutoBackgroundUpload({
  bgUploadOpen,
  setBgUploadOpen,
  imageData,
  setImageData,
}) {
  const { id } = useRecoilValue(userAtom)
  const { t, i18n } = useTranslation()
  const [autoBgInfo, setAutoBgInfo] = useRecoilState(autoBgInfoAtom)
  const [selectDialog, setSelectDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const resetAutoBgInfo = useResetRecoilState(autoBgInfoAtom)

  const [uploadImgHeight, setUploadImgHeight] = useState(0)
  const [uploadImgWidth, setUploadImgWidth] = useState(0)

  const { showConfirm } = useConfirm()
  const theme = useTheme()

  const handleClose = e => {
    // 백드롭 클릭 시 다이얼로그 꺼지는 것을 방지하기 위해서 분기처리함.
    // console.log(e.target.tagName)
    if (e.target.tagName !== 'DIV') {
      setBgUploadOpen(false)
      resetAutoBgInfo()
    }
  }

  // const autoUpload = async event => {
  //   if (
  //     autoBgInfo.name !== '' &&
  //     autoBgInfo.tag !== '' &&
  //     autoBgInfo.category !== '' &&
  //     autoBgInfo.sub_category !== ''
  //   ) {
  //     const d = {
  //       file: imageData.file,
  //       data: {
  //         name: autoBgInfo.name,
  //         tag: autoBgInfo.tag,
  //         category: autoBgInfo.category,
  //         sub_category: autoBgInfo.sub_category,
  //         user_id: id,
  //       },
  //     }

  //     const formData = new FormData()
  //     formData.append('image', d.file)

  //     const params = {
  //       ...d.data,
  //     }

  //     apis.bg.uploadBgAuto(formData, { params }).then(res => {
  //       setImageData(res.data).then(() => {})
  //     })

  //     event.target.value = null
  //   } else {
  //     alert('모든 항목을 완료해주세요.')
  //   }
  // }

  const onLoadImage = e => {
    // 이미지 비율에 따라 높이를 최대로 너비를 최대로 할지 결정 후 비율 맞혀서 div값을 지정.
    realWidth = e.target.naturalWidth
    realHeight = e.target.naturalHeight

    const realRatio = realHeight / realWidth
    const standardRatio = standardHeight / standardWidth

    if (realRatio >= standardRatio) {
      setUploadImgWidth((realWidth * standardHeight) / realHeight)
      setUploadImgHeight(standardHeight)
    }

    if (realRatio < standardRatio) {
      setUploadImgWidth(standardWidth)
      setUploadImgHeight((realHeight * standardWidth) / realWidth)
    }
  }

  const sodImage = event => {
    // setSelectDialog(true)
    // 임시로 꺼둠
    if (autoBgInfo.name !== '' && autoBgInfo.tag !== '' && autoBgInfo.category !== '') {
      const d = {
        file: imageData.file,
        data: {
          name: autoBgInfo.name,
          tag: autoBgInfo.tag,
          category: autoBgInfo.category,
          sub_category: 'auto',
          user_id: id,
        },
      }

      setIsLoading(true)

      const formData = new FormData()
      formData.append('image', d.file)

      const params = {
        ...d.data,
      }

      apis.bg
        .uploadBgAuto(formData, { params })
        .then(res => {
          // console.log(res)

          resetAutoBgInfo()
          setImageData(() => ({ ...res.data }))
          setIsLoading(false)
          setBgUploadOpen(false)
          setSelectDialog(true)
        })
        .catch(() => {
          setIsLoading(false)
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('common.error')}</Typography>,
          })
        })
    } else {
      showConfirm({
        alertOnly: true,
        content: <Typography>항목을 채워주세요.</Typography>,
      })
    }
  }

  return (
    <>
      <Dialog
        open={bgUploadOpen}
        fullWidth={true}
        maxWidth="lg"
        onClose={handleClose}
        sx={{
          overflow: 'hidden',
          py: 2,
          '& .MuiDialog-paper': {
            overflowY: 'hidden',
            maxHeight: '727px',
            minHeight: '727px',
            maxWidth: '984px',
            minWidth: '984px',
            borderRadius: '20px',
          },
        }}
      >
        <DialogTitle
          sx={{
            position: 'relative',
            pt: '58px',
            pb: '45px',
            overflowY: 'hidden',
            backgroundColor: 'white',
            zIndex: 9999,
          }}
        >
          <CenterAlignBox>
            <Typography sx={{ textAlign: 'center', fontSize: '25px', fontWeight: '700' }}>
              배경추가
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', right: '26px', top: '25px' }}
            >
              <CloseIcon sx={{ width: '12px', height: '12px' }} />
            </IconButton>
          </CenterAlignBox>
        </DialogTitle>

        <DialogContent
          sx={{
            mx: '56px',
            overflow: 'hidden',
            p: 0,
            maxHeight: standardHeight,
            minHeight: standardHeight,
          }}
        >
          <CenterAlignBox height={standardHeight}>
            <FlexBasis flexBasis={['50%', '50%']} justifyContent="center" alignItems="center">
              <Stack>
                <img
                  src={imageData.url}
                  onLoad={onLoadImage}
                  style={{ width: uploadImgWidth, height: uploadImgHeight }}
                />
              </Stack>
              <AutoBgInfo />

              {/* <img src={imageData.url} style={{ display: 'none' }} onLoad={onLoadImage} /> */}
            </FlexBasis>
          </CenterAlignBox>
        </DialogContent>

        <CenterAlignBox
          gap={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            pt: '40px',
            pb: '50px',
            backgroundColor: 'white',
            zIndex: 9999,
          }}
        >
          <Button
            variant="contained"
            size="large"
            sx={{ width: '260px', height: '60px' }}
            onClick={sodImage}
            disabled={isLoading}
          >
            {isLoading ? <PuffLoader size={45} color={theme.palette.draph.blue} /> : '피사체 제거'}
          </Button>
        </CenterAlignBox>
      </Dialog>
      <SelectArea
        selectDialog={selectDialog}
        setSelectDialog={setSelectDialog}
        imageData={imageData}
        setImageData={setImageData}
      />
    </>
  )
}

function AutoBgInfo() {
  const [autoBgInfo, setAutoBgInfo] = useRecoilState(autoBgInfoAtom)

  // ex) subCategoryItems = {person: [whole, torso] ~~~, etc: [없음]}
  const [subCategoryItems, setSubCategoryItems] = useState([])
  const [namePlaceholder, setNamePlaceholder] = useState('[예시] 봄 배경 01')
  const [tagPlaceholder, setTagPlaceholder] = useState('꽃밭, 봄, 자연, 화장품, 핑크, 화사한, 밝은')

  // useEffect(() => {
  //   setAutoBgInfo(prev => ({ ...prev, subcategory: '' }))

  //   const category = autoBgInfo.category
  //   const items = SUBCATEGORIES[category]

  //   setSubCategoryItems(items)
  // }, [autoBgInfo.category])

  // console.log(subCategoryItems)

  const onChangHandle = key => e => {
    if (key === 'name') {
      setAutoBgInfo(prev => {
        return { ...prev, name: e.target.value }
      })
    }

    if (key === 'tag') {
      setAutoBgInfo(prev => {
        return { ...prev, tag: e.target.value }
      })
    }
  }

  return (
    <Box sx={{ pl: '37px' }}>
      <Typography sx={{ fontWeight: 600, fontSize: '18px', pb: '7px' }}>카테고리</Typography>
      <Select
        sx={{
          width: '100px',
          height: '34px',
          textAlign: 'center',
          fontSize: '15px',
        }}
        value={autoBgInfo.category}
        onChange={e => {
          setAutoBgInfo(prev => ({ ...prev, category: e.target.value }))
        }}
      >
        {CATEGORIES.map(item => {
          return (
            <MenuItem value={item.value} key={item.value}>
              {item.text}
            </MenuItem>
          )
        })}
      </Select>

      <Typography sx={{ fontWeight: 600, fontSize: '18px', pt: '40px', pb: '4px' }}>
        배경 제목
      </Typography>
      <Typography sx={{ fontWeight: 400, fontSize: '14px', pb: '15px' }}>
        배경에 맞는 제목을 입력해주세요.{' '}
      </Typography>
      <BgOntlinedInput
        sx={{ width: '100%', height: '37px', mb: 4 }}
        value={autoBgInfo.name}
        onChange={onChangHandle('name')}
        placeholder={namePlaceholder}
        type="text"
        inputProps={{ maxLength: 20 }}
        onFocus={() => {
          setNamePlaceholder('')
        }}
        onBlur={() => {
          setNamePlaceholder('[예시] 봄 배경 01')
        }}
      />

      <Typography sx={{ fontWeight: 600, fontSize: '18px', pb: '4px' }}>키워드</Typography>
      <Typography sx={{ fontWeight: 400, fontSize: '14px', pb: '15px' }}>
        추후 원하시는 배경을 적용하기 쉽도록 관련 키워드를 입력해주세요.{' '}
      </Typography>
      <BgOntlinedInput
        sx={{ width: '100%', height: '37px' }}
        value={autoBgInfo.tag}
        onChange={onChangHandle('tag')}
        placeholder={tagPlaceholder}
        type="text"
        inputProps={{ maxLength: 40 }}
        onFocus={() => {
          setTagPlaceholder('')
        }}
        onBlur={() => {
          setTagPlaceholder('꽃밭, 봄, 자연, 화장품, 핑크, 화사한, 밝은')
        }}
      />
    </Box>
  )
}

const showWindowWidth = 750
const showWindowHeight = 440
let srcImageWidth = 0
let srcImageHeight = 0

function SelectArea({ selectDialog, setSelectDialog, imageData, setImageData }) {
  const { id } = useRecoilValue(userAtom)
  const { t, i18n } = useTranslation()

  const { refreshBackground } = useBackground()

  const [uploadImgHeight, setUploadImgHeight] = useState(0)
  const [uploadImgWidth, setUploadImgWidth] = useState(0)
  const [crop, setCrop] = useState()
  const [cropImgURL, setCropImgURL] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState({ filepath: undefined, s3_url: undefined })
  // 시간 초과 시 에러발생

  const originalImgEl = useRef()
  const selectEl = useRef()
  const { showConfirm } = useConfirm()
  const theme = useTheme()

  const handleClose = e => {
    // 백드롭 클릭 시 다이얼로그 꺼지는 것을 방지하기 위해서 분기처리함.
    if (e.target.tagName !== 'DIV') {
      // console.log(imageData)
      deleteBg(imageData)
    }
  }

  const deleteBg = imageData => {
    showConfirm({
      title: t('background_item.background_delete'),
      content: <p>{t('background_item.background_delete_comment')}</p>,
      onConfirm: () => {
        apis.background.deleteBackground(imageData.id).then(() => {
          // console.log('delete 성공')
          refreshBackground()
          setImageData({})
          setSelectDialog(false)
          setCrop()
        })
      },
      onCancel: () => {},
    })
  }

  const areaSelectOnClick = async () => {
    const formData = new FormData()
    const blob = await fetch(cropImgURL).then(r => r.blob())
    formData.append('mask_img', blob)

    const params = {
      user_id: id,
      inpath: data.filepath || imageData.filepath,
    }

    setIsLoading(true)

    // 로딩..?
    apis.bg
      .masking(formData, { params })
      .then(res => {
        const newBgData = res.data
        setIsLoading(false)
        setData(newBgData)
        setCrop()
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
        showConfirm({
          alertOnly: true,
          content: <Typography>{t('common.error')}</Typography>,
        })
      })
  }

  const onLoadImage = e => {
    // 이미지 비율에 따라 높이를 최대로 너비를 최대로 할지 결정 후 비율 맞혀서 div값을 지정.
    srcImageWidth = e.target.naturalWidth
    srcImageHeight = e.target.naturalHeight

    const realRatio = srcImageHeight / srcImageWidth
    const standardRatio = showWindowHeight / showWindowWidth

    if (realRatio >= standardRatio) {
      setUploadImgWidth((srcImageWidth * showWindowHeight) / srcImageHeight)
      setUploadImgHeight(showWindowHeight)
    }

    if (realRatio < standardRatio) {
      setUploadImgWidth(showWindowWidth)
      setUploadImgHeight((srcImageHeight * showWindowWidth) / srcImageWidth)
    }
  }

  const onChangeHandler = event => {
    setCrop(event)
  }

  const onComplete = crop => {
    makeClientCrop(crop)
  }

  const makeClientCrop = crop => {
    if (crop.width && crop.height) {
      const image = originalImgEl.current
      const cropURL = getCroppedImg(image, crop)

      setCropImgURL(cropURL)
    }
  }
  const getCroppedImg = (image, crop) => {
    const { Image } = require('image-js')
    const canvas = document.createElement('canvas')
    let ratio = 1

    ratio = image.naturalWidth / uploadImgWidth

    canvas.width = image.naturalWidth
    canvas.height = image.naturalHeight

    const ctx = canvas.getContext('2d')

    ctx.fillStyle = 'white'
    ctx.fillRect(crop.x * ratio, crop.y * ratio, crop.width * ratio, crop.height * ratio)

    const selectData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const originalImg = new Image(canvas.width, canvas.height, selectData.data)
    const greyedImg = originalImg.grey()

    return greyedImg.toDataURL()
  }

  const saveOnClick = () => {
    const putData = { filepath: data.filepath, s3_url: data.s3_url }

    apis.background
      .putBackground(imageData.id, putData)
      .then(() => {
        refreshBackground()
        setSelectDialog(false)
        setImageData({})
        setCrop()
      })
      .catch(() => {
        showConfirm({
          alertOnly: true,
          content: <Typography>{t('common.error')}</Typography>,
        })
      })
  }

  return (
    <Dialog
      open={selectDialog}
      fullWidth={true}
      onClose={handleClose}
      sx={{
        overflow: 'hidden',
        py: 2,
        '& .MuiDialog-paper': {
          minWidth: '750px',
          minHeight: '700px',
          maxHeight: '700px',
          overflowY: 'hidden',
          borderRadius: '20px',
        },
      }}
    >
      <DialogTitle sx={{ pt: 3 }}>
        {' '}
        <CenterAlignStack>
          <CenterAlignBox>
            <Typography sx={{ textAlign: 'center', fontSize: '25px', fontWeight: '700' }}>
              피사체 제거
            </Typography>

            <IconButton
              edge="end"
              onClick={handleClose}
              sx={{ position: 'absolute', right: 25, top: 13 }}
            >
              <AiOutlineClose />
            </IconButton>
          </CenterAlignBox>
        </CenterAlignStack>
      </DialogTitle>
      <DialogContent
        sx={{
          mx: '12.5px',
          overflow: 'hidden',
          p: 0,
          m: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: showWindowHeight,
          minHeight: showWindowHeight,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            width={'100%'}
            maxWidth={uploadImgWidth}
            minHeight={uploadImgHeight}
            sx={{
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'lightgray',
            }}
            ref={selectEl}
          >
            <ReactCrop crop={crop} onChange={onChangeHandler} onComplete={onComplete}>
              <img
                width={uploadImgWidth}
                height={uploadImgHeight}
                src={getS3ImageSrc(data.s3_url) || getS3ImageSrc(imageData.s3_url)}
                onLoad={onLoadImage}
                ref={originalImgEl}
                crossOrigin="anonymous"
                // style={{objectFit:'cover'}}
              />
            </ReactCrop>
          </Box>
        </Box>
      </DialogContent>
      <Typography
        sx={{ textAlign: 'center', fontSize: '18px', fontWeight: '600', pt: '30px', pb: '28px' }}
      >
        피사체가 덜 지워진 영역을 드래그로 지정하세요.
      </Typography>
      <CenterAlignBox gap={4}>
        <Button
          variant="outlined"
          sx={{ width: '35%', height: '55px', fontWeight: 800, fontSize: '18px' }}
          onClick={areaSelectOnClick}
          disabled={!crop || crop?.width === 0 || crop?.height === 0}
        >
          {isLoading ? (
            <PuffLoader size={45} color={theme.palette.draph.blue} />
          ) : (
            '선택 영역 다시 지우기'
          )}
        </Button>

        <Button
          variant="contained"
          sx={{ width: '35%', height: '55px', fontWeight: 800, fontSize: '18px', color: '#FFF' }}
          onClick={saveOnClick}
        >
          완료하기
        </Button>
      </CenterAlignBox>
    </Dialog>
  )
}

// function InputWithLabel({ items, label, value, setValue, input }) {
//   return (
//     <Stack direction="row" gap={1} alignItems="center">
//       <Typography>
//         {label}
//         <i>
//           <sup style={{ color: '#FF615C' }}>&#42;</sup>
//         </i>
//       </Typography>

//       <Select
//         size="small"
//         sx={selectStyle}
//         value={value || ''}
//         onChange={e => {
//           setValue(prev => ({ ...prev, [input]: e.target.value }))
//         }}
//       >
//         {items?.map(item => {
//           return (
//             <MenuItem key={item} value={item}>
//               {item}
//             </MenuItem>
//           )
//         })}
//       </Select>
//     </Stack>
//   )
// }
