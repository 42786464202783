import React from 'react'
import * as _ from 'lodash'
import Tool from './Tool'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { clicksAtom, imageAtom } from 'atoms'
import ToolforRemovedBg from './ToolforRemovedBg'

const Stage = ({ uploadImgHeight, uploadImgWidth, usage }) => {
  const setClicks = useSetRecoilState(clicksAtom)
  const image = useRecoilValue(imageAtom)

  const getClick = (x, y) => {
    const clickType = 1
    return { x, y, clickType }
  }

  // Get mouse position and scale the (x, y) coordinates back to the natural
  // scale of the image. Update the state of clicks with setClicks to trigger
  // the ONNX model to run and generate a new mask via a useEffect in App.tsx
  const handleMouseMove = _.throttle(e => {
    const el = e.nativeEvent.target
    const rect = el.getBoundingClientRect()
    let x = e.clientX - rect.left
    let y = e.clientY - rect.top
    const imageScale = image ? image.width / el.offsetWidth : 1
    x *= imageScale
    y *= imageScale

    const click = getClick(Number(Math.round(x)), Number(Math.round(y)))
    if (click) setClicks([click])
  }, 15)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: uploadImgWidth,
        height: uploadImgHeight,
      }}
    >
      {usage === 'default' && <Tool handleMouseMove={handleMouseMove} />}
      {usage === 'nukki' && <ToolforRemovedBg handleMouseMove={handleMouseMove} />}
    </div>
  )
}

export default Stage
