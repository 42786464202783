import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Divider,
  InputLabel,
  FormControl,
} from '@mui/material'
import { CenterAlignStack, FormErrorMessage, TextInput } from 'components'
import { Desktop, Mobile, useBreakPoint } from 'hooks/useMediaQuery'
import { useEffect, useRef, useState } from 'react'
import { apis } from 'apis'
import { StyledDataGrid, columns as promotionColumns, MiniButton } from './AdminPromotion'
import { columns as userColumns } from './AdminCredit'
import moment from 'moment'
import { useSnackbar } from 'notistack'

const pColumns = [promotionColumns[0], promotionColumns[1]]

const now = moment().local().format('YYYY-MM-DDTHH:mm:ss')
const today = moment().local().format('YYYY-MM-DD')
const weekLater = moment().add(1, 'W').local().format('YYYY-MM-DD')
const monthLater = moment().add(1, 'M').local().format('YYYY-MM-DD')
const yearLater = moment().add(1, 'Y').local().format('YYYY-MM-DD')

const defaultStartTime = '09:00:00'
const defaultEndTime = '23:59:59'

const columns = [
  {
    field: 'id',
    width: 50,
    headerName: 'ID',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'code',
    width: 250,
    headerName: 'code',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'date',
    width: 180,
    headerName: `발급일 / 만료일 (${moment.tz(moment.tz.guess()).zoneName()}) `,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => (
      <div>
        <span>{moment(moment.utc(params.value).toDate()).local().format('YY-MM-DD HH:mm:ss')}</span>
        <br />
        <span style={{ color: params.row.expires < now && !params.row.expired ? 'red' : '' }}>
          {moment(moment.utc(params.row.expires).toDate()).local().format('YY-MM-DD HH:mm:ss')}
        </span>
      </div>
    ),
  },

  {
    field: 'promotion_id',
    width: 90,
    headerName: '프로모션ID',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'user_id',
    width: 80,
    headerName: '유저ID',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'credit_amount',
    width: 100,
    headerName: '지급 크레딧',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'use_count',
    width: 100,
    headerName: '등록 수',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => (
      <span style={{ color: 'green', fontWeight: 700 }}>
        {params.row.coupon_type === 'common' ? params.value : ''}
      </span>
    ),
  },
  {
    field: 'expired',
    width: 100,
    headerName: '만료여부',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => (
      <span style={{ color: params.row.expires < now && !params.value ? 'red' : '' }}>
        {params.value ? '만료됨' : ''}
      </span>
    ),
  },
]

export default function AdminCoupon() {
  const defaultExpireDate = moment().add(1, 'Y').local().format('YYYY-MM-DD')
  const [couponType, setCouponType] = useState('random')
  const [couponText, setCouponText] = useState('')
  const [selectedPromotionId, setSelectedPromotionId] = useState('')
  const [creditAmount, setCreditAmount] = useState(5)
  const [promotionList, setPromotionList] = useState([])
  const [expireDate, setExpireDate] = useState('')
  const [list, setList] = useState([])
  const [userIdInput, setUserIdInput] = useState('')
  const [targetUser, setTargetUser] = useState(null)

  const { enqueueSnackbar } = useSnackbar()

  const refreshCoupon = () => {
    apis.admin.getCoupon().then(response => {
      setList(response.data)
    })
  }

  useEffect(() => {
    refreshCoupon()

    apis.admin.getPromotion('valid').then(response => {
      setPromotionList(response.data)
    })

    setExpireDate(`${defaultExpireDate}T${defaultEndTime}`)
  }, [])

  useEffect(() => {
    const p = promotionList.filter(p => p.id === selectedPromotionId)[0]
  }, [selectedPromotionId])

  const getTargetUser = () => {
    if (userIdInput.length < 1) return
    apis.admin.getUserById(userIdInput).then(response => {
      setTargetUser(response.data)
    })
  }

  const handleRandomCodeCoupon = () => {
    if (!expireDate) {
      alert('만료일을 입력해주세요')
      return
    }

    const p = promotionList.filter(p => p.id === selectedPromotionId)[0]
    const d = {
      user: targetUser ? `${targetUser.username} (${targetUser.id})` : null,
      credit: creditAmount,
      promotion: selectedPromotionId ? `${p.name} (${p.id})` : null,
    }
    const c = confirm(JSON.stringify(d))

    if (!c) return

    apis.admin
      .createCoupon({
        coupon_type: 'random',
        credit_amount: creditAmount,
        promotion_id: selectedPromotionId.length < 1 ? null : selectedPromotionId,
        expires: expireDate,
        user_id: targetUser ? targetUser.id : null,
      })
      .then(() => {
        refreshCoupon()
        enqueueSnackbar('완료', { variant: 'success' })
      })
  }

  const handleCommonCoupon = () => {
    if (couponText.length < 1 || selectedPromotionId.length < 1) {
      if (selectedPromotionId.length < 1) {
        alert('프로모션을 선택하세요')
      } else if (couponText.length < 1) {
        alert('쿠폰 텍스트를 입력하세요')
      }
      return
    }
    apis.admin
      .createCoupon({
        promotion_id: selectedPromotionId,
        coupon_type: 'common',
        coupon_text: couponText,
        credit_amount: creditAmount,
      })
      .then(response => {
        if (response.data.success) {
          refreshCoupon()
          setCouponText('')
          enqueueSnackbar('완료', { variant: 'success' })
        } else {
          enqueueSnackbar('에러: ' + response.data.message, { variant: 'error' })
        }
      })
      .catch(() => {
        enqueueSnackbar('에러', { variant: 'error' })
      })
  }

  return (
    <div>
      <CenterAlignStack sx={{ width: '100%' }}>
        <Typography sx={{ fontWeight: 800, fontSize: '3rem', mb: 4 }}>쿠폰 관리</Typography>

        <Stack direction="row" sx={{ '& .MuiTypography-root': { cursor: 'pointer' } }}>
          <Typography
            sx={{
              fontSize: '2rem',
              fontWeight: couponType === 'random' ? 600 : 400,
              color: theme => (couponType === 'random' ? theme.palette.draph.blue : 'unset'),
            }}
            onClick={() => setCouponType('random')}
          >
            난수 쿠폰 발급
          </Typography>
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginLeft: '6rem', marginRight: '6rem' }}
          />
          <Typography
            sx={{
              fontSize: '2rem',
              fontWeight: couponType === 'text' ? 600 : 400,
              color: theme => (couponType === 'text' ? theme.palette.draph.blue : 'unset'),
            }}
            onClick={() => setCouponType('text')}
          >
            공용 쿠폰 발급
          </Typography>
        </Stack>

        <CenterAlignStack sx={{ py: '4rem' }}>
          <Stack direction="row" sx={{ py: '3rem' }} spacing={1}>
            <FormControl>
              <InputLabel shrink id="demo-simple-select-label" sx={{ backgroundColor: 'white' }}>
                프로모션 선택
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                label="프로모션 선택"
                sx={{ width: '20rem' }}
                value={selectedPromotionId}
                onChange={e => {
                  setSelectedPromotionId(e.target.value)
                }}
              >
                <MenuItem value={''}>선택안함</MenuItem>
                {promotionList.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.id} - {p.name}
                  </MenuItem>
                ))}
              </Select>
              {couponType === 'text' && (
                <Typography fontSize="1.2rem" color="red">
                  공용 쿠폰은 프로모션을 꼭 선택
                </Typography>
              )}
            </FormControl>

            <TextField
              label="크레딧"
              placeholder="크레딧"
              autoComplete="off"
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              value={creditAmount}
              onChange={e => setCreditAmount(e.target.value)}
            ></TextField>
          </Stack>

          <Stack sx={{ width: '100%' }}>
            <Stack spacing={1}>
              <Typography variant="h5">
                만료일 (쿠폰 등록 기한) ({moment.tz(moment.tz.guess()).zoneName()})
              </Typography>
              <input
                type="datetime-local"
                value={expireDate}
                min={moment().local().format('YYYY-MM-DDTHH:mm:ss')}
                onChange={e => {
                  setExpireDate(e.target.value)
                }}
                style={{ width: '300px' }}
              />
              <Stack direction="row" spacing="0.2rem" sx={{ m: '1rem' }}>
                <MiniButton
                  onClick={() => {
                    setExpireDate(`${today}T${defaultEndTime}`)
                  }}
                >
                  오늘
                </MiniButton>
                <MiniButton
                  onClick={() => {
                    setExpireDate(`${weekLater}T${defaultEndTime}`)
                  }}
                >
                  1주후
                </MiniButton>
                <MiniButton
                  onClick={() => {
                    setExpireDate(`${monthLater}T${defaultEndTime}`)
                  }}
                >
                  1달후
                </MiniButton>
                <MiniButton
                  onClick={() => {
                    setExpireDate(`${yearLater}T${defaultEndTime}`)
                  }}
                >
                  1년후
                </MiniButton>
              </Stack>
            </Stack>

            {couponType === 'random' && (
              <Stack sx={{ my: '2rem', borderRadius: '1rem', border: '1px solid gray', p: '2rem' }}>
                <Typography variant="h5">유저 지정</Typography>
                <br />
                <Stack direction="row">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="user id 또는 username"
                    autoComplete="off"
                    value={userIdInput}
                    sx={{ '& input': { p: 0, py: '5px' } }}
                    onChange={e => setUserIdInput(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        getTargetUser()
                      }
                    }}
                  ></TextField>
                  <Button variant="contained" onClick={getTargetUser}>
                    유저 조회
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setUserIdInput('')
                      setTargetUser(null)
                    }}
                  >
                    리셋
                  </Button>
                </Stack>

                <Box sx={{ width: '30rem' }}>
                  <StyledDataGrid
                    disableColumnMenu
                    rows={targetUser ? [targetUser] : []}
                    columns={[userColumns[0], userColumns[1]]}
                    autoHeight
                    hideFooter
                    onRowClick={v => {
                      console.log(v)
                    }}
                  />
                </Box>
              </Stack>
            )}
          </Stack>

          {couponType === 'text' && (
            <Stack sx={{ my: '40px' }}>
              <TextField
                label="공용쿠폰텍스트"
                autoComplete="off"
                value={couponText}
                sx={{ width: '400px', '& input': { p: 0, py: '5px' } }}
                onChange={e => setCouponText(e.target.value.toUpperCase())}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </Stack>
          )}

          {couponType === 'random' ? (
            <Button
              variant="outlined"
              onClick={handleRandomCodeCoupon}
              sx={{ width: '100%', height: '7rem' }}
            >
              난수 쿠폰 발급
            </Button>
          ) : couponType === 'text' ? (
            <Button
              variant="outlined"
              onClick={handleCommonCoupon}
              sx={{ width: '100%', height: '7rem' }}
            >
              공용 쿠폰 발급
            </Button>
          ) : (
            <></>
          )}
        </CenterAlignStack>

        <div style={{ width: '100rem', marginBottom: '2rem' }}>
          <Divider flexItem />
        </div>

        <Stack direction="row">
          {/* <Stack>
            <Typography>진행중인 프로모션 목록</Typography>
            <Box sx={{ my: '3rem', width: '30rem', height: '50rem', overflow: 'scroll' }}>
              <StyledDataGrid
                disableColumnMenu
                rows={promotionList}
                columns={pColumns}
                autoHeight
                hideFooter
              />
            </Box>
          </Stack> */}
          <Stack>
            <Typography>쿠폰 목록</Typography>
            <Box sx={{ my: '3rem', height: '50rem', overflow: 'scroll' }}>
              <StyledDataGrid
                disableColumnMenu
                rows={list}
                columns={columns}
                autoHeight
                hideFooter
              />
            </Box>
          </Stack>
        </Stack>
      </CenterAlignStack>
    </div>
  )
}
