import { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Divider,
  InputLabel,
  FormControl,
} from '@mui/material'
import { apis } from 'apis'
import { useNavigate } from 'react-router-dom'
import { getAccessToken } from 'utils/cookie'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  userStatusAtom,
  userAtom,
  creditPolicyAtom,
  userCreditAtom,
  userSubscriptionId,
  userSubscriptionIdAtom,
} from 'atoms'

const ADMIN_PASSWORD = [
  'juwan1',
  'taehoon2',
  'minju3',
  'gayeong4',
  'haeun5',
  'jinho6',
  'daehan7',
  'jihye8',
  'hyeonghun9',
]
export default function AdminOnlyWrapper({ children }) {
  const token = getAccessToken()
  const [user, setUser] = useRecoilState(userAtom)
  const [isLoading, setLoading] = useState(true)
  const [reallyAdmin, setReallyAdmin] = useState(false)
  const [t, setT] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      apis.user.getUser().then(response => {
        setUser(response.data)
        if (response.data.is_admin) {
          setLoading(false)
        } else {
          navigate('/')
        }
      })
    }
  }, [token])

  if (isLoading) {
    return (
      <>
        <div></div>
      </>
    )
  }

  const check = () => {
    if (ADMIN_PASSWORD.includes(t)) {
      setReallyAdmin(true)
    }
  }

  if (!reallyAdmin) {
    return (
      <>
        <Stack sx={{ p: 3, width: '400px' }} spacing={2}>
          <Typography variant="h3">어드민 재확인</Typography>
          <TextField
            InputLabelProps={{ shrink: true }}
            label="암호를 입력하시옹"
            value={t}
            onChange={e => {
              setT(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                check()
              }
            }}
          ></TextField>
          <Button onClick={check} variant="contained">
            완료
          </Button>
        </Stack>
      </>
    )
  }

  return children
}
