import { useEffect, useState } from 'react'
import { motion, useScroll, AnimatePresence } from 'framer-motion'
import { ArrowUpInCircleIcon as ArrowUpIcon } from 'theme/icon'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useLocation } from 'react-router-dom'

const scrollVariants = {
  initial: { y: '.5rem', opacity: 0 },
  animate: {
    y: '0rem',
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
}

export const ScrollToTop = ({ targetRef, upside = null }) => {
  const [isVisible, setIsVisible] = useState(false)
  const { scrollY, scrollYProgress } = useScroll({ container: targetRef, layoutEffect: false })
  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    // TODO 모바일에서 표시가 안됨
    const y = isMobile ? 100 : 300
    scrollY.onChange(() => {
      if (scrollY.get() > y) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    })
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const scrollToAnchor = () => {
    const anchor = document.querySelector('#scroll-anchor')

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    }
  }

  return (
    <AnimatePresence>
      {/* <svg
          id="progress"
          width="100"
          height="100"
          viewBox="0 0 100 100"
          style={{ position: 'absolute', transform: 'rotate(-90deg)' }}
        >
          <circle
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            className="bg"
            style={{ strokeDashoffset: 0, strokeWidth: '15%', fill: 'none', stroke: 'gray' }}
          />
          <motion.circle
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            className="indicator"
            style={{ pathLength: scrollYProgress, stroke: 'lime' }}
          />
        </svg> */}
      {isVisible && (
        // {true && (
        <motion.button
          className={'scroll-to-top'}
          // onClick={scrollToTop}
          onClick={upside !== true ? scrollToAnchor : scrollToTop}
          variants={scrollVariants}
          initial="initial"
          animate="animate"
          exit="initial"
          style={{
            background: 'transparent',
            zIndex: 1000,
            bottom: isMobile ? '5.5rem' : '1.5rem',
          }}
        >
          <ArrowUpIcon />
        </motion.button>
      )}
    </AnimatePresence>
  )
}

export function ScrollToTopComponenet() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return null
}
