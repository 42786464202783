import { apis } from 'apis'
import { configData, BANNER_CONFIG_DEFAULT, PORTFOLIO_CONFIG_DEFAULT_BACK } from 'config'
import { PORTFOLIO_CONFIG_DEFAULT } from 'atoms'
import { useRecoilState } from 'recoil'
import moment from 'moment'
import 'moment-timezone'

import watermark from 'watermarkjs'
import { Box } from '@mui/material'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { getAccessToken } from './cookie'
import React from 'react'

export const EMAIL_REGEX = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const PWD_REGEX = /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/
export const PHONE_REGEX = /^\d{3}\d{3,4}\d{4}$/
export const URL_REGEX =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(:[0-9]+)?(\/[^\s]*)?$/

export const getS3ImageSrc = s3Url => (s3Url ? `${configData.STATIC_URL}/${s3Url}` : '')

export function padZeros(number, totalLength = 2) {
  return number.toString().padStart(totalLength, '0')
}

export const isMoblieDevice = () => {
  const userAgent = navigator.userAgent
  const isMoblie = Boolean(
    userAgent.match(/Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i)
  )
  return isMoblie
}

export const kFormatter = num => {
  if (!num) return num
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num)
}

export const numberCommaFormatter = num => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 테스트용...
// https://brianium.github.io/watermarkjs/docs.html

// const l =
//   'https://d1p0kjf7jiqoy5.cloudfront.net/1679614522312_7d2b0b80207641dcbb9d1fc278243fd8_20221228214534/shadow_results/others__16733330418235_cloned_esr_result_0.jpg'
// watermark([l + '?not-from-cache', '/static/images/logo/logo_h_white.png'], {
//   init: function (img) {
//     img.crossOrigin = 'anonymous'
//   },
// })
//   .dataUrl(watermark.image.center(0.5))
//   .then(function (url) {
//     console.log(url)
//   })

export const setWatermark = l => {
  return watermark([l + '?not-from-cache', '/static/images/logo/logo_h_white.png'], {
    init: function (img) {
      img.crossOrigin = 'anonymous'
    },
  }).dataUrl(watermark.image.center(0.5))
}
//

export const utcToLocal = (dateString, format = 'YYYY-MM-DDTHH:mm:ss') => {
  const utcDate = moment.utc(dateString).toDate()
  const localDateString = moment(utcDate).local().format(format)
  return localDateString
}

// export const byteSize = str => new Blob([str]).size

export function byteSize(str) {
  let character
  let charBytes = 0

  for (let i = 0; i < str.length; i += 1) {
    character = str.charAt(i)

    if (escape(character).length > 4) charBytes += 2
    else charBytes += 1
  }

  return charBytes
}

export const getCurrencyFormatter = currency => {
  return currencyFormatter[currency.toLowerCase()]
}

const currencyFormatter = {
  usd: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }),
  krw: new Intl.NumberFormat('ko-KR', {
    style: 'currency',
    currency: 'KRW',
    maximumFractionDigits: 0,
  }),
}

export const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

export function arrayChangeIdx(arr, fromIndex, toIndex) {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}
export const isDev = process.env.NODE_ENV === 'development'

export const AIIcon = ({ color = '#303030' }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: { lg: '6.4rem', xs: '4.6rem' },
        height: { lg: '2.8rem', xs: '2rem' },
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '1rem',
        color: theme.palette.common.white,
        fontWeight: 700,
        fontSize: { lg: '1.6rem', xs: '1.4rem' },
      }}
    >
      AI
    </Box>
  )
}
export const AIIconMini = ({ color = '#303030' }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: { lg: '2.65rem', xs: '2.65rem' },
        height: { lg: '1.16rem', xs: '1.16rem' },
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '6px',
        color: theme.palette.common.white,
        fontWeight: 700,
        fontSize: { lg: '1rem', xs: '1rem' },
      }}
    >
      AI
    </Box>
  )
}

export const getPieceType = path => {
  if (path.includes('bgremoved')) {
    return 'white'
  } else if (path.includes('basic')) {
    return 'simple'
  } else if (path.includes('simplegen')) {
    return 'simplegen'
  } else if (path.includes('imggen')) {
    return 'generate'
  } else if (path.includes('animated')) {
    return 'animated'
  } else {
    return 'complex'
  }
}

export const sortPieces = object => {
  const pieces = object.pieces

  if (!pieces.length) return pieces

  const categorizedPieces = {
    bgremoved: [],
    basic: [],
    simplegen: [],
    imggen: [],
    animated: [],
    none: [],
  }

  pieces.forEach(piece => {
    if (piece.path.includes('bgremoved')) {
      categorizedPieces.bgremoved.push(piece)
    } else if (piece.path.includes('animated')) {
      categorizedPieces.animated.push(piece)
    } else if (piece.path.includes('basic')) {
      categorizedPieces.basic.push(piece)
    } else if (piece.path.includes('simplegen')) {
      categorizedPieces.simplegen.push(piece)
    } else if (piece.path.includes('imggen')) {
      categorizedPieces.imggen.push(piece)
    } else {
      categorizedPieces.none.push(piece)
    }
  })

  const sortedPieces = [
    ...categorizedPieces.bgremoved,
    ...categorizedPieces.simplegen,
    ...categorizedPieces.basic,
    ...categorizedPieces.none,
    ...categorizedPieces.imggen,
    ...categorizedPieces.animated,
  ]

  return sortedPieces
}

export const GIFstandardDate = moment('20231016', 'YYYYMMDD')
export const GIFCompogenDate = moment('20240723', 'YYYYMMDD')

export function imageToFile(imgsrc, name = '') {
  // atob : base64로 인코딩된 src 디코딩
  const bstr = window.atob(imgsrc.split(',')[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  const f = new File([u8arr], name.length > 0 ? name : 'image.png', { type: 'mime' })
  return f
  // javascript 보안상 file을 input type file value에 할당할 수는 없음.
}

export const resizeImage = (f, whLimit) => {
  const reader = new FileReader()
  const image = new Image()
  const canvas = document.createElement('canvas')
  const imageType = f.type
  // console.log('f', f)
  const dataURItoBlob = function (dataURI) {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? window.atob(dataURI.split(',')[1])
        : window.decodeURI(dataURI.split(',')[1])
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const max = bytes.length
    const ia = new Uint8Array(max)
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i)

    // console.log(imageType)
    return new Blob([ia], { type: imageType })
  }
  //  'image/jpeg'
  const resize = function () {
    let width = image.width
    let height = image.height
    if (width > height) {
      if (width > whLimit) {
        height *= whLimit / width
        width = whLimit
      }
    } else {
      if (height > whLimit) {
        width *= whLimit / height
        height = whLimit
      }
    }
    canvas.width = width
    canvas.height = height
    canvas.getContext('2d').drawImage(image, 0, 0, width, height)
    const dataUrl = canvas.toDataURL(imageType)
    return dataURItoBlob(dataUrl)
  }

  return new Promise(function (resolve, reject) {
    if (!f.type.match(/image.*/)) {
      reject(new Error('Not an image'))
      return
    }
    reader.onload = function (readerEvent) {
      image.onload = function () {
        return resolve(resize())
      }

      image.src = readerEvent.target.result
    }
    reader.readAsDataURL(f)
  })
}

export function isKo(i18n) {
  return i18n.language === 'ko'
}

export function trasnferDate(date, option = 'num', i18n) {
  const lang = i18n ? i18n.language : 'ko'
  const form = {
    num: { ko: 'YYYY-MM-DD', en: 'DD-MM-YYYY' },
    numM: { ko: 'YY-MM-DD', en: 'DD-MM-YY' },
    dot: { ko: 'YYYY.MM.DD', en: 'DD.MM.YYYY' },
    dotM: { ko: 'YY.MM.DD', en: 'DD.MM.YY' },
    detail: {
      ko: 'YYYY년 MM월 DD일',
      en: 'MMM Do YY',
    },
  }

  return moment(date).format(form[option][lang])
}

export async function imageUrlToFile(imgURL, fileName = 'image.jpg') {
  try {
    const cacheBustingURL = `${imgURL}?w=${Date.now()}`

    const blob = await fetch(cacheBustingURL).then(r => r.blob())

    // const blob = await fetch(imgURL + '?not-from-cache').then(r => r.blob())
    const file = new File([blob], fileName, { type: blob.type })
    return file
  } catch (error) {
    console.error('Error converting image URL to file:', error)
    return null
  }
}

export function removeTags(htmlString) {
  if (htmlString === null || htmlString === '') return false
  else htmlString = htmlString.toString()
  return htmlString
    .replace(/(<([^>]+)>)/gi, ' ')
    .replace(/\s{2,}/g, ' ')
    .replace(/&nbsp;/gi, ' ')
}

export const CheckForIndexHTMLChange = async indexHTMLURL => {
  try {
    const resp = await fetch(indexHTMLURL, { method: 'get', mode: 'cors' })
    const text = await resp.text()
    const r = /^.*<script.*\/(main.*\.js).*$/gim.exec(text)
    if (!r || r.length < 2) {
      return
    }
    const remoteMainScript = r.length > 1 ? r[1] : null
    if (remoteMainScript === null) {
      return
    }
    let localMainScript = null
    const scripts = document.body.getElementsByTagName('script')
    for (const script of scripts) {
      const rl = /^.*\/(main.*\.js).*$/gim.exec(script.src)
      if (!rl || rl.length < 2) {
        continue
      }
      localMainScript = rl[1]
      break
    }
    if (localMainScript === null) {
      return
    }
    return remoteMainScript !== localMainScript
  } catch (err) {
    console.log(err)
    return false
  }
}

export const isFullUrl = str => {
  const regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ //eslint-disable-line
  return regex.test(str)
}

export const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp']

export const imageTransparent = originalBase64Image => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      // 이미지 수정 작업 수행
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const data = imageData.data
      for (let i = 0; i < data.length; i += 4) {
        if (data[i] > 200 && data[i + 1] > 200 && data[i + 2] > 200) {
          data[i + 3] = 0 // 알파값을 0으로 설정하여 흰색 부분 투명하게 만듦
        }
      }
      ctx.putImageData(imageData, 0, 0)

      // 수정된 이미지를 Base64로 인코딩하여 resolve를 호출하여 반환
      const modifiedBase64Image = canvas.toDataURL('image/png')
      resolve(modifiedBase64Image)
    }
    img.onerror = function () {
      reject(new Error('Failed to load image'))
    }
    img.src = originalBase64Image
  })
}

export const convertToBlob = data => {
  const jsonStr = JSON.stringify(data)
  const blob = new Blob([jsonStr], { type: 'application/json' })
  return blob
}

export const nullZero = data => {
  if (data === null) return 0
  return data
}

export function getDefaultConfigByType(type) {
  switch (type) {
    case 'banner':
      return BANNER_CONFIG_DEFAULT
    default:
      return {}
  }
}

export const getDownloadPieceFileName = ({ prefix, idx, ext, width = null, height = null }) => {
  let fileName = `${prefix}_draphed_${padZeros(idx)}`

  if (width && height) {
    fileName += `_${width}x${height}`
  }

  fileName += `.${ext}`
  return fileName
}

export const bannerCopyReprocessing = copyArray => {
  const result = { title: [], subtitle: [] }

  if (!copyArray) return

  const addArray = ['직접입력', '없음']

  for (let i = 0; i < copyArray[0].length; i += 2) {
    // i와 i+1 인덱스의 요소로 하위 배열을 만듭니다.
    result.title.push([copyArray[0][i], copyArray[0][i + 1] || ''])
  }

  for (let i = 0; i < copyArray[1].length; i += 2) {
    result.subtitle.push([copyArray[1][i], copyArray[1][i + 1] || ''])
  }

  result.title.push(...addArray)
  result.subtitle.push(...addArray)

  return result
}

export function rgbToHex(arr) {
  if (_.isArray(arr)) {
    const [r, g, b] = arr
    // 각 색상 값을 16진수로 변환하고, 결과가 한 자리수면 앞에 '0'을 붙입니다.
    const toHex = c => c.toString(16).padStart(2, '0').toUpperCase()

    // RGB 값을 HEX 코드로 변환합니다.
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
  }
}

export function hexToRgb(hex) {
  // HEX 코드의 앞에 '#'가 있다면 제거합니다.

  if (hex.startsWith('#')) {
    hex = hex.slice(1)
  }

  // 16진수 문자열을 정수로 변환합니다.
  var r = parseInt(hex.substring(0, 2), 16)
  var g = parseInt(hex.substring(2, 4), 16)
  var b = parseInt(hex.substring(4, 6), 16)

  return [r, g, b]
}

export const canvasToBlob = canvas => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob) {
        resolve(blob)
      } else {
        reject(new Error('Canvas to Blob 변환 실패'))
      }
    }, 'image/png')
  })
}

export function loadCanvasFromJSON(newCanvas, canvasData) {
  return new Promise((resolve, reject) => {
    newCanvas.loadFromJSON(canvasData, () => {
      resolve()
    })
  })
}

export function base64ToBlob(base64, mimeType) {
  // Base64 문자열을 디코딩하여 바이너리 데이터로 변환
  const byteCharacters = atob(base64.replace(/^data:image\/[a-z]+;base64,/, ''))
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  // 바이너리 데이터로 Blob 객체 생성
  return new Blob(byteArrays, { type: mimeType })
}

export const initBrowserNotification = () => {
  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification')
  } else if (Notification.permission !== 'denied') {
    // need to ask the user for permission
    Notification.requestPermission().then(() => {})
  }
}

export const sendApiErrorAlert = ({ path, httpStatus, message, detail }) => {
  const token = getAccessToken()
  if (token) {
    apis.user.alert({ path, http_status: httpStatus, message, detail: JSON.stringify(detail) })
  }
}

export function convertPriceSymbolToWord(input) {
  // '$' 기호를 제거합니다.
  let result = input.replace('₩', '')
  // '원' 문자열을 추가합니다.
  result += '원'
  return result
}

export const extractTextFromComponent = component => {
  let text = ''

  const extractText = children => {
    React.Children.forEach(children, child => {
      if (typeof child === 'string') {
        text += child + ' '
      } else if (React.isValidElement(child)) {
        extractText(child.props.children)
      }
    })
  }

  extractText(component.props.children)
  return text.trim()
}

export const extractTextFromDOM = element => {
  if (element.id.includes('noSearch')) return ''

  const text = element.innerText || element.textContent
  return text.replace(/\n/g, ' ').trim()
}

export const transferToFront = config => {
  const transferConfig = {
    ...PORTFOLIO_CONFIG_DEFAULT,
    genShadow: config.gen_shadow,
    genFace: config.gen_face,
    category: config.object_category ?? 'auto',
    subCategory: config.object_sub_category ?? 'auto',
    objectBoundary: config.object_boundary ?? 'none',
    objectAngle: config.angle ?? 'auto',
    bgGenerate: config.flag_generate,
    bgComplex: config.flag_complex_cmp,
    bgSimple: config.flag_simple_cmp,
    bgWhite: config.flag_white_cmp,

    flagGenCompo: config.flag_gen_compo ?? false,
    flagBgExpansion: config.flag_bg_expansion ?? false,
    flagMultiblobSod: config.flag_multiblob_sod ?? false,

    flagHumanBackground: config.flag_human_background ?? false,
    SOLengthScale: config.SO_length_scale ?? 'auto',

    flagFacemorphing: config.flag_facemorphing ?? false,
    facemorphingRace: config.facemorphing_race ?? 'asian',
    facemorphingGender: config.facemorphing_gender ?? 'none',

    outputWidth: config.output_size_w ?? 0,
    outputHeight: config.output_size_h ?? 0,

    selected_bg_ids: '',

    theme: '',
    theme_background: '',
    theme_template: config.theme_template ?? 'auto',
    theme_custom: config.theme_custom ?? '',

    outputSizeList:
      config.output_size_list?.length &&
      config.output_size_list[0].output_w === 0 &&
      config.output_size_list[0].output_h === 0
        ? [{ w: null, h: null }]
        : config.output_size_list?.map(o => ({ w: o.output_w, h: o.output_h })) ?? [
            { w: null, h: null },
          ],

    hasRefImage: config.has_ref_image ?? false,

    simpleBgColorList: config.simple_bg_color_list,
  }

  return transferConfig
}

export const transferToBack = config => {
  const editedConfigForbody = { ...config }

  // null 값을 제거한 outputSizeList 생성
  editedConfigForbody.outputSizeList =
    config.outputSizeList?.filter(o => o.w != null && o.h != null) ?? []

  // 필터링된 리스트의 길이에 따라 simpleBgColorList 설정
  editedConfigForbody.simpleBgColorList =
    editedConfigForbody.outputSizeList.length > 1
      ? [[255, 255, 255]]
      : PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList

  // 첫 번째 유효한 output size 가져오기
  const firstOutputList = editedConfigForbody.outputSizeList[0]
  const outputWidth = firstOutputList?.w ?? 0
  const outputHeight = firstOutputList?.h ?? 0

  const transferConfig = {
    ...PORTFOLIO_CONFIG_DEFAULT_BACK,

    gen_shadow: config.genShadow,
    gen_face: config.genFace,
    object_category: config.category ?? 'auto',
    object_sub_category: config.subCategory ?? 'auto',
    object_boundary: config.objectBoundary ?? 'none',
    object_angle: config.objectAngle ?? 'auto',
    flag_generate: config.bgGenerate,
    flag_complex_cmp: config.bgComplex,
    flag_simple_cmp: config.bgSimple,
    flag_white_cmp: config.bgWhite,

    flag_gen_compo: config.flagGenCompo ?? false,
    flag_bg_expansion: config.flagBgExpansion ?? false,
    flag_multiblob_sod: config.flagMultiblobSod ?? false,

    flag_human_background: config.flagHumanBackground ?? false,
    SO_length_scale: config.SOLengthScale ?? 'auto',

    flag_facemorphing: config.flagFacemorphing ?? false,
    facemorphing_race: config.facemorphingRace ?? 'asian',
    facemorphing_gender: config.facemorphingGender ?? 'none',

    output_size_w: outputWidth ?? 0,
    output_size_h: outputHeight ?? 0,

    selected_bg_ids: '',

    theme: '',
    theme_background: '',
    theme_template: config.theme_template ?? 'auto',
    theme_custom: config.theme_custom ?? '',

    output_size_list: editedConfigForbody.outputSizeList.length
      ? editedConfigForbody.outputSizeList.map(o => ({ output_w: o.w, output_h: o.h }))
      : [],
    simple_bg_color_list:
      editedConfigForbody.simpleBgColorList ?? PORTFOLIO_CONFIG_DEFAULT.simpleBgColorList,
  }

  return transferConfig
}

export const notify = (message, options) => {
  if ('Notification' in window && Notification.permission === 'granted' && message) {
    return new Notification(message, options)
  }
}

export const shuffle = items => {
  return items.sort(() => 0.5 - Math.random())
}

export const MODEL_FACE_CHANGE = moment('20240909', 'YYYYMMDD')

// 배열 넣으면 랜덤하게 하나 뱉는 함수
export function getRandomElement(arr) {
  if (arr.length === 0) {
    return null
  }
  const randomIndex = Math.floor(Math.random() * arr.length)
  return arr[randomIndex]
}
