import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { artworkRegenCountAtom, endpointAtom } from 'atoms'
import { CenterAlignStack } from 'components'
import { useEffect, useState } from 'react'

import { apis } from 'apis'
import useConfirm from 'hooks/useConfirm'
import { useParams } from 'react-router-dom'

export const endpointCookieName = 'image_upload_config'
const defaultText =
  '{"app_front":"https://api-dev.draph.art/", "app_face":"http://host.docker.internal:6912/api/v1/face_morphing"}'

export default function DevEndpointDialog({ open, setOpen }) {
  const [text, setText] = useState('')
  const [valid, setValid] = useState(true)
  const [errorText, setErrorText] = useState('')
  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)
  const resetEndpont = useResetRecoilState(endpointAtom)

  useEffect(() => {
    setText(JSON.stringify(endpoint))
  }, [])

  useEffect(() => {
    if (text.length < 1) {
      const configText = localStorage.getItem(endpointCookieName)

      if (configText) {
        setText(configText)
      } else {
        setText(defaultText)
      }
    }
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const onChangeText = e => {
    const str = e.target.value
    setText(str)
    if (str.length < 1) {
      setValid(true)
      setErrorText('')
      return
    }

    try {
      JSON.parse(str)
      setValid(true)
      setErrorText('')
    } catch (e) {
      setValid(false)
      setErrorText('Invalid')
    }
  }

  const saveEndpointConfig = () => {
    if (text.length > 1) {
      const config = JSON.parse(text)
      setEndpoint(config)

      localStorage.setItem(endpointCookieName, text)
    } else {
      resetEndpont()
      localStorage.removeItem(endpointCookieName)
    }
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">엔드포인트 설정</DialogTitle>
      <DialogContent>
        <Typography>리셋하려면 텍스트를 모두 지운 후 저장하세요</Typography>
        <TextField
          label="JSON"
          multiline
          rows={10}
          sx={{ m: 2, width: '40rem' }}
          InputLabelProps={{ shrink: true }}
          value={text}
          onChange={onChangeText}
          error={!valid}
          helperText={errorText}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveEndpointConfig} disabled={!valid}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  )
}
