import { MHidden } from 'components/@material-extend'
import {
  Box,
  Typography,
  Checkbox,
  Grid,
  Divider,
  IconButton,
  Stack,
  TextField,
  Pagination,
  Button,
  Select,
  MenuItem,
  ToggleButton,
  Backdrop,
  CircularProgress,
  Skeleton,
} from '@mui/material'
import {
  AddPieceDialog,
  CenterAlignBox,
  CenterAlignStack,
  ImageSwipeableDrawer,
  PieceGallery,
} from 'components'

import { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react'
import { styled, useTheme, alpha } from '@mui/material/styles'
import {
  GIFstandardDate,
  getPieceType,
  getQueryParam,
  getS3ImageSrc,
  imageUrlToFile,
  isDev,
  isKo,
  padZeros,
  setWatermark,
  sortPieces,
  utcToLocal,
} from 'utils/common'
import _, { isMap, result } from 'lodash'

import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Navigation, Autoplay, Swiper, Keyboard, Controller } from 'swiper'
import { checkedURLforNoCache } from './ArtworkList'
import { backgroundLoadingAtom, defaultPortfolioAtom, portfolioTypeAtom, userAtom } from 'atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { apis } from 'apis'
import useConfirm from 'hooks/useConfirm'
import * as config from 'config'
import { CirclePlusIcon, ErrorTriangleIcon, getPaletteColor } from 'theme/icon'
import { English, Korean } from 'hooks/useLanguage'
import { bgRemovedStyle, isRemovedBg } from 'utils/artwork'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import {
  dracula,
  vscDarkPlus,
  solarizedlight,
} from 'react-syntax-highlighter/dist/esm/styles/prism'
import palette from 'theme/palettes/default'
import ConfigViewer from 'components/ConfigViewer'
import { RIGHT_CLICK_ALLOW_USER } from 'App'

const ImageSwiperNavigatePrev = () => {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5118 0.488155C10.911 -0.112651 9.96563 -0.158867 9.31181 0.349508L9.1548 0.488155L0.821468 8.82149C0.220662 9.4223 0.174446 10.3677 0.682821 11.0215L0.821468 11.1785L9.1548 19.5118C9.80568 20.1627 10.861 20.1627 11.5118 19.5118C12.1126 18.911 12.1588 17.9657 11.6505 17.3118L11.5118 17.1548L4.35831 10L11.5118 2.84518C12.1126 2.24437 12.1588 1.29899 11.6505 0.645168L11.5118 0.488155Z"
        fill="#303030"
      />
    </svg>
  )
}

const ImageSwiperNavigateNext = () => {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.488155 0.488155C1.08896 -0.112651 2.03435 -0.158867 2.68817 0.349508L2.84518 0.488155L11.1785 8.82149C11.7793 9.4223 11.8255 10.3677 11.3172 11.0215L11.1785 11.1785L2.84518 19.5118C2.1943 20.1627 1.13903 20.1627 0.488155 19.5118C-0.112651 18.911 -0.158867 17.9657 0.349508 17.3118L0.488155 17.1548L7.64167 10L0.488155 2.84518C-0.112651 2.24437 -0.158867 1.29899 0.349508 0.645168L0.488155 0.488155Z"
        fill="#303030"
      />
    </svg>
  )
}

export const LARGE_IMAGE_WIDTH_REM = 40
const slidesPerView = 3
const thumbImageWidthRem = 12

// rem
const thumbSpaceBetween =
  (LARGE_IMAGE_WIDTH_REM - thumbImageWidthRem * slidesPerView) / (slidesPerView - 1)

const ImageCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
    borderRadius: '2rem',
  },
  '&:hover': {
    '& .MuiSvgIcon-root': {
      // boxShadow: '0px 0px 4px rgba(0, 232, 185, 0.4)',
    },
  },
}))

const CircleIcon = props => {
  const color = props.color ? getPaletteColor(props.color) ?? props.color : '#909090'
  const fill = props.fill ? getPaletteColor(props.fill) ?? props.fill : 'none'
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill={fill} fillOpacity="0.5" />
      <rect
        x="1"
        y="1"
        width="26"
        height="26"
        rx="13"
        stroke={color}
        strokeOpacity="0.8"
        strokeWidth="2"
      />
    </svg>
  )
}

const CheckedCircleIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="26"
        height="26"
        rx="13"
        fill="white"
        fillOpacity="0.7"
        stroke="#2C4DFF"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6886 10.8559C18.1259 10.4185 18.8351 10.4185 19.2725 10.8559C19.6762 11.2596 19.7073 11.8949 19.3656 12.3343L19.2725 12.4398L13.6725 18.0398C13.2687 18.4435 12.6334 18.4746 12.1941 18.133L12.0886 18.0398L8.72855 14.6798C8.29117 14.2424 8.29117 13.5333 8.72855 13.0959C9.1323 12.6921 9.76759 12.6611 10.207 13.0027L10.3125 13.0959L12.8805 15.663L17.6886 10.8559Z"
        fill="#303030"
      />
    </svg>
  )
}

export function ArtworkRowImageSwiper({
  artwork,
  setArtwork,
  artworkIdx,
  checked,
  setChecked,
  hideThumbSwiper = false,
  setCurrentPiece, // 지금 크게 보여지는 피스
  canMakeGIF, // 지금 크게 보여지는 피스 GIF 제작 가능 여부
  goToPiece = null,
  setGoToPiece,
  refreshArtworks,
  showDialog,
  pieceImageSize,
  setPieceImageSize,
}) {
  const artworkDone = artwork.status === config.ARTWORK_DONE_STATUS
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isBannerPage = portfolioType === config.PORTFOLIO_TYPE_BANNER
  const user = useRecoilValue(userAtom)

  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE
  const onlyOneOutput = isRemoveBgPage || isFacePage

  const onLoadUploaded = e => {
    if (e.target.naturalWidth > 1 && e.target.naturalHeight > 1) {
      setArtwork(a => {
        return { ...a, uploadedSize: { w: e.target.naturalWidth, h: e.target.naturalHeight } }
      })
    }
  }

  const artworkConfig = JSON.parse(artwork.config)

  return (
    <>
      {artworkDone || artwork.pieces?.length > 0 ? (
        <>
          {isBannerPage ? (
            <>
              <BannerArtworkSwiper
                artwork={artwork}
                setArtwork={setArtwork}
                artworkIdx={artworkIdx}
                checked={checked}
                setChecked={setChecked}
                hideThumbSwiper={hideThumbSwiper}
                setCurrentPiece={setCurrentPiece}
                refreshArtworks={refreshArtworks}
                showDialog={showDialog}
                pieceImageSize={pieceImageSize}
                setPieceImageSize={setPieceImageSize}
              />
              {/* 원본 이미지(업로드한 이미지)의 사이즈  체크를 위해 display:none 으로 있어야함 */}
              <img
                src={getS3ImageSrc(artwork.uploaded)}
                onLoad={onLoadUploaded}
                style={{ display: 'none' }}
              />
            </>
          ) : (
            <>
              <GeneratedImageSwiper
                artwork={artwork}
                setArtwork={setArtwork}
                artworkIdx={artworkIdx}
                checked={checked}
                setChecked={setChecked}
                hideThumbSwiper={hideThumbSwiper}
                setCurrentPiece={setCurrentPiece}
                canMakeGIF={canMakeGIF} // 현재 선택된(크게 보이고 있는) 피스가 gif 생성 가능한지
                goToPiece={goToPiece}
                setGoToPiece={setGoToPiece}
                refreshArtworks={refreshArtworks}
                showDialog={showDialog}
                pieceImageSize={pieceImageSize}
                setPieceImageSize={setPieceImageSize}
              />
              <img
                src={getS3ImageSrc(artwork.uploaded)}
                onLoad={onLoadUploaded}
                style={{ display: 'none' }}
              />
            </>
          )}
        </>
      ) : artworkError ? (
        // 피스가 하나도 없고 에러 상태
        <>
          <Box
            sx={{
              width: `${LARGE_IMAGE_WIDTH_REM}rem`,
              height: `${LARGE_IMAGE_WIDTH_REM}rem`,
              '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              },
            }}
          >
            <img src={getS3ImageSrc(artwork.uploaded)} onLoad={onLoadUploaded} />
          </Box>
        </>
      ) : (
        // 생성중이고 피스가 하나도 없는 로딩상태
        <GeneratedImageSwiperSkeleton largeImageOnly={onlyOneOutput} />
      )}

      {(isDev || RIGHT_CLICK_ALLOW_USER.includes(user.id)) && (
        <ConfigViewer config={artworkConfig} time={artwork.created} />
      )}
    </>
  )
}

function GeneratedImageSwiper({
  artwork,
  setArtwork,
  artworkIdx,
  checked,
  setChecked,
  hideThumbSwiper = false,
  setCurrentPiece, // 지금 크게 보여지는 피스
  canMakeGIF, // 지금 크게 보여지는 피스 GIF 제작 가능 여부
  goToPiece = null,
  setGoToPiece,
  refreshArtworks,
  showDialog,
  pieceImageSize,
  setPieceImageSize,
}) {
  // artworkIdx: 현재 페이지의 아트워크 목록에서 이 아트워크의 인덱스

  const [swiper, setSwiper] = useState()
  const [previewSwiper, setPreviewSwiper] = useState()
  const [currentItem, setCurrentItem] = useState()
  const [currentItemIdx, setCurrentItemIdx] = useState(0)

  const [prevButtonDisabled, setPrevButtonDisabled] = useState(false)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
  const [canAddPiece, setCanAddPiece] = useState(false)

  const [openShowAll, setOpenShowAll] = useState(false)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const setLoading = useSetRecoilState(backgroundLoadingAtom)
  const user = useRecoilValue(userAtom)

  const { t } = useTranslation()
  const { showConfirm } = useConfirm()

  const items = artwork.pieces
  const isMultiBlob = JSON.parse(artwork.config)?.flag_multiblob_sod ?? false

  const artworkDone = config.ARTWORK_DONE_STATUS === artwork.status
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)
  const artworkLoading =
    (!artworkDone && !artworkError) || config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)

  //
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE

  const onlyOneOutput = isRemoveBgPage || isFacePage

  //

  useEffect(() => {
    const previewSw = new Swiper(`#preview-swiper-container-${artworkIdx}`, {
      navigation: {
        prevEl: `.result-image-viewer-${artworkIdx} .result-swiper-button-prev`,
        nextEl: `.result-image-viewer-${artworkIdx} .result-swiper-button-next`,
      },
      modules: [Navigation, Controller, Keyboard],
      slidesPerView: 1,
      on: {
        slideChange: function () {
          // console.log('큰 스와이퍼 onSlideChange', previewSw.realIndex)
          setCurrentItemIdx(previewSw.realIndex)
        },
      },
    })

    if (_.isArray(previewSw)) {
      setPreviewSwiper(previewSw[0])
    } else {
      setPreviewSwiper(previewSw)
    }

    // 썸네일 목록 스와이퍼

    if (!hideThumbSwiper) {
      const sw = new Swiper(`#result-swiper-container-${artworkIdx}`, {
        modules: [Controller],
        slidesPerView: slidesPerView,
        spaceBetween: thumbSpaceBetween * 10, // px
        centeredSlides: true,
        centeredSlidesBounds: true,
        slideToClickedSlide: true,

        on: {
          slideChange: function (e) {
            // 동기화 방향..
            // 큰 스와이퍼(previewSwipe) 의 슬라이드 변경 -> currentItemIdx 변경 -> useEffect 에서 썸네일 스와이퍼 슬라이드 변경
            // console.log('썸넬 스와이퍼 onSlideChange', e.realIndex)
          },
        },
      })

      if (_.isArray(sw)) {
        setSwiper(sw[0])
      } else {
        setSwiper(sw)
      }
    }
  }, [])

  useEffect(() => {
    if (artwork.pieces) {
      setChecked(Array(artwork.pieces?.length).fill(false))
    }
  }, [artwork.pieces])

  useEffect(() => {
    if (artworkLoading) {
      setPrevButtonDisabled(true)
      setNextButtonDisabled(true)
    } else {
      if (currentItemIdx === 0) {
        setPrevButtonDisabled(true)
      } else {
        setPrevButtonDisabled(false)
      }

      if (artwork.pieces && currentItemIdx === artwork.pieces.length - 1) {
        setNextButtonDisabled(true)
      } else {
        setNextButtonDisabled(false)
      }
    }

    if (swiper && swiper.realIndex !== currentItemIdx) {
      // 작은 이미지(썸네일)스와이퍼를 움직여준다
      swiper.slideTo(currentItemIdx, 200)
    }
  }, [currentItemIdx])

  useEffect(() => {
    setCurrentItem(items[currentItemIdx])
  }, [currentItemIdx, artwork.pieces?.length])

  useEffect(() => {
    // 부모에게 넘겨주는 데이터
    setCurrentPiece(currentItem)
  }, [currentItem])

  useEffect(() => {
    if (artwork.pieces?.length < 15 && !isMultiBlob && ['upload', null].includes(portfolioType)) {
      // 배경 생성 메뉴, 유저생성 포트폴리오 메뉴에서만 가능
      setCanAddPiece(true)
    } else {
      setCanAddPiece(false)
    }
  }, [artwork.pieces?.length])

  useEffect(() => {
    // 큰 이미지 스와이퍼의 슬라이드 인덱스 조정하는 useEffect
    if (previewSwiper) {
      previewSwiper.update()
    }

    // if-else 슬라이드 이동 우선순위
    // 1) 생성중일 때는 무조건 맨 뒤 슬라이드
    // 2) goToPiece
    // 3) 아트워크
    // 4) (버그 대응..) 슬라이드 인덱스가 슬라이드 개수를 넘어갔을 때 맨 뒤 슬라이드로 세팅
    if (artworkLoading) {
      // console.log(previewSwiper?.realIndex, ' / ', artwork.pieces.length - 1)
      if (previewSwiper && previewSwiper.realIndex !== artwork.pieces.length) {
        try {
          // 맨 끝 '생성중' 슬라이드가 보이도록 피스길이-1이 아닌 피스길이 인덱스로보냄
          previewSwiper.slideTo(artwork.pieces.length)
        } catch (err) {
          console.log(err)
        }
      }
    } else if (Number.isInteger(goToPiece)) {
      let toIdx = goToPiece
      if (goToPiece === -1) {
        toIdx = artwork.pieces.length - 1
      }
      // setCurrentItemIdx(toIdx)
      // console.log('GOTO', toIdx, ' / ', previewSwiper.slides.length)
      if (previewSwiper && previewSwiper.slides.length - 1 >= toIdx) {
        previewSwiper.slideTo(toIdx)
        setGoToPiece()
      }
    } else if (previewSwiper && artworkDone) {
      previewSwiper.slideTo(0)
    } else if (artwork.pieces?.length <= currentItemIdx) {
      if (previewSwiper) {
        previewSwiper.slideTo(artwork.pieces.length - 1)
      } else {
        setCurrentItemIdx(artwork.pieces.length - 1)
      }
    }
  }, [artwork.pieces?.length, previewSwiper, artworkDone, artworkLoading])

  const handleCheck = (idx, ck) => {
    const c = [...checked]
    c[idx] = ck
    setChecked(c)
  }

  const makePieceGif = () => {
    const body = {
      user_id: user.id.toString(),
      portfolio_id: portfolioId,
      artwork_id: artwork.id,
      gen_img_url_list: [getS3ImageSrc(currentItem.path)],
    }

    setLoading(true)

    apis.appfront
      .getAnimatedFile(body)
      .then(res => {
        setLoading(false)
        if (res.data.code === 200) {
          refreshArtworks(() => {
            setGoToPiece(-1)
          })
        } else {
          return showConfirm({
            alertOnly: true,
            content: (
              <>
                <Typography>{t('common.error')}</Typography>
              </>
            ),
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })

    // ----- GA4 event -----
    window.gtag('event', portfolioType ? 'gif_begin' : 'portfolio_gif_begin', {
      method: 'piece',
    })
    // ---------------------
  }

  const onLoadPieceImage = (e, piece) => {
    setPieceImageSize(s => {
      const size = {
        ...s,
      }
      size[piece.id] = { w: e.target.naturalWidth, h: e.target.naturalHeight }
      return size
    })
  }

  return (
    <>
      <Desktop>
        <CenterAlignStack
          className={`result-image-viewer result-image-viewer-${artworkIdx}`}
          sx={{
            userSelect: 'none',
            position: 'relative',
            overflow: 'hidden',
            // border: '1px dashed red',
          }}
        >
          {/* 크게보는 프리뷰 스와이퍼 */}
          <CenterAlignStack
            direction="row"
            sx={{
              '& .result-swiper-container': {
                width: '40rem',
                overflow: 'hidden',
                mx: '1.6rem',
                position: 'relative',
              },

              '& .swiper-slide': {
                cursor: 'pointer',
                width: `${LARGE_IMAGE_WIDTH_REM}rem`,
                height: `${LARGE_IMAGE_WIDTH_REM}rem`,
                '& img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  opacity: artworkLoading ? 0.6 : 1,
                },
              },

              '& .swiper-button': {
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '4rem',
                height: '4rem',
              },

              position: 'relative',
            }}
          >
            <Box
              className={`swiper-button result-swiper-button-prev`}
              sx={{
                '& svg path': {
                  fill: prevButtonDisabled ? '#BBBBBB' : '',
                },
              }}
            >
              {!onlyOneOutput && <ImageSwiperNavigatePrev />}
            </Box>

            {artworkLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 999,
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={`${LARGE_IMAGE_WIDTH_REM}rem`}
                  height={`${LARGE_IMAGE_WIDTH_REM}rem`}
                />
              </Box>
            )}

            <Box id={`preview-swiper-container-${artworkIdx}`} className="result-swiper-container">
              {artworkDone && canMakeGIF && (
                // 피스 한장에 대하여 GIF 생성하는 버튼
                <Box sx={{ position: 'absolute', top: '0.8rem', right: '0.8rem', zIndex: 2 }}>
                  <MakeGIFBadge handleClick={makePieceGif} />
                </Box>
              )}
              <div className="swiper-wrapper result-swiper-wrapper">
                {artwork.pieces?.map((piece, idx) => {
                  const path = piece.path
                  const img = getS3ImageSrc(checkedURLforNoCache(path))

                  return (
                    <div
                      key={idx}
                      className={`swiper-slide`}
                      onClick={() => {
                        showDialog(artwork, idx, { pieceImageSize })
                      }}
                    >
                      <Box
                        component="img"
                        src={img}
                        sx={{ ...(isRemovedBg(piece.path) && bgRemovedStyle) }}
                        onLoad={e => {
                          onLoadPieceImage(e, piece)
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </Box>

            <Box
              className={`swiper-button result-swiper-button-next`}
              sx={{
                '& svg path': {
                  fill: nextButtonDisabled ? '#BBBBBB' : '',
                },
              }}
            >
              {!onlyOneOutput && <ImageSwiperNavigateNext />}
            </Box>

            {/*  몇장 중 몇장인지 M / N */}
            {!onlyOneOutput && (
              <CenterAlignBox
                sx={{
                  zIndex: 1,
                  position: 'absolute',
                  px: { lg: '1.3rem', xs: '1rem' },
                  py: { lg: '0.5rem', xs: '0.3rem' },
                  background: '#3030304D',
                  borderRadius: '4px',
                  fontSize: { lg: '1.2rem', xs: '1rem' },
                  fontWeight: 500,
                  color: 'white',
                  bottom: '0.8rem',

                  left: '50%',
                  transform: 'translate(-50%)',
                }}
              >
                {currentItemIdx + 1} / {artwork.pieces?.length}
              </CenterAlignBox>
            )}
          </CenterAlignStack>

          {/* 작은 썸네일 스와이퍼 */}
          {!hideThumbSwiper && (
            <CenterAlignStack
              direction="row"
              id={`result-swiper-box-${artworkIdx}`}
              sx={{
                mt: '1.6rem',
                '& .result-swiper-container': {
                  width: `${LARGE_IMAGE_WIDTH_REM}rem`,
                  overflow: 'hidden',
                  mx: '1.6rem',
                },

                '& .swiper-slide': {
                  minWidth: `${thumbImageWidthRem}rem`,
                  maxWidth: `${thumbImageWidthRem}rem`,
                  width: `${thumbImageWidthRem}rem`,
                  height: `${thumbImageWidthRem}rem`,
                  position: 'relative',

                  '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',

                    opacity: artworkLoading ? 0.6 : 1,
                  },
                  '& .slide': {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                  },

                  '&.current .slide': {
                    border: artworkLoading ? '' : '4px solid #2C4DFF',
                  },
                },

                '& .swiper-button': {
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '4rem',
                  height: '4rem',
                },
              }}
            >
              <Box id={`result-swiper-container-${artworkIdx}`} className="result-swiper-container">
                <div className="swiper-wrapper result-swiper-wrapper">
                  {artwork.pieces?.map((piece, idx) => {
                    const path = piece.path
                    const img = getS3ImageSrc(checkedURLforNoCache(path))

                    return (
                      <div
                        key={idx}
                        className={`swiper-slide ${idx === currentItemIdx && 'current'}`}
                        onClick={() => {
                          // setCurrentItemIdx(idx)
                          if (previewSwiper) {
                            previewSwiper.slideTo(idx, 200)
                          }
                        }}
                      >
                        <ThumbnailImage
                          artworkLoading={artworkLoading}
                          img={img}
                          handleCheck={ck => {
                            handleCheck(idx, ck)
                          }}
                          checked={
                            checked?.length > 0 && typeof checked[idx] !== typeof undefined
                              ? checked[idx]
                              : false
                          }
                          isRemovedBg={isRemovedBg}
                          darkCheckbox={isBundlePage}
                        />
                        <div className="slide" />
                      </div>
                    )
                  })}

                  {artworkLoading && (
                    <Box component="div" className={`swiper-slide`}>
                      <LoadingThumbnail artwork={artwork} />
                    </Box>
                  )}

                  {artworkDone && canAddPiece && (
                    <Box
                      component="div"
                      className={`swiper-slide`}
                      sx={
                        {
                          // borderLeft: '1px solid #D9E1EC',
                        }
                      }
                    >
                      <AddPieceItem artwork={artwork} setArtwork={setArtwork} />
                    </Box>
                  )}
                </div>
              </Box>
            </CenterAlignStack>
          )}

          {(artworkDone || artworkLoading) && !onlyOneOutput && (
            // 피스 전체 보기 drawer 열기 버튼
            <>
              <ShowAllButton
                artworkLoading={artworkLoading}
                handleClick={
                  artworkDone
                    ? () => {
                        setOpenShowAll(o => !o)
                      }
                    : null
                }
                openShowAll={openShowAll}
                setOpenShowAll={setOpenShowAll}
              />
            </>
          )}

          {/* 생성중인 아트워크 스와이퍼 인터랙션을 막기 위해 */}
          {artworkLoading && (
            <Box
              sx={{
                // opacity: 0.3,
                // background: 'pink',
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 99,
              }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
            ></Box>
          )}

          <PieceGallery
            artwork={artwork}
            open={openShowAll}
            setOpen={setOpenShowAll}
            sx={{ width: `${LARGE_IMAGE_WIDTH_REM}rem` }}
            handleCheck={handleCheck}
            handleClcikImage={idx => {
              showDialog(artwork, idx, { pieceImageSize })
            }}
            checked={checked}
            isRemovedBg={isRemovedBg}
            isBundlePage={isBundlePage}
            previewSwiper={previewSwiper}
          />
        </CenterAlignStack>
      </Desktop>
    </>
  )
}

function GeneratedImageSwiperSkeleton({ largeImageOnly = false }) {
  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ width: '51.2rem' }} spacing="1.2rem">
          <CenterAlignStack direction="row" spacing="1.2rem">
            {/* <Skeleton
              variant="rectangular"
              width="4rem"
              height="9rem"
              sx={{ background: 'transparent' }}
            /> */}

            <Box
              width="4rem"
              height="9rem"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg path': {
                  fill: '#BBBBBB',
                },
              }}
            >
              {!largeImageOnly && <ImageSwiperNavigatePrev />}
            </Box>
            <Skeleton
              variant="rectangular"
              width={`${LARGE_IMAGE_WIDTH_REM}rem`}
              height={`${LARGE_IMAGE_WIDTH_REM}rem`}
            />

            <Box
              width="4rem"
              height="9rem"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg path': {
                  fill: '#BBBBBB',
                },
              }}
            >
              {!largeImageOnly && <ImageSwiperNavigateNext />}
            </Box>
            {/* <Skeleton
              variant="rectangular"
              width="4rem"
              height="9rem"
              sx={{ background: 'transparent' }}
            /> */}
          </CenterAlignStack>
          {!largeImageOnly && (
            <>
              <CenterAlignStack
                direction="row"
                spacing={`${thumbSpaceBetween}rem`}
                sx={{ mx: '1.6rem' }}
              >
                <Skeleton
                  variant="rectangular"
                  width={`${thumbImageWidthRem}rem`}
                  height={`${thumbImageWidthRem}rem`}
                />
                <Skeleton
                  variant="rectangular"
                  width={`${thumbImageWidthRem}rem`}
                  height={`${thumbImageWidthRem}rem`}
                />
                <Skeleton
                  variant="rectangular"
                  width={`${thumbImageWidthRem}rem`}
                  height={`${thumbImageWidthRem}rem`}
                />
              </CenterAlignStack>

              <Skeleton
                variant="rectangular"
                width={`${LARGE_IMAGE_WIDTH_REM}rem`}
                height={`3.2rem`}
              />
            </>
          )}
        </CenterAlignStack>
      </Desktop>
    </>
  )
}

export function ThumbnailImage({
  artworkLoading,
  img,
  darkCheckbox = false,
  handleCheck,
  checked,
  isRemovedBg,
  ...props
}) {
  return (
    <Box sx={{ width: '100%', height: '100%', position: 'relative' }} {...props}>
      <Box component="img" src={img} sx={{ ...(isRemovedBg(img) && bgRemovedStyle) }} />
      {!artworkLoading && (
        <Box sx={{ position: 'absolute', bottom: 0, right: 0, p: '8px' }}>
          <ImageCheckbox
            disableRipple
            checked={checked}
            onClick={e => {
              e.stopPropagation()
              handleCheck(!checked)
            }}
            // onChange={handleCheck}
            icon={
              <CircleIcon
                color={darkCheckbox ? '#909090' : 'common.white'}
                fill={darkCheckbox ? 'rgb(209, 209, 209, 0.6)' : '#FFFFFF50'}
              />
            }
            checkedIcon={<CheckedCircleIcon />}
          />
        </Box>
      )}
    </Box>
  )
}

function MakeGIFBadge({ handleClick }) {
  const { t } = useTranslation()
  return (
    <>
      <CenterAlignBox
        onClick={handleClick}
        sx={{
          borderRadius: '0.4rem',
          background: theme => theme.palette.common.black,
          py: '0.5rem',
          px: '1.3rem',
          cursor: 'pointer',
          opacity: 0.6,
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <Typography sx={{ fontSize: '1.2rem', fontWeight: 500, color: 'white' }}>
          {t('background_item.GIF_create')}
        </Typography>
      </CenterAlignBox>
    </>
  )
}

function AddPieceItem({ artwork, setArtwork }) {
  const [openAddDialog, setOpenAddDialog] = useState(false)

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  return (
    <>
      <CenterAlignBox
        sx={{
          width: `${thumbImageWidthRem}rem`,
          height: `${thumbImageWidthRem}rem`,
          alignItems: 'center',
          cursor: 'pointer',
          backgroundColor: '#FAFAFA',
          boxShadow: '0px 0px 0px 1px #D9D9D9 inset',
          '&:hover': {
            backgroundColor: '#EEEEEE',
          },
        }}
        onClick={() => {
          setOpenAddDialog(true)
          // ----- GA4 event -----
          window.gtag(
            'event',
            !portfolioType ? 'portfolio_bg_regen_begin' : 'upload_bg_regen_begin',
            {}
          )
          // ---------------------
        }}
      >
        {artwork.feedback_status === config.ARTWORK_ADDPIECE_ERROR_STATUS && (
          <Stack
            direction="row"
            sx={{
              position: 'absolute',
              top: { lg: '1.4rem', xs: '0.4rem' },
              alignItems: 'center',
            }}
          >
            <Desktop>
              <ErrorTriangleIcon />
            </Desktop>
            <Typography
              color="#FF2323B2"
              fontSize={{ lg: '1.2rem', xs: '1rem' }}
              fontWeight={500}
              sx={{ ml: '0.4rem', mt: { lg: 0, xs: '0.5rem' } }}
            >
              <Korean>다시 시도해주세요</Korean>
              <English>Please try again</English>
            </Typography>
          </Stack>
        )}

        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 2V26"
            stroke="#808080"
            strokeWidth="4"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M1.99985 13.9995H25.9998"
            stroke="#808080"
            strokeWidth="4"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      </CenterAlignBox>
      <AddPieceDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
        artwork={artwork}
        setArtwork={setArtwork}
      />
    </>
  )
}

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  '&.MuiCircularProgress-root': {
    color: '#7DA3FF',
  },

  '& .MuiCircularProgress-svg': {
    transform: 'scaleY(-1)',

    '& .MuiCircularProgress-circle': {
      '&::after': {
        content: '" *"',
        color: theme => theme.palette.common.red,
      },
    },
  },
}))

function LoadingThumbnail({ artwork }) {
  const r = 4
  const totalStage = 3

  const t0 = (2 * Math.PI) / (totalStage + 1)
  const d0 = 360 / (totalStage + 1)

  // const r = thumbnailMode ? (isMobile ? 4.5 : 5.5) : isMobile ? 8 : 10 // 원형 progress 의 반지름 길이 (단위는 rem)

  const { t } = useTranslation()

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress(config.ARTWORK_STATUS_DICT[artwork.status]?.progress)
  }, [artwork.status])

  return (
    <>
      <CenterAlignBox
        sx={{
          width: `${thumbImageWidthRem}rem`,
          height: `${thumbImageWidthRem}rem`,
          alignItems: 'center',
          backgroundColor: '#fff',
          boxShadow: '0px 0px 0px 1px #D9E1EC inset',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: `${2 * r}rem`,
            height: `${2 * r}rem`,
          }}
        >
          <Box sx={{ position: 'absolute' }}>
            <CircularProgress
              variant="determinate"
              value={100}
              thickness={2.7}
              size={`${2 * r}rem`}
              sx={{
                color: '#EFEFEF',
              }}
            />
          </Box>
          <Typography
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: `${2 * r}rem`,
              textAlign: 'center',
            }}
            fontSize={{ lg: '1.2rem', xs: '1.2rem' }}
            fontWeight={700}
            color="#000000"
          >
            {artwork.feedback_status === config.ARTWORK_ADDPIECE_STATUS &&
            config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)
              ? t('configOrDict.ARTWORK_STATUS_DICT.add')
              : artwork.feedback_status === config.ARTWORK_MODPIECE_STATUS &&
                config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)
              ? t('configOrDict.ARTWORK_STATUS_DICT.size')
              : config.ARTWORK_STATUS_DICT[artwork.status]?.text
              ? t(
                  `configOrDict.ARTWORK_STATUS_DICT.${
                    config.ARTWORK_STATUS_DICT[artwork.status]?.text
                  }`
                )
              : ''}
          </Typography>
          {(config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status) ||
            config.ARTWORK_REGEN_STATUS.includes(artwork.status)) && (
            <>
              {/* 돌아가는 애니메이션 원 */}
              <Box
                sx={{
                  position: 'absolute',
                  transform: 'scaleX(-1)',
                }}
              >
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={{
                    // color: 'gray',
                    // opacity: '0.5',
                    color: theme => theme.palette.draph.newblue,
                    animationDuration: '1500ms',
                  }}
                  size={`${2 * r}rem`}
                  thickness={1}
                />
              </Box>

              {/* 실제 progress 표시 원 */}

              {/* <StyledCircularProgress
                variant="determinate"
                value={progress}
                thickness={1}
                size={`${2 * r}rem`}
              /> */}

              {/* 항상 [3 생성중] 으로 보이게되어서 색칠된 원과 숫자부분은 제거 */}
              {/* {artwork.feedback_status !== config.ARTWORK_ADDPIECE_STATUS &&
                artwork.feedback_status !== config.ARTWORK_MODPIECE_STATUS &&
                config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx > 0 && (
                  <Box
                    sx={{
                      position: 'relative',
                      borderRadius: '50%',
                      transform: `rotate(-${
                        config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx * d0
                      }deg)`,
                      transition: 'transform 0.3s linear',
                    }}
                    width={`${2 * r}rem`}
                    height={`${2 * r}rem`}
                  >
                    
                    <CenterAlignBox
                      sx={{
                        width: '2.5rem',
                        height: '2.5rem',
                        position: 'absolute',
                        borderRadius: '50%',
                        alignItems: 'center',
                        backgroundColor: theme => theme.palette.draph.blue,
                        transform: `rotate(${
                          config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx * d0
                        }deg)`,
                        left: `${r - 2.5 / 2}rem`,
                        top: `-${2.5 / 2}rem`,
                        transition: 'transform 0.3s linear',

                        boxShadow: '2px 2px 10px rgba(77, 128, 255, 0.4)',

                        // left: `${r + indexPosition[0] - 4.5 / 2}rem`,
                        // top: `${r - indexPosition[1] - 4.5 / 2}rem`,
                        // transition: 'left 1s, top 1s',
                      }}
                    >
                      <Typography fontSize="1.3rem" fontWeight={800} color="#FFFFFF">
                        {config.ARTWORK_STATUS_DICT[artwork.status]?.progressIdx}
                      </Typography>
                    </CenterAlignBox>
                  </Box>
                )} */}
            </>
          )}
        </Box>
      </CenterAlignBox>
    </>
  )
}

function BannerArtworkSwiper({
  artwork,
  setArtwork,
  artworkIdx,
  checked,
  setChecked,
  setCurrentPiece,
  refreshArtworks,
  showDialog,
  pieceImageSize,
  setPieceImageSize,

  darkCheckbox = false,
}) {
  // artworkIdx: 현재 페이지의 아트워크 목록에서 이 아트워크의 인덱스

  const [swiper, setSwiper] = useState()
  const [textSwiper, setTextSwiper] = useState()
  const [currentItem, setCurrentItem] = useState()
  const [currentItemIdx, setCurrentItemIdx] = useState()

  const [prevButtonDisabled, setPrevButtonDisabled] = useState(false)
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)

  const [openShowAll, setOpenShowAll] = useState(false)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId

  const setLoading = useSetRecoilState(backgroundLoadingAtom)
  const user = useRecoilValue(userAtom)

  const { t } = useTranslation()
  const { showConfirm } = useConfirm()

  const items = artwork.pieces
  const isMultiBlob = JSON.parse(artwork.config)?.flag_multiblob_sod ?? false

  const artworkDone = config.ARTWORK_DONE_STATUS === artwork.status
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)
  const artworkLoading =
    (!artworkDone && !artworkError) || config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status)

  //
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE

  //

  useEffect(() => {
    // console.log(JSON.parse(artwork.config))
  }, [])

  useEffect(() => {
    return () => {
      if (swiper) swiper.destroy()
      if (textSwiper) textSwiper.destroy()
    }
  }, [artwork.pieces.length])

  useEffect(() => {
    if (artwork.pieces?.length <= currentItemIdx) {
      if (swiper) {
        swiper.slideTo(artwork.pieces.length - 1)
      } else {
        setCurrentItemIdx(artwork.pieces.length - 1)
      }
    } else if (artworkLoading) {
      if (swiper && swiper.realIndex !== artwork.pieces.length - 1) {
        swiper.slideTo(artwork.pieces.length - 1)
      }
    }
  }, [artwork.pieces?.length, swiper?.slides?.length])

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0)
    }
  }, [artwork.status])

  useEffect(() => {
    if (swiper) swiper.destroy()

    // 배너 문구 표시 스와이퍼
    // const textSw = new Swiper(`#text-swiper-container-${artworkIdx}`, {
    //   navigation: {
    //     nextEl: `#text-swiper-box-${artworkIdx} .text-swiper-button-next`,
    //     prevEl: `#text-swiper-box-${artworkIdx} .text-swiper-button-prev`,
    //   },
    //   modules: [Navigation, Controller, Keyboard],
    //   slidesPerView: 1,
    //   on: {
    //     slideChange: function () {
    //       setCurrentItemIdx(textSw.realIndex)
    //     },
    //   },
    // })

    // if (_.isArray(textSw)) {
    //   setTextSwiper(textSw[0])
    // } else {
    //   setTextSwiper(textSw)
    // }

    // 배너 이미지 스와이퍼
    const sw = new Swiper(`#swiper-container-${artworkIdx}`, {
      modules: [Controller, Navigation],
      navigation: {
        nextEl: `#swiper-box-${artworkIdx} .swiper-button-next`,
        prevEl: `#swiper-box-${artworkIdx} .swiper-button-prev`,
      },
      slidesPerView: 1,
      spaceBetween: thumbSpaceBetween * 10, // px
      centeredSlides: true,
      centeredSlidesBounds: true,
      slideToClickedSlide: true,

      on: {
        slideChange: function (e) {
          setCurrentItemIdx(sw.realIndex)
        },
      },
    })

    if (_.isArray(sw)) {
      setSwiper(sw[0])
    } else {
      setSwiper(sw)
    }

    if (!Number.isInteger(currentItemIdx)) {
      setCurrentItemIdx(0)
    }

    if (artwork.pieces) {
      setChecked(Array(artwork.pieces?.length).fill(false))
    }
  }, [artwork.pieces])

  useEffect(() => {
    // console.log('currentItemIdx changed to', currentItemIdx)

    if (artworkLoading) {
      setPrevButtonDisabled(true)
      setNextButtonDisabled(true)
    } else {
      if (currentItemIdx === 0) {
        setPrevButtonDisabled(true)
      } else {
        setPrevButtonDisabled(false)
      }

      if (artwork.pieces && currentItemIdx === artwork.pieces.length - 1) {
        setNextButtonDisabled(true)
      } else {
        setNextButtonDisabled(false)
      }
    }

    setCurrentItem(items[currentItemIdx])
  }, [currentItemIdx])

  useEffect(() => {
    // 부모에게 넘겨주는 데이터
    setCurrentPiece(currentItem)
  }, [currentItem])

  useEffect(() => {
    if (swiper && swiper.realIndex !== currentItemIdx) {
      setCurrentItemIdx(swiper.realIndex)
    }
  }, [swiper?.realIndex])

  useEffect(() => {}, [checked])

  const handleCheck = (idx, ck) => {
    const c = [...checked]
    c[idx] = ck
    setChecked(c)
  }

  const onLoadPieceImage = (e, piece) => {
    setPieceImageSize(s => {
      s[piece.id] = { w: e.target.naturalWidth, h: e.target.naturalHeight }
      return s
    })
  }

  const itemSize = currentItem ? pieceImageSize[currentItem?.id] : null

  return (
    <>
      <Desktop>
        <CenterAlignStack
          className="result-image-viewer"
          sx={{ userSelect: 'none', position: 'relative', overflow: 'hidden' }}
        >
          {/* 크게보는 프리뷰 스와이퍼 */}
          <CenterAlignStack
            id={`swiper-box-${artworkIdx}`}
            direction="row"
            sx={{
              position: 'relative',
              // background: 'pink',
              '& .result-swiper-container': {
                width: `${LARGE_IMAGE_WIDTH_REM}rem`,
                // border: '1px dashed red',
                overflow: 'hidden',
                mx: '1.6rem',
              },

              '& .swiper-slide': {
                // border: '1px dashed red',
                width: `${LARGE_IMAGE_WIDTH_REM}rem`,
                height: `${LARGE_IMAGE_WIDTH_REM}rem`,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                '& .image-checkbox-wrapper': {
                  position: 'relative',
                  ...(itemSize &&
                    itemSize.w > itemSize.h && {
                      background: 'gold',
                      width: `${LARGE_IMAGE_WIDTH_REM}rem`,
                      height: `${LARGE_IMAGE_WIDTH_REM * (itemSize.h / itemSize.w)}rem`,
                    }),
                  ...(itemSize &&
                    itemSize.h > itemSize.w && {
                      background: 'pink',
                      width: `${LARGE_IMAGE_WIDTH_REM * (itemSize.w / itemSize.h)}rem`,
                      height: `${LARGE_IMAGE_WIDTH_REM}rem`,
                    }),
                },

                '& img': {
                  // width: '100%',
                  // height: '100%',
                  objectFit: 'contain',
                  opacity: artworkLoading ? 0.6 : 1,
                },
              },

              '& .swiper-button': {
                marginTop: '0 !important',
                // border: '1px dashed black',
                position: 'unset !important',
                backgroundImage: 'none',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '4rem',
                height: '4rem',
              },

              // mb: '1.2rem',
              // position: 'relative',
            }}
          >
            <Box
              className={`swiper-button swiper-button-prev`}
              sx={{
                '& svg path': {
                  fill: prevButtonDisabled ? '#BBBBBB' : '',
                },
              }}
            >
              <ImageSwiperNavigatePrev />
            </Box>

            {artworkLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 999,
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={`${LARGE_IMAGE_WIDTH_REM}rem`}
                  height={`${LARGE_IMAGE_WIDTH_REM}rem`}
                />
              </Box>
            )}

            <Box id={`swiper-container-${artworkIdx}`} className="result-swiper-container">
              <div className="swiper-wrapper result-swiper-wrapper">
                {artwork.pieces?.map((piece, idx) => {
                  const path = piece.path
                  const img = getS3ImageSrc(checkedURLforNoCache(path))

                  return (
                    <div
                      key={idx}
                      className={`swiper-slide`}
                      onClick={() => {
                        showDialog(artwork, idx, { pieceImageSize })
                      }}
                    >
                      <Box className="image-checkbox-wrapper">
                        <Box
                          component="img"
                          src={img}
                          sx={{ ...(isRemovedBg(piece.path) && bgRemovedStyle) }}
                          onLoad={e => {
                            onLoadPieceImage(e, piece)
                          }}
                        />

                        {!artworkLoading && (
                          <Box sx={{ position: 'absolute', bottom: 0, right: 0, p: '8px' }}>
                            <ImageCheckbox
                              disableRipple
                              checked={
                                checked?.length > 0 && typeof checked[idx] !== typeof undefined
                                  ? checked[idx]
                                  : false
                              }
                              onClick={e => {
                                e.stopPropagation()
                                const c =
                                  checked?.length > 0 && typeof checked[idx] !== typeof undefined
                                    ? checked[idx]
                                    : false
                                handleCheck(idx, !c)
                              }}
                              icon={
                                <CircleIcon
                                  color={darkCheckbox ? '#909090' : 'common.white'}
                                  fill={darkCheckbox ? 'rgb(209, 209, 209, 0.6)' : '#FFFFFF50'}
                                />
                              }
                              checkedIcon={<CheckedCircleIcon />}
                            />
                          </Box>
                        )}
                      </Box>
                    </div>
                  )
                })}
              </div>
            </Box>
            <Box
              className={`swiper-button swiper-button-next`}
              sx={{
                '& svg path': {
                  fill: nextButtonDisabled ? '#BBBBBB' : '',
                },
              }}
            >
              <ImageSwiperNavigateNext />
            </Box>

            {/*  몇장 중 몇장인지 M / N */}
            <CenterAlignBox
              sx={{
                zIndex: 1,
                position: 'absolute',
                px: { lg: '1.3rem', xs: '1rem' },
                py: { lg: '0.5rem', xs: '0.3rem' },
                background: '#3030304D',
                borderRadius: '4px',
                fontSize: { lg: '1.2rem', xs: '1rem' },
                fontWeight: 500,
                color: 'white',
                bottom: '0.8rem',

                left: '50%',
                transform: 'translate(-50%)',
              }}
            >
              {currentItemIdx + 1} / {artwork.pieces?.length}
            </CenterAlignBox>
          </CenterAlignStack>

          {/* 배너 문구 텍스트 스와이퍼 */}
          {/* <CenterAlignStack
            direction="row"
            id={`text-swiper-box-${artworkIdx}`}
            sx={{
              '& .text-swiper-container': {
                width: `${LARGE_IMAGE_WIDTH_REM}rem`,
                overflow: 'hidden',
                mx: '1.6rem',
              },

              '& .swiper-slide': {
                width: '100%',
                height: `${thumbImageWidthRem}rem`,
                position: 'relative',

                '& img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',

                  opacity: artworkLoading ? 0.6 : 1,
                },
                '& .slide': {
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                },

                '&.current .slide': {
                  border: artworkLoading ? '' : '4px solid #2C4DFF',
                },
              },

              '& .swiper-button': {
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '4rem',
                height: '4rem',
              },
            }}
          >
            <Box
              className={`swiper-button text-swiper-button-prev`}
              sx={{
                '& svg path': {
                  fill: prevButtonDisabled ? '#BBBBBB' : '',
                },
              }}
            >
              <ImageSwiperNavigatePrev />
            </Box>

            <Box id={`text-swiper-container-${artworkIdx}`} className="text-swiper-container">
              <div className="swiper-wrapper text-swiper-wrapper">
                {artwork.pieces?.map((piece, idx) => {
                  const path = piece.path
                  const img = getS3ImageSrc(checkedURLforNoCache(path))

                  return (
                    <div
                      key={idx}
                      className={`swiper-slide ${idx === currentItemIdx && 'current'}`}
                      onClick={() => {
                        // setCurrentItemIdx(idx)
                        if (textSwiper) {
                          textSwiper.slideTo(idx, 200)
                        }
                      }}
                    >
                      <ThumbnailImage
                        artworkLoading={artworkLoading}
                        img={img}
                        handleCheck={ck => {
                          handleCheck(idx, ck)
                        }}
                        checked={
                          checked?.length > 0 && typeof checked[idx] !== typeof undefined
                            ? checked[idx]
                            : false
                        }
                        isRemovedBg={isRemovedBg}
                        darkCheckbox={isBundlePage}
                      />
                      <div className="slide" />
                    </div>
                  )
                })}

                {artworkLoading && (
                  <Box component="div" className={`swiper-slide`}>
                    <LoadingThumbnail artwork={artwork} />
                  </Box>
                )}
              </div>
            </Box>
            <Box
              className={`swiper-button text-swiper-button-next`}
              sx={{
                '& svg path': {
                  fill: nextButtonDisabled ? '#BBBBBB' : '',
                },
              }}
            >
              <ImageSwiperNavigateNext />
            </Box>
          </CenterAlignStack> */}

          {(artworkDone || artworkLoading) && (
            // 피스 전체 보기 drawer 열기 버튼
            <>
              <ShowAllButton
                artworkLoading={artworkLoading}
                handleClick={
                  artworkDone
                    ? () => {
                        setOpenShowAll(o => !o)
                      }
                    : null
                }
                openShowAll={openShowAll}
                setOpenShowAll={setOpenShowAll}
              />
            </>
          )}

          {/* 생성중인 아트워크 스와이퍼 인터랙션을 막기 위해 */}
          {artworkLoading && (
            <Box
              sx={{
                // opacity: 0.3,
                // background: 'pink',
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 99,
              }}
            ></Box>
          )}

          <PieceGallery
            artwork={artwork}
            open={openShowAll}
            setOpen={setOpenShowAll}
            sx={{ width: `${LARGE_IMAGE_WIDTH_REM}rem` }}
            handleCheck={handleCheck}
            handleClcikImage={idx => {
              showDialog(artwork, idx, { pieceImageSize })
            }}
            checked={checked}
            isRemovedBg={isRemovedBg}
            darkCheckbox={isBundlePage}
            previewSwiper={swiper}
          />
        </CenterAlignStack>
      </Desktop>
    </>
  )
}

function ShowAllButton({ artworkLoading, handleClick, openShowAll, setOpenShowAll }) {
  return (
    <CenterAlignBox
      sx={{
        mt: '1.6rem',
        background: '#EEEEEE',
        alignItems: 'center',
        width: { lg: `${LARGE_IMAGE_WIDTH_REM}rem` },
        fontSize: { lg: '1.4rem', xs: '1.2rem' },
        fontWeight: 600,
        lineHeight: { lg: '2.4rem' },
        py: '0.4rem',
        cursor: 'pointer',
        position: 'relative',
        zIndex: 4,

        opacity: artworkLoading ? 0.7 : 1,
      }}
      onClick={handleClick}
    >
      <CenterAlignStack direction="row" sx={{ justifyContent: 'center', width: '100%' }}>
        <Korean>{openShowAll ? '접기' : '전체 보기'}</Korean>
        <English>{openShowAll ? '' : 'Show All'}</English>

        <Box sx={{ display: 'flex', ml: '8px' }}>
          {openShowAll ? (
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.79289 9.29289C6.43241 9.65338 6.40468 10.2206 6.7097 10.6129L6.79289 10.7071L11.7929 15.7071C12.1534 16.0676 12.7206 16.0953 13.1129 15.7903L13.2071 15.7071L18.2071 10.7071C18.5976 10.3166 18.5976 9.68342 18.2071 9.29289C17.8466 8.93241 17.2794 8.90468 16.8871 9.2097L16.7929 9.29289L12.5 13.585L8.20711 9.29289C7.84662 8.93241 7.27939 8.90468 6.8871 9.2097L6.79289 9.29289Z"
                fill="#303030"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.29289 15.7071C5.93241 15.3466 5.90468 14.7794 6.2097 14.3871L6.29289 14.2929L11.2929 9.29289C11.6534 8.93241 12.2206 8.90468 12.6129 9.2097L12.7071 9.29289L17.7071 14.2929C18.0976 14.6834 18.0976 15.3166 17.7071 15.7071C17.3466 16.0676 16.7794 16.0953 16.3871 15.7903L16.2929 15.7071L12 11.415L7.70711 15.7071C7.34662 16.0676 6.77939 16.0953 6.3871 15.7903L6.29289 15.7071Z"
                fill="#303030"
              />
            </svg>
          )}
        </Box>
      </CenterAlignStack>
    </CenterAlignBox>
  )
}
