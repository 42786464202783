import { Box, Button, Grow, Snackbar, Stack, Typography, useTheme } from '@mui/material'
import { newPortfolioAtom, tutorialAtom } from 'atoms'
import { useEffect, useRef, useState } from 'react'

import { useRecoilState, useResetRecoilState } from 'recoil'
import { TutorialSkipArrow, ArtworkGrayCloseIcon, TutorialCloseIcon } from './icon'
import { useNavigate } from 'react-router-dom'
import { Desktop, Mobile, useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { apis } from 'apis'
import { CenterAlignBox, CenterAlignStack } from 'components'
import useConfirm from 'hooks/useConfirm'

const textStyle = { fontSize: '2rem', fontWeight: 800 }
const mobileTextStyle = { fontSize: '1.4rem', fontWeight: 800, color: 'black' }

const filterStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 4000,
}

const filterStyle1 = {
  position: 'absolute',
  top: '-1561px',
  left: '-50vw',
  width: '100vw',
  height: '1705px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 4000,
}

const noFilterStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  zIndex: 4000,
}

const skipArrow = {
  height: '7.2rem',
  width: '7.2rem',
  backgroundColor: '#fff',
  cursor: 'pointer',
  position: 'absolute',
  zIndex: 4001,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  animation: 'opacity ease-in-out 1.5s infinite',
  borderRadius: '10px',
  boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.25)',
}

const skipArrow1 = {
  width: '5rem',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  position: 'relative',
  zIndex: 4001,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  animation: 'moving1 ease-in-out 1s infinite',
}

export const tutorialStylePack = step => {
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const isDesktop = useDesktopMediaQuery()
  const theme = useTheme()

  let childZIndex = null
  let glowEffectBorder = null

  switch (true) {
    case tutorial.mode && tutorial.step === step && step === 0:
      if (isDesktop) {
        childZIndex = { position: 'relative', zIndex: 4001 }
        glowEffectBorder = {
          border: '2px solid white',
          borderRadius: '30px',
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          padding: '1rem',
          background: `rgba(255, 255, 255, 0.15)`,
          animation: 'wave ease-in-out 1s infinite',
        }
      } else {
        childZIndex = { position: 'relative', zIndex: 4001 }
        glowEffectBorder = {
          border: '2px solid white',
          borderRadius: '35px',
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          padding: '1rem',
          background: `rgba(255, 255, 255, 0.15)`,
          animation: 'wave ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 1:
      if (isDesktop) {
        childZIndex = { position: 'relative', zIndex: 4001, background: 'white' }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          marginTop: '3.1rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }
      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 2:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          padding: '0px 7.1rem',
          marginTop: '2.2rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          marginTop: '3rem',
          paddingTop: '3.5rem',
          paddingBottom: '3.5rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }
      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 3:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          padding: '2.7rem 7.1rem',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          marginTop: '3rem',
          padding: '2.5rem 100%',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === '3_1':
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          padding: '0rem calc(50vw - 9.8rem) 2rem',
          margin: '2rem 0',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          margin: '3rem 0',
          padding: '0rem 100%',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === '3_2':
      if (isDesktop) {
        childZIndex = {
          position: 'absolute',
          zIndex: 4001,
          background: 'white',
          padding: '2rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 4:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          padding: '0px 7.1rem',
          marginTop: '2.2rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          marginTop: '3rem',
          paddingTop: '0.1rem',
          paddingBottom: '0.1rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 5:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: '#F8F8F8',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: '#F8F8F8',
          width: '100vw',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 6:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          padding: '0px 7.1rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          width: '100vw',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 7:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          padding: '0px 5.8rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1rem',
          marginBottom: '1.5rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }
      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 8:
      if (isDesktop) {
        childZIndex = {
          position: 'absolute',
          zIndex: 4001,
          background: 'white',
          padding: '2rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'absolute',
          zIndex: 4001,
          background: 'white',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 9:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }
      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 10:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 11:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          // padding: '0px 2rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          // paddingRight: '12px',
          // paddingLeft: '4px',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 12:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: '#F8F8F8',
          padding: '0px 10px 0px 0px',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
          padding: '0px 13px 0px 1px',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 13:
      if (isDesktop) {
        childZIndex = {
          position: 'absolute',
          zIndex: 4001,
          background: 'white',
          padding: '2rem',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          background: 'white',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 14:
      childZIndex = {
        position: 'relative',
        zIndex: 4001,
        background: 'white',
      }
      glowEffectBorder = {
        boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
        animation: 'glow ease-in-out 1s infinite',
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === '14_1':
      childZIndex = {
        position: 'relative',
        zIndex: 4001,
        background: 'white',
        width: '100%',
      }
      glowEffectBorder = {
        boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
        animation: 'glow ease-in-out 1s infinite',
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === '14_2':
      childZIndex = {
        position: 'relative',
        zIndex: 4001,
        background: 'white',
        paddingTop: isDesktop ? '2rem' : '0rem',
      }
      glowEffectBorder = {
        boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
        animation: 'glow ease-in-out 1s infinite',
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === '14_3':
      childZIndex = {
        position: 'relative',
        zIndex: 4001,
        background: 'white',
      }
      glowEffectBorder = {
        boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
        animation: 'glow ease-in-out 1s infinite',
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === '14_4':
      childZIndex = {
        position: 'relative',
        zIndex: 4001,
        background: 'white',
      }
      glowEffectBorder = {
        boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
        animation: 'glow ease-in-out 1s infinite',
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === '14_5':
      childZIndex = {
        position: 'relative',
        zIndex: 4001,
        background: 'white',
        padding: '3rem',
      }
      glowEffectBorder = {
        boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
        animation: 'glow ease-in-out 1s infinite',
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 15:
      childZIndex = {
        position: 'relative',
        zIndex: 4001,
        background: 'white',
        paddingTop: '2.4rem',
      }
      glowEffectBorder = {
        boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
        animation: 'glow ease-in-out 1s infinite',
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === '15_5':
      childZIndex = {
        position: 'relative',
        zIndex: 4001,
        background: 'white',
      }
      glowEffectBorder = {
        border: `3.5px solid ${theme.palette.draph.green}`,
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 16:
      childZIndex = {
        position: 'relative',
        zIndex: 4001,
        background: 'white',
        borderRadius: '30px',
      }
      glowEffectBorder = {
        boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
        animation: 'glow ease-in-out 1s infinite',
      }

      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 17:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          borderRadius: '20px',
        }
        glowEffectBorder = {
          border: '2px solid white',
          borderRadius: '60px',
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          paddingLeft: '0.7rem',
          paddingRight: '1rem',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          background: `rgba(255, 255, 255, 0.15)`,
          animation: 'wave ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          zIndex: 4001,
          borderRadius: '10px',
        }
        glowEffectBorder = {
          border: '2px solid white',

          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          paddingLeft: '0.7rem',
          paddingRight: '0.7rem',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          background: `rgba(255, 255, 255, 0.15)`,
          animation: 'wave ease-in-out 1s infinite',
        }
      }
      return { childZIndex, glowEffectBorder }

    case tutorial.mode && tutorial.step === step && step === 18:
      if (isDesktop) {
        childZIndex = {
          position: 'relative',
          background: 'white',
          display: 'flex',
          zIndex: '4001',
          flexDirection: 'column',
          alignItems: 'center',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          width: 'max-content',
          borderRadius: '20px',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      } else {
        childZIndex = {
          position: 'relative',
          background: 'white',
          display: 'flex',
          zIndex: '4001',
          flexDirection: 'column',
          alignItems: 'center',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          width: '100vw',
        }
        glowEffectBorder = {
          boxShadow: ' 0px 0px 20px rgba(255, 255, 255, 0.3)',
          animation: 'glow ease-in-out 1s infinite',
        }
      }
      return { childZIndex, glowEffectBorder }

    default:
      return { childZIndex, glowEffectBorder }
  }
}

export function Tutorial({
  children,
  style,
  step,
  setOpen,
  guideOff = false,
  filterOff = false,
  styleOff = false,
  arrowOff = false,
}) {
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const { childZIndex, glowEffectBorder } = tutorialStylePack(step)
  const isDesktop = useDesktopMediaQuery()

  const onClick = () => {
    if (isDesktop && tutorial.step === 1) {
      tutorial.mode && setTutorial(prev => ({ ...prev, step: 2 }))
    }
  }

  useEffect(() => {}, [])

  useEffect(() => {
    if (step !== tutorial.step) return

    if (tutorial.step === 7) {
      scrollTo({ top: 700, behavior: 'smooth' })
    }
    if (tutorial.step === 8) {
      scrollTo({ top: 900, behavior: 'smooth' })
    }
    if (isDesktop) {
      if (
        tutorial.mode &&
        !(tutorial.step === 5 || tutorial.step === 3 || tutorial.step === 6) &&
        (tutorial.step < 10 || tutorial.step === '3_1' || tutorial.step === '3_2')
      ) {
        const anchor = document.querySelector(`#anchor_${step}`)
        anchor.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      } else {
        if (tutorial.step === 6) {
          scrollTo({ top: 600, behavior: 'smooth' })
        }
        if (tutorial.step === 14) {
          scrollTo({ top: 0, behavior: 'smooth' })
        }
      }
    } else {
      if (
        tutorial.mode &&
        ((tutorial.step > 4 && tutorial.step < 10) || ['3_1'].includes(tutorial.step))
      ) {
        const anchor = document.querySelector(`#anchor_${step}`)
        anchor.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }
    }
  }, [tutorial.step])

  return (
    <>
      {step === 16 || step === 15 ? (
        <>
          {tutorial.mode && step === tutorial.step && (
            <TutorialStep
              step={tutorial.step}
              guideOff={guideOff}
              filterOff={filterOff}
              arrowOff={arrowOff}
              setOpen={setOpen}
            />
          )}
          <div
            id={`anchor_${step}`}
            onClick={onClick}
            style={styleOff ? null : { ...childZIndex, ...glowEffectBorder, ...style }}
          >
            {children}
          </div>
        </>
      ) : step !== tutorial.step ? (
        children
      ) : (
        <>
          <div
            id={`anchor_${step}`}
            onClick={onClick}
            style={styleOff ? null : { ...childZIndex, ...glowEffectBorder, ...style }}
          >
            {children}
          </div>
          {tutorial.mode && step === tutorial.step && (
            <TutorialStep
              step={tutorial.step}
              guideOff={guideOff}
              filterOff={filterOff}
              arrowOff={arrowOff}
              setOpen={setOpen}
            />
          )}
        </>
      )}
    </>
  )
}

const TutorialStep = ({ step, guideOff, filterOff, arrowOff, setOpen }) => {
  const theme = useTheme()
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const navigate = useNavigate()
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  switch (step) {
    case 0:
      return (
        <>
          <Desktop>
            <div>
              <FilterFrame />
              <TutorialTextbox style={{ top: '17rem', left: '50%' }}>
                <Typography variant="body" sx={textStyle}>
                  상단 메뉴에 있는{' '}
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>포트폴리오</span>
                  를 클릭하세요.
                </Typography>
              </TutorialTextbox>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative' }}>
              <div
                style={
                  isSafari
                    ? { ...filterStyle, top: 0 }
                    : { ...filterStyle, top: 'calc(-100vh + 5.2rem' }
                }
              />

              {/* <FilterFrame style={{ top: 'calc(-100vh + 5.2rem' }} /> */}
              <TutorialMobileTextbox style={{ top: '-62px', left: '-67px' }}>
                <Typography variant="body" sx={mobileTextStyle}>
                  <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                    포트폴리오
                  </span>
                  를 눌러주세요.
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case 1:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative', top: '61px', left: '-12%' }}>
              {/* <div style={{ ...filterStyle }} /> */}
              <FilterFrame />

              <TutorialTextbox>
                <Typography variant="body" sx={textStyle}>
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    새 포트폴리오 만들기
                  </span>
                  를 클릭하세요.
                </Typography>
              </TutorialTextbox>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextbox style={{ top: '43px' }}>
                <Typography variant="body" sx={mobileTextStyle}>
                  <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                    새 포트폴리오 만들기
                  </span>
                  를 눌러주세요.
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case 1.5:
      return <></>

    case 2:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox style={{ top: '61px' }}>
                <Typography variant="body" sx={textStyle}>
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    포트폴리오 제목
                  </span>
                  을 입력해주세요.
                </Typography>
              </TutorialTextbox>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextbox style={{ top: '43px', left: '50%' }}>
                <Typography variant="body" sx={mobileTextStyle}>
                  <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                    포트폴리오 제목
                  </span>
                  을 입력해주세요.
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case 3:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox style={{ top: '53px' }}>
                <Typography variant="body" sx={textStyle}>
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    상품 카테고리 - 대분류
                  </span>
                  를 선택해주세요.
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '1.7rem',
                  left: '23.6rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: '3_1' }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextboxArrowPlus
                style={{ top: '43px', left: '50%' }}
                arrowStyle={{ width: '3.5rem' }}
                nextTutorial={() => {
                  setTutorial(prev => ({ ...prev, step: '3_1' }))
                }}
              >
                <Typography variant="body" sx={mobileTextStyle}>
                  <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                    상품 카테고리 - 대분류
                  </span>
                  를 선택해주세요.
                </Typography>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case '3_1':
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '-43.1rem',
                  width: '60rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  샘플 이미지를 참고하여
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    상품 카테고리 - 소분류
                  </span>
                  를 선택해주세요.
                </Typography>
                <Typography variant="body" sx={textStyle}>
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    다양한 소분류 이미지
                  </span>
                  를 올릴 예정이시라면
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}> 자동</span>을
                  선택해주세요.
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '-46.8rem',
                  left: '31.6rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: '3_2' }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>

          <Mobile>
            <div id={`step_${tutorial.step}`} style={{ position: 'relative' }}>
              {/* <FilterFrame style={{ filterStyle1 }} /> */}
              <div style={{ ...filterStyle1, left: '-90vw', width: '200vw' }} />
              <TutorialMobileTextbox
                style={{
                  width: '32rem',
                  height: '9.2rem',
                  top: '3rem',
                  left: '50%',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={mobileTextStyle}>
                  샘플 이미지를 참고하여
                  <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    상품 카테고리 - 소분류
                  </span>
                  를
                </Typography>
                <Typography variant="body" sx={mobileTextStyle}>
                  선택해주세요.
                  <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                    다양한 소분류 이미지
                  </span>
                  를 올릴
                </Typography>
                <Typography variant="body" sx={mobileTextStyle}>
                  예정이시라면
                  <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}> 자동</span>
                  을 선택해주세요.
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case '3_2':
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '-7.3rem',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  다음 설정으로 이동해주세요.
                </Typography>
              </TutorialTextbox>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative' }}>
              {/* <FilterFrame style={{ ...filterStyle1 }} /> */}
              <div style={{ ...filterStyle1, left: '-90vw', width: '200vw', height: '1735px' }} />
              <TutorialMobileTextbox style={{ top: '-5.3rem', left: '-11rem' }}>
                <Typography variant="body" sx={mobileTextStyle}>
                  다음 설정으로 이동해주세요.
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case 4:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '82px',
                  left: '50%',
                  width: '48.4rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={textStyle}>
                  원하시는 컨셉이 있는 경우 키워드를 입력해 주세요.
                </Typography>{' '}
                <Typography variant="body" sx={textStyle}>
                  없으시다면
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    입력하지 않으셔도 괜찮습니다.
                  </span>
                </Typography>
              </TutorialTextbox>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextbox
                style={{
                  top: '61px',
                  left: '50%',
                  width: '32rem',
                  height: '8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={mobileTextStyle}>
                  원하시는 컨셉이 있는 경우 키워드를 입력해 주세요.
                </Typography>
                <Typography variant="body" sx={mobileTextStyle}>
                  없으시다면
                  <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    입력하지 않으셔도 괜찮습니다.
                  </span>
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case 5:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />

              <TutorialTextbox
                style={{
                  top: '6.9rem',
                  left: '50%',
                  width: '48.4rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={textStyle}>
                  원하시는 이미지 사이즈가 있다면 입력해 주세요.
                </Typography>{' '}
                <Typography variant="body" sx={textStyle}>
                  없으시다면
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    입력하지 않으셔도 괜찮습니다.
                  </span>
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '3.2rem',
                  left: '26rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative' }}>
              <div style={{ ...filterStyle1, top: '-547px' }} />
              <TutorialMobileTextboxArrowPlus
                style={{
                  top: '61px',
                  left: '11%',
                  width: '32rem',
                  height: '8rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={mobileTextStyle}>
                    원하시는 사이즈가 있다면 입력해주세요.
                  </Typography>
                  <Typography variant="body" sx={mobileTextStyle}>
                    없으시다면
                    <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                      {' '}
                      입력하지 않으셔도 괜찮습니다.
                    </span>
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case 6:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  position: 'absolute',
                  top: '6.6rem',
                  left: '50%',
                  width: '48.4rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                {' '}
                <Typography variant="body" sx={textStyle}>
                  예시를 참고하여
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    원하시는 이미지 종류를 선택해주세요.
                  </span>
                </Typography>
                <Typography sx={textStyle}>
                  다양한 이미지를 원하실 경우 전부 선택해주시면 됩니다.
                </Typography>{' '}
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '3rem',
                  left: '26rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative' }}>
              <div style={{ ...filterStyle1 }} />
              <TutorialMobileTextboxArrowPlus
                style={{
                  top: '5.4rem',
                  left: '-4px',
                  width: '32rem',
                  height: '7rem',
                  padding: '0px 3px 0px 16px',
                }}
                arrowStyle={{ width: '3.5rem' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={{ ...mobileTextStyle, fontSize: '1.2rem' }}>
                    예시를 참고하여
                    <span
                      style={{
                        ...mobileTextStyle,
                        color: theme.palette.draph.blue,
                        fontSize: '1.2rem',
                      }}
                    >
                      {' '}
                      원하시는 이미지 종류를 선택해주세요.
                    </span>
                  </Typography>
                  <Typography variant="body" sx={{ ...mobileTextStyle, fontSize: '1.2rem' }}>
                    다양한 이미지를 원하실 경우 전부 선택해주시면 됩니다.
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case 7:
      return (
        <>
          <Desktop>
            <div style={{ height: '7.7rem', position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '6.9rem',
                  width: '48.4rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                {' '}
                <Typography sx={textStyle}>
                  위에서 저장한 설정을 계속 사용하고 싶으신 경우
                </Typography>{' '}
                <Typography variant="body" sx={textStyle}>
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    &apos;위 설정 저장&apos; 체크박스를 눌러주세요.
                  </span>
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '3.1rem',
                  left: '26rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div
                style={{
                  ...filterStyle1,
                  top: '-1634px',
                  height: '1741px',
                }}
              />
              <TutorialMobileTextboxArrowPlus
                style={{
                  top: '-120px',
                  left: '6px',
                  width: '33rem',
                  height: '7rem',
                }}
                arrowStyle={{ width: '3.5rem' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={mobileTextStyle}>
                    위에서 저장한 설정을 계속 사용하고 싶으신 경우
                  </Typography>
                  <Typography variant="body" sx={mobileTextStyle}>
                    <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                      &apos;위 설정 저장&apos;을 눌러주세요.
                    </span>
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case 8:
      return (
        <>
          <Desktop>
            <div>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  position: 'relative',
                  top: '-5.5rem',
                  left: '50%',
                }}
              >
                {' '}
                <Typography sx={textStyle}>다음 설정으로 이동해주세요.</Typography>{' '}
              </TutorialTextbox>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextbox
                style={{
                  top: '-44px',
                  left: '-6px',
                }}
              >
                <Typography variant="body" sx={mobileTextStyle}>
                  다음 설정으로 이동해주세요.
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case 9:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative', height: '7rem' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '14rem',
                  left: '-10rem',
                  width: '48.4rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                {' '}
                <Typography sx={textStyle}>그림자 생성 버튼입니다.</Typography>{' '}
                <Typography variant="body" sx={textStyle}>
                  특별한 경우가 아닌 이상
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    그림자 생성을 켜주세요.
                  </span>
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '10.5rem',
                  left: '16rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextboxArrowPlus
                style={{
                  top: '8rem',
                  left: '1rem',
                  width: '33rem',
                  height: '7rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    그림자 생성 설정으로 특별한 경우를 제외하고
                  </Typography>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                      {' '}
                      그림자 생성을 켜주세요.
                    </span>
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case 10:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative', height: '7rem' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '13.9rem',
                  left: '-10.3rem',
                  width: '48.4rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  모델 얼굴을 변경하고 싶으신 경우
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}> 켜주세요.</span>
                </Typography>
                <Typography variant="body" sx={textStyle}>
                  모델 얼굴 변경을 원하지 않으실 경우엔
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}> 꺼주세요.</span>
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '10.3rem',
                  left: '16.2rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextboxArrowPlus
                style={{
                  top: '8rem',
                  left: '-16.5rem',
                  width: '33rem',
                  height: '7rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    모델 얼굴을 변경하고 싶으신 경우
                    <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                      {' '}
                      켜주세요.
                    </span>
                  </Typography>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    모델 얼굴 변경을 원하지 않으시면
                    <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                      {' '}
                      꺼주세요.
                    </span>
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case 11:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative', height: '7rem' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '-7rem',
                  left: '-12.1rem',
                  width: '48.4rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={textStyle}>
                  상품 이미지 경계를 다양한 설정으로 다듬을 수 있습니다.
                </Typography>{' '}
                <Typography variant="body" sx={textStyle}>
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    자동으로 두셔도 괜찮습니다.
                  </span>
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '-10.8rem',
                  left: '14.3rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextboxArrowPlus
                style={{
                  top: '8.5rem',
                  width: '33rem',
                  height: '7rem',
                }}
                arrowStyle={{ width: '3.5rem' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={{ ...mobileTextStyle, fontSize: '1.2rem' }}>
                    상품 이미지 경계를 다양한 설정으로 다듬을 수 있습니다.
                  </Typography>
                  <Typography variant="body" sx={{ ...mobileTextStyle, fontSize: '1.2rem' }}>
                    <span
                      style={{
                        ...mobileTextStyle,
                        color: theme.palette.draph.blue,
                        fontSize: '1.2rem',
                      }}
                    >
                      {' '}
                      자동으로 두셔도 괜찮습니다.
                    </span>
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case 12:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative', height: '7rem' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '-7rem',
                  left: '-11.7rem',
                  width: '48.4rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={textStyle}>특정 각도의 사진만 올리실 때 수정해주세요.</Typography>{' '}
                <Typography variant="body" sx={textStyle}>
                  하지만 수정하지 않고
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    자동으로 두셔도 괜찮습니다.
                  </span>
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '-10.7rem',
                  left: '14.7rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextboxArrowPlus
                style={{
                  top: '8.5rem',
                  left: '-16.5rem',
                  width: '33rem',
                  height: '7rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={{ ...mobileTextStyle, fontSize: '1.2rem' }}>
                    특정 각도의 사진만을 올리실 때 수정해주세요.
                  </Typography>
                  <Typography variant="body" sx={{ ...mobileTextStyle, fontSize: '1.2rem' }}>
                    하지만 수정하지 않고
                    <span
                      style={{
                        ...mobileTextStyle,
                        color: theme.palette.draph.blue,
                        fontSize: '1.2rem',
                      }}
                    >
                      {' '}
                      자동으로 두셔도 괜찮습니다.
                    </span>
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case 13:
      return (
        <>
          <Desktop>
            <div>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  position: 'relative',
                  top: '-5.5rem',
                  left: '50%',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  포트폴리오를
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    생성해 주세요.
                  </span>
                </Typography>
              </TutorialTextbox>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextbox
                style={{
                  top: '-5rem',
                  left: '-10.6rem',
                }}
              >
                <Typography variant="body" sx={mobileTextStyle}>
                  포트폴리오를 생성해 주세요.
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case 14:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '10rem',
                  left: '-14rem',
                  width: '48.8rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    새 이미지 만들기
                  </span>
                  를 클릭하신 다음
                </Typography>
                <Typography sx={textStyle}>원하시는 상품 이미지들을 업로드해주세요.</Typography>{' '}
              </TutorialTextbox>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextbox
                style={{
                  width: '32rem',
                  height: '8rem',
                  top: '7rem',
                  left: '-9rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={{ ...mobileTextStyle }}>
                  <span
                    style={{
                      ...mobileTextStyle,
                      color: theme.palette.draph.blue,
                    }}
                  >
                    새 이미지 만들기
                  </span>
                  를 클릭하신 다음
                </Typography>
                <Typography variant="body" sx={{ ...mobileTextStyle }}>
                  원하시는 상품 이미지들을 업로드 해주세요.
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case '14_1':
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '-34.9rem',
                  left: '50%',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  포트폴리오 설정과
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    동일한 카테고리 이미지
                  </span>
                  인지,
                </Typography>
                <Typography variant="body" sx={textStyle}>
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    아래에 해당되지 않는 이미지
                  </span>
                  인지 확인해주세요.
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  ...skipArrow,
                  top: '-38.5rem',
                  left: '65rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_2' }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextboxArrowPlus
                style={{
                  width: '33rem',
                  height: '8rem',
                  top: '-52rem',
                  left: '0',
                }}
                arrowStyle={{ width: '4rem' }}
                nextTutorial={() => {
                  setTutorial(prev => ({ ...prev, step: '14_2' }))
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    포트폴리오 설정과
                    <span
                      style={{
                        ...mobileTextStyle,
                        color: theme.palette.draph.blue,
                      }}
                    >
                      {' '}
                      동일한 카테고리 이미지
                    </span>
                    인지,
                  </Typography>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    <span
                      style={{
                        ...mobileTextStyle,
                        color: theme.palette.draph.blue,
                      }}
                    >
                      아래에 해당되지 않는 이미지
                    </span>
                    인지 확인해주세요.
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case '14_2':
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '6.1rem',
                  height: '9.8rem',
                  left: '50%',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    이미지는 최대 20장
                  </span>
                  까지 업로드 가능합니다.
                </Typography>
                <Typography variant="body" sx={textStyle}>
                  튜토리얼에서는
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}> 1장</span>만
                  가능합니다.
                </Typography>
              </TutorialTextbox>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle, width: '100%' }} />
              <TutorialMobileTextbox
                style={{
                  top: '5rem',
                  left: '0',
                  height: '7rem',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    <span
                      style={{
                        ...mobileTextStyle,
                        color: theme.palette.draph.blue,
                      }}
                    >
                      이미지는 최대 20장
                    </span>
                    까지 업로드 가능합니다.
                  </Typography>
                  <Typography variant="body" sx={mobileTextStyle}>
                    튜토리얼에서는
                    <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
                      {' '}
                      1장
                    </span>
                    만 가능합니다.
                  </Typography>
                </div>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case '14_3':
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '-9.2rem',
                  left: '-10rem',
                  flexDirection: 'column',
                  height: '10rem',
                  width: '50rem',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  업로드 버튼을 눌러주세요.
                </Typography>
                <Typography variant="body" sx={textStyle}>
                  차감되는 크레딧은 튜토리얼{' '}
                  <span style={{ color: theme.palette.draph.blue }}>완료 후</span> 다시 충전됩니다.
                </Typography>
                <Typography variant="body" sx={textStyle}></Typography>
              </TutorialTextbox>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextbox
                style={{
                  top: '-8rem',
                  left: '-10rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={{ ...mobileTextStyle }}>
                  업로드 버튼을 눌러주세요.
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case '14_4':
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
            </div>
          </Mobile>
        </>
      )

    case '14_5':
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative' }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '-7rem',
                  left: '-13rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  생성이 완료될 때까지 기다려 주세요
                </Typography>
              </TutorialTextbox>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextbox
                style={{
                  top: '-26rem',
                  left: '11rem',
                }}
              >
                <Typography variant="body" sx={{ ...mobileTextStyle }}>
                  생성이 완료될 때까지 기다려 주세요
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case 15:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative', left: '50vw', width: 0 }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '-8rem',
                  left: '0',
                  width: '44rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  다운로드 받고 싶은
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
                    {' '}
                    이미지들을 선택하신 다음
                  </span>
                </Typography>
                <Typography sx={textStyle}>
                  오른쪽에 있는 버튼으로
                  <span style={{ ...textStyle, color: theme.palette.draph.blue }}> 다운로드 </span>
                  받을 수 있습니다.
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  width: '100vw',
                  height: '100vh',
                  backgroundColor: 'transparent',
                  zIndex: 4002,
                  top: '0',
                  left: '-50vw',
                  position: 'absolute',
                }}
              ></Box>
              <Box
                sx={{
                  ...skipArrow,
                  top: '-11.8rem',
                  left: '24rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextbox
                style={{
                  top: '-5rem',
                  left: '18rem',
                }}
              >
                <Typography variant="body" sx={{ ...mobileTextStyle }}>
                  생성된 이미지
                  <span
                    style={{
                      ...mobileTextStyle,
                      color: theme.palette.draph.blue,
                    }}
                  >
                    {' '}
                    썸네일을 클릭하세요.
                  </span>
                </Typography>
              </TutorialMobileTextbox>
            </div>
          </Mobile>
        </>
      )

    case '15_5':
      return (
        <>
          <Mobile>
            <div style={{ position: 'relative', height: '0rem' }}>
              <div style={{ ...noFilterStyle }} />
              <TutorialMobileTextboxArrowPlus
                style={{
                  width: '33rem',
                  height: '8rem',
                  top: '-8rem',
                  left: '0rem',
                }}
                nextTutorial={() => {
                  setOpen(false)
                  setTutorial(prev => ({ ...prev, step: 16 }))
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    모바일에서는
                    <span
                      style={{
                        ...mobileTextStyle,
                        color: theme.palette.draph.blue,
                      }}
                    >
                      {' '}
                      표시된 다운로드 버튼을 이용해
                    </span>
                  </Typography>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    1장씩 개별 다운로드 가능합니다.
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
            </div>
          </Mobile>
        </>
      )

    case 16:
      return (
        <>
          <Desktop>
            <div style={{ position: 'relative', left: '50vw', width: 0 }}>
              <FilterFrame />
              <TutorialTextbox
                style={{
                  top: '-8rem',
                  left: '0',
                  width: '44rem',
                  height: '9.8rem',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="body" sx={textStyle}>
                  생성 결과물이 마음에 들지 않으실 때는
                </Typography>
                <Typography variant="body" sx={textStyle}>
                  재생성 기능을 사용하실 수 있습니다.
                </Typography>
              </TutorialTextbox>
              <Box
                sx={{
                  width: '100vw',
                  height: '100vh',
                  backgroundColor: 'transparent',
                  zIndex: 4002,
                  top: '0',
                  left: '-50vw',
                  position: 'absolute',
                }}
              ></Box>
              <Box
                sx={{
                  ...skipArrow,
                  top: '-11.8rem',
                  left: '24rem',
                }}
                onClick={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: 17 }))
                  navigate('/portfolio')
                }}
              >
                <TutorialSkipArrow />
              </Box>
            </div>
          </Desktop>
          <Mobile>
            <div style={{ position: 'relative', left: '50vw' }}>
              <div style={{ ...filterStyle }} />
              <TutorialMobileTextboxArrowPlus
                nextTutorial={() => {
                  tutorial.mode && setTutorial(prev => ({ ...prev, step: 17 }))
                  navigate('/portfolio')
                }}
                style={{ width: '31rem', height: '7rem', top: '-6rem', left: '-1rem' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    생성 결과물이 마음에 들지 않으실 때는
                  </Typography>
                  <Typography variant="body" sx={{ ...mobileTextStyle }}>
                    재생성 기능을 사용하실 수 있습니다.
                  </Typography>
                </div>
              </TutorialMobileTextboxArrowPlus>
              <Box
                sx={{
                  width: '100vw',
                  height: '100vh',
                  backgroundColor: 'transparent',
                  zIndex: 4002,
                  top: '0',
                  right: '50vw',
                  position: 'absolute',
                }}
              ></Box>
            </div>
          </Mobile>
        </>
      )

    // case 17:
    //   return (
    //     <>
    //       <Desktop>
    //         <div style={{ position: 'relative' }}>
    //                      <FilterFrame />
    //           <TutorialTextbox
    //             style={{
    //               top: '15.2rem',
    //               left: '-21rem',
    //               width: '44rem',
    //               height: '9.8rem',
    //               flexDirection: 'column',
    //             }}
    //           >
    //             <Typography variant="body" sx={textStyle}>
    //               남은 크레딧 및 이용기간을 확인하시려면
    //             </Typography>

    //             <Typography variant="body" sx={textStyle}>
    //               <span style={{ ...textStyle, color: theme.palette.draph.blue }}>
    //                 우측 상단에 있는 프로필을 클릭하세요.
    //               </span>
    //             </Typography>
    //           </TutorialTextbox>
    //         </div>
    //       </Desktop>

    //       <Mobile>
    //         <div style={{ position: 'relative' }}>
    //                      <FilterFrame />
    //           <TutorialMobileTextbox
    //             style={{ width: '31rem', height: '7rem', top: '93px', left: '-153px' }}
    //           >
    //             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
    //               <Typography variant="body" sx={{ ...mobileTextStyle }}>
    //                 남은 크레딧 및 이용기간을 확인하시려면
    //               </Typography>
    //               <Typography variant="body" sx={{ ...mobileTextStyle }}>
    //                 <span style={{ ...mobileTextStyle, color: theme.palette.draph.blue }}>
    //                   우측 상단에 있는 프로필을 클릭하세요.
    //                 </span>
    //               </Typography>
    //             </div>
    //           </TutorialMobileTextbox>
    //         </div>
    //       </Mobile>
    //     </>
    //   )

    // case 18:
    //   return (
    //     <>
    //       <Desktop>
    //         <div style={{ position: 'relative' }}>
    //                      <FilterFrame />

    //           <TutorialTextbox
    //             style={{
    //               position: 'absolute',
    //               top: '7.5rem',
    //               left: '-10rem',
    //               width: '38rem',
    //               height: '9.8rem',
    //               flexDirection: 'column',
    //             }}
    //             arrowStyle={{ width: '4rem' }}
    //           >
    //             <Typography variant="body" sx={textStyle}>
    //               이용 중인 요금제, 크레딧, 이용기간을
    //             </Typography>

    //             <Typography variant="body" sx={textStyle}>
    //               확인하실 수 있습니다.
    //             </Typography>
    //           </TutorialTextbox>

    //           <Box
    //             sx={{
    //               ...skipArrow,
    //               top: '2.5rem',
    //               left: '11rem',
    //             }}
    //             onClick={() => {
    //               resetTutorial()
    //               setOpen(false)
    //             }}
    //           >
    //             <TutorialSkipArrow />
    //           </Box>
    //         </div>
    //       </Desktop>

    //       <Mobile>
    //         <div style={{ position: 'relative' }}>
    //                      <FilterFrame />
    //           <TutorialMobileTextboxArrowPlus
    //             style={{ width: '31rem', height: '7rem', top: '60px', left: '0px' }}
    //           >
    //             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
    //               <Typography variant="body" sx={{ ...mobileTextStyle }}>
    //                 이용 중인 요금제, 크레딧, 이용기간을
    //               </Typography>
    //               <Typography variant="body" sx={{ ...mobileTextStyle }}>
    //                 확인하실 수 있습니다.
    //               </Typography>
    //             </div>
    //           </TutorialMobileTextboxArrowPlus>
    //         </div>
    //       </Mobile>
    //     </>
    // )

    default:
      break
  }
}

const TutorialTextbox = ({ children, style }) => {
  const theme = useTheme()

  const textBoxStyle = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 4001,
    backgroundColor: theme.palette.common.white,
    border: `3.5px solid ${theme.palette.draph.green}`,
    width: '44rem',
    height: '7.2rem',
    borderRadius: '10px',
    transform: 'translate(-50%, -50%)',
    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.25)',
  }

  return (
    <div
      style={{
        ...textBoxStyle,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

const TutorialMobileTextbox = ({ children, style }) => {
  const theme = useTheme()

  const textBoxStyle = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 10px',
    zIndex: 4001,
    backgroundColor: theme.palette.common.white,
    border: `3.5px solid ${theme.palette.draph.green}`,
    width: '28.6rem',
    height: '5.2rem',
    borderRadius: '10px',
    transform: 'translate(-50%, -50%)',
    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.25)',
  }

  return (
    <div
      style={{
        ...textBoxStyle,
        ...style,
      }}
    >
      {children}
    </div>
  )
}

const TutorialMobileTextboxArrowPlus = ({ children, style, arrowStyle, nextTutorial = false }) => {
  const theme = useTheme()
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const resetTutorial = useResetRecoilState(tutorialAtom)

  const textBoxStyle = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    zIndex: 4001,
    backgroundColor: theme.palette.common.white,
    border: `3.5px solid ${theme.palette.draph.green}`,
    width: 'max-content',
    padding: '0px 3px 0px 19px',
    height: '5.2rem',
    borderRadius: '10px',
    transform: 'translate(-50%, -50%)',
    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.25)',
  }

  return (
    <div
      style={{
        ...textBoxStyle,
        ...style,
      }}
    >
      {children}
      <Box
        sx={{
          ...skipArrow1,
          ...arrowStyle,
        }}
        onClick={() => {
          nextTutorial !== false
            ? nextTutorial()
            : tutorial.mode && setTutorial(prev => ({ ...prev, step: tutorial.step + 1 }))
          tutorial.step === 18 && resetTutorial()
          tutorial.step === 18 && window.location.reload()
        }}
      >
        <TutorialSkipArrow
          stroke="black"
          sx={{
            width: tutorial.step === 15 || tutorial.step === 3 ? '0.9rem' : '1.2rem',
            height: '5.2rem',
          }}
        />
      </Box>
    </div>
  )
}

export const PopupComponent = () => {
  const [open, setOpen] = useState(false)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetTutorial = useResetRecoilState(tutorialAtom)
  const theme = useTheme()

  useEffect(() => {
    apis.user.finishTutorial({ artwork_id: tutorial.artworkId }).then(res => {})

    // ----- GA4 event -----
    window.gtag('event', 'tutorial_complete', {})
    // ---------------------

    setOpen(true)
    // localStorage.setItem('tutorial_complete', true) // 버튼 클릭 시 팝업 열기
    setTimeout(() => {
      resetTutorial()
      setOpen(false) // 3초 후 팝업 닫기
    }, 3000)
  }, [])

  return (
    <Snackbar
      TransitionComponent={Grow}
      sx={{
        '&.MuiSnackbar-root': {
          top: '50%',
          right: { xs: 0 },
          left: { lg: '50%', xs: '50%' },
          width: 'min-content',
          transform: 'translate(-50%, -50%)',
        },
        '& .MuiSnackbarContent-root': {
          padding: 0,
          backgroundColor: 'transparent',
          minWidth: 'auto',
        },
        '& .MuiSnackbarContent-message': { padding: 0 },
      }}
      open={open}
      // anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      message={
        <Box
          sx={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: { lg: '48.8rem', xs: '31.2rem' },
            height: { lg: '9.8rem', xs: '8rem' },
            backgroundColor: theme.palette.common.white,
            border: `3px solid ${theme.palette.draph.green}`,
            borderRadius: '10px',
            boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: '2rem', xs: '1.4rem' },
              fontWeight: 800,
              color: theme.palette.common.black,
              textAlign: 'center',
            }}
          >
            <span style={{ color: theme.palette.draph.blue }}>튜토리얼이 모두 끝났습니다.</span>
            <br />
            드랩아트로 원하시는 상품 이미지를 만들어 보세요!
          </Typography>
        </Box>
      }
      autoHideDuration={3000}
      onClose={() => setOpen(false)}
    />
  )
}

const FilterFrame = ({ style }) => {
  const resetTutorial = useResetRecoilState(tutorialAtom)
  const resetStep = useResetRecoilState(newPortfolioAtom)
  const { showConfirm } = useConfirm()
  return (
    <div style={{ ...filterStyle, ...style }}>
      <CenterAlignBox sx={{ width: '100%' }}>
        {/* <Typography sx={{ px: '0.8rem', color: '#fff' }}>
          튜토리얼을 나가도 1회 완료하기 전까지 계속 나옵니다.
        </Typography> */}
        <Stack sx={{ width: '128rem', display: 'flex', alignItems: 'center' }}>
          <Button
            sx={{
              color: '#fff',
              height: { lg: '4.6rem', xs: '5.2rem' },
              fontSize: '1.4rem',
              '&:hover': { backgroundColor: 'transparent' },
              mr: '1.7rem',
            }}
            startIcon={<TutorialCloseIcon />}
            onClick={() => {
              showConfirm({
                content: (
                  <Typography>
                    튜토리얼을 1회 완료하지 않으실 경우, <br />
                    로그인 후 튜토리얼이 다시 진행됩니다.
                  </Typography>
                ),
                onConfirm: () => {
                  resetTutorial()
                  resetStep()
                },
                onCancel: () => {},
              })
            }}
          >
            튜토리얼 나가기
          </Button>
        </Stack>
      </CenterAlignBox>
    </div>
  )
}

export const TutorialExitButton = ({ style }) => {
  const resetTutorial = useResetRecoilState(tutorialAtom)
  const resetStep = useResetRecoilState(newPortfolioAtom)

  const { showConfirm } = useConfirm()
  return (
    <Button
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 4001,
        color: '#fff',
        height: { lg: '4.6rem', xs: '5.2rem' },
        fontSize: '1.4rem',
        '&:hover': { backgroundColor: 'transparent' },
        ml: { xs: '2rem' },
        ...style,
      }}
      startIcon={<TutorialCloseIcon />}
      onClick={() => {
        showConfirm({
          content: (
            <Typography>
              튜토리얼을 1회 완료하지 않으실 경우, <br />
              로그인 후 튜토리얼이 다시 진행됩니다.
            </Typography>
          ),
          onConfirm: () => {
            resetTutorial()
            resetStep()
          },
          onCancel: () => {},
        })
      }}
    >
      튜토리얼 나가기
    </Button>
  )
}
