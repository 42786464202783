import { apis } from 'apis'
import moment from 'moment'
import { getCurrencyFormatter } from 'utils/common'

export const DEFAULT_CURRENCY = 'krw'

export const getDiscountRate = (plans, planId = 'paygo1', currency = 'krw') => {
  const priceOfOne = plans.payGo.filter(p => p.id === 'paygo1')[0].price[currency]

  const allPlans = [...plans.subscription, ...plans.payGo]

  const plan = allPlans.filter(p => p.id === planId)[0]
  const planPricePerOne = plan.price[currency] / plan.creditAmount

  const d = ((priceOfOne - planPricePerOne) / priceOfOne) * 100
  return Math.round(d)
}

export const getOriginalPrice = (plans, planId = 'paygo1', currency = 'krw') => {
  const priceOfOne = plans.payGo.filter(p => p.id === 'paygo1')[0].price[currency]
  const allPlans = [...plans.subscription, ...plans.payGo]
  const plan = allPlans.filter(p => p.id === planId)[0]

  return priceOfOne * plan.creditAmount
}

// export const pricingPlanDict = {
//   subscription: 'subscription',
//   payGo: 'payGo',
//   paygo: 'paygo',
//   monthly: 'monthly',
// }

// export const getPlanDisplayName = (planId, lang = 'ko') => {
//   const pricingPlanDict = {
//     ko: { subscription: '월정액 구독', payGo: '건별 충전', paygo: '건별 충전', monthly: '월정액' },
//     en: {
//       subscription: 'monthly ',
//       payGo: 'Per usage ',
//       paygo: 'Per usage ',
//       monthly: 'monthly ',
//     },
//   }

//   if (!planId) return
//   let name = planId
//   if (planId.includes('monthly')) {
//     name = name.replace('monthly', `${pricingPlanDict[lang].monthly} `)
//   } else if (planId.includes('paygo')) {
//     name = name.replace('paygo', `${pricingPlanDict[lang].paygo} `)
//   }
//   return name
// }

// export const paymentMethodDict = {
//   card: 'card',
// }

// 플랜에서 가격만
export const getPlanPrice = (plans, planId) => {
  if (!planId) return
  // 간이 예외처리 ㅠㅠ
  if (planId.includes('admin')) {
    const splitPlanId = planId.split('-')
    planId = splitPlanId[0]
  }

  const allPlans = [...plans.subscription, ...plans.payGo]
  const plan = allPlans.find(p => p.id === planId)
  if (!plan) return

  const currency = Object.keys(plan.price)[0]
  const currencyFormatter = getCurrencyFormatter(currency)
  const v = currencyFormatter.format(plan.price[currency]).slice(1)
  // console.log('dd', currencyFormatter.format(plan.price[currency]))
  return v
}

export const getNextPaidDay = lastPaid => {
  if (!lastPaid) return ''
  const utcDate = moment.utc(lastPaid).toDate()
  const localNextPaidDay = moment(utcDate).add(1, 'M').local().format('YYYY-MM-DD')

  return localNextPaidDay
}

export const getCreditAmount = (plans, planId) => {
  if (!planId) return

  // 간이 예외처리 ㅠㅠ
  if (planId.includes('admin')) {
    const splitPlanId = planId.split('-')
    planId = splitPlanId[0]
  }
  const allPlans = [...plans.subscription, ...plans.payGo]
  const plan = allPlans.find(p => p.id === planId)
  if (!plan) return

  return plan.creditAmount
}
