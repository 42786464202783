import axios from 'axios'
import { configData } from 'config'
import * as cookie from 'utils/cookie'
import moment from 'moment'

// let isTokenRefreshing = false

const commerceAxios = axios.create({
  baseURL: configData.COMMERCE_URL,
  headers: { 'content-type': 'application/json' },
})

commerceAxios.interceptors.request.use(config => {
  const accessToken = cookie.getAccessTokenNaver()
  // console.log(accessToken)
  config.headers.Authorization = `Bearer ${accessToken}`
  return config
})

commerceAxios.interceptors.response.use(
  response => response
  // async error => {
  //   const { response } = error

  //   console.log(error)

  //   if (response && response.status && response.status === 401) {
  //     if (!isTokenRefreshing) {
  //       const accessToken = cookie.getAccessToken()
  //       const refreshToken = cookie.getRefreshToken()

  //       if (accessToken && refreshToken) {
  //         isTokenRefreshing = true

  //         try {
  //           const response = await axios.post(
  //             `${configData.DB_URL}/user/refresh_token`,
  //             {
  //               access_token: accessToken,
  //               refresh_token: refreshToken,
  //             },
  //             { headers: { 'Content-type': 'application/json' } }
  //           )

  //           cookie.setAccessToken(response.data.access_token)
  //           cookie.setRefreshToken(response.data.refresh_token)
  //           isTokenRefreshing = false
  //           window.location.reload()
  //         } catch (error) {
  //           cookie.removeUserCookie()
  //           window.location.href = '/login'
  //         } finally {
  //           isTokenRefreshing = false
  //         }
  //       } else {
  //         cookie.removeUserCookie()
  //       }
  //     }
  //   }
  //   return Promise.reject(error)
  // }
)
export default commerceAxios
