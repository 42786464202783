import 'assets/css/blog.style.css'
import 'assets/css/blog.content.style.css'

import { Box } from '@mui/material'
import { CenterAlignStack } from 'components/CenterAlignContainer'

export function BlogSample() {
  return (
    <>
      <CenterAlignStack sx={{ m: '5rem', p: '5rem', border: '1px dashed gray' }}>
        <Box
          sx={{
            width: '76rem',
            // border: '1px dashed pink'
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: rawHtml }} />
        </Box>
      </CenterAlignStack>
    </>
  )
}

const rawHtml = `<div class="tt_article_useless_p_margin contents_style">
<div class="book-toc">
  <p data-ke-size="size16">목차</p>
  <ul id="toc" style="list-style-type: disc" data-ke-list-type="disc">
    <li>
      <a href="#고객을_사로_잡을_수_있는_시간은_매우_짧다"
        >고객을 사로 잡을 수 있는 시간은 매우 짧다</a
      >
    </li>
    <li>
      <a href="#매력적인_쇼핑몰의_필수_요소는_멋진_상품_사진이다"
        >매력적인&nbsp;쇼핑몰의&nbsp;필수&nbsp;요소는&nbsp;멋진&nbsp;상품&nbsp;사진이다</a
      >
    </li>
    <li>
      <a href="#상품_사진_유형">상품&nbsp;사진&nbsp;유형</a>
      <ul>
        <li><a href="#1._단독_샷">1. 단독 샷</a></li>
        <li><a href="#2._그룹_샷">2. 그룹 샷</a></li>
        <li><a href="#3._실제_사용_샷">3. 실제 사용 샷</a></li>
        <li><a href="#4._실제_크기_샷">4. 실제 크기 샷</a></li>
        <li><a href="#5._상세_샷">5. 상세 샷</a></li>
        <li><a href="#6._패키지_샷">6. 패키지 샷</a></li>
      </ul>
    </li>
    <li>
      <a href="#드랩아트로_상품사진_만들기">드랩아트로&nbsp;상품사진&nbsp;만들기</a>
      <ul>
        <li><a href="#1._깔끔한_단색_배경의_사진">1. 깔끔한 단색 배경의 사진</a></li>
        <li><a href="#2._다양한_컨셉_사진">2. 다양한 컨셉 사진</a></li>
      </ul>
    </li>
    <li><a href="#결론">결론</a></li>
  </ul>
</div>
<h3
  style="color: #000000; text-align: start"
  data-ke-size="size23"
  id="고객을_사로_잡을_수_있는_시간은_매우_짧다"
>
  <b>고객을 사로 잡을 수 있는 시간은 매우 짧다</b>
</h3>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  사람의&nbsp;뇌는&nbsp;단&nbsp;1초도&nbsp;안&nbsp;되는&nbsp;시간에&nbsp;이미지를&nbsp;처리할&nbsp;수&nbsp;있다고&nbsp;합니다.&nbsp;이는&nbsp;<b>쇼핑몰에&nbsp;처음&nbsp;방문한&nbsp;고객의&nbsp;관심을&nbsp;끌&nbsp;수&nbsp;있는&nbsp;시간이&nbsp;매우&nbsp;짧다는&nbsp;것</b>이기도&nbsp;하며,&nbsp;그만큼&nbsp;고객의&nbsp;시선을&nbsp;사로잡고&nbsp;계속&nbsp;흥미를&nbsp;유발할&nbsp;수&nbsp;있는&nbsp;온라인&nbsp;쇼핑몰을&nbsp;만드는&nbsp;것이&nbsp;매우&nbsp;중요하다는&nbsp;것을&nbsp;의미합니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h3
  style="color: #000000; text-align: start"
  data-ke-size="size23"
  id="매력적인_쇼핑몰의_필수_요소는_멋진_상품_사진이다"
>
  <b>매력적인&nbsp;쇼핑몰의&nbsp;필수&nbsp;요소는&nbsp;멋진&nbsp;상품&nbsp;사진이다</b>
</h3>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  그럼&nbsp;고객의&nbsp;시선을&nbsp;사로잡는&nbsp;쇼핑몰을&nbsp;만드는&nbsp;데&nbsp;가장&nbsp;좋은&nbsp;방법은&nbsp;무엇일까요?<br />바로&nbsp;<b>멋진&nbsp;상품&nbsp;사진을&nbsp;이용해&nbsp;고객의&nbsp;시선을&nbsp;묶어두는&nbsp;것</b>입니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  <br />우리는 판매하는 상품이 얼마나 좋은 지 알고 있지만, 고객은 오로지 쇼핑몰에 나와있는
  내용으로만 상품을 판단할 수밖에 없습니다. 그렇기 때문에 대다수의 쇼핑몰이 많은 비용과 시간을
  들여서라도 멋진 상품 사진을 제작하고 있죠.
  그래서&nbsp;이번&nbsp;글에서는&nbsp;<b>쇼핑몰에서&nbsp;주로&nbsp;사용하는&nbsp;상품&nbsp;사진의&nbsp;유형</b>에&nbsp;대해&nbsp;알려드리고자&nbsp;합니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h3 style="color: #000000; text-align: start" data-ke-size="size23" id="상품_사진_유형">
  <b>상품&nbsp;사진&nbsp;유형</b>
</h3>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  각&nbsp;상품의&nbsp;특성을&nbsp;이해하고&nbsp;그것을&nbsp;잘&nbsp;나타낼&nbsp;수&nbsp;있게&nbsp;상품&nbsp;사진을&nbsp;제작하는&nbsp;것이&nbsp;중요합니다.&nbsp;대다수의&nbsp;쇼핑몰에서&nbsp;많이&nbsp;이용하는&nbsp;일반적인&nbsp;상품&nbsp;사진의&nbsp;유형은&nbsp;아래와&nbsp;같습니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h4 style="color: #000000; text-align: start" data-ke-size="size20" id="1._단독_샷">
  <b>1. 단독 샷</b>
</h4>
<p></p>
<figure
  class="imageblock alignCenter"
  data-ke-mobilestyle="widthOrigin"
  data-filename="pexels-bilakis-15545362.jpg"
  data-origin-width="1920"
  data-origin-height="1280"
>
  <span
    data-url="https://blog.kakaocdn.net/dn/tW2jb/btstNCD8wlE/a12FsizYkqVrnBilkYCEhK/img.jpg"
    data-lightbox="lightbox"
    data-alt="출처: unsplash"
    ><img
      src="https://blog.kakaocdn.net/dn/tW2jb/btstNCD8wlE/a12FsizYkqVrnBilkYCEhK/img.jpg"
      srcset="
        https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&amp;fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FtW2jb%2FbtstNCD8wlE%2Fa12FsizYkqVrnBilkYCEhK%2Fimg.jpg
      "
      onerror="this.onerror=null; this.src='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png'; this.srcset='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png';"
      data-filename="pexels-bilakis-15545362.jpg"
      data-origin-width="1920"
      data-origin-height="1280"
  /></span>
  <figcaption>출처: unsplash</figcaption>
</figure>
<p></p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  <b
    >가장&nbsp;일반적인&nbsp;유형으로&nbsp;한&nbsp;장의&nbsp;사진에&nbsp;하나의&nbsp;상품만&nbsp;포함되어&nbsp;있습니다.&nbsp;</b
  >상품&nbsp;카탈로그,&nbsp;배너&nbsp;이미지,&nbsp;상품&nbsp;상세&nbsp;페이지&nbsp;등&nbsp;상품이&nbsp;노출되는&nbsp;대부분의&nbsp;영역에서&nbsp;사용되며,&nbsp;흰색&nbsp;같은&nbsp;단색&nbsp;배경으로&nbsp;제작하는&nbsp;경우가&nbsp;많습니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h4 style="color: #000000; text-align: start" data-ke-size="size20" id="2._그룹_샷">
  <b>2. 그룹 샷</b>
</h4>
<p></p>
<figure
  class="imageblock alignCenter"
  data-ke-mobilestyle="widthOrigin"
  data-filename="pexels-karolina-grabowska-4735904.jpg"
  data-origin-width="1920"
  data-origin-height="2880"
>
  <span
    data-url="https://blog.kakaocdn.net/dn/diF5Kn/btstY9fzxRB/ae6r3j58J8sTiklnvFZKfK/img.jpg"
    data-lightbox="lightbox"
    data-alt="출처: pexels"
    ><img
      src="https://blog.kakaocdn.net/dn/diF5Kn/btstY9fzxRB/ae6r3j58J8sTiklnvFZKfK/img.jpg"
      srcset="
        https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&amp;fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FdiF5Kn%2FbtstY9fzxRB%2Fae6r3j58J8sTiklnvFZKfK%2Fimg.jpg
      "
      onerror="this.onerror=null; this.src='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png'; this.srcset='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png';"
      data-filename="pexels-karolina-grabowska-4735904.jpg"
      data-origin-width="1920"
      data-origin-height="2880"
  /></span>
  <figcaption>출처: pexels</figcaption>
</figure>
<p></p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  <b
    >2개&nbsp;이상의&nbsp;상품이&nbsp;한&nbsp;장의&nbsp;사진에&nbsp;포함되어&nbsp;있는&nbsp;유형입니다</b
  >.&nbsp;여러&nbsp;개의&nbsp;상품을&nbsp;묶어서&nbsp;판매하거나&nbsp;하나의&nbsp;상품에&nbsp;다양한&nbsp;라인업이&nbsp;있는&nbsp;경우에&nbsp;사용됩니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h4 style="color: #000000; text-align: start" data-ke-size="size20" id="3._실제_사용_샷">
  <b>3. 실제 사용 샷</b>
</h4>
<p></p>
<figure
  class="imageblock alignCenter"
  data-ke-mobilestyle="widthOrigin"
  data-filename="pexels-ivan-oboleninov-2916814.jpg"
  data-origin-width="1920"
  data-origin-height="2880"
>
  <span
    data-url="https://blog.kakaocdn.net/dn/bbJSlc/btstRJW2mTC/i3eOl7rvVgRtm20GF9hsk1/img.jpg"
    data-lightbox="lightbox"
    data-alt="출처: pexels"
    ><img
      src="https://blog.kakaocdn.net/dn/bbJSlc/btstRJW2mTC/i3eOl7rvVgRtm20GF9hsk1/img.jpg"
      srcset="
        https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&amp;fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbbJSlc%2FbtstRJW2mTC%2Fi3eOl7rvVgRtm20GF9hsk1%2Fimg.jpg
      "
      onerror="this.onerror=null; this.src='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png'; this.srcset='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png';"
      data-filename="pexels-ivan-oboleninov-2916814.jpg"
      data-origin-width="1920"
      data-origin-height="2880"
  /></span>
  <figcaption>출처: pexels</figcaption>
</figure>
<p></p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  <b>상품의&nbsp;실제&nbsp;모습을&nbsp;보여줄&nbsp;수&nbsp;있는&nbsp;유형입니다.&nbsp;</b
  >의류의&nbsp;경우&nbsp;모델이&nbsp;직접&nbsp;입고&nbsp;있는&nbsp;모습을&nbsp;촬영하거나,&nbsp;일반&nbsp;상품의&nbsp;경우&nbsp;실제&nbsp;어떻게&nbsp;사용하고&nbsp;있는지&nbsp;보여줍니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h4 style="color: #000000; text-align: start" data-ke-size="size20" id="4._실제_크기_샷">
  <b>4. 실제 크기 샷</b>
</h4>
<p></p>
<figure
  class="imageblock alignCenter"
  data-ke-mobilestyle="widthOrigin"
  data-filename="pexels-andrea-davis-13327416.jpg"
  data-origin-width="3311"
  data-origin-height="2208"
>
  <span
    data-url="https://blog.kakaocdn.net/dn/cebgTC/btstY88OdNm/eznoZk5gwBchV75pLAiDB1/img.jpg"
    data-lightbox="lightbox"
    data-alt="출처: pexels"
    ><img
      src="https://blog.kakaocdn.net/dn/cebgTC/btstY88OdNm/eznoZk5gwBchV75pLAiDB1/img.jpg"
      srcset="
        https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&amp;fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FcebgTC%2FbtstY88OdNm%2FeznoZk5gwBchV75pLAiDB1%2Fimg.jpg
      "
      onerror="this.onerror=null; this.src='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png'; this.srcset='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png';"
      data-filename="pexels-andrea-davis-13327416.jpg"
      data-origin-width="3311"
      data-origin-height="2208"
  /></span>
  <figcaption>출처: pexels</figcaption>
</figure>
<p></p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  <b
    >온라인 쇼핑에서 고객이 어려움을 느끼는 부분 중 하나는 상품의 실제 크기를 명확히 알 수
    없다는 점입니다.</b
  ><span>&nbsp;</span>그러나 실제 크기샷을 이용하면 다른 일상 품목과 상품을 비교하여 고객이
  상품의 실제 크기를 더 잘 알 수 있도록 도울 수 있습니다.<br />이를&nbsp;통해&nbsp;고객은&nbsp;일상&nbsp;생활에서&nbsp;우리의&nbsp;상품을&nbsp;어떻게&nbsp;사용할&nbsp;수&nbsp;있을지&nbsp;좀&nbsp;더&nbsp;명확하게&nbsp;인지할&nbsp;수&nbsp;있고,&nbsp;이는&nbsp;구매를&nbsp;확정하는데&nbsp;도움이&nbsp;될&nbsp;수&nbsp;있습니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h4 style="color: #000000; text-align: start" data-ke-size="size20" id="5._상세_샷">
  <b>5. 상세 샷</b>
</h4>
<p></p>
<figure
  class="imageblock alignCenter"
  data-ke-mobilestyle="widthOrigin"
  data-filename="pexels-the-glorious-studio-10475789.jpg"
  data-origin-width="1920"
  data-origin-height="1920"
>
  <span
    data-url="https://blog.kakaocdn.net/dn/9806L/btstY5jWK3w/gZBum6gkmOOe6nlI4FCSek/img.jpg"
    data-lightbox="lightbox"
    data-alt="출처: pexels"
    ><img
      src="https://blog.kakaocdn.net/dn/9806L/btstY5jWK3w/gZBum6gkmOOe6nlI4FCSek/img.jpg"
      srcset="
        https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&amp;fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F9806L%2FbtstY5jWK3w%2FgZBum6gkmOOe6nlI4FCSek%2Fimg.jpg
      "
      onerror="this.onerror=null; this.src='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png'; this.srcset='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png';"
      data-filename="pexels-the-glorious-studio-10475789.jpg"
      data-origin-width="1920"
      data-origin-height="1920"
  /></span>
  <figcaption>출처: pexels</figcaption>
</figure>
<p></p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  쥬얼리,&nbsp;액세서리&nbsp;등&nbsp;사이즈가&nbsp;작으면서&nbsp;복잡한&nbsp;디테일이&nbsp;있는&nbsp;상품의&nbsp;경우,&nbsp;<b
    >상세&nbsp;샷을&nbsp;통해&nbsp;다른&nbsp;사진에서는&nbsp;육안으로&nbsp;파악하기&nbsp;힘든&nbsp;해당&nbsp;상품만의&nbsp;특징을&nbsp;강조하는데&nbsp;도움이&nbsp;됩니다.</b
  >
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h4 style="color: #000000; text-align: start" data-ke-size="size20" id="6._패키지_샷">
  <b>6. 패키지 샷</b>
</h4>
<p></p>
<figure
  class="imageblock alignCenter"
  data-ke-mobilestyle="widthOrigin"
  data-filename="pexels-ray-piedra-2720447.jpg"
  data-origin-width="1920"
  data-origin-height="1280"
>
  <span
    data-url="https://blog.kakaocdn.net/dn/brby9w/btstVhlaBtk/bKVfI1UalU3kv11B2WMPyk/img.jpg"
    data-lightbox="lightbox"
    data-alt="출처: pexels"
    ><img
      src="https://blog.kakaocdn.net/dn/brby9w/btstVhlaBtk/bKVfI1UalU3kv11B2WMPyk/img.jpg"
      srcset="
        https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&amp;fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fbrby9w%2FbtstVhlaBtk%2FbKVfI1UalU3kv11B2WMPyk%2Fimg.jpg
      "
      onerror="this.onerror=null; this.src='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png'; this.srcset='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png';"
      data-filename="pexels-ray-piedra-2720447.jpg"
      data-origin-width="1920"
      data-origin-height="1280"
  /></span>
  <figcaption>출처: pexels</figcaption>
</figure>
<p></p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  <b
    >우리의&nbsp;생각보다&nbsp;훨씬&nbsp;더&nbsp;고객은&nbsp;상품&nbsp;패키지를&nbsp;중요하게&nbsp;여깁니다.&nbsp;</b
  >쇼핑몰을&nbsp;탐색하고,&nbsp;구매하고,&nbsp;택배로&nbsp;상품을&nbsp;받는&nbsp;것까지.&nbsp;고객은&nbsp;처음부터&nbsp;끝까지&nbsp;일관된&nbsp;쇼핑&nbsp;경험을&nbsp;원합니다.&nbsp;브랜드의&nbsp;매력이&nbsp;녹아있는&nbsp;멋지고&nbsp;아름다운&nbsp;패키지를&nbsp;상품&nbsp;사진에&nbsp;함께&nbsp;포함한다면,&nbsp;고객은&nbsp;우리의&nbsp;상품에&nbsp;한&nbsp;번&nbsp;더&nbsp;눈길이&nbsp;갈&nbsp;수밖에&nbsp;없을&nbsp;것입니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h3
  style="color: #000000; text-align: start"
  data-ke-size="size23"
  id="드랩아트로_상품사진_만들기"
>
  <b>드랩아트로&nbsp;상품사진&nbsp;만들기</b>
</h3>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  드랩아트를&nbsp;이용하면&nbsp;별도의&nbsp;스튜디오를&nbsp;대여하거나&nbsp;촬영&nbsp;소품을&nbsp;따로&nbsp;준비하지&nbsp;않고도&nbsp;다양한&nbsp;유형의&nbsp;상품&nbsp;사진을&nbsp;얻을&nbsp;수&nbsp;있습니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h4
  style="color: #000000; text-align: start"
  data-ke-size="size20"
  id="1._깔끔한_단색_배경의_사진"
>
  <b>1. 깔끔한 단색 배경의 사진</b>
</h4>
<p></p>
<figure
  class="imageblock alignCenter"
  data-ke-mobilestyle="widthOrigin"
  data-filename="input-horz.jpg"
  data-origin-width="2333"
  data-origin-height="1000"
>
  <span
    data-url="https://blog.kakaocdn.net/dn/c5nU1U/btstX19VB6e/j5ze0jEyWj9B4pZa2VGYwK/img.jpg"
    data-lightbox="lightbox"
    data-alt="(좌)원본 사진, (우)드랩아트가 생성한 흰색 배경 사진"
    ><img
      src="https://blog.kakaocdn.net/dn/c5nU1U/btstX19VB6e/j5ze0jEyWj9B4pZa2VGYwK/img.jpg"
      srcset="
        https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&amp;fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fc5nU1U%2FbtstX19VB6e%2Fj5ze0jEyWj9B4pZa2VGYwK%2Fimg.jpg
      "
      onerror="this.onerror=null; this.src='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png'; this.srcset='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png';"
      data-filename="input-horz.jpg"
      data-origin-width="2333"
      data-origin-height="1000"
  /></span>
  <figcaption>(좌)원본 사진, (우)드랩아트가 생성한 흰색 배경 사진</figcaption>
</figure>
<p></p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  <b>상품만 남기고, 다른 피사체는 모두 제거한 상태의 깔끔한 단색 배경 사진</b>을 만들 수 있으며,
  원본 사진에는 없는 그림자까지 생성합니다. 따로 스튜디오를 대여하거나 조명, 배경 스크린,
  포토박스 등의 사진 촬영 장비가 필요없습니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<h4 style="color: #000000; text-align: start" data-ke-size="size20" id="2._다양한_컨셉_사진">
  <b>2. 다양한 컨셉 사진</b>
</h4>
<p></p>
<figure
  class="imageblock alignCenter"
  data-ke-mobilestyle="widthOrigin"
  data-filename="input_draphed_01 (1)-horz.jpg"
  data-origin-width="3108"
  data-origin-height="1554"
>
  <span
    data-url="https://blog.kakaocdn.net/dn/b1lAtZ/btstSBj7Rzp/wLEjqNKGjZ51KbYzJobrO0/img.jpg"
    data-lightbox="lightbox"
    data-alt="드랩아트가 생성한 사진"
    ><img
      src="https://blog.kakaocdn.net/dn/b1lAtZ/btstSBj7Rzp/wLEjqNKGjZ51KbYzJobrO0/img.jpg"
      srcset="
        https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&amp;fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fb1lAtZ%2FbtstSBj7Rzp%2FwLEjqNKGjZ51KbYzJobrO0%2Fimg.jpg
      "
      onerror="this.onerror=null; this.src='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png'; this.srcset='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png';"
      data-filename="input_draphed_01 (1)-horz.jpg"
      data-origin-width="3108"
      data-origin-height="1554"
  /></span>
  <figcaption>드랩아트가 생성한 사진</figcaption>
</figure>
<p></p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  상품의&nbsp;특징과&nbsp;브랜드&nbsp;아이덴티티를&nbsp;잘&nbsp;드러내는&nbsp;상품&nbsp;사진을&nbsp;찍는&nbsp;건&nbsp;쉽지&nbsp;않습니다.&nbsp;컨셉&nbsp;기획부터&nbsp;시작해&nbsp;컨셉을&nbsp;잘&nbsp;표현해줄&nbsp;전문&nbsp;사진&nbsp;기사를&nbsp;고용하고,&nbsp;필요한&nbsp;소품을&nbsp;구매하는&nbsp;등&nbsp;매우&nbsp;길고&nbsp;복잡한&nbsp;과정이&nbsp;필요합니다.<br />하지만
  드랩아트를 사용하면 위에 과정을 모두 생략할 수 있습니다.<span>&nbsp;</span
  ><b
    >스마트폰으로 대충 찍은 사진이라도 원하는 사진 컨셉을 입력하면 드랩아트의 AI가 해당 컨셉을
    반영한 새로운 상품사진을 만들어 내기 때문입니다.</b
  >
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<div
  class="txc-textbox"
  style="background-color: #ffffff; border: #cbcbcb 3px double; padding: 10px"
>
  <h3 style="color: #000000; text-align: start" data-ke-size="size23" id="결론"><b>결론</b></h3>
  <p style="color: #333333; text-align: start" data-ke-size="size16">
    <b
      >&nbsp;&nbsp;&nbsp;• 쇼핑몰에 처음 방문한 고객의 관심을 끌 수 있는 시간은 매우 짧음<br />&nbsp;&nbsp;&nbsp;•&nbsp;짧은&nbsp;시간&nbsp;내에&nbsp;고객의&nbsp;관심을&nbsp;끌&nbsp;수&nbsp;있는&nbsp;가장&nbsp;효과적인&nbsp;방법은&nbsp;‘상품&nbsp;사진’<br />&nbsp;&nbsp;&nbsp;•&nbsp;다양한&nbsp;유형의&nbsp;상품&nbsp;사진을&nbsp;활용하여&nbsp;고객의&nbsp;눈길을&nbsp;계속&nbsp;붙잡아&nbsp;두는&nbsp;것이&nbsp;필요</b
    >
  </p>
  <p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
</div>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  <b
    >지금까지&nbsp;쇼핑몰&nbsp;상품&nbsp;사진&nbsp;잘&nbsp;찍는&nbsp;방법에&nbsp;대해&nbsp;알아봤습니다.</b
  >
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  쇼핑몰에 처음 방문한 고객은 상품에 대한 정보가 전무한 상태이기 때문에 시각적인 자극에 가장
  예민하게 반응할 수밖에 없습니다. 그래서 고객의 눈길을 사로잡는 상품 사진을 제작하는 것은 매우
  중요합니다.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">
  <b>상품 사진 제작에 비용과 시간이 많이 들어 어떻게 해야할지 고민 중</b>이시라면 드랩아트를
  이용해 고품질의 상품 사진을 간단하게 만들어 보세요.
</p>
<p style="color: #333333; text-align: start" data-ke-size="size16">&nbsp;</p>
<p></p>
<figure
  class="imageblock alignCenter"
  data-ke-mobilestyle="widthOrigin"
  data-filename="네이버 블로그 _ 본문 드랩 바로가기 배너-001.png"
  data-origin-width="400"
  data-origin-height="70"
>
  <a
    href="https://draph.art/home?utm_source=draph_blog&amp;utm_medium=draph_blog&amp;utm_campaign=draph_blog"
    target="_blank"
    ><img
      src="https://blog.kakaocdn.net/dn/tCGYf/btstVgHWF1B/AKnH5tweHV2zmw6eL5Pyr1/img.png"
      srcset="
        https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&amp;fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FtCGYf%2FbtstVgHWF1B%2FAKnH5tweHV2zmw6eL5Pyr1%2Fimg.png
      "
      onerror="this.onerror=null; this.src='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png'; this.srcset='//t1.daumcdn.net/tistory_admin/static/images/no-image-v1.png';"
      data-filename="네이버 블로그 _ 본문 드랩 바로가기 배너-001.png"
      data-origin-width="400"
      data-origin-height="70"
  /></a>
</figure>
<p></p>
</div>`
