import { Box, Button, useTheme } from '@mui/material'
import { ShadowButton } from './CustomStyle'
import { useTranslation } from 'react-i18next'
import { useMobileMediaQuery } from 'hooks/useMediaQuery'

export function DialogButton({
  actionText,
  cancelText = null,
  handleClose,
  handleAction,
  cancelSx = {},
  actionSx = {},
  actionprops = {},
  cancelprops = {},
  gap = '0',
}) {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const isMobile = useMobileMediaQuery()

  return (
    <Box sx={{ display: 'flex', gap: gap }}>
      <Button
        disableTouchRipple
        sx={{
          height: '4rem',
          fontSize: '1.6rem',
          borderRadius: '4px',
          fontWeight: 700,
          px: '2.6rem',
          color: '#808080',

          '&:hover': { background: '#ffffff', color: theme.palette.common.black },
          ...cancelSx,
        }}
        onClick={handleClose}
        {...cancelprops}
      >
        {cancelText ?? t('button.close')}
      </Button>
      <Button
        variant="contained"
        sx={{
          minWidth: isMobile ? '12rem' : '14rem',
          height: '4rem',
          borderRadius: '4px',
          fontSize: '1.6rem',
          fontWeight: 700,
          px: '2.4rem',
        }}
        onClick={handleAction}
        // disabled={true}
        {...actionprops}
      >
        {actionText}
      </Button>

      {/* <ShadowButton
        onClick={handleClose}
        sx={{
          fontSize: '2rem',
          fontWeight: 800,
          height: '5rem',
          background: 'white',
          // '&.Mui-disabled': {
          //   color: '#909090',
          //   backgroundColor: '#dedede',
          // },
          borderRadius: '1rem',
          ...cancelSx,
        }}
        {...cancelprops}
      >
        {cancelText ?? t('button.cancel')}
      </ShadowButton>

      <Button
        variant="contained"
        sx={{
          fontSize: '2rem',
          fontWeight: 800,
          minWidth: '18.4rem',
          width: 'auto',
          height: '5rem',
          borderRadius: '1rem',
          px: '2.4rem',
          ...actionSx,
        }}
        onClick={handleAction}
        // disabled={true}
        {...actionprops}
      >
        {actionText}
      </Button> */}
    </Box>
  )
}
