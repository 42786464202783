import { useRecoilState, useResetRecoilState } from 'recoil'
import FragmentImages from './FragmentImages'
import {
  mannequinLoadingAtom,
  maskImgArrayAtom,
  nukkiSAMSizeAtom,
  segmentLoadingAtom,
  segmentStepAtom,
  selectedMaskImgAtom,
  uploadFilesAndUrlAtom,
  uploadImgHeightAtom,
  uploadImgWidthAtom,
} from 'atoms'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { useEffect, useState } from 'react'
import { FadeLoader } from 'react-spinners'
import { useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'

export function Segment({
  widthLimit,
  heightLimit,
  usage = 'default',
  setOriSize = () => {},
  fileAtom = uploadFilesAndUrlAtom,
}) {
  const theme = useTheme()

  const [uploadImgHeight, setUploadImgHeight] = useRecoilState(uploadImgHeightAtom)
  const [uploadImgWidth, setUploadImgWidth] = useRecoilState(uploadImgWidthAtom)
  const [scale, setScale] = useState()

  const [files, setFiles] = useRecoilState(fileAtom)
  const [maskImgArray, setMaskImgArray] = useRecoilState(maskImgArrayAtom)
  const [segmentStep, setSegmentStep] = useRecoilState(segmentStepAtom)
  const [selectedMaskImg, setSelectedMaskImg] = useRecoilState(selectedMaskImgAtom)
  const [segmentLoading, setSegmentLoading] = useRecoilState(segmentLoadingAtom)
  const [loading, setLoading] = useRecoilState(mannequinLoadingAtom)
  const [nukkiSAMSize, setnukkiSAMSize] = useRecoilState(nukkiSAMSizeAtom)

  const images = files[0]

  const loadHandler = e => {
    const realWidth = e.target.naturalWidth
    const realHeight = e.target.naturalHeight

    const widthScale = widthLimit / realWidth
    const heightScale = heightLimit / realHeight //  높이 / 너비 너비 비율

    if (usage === 'nukki') {
      setOriSize({ w: realWidth, h: realHeight })
    }

    if (widthScale >= heightScale) {
      setUploadImgWidth(`${(realWidth * heightScale) / 10}rem`)
      setUploadImgHeight(`${(realHeight * heightScale) / 10}rem`)
      setScale(heightScale)
    }

    if (widthScale < heightScale) {
      setUploadImgWidth(`${(realWidth * widthScale) / 10}rem`)
      setUploadImgHeight(`${(realHeight * widthScale) / 10}rem`)
      setScale(widthScale)
    }
  }

  useEffect(() => {
    return () => {
      if (usage === 'default') {
        // files.forEach(image => URL.revokeObjectURL(image.url))
        // resetFiles()
        // resetUploadImgWidth()
        // resetUploadImgHeight()
        // resetSelectedMaskImg()
        // resetMannequinLoading()
        // resetSegmentLoading()
        // resetSegmentStep()
        // resetMaskImgArray()
      }
    }
  }, [])

  return (
    <>
      {files.length !== 0 && segmentStep === 0 && (
        <CenterAlignStack>
          <img
            src={images.url}
            style={{ width: uploadImgWidth, height: uploadImgHeight }}
            onLoad={loadHandler}
          />
          {segmentLoading && (
            <CenterAlignBox
              sx={{
                position: 'absolute',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.50)',
                width: uploadImgWidth,
                height: uploadImgHeight,
              }}
            >
              <FadeLoader
                color={theme.palette.common.white}
                height={25}
                margin={16}
                radius={9}
                width={6}
              />
            </CenterAlignBox>
          )}
        </CenterAlignStack>
      )}

      {segmentStep === 1 && (
        <FragmentImages
          files={images}
          uploadImgHeight={uploadImgHeight}
          uploadImgWidth={uploadImgWidth}
          usage={usage}
        />
      )}
    </>
  )
}
