import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
  TableHead,
  TableSortLabel,
  alpha,
  Divider,
} from '@mui/material'
import {
  userAtom,
  portfolioAtom,
  portfolioDeleteModeAtom,
  viewModeAtom,
  defaultPortfolioAtom,
} from 'atoms'
import * as config from 'config'

import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { useInView } from 'framer-motion'
import { useEffect, useMemo, useRef, useState, Fragment } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { CustomPagination, triggerGA4DownloadEvent } from 'components'
import { ShowingPortfolio } from './ShowingPortfolio'
import { AiFillDelete, AiOutlineFilter } from 'react-icons/ai'
import { visuallyHidden } from '@mui/utils'
import {
  creditPolicyDictSelector,
  filteredPortfolioSelector,
  userPortfolioNamesSelector,
} from 'selector'
import { DataGrid } from '@mui/x-data-grid'
import { byteSize, getPieceType, getS3ImageSrc, padZeros } from 'utils/common'
import { useLocation, useNavigate } from 'react-router-dom'
import { PuffLoader as Loader } from 'react-spinners'
import { styled } from '@mui/material/styles'
import { useDesktopMediaQuery, useMobileMediaQuery } from 'hooks/useMediaQuery'
import useConfirm from 'hooks/useConfirm'
import { apis } from 'apis'
import { CopyButton, DeleteButton, DownloadButton } from './PortfolioCard'
import JSZip from 'jszip'
import { checkedURLforNoCache } from './ArtworkList'
import axios from 'axios'
import _ from 'lodash'
import { enqueueSnackbar } from 'notistack'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getDownloadPieceFileName } from '../../utils/common'

const HeaderName = styled(Typography)(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: 500,
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.3rem',
    fontWeight: 500,
  },
}))

const PORTFOLIOS_PER_PAGE = 15
const columns = [
  // {
  //   field: 'id',
  //   width: 80,
  //   headerName: 'ID',
  //   align: 'center',
  //   headerAlign: 'center',
  //   sortable: false,
  //   renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
  //   renderCell: params => (
  //     <Typography fontSize="1.8rem" fontWeight={500}>
  //       {params.value}
  //     </Typography>
  //   ),
  // },
  {
    field: 'thumb',
    headerName: '',
    headerAlign: 'center',
    sortable: false,
    renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
    renderCell: params => {
      const thumbs = params.value.filter(x => x)
      return (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: '2.1rem',
          }}
        >
          {thumbs[0] ? (
            <img
              src={getS3ImageSrc(thumbs[0])}
              style={{ width: '7rem', height: '7rem', objectFit: 'cover' }}
            />
          ) : (
            <img
              src="/static/images/logo/logo_image.png"
              style={{ width: '7rem', height: '7rem', objectFit: 'cover', opacity: 0.3 }}
            />
          )}
        </Box>
      )
    },
  },
  {
    field: 'name',
    headerName: '이름',
    headerAlign: 'center',
    minWidth: 190,
    sortable: false,
    renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
    renderCell: params => (
      <Typography
        fontSize="1.8rem"
        fontWeight={700}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: params.row.is_default ? 'gray' : '',
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'theme',
    headerName: '키워드',
    headerAlign: 'center',
    sortable: false,
    width: 360,
    renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
    renderCell: params => (
      <Typography
        component="span"
        fontSize="1.8rem"
        fontWeight={400}
        sx={{
          width: '60rem',
          whiteSpace: 'nowrap',
          color: '#505050',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.value
          ?.split(',')
          .map((t, i) => (t.trim().length > 0 ? `# ${t.replace(' ', '')}  ` : null))}
      </Typography>
    ),
  },
  {
    field: 'created',
    headerName: '생성 날짜',
    headerAlign: 'center',
    align: 'center',
    width: 120,
    sortable: false,
    renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
    renderCell: params => (
      <Typography fontSize="1.7rem" fontWeight={400}>
        {params.value ? moment(params.value.slice(0, 10)).format('YYYY.MM.DD') : ''}
      </Typography>
    ),
  },
  {
    field: '',
    headerName: '',
    headerAlign: 'center',
    sortable: false,
    width: 180,
    // renderHeader: params => <HeaderName> </HeaderName>,
    renderCell: params => {
      // console.log('params', params)
      return <PortfolioController row={params.row} />
    },
  },
]

const columnsMobile = [
  {
    field: 'id',
    width: 45,
    minWidth: 40,
    headerName: 'ID',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
    renderCell: params => (
      <Typography
        fontSize="1.2rem"
        fontWeight={500}
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'thumb',
    headerName: '',
    headerAlign: 'center',
    width: 45,
    minWidth: 45,
    sortable: false,
    renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
    renderCell: params => {
      const thumbs = params.value.filter(x => x)
      return (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: '1.2rem',
          }}
        >
          {thumbs[0] ? (
            <img
              src={getS3ImageSrc(thumbs[0])}
              style={{ width: '4rem', height: '4rem', objectFit: 'cover' }}
            />
          ) : (
            <img
              src="/static/images/logo/logo_image.png"
              style={{ width: '4rem', height: '4rem', objectFit: 'cover', opacity: 0.3 }}
            />
          )}
        </Box>
      )
    },
  },
  {
    field: 'name',
    headerName: '이름',
    headerAlign: 'center',
    minWidth: 135,
    sortable: false,
    renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
    renderCell: params => (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Typography
          fontSize="1.3rem"
          fontWeight={700}
          sx={{
            pl: '1.8rem',
            pr: '1rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {params.value}
        </Typography>
        <Typography
          fontSize="1.2rem"
          fontWeight={400}
          sx={{
            pl: '1.8rem',
            pr: '1rem',
            color: '#666',
          }}
        >
          {moment(params.row.create).format('YYYY.MM.DD')}
        </Typography>
      </Box>
    ),
  },
  {
    field: ' ',
    headerName: ' ',
    align: 'center',
    headerAlign: 'center',
    minWidth: 120,
    width: 120,
    sortable: false,
    renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
    renderCell: params => {
      console.log('params', params)
      return <PortfolioController row={params.row} />
    },
  },
  // {
  //   field: 'theme',
  //   headerName: '키워드',
  //   headerAlign: 'center',
  //   sortable: false,
  //   width: 120,
  //   renderHeader: params => <HeaderName>{params.colDef.headerName}</HeaderName>,
  //   renderCell: params => {
  //     const bytePerLine = 18 // width 조정되면 수정해야함
  //     let byteCount = 0
  //     let result = ''

  //     if (params.value?.length > 0) {
  //       const txtArr = params.value?.split(',').map((t, i) => {
  //         return t.length > 0 ? `#${t.replace(' ', '')}` : null
  //       })

  //       txtArr?.forEach(t => {
  //         const b = byteSize(t) + 1
  //         if (byteCount + b < bytePerLine) {
  //           result += t + ' '
  //           byteCount += b
  //         } else {
  //           if (result.length < 1) {
  //             result += t + ' '
  //             byteCount = 0
  //           } else {
  //             result += '\n' + t + ' '
  //             byteCount = b
  //           }
  //         }
  //       })
  //     }

  //     return (
  //       <Box
  //         sx={{
  //           width: '100%',
  //           ml: '1.8rem',
  //           m: '1.1rem',
  //           pt: '0.2rem',

  //           height: '4.2rem',
  //           maxHeight: '6.4rem',
  //           whiteSpace: 'pre-wrap',
  //           wordBreak: 'break-all',
  //           overflow: 'hidden',
  //           display: 'flex',
  //           alignItems: 'center',
  //         }}
  //       >
  //         <Typography
  //           component="div"
  //           fontSize="1.2rem"
  //           fontWeight={400}
  //           sx={{
  //             maxHeight: '100%',
  //             height: 'fit-content',
  //             color: '#505050',
  //             lineHeight: '1.3rem',
  //             overflow: 'hidden',
  //             textOverflow: 'ellipsis',
  //             whiteSpace: 'pre',
  //           }}
  //         >
  //           {result}
  //         </Typography>
  //       </Box>
  //     )
  //   },
  // },
]

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.black,
  border: 0,
  width: '100%',
  paddingBottom: '3rem',
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100% !important',
  },

  '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
    margin: '0 1.3rem',
    backgroundColor: 'transparent',

    '&.MuiDataGrid-cell:last-child': {
      width: '0 !important',
      margin: '0 !important',
      padding: '0 !important',
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    borderBottom: '0.1rem solid',
    borderColor: theme.palette.common.black,
    justifyContent: 'center',

    '& .MuiDataGrid-columnHeader': {
      '&:focus-within': { outline: 'none !important' },
    },
  },

  '& .MuiDataGrid-row': {
    // padding: '0 7.6rem',
    width: '100% !important',
    justifyContent: 'center',

    borderBottom: '0.05rem solid #8F8F8F',
    cursor: 'pointer',

    '&.Mui-selected': {
      background: '#F2F6FF',
      '&:hover': {
        background: '#F2F6FF',
      },
    },
  },

  '& .MuiDataGrid-cell': {
    borderBottom: 0,

    '&:focus-within': { outline: 'none !important' },
    '&:last-child': {
      // width: '24.6rem !important',
      // minWidth: '24.6rem !important',
      // maxWidth: '24.6rem !important',
    },
  },

  '& .MuiDataGrid-columnSeparator': { display: 'none' },

  [theme.breakpoints.down('lg')]: {
    '& .MuiDataGrid-columnHeaders': {
      minHeight: '4rem !important',
      maxHeight: '4rem !important',
      lineHeight: '4rem !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '4rem !important', // '& .MuiDataGrid-columnHeaders' 높이와 맞춰야함
    },
    '& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader': {
      margin: '0rem',
      padding: 0,
    },
  },
}))

export function PortfolioTable({ setIsLoading, setPortfolio }) {
  const navigate = useNavigate()
  const portfolio = useRecoilValue(portfolioAtom)
  const currentUser = useRecoilValue(userAtom)
  const [deleteMode, setDeleteMode] = useRecoilState(portfolioDeleteModeAtom)
  const [viewMode, setViewMode] = useRecoilState(viewModeAtom)

  const filteredPortfolios = useRecoilValue(filteredPortfolioSelector)
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [page, setPage] = useState(1)
  const [pageData, setPageData] = useState([])

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const pages = queryParams.get('pages') || 1

  useEffect(() => {
    setPage(Number(pages))
  }, [pages])

  useEffect(() => {
    if (filteredPortfolios) {
      const p = defaultPortfolio.id
        ? [{ ...defaultPortfolio }, ...filteredPortfolios]
        : filteredPortfolios
      const a = (page - 1) * PORTFOLIOS_PER_PAGE
      const b = page * PORTFOLIOS_PER_PAGE
      const pData = p?.slice(a, b) ?? []
      setPageData(pData)
    }
  }, [defaultPortfolio, filteredPortfolios, page])

  useEffect(() => {
    if (viewMode.type === 'table' && viewMode.page) {
      setPage(viewMode.page)
    }
  }, [])

  const isDesktop = useDesktopMediaQuery()

  const selectPortfolio = ({ row }) => {
    navigate(`/portfolio/${row.id}?pages=1`)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setViewMode(m => ({ ...m, page }))
  }, [page])

  return (
    <CenterAlignStack sx={{ width: '100%', pb: '5rem' }}>
      <StyledDataGrid
        disableColumnMenu
        getRowHeight={() => 'auto'}
        rows={pageData}
        components={{
          NoRowsOverlay: EmptyRow,
        }}
        keepNonExistentRowsSelected
        selectionModel={deleteMode.ids}
        onSelectionModelChange={ids => {
          setDeleteMode(d => ({ ...d, ids }))
        }}
        columns={isDesktop ? columns : columnsMobile}
        pageSize={PORTFOLIOS_PER_PAGE}
        // rowsPerPageOptions={[5, 10, 15]}
        autoHeight
        disableSelectionOnClick={!deleteMode.on}
        checkboxSelection={deleteMode.on}
        hideFooter
        initialState={{
          sorting: {},
        }}
        onRowClick={
          deleteMode.on
            ? ({ row }) => {
                if (deleteMode.ids.includes(row.id)) {
                  setDeleteMode(d => ({ ...d, ids: d.ids.filter(e => e !== row.id) }))
                } else {
                  setDeleteMode(d => ({ ...d, ids: [...d.ids, row.id] }))
                }
              }
            : selectPortfolio
        }
      />

      <CustomPagination
        count={Math.ceil((filteredPortfolios?.length ?? 0) / PORTFOLIOS_PER_PAGE)}
        page={page}
        onChange={(e, value) => {
          setPage(value)
          navigate(`/portfolio?pages=${value}`)
        }}
      />
    </CenterAlignStack>
  )
}

function EmptyRow() {
  const filteredPortfolios = useRecoilValue(filteredPortfolioSelector)

  return (
    <CenterAlignBox sx={{ width: '100%', py: 4 }}>
      {filteredPortfolios === null ? (
        <>
          <Loader />
        </>
      ) : (
        <Typography>포트폴리오가 없습니다.</Typography>
      )}
    </CenterAlignBox>
  )
}

function PortfolioController({ row }) {
  const { showConfirm } = useConfirm()
  const [portfolio, setPortfolio] = useRecoilState(portfolioAtom)
  const [user, setUser] = useRecoilState(userAtom)
  const userPortfolioNames = useRecoilValue(userPortfolioNamesSelector)
  const creditPolicy = useRecoilValue(creditPolicyDictSelector)
  const isMobile = useMobileMediaQuery()
  const { t, i18n } = useTranslation()

  const refreshPortfolio = () => {
    apis.portfolio.getAllportfolio().then(response => {
      setPortfolio(response.data)
    })
  }

  const deleteAll = e => {
    showConfirm({
      title: '포트폴리오 삭제',
      content: <Typography>포트폴리오를 삭제하시겠습니까?</Typography>,
      onConfirm: () => {
        apis.portfolio.deletePortfolio(row.id).then(() => {
          refreshPortfolio()
        })
      },
    })
  }

  const copyPortofolio = () => {
    showConfirm({
      title: '포트폴리오 복사',
      content: <Typography>포트폴리오 설정만 복사 후 생성합니다.</Typography>,
      onConfirm: () => {
        const data = row
        // name 처리
        const pattern = new RegExp(`${data.name} copy_(\\d+)`)
        const numbers = userPortfolioNames
          .filter(name => pattern.test(name))
          .map(name => parseInt(name.match(pattern)[1]))

        const maxNumber = numbers.length > 0 ? Math.max(...numbers) : 0

        const nameCopy = `${data.name} copy_${maxNumber + 1}`
        const configCopy = { ...data.config, name: nameCopy }
        const themeCopy = data.theme

        const body = {
          name: nameCopy,
          theme: themeCopy,
          config: configCopy,
        }

        apis.portfolio
          .createPortfoilo(body)
          .then(response => {
            if (!response.data.success) {
              showConfirm({
                alertOnly: true,
                content: <Typography>{response.data.message}</Typography>,
                // TODO 안내 문구 다국어 지원하려면 ?
              })
            }

            refreshPortfolio()
          })
          .catch(error => {
            console.log(error)
          })
      },
    })
  }

  const downloadAll = async e => {
    const zip = new JSZip()
    const dirName = row.name
    const ax = axios.create()

    zip.folder(dirName)

    const promises = []
    const downloadInfos = []
    const fileCount = {}

    const artworksData = await apis.portfolio.getAllPortfolioArtwork(row.id)
    const artworks = artworksData.data

    artworks.forEach(artwork => {
      const paths = []
      if (config.ARTWORK_DONE_STATUS !== artwork.status) return

      const artworkName = artwork.name
        .replace('.' + _.last(artwork.name.split('.')), '')
        .replaceAll(' ', '_')

      const target = artwork.pieces.map((piece, idx) => {
        return piece.path
      })

      target.forEach(path => {
        fileCount[artworkName] = fileCount[artworkName] ? fileCount[artworkName] + 1 : 1
        const fileIdx = fileCount[artworkName]
        const url = getS3ImageSrc(checkedURLforNoCache(path))
        if (!url) return

        paths.push(path)
        promises.push(
          ax.get(url + `?w=${Date.now().toString()}`, { responseType: 'blob' }).then(response => {
            return { response, artworkName, fileIdx }
          })
        )
      })

      const artworkConfig = JSON.parse(artwork.config)

      // ----- GA4 event -----
      triggerGA4DownloadEvent({ artworkConfig, artwork, user, count: paths.length })
      // ---------------------

      downloadInfos.push({ artwork_id: artwork.id, paths })
    })

    if (downloadInfos.length < 1) {
      enqueueSnackbar('생성 완료된 이미지만 다운로드 가능합니다.', { variant: 'error' })
      return
    }

    showConfirm({
      title: '생성 이미지 전체 다운로드',
      content: (
        <Typography>총 {promises.length}개 생성 이미지를 다운로드 받으시겠습니까? </Typography>
      ),
      onConfirm: async () => {
        if (artworks.length > 200 && user.grade !== 'enterpriseB') {
          enqueueSnackbar('최대 다운로드 가능한 이미지 개수를 초과하였습니다.', {
            variant: 'error',
          })
          return
        }

        // 크레딧 계산 시 사용
        let artworkCount = 0

        const allPieces = []
        artworks.forEach(artwork => {
          allPieces.push(...artwork.pieces)
          if (artwork.download < 1 && artwork.pieces[0].path) {
            artworkCount += 1
          }
        })

        if (user.use_credit_on.includes('artwork_download')) {
          const credit = await checkUserCredit()

          if (credit < creditPolicy.artwork_download * artworkCount) {
            showConfirm({
              alertOnly: true,
              content: <Typography>{t('common.credit_warning')}</Typography>,
            })
            return
          }
        }
        Promise.all(promises).then(results => {
          apis.portfolio.updateMultiArtworkDownload(row.id, downloadInfos).then(response => {
            // 싹다 업데이트 ?
          })
          results.forEach(({ response, artworkName, fileIdx }) => {
            const ext = _.last(
              _.first(_.last(response.config.url.split('/')).split('?')).split('.')
            )

            const piece = allPieces.filter(p => response?.config?.url.includes(p.path))[0]
            const fileName =
              piece?.result_filename?.length > 0
                ? piece.result_filename
                : getDownloadPieceFileName({ prefix: artworkName, idx: fileIdx, ext })
            // 아트워크별로 폴더를 나누어서 담으려면 ..
            // zip.folder(dirName).folder(artworkName).file(fileName, response.data)
            zip.folder(dirName).file(fileName, response.data)
          })

          zip.generateAsync({ type: 'blob' }).then(blob => {
            saveAs(blob, `${dirName}.zip`)
          })
        })
      },
      onCancel: () => {},
    })
  }

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  return (
    <Box>
      {' '}
      <CenterAlignBox
        sx={{
          height: { lg: '4.85rem', xs: '3.7rem' },
          margin: { lg: '0.3rem' },
          alignItems: 'center',
        }}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <DownloadButton clickHandler={downloadAll} color="#303030" isTable={!isMobile} />
        {!row.is_default && (
          <>
            <Divider
              flexItem
              orientation="vertical"
              variant="middle"
              sx={{ m: { lg: '0rem 0.2rem 2.3rem', xs: ' 1.05rem 0.5rem' } }}
            />
            <CopyButton clickHandler={copyPortofolio} color="#303030" isTable={!isMobile} />
            <Divider
              flexItem
              orientation="vertical"
              variant="middle"
              sx={{ m: { lg: '0rem 0.2rem 2.3rem', xs: ' 1.05rem 0.5rem' } }}
            />
            <DeleteButton clickHandler={deleteAll} color="#303030" isTable={!isMobile} />
          </>
        )}
      </CenterAlignBox>
    </Box>
  )
}
