import { Cookies } from 'react-cookie'
import { configData } from 'config'

const cookies = new Cookies()
const accessToken = 'access_token'
const refreshToken = 'refresh_token'
const accessTokenNaver = 'access_token_naver'

export const guestSessionToken = '_draph_art_guest_session'
export const guestAccessToken = '_draph_art_guest' // 업로드에 쓰이는 유저 액세스 토큰 개념
export const guestRedirectPath = '_draph_art_guest_redir'

const userCookies = []

const defaultOption = {
  path: '/',
  sameSite: true,
  secure: !process.env.NODE_ENV === 'development',
}

export const setCookie = (name, value, option) => {
  cookies.set(name, value, { ...option, path: '/' })
  userCookies.push(name)
}

export const getCookie = name => cookies.get(name)

export const removeCookie = name => cookies.remove(name, { path: '/' })

export const setAccessToken = (value, option) => {
  cookies.set(accessToken, value, { ...defaultOption, ...option })
}
export const setAccessTokenNaver = (value, option) => {
  cookies.set(accessTokenNaver, value, { ...defaultOption, ...option })
}

export const getAccessToken = () => cookies.get(accessToken)
export const getAccessTokenNaver = () => cookies.get(accessTokenNaver)

export const removeAccessToken = () => cookies.remove(accessToken, { path: '/' })

export const setRefreshToken = (value, option) =>
  cookies.set(refreshToken, value, {
    ...option,
    path: '/',
    secure: true,
    sameSite: true,
  })

export const getRefreshToken = () => cookies.get(refreshToken)

export const removeUserCookie = () => {
  const all = cookies.getAll()
  for (const name in all) {
    if (
      name.includes('user_') ||
      name.includes(accessToken) ||
      name.includes(refreshToken) ||
      userCookies.includes(name)
    ) {
      cookies.remove(name, { path: '/' })
    }
  }
}
export const removeGuestCookie = () => {
  const all = cookies.getAll()
  for (const name in all) {
    if (name.includes('_draph_art_guest')) {
      cookies.remove(name, { path: '/' })
    }
  }
}

export const getGuestSession = () => cookies.get(guestSessionToken)
export const getGuestAccess = () => cookies.get(guestAccessToken)

export const setGuestSession = (value, option) => {
  // cookies.set(guestSessionToken, value, {
  //   path: '/',
  //   secure: true,
  //   sameSite: 'none',
  //   httpOnly: true,
  //   ...option,
  // })
  cookies.set(guestSessionToken, value, { ...defaultOption, sameSite: false, ...option })
}

export const setGuestAccess = (value, option) => {
  // cookies.set(guestAccessToken, value, {
  //   path: '/',
  //   secure: true,
  //   sameSite: 'none',
  //   httpOnly: true,
  //   ...option,
  // })
  cookies.set(guestAccessToken, value, { ...defaultOption, ...option })
}
