import { useTheme } from '@emotion/react'
import { Box, Checkbox, Stack } from '@mui/material'
import { CenterAlignBox } from 'components/CenterAlignContainer'
import { Desktop } from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import * as config from 'config'
import { useRecoilValue } from 'recoil'
import { defaultPortfolioAtom, portfolioTypeAtom } from 'atoms'
import { useTranslation } from 'react-i18next'
import { ArtworkRowImageSwiper } from './ArtworkRowImageSwiper'
import { ArtworkControl, ArtworkError } from './ArtworkRowControl'
import { DESKTOP_CONTENT_WIDTH, checkedURLforNoCache, processImages } from './ArtworkList'
import { convertPriceSymbolToWord } from 'utils/common'

const sample = [
  {
    uploaded: '1721285784475_IMG_5109/input.jpg',
    portfolio_id: 1570,
    feedback_content: null,
    status: 'analysis',
    user_id: 80,
    selected_piece: null,
    err_type: null,
    s3_urls: null,
    total_pieces: null,
    generated: [],
    created: '2024-07-18T06:56:25.852107',
    deleted: false,
    effect: '-',
    config:
      '{"gen_shadow": true, "gen_face": false, "object_category": "food", "object_sub_category": "raw", "object_boundary": "none", "object_angle": "auto", "flag_generate": true, "flag_complex_cmp": true, "flag_simple_cmp": false, "flag_white_cmp": false, "flag_gen_compo": false, "flag_bg_expansion": false, "flag_multiblob_sod": false, "flag_human_background": false, "SO_length_scale": "auto", "flag_facemorphing": false, "facemorphing_race": "asian", "facemorphing_gender": "none", "output_size_w": 0, "output_size_h": 0, "selected_bg_ids": "person_torso_16693636557629", "theme": "", "theme_background": "", "theme_template": "auto", "theme_custom": "", "output_size_list": [{"output_w": 0, "output_h": 0}], "simple_bg_color_list": [[255, 255, 255], [242, 242, 244]], "endpoints": {}, "from_zip": false, "zip_path": null, "upload_id": "d1fa1046-1d83-4735-8d7f-bcf96223c0b1", "user_id": 80, "user_name": "ga00e", "portfolio_id": "1570", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiODAiLCJpYXQiOjE3MjEyODIzNzYsImV4cCI6MTcyMTMwMzk3NiwidXNlcm5hbWUiOiJnYTAwZSJ9.R7MvnGZf_tmZWn-fg-BgQCcRGTd5muYeB53P0ISbEQ4", "webhook_url": "", "use_credit_type": "", "query": "", "output_w": 0, "output_h": 0, "input_filename": "IMG_5109.jpeg", "origin_filename": "IMG_5109.jpeg"}',
    upload_id: 'd1fa1046-1d83-4735-8d7f-bcf96223c0b1',
    id: '1721285784475_IMG_5109',
    url: 'display/1721285784475_IMG_5109',
    feedback_status: null,
    name: '',
    subject: '',
    feedback_updated: null,
    caption: '',
    pieces: [],
    used_credit: -1.0,
  },

  {
    uploaded: '1721285784475_IMG_5109/input.jpg',
    portfolio_id: 1570,
    feedback_content: null,
    status: 'gen_start',
    user_id: 80,
    selected_piece: null,
    err_type: null,
    s3_urls: null,
    total_pieces: 7,
    generated: [],
    created: '2024-07-18T06:56:25.852107',
    deleted: false,
    effect: '-',
    config:
      '{"gen_shadow": true, "gen_face": false, "object_category": "food", "object_sub_category": "raw", "object_boundary": "none", "object_angle": "auto", "flag_generate": true, "flag_complex_cmp": true, "flag_simple_cmp": false, "flag_white_cmp": false, "flag_gen_compo": false, "flag_bg_expansion": false, "flag_multiblob_sod": false, "flag_human_background": false, "SO_length_scale": "auto", "flag_facemorphing": false, "facemorphing_race": "asian", "facemorphing_gender": "none", "output_size_w": 0, "output_size_h": 0, "selected_bg_ids": "person_torso_16693636557629", "theme": "", "theme_background": "", "theme_template": "auto", "theme_custom": "", "output_size_list": [{"output_w": 0, "output_h": 0}], "simple_bg_color_list": [[255, 255, 255], [242, 242, 244]], "endpoints": {}, "from_zip": false, "zip_path": null, "upload_id": "d1fa1046-1d83-4735-8d7f-bcf96223c0b1", "user_id": 80, "user_name": "ga00e", "portfolio_id": "1570", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiODAiLCJpYXQiOjE3MjEyODIzNzYsImV4cCI6MTcyMTMwMzk3NiwidXNlcm5hbWUiOiJnYTAwZSJ9.R7MvnGZf_tmZWn-fg-BgQCcRGTd5muYeB53P0ISbEQ4", "webhook_url": "", "use_credit_type": "", "query": "", "output_w": 0, "output_h": 0, "input_filename": "IMG_5109.jpeg", "origin_filename": "IMG_5109.jpeg", "used_bg_ids": ["cosmetics__17174089844297", "cosmetics__17174702245804", "cosmetics__17174706373387"], "bg_search_filter": {"category": "food", "sub_category": "raw", "angle": "perpen", "so_size": "small", "es_hits": 3, "returned": 3}, "total_pieces": 7, "category": "food", "sub_category": "raw", "angle": "perpen"}',
    upload_id: 'd1fa1046-1d83-4735-8d7f-bcf96223c0b1',
    id: '1721285784475_IMG_5109',
    url: 'display/1721285784475_IMG_5109',
    feedback_status: null,
    name: 'IMG_5109.jpeg',
    subject: '',
    feedback_updated: null,
    caption: '',
    pieces: [],
    used_credit: -1.0,
  },

  {
    uploaded: '1721285784475_IMG_5109/input.jpg',
    portfolio_id: 1570,
    feedback_content: null,
    status: 'gen',
    user_id: 80,
    selected_piece: 283792,
    err_type: null,
    s3_urls: null,
    total_pieces: 7,
    generated: [],
    created: '2024-07-18T06:56:25.852107',
    deleted: false,
    effect: '-',
    config:
      '{"gen_shadow": true, "gen_face": false, "object_category": "food", "object_sub_category": "raw", "object_boundary": "none", "object_angle": "auto", "flag_generate": true, "flag_complex_cmp": true, "flag_simple_cmp": false, "flag_white_cmp": false, "flag_gen_compo": false, "flag_bg_expansion": false, "flag_multiblob_sod": false, "flag_human_background": false, "SO_length_scale": "auto", "flag_facemorphing": false, "facemorphing_race": "asian", "facemorphing_gender": "none", "output_size_w": 0, "output_size_h": 0, "selected_bg_ids": "person_torso_16693636557629", "theme": "", "theme_background": "", "theme_template": "auto", "theme_custom": "", "output_size_list": [{"output_w": 0, "output_h": 0}], "simple_bg_color_list": [[255, 255, 255], [242, 242, 244]], "endpoints": {}, "from_zip": false, "zip_path": null, "upload_id": "d1fa1046-1d83-4735-8d7f-bcf96223c0b1", "user_id": 80, "user_name": "ga00e", "portfolio_id": "1570", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiODAiLCJpYXQiOjE3MjEyODIzNzYsImV4cCI6MTcyMTMwMzk3NiwidXNlcm5hbWUiOiJnYTAwZSJ9.R7MvnGZf_tmZWn-fg-BgQCcRGTd5muYeB53P0ISbEQ4", "webhook_url": "", "use_credit_type": "", "query": "", "output_w": 0, "output_h": 0, "input_filename": "IMG_5109.jpeg", "origin_filename": "IMG_5109.jpeg", "used_bg_ids": ["cosmetics__17174089844297", "cosmetics__17174702245804", "cosmetics__17174706373387"], "bg_search_filter": {"category": "food", "sub_category": "raw", "angle": "perpen", "so_size": "small", "es_hits": 3, "returned": 3}, "total_pieces": 7, "category": "food", "sub_category": "raw", "angle": "perpen"}',
    upload_id: 'd1fa1046-1d83-4735-8d7f-bcf96223c0b1',
    id: '1721285784475_IMG_5109',
    url: 'display/1721285784475_IMG_5109',
    feedback_status: null,
    name: 'IMG_5109.jpeg',
    subject: '',
    feedback_updated: null,
    caption: '',
    pieces: [
      {
        id: 283792,
        path: '1721285784475_IMG_5109/shadow/person_torso_16693636557629_1721285976.0844045.jpg',
        bg_id: 'person_torso_16693636557629',
        config: {},
        pos_info: {
          bg_h: 744.0,
          bg_w: 1116.0,
          so_h: 621.0,
          so_w: 358.0,
          scale: 1.0,
          fitted: 0.0,
          so_lr_x: 381.0,
          so_lr_y: 121.0,
          offset_h: 0.0,
          offset_w: 0.0,
        },
        preferred: false,
        result_filename: '',
      },
    ],
    used_credit: -1.0,
  },

  {
    uploaded: '1721285784475_IMG_5109/input.jpg',
    portfolio_id: 1570,
    feedback_content: null,
    status: 'gen',
    user_id: 80,
    selected_piece: 283792,
    err_type: null,
    s3_urls: null,
    total_pieces: 7,
    generated: [],
    created: '2024-07-18T06:56:25.852107',
    deleted: false,
    effect: '-',
    config:
      '{"gen_shadow": true, "gen_face": false, "object_category": "food", "object_sub_category": "raw", "object_boundary": "none", "object_angle": "auto", "flag_generate": true, "flag_complex_cmp": true, "flag_simple_cmp": false, "flag_white_cmp": false, "flag_gen_compo": false, "flag_bg_expansion": false, "flag_multiblob_sod": false, "flag_human_background": false, "SO_length_scale": "auto", "flag_facemorphing": false, "facemorphing_race": "asian", "facemorphing_gender": "none", "output_size_w": 0, "output_size_h": 0, "selected_bg_ids": "person_torso_16693636557629", "theme": "", "theme_background": "", "theme_template": "auto", "theme_custom": "", "output_size_list": [{"output_w": 0, "output_h": 0}], "simple_bg_color_list": [[255, 255, 255], [242, 242, 244]], "endpoints": {}, "from_zip": false, "zip_path": null, "upload_id": "d1fa1046-1d83-4735-8d7f-bcf96223c0b1", "user_id": 80, "user_name": "ga00e", "portfolio_id": "1570", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiODAiLCJpYXQiOjE3MjEyODIzNzYsImV4cCI6MTcyMTMwMzk3NiwidXNlcm5hbWUiOiJnYTAwZSJ9.R7MvnGZf_tmZWn-fg-BgQCcRGTd5muYeB53P0ISbEQ4", "webhook_url": "", "use_credit_type": "", "query": "", "output_w": 0, "output_h": 0, "input_filename": "IMG_5109.jpeg", "origin_filename": "IMG_5109.jpeg", "used_bg_ids": ["cosmetics__17174089844297", "cosmetics__17174702245804", "cosmetics__17174706373387"], "bg_search_filter": {"category": "food", "sub_category": "raw", "angle": "perpen", "so_size": "small", "es_hits": 3, "returned": 3}, "total_pieces": 7, "category": "food", "sub_category": "raw", "angle": "perpen"}',
    upload_id: 'd1fa1046-1d83-4735-8d7f-bcf96223c0b1',
    id: '1721285784475_IMG_5109',
    url: 'display/1721285784475_IMG_5109',
    feedback_status: null,
    name: 'IMG_5109.jpeg',
    subject: '',
    feedback_updated: null,
    caption: '',
    pieces: [
      {
        id: 283792,
        path: '1721285784475_IMG_5109/shadow/person_torso_16693636557629_1721285976.0844045.jpg',
        bg_id: 'person_torso_16693636557629',
        config: {},
        pos_info: {
          bg_h: 744.0,
          bg_w: 1116.0,
          so_h: 621.0,
          so_w: 358.0,
          scale: 1.0,
          fitted: 0.0,
          so_lr_x: 381.0,
          so_lr_y: 121.0,
          offset_h: 0.0,
          offset_w: 0.0,
        },
        preferred: false,
        result_filename: '',
      },
      {
        id: 283793,
        path: '1721285784475_IMG_5109/shadow/compogen_result_0_1721285994.730168.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
    ],
    used_credit: -1.0,
  },

  {
    uploaded: '1721285784475_IMG_5109/input.jpg',
    portfolio_id: 1570,
    feedback_content: null,
    status: 'gen',
    user_id: 80,
    selected_piece: 283792,
    err_type: null,
    s3_urls: null,
    total_pieces: 7,
    generated: [],
    created: '2024-07-18T06:56:25.852107',
    deleted: false,
    effect: '-',
    config:
      '{"gen_shadow": true, "gen_face": false, "object_category": "food", "object_sub_category": "raw", "object_boundary": "none", "object_angle": "auto", "flag_generate": true, "flag_complex_cmp": true, "flag_simple_cmp": false, "flag_white_cmp": false, "flag_gen_compo": false, "flag_bg_expansion": false, "flag_multiblob_sod": false, "flag_human_background": false, "SO_length_scale": "auto", "flag_facemorphing": false, "facemorphing_race": "asian", "facemorphing_gender": "none", "output_size_w": 0, "output_size_h": 0, "selected_bg_ids": "person_torso_16693636557629", "theme": "", "theme_background": "", "theme_template": "auto", "theme_custom": "", "output_size_list": [{"output_w": 0, "output_h": 0}], "simple_bg_color_list": [[255, 255, 255], [242, 242, 244]], "endpoints": {}, "from_zip": false, "zip_path": null, "upload_id": "d1fa1046-1d83-4735-8d7f-bcf96223c0b1", "user_id": 80, "user_name": "ga00e", "portfolio_id": "1570", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiODAiLCJpYXQiOjE3MjEyODIzNzYsImV4cCI6MTcyMTMwMzk3NiwidXNlcm5hbWUiOiJnYTAwZSJ9.R7MvnGZf_tmZWn-fg-BgQCcRGTd5muYeB53P0ISbEQ4", "webhook_url": "", "use_credit_type": "", "query": "", "output_w": 0, "output_h": 0, "input_filename": "IMG_5109.jpeg", "origin_filename": "IMG_5109.jpeg", "used_bg_ids": ["cosmetics__17174089844297", "cosmetics__17174702245804", "cosmetics__17174706373387"], "bg_search_filter": {"category": "food", "sub_category": "raw", "angle": "perpen", "so_size": "small", "es_hits": 3, "returned": 3}, "total_pieces": 7, "category": "food", "sub_category": "raw", "angle": "perpen"}',
    upload_id: 'd1fa1046-1d83-4735-8d7f-bcf96223c0b1',
    id: '1721285784475_IMG_5109',
    url: 'display/1721285784475_IMG_5109',
    feedback_status: null,
    name: 'IMG_5109.jpeg',
    subject: '',
    feedback_updated: null,
    caption: '',
    pieces: [
      {
        id: 283792,
        path: '1721285784475_IMG_5109/shadow/person_torso_16693636557629_1721285976.0844045.jpg',
        bg_id: 'person_torso_16693636557629',
        config: {},
        pos_info: {
          bg_h: 744.0,
          bg_w: 1116.0,
          so_h: 621.0,
          so_w: 358.0,
          scale: 1.0,
          fitted: 0.0,
          so_lr_x: 381.0,
          so_lr_y: 121.0,
          offset_h: 0.0,
          offset_w: 0.0,
        },
        preferred: false,
        result_filename: '',
      },
      {
        id: 283793,
        path: '1721285784475_IMG_5109/shadow/compogen_result_0_1721285994.730168.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283794,
        path: '1721285784475_IMG_5109/shadow/compogen_result_2_1721285997.5927799.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283795,
        path: '1721285784475_IMG_5109/shadow/compogen_result_1_1721286002.3955753.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
    ],
    used_credit: -1.0,
  },
  {
    uploaded: '1721285784475_IMG_5109/input.jpg',
    portfolio_id: 1570,
    feedback_content: null,
    status: 'gen',
    user_id: 80,
    selected_piece: 283792,
    err_type: null,
    s3_urls: null,
    total_pieces: 7,
    generated: [],
    created: '2024-07-18T06:56:25.852107',
    deleted: false,
    effect: '-',
    config:
      '{"gen_shadow": true, "gen_face": false, "object_category": "food", "object_sub_category": "raw", "object_boundary": "none", "object_angle": "auto", "flag_generate": true, "flag_complex_cmp": true, "flag_simple_cmp": false, "flag_white_cmp": false, "flag_gen_compo": false, "flag_bg_expansion": false, "flag_multiblob_sod": false, "flag_human_background": false, "SO_length_scale": "auto", "flag_facemorphing": false, "facemorphing_race": "asian", "facemorphing_gender": "none", "output_size_w": 0, "output_size_h": 0, "selected_bg_ids": "person_torso_16693636557629", "theme": "", "theme_background": "", "theme_template": "auto", "theme_custom": "", "output_size_list": [{"output_w": 0, "output_h": 0}], "simple_bg_color_list": [[255, 255, 255], [242, 242, 244]], "endpoints": {}, "from_zip": false, "zip_path": null, "upload_id": "d1fa1046-1d83-4735-8d7f-bcf96223c0b1", "user_id": 80, "user_name": "ga00e", "portfolio_id": "1570", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiODAiLCJpYXQiOjE3MjEyODIzNzYsImV4cCI6MTcyMTMwMzk3NiwidXNlcm5hbWUiOiJnYTAwZSJ9.R7MvnGZf_tmZWn-fg-BgQCcRGTd5muYeB53P0ISbEQ4", "webhook_url": "", "use_credit_type": "", "query": "", "output_w": 0, "output_h": 0, "input_filename": "IMG_5109.jpeg", "origin_filename": "IMG_5109.jpeg", "used_bg_ids": ["cosmetics__17174089844297", "cosmetics__17174702245804", "cosmetics__17174706373387"], "bg_search_filter": {"category": "food", "sub_category": "raw", "angle": "perpen", "so_size": "small", "es_hits": 3, "returned": 3}, "total_pieces": 7, "category": "food", "sub_category": "raw", "angle": "perpen"}',
    upload_id: 'd1fa1046-1d83-4735-8d7f-bcf96223c0b1',
    id: '1721285784475_IMG_5109',
    url: 'display/1721285784475_IMG_5109',
    feedback_status: null,
    name: 'IMG_5109.jpeg',
    subject: '',
    feedback_updated: null,
    caption: '',
    pieces: [
      {
        id: 283792,
        path: '1721285784475_IMG_5109/shadow/person_torso_16693636557629_1721285976.0844045.jpg',
        bg_id: 'person_torso_16693636557629',
        config: {},
        pos_info: {
          bg_h: 744.0,
          bg_w: 1116.0,
          so_h: 621.0,
          so_w: 358.0,
          scale: 1.0,
          fitted: 0.0,
          so_lr_x: 381.0,
          so_lr_y: 121.0,
          offset_h: 0.0,
          offset_w: 0.0,
        },
        preferred: false,
        result_filename: '',
      },
      {
        id: 283793,
        path: '1721285784475_IMG_5109/shadow/compogen_result_0_1721285994.730168.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283794,
        path: '1721285784475_IMG_5109/shadow/compogen_result_2_1721285997.5927799.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283795,
        path: '1721285784475_IMG_5109/shadow/compogen_result_1_1721286002.3955753.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283796,
        path: '1721285784475_IMG_5109/shadow/imggen_result_0_1721286006.7281802.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
    ],
    used_credit: -1.0,
  },
  {
    uploaded: '1721285784475_IMG_5109/input.jpg',
    portfolio_id: 1570,
    feedback_content: null,
    status: 'gen',
    user_id: 80,
    selected_piece: 283792,
    err_type: null,
    s3_urls: null,
    total_pieces: 7,
    generated: [],
    created: '2024-07-18T06:56:25.852107',
    deleted: false,
    effect: '-',
    config:
      '{"gen_shadow": true, "gen_face": false, "object_category": "food", "object_sub_category": "raw", "object_boundary": "none", "object_angle": "auto", "flag_generate": true, "flag_complex_cmp": true, "flag_simple_cmp": false, "flag_white_cmp": false, "flag_gen_compo": false, "flag_bg_expansion": false, "flag_multiblob_sod": false, "flag_human_background": false, "SO_length_scale": "auto", "flag_facemorphing": false, "facemorphing_race": "asian", "facemorphing_gender": "none", "output_size_w": 0, "output_size_h": 0, "selected_bg_ids": "person_torso_16693636557629", "theme": "", "theme_background": "", "theme_template": "auto", "theme_custom": "", "output_size_list": [{"output_w": 0, "output_h": 0}], "simple_bg_color_list": [[255, 255, 255], [242, 242, 244]], "endpoints": {}, "from_zip": false, "zip_path": null, "upload_id": "d1fa1046-1d83-4735-8d7f-bcf96223c0b1", "user_id": 80, "user_name": "ga00e", "portfolio_id": "1570", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiODAiLCJpYXQiOjE3MjEyODIzNzYsImV4cCI6MTcyMTMwMzk3NiwidXNlcm5hbWUiOiJnYTAwZSJ9.R7MvnGZf_tmZWn-fg-BgQCcRGTd5muYeB53P0ISbEQ4", "webhook_url": "", "use_credit_type": "", "query": "", "output_w": 0, "output_h": 0, "input_filename": "IMG_5109.jpeg", "origin_filename": "IMG_5109.jpeg", "used_bg_ids": ["cosmetics__17174089844297", "cosmetics__17174702245804", "cosmetics__17174706373387"], "bg_search_filter": {"category": "food", "sub_category": "raw", "angle": "perpen", "so_size": "small", "es_hits": 3, "returned": 3}, "total_pieces": 7, "category": "food", "sub_category": "raw", "angle": "perpen"}',
    upload_id: 'd1fa1046-1d83-4735-8d7f-bcf96223c0b1',
    id: '1721285784475_IMG_5109',
    url: 'display/1721285784475_IMG_5109',
    feedback_status: null,
    name: 'IMG_5109.jpeg',
    subject: '',
    feedback_updated: null,
    caption: '',
    pieces: [
      {
        id: 283792,
        path: '1721285784475_IMG_5109/shadow/person_torso_16693636557629_1721285976.0844045.jpg',
        bg_id: 'person_torso_16693636557629',
        config: {},
        pos_info: {
          bg_h: 744.0,
          bg_w: 1116.0,
          so_h: 621.0,
          so_w: 358.0,
          scale: 1.0,
          fitted: 0.0,
          so_lr_x: 381.0,
          so_lr_y: 121.0,
          offset_h: 0.0,
          offset_w: 0.0,
        },
        preferred: false,
        result_filename: '',
      },
      {
        id: 283793,
        path: '1721285784475_IMG_5109/shadow/compogen_result_0_1721285994.730168.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283794,
        path: '1721285784475_IMG_5109/shadow/compogen_result_2_1721285997.5927799.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283795,
        path: '1721285784475_IMG_5109/shadow/compogen_result_1_1721286002.3955753.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283796,
        path: '1721285784475_IMG_5109/shadow/imggen_result_0_1721286006.7281802.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283797,
        path: '1721285784475_IMG_5109/shadow/imggen_result_1_1721286007.4775498.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
    ],
    used_credit: -1.0,
  },

  {
    uploaded: '1721285784475_IMG_5109/input.jpg',
    portfolio_id: 1570,
    feedback_content: null,
    status: 'done',
    user_id: 80,
    selected_piece: 283792,
    err_type: null,
    s3_urls: null,
    total_pieces: 7,
    generated: [],
    created: '2024-07-18T06:56:25.852107',
    deleted: false,
    effect: '-',
    config:
      '{"gen_shadow": true, "gen_face": false, "object_category": "food", "object_sub_category": "raw", "object_boundary": "none", "object_angle": "auto", "flag_generate": true, "flag_complex_cmp": true, "flag_simple_cmp": false, "flag_white_cmp": false, "flag_gen_compo": false, "flag_bg_expansion": false, "flag_multiblob_sod": false, "flag_human_background": false, "SO_length_scale": "auto", "flag_facemorphing": false, "facemorphing_race": "asian", "facemorphing_gender": "none", "output_size_w": 0, "output_size_h": 0, "selected_bg_ids": "person_torso_16693636557629", "theme": "", "theme_background": "", "theme_template": "auto", "theme_custom": "", "output_size_list": [{"output_w": 0, "output_h": 0}], "simple_bg_color_list": [[255, 255, 255], [242, 242, 244]], "endpoints": {}, "from_zip": false, "zip_path": null, "upload_id": "d1fa1046-1d83-4735-8d7f-bcf96223c0b1", "user_id": 80, "user_name": "ga00e", "portfolio_id": "1570", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiODAiLCJpYXQiOjE3MjEyODIzNzYsImV4cCI6MTcyMTMwMzk3NiwidXNlcm5hbWUiOiJnYTAwZSJ9.R7MvnGZf_tmZWn-fg-BgQCcRGTd5muYeB53P0ISbEQ4", "webhook_url": "", "use_credit_type": "", "query": "", "output_w": 0, "output_h": 0, "input_filename": "IMG_5109.jpeg", "origin_filename": "IMG_5109.jpeg", "used_bg_ids": ["cosmetics__17174089844297", "cosmetics__17174702245804", "cosmetics__17174706373387"], "bg_search_filter": {"category": "food", "sub_category": "raw", "angle": "perpen", "so_size": "small", "es_hits": 3, "returned": 3}, "total_pieces": 7, "category": "food", "sub_category": "raw", "angle": "perpen", "first_gen_total_pieces": 7}',
    upload_id: 'd1fa1046-1d83-4735-8d7f-bcf96223c0b1',
    id: '1721285784475_IMG_5109',
    url: 'display/1721285784475_IMG_5109',
    feedback_status: null,
    name: 'IMG_5109.jpeg',
    subject: '',
    feedback_updated: null,
    caption: '',
    pieces: [
      {
        id: 283792,
        path: '1721285784475_IMG_5109/shadow/person_torso_16693636557629_1721285976.0844045.jpg',
        bg_id: 'person_torso_16693636557629',
        config: {},
        pos_info: {
          bg_h: 744.0,
          bg_w: 1116.0,
          so_h: 621.0,
          so_w: 358.0,
          scale: 1.0,
          fitted: 0.0,
          so_lr_x: 381.0,
          so_lr_y: 121.0,
          offset_h: 0.0,
          offset_w: 0.0,
        },
        preferred: false,
        result_filename: '',
      },
      {
        id: 283793,
        path: '1721285784475_IMG_5109/shadow/compogen_result_0_1721285994.730168.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283794,
        path: '1721285784475_IMG_5109/shadow/compogen_result_2_1721285997.5927799.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283795,
        path: '1721285784475_IMG_5109/shadow/compogen_result_1_1721286002.3955753.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283796,
        path: '1721285784475_IMG_5109/shadow/imggen_result_0_1721286006.7281802.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283797,
        path: '1721285784475_IMG_5109/shadow/imggen_result_1_1721286007.4775498.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
      {
        id: 283798,
        path: '1721285784475_IMG_5109/shadow/imggen_result_2_1721286008.1952207.jpg',
        bg_id: null,
        config: {},
        pos_info: null,
        preferred: false,
        result_filename: '',
      },
    ],
    used_credit: -1.0,
  },
]

// @@@@@@ ArtworkRowImageSwiper 동작, ArtworkControl 표시 체크용으로 사용
export function SampleArtworkRow({ showDialog }) {
  const theme = useTheme()

  const [artwork, setArtwork] = useState(sample[0])
  const [pieceChecked, setPieceChecked] = useState([])
  const [selectedPiece, setSelectedPiece] = useState([])

  const [pieceImageSize, setPieceImageSize] = useState({})

  const [canMakeGIF, setCanMakeGIF] = useState(false)
  const [openGIFDialog, setOpenGIFDialog] = useState(false)
  const [canMakeGIFPieces, setCanMakeGIFPieces] = useState([])
  const [intervalId, setIntervalId] = useState()

  const [goToPiece, setGoToPiece] = useState()

  const [currentPiece, setCurrentPiece] = useState()

  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isFunFamePage = portfolioType === config.PORTFOLIO_TYPE_FAME
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isFacePage = portfolioType === config.PORTFOLIO_TYPE_FACE
  const onlyOneOutput = isRemoveBgPage || isFacePage

  const renderErrorRetryButton =
    !(isFunFamePage || isBundlePage) &&
    config.ARTWORK_ERROR_STATUS.includes(artwork.status) &&
    artwork?.err_type?.toString() !== '10001'

  const { t } = useTranslation()

  const artworkDone = artwork.status === config.ARTWORK_DONE_STATUS
  const artworkError = config.ARTWORK_ERROR_STATUS.includes(artwork.status)

  const refreshArtwork = () => {}
  const refreshArtworks = () => {
    console.log('refresh all')
  }

  useEffect(() => {
    if (config.ARTWORK_IN_PROGRESS_STATUS.includes(artwork.status) && !intervalId) {
      subscribeStatus()
    } else if (config.ARTWORK_DONE_STATUS === artwork.status?.toLowerCase() && intervalId) {
      unsubscribeStatus()
    } else if (config.ARTWORK_ERROR_STATUS.includes(artwork.status?.toLowerCase()) && intervalId) {
      unsubscribeStatus()
    }
  }, [artwork.id, artwork.status])

  const idx = 999

  let sampleIdx = 0
  const subscribeStatus = () => {
    const x = setInterval(() => {
      sampleIdx += 1
      console.log(sampleIdx)

      setArtwork(sample[sampleIdx])

      // apis.portfolio
      //   .getArtwork(artwork.portfolio_id, artwork.id)
      //   .then(response => {
      //     const a = response.data
      //     // console.log(a)
      //     a.pieces = sortPieces(a)

      //     setArtwork(a)

      //     if (config.ARTWORK_DONE_STATUS === a.status?.toLowerCase()) {
      //       notifyArtworkDone(a.name)

      //       if (config.ARTWORK_MODPIECE_ERROR_STATUS === a.feedback_status) {
      //         setModPieceError(true)
      //       }
      //     } else if (config.ARTWORK_ERROR_STATUS.includes(a.status?.toLowerCase())) {
      //       notify(`오류 - ${a.name}`)
      //       refreshArtworks()
      //     }
      //   })
      //   .catch(e => {
      //     console.log(e)
      //   })
    }, 2500)
    setIntervalId(x)
  }

  const unsubscribeStatus = () => {
    clearInterval(intervalId)
    setIntervalId(null)
  }

  return (
    <CenterAlignBox
      sx={{
        py: { lg: '6rem', xs: '1.5rem' },
        borderBottom: '0.05rem solid',
        borderColor: theme.palette.common.black,
        backgroundColor: pieceChecked.some(v => v)
          ? theme => theme.palette.draph.lighterblue
          : theme => theme.palette.common.white,
      }}
    >
      {/* 데스크탑용 레이아웃 - v2 */}
      <Desktop>
        <Stack direction="row" sx={{ width: DESKTOP_CONTENT_WIDTH }}>
          {/* 1) 체크박스 */}
          <Box sx={{ width: '3.8rem' }}>
            {(artworkDone || artworkError) && (
              <Checkbox
                sx={{ pt: 0 }}
                // checked={checked[idx] ?? false}
                // onChange={handleCheck}
              ></Checkbox>
            )}
          </Box>

          {/* 2) 생성 결과 이미지 스와이퍼 */}
          <CenterAlignBox sx={{ width: '51.2rem' }}>
            <ArtworkRowImageSwiper
              artwork={artwork}
              setArtwork={setArtwork}
              artworkIdx={idx}
              checked={pieceChecked}
              setChecked={setPieceChecked}
              hideThumbSwiper={onlyOneOutput}
              setCurrentPiece={setCurrentPiece}
              canMakeGIF={canMakeGIF} // 현재 선택된(크게 보이고 있는) 피스가 gif 생성 가능한지
              goToPiece={goToPiece}
              setGoToPiece={setGoToPiece}
              refreshArtworks={refreshArtworks}
              showDialog={showDialog}
              pieceImageSize={pieceImageSize}
              setPieceImageSize={setPieceImageSize}
            />
          </CenterAlignBox>

          {/* 3) 설정 표시 및 기능 버튼 */}
          <CenterAlignBox sx={{ px: '2.4rem' }}>
            {artworkError ? (
              <ArtworkError artwork={artwork} errorRetry={() => {}} />
            ) : (
              <ArtworkControl
                artwork={artwork}
                setArtwork={setArtwork}
                // redoAnimationStart={redoAnimationStart}
                currentPiece={currentPiece}
                selectedPiece={selectedPiece}
                refreshArtworks={refreshArtworks}
                refreshArtwork={refreshArtwork}
                processImages={processImages}
                canMakeGIFPieces={canMakeGIFPieces}
                handleOpenGIFDialog={() => {
                  setOpenGIFDialog(true)
                }}
                pieceImageSize={pieceImageSize}
              />
            )}
          </CenterAlignBox>
        </Stack>
      </Desktop>
    </CenterAlignBox>
  )
}
