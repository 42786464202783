import { atom, RecoilRoot, useRecoilState } from 'recoil'
import { RecoilDebugButton } from '../../components'
import { testAtom } from './TestPage'
import { useNavigate } from 'react-router-dom'
import C from './C.js'

export default function TestPage2() {
  const navigate = useNavigate()
  const [count, setCount] = useRecoilState(testAtom)

  return (
    <>
      <h1>Test Page22222~~ {count}</h1>

      <RecoilDebugButton />

      <hr />
      <h5>Recoli Root here</h5>
      <RecoilRoot>
        <C />
      </RecoilRoot>
      <hr />

      <button
        onClick={() => {
          navigate('/test')
        }}
      >
        navigate back
      </button>
    </>
  )
}
