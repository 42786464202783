import * as config from './test_config'

const FILE_PATH = '/testcase'

async function srcToFile(src, fileName, mimeType) {
  return fetch(src)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new File([buf], fileName)
      // return new File([buf], fileName, { type: mimeType })
    })
}

export const getImagePromises = (includePath = '') => {
  // const files = images.map(path => {
  //   const ext = path.split('.').at(-1)
  //   const mimeType = `image/${ext}`
  //   return `${path}zz`
  // })

  const promises = []

  let targetImages = config.images

  if (includePath.length > 0) {
    targetImages = targetImages.filter(path => path.includes(includePath))
  }

  console.log('업로드 대상', targetImages)

  targetImages.forEach(path => {
    const ext = path.split('.').at(-1)
    const name = path.split('/').at(-1).replace(`.${ext}`, '')
    const mimeType = `image/${ext}`

    promises.push(
      srcToFile(path, path.split('/').at(-1), mimeType).then(result => {
        // console.log(result)
        // console.log(config[name])

        // const downloadLink = document.createElement('a')
        // downloadLink.href = URL.createObjectURL(result)
        // downloadLink.download = `test.${ext}`
        // downloadLink.click()
        return { file: result, config: config[name], orgPath: path }
      })
    )
  })

  // Promise.all(promises).then(results => {
  //   console.log(results)
  // })

  return promises
}
