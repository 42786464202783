import { useRecoilState } from 'recoil'
import { allowBrowserNotiDialogAtom } from 'atoms'

export default function useBrowserNotificationDialog() {
  const [dialog, setDialog] = useRecoilState(allowBrowserNotiDialogAtom)

  const showBrowserNotificationDialog = props => {
    setDialog({ ...dialog, open: true, ...props })
  }

  return {
    dialog,
    setDialog,
    showBrowserNotificationDialog,
  }
}
