import { Button, TextField, styled } from '@mui/material'
import { apis } from 'apis'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import Moveable from 'react-moveable'
import Selecto from 'react-selecto'

export default function TestPage12() {
  const targetRef = useRef()

  return (
    <>
      {/* <div className="container">
        <svg
          style={{
            border: '1px solid black',
            width: '500px',
            height: '500px',
          }}
        >
          <g ref={targetRef}>
            <circle fill="red" cx="100" cy="100" r={10} stroke="black" strokeWidth={1} />
            <circle fill="skyblue" cx="100" cy="400" r="10" stroke="black" strokeWidth={1} />
          </g>
        </svg>

        <Moveable
          target={targetRef}
          draggable={true}
          rotatable={false}
          scalable={true}
          onRender={e => {
            e.target.style.cssText += e.cssText
          }}
        />
      </div> */}
      <Test />
    </>
  )
}

function Test() {
  // const [credit, setCredit] = useState(0)
  // const change = e => {
  //   setCredit(e.target.value)
  // }

  // const click = () => {
  //   const exp = moment().add(1, 'M').local().format('YYYY-MM-DDT23:59:59')

  //   const expUtc = moment.utc(new Date(exp)).format('YYYY-MM-DDTHH:mm:ss')

  //   apis.admin
  //     .addCreditToUser({
  //       target_user_id: 37,
  //       credit_amount: credit,
  //       note: '어드민 지급',
  //       expires: expUtc,
  //     })
  //     .then(() => {
  //       enqueueSnackbar('지급되었습니다', { variant: 'success' })
  //     })
  //     .catch(() => {
  //       enqueueSnackbar('오류', { variant: 'error' })
  //     })
  // }

  const [targets, setTargets] = useState([])
  const [markerAPosInfo, setMarkerAPosInfo] = useState({ x: 100, y: 100 })
  const [markerBPosInfo, setMarkerBPosInfo] = useState({ x: 100, y: 400 })

  const moveableRef = useRef(null)
  const selectoRef = useRef(null)
  const targetRef = useRef(null)
  const test = useRef(null)

  useEffect(() => {
    console.log(markerAPosInfo)
  })

  return (
    <>
      <svg
        id="svg"
        style={{
          border: '1px solid black',
          width: '500px',
          height: '500px',
        }}
      >
        <circle
          className="selecto-area"
          fill="red"
          cx="100"
          cy="100"
          r={10}
          stroke="black"
          strokeWidth={1}
        />
        <circle
          className="selecto-area"
          fill="skyblue"
          cx="100"
          cy="400"
          r="10"
          stroke="black"
          strokeWidth={1}
        />
        {/* <g ref={targetRef}>
          <circle
            opacity={1}
            fill="red"
            cx={markerAPosInfo.x}
            cy={markerAPosInfo.y}
            r={10}
            stroke="black"
            strokeWidth={1}
          />
          <circle
            opacity={1}
            fill="skyblue"
            cx={markerBPosInfo.x}
            cy={markerBPosInfo.y}
            r={10}
            stroke="black"
            strokeWidth={1}
          />
        </g>{' '} */}
      </svg>

      <Moveable
        ref={moveableRef}
        draggable={true}
        target={targets}
        origin={false}
        onClickGroup={e => {
          selectoRef.current.clickTarget(e.inputEvent, e.inputTarget)
        }}
        onDrag={e => {
          e.target.style.transform = e.transform
        }}
        onDragGroup={e => {
          e.events.forEach(ev => {
            ev.target.style.transform = ev.transform
          })
        }}
      />
      <Selecto
        ref={selectoRef}
        dragContainer={'#svg'}
        selectableTargets={['.selecto-area']}
        hitRate={0}
        selectByClick={true}
        boundContainer={'#svg'}
        selectFromInside={false}
        toggleContinueSelect={['shift']}
        ratio={0}
        onDragStart={e => {
          const moveable = moveableRef.current
          const target = e.inputEvent.target
          if (
            moveable.isMoveableElement(target) ||
            targets.some(t => t === target || t.contains(target))
          ) {
            e.stop()
          }
        }}
        onSelectEnd={e => {
          const moveable = moveableRef.current
          if (e.isDragStart) {
            e.inputEvent.preventDefault()

            moveable.waitToChangeTarget().then(() => {
              moveable.dragStart(e.inputEvent)
            })
          }
          setTargets(e.selected)
        }}
      />

      {/* <TextField value={credit} onChange={change}></TextField>
      <Button onClick={click}>보내기</Button> */}
    </>
  )
}
