import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Box,
  DialogActions,
  Stack,
  SwipeableDrawer,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { apis } from 'apis'
import { CenterAlignStack, CenterAlignBox } from 'components/CenterAlignContainer'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { FadeLoader as Loader } from 'react-spinners'
import { DialogButton } from 'components/DialogButtons'
import {
  SearchInput,
  CustomPagination,
  UploadImageThumb,
  BadExampleWarning,
  processInGroups,
  CommerceProductUpload,
  MobileSwipeableDrawer,
} from 'components'

import { LoadingButton } from '@mui/lab'

import {
  BackButton,
  BackIcon,
  CommerceUploadIcon,
  MobileArtworkGrayCloseIcon,
  SearchIcon,
  SelectedIcon,
  UndoIcon,
} from 'theme/icon'
import { useRecoilValue } from 'recoil'
import { portfolioConfigAtom } from 'atoms'
import { iOS, imageToFile } from 'utils/common'
import Register from 'pages/Register'
import Login from 'pages/Login'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { CommerceProductUploadV2 } from 'components/CommerceProductUpload'

const CAFE24_APP_CLIENT_ID = '9W9fH80oXi7KffBPN6d4eM'
const CAFE24_REDIRECT_URI = `${location.origin}/cafe24`
const CAFE24_AUTH_SCOPE = `mall.read_application,mall.write_application,mall.read_product,mall.write_product`

export function Cafe24() {
  const navigate = useNavigate()
  // https://c280-218-144-89-1.ngrok-free.app/cafe24_redirect?lang=ko_KR&mall_id=ga00e&nation=KR&shop_no=1&timestamp=1716425270&user_id=ga00e&user_name=%EB%8C%80%ED%91%9C%20%EA%B4%80%EB%A6%AC%EC%9E%90&user_type=P&hmac=V2yTKQSFM3NRagek74NqgACjZeE2OBcWN5yZ1jGHlM0%3D
  // http://localhost:3000/cafe24_redirect?lang=ko_KR&mall_id=ga00e&nation=KR&shop_no=1&timestamp=1718157609&user_id=ga00e&user_name=%EB%8C%80%ED%91%9C%20%EA%B4%80%EB%A6%AC%EC%9E%90&user_type=P&hmac=V2yTKQSFM3NRagek74NqgACjZeE2OBcWN5yZ1jGHlM0%3D

  const requestCafe24OAuth = mallId => {
    const state = mallId
    const params = `response_type=code&client_id=${CAFE24_APP_CLIENT_ID}&state=${state}&redirect_uri=${CAFE24_REDIRECT_URI}&scope=${CAFE24_AUTH_SCOPE}`
    const authPath = `https://${mallId}.cafe24api.com/api/v2/oauth/authorize?${params}`

    // const response = await axios.get(authPath, { observe: 'response' })
    // console.log(response)

    location.href = authPath
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const mallId = queryParams.get('mall_id')

    const timestamp = queryParams.get('timestamp')
    const now = Date.now() / 1000

    const diffHour = (now - parseInt(timestamp)) / 3600

    if (Math.abs(diffHour) > 2) {
      alert('잘못된 요청입니다')
      navigate('/home')
      return
    }

    const hmac = queryParams.get('hmac')
    apis.cafe24Commerce
      .authRequest({ hmac, query_string: location.search.replace('?', '') })
      .then(response => {
        if (response.data.ok) {
          requestCafe24OAuth(mallId)
        } else {
          alert('잘못된 요청입니다')
          navigate('/home')
        }
      })
  }, [])

  return (
    <>
      <CenterAlignStack sx={{ width: '100%', height: '100vh' }}>
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme => theme.palette.common.black,
            height: '8rem',
            width: '100%',
          }}
        >
          <img src="/static/images/logo/logo_h_white.png" style={{ height: '4.9rem' }} />
        </Stack>
        <CenterAlignStack sx={{ py: '5rem' }} direction="row" spacing={'1.6rem'}>
          <img src="/static/images/logo/logo_h_black.png" style={{ height: '2.5rem' }} />

          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.07994 7.41573L0.210379 0H2.88219L7.53156 5.9176L12.223 0H14.7896L8.89902 7.35955L15 15H12.3703L7.48948 8.85768L2.58766 15H0L6.07994 7.41573Z"
              fill="#303030"
            />
          </svg>

          <img src="/static/images/etc/cafe24_logo_color.png" style={{ height: '1.5rem' }} />
        </CenterAlignStack>
      </CenterAlignStack>
    </>
  )
}

export function Cafe24ProductButton({ sx, text, type = 'get', loading = false, ...props }) {
  const updateButtonStyle = {
    fontSize: { lg: '1.7rem', xs: '1.2rem' },
    fontWeight: 600,
    width: { lg: '19.4rem', xs: '13.2rem' },
    minWidth: { lg: '19.4rem', xs: '13.2rem' },
    p: 0,
    '& img': {
      height: { lg: '10px', xs: '12px' },
    },
  }

  return (
    <LoadingButton
      variant="contained"
      sx={{
        width: { lg: '22rem', xs: '14rem' },
        // height: { lg: '6rem', xs: '4.2rem' },
        fontSize: { lg: '2.2rem', xs: '1.5rem' },
        fontWeight: 800,
        backgroundColor: theme => theme.palette.cafe24,
        '&:hover': {
          // backgroundColor: theme => theme.palette.common.black,
        },
        '& img': {
          height: { lg: '14px', xs: '12px' },
        },

        ...(type === 'update' ? updateButtonStyle : {}),
        ...sx,
      }}
      loading={loading}
      {...props}
    >
      {!loading && <img src="/static/images/etc/cafe24_logo_white.png" />}&nbsp; {text}
    </LoadingButton>
  )
}

export function Cafe24ProductListDialog({
  open,
  setOpen,
  uploadImage,
  uploading,
  setUploading,
  sx,
  ...props
}) {
  const handleUpload = async selected => {
    if (!uploading) {
      setUploading(true)

      const files = []

      const res = await apis.cafe24Commerce.getImage({
        images: selected.map(p => {
          return {
            product_no: p.product_no,
            product_name: p.product_name,
            url: p.detail_image,
          }
        }),
      })
      const imageData = res.data

      imageData.forEach(d => {
        const img = `data:image/png;base64,${d.imgb}`

        // 주의 - 상품 이미지 업로드할 때 네이밍 규칙이 활용됨(아트워크id에 활용됨)
        const f = imageToFile(img, `cafe24_${d.product_no}.png`)
        files.push(f)
      })

      uploadImage(files, 'cafe24_upload')
      setOpen(false)
    }
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={(e, reason) => {
            if (reason && reason === 'backdropClick') return
            setOpen(false)
          }}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              width: { lg: '86rem' },
              maxWidth: 'unset',
              height: { lg: '72.8rem' },
              border: '3px solid',
              borderRadius: '2rem',
              borderColor: theme => theme.palette.cafe24,
              boxShadow: '4px 4px 20px 0px rgba(3, 199, 91, 0.2)',
            },

            ...sx,
          }}
          {...props}
        >
          <CommerceProductUpload
            open={open}
            setOpen={setOpen}
            uploading={uploading}
            setUploading={setUploading}
            getProductApi={apis.cafe24Commerce.getProductList}
            imageFieldKey={'detail_image'}
            productIdKey={'product_no'}
            productNameKey={'product_name'}
            uploadEventName={'cafe24_upload'}
            titleText={'스토어 상품 대표 이미지 목록 '}
            handleUpload={handleUpload}
          />
        </Dialog>
      </Desktop>
      <Mobile>
        <MobileSwipeableDrawer
          open={open}
          onOpen={() => {
            return open
          }}
          onClose={(e, reason) => {
            if (reason && reason === 'backdropClick') return
            setOpen(false)
          }}
        >
          <CommerceProductUpload
            open={open}
            setOpen={setOpen}
            uploadImage={uploadImage}
            uploading={uploading}
            setUploading={setUploading}
            getProductApi={apis.cafe24Commerce.getProductList}
            imageFieldKey={'detail_image'}
            productIdKey={'product_no'}
            productNameKey={'product_name'}
            uploadEventName={'cafe24_upload'}
            titleText={'스토어 상품 대표 이미지 목록 '}
            handleUpload={handleUpload}
          />
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}

export const Cafe24UploadDialog = () => {
  const theme = useTheme()

  const [open, setOpen] = useState(false)

  return (
    <>
      <CenterAlignStack>
        <CenterAlignBox
          sx={{
            width: '18.8rem',
            height: '9.2rem',
            alignItems: 'center',
            backgroundColor: '#E6ECFF',
            borderRadius: '10px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpen(true)
          }}
        >
          <CommerceUploadIcon color={theme.palette.cafe24} />
        </CenterAlignBox>

        <Box
          sx={{
            display: 'flex',
            mt: '1.2rem',
            gap: '0.51rem',
            alignItems: 'center',
          }}
        >
          <img
            src="/static/images/etc/mini_cafe24_icon.png"
            style={{ width: '2rem', height: '2rem' }}
          />
          <Typography sx={{ fontWeight: 600 }}>카페24 상품 불러오기</Typography>
        </Box>
      </CenterAlignStack>

      {open && (
        <>
          <Desktop>
            <Dialog
              open={open}
              onClose={(e, reason) => {
                if (reason && reason === 'backdropClick') return
                setOpen(false)
              }}
              sx={{
                '& .MuiPaper-root.MuiDialog-paper': {
                  width: { lg: '86rem' },
                  maxWidth: 'unset',
                  height: { lg: '72.8rem' },
                  border: '3px solid',
                  borderRadius: '2rem',
                  borderColor: theme => theme.palette.cafe24,
                  boxShadow: '4px 4px 20px 0px rgba(3, 199, 91, 0.2)',
                },
              }}
            >
              <CommerceProductUploadV2
                open={open}
                setOpen={setOpen}
                getProductApi={apis.cafe24Commerce.getProductList}
                imageFieldKey={'detail_image'}
                productIdKey={'product_no'}
                productNameKey={'product_name'}
                uploadEventName={'cafe24_upload'}
                titleText={'스토어 상품 대표 이미지 목록 '}
              />
            </Dialog>
          </Desktop>
          <Mobile>
            <SwipeableDrawer
              open={open}
              onOpen={() => {
                return open
              }}
              onClose={(e, reason) => {
                if (reason && reason === 'backdropClick') return
                setOpen(false)
              }}
              anchor="bottom"
              sx={{
                backgroundColor: 'white',
                '& .MuiDrawer-paper': {
                  // top: '20px',
                },
                '& .MuiDrawer-paperAnchorBottom': {
                  borderTopLeftRadius: '25px',
                  borderTopRightRadius: '25px',
                },
              }}
              disableDiscovery={iOS}
              disableSwipeToOpen={true}
            >
              <CommerceProductUploadV2
                open={open}
                setOpen={setOpen}
                getProductApi={apis.cafe24Commerce.getProductList}
                imageFieldKey={'detail_image'}
                productIdKey={'product_no'}
                productNameKey={'product_name'}
                uploadEventName={'cafe24_upload'}
                titleText={'스토어 상품 대표 이미지 목록 '}
              />
            </SwipeableDrawer>
          </Mobile>
        </>
      )}
    </>
  )
}
