import axios from 'axios'
import mailAxios from './configs/mail'

export const mailerAPI = {
  send: body => {
    return mailAxios.post('/send', body, {
      headers: {
        'Content-type': 'application/json',
      },
      timeout: 300000,
    })
  },

  verifyCode: body => {
    return mailAxios.post('/verify-code', body)
  },

  sendToken: body => {
    return mailAxios.post('/tokenSend', body)
  },

  inquiry: body => {
    return mailAxios.post('/inquiry', body, {})
  },

  sendMail: body => {
    return mailAxios.post('/sendMail', body, {
      headers: {
        'Content-type': 'application/json',
      },
    })
  },
}
