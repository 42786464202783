import {
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Divider,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { apis } from 'apis'
import { StyledDataGrid, columns as promotionColumns, MiniButton } from './AdminPromotion'
import { columns as userColumns } from './AdminCredit'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { EMAIL_REGEX, PHONE_REGEX, PWD_REGEX } from 'utils/common'
import * as yup from 'yup'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RegisterBox } from '../../components'
import { LoadingButton } from '@mui/lab'
import {
  CenterAlignStack,
  FormErrorMessage,
  TextInput,
  FormCheckbox,
  RegisterLabel,
  SelectInput,
  PhoneInput,
  FlexBasis,
} from 'components'

export default function AdminUser() {
  const [list, setList] = useState([])
  const [userIdInput, setUserIdInput] = useState('')
  const [targetUser, setTargetUser] = useState(null)
  const [grades, setGrades] = useState([])
  const [update, setUpdate] = useState({ grade: '-', master_username: '' })
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    apis.admin.getUserGrade().then(response => {
      setGrades(response.data)
    })
  }, [])
  useEffect(() => {
    setUpdate({ grade: '-', master_username: '' })
  }, [targetUser])

  const columns = [
    {
      field: 'id',
      width: 100,
      headerName: 'ID',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'username',
      width: 200,
      headerName: 'Username',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: params => (
        <Typography fontSize="2.2rem" fontWeight={700} color="red">
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'master_user_id',
      width: 150,
      headerName: 'master',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: params => (
        <>
          <CenterAlignStack spacing="1rem">
            {params.value && (
              <Typography sx={{ textAlign: 'center' }}>
                {params.row.master_username} ({params.value})
              </Typography>
            )}

            <TextField
              defaultValue="-"
              value={update.master_username}
              onChange={e => {
                setUpdate(u => ({ ...u, master_username: e.target.value }))
              }}
              label="지정 (username 입력)"
              InputLabelProps={{ shrink: true }}
              sx={{ width: '15rem', '& input': { padding: 0 } }}
            ></TextField>
          </CenterAlignStack>
        </>
      ),
    },
    {
      field: 'grade',
      width: 150,
      headerName: 'grade',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: params => (
        <>
          <CenterAlignStack spacing="1rem">
            <Typography>{params.value}</Typography>
            <TextField
              defaultValue={'-'}
              value={update.grade}
              onChange={e => {
                setUpdate(u => ({ ...u, grade: e.target.value }))
              }}
              select // tell TextField to render select
              label="유저 등급 변경"
              sx={{ width: '15rem', '& .MuiSelect-select': { p: 0 } }}
            >
              <MenuItem disabled value="-"></MenuItem>
              {grades.map(g => (
                <MenuItem key={g} value={g}>
                  {g}
                </MenuItem>
              ))}
            </TextField>
          </CenterAlignStack>
        </>
      ),
    },
    {
      field: '',
      width: 100,
      headerName: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: params => (
        <Button variant="outlined" onClick={updateUser}>
          수정 저장
        </Button>
      ),
    },
  ]

  const updateUser = () => {
    apis.admin
      .updateUser({
        id: targetUser.id,
        ...update,
      })
      .then(response => {
        apis.admin.getUserById(targetUser.id).then(response => {
          setTargetUser(response.data)
        })
      })
  }

  const getTargetUser = () => {
    if (userIdInput.length < 1) return
    apis.admin.getUserById(userIdInput).then(response => {
      setTargetUser(response.data)
    })
  }

  return (
    <div>
      <CenterAlignStack sx={{ width: '100%' }}>
        <Typography sx={{ fontWeight: 800, fontSize: '3rem' }}>유저 관리</Typography>

        <Stack
          sx={{
            my: '2rem',
            borderRadius: '1rem',
            border: '1px solid gray',
            p: '2rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <br />
          <Stack direction="row">
            <TextField
              InputLabelProps={{ shrink: true }}
              label="user id 또는 username"
              autoComplete="off"
              value={userIdInput}
              onChange={e => setUserIdInput(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  getTargetUser()
                }
              }}
              sx={{ '& .MuiInputBase-root': { p: 0 } }}
            ></TextField>
            <Button variant="contained" onClick={getTargetUser}>
              조회
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setUserIdInput('')
                setTargetUser(null)
              }}
            >
              리셋
            </Button>
          </Stack>

          <Box sx={{ minWidth: '40rem' }}>
            <StyledDataGrid
              sx={{
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: '10rem',
                },
                '& .MuiDataGrid-row': {
                  minHeight: '10rem !important',
                  maxHeight: '10rem !important',
                },
                '& .MuiDataGrid-cell': {
                  minHeight: '10rem !important',
                  maxHeight: '10rem !important',
                },
              }}
              disableColumnMenu
              rows={targetUser ? [targetUser] : []}
              columns={columns}
              autoHeight
              hideFooter
              onRowClick={v => {
                console.log(v)
              }}
            />
          </Box>
        </Stack>

        <div style={{ width: '100rem', marginBottom: '2rem' }}>
          <Divider flexItem />
        </div>

        <CreateUserForm />
      </CenterAlignStack>
    </div>
  )
}

const RegisterSchema = yup.object({
  username: yup
    .string()
    .required('아이디를 입력해주세요.')
    .matches(/^[a-zA-Z0-9]+$/, '영문 대소문자와 숫자만 사용할 수 있습니다.')
    .test('no-spaces', '공백을 사용할 수 없습니다.', value => !/\s/.test(value))
    .min(2, '아이디를 2글자 이상 입력해주세요.')
    .max(20, '아이디를 20글자 이내로 입력해주세요.'),
  email: yup
    .string()
    .matches(EMAIL_REGEX, '올바른 이메일 형식으로 입력해주세요.')
    // .email('올바른 이메일 형식으로 입력해주세요.')
    .required('이메일을 입력해주세요.'),
  password: yup
    .string()
    .matches(PWD_REGEX, '영문, 숫자, 특수문자를 이용해주세요.')
    .min(4, '비밀번호를 4글자 이상 입력해주세요.')
    .max(20, '비밀번호 20글자 이내로 입력해주세요.')
    .required('비밀번호를 입력해주세요.'),
  checkedPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
    .required(' '),
  company: yup.string().required('소속을 입력해주세요.'),
  // gender: yup.string().required('성별을 선택해주세요.'),
  phone: yup.string().matches(PHONE_REGEX, {
    message: '올바른 휴대폰 번호를 입력해주세요.',
    excludeEmptyString: true,
  }),
  website: yup.string(),
  grade: yup.string().required(''),
  masterUserName: yup.string(),
})

function CreateUserForm() {
  const [showPassword, setShowPassword] = useState({
    password: false,
    checkedPassword: false,
  })

  const [loading, setLoading] = useState(false)
  const [grades, setGrades] = useState([])
  const { enqueueSnackbar } = useSnackbar()

  const {
    formState: { isDirty, dirtyFields, errors, isSubmitting },
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    setFocus,
    register,
    clearErrors,
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      username: '',
      email: '',
      emailCodeAccess: '',
      password: '',
      checkedPassword: '',
      nickname: '',
      company: '',
      gender: '',
      phone: '',
      phone1: '010',
      phone2: '',
      phone3: '',
      website: '',
      grade: 'welcome',
      masterUserName: '',
    },
    resolver: yupResolver(RegisterSchema),
    mode: 'onSubmit',
  })

  useEffect(() => {
    apis.admin.getUserGrade().then(response => {
      setGrades(response.data)
    })
  }, [])

  const onSubmit = values => {
    console.log(values)
    const createValues = { ...values }

    delete createValues.checkedPassword
    delete createValues.phone1
    delete createValues.phone2
    delete createValues.phone3
    apis.admin
      .addUser(createValues)
      .then(response => {
        if (response.data.success) {
          enqueueSnackbar('완료', { variant: 'success' })
          reset()
        } else {
          alert(response.data.message)
        }
      })
      .catch(() => {
        enqueueSnackbar('오류', { variant: 'error' })
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <CenterAlignStack sx={{ width: '100%', py: '8rem' }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            유저생성
          </Typography>
          <Button
            variant="outlined"
            sx={{ height: '4rem', mb: 1.5 }}
            onClick={() => {
              reset()
            }}
          >
            모두 지우기
          </Button>

          <Stack sx={{ alignItems: 'flex' }}>
            <RegisterBox>
              <RegisterLabel required={true} label="유저등급" />
              <TextField
                control={control}
                name="grade"
                defaultValue={'welcome'}
                onChange={e => setValue('grade', e.target.value)}
                select // tell TextField to render select
                label="유저 등급 선택"
                sx={{ width: '30rem', '& .MuiSelect-select': { p: 0 } }}
              >
                {grades.map(g => (
                  <MenuItem key={g} value={g}>
                    {g}
                  </MenuItem>
                ))}
              </TextField>
            </RegisterBox>

            <RegisterBox>
              <RegisterLabel required={true} label="아이디" />
              <TextInput
                control={control}
                name="username"
                placeholder="아이디"
                onChange={e => {
                  setValue('username', e.target.value)
                }}
                error={!!errors.username}
              />
            </RegisterBox>
            <FormErrorMessage message={errors.username?.message} />

            <RegisterBox>
              <RegisterLabel required={true} label="이메일" />
              <TextInput
                control={control}
                name="email"
                placeholder="이메일"
                error={!!errors.email}
              />
            </RegisterBox>
            <FormErrorMessage message={errors.email?.message} />

            <RegisterBox>
              <RegisterLabel required={true} label="비밀번호" />
              <TextInput
                name="password"
                autoComplete="current-password"
                type={showPassword.password ? 'text' : 'password'}
                control={control}
                error={!!errors.password}
                englishMode={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setShowPassword(prev => ({
                            ...prev,
                            password: !prev.password,
                          }))
                        }
                      >
                        {showPassword.password ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="비밀번호"
              />
            </RegisterBox>
            <FormErrorMessage message={errors.password?.message} />

            <RegisterBox>
              <RegisterLabel required={true} label="비밀번호 확인" />
              <TextInput
                name="checkedPassword"
                autoComplete="current-password"
                type={showPassword.checkedPassword ? 'text' : 'password'}
                control={control}
                error={!!errors.checkedPassword}
                englishMode={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setShowPassword(prev => ({
                            ...prev,
                            checkedPassword: !prev.checkedPassword,
                          }))
                        }
                      >
                        {showPassword.checkedPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="비밀번호 확인"
              />
            </RegisterBox>
            <FormErrorMessage message={errors.checkedPassword?.message} />

            <RegisterBox>
              <RegisterLabel required={true} label="소속" />
              <TextInput control={control} name="company" error={!!errors.company} />
            </RegisterBox>
            <FormErrorMessage message={errors.company?.message} />

            <RegisterBox>
              <RegisterLabel required={false} label="마스터유저 아이디 (username)" />
              <TextInput control={control} name="masterUserName" error={!!errors.masterUserName} />
            </RegisterBox>
            <FormErrorMessage message={errors.masterUserName?.message} />

            <RegisterBox>
              <RegisterLabel required={false} label="닉네임" />
              <TextInput control={control} name="nickname" error={!!errors.nickname} />
            </RegisterBox>
            <FormErrorMessage message={errors.nickname?.message} />

            <RegisterBox>
              <RegisterLabel required={false} label="성별" />
              <SelectInput
                items={[
                  { value: 'F', text: '여성' },
                  { value: 'M', text: '남성' },
                ]}
                control={control}
                name="gender"
                error={!!errors.gender}
              />
            </RegisterBox>
            <FormErrorMessage message={errors.gender?.message} />

            <RegisterBox>
              <FlexBasis
                flexBasis={['46%', '54%']}
                sx={{ display: 'flex', justifyContent: ' center' }}
              >
                <RegisterLabel required={false} label="휴대폰 번호" />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PhoneInput
                    control={control}
                    name="phone1"
                    sx={{ width: '10rem' }}
                    inputProps={{ maxLength: 3 }}
                    error={!!errors.phone1}
                    placeholder="010"
                    disabled
                  />
                  <Typography px={1.4}>-</Typography>
                  <PhoneInput
                    control={control}
                    name="phone2"
                    inputProps={{ maxLength: 4 }}
                    error={!!errors.phone2}
                    onChange={e => {
                      setValue('phone2', e.target.value)
                      setValue('phone', watch('phone1') + e.target.value + watch('phone3'))
                    }}
                  />
                  <Typography px={1.4}>-</Typography>
                  <PhoneInput
                    control={control}
                    name="phone3"
                    inputProps={{ maxLength: 4 }}
                    error={!!errors.phone3}
                    onChange={e => {
                      setValue('phone3', e.target.value)
                      setValue('phone', watch('phone1') + watch('phone2') + e.target.value)
                    }}
                  />
                </Box>

                {/* <TextInput
                control={control}
                name="phone"
                placeholder="휴대폰 번호"
                inputProps={{ maxLength: 13 }}
                error={!!errors.phone}
              /> */}
              </FlexBasis>
            </RegisterBox>
            <FormErrorMessage message={errors.phone?.message} />
            <RegisterBox>
              <RegisterLabel required={false} label="웹사이트" />
              <TextInput
                control={control}
                name="website"
                error={!!errors.website}
                placeholder="소속 회사 혹은 개인 웹을 기재해주세요."
              />
            </RegisterBox>
            <FormErrorMessage message={errors.website?.message} />
          </Stack>

          <LoadingButton
            size="large"
            type="submit"
            loading={isSubmitting}
            variant="contained"
            sx={{
              width: '30rem',
              color: theme => theme.palette.common.white,
            }}
          >
            생성
          </LoadingButton>
        </CenterAlignStack>
      </form>
    </>
  )
}
