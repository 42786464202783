export default function MenuItem(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `${theme.palette.draph.lighterblue}`,
          },
          '&:hover': {
            backgroundColor: `${theme.palette.draph.lighterblue}`,
          },
          [theme.breakpoints.down('lg')]: {
            paddingLeft: '1.5rem',
            minHeight: '3rem',
          },
        },
      },
    },
  }
}
