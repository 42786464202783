import { configData } from 'config'
import {
  Box,
  Typography,
  Button,
  Stack,
  Select,
  MenuItem,
  Card,
  FormControl,
  SwipeableDrawer,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import {
  ScrollToTop,
  ArtworkList,
  ArtworkFilters,
  UploadWrapper,
  CenterAlignStack,
  CenterAlignBox,
  DragDropFullSize,
  EmptyArtworkList,
  PleaseLoginDialog,
  UploadImageDialog,
  DialogButton,
  MobileSwipeableDrawer,
  UploadHeader,
  ModelBgConfigComponent,
  GuideComponent,
  ModelRenerateDialog,
  MobileModelUploadOld,
} from 'components'
import { BeforeAfterArrow, SettingIcon } from 'theme/icon'
import { styled, useTheme } from '@mui/material/styles'

import { useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useRecoilState, useResetRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  portfolioDetailAtom,
  portfolioArtworkAtom,
  userAtom,
  portfolioConfigAtom,
  artworkViewConfigAtom,
  userLikedBgAtom,
  PORTFOLIO_CONFIG_DEFAULT,
  tutorialAtom,
  endpointAtom,
  artworkListsIsFoldedAtom,
  defaultPortfolioAtom,
  portfolioAtom,
  autoUploadAtom,
  dragDropOpenAtom,
  uploadDialogOpenAtom,
  portfolioTypeAtom,
  uploadFilesAtom,
  blockDragDropAtom,
  uploadDialogOpen2Atom,
} from 'atoms'
import { apis } from 'apis'
import { BarLoader as Loader, PuffLoader } from 'react-spinners'
import {
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
  portfolioUploadConfigSelector,
} from 'selector'
import useConfirm from 'hooks/useConfirm'
import _, { debounce } from 'lodash'
import axios from 'axios'
import { getAccessToken } from 'utils/cookie'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { Tutorial } from 'theme/Tutorial'
import { APPBAR_DESKTOP, APPBAR_MOBILE } from 'layouts/main/MainAppBar'
import MainFooter from 'layouts/main/MainFooter'
import { endpointCookieName } from 'components/portfolio/DevEndpointDialog'
import * as config from 'config'
import { useMotionValueEvent, useScroll, useTransform } from 'framer-motion'

import { PAGE_HEADER_HEIGHT, ScrollHandler } from './PortfolioDetail'
import { useTranslation } from 'react-i18next'
import { EmptyPageHeader, EmptyUploadHeader, PageHeaderLayout } from './PortfolioUpload'
import usePleaseLoginDialog from 'hooks/usePleaseLoginDialog'
import {
  triggerGA4UploadEvent,
  triggerGA4UploadEventManualConfig,
} from '../components/portfolio/Header'
import { modelBgRegenDialogAtom } from '../atoms'
import useBrowserNotificationDialog from 'hooks/useBrowserNotificationDialog'
import { usePortfolio } from 'hooks/usePortfolio'
import { getUserType } from 'utils/user'

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    // alignItems: 'center',
  },
}))

export default function ModelBg() {
  const tutorial = useRecoilValue(tutorialAtom)

  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const [dragDropOpen, setDragDropOpen] = useRecoilState(dragDropOpenAtom)
  const [blockDragDrop, setBlockDragDrop] = useRecoilState(blockDragDropAtom)

  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'modelbg', {})
    // ---------------------

    return () => {
      setBlockDragDrop(false)
    }
  }, [])

  const token = getAccessToken()
  if (!token)
    return (
      <RootStyle>
        <Mobile>
          <EmptyPageHeader />
        </Mobile>

        <Desktop>
          <EmptyUploadHeader videoSrc={config.GUIDE_VIDEOS.modelbg} />
        </Desktop>
        <EmptyArtworkList />
      </RootStyle>
    )

  return (
    <>
      <RootStyle
        style={{
          // overflow: tutorial.step === 15 ? 'hidden' : 'scroll',
          zIndex: tutorial.step === 17 ? '-1' : null,
        }}
        onDragEnter={e => {
          e.preventDefault()
          e.stopPropagation()

          if (!blockDragDrop) {
            setDragDropOpen(true)
          }
        }}
      >
        <>
          <UploadWrapper>
            {isMobile ? (
              <PageHeader />
            ) : (
              <UploadHeader
                configComponent={<ModelBgConfigComponent uploadButtonOnly={false} />}
                uploadButtonComponent={<ModelBgConfigComponent uploadButtonOnly={true} />}
                gudieComponent={<GuideComponent />}
                imageDelay={0.2}
                configDelay={0.3}
              />
            )}
          </UploadWrapper>
          <Mobile>
            <Guide />
          </Mobile>

          <ArtworkList upload={true} hideGroupHeader={true} />
        </>
      </RootStyle>
      <ScrollToTop />
      <ScrollHandler />
      {dragDropOpen && !blockDragDrop && <DragDropFullSize />}
      <ModelRenerateDialog />
    </>
  )
}

function PageHeader() {
  const { prepareUpload, makeUploadFormData, checkUserCredit, refreshArtworks } = usePortfolio()
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const [user, setUser] = useRecoilState(userAtom)
  const [viewConfig, setViewConfig] = useRecoilState(artworkViewConfigAtom)
  const { t } = useTranslation()
  const theme = useTheme()

  const [portfolioDetail, setPortfolioDetail] = useRecoilState(portfolioDetailAtom)
  const [isFolded, setIsFolded] = useRecoilState(artworkListsIsFoldedAtom)

  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)

  const resetPortfolioDetail = useResetRecoilState(portfolioDetailAtom)
  const resetViewConfig = useResetRecoilState(artworkViewConfigAtom)
  const resetPortfolioConfig = useResetRecoilState(portfolioConfigAtom)

  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const [uploading, setUploading] = useState(false)
  const [uploadButtonDisabled, setUploadButtonDiasbled] = useState(false)
  const [downloadedFilter, setDownloadedFilter] = useState('all')
  const [textFilter, setTextFilter] = useState('')
  const [soSize, setSOSize] = useState('auto')
  const [gender, setGender] = useState('female')
  const [model, setModel] = useState(['auto'])
  const [configDialogOpen, setConfigDialogOpen] = useRecoilState(uploadDialogOpen2Atom)

  const [endpoint, setEndpoint] = useRecoilState(endpointAtom)

  const isMobile = useMobileMediaQuery()

  const queryParams = new URLSearchParams(location.search)

  const { showBrowserNotificationDialog } = useBrowserNotificationDialog()

  useEffect(() => {
    if (uploadOpen) {
      // ----- GA4 event -----
      window.gtag('event', 'modelbg_begin', {})
      // ---------------------
    }
  }, [uploadOpen])

  const handleUpload = async files => {
    const uploadFiles = await prepareUpload(files, config.PORTFOLIO_TYPE_MODELBG)
    if (!uploadFiles) return

    setUploadButtonDiasbled(true)
    setUploading(true)
    setUploadOpen(false)
    setConfigDialogOpen(false)

    const modelBgConfig = {
      flag_human_background: true,
      // SO_length_scale: soSize,

      // 나머지 설정 오버라이드용
      flag_generate: false,
      flag_complex_cmp: false,
      flag_simple_cmp: false,
      flag_white_cmp: false,
      is_male: gender === 'male',
      model_name: model,
    }

    // ----- GA4 event -----
    triggerGA4UploadEventManualConfig(
      {
        count: uploadFiles.length,
        menu: gender === 'male' ? '남' : '여',
        method: model.sort().join(', '),
      },
      'modelbg_upload'
    )
    // ---------------------

    const formData = await makeUploadFormData(uploadFiles, modelBgConfig)

    console.log('--------- start uploading modelbg')

    await apis.appfront
      .upload(formData)
      .then(() => {
        checkUserCredit()
        setUploading(false)
        setUploadButtonDiasbled(false)
        refreshArtworks()

        if ('Notification' in window && Notification.permission === 'default') {
          showBrowserNotificationDialog()
        }
      })
      .catch(() => {
        setUploading(false)
        setUploadButtonDiasbled(false)
      })
  }

  return (
    <>
      <PageHeaderLayout
        headerProps={{
          sx: {
            opacity: isFolded.value ? 0 : 1,
            transition: 'all 0.2s ease',
          },
          zIndex: isFolded.value
            ? -1
            : tutorial.step === 14 || tutorial.step === '14_4' || tutorial.step === 0
            ? 'auto'
            : null,
        }}
        uploadButtonProps={{
          disabled: uploading || !isOwner || uploadButtonDisabled,
          onClick: () => {
            tutorial.mode && setTutorial(prev => ({ ...prev, step: '14_1' }))
            setUploadOpen(true)
          },
        }}
        uploading={uploading}
        uploadDialog={
          <>
            <MobileModelUploadOld
              mannquinUpload={false}
              gender={gender}
              setGender={setGender}
              model={model}
              setModel={setModel}
              handleUpload={handleUpload}
              uploadButtonTitle={t('button.modelbg_upload')}
              openAtom={uploadDialogOpen2Atom}
            />
            <UploadImageDialog
              resetImage={false}
              uploadImage={() => {
                setConfigDialogOpen(true)
                setUploadOpen(false)
              }}
              uploadButtonTitle={t('button.next')}
              notice={
                <CenterAlignStack
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: { lg: '1.4rem', xs: '1.2rem' },
                      fontWeight: 400,
                      color: '#595959',
                      lineHeight: 'normal',
                    },
                  }}
                >
                  <Desktop>
                    <Typography>{t('upload_dialog.notice_1')}</Typography>
                    <Typography sx={{}}>
                      {t('upload_dialog.modelbg_comment_1')}
                      {t('upload_dialog.modelbg_comment_2')}
                    </Typography>
                  </Desktop>
                  <Mobile>
                    <Typography>{t('upload_dialog.upload_notice_mobile_1')}</Typography>
                    <Typography>{t('upload_dialog.modelbg_comment_1')}</Typography>
                    <Typography>{t('upload_dialog.modelbg_comment_2')}</Typography>
                  </Mobile>
                </CenterAlignStack>
              }
              // example={<SelectProductBottomWidth soSize={soSize} setSOSize={setSOSize} />}
            />
          </>
        }
        artworkFilter={
          <ArtworkFilters
            selectValue={downloadedFilter}
            setSelectValue={setDownloadedFilter}
            textValue={textFilter}
            setTextValue={setTextFilter}
          />
        }
      />

      {/* <ModelBgRegenDialog /> */}
    </>
  )
}

const ExampleImage = () => (
  <>
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        '& img.before': {
          width: { lg: '40px', xs: '23px' },
        },
        '& img.after': {
          width: { lg: '180px', xs: '100px' },
        },
      }}
      spacing={{ lg: '1.5rem', xs: '0.5rem' }}
    >
      <img src="/static/images/modelbg_before.png" className="before" />
      <Desktop>
        <BeforeAfterArrow />
      </Desktop>
      <Mobile>
        <BeforeAfterArrow size="small" />
      </Mobile>
      <img src="/static/images/modelbg_after.png" className="after" />
    </Stack>
  </>
)

const ExampleText = ({ sx }) => {
  const { t } = useTranslation()
  return (
    <Card
      sx={{
        ml: { lg: '4rem !important', xs: '1.5rem' },
        px: { lg: '2.5rem', xs: '1.5rem' },
        '& .MuiTypography-root': {
          fontSize: { lg: '2rem', xs: '1rem' },
          fontWeight: 500,
          textAlign: { xs: 'center' },
        },
        borderRadius: '1rem',
        boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.2)',
        height: { lg: '10rem', xs: '4.4rem' },
        justifyContent: 'center',
        ...sx,
      }}
    >
      <Stack sx={{ height: '100%', justifyContent: 'center', alignItems: 'start' }}>
        <Typography>{t('modelbg.comment_1')}</Typography>
        <Typography sx={{ '& span': { fontWeight: 700 } }}>
          <span>{t('modelbg.comment_2_bold')}</span>
          {t('modelbg.comment_2')}
        </Typography>
        <Typography>{t('modelbg.comment_3')}</Typography>
      </Stack>
    </Card>
  )
}

const Guide = () => (
  <Stack
    direction="row"
    sx={{ alignItems: 'center', justifyContent: 'center', mt: '1rem', mb: '4rem' }}
  >
    <ExampleImage />
    <ExampleText />
  </Stack>
)

function SelectProductBottomWidth({ soSize, setSOSize, showWraning = true }) {
  const { t } = useTranslation()

  const soSizeList = [
    { id: 'auto', value: 'auto', text: t('modelbg.so_size.auto') },
    { id: 'tiny', value: 't', text: t('modelbg.so_size.t') },
    { id: 'small', value: 's', text: t('modelbg.so_size.s') },
    { id: 'medium', value: 'm', text: t('modelbg.so_size.m') },
    { id: 'large', value: 'l', text: t('modelbg.so_size.l') },
  ]

  return (
    <>
      <CenterAlignStack sx={{ pt: { lg: '2rem', xs: '0' } }}>
        <Desktop>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 500 }}>
            {t('modelbg.select_product_width_1')}
            {t('modelbg.select_product_width_2')}
          </Typography>
        </Desktop>
        <Mobile>
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
            {t('modelbg.select_product_width_1')}
          </Typography>
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
            {t('modelbg.select_product_width_2')}
          </Typography>
        </Mobile>

        <Stack
          direction={{ lg: 'row' }}
          sx={{ alignItems: 'center', py: '1.4rem' }}
          spacing={{ lg: '1rem', xs: '0.5rem' }}
        >
          <Typography sx={{ fontSize: { lg: '2rem', xs: '1.4rem' }, fontWeight: 600 }}>
            {t('modelbg.product_bottom_width')}
          </Typography>
          <FormControl>
            <Select
              value={soSize}
              onChange={e => {
                setSOSize(e.target.value)
              }}
              sx={{
                fontSize: '1.8rem',
                width: '14.8rem',
                height: '4rem',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '0.2rem',
                },
              }}
            >
              {soSizeList.map(item => (
                <MenuItem key={item.id} value={item.value} sx={{ fontSize: '1.8rem' }}>
                  {item.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {showWraning && (
            <Typography
              sx={{
                fontSize: { lg: '1.5rem', xs: '1.2rem' },
                fontWeight: 400,
                color: theme => theme.palette.common.red,
              }}
            >
              {t('modelbg.product_bottom_width_comment')}
            </Typography>
          )}
        </Stack>
      </CenterAlignStack>
    </>
  )
}

export function ModelBgRegenDialog() {
  const user = useRecoilValue(userAtom)
  const [dialog, setDialog] = useRecoilState(modelBgRegenDialogAtom)

  const { artwork, setArtwork, refreshArtwork, refreshRegenCount } = dialog

  const resetDialog = useResetRecoilState(modelBgRegenDialogAtom)

  const { t } = useTranslation()

  const handleClose = () => {
    resetDialog()
  }

  useEffect(() => {
    if (!artwork || !artwork.config) return
    const config = JSON.parse(artwork.config)
  }, [artwork])

  useEffect(() => {
    if (dialog.open) {
      // ----- GA4 event -----
      window.gtag('event', 'modelbg_regenerate_begin', {})
      // ---------------------
    }
  }, [dialog.open])

  const handleRegenerate = () => {
    const feedback = 'regenerate_auto'
    const artworkConfig = JSON.parse(artwork.config)

    // ----- GA4 event -----
    window.gtag('event', 'modelbg_regenerate', {})
    // ---------------------

    setArtwork({ ...artwork, status: feedback })

    const formData = new FormData()
    formData.append('user_id', user.id)
    formData.append('username', user.username)
    formData.append('user_type', getUserType(user))
    formData.append('artwork_id', artwork.id)
    formData.append('portfolio_id', artwork.portfolio_id)
    formData.append('retry_type', 'regenerate')

    const genOptions = {
      ...artworkConfig,

      flag_human_background: true,

      // 변경하여 적용할 config
    }
    formData.append('gen_options', JSON.stringify(genOptions))

    apis.portfolio
      .updateArtworkFeedback(artwork.portfolio_id, artwork.id, {
        feedback,
      })
      .then(() => {
        handleClose()
        refreshArtwork()
        apis.appfront
          .retry(formData)
          .then(() => {
            refreshArtwork()
          })
          .catch(error => {
            console.log(error)
            refreshArtwork()
          })
        refreshRegenCount()
      })
      .catch(() => {
        alert('오류가 발생하였습니다')
        refreshArtwork()
      })
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={dialog.open}
          onClose={handleClose}
          sx={{
            '& .MuiDialog-paper': {
              minWidth: '68rem',
              borderRadius: '20px',
            },
          }}
        >
          <DialogContent sx={{}}>
            <CenterAlignStack>
              <Typography sx={{ fontSize: '2rem', fontWeight: 700, py: '1rem' }}>
                {t('regenerate_dialog.regenerate')}
              </Typography>
            </CenterAlignStack>
            {/* <SelectProductBottomWidth soSize={soSize} setSOSize={setSOSize} showWraning={false} /> */}
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', pt: '1rem', pb: '3rem' }}>
            <DialogButton
              handleClose={handleClose}
              actionText={t('regenerate_dialog.button_regenerate')}
              handleAction={handleRegenerate}
            />
          </DialogActions>
        </Dialog>
      </Desktop>

      <Mobile>
        <MobileSwipeableDrawer
          open={dialog.open}
          onOpen={() => {
            return dialog.open
          }}
          onClose={handleClose}
        >
          <CenterAlignStack>
            <Typography sx={{ fontSize: '1.6rem', fontWeight: 700, py: '1.6rem' }}>
              {t('regenerate_dialog.regenerate')}
            </Typography>

            {/* <SelectProductBottomWidth soSize={soSize} setSOSize={setSOSize} showWraning={false} /> */}

            <CenterAlignBox sx={{ pt: '2rem', pb: '3rem' }}>
              <DialogButton
                handleClose={handleClose}
                actionText={t('regenerate_dialog.button_regenerate')}
                handleAction={handleRegenerate}
              />
            </CenterAlignBox>
          </CenterAlignStack>
        </MobileSwipeableDrawer>
      </Mobile>
    </>
  )
}
