import { useEffect } from 'react'
import { fabric } from 'fabric'

export default function useGridHandler(canvas, gridOptions) {
  if (!canvas) return

  // const drawGrid = () => {
  //   const { grid, lineColor, borderColor, enabled } = gridOptions
  //   if (enabled && grid) {
  //     const width = 5000
  //     const gridLength = width / grid
  //     const lineOptions = {
  //       stroke: lineColor,
  //       selectable: false,
  //       evented: false,
  //       id: 'grid',
  //     }
  //     for (let i = 0; i < gridLength; i++) {
  //       const distance = i * grid
  //       const horizontal = new fabric.Line([distance, -width, distance, width], lineOptions)
  //       const vertical = new fabric.Line([-width, distance, width, distance], lineOptions)
  //       canvas.add(horizontal, vertical)
  //       if (i % 5 === 0) {
  //         horizontal.set({ stroke: borderColor })
  //         vertical.set({ stroke: borderColor })
  //       }
  //     }
  //     canvas.renderAll()
  //   }
  // }

  const setCoords = target => {
    const { enabled, grid, snapToGrid } = gridOptions
    if (enabled && grid && snapToGrid) {
      const updatePosition = obj => {
        obj.set({
          left: Math.round(obj.left / grid) * grid,
          top: Math.round(obj.top / grid) * grid,
        })
        obj.setCoords()
      }

      if (target.type === 'activeSelection') {
        target.forEachObject(updatePosition)
      } else {
        updatePosition(target)
      }
      canvas.renderAll()
    }
  }

  // drawGrid()
  canvas.on('object:moving', ({ target }) => setCoords(target))

  return () => {
    canvas.off('object:moving')
  }
}
