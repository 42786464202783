// 네이버 커머스 관련 api

import axios from 'axios'
import crudAxios from './configs/crud'

const path = '/nhn_commerce'

export const nhnCommerceAPI = {
  checkUser: body => {
    return crudAxios.post(`${path}/check_user`, body)
  },
  login: body => {
    return crudAxios.post(`${path}/login_user`, body)
  },
  createUser: body => {
    return crudAxios.post(`${path}/user`, body)
  },
  getProductList: ({
    withImageBytes = false,
    search = '',
    page = 1,
    pageSize = 10,
    withCancelToken = false,
  }) => {
    const config = {}
    let cToken

    if (withCancelToken) {
      cToken = axios.CancelToken.source()
      config.cancelToken = cToken.token
    }

    return [
      crudAxios.get(
        `${path}/product_list?with_image_bytes=${withImageBytes}&search=${search}&page=${page}&page_size=${pageSize}`,
        config
      ),
      cToken,
    ]
  },
  getProductDetail: (productNo, withImageBytes = false) => {
    return crudAxios.get(`${path}/product/${productNo}`)
  },
  updateProductImage: body => {
    return crudAxios.post(`${path}/product/image`, body)
  },
  linkUser: body => {
    // 기존에 존재하던 드랩아트 회원 계정을 네이버 솔루션 연동하려는 경우
    return crudAxios.post(`${path}/link_user`, body)
  },
}
