import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { isDev } from '../utils/common'

const { useEffect } = require('react')

function useMetaEdit() {
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()

  const PAGE_TITLE = {
    terms: t('footer.terms'),
    privacy: t('footer.privacy'),
    marketing: t('menu.marketing'),
    Advertisement: t('menu.Advertisement'),

    login: t('title.login'),
    findID: t('title.findID'),
    findPW: t('title.findPW'),
    resetPW: t('title.resetPW'),
    Register: t('title.register'),
    join: t('title.join'),

    Board: t('title.board'),
    guide: t('title.guide'),
    FAQ: t('title.FAQ'),
    inquiry: t('title.inquiry'),
    api_info: t('title.api_info'),

    mypage: t('title.mypage'),
    background: t('title.background'),
    creditInfo: t('title.creditinfo'),
    coupon: t('title.coupon'),
    profile: t('title.profile'),

    blog: t('title.blog'),

    home: t('title.home'),
    portfolio: t('title.portfolio'),

    paid: t('menu.paid'),
    pricing: t('title.pricing'),

    admin: '관리자페이지',
    'generate/upload': t('title.upload'),
    'generate/removebg': t('title.removebg'),
    'generate/bundle': t('title.bundle'),
    'generate/canvas': t('title.canvas'),
    'model/mannequin': t('title.mannequin'),
    'model/modelbg': t('title.modelbg'),
    'model/face': t('title.face'),
    'fun/fame': t('title.fame'),
    'ads/banner': t('title.banner'),

    // 하위를 나중에 확인함으로
    promotion: t('title.promotion'),
    'promotion/domeggook_event': '드랩아트X도매꾹 이벤트',
    'promotion/oddoffice_event': '드랩아트X이상한사무실 이벤트',
    'promotion/shoplinker_event': '드랩아트X샵링커 이벤트',
    'promotion/aithumbnail_ebook': '상품 썸네일 100개 AI로 순식간에 만들기 전자책',
    default: t('title.home'),
  }

  const pageTitle = () => {
    let titleKey = Object.keys(PAGE_TITLE).filter(title => pathname.replace('/', '') === title)[0]
    if (!titleKey) {
      titleKey = Object.keys(PAGE_TITLE).filter(title => pathname.includes(title))[0]
    }
    // Object.keys(PAGE_TITLE).forEach(title => {
    //   console.log(pathname)
    //   if (pathname.includes(title)) {
    //     titleKey = title
    //   }
    // })

    const titleName = PAGE_TITLE[titleKey ?? 'default']

    const defaultName = () => {
      if (titleKey === 'blog') {
        return ' | ' + t('title.draph_blog')
      }

      return ' | ' + t('menu.draph_art')
    }

    useEffect(() => {
      document.title = titleName + defaultName()
    }, [pathname])
  }

  const metaDescription = () => {
    let titleKey = null
    const { t, i18n } = useTranslation()
    const { pathname } = useLocation()

    const PAGE_DESCRIPTION = {
      login: t('description.login'),
      join: t('description.join'),

      blog: t('description.blog'),

      portfolio: t('description.portfolio'),

      pricing: t('description.pricing'),

      'generate/upload': t('description.upload'),
      'generate/removebg': t('description.removebg'),
      'generate/bundle': t('description.bundle'),
      'generate/canvas': t('description.bg_expansion'),
      'model/mannequin': t('description.mannequin'),
      'model/modelbg': t('description.modelbg'),
      'model/face': t('description.face'),
      'fun/fame': t('description.fame'),
      'ads/banner': t('description.banner'),

      // 하위를 나중에 확인함으로
      promotion: t('description.promotion'),
      'promotion/domeggook_event': t('description.promotion'),
      'promotion/oddoffice_event': t('description.promotion'),
      'promotion/shoplinker_event': t('description.promotion'),
      default: t('description.default'),
    }

    Object.keys(PAGE_DESCRIPTION).forEach(title => {
      if (pathname.includes(title)) {
        titleKey = title
      }
    })

    const titleName = PAGE_DESCRIPTION[titleKey ?? 'default']

    return (
      <>
        <meta name="description" content={titleName} />
        {isDev ? (
          <meta name="robots" content="noindex, nofollow"></meta>
        ) : (
          <meta name="robots" content="index, follow"></meta>
        )}

        {(titleKey === 'promotion/domeggook_event' ||
          titleKey === 'promotion/oddoffice_event' ||
          titleKey === 'promotion/shoplinker_event') && (
          <meta name="robots" content="noindex"></meta>
        )}
      </>
    )
  }

  return { pageTitle, metaDescription }
}

export default useMetaEdit
