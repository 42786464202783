import React, { useState, useMemo, useEffect } from 'react'
import { portfolioDetailAtom } from 'atoms'
import { useArtworkQuery, useDeleteArtworks } from 'hooks/useReactQuery'
import { useRecoilValue } from 'recoil'
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from '@mui/material'
import CustomPagination from 'components/CustomPagination'
import { EmptyRow } from './ArtworkRow'
import BannerArtworkRow from './BannerArtworkRow'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { DeleteSelectedButton } from './ArtworkControl'

export default function BannerArtworkList() {
  const portfolioDetail = useRecoilValue(portfolioDetailAtom)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const [checkedArtworks, setCheckedArtworks] = useState({})

  const { getArtworks } = useArtworkQuery({ portfolioId: portfolioDetail.id })
  const { data: artworks, refetch, isLoading } = getArtworks

  const { mutate: deleteArtworks, isPending: isDeleting } = useDeleteArtworks()

  const paginatedArtworks = useMemo(() => {
    if (!artworks) return []
    const startIndex = (currentPage - 1) * itemsPerPage
    return artworks.slice(startIndex, startIndex + itemsPerPage)
  }, [artworks, currentPage])

  const totalPages = useMemo(() => {
    if (!artworks) return 0
    return Math.ceil(artworks.length / itemsPerPage)
  }, [artworks])

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }

  const handleMainCheckboxChange = event => {
    const isChecked = event.target.checked
    const newCheckedArtworks = {}
    paginatedArtworks.forEach(artwork => {
      newCheckedArtworks[artwork.id] = isChecked
    })
    setCheckedArtworks(prevState => ({
      ...prevState,
      ...newCheckedArtworks,
    }))
  }

  const handleRowCheckboxChange = artworkId => {
    setCheckedArtworks(prev => ({
      ...prev,
      [artworkId]: !prev[artworkId],
    }))
  }

  useEffect(() => {
    console.log('checkedArtworks', checkedArtworks)
  }, [checkedArtworks])

  const handleDelete = () => {
    const artworksToDelete = Object.entries(checkedArtworks)
      .filter(([_, isChecked]) => isChecked)
      .map(([id]) => id)

    if (artworksToDelete.length > 0) {
      deleteArtworks({ portfolioId: portfolioDetail.id, artworkIds: artworksToDelete })
    }
  }

  const isAllChecked =
    paginatedArtworks.length > 0 && paginatedArtworks.every(artwork => checkedArtworks[artwork.id])

  if (isLoading) return <Typography>Loading...</Typography>

  return (
    <CenterAlignStack sx={{ width: '100%', mb: 2 }}>
      <CenterAlignStack sx={{ width: '128rem' }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Artworks
        </Typography>
        <CustomPagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          size="small"
          showFirstButton={false}
          showLastButton={false}
          boundaryCount={1}
          siblingCount={1}
          sx={{ mb: 2 }}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isAllChecked}
                    onChange={handleMainCheckboxChange}
                    indeterminate={
                      paginatedArtworks.some(artwork => checkedArtworks[artwork.id]) &&
                      !isAllChecked
                    }
                  />
                </TableCell>
                <TableCell>이미지</TableCell>
                <TableCell>아트워크 정보</TableCell>
                <TableCell>
                  <DeleteSelectedButton handleDelete={handleDelete} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {artworks.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <EmptyRow />
                  </TableCell>
                </TableRow>
              ) : (
                paginatedArtworks.map((artwork, index) => (
                  <BannerArtworkRow
                    key={artwork.id}
                    initArtwork={artwork}
                    isChecked={checkedArtworks[artwork.id] || false}
                    onCheckboxChange={() => handleRowCheckboxChange(artwork.id)}
                    idx={index + 1 + (currentPage - 1) * itemsPerPage}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" onClick={refetch} sx={{ mt: 2 }}>
          Refresh Artworks
        </Button>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}
