import {
  styled,
  Box,
  Stack,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import { CenterAlignStack, FormErrorMessage, TextInput } from 'components'
import { Desktop, Mobile, useBreakPoint } from 'hooks/useMediaQuery'
import { useEffect, useRef, useState } from 'react'
import { apis } from 'apis'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { columns as userColumns } from './AdminCredit'
import { StyledDataGrid, columns as promotionColumns, MiniButton } from './AdminPromotion'
import { plans } from '../../pricing'

export const columns = [
  {
    field: 'id',
    width: 50,
    headerName: 'ID',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'user_id',
    width: 80,
    headerName: '유저ID',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'plan_id',
    width: 150,
    headerName: '구독상품 id / 이름',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => (
      <div>
        {params.value}
        <br />
        {params.row.plan_name}
      </div>
    ),
  },
  {
    field: 'start_date',
    width: 160,
    headerName: `구독시작/종료일 (${moment.tz(moment.tz.guess()).zoneName()}) `,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => (
      <div>
        <span>{moment(moment.utc(params.value).toDate()).local().format('YY-MM-DD HH:mm:ss')}</span>
        <br />
        {
          <span>
            {params.row.end_date
              ? moment(moment.utc(params.row.end_date).toDate()).local().format('YY-MM-DD HH:mm:ss')
              : '-'}
          </span>
        }
      </div>
    ),
  },
  {
    field: 'last_paid',
    width: 150,
    headerName: `최종결제 (${moment.tz(moment.tz.guess()).zoneName()}) `,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: params => (
      <span>{moment(moment.utc(params.value).toDate()).local().format('YY-MM-DD HH:mm:ss')}</span>
    ),
  },
  {
    field: 'charge_credit_amount',
    width: 80,
    headerName: '크레딧',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'charge_price',
    width: 100,
    headerName: '결제가격',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'charge_cycle',
    width: 100,
    headerName: '주기',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'valid',
    width: 100,
    headerName: '유효',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
]

const today = moment().local().format('YYYY-MM-DD')

export default function AdminSubscription() {
  const [list, setList] = useState([])
  const [userIdInput, setUserIdInput] = useState('')
  const [targetUser, setTargetUser] = useState(null)
  const [creditAmount, setCreditAmount] = useState(100)
  const [startDate, setStartDate] = useState('')
  const [cycle, setCycle] = useState('monthly')

  const { enqueueSnackbar } = useSnackbar()

  const refreshSubscription = () => {
    apis.admin.getSubscriptions().then(response => {
      setList(response.data)
    })
  }

  useEffect(() => {
    setStartDate(today)
    refreshSubscription()
  }, [])

  const getTargetUser = () => {
    if (userIdInput.length < 1) return
    apis.admin.getUserById(userIdInput).then(response => {
      setTargetUser(response.data)
    })
  }

  const handleSubscription = () => {
    if (!targetUser?.id) {
      enqueueSnackbar('유저선택', { variant: 'error' })
      return
    }

    const body = {
      user_id: targetUser.id,
      charge_cycle: cycle,
      charge_credit_amount: creditAmount,
    }
    apis.admin.addFreeSubscription(body).then(response => {
      if (response.data.success) {
        enqueueSnackbar('완료', { variant: 'success' })
        refreshSubscription()
      } else {
        enqueueSnackbar('에러', { variant: 'error' })
      }
    })
  }

  return (
    <div>
      <CenterAlignStack sx={{ width: '100%' }}>
        <Typography sx={{ fontWeight: 800, fontSize: '3rem' }}>구독 관리</Typography>

        <Stack sx={{ py: '2rem' }} spacing="1rem">
          <Typography variant="h5">구독 추가</Typography>
          <Typography variant="h6">
            - enterprise 고객 결제 없이 주기적으로 충전하는 경우에만 수동 추가
          </Typography>
          <br />

          <Stack sx={{ my: '2rem', borderRadius: '1rem', border: '1px solid gray', p: '2rem' }}>
            <br />
            <Stack direction="row">
              <TextField
                InputLabelProps={{ shrink: true }}
                label="user id 또는 username"
                autoComplete="off"
                value={userIdInput}
                onChange={e => setUserIdInput(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    getTargetUser()
                  }
                }}
                sx={{ '& .MuiInputBase-root': { p: 0 } }}
              ></TextField>
              <Button variant="contained" onClick={getTargetUser}>
                조회
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setUserIdInput('')
                  setTargetUser(null)
                }}
              >
                리셋
              </Button>
            </Stack>

            <Box sx={{ width: '30rem' }}>
              <StyledDataGrid
                disableColumnMenu
                rows={targetUser ? [targetUser] : []}
                columns={[userColumns[0], userColumns[1]]}
                autoHeight
                hideFooter
                onRowClick={v => {
                  console.log(v)
                }}
              />
            </Box>
          </Stack>

          <TextField
            label="크레딧"
            placeholder="크레딧"
            autoComplete="off"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={creditAmount}
            onChange={e => setCreditAmount(e.target.value)}
          ></TextField>

          <TextField label="충전주기 (고정)" autoComplete="off" value={cycle} disabled></TextField>

          {/* <Stack direction="row">
            <Typography>시작 날짜 ({moment.tz(moment.tz.guess()).zoneName()})</Typography>
            <input
              type="date"
              id="start"
              value={startDate}
              min={moment().local().format('YYYY-MM-DD')}
              name="start-date"
              onChange={e => {
                setStartDate(e.target.value)
              }}
            />
            {today === startDate && <Typography>&nbsp;- 즉시 지급 후 구독 시작</Typography>}
          </Stack> */}

          <Button
            variant="outlined"
            onClick={handleSubscription}
            sx={{ width: '100%', height: '7rem' }}
          >
            구독 추가 (즉시 크레딧 지급 후 구독 시작)
          </Button>
        </Stack>

        <Box width="100rem" sx={{ ml: '7rem' }}>
          <StyledDataGrid
            disableColumnMenu
            rows={list}
            columns={columns}
            autoHeight
            hideFooter
            onRowClick={v => {
              console.log(v)
            }}
          />
        </Box>
      </CenterAlignStack>
    </div>
  )
}
