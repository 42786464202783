import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useRecoilState, useResetRecoilState } from 'recoil'
import {
  confirmDialogAtom,
  pleaseLoginDialogAtom,
  uploadDialogOpenAtom,
  welcomeDialogAtom,
} from 'atoms'
import { alpha } from '@mui/material/styles'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CenterAlignBox, CenterAlignStack } from './CenterAlignContainer'
import { useNavigate } from 'react-router-dom'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { DialogButton } from './DialogButtons'
import { SwipeableDrawer } from '@mui/material'
import { iOS } from 'utils/common'

export default function WelcomeDialog() {
  const [dialog, setDialog] = useRecoilState(welcomeDialogAtom)
  const { open } = dialog

  const resetData = useResetRecoilState(welcomeDialogAtom)
  const [uploadOpen, setUploadOpen] = useRecoilState(uploadDialogOpenAtom)

  const { t, i18n } = useTranslation()
  const isKo = i18n.language === 'ko'
  const navigate = useNavigate()

  const handleClose = () => {
    resetData()
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            zIndex: 9999,
            '& .MuiDialog-paper': { width: { lg: '35.2rem' }, borderRadius: '2rem' },
          }}
        >
          <DialogTitle>
            <CenterAlignStack sx={{ pt: '1rem' }}>
              <Typography sx={{ fontSize: '2.4rem', fontWeight: 700 }}>
                {t('welcome_dialog.title')}
              </Typography>
            </CenterAlignStack>
          </DialogTitle>
          <DialogContent
            sx={{
              fontSize: '1.6rem',
              fontWeight: 400,
            }}
          >
            <CenterAlignStack
              sx={{
                justifyContent: 'center',
                '& .comment': {
                  fontSize: '1.3rem',
                  fontWeight: 600,
                  color: theme => theme.palette.draph.blue,
                  lineHeight: 1.2,
                },
              }}
            >
              <Stack sx={{ width: '27.6rem' }}>
                <Box sx={{ width: '100%' }}>
                  {isKo ? (
                    <img src="/static/images/coupon_ko.png" style={{}} alt="" />
                  ) : (
                    <img src="/static/images/coupon_en.png" style={{}} alt="" />
                  )}
                </Box>

                <Box
                  component="ul"
                  sx={{
                    my: '2.3rem',

                    pl: '1.5rem',
                    '& li': {
                      fontSize: { lg: '1.5rem', xs: '1.2rem' },
                      fontWeight: 400,
                      lineHeight: 1.3,
                      listStyleType: 'none',
                      textIndent: '-1.7rem',
                      '&::before': {
                        content: '"•"',
                        marginRight: '0.7rem',
                      },
                    },
                  }}
                >
                  <Typography component="li">{t('welcome_dialog.content_1')}</Typography>
                  <Typography component="li">{t('welcome_dialog.content_2')}</Typography>
                </Box>

                <Stack spacing={isKo ? 0 : 1} sx={{ textAlign: 'center' }}>
                  <Typography className="comment">{t('welcome_dialog.comment_1')}</Typography>
                  <Typography className="comment">{t('welcome_dialog.comment_2')}</Typography>
                </Stack>
              </Stack>
            </CenterAlignStack>
          </DialogContent>
          <DialogActions sx={{ pb: '2.5rem' }}>
            <CenterAlignBox sx={{ width: '100%' }}>
              <DialogButton
                cancelText={t('button.close')}
                actionText={t('button.new_image')}
                handleClose={() => {
                  // ----- GA4 event -----
                  window.gtag('event', 'welcome_click', { method: 'close' })
                  // ---------------------
                  handleClose()
                }}
                handleAction={() => {
                  // ----- GA4 event -----
                  window.gtag('event', 'welcome_click', { method: 'generate' })
                  // ---------------------
                  handleClose()
                  setUploadOpen(true)
                }}
              />
            </CenterAlignBox>
          </DialogActions>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={open}
          onClose={handleClose}
          onOpen={() => {
            return open
          }}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignStack sx={{ pt: '1rem' }}>
            <CenterAlignBox
              sx={{
                width: '4rem',
                height: '0.4rem',
                backgroundColor: '#BCBCBC',
                borderRadius: '20px',
                mt: '1.2rem',
                mb: '1.2rem',
              }}
            ></CenterAlignBox>

            <Typography sx={{ fontSize: '2.4rem', fontWeight: 700 }}>
              {t('welcome_dialog.title')}
            </Typography>

            <Stack
              sx={{
                py: '2rem',
                width: '27.6rem',
                '& .comment': {
                  fontSize: '1.3rem',
                  fontWeight: 600,
                  color: theme => theme.palette.draph.blue,
                  lineHeight: 1.2,
                },
              }}
            >
              <Box sx={{ width: '100%' }}>
                {isKo ? (
                  <img src="/static/images/coupon_ko.png" style={{}} alt="" />
                ) : (
                  <img src="/static/images/coupon_en.png" style={{}} alt="" />
                )}
              </Box>

              <Box
                component="ul"
                sx={{
                  my: '2.3rem',

                  pl: '1.5rem',
                  '& li': {
                    fontSize: '1.5rem',
                    fontWeight: 400,
                    lineHeight: 1.3,
                    listStyleType: 'none',
                    textIndent: '-1.7rem',
                    '&::before': {
                      content: '"•"',
                      marginRight: '0.7rem',
                    },
                  },
                }}
              >
                <Typography component="li">{t('welcome_dialog.content_1')}</Typography>
                <Typography component="li">{t('welcome_dialog.content_2')}</Typography>
              </Box>

              <Stack spacing={isKo ? 0 : 1} sx={{ textAlign: 'center', mb: '2rem' }}>
                <Typography className="comment">{t('welcome_dialog.comment_1')}</Typography>
                <Typography className="comment">{t('welcome_dialog.comment_2')}</Typography>
              </Stack>

              <DialogButton
                cancelText={t('button.close')}
                actionText={t('button.new_image')}
                handleClose={() => {
                  handleClose()
                }}
                handleAction={() => {
                  handleClose()
                  setUploadOpen(true)
                }}
              />
            </Stack>
          </CenterAlignStack>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}
