import { apis } from 'apis'
import {
  PORTFOLIO_CONFIG_DEFAULT,
  brandConfigUpdateAtom,
  currentMenuAtom,
  defaultPortfolioAtom,
  endpointAtom,
  guestPortfolioAtom,
  guestUserAtom,
  portfolioArtworkAtom,
  portfolioConfigAtom,
  portfolioDetailAtom,
  portfolioTypeAtom,
  unusualPortfolioConfigUpdateAtom,
  userAtom,
  userLikedBgAtom,
} from 'atoms'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  creditPolicyDictSelector,
  guestPortfolioUploadConfigSelector,
  portfolioUploadConfigSelector,
} from 'selector'
import useCreditWarningDialog from './useCreditWarningDialog'
import { DEFAULT_PORTFOLIO_TYPES } from 'config'
import { getUploadUUID } from 'utils/artwork'
import * as config from 'config'
import useConfirm from './useConfirm'
import { Typography } from '@mui/material'
import { resizeImage } from 'utils/common'
import { processInGroups } from 'components'
import * as cookie from 'utils/cookie'

const uploadMaxSize = 3000

export function useGuestPortfolio() {
  const uploadConfig = useRecoilValue(guestPortfolioUploadConfigSelector)
  const [user, setUser] = useRecoilState(guestUserAtom)
  const guestPortfolio = useRecoilValue(guestPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const setArtworkList = useSetRecoilState(portfolioArtworkAtom)
  const portfolioId = guestPortfolio.id

  const { t, i18n } = useTranslation()

  const { showConfirm } = useConfirm()
  const currentMenu = useRecoilValue(currentMenuAtom)

  const refreshArtworks = callback => {
    apis.guest.getGuestArtworkAll(portfolioType).then(response => {
      setArtworkList(response.data)
    })
  }

  const triggerGa4InputImageSize = (width, height) => {
    // ----- GA4 event -----
    window.gtag('event', 'input_size', {
      // 매개변수 신규등록 없이 기존것 재활용 하기 위해 이름만 output_size_xxxx
      output_size_width: width,
      output_size_height: height,
    })
  }

  const makeUploadFormData = async (
    uploadFiles,
    addGenOption = {},
    addFormData = {},
    mode = 'default'
  ) => {
    const formData = new FormData()
    const uploadGroupUid = getUploadUUID()

    switch (mode) {
      case 'default': {
        console.log('--------- start appending images')

        const promises = uploadFiles.map(f => {
          const func = async () => {
            try {
              const image = new Image()
              const objectUrl = window.URL.createObjectURL(f)
              let smallImgFile = null

              image.src = objectUrl
              await image.decode()

              triggerGa4InputImageSize(image.width, image.height)

              if (image.width > uploadMaxSize || image.height > uploadMaxSize) {
                console.log(f.name, '..resizing image')
                const smallerImage = await resizeImage(f, uploadMaxSize)
                // console.log('smallerImage', smallerImage)
                smallerImage.lastModified = new Date()
                smallerImage.name = f.name
                smallImgFile = new File([smallerImage], f.name)

                formData.append('images', smallImgFile)
              } else {
                console.log(f.name)
                formData.append('images', f)
              }
            } catch (e) {
              console.log(e, 'ERROR ', f.name)
            }
          }

          return func
        })

        await processInGroups(promises)

        console.log('--------- END appending images')

        //
        const genOptions = {
          ...uploadConfig,
          flag_generate: uploadConfig.flag_generate ?? true,
          flag_complex_cmp: uploadConfig.flag_complex_cmp ?? true,
          flag_simple_cmp: uploadConfig.flag_simple_cmp ?? false,
          flag_white_cmp: uploadConfig.flag_white_cmp ?? true,

          output_size_w: uploadConfig.output_size_w ?? 0,
          output_size_h: uploadConfig.output_size_h ?? 0,

          selected_bg_ids: '',
          endpoints: {},
          gen_shadow: uploadConfig.gen_shadow,
          gen_face: uploadConfig.gen_face,
          object_category: uploadConfig.object_category ?? 'auto',
          object_sub_category: uploadConfig.object_sub_category ?? 'auto',
          object_boundary: uploadConfig.object_boundary ?? 'none',
          object_angle: 'auto', // 포트폴리오 설정에서 각도 옵션을 삭제하여서, 최초 업로드시 무조건 자동으로 하도록 수정

          // flag_gen_compo: portfolioConfig.flagGenCompo,
          // flag_bg_expansion: portfolioConfig.flagBgExpansion,
          // flag_multiblob_sod: portfolioConfig.flagMultiblobSod,

          output_size_list: uploadConfig.output_size_list ?? [],
          simple_bg_color_list: uploadConfig.simple_bg_color_list ?? [[255, 255, 255]],

          //
          theme_template: uploadConfig.theme_template ?? 'auto',
          theme_custom: uploadConfig.theme_custom ?? '',

          //
          flag_human_background: false,
          SO_length_scale: 'auto',
          //

          //
          flag_facemorphing: false,
          facemorphing_race: 'asian',
          facemorphing_gender: 'none',
          //

          // 항상 마지막에 -----
          ...addGenOption,
          // ---------------

          // 포트폴리오가 from_zip true 여도 일반 업로드에서는 꺼줘야 아트워크 설정에서 구분 가능
          from_zip: false,
          zip_path: null,

          //
          upload_id: uploadGroupUid,

          // 필수! 게스트 유저 구분용 ----------
          _artwork_info: { guest_session: cookie.getGuestSession() },
          // _artwork_info: JSON.stringify({ guest_session: getGuestSession() }),
        }

        formData.append('user_id', user.id)
        formData.append('username', user.username)
        formData.append('portfolio_id', portfolioId)

        if (Object.keys(addFormData).length !== 0) {
          Object.keys(addFormData).forEach(k => {
            formData.delete(k)
            formData.append(k, addFormData[k])
          })
        }

        formData.append('gen_options', JSON.stringify(genOptions))

        // ------------------------------------------------------------------------------------------

        return formData
      }
      // ---------------
    }
  }

  const prepareUpload = async files => {
    const response = await apis.guest.checkGuestAccessToken()
    if (response.data?.x) {
      cookie.setGuestAccess(response.data.x)
    } else {
      //
    }

    return files
  }

  return {
    refreshArtworks,
    makeUploadFormData,
    prepareUpload,
  }
}
