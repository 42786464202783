import {
  Grid,
  Stack,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  TextField,
  Dialog,
  DialogContent,
} from '@mui/material'
import {
  CenterAlignStack,
  CenterAlignBox,
  FormTextField,
  StyledFormTextField,
  FormCheckbox,
  MobileSwipeableDrawer,
} from 'components'
import { apis } from 'apis'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'

import * as config from 'config'
import { useEffect, useState } from 'react'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'

import SwiperCore, { Navigation, Pagination, Autoplay, Swiper, Manipulation } from 'swiper'
import _ from 'lodash'

import { useTranslation } from 'react-i18next'
import { EnterpriseCustomerLogoMarquee } from './Home'
import { useNavigate } from 'react-router-dom'
import { English, Korean } from 'hooks/useLanguage'

const pageStyle = {
  '& .section': {
    width: '100%',
    '& .title': {
      fontSize: { lg: '4rem', xs: '2.4rem' },
      fontWeight: 800,
    },
    '& .subtitle': {
      textAlign: 'center',
      fontSize: { lg: '2.6rem', xs: '1.4rem' },
      fontWeight: 600,
    },

    '&:nth-of-type(odd)': {
      background: 'white',
    },
    '&:nth-of-type(even)': {
      background: '#F8F8F8',
    },
  },
}

export default function APIInfo() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    // ----- GA4 event -----
    window.gtag('event', 'api', {})
    // ---------------------
  }, [])

  return (
    <>
      <CenterAlignStack
        sx={{
          ...pageStyle,
        }}
      >
        <CenterAlignStack
          className="section"
          sx={{
            pt: { lg: '4.8rem', xs: '2.8rem' },
            pb: { lg: '6.4rem', xs: '4rem' },
          }}
        >
          <Korean>
            <Typography className="title" sx={{}}>
              기업용 맞춤형 기능 도입안내
            </Typography>
            <Typography className="subtitle" sx={{}}>
              수 많은 기업들이 사용하고 있는 드랩아트를
              <Desktop> </Desktop>
              <Mobile>
                <br />
              </Mobile>
              간편하게 도입하세요!
            </Typography>
          </Korean>

          <English>
            <Typography className="title" sx={{}}>
              Introducing Draph Art’s API
            </Typography>
            <Typography className="subtitle" sx={{}}>
              Draph Art has been chosen by multiple
              <Mobile>
                <br />
              </Mobile>{' '}
              leading companies.
            </Typography>
            <Typography className="subtitle" sx={{}}>
              Use Draph Art’s API to conveniently integrate
              <Mobile>
                <br />
              </Mobile>{' '}
              our beloved service to your channel!
            </Typography>
          </English>

          <Box
            sx={{
              height: { lg: '34.4rem', xs: '16.1rem' },
              mt: { lg: '6rem', xs: '2.2rem' },
              mb: { lg: '4.8rem', xs: '2rem' },
            }}
          >
            <img src="/static/images/etc/api_page_sample.png" style={{ height: '100%' }} />
          </Box>

          <EnterpriseCustomerLogoMarquee />

          <Button
            variant="contained"
            sx={{
              mt: { lg: '4rem', xs: '2.8rem' },

              px: { lg: '9.1rem', xs: '5.2rem' },
              py: { lg: '1rem', xs: '0.4rem' },
              fontSize: { lg: '2.8rem', xs: '1.6rem' },
              fontWeight: { lg: 800, xs: 800 },
              borderRadius: '1rem',
            }}
            onClick={() => {
              // ----- GA4 event -----
              window.gtag('event', 'api_button', { method: 'api_top' })
              // ---------------------

              const anchor = document.querySelector('#inquiry-form')

              if (anchor) {
                anchor.scrollIntoView({
                  block: 'center',
                  behavior: 'smooth',
                })
              }
            }}
          >
            {t('api_info.inquiry')}
          </Button>
        </CenterAlignStack>

        <CenterAlignStack className="section" sx={{ py: '6rem' }}>
          <Typography className="title" sx={{ mb: { lg: '4.4rem', xs: '2rem' } }}>
            {t('api_info.features')}
          </Typography>

          <Stack
            sx={{
              '& .card': {
                boxShadow: '4px 4px 20px 0px #0000001A',
                borderRadius: { lg: '2rem', xs: '1rem' },

                py: { lg: '2.4rem', xs: '1rem' },
                pl: { lg: '4.3rem', xs: '1.3rem' },
                pr: { lg: '4.1rem', xs: '1.6rem' },

                '& .wrapper': {
                  justifyContent: 'right',
                },

                '& .title': {
                  fontSize: { lg: '3.2rem', xs: '1.4rem' },
                  fontWeight: 800,
                  textAlign: 'right',
                },
                '& .title-sm': {
                  fontSize: { lg: '3rem', xs: '1.2rem' },
                  fontWeight: 800,
                  textAlign: 'right',
                },
                '& .subtitle': {
                  fontSize: { lg: '2.8rem', xs: '1.3rem' },
                  fontWeight: 500,
                  lineHeight: 1.2,
                  textAlign: 'right',
                },
                '& .subtitle-sm': {
                  fontSize: { lg: '2.5rem', xs: '1rem' },
                  fontWeight: 500,
                  lineHeight: 1.2,
                  textAlign: 'right',
                },

                '& .image': {
                  width: { lg: '20rem', xs: '7.2rem' },
                  height: { lg: '20rem', xs: '7.2rem' },
                  objectFit: 'cover',
                },
                '& .arrow-image': {
                  width: { lg: '6rem', xs: '1.8rem' },
                  height: { lg: '6rem', xs: '1.8rem' },
                },
              },
            }}
            spacing={{ lg: '2.4rem', xs: '1rem' }}
          >
            <Card className="card">
              <CenterAlignStack
                direction="row"
                spacing={{ lg: '3.2rem', xs: '0.8rem' }}
                className="wrapper"
              >
                <Box className="text-box">
                  <Korean>
                    <Typography className="title">상품 이미지 생성</Typography>
                    <Typography className="subtitle">원하는 테마에 맞춰</Typography>
                    <Typography className="subtitle">자동 편집 및 보정</Typography>
                  </Korean>

                  <English>
                    <Typography className="title-sm">Product Image Generation</Typography>
                    <Typography className="subtitle-sm">
                      Automatic editing and enhancement
                    </Typography>
                    <Typography className="subtitle-sm">
                      that matches your desired themes
                    </Typography>
                  </English>
                </Box>
                <Box className="image">
                  <img src="/static/images/api_info/feature_1_before.png" />
                </Box>
                <Box className="arrow-image">
                  <img src="/static/images/api_info/gradient_arrow.png" />
                </Box>
                <Box className="image">
                  <img src="/static/images/api_info/feature_1_after.png" />
                </Box>
              </CenterAlignStack>
            </Card>

            <Card className="card">
              <CenterAlignStack
                direction="row"
                spacing={{ lg: '3.2rem', xs: '0.8rem' }}
                className="wrapper"
              >
                <Box className="text-box">
                  <Korean>
                    <Typography className="title">이미지 배경 제거</Typography>
                    <Typography className="subtitle">대량 작업 자동화로</Typography>
                    <Typography className="subtitle">비용과 시간 단축</Typography>
                  </Korean>

                  <English>
                    <Typography className="title-sm">Image Background Removal</Typography>
                    <Typography className="subtitle-sm">Automate bulk tasks</Typography>
                    <Typography className="subtitle-sm">to save costs and time</Typography>
                  </English>
                </Box>
                <Box className="image">
                  <img src="/static/images/api_info/feature_2_before.png" />
                </Box>
                <Box className="arrow-image">
                  <img src="/static/images/api_info/gradient_arrow.png" />
                </Box>
                <Box className="image">
                  <img src="/static/images/api_info/feature_2_after.png" />
                </Box>
              </CenterAlignStack>
            </Card>

            <Card className="card">
              <CenterAlignStack
                direction="row"
                spacing={{ lg: '3.2rem', xs: '0.8rem' }}
                className="wrapper"
              >
                <Box className="text-box">
                  <Korean>
                    <Typography className="title">AI 모델 생성</Typography>
                    <Typography className="subtitle">섭외비 및 초상권</Typography>
                    <Typography className="subtitle">걱정 없는 AI 모델</Typography>
                  </Korean>
                  <English>
                    <Typography className="title-sm">AI Model Creation</Typography>
                    <Typography className="subtitle-sm">
                      AI models that don’t have hiring fees
                    </Typography>
                    <Typography className="subtitle-sm">or portrait rights concerns</Typography>
                  </English>
                </Box>
                <Box className="image">
                  <img src="/static/images/api_info/feature_3_before.png" />
                </Box>
                <Box className="arrow-image">
                  <img src="/static/images/api_info/gradient_arrow.png" />
                </Box>
                <Box className="image">
                  <img src="/static/images/api_info/feature_3_after.png" />
                </Box>
              </CenterAlignStack>
            </Card>
          </Stack>
        </CenterAlignStack>

        <CenterAlignStack
          className="section"
          sx={{
            width: '100%',
            py: { lg: '4.8rem', xs: '2.2rem' },
          }}
        >
          <UsecaseSwiper />
        </CenterAlignStack>

        <CenterAlignStack
          className="section"
          sx={{
            pt: { lg: '6rem', xs: '2.8rem' },
            pb: { lg: '5.2rem', xs: '3.1rem' },
          }}
        >
          <InquiryForm />
        </CenterAlignStack>

        <Korean>
          <CenterAlignStack className="section" sx={{ py: { lg: '6.8rem', xs: '3.2rem' } }}>
            <Typography
              sx={{
                fontSize: { lg: '2.8rem', xs: '1.8rem' },
                fontWeight: 500,
              }}
            >
              {t('api_info.service_comment_1')}
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: '4rem', xs: '2.6rem' },
                fontWeight: 900,

                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundImage:
                  'linear-gradient(72.74deg, #1CC2D3 16.34%, #4D80FF 34.51%, #2CADE1 63.77%, #00E8B9 80.93%)',
              }}
            >
              {t('api_info.service_comment_2')}
            </Typography>

            <Button
              variant="contained"
              sx={{
                borderRadius: '1rem',
                mt: { lg: '2rem', xs: '1.2rem' },
                px: { lg: '5rem', xs: '2.3rem' },
                py: { lg: '1.3rem', xs: '0.8rem' },
                fontWeight: 800,
                fontSize: { lg: '2.8rem', xs: '1.6rem' },
              }}
              onClick={() => {
                // ----- GA4 event -----
                window.gtag('event', 'api_button', { method: 'sign_up_draphart' })
                // ---------------------
                navigate('/api_main')
              }}
            >
              {t('api_info.signup_draphart')}
            </Button>
          </CenterAlignStack>
        </Korean>
      </CenterAlignStack>
    </>
  )
}

function UsecaseSwiper() {
  const [swiper, setSwiper] = useState(null)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const sw = new Swiper('.usecase-swiper-container', {
      autoHeight: true,
      pagination: {
        el: '.usecase-swiper-pagination',
      },
      navigation: {
        nextEl: '.usecase-swiper-button-next',
        prevEl: '.usecase-swiper-button-prev',
      },
      modules: [Autoplay, Pagination, Navigation, Manipulation],
      slidesPerView: 1,
      loop: true,
    })

    // 왜...배열로 들어오는지 확인이 어려움
    if (_.isArray(sw)) {
      setSwiper(sw[0])
    } else {
      setSwiper(sw)
    }

    // return () => {
    //   sw.destroy()
    // }
  }, [])

  useEffect(() => {}, [swiper])

  const swiperStyle = {
    justifyContent: 'center',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    '& .title': {
      fontSize: { lg: '4rem', xs: '1.8rem' },
      fontWeight: 800,
      textAlign: 'center',
    },

    '& .swiper-slide': {
      pt: { lg: '3rem', xs: '1.6rem' },
      '& .subtitle': {
        fontSize: { lg: '2.8rem', xs: '1.4rem' },
        fontWeight: 700,
        color: theme => theme.palette.draph.blue,
        mb: { lg: '0.8rem', xs: '0.4rem' },
      },
      '& .comment': {
        fontSize: { lg: '2rem', xs: '1.2rem' },
        fontWeight: 400,
        lineHeight: { lg: 1.2, xs: 1.2 },
        textAlign: 'center',
      },
      '& .image-box': {
        pt: { lg: '4rem', xs: '1.6rem' },
        '& .image': {
          width: { lg: '36rem', xs: '15.4rem' },
        },
        '& .arrow-image': {},
      },
      '& .arrow-image': {
        width: { lg: '8rem', xs: '2.4rem' },
        height: { lg: '8rem', xs: '2.4rem' },
      },
    },

    '& .swiper-button': {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: { lg: '6.4rem', xs: '2.8rem' },
      height: { lg: '6.4rem', xs: '2.8rem' },
      borderRadius: { lg: '1rem', xs: '0.6rem' },
      background: '#0000004D',
      position: 'unset',
      mt: 'unset',
      // position: 'absolute',
      // top: 22,
      '&:hover': {
        background: '#00000099',
      },

      '&.usecase-swiper-button-prev': { left: 0 },
      '&.usecase-swiper-button-next': { right: 0 },
    },
  }

  return (
    <>
      <Box
        sx={{
          width: { lg: '86rem', xs: '36rem' },
          position: 'relative',
        }}
      >
        <CenterAlignStack className={'usecase-swiper-container'} sx={{ ...swiperStyle }}>
          <CenterAlignStack direction="row" sx={{ width: '100%', justifyContent: 'space-between' }}>
            <Box className="swiper-button usecase-swiper-button-prev">{swiperPrefArrow}</Box>
            <Korean>
              <Typography className="title">{t('api_info.usecase')}</Typography>
            </Korean>
            <English>
              <Typography className="title">
                Actual Cases using{' '}
                <Mobile>
                  <br />
                </Mobile>{' '}
                Draph Art’s API
              </Typography>
            </English>
            <Box className="swiper-button usecase-swiper-button-next">{swiperNextArrow}</Box>
          </CenterAlignStack>
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <CenterAlignStack>
                <Korean>
                  <Typography className="subtitle">{t('api_info.usecase_1_title')}</Typography>
                  <Desktop>
                    <Typography className="comment">{t('api_info.usecase_1_comment_a')}</Typography>
                    <Typography className="comment">{t('api_info.usecase_1_comment_b')}</Typography>
                    <Typography className="comment">{t('api_info.usecase_1_comment_c')}</Typography>
                  </Desktop>
                  <Mobile>
                    <Typography className="comment" sx={{ width: '32rem' }}>
                      {t('api_info.usecase_1_comment_a')} {t('api_info.usecase_1_comment_b')}{' '}
                      {t('api_info.usecase_1_comment_c')}
                    </Typography>
                  </Mobile>
                </Korean>

                <English>
                  <Typography className="subtitle">Unified Thumbnail Images</Typography>
                  <Typography className="comment">
                    Beauty Shop A wanted to bring its offline store’s interior vibe to its online
                    store as well.{' '}
                    <Desktop>
                      <br />
                    </Desktop>
                    Using Draph Art’s API, they automated the editing process of changing the
                    previously inconsistent thumbnail images to fit a single template, reducing
                    design cost by over 95%.
                  </Typography>
                </English>

                <CenterAlignStack className="image-box" direction="row">
                  <Box className="image">
                    <Korean>
                      <img src="/static/images/api_info/usecase_1_before.png" />
                    </Korean>
                    <English>
                      <img src="/static/images/api_info/usecase_1_before_en.png" />
                    </English>
                  </Box>
                  <Box className="arrow-image">
                    <img src="/static/images/api_info/gradient_arrow.png" />
                  </Box>
                  <Box className="image">
                    <Korean>
                      <img src="/static/images/api_info/usecase_1_after.png" />
                    </Korean>
                    <English>
                      <img src="/static/images/api_info/usecase_1_after_en.png" />
                    </English>
                  </Box>
                </CenterAlignStack>
              </CenterAlignStack>
            </div>

            <div className="swiper-slide">
              <CenterAlignStack>
                <Korean>
                  <Typography className="subtitle">{t('api_info.usecase_2_title')}</Typography>
                  <Desktop>
                    <Typography className="comment">{t('api_info.usecase_2_comment_a')}</Typography>
                    <Typography className="comment">{t('api_info.usecase_2_comment_b')}</Typography>
                    <Typography className="comment">{t('api_info.usecase_2_comment_c')}</Typography>
                  </Desktop>
                  <Mobile>
                    <Typography className="comment" sx={{ width: '32rem' }}>
                      {t('api_info.usecase_2_comment_a')} {t('api_info.usecase_2_comment_b')}{' '}
                      {t('api_info.usecase_2_comment_c')}
                    </Typography>
                  </Mobile>
                </Korean>
                <English>
                  <Typography className="subtitle">Automating Repetitive Design Tasks</Typography>
                  <Typography className="comment">
                    Shopping Mall B wanted to cut down the time spent on designing around 3,000
                    product photos everyday. By automating various design tasks such as background
                    removal, object scaling, image enhancement, and logo replacement with Draph
                    Art’s API, they reduced their work time by over 90%.
                  </Typography>
                </English>

                <CenterAlignStack className="image-box" direction="row">
                  <Box
                    className="image"
                    sx={{ width: { lg: '20rem !important', xs: '8.6rem !important' } }}
                  >
                    <Korean>
                      <img
                        src="/static/images/api_info/usecase_2_before.png"
                        style={{ width: '100%' }}
                      />
                    </Korean>
                    <English>
                      <img
                        src="/static/images/api_info/usecase_2_before_en.png"
                        style={{ width: '100%' }}
                      />
                    </English>
                  </Box>
                  <Box className="arrow-image" sx={{ mx: { lg: '2.8rem', xs: '0.8rem' } }}>
                    <img src="/static/images/api_info/gradient_arrow.png" />
                  </Box>
                  <Box className="image">
                    <Korean>
                      <img src="/static/images/api_info/usecase_2_after.png" />
                    </Korean>
                    <English>
                      <img src="/static/images/api_info/usecase_2_after_en.png" />
                    </English>
                  </Box>
                </CenterAlignStack>
              </CenterAlignStack>
            </div>

            <div className="swiper-slide">
              <CenterAlignStack>
                <Korean>
                  <Typography className="subtitle">{t('api_info.usecase_3_title')}</Typography>
                  <Desktop>
                    <Typography className="comment">{t('api_info.usecase_3_comment_a')}</Typography>
                    <Typography className="comment">{t('api_info.usecase_3_comment_b')}</Typography>
                    <Typography className="comment">{t('api_info.usecase_3_comment_c')}</Typography>
                  </Desktop>
                  <Mobile>
                    <Typography className="comment" sx={{ width: '32rem' }}>
                      {t('api_info.usecase_3_comment_a')} {t('api_info.usecase_3_comment_b')}{' '}
                      {t('api_info.usecase_3_comment_c')}
                    </Typography>
                  </Mobile>
                </Korean>
                <English>
                  <Typography className="subtitle">Boosting Photo Reviews</Typography>
                  <Desktop>
                    <Typography className="comment">
                      Online Duty-Free shop C wanted to increase the number of its photo reviews
                      which was falling way behind its text reviews. They developed a feature using
                      Draph Art’s API that automatically changes the customer’s faces and background
                      in the review photos, which reduced the burden on customers in posting photo
                      reviews and increased the number of their photo reviews by more than 9 times.
                    </Typography>
                  </Desktop>
                  <Mobile>
                    <Typography className="comment" sx={{ width: '32rem' }}>
                      {t('api_info.usecase_3_comment_a')} {t('api_info.usecase_3_comment_b')}{' '}
                      {t('api_info.usecase_3_comment_c')}
                    </Typography>
                  </Mobile>
                </English>

                <CenterAlignStack className="image-box" direction="row">
                  <Box className="image">
                    <Korean>
                      <img src="/static/images/api_info/usecase_3_before.png" />
                    </Korean>
                    <English>
                      <img src="/static/images/api_info/usecase_3_before_en.png" />
                    </English>
                  </Box>
                  <Box className="arrow-image">
                    <img src="/static/images/api_info/gradient_arrow.png" />
                  </Box>
                  <Box className="image">
                    <Korean>
                      <img src="/static/images/api_info/usecase_3_after.png" />
                    </Korean>
                    <English>
                      <img src="/static/images/api_info/usecase_3_after_en.png" />
                    </English>
                  </Box>
                </CenterAlignStack>
              </CenterAlignStack>
            </div>
          </div>

          {/* <div className="swiper-pagination"></div> */}
        </CenterAlignStack>
      </Box>
    </>
  )
}

const swiperPrefArrow = (
  <>
    <Desktop>
      <svg
        width="21"
        height="38"
        viewBox="0 0 21 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 35L2 19L18 3"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </Desktop>
    <Mobile>
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 13L1 7L7 1" stroke="white" strokeLinecap="square" strokeLinejoin="round" />
      </svg>
    </Mobile>
  </>
)

const swiperNextArrow = (
  <>
    <Desktop>
      <svg
        width="21"
        height="38"
        viewBox="0 0 21 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 3L19 19L3 35"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </Desktop>
    <Mobile>
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L7 7L1 13" stroke="white" strokeLinecap="square" strokeLinejoin="round" />
      </svg>
    </Mobile>
  </>
)

function InquiryForm() {
  const { t, i18n } = useTranslation()

  const [agreeInfoDialogOpen, setAgreeInfoDialogOpen] = useState(false)

  const schema = yup.object().shape({
    name: yup.string().required(t('api_info.inquiry_form_name_required')),
    company: yup.string().required(t('api_info.inquiry_form_company_required')),
    email: yup.string().required(t('api_info.inquiry_form_email_required')),
    contact: yup.string().required(t('api_info.inquiry_form_contact_required')),
    content: yup.string().required(t('api_info.inquiry_form_content_required')),
    agree: yup.boolean().oneOf([true], ' ').required(' '),
  })

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      company: '',
      email: '',
      contact: '',
      content: '',
    },
  })

  const onSubmit = () => {
    const values = getValues()

    // ----- GA4 event -----
    window.gtag('event', 'api_inquiry', {})
    // ---------------------

    apis.common.submitApiInquiry(values).then(response => {
      if (response.data.success) {
        let msg =
          '정상적으로 문의가 접수되었습니다. 휴일 제외 5일 이내에 연락드리겠습니다. 감사합니다.'

        if (i18n.language === 'en') {
          msg =
            'Your inquiry has been successfully submitted. We will contact you within 5 business days. Thank you.'
        }
        alert(msg)
        reset()
      } else {
        alert('오류가 발생하였습니다.')
      }
    })
  }

  const FIELD_KEY_WIDTH_LG = '10rem'
  const FIELD_KEY_WIDTH_XS = '7rem'

  return (
    <>
      <CenterAlignStack
        id="inquiry-form"
        sx={{
          '& .comment': {
            lineHeight: 1.2,
            fontSize: { lg: '1.6rem', xs: '1.4rem' },
            fontWeight: { lg: 500, xs: 400 },
          },
        }}
      >
        <Typography className="title">{t('api_info.inquiry_title')}</Typography>
        <Typography className="comment">{t('api_info.inquery_comment_1')}</Typography>
        <Typography className="comment" sx={{ textAlign: 'center' }}>
          {t('api_info.inquery_comment_2')}
          <Mobile>
            <br />
          </Mobile>{' '}
          {t('api_info.inquery_comment_3')}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <CenterAlignStack
            sx={{
              mt: { lg: '1rem', xs: '0.4rem' },
              '& .key-value-wrapper': {
                mt: { lg: '1.6rem', xs: '1.2rem' },
                ml: {
                  lg: `-${FIELD_KEY_WIDTH_LG}`,
                  // xs: `-${FIELD_KEY_WIDTH_XS}`
                },
              },
              '& span.key': {
                width: { lg: FIELD_KEY_WIDTH_LG, xs: FIELD_KEY_WIDTH_XS },
                textAlign: 'right',
                fontSize: { lg: '2.2rem', xs: '1.6rem' },
                fontWeight: 500,
                '&::after': {
                  content: '"*"',
                  color: theme => theme.palette.common.red,
                  marginLeft: '2px',
                },
              },

              '& .value-field': {
                '&.error': {
                  '& .MuiInputBase-root': {
                    borderColor: theme => `${theme.palette.error.main} !important`,
                    border: '1px solid',
                  },
                },
                '& input': {
                  background: 'white',
                  border: '1px solid #303030',
                  p: 0,
                  px: '1rem',
                  height: { lg: '4rem', xs: '2.8rem' },
                },
                '& textarea': {
                  background: 'white',
                  border: '1px solid #303030',
                  p: { lg: '1rem', xs: '0.5rem' },
                  height: { lg: '12rem !important', xs: '9.6rem !important' },
                },
                '& .MuiInputBase-root ': { p: 0 },
                px: { lg: '2rem', xs: '0.8rem' },

                width: { lg: '38.4rem', xs: '24.4rem' },
              },
            }}
          >
            {Object.keys(schema.fields).map(k => {
              if (schema.fields[k].type !== 'string') return null
              return (
                <CenterAlignStack className="key-value-wrapper" direction="row" key={k}>
                  <span className="key">{t(`api_info.inquiry_form_${k}`)}</span>

                  <FormTextField
                    className={`value-field ${errors[k] && 'error'} ${
                      k === 'content' && 'multiline'
                    }`}
                    name={k}
                    control={control}
                    errors={errors}
                    multiline={k === 'content'}
                    maxRows={k === 'content' ? 5 : 1}
                  />
                </CenterAlignStack>
              )
            })}

            <Box
              sx={{
                mt: { lg: '3.2rem', xs: '1.6rem' },
                mb: { lg: '2.4rem', xs: '1.6rem' },

                '& .error': {
                  '& .checkbox-label': {
                    color: theme => theme.palette.error.main,
                  },
                },

                '& svg': {
                  width: { lg: '2.4rem', xs: '1.6rem' },
                },
              }}
            >
              <FormCheckbox
                name="agree"
                control={control}
                errors={errors}
                className={`${errors.agree && 'error'}`}
                sx={{ maxWidth: { lg: 'unset', xs: '28rem' } }}
                label={
                  <Typography
                    onClick={() => {
                      setAgreeInfoDialogOpen(true)
                    }}
                    className="checkbox-label"
                    sx={{
                      fontSize: { lg: '2.2rem', xs: '1.6rem' },
                      fontWeight: 500,
                      '&::after': {
                        content: '"*"',
                        color: theme => theme.palette.common.red,
                        marginLeft: '2px',
                      },
                    }}
                  >
                    {t('api_info.inquiry_agree')}
                  </Typography>
                }
              />
            </Box>

            <Button
              variant="contained"
              disabled={!isValid}
              // type="submit"
              onClick={handleSubmit(onSubmit)}
              sx={{
                fontSize: { lg: '2.8rem', xs: '1.6rem' },
                fontWeight: 800,
                px: { lg: '9.1rem', xs: '5.2rem' },
              }}
            >
              {t('api_info.inquiry')}
            </Button>
          </CenterAlignStack>
        </form>

        <Desktop>
          <Dialog
            open={agreeInfoDialogOpen}
            onClose={() => {
              setAgreeInfoDialogOpen(false)
            }}
            sx={{ '& .MuiPaper-root': { borderRadius: '2rem' } }}
          >
            <DialogContent>
              {agreeDialogContent}

              <CenterAlignStack sx={{ width: '100%' }}>
                <Button
                  variant="contained"
                  sx={{
                    width: { lg: '14rem', xs: '12rem' },
                    height: { lg: '5rem', xs: '4.8rem' },
                    fontSize: { lg: '2rem', xs: '1.8rem' },
                    fontWeight: { lg: 800, xs: 800 },
                    borderRadius: '1rem',
                  }}
                  onClick={() => {
                    setAgreeInfoDialogOpen(false)
                  }}
                >
                  {t('button.close')}
                </Button>
              </CenterAlignStack>
            </DialogContent>
          </Dialog>
        </Desktop>

        <Mobile>
          <MobileSwipeableDrawer
            open={agreeInfoDialogOpen}
            onOpen={() => {}}
            onClose={() => {
              setAgreeInfoDialogOpen(false)
            }}
          >
            <Stack sx={{ px: '2rem' }}>{agreeDialogContent}</Stack>
            <CenterAlignStack sx={{ width: '100%', mb: '2.2rem' }}>
              <Button
                variant="contained"
                sx={{
                  width: { lg: '14rem', xs: '12rem' },
                  height: { lg: '5rem', xs: '4.8rem' },
                  fontSize: { lg: '2rem', xs: '1.8rem' },
                  fontWeight: { lg: 800, xs: 800 },
                  borderRadius: '1rem',
                }}
                onClick={() => {
                  setAgreeInfoDialogOpen(false)
                }}
              >
                {t('button.close')}
              </Button>
            </CenterAlignStack>
          </MobileSwipeableDrawer>
        </Mobile>
      </CenterAlignStack>
    </>
  )
}

const agreeDialogContent = (
  <Stack
    sx={{
      width: { lg: '34.6rem', xs: '32rem' },
      '& .title': {
        fontSize: '1.4rem',
        fontWeight: 700,
        mb: '0.4rem',
      },
      '& .content': {
        fontSize: '1.2rem',
        fontWeight: 400,
        lineHeight: 1.2,
      },
    }}
  >
    <Typography className="title">
      <Korean>개인정보 수집 및 이용 동의</Korean>
      <English>Consent to Collection and Use of Personal Information</English>
    </Typography>
    <Typography className="content" sx={{ mb: '2.4rem' }}>
      <Korean>
        {`고객이 입력한 개인정보는 '정보통신망 이용촉진 및 정보보호 등 에 관한 법률'에 따라
      주식회사 드랩이 수집, 이용 시 본인의 동의를 얻어야 하는 정보입니다. 입력하신
      개인정보는 아래의 목적으로만 사용되며, 이외의 용도로는 이용되지 않습니다. 드랩아트
      API 도입 문의 고객은 개인정보 수집 · 이용에 대하여 동의를 거부할 권리가 있으며,
      미동의 시 드랩아트 API 도입 문의를 하실 수 없습니다.`}
      </Korean>
      <English>
        {`According to the 'Act on Promotion of Information and Communications Network Utilization and Information Protection, etc.', Draph Co., Ltd. requires your consent to collect and use your personal information. The personal information you provide will be used solely for the purposes outlined below and will not be used for any other purposes. You have the right to refuse consent to the collection and use of your personal information. However, if you do not consent, you will not be able to inquire about the adoption of the Draph Art API.
`}
      </English>
    </Typography>

    <Typography className="title">
      <Korean>개인정보 수집 및 이용 목적</Korean>
      <English>Purpose of Collecting and Using Personal Information</English>
    </Typography>
    <Typography className="content" sx={{ mb: '2.4rem' }}>
      <Korean>드랩아트 API 도입 문의</Korean>
      <English>Inquiry about the adoption of the Draph Art API</English>
    </Typography>

    <Typography className="title">
      <Korean>수집하는 개인정보 항목</Korean>
      <English>Items of Personal Information Collected</English>
    </Typography>
    <Typography className="content" sx={{ mb: '2.4rem' }}>
      <Korean>이름, 이메일, 연락처</Korean>
      <English>Name, Email, Contact Number</English>
    </Typography>

    <Typography className="title">
      <Korean>개인정보 보유 및 이용 기간</Korean>
      <English>Retention and Use Period of Personal Information</English>
    </Typography>
    <Typography className="content" sx={{ mb: '2.4rem' }}>
      <Korean>
        드랩아트 API 도입 문의 등록 후 1년 (단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우
        법령에 따라 보관)
      </Korean>
      <English>
        1 year after the Draph Art API adoption inquiry registration (However, if required by
        relevant laws and regulations, the information will be retained according to those laws).
      </English>
    </Typography>
  </Stack>
)
