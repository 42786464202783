import React, { useEffect, useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import 'assets/css/Swiper.css'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { centerCrop } from 'react-image-crop'

// import required modules
import { FreeMode, Navigation, Thumbs, Pagination, Keyboard, Controller } from 'swiper'

import _, { slice } from 'lodash'
import {
  BsFillStarFill as StarIcon,
  BsFillPinFill as PinIcon,
  BsTrashFill as TrashIcon,
} from 'react-icons/bs'
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Typography,
  Paper,
  Divider,
  SwipeableDrawer,
  makeStyles,
} from '@mui/material'
import {
  GIFstandardDate,
  getPieceType,
  getS3ImageSrc,
  iOS,
  isKo,
  padZeros,
  sortPieces,
} from 'utils/common'
import { apis } from 'apis'

import { useLocation, useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import useConfirm from 'hooks/useConfirm'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import {
  backgroundAtom,
  backgroundDialogAtom,
  backgroundFetchInfoAtom,
  backgroundFilterAtom,
  backgroundLoadingAtom,
  defaultPortfolioAtom,
  tutorialAtom,
  userAtom,
  userLikedBgAtom,
  portfolioTypeAtom,
  currentMenuAtom,
} from 'atoms'
import { AiFillPushpin, AiOutlinePushpin, AiOutlineScissor } from 'react-icons/ai'
import { CenterAlignBox, CenterAlignStack } from 'components/CenterAlignContainer'
import { fetchBackground, refreshBackground } from 'pages/Background'

import { useSnackbar } from 'notistack'
import { AnimatePresence, motion } from 'framer-motion'
import {
  backgroundFilterSelector,
  creditPolicyDictSelector,
  isPortfolioOwnerSelector,
} from 'selector'
import { CreateBackground } from 'components/background/CreateBackground'
import { useTheme } from '@mui/system'
import {
  StarFilledIcon,
  StarOutlinedIcon,
  DownloadIcon,
  TrashCanIcon,
  TrashCanIcon2,
  CloseIcon,
  CropIcon,
  SizeControlIcon,
  BlueCircleCheckIcon,
  BadExampleIcon,
  GIFSideIcon,
} from 'theme/icon'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useMediaQuery } from 'react-responsive'
import { Tutorial } from 'theme/Tutorial'
import * as config from 'config'

import { ADDPIECE_SELECTOR } from './ArtworkList'
import {
  CustomTooltip,
  Loading,
  SpanDraphBlue,
  getGA4EventNameByPortfolioType,
  triggerGA4DownloadEvent,
} from 'components'
import useBackground from 'hooks/useBackground'
import { usePortfolioId } from 'hooks/usePortfoliId'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getDownloadPieceFileName } from '../../utils/common'

const IMAGE_VIEWER_WIDTH = 78
const IMAGE_VIEWER_HEIGHT = 44

export function ArtworksImageSwiperSlideDialog({
  open,
  setOpen,
  items,
  pieceIdx,
  setPieceIdx,
  artwork,
  setArtwork,
  setControlSizeDialog = () => {},
  refreshArtworks = () => {},
}) {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 800px)' })
  const defaultPortfolio = useRecoilValue(defaultPortfolioAtom)
  const portfolioType = useRecoilValue(portfolioTypeAtom)

  const isBannerPage = portfolioType === config.PORTFOLIO_TYPE_BANNER

  const isRemoveBgPage = portfolioType === config.PORTFOLIO_TYPE_REMOVEBG
  const isMannequinPage = portfolioType === config.PORTFOLIO_TYPE_MANNEQUIN
  const isFunFamePage = portfolioType === config.PORTFOLIO_TYPE_FAME
  const isBundlePage = portfolioType === config.PORTFOLIO_TYPE_BUNDLE
  const isModelBgPage = portfolioType === config.PORTFOLIO_TYPE_MODELBG

  const bannerMode = isBannerPage

  const portfolioId = portfolioType ? defaultPortfolio.id : useParams().portfolioId
  const [swiper, setSwiper] = useState(null)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const { showConfirm } = useConfirm()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [currentItem, setCurrentItem] = useState(null)
  const [loading, setLoading] = useRecoilState(backgroundLoadingAtom)
  const [user, setUser] = useRecoilState(userAtom)
  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const creditPolicy = useRecoilValue(creditPolicyDictSelector)
  const isOwner = useRecoilValue(isPortfolioOwnerSelector)
  const [viewGif, setViewGif] = useState(false)
  const [callGif, setCallGif] = useState(false)

  const theme = useTheme()
  const { t } = useTranslation()

  const [cropMode, setCropMode] = useState(false)
  const [completedCrop, setCompletedCrop] = useState()

  const [crop, setCrop] = useState()
  const [count, setCount] = useState(new Date())

  const [imgCropSize, setImgCropSize] = useState({})
  const cropImgRef = useRef(null)
  const canvasRef = useRef(null)

  const location = useLocation()

  const currentMenu = useRecoilValue(currentMenuAtom)

  useEffect(() => {
    if (open) {
      // ----- GA4 event -----
      window.gtag('event', 'image_viewer_imp', {
        menu: currentMenu?.id,
      })
      // ---------------------
    }
  }, [open])

  const checkUserCredit = async () => {
    const res = await apis.user.getCredit()
    const credit = res.data.credit
    setUser({ ...user, credit })
    return credit
  }

  const checkedURLforNoCache = path => {
    if (path?.includes(ADDPIECE_SELECTOR)) {
      return `${path}?w=${count}`
    }

    return path
  }

  const params = {
    modules: [Controller, Pagination, Navigation, Keyboard],
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: false,
    spaceBetween: 30,
    onSwiper: setSwiper,
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    slideToClickedSlide: !cropMode,
    onSlideChange: () => {
      setPieceIdx(swiper.realIndex)
      setCurrentItem(items[swiper.realIndex])
    },
    allowSlideNext: !cropMode, // Crop Mode가 비활성화된 경우에만 다음 슬라이드로 이동
    allowSlidePrev: !cropMode,
  }

  const thumbsParams = {
    modules: [Controller],
    slideToClickedSlide: !cropMode,
    slidesPerView: 6,
    centeredSlides: true,
    spaceBetween: 10,
    onSwiper: setThumbsSwiper,
    allowSlideNext: !cropMode, // Crop Mode가 비활성화된 경우에만 다음 슬라이드로 이동
    allowSlidePrev: !cropMode,
    style: {
      paddingTop: bannerMode ? '2rem' : '0rem',
      paddingBottom: '2rem',
    },
  }

  const thumbsParamsMobile = {
    modules: [Controller],
    slideToClickedSlide: true,
    slidesPerView: 2.6,
    centeredSlides: true,
    spaceBetween: 6,
    onSwiper: setThumbsSwiper,
    style: {
      paddingBottom: '2rem',
    },
  }

  const bgRemovedStyle = {
    background:
      'linear-gradient(45deg, lightgray 25%, transparent 25%), linear-gradient(-45deg, lightgray 25%, transparent 25%), linear-gradient(45deg, transparent 75%, lightgray 75%), linear-gradient(-45deg, white 75%, lightgray 75%)',
    backgroundSize: '20px 20px' /* 크기 조정 */,
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0',
  }

  useEffect(() => {
    if (swiper && thumbsSwiper) {
      swiper.controller.control = thumbsSwiper
      thumbsSwiper.controller.control = swiper
    }
  }, [swiper, thumbsSwiper])

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(pieceIdx, 200)
      setCurrentItem(items[swiper.realIndex])
      setPieceIdx(swiper.realIndex)
    }
  }, [swiper, items])

  // if (isDesktopOrLaptop) {
  //   useEffect(() => {
  //     if (swiper) {
  //       swiper.slideTo(pieceIdx, 200)
  //     }
  //     // if (swiper && backgroundList.length > 31) swiper.slideTo(backgroundList.length - 31, 0)
  //   }, [swiper])
  // } else {
  //   useEffect(() => {
  //     if (swiper) {
  //       swiper.slideTo(pieceIdx, 200)
  //     }
  //     // if (swiper && backgroundList.length > 31) swiper.slideTo(backgroundList.length - 31, 0)
  //   }, [swiper])
  // }

  const handleClose = () => {
    if (!tutorial.mode && open) {
      setOpen(false)
      history.back()
      setCropMode(false)
      setCrop(null)
    } else if (!tutorial.mode && !open) {
      setOpen(false)
      setCropMode(false)
      setCrop(null)
    }
  }

  const toggleStar = piece => {
    const changeTo = !userLikedBg.liked.includes(piece.bg_id)
    apis.background.likeBackground(piece.bg_id, changeTo).then(response => {
      const message = changeTo
        ? t('background_item.personal_added')
        : t('background_item.personal_removed')
      const variant = changeTo ? 'success' : 'error'
      enqueueSnackbar(message, { variant })
      apis.background.getLikedBackgrounds().then(response => {
        setUserLikedBg(response.data)
      })
    })

    // ----- GA4 event -----
    window.gtag('event', 'image_viewer_bg_add', {
      menu: currentMenu?.id,
      method: changeTo ? 'personal_add' : 'personal_remove',
    })
    // ---------------------
  }

  const togglePin = piece => {
    const changeTo = !userLikedBg.pinned.includes(piece.bg_id)

    apis.background.likeBackground(piece.bg_id, null, changeTo).then(response => {
      if (!response.data.success && response.data.message.toLowerCase() === 'pin_max') {
        enqueueSnackbar(t('background_item.max_pinned_added'), { variant: 'error' })
      } else {
        if (!changeTo) {
          enqueueSnackbar(t('background_item.pinned_removed'), { variant: 'error' })
        }
        if (changeTo) {
          enqueueSnackbar(t('background_item.pinned_added'), { variant: 'success' })
        }

        apis.background.getLikedBackgrounds().then(response => {
          setUserLikedBg(response.data)
        })
      }
    })

    // ----- GA4 event -----
    window.gtag('event', 'image_viewer_bg_add', {
      menu: currentMenu?.id,
      method: changeTo ? 'pin_add' : 'pin_remove',
    })
    // ---------------------
  }

  useEffect(() => {
    if (cropMode === true && swiper?.realIndex) {
      const { width, height, x, y } = imgCropSize[swiper?.realIndex]

      setCrop({ unit: 'px', x: 0, y: 0, width: width / 2, height: height / 2 })
    }
  }, [cropMode])

  // useEffect(() => {
  // console.log('cropMode', cropMode)
  // console.log('crop', crop)
  // console.log('cropImg', cropImgRef)
  // console.log('imgCropsize', imgCropSize)
  // console.log('cropMode', cropMode)
  // console.log('cropMode', cropMode)
  // console.log('cropMode', cropMode)
  // })
  const onLoadImage = idx => e => {
    const naturalWidth = e.target.naturalWidth
    const naturalHeight = e.target.naturalHeight

    const elHeight = e.target.height
    const elWidth = e.target.width
    const originalRatio = IMAGE_VIEWER_HEIGHT / IMAGE_VIEWER_WIDTH //  높이 / 너비 너비 비율
    const resizeRatio = elHeight / elWidth
    const isWidthLong = originalRatio > resizeRatio

    const isImageSmall =
      naturalHeight <= IMAGE_VIEWER_HEIGHT * 10 && naturalWidth <= IMAGE_VIEWER_WIDTH * 10

    const data = {
      [idx]: {
        width: isImageSmall ? naturalWidth : elWidth,
        height: isImageSmall ? naturalHeight : elHeight,
        isWidthLong,
        isImageSmall,
      },
    }

    setImgCropSize(prev => ({ ...prev, ...data }))
  }

  // useEffect(() => {
  //   console.log(imgCropSize)
  // })

  const cropDownload = piece => {
    const canvas = document.createElement('canvas')

    const context = canvas.getContext('2d')
    const { x, y, width, height } = crop

    const image = new Image()
    image.src = getS3ImageSrc(
      checkedURLforNoCache(items[swiper?.realIndex].path) + `?w=${Date.now().toString()}`
    )
    image.crossOrigin = 'anonymous'

    image.onload = () => {
      const size = imgCropSize[swiper?.realIndex]

      const scaleX = image.naturalWidth / size.width
      const scaleY = image.naturalHeight / size.height

      canvas.width = width * scaleX
      canvas.height = height * scaleY

      context.drawImage(
        image,
        x * scaleX,
        y * scaleY,
        width * scaleX,
        height * scaleY,
        0,
        0,
        width * scaleX,
        height * scaleY
      )

      const name = artwork.name.split('.')[0]

      canvas.toBlob(blob => {
        const file = new File([blob], `crop.jpg`, { type: 'image/jpeg' })

        // file을 사용하여 다운로드 또는 업로드 등의 작업 수행
        // 예시: 파일 다운로드
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(file)
        downloadLink.download = `${name}_cropped.jpg`
        downloadLink.click()
      }, 'image/jpeg')
    }
  }

  const download = async piece => {
    if (user.use_credit_on.includes('artwork_download')) {
      const credit = await checkUserCredit()

      if (credit < creditPolicy.artwork_download) {
        showConfirm({
          alertOnly: true,
          content: <Typography>{t('common.credit_warning')}</Typography>,
        })
        return
      }
    }

    const path = piece.path
    const artworkName = artwork.name.replace('.' + _.last(artwork.name.split('.')), '')
    const url = checkedURLforNoCache(getS3ImageSrc(path))

    const img = new Image()
    img.src = url

    const blob = await fetch(url + `?w=${Date.now().toString()}`).then(r => r.blob())

    const ext = _.last(_.first(_.last(url.split('/')).split('?')).split('.'))
    const idx = 1

    const fileName =
      piece.result_filename?.length > 0
        ? piece.result_filename
        : getDownloadPieceFileName({
            prefix: artworkName,
            idx: idx,
            width: img.width,
            height: img.height,
            ext: ext,
          })
    saveAs(blob, fileName)

    if (isOwner) {
      apis.portfolio
        .updateArtworkDownload(portfolioId, artwork.id, { paths: [path] })
        .then(response => {
          const d = response.data.total_download
          checkUserCredit()
          if (d) setArtwork({ ...artwork, download: d })
        })
    }

    const artworkConfig = JSON.parse(artwork.config)

    // ----- GA4 event -----
    if (config.DEFAULT_PORTFOLIO_TYPES.includes(portfolioType)) {
      const ev = getGA4EventNameByPortfolioType(portfolioType, 'download')
      triggerGA4DownloadEvent({
        eventName: ev,
        params: { count: 1 },
        method: 'dialog',
      })
    } else if (artworkConfig.flag_bg_expansion) {
      triggerGA4DownloadEvent({
        eventName: 'ai_canvas_exp_download',
        params: { count: 1 },
        method: 'dialog',
      })
    } else if (!portfolioType) {
      triggerGA4DownloadEvent({
        eventName: 'portfolio_download',
        artworkConfig,
        user,
        artwork,
        count: 1,
        method: 'dialog',
      })
    } else {
      triggerGA4DownloadEvent({
        artworkConfig,
        user,
        artwork,
        count: 1,
        method: 'dialog',
      })
    }

    // ---------------------
  }

  const handleDelete = path => {
    showConfirm({
      title: t('background_item.background_delete'),
      content: <p>{t('background_item.background_delete_comment')}</p>,
      onConfirm: () => {
        apis.portfolio
          .deletePiece(portfolioId, artwork.id, {
            data: { paths: [path] },
          })
          .then(response => {
            if (response.data?.id) {
              apis.portfolio.getArtwork(portfolioId, artwork.id).then(response => {
                const a = response.data
                a.pieces = sortPieces(a)

                setArtwork(a)
              })
            } else {
              refreshArtworks()
              handleClose()
            }
            // const a = response.data
            // if (a?.id) {
            //   a.pieces = sortPieces(a)
            //   setArtwork(a)
            // } else {
            //   refreshArtworks()
            //   handleClose()
            // }
          })

        // ----- GA4 event -----
        window.gtag('event', 'image_delete', {
          menu: currentMenu?.id,
          method: 'dialog',
          count: 1,
        })
        // ---------------------
      },
      onCancel: () => {},
    })
  }

  const onChangeHandler = event => {
    setCrop(event)
  }

  useEffect(() => {
    if (callGif) {
      swiper?.slideTo(artwork.pieces.length - 1, 200)
      setCallGif(false)
    }
  }, [artwork.pieces.length])

  const GIFUpload = () => {
    const body = {
      user_id: user.id.toString(),
      portfolio_id: portfolioId,
      artwork_id: artwork.id,
      gen_img_url_list: [getS3ImageSrc(currentItem.path)],
    }

    setLoading(true)

    apis.appfront
      .getAnimatedFile(body)
      .then(res => {
        setLoading(false)
        if (res.data.code === 200) {
          refreshArtworks()
          setCallGif(true)
        } else {
          return showConfirm({
            alertOnly: true,
            content: (
              <>
                <Typography>{t('upload_dialog.warning_5')}</Typography>
              </>
            ),
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })

    // ----- GA4 event -----
    window.gtag('event', portfolioType ? 'gif_begin' : 'portfolio_gif_begin', {
      method: 'dialog',
    })
    // ---------------------
  }

  useEffect(() => {
    if (!currentItem || !currentItem?.path) return setViewGif(false)

    if (!/imggen_result|simplegen_result/.test(currentItem?.path)) return setViewGif(false)

    const GIFableDate = moment(artwork?.created) - GIFstandardDate >= 0

    const currentGIFable =
      /imggen_result|simplegen_result/.test(currentItem?.path) &&
      !currentItem?.path.includes('animated')

    const piecesPath = []

    artwork.pieces?.forEach(p => {
      if (/imggen_result|simplegen_result/.test(p.path)) {
        piecesPath.push(p.path)
      }
    })

    const regex = /(.*)((imggen|simplegen)_result_(\d+)_(\d+))/

    const match = currentItem?.path?.match(regex)[0]

    const newString = match.replace(/(.*\/)(imggen|simplegen)/, (match, p1, p2) => {
      return p1 + 'animated_' + p2 // Prefix 'animated_'를 추가
    })

    setViewGif(currentGIFable && !piecesPath.includes(`${newString}.gif`) && GIFableDate)
  }, [currentItem])

  return (
    <>
      <Desktop>
        <Dialog
          open={!!open}
          id="image-swiper-dialog"
          onClose={handleClose}
          maxWidth="xl"
          PaperProps={{
            sx: { overflowY: 'unset', borderRadius: '10px' },
          }}
        >
          {!bannerMode && (
            <Box
              id="bg-like-control-box"
              sx={{
                width: '16.4rem',
                height: '10rem',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.common.white,
                position: 'absolute',
                right: '-18rem',
                boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
                borderRadius: '0px 20px 20px 0px',
                p:
                  currentItem?.path.includes('imggen') || currentItem?.path.includes('simplegen')
                    ? '0'
                    : '1.2rem 1.8rem 1.2rem 2rem',

                zIndex: 2,
              }}
              display={currentItem?.bg_id || viewGif ? 'flex' : 'none'}
            >
              {currentItem?.bg_id && (
                <CenterAlignStack sx={{ alignItems: 'flex-start', width: '100%' }}>
                  <CenterAlignBox sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      variant="text"
                      onClick={() => {
                        toggleStar(currentItem)
                      }}
                      sx={{
                        minWidth: 'auto',
                        fontSize: '1.5rem',
                        fontWeight: 500,
                        p: '0 0.4rem 0 0.4rem',
                        color: theme.palette.common.black,
                        '&:hover': {
                          backgroundColor: 'transparent',
                          '& svg': {
                            stroke: '#FFBD18', // 마우스 오버 시 변경할 색상
                          },
                        },
                      }}
                      startIcon={
                        userLikedBg.liked.includes(currentItem?.bg_id) ? (
                          <StarFilledIcon
                            sx={{ ml: '0.15rem', mr: '0.15rem', mb: '0.2rem', stroke: '#FFBD18' }}
                          />
                        ) : (
                          <StarOutlinedIcon
                            sx={{
                              width: '2.3rem',
                              height: '2.1rem',
                              fill: 'none',
                              stroke: theme.palette.common.gray,
                              strokeWidth: '0.19rem',
                              mb: '0.2rem',
                            }}
                          />
                        )
                      }
                    >
                      {userLikedBg.liked.includes(currentItem?.bg_id)
                        ? t('background_item.personal_removed_s')
                        : t('background_item.personal_added_s')}
                    </Button>
                  </CenterAlignBox>
                  <Divider flexItem sx={{ width: '100%', borderColor: '#909090', my: '1.2rem' }} />

                  <CenterAlignBox sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      variant="text"
                      onClick={() => {
                        togglePin(currentItem)
                      }}
                      sx={{
                        minWidth: 'auto',
                        fontSize: '1.5rem',
                        fontWeight: 500,
                        p: '0 0.4rem 0 0.4rem',
                        color: theme.palette.common.black,
                        '&:hover': {
                          backgroundColor: 'transparent',
                          '& svg': {
                            color: `${theme.palette.draph.blue} !important`, // 마우스 오버 시 변경할 색상
                          },
                        },
                      }}
                      startIcon={
                        userLikedBg.pinned.includes(currentItem?.bg_id) ? (
                          <AiFillPushpin
                            color={theme.palette.draph.blue}
                            style={{ width: '2.4rem', height: '2.4rem' }}
                          />
                        ) : (
                          <AiOutlinePushpin
                            color={theme.palette.common.gray}
                            style={{ width: '2.4rem', height: '2.4rem', strokeWidth: '16px' }}
                          />
                        )
                      }
                    >
                      {userLikedBg.pinned.includes(currentItem?.bg_id)
                        ? t('background_item.pinned_removed_s')
                        : t('background_item.pinned_added_s')}
                    </Button>
                  </CenterAlignBox>
                </CenterAlignStack>
              )}

              {viewGif && (
                <Box
                  sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={GIFUpload}
                >
                  <img
                    src="/static/images/gifImage.gif"
                    style={{ width: '10rem', height: '10rem' }}
                  />
                  <Typography
                    sx={{ flexGrow: 1, textAlign: 'center', fontSize: '1.4rem', fontWeight: 500 }}
                  >
                    {t('background_item.GIF_create')}
                  </Typography>
                </Box>
              )}
            </Box>
          )}

          <DialogContent sx={{ p: 0, width: '78rem', position: 'relative' }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                edge="end"
                onClick={handleClose}
                sx={{ margin: '1rem', marginRight: '1.5rem' }}
              >
                <CloseIcon sx={{ width: '12px', height: '12px' }} />
              </IconButton>
            </Box>
            <Swiper {...params} className="gallary">
              {items.map((piece, idx) => {
                const path = piece.path
                const img = getS3ImageSrc(checkedURLforNoCache(path))
                const isRemovedBg = path?.includes('bgremoved')

                return (
                  <SwiperSlide
                    key={`${img}_${idx}`}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      // background:
                      //   'linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(255, 255, 255, 0) 5%), linear-gradient(180deg, rgba(255, 255, 255, 0) 95%, rgba(0, 0, 0, 0.08) 100%)',
                      background: '#efefef',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '78rem',
                        height: '44rem',
                      }}
                    >
                      {!(cropMode && idx === swiper?.realIndex) ? (
                        <img
                          onLoad={onLoadImage(idx)}
                          src={img}
                          style={{
                            ...(imgCropSize[idx]?.isWidthLong
                              ? { width: '78rem' }
                              : { height: '44rem' }),
                            ...(imgCropSize[idx]?.isImageSmall && {
                              width: 'auto',
                              height: 'auto',
                            }),
                            objectFit: 'contain',
                            ...(isRemovedBg && bgRemovedStyle),
                            userSelect: 'none',
                          }}
                        />
                      ) : (
                        <ReactCrop
                          ruleOfThirds
                          crop={crop}
                          onChange={onChangeHandler}
                          // onComplete={c => setCompletedCrop(c)}
                        >
                          <img
                            ref={cropImgRef}
                            src={img}
                            style={{
                              ...(imgCropSize[idx]?.isWidthLong
                                ? { width: '78rem' }
                                : { height: '44rem' }),
                              ...(imgCropSize[idx]?.isImageSmall && {
                                width: 'auto',
                                height: 'auto',
                              }),
                              objectFit: 'contain',
                              ...(isRemovedBg && bgRemovedStyle),
                            }}
                            // crossOrigin="anonymous"
                          />
                        </ReactCrop>
                      )}
                    </Box>
                  </SwiperSlide>
                )
              })}
              {
                <div
                  title="next"
                  className="swiper-button-next swiper-button-white"
                  style={{ display: !cropMode ? '' : 'none' }}
                ></div>
              }
              {
                <div
                  title="prev"
                  className="swiper-button-prev swiper-button-white"
                  style={{ display: !cropMode ? '' : 'none' }}
                ></div>
              }
            </Swiper>

            {!cropMode && (
              <CenterAlignStack
                direction="row"
                sx={{
                  gap: '2rem',
                  position: 'absolute',
                  bottom: isBannerPage ? '21.5%' : '30.5%',
                  left: '50%',
                  zIndex: 1,
                  transform: 'translate(-50%,-50%)',
                }}
              >
                {/* <IconButton
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                    '& svg': {
                      stroke: theme.palette.draph.blue, // 마우스 오버 시 변경할 색상
                    },
                  },
                }}
                onClick={() => {
                  setCropMode(prev => !prev)
                }}
              >
                <CropIcon />
              </IconButton> */}
                <IconButton
                  sx={{
                    width: '4.8rem',
                    height: '4.8rem',
                    borderRadius: '10px',
                    backgroundColor: 'rgb(100, 100, 100, 0.4)',
                    opacity: 0.5,
                    '&:hover': { backgroundColor: 'rgb(100, 100, 100, 0.7)', opacity: 0.7 },
                    transition: 'all 0.2s ease-in-out',
                  }}
                  onClick={() => {
                    download(currentItem)
                  }}
                >
                  <DownloadIcon size="large" color="#fff" />
                </IconButton>

                {!bannerMode && (
                  <IconButton
                    sx={{
                      width: '4.8rem',
                      height: '4.8rem',
                      borderRadius: '10px',
                      backgroundColor: 'rgb(100, 100, 100, 0.4)',
                      opacity: 0.5,
                      '&:hover': { backgroundColor: 'rgb(100, 100, 100, 0.7)', opacity: 0.7 },
                      transition: 'all 0.2s ease-in-out',
                    }}
                    onClick={() => {
                      handleDelete(currentItem.path)
                    }}
                  >
                    <TrashCanIcon size="large" color="#fff" />
                  </IconButton>
                )}
              </CenterAlignStack>
            )}

            {!bannerMode && (
              <CenterAlignBox
                sx={{ height: '9.2rem', mb: '0.7rem', alignItems: 'center', gap: '2.8rem' }}
              >
                {!cropMode ? (
                  <>
                    <CustomTooltip
                      title={
                        !isOwner ? (
                          <p>&#128274;</p>
                        ) : currentItem?.pos_info?.fitted === 1 ? (
                          t('background_item.size_warning_1')
                        ) : currentItem?.pos_info === null || currentItem?.bg_id === null ? (
                          t('background_item.size_warning_2')
                        ) : null

                        // currentItem?.bg_id === null ||
                        // currentItem?.pos_info?.fitted === 1 ||
                        // currentItem?.pos_info === null
                        //   ? '누끼 또는 생성 배경의 경우 사용 불가'
                        //   : ''
                      }
                    >
                      <span>
                        <Button
                          variant="text"
                          startIcon={
                            <SizeControlIcon />
                            // <p>&#x1F6A7;</p>
                          }
                          // disabled={true}
                          sx={{
                            color: '#303030',
                            borderRadius: '20px',
                            height: ' 4.8rem',
                            boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.10)',
                            width: '20rem',
                            padding: 0,
                            fontSize: '1.8rem',
                            fontWeight: 600,
                            p: '0 !important',
                            '&.Mui-disabled': {
                              color: '#ABABAB',
                              '& svg': {
                                stroke: '#ABABAB',
                              },
                            },
                            '& svg': {
                              transition: 'all 0.3s',
                            },
                            '&:hover': {
                              color: theme.palette.draph.blue,
                              boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.10)',
                              backgroundColor: 'transparent',
                              '& svg': {
                                stroke: theme.palette.draph.blue, // 마우스 오버 시 변경할 색상
                              },
                            },
                          }}
                          onClick={() => {
                            handleClose()
                            setControlSizeDialog(true)

                            // ----- GA4 event -----
                            window.gtag('event', 'image_viewer_resize', {
                              menu: currentMenu?.id,
                            })
                            // ---------------------
                          }}
                          disabled={
                            !isOwner
                              ? true
                              : currentItem?.bg_id === null ||
                                currentItem?.pos_info === null ||
                                currentItem?.pos_info.fitted === 1
                          }
                        >
                          {t('button.size')}
                        </Button>
                      </span>
                    </CustomTooltip>
                    <Button
                      variant="text"
                      startIcon={<CropIcon />}
                      sx={{
                        color: '#303030',
                        borderRadius: '20px',
                        height: ' 4.8rem',
                        boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.10)',
                        width: '20rem',
                        padding: 0,
                        fontSize: '1.8rem',
                        fontWeight: 600,
                        '& svg': {
                          transition: 'all 0.3s',
                        },
                        '&:hover': {
                          color: theme.palette.draph.blue,
                          boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.10)',
                          backgroundColor: 'transparent',
                          '& svg': {
                            stroke: theme.palette.draph.blue, // 마우스 오버 시 변경할 색상
                          },
                        },
                      }}
                      onClick={() => {
                        setCrop({
                          unit: '%', // Can be 'px' or '%'
                          x: 0,
                          y: 0,
                          width: 50,
                          height: 50,
                        })
                        setCropMode(prev => !prev)
                        // ----- GA4 event -----
                        window.gtag('event', 'image_viewer_crop', {
                          menu: currentMenu?.id,
                        })
                        // ---------------------
                      }}
                    >
                      {t('button.crop')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      sx={{
                        fontSize: '2rem',
                        fontWeight: 500,
                        color: theme.palette.common.black,
                        borderRadius: '20px',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: theme.palette.draph.blue,
                        },
                      }}
                      startIcon={<BlueCircleCheckIcon style={{ width: '2rem', height: '2rem' }} />}
                      onClick={() => {
                        cropDownload(currentItem)
                      }}
                    >
                      {t('button.confirmation_1')}
                    </Button>
                    <Button
                      sx={{
                        fontSize: '2rem',
                        fontWeight: 500,
                        color: theme.palette.common.black,
                        borderRadius: '20px',

                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: theme.palette.common.red,
                        },
                      }}
                      startIcon={<BadExampleIcon style={{ width: '2rem', height: '2rem' }} />}
                      onClick={() => {
                        setCropMode(false)
                        setCrop(null)
                      }}
                    >
                      {' '}
                      {t('button.cancel')}
                    </Button>
                  </>
                )}
              </CenterAlignBox>
            )}

            {bannerMode ? (
              <CenterAlignBox sx={{ my: '2rem', gap: '2rem' }}>
                {items.map((piece, idx) => (
                  <img
                    key={idx}
                    src={getS3ImageSrc(checkedURLforNoCache(piece.path))}
                    style={{
                      width: '11.6rem',
                      height: '11.6rem',
                      objectFit: 'contain',
                      borderRadius: '10px',
                      border:
                        idx === swiper?.realIndex ? `4px solid ${theme.palette.draph.blue}` : null,
                    }}
                    onClick={() => {
                      swiper?.slideTo(idx, 200)
                      // setCurrentItem(items[swiper.realIndex])
                      // setPieceIdx(swiper.realIndex)
                    }}
                  />
                ))}
              </CenterAlignBox>
            ) : (
              <Swiper className="gallery-thumbs" {...thumbsParams}>
                {items.map((piece, idx) => (
                  <SwiperSlide key={`${getS3ImageSrc(checkedURLforNoCache(piece.path))}_${idx}`}>
                    <img
                      src={getS3ImageSrc(checkedURLforNoCache(piece.path))}
                      style={{
                        width: '11.6rem',
                        height: '11.6rem',
                        objectFit: 'conatin',

                        borderRadius: '10px',
                        border:
                          idx === swiper?.realIndex
                            ? `4px solid ${theme.palette.draph.blue}`
                            : null,
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </DialogContent>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={!!open}
          onOpen={() => {
            return open
          }}
          id="image-swiper-dialog"
          onClose={handleClose}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignBox
            sx={{
              mt: '1.6rem',
              mb: '4rem',
            }}
          >
            <Box
              sx={{
                width: '4rem',
                height: '0.4rem',
                backgroundColor: '#BCBCBC',
                borderRadius: '20px',
              }}
            ></Box>
          </CenterAlignBox>
          <Swiper {...params} className="gallary">
            {items?.map((piece, idx) => {
              const path = piece.path
              const img = getS3ImageSrc(checkedURLforNoCache(path))

              return (
                <SwiperSlide
                  key={`${img}_${idx}`}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Box sx={{ position: 'relative' }}>
                    <img
                      src={img}
                      style={{
                        // width: '78rem',
                        height: '36.1rem',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                </SwiperSlide>
              )
            })}
          </Swiper>
          {}
          <CenterAlignStack
            // direction="row"
            sx={{ height: '8rem', justifyContent: 'center', alignItems: 'center' }}
          >
            {iOS && (
              <CenterAlignBox>
                <Typography sx={{ fontSize: '1rem' }}>
                  {t('image_swiper.ios_comment_1_a')}
                  <SpanDraphBlue style={{ fontWeight: 600 }}>
                    {t('image_swiper.ios_comment_1_b')}
                  </SpanDraphBlue>
                  {t('image_swiper.ios_comment_1_c')}
                </Typography>
              </CenterAlignBox>
            )}
            <CenterAlignStack direction="row">
              <Tutorial step="15_5" setOpen={setOpen}>
                <IconButton
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                  onClick={() => {
                    download(currentItem)
                  }}
                >
                  <DownloadIcon size="large" color="#808080" />
                </IconButton>
              </Tutorial>
              <Divider orientation="vertical" sx={{ height: '2rem', mx: '1rem' }} />
              <IconButton
                onClick={() => {
                  handleDelete(currentItem.path)
                }}
                sx={{ '&:hover': { backgroundColor: 'transparent' } }}
              >
                <TrashCanIcon size="large" color="#808080" />
              </IconButton>

              {viewGif && (
                <>
                  <Divider orientation="vertical" sx={{ height: '2rem', mx: '1rem' }} />
                  <IconButton
                    onClick={GIFUpload}
                    sx={{
                      '&:hover': { backgroundColor: 'transparent' },
                      position: 'relative',
                    }}
                  >
                    <GIFSideIcon color="#808080" />
                    <Typography
                      sx={{
                        fontSize: '1.3rem',
                        fontWeight: 900,
                        position: 'absolute',
                        color: '#808080',
                      }}
                    >
                      GIF
                    </Typography>
                  </IconButton>
                </>
              )}
            </CenterAlignStack>
          </CenterAlignStack>
          <Swiper className="gallery-thumbs" {...thumbsParamsMobile}>
            {items?.map((piece, idx) => (
              <SwiperSlide key={`${getS3ImageSrc(checkedURLforNoCache(piece.path))}_${idx}`}>
                <img
                  src={getS3ImageSrc(checkedURLforNoCache(piece.path))}
                  style={{
                    width: '11.6rem',
                    height: '11.6rem',
                    objectFit: 'cover',

                    borderRadius: '10px',
                    border:
                      idx === swiper?.realIndex ? `4px solid ${theme.palette.draph.blue}` : null,
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </SwipeableDrawer>
      </Mobile>
    </>
  )
}

export function BackgroundImageSwiperSlideDialog() {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 800px)' })
  const { t, i18n } = useTranslation()
  const setBackgroundList = useSetRecoilState(backgroundAtom)
  const [fetchInfo, setFetchInfo] = useRecoilState(backgroundFetchInfoAtom)
  const [backgrounDialog, setBackgroundDialog] = useRecoilState(backgroundDialogAtom)
  const filter = useRecoilValue(backgroundFilterAtom)

  const { refreshBackground, fetchBackground } = useBackground()

  const filteredBackgroundList = useRecoilValue(backgroundFilterSelector)

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [currentBackground, setCurrentBackground] = useState(null)
  const [createBackgroundDialog, setCreateBackgroundDialog] = useState(false)

  const [swiper, setSwiper] = useState(null)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [userLikedBg, setUserLikedBg] = useRecoilState(userLikedBgAtom)

  const { showConfirm } = useConfirm()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()

  const params = {
    modules: [Controller, Pagination, Navigation, Keyboard],
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: false,
    spaceBetween: 30,
    onSwiper: setSwiper,
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },

    onSlideChange: () => {
      setCurrentSlideIndex(swiper.realIndex)
    },
  }

  const thumbsParams = {
    modules: [Controller],
    slideToClickedSlide: true,
    slidesPerView: 6,
    centeredSlides: true,
    spaceBetween: 10,
    onSwiper: setThumbsSwiper,
    style: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
  }

  const thumbsParamsMobile = {
    modules: [Controller],
    slideToClickedSlide: true,
    slidesPerView: 2.3,
    centeredSlides: true,
    spaceBetween: 6,
    onSwiper: setThumbsSwiper,
    style: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
  }

  useEffect(() => {
    setCurrentBackground(() =>
      filteredBackgroundList ? filteredBackgroundList[currentSlideIndex] : null
    )
  }, [swiper, currentSlideIndex, filteredBackgroundList])

  useEffect(() => {
    if (swiper && thumbsSwiper) {
      swiper.controller.control = thumbsSwiper
      thumbsSwiper.controller.control = swiper
    }
  }, [swiper, thumbsSwiper])

  // 흠 어거지... ㅠ

  if (isDesktopOrLaptop) {
    useEffect(() => {
      if (swiper) {
        swiper.slideTo(backgrounDialog.idx, 200)
      }
      // if (swiper && backgroundList.length > 31) swiper.slideTo(backgroundList.length - 31, 0)
    }, [swiper])
  } else {
    useEffect(() => {
      if (swiper) {
        swiper.slideTo(backgrounDialog.idx, 200)
      }
      // if (swiper && backgroundList.length > 31) swiper.slideTo(backgroundList.length - 31, 0)
    }, [backgrounDialog.idx])
  }

  // useEffect(() => {
  //   console.log(swiper?.activeIndex)
  //   setCurrentSlideIndex(swiper?.activeIndex)
  // }, [backgroundList])

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(currentSlideIndex, 0)
    }
  }, [filteredBackgroundList])

  const handleClose = () => {
    setBackgroundDialog(prev => ({ ...prev, open: false }))
    setCurrentBackground(null)
    setCurrentSlideIndex(0)
    refreshBackground()
  }

  const onReachHandle = () => {
    // 하나일 경우, 가장 끝이기도해서 계속 Background 없는데 붙이려함
    if (filteredBackgroundList?.length - 1 !== currentSlideIndex) {
      fetchBackground()
    }
  }

  const deleteBg = (background, idx) => () => {
    showConfirm({
      title: t('background_item.background_delete'),
      content: <p>{t('background_item.background_delete_comment')}</p>,
      onConfirm: () => {
        apis.background.deleteBackground(background.id).then(() => {
          // console.log('delete 성공')
          refreshBackground()
        })
      },
      onCancel: () => {},
    })
  }

  const toggleStar = bg => e => {
    apis.background.likeBackground(bg.id, !bg.liked).then(response => {
      if (bg.liked) {
        enqueueSnackbar(t('background_item.personal_removed'), { variant: 'error' })
      }
      if (!bg.liked) {
        enqueueSnackbar(t('background_item.personal_added'), { variant: 'success' })
      }
      refreshBackground()
    })
  }

  const togglePin = bg => e => {
    apis.background.likeBackground(bg.id, null, !bg.pinned).then(response => {
      if (!response.data.success && response.data.message.toLowerCase() === 'pin_max') {
        enqueueSnackbar(t('background_item.max_pinned_added'), { variant: 'error' })
      } else {
        if (bg.pinned) {
          enqueueSnackbar(t('background_item.pinned_removed'), { variant: 'error' })
        }
        if (!bg.pinned) {
          enqueueSnackbar(t('background_item.pinned_added'), { variant: 'success' })
        }
        refreshBackground()
      }
    })
  }

  const togglePinStar = bg => () => {
    apis.background.likeBackground(bg.id, false, false).then(response => {
      if (bg.pinned || bg.liked) {
        enqueueSnackbar(t('background_item.personal_pinned_removed'), { variant: 'error' })
      }

      refreshBackground()
    })
  }

  return (
    <>
      <Desktop>
        <Dialog
          open={backgrounDialog.open}
          id="image-swiper-dialog"
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              background: 'transparent',
              borderRadius: 0,
              boxShadow: 0,
              alignItems: 'center',
              overflow: 'visible',
            },
            '& .MuiDialog-paper': {
              overflowY: 'hidden',
              maxWidth: '70rem',
              minWidth: '70rem',
              minHeight: '68rem',
            },
          }}
        >
          <Box
            sx={{
              width: '16.4rem',
              height: '12.3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.common.white,
              position: 'absolute',
              right: '-18rem',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
              borderRadius: '0px 20px 0px 0px',
              p: '1.2rem 1.8rem 1.2rem 1.8rem',

              zIndex: 2,
            }}
          >
            <CenterAlignStack
              sx={{ alignItems: 'flex-start', ml: isKo(i18n) ? '0.5rem' : '0.9rem', width: '100%' }}
            >
              <CenterAlignBox sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <IconButton onClick={toggleStar(currentBackground)} sx={{ p: '0.6rem' }}>
                {!currentBackground?.liked ? (
                  <StarOutlinedIcon color={theme.palette.common.black} />
                ) : (
                  <StarFilledIcon />
                )}
              </IconButton> */}
                <Button
                  variant="text"
                  onClick={toggleStar(currentBackground)}
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 500,
                    p: '0 0.4rem 0 0.4rem',
                    color: theme.palette.common.black,

                    '&:hover': {
                      backgroundColor: 'transparent',
                      '& svg': {
                        stroke: '#FFBD18', // 마우스 오버 시 변경할 색상
                      },
                    },
                  }}
                  startIcon={
                    !currentBackground?.liked ? (
                      <StarOutlinedIcon
                        sx={{
                          width: '2.3rem',
                          height: '2.1rem',
                          fill: 'none',
                          stroke: theme.palette.common.gray,
                          strokeWidth: '0.19rem',
                          mb: '0.2rem',
                        }}
                      />
                    ) : (
                      <StarFilledIcon
                        sx={{ ml: '0.15rem', mr: '0.15rem', mb: '0.2rem', stroke: '#FFBD18' }}
                      />
                    )
                  }
                >
                  {!currentBackground?.liked
                    ? t('background_item.personal_added_s')
                    : t('background_item.personal_removed_s')}
                </Button>
              </CenterAlignBox>
              <Divider flexItem sx={{ width: '100%', borderColor: '#909090', my: '1.5rem' }} />

              <CenterAlignBox sx={{ display: 'flex', alignItems: 'center' }}>
                {/* <IconButton onClick={togglePin(currentBackground)} sx={{ p: '0.6rem' }}>
                {!currentBackground?.pinned ? (
                  <AiOutlinePushpin
                    color="#909090"
                    style={{ width: '2.3rem', height: '2.3rem', strokeWidth: '16px' }}
                  />
                ) : (
                  <AiFillPushpin
                    color={theme.palette.draph.blue}
                    style={{ width: '2.3rem', height: '2.3rem' }}
                  />
                )}
              </IconButton>
              <Typography sx={{ mr: '0.6rem' }}>
                {!currentBackground?.pinned ? '고정 배경 추가' : '고정 배경 해제'}
              </Typography> */}

                <Button
                  variant="text"
                  onClick={togglePin(currentBackground)}
                  sx={{
                    minWidth: 'auto',
                    fontSize: '1.5rem',
                    fontWeight: 500,
                    p: '0 0.4rem 0 0.4rem',
                    color: theme.palette.common.black,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      '& svg': {
                        color: `${theme.palette.draph.blue} !important`, // 마우스 오버 시 변경할 색상
                      },
                    },
                  }}
                  startIcon={
                    !currentBackground?.pinned ? (
                      <AiOutlinePushpin
                        color={theme.palette.common.gray}
                        style={{ width: '2.4rem', height: '2.4rem', strokeWidth: '16px' }}
                      />
                    ) : (
                      <AiFillPushpin
                        color={theme.palette.draph.blue}
                        style={{ width: '2.4rem', height: '2.4rem' }}
                      />
                    )
                  }
                >
                  {!currentBackground?.pinned
                    ? t('background_item.pinned_added_s')
                    : t('background_item.pinned_removed_s')}
                </Button>
              </CenterAlignBox>
            </CenterAlignStack>
          </Box>

          <Box
            sx={{
              width: '16.4rem',
              height: '4.8rem',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: theme.palette.common.white,
              position: 'absolute',
              right: '-18rem',
              top: '13.5rem',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
              borderRadius: '0px 0px 20px 0px',
              p: 1.4,
              zIndex: 2,
            }}
          >
            {' '}
            <CenterAlignBox sx={{ display: 'flex', alignItems: 'center' }}>
              {/* <IconButton onClick={deleteBg(currentBackground)} sx={{ p: '0.7rem' }}>
              <TrashCanIcon2 style={{ width: '2.0rem', height: '2.0rem' }} />
            </IconButton>
            <Typography sx={{ margin: '1.2rem', textAlign: 'center' }}>배경 삭제</Typography> */}

              <Button
                variant="text"
                onClick={
                  currentBackground?.own
                    ? deleteBg(currentBackground)
                    : togglePinStar(currentBackground)
                }
                sx={{
                  justifyContent: 'flex-start',
                  color: theme.palette.common.black,
                  width: '13rem',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    '& svg': {
                      stroke: `#FF2324 !important`, // 마우스 오버 시 변경할 색상
                    },
                  },
                }}
                startIcon={
                  <TrashCanIcon2
                    style={{ width: '2.0rem', height: '2.0rem', stroke: theme.palette.common.gray }}
                  />
                }
              >
                <Typography sx={{ ml: '0.4rem', fontSize: '1.5rem', fontWeight: 500 }}>
                  {t('background_item.delete_background')}
                </Typography>
              </Button>
            </CenterAlignBox>
          </Box>
          <DialogContent
            sx={{
              backgroundColor: theme.palette.common.white,
              p: 0,
              width: '70rem',
              borderRadius: '10px',
            }}
          >
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                edge="end"
                onClick={handleClose}
                sx={{ margin: '1rem', marginRight: '1.5rem' }}
              >
                <CloseIcon sx={{ width: '12px', height: '12px' }} />
              </IconButton>
            </Box>

            <Swiper {...params} className="gallary" onReachEnd={onReachHandle}>
              {filteredBackgroundList?.map((background, idx) => {
                const img = getS3ImageSrc(background.s3_url)

                return (
                  <SwiperSlide
                    key={`${background.id}_${idx}`}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Box sx={{ position: 'relative' }}>
                      <img
                        src={img}
                        // style={{ width: '40rem', height: '40rem', objectFit: 'cover' }}
                        style={{
                          width: '78rem',
                          height: '44rem',
                          objectFit: 'contain',
                        }}
                        onLoad={e => {
                          if (e.target.naturalWidth < e.target.naturalHeight) {
                            e.target.style.objectFit = 'contain'
                          }
                        }}
                      />
                    </Box>
                  </SwiperSlide>
                )
              })}
              <div title="next" className="swiper-button-next swiper-button-white"></div>
              <div title="prev" className="swiper-button-prev swiper-button-white"></div>
            </Swiper>

            <Swiper className="gallery-thumbs" {...thumbsParams}>
              {filteredBackgroundList?.map((background, idx) => (
                <SwiperSlide key={idx} style={{}}>
                  <img
                    src={getS3ImageSrc(background.s3_url)}
                    style={{
                      width: '10.5rem',
                      height: '10.5rem',
                      objectFit: 'cover',

                      borderRadius: '10px',
                      backgroundColor: theme.palette.common.white,
                      border:
                        idx === swiper?.realIndex ? `4px solid ${theme.palette.draph.blue}` : null,
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </DialogContent>
          <DialogActions sx={{ background: 'transparent' }}>
            <Box sx={{ pt: '3.5rem' }}>
              <Button
                sx={{
                  backgroundColor: theme.palette.common.white,
                  borderRadius: '30px',
                  width: '25rem',
                  height: '5.5rem',
                  borderWidth: 0,
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                  fontWeight: 800,
                  fontSize: '2rem',
                }}
                variant="outlined"
                onClick={() => {
                  setBackgroundDialog(prev => ({ ...prev, open: false }))
                  setCreateBackgroundDialog(true)
                }}
              >
                {t('background_item.AI_bg')}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Desktop>

      <Mobile>
        <SwipeableDrawer
          open={backgrounDialog.open}
          onOpen={() => {
            return backgrounDialog.open
          }}
          id="image-swiper-dialog"
          onClose={handleClose}
          anchor="bottom"
          sx={{
            '& .MuiDrawer-paperAnchorBottom': {
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
            },
          }}
          disableDiscovery={iOS}
          disableSwipeToOpen={true}
        >
          <CenterAlignBox
            sx={{
              mt: '1.6rem',
              mb: '4rem',
            }}
          >
            <Box
              sx={{
                width: '4rem',
                height: '0.4rem',
                backgroundColor: '#BCBCBC',
                borderRadius: '20px',
              }}
            ></Box>
          </CenterAlignBox>
          <Swiper {...params} className="gallary" onReachEnd={onReachHandle}>
            {filteredBackgroundList?.map((background, idx) => {
              const img = getS3ImageSrc(background.s3_url)

              return (
                <SwiperSlide
                  key={`${background.id}_${idx}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box sx={{ position: 'relative' }}>
                    <img
                      src={img}
                      // style={{ width: '40rem', height: '40rem', objectFit: 'cover' }}
                      style={{
                        width: 'auto',
                        height: '40rem',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                </SwiperSlide>
              )
            })}
          </Swiper>

          <Swiper className="gallery-thumbs" {...thumbsParamsMobile}>
            {filteredBackgroundList?.map((background, idx) => (
              <SwiperSlide key={idx} style={{}}>
                <img
                  src={getS3ImageSrc(background.s3_url)}
                  style={{
                    width: '10.5rem',
                    height: '10.5rem',
                    objectFit: 'cover',

                    borderRadius: '10px',
                    backgroundColor: theme.palette.common.white,
                    border:
                      idx === swiper?.realIndex ? `4px solid ${theme.palette.draph.blue}` : null,
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </SwipeableDrawer>
      </Mobile>
      <CreateBackground
        open={createBackgroundDialog}
        setOpen={setCreateBackgroundDialog}
        selectedImage={filteredBackgroundList ? filteredBackgroundList[currentSlideIndex] : null}
      />
    </>
  )
}
