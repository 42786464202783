import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Box,
  DialogActions,
  Stack,
  SwipeableDrawer,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { apis } from 'apis'
import { CenterAlignStack, CenterAlignBox } from 'components/CenterAlignContainer'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { FadeLoader as Loader } from 'react-spinners'
import {
  DialogButton,
  SearchInput,
  CustomPagination,
  UploadImageThumb,
  processInGroups,
} from 'components'

import { LoadingButton } from '@mui/lab'

import {
  BackButton,
  BackIcon,
  MobileArtworkGrayCloseIcon,
  SearchIcon,
  SelectedIcon,
  UndoIcon,
} from 'theme/icon'
import { useRecoilState, useRecoilValue } from 'recoil'
import { iOS, imageToFile } from 'utils/common'
import { keepShowingConfigAtom, uploadFilesAndUrlAtom } from 'atoms'

const IMG_BASE64 = 'data:image/png;base64,'

const UPLOAD_LIMIT = 20

const imageHeight = '14rem'

const ProductThumbnail = ({
  p,
  imageSrc,
  keyColor,
  productIdKey,
  productNameKey,
  showCheck,
  ...props
}) => (
  <CenterAlignStack sx={{ width: '14rem', cursor: 'pointer', position: 'relative' }} {...props}>
    {showCheck && (
      <CenterAlignBox
        width="100%"
        height={imageHeight}
        sx={{
          background: 'rgba(48, 48, 48, 0.3)',
          position: 'absolute',
          top: 0,
          alignItems: 'center',
        }}
      >
        <SelectedIcon
          sx={{
            width: { lg: '8rem', xs: '8rem' },
            height: { lg: '8rem', xs: '8rem' },
          }}
        />
      </CenterAlignBox>
    )}
    <img src={imageSrc} style={{ objectFit: 'cover', width: '100%', height: imageHeight }} />
    <Typography
      sx={{
        color: theme => (showCheck ? keyColor : theme.palette.common.black),
        fontSize: '1.4rem',
        fontWeight: 700,
        my: '0.1rem',
      }}
    >
      {p[productIdKey]}
    </Typography>
    <Typography
      sx={{
        color: theme => (showCheck ? keyColor : theme.palette.common.black),
        width: '100%',
        height: { lg: '4.2rem', xs: '1.4rem' },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: { lg: '1.4rem' },
        fontSize: '1.2rem',
        fontWeight: { lg: 400 },
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
      }}
    >
      {p[productNameKey]}
    </Typography>
  </CenterAlignStack>
)

const ErrorTriangleIcon = (
  <svg width="84" height="76" viewBox="0 0 84 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M79.9558 61.3404L48.7807 6.93976C48.1009 5.74374 47.1152 4.74892 45.924 4.05679C44.7328 3.36466 43.3788 3 42.0001 3C40.6214 3 39.2674 3.36466 38.0762 4.05679C36.885 4.74892 35.8993 5.74374 35.2195 6.93976L4.04435 61.3404C3.35726 62.527 2.99698 63.8736 3.00002 65.2437C3.00306 66.6139 3.36932 67.9589 4.06168 69.1424C4.75403 70.3259 5.74787 71.3058 6.94245 71.9829C8.13704 72.66 9.4899 73.0101 10.8639 72.9977H73.2142C74.5816 72.9963 75.9246 72.6362 77.1083 71.9536C78.292 71.2709 79.2747 70.2897 79.9578 69.1085C80.6409 67.9273 81.0003 66.5877 81 65.2242C80.9996 63.8607 80.6395 62.5213 79.9558 61.3404Z"
      stroke="#FF2323"
      strokeOpacity="0.5"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.0547 30.7739V46.317"
      stroke="#FF2323"
      strokeOpacity="0.5"
      strokeWidth="6"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M42.0547 56.9346H42.0956"
      stroke="#FF2323"
      strokeOpacity="0.5"
      strokeWidth="6"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)

export function CommerceProductUpload({
  open,
  setOpen,
  uploading,
  setUploading,
  titleText,
  commerceStoreName,
  handleUpload,
  keyColor,
  imageFieldKey,
  productIdKey,
  productNameKey,
  uploadEventName,
  getProductApi = () => {},
}) {
  const isMobile = useMobileMediaQuery()

  const [step, setStep] = useState(1)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(isMobile ? 4 : 10)

  const [totalProducts, setTotalProducts] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [cancelRequest, setCancelRequest] = useState()
  const [selected, setSelected] = useState([])
  const [products, setProducts] = useState([])
  const [pageData, setPageData] = useState([])
  const [searchText, setSearchText] = useState('')

  const [mobileSearchState, setMobileSearchState] = useState(false)
  const [isSearch, setIsSearch] = useState(false)

  const theme = useTheme()

  const resetAllState = () => {
    setIsLoading(false)
    setError(false)
    setSearchText('')
    setProducts([])
    setPageData([])
    setSelected([])
    setStep(1)
    setPage(1)
    setTotalPages(1)
  }

  const fetchData = (search = false, reset = false) => {
    if (!open) return

    setIsLoading(true)
    if (searchText.replace(/\s/g, '').length > 0) {
      setProducts([])
    }

    const [req, cancel] = getProductApi({
      search: reset ? '' : searchText.replace(/\s/g, ''),
      page,
      pageSize,
      withCancelToken: true,
    })
    setCancelRequest(cancel)

    if (search) setPage(1)
    req.then(response => {
      setIsLoading(false)
      if (response.data.products) {
        setError(false)
        const d = response.data
        setPageData(d.products ?? [])
        setTotalPages(d.total_pages ?? 0)
        setTotalProducts(d.total_products ?? [])

        const p = [...products]
        p[page - 1] = d.products
        setProducts(p)
      } else {
        setProducts([])
        setPageData([])
        setError(true)
      }

      setIsSearch(search)
    })
  }

  useEffect(() => {
    if (open) {
      fetchData()
      // setIsLoading(true)
      // setError(true)
    } else {
      if (cancelRequest) {
        cancelRequest.cancel()
      }

      resetAllState()
    }
  }, [open])

  useEffect(() => {
    if (!isLoading) {
      if (products[page - 1]?.length > 0) {
        // 이전에 로드된 페이지는 새로 불러오지 않고 활용
        setPageData(products[page - 1])
        return
      }
      fetchData()
    }
  }, [page])

  const handleSelect = p => e => {
    if (selected.some(item => item[productIdKey] === p[productIdKey])) {
      // 제거
      const targetIndex = selected.findIndex(item => item[productIdKey] === p[productIdKey])
      if (targetIndex > -1) {
        const s = [...selected]
        s.splice(targetIndex, 1)
        setSelected(s)
      }
    } else {
      if (selected.length < UPLOAD_LIMIT) {
        // 추가

        setSelected(prev => [...prev, p])
      }
    }
  }

  const searchProduct = () => {
    fetchData(true)

    if (products.length !== 0) {
      setMobileSearchState(false)
    }
  }

  // const handleUpload = async () => {
  //   if (!uploading) {
  //     setIsLoading(true)
  //     setUploading(true)

  //     console.log(selected.map(p => p[imageFieldKey]))

  //     const files = []

  //     const promises = selected.map(p => {
  //       const func = async () => {
  //         if (!p[imageFieldKey]) return
  //         const name = p[imageFieldKey]?.split('/').at(-1)
  //         const blob = await fetch(p[imageFieldKey]).then(r => r.blob())
  //         console.log(blob)
  //         const file = new File([blob], `${p[productIdKey]}_${name}`, { type: blob.type })

  //         files.push(file)
  //       }

  //       return func
  //     })

  //     await processInGroups(promises)
  //     uploadImage(files, uploadEventName)
  //     setOpen(false)
  //   }
  // }

  const resetProduct = () => {
    setSearchText('')
    fetchData(false, true)
  }

  return (
    <>
      {step === 1 ? (
        <>
          <Box sx={{ pt: { lg: '3.2rem', xs: '1rem' } }}>
            <CenterAlignStack sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontSize: { lg: '2.2rem', xs: '1.6rem' },
                  fontWeight: { lg: 700, xs: 800 },
                  lineHeight: 'normal',
                }}
              >
                {titleText}
                {!isLoading && !error && (
                  <>
                    (<span style={{ color: keyColor }}>{selected.length}</span>/{UPLOAD_LIMIT})
                  </>
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '1.5rem', xs: '1.3rem' },
                  fontWeight: { lg: 400 },
                  color: '#595959',
                }}
              >
                배경을 바꾸고 싶은 이미지를 선택 후 업로드하세요{' '}
                {!isLoading && !error && ` (최대 ${UPLOAD_LIMIT}장)`}
              </Typography>
            </CenterAlignStack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: '1 1 auto',
              m: { lg: '0', xs: 0 },
              px: { lg: '4rem', xs: '0' },
            }}
          >
            {isLoading ? (
              <>
                <CenterAlignStack sx={{ alignItems: 'center', p: '12.3rem 0 18.7rem 0' }}>
                  <Box
                    sx={{
                      '& #loader': isMobile ? { top: '2.1rem', left: '3.2rem' } : {},
                    }}
                  >
                    <Loader
                      id="loader"
                      color={keyColor}
                      width={8}
                      height={30}
                      margin={17}
                      radius={10}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { lg: '1.8rem', xs: '1.5rem' },
                      fontWeight: { lg: 700, xs: 800 },
                      mt: { lg: '4.5rem', xs: '4.5rem' },
                      '& .color-point': { color: keyColor },
                      textAlign: ' center',
                      lineHeight: 'normal',
                    }}
                  >
                    <span className="color-point">{commerceStoreName} </span> {isMobile && <br />}
                    상품 이미지를 불러오고 있습니다
                  </Typography>
                </CenterAlignStack>
              </>
            ) : error ? (
              <>
                <CenterAlignStack
                  sx={{
                    alignItems: 'center',
                    p: '13rem 0 19.3rem 0',

                    '& .MuiTypography-root': {
                      lineHeight: { lg: '2.2rem' },
                      fontSize: { lg: '1.8rem' },
                      fontWeight: { lg: 500 },
                    },
                    '& svg': {
                      mb: '1.5rem',
                    },
                  }}
                >
                  {ErrorTriangleIcon}
                  <Typography
                    textAlign="center"
                    sx={{
                      fontSize: { lg: '1.8rem', xs: '1.5rem' },
                      fontWeight: 500,
                      lineHeight: 'normal',
                    }}
                  >
                    요청이 반복되어 상품 이미지를 불러오는 중 {isMobile && <br />}문제가
                    발생하였습니다. {!isMobile && <br />} 잠시 후 다시 시도해주세요.
                  </Typography>
                </CenterAlignStack>
              </>
            ) : (
              <>
                <CenterAlignStack
                  sx={{
                    width: '100%',
                    p: { lg: 0, xs: '2.4rem 0' },
                  }}
                >
                  <Grid
                    container
                    columns={10}
                    sx={{
                      width: { lg: '100%', xs: '30.4rem' },
                      minHeight: { lg: '46rem', xs: '42.8rem' },
                    }}
                    spacing={{ lg: 0, xs: 2 }}
                  >
                    {pageData.length ? (
                      pageData.map((p, i) => (
                        <Grid
                          key={p.product_no}
                          item
                          lg={2}
                          md={5}
                          sm={5}
                          xs={5}
                          display="flex"
                          justifyContent={'space-evenly'}
                          sx={{
                            height: { lg: '23rem', xs: 'auto' },
                          }}
                        >
                          <ProductThumbnail
                            p={p}
                            imageSrc={p[imageFieldKey]}
                            productIdKey={productIdKey}
                            productNameKey={productNameKey}
                            keyColor={theme.palette.cafe24}
                            onClick={handleSelect(p)}
                            showCheck={selected.some(
                              item => item[productIdKey] === p[productIdKey]
                            )}
                          />
                        </Grid>
                      ))
                    ) : (
                      <EmptyProducts isSearch={isSearch} resetProduct={resetProduct} />
                    )}
                  </Grid>

                  <Stack
                    direction="row"
                    sx={{
                      width: '100%',
                      height: { lg: '4.5rem' },
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { lg: 'flex-start', xs: 'center' },
                    }}
                  >
                    <Desktop>
                      <div style={{ width: '22rem', background: 'pink' }}></div>
                      <Box
                        sx={{
                          width: { lg: 'calc(100% - 44rem)' },
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <CustomPagination
                          count={totalPages}
                          page={page}
                          onChange={(e, p) => {
                            setPage(p)
                          }}
                        />
                      </Box>

                      <div
                        style={{
                          width: '22rem',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          padding: '0.4rem',
                        }}
                      >
                        <SearchInputComponent
                          searchText={searchText}
                          setSearchText={setSearchText}
                          searchProduct={searchProduct}
                          resetProduct={resetProduct}
                          mobileSearchState={mobileSearchState}
                          setMobileSearchState={setMobileSearchState}
                          fetchData={fetchData}
                          products={products}
                          keyColor={theme.palette.cafe24}
                        />
                      </div>
                    </Desktop>
                    <Mobile>
                      <CenterAlignBox sx={{ mt: '3.5rem' }}>
                        <CustomPagination
                          count={totalPages}
                          page={page}
                          onChange={(e, p) => {
                            setPage(p)
                          }}
                        />
                      </CenterAlignBox>
                    </Mobile>
                  </Stack>
                </CenterAlignStack>
              </>
            )}
          </Box>

          <Box
            sx={{
              p: { lg: '0.8rem' },
              pb: { lg: '3.2rem' },
            }}
          >
            <CenterAlignStack sx={{ width: '100%', mb: { lg: 0, xs: '2.2rem' } }}>
              <Desktop>
                <DialogButton
                  handleClose={() => {
                    setOpen(false)
                  }}
                  actionText={error ? '다시 시도' : '다음'}
                  actionprops={{ disabled: isLoading || error || selected.length < 1 }}
                  actionSx={{ background: keyColor }}
                  handleAction={
                    error
                      ? () => {
                          fetchData()
                        }
                      : () => {
                          setStep(2)
                        }
                  }
                />
              </Desktop>
              <Mobile>
                {!mobileSearchState ? (
                  <CenterAlignBox sx={{ gap: '1.2rem' }}>
                    <Box sx={{ width: '4.8rem', height: '4.8rem' }}></Box>
                    <Button
                      onClick={
                        error
                          ? () => {
                              fetchData()
                            }
                          : () => {
                              setStep(2)
                            }
                      }
                      sx={{
                        fontSize: '1.8rem',
                        width: '16rem',
                        height: '4.8rem',

                        background: keyColor,
                      }}
                      disabled={isLoading || error || selected.length < 1}
                      variant="contained"
                    >
                      {error ? '다시 시도' : '다음'}
                    </Button>
                    <Button
                      variant="text"
                      sx={{
                        minWidth: 'auto',
                        width: '4.8rem',
                        height: '4.8rem',
                        border: `1px solid ${keyColor}`,
                        p: 0,
                        '&.Mui-disabled': {
                          border: `1px solid ${theme.palette.action.disabledBackground}`,
                          '& path': {
                            stroke: theme.palette.action.disabledBackground,
                          },
                        },
                      }}
                      disabled={isLoading || error}
                      onClick={() => {
                        setMobileSearchState(!mobileSearchState)
                      }}
                    >
                      <SearchIcon color={keyColor} />
                    </Button>
                  </CenterAlignBox>
                ) : (
                  <SearchInputComponent
                    searchText={searchText}
                    setSearchText={setSearchText}
                    searchProduct={searchProduct}
                    resetProduct={resetProduct}
                    mobileSearchState={mobileSearchState}
                    setMobileSearchState={setMobileSearchState}
                    fetchData={fetchData}
                    keyColor={theme.palette.cafe24}
                  />
                )}
              </Mobile>
            </CenterAlignStack>
          </Box>
        </>
      ) : step === 2 ? (
        <>
          <Box sx={{ pt: { lg: '3.2rem', xs: 0 } }}>
            <CenterAlignStack sx={{ width: '100%' }}>
              <Typography
                sx={{ fontSize: { lg: '2.2rem', xs: '1.6rem' }, fontWeight: { lg: 700, xs: 800 } }}
              >
                새 이미지 만들기
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '1.5rem', xs: '1.2rem' },
                  fontWeight: { lg: 400 },
                  color: '#595959',
                }}
              >
                {commerceStoreName} 상품 대표 이미지로 새로운 사진을 생성해드립니다.
              </Typography>
            </CenterAlignStack>
          </Box>
          <DialogContent
            sx={{
              p: 0,
            }}
          >
            <CenterAlignStack sx={{ width: '100%', height: '100%', justifyContent: 'center' }}>
              <CenterAlignStack
                sx={{
                  width: '100%',
                  height: '100%',
                  mt: { lg: '3rem', xs: '2.4rem' },
                  mb: { lg: '3.9rem', xs: '3rem' },
                  px: { lg: '4rem', xs: '0' },
                  py: { lg: '5rem', xs: '0' },
                  justifyContent: 'center',
                }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: 'center',
                    columnGap: { lg: 1.2, xs: 0.4 },
                    rowGap: { lg: 1.3, xs: 0.4 },
                  }}
                  column={10}
                >
                  {selected.map((p, i) => (
                    <UploadImageThumb
                      key={i}
                      url={p[imageFieldKey]}
                      idx={i}
                      files={selected}
                      setFiles={setSelected}
                    />
                  ))}
                </Grid>
              </CenterAlignStack>
            </CenterAlignStack>
          </DialogContent>

          <DialogActions
            sx={{
              pt: { lg: '0.8rem', xs: '2rem' },
              pb: { lg: '3.2rem', xs: '2.2rem' },
            }}
          >
            <CenterAlignStack
              sx={{ width: '100%', justifyContent: 'center', position: 'relative' }}
              direction="row"
            >
              <IconButton
                sx={{
                  position: ' absolute',
                  bottom: 0,
                  left: { lg: '5rem', xs: '1.2rem' },
                  borderRadius: '1rem',
                  boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
                  width: '5rem',
                  height: '5rem',
                }}
                onClick={() => {
                  setStep(1)
                }}
              >
                <BackIcon style={{ width: '1rem', height: '2rem' }} />
              </IconButton>
              <Desktop>
                {' '}
                <DialogButton
                  handleClose={() => {
                    setOpen(false)
                  }}
                  actionText="업로드"
                  actionProps={{ disabled: isLoading || error || selected.length < 1 }}
                  actionSx={{ background: keyColor }}
                  handleAction={() => {
                    handleUpload(selected)
                  }}
                />
              </Desktop>
              <Mobile>
                <Button
                  onClick={() => {
                    handleUpload(selected)
                  }}
                  sx={{
                    fontSize: '1.8rem',
                    width: '16rem',
                    height: '4.8rem',

                    background: keyColor,
                  }}
                  disabled={isLoading || error || selected.length < 1}
                  variant="contained"
                >
                  업로드
                </Button>
              </Mobile>
            </CenterAlignStack>
          </DialogActions>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

const EmptyProducts = ({ isSearch, resetProduct }) => {
  const isMobile = useMobileMediaQuery()
  const theme = useTheme()

  return (
    <CenterAlignStack
      sx={{
        width: '100%',
        justifyContent: 'center',
        gap: { lg: '1.6rem' },
        marginLeft: isMobile ? '2.5rem' : '',
      }}
    >
      <Typography sx={{ fontSize: { lg: '1.8rem', xs: '1.6rem' }, textAlign: 'center' }}>
        {isSearch ? (
          <span>
            검색 결과가 없습니다.
            <br />
            검색어를 다시 입력해주세요
          </span>
        ) : (
          <span>상품이 없습니다. </span>
        )}
      </Typography>

      <Desktop>
        <IconButton
          onClick={resetProduct}
          sx={{
            p: '0',
            '&:hover': {
              backgroundColor: 'transparent',
              '& path': {
                stroke: theme.palette.draph.blue,
                transition: 'stroke 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              },
              '& rect': {
                fill: theme.palette.draph.lighterblue,
              },
            },
          }}
        >
          <UndoIcon />
        </IconButton>
      </Desktop>
    </CenterAlignStack>
  )
}

export const SearchInputComponent = ({
  searchText,
  setSearchText,
  searchProduct,
  resetProduct,
  mobileSearchState,
  setMobileSearchState,
  fetchData,
  products,
  keyColor,
}) => {
  const theme = useTheme()
  const isMobile = useMobileMediaQuery()

  return (
    <CenterAlignBox sx={{ minHeight: '4.8rem', gap: { lg: 0, xs: '1.6rem' } }}>
      <Mobile>
        <IconButton
          onClick={resetProduct}
          sx={{
            p: '0',
            '&:hover': {
              backgroundColor: 'transparent',
              '& path': {
                stroke: theme.palette.draph.blue,
                transition: 'stroke 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              },
              '& rect': {
                fill: theme.palette.draph.lighterblue,
              },
            },
          }}
        >
          <UndoIcon />
        </IconButton>
      </Mobile>

      <SearchInput
        placeholder="상품번호로 검색"
        value={searchText}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            searchProduct()
          }
        }}
        onChange={e => {
          setSearchText(e.target.value)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ cursor: 'pointer' }}
              onClick={
                isMobile
                  ? () => {
                      setSearchText('')
                      if (products.length === 0) {
                        resetProduct()
                      }
                    }
                  : searchProduct
              }
            >
              {isMobile ? (
                <MobileArtworkGrayCloseIcon strokeWidth={1} />
              ) : (
                <SearchIcon id="search-icon" />
              )}
            </InputAdornment>
          ),
        }}
        sx={{
          mt: { lg: 0, xs: '0.5rem' },
          '& .MuiInputBase-root': {
            width: { lg: '18rem', xs: '20.4rem' },
            height: '3.6rem',

            '& fieldset': {
              borderWidth: '0.2rem',
              borderColor: theme.palette.common.gray,
            },

            '&.Mui-focused fieldset': {
              borderColor: keyColor,
            },

            '& #search-icon path': {
              stroke: theme.palette.common.gray,
            },
          },
        }}
      />

      <Mobile>
        <Button
          variant="text"
          sx={{
            minWidth: 'auto',
            width: '3.6rem',
            height: '3.6rem',
            mt: '0.5rem',
            border: `1px solid ${keyColor}`,
            p: 0,
            '&.Mui-disabled': {
              border: `1px solid ${theme.palette.action.disabledBackground}`,
              '& path': {
                stroke: theme.palette.action.disabledBackground,
              },
            },
          }}
          onClick={() => {
            setMobileSearchState(!mobileSearchState)
            fetchData(true)
          }}
        >
          <SearchIcon color={keyColor} />
        </Button>
      </Mobile>
    </CenterAlignBox>
  )
}

export function CommerceProductUploadV2({
  open,
  setOpen,
  titleText,
  commerceStoreName,
  keyColor,
  imageFieldKey,
  productIdKey,
  productNameKey,
  getProductApi = () => {},
}) {
  const isMobile = useMobileMediaQuery()

  const [files, setFiles] = useRecoilState(uploadFilesAndUrlAtom)
  const [keepShowingConfig, setKeepShowingConfig] = useRecoilState(keepShowingConfigAtom)

  const [step, setStep] = useState(1)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(isMobile ? 4 : 10)

  const [totalProducts, setTotalProducts] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [cancelRequest, setCancelRequest] = useState()
  const [selected, setSelected] = useState([])
  const [products, setProducts] = useState([])
  const [pageData, setPageData] = useState([])
  const [searchText, setSearchText] = useState('')

  const [mobileSearchState, setMobileSearchState] = useState(false)
  const [isSearch, setIsSearch] = useState(false)

  const theme = useTheme()

  const resetAllState = () => {
    setIsLoading(false)
    setError(false)
    setSearchText('')
    setProducts([])
    setPageData([])
    setSelected([])
    setStep(1)
    setPage(1)
    setTotalPages(1)
  }

  const fetchData = (search = false, reset = false) => {
    if (!open) return

    setIsLoading(true)
    if (searchText.replace(/\s/g, '').length > 0) {
      setProducts([])
    }

    const [req, cancel] = getProductApi({
      search: reset ? '' : searchText.replace(/\s/g, ''),
      page,
      pageSize,
      withCancelToken: true,
    })
    setCancelRequest(cancel)

    if (search) setPage(1)
    req.then(response => {
      setIsLoading(false)
      if (response.data.products) {
        setError(false)
        const d = response.data
        setPageData(d.products ?? [])
        setTotalPages(d.total_pages ?? 0)
        setTotalProducts(d.total_products ?? [])

        const p = [...products]
        p[page - 1] = d.products
        setProducts(p)
      } else {
        setProducts([])
        setPageData([])
        setError(true)
      }

      setIsSearch(search)
    })
  }

  useEffect(() => {
    if (open) {
      fetchData()
      // setIsLoading(true)
      // setError(true)
    } else {
      if (cancelRequest) {
        cancelRequest.cancel()
      }

      resetAllState()
    }
  }, [open])

  useEffect(() => {
    if (!isLoading) {
      if (products[page - 1]?.length > 0) {
        // 이전에 로드된 페이지는 새로 불러오지 않고 활용
        setPageData(products[page - 1])
        return
      }
      fetchData()
    }
  }, [page])

  const handleSelect = p => e => {
    if (selected.some(item => item[productIdKey] === p[productIdKey])) {
      // 제거
      const targetIndex = selected.findIndex(item => item[productIdKey] === p[productIdKey])
      if (targetIndex > -1) {
        const s = [...selected]
        s.splice(targetIndex, 1)
        setSelected(s)
      }
    } else {
      if (selected.length < UPLOAD_LIMIT) {
        // 추가

        setSelected(prev => [...prev, p])
      }
    }
  }

  const searchProduct = () => {
    fetchData(true)

    if (products.length !== 0) {
      setMobileSearchState(false)
    }
  }

  const handleUpload = async selected => {
    const images = []

    const res = await apis.cafe24Commerce.getImage({
      images: selected.map(p => {
        return {
          product_no: p.product_no,
          product_name: p.product_name,
          url: p.detail_image,
        }
      }),
    })
    const imageData = res.data

    imageData.forEach(d => {
      const img = `data:image/png;base64,${d.imgb}`

      // 주의 - 상품 이미지 업로드할 때 네이밍 규칙이 활용됨(아트워크id에 활용됨)
      const f = imageToFile(img, `cafe24_${d.product_no}.png`)
      images.push(f)
    })

    const newImages = Array.from(images).map(file => ({
      url: URL.createObjectURL(file),
      file,
    }))

    setFiles(newImages)
    setKeepShowingConfig(true)
  }

  // const handleUpload = async () => {
  //   if (!uploading) {
  //     setIsLoading(true)
  //     setUploading(true)

  //     console.log(selected.map(p => p[imageFieldKey]))

  //     const files = []

  //     const promises = selected.map(p => {
  //       const func = async () => {
  //         if (!p[imageFieldKey]) return
  //         const name = p[imageFieldKey]?.split('/').at(-1)
  //         const blob = await fetch(p[imageFieldKey]).then(r => r.blob())
  //         console.log(blob)
  //         const file = new File([blob], `${p[productIdKey]}_${name}`, { type: blob.type })

  //         files.push(file)
  //       }

  //       return func
  //     })

  //     await processInGroups(promises)
  //     uploadImage(files, uploadEventName)
  //     setOpen(false)
  //   }
  // }

  const resetProduct = () => {
    setSearchText('')
    fetchData(false, true)
  }

  return (
    <>
      {step === 1 ? (
        <>
          <Box sx={{ pt: { lg: '3.2rem', xs: '1rem' } }}>
            <CenterAlignStack sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontSize: { lg: '2.2rem', xs: '1.6rem' },
                  fontWeight: { lg: 700, xs: 800 },
                  lineHeight: 'normal',
                }}
              >
                {titleText}
                {!isLoading && !error && (
                  <>
                    (<span style={{ color: keyColor }}>{selected.length}</span>/{UPLOAD_LIMIT})
                  </>
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '1.5rem', xs: '1.3rem' },
                  fontWeight: { lg: 400 },
                  color: '#595959',
                }}
              >
                배경을 바꾸고 싶은 이미지를 선택 후 업로드하세요{' '}
                {!isLoading && !error && ` (최대 ${UPLOAD_LIMIT}장)`}
              </Typography>
            </CenterAlignStack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: '1 1 auto',
              m: { lg: '0', xs: 0 },
              px: { lg: '4rem', xs: '0' },
            }}
          >
            {isLoading ? (
              <>
                <CenterAlignStack sx={{ alignItems: 'center', p: '12.3rem 0 18.7rem 0' }}>
                  <Box
                    sx={{
                      '& #loader': isMobile ? { top: '2.1rem', left: '3.2rem' } : {},
                    }}
                  >
                    <Loader
                      id="loader"
                      color={keyColor}
                      width={8}
                      height={30}
                      margin={17}
                      radius={10}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { lg: '1.8rem', xs: '1.5rem' },
                      fontWeight: { lg: 700, xs: 800 },
                      mt: { lg: '4.5rem', xs: '4.5rem' },
                      '& .color-point': { color: keyColor },
                      textAlign: ' center',
                      lineHeight: 'normal',
                    }}
                  >
                    <span className="color-point">{commerceStoreName} </span> {isMobile && <br />}
                    상품 이미지를 불러오고 있습니다
                  </Typography>
                </CenterAlignStack>
              </>
            ) : error ? (
              <>
                <CenterAlignStack
                  sx={{
                    alignItems: 'center',
                    p: '13rem 0 19.3rem 0',

                    '& .MuiTypography-root': {
                      lineHeight: { lg: '2.2rem' },
                      fontSize: { lg: '1.8rem' },
                      fontWeight: { lg: 500 },
                    },
                    '& svg': {
                      mb: '1.5rem',
                    },
                  }}
                >
                  {ErrorTriangleIcon}
                  <Typography
                    textAlign="center"
                    sx={{
                      fontSize: { lg: '1.8rem', xs: '1.5rem' },
                      fontWeight: 500,
                      lineHeight: 'normal',
                    }}
                  >
                    요청이 반복되어 상품 이미지를 불러오는 중 {isMobile && <br />}문제가
                    발생하였습니다. {!isMobile && <br />} 잠시 후 다시 시도해주세요.
                  </Typography>
                </CenterAlignStack>
              </>
            ) : (
              <>
                <CenterAlignStack
                  sx={{
                    width: '100%',
                    p: { lg: 0, xs: '2.4rem 0' },
                  }}
                >
                  <Grid
                    container
                    columns={10}
                    sx={{
                      width: { lg: '100%', xs: '30.4rem' },
                      minHeight: { lg: '46rem', xs: '42.8rem' },
                    }}
                    spacing={{ lg: 0, xs: 2 }}
                  >
                    {pageData.length ? (
                      pageData.map((p, i) => (
                        <Grid
                          key={p.product_no}
                          item
                          lg={2}
                          md={5}
                          sm={5}
                          xs={5}
                          display="flex"
                          justifyContent={'space-evenly'}
                          sx={{
                            height: { lg: '23rem', xs: 'auto' },
                          }}
                        >
                          <ProductThumbnail
                            p={p}
                            imageSrc={p[imageFieldKey]}
                            productIdKey={productIdKey}
                            productNameKey={productNameKey}
                            keyColor={theme.palette.cafe24}
                            onClick={handleSelect(p)}
                            showCheck={selected.some(
                              item => item[productIdKey] === p[productIdKey]
                            )}
                          />
                        </Grid>
                      ))
                    ) : (
                      <EmptyProducts isSearch={isSearch} resetProduct={resetProduct} />
                    )}
                  </Grid>

                  <Stack
                    direction="row"
                    sx={{
                      width: '100%',
                      height: { lg: '4.5rem' },
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { lg: 'flex-start', xs: 'center' },
                    }}
                  >
                    <Desktop>
                      <div style={{ width: '22rem', background: 'pink' }}></div>
                      <Box
                        sx={{
                          width: { lg: 'calc(100% - 44rem)' },
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <CustomPagination
                          count={totalPages}
                          page={page}
                          onChange={(e, p) => {
                            setPage(p)
                          }}
                        />
                      </Box>

                      <div
                        style={{
                          width: '22rem',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          padding: '0.4rem',
                        }}
                      >
                        <SearchInputComponent
                          searchText={searchText}
                          setSearchText={setSearchText}
                          searchProduct={searchProduct}
                          resetProduct={resetProduct}
                          mobileSearchState={mobileSearchState}
                          setMobileSearchState={setMobileSearchState}
                          fetchData={fetchData}
                          products={products}
                          keyColor={theme.palette.cafe24}
                        />
                      </div>
                    </Desktop>
                    <Mobile>
                      <CenterAlignBox sx={{ mt: '3.5rem' }}>
                        <CustomPagination
                          count={totalPages}
                          page={page}
                          onChange={(e, p) => {
                            setPage(p)
                          }}
                        />
                      </CenterAlignBox>
                    </Mobile>
                  </Stack>
                </CenterAlignStack>
              </>
            )}
          </Box>

          <Box
            sx={{
              p: { lg: '0.8rem' },
              pb: { lg: '3.2rem' },
            }}
          >
            <CenterAlignStack sx={{ width: '100%', mb: { lg: 0, xs: '2.2rem' } }}>
              <Desktop>
                <DialogButton
                  handleClose={() => {
                    setOpen(false)
                  }}
                  actionText={error ? '다시 시도' : '다음'}
                  actionprops={{ disabled: isLoading || error || selected.length < 1 }}
                  actionSx={{ background: keyColor }}
                  handleAction={
                    error
                      ? () => {
                          fetchData()
                        }
                      : () => {
                          setStep(2)
                        }
                  }
                />
              </Desktop>
              <Mobile>
                {!mobileSearchState ? (
                  <CenterAlignBox sx={{ gap: '1.2rem' }}>
                    <Box sx={{ width: '4.8rem', height: '4.8rem' }}></Box>
                    <Button
                      onClick={
                        error
                          ? () => {
                              fetchData()
                            }
                          : () => {
                              setStep(2)
                            }
                      }
                      sx={{
                        fontSize: '1.8rem',
                        width: '16rem',
                        height: '4.8rem',

                        background: keyColor,
                      }}
                      disabled={isLoading || error || selected.length < 1}
                      variant="contained"
                    >
                      {error ? '다시 시도' : '다음'}
                    </Button>
                    <Button
                      variant="text"
                      sx={{
                        minWidth: 'auto',
                        width: '4.8rem',
                        height: '4.8rem',
                        border: `1px solid ${keyColor}`,
                        p: 0,
                        '&.Mui-disabled': {
                          border: `1px solid ${theme.palette.action.disabledBackground}`,
                          '& path': {
                            stroke: theme.palette.action.disabledBackground,
                          },
                        },
                      }}
                      disabled={isLoading || error}
                      onClick={() => {
                        setMobileSearchState(!mobileSearchState)
                      }}
                    >
                      <SearchIcon color={keyColor} />
                    </Button>
                  </CenterAlignBox>
                ) : (
                  <SearchInputComponent
                    searchText={searchText}
                    setSearchText={setSearchText}
                    searchProduct={searchProduct}
                    resetProduct={resetProduct}
                    mobileSearchState={mobileSearchState}
                    setMobileSearchState={setMobileSearchState}
                    fetchData={fetchData}
                    keyColor={theme.palette.cafe24}
                  />
                )}
              </Mobile>
            </CenterAlignStack>
          </Box>
        </>
      ) : step === 2 ? (
        <>
          <Box sx={{ pt: { lg: '3.2rem', xs: 0 } }}>
            <CenterAlignStack sx={{ width: '100%' }}>
              <Typography
                sx={{ fontSize: { lg: '2.2rem', xs: '1.6rem' }, fontWeight: { lg: 700, xs: 800 } }}
              >
                새 이미지 만들기
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: '1.5rem', xs: '1.2rem' },
                  fontWeight: { lg: 400 },
                  color: '#595959',
                }}
              >
                {commerceStoreName} 상품 대표 이미지로 새로운 사진을 생성해드립니다.
              </Typography>
            </CenterAlignStack>
          </Box>
          <DialogContent
            sx={{
              p: 0,
            }}
          >
            <CenterAlignStack sx={{ width: '100%', height: '100%', justifyContent: 'center' }}>
              <CenterAlignStack
                sx={{
                  width: '100%',
                  height: '100%',
                  mt: { lg: '3rem', xs: '2.4rem' },
                  mb: { lg: '3.9rem', xs: '3rem' },
                  px: { lg: '4rem', xs: '0' },
                  py: { lg: '5rem', xs: '0' },
                  justifyContent: 'center',
                }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: 'center',
                    columnGap: { lg: 1.2, xs: 0.4 },
                    rowGap: { lg: 1.3, xs: 0.4 },
                  }}
                  column={10}
                >
                  {selected.map((p, i) => (
                    <UploadImageThumb
                      key={i}
                      url={p[imageFieldKey]}
                      idx={i}
                      files={selected}
                      setFiles={setSelected}
                    />
                  ))}
                </Grid>
              </CenterAlignStack>
            </CenterAlignStack>
          </DialogContent>

          <DialogActions
            sx={{
              pt: { lg: '0.8rem', xs: '2rem' },
              pb: { lg: '3.2rem', xs: '2.2rem' },
            }}
          >
            <CenterAlignStack
              sx={{ width: '100%', justifyContent: 'center', position: 'relative' }}
              direction="row"
            >
              <IconButton
                sx={{
                  position: ' absolute',
                  bottom: 0,
                  left: { lg: '5rem', xs: '1.2rem' },
                  borderRadius: '1rem',
                  boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
                  width: '5rem',
                  height: '5rem',
                }}
                onClick={() => {
                  setStep(1)
                }}
              >
                <BackIcon style={{ width: '1rem', height: '2rem' }} />
              </IconButton>
              <Desktop>
                {' '}
                <DialogButton
                  handleClose={() => {
                    setOpen(false)
                  }}
                  actionText="업로드"
                  actionProps={{ disabled: isLoading || error || selected.length < 1 }}
                  actionSx={{ background: keyColor }}
                  handleAction={() => {
                    handleUpload(selected)
                  }}
                />
              </Desktop>
              <Mobile>
                <Button
                  onClick={() => {
                    handleUpload(selected)
                  }}
                  sx={{
                    fontSize: '1.8rem',
                    width: '16rem',
                    height: '4.8rem',

                    background: keyColor,
                  }}
                  disabled={isLoading || error || selected.length < 1}
                  variant="contained"
                >
                  업로드
                </Button>
              </Mobile>
            </CenterAlignStack>
          </DialogActions>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
