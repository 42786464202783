export const useGuidelines = (canvas, guidelineOptions) => {
  if (!canvas || !guidelineOptions.enabled) return

  let verticalLines = []
  let horizontalLines = []

  const drawLine = (x1, y1, x2, y2, color) => {
    const ctx = canvas.getSelectionContext()
    ctx.save()
    ctx.lineWidth = 1
    ctx.strokeStyle = color
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
    ctx.restore()
  }

  const drawVerticalLine = line => {
    drawLine(line.x, line.y1, line.x, line.y2, guidelineOptions.aligningLineColor)
  }

  const drawHorizontalLine = line => {
    drawLine(line.x1, line.y, line.x2, line.y, guidelineOptions.aligningLineColor)
  }

  const beforeRender = () => {
    canvas.clearContext(canvas.getSelectionContext())
  }

  const afterRender = () => {
    verticalLines.forEach(drawVerticalLine)
    horizontalLines.forEach(drawHorizontalLine)
    // Clear the lines for the next render
    verticalLines = []
    horizontalLines = []
  }

  // Add event listeners
  canvas.on('before:render', beforeRender)
  canvas.on('after:render', afterRender)

  // Remove event listeners on cleanup
  return () => {
    canvas.off('before:render', beforeRender)
    canvas.off('after:render', afterRender)
  }
}
