export const getUserType = user => {
  // 백엔드에 업로드 api 호출시 실어보내는 유저 정보 (우선순위 큐 배정에 사용됨)
  // user 구독자 여부 확인
  // 추후 다른 타입 추가 (엔터프라이즈 등)
  if (user.subscription_plan || user.grade === 'admin') {
    return 'subscriber'
  }

  return null
}
