import { commonAPI } from './common'
import { userAPI } from './user'
import { portfolioAPI } from './portfolio'
import { backgroundAPI } from './background'
import { statAPI } from './stat'
import { effectAPI } from './effect'
import { bgAPI, frontAPI } from './ml/mlAPI'
import { mailerAPI } from './mailer'
import { sse } from './sse'
import { paymentAPI } from './payment'
import { adminAPI } from './admin'
import { commerceAPI } from './commerce'
import { naverCommerceAPI } from './naver_commerce'
import { nhnCommerceAPI } from './nhn_commerce'
import { cafe24CommerceAPI } from './cafe24_commerce'
import { blogAPI } from './blog'
import { guestAPI } from './guest'

export const apis = {
  common: commonAPI,
  user: userAPI,
  portfolio: portfolioAPI,
  background: backgroundAPI,
  stat: statAPI,
  effect: effectAPI,

  mailer: mailerAPI,
  sse,
  payment: paymentAPI,
  admin: adminAPI,
  commerce: commerceAPI,

  naverCommerce: naverCommerceAPI,
  nhnCommerce: nhnCommerceAPI,
  cafe24Commerce: cafe24CommerceAPI,
  guest: guestAPI,

  blog: blogAPI,

  // ------ Backend ------
  bg: bgAPI,
  appfront: frontAPI,
  // ----------------------
}
