import axios from 'axios'
import { configData } from 'config'

const mailAxios = axios.create({
  baseURL: configData.MAILER_URL,
  headers: {},
})

mailAxios.interceptors.request.use(config => {
  // TODO
  return config
})

mailAxios.interceptors.response.use(
  response => response,
  async error => {
    console.log(error)
    // TODO
    return Promise.reject(error)
  }
)

export default mailAxios
