import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Link,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  FormControlLabel,
  TextField,
  Stack,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { CenterAlignStack, StyledFormTextField, FormErrorMessage } from 'components'
import { apis } from 'apis'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { setAccessToken, setRefreshToken } from 'utils/cookie'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useRecoilState } from 'recoil'
import { userAtom } from 'atoms'
import { styled, useTheme } from '@mui/material/styles'
import { CheckedSquareIcon, SquareIcon, VerticalDividerIcon } from 'theme/icon'
import { motion } from 'framer-motion'
import { useSnackbar } from 'notistack'
import useConfirm from 'hooks/useConfirm'

import { EMAIL_REGEX } from 'utils/common'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { buttonMobileStyle } from './Login'
import { useTranslation } from 'react-i18next'

const buttonStyle = {
  width: '38.4rem',
  mt: '3rem',
  mb: '1rem',
}

const TextButtonStyle = {
  fontSize: '1.2rem',
  fontWeight: 400,
  color: 'black',
  cursor: 'pointer',
}

export default function FindPW() {
  const [find, setFind] = useState(true)
  const [result, setResult] = useState('')
  const { t, i18n } = useTranslation()

  const { showConfirm } = useConfirm()

  return (
    <motion.div
      initial={{ x: 10, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {find ? (
        <FindForm find={find} setFind={setFind} setResult={setResult} />
      ) : (
        <Result result={result} />
      )}
    </motion.div>
  )
}

function FindForm({ setFind, setResult }) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { t, i18n } = useTranslation()

  const [loading, setLoading] = useState(false)
  const { showConfirm } = useConfirm()

  const findPWSchema = yup.object().shape({
    username: yup
      .string()
      .required(t('register.id_error_1'))
      .matches(/^[a-zA-Z0-9]+$/, t('register.id_error_2'))
      .test('no-spaces', t('register.id_error_3'), value => !/\s/.test(value)),
    email: yup
      .string()
      .matches(EMAIL_REGEX, t('register.email_error_1'))
      .required(t('register.email_error_2')),
  })

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      username: '',
      email: '',
    },
    resolver: yupResolver(findPWSchema),
  })

  useEffect(() => {}, [])

  const onSubmit = values => {
    setLoading(true)

    const url = window.location.hostname
    apis.user
      .postResetPasswordInfo({ username: values.username, email: values.email })
      .then(response => {
        if (!response.data.token) {
          alert(t('common.error'))
        } else {
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('register.email_code_error_3')}</Typography>,
          })
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          showConfirm({
            alertOnly: true,
            content: <Typography>{t('reset_password.error_1')}</Typography>,
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })

    // ----- GA4 event -----
    window.gtag('event', 'find_pw', { method: 'complete' })
    // ---------------------
  }

  return (
    <CenterAlignStack sx={{ pt: '8rem' }}>
      <Typography fontSize="3rem" fontWeight={800}>
        {t('reset_password.reset')}
      </Typography>
      <Typography color={theme.palette.text.lightgrey}> {t('reset_password.notice_1')}</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ py: 5 }}>
          <StyledFormTextField
            placeholder={t('register.id')}
            name="username"
            control={control}
            errors={errors}
          />

          {errors.username?.message.length > 0 && (
            <FormErrorMessage message={errors.username?.message} />
          )}

          <StyledFormTextField
            placeholder={t('register.email')}
            name="email"
            control={control}
            errors={errors}
          />

          {errors.email?.message.length > 0 && <FormErrorMessage message={errors.email?.message} />}
          <Desktop>
            <LoadingButton
              size="large"
              type="submit"
              // onClick={handleClick}
              // endIcon={<SendIcon />}
              loading={loading}
              // loadingPosition="end"
              variant="contained"
              sx={buttonStyle}
            >
              {t('reset_password.reset_button')}
            </LoadingButton>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ width: '100%', mb: '3rem' }}
            >
              <Stack direction="row" alignItems="center">
                <Typography sx={TextButtonStyle} onClick={() => navigate('/login')}>
                  {t('reset_password.back_login_button')}
                </Typography>
              </Stack>
            </Stack>
          </Desktop>

          <Mobile>
            <LoadingButton
              size="large"
              type="submit"
              // onClick={handleClick}
              // endIcon={<SendIcon />}
              loading={loading}
              // loadingPosition="end"
              variant="contained"
              sx={buttonMobileStyle}
            >
              {t('reset_password.reset_button')}
            </LoadingButton>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="end"
              sx={{ width: '100%', my: '0.7rem' }}
            >
              <Stack direction="row" alignItems="center">
                <Typography sx={TextButtonStyle} onClick={() => navigate('/login')}>
                  {t('reset_password.back_login_button')}
                </Typography>
              </Stack>
            </Stack>
          </Mobile>
        </Stack>
      </form>
    </CenterAlignStack>
  )
}

function Result({ result }) {
  const navigate = useNavigate()
  const theme = useTheme()

  const { t, i18n } = useTranslation()

  useEffect(() => {}, [])

  return (
    <CenterAlignStack sx={{ pt: '8rem' }}>
      <Typography fontSize="3rem" fontWeight={800}>
        {t('reset_password.reset')}
      </Typography>

      <CenterAlignStack sx={{ py: 5 }}>
        <Typography fontSize="2.4rem" color={theme.palette.common.black}>
          {t('reset_password.notice_2')}
        </Typography>
        <Typography fontSize="2rem" color={theme.palette.common.black}>
          {t('reset_password.notice_3')}
        </Typography>

        <Stack sx={{ py: '5rem' }}></Stack>
        <LoadingButton
          size="large"
          type="submit"
          onClick={() => navigate('/login')}
          variant="contained"
          sx={buttonStyle}
        >
          {t('reset_password.back_login_button')}
        </LoadingButton>
      </CenterAlignStack>
    </CenterAlignStack>
  )
}
