import {
  Box,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
  MenuItem,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
} from '@mui/material'
import { apis } from 'apis'
import { userAtom } from 'atoms'
import axios from 'axios'
import { AgreeDialog, BorderInput, CenterAlignStack, FormErrorMessage } from 'components'
import useConfirm from 'hooks/useConfirm'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { INFO_PERCENTAGE_WIDHT } from 'layouts/InfoLayout'
import { MYPAGE_SIDE_MARGIN } from 'layouts/MypageLayout'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { useRecoilValue } from 'recoil'
import { CloseCuteIcon, CloseIcon } from 'theme/icon'
import { EMAIL_REGEX, allowedTypes, isKo } from 'utils/common'
import * as yup from 'yup'

const titleStyle = { fontWeight: 700, fontSize: '2rem', lineHeight: 'normal' }
const mobileTitleStyle = { fontWeight: 700, fontSize: '1.6rem', lineHeight: 'normal' }
const inputFontStyle = { fontSize: '1.8rem', fontWeight: 400 }
const mobileInputFontStyle = { fontSize: '1.6rem', fontWeight: 400 }

const ERROR_HEIGHT = '0.8rem'

export default function Inquiry() {
  const { t, i18n } = useTranslation()
  const [userEmail, setUserEmail] = useState('')
  const [troubleCategory, setTroubleCategory] = useState('')
  const [troubleDetail, setTroubleDetail] = useState('')
  const [files, setFiles] = useState([])

  const [errors, setErrors] = useState({})
  const inputRef = useRef(null)
  const uuid = Math.ceil(Math.random() * 1000)

  const [isLoading, setIsLoading] = useState(false)

  const user = useRecoilValue(userAtom)

  const { showConfirm } = useConfirm()

  const validationSchema = yup.object().shape({
    userEmail: yup
      .string()
      .matches(EMAIL_REGEX, t('register.email_error_1'))
      .required(t('register.email_error_2')),
    troubleCategory: yup.string().required(t('inquiry.error_category')),
    troubleDetail: yup.string().required(t('inquiry.error_detail')),
  })

  const selectMenu = [
    t('inquiry.service_usage'),
    t('inquiry.refund'),
    t('inquiry.account'),
    t('inquiry.etc'),
  ]

  useEffect(() => {
    return () => {
      if (inputRef && inputRef.current) inputRef.current.value = null
    }
  }, [])

  const handleChange = e => {
    if (e.target.name === 'email') {
      setUserEmail(e.target.value)
    }
    if (e.target.name === 'category') {
      setTroubleCategory(e.target.value)
    }
    if (e.target.name === 'detail') {
      setTroubleDetail(e.target.value)
    }
    if (e.target.name === 'image') {
      e.preventDefault()
      const imageFile = e.target.files

      if (e.target.files && imageFile.length) {
        console.log('by click', imageFile)
        const uploadFiles = Array.prototype.slice.call(e.target.files)

        setFiles(prev => {
          const prevCopy = [...prev]

          prevCopy.push(...uploadFiles)

          if (prevCopy.length > 3) {
            return prevCopy.slice(0, 3)
          }

          return prevCopy
        })

        e.target.value = ''
      }
    }
  }

  const handleDeleteImage = idx => {
    setFiles(prev => {
      const updatedFiles = [...prev].filter((f, i) => i !== idx)
      return updatedFiles
    })
  }

  const onSubmit = () => {
    validationSchema
      .validate({ userEmail, troubleCategory, troubleDetail }, { abortEarly: false })
      .then(() => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('userEmail', userEmail)
        formData.append('troubleCategory', troubleCategory)
        formData.append('troubleDetail', troubleDetail)
        formData.append('user', user.username)

        Array.from(files).forEach((file, index) => {
          if (index < 3) {
            // 5개 파일 제한
            formData.append('files', file)
          }
        })

        // 이 문의 부분은 우리한테 셀프 나중에 스티비로 전환하거나 그대로 메일러 사용
        apis.mailer
          .inquiry(formData)
          // axios
          //   .post('http://localhost:4000/inquiry', formData)
          .then(res => {
            setIsLoading(false)
            setUserEmail('')
            setTroubleCategory('')
            setTroubleDetail('')
            setFiles([])
            showConfirm({
              alertOnly: true,
              sx: { '& .MuiDialog-paper': { height: '24rem' } },
              content: (
                <CenterAlignStack>
                  <Typography
                    sx={{
                      mb: '1.4rem',
                      fontWeight: 700,
                      fontSize: '2rem',
                      color: theme.palette.draph.blue,
                    }}
                  >
                    {t('inquiry.dialog_title')}{' '}
                  </Typography>
                  <Typography sx={{ fontWeight: 400, fontSize: '1.8rem' }}>
                    {t('inquiry.dialog_comment_1')}
                  </Typography>
                  <Typography sx={{ fontWeight: 400, fontSize: '1.8rem' }}>
                    {t('inquiry.dialog_comment_2')}
                  </Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: '1.8rem' }}>
                    {t('inquiry.dialog_comment_3')}
                  </Typography>
                </CenterAlignStack>
              ),
            })
          })
          .catch(() => {
            showConfirm({
              alertOnly: true,
              content: (
                <CenterAlignStack>
                  <Typography>{t('register.email_code_error_4')}</Typography>
                </CenterAlignStack>
              ),
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
        setErrors({})
      })
      .catch(validationErrors => {
        // 유효성 검사 실패한 경우에 실행할 코드
        setIsLoading(false)

        const newErrors = {}
        validationErrors.inner.forEach(error => {
          newErrors[error.path] = error.message
        })
        setErrors(newErrors)
      })
  }

  const theme = useTheme()

  return (
    <>
      <Desktop>
        <CenterAlignStack sx={{ width: '82%', pl: MYPAGE_SIDE_MARGIN, mb: '4rem' }}>
          <CenterAlignStack sx={{ width: '54rem' }}>
            <Typography sx={{ fontWeight: 800, fontSize: '3rem' }}>
              {t('inquiry.inquiry')}
            </Typography>
            <Stack sx={{ mt: '2.4rem', mb: ERROR_HEIGHT }}>
              <Typography sx={{ fontWeight: 500, fontSize: '1.7rem', textIndent: '-1rem' }}>
                {t('inquiry.inquiry_comment_1_a')}
                <RouterLink
                  to="/FAQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'unset' }}
                >
                  {t('inquiry.inquiry_comment_1_b')}
                </RouterLink>
                {t('inquiry.inquiry_comment_1_c')}
              </Typography>

              <Typography sx={{ fontWeight: 500, fontSize: '1.7rem', textIndent: '-1rem' }}>
                {t('inquiry.inquiry_comment_2')}
              </Typography>
              <Typography sx={{ fontWeight: 500, fontSize: '1.7rem', textIndent: '-1rem' }}>
                {t('inquiry.inquiry_comment_3')}
                <AgreeDialog
                  category={isKo(i18n) ? 'agreePrivacy' : 'Privacy'}
                  openButton={<span style={{ fontWeight: 700 }}>{`(${t('footer.privacy')})`}</span>}
                />
              </Typography>

              <Stack
                sx={{ mt: '5.2rem', mb: errors.userEmail ? ERROR_HEIGHT : '3.2rem' }}
                gap="1rem"
              >
                <Typography sx={{ ...titleStyle }}>
                  {t('inquiry.email')}
                  <sup style={{ color: theme.palette.error.main }}>*</sup>
                </Typography>{' '}
                <FormControl>
                  <OutlinedInput
                    error={Boolean(errors.userEmail)}
                    value={userEmail}
                    name="email"
                    onChange={handleChange}
                    placeholder={t('inquiry.email_placeholder')}
                    sx={{
                      ...inputFontStyle,
                      width: '53.6rem',
                      height: '4.6rem',
                      background: ' white',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.common.black,
                      },
                      '& .MuiOutlinedInput-input': { padding: 0 },
                    }}
                  />
                  {errors.userEmail && <FormErrorMessage message={errors.userEmail} />}
                </FormControl>
              </Stack>

              <Stack sx={{ mb: errors.troubleCategory ? ERROR_HEIGHT : '3.2rem' }} gap="1rem">
                <Typography sx={{ ...titleStyle }}>
                  {t('inquiry.category')}
                  <sup style={{ color: theme.palette.error.main }}>*</sup>
                </Typography>
                <FormControl>
                  <Select
                    displayEmpty
                    error={Boolean(errors.troubleCategory)}
                    value={troubleCategory}
                    name="category"
                    onChange={handleChange}
                    sx={{
                      width: '53.6rem',
                      height: '4.6rem',
                      background: ' white',
                      ...inputFontStyle,
                    }}
                    renderValue={selected => {
                      if (selected.length === 0) {
                        return (
                          <Typography sx={{ ...inputFontStyle, color: '#878787' }}>
                            {t('inquiry.category_placeholder')}
                          </Typography>
                        )
                      } else {
                        return <Typography sx={{ ...inputFontStyle }}>{selected}</Typography>
                      }
                    }}
                  >
                    {selectMenu.map((m, i) => {
                      return (
                        <MenuItem key={i} value={m}>
                          {m}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {errors.troubleCategory && <FormErrorMessage message={errors.troubleCategory} />}
                </FormControl>
              </Stack>

              <Stack sx={{ mb: errors.troubleDetail ? ERROR_HEIGHT : '3.2rem' }} gap="1rem">
                <Typography sx={{ ...titleStyle }}>
                  {t('inquiry.detail')}
                  <sup style={{ color: theme.palette.error.main }}>*</sup>
                </Typography>
                <FormControl>
                  <OutlinedInput
                    placeholder={t('inquiry.detail_placeholder')}
                    error={Boolean(errors.troubleDetail)}
                    value={troubleDetail}
                    multiline={true}
                    maxRows="11"
                    name="detail"
                    onChange={handleChange}
                    sx={{
                      width: '53.6rem',
                      height: '30rem',
                      background: ' white',
                      alignItems: 'flex-start',
                      padding: '1.25rem 1.4rem',
                      ...inputFontStyle,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.common.black,
                      },

                      '& .MuiOutlinedInput-input': { padding: 0 },
                    }}
                  />{' '}
                  {errors.troubleDetail && <FormErrorMessage message={errors.troubleDetail} />}
                </FormControl>
              </Stack>

              <Stack sx={{ mb: '3.2rem' }} gap="1rem">
                <Stack>
                  <Typography sx={{ ...titleStyle }}>{t('inquiry.upload_title')}</Typography>{' '}
                  <Typography sx={{ fontSize: '1.6rem' }}>
                    {t('inquiry.upload_subtitle')}
                  </Typography>{' '}
                </Stack>

                <Box
                  sx={{
                    display: 'flex',
                    p: '0.8rem 1.2rem',
                    border: '1px solid #EEEEEE',
                    backgroundColor: '#F8F8F8',
                    gap: '0.8rem',
                  }}
                >
                  <input
                    ref={inputRef}
                    type="file"
                    accept={allowedTypes.join(', ')}
                    multiple
                    onChange={handleChange}
                    id={`input-file-upload-${uuid}`}
                    name="image"
                    style={{ display: 'none' }}
                    disabled={files.length > 2}
                  />

                  <Button
                    variant="contained"
                    sx={{
                      minWidth: 'auto',
                      width: '6.5rem',
                      height: '2.4rem',
                      p: 0,
                      fontSize: '1.2rem',
                    }}
                    disabled={files.length > 2}
                    onClick={() => {
                      inputRef.current.click()
                    }}
                  >
                    {t('inquiry.upload')}
                  </Button>

                  {files.length ? (
                    <Stack sx={{ justifyContent: 'center', gap: '0.7rem' }}>
                      {files.map((f, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{
                              display: 'flex',

                              alignContent: 'center',
                              fontSize: '1.6rem',
                              lineHeight: '2.4rem',
                            }}
                          >
                            {f.name}
                            <IconButton
                              disableFocusRipple
                              disableRipple
                              disableTouchRipple
                              sx={{
                                p: '0.7rem 0.7rem 0.4rem 0.7rem',
                                '&:hover': { backgroundColor: 'transparent' },
                              }}
                              onClick={() => handleDeleteImage(i)}
                            >
                              {' '}
                              <CloseCuteIcon />
                            </IconButton>
                          </Box>
                        )
                      })}
                    </Stack>
                  ) : (
                    <Typography sx={{ color: '#808080' }}>{t('inquiry.no_files')}</Typography>
                  )}
                </Box>
              </Stack>
            </Stack>

            <Button
              variant="contained"
              onClick={onSubmit}
              sx={{ width: '20rem', height: '5.2rem', fontWeight: 800, fontSize: '1.8rem' }}
              disabled={isLoading}
            >
              {!isLoading ? t('inquiry.submit') : <PuffLoader color="white" size="4rem" />}
            </Button>
          </CenterAlignStack>
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack
          sx={{
            width: '100%',
            padding: '0 2rem',
            minWidth: '28rem',
            maxWidth: '48rem',
            mt: '3.4rem',
            textIndent: '-0.9rem',
          }}
        >
          <Typography sx={{ fontWeight: 800, fontSize: '2.4rem', mb: '2rem' }}>
            {t('inquiry.inquiry')}
          </Typography>
          <Typography sx={{ fontWeight: 400, fontSize: '1.4rem', ml: '1rem', width: '94%' }}>
            {t('inquiry.inquiry_comment_1_a')}
            <RouterLink
              to="route"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'unset' }}
            >
              {t('inquiry.inquiry_comment_1_b')}
            </RouterLink>
            {t('inquiry.inquiry_comment_1_c')}
          </Typography>

          <Typography sx={{ fontWeight: 400, fontSize: '1.4rem', ml: '1rem', width: '94%' }}>
            {t('inquiry.inquiry_comment_2')}
          </Typography>
          <Typography sx={{ fontWeight: 400, fontSize: '1.4rem', ml: '1rem', width: '94%' }}>
            {t('inquiry.inquiry_comment_3')}
            <AgreeDialog
              category={isKo(i18n) ? 'agreePrivacy' : 'Privacy'}
              openButton={<span style={{ fontWeight: 500 }}>{`(${t('footer.privacy')})`}</span>}
            />
          </Typography>
        </CenterAlignStack>

        <Stack sx={{ width: '100%', maxWidth: '45rem', px: '2rem', mt: '2.8rem' }}>
          <Stack gap="1rem" sx={{ mb: errors.userEmail ? '0.8rem' : '3.2rem' }}>
            <Typography sx={{ ...mobileTitleStyle }}>
              {' '}
              {t('inquiry.email')}
              <sup style={{ color: theme.palette.error.main }}>*</sup>
            </Typography>
            <FormControl>
              <OutlinedInput
                error={Boolean(errors.userEmail)}
                value={userEmail}
                name="email"
                onChange={handleChange}
                placeholder={t('inquiry.email_placeholder')}
                inputProps={{ style: { ...mobileInputFontStyle } }}
                sx={{
                  width: '100%',
                  height: '4rem',
                  background: ' white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.common.black,
                  },
                  '& .MuiOutlinedInput-input': { padding: 0 },
                }}
              />
              {errors.userEmail && <FormErrorMessage message={errors.userEmail} />}
            </FormControl>
          </Stack>

          <Stack gap="1rem" sx={{ mb: errors.troubleCategory ? '0.8rem' : '3.2rem' }}>
            <Typography sx={{ ...mobileTitleStyle }}>
              {' '}
              {t('inquiry.category')}
              <sup style={{ color: theme.palette.error.main }}>*</sup>
            </Typography>
            <FormControl>
              <Select
                displayEmpty
                error={Boolean(errors.troubleCategory)}
                value={troubleCategory}
                name="category"
                onChange={handleChange}
                sx={{
                  width: '100%',
                  height: '4rem',
                  background: ' white',
                }}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return (
                      <Typography sx={{ ...mobileInputFontStyle, color: '#878787' }}>
                        {t('inquiry.category_placeholder')}
                      </Typography>
                    )
                  } else {
                    return <Typography sx={{ ...mobileInputFontStyle }}>{selected}</Typography>
                  }
                }}
              >
                {selectMenu.map((m, i) => {
                  return (
                    <MenuItem key={i} value={m}>
                      {m}
                    </MenuItem>
                  )
                })}
              </Select>
              {errors.troubleCategory && <FormErrorMessage message={errors.troubleCategory} />}
            </FormControl>
          </Stack>

          <Stack gap="1rem" sx={{ mb: errors.troubleDetail ? '0.8rem' : '3.2rem' }}>
            <Typography sx={{ ...mobileTitleStyle }}>
              {t('inquiry.detail')}
              <sup style={{ color: theme.palette.error.main }}>*</sup>
            </Typography>
            <FormControl>
              <OutlinedInput
                placeholder={t('inquiry.detail_placeholder')}
                error={Boolean(errors.troubleDetail)}
                value={troubleDetail}
                multiline={true}
                maxRows="7"
                name="detail"
                onChange={handleChange}
                sx={{
                  width: '100%',
                  height: '18rem',
                  background: ' white',
                  alignItems: 'flex-start',
                  padding: '1rem 1.4rem',
                  ...mobileInputFontStyle,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.common.black,
                  },

                  '& .MuiOutlinedInput-input': { padding: 0 },
                }}
              />{' '}
              {errors.troubleDetail && <FormErrorMessage message={errors.troubleDetail} />}
            </FormControl>
          </Stack>

          <Stack gap="1rem" sx={{ mb: errors.troubleDetail ? '0.8rem' : '3.2rem' }}>
            <Stack>
              <Typography sx={{ ...mobileTitleStyle, lineHeight: 'normal' }}>
                {t('inquiry.upload_title')}
              </Typography>{' '}
              <Typography sx={{ fontSize: '1.3rem' }}>{t('inquiry.upload_subtitle')}</Typography>{' '}
            </Stack>

            <Box
              sx={{
                display: 'flex',
                p: '0.8rem',
                border: '1px solid #EEEEEE',
                backgroundColor: '#F8F8F8',
                gap: '0.8rem',
              }}
            >
              <input
                ref={inputRef}
                type="file"
                accept={allowedTypes.join(', ')}
                multiple
                onChange={handleChange}
                id={`input-file-upload-${uuid}`}
                name="image"
                style={{ display: 'none' }}
                disabled={files.length > 2}
              />

              <Button
                variant="contained"
                sx={{
                  minWidth: 'auto',
                  width: '6.5rem',
                  height: '2.4rem',
                  p: 0,
                  fontSize: '1.2rem',
                }}
                disabled={files.length > 2}
                onClick={() => {
                  inputRef.current.click()
                }}
              >
                {t('inquiry.upload')}
              </Button>

              {files.length ? (
                <Stack sx={{ justifyContent: 'center', gap: '0.7rem' }}>
                  {files.map((f, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: 'flex',

                          alignContent: 'center',
                          fontSize: '1.4rem',
                          lineHeight: '2rem',
                        }}
                      >
                        {f.name}
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          sx={{
                            p: '0.7rem 0.7rem 0.4rem 0.7rem',
                            '&:hover': { backgroundColor: 'transparent' },
                          }}
                          onClick={() => handleDeleteImage(i)}
                        >
                          {' '}
                          <CloseCuteIcon />
                        </IconButton>
                      </Box>
                    )
                  })}
                </Stack>
              ) : (
                <Typography sx={{ color: '#808080' }}>{t('inquiry.no_files')}</Typography>
              )}
            </Box>
          </Stack>
          <CenterAlignStack>
            <Button
              variant="contained"
              onClick={onSubmit}
              sx={{
                width: '16rem',
                height: '4rem',
                fontWeight: 800,
                fontSize: '1.8rem',
                mb: '6.4rem',
              }}
              disabled={isLoading}
            >
              {!isLoading ? t('inquiry.submit') : <PuffLoader color="white" size="2.3rem" />}
            </Button>
          </CenterAlignStack>
        </Stack>
      </Mobile>
    </>
  )
}
