import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  InputBase,
  Stack,
  useTheme,
  Button,
  ListItemButton,
} from '@mui/material'
import { CenterAlignStack, CustomeListItemButton } from 'components'
import { fontSize } from './test/Toolbar'
import {
  APIContent,
  APIs,
  Content1,
  GenerateAPI,
  GenerateBodyData,
  GenerateFailedResponse,
  GenerateHeader,
  GenerateMethod,
  GenerateResponse,
  GenerateURL,
  SampleCode,
  TokenContent,
} from './APIContent'
import { extractTextFromComponent, extractTextFromDOM } from 'utils/common'

const style = {
  title: {
    fontWeight: 500,
  },

  subsubtitle: {
    color: '#505050',
  },
  contentBox: {
    p: '2rem',
  },
  contentTitle: {
    fontSize: '2.8rem',
    fontWeight: 700,
  },
}

const categories = [
  {
    id: 'Token',
    label: '1. API 토큰',
    subcategories: [],
  },
  {
    id: 'APIs',
    label: '2. API 명세',
    subcategories: [
      {
        id: 'Sub 2-1',
        label: '• 이미지 생성 API',
        subcategories: [
          {
            id: 'Sub 2-1-1',
            label: '- URL',
            subcategories: [],
          },
          {
            id: 'Sub 2-1-2',
            label: '- Method',
            subcategories: [],
          },
          {
            id: 'Sub 2-1-3',
            label: '- Header',
            subcategories: [],
          },
          {
            id: 'Sub 2-1-4',
            label: '- Body Data',
            subcategories: [],
          },
          {
            id: 'Sub 2-1-5',
            label: '- Response',
            subcategories: [],
          },
          {
            id: 'Sub 2-1-6',
            label: '- Failed Response',
            subcategories: [],
          },
        ],
      },
    ],
  },
  {
    id: 'Sample',
    label: '3. Sample Code',
    subcategories: [],
  },
]

// const content = {
//   Token: [<TokenContent key="Token" />],
//   APIs: [
//     <APIs key="APIs" />,
//     <GenerateAPI key="Sub 2-1" />,
//     <GenerateURL key="Sub 2-1-1" />,
//     <GenerateMethod key="Sub 2-1-2" />,
//     <GenerateHeader key="Sub 2-1-3" />,
//     <GenerateBodyData key="Sub 2-1-4" />,
//     <GenerateResponse key="Sub 2-1-5" />,
//     <GenerateFailedResponse key="Sub 2-1-6" />,
//   ],
//   Sample: [<SampleCode key="Sub 3-1" />],
// }

export default function APIDoc() {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [filteredContent, setFilteredContent] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [texts, setTexts] = useState([])

  const theme = useTheme()

  // const handleCategoryClick = category => {
  //   setSelectedCategory(category)
  //   const firstItem = content[category][0]
  //   if (firstItem) {
  //     scrollToElement(firstItem.props.id)
  //   }
  // }

  // const handleSubCategoryClick = subcategory => {
  //   setSelectedCategory(subcategory)
  //   const allContent = Object.values(content).flat()
  //   const detail = allContent.find(item => item.props.id.includes(subcategory))
  //   if (detail) {
  //     scrollToElement(detail.props.id)
  //   }
  // }

  const scrollToElement = id => {
    const rootFontRatio = parseFloat(
      getComputedStyle(document.documentElement).getPropertyValue('--font-size')
    )

    const offset = -8 * ((16 * rootFontRatio) / 100) - 10 // 8rem in pixels
    const element = document.getElementById(id)
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + offset
      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }

  // const handleSearch = event => {
  //   const term = event.target.value.toLowerCase()
  //   setSearchTerm(term)
  //   const results = term
  //     ? Object.values(content)
  //         .flat()
  //         .filter((item, index) => {
  //           const textContent = texts[index].toLowerCase()
  //           console.log(textContent)
  //           return textContent.includes(term)
  //         })
  //     : null
  //   console.log(results, 'results')
  //   setFilteredContent(results)
  // }

  // const getTextContent = element => {
  //   if (typeof element === 'string') return element
  //   if (typeof element === 'number') return element.toString()
  //   if (React.isValidElement(element) && element.props.children) {
  //     if (element.props?.id && element.props.id.includes('noSearch')) return ''
  //     if (Array.isArray(element.props.children)) {
  //       return element.props.children.map(getTextContent).join(' ')
  //     }
  //     return getTextContent(element.props.children)
  //   }
  //   return ''
  // }

  // const highlightTextInElement = (element, term) => {
  //   console.log(element, 'element2')
  //   if (typeof element === 'string') {
  //     return highlightText(element, term)
  //   }
  //   if (React.isValidElement(element) && element.props.children) {
  //     if (Array.isArray(element.props.children)) {
  //       return React.cloneElement(element, {
  //         children: element.props.children.map(child => highlightTextInElement(child, term)),
  //       })
  //     }
  //     return React.cloneElement(element, {
  //       children: highlightTextInElement(element.props.children, term),
  //     })
  //   }
  //   return element
  // }

  // const highlightText = (text, term) => {
  //   if (!term) return text
  //   const parts = text.split(new RegExp(`(${term})`, 'gi'))
  //   return parts.map((part, index) =>
  //     part.toLowerCase() === term.toLowerCase() ? (
  //       <span key={index} style={{ backgroundColor: 'yellow' }}>
  //         {part}
  //       </span>
  //     ) : (
  //       part
  //     )
  //   )
  // }

  const handleMenuClick = item => {
    setSelectedCategory(item)

    scrollToElement(item)
  }

  const renderSubCategories = (subcategories, left = 1.5) => {
    return subcategories.map(sub => {
      const PLUS_PADDING = 1
      return (
        <React.Fragment key={sub.id}>
          <CustomeListItemButton
            onClick={() => handleMenuClick(sub.id)}
            selected={selectedCategory === sub.id}
            sx={{ pl: left + 'rem', py: '1.6rem' }}
          >
            <ListItemText primary={sub.label} />
          </CustomeListItemButton>
          {renderSubCategories(sub.subcategories, left + PLUS_PADDING)}
        </React.Fragment>
      )
    })
  }

  // useEffect(() => {
  //   if (!searchTerm) {
  //     setFilteredContent(null)
  //   }
  // }, [searchTerm])

  // const refs = useRef([])
  // const [highlightIndex, setHighlightIndex] = useState(null)

  // useEffect(() => {
  //   const extractedTexts = refs.current.map(ref => extractTextFromDOM(ref))

  //   setTexts(extractedTexts)
  // }, [])

  return (
    <CenterAlignStack sx={{ minHeight: 'inherit', pt: '2.8rem' }}>
      <Box display="flex" sx={{ minHeight: 'inherit' }}>
        <Stack
          sx={{
            width: '22.8rem',

            px: '1rem',
            position: 'sticky',
            height: '100%',
            justifyContent: 'flex-end',
            top: '11.8rem',
          }}
        >
          {/* <InputBase
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            sx={{ marginBottom: '20px', width: '100%' }}
          /> */}
          <Typography sx={{ fontSize: '1.6rem', fontWeight: 600 }}>API Guide</Typography>
          <List>
            {categories.map(category => (
              <React.Fragment key={category.id}>
                <CustomeListItemButton
                  onClick={() => handleMenuClick(category.id)}
                  selected={selectedCategory === category.id}
                  // sx={{
                  //   p: '1.6rem 0.8rem',
                  //   '&:hover': {
                  //     '& .MuiTypography-root': {
                  //       color: theme.palette.draph.blue,
                  //     },
                  //     backgroundColor: theme.palette.draph.lighterblue,
                  //   },
                  // }}
                >
                  <ListItemText primary={category.label} />
                </CustomeListItemButton>
                {renderSubCategories(category.subcategories)}
              </React.Fragment>
            ))}
          </List>
        </Stack>
        <Stack
          sx={{
            width: '78.2rem',
            pl: '1rem',
            minHeight: 'inherit',
            // borderLeft: '1px solid #ddd',
          }}
        >
          <Typography
            sx={{
              p: '1.2rem 2rem',
              backgroundColor: '#F8F8F8',
              fontSize: '1.8rem',
              fontWeight: 500,
              borderRadius: '10px',
            }}
          >
            Raw image와 생성 옵션을 입력으로 하여 배경 제거, 단색 배경, 심플 배경, 컨셉 배경을
            지정하여 <br /> draph.art의 이미지 생성 서비스를 제공하는 API
          </Typography>
          {/* {filteredContent
            ? filteredContent.map((item, index) => (
                <Box key={item.props.id} id={item.props.id} ref={el => (refs.current[index] = el)}>
                  {item}
                </Box>
              ))
            : Object.values(content)
                .flat()
                .map((item, index) => (
                  <Box
                    key={item.props.id}
                    id={item.props.id}
                    ref={el => (refs.current[index] = el)}
                  >
                    {item}
                  </Box>
                ))} */}
          <APIContent setSelectedCategory={setSelectedCategory} />
        </Stack>
      </Box>
    </CenterAlignStack>
  )
}
