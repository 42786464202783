import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import styled from '@emotion/styled'
import * as configConstant from 'config'
import {
  autoUploadAtom,
  currentMenuAtom,
  customTemplateAtom,
  isSimpleAtom,
  portfolioConfigAtom,
  portfolioDetailAtom,
  portfolioTypeAtom,
  userAtom,
  PORTFOLIO_CONFIG_DEFAULT,
} from 'atoms'
import { cloneElement, useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil'

import { PORTFOLIO_CATEGORIES, OBJECT_ANGLE, OBJECT_BOUNDARY } from 'config'
import { apis } from 'apis'
import {
  CenterAlignStack,
  CenterAlignBox,
  BlueSwitch,
  FormErrorMessage,
  OutputField,
  TwoImageSlider,
  CustomOutlinedInput,
  SpanDraphBlue,
  CustomTooltip,
  OutlinedImage,
  OpacityMotionDiv,
} from 'components'
import { portfolioBgTypeSelector, userPortfolioNamesSelector } from 'selector'
import { useSnackbar } from 'notistack'
import {
  CheckedSquareIcon2,
  SelectSideIconThin,
  CloseIcon,
  PlusIcon,
  TrashCanIcon,
  ClothesIcon,
  FoodIcon,
  CosmeticsIcon,
  CarIcon,
  MinusIcon,
  DashCirclePencilIcon,
  PrevPageIcon,
  OpenMarketIcon,
  CashIcon,
  CloseCuteIcon,
  MiscIcon,
  PlusIcon2,
} from 'theme/icon'
import { Desktop, Mobile, useMobileMediaQuery } from 'hooks/useMediaQuery'
import { MOBILE_PORTFOLIO_WIDTH_LIMIT } from 'pages/Portfolio'
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  ToggleButton,
  collapseClasses,
  useTheme,
} from '@mui/material'
import { usePortfolioId } from 'hooks/usePortfoliId'
import { AIIcon, isDev, isKo } from 'utils/common'
import { tutorialAtom } from '../../atoms'
import { RIGHT_CLICK_ALLOW_USER } from 'App'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { Korean } from 'hooks/useLanguage'
import { fontSize } from 'pages/test/Toolbar'
import { UploadButton } from 'components/UploadHeader'

const ALL_SUBCATEGORIES = []
for (const [key, value] of Object.entries(PORTFOLIO_CATEGORIES)) {
  ALL_SUBCATEGORIES.push(...value.children)
}

export const OpenMarketToggle = styled(ToggleButton)(({ theme }) => ({
  background: theme.palette.common.white,
  width: '14.8rem',
  height: '3.2rem',
  borderRadius: '2rem',
  color: theme.palette.common.black,
  border: `2px solid ${theme.palette.common.black}`,
  '&: hover': {
    border: `2px solid ${theme.palette.draph.blue}`,
    color: theme.palette.draph.blue,
    background: theme.palette.common.white,
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.draph.blue,
    color: theme.palette.common.white,
    border: `2px solid ${theme.palette.draph.blue}`,
    '&:hover': {
      border: `2px solid ${theme.palette.draph.blue}`,
      color: theme.palette.draph.blue,
      background: theme.palette.common.white,
    },
  },
}))

export const OpenMarketButton = styled(Button)(({ theme }) => ({
  background: theme.palette.common.white,
  width: '10.8rem',
  height: '3.2rem',
  borderRadius: '2rem',
  color: theme.palette.common.black,
  marginLeft: '1.6rem',
  padding: 0,
  border: `2px solid ${theme.palette.common.black}`,
  '&: hover': {
    border: `2px solid ${theme.palette.draph.blue}`,
    color: theme.palette.draph.blue,
    background: theme.palette.common.white,
  },
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  height: '5.2rem',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  color: theme.palette.common.black,
  backgroundColor: '#F8F8F8',
  fontSize: '1.8rem',
  fontWeight: 700,
  minWidth: 'auto',
  minHeight: 'auto',

  '&.Mui-selected': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.draph.newBlue,
  },

  [theme.breakpoints.down('lg')]: {
    height: '3.2rem',
    lineHeight: 'normal',
    borderTopRightRadius: '1rem',
    borderTopLeftRadius: '1rem',
    fontSize: '1.2rem',
  },
}))

const PageTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.6rem',
  fontWeight: 700,
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.6rem',
  },
}))

const PageComment = styled(Typography)(({ theme }) => ({
  fontSize: '1.3rem',
  fontWeight: 400,
  color: '#303030',
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    lineHeight: 'normal',
  },
}))

export function PortfolioConfigTabs({
  tabs = false,
  value,
  onChange,
  sx = {},
  tabSx = {},
  mobileSmall = false,
  small = false,
  large = false,
  xlarge = false,
  ...props
}) {
  const isMobile = useMobileMediaQuery()
  const { t } = useTranslation()

  const tabsArray = tabs || [
    { default: t('upload_config.portfolio_title'), mobile: t('upload_config.portfolio_title_s') },
    { default: t('upload_config.bg_theme'), mobile: t('upload_config.bg_theme_s') },
    { default: t('upload_config.product_category'), mobile: t('upload_config.product_category_s') },
    { default: t('upload_config.advanced_setting'), mobile: t('upload_config.advanced_setting') },
    // { default: t('upload_config.advanced_setting'), mobile: t('upload_config.advanced_setting_s') },
  ]

  return (
    <Tabs
      value={value}
      onChange={onChange}
      centered={true}
      TabIndicatorProps={{
        style: { height: '0.3rem' },
      }}
      sx={{
        width: 'inherit',
        minHeight: 'auto',
        '& .MuiButtonBase-root': { padding: { lg: '1.2rem 1.6rem', xs: '0' } },
        '& .MuiTabs-scroller': { display: 'flex', justifyContent: 'center' },
        '& .MuiTabs-indicator': { display: 'none' },
        '& .MuiTabs-flexContainer': { width: 'inherit' },

        ...sx,
      }}
      {...props}
    >
      {tabsArray.map(t => (
        <StyledTab
          key={t.default}
          label={isMobile ? t.mobile : t.default}
          sx={{
            width: {
              lg: `${100 / tabsArray.length}%`,
              xs: `${100 / tabsArray.length}%`,
              // ...(xlarge && { lg: '59.2rem' }),
              ...(small && { lg: '20rem' }),
              // ...(large && { lg: '29.6rem' }),
              ...(mobileSmall && { xs: '6.8rem' }),
            },
            maxWidth: 'none',
            ...tabSx,
          }}
          disabled={t.disabled}
        />
      ))}
    </Tabs>
  )
}

export function SetPortfolioName({
  value,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  errorMessage,
  sx,
  ...props
}) {
  const isMobile = useMobileMediaQuery()
  const { t } = useTranslation()
  return (
    <CenterAlignStack
      sx={{ m: { lg: '3.6rem 0 4.4rem 0', xs: '2.2rem 0 0rem 0' }, ...sx }}
      {...props}
    >
      <PageTitle
        className="title"
        sx={
          {
            // '&::after': {
            //   content: '" *"',
            //   color: theme => theme.palette.common.red,
            // },
          }
        }
      >
        {t('upload_config.portfolio_title')}
      </PageTitle>
      <PageComment sx={{ mb: '2.7rem' }}> {t('upload_config.portfolio_title_comment')}</PageComment>
      <CustomOutlinedInput
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <FormErrorMessage
        message={errorMessage}
        // width={isMobile ? '32rem' : '100%'}
      />
    </CenterAlignStack>
  )
}

export function SetPortfolioCategory({ config, setConfig, onClickMain, sx, ...props }) {
  const { t } = useTranslation()
  const user = useRecoilValue(userAtom)
  const isMobile = useMobileMediaQuery()
  const PortfolioDetail = useRecoilValue(portfolioDetailAtom)

  const [subCategoryItems, setSubCategoryItems] = useState([])

  const theme = useTheme()

  const isOutputSizeValid =
    config?.outputSizeList.length > 1 &&
    config?.outputSizeList[1].w !== null &&
    config?.outputSizeList[1].h !== null

  // const checkedBgConfig = () => {
  //   let overwrite = {}

  //   const isSelectedBgConfig =
  //     config.bgSimple || config.bgWhite || config.bgComplex || config.bgGenerate

  //   if (!isSelectedBgConfig && config.flagBgExpansion) {
  //     overwrite = { bgSimple: false, bgWhite: false, bgComplex: false, bgGenerate: false }
  //   } else if (!isSelectedBgConfig && isOutputSizeValid) {
  //     overwrite = {
  //       bgSimple: true,
  //       bgWhite: true,
  //       bgComplex: false,
  //       bgGenerate: false,
  //       flagBgExpansion: false,
  //       flagGenCompo: false,
  //     }
  //   } else if (!isSelectedBgConfig && !isOutputSizeValid) {
  //     overwrite = {
  //       bgSimple: true,
  //       bgWhite: true,
  //       bgComplex: false,
  //       bgGenerate: true,
  //       flagBgExpansion: false,
  //       flagGenCompo: false,
  //     }
  //   }

  //   return overwrite
  // }

  useEffect(() => {
    setConfig({
      ...config,
      // ...checkedBgConfig(),
    })
  }, [config.category, config.subCategory])

  const CATEGORY_NEW = [
    {
      id: 'auto',
      name: t('artwork_category.auto'),
      icon: <AIIcon />,
    },
    {
      id: 'person',
      name: t('artwork_category.person'),
      icon: <ClothesIcon style={isMobile ? { width: '3.4rem', height: '3.4rem' } : {}} />,
    },
    {
      id: 'food',
      name: t('artwork_category.food'),
      icon: <FoodIcon style={isMobile ? { width: '3.4rem', height: '3.4rem' } : {}} />,
    },
    {
      id: 'cosmetics',
      name: t('artwork_category.cosmetics'),
      icon: <CosmeticsIcon style={isMobile ? { width: '4.7rem', height: '4.7rem' } : {}} />,
    },
    // {
    //   id: 'misc',
    //   name: t('artwork_category.misc'),
    //   icon: <MiscIcon style={isMobile ? { width: '4.2rem', height: '4.6rem' } : {}} />,
    // },
    {
      id: 'car',
      name: t('artwork_category.car'),
      icon: <CarIcon style={isMobile ? { width: '4.2rem', height: '4.6rem' } : {}} />,
    },
  ]

  const secondArray = [
    {
      id: 'person',
      name: (
        <span>
          {t('artwork_category.model_true_1')}
          <br />
          {t('artwork_category.model_true_2')}
        </span>
      ),
      img: '/static/images/main/personCategory.jpeg',
    },
    {
      id: 'clothes',
      name: (
        <span>
          {t('artwork_category.model_false_1')}
          <br />
          {t('artwork_category.model_false_2')}
        </span>
      ),
      img: '/static/sample/clothes/etc_1.png',
    },
  ]

  useEffect(() => {
    let sub = PORTFOLIO_CATEGORIES.find(c => c.id === config.category)?.children || []

    if (user.master_user_id && configConstant.MUSER_SUBCATEGORIES[user.master_user_id]) {
      const s = configConstant.MUSER_SUBCATEGORIES[user.master_user_id][config.category]

      if (s) sub = s
    }

    setSubCategoryItems(sub)
  }, [config.category, user.id])

  useEffect(() => {
    // window.scrollTo({
    //   top: document.body.scrollHeight - 1100,
    //   behavior: 'smooth',
    // })
  }, [subCategoryItems])

  return (
    // 높이를 지정, 사진 불러오기 전 높이가 스크롤 다운을 할 수 없음
    <CenterAlignStack
      sx={{
        width: '100%',
        m: { lg: '3.6rem 0 4.4rem 0', xs: '2.2rem 0 2.4rem 0' },
        height: { lg: 'auto', xs: 'auto' },
        ...sx,
      }}
      {...props}
    >
      <PageTitle>{t('upload_config.product_category')}</PageTitle>
      <PageComment>{t('upload_config.product_category_comment')}</PageComment>

      <Stack
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          mt: { lg: '3rem', xs: '2.4rem' },
        }}
      >
        <Typography
          fontSize={{ lg: '1.6rem', xs: '1.2rem' }}
          fontWeight={700}
          sx={{ textAlign: 'center', width: { lg: '9.1rem', xs: '7.3rem' }, mr: { lg: '0rem' } }}
        >
          {t('upload_config.category')}
        </Typography>
        <Grid
          container
          sx={{
            gap: { lg: '0.8rem', xs: '1.2rem' },
            display: 'flex',
            justifyContent: 'center',
            mt: '1rem',
            width: { lg: '100%', xs: '32rem' },
          }}
        >
          {CATEGORY_NEW.map((category, index) => {
            const isSelected =
              category.id === config.category ||
              (index === 1 && ['clothes', 'person'].includes(config.category))

            return (
              <CenterAlignStack key={index} sx={{ gap: { lg: '1rem', xs: '0.8rem' } }}>
                <Grid item>
                  <CenterAlignBox
                    sx={{
                      alignItems: 'center',
                      width: { lg: '10rem', xs: '7.2rem' },
                      height: { lg: '10rem', xs: '6.8rem' },
                      borderRadius: '1rem',

                      border: theme =>
                        isSelected
                          ? isMobile
                            ? `2px solid ${theme.palette.draph.blue}`
                            : `3px solid ${theme.palette.draph.blue}`
                          : '1px solid #D9E1EC',

                      cursor: 'pointer',
                    }}
                    onClick={onClickMain(category)}
                  >
                    {cloneElement(category.icon, {
                      color: isSelected ? theme.palette.draph.blue : theme.palette.common.black,
                    })}
                  </CenterAlignBox>
                </Grid>
                <Typography
                  sx={{
                    fontWeight: isSelected ? 700 : 400,
                    fontSize: { lg: '1.5rem', xs: '1.2rem' },
                    color: theme =>
                      isSelected ? theme.palette.draph.blue : theme.palette.common.black,
                  }}
                >
                  {category.name}
                </Typography>
              </CenterAlignStack>
            )
          })}
        </Grid>
      </Stack>

      {['clothes', 'person'].includes(config.category) && (
        <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            mt: { lg: '3rem', xs: '2rem' },
            py: { lg: 0, xs: '1.5rem' },
            backgroundColor: { lg: 'transparent', xs: '#F8F8F8' },
          }}
        >
          <Typography
            fontSize={{ lg: '1.6rem', xs: '1.2rem' }}
            fontWeight={700}
            sx={{ textAlign: 'center', width: { lg: '9.1rem', xs: '7.3rem' }, mr: { lg: '0rem' } }}
          >
            {t('upload_config.category_2')}
          </Typography>
          <Grid
            container
            sx={{
              gap: { lg: '1rem', xs: '1.6rem' },
              display: 'flex',
              justifyContent: 'center',
              mt: '1rem',
            }}
          >
            {secondArray.map((category, index) => {
              const isSelected = category.id === config.category
              return (
                <CenterAlignStack key={index} sx={{ gap: { lg: '1rem', xs: '0.8rem' } }}>
                  <Grid item>
                    <CenterAlignBox
                      sx={{
                        alignItems: 'center',
                        width: { lg: '10rem', xs: '7.2rem' },
                        height: { lg: '10rem', xs: '6.8rem' },
                        borderRadius: '1rem',

                        backgroundColor: '#E3E3E3',
                        outline: theme =>
                          isSelected
                            ? isMobile
                              ? `2px solid ${theme.palette.draph.blue}`
                              : `3px solid ${theme.palette.draph.blue}`
                            : '1px solid #D9E1EC',
                        cursor: 'pointer',
                      }}
                      onClick={onClickMain(category)}
                    >
                      <img
                        src={category.img}
                        style={{
                          height: index === 1 ? 'auto' : 'inherit',
                          objectFit: 'cover',
                          borderRadius: '1rem',
                          width: index === 1 ? (isMobile ? '4.8rem' : '7.4rem') : 'auto',
                        }}
                      />
                    </CenterAlignBox>
                  </Grid>
                  <Typography
                    sx={{
                      color: theme =>
                        isSelected ? theme.palette.draph.blue : theme.palette.common.black,
                      fontWeight: isSelected ? 700 : 400,
                      textAlign: 'center',
                      lineHeight: 1.2,
                      fontSize: { lg: '1.4rem', xs: '1.2rem' },
                    }}
                  >
                    {category.name}
                  </Typography>
                </CenterAlignStack>
              )
            })}
          </Grid>
        </Stack>
      )}

      {['clothes', 'food', 'person'].includes(config.category) && (
        <SubCategoryDetail
          subCategorys={subCategoryItems}
          small={true}
          config={config}
          setConfig={setConfig}
        />
      )}
    </CenterAlignStack>
  )
}

export function SetPortfolioConcept({
  config,
  setConfig,
  isDialog,
  sx,
  files = [],
  setFiles = () => {},
  ...props
}) {
  const isMobile = useMobileMediaQuery()
  const { t } = useTranslation()

  const simpleOrConcept = [
    {
      imageSrc: '/static/images/api_info/feature_1_before.png',
      value: true,
      source: t('upload_config.bg_simple'),
    },
    {
      imageSrc: '/static/images/api_info/feature_1_after.png',
      value: false,
      source: t('upload_config.bg_concept'),
    },
  ]
  const THEME_ITEM_PER_PAGE = 15 // 고정아이템 포함한 수
  const THEME_ITEM_PER_ROW_MOBILE = 4 // 모바일

  const scrollRef = useRef(null)

  const ex0 = t('upload_config.bg_theme_ex_1')
  const [placeholder, setPlaceholder] = useState(ex0)

  const [isSimple, setIsSimple] = useRecoilState(isSimpleAtom) // 기본 선택일 경우 옮겨지는 느낌이 생김

  const [showCustomTheme, setShowCustomTheme] = useState(false)
  const [selected, setSelected] = useState(null)
  const [themeList, setThemeList] = useState([]) // 고정아이템을 제외한 테마들만
  const [themeListWithFix, setThemeListWithFix] = useState([]) // 고정아이템 포함
  const [themePageItem, setThemePageItem] = useState([])
  const [themePageCount, setThemePageCount] = useState(1)
  const [themePage, setThemePage] = useState(1)
  const [showLeftShadow, setShowLeftShadow] = useState(false)
  const [showRightShadow, setShowRightShadow] = useState(true)

  const portfolioType = useRecoilValue(portfolioTypeAtom)
  const isUploadPage = portfolioType === configConstant.PORTFOLIO_TYPE_UPLOAD

  const isFile = Boolean(files?.length)
  const [hasError, setHasError] = useState(false)

  const onFocus = idx => () => {
    if (idx === 0) setPlaceholder('')
  }
  const onBlur = idx => () => {
    if (idx === 0) setPlaceholder(ex0)
  }

  const handleScroll = () => {
    const l = scrollRef.current.scrollLeft
    const sw = scrollRef.current.scrollWidth
    const cw = scrollRef.current.clientWidth

    if (l > 10) {
      setShowLeftShadow(true)
    } else {
      setShowLeftShadow(false)
    }

    if (cw + l < sw - 10) {
      setShowRightShadow(true)
    } else {
      setShowRightShadow(false)
    }
  }

  useEffect(() => {
    const div = scrollRef.current
    if (div) {
      div.addEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (!selected) return
    if (selected?.id === 'custom') {
      setShowCustomTheme(true)
      setConfig({ ...config, theme_template: selected.id })
    } else if (selected?.id) {
      setShowCustomTheme(false)
      setConfig({ ...config, theme_custom: '', theme_template: selected.id })
    }
  }, [selected])

  useEffect(() => {
    const t = configConstant.BG_THEME_TEMPLATES.filter(t => t.id === config.theme_template)

    if (t.length > 0) {
      setSelected(t[0])
    } else {
      setSelected({ id: 'auto' })
    }
  }, [config.theme_template])

  useEffect(() => {
    if (isSimple === 'nothing') return
    // ref가 있는데 그게 심플로 처리되는 문제 발생
    if (isSimple === true) {
      // setShowCustomTheme(false)
      setConfig({
        ...config,
        bgSimple: true,
        bgComplex: false,
        bgGenerate: false,
        theme_template: 'auto',
      })

      setHasError(false)
    } else {
      const updatedConfig = {
        ...config,
        bgSimple: false,
        bgComplex: !config.flagMultiblobSod,
        bgGenerate: true,
      }

      if (config.outputSizeList.length > 1) {
        let configOutputlist = [...config.outputSizeList]

        configOutputlist = configOutputlist.filter(o => o.w !== null && o.h !== null).slice(0, 1)

        setConfig({
          ...updatedConfig,
          outputSizeList: configOutputlist,
        })
        setHasError(true)
      } else {
        setConfig(updatedConfig)
      }
    }
  }, [isSimple])

  useEffect(() => {
    if (config?.hasRefImage) {
      setIsSimple(false)
      setSelected({ id: 'auto' })
    } else {
      const isSimpleConfig = !config.bgComplex && !config.bgGenerate
      setIsSimple(isSimpleConfig)
    }

    return () => {
      setIsSimple('nothing')
    }
  }, [])

  useEffect(() => {
    let arr = [...configConstant.BG_THEME_TEMPLATES]
    arr = arr.filter(x => !x.fixInPage)

    const order = configConstant.BG_THEME_ORDER.default

    arr.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))

    setThemeList(arr)
  }, [config.category])

  useEffect(() => {
    const fixItems = configConstant.BG_THEME_TEMPLATES.filter(x => x.fixInPage)

    setThemeListWithFix([
      ...fixItems.filter(x => x.fixInPage === 'first'),
      ...themeList,
      ...fixItems.filter(x => x.fixInPage === 'last'),
    ])
  }, [themeList])

  useEffect(() => {
    const fixItems = configConstant.BG_THEME_TEMPLATES.filter(x => x.fixInPage)

    const c = THEME_ITEM_PER_PAGE - fixItems.length

    setThemePageCount(Math.ceil(themeList.length / c))
    const a = (themePage - 1) * c
    const b = themePage * c

    setThemePageItem([
      ...fixItems.filter(x => x.fixInPage === 'first'),
      ...themeList.slice(a, b),
      ...fixItems.filter(x => x.fixInPage === 'last'),
    ])
  }, [themeList, themePage])

  const theme = useTheme()

  const dialogBoxStyle = {
    width: '10rem',
    height: '10rem',
  }

  const dialogSourceStyle = {
    height: '2.2rem',
    width: '10rem',
    fontSize: '1.2rem',
  }

  const dialogImageStyle = {
    height: '7.8rem',
    width: '10rem',
    objectPosition: 'top',
  }

  const contentStyle = {
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    width: '100%',
    height: '2.4rem',

    color: theme.palette.common.white,
    borderRadius: '0 0 10px 10px',
    fontSize: '1.2rem',
    fontWeight: 600,
  }

  const deleteCallback = () => {
    setIsSimple(false)
    setSelected({ id: 'auto' })
  }

  // hasRefImage 일 때 그냥 돌릴 경우
  useEffect(() => {
    if (config.hasRefImage) {
      setConfig(p => ({ ...p, theme_template: 'auto' }))
    }
  }, [])

  const containerRef = useRef(null)

  // useEffect(() => {
  //   if (showCustomTheme) {
  //     containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
  //   }
  // }, [showCustomTheme])

  return (
    <CenterAlignStack sx={{ m: { lg: '3.2rem 0 0rem 0', xs: '2.2rem 0 2.4rem 0' }, width: '100%' }}>
      {/* TODO 문구 변경 필요 */}
      <PageTitle sx={{}}>{t('upload_config.bg_theme')}</PageTitle>
      <PageComment
        sx={{
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          mb: { lg: '1.6rem', xs: '1.6rem' },
        }}
        component="div"
      >
        {t('upload_config.bg_theme_comment_1')}
      </PageComment>

      <CenterAlignBox sx={{ gap: '1.2rem', mb: hasError ? '1.2rem' : '2.4rem' }}>
        {simpleOrConcept.map(p => {
          return (
            <OutlinedImage
              isDisabled={isFile}
              key={p.source}
              imageSrc={p.imageSrc}
              source={p.source}
              value={p.value}
              config={isSimple}
              boxStyleAdd={{
                ...dialogBoxStyle,
                ...(isFile && { border: '3px solid #EEEEEE' }),
              }}
              sourceStyleAdd={{
                ...dialogSourceStyle,
                ...(isFile && { backgroundColor: '#EEEEEE' }),
              }}
              imageStyleAdd={{
                objectPosition: 'top',
                ...dialogImageStyle,
                ...(isFile && { filter: 'grayscale(1)' }),
              }}
              setConfig={setIsSimple}
              // clickAdd={() => {
              //   if (!p.value) {
              //     setSelected({ id: 'auto' })
              //   }
              // }}
            />
          )
        })}
        {isUploadPage && (
          <UploadButton
            formStyle={{ width: '10rem', height: '10rem' }}
            multiple={false}
            labelFormStyle={{
              border:
                isSimple === 'ref'
                  ? `3px solid ${theme.palette.draph.newBlue}`
                  : '1px solid #d9d9d9',
              backgroundColor: isSimple === 'ref' ? '#F2F6FF' : '#eee',
            }}
            files={files}
            setFiles={setFiles}
            uploadImglimit={1}
            thumbnailMode={true}
            deleteCallback={deleteCallback}
            plusSx={{ width: '2rem', height: '2rem' }}
            keepShowingOn={false}
            thumbnailPlusComponent={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  marginBottom: '1.4rem',
                }}
              >
                <PlusIcon2
                  color={isSimple === 'ref' ? '#B6CBFF' : '#808080'}
                  strokeWidth="5"
                  style={{ width: '2rem', height: '2rem' }}
                />
                <CenterAlignBox
                  sx={{
                    ...contentStyle,
                    backgroundColor:
                      isSimple === 'ref'
                        ? theme.palette.draph.newBlue
                        : files.length
                        ? theme.palette.draph.newBlue
                        : theme.palette.common.black,
                  }}
                >
                  {t('upload_header.ref_image')}
                </CenterAlignBox>
              </div>
            }
          />
        )}
      </CenterAlignBox>

      {config?.hasRefImage && (
        <OpacityMotionDiv>
          <CenterAlignStack
            sx={{
              borderRadius: '20px',
              p: '0.9rem 2.3rem',
              backgroundColor: '#F8F8F8',
              mb: '2.4rem',
            }}
          >
            <Typography sx={{ fontSize: '1.3rem', fontWeight: 500 }}>
              {t('regenerate_dialog.ref_warning_1')}
              <span style={{ fontWeight: 700 }}>{t('regenerate_dialog.ref_warning_2')}</span>
            </Typography>
          </CenterAlignStack>
        </OpacityMotionDiv>
      )}

      {hasError && (
        <OpacityMotionDiv>
          <Typography
            sx={{
              fontSize: { lg: '1.2rem', xs: '1.1rem' },
              color: theme.palette.error.main,
              mb: { lg: '2rem', xs: '1.8rem' },
              textAlign: 'center',
            }}
          >
            {t('upload_config.concept_warning_1')} <br /> {t('upload_config.concept_warning_2')}
          </Typography>
        </OpacityMotionDiv>
      )}
      <Desktop>
        {!isSimple && !isFile && (
          <OpacityMotionDiv
            style={{
              backgroundColor: '#FAFAFA',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ mt: '2.4rem', fontSize: '1.3rem' }}>
              {t('upload_config.bg_theme_comment_2')}
            </Typography>

            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                '& #thumb-list': { mx: '2rem' },
                '& .svgBox': {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  width: '4rem',
                  height: '4rem',
                  backgroundColor: '#303030',
                  transition: 'opacity 0.3s',
                  opacity: 0.3,

                  '&.prev': {
                    cursor: themePage <= 1 ? 'not-allowed' : 'pointer',
                    '&:hover': { opacity: themePage <= 1 ? 0.3 : 0.6 },
                  },
                  '&.next': {
                    cursor: themePage >= themePageCount ? 'not-allowed' : 'pointer',
                    '&:hover': { opacity: themePage >= themePageCount ? 0.3 : 0.6 },
                  },
                },

                '& #prev-icon': { '& path': { stroke: themePage <= 1 ? '#BBBBBB' : '#FFFFFF' } },
                '& #next-icon': {
                  '& path': { stroke: themePage >= themePageCount ? '#BBBBBB' : '#FFFFFF' },
                },
              }}
            >
              <Box
                className="svgBox prev"
                onClick={() => {
                  if (themePage > 1) {
                    setThemePage(p => p - 1)
                  }
                }}
              >
                <SvgIcon
                  id="prev-icon"
                  sx={{ width: '1.167rem', height: '2rem', fill: 'none' }}
                  viewBox="0 0 12 20"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5119 0.488155C10.9111 -0.112651 9.96569 -0.158867 9.31188 0.349508L9.15486 0.488155L0.821529 8.82149C0.220723 9.4223 0.174507 10.3677 0.682882 11.0215L0.821529 11.1785L9.15486 19.5118C9.80574 20.1627 10.861 20.1627 11.5119 19.5118C12.1127 18.911 12.1589 17.9657 11.6505 17.3118L11.5119 17.1548L4.35837 10L11.5119 2.84518C12.1127 2.24437 12.1589 1.29899 11.6505 0.645168L11.5119 0.488155Z"
                    fill="white"
                  />
                </SvgIcon>
              </Box>

              <Grid
                id="thumb-list"
                container
                columns={5}
                gap={1}
                sx={{
                  width: { lg: '55rem', xs: '33rem' },
                  mb: '3rem',
                  mt: '1.8rem',

                  justifyContent: 'center',
                  // border: '1px solid red',
                }}
              >
                {themePageItem.map((item, idx) => (
                  <Grid
                    item
                    key={`${item.id}_${idx}`}
                    // lg={item.id === 'auto' ? 13 : 3}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelected(item)
                    }}
                  >
                    <ThemeTemplateThumb
                      theme={item}
                      selected={selected?.id === item.id}
                      category={config.category}
                      sx={{ width: '10rem', height: '10rem' }}
                    />
                  </Grid>
                ))}

                {/* dummy */}
                {Array(THEME_ITEM_PER_PAGE - themePageItem.length)
                  .fill(0)
                  .map((a, i) => (
                    <Grid item key={i}>
                      <ThemeTemplateThumb
                        theme={{ id: null }}
                        sx={{
                          background: 'transparent',
                          width: '10rem',
                          height: '10rem',
                          border: 'none',
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>

              <Box className="svgBox next">
                <SvgIcon
                  id="next-icon"
                  sx={{ width: '1.167rem', height: '2rem', fill: 'none' }}
                  viewBox="0 0 12 20"
                  onClick={() => {
                    if (themePage < themePageCount) {
                      setThemePage(p => p + 1)
                    }
                  }}
                >
                  <path
                    d="M0.488155 0.488155C1.08896 -0.112651 2.03435 -0.158867 2.68817 0.349508L2.84518 0.488155L11.1785 8.82149C11.7793 9.4223 11.8255 10.3677 11.3172 11.0215L11.1785 11.1785L2.84518 19.5118C2.1943 20.1627 1.13903 20.1627 0.488155 19.5118C-0.112651 18.911 -0.158867 17.9657 0.349508 17.3118L0.488155 17.1548L7.64167 10L0.488155 2.84518C-0.112651 2.24437 -0.158867 1.29899 0.349508 0.645168L0.488155 0.488155Z"
                    fill="white"
                  />
                </SvgIcon>
              </Box>
            </Stack>
          </OpacityMotionDiv>
        )}
      </Desktop>

      <Mobile>
        {!isSimple && !isFile && (
          <Box
            sx={{
              width: '36rem',
              position: 'relative',
              mb: '1rem',
              display: 'flex',
              justifyContent: 'center',
              ml: '3rem',
            }}
          >
            {/* <Box
            sx={{
              width: '100%',
              // height: `${Math.floor(themeListWithFix.length / THEME_ITEM_PER_ROW_MOBILE) * 10}rem`,
              height: '100%',
              position: 'absolute',
              display: 'flex',
            }}
          >
            <div
              style={{
                top: 0,
                position: 'absolute',
                height: '100%',

                zIndex: 3,
                transform: 'rotate(180deg)',
                background:
                  'linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
                opacity: showLeftShadow ? 1 : 0,
                width: showLeftShadow ? '5.6rem' : '0rem',
                transition: 'all 0.1s',
              }}
            />

            <div
              style={{
                top: 0,
                right: 0,
                position: 'absolute',
                height: '100%',
                zIndex: 3,
                background:
                  'linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
                opacity: showRightShadow ? 1 : 0,
                width: showRightShadow ? '5.6rem' : '0rem',
                transition: 'all 0.1s',
              }}
            />
          </Box> */}
            <div
              style={{
                width: '36rem',
                overflow: 'auto',
              }}
              ref={scrollRef}
            >
              <Grid
                id="thumb-list"
                container
                columns={4}
                gap="1rem"
                sx={{
                  width: '43rem',
                  overflow: 'scroll',
                  py: '1rem',
                  justifyContent: 'center',
                }}
              >
                {themeListWithFix.map((item, idx) => (
                  <Grid
                    item
                    key={`${item.id}_${idx}`}
                    // xs={3}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelected(item)
                    }}
                  >
                    <ThemeTemplateThumb
                      theme={item}
                      selected={selected?.id === item.id}
                      category={config.category}
                    />
                  </Grid>
                ))}
                {/* dummy */}
                {Array(
                  THEME_ITEM_PER_ROW_MOBILE - (themeListWithFix.length % THEME_ITEM_PER_ROW_MOBILE)
                )
                  .fill(0)
                  .map((a, i) => (
                    <Grid item key={i}>
                      <ThemeTemplateThumb
                        theme={{ id: null }}
                        sx={{
                          background: 'transparent',
                          width: '10rem',
                          height: '10rem',
                          border: 'none',
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Box>
        )}
      </Mobile>

      {!isSimple && !isFile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isMobile ? '#FFFFFF' : '#F8F8F8',
            width: '100%',
            pb: showCustomTheme ? (isMobile ? 0 : '3rem') : 0,
            height: showCustomTheme ? (isMobile ? 'auto' : '15.9rem') : 0,
            transition: 'height 0.3s',
          }}
          ref={containerRef}
        >
          <motion.div
            animate={showCustomTheme ? 'show' : 'hide'}
            variants={{
              show: {
                opacity: 1,
                y: 0,
                transition: {
                  type: 'cubic-bezier(0, 1.35, 0.64, 0.95)',
                  duration: 0.5,
                },
              },
              hide: {
                // y: -5,
                opacity: 0,
              },
            }}
            onAnimationComplete={latest => {
              if (showCustomTheme) {
                containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
              }
            }}
          >
            <Card
              sx={{
                pt: '2rem',
                pb: '3rem',
                px: { lg: '5rem', xs: '2rem' },
                display: showCustomTheme ? '' : 'none',
              }}
            >
              <CenterAlignStack>
                <Typography
                  sx={{
                    fontSize: { lg: '1.6rem', xs: '1.3rem' },
                    fontWeight: 600,
                    mb: { lg: '1.5rem', xs: '0.6rem' },
                  }}
                >
                  {t('upload_config.bg_theme_comment_2_a')}
                  <SpanDraphBlue>{t('upload_config.bg_theme_comment_2_b')}</SpanDraphBlue>
                  {t('upload_config.bg_theme_comment_2_c')}
                </Typography>

                <CustomOutlinedInput
                  value={config.theme_custom}
                  placeholder={placeholder}
                  onChange={e => {
                    setConfig({ ...config, theme_custom: e.target.value })
                  }}
                  onFocus={onFocus(0)}
                  onBlur={onBlur(0)}
                  type="text"
                  inputProps={{ maxLength: 1000 }}
                  sx={{ height: '4rem' }}
                />
              </CenterAlignStack>
            </Card>
          </motion.div>
        </Box>
      )}
    </CenterAlignStack>
  )
}

export function ThemeTemplateThumb({ theme, selected, category, sx }) {
  const { t } = useTranslation()
  const THUMB_IMAGE_PATH = '/static/images/theme_template'
  const th = useTheme()
  const cardStyle = {
    boxShadow: 'none',
    borderRadius: '10px',
    border:
      ['auto', 'custom'].includes(theme.id) && selected
        ? `3px solid ${th.palette.draph.blue}`
        : `1px solid #D9D9D9`,
  }

  const AutoThumb = () => (
    <CenterAlignStack id="theme-auto" sx={{ height: '100%', justifyContent: 'center' }}>
      <Box id="image-box" sx={{ width: { lg: '4rem', xs: '4rem' } }}>
        <img
          src="/static/images/logo/logo_image.png"
          style={{
            objectFit: 'cover',
            height: 'auto',
          }}
        />
      </Box>
      <CenterAlignBox
        id="title-box"
        sx={{
          mt: '1rem',
          width: { lg: '5.8rem', xs: '6.4rem' },
          height: { lg: '2rem', xs: '2.2rem' },
          borderRadius: '10px',
          background: selected ? th.palette.draph.blue : th.palette.common.black,
          alignItems: 'center',
        }}
      >
        <Typography id="title" sx={{ color: 'white', fontSize: '1.2rem', fontWeight: 700 }}>
          AI
        </Typography>
      </CenterAlignBox>
    </CenterAlignStack>
  )

  const CustomThumb = () => (
    <CenterAlignStack id="theme-custom" sx={{ height: '100%', justifyContent: 'center' }}>
      <DashCirclePencilIcon
        id="icon"
        color={selected ? th.palette.draph.blue : th.palette.common.black}
      />
      <CenterAlignBox
        id="title-box"
        sx={{
          mt: '1rem',
          width: { lg: '7.2rem', xs: '6.4rem' },
          height: { lg: '2.2rem', xs: '2.2rem' },
          borderRadius: '10px',
          background: selected ? th.palette.draph.blue : th.palette.common.black,
          alignItems: 'center',
        }}
      >
        <Typography id="title" sx={{ color: 'white', fontSize: '1.2rem', fontWeight: 700 }}>
          {t('upload_config.custom')}
        </Typography>
      </CenterAlignBox>
    </CenterAlignStack>
  )

  return (
    <Card
      sx={{
        width: { lg: '10.8rem', xs: '10rem' },
        height: { lg: '10.8rem', xs: '10rem' },
        ...cardStyle,
        ...sx,
      }}
    >
      {!theme.id ? (
        <></>
      ) : theme.id === 'auto' ? (
        <AutoThumb />
      ) : theme.id === 'custom' ? (
        <CustomThumb />
      ) : (
        <Box
          id="item-box"
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            // background: 'lightgray',
            // border: '1px solid gold',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* {theme.id} */}
          <img
            src={`${THUMB_IMAGE_PATH}/${theme.id}/${
              category === 'person' && theme.sample?.person
                ? theme.sample?.person
                : theme.sample?.default
            }`}
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
          <Box
            id="selected-name-box"
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: { lg: '2.8rem', xs: '2.4rem' },
              background: selected
                ? th.palette.draph.blue
                : 'linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 100%)',

              borderRadius: '0 0 10px 10px',
            }}
          ></Box>
          <Typography
            id="selected-name-text"
            sx={{
              fontSize: { lg: '1.3rem', xs: '1.3rem' },
              fontWeight: 600,
              color: 'white',
              position: 'absolute',
              bottom: '3.5px',
              zIndex: 2,
            }}
          >
            {t(`theme_template.${theme.id}`)}
          </Typography>
          <Box
            id="selected-border-box"
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              borderRadius: '10px',
              border: theme => (selected ? `3px solid ${theme.palette.draph.blue}` : ''),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'end',
            }}
          ></Box>
        </Box>
      )}
    </Card>
  )
}

// -----

const MULTI_SIZE_LIMIT = 6

export function SetPortfolioOutput({ config, setConfig, sx, ...props }) {
  const { t, i18n } = useTranslation()
  const user = useRecoilValue(userAtom)
  const isSimple = Boolean(config.bgSimple)

  const currentMenu = useRecoilValue(currentMenuAtom)
  const theme = useTheme()

  const [showSizeWarning, setShowSizeWarning] = useState(false)
  const [minSizeWarning, setMinSizeWarning] = useState(false)
  const [maxSizeWarning, setMaxSizeWarnig] = useState(false)
  const [openMarket, setOpenMarket] = useState(false)
  const openMarketSizeLists = [{ w: 1000, h: 1000 }]

  const bgTypes = useRecoilValue(portfolioBgTypeSelector).filter(bg => bg.name !== 'bgWhite')
  const bgWhite = useRecoilValue(portfolioBgTypeSelector).filter(bg => bg.name === 'bgWhite')

  const isMobile = useMobileMediaQuery()
  const { enqueueSnackbar } = useSnackbar()

  const isOutputSizeValid =
    config?.outputSizeList.length > 1 &&
    config?.outputSizeList[1].w !== null &&
    config?.outputSizeList[1].h !== null

  const bgTypeChecked = () => {
    // 하나라도 체크 되어있는지
    let count = 0
    if (config.bgSimple) count++
    if (config.bgComplex) count++
    if (config.bgGenerate) count++
    return count
  }

  useEffect(() => {
    if (!bgTypeChecked()) {
      setConfig({
        ...config,
        bgSimple: true,
      })
    }
  }, [
    config.bgSimple,
    config.bgComplex,
    config.bgGenerate,
    config.outputSizeList,
    config.flagMultiblobSod,
  ])

  // const openMarketHandle = () => {
  //   setOpenMarket(!openMarket)

  //   if (config.outputSizeList.length === 2) {
  //     setConfig({
  //       ...config,
  //       bgComplex: true,
  //       bgGenerate: true,
  //       bgSimple: true,
  //       bgWhite: true,
  //     })
  //   }

  //   if (!openMarket) {
  //     setConfig(prevState => ({
  //       ...prevState,
  //       outputSizeList: [...openMarketSizeLists, ...prevState.outputSizeList],
  //     }))
  //   } else {
  //     setConfig(prevState => {
  //       let editList = [...prevState.outputSizeList]
  //       if (prevState.outputSizeList.length === 1) {
  //         editList = [{ w: null, h: null }]
  //       } else {
  //         editList.shift()
  //       }
  //       // editList.shift()
  //       return {
  //         ...prevState,
  //         outputSizeList: [...editList],
  //       }
  //     })
  //   }
  // }

  useEffect(() => {
    if (config.outputSizeList.length === 0) {
      setConfig(p => ({ ...p, outputSizeList: [{ w: null, h: null }] }))
    }
  }, [])

  useEffect(() => {
    if (isOutputSizeValid && (config.bgSimple || config.bgWhite)) {
      setConfig({
        ...config,
        bgComplex: false,
        bgGenerate: false,
      })
    }
  }, [config.outputSizeList])

  useEffect(() => {
    if (!config.outputSizeList.length) return

    let isSizeWarning = false
    for (const o of config.outputSizeList) {
      const ratio = o.w / o.h
      if (ratio > 2 || ratio < 0.5) {
        isSizeWarning = true
        break
      }
    }

    setShowSizeWarning(isSizeWarning)
  }, [config.outputSizeList])

  const addOutputList = () => {
    setConfig(prevState => ({
      ...prevState,
      outputSizeList: [...prevState.outputSizeList, { w: null, h: null }],
    }))
  }

  const addOpenMarket =
    (oneMode = false) =>
    () => {
      const lastSize = config.outputSizeList[config.outputSizeList.length - 1]

      if (oneMode) {
        return setConfig(prevState => ({
          ...prevState,
          outputSizeList: [{ w: 1000, h: 1000 }],
        }))
      }

      if (config.outputSizeList.length === 6 && lastSize.w === null && lastSize.h === null) {
        setConfig(prevState => {
          const copyOutputSizeList = [...prevState.outputSizeList]
          copyOutputSizeList.pop()
          copyOutputSizeList.push({ w: 1000, h: 1000 })

          return { ...prevState, outputSizeList: copyOutputSizeList }
        })
      } else if (config.outputSizeList.length === 6) {
        return null
      } else {
        setConfig(prevState => ({
          ...prevState,
          outputSizeList: [{ w: 1000, h: 1000 }, ...prevState.outputSizeList],
        }))
      }

      // ----- GA4 event -----
      window.gtag('event', 'universal_thumbnail', { menu: currentMenu?.id })
      // ---------------------
    }

  const deleteOutputList = idx => () => {
    // 2줄 일때 1줄로 삭제될 시 설정값 초기화
    if (config.outputSizeList.length === 2) {
      setConfig({
        ...config,
        bgComplex: !config.flagMultiblobSod,
        bgGenerate: true,
        bgSimple: true,
        bgWhite: true,
      })
    }

    setConfig(prevState => {
      let editList = [...prevState.outputSizeList]
      if (prevState.outputSizeList.length === 1) {
        editList = [{ w: null, h: null }]
      } else {
        editList.splice(idx, 1)
      }

      return {
        ...prevState,
        outputSizeList: [...editList],
      }
    })
  }

  // 여기부터 고급설정 부분 이관

  const faceImages = [
    {
      image: '/static/images/portfolioConfig/face/face_before.jpg',
      imageStyle: {
        width: '10rem',
        height: '7.8rem',
        objectPosition: '0 -0.3rem',
      },
      style: {
        width: '10rem',
        height: '10rem',
      },

      sourceStyle: {
        alignItems: 'center',
        width: '10rem',
        height: '2.2rem',
        backgroundColor: !config.genFace ? theme.palette.draph.newBlue : theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: '1.2rem',
        lineHeight: 1,
        fontWeight: 700,
      },
      face: false,
      source: 'OFF',
    },
    {
      image: '/static/images/portfolioConfig/face/face_aftrer.jpg',
      imageStyle: {
        width: '10rem',
        height: '7.8rem',
        objectPosition: '0 -0.3rem',
      },
      style: {
        width: '10rem',
        height: '10rem',
      },
      sourceStyle: {
        alignItems: 'center',
        width: '10rem',
        height: '2.2rem',
        backgroundColor: config.genFace ? theme.palette.draph.blue : theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: '1.2rem',
        lineHeight: 1,
        fontWeight: 700,
      },
      face: true,
      source: 'ON',
    },
  ]

  const shadowImages = [
    {
      image: '/static/images/portfolioConfig/shadow/shadow_before.jpg',
      imageStyle: {
        width: '10rem',
        height: '7.8rem',
        objectPosition: '0 -0.8rem',
      },
      style: {
        width: '10rem',
        height: '10rem',
      },
      shadow: false,
      sourceStyle: {
        alignItems: 'center',
        width: '10rem',
        height: '2.2rem',
        backgroundColor: !config.genShadow
          ? theme.palette.draph.newBlue
          : theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: '1.2rem',
        lineHeight: 1,
        fontWeight: 700,
      },
      source: 'OFF',
    },
    {
      image: '/static/images/portfolioConfig/shadow/shadow_after.jpg',
      imageStyle: {
        width: '10rem',
        height: '7.8rem',
        objectPosition: '0 -0.8rem',
      },
      style: {
        width: '10rem',
        height: '10rem',
      },
      shadow: true,
      sourceStyle: {
        width: '10rem',
        height: '2.2rem',
        backgroundColor: config.genShadow
          ? theme.palette.draph.newBlue
          : theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: '1.2rem',
        lineHeight: 1,
        fontWeight: 700,
      },
      source: 'ON',
    },
  ]

  const multiBlobImages = [
    {
      image: '/static/images/portfolioConfig/mult_blob/multi_blob_2.png',
      imageStyle: {
        width: '10rem',
        height: '7.8rem',
        border: '1px solid #D9D9D9',
        objectPosition: '0 -0.8rem',
      },
      style: {
        width: '10rem',
        height: '10rem',
      },
      multiBlob: true,
      sourceStyle: {
        width: '10rem',
        height: '2.2rem',
        backgroundColor: config.flagMultiblobSod
          ? theme.palette.draph.newBlue
          : theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: '1.2rem',
        lineHeight: 1,
        fontWeight: 700,
      },
      source: 'OFF',
    },
    {
      image: '/static/images/portfolioConfig/mult_blob/multi_blob_1.png',
      imageStyle: {
        width: '10rem',
        height: '7.8rem',
        border: '1px solid #D9D9D9',
        objectPosition: '0 -0.8rem',
      },
      style: {
        width: '10rem',
        height: '10rem',
      },
      multiBlob: false,
      sourceStyle: {
        width: '10rem',
        height: '2.2rem',
        backgroundColor: !config.flagMultiblobSod
          ? theme.palette.draph.newBlue
          : theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: '1.2rem',
        lineHeight: 1,
        fontWeight: 700,
      },
      source: 'ON',
    },
  ]

  const AIIcon = ({ color = '#303030' }) => {
    return (
      <Box
        sx={{
          width: '6.4rem',
          height: '2.8rem',
          backgroundColor: color,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '1rem',
          color: theme.palette.common.white,
          fontWeight: 700,
          fontSize: '1.6rem',
        }}
      >
        AI
      </Box>
    )
  }

  const angleImages = [
    { image: <AIIcon />, value: 'auto' },
    { image: '/static/images/angle_example/angle_02_floor.png', value: 'floor' },
    { image: '/static/images/angle_example/angle_03_45.png', value: 'diag' },
    { image: '/static/images/angle_example/angle_04_perpen.png', value: 'perpen' },
  ]

  const shadowClick = value => () => {
    setConfig({
      ...config,
      genShadow: value,
    })
  }
  const faceClick = value => () => {
    setConfig({
      ...config,
      genFace: value,
    })
  }
  const multiBlobClick = value => () => {
    setConfig({
      ...config,
      bgComplex: isSimple ? false : !value,
      flagMultiblobSod: value,
    })
  }

  const angleClick = value => () => {
    setConfig({ ...config, objectAngle: value })
  }

  return (
    <>
      <CenterAlignStack
        sx={{
          width: '100%',
          m: { lg: '3.6rem 0 4.4rem 0', xs: '2.2rem 0 2.4rem 0' },
        }}
      >
        <PageTitle>{t('upload_config.advanced_setting')}</PageTitle>
        <PageComment
          sx={{
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
            mb: { lg: '3.6rem', xs: '1.6rem' },
          }}
          component="div"
        >
          {t('upload_config.advanced_setting_notice')}
        </PageComment>

        <CenterAlignStack
          sx={{
            width: '100%',
            m: { lg: '0rem 0 0rem 0', xs: '2rem 0 0 0' },
          }}
        >
          {/* <Typography fontSize={{ lg: '1.6rem', xs: '1.3rem' }} fontWeight={600}>
            {t('upload_config.advanced_setting_notice')}
          </Typography> */}

          <Stack direction="row" sx={{ p: '0rem 0 2.8rem 0' }}>
            <Typography fontSize={'1.6rem'} fontWeight={700} sx={{ mx: ' 1rem' }}>
              {t('upload_config.include_nukki')}
            </Typography>
            <BlueSwitch
              sx={isMobile ? optionSwitchMobileStyle : optionSwitchStyle}
              checked={config.bgWhite}
              onChange={e => {
                setConfig({
                  ...config,
                  bgWhite: !config.bgWhite,
                })
              }}
            />
          </Stack>

          <Stack
            direction="column"
            sx={{
              alignItems: { lg: 'center' },
              gap: { lg: 0, xs: '0' },
              backgroundColor: '#FAFAFA',
              width: { lg: 'inherit', xs: '100vw' },
              p: isSimple ? '1.8rem 0 1.8rem 0' : '1.8rem 0 2.4rem 0',
            }}
          >
            <Typography
              sx={{
                lineHeight: 'normal',
                fontSize: '1.6rem',
                fontWeight: 800,
                textAlign: 'center',
                mb: { lg: '0.4rem', xs: '0.2rem' },
              }}
            >
              {t('upload_config.image_size')}
            </Typography>
            <Typography
              sx={{
                mb: { lg: '2rem', xs: '1.2rem' },
                lineHeight: 'normal',
                fontSize: { lg: '1.3rem', xs: '1.2rem' },
                color: '#303030',
                textAlign: 'center',
              }}
            >
              {t('upload_config.image_size_comment_1_a')} {isMobile && <br />}
              {t('upload_config.image_size_comment_1_b')}{' '}
            </Typography>
            {config.outputSizeList.map((list, idx, lists) => {
              return (
                <CenterAlignStack key={idx}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {!isMobile && isSimple && (
                      <Box sx={{ width: '4.6rem', height: '3.6rem' }}></Box>
                    )}
                    {idx === 0 && !isMobile && isKo(i18n) && (
                      <Box sx={{ width: '10.8rem', height: '3.2rem', mr: '1.6rem' }}></Box>
                    )}

                    <OutputField
                      config={config}
                      setConfig={setConfig}
                      setMinSizeWarning={setMinSizeWarning}
                      setMaxSizeWarning={setMaxSizeWarnig}
                      index={idx}
                      list={list}
                      openMarket={openMarket}
                    />

                    {isSimple && (
                      <IconButton
                        sx={{
                          backgroundColor: '#EEE',
                          m: isMobile ? '0.8rem 0rem 0.8rem 1.6rem' : '0.8rem 0rem 0.8rem 1.6rem',
                          width: '3.2rem',
                          height: '3.2rem',
                          '&:disabled': { backgroundColor: '#e8e6e6', opacity: 0.4 },
                          '&:hover': {
                            backgroundColor: 'rgb(193, 213, 255, 0.7)',
                            opacity: 0.8,
                          },
                          transition: 'all 0.3s',
                        }}
                        onClick={deleteOutputList(idx)}
                        disabled={openMarket && idx === 0}
                      >
                        <MinusIcon />
                      </IconButton>
                    )}

                    {!isSimple && isMobile && (
                      // <IconButton
                      //   sx={{
                      //     backgroundColor: '#EEE',
                      //     m: isMobile ? '0 0 0 1.6rem' : '0.8rem 0rem 0.8rem 1.6rem',
                      //     width: '3.2rem',
                      //     height: '3.2rem',
                      //     '&:disabled': { backgroundColor: '#e8e6e6', opacity: 0.4 },
                      //     '&:hover': {
                      //       backgroundColor: 'rgb(193, 213, 255, 0.7)',
                      //       opacity: 0.8,
                      //     },
                      //     transition: 'all 0.3s',
                      //   }}
                      //   onClick={deleteOutputList(idx)}

                      // >
                      //   <MinusIcon />
                      // </IconButton>
                      <IconButton
                        variant="outlined"
                        onClick={addOpenMarket(!isSimple)}
                        sx={{
                          ml: '0.7rem',
                          p: 0,
                          '&:hover': {
                            background: theme.palette.common.white,
                            '& path': { stroke: theme.palette.draph.blue },
                            '& circle': { stroke: theme.palette.draph.blue },
                          },
                        }}
                      >
                        <OpenMarketIcon />
                      </IconButton>
                    )}
                    <Desktop>
                      <CustomTooltip
                        title={
                          <>
                            선택 시 주요 오픈마켓에서 <br />
                            사용되는 대표 이미지 사이즈가 자동으로 입력됩니다.
                          </>
                        }
                      >
                        {idx === 0 && isKo(i18n) && (
                          <OpenMarketButton variant="outlined" onClick={addOpenMarket(!isSimple)}>
                            오픈 마켓 범용
                          </OpenMarketButton>

                          // <OpenMarketToggle
                          //   value="check"
                          //   selected={openMarket}
                          //   onChange={openMarketHandle}
                          //   //   () => {
                          //   //   setOpenMarket(!openMarket)
                          //   // }

                          //   sx={{}}
                          // >
                          //   오픈 마켓 범용
                          // </OpenMarketToggle>
                        )}
                      </CustomTooltip>
                    </Desktop>
                  </Box>
                  {lists.length - 1 === idx && isSimple && (
                    <CenterAlignBox sx={{ gap: '0.6rem', mt: isMobile ? '1.1rem' : '2rem' }}>
                      <Mobile>
                        <Box
                          sx={{
                            width: '3.2rem',
                            height: '3.2rem',
                          }}
                        ></Box>
                      </Mobile>

                      <IconButton
                        sx={{
                          borderRadius: '2rem',
                          width: '10rem',
                          height: '3.2rem',

                          backgroundColor: '#EEE',
                          '&:disabled': { backgroundColor: '#e8e6e6', opacity: 0.4 },
                          '&:hover': {
                            backgroundColor: 'rgb(193, 213, 255, 0.7)',
                            opacity: 0.8,
                          },
                          transition: 'all 0.3s',
                        }}
                        onClick={addOutputList}
                        disabled={
                          !lists[lists.length - 1].w ||
                          !lists[lists.length - 1].h ||
                          lists.length === MULTI_SIZE_LIMIT
                        }
                      >
                        <PlusIcon style={{ width: '1.28rem', height: '1.28rem' }} color="#303030" />{' '}
                      </IconButton>

                      <Mobile>
                        {isKo(i18n) && (
                          <IconButton
                            variant="outlined"
                            onClick={addOpenMarket(!isSimple)}
                            sx={{
                              ml: '0.7rem',
                              p: 0,
                              '&:hover': {
                                background: theme.palette.common.white,
                                '& path': { stroke: theme.palette.draph.blue },
                                '& circle': { stroke: theme.palette.draph.blue },
                              },
                            }}
                          >
                            <OpenMarketIcon />
                          </IconButton>
                        )}

                        {/* <ToggleButton
                          value="check"
                          selected={openMarket}
                          onChange={openMarketHandle}
                          sx={{
                            mt: '1.6rem',

                            width: '3.2rem',
                            height: '3.2rem',
                            borderRadius: '50%',
                            background: theme.palette.common.white,
                            '&:hover': {
                              background: theme.palette.common.white,
                              '& path': { stroke: theme.palette.draph.blue },
                              '& circle': { stroke: theme.palette.draph.blue },
                            },

                            '&.Mui-selected': {
                              background: theme.palette.draph.blue,
                              '& path': { stroke: theme.palette.common.white },
                              '& circle': { stroke: theme.palette.draph.blue },

                              '&:hover': {
                                background: theme.palette.common.white,
                                '& path': { stroke: theme.palette.draph.blue },
                                '& circle': { stroke: theme.palette.draph.blue },
                              },
                            },
                          }}
                        >
                          <OpenMarketIcon />
                        </ToggleButton> */}
                      </Mobile>
                    </CenterAlignBox>
                  )}
                </CenterAlignStack>
              )
            })}
            {(showSizeWarning || minSizeWarning || maxSizeWarning) && (
              <Box
                sx={{
                  // position: 'absolute',
                  bottom: 0,
                  // height: '4.5rem',
                  mt: '1rem',
                  width: { lg: '100%', xs: 'auto' },
                }}
              >
                {showSizeWarning && (
                  <Typography
                    sx={{
                      color: theme => theme.palette.common.red,
                      fontSize: { lg: '1.3rem', xs: '1.2rem' },
                      textAlign: 'center',
                      lineHeight: '1.4rem',
                      mt: { xs: '0.5rem' },
                    }}
                  >
                    {t('upload_config.image_size_warning_1_a')} {isMobile ? <br /> : ''}
                    {t('upload_config.image_size_warning_1_b')}
                  </Typography>
                )}
                {minSizeWarning && (
                  <Typography
                    sx={{
                      color: theme => theme.palette.common.red,
                      fontSize: { lg: '1.3rem', xs: '1.2rem' },
                      textAlign: 'center',
                      lineHeight: '1.4rem',
                      mt: { xs: '0.5rem' },
                    }}
                  >
                    {t('upload_config.image_size_warning_2')}
                  </Typography>
                )}
                {maxSizeWarning && (
                  <Typography
                    sx={{
                      color: theme => theme.palette.common.red,
                      fontSize: { lg: '1.3rem', xs: '1.2rem' },
                      textAlign: 'center',
                      lineHeight: '1.4rem',
                      mt: { xs: '0.5rem' },
                    }}
                  >
                    {t('upload_config.image_size_warning_3')}
                  </Typography>
                )}
              </Box>
            )}
          </Stack>
        </CenterAlignStack>
        {/* <Divider flexItem /> */}

        <CenterAlignStack
          sx={{
            width: { lg: '100%', xs: MOBILE_PORTFOLIO_WIDTH_LIMIT },
            pt: { lg: '3.4rem', xs: '3rem' },
          }}
        >
          <Stack
            justifyContent="center"
            sx={{
              width: '100%',
              px: '1rem',
              flexDirection: { lg: 'row', xs: 'column' },
              gap: { lg: '4rem', xs: '2.6rem' },
            }}
          >
            <Box sx={{ width: 'auto' }}>
              <CenterAlignStack sx={{ position: 'relative' }}>
                <CenterAlignBox sx={{ optionBoxStyle }}>
                  <Typography
                    fontSize={{ lg: '1.6rem', xs: '1.3rem' }}
                    fontWeight={700}
                    sx={{ mx: { lg: '1.0rem', xs: '0.6rem' } }}
                  >
                    {t('upload_config.multi_blob')}
                  </Typography>
                  <BlueSwitch
                    sx={isMobile ? optionSwitchMobileStyle : optionSwitchStyle}
                    checked={!config.flagMultiblobSod}
                    onChange={e => {
                      setConfig({
                        ...config,
                        bgComplex: config.flagMultiblobSod,
                        flagMultiblobSod: !config.flagMultiblobSod,
                      })
                    }}
                  />
                </CenterAlignBox>

                <Box sx={{ display: 'flex', gap: '1.2rem', mt: '1.4rem' }}>
                  {multiBlobImages.map((i, index) => {
                    return (
                      <OutlinedImage
                        key={index}
                        imageSrc={i.image}
                        source={i.source}
                        sourceStyleAdd={i.sourceStyle}
                        boxStyleAdd={i.style}
                        imageStyleAdd={i.imageStyle}
                        value={i.multiBlob}
                        config={config.flagMultiblobSod}
                        clickAdd={multiBlobClick(i.multiBlob)}
                      />
                    )
                  })}
                </Box>
              </CenterAlignStack>
            </Box>

            {isSimple && (
              <Box sx={{ width: 'auto' }}>
                <CenterAlignStack>
                  <CenterAlignBox sx={{ optionBoxStyle }}>
                    <Typography
                      fontSize={{ lg: '1.6rem', xs: '1.3rem' }}
                      fontWeight={700}
                      sx={{ mx: { lg: '1.0rem', xs: '0.6rem' } }}
                    >
                      {t('upload_config.shadow')}
                    </Typography>
                    <BlueSwitch
                      sx={isMobile ? optionSwitchMobileStyle : optionSwitchStyle}
                      checked={config.genShadow}
                      onChange={e => {
                        setConfig({
                          ...config,
                          genShadow: !config.genShadow,
                        })
                      }}
                    />
                  </CenterAlignBox>

                  <Box sx={{ display: 'flex', gap: '1.2rem', mt: '1.4rem' }}>
                    {shadowImages.map((i, index) => {
                      return (
                        <OutlinedImage
                          key={index}
                          imageSrc={i.image}
                          source={i.source}
                          sourceStyleAdd={i.sourceStyle}
                          boxStyleAdd={i.style}
                          imageStyleAdd={i.imageStyle}
                          value={i.shadow}
                          config={config.genShadow}
                          clickAdd={shadowClick(i.shadow)}
                        />
                      )
                    })}
                  </Box>
                </CenterAlignStack>
              </Box>
            )}

            <Box sx={{ width: 'auto', mt: { lg: 0, xs: '0' } }}>
              <CenterAlignStack>
                <CenterAlignBox sx={optionBoxStyle}>
                  <Typography
                    fontSize={{ lg: '1.6rem', xs: '1.3rem' }}
                    fontWeight={700}
                    sx={{ mx: { lg: '1.0rem', xs: '0.6rem' } }}
                  >
                    {t('upload_config.face_swap')}
                  </Typography>
                  <BlueSwitch
                    sx={isMobile ? optionSwitchMobileStyle : optionSwitchStyle}
                    checked={config.genFace}
                    onChange={e => {
                      setConfig({
                        ...config,
                        genFace: !config.genFace,
                      })
                    }}
                  />
                </CenterAlignBox>
                <Box sx={{ display: 'flex', gap: '1.2rem', mt: '1.4rem' }}>
                  {faceImages.map((i, index) => {
                    return (
                      <OutlinedImage
                        key={index}
                        imageSrc={i.image}
                        source={i.source}
                        sourceStyleAdd={i.sourceStyle}
                        boxStyleAdd={i.style}
                        imageStyleAdd={i.imageStyle}
                        value={i.face}
                        config={config.genFace}
                        clickAdd={faceClick(i.face)}
                      />
                    )
                  })}
                </Box>
              </CenterAlignStack>
            </Box>
          </Stack>

          {/* <CenterAlignStack sx={{ width: '100%', mt: '3.6rem' }}>
          <Typography
            fontSize={{ lg: '1.6rem', xs: '1.3rem' }}
            fontWeight={500}
            sx={{ mb: '1.2rem' }}
          >
            촬영 각도
          </Typography>

          <CenterAlignBox
            gap="1.2rem"
            sx={{ width: { lg: 'auto', xs: '32rem' }, flexFlow: { lg: 'none', xs: 'wrap' } }}
          >
            {angleImages.map((i, index) => {
              const isSelected = i.value === config.objectAngle
              return (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '15rem',
                    height: '10.8rem',
                    borderRadius: '1rem',
                    // border: isSelected ? `3px solid ${theme.palette.draph.blue}` : 'none',
                    boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
                  }}
                  onClick={angleClick(i.value)}
                >
                  {index === 0 ? (
                    <>
                      {cloneElement(i.image, {
                        color: isSelected ? theme.palette.draph.blue : theme.palette.common.black,
                      })}
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '15rem',
                          height: '10.8rem',
                          borderRadius: '1rem',
                          border: isSelected ? `3px solid ${theme.palette.draph.blue}` : 'none',
                        }}
                      ></Box>
                    </>
                  ) : (
                    <>
                      <img
                        src={i.image}
                        style={{
                          borderRadius: '1rem',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          width: '15rem',
                          height: '10.8rem',
                          borderRadius: '1rem',
                          border: isSelected ? `3px solid ${theme.palette.draph.blue}` : 'none',
                        }}
                      ></Box>
                    </>
                  )}
                </Box>
              )
            })}
          </CenterAlignBox>
        </CenterAlignStack> */}
        </CenterAlignStack>
      </CenterAlignStack>
    </>
  )
}

const ExampleImage = props => {
  const isMobile = useMobileMediaQuery()
  return (
    <Box {...props} sx={{ cursor: 'pointer', display: 'inline-block' }}>
      <img
        src={props.src}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          width: isMobile ? '15.2rem' : '19.6rem',
          height: isMobile ? '11.2rem' : '14rem',
          borderRadius: isMobile ? '1rem' : '0rem',
          filter:
            (props.idx === 2 && props.flagmultiblodsod) ||
            props.flagbgexpansion ||
            (props.idx > 0 && props.isoutputsizevalid)
              ? 'grayscale(100%)'
              : 'none',
        }}
      />
    </Box>
  )
}

export function SetPortfolioAdvanced({ portfolioConfig, setPortfolioConfig, sx, ...props }) {
  const theme = useTheme()
  const isMobile = useMobileMediaQuery()
  const { t } = useTranslation()

  return (
    <CenterAlignStack sx={{ m: { lg: '3.6rem 0 4.4rem 0', xs: '2rem 0 2.8rem 0' } }}>
      <PageTitle>{t('upload_config.advanced_setting')}</PageTitle>
      <PageComment>{t('upload_config.advanced_setting_comment')}</PageComment>
    </CenterAlignStack>
  )
}

const optionBoxStyle = {
  justifyContent: 'start',
  alignItems: 'center',
}
export const optionSwitchStyle = {
  height: '2.4rem',
  width: '4.4rem',
  '& .MuiSwitch-switchBase': {
    top: '0.3rem',
    left: '0.3rem',
    '&.Mui-checked': {
      transform: 'translateX(1.9rem)',
    },
  },
  '& .MuiSwitch-thumb': { width: '1.8rem', height: '1.8rem' },
}

const optionSwitchMobileStyle = {
  height: '2rem',
  width: '4rem',
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      transform: 'translateX(2rem)',
    },
  },
  '& .MuiSwitch-thumb': { width: '2rem', height: '2rem' },
}

function SubCategoryDetail({ config, setConfig, subCategorys, small = false }) {
  const tutorial = useRecoilValue(tutorialAtom)
  const { t } = useTranslation()

  return (
    <>
      <Desktop>
        <CenterAlignStack
          sx={{
            mt: small ? '3rem' : tutorial.step === '3_1' ? '2rem' : '5rem',
          }}
        >
          <Typography
            fontSize={{ lg: '1.6rem', xs: '1.2rem' }}
            fontWeight={700}
            sx={{ textAlign: 'center', width: { lg: '9.1rem', xs: '7.3rem' } }}
          >
            {t('upload_config.category_3')}
          </Typography>
          <CenterAlignBox
            sx={{
              mt: small ? '2rem' : '2.5rem',
            }}
            gap="1rem"
          >
            {subCategorys?.map((subCategory, idx) => {
              return (
                <SubcategoryImgCard
                  key={idx}
                  src={subCategory.example}
                  // name={subCategory.name}
                  name={t(`artwork_category.${subCategory.id}`)}
                  subCategory={subCategory}
                  config={config}
                  setConfig={setConfig}
                  idx={idx}
                  small={small}
                />
              )
            })}
          </CenterAlignBox>
        </CenterAlignStack>
      </Desktop>

      <Mobile>
        <CenterAlignStack
          sx={{ mt: small ? '2rem' : tutorial.step === '3_1' ? '2rem' : '4rem', width: '32rem' }}
        >
          <Typography
            fontSize={{ xs: '1.2rem' }}
            fontWeight={700}
            sx={{ textAlign: 'center', width: { lg: '9.1rem', xs: '7.3rem' } }}
          >
            소분류
          </Typography>
          <Grid
            container
            spacing={{ xs: '1', lg: 6 }}
            columns={{ xs: 12, lg: 12 }}
            justifyContent="center"
            sx={{ px: '2rem', pt: '1.2rem' }}
          >
            {subCategorys?.map((subCategory, idx) => {
              return (
                <SubcategoryImgCard
                  key={idx}
                  src={subCategory.example}
                  name={subCategory.name}
                  subCategory={subCategory}
                  idx={idx}
                  oneCategory={subCategorys.length === 1}
                  config={config}
                  setConfig={setConfig}
                  small={small}
                />
              )
            })}
          </Grid>

          {/* <CenterAlignBox sx={{ mt: small ? '2rem' : '2.5rem' }} gap={2}></CenterAlignBox> */}
        </CenterAlignStack>
      </Mobile>
    </>
  )
}

const SubcategoryImgCard = ({
  config,
  setConfig,
  src,
  name,
  idx,
  subCategory,
  small,
  oneCategory = false,
}) => {
  const theme = useTheme()
  const [tutorial, setTutorial] = useRecoilState(tutorialAtom)
  const isPerson = config.category === 'person'

  const onClickSub = subCategory => event => {
    tutorial.step === '3_1' && setTutorial(prev => ({ ...prev, step: '3_2' }))

    setConfig({
      ...config,
      subCategory,
    })
  }

  return (
    <>
      <Desktop>
        {src ? (
          <CenterAlignStack>
            <CenterAlignStack
              sx={{
                width: small ? '10rem' : '18rem',
                height: small ? '10rem' : '20rem',
                background: '#FFFFFF',
                justifyContent: 'center',

                borderRadius: '1rem',
                cursor: 'pointer',
                outline:
                  config.subCategory === subCategory.id
                    ? `3px solid ${theme.palette.draph.blue}`
                    : '1px solid #D9E1EC',
              }}
              onClick={onClickSub(subCategory.id)}
            >
              {typeof src === 'string' ? (
                <img
                  src={src}
                  style={{
                    objectFit: 'cover',
                    width: small ? '10rem' : '18rem',
                    height: small ? '10rem' : '20rem',
                    borderRadius: '1rem',
                  }}
                />
              ) : (
                cloneElement(src, {
                  color:
                    config.subCategory === subCategory.id
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                })
              )}
            </CenterAlignStack>
            <Typography
              sx={{
                mt: '1.2rem',
                fontWeight: config.subCategory === subCategory.id ? 700 : 400,
                fontSize: small ? '1.6rem' : '2rem',
                color:
                  config.subCategory === subCategory.id
                    ? theme.palette.draph.blue
                    : theme.palette.common.black,
              }}
            >
              {name}
            </Typography>
          </CenterAlignStack>
        ) : (
          <CenterAlignStack
            sx={{
              width: small ? '13rem' : '18rem',
              minWidth: small ? '13rem' : '18rem',
              height: small ? '15rem' : '11.2rem',
              background: '#FFFFFF',
              boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2)',
              borderRadius: '10px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              border:
                config.subCategory === subCategory.id
                  ? `3px solid ${theme.palette.draph.blue}`
                  : null,
            }}
            onClick={onClickSub(subCategory.id)}
          >
            <Typography
              color="primary"
              sx={{ fontSize: small ? '2.3rem' : '3rem', fontWeight: 700 }}
            >
              {name}
            </Typography>
          </CenterAlignStack>
        )}
      </Desktop>

      <Mobile>
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent={isPerson ? 'center' : idx % 2 === 0 ? 'right' : 'left'}
          xs={isPerson ? 4 : 6}
        >
          {src ? (
            <CenterAlignStack>
              <CenterAlignStack
                sx={{
                  width: small ? '7.2rem' : '15.2rem',
                  height: small ? '6.8rem' : '16.9rem',
                  background: '#FFFFFF',
                  boxShadow: '2px 2px 10px 0px rgba(0, 0, 0, 0.20)',
                  justifyContent: 'center',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  outline:
                    config.subCategory === subCategory.id
                      ? `3px solid ${theme.palette.draph.blue}`
                      : null,
                }}
                onClick={onClickSub(subCategory.id)}
              >
                {typeof src === 'string' ? (
                  <img
                    src={src}
                    style={{
                      objectFit: 'cover',
                      width: small ? '7.2rem' : '15.2rem',
                      height: small ? '6.8rem' : '16.9rem',
                      borderRadius: '10px',
                    }}
                  />
                ) : (
                  cloneElement(src, {
                    color:
                      config.subCategory === subCategory.id
                        ? theme.palette.draph.blue
                        : theme.palette.common.black,
                  })
                )}
              </CenterAlignStack>
              <Typography
                sx={{
                  mt: '0.8rem',
                  fontWeight: config.subCategory === subCategory.id ? 700 : 400,
                  fontSize: small
                    ? '1.2rem'
                    : config.subCategory === subCategory.id
                    ? '2.2rem'
                    : '2rem',
                  color:
                    config.subCategory === subCategory.id
                      ? theme.palette.draph.blue
                      : theme.palette.common.black,
                }}
              >
                {name}
              </Typography>
            </CenterAlignStack>
          ) : (
            <CenterAlignStack>
              <CenterAlignStack
                sx={{
                  width: small ? '13rem' : '15.2rem',
                  height: small ? '15rem' : '16.9rem',
                  background: '#FFFFFF',
                  boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2)',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  outline:
                    config.subCategory === subCategory.id
                      ? `5px solid ${theme.palette.draph.blue}`
                      : null,
                }}
                onClick={onClickSub(subCategory.id)}
              >
                <Typography
                  sx={{
                    fontSize: small ? '2.3rem' : '2.5rem',
                    fontWeight: 700,
                  }}
                >
                  {name}
                </Typography>
              </CenterAlignStack>
            </CenterAlignStack>
          )}
        </Grid>
      </Mobile>
    </>
  )
}
export function SetNewPortfolioConcept({
  files,
  portfolioConfig,
  setPortfolioConfig,
  isSimple,
  setIsSimple,
  selected,
  setSelected,
  sx,
  ...props
}) {
  const isMobile = useMobileMediaQuery()
  const { t } = useTranslation()
  const [removeBgImageFile, setRemoveBgImageFile] = useRecoilState(autoUploadAtom)

  const isFile = Boolean(files?.length)

  const simpleOrConcept = [
    {
      imageSrc: '/static/images/api_info/feature_1_before.png',
      value: true,
      source: t('upload_config.bg_simple'),
    },
    {
      imageSrc: '/static/images/api_info/feature_1_after.png',
      value: false,
      source: t('upload_config.bg_concept'),
    },
  ]

  const sampleText = [
    { adornment: 'Popular', content: t('upload_header.sample_1') },
    { content: t('upload_header.sample_2') },
    { content: t('upload_header.sample_3') },
    { adornment: 'Best', content: t('upload_header.sample_4') },
  ]
  const THEME_ITEM_PER_PAGE = 15 // 고정아이템 포함한 수
  const THEME_ITEM_PER_ROW_MOBILE = 4 // 모바일

  const scrollRef = useRef(null)

  const ex0 = t('upload_config.bg_theme_ex_1')
  const [isFocused, setIsFocused] = useState(false)

  const [showCustomTheme, setShowCustomTheme] = useState(false)

  const [themeList, setThemeList] = useState([]) // 고정아이템을 제외한 테마들만
  const [themeListWithFix, setThemeListWithFix] = useState([]) // 고정아이템 포함
  const [themePageItem, setThemePageItem] = useState([])
  const [themePageCount, setThemePageCount] = useState(1)
  const [themePage, setThemePage] = useState(1)
  const [showLeftShadow, setShowLeftShadow] = useState(false)
  const [showRightShadow, setShowRightShadow] = useState(true)

  const [hasError, setHasError] = useState(false)

  const onFocus = idx => () => {
    if (idx === 0) setIsFocused(true)
  }
  const onBlur = idx => () => {
    if (idx === 0) setIsFocused(false)
  }

  const handleScroll = () => {
    const l = scrollRef.current.scrollLeft
    const sw = scrollRef.current.scrollWidth
    const cw = scrollRef.current.clientWidth

    if (l > 10) {
      setShowLeftShadow(true)
    } else {
      setShowLeftShadow(false)
    }

    if (cw + l < sw - 10) {
      setShowRightShadow(true)
    } else {
      setShowRightShadow(false)
    }
  }

  // 참고 배경 넣은 뒤 커스텀 아무것도 없는게 아닌 기본 옵션으로 보여주기
  useEffect(() => {
    if (portfolioConfig.theme_template === 'custom' && portfolioConfig.theme_custom === '') {
      setPortfolioConfig(p => ({ ...p, theme_template: 'auto' }))
    }
  }, [])

  useEffect(() => {
    const div = scrollRef.current
    if (div) {
      div.addEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (!selected) return
    if (selected?.id === 'custom') {
      setShowCustomTheme(true)
      setPortfolioConfig({ ...portfolioConfig, theme_template: selected.id })
    } else if (selected?.id) {
      setShowCustomTheme(false)
      setPortfolioConfig({ ...portfolioConfig, theme_custom: '', theme_template: selected.id })
    }
  }, [selected])

  useEffect(() => {
    const t = configConstant.BG_THEME_TEMPLATES.filter(t => t.id === portfolioConfig.theme_template)

    if (t.length > 0) {
      setSelected(t[0])
    } else {
      setSelected({ id: 'auto' })
    }
  }, [portfolioConfig.theme_template])

  useEffect(() => {
    if (isSimple === 'nothing') return

    if (isSimple === true) {
      // setShowCustomTheme(false)
      setPortfolioConfig({
        ...portfolioConfig,
        bgSimple: true,
        bgComplex: false,
        bgGenerate: false,
        theme_template: 'auto',
      })

      setHasError(false)
    } else {
      const updatedConfig = {
        ...portfolioConfig,
        bgSimple: false,
        bgComplex: !portfolioConfig.flagMultiblobSod,
        bgGenerate: true,
      }

      if (portfolioConfig.outputSizeList.length > 1) {
        let configOutputlist = [...portfolioConfig.outputSizeList]

        configOutputlist = configOutputlist.filter(o => o.w !== null && o.h !== null).slice(0, 1)

        setPortfolioConfig({
          ...updatedConfig,
          outputSizeList: configOutputlist,
        })
        setHasError(true)
      } else {
        setPortfolioConfig(updatedConfig)
      }
    }
  }, [isSimple])

  useEffect(() => {
    const isSimpleConfig = !portfolioConfig.bgComplex && !portfolioConfig.bgGenerate
    setIsSimple(isSimpleConfig)
  }, [portfolioConfig.bgComplex, portfolioConfig.bgGenerate, portfolioConfig.bgSimple])

  useEffect(() => {
    let arr = [...configConstant.BG_THEME_TEMPLATES]
    arr = arr.filter(x => !x.fixInPage)

    const order = configConstant.BG_THEME_ORDER.default

    arr.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))

    setThemeList(arr)
  }, [portfolioConfig.category])

  useEffect(() => {
    const fixItems = configConstant.BG_THEME_TEMPLATES.filter(x => x.fixInPage)

    setThemeListWithFix([...fixItems.filter(x => x.fixInPage === 'first'), ...themeList])
  }, [themeList])

  useEffect(() => {
    const fixItems = configConstant.BG_THEME_TEMPLATES.filter(x => x.fixInPage)

    const c = THEME_ITEM_PER_PAGE - fixItems.length

    setThemePageCount(Math.ceil(themeList.length / c))
    const a = (themePage - 1) * c
    const b = themePage * c

    setThemePageItem([
      ...fixItems.filter(x => x.fixInPage === 'first'),
      ...themeList.slice(a, b),
      ...fixItems.filter(x => x.fixInPage === 'last'),
    ])
  }, [themeList, themePage])

  const theme = useTheme()

  // useEffect(() => {
  //   if (!isFile && selected) {

  //     if (customTemplate) {
  //       setSelected({ id: 'custom' })
  //     } else if (customTemplate === '') {
  //       setSelected({ id: 'auto' })
  //     }
  //   }
  // }, [customTemplate, isFile])

  // useEffect(() => {
  //   if (portfolioConfig.theme_custom) {
  //     setCustomTemplate(portfolioConfig.theme_custom)
  //   }
  // }, [])

  useEffect(() => {
    if (isFile) {
      setSelected({ id: 'custom' })
      setPortfolioConfig({
        ...portfolioConfig,
        theme_custom: '',
        ...(isFile && { bgComplex: true, bgGenerate: true, bgSimple: false }),
      })
    }
  }, [files])

  return (
    <Stack sx={{ m: { lg: '0rem 0 0 2.8rem', xs: '2.2rem 0 2.4rem 0' }, width: 'inherit' }}>
      <Box sx={{ gap: '1.2rem', mb: hasError ? '1.2rem' : '2.4rem', display: 'flex' }}>
        {simpleOrConcept.map(p => {
          return (
            <OutlinedImage
              isDisabled={isFile}
              key={p.source}
              imageSrc={p.imageSrc}
              source={p.source}
              value={p.value}
              config={isSimple}
              boxStyleAdd={{ height: '12rem', ...(isFile && { border: '3px solid #EEEEEE' }) }}
              sourceStyleAdd={{
                height: '2.4rem',
                fontSize: '1.4rem',
                ...(isFile && { backgroundColor: '#EEEEEE' }),
              }}
              imageStyleAdd={{
                height: '9.6rem',
                objectPosition: 'top',
                ...(isFile && { filter: 'grayscale(1)' }),
              }}
              setConfig={setIsSimple}
            />
          )
        })}
      </Box>

      {hasError && (
        <OpacityMotionDiv>
          <Typography
            sx={{
              fontSize: { lg: '1.4rem', xs: '1.3rem' },
              color: theme.palette.error.main,
              mb: { lg: '2.8rem', xs: '2.4rem' },
              textAlign: 'center',
            }}
          >
            {t('upload_config.concept_warning_1')} <br /> {t('upload_config.concept_warning_2')}
          </Typography>
        </OpacityMotionDiv>
      )}
      <Desktop>
        {!isSimple && !isFile && (
          <Stack>
            <OpacityMotionDiv
              style={{
                backgroundColor: '#F8F8F8',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '10px',
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  '& #thumb-list': { mx: '4rem' },
                  '& .svgBox': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    width: '6.4rem',
                    height: '6.4rem',
                    backgroundColor: '#000000',
                    transition: 'opacity 0.3s',
                    opacity: 0.3,

                    '&.prev': {
                      cursor: themePage <= 1 ? 'not-allowed' : 'pointer',
                      '&:hover': { opacity: themePage <= 1 ? 0.3 : 0.6 },
                    },
                    '&.next': {
                      cursor: themePage >= themePageCount ? 'not-allowed' : 'pointer',
                      '&:hover': { opacity: themePage >= themePageCount ? 0.3 : 0.6 },
                    },
                  },

                  '& #prev-icon': { '& path': { stroke: themePage <= 1 ? '#BBBBBB' : '#FFFFFF' } },
                  '& #next-icon': {
                    '& path': { stroke: themePage >= themePageCount ? '#BBBBBB' : '#FFFFFF' },
                  },
                }}
              >
                {/* <Box
                className="svgBox prev"
                onClick={() => {
                  if (themePage > 1) {
                    setThemePage(p => p - 1)
                  }
                }}
              >
                <SvgIcon
                  id="prev-icon"
                  sx={{ width: '1.7rem', height: '3rem', fill: 'none' }}
                  viewBox="0 0 17 30"
                >
                  <path
                    d="M14 3L2 15L14 27"
                    stroke="#303030"
                    strokeWidth="3"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                </SvgIcon>
              </Box> */}

                <Grid
                  id="thumb-list"
                  container
                  columns={5}
                  sx={{
                    width: { lg: '100%', xs: '33rem' },
                    gap: '1.2rem',
                    my: '2.8rem',
                    justifyContent: 'flex-start',
                    // border: '1px solid red',
                  }}
                >
                  {themePageItem.map((item, idx) => (
                    <Grid
                      item
                      key={`${item.id}_${idx}`}
                      // lg={item.id === 'auto' ? 13 : 3}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected(item)
                      }}
                    >
                      <ThemeTemplateThumb
                        theme={item}
                        selected={selected?.id === item.id}
                        category={portfolioConfig.category}
                        sx={{ width: '10.8rem', height: '10.8rem' }}
                      />
                    </Grid>
                  ))}

                  {/* dummy */}
                  {Array(THEME_ITEM_PER_PAGE - themePageItem.length)
                    .fill(0)
                    .map((a, i) => (
                      <Grid item key={i}>
                        <ThemeTemplateThumb
                          theme={{ id: null }}
                          sx={{ background: 'transparent', border: 'none' }}
                        />
                      </Grid>
                    ))}
                </Grid>

                {/* <Box className="svgBox next">
                <SvgIcon
                  id="next-icon"
                  sx={{ width: '1.7rem', height: '3rem', fill: 'none' }}
                  viewBox="0 0 17 30"
                  onClick={() => {
                    if (themePage < themePageCount) {
                      setThemePage(p => p + 1)
                    }
                  }}
                >
                  <path
                    d="M3 27L15 15L3 3"
                    stroke="#303030"
                    strokeWidth="3"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                  />
                </SvgIcon>
              </Box> */}
              </Stack>
            </OpacityMotionDiv>

            {showCustomTheme && (
              <OpacityMotionDiv
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.2rem',
                  marginTop: '2rem',
                }}
              >
                <CustomOutlinedInput
                  value={portfolioConfig.theme_custom}
                  onChange={e => {
                    setPortfolioConfig({ ...portfolioConfig, theme_custom: e.target.value })
                  }}
                  // value={customTemplate}
                  // onChange={e => {
                  //   setCustomTemplate(e.target.value)
                  // }}
                  onFocus={onFocus(0)}
                  onBlur={onBlur(0)}
                  type="text"
                  inputProps={{ maxLength: 1000 }}
                  multiline
                  minRows={4}
                  startAdornment={
                    <div style={{ position: 'absolute', top: '2.2rem', left: '1.2rem' }}>
                      {!isFocused && !portfolioConfig.theme_custom.length && (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              backgroundColor: theme.palette.common.black,
                              color: theme.palette.common.white,
                              p: '0.3rem 0.6rem',
                              fontSize: '1.2rem',
                              fontWeight: 600,
                              borderRadius: '6px',
                              lineHeight: 'normal',
                            }}
                          >
                            {t('upload_header.custom_template')}
                          </Box>
                        </InputAdornment>
                      )}
                    </div>
                  }
                  sx={{
                    height: 'auto',
                    width: '100%',
                    '&.MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderWidth: '0.1rem',
                        borderColor: '#000000',
                        borderRadius: '10px',
                      },
                    },
                  }}
                />
                <Box sx={{ display: 'flex', gap: '1.1rem' }}>
                  {sampleText.map((s, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          width: '25%',
                          p: '1.2rem 1.2rem',
                          border: '1px solid #D9E1EC',
                          borderRadius: '10px',
                          fontSize: '1rem',
                          '&:hover': {
                            border: `1px solid ${theme.palette.common.black}`,
                            transition: 'all 0.5s',
                            '& .MuiBox-root': {
                              backgroundColor: theme.palette.common.black,
                              transition: 'background-color 0.5s', // 여기에 transition 추가
                            },
                          },
                          transition: 'all 0.5s',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setPortfolioConfig({ ...portfolioConfig, theme_custom: s.content })
                        }}
                      >
                        <Stack sx={{ gap: '0.4rem' }}>
                          {s.adornment && (
                            <Box
                              sx={{
                                backgroundColor: '#989898',
                                color: theme.palette.common.white,
                                p: '0.3rem 0.6rem',
                                fontSize: '1.2rem',
                                fontWeight: 600,
                                borderRadius: '6px',
                                lineHeight: 'normal',
                                minWidth: '5.6rem',
                                width: 'min-content',
                                textAlign: 'center',
                                transition: 'background-color 0.5s', // 여기에 transition 추가
                              }}
                            >
                              {s.adornment}
                            </Box>
                          )}
                          {s.content}
                        </Stack>
                      </Box>
                    )
                  })}
                </Box>
              </OpacityMotionDiv>
            )}
          </Stack>
        )}
      </Desktop>

      <Mobile>
        {!isSimple && (
          <Box
            sx={{
              width: '36rem',
              position: 'relative',
              mb: '1rem',
              display: 'flex',
              justifyContent: 'center',
              ml: '3rem',
            }}
          >
            {/* <Box
            sx={{
              width: '100%',
              // height: `${Math.floor(themeListWithFix.length / THEME_ITEM_PER_ROW_MOBILE) * 10}rem`,
              height: '100%',
              position: 'absolute',
              display: 'flex',
            }}
          >
            <div
              style={{
                top: 0,
                position: 'absolute',
                height: '100%',

                zIndex: 3,
                transform: 'rotate(180deg)',
                background:
                  'linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
                opacity: showLeftShadow ? 1 : 0,
                width: showLeftShadow ? '5.6rem' : '0rem',
                transition: 'all 0.1s',
              }}
            />

            <div
              style={{
                top: 0,
                right: 0,
                position: 'absolute',
                height: '100%',
                zIndex: 3,
                background:
                  'linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) 100%)',
                opacity: showRightShadow ? 1 : 0,
                width: showRightShadow ? '5.6rem' : '0rem',
                transition: 'all 0.1s',
              }}
            />
          </Box> */}
            <div
              style={{
                width: '36rem',
                overflow: 'auto',
              }}
              ref={scrollRef}
            >
              <Grid
                id="thumb-list"
                container
                columns={4}
                gap="1rem"
                sx={{
                  width: '43rem',
                  overflow: 'scroll',
                  py: '1rem',
                  justifyContent: 'center',
                }}
              >
                {themeListWithFix.map((item, idx) => (
                  <Grid
                    item
                    key={`${item.id}_${idx}`}
                    // xs={3}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelected(item)
                    }}
                  >
                    <ThemeTemplateThumb
                      theme={item}
                      selected={selected?.id === item.id}
                      category={portfolioConfig.category}
                    />
                  </Grid>
                ))}
                {/* dummy */}
                {Array(
                  THEME_ITEM_PER_ROW_MOBILE - (themeListWithFix.length % THEME_ITEM_PER_ROW_MOBILE)
                )
                  .fill(0)
                  .map((a, i) => (
                    <Grid item key={i}>
                      <ThemeTemplateThumb theme={{ id: null }} />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Box>
        )}
      </Mobile>
    </Stack>
  )
}
