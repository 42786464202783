import { Box, Button, Card, Collapse, Slider, Stack, Typography, useTheme } from '@mui/material'
import { blockArrowKeydownAtom, defaultConfigAtom } from 'atoms'
import { BlueSwitch, CustomOutlinedInput } from 'components/CustomStyle'
import { useState } from 'react'
import { MdArrowDownward } from 'react-icons/md'
import { useRecoilState } from 'recoil'

export default function BannerV2default() {
  const theme = useTheme()
  const [defaultConfig, setDefaultConfig] = useRecoilState(defaultConfigAtom)
  const [defaultConfigOpen, setDefaultConfigOpen] = useState(false)
  const [blockArrowKeydown, setBlockArrowkeydown] = useRecoilState(blockArrowKeydownAtom)

  const handleFocus = () => {
    setBlockArrowkeydown(true)
  }

  const handleBlur = () => {
    setBlockArrowkeydown(false)
  }

  const updateConfig = (name, value) => {
    setDefaultConfig(p => {
      return { ...p, [name]: value }
    })
  }

  // useEffect(() => {
  //   console.log('defaultConfig:', defaultConfig)
  // })

  return (
    <Stack sx={{ mt: '1rem' }}>
      <Card
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '2rem',
          '& .select-box': { display: 'flex', alignItems: 'center', gap: '2rem' },
          '& .select-title': { minWidth: '7rem' },
          '& .select-option': { flex: 1 },
        }}
      >
        <Button
          endIcon={<MdArrowDownward />}
          onClick={() => {
            setDefaultConfigOpen(p => !p)
          }}
          sx={{
            '& .MuiButton-icon': {
              transition: 'transform 0.3s ease-out',
              transform: defaultConfigOpen ? 'rotate(0deg)' : 'rotate(180deg)',
            },
          }}
        >
          기본 설정
        </Button>
        <Collapse
          in={defaultConfigOpen}
          sx={{
            // p: '1rem',
            // border: '1px solid #D9D9D9',
            '& .select-title': { minWidth: '7rem', fontSize: '1.4rem' },
          }}
        >
          <Stack sx={{ width: 'inherit', p: '1rem', gap: '0.4rem' }}>
            <Stack>
              <Box className="select-box">
                <Typography className="select-title">상품 이름</Typography>
                <CustomOutlinedInput
                  className="select-option"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={defaultConfig.product_name}
                  onChange={e => {
                    updateConfig('product_name', e.target.value)
                  }}
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    width: 'auto',
                    height: '2.8rem',
                    '& input': { textAlign: 'start', fontSize: '1.2rem' },

                    '&.MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderWidth: '0.1rem',
                        borderColor: defaultConfig.product_name
                          ? theme.palette.common.black
                          : '#D9E1EC',
                      },
                    },
                  }}
                />
              </Box>
            </Stack>

            <Stack sx={{ width: 'inherit' }}>
              <Box className="select-box">
                <Typography className="select-title">상품 특징</Typography>
                <CustomOutlinedInput
                  className="select-option"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={defaultConfig.product_feature}
                  onChange={e => {
                    updateConfig('product_feature', e.target.value)
                  }}
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    width: 'auto',
                    height: '2.8rem',
                    '& input': { textAlign: 'start', fontSize: '1.2rem' },

                    '&.MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderWidth: '0.1rem',
                        borderColor: defaultConfig.product_feature
                          ? theme.palette.common.black
                          : '#D9E1EC',
                      },
                    },
                  }}
                />
              </Box>
            </Stack>

            <Stack sx={{ width: 'inherit' }}>
              <Box className="select-box">
                <Typography className="select-title">브랜드 이름</Typography>
                <CustomOutlinedInput
                  className="select-option"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={defaultConfig.brand_name}
                  onChange={e => {
                    updateConfig('brand_name', e.target.value)
                  }}
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    width: 'auto',
                    height: '2.8rem',
                    '& input': { textAlign: 'start', fontSize: '1.2rem' },

                    '&.MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderWidth: '0.1rem',
                        borderColor: defaultConfig.brand_name
                          ? theme.palette.common.black
                          : '#D9E1EC',
                      },
                    },
                  }}
                />
              </Box>
            </Stack>

            <Stack sx={{ width: 'inherit' }}>
              <Box className="select-box">
                <Typography className="select-title">브랜드 특징</Typography>
                <CustomOutlinedInput
                  className="select-option"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={defaultConfig.brand_feature}
                  onChange={e => {
                    updateConfig('brand_feature', e.target.value)
                  }}
                  sx={{
                    backgroundColor: theme.palette.common.white,
                    width: 'auto',
                    height: '2.8rem',
                    '& input': { textAlign: 'start', fontSize: '1.2rem' },

                    '&.MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderWidth: '0.1rem',
                        borderColor: defaultConfig.brand_feature
                          ? theme.palette.common.black
                          : '#D9E1EC',
                      },
                    },
                  }}
                />
              </Box>
            </Stack>

            {/* <Stack sx={{ width: 'inherit' }}>
              <Box className="select-box">
                <Typography className="select-title">그레인 필터</Typography>
                <BlueSwitch
                  sx={{
                    height: '2.4rem',
                    width: '17.6rem',
                    '& .MuiSwitch-switchBase': {
                      top: '0.3rem',
                      left: '0.3rem',
                      '&.Mui-checked': {
                        transform: 'translateX(11.9rem)',
                      },
                    },
                    '& .MuiSwitch-thumb': {
                      width: '5rem',
                      height: '1.8rem',
                      borderRadius: '10px',
                    },
                  }}
                  checked={defaultConfig.filter_grain_flag}
                  onChange={(e, c) => {
                    updateConfig('filter_grain_flag', c)
                  }}
                />
              </Box>
            </Stack>

            <Collapse in={defaultConfig.filter_grain_flag}>
              <Slider
                valueLabelDisplay="on"
                value={defaultConfig.filter_grain_intensity}
                step={0.1}
                // slots={{
                //   valueLabel: ValueLabelComponent,
                // }}
                // marks={defaultConfig.filter_grain_intensity}
                min={0}
                max={1}
                onChange={e => updateConfig('filter_grain_intensity', e.target.value)}
              />
            </Collapse>

            <Stack sx={{ width: 'inherit' }}>
              <Box className="select-box">
                <Typography className="select-title">선명함 필터</Typography>
                <BlueSwitch
                  sx={{
                    height: '2.4rem',
                    width: '17.6rem',
                    '& .MuiSwitch-switchBase': {
                      top: '0.3rem',
                      left: '0.3rem',
                      '&.Mui-checked': {
                        transform: 'translateX(11.9rem)',
                      },
                    },
                    '& .MuiSwitch-thumb': {
                      width: '5rem',
                      height: '1.8rem',
                      borderRadius: '10px',
                    },
                  }}
                  checked={defaultConfig.filter_sharpen_flag}
                  onChange={(e, c) => {
                    updateConfig('filter_sharpen_flag', c)
                  }}
                />
              </Box>
            </Stack>

            <Stack sx={{ width: 'inherit' }}>
              <Box className="select-box">
                <Typography className="select-title">WB switch</Typography>
                <BlueSwitch
                  sx={{
                    height: '2.4rem',
                    width: '17.6rem',
                    '& .MuiSwitch-switchBase': {
                      top: '0.3rem',
                      left: '0.3rem',
                      '&.Mui-checked': {
                        transform: 'translateX(11.9rem)',
                      },
                    },
                    '& .MuiSwitch-thumb': {
                      width: '5rem',
                      height: '1.8rem',
                      borderRadius: '10px',
                    },
                  }}
                  checked={defaultConfig.filter_whitebalance_flag}
                  onChange={(e, c) => {
                    updateConfig('filter_whitebalance_flag', c)
                  }}
                />
              </Box>
            </Stack>

            <Collapse in={defaultConfig.filter_whitebalance_flag}>
              <Slider
                valueLabelDisplay="on"
                value={defaultConfig.filter_whitebalance_temperature}
                step={1}
                // slots={{
                //   valueLabel: ValueLabelComponent,
                // }}
                // marks={defaultConfig.filter_whitebalance_temperature}
                min={0}
                max={10}
                onChange={e => updateConfig('filter_whitebalance_temperature', e.target.value)}
              />
            </Collapse> */}
          </Stack>
        </Collapse>
      </Card>
    </Stack>
  )
}
