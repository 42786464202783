import { useEffect } from 'react'
// material
import { alpha, styled } from '@mui/material/styles'
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Typography } from '@mui/material'

import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'

import { MdMenuOpen } from 'react-icons/md'

import { LanguageSelect, ServiceTitle } from 'components'

import { appMenuAtom, userAtom } from 'atoms'
import { useRecoilValue, useResetRecoilState } from 'recoil'

import { removeUserCookie, getAccessToken } from 'utils/cookie'
import { Desktop, Mobile } from 'hooks/useMediaQuery'
import { useMenu } from 'hooks/useMenu'
// ----------------------------------------------------------------------

export const APPBAR_MOBILE = '5.2rem'
export const APPBAR_DESKTOP = '8rem'

const RootStyle = styled(AppBar)(({ theme }) => ({
  color: 'lime',
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.common.black,

  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    width: '100%',
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  // borderBottom: '1px dashed lightgray',

  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    paddingLeft: '4.8rem',
    paddingRight: '4.8rem',
  },
}))

export default function BasicAppBar() {
  return (
    <RootStyle>
      <ToolbarStyle>
        <Content />
      </ToolbarStyle>
    </RootStyle>
  )
}

function Content() {
  const { menu } = useMenu()
  const resetUser = useResetRecoilState(userAtom)

  const logoutUser = () => {
    removeUserCookie()
    resetUser()
    window.location.reload()
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexBasis: '80%' }}>
        <Desktop>
          <RouterLink to="/home" style={{ textDecoration: 'none' }}>
            <Stack direction="row" alignItems="center">
              <img src="/static/images/logo/logo_image.png" style={{ width: '5rem' }} />
              <ServiceTitle sx={{ ml: '1.2rem' }} />
            </Stack>
          </RouterLink>
        </Desktop>
        <Mobile>
          <RouterLink to="/home" style={{ textDecoration: 'none' }}>
            <Stack direction="row" alignItems="center">
              <img src="/static/images/logo/logo_image.png" style={{ height: '2.8rem' }} />
            </Stack>
          </RouterLink>
        </Mobile>
      </Box>
    </>
  )
}
