import crudAxios from './configs/crud'

export const adminAPI = {
  getPromotion: filter => {
    let l = '/admin/promotion'
    if (filter) l += '?filter=' + filter
    return crudAxios.get(l)
  },
  getCoupon: () => {
    return crudAxios.get('/admin/coupon')
  },
  getUserById: id => {
    return crudAxios.get('/admin/user?id=' + id)
  },
  createPromotion: body => {
    return crudAxios.post('/admin/promotion', body)
  },
  createCoupon: body => {
    return crudAxios.post('/admin/coupon', body)
  },
  addCreditToUser: body => {
    return crudAxios.post('/admin/credit', body)
  },
  getUserCredtiHistory: userId => {
    return crudAxios.get('/admin/credit?user_id=' + userId)
  },
  cleanExpiredPromotion: () => {
    return crudAxios.post('/admin/expire_promotion')
  },
  getSubscriptions: validFilter => {
    let l = '/admin/subscription'
    if (validFilter) l += '?valid=' + validFilter
    return crudAxios.get(l)
  },
  addFreeSubscription: body => {
    return crudAxios.post('/admin/subscription', body)
  },
  addUser: body => {
    return crudAxios.post('/admin/user', body)
  },
  updateUser: body => {
    return crudAxios.put('/admin/user', body)
  },
  getUserGrade: () => {
    return crudAxios.get('/admin/user_grade')
  },
  useUserCredit: body => {
    return crudAxios.put('/admin/user/use_credit', body)
  },
}
