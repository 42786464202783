import { apis } from 'apis'
import axios from 'axios'

const TestPage5 = () => {
  const emailArray = ['fleve91@naver.com', 'fleve91@gmail.com', 'jinho@draph.ai']
  const emailArray1 = [
    'fleve91@naver.com',
    'fleve91@gmail.com',
    'jinho@draph.ai',
    'haeun@draph.ai',
    'happy-ji-hye@daum.net',
    '911002z@naver.com',
  ]

  const onSubmit = e => {
    e.preventDefault()
    emailArray1.forEach(email => {
      axios
        .post('http://localhost:4000/sendMail', {
          email: email,
          planId: '월정액000',
          payTotal: '12,456원',
          chargedCredit: '100',
          nextPaidDay: '2023년 27월 20일',
          paidDay: '2023년 27월 20일',
          use: 'completedCreditPayment',
        })
        .then(res => {
          console.log(res.data)
        })
    })
  }

  const onSubmit1 = e => {
    e.preventDefault()
    apis.user.leaveDraph()
  }
  return (
    <>
      <form onSubmit={onSubmit}>
        <input type="submit" value="메일 보내기" />
      </form>

      <form onSubmit={onSubmit1}>
        <input type="submit" value="탈퇴" />
      </form>
    </>
  )
}

export default TestPage5
