import {
  Button,
  Link,
  IconButton,
  InputAdornment,
  FormGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
  Stack,
  CircularProgress,
  TextField,
  Typography,
  Box,
  Grid,
  Divider,
  MenuItem,
  Dialog,
  styled,
  useTheme,
} from '@mui/material'
import { Desktop, Mobile } from 'hooks/useMediaQuery'

import { useController } from 'react-hook-form'
import { CheckedSquareIcon3, SquareIcon2 } from 'theme/icon'
import { FormTextField, FormSelect } from './FormInput'

export const RegisterButtonBox = styled(Box)(({ theme }) => ({
  width: '14rem',
  marginRight: '1.4rem',
  minWidth: '14rem',
  display: 'flex',
  justifyContent: 'flex-end',
}))

export const RegisterInputButton = styled(Button)(({ theme }) => ({
  width: '12rem',
  minWidth: '12rem',
  marginLeft: '1.2rem',
  lineHeight: 1.1,

  boxShadow: 'none',

  [theme.breakpoints.down('lg')]: {
    width: '10rem',
  },

  height: '4rem',
  fontWeight: 500,
  fontSize: '1.6rem',
}))

export const FormErrorMessage = ({ message, style, width = '100%', ...props }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        mb: { lg: message ? '0.7rem' : '0rem', xs: message ? '0.7rem' : '0rem' },
        // height: { lg: message ? '2rem' : '1.6rem', xs: message ? '2.3rem' : '1.6rem' },
        height: { lg: '1.6rem', xs: '1.6rem' },
        width: '100%',
        marginLeft: { lg: message ? REGISTER_LABEL_WIDTH_DESKTOP : '0' },
        transition: 'margin-bottom 0.3s ',
        // transition: 'height 0.3s',
        pl: '0.1rem',
        ...style,
      }}
    >
      <span
        className="msg-span"
        style={{
          color: '#FF2323',
          fontWeight: 400,
          fontSize: '1.2rem',
          width: '100%',
          minHeight: '1.6rem',
          textAlign: 'start',
        }}
      >
        {message}
      </span>
    </Box>
  )
}

export const REGISTER_LABEL_WIDTH_DESKTOP = '15rem'

export const RegisterLabel = ({ required, label, timer, ...style }) => {
  const theme = useTheme()

  return (
    <>
      <Desktop>
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            width: REGISTER_LABEL_WIDTH_DESKTOP,
            fontWeight: 500,
            fontSize: '1.6rem',
            // lineHeight: '2.4rem',
            ...style,
          }}
        >
          {label}&nbsp;
          {required && (
            <sup
              style={{
                color: '#FF2323',
                fontWeight: 500,
                fontSize: '1.6rem',
                // lineHeight: '2.4rem',
                verticalAlign: 'text-top',
              }}
            >
              &#42;
            </sup>
          )}
        </span>
      </Desktop>

      <Mobile>
        <span
          style={{
            fontWeight: 500,
            fontSize: '1.4rem',
            marginBottom: '0.3rem',
            ...style,
          }}
        >
          {label}&nbsp;
          {required && (
            <sup
              style={{
                color: '#FF2323',
                fontWeight: 500,
                fontSize: '1.4rem',
                // lineHeight: '2.1rem',
                verticalAlign: 'text-top',
              }}
            >
              &#42;&nbsp;
            </sup>
          )}
        </span>
        {timer}
      </Mobile>
    </>
  )
}
export const SelectInput = styled(FormSelect)(({ theme }) => ({
  width: '100%',
  '& svg': {
    top: 'calc(50% - 0.4rem)',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },

  [theme.breakpoints.up('lg')]: {},

  [theme.breakpoints.down('lg')]: {},

  '& .MuiInputBase-root': {
    padding: 0,
    height: '4rem',
  },

  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.draph.blue,
      borderWidth: '0.2rem',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D9E1EC',
      borderWidth: '0.1rem',
    },

    '&.Mui-focused fieldset': {},

    '& .MuiOutlinedInput-input': {
      padding: '0 0 0 1.5rem',
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
  },

  '& .MuiInputAdornment-root .MuiButtonBase-root': {
    marginRight: '0.5rem',
  },
}))

export const PhoneInput = styled(FormTextField)(({ theme }) => ({
  width: '8.3rem',
  height: '4rem',
  [theme.breakpoints.down('lg')]: {
    width: '8.0rem',
    minWidth: '8.0rem',
  },
  '& .MuiInputBase-root': {
    padding: 0,
    height: '4.6rem',
    fontWeight: 400,
    fontSize: '1.8rem',
    lineHeight: '2.1rem',
  },

  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.draph.blue,
      borderWidth: '0.2rem',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D9E1EC',
      borderWidth: '0.1rem',
    },

    '&.Mui-error fieldset': {
      borderColor: '#FF2323',
    },

    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      WebkitBoxShadow: '0 0 0 1000px white inset',
      padding: '0rem  1.4rem ',
    },
  },

  '& .MuiInputAdornment-root .MuiButtonBase-root': {
    marginRight: '0.5rem',
  },
}))

export function FormCheckbox({ control, name, rules = {}, checked, smallIcon, sx, ...props }) {
  const {
    field: { ref, ...fieldprops },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <>
      <FormControlLabel
        sx={{
          mx: 0,

          width: '100%',
          '& .checkbox': {
            pl: '0 !important',
          },

          '& .label': {
            width: '100%',
            justifyContent: 'space-between',

            '& .text': {
              width: { lg: '39rem', xs: '25rem' },
              fontSize: { lg: '1.5rem', xs: '1.4rem' },
              lineHeight: 1.1,
            },
          },
          ...sx,
        }}
        {...props}
        control={
          <Checkbox
            className="checkbox"
            icon={<SquareIcon2 />}
            checkedIcon={<CheckedSquareIcon3 sx={{ width: '2rem', height: '2rem' }} />}
            {...fieldprops}
            checked={checked}
            inputRef={ref}
          />
        }
      />
    </>
  )
}

export const AgreementReadButton = styled(Button)(({ theme }) => ({
  height: '2.7rem',

  fontWeight: 500,
  fontSize: '1.4rem',
  lineHeight: 1.1,

  paddingLeft: '1.6rem',
  paddingRight: '1.6rem',
  boxShadow: 'none',

  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
    paddingLeft: '0.9rem',
    paddingRight: '0.9rem',
  },
}))

export const TextInput = styled(FormTextField)(({ theme }) => ({
  width: '100%',
  // width: '35rem',

  [theme.breakpoints.up('lg')]: {
    // marginRight: '2rem',
  },

  [theme.breakpoints.down('lg')]: {
    // marginBottom: '1.8rem',
  },

  '& .MuiInputBase-root': {
    padding: 0,
    height: '4rem',
    fontWeight: 400,
    fontSize: '1.6rem',
    lineHeight: '2.1rem',
  },

  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.draph.blue,
      borderWidth: '0.1rem',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D9E1EC',
      borderWidth: '0.1rem',
    },

    '&.Mui-focused fieldset': {},

    '& .MuiOutlinedInput-input': {
      WebkitBoxShadow: '0 0 0 1000px white inset',
      padding: '0rem  1.4rem ',
    },
  },

  '& .MuiInputAdornment-root .MuiButtonBase-root': {
    marginRight: '0.5rem',
  },
}))
