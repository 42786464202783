import { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import 'swiper/css'

import { getS3ImageSrc } from 'utils/common'
import { Desktop, Mobile, useDesktopMediaQuery } from 'hooks/useMediaQuery'
import { useMediaQuery } from 'react-responsive'

// import './RollingShowcase.css'

// https://codesandbox.io/s/swiper-autoplay-forked-lce07y?file=/index.html

// const StyledSwiper = styled(Swiper)(({ theme }) => ({
//   width: '100%',
// }))
// const StyledSlide = styled(SwiperSlide)(({ theme }) => ({
//   '&.swiper-slide': {
//     width: '40rem',
//     height: '30rem',
//     display: 'inline-flex',
//   },

//   fontSize: '2rem',
//   textAlign: 'center',
//   backgroundColor: 'lightgreen',
//   justifyContent: 'center',
//   alignItems: 'center',
// }))

const swiperStyle = {
  width: '100%',
}

const slideStyle = {
  width: '36rem',
  height: '36rem',
  display: 'inline-flex',

  borderRadius: '15px',
  textAlign: 'center',
  backgroundColor: 'lightgray',
  justifyContent: 'center',
  alignItems: 'center',
}

const mobileSlideStyle = {
  width: '15rem',
  height: '15rem',
  display: 'inline-flex',

  borderRadius: '15px',
  textAlign: 'center',
  backgroundColor: 'lightgray',
  justifyContent: 'center',
  alignItems: 'center',
}

const swiperProps = {
  slidesPerView: 'auto',
  spaceBetween: 30,
  speed: 3000,
  loop: true,
  grabCursor: true,
  modules: [Autoplay],
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  freeMode: true,
}

const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '15px',
}

const mobileImageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '15px',
}

const samples = [
  '/static/images/showcase/showcase1.png',
  '/static/images/showcase/showcase2.png',
  '/static/images/showcase/showcase3.png',
  '/static/images/showcase/showcase4.png',
  '/static/images/showcase/showcase5.png',
  '/static/images/showcase/showcase6.png',
  '/static/images/showcase/showcase7.png',
  '/static/images/showcase/showcase8.png',
]

// TODO: 이미지가 적을 떄 autoplay 동작 이상함.. 많을때도 이상함 그냥 이상함
export default function RollingShowcase({ items }) {
  const navigate = useNavigate()

  // useEffect(() => {
  //   const swiper = new Swiper('.showcase-swiper', swiperProps)
  //   return () => {
  //     swiper.destroy()
  //   }
  // }, [])

  const handleClick = item => e => {
    navigate(`/portfolio/${item.portfolioId}?artworkId=${item.artworkId}`)
  }

  return (
    <>
      <Desktop>
        <Swiper {...swiperProps}>
          {items?.length > 0
            ? items.map((item, i) => (
                <SwiperSlide key={i} style={slideStyle} onClick={handleClick(item)}>
                  <img src={getS3ImageSrc(item.image)} style={imageStyle} />
                </SwiperSlide>
              ))
            : samples.map((sample, i) => (
                <SwiperSlide className="swiper-slide" key={i} style={slideStyle}>
                  <img src={sample} style={imageStyle} />
                </SwiperSlide>
              ))}
        </Swiper>
      </Desktop>

      <Mobile>
        <Swiper {...swiperProps}>
          {items?.length > 0
            ? items.map((item, i) => (
                <SwiperSlide key={i} style={mobileSlideStyle} onClick={handleClick(item)}>
                  <img src={getS3ImageSrc(item.image)} style={imageStyle} />
                </SwiperSlide>
              ))
            : samples.map((sample, i) => (
                <SwiperSlide className="swiper-slide" key={i} style={mobileSlideStyle}>
                  <img src={sample} style={imageStyle} />
                </SwiperSlide>
              ))}
        </Swiper>
      </Mobile>
      {/*
      <div className="swiper showcase-swiper" style={swiperStyle}>
        <div className="swiper-wrapper">
          {items?.length > 0
            ? items.map((item, i) => (
                <div
                  className="swiper-slide"
                  key={i}
                  style={slideStyle}
                  onClick={handleClick(item)}
                >
                  <img src={getS3ImageSrc(item.image)} style={imageStyle} />
                </div>
              ))
            : x.map((_, i) => <div className="swiper-slide" key={i} style={slideStyle}></div>)}
        </div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
      </div> */}
    </>
  )
}
