import crudAxios from './configs/crud'

export const commonAPI = {
  getVersion: () => {
    return crudAxios.get('/common/version')
  },
  getPlans: () => {
    return crudAxios.get('/common/plans')
  },
  getAppStatus: () => {
    return crudAxios.get('/common/app_status')
  },
  submitApiInquiry: body => {
    return crudAxios.post('/common/api_inquiry', body)
  },
  sendRegisterCodeMail: body => {
    return crudAxios.post('/common/register_code', body)
  },
  verifyRegisterCode: body => {
    return crudAxios.post('/common/verify_register_code', body)
  },
  applyEvent: (id, body) => {
    // 비회원 응모 이벤트
    return crudAxios.post(`/common/apply_event/${id}`, body)
  },
  getBestPracticeImages: () => {
    return crudAxios.get(`/common/imgs`)
  },

  checkPlanValid: body => {
    return crudAxios.post('/common/check_plan', body)
  },
}
