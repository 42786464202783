import { Box, Stack, Typography, Button, TextField } from '@mui/material'
import { CenterAlignStack, FormErrorMessage, TextInput } from 'components'
import { Desktop, Mobile, useBreakPoint } from 'hooks/useMediaQuery'
import { useEffect, useRef, useState } from 'react'
import { apis } from 'apis'
import { DataGrid } from '@mui/x-data-grid'
import { HeaderName, CellTypography } from 'pages/UserCharge'
import { utcToLocal, getCurrencyFormatter } from 'utils/common'
import { StyledDataGrid } from './AdminPromotion'
import { usePricing } from 'hooks/usePricing'

const columns = [
  {
    field: 'id',
    width: 80,
    headerName: 'ID',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'username',
    width: 120,
    headerName: 'Username',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
  {
    field: 'credit',
    width: 120,
    headerName: 'credit',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
  },
]

export default function AdminCreditHistory() {
  const [note, setNote] = useState('어드민 지급')
  const [userIdInput, setUserIdInput] = useState('')
  const [targetUser, setTargetUser] = useState(null)
  const [creditHistory, setCreditHistory] = useState([])
  const [creditAmount, setCreditAmount] = useState(10)

  const { getPlanDisplayName } = usePricing()
  useEffect(() => {}, [])

  const getTargetUser = () => {
    if (userIdInput.length < 1) return
    apis.admin.getUserById(userIdInput).then(response => {
      setTargetUser(response.data[0])
    })

    apis.admin.getUserCredtiHistory(userIdInput).then(response => {
      setCreditHistory(response.data)
    })
  }

  const creditColumns = [
    {
      field: 'date',
      flex: 1.5,
      headerName: '충전일',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: params => {
        return params.value ? (
          <CellTypography>
            <Desktop>{utcToLocal(params.value, 'YYYY-MM-DD')}</Desktop>
            <Mobile>{utcToLocal(params.value, 'YY.MM.DD')}</Mobile>
          </CellTypography>
        ) : (
          <></>
        )
      },
    },
    {
      field: 'type',
      headerName: '상세 내역',
      align: 'center',
      headerAlign: 'center',
      flex: 1.5,
      sortable: false,
      renderCell: params => {
        const creditTypeArr = params.value.split('_')
        let value = params.value
        if (['paygo', 'subscription'].includes(creditTypeArr[0])) {
          value = getPlanDisplayName(creditTypeArr[1])
        } else if (creditTypeArr[0].includes('promotion')) {
          value = '프로모션 크레딧 ' + params.row.note
          // TODO 프로모션 정보 가져다 붙이기
        }
        return <CellTypography>{value}</CellTypography>
      },
    },
    {
      field: 'amount',
      headerName: '크레딧',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      flex: 1.5,
      renderCell: params => <CellTypography>{params.value}</CellTypography>,
    },
    {
      field: 'paid_price',
      headerName: '결제 금액',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      sortable: false,
      renderCell: params => {
        const currency = params.row.currency ?? 'krw'
        const currencyFormatter = getCurrencyFormatter(currency)
        const v = currencyFormatter.format(params.value)
        return <CellTypography>{params.value ? v : '-'}</CellTypography>
      },
    },
    {
      field: 'expires',
      headerName: '유효 기간',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      sortable: false,
      renderCell: params =>
        params.value ? (
          <CellTypography>
            <Desktop>{utcToLocal(params.value, 'YYYY-MM-DD')}</Desktop>
            <Mobile>{utcToLocal(params.value, 'YY.MM.DD')}</Mobile>
          </CellTypography>
        ) : (
          <></>
        ),
    },
    {
      field: 'etc',
      headerName: '비고',
      headerAlign: 'center',
      align: 'center',
      flex: 1.5,
      sortable: false,
    },
  ]

  const handleAddCredit = () => {
    const c = confirm(`${targetUser?.username} 에게 ${creditAmount} 크레딧을 지급합니다??`)
    if (!c) return

    apis.admin.addCreditToUser({
      target_user_id: targetUser.id,
      credit_amount: creditAmount,
      note,
    })
  }

  return (
    <div>
      <CenterAlignStack sx={{ width: '100%', mb: '10rem' }}>
        <Typography sx={{ fontWeight: 800, fontSize: '3rem' }}>
          크레딧 지급/사용 내역 조회
        </Typography>

        <Stack direction="row">
          <TextField
            InputLabelProps={{ shrink: true }}
            label="user id 또는 username"
            autoComplete="off"
            value={userIdInput}
            onChange={e => setUserIdInput(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                getTargetUser()
              }
            }}
          ></TextField>
          <Button variant="outlined" onClick={getTargetUser}>
            유저 조회
          </Button>
        </Stack>

        <Box>
          <Typography>유저정보</Typography>
          <Box sx={{ width: '50rem', height: '20rem' }}>
            <DataGrid
              disableColumnMenu
              rows={targetUser ? [targetUser] : []}
              columns={columns}
              autoHeight
              hideFooter
            />
          </Box>
        </Box>

        <Box>
          <Typography>크레딧 지급/사용 내역</Typography>
          <Box
            sx={{ width: '80rem', height: '50rem', overflow: 'scroll', border: '2px solid gray' }}
          >
            <StyledDataGrid
              disableColumnMenu
              rows={creditHistory ?? []}
              columns={creditColumns}
              autoHeight
              hideFooter
            />
          </Box>
        </Box>
      </CenterAlignStack>
    </div>
  )
}
